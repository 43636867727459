import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useState } from "react";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import Popconfirm from "../modal/Popconfirm";
import { uploadFileToDatabase } from "../../data/slices/createAdminSlice";

const UploadFile = ({
	appSlice, appDefinition, appName, collection, spaceInfo = {}, isIcon = true, refineKeys = [],
	onSuccess, numbersToStrings = false
}) => {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	const dispatch = useDispatch();

	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const [selectedFile, setSelectedFile] = useState(undefined);
	const [isUploaded, setIsUploaded] = useState(false);
	const [isUploadError, setIsUploadError] = useState(false);

	// All the numbers will be converted to Strings. It does not
	// apply to the numbers within the arrays.
	const convertNumbersToStrings = (data) => {
		const converted = data.map((el) => {
			const values = Object.keys(el).reduce((acc, curr) => {
				if (typeof el[curr] === "number") {
					acc[curr] = el[curr]?.toString();
				} else {
					acc[curr] = el[curr];
				}
				return acc;
			}, {});
			return values;
		});
		return converted;
	};

	const readUploadFile = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const reader = new FileReader();
			reader.onload = (e) => {
				let uploadValue = [];
				const data = e.target.result;
				const workbook = XLSX.read(data, { type: "array" });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json = XLSX.utils.sheet_to_json(worksheet);

				uploadValue = numbersToStrings ? convertNumbersToStrings(json) : [...json];

				dispatch(uploadFileToDatabase({
					appName,
					dataset: {
						collection,
						data: uploadValue,
						adminCallFromApp: activeRefines?.secLevelMenu,
						spaceInfo // if it's an empty object (spaceInfo is not provided) it applies to all the clients/projects.
						// In other words the data in the collection will not have spaceLabel or client information.
					},
					resetSkip: false
				}))
				.unwrap()
				.then((originalPromiseResult) => {
					const isSuccess = originalPromiseResult.res.every((item) => item === 1);

					if (isSuccess) {
						console.log("Upload file success");

						dispatch(appSlice.actions.refine(refineKeys.onSuccess));

						setSelectedFile(undefined);
						setIsUploaded(false);
						onSuccess();
					} else {
						// return error item position in file
						const res = originalPromiseResult.res.reduce((acc, cur, i) => {
							if (cur !== 1) return [...acc, i];
							return [...acc];
						}, []);

						console.log("upload file to database fail", res);
						setIsUploadError(true);
					}
				})
				.catch((rejectedValueOrSerializedError) => {
					console.log("Upload error", rejectedValueOrSerializedError);
					setIsUploaded(false);
					setIsUploadError(true);
				});
			};

			reader.readAsArrayBuffer(e.target.files[0]);
		}

		// It's enough to close the upload confirmation modal.
		setIsUploaded(false);
	};

	return (
		<>
			<label
				htmlFor="uploadFile"
				className={`${isIcon ?
					"text-admin_primary-accent rounded-xl border border-gray-100 shadow-sm p-1 bg-white hover:border-gray-200 cursor-pointer"
					:
					`bg-admin_primary-default text-white hover:bg-admin_primary-dark w-15 font-bold uppercasetext-smaller
                    md:text-xs lg:text-smaller xl:text-xs 2xl:text-smaller px-3 md:px-1 lg:px-2 xl:px-1.5 2xl:px-3 py-1 
                    rounded ease-linear transition-all duration-150 z-3xl text-center`
            }`}
			>
				{isIcon ? <FileUploadIcon className="text-admin_primary-default" /> : "UPLOAD"}
			</label>
			<input
				type="file"
				id="uploadFile"
				className="hidden invisible w-0"
				title="Import data in Excel format"
				onChange={(e) => {
					setSelectedFile(e);
					setIsUploaded(true);
				}}
			/>
			{isUploaded && (
				<Popconfirm
					title="Upload"
					// eslint-disable-next-line max-len
					description="You are about to upload this file to the database. Caution: If you are not editing the downloaded file but using a new local file, there is a risk of losing all existing data. Are you sure you want to proceed with this action?"
					confirmBtnText="Confirm"
					iconsType="exclamationMark"
					onClickConfirm={() => { readUploadFile(selectedFile); }}
					onClickCancel={() => {
						setIsUploaded(false);
					}}
				/>
			)}
			{isUploadError && (
				<Popconfirm
					title="Something went wrong"
					description={"The file upload has failed, possibly due to insufficient permissions or an incompatible file format."}
					showConfirm={false}
					iconsType="exclamationMark"
					onClickCancel={() => {
						setIsUploadError(false);
					}}
				/>
			)}
		</>
	);
};

UploadFile.propTypes = {
	appSlice: PropTypes.object,
	appDefinition: PropTypes.object,
	appName: PropTypes.string,
	collection: PropTypes.string,
	spaceInfo: PropTypes.object,
	isIcon: PropTypes.bool,
	refineKeys: PropTypes.array,
	onSuccess: PropTypes.func,
	numbersToStrings: PropTypes.bool
};

export default UploadFile;
