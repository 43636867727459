import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import BusinessIcon from "@mui/icons-material/Business";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssessmentIcon from "@mui/icons-material/Assessment";

import { appDefinition } from "../../apps/configs/AtAGlanceConfig";

function AtAGlanceSiteDetailedCard({
	appSlice, dataset, extraDataSet, programs
}) {
	const { t } = useTranslation(appDefinition.locales);

	// Load the store hook
	const dispatch = useDispatch();

	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const data = useSelector(appSlice.selectDatasets)[dataset]?.data;
	const assessements = useSelector(appSlice.selectDatasets)[extraDataSet]?.data;

	const isData = data?.length > 0 && assessements?.length > 0;

	const filteredAssessment = () => {
		if (activeRefines?.siteUniqueId !== undefined) {
			return assessements?.filter((el) => (el.auditedCompanies.includes(activeRefines?.siteUniqueId)))[0];
		}
		if (activeRefines?.supplier !== undefined) {
			return assessements?.filter((el) => (el._id === activeRefines.supplier))[0];
		}
	};

	const comp = () => (
		<div className="bg-white p-5 rounded-lg relative h-full">
			<div className="bg-black rounded-lg w-fit cursor-pointer absolute -left-4"
				onClick={() => dispatch(appSlice.actions.clear([{ refine: "siteUniqueId" }, { refine: "supplier" }]))}
			>
				<ArrowBackIcon className="text-white" />
			</div>
			<div className="flex">
				<BusinessIcon fontSize="large" className="mr-2" />
				<div className="flex flex-growclose flex-col pt-2 text-ellipsis w-full">
					<p className="space-x-2">{data?.[0].label}</p>
				</div>
			</div>
			{data?.[0].options.map((site, i) => (
				<>
					<div key={i} className="flex">
						<HomeWorkIcon fontSize="medium" className="ml-1 mr-2"/>
						<div>
							<p
								className="text-medium text-ellipsis w-full cursor-pointer space-x-2"
								onClick={() => {
									dispatch(appSlice.actions.refine([{ key: "siteUniqueId", value: site.value }]));
									dispatch(appSlice.actions.clear([{ refine: "supplier" }]));
								}}
							>{site.label}</p>
							<p className="text-smaller font-light text-ellipsis w-36">{site.city} - {site.country}</p>
						</div>
					</div>
					{/* Show product only when showing only one site on the card */}
					{data?.[0].options?.length === 1 &&
					<div className="flex">
						<div className="w-8 ml-6">
							<DeviceHubIcon fontSize="small"/>
						</div>
						<p className="mt-1 text-sm line-clamp-1">{site.product}</p>
					</div>
					}
				</>
			))}
			{/* Show product only when showing only one site on the card */}
			{data?.[0].options?.length === 1 &&
				<div className={"absolute border-gray-400 border-l border-b w-2 top-[80px] left-[32px] h-6"}/>
			}
			{/* Show the number of assessments only when the card shows only one audited company */}
			{data?.[0].options?.length === 1 &&
				<>
					<div className="flex mt-2">
						<AssessmentIcon fontSize="medium" className="ml-1 mr-2"/>
						<p className="flex text-ellipsis w-full pt-[1px]">{t("sitePage.assessments")}</p>
					</div>
					<div className="ml-7 mt-[2px] relative">
						{programs.map((program, i) => (
							<>
								<div key={i}
									className={"absolute border-gray-400 border-l border-b w-2 -left-[12px] h-4"}
									style={{ top: `${-3 + i * 22}px` }}
								/>
								<div className="flex pt-[3px]">
									<p className="text-[12px]">{program}: </p>
									<p className="text-[9px] bg-sky-300 rounded-md px-2 ml-1 self-center pt-[1px]">
										{`${filteredAssessment()[program]}/${filteredAssessment().companiesPerSupplier} ${t("sitePage.sites")}`}</p>
								</div>
							</>
						))}
					</div>
				</>
			}
		</div>
	);

	return (
		<>
			{isData ? comp() : <div className="bg-slate-100 p-5 rounded-lg relative h-full"></div>}
		</>
	);
}

AtAGlanceSiteDetailedCard.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	extraDataSet: PropTypes.string,
	programs: PropTypes.array
};

export default AtAGlanceSiteDetailedCard;
