import { configureStore, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit";
import { userSlice } from "./slices/UserSlice";

const staticReducers = {
	user: userSlice.reducer
};

function createReducer(asyncReducers) {
	return combineReducers({
		...staticReducers,
		...asyncReducers
	});
}

// Configure the store
export default function configureAppStore() {
	const store = configureStore({
		reducer: createReducer(),
		middleware: getDefaultMiddleware({
			serializableCheck: false
		})
	});

	// Create an object for any later reducers
	store.asyncReducers = { };

	// Create an inject reducer function
	// This function adds the async reducer, and creates a new combined reducer
	store.injectReducer = (key, asyncReducer) => {
		store.asyncReducers[key] = asyncReducer;
		store.replaceReducer(createReducer(store.asyncReducers));
	};

	return store;
}
