import React, {
	useState, useRef
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import chroma from "chroma-js";

import DoneAllIcon from "@mui/icons-material/DoneAll";

import ArrayFieldEditor from "./ArrayFieldEditor";
import FieldTransformerController from "./FieldTransformerController";
import SelectFieldEditor from "./SelectFieldEditor";

import CreatFormBtnGroup from "../button/CreatFormBtnGroup";
import Popconfirm from "../modal/Popconfirm";

import IF from "../../utils/IF";

import {
	appDefinition, currentFieldOptions, typeSelector, fieldAttributeBuilder
} from "../../apps/configs/AdminConfig";

// remove k key when field type is array
const RemoveKey = (submitValue) => {
	// remove key k in case field is type of array of object
	const sumbitKeyValuePair = Object.entries(submitValue);

	const removeKeyK = sumbitKeyValuePair.reduce((arr, cur) => {
		let getValue;
		if (Array.isArray(cur[1])) {
			getValue = cur[1].map((el) => (el.k));
			return { ...arr, [cur[0]]: getValue };
		}

		return { ...arr, [cur[0]]: cur[1] };
	}, {});

	return removeKeyK;
};

const dot = (color = "transparent") => ({
	alignItems: "center",
	display: "flex",

	":before": {
		backgroundColor: color,
		borderRadius: 4,
		content: "\" \"",
		display: "block",
		marginRight: 8,
		height: 16,
		width: 16
	}
});

const defaultStyles = {
	control: (styles) => ({
		...styles,
		backgroundColor: "white",
		border: "1px solid lightgray", // default border color
		"&:hover": { borderColor: "#1c3faaff", border: "2px solid" }, // border style hover color:admin_primary-default
		boxShadow: "none",
		margin: "0.5rem",
		borderRadius: "0.5rem"
	})
};

const colourStyles = {
	control: (styles) => ({
		...styles,
		backgroundColor: "white",
		border: "1px solid lightgray",
		"&:hover": { borderColor: "#1c3faaff", border: "2px solid" },
		boxShadow: "none",
		margin: "0.5rem",
		borderRadius: "0.5rem"
	}),
	option: (styles, {
		data, isDisabled, isFocused, isSelected
	}) => {
		const color = chroma(data.color);
		return {
			...styles,
			backgroundColor: isDisabled
				? undefined
				: isSelected
					? data.color
					: isFocused
						? color.alpha(0.1).css()
						: undefined,
			color: isDisabled
				? "#ccc"
				: isSelected
					? chroma.contrast(color, "white") > 2
						? "white"
						: "black"
					: data.color,
			cursor: isDisabled ? "not-allowed" : "default",

			":active": {
				...styles[":active"],
				backgroundColor: !isDisabled
					? isSelected
						? data.color
						: color.alpha(0.3).css()
					: undefined
			}
		};
	},
	input: (styles) => ({ ...styles, ...dot() }),
	placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
	singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
};

const AdminDomainUpdateForm = ({
	data,
	appSlice,
	clearRefineKeys,
	targetDocAccessor,
	renderButtonGroup = true,
	screenHeight,
	requiredFields,
	onConfirm,
	disabledFields = []
}) => {
	const dispatch = useDispatch();
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const [confirmModal, setconfirmModal] = useState(false);

	// get all the field from mongoDB
	const allFieldsOfCurrentCollection = [...new Set(data?.flatMap((el) => Object.keys(el)))];

	// all the fields in key type pair
	const AllKeyTypePair = Object.entries(Object.fromEntries(new Map(data?.flatMap((el) => Object.entries(el)))))
	?.reduce((acc, cur) => ({ ...acc, [cur[0]]: typeSelector(cur, activeRefines) }), {});

	const targetDocument = data.find(targetDocAccessor);

	const {
		control, register, handleSubmit, formState: { errors }, reset, getValues, setValue, watch
	} = useForm({
		defaultValues: targetDocument,
		criteriaMode: "firstError",
		shouldFocusError: true
	});

	const onSubmit = (e) => {
		e.preventDefault();
		setconfirmModal(true);
	};

	const submit = (submitValue) => {
		let res = RemoveKey(submitValue);
		res = { ...res, _id: targetDocument._id };

		const resWithoutId = { ...res };
		delete resWithoutId._id;

		onConfirm(res, resWithoutId, setconfirmModal);
	};

	// prevent enter event from triggering form to submit
	const checkKeyDown = (e) => {
		if (e.key === "Enter") e.preventDefault();
	};

	const getStyles = (field) => {
		if (field === "domainColor") {
			return (colourStyles);
		}
		return (defaultStyles);
	};

	const customLabelLogo = (e) => (
		<div style={{ display: "flex", alignItems: "center" }}>
			<img src={e.value} alt={e.label} className="w-10 h-10 my-1 mr-2"/>
			{e.label}
		</div>
	);

	const optionalProps = (field) => (field === "domainLogo") ? { customLabel: customLabelLogo } : {};

	return (
		<>
			{confirmModal
			&& <Popconfirm
				title="Update current domain"
				description="Are you sure you want to update this  domain?"
				confirmBtnText="Confirm"
				onClickConfirm={handleSubmit(submit)}
				onClickCancel={() => setconfirmModal(false)}
			/>

			}
			{renderButtonGroup &&
				<div className="flex flex-row space-x-1 border-b border-admin_primary-default pb-2">

					<CreatFormBtnGroup appSlice={appSlice} locales={appDefinition.locales}
						renderGroup={true} renderBack={true} renderAdd={false} clearRefineKeys={clearRefineKeys}/>
				</div>}

			<form
				className="flex flex-col px-2 my-4 space-y-6 overflow-y-auto"
				style={{ height: screenHeight - 260 }}
				onSubmit={onSubmit}
				onKeyDown={(e) => checkKeyDown(e)}
			>
				{allFieldsOfCurrentCollection
				?.filter((item) => !fieldAttributeBuilder(activeRefines.secLevelMenu, "domainsHiddenFields")?.includes(item))
				?.map((field, i) => (
					<div key={`field-${field}-${i}`}>
						<IF condition={AllKeyTypePair[field] === "select"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                    text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${field}`}
								</span>
								<SelectFieldEditor
									field={field}
									control={control}
									appSlice={appSlice}
									options={currentFieldOptions(field)}
									styles={ getStyles(field) }
									{...optionalProps(field)}
									requiredFields={requiredFields}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "string"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${field}`}
								</span>
								<input {...register(`${field}`, { required: true })}
									className="p-2 my-2 text-black border rounded-lg bg-white focus:outline-none focus:ring-2
									focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>
					</div>
				))}

				<div className="flex flex-col space-y-4 mt-10">
					<button className="border bg-admin_primary-default hover:bg-admin_primary-dark
                    text-white font-bold py-1 px-3 rounded uppercase" type="submit">
						<DoneAllIcon />
					</button>
				</div>

			</form>
		</>
	);
};

AdminDomainUpdateForm.propTypes = {
	appSlice: PropTypes.object,
	clearRefineKeys: PropTypes.array,
	data: PropTypes.array.isRequired,
	targetDocAccessor: PropTypes.func,
	renderButtonGroup: PropTypes.bool,
	screenHeight: PropTypes.number,
	requiredFields: PropTypes.array,
	onConfirm: PropTypes.func,
	disabledFields: PropTypes.array
};

export default AdminDomainUpdateForm;
