import PropTypes from "prop-types";
import React from "react";
import { Marker, Popup } from "react-leaflet";
import { useTranslation } from "react-i18next";
import L from "leaflet";

import BusinessIcon from "@mui/icons-material/Business";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { getMarkerColor } from "../../apps/configs/SupplyRConfig";
import getValueNestedObject from "../../utils/GetValueNestedObject";

const getIcon = (pathFillColor) => {
	const svgTemplate = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 16 16" xml:space="preserve">
   <path fill="${pathFillColor}" class="path1" stroke="black" stroke-width= "0.3px"
   d="M8 2.1c1.1 0 2.2 0.5 3 1.3 0.8 0.9 1.3 1.9 1.3 3.1s-0.5 2.5-1.3 3.3l-3
   3.1-3-3.1c-0.8-0.8-1.3-2-1.3-3.3 0-1.2 0.4-2.2 1.3-3.1 0.8-0.8 1.9-1.3 3-1.3z"></path>

</svg>`;

	const icon = L.divIcon({
		className: "marker",
		html: svgTemplate,
		iconSize: [48, 48],
		iconAnchor: [24, 36],
		popupAnchor: [7, -16]
	});
	return icon;
};

const AdminMapMarker = ({
	appSlice,
	marker,
	width = 182,
	transparent = false,
	geopointField,
	filtered = false
}) => {
	const geopoint = getValueNestedObject(marker, geopointField);

	if (!geopoint || geopoint?.[0] === null || geopoint?.[0] === "") {
		return null;
	}

	const score = (!filtered && marker.resilience) ?
		marker.resilience
		: marker?.score?.[0]?.resilience;

	return (
		<Marker score={marker?.resilience}
			position={[Number(geopoint[1]), Number(geopoint[0])]}
			icon={getIcon(getMarkerColor(score))}
		>
			<Popup direction="top" offset={[-8, -8]} opacity={1} >
				<div className="-my-2 -mx-4 z-2xl">
					<div className={`relative flex flex-col w-full gap-y-1.5 rounded-2xl px-4 py-2.5 border-2 ${ !transparent && "alphaBlur"}` }
						style={{ width }}>

						<div id="company" className="flex gap-x-2 w-full" >
							<BusinessIcon />
							<div className="flex flex-col" style={{ maxWidth: width - 25 }}>
								<p className="text-medium leading-6 line-clamp-1 font-semibold">{marker.supplier}</p>
							</div>
						</div>

						<div className="flex w-full gap-x-2.5">
							<MapsHomeWorkIcon className="text-sm ml-2"/>

							<p className="text-small line-clamp-2" >
								{marker.auditedCompany}
							</p>
						</div>

						<div id="location" className="flex border items-center gap-x-2 px-2 py-1 border-gray-200 rounded-md text-medium ">
							<PinDropIcon fontSize="inherit" />
							<p className="text-small leading-4 line-clamp-1 font-light" style={{ maxWidth: width - 25 }}>
								{marker.city} - {marker.country}
							</p>
						</div>

					</div>
				</div>
			</Popup>
		</Marker>
	);
};

AdminMapMarker.propTypes = {
	appSlice: PropTypes.any,
	filtered: PropTypes.bool,
	geopointField: PropTypes.any,
	marker: PropTypes.shape({
		auditedCompany: PropTypes.any,
		city: PropTypes.any,
		country: PropTypes.any,
		resilience: PropTypes.any,
		score: PropTypes.any,
		supplier: PropTypes.any
	}),
	transparent: PropTypes.bool,
	width: PropTypes.number

};

export default AdminMapMarker;
