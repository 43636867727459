import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import AddBusinessIcon from "@mui/icons-material/AddBusiness";

import VendorDDSiteCard from "../cards/VendorDDSiteCard";

function VendorDDTooltip({ appSlice, marker, locales }) {
	// Load the store hook
	const dispatch = useDispatch();

	// Internationalization hook
	const { t } = useTranslation(locales);

	const activeRefines = useSelector(appSlice.selectActiveRefines);

	return (
		<div className="flex flex-col gap-y-2 w-56">
			<VendorDDSiteCard appSlice={appSlice} siteData={marker} locales={locales} />
			{activeRefines.vendorId === undefined && <button
				className="flex items-center justify-center rounded-lg bg-vendorDD_primary-menu
                 text-white px-3 py-2 gap-x-1 cursor-pointer"
				onClick={() => dispatch(appSlice.actions.refine([{ key: "vendorId", value: marker.vendorId }]))}>
				<AddBusinessIcon />
				<p className="text-sm pl-1 cursor-pointer">{t("mapPage.tooltip.viewSite")}</p>
			</button>}
		</div>
	);
}

VendorDDTooltip.propTypes = {
	appSlice: PropTypes.object,
	marker: PropTypes.object,
	locales: PropTypes.string
};

export default VendorDDTooltip;
