import React from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import CheckboxController from "./CheckboxController";
import IF from "../../utils/IF";
import { typeSelector } from "../../apps/configs/AdminConfig";

const ConditionalRenderField = ({
	field,
	fieldValue: originFieldValue,
	register,
	watch,
	setValue,
	activeRefines,
	options
}) => {
	const fieldValueType = typeSelector([field, originFieldValue], activeRefines);

	const supplychainParams = watch("supplychainParams");

	const handleIsCommonChange = (selectedOption) => {
		const isCommon = selectedOption.value === true;
		const newGroupKey = isCommon ? "siteUniqueId" : "siteLabelId";
		setValue("supplychainParams.groupKey", newGroupKey);
		setValue("supplychainParams.isCommon", isCommon);
	};

	const handleGroupKeyChange = (selectedOption) => {
		setValue("supplychainParams.groupKey", selectedOption.value);
	};

	const onChangeBuilder = (field) => {
		switch (field) {
			case "supplychainParams.isCommon":
				return handleIsCommonChange;

			case "supplychainParams.groupKey":
				return handleGroupKeyChange;

			default:
				break;
		}
	};

	const optionBuilder = (field, options) => {
		switch (field) {
			case "supplychainParams.groupKey":
				return options.filter((option) => {
					if (supplychainParams.isCommon) {
						return option.value === "siteUniqueId";
					}
					return (
						option.value === "siteLabelId" || option.value === "siteId"
					);
				});

			default:
				return options;
		}
	};

	const valueBuilder = (field, options) => {
		switch (field) {
			case "supplychainParams.isCommon":
				return options.find(
					(option) => option.value === supplychainParams.isCommon
				);
			case "supplychainParams.groupKey":
				return options.find(
					(option) => option.value === supplychainParams.groupKey
				);

			default:
				return options?.[0];
		}
	};
	const disableBuilder = (field) => {
		switch (field) {
			case "supplychainParams.groupKey":
				return supplychainParams.isCommon;

			default:
				break;
		}
	};

	return (
		<>
			<IF condition={fieldValueType === "select" }>
				<label className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
								text-admin_primary-default border px-2 py-3 rounded-lg"
				>
					<span className="w-min h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
						{field}
					</span>
					<Select
						{...register("supplychainParams.groupKey")}
						id="groupKey"
						name="groupKey"
						options={optionBuilder(field, options)}
						value={valueBuilder(field, options)}
						onChange={onChangeBuilder(field)}
						isDisabled={disableBuilder(field)}
					/>
				</label>
			</IF>

		</>
	);
};

ConditionalRenderField.propTypes = {
	activeRefines: PropTypes.any,
	field: PropTypes.any,
	fieldValue: PropTypes.any,
	register: PropTypes.func,
	setValue: PropTypes.func,
	targetDocument: PropTypes.any,
	watch: PropTypes.func,
	options: PropTypes.array
};

export default ConditionalRenderField;
