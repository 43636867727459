import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { max } from "d3-array";
import { Bar, LinePath } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom } from "@visx/axis";
import * as allCurves from "@visx/curve";
import { withTooltip, useTooltipInPortal } from "@visx/tooltip";
import { Text } from "@visx/text";
// accessors
const getDefaultX = (d) => d.month;
const getDefaultY = (d) => d.value;
const getDefaultFrequency = (d) => d.frequency;
const getDefaultTopInfo = (d) => d.topInfo;
const line1 = [{
	month: "Jan", value: 70
},
{
	month: "Feb", value: 75
},
{
	month: "Mar", value: 73
},
{
	month: "Apr", value: 65
},
{
	month: "May", value: 50
},
{
	month: "June", value: 30
}];

const line2 = [{
	month: "Jan", value: 110
},
{
	month: "Feb", value: 115
},
{
	month: "Mar", value: 110
},
{
	month: "Apr", value: 105
},
{
	month: "May", value: 110
},
{
	month: "June", value: 110
}];

const line3 = [{
	month: "Jan", value: 150
},
{
	month: "Feb", value: 145
},
{
	month: "Mar", value: 145
},
{
	month: "Apr", value: 145
},
{
	month: "May", value: 165
},
{
	month: "June", value: 175
}];

const columns = [
	{
		month: "Jan", frequency: 85
	},
	{
		month: "Feb", frequency: 70
	},
	{
		month: "Mar", frequency: 88
	},
	{
		month: "Apr", frequency: 120
	},
	{
		month: "May", frequency: 120
	},
	{
		month: "June", frequency: 120
	}
];

let tooltipTimeout;

/**
 * How to define MonthAnalysis section:
 * graph = linesAxisGroup + barsGroup
 * linesAxisGroup = lines + dots + tooltip + axisX
 * barGroup = bar + bar background + bar top circle + bar top info
 * @component
 */
// stroke-clarity_primary-red stroke-clarity_primary-yellow stroke-clarity_primary-orange stroke-clarity_primary-green
// fill-clarity_primary-red fill-clarity_primary-yellow fill-clarity_primary-orange fill-clarity_primary-green
const MonthAnalysis = ({
	appSlice,
	width = 500,
	height = 250,
	margin = {
		top: 0, left: 0, right: 0, bottom: 50
	},
	showPoints = true,
	linesNonConformities = [line1, line2, line3],
	linesMaturity = null,
	lineNbSites = columns,
	getX = getDefaultX,
	getY = getDefaultY,
	getFrequency = getDefaultFrequency,
	getTopInfo = getDefaultTopInfo,
	linesColor = ["#FF475C", "#FFC362", "#CCDB73"],
	tooltipOpen,
	tooltipLeft,
	tooltipTop,
	tooltipData,
	hideTooltip,
	showTooltip,
	fillCircleColor = "#CECECE",
	fillBarColor = "#CECECE",
	graphTop = 15,
	barsGroupTop = 0,
	linesAxisGroupTop = 0,
	barTopInfoTop = -7,
	barTopInfoLeft = -2,
	linesGroupTop = 0,
	yScaleDomainFactor = 1,
	colScaleDomainFactor = 2,
	isRounded = true,
	isTrimestrial = false,
	legend = undefined,
	maturityDisplay = false
}) => {
	// client parameters
	const clientParameters = useSelector(appSlice.selectClientParameters);

	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;
	const maturityGL = maturityDisplay ? linesMaturity[0]?.list.length : [];
	const graphLeft = (xMax / (maturityDisplay ? maturityGL : linesNonConformities[0].length)) / 2;
	let linesMaturityWithColor = [];
	if (maturityDisplay) {
		linesMaturityWithColor = linesMaturity.map((node) => {
			const lineColor = clientParameters?.maturityColors?.filter((colorObj) => colorObj.label === node._id);
			const color = lineColor[0]?.color;
			return node.list.map((listEl) => ({ ...listEl, color }));
		});
	}
	const allData = Array.prototype.concat.apply([], (maturityDisplay
		? linesMaturityWithColor
		: linesNonConformities));
	const linesData = maturityDisplay ? linesMaturityWithColor : linesNonConformities;
	const xScale = useMemo(
		() => scaleBand({
			range: [0, xMax],
			domain: allData.map(getX)
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[allData, xMax]
	);

	const yScale = useMemo(
		() => scaleLinear({
			range: [0, yMax],
			domain: [max(allData, getY) * yScaleDomainFactor, 0]
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[allData, yMax]
	);

	const colScale = scaleLinear({
		range: [0, yMax],
		domain: [max(lineNbSites, getFrequency) * colScaleDomainFactor, 0],
		clamp: true
	});

	const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
		scroll: true,
		detectBounds: true
	});
	return width < 10 ? null : (
		<div ref={containerRef}>
			<svg width={width} height={height} >
				<Group top={graphTop} left={graphLeft}>
					{lineNbSites.map((d, i) => {
						const relativeBarHeight = colScale(getFrequency(d)) ? colScale(getFrequency(d)) : 0;

						const month = getX(d);
						const barWidth = 7;
						const barHeight = yMax - relativeBarHeight;
						const barX = xScale(getX(d)) - barWidth / 2;
						const barY = yMax - barHeight + barsGroupTop;

						return <g key={i}>
							<text x={barX + barTopInfoLeft} y={barTopInfoTop} className="text-xs">{getTopInfo(d)}</text>
							<rect width={barWidth} height={yMax} x={barX} y={0} fill="#FBFBFB" />
							{isRounded && <circle
								r={3}
								cx={xScale(getX(d))}
								cy={colScale(getFrequency(d)) + 1}
								fill={fillCircleColor}
								stroke="transparent"
								strokeWidth={5}
								onMouseOut={() => {
									tooltipTimeout = window.setTimeout(() => {
										hideTooltip();
									}, 500);
								}}
								onMouseOver={() => {
									if (tooltipTimeout) clearTimeout(tooltipTimeout);
									showTooltip({
										tooltipData: getFrequency(d),
										tooltipTop: colScale(getFrequency(d)),
										tooltipLeft: xScale(getX(d))
									});
								}}
							/>}
							<Bar
								key={`bar-${month}`}
								x={barX}
								y={barY}
								width={barWidth}
								height={barHeight}
								fill={fillBarColor}
							/>
						</g>;
					})}

					<Group top={linesAxisGroupTop}>
						{linesData.map((lineData, i) => (
							<g key={`lines-${i}`}>
								<Group top={linesGroupTop}>
									<LinePath
										curve={allCurves.curveMonotoneX}
										data={lineData}
										x={(d) => xScale(getX(d)) ?? 0}
										y={(d) => yScale(getY(d)) ?? 0}
										stroke={linesColor[i]}
										className={maturityDisplay && `stroke-clarity_primary-${lineData[0].color}`}
									/>
									{showPoints &&
										lineData.map((d, j) => <g key={i + j}>
											<circle
												className={maturityDisplay && `fill-clarity_primary-${d.color}`}
												r={4}
												cx={xScale(getX(d))}
												cy={yScale(getY(d))}
												fill={linesColor[i]}
												stroke="transparent"
												strokeWidth={2}
												onMouseOut={() => {
													tooltipTimeout = window.setTimeout(() => {
														hideTooltip();
													}, 500);
												}}
												onMouseOver={() => {
													if (tooltipTimeout) clearTimeout(tooltipTimeout);
													showTooltip({
														tooltipData: getY(d),
														tooltipTop: yScale(getY(d)),
														tooltipLeft: xScale(getX(d))
													});
												}}
											/>
											<Text
												x={xScale(getX(d)) + 6}
												y={yScale(getY(d)) - 4}
												width="50"
												verticalAnchor="start"
												style={{ fontSize: "0.6rem" }}
											>
												{getY(d)}

											</Text>
										</g>
										)}
								</Group>
								<AxisBottom
									hideAxisLine
									hideTicks
									top={yMax}
									left={-graphLeft}
									scale={xScale}
									stroke="black"
									tickLabelProps={() => ({
										fontSize: 11,
										fontWeight: "1px",
										textAnchor: "middle"
									})}
								/>
								{tooltipOpen && (
									<foreignObject x={`${tooltipLeft}`} y={`${tooltipTop}`} width="100" height="100" >
										<TooltipInPortal
											key={Math.random()} // needed for bounds to update correctly
											left={tooltipLeft}
											top={tooltipTop}
										>
											{tooltipData}
										</TooltipInPortal>

									</foreignObject>
								)}
							</g>
						))}
					</Group>
				</Group>
			</svg>
			<div className="w-full h-8 flex justify-center items-center mt-4">
				{legend &&
				linesNonConformities?.map((line, i) => (
					<div key={`legend-${i}`} className="mx-5">
						<div className="relative">
							<div className="flex justify-center items-center">
								<div
									className="w-2.5 h-2.5 rounded-full"
									style={{ background: linesColor[i] }}>
								</div>
								<div
									className="absolute w-8 h-0.5 top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4"
									style={{ background: linesColor[i] }}>
								</div>
							</div>
						</div>
						<span className="text-sm">{legend[i]}</span>
					</div>
				))}
			</div>
		</div>
	);
};

MonthAnalysis.propTypes = {
	/** defiens appslice */
	appSlice: PropTypes.object,
	/** left margin for bar top info */
	barTopInfoLeft: PropTypes.number,
	/** top margin for bar top info */
	barTopInfoTop: PropTypes.number,
	/** variable to adjust bar group y position */
	barsGroupTop: PropTypes.number,
	/** colScale domain scale factor */
	colScaleDomainFactor: PropTypes.number,
	/** fil color of the bars */
	fillBarColor: PropTypes.string,
	/** fill color of rounded tip */
	fillCircleColor: PropTypes.string,
	/** frequency accessor */
	getFrequency: PropTypes.func,
	/** top info accessor */
	getTopInfo: PropTypes.func,
	/** x axis accessor */
	getX: PropTypes.func,
	/** y axis accessor */
	getY: PropTypes.func,
	/** graph top positionning */
	graphTop: PropTypes.number,
	/** defines height */
	height: PropTypes.number,
	/** */
	hideTooltip: PropTypes.func,
	/** */
	lineNbSites: PropTypes.array,
	/** */
	linesAxisGroupTop: PropTypes.number,
	/** */
	linesColor: PropTypes.array,
	/** */
	linesGroupTop: PropTypes.number,
	/** */
	linesNonConformities: PropTypes.array,
	/** array of objects containing maturity data. ex: [{_id: "Critical", list:[{},{}], {...}}] */
	linesMaturity: PropTypes.array,
	/** */
	margin: PropTypes.object,
	/** */
	showPoints: PropTypes.bool,
	/** */
	showTooltip: PropTypes.func,
	/** */
	tooltipData: PropTypes.any,
	/** */
	tooltipLeft: PropTypes.number,
	/** */
	tooltipOpen: PropTypes.bool,
	/** */
	tooltipTop: PropTypes.number,
	/** */
	width: PropTypes.number,
	/** */
	yScaleDomainFactor: PropTypes.number,
	/** */
	isRounded: PropTypes.bool,
	/** */
	isTrimestrial: PropTypes.bool,
	/** */
	legend: PropTypes.array,
	/** toggle maturity display */
	maturityDisplay: PropTypes.bool
};

export default withTooltip(MonthAnalysis);
