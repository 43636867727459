import PropTypes from "prop-types";
import React from "react";
import Pie from "@visx/shape/lib/shapes/Pie";
import { Group } from "@visx/group";
import { animated, useTransition, to } from "react-spring";
import { Text } from "@visx/text";

const fromLeaveTransition = ({ endAngle }) => ({
	// enter from 360° if end angle is > 180°
	startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
	endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
	opacity: 0
});
const enterUpdateTransition = ({ startAngle, endAngle }) => ({
	startAngle,
	endAngle,
	opacity: 1
});

const AnimatedPie = ({
	animate,
	arcs,
	path,
	getKey,
	getColor,
	onClickDatum
}) => {
	const transitions = useTransition(arcs, {
		from: animate ? fromLeaveTransition : enterUpdateTransition,
		enter: enterUpdateTransition,
		update: enterUpdateTransition,
		leave: animate ? fromLeaveTransition : enterUpdateTransition,
		keys: getKey
	});
	return transitions((props, arc, { key }) => {
		const [centroidX, centroidY] = path.centroid(arc);
		const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

		return (
			<g key={key}>
				<animated.path
					// compute interpolated path d attribute from intermediate angle values
					d={to(
						[props.startAngle, props.endAngle],
						(startAngle, endAngle) => path({
							...arc,
							startAngle,
							endAngle
						})
					)}
					fill={getColor(arc)}
					onClick={() => onClickDatum(arc)}
					onTouchStart={() => onClickDatum(arc)}
				/>
				{hasSpaceForLabel && (
					<animated.g style={{ opacity: props.opacity }}>
						<text
							fill="white"
							x={centroidX}
							y={centroidY}
							dy=".33em"
							fontSize={9}
							textAnchor="middle"
							pointerEvents="none"
						>
							{getKey(arc)}
						</text>
					</animated.g>
				)}
			</g>
		);
	});
};

// accessor functions
const getValue = (d) => d.value;
const getColor = (d) => d.data.color;

const VariableRadiusDonutBigger = ({
	width = "300",
	height = "300",
	animate = true,
	displayInner = true,
	dataInfo,
	total,
	totalCovered,
	planedCount = 0,
	inProgressCount = 0,
	innerText = undefined
}) => {
	const radius = Math.min(width, height) / 1.5;
	const donutThickness = 80;
	const pieSortValues = (a, b) => b - a;

	const dataInfoAssessed = [
		{
			index: 0, label: "assessed", value: (totalCovered / total) * 100, color: "#8ac926"
		},
		{
			index: 1, label: "planned", value: (planedCount / total) * 100, color: "#FFC362"
		},
		{
			index: 2, label: "in progress", value: (inProgressCount / total) * 100, color: "#00BCFC"
		},
		{
			index: 3,
			label: "not assessed",
			value: 100 - (
				(totalCovered / total) + (planedCount / total) + (inProgressCount / total)) * 100,
			color: "#F5F5F5"
		}
	];

	if (width < 10) return null;

	return (
		<div className="flex" style={{ width: width / 1.5, height: height / 1.5 }}>
			<svg width={width / 1.5} height={height / 1.5 } viewBox="0 0 400 400">
				<Group top={height / 1.5} left={width / 1.5}>
					<Pie
						data={dataInfo}
						pieValue={getValue}
						outerRadius={(arc) => radius - donutThickness + ((4 - arc.index) * 10) }
						innerRadius={radius - donutThickness}
						pieSortValues={pieSortValues}
					>
						{(pie) => (
							<AnimatedPie
								{...pie}
								animate={animate}
								getKey={() => null}
								onClickDatum={() => null}
								getColor={(arc) => getColor(arc)}
							/>
						)}
					</Pie>
					{displayInner && <Pie
						data={dataInfoAssessed}
						pieValue={getValue}
						outerRadius={radius - donutThickness - 5}
						innerRadius={radius - donutThickness - 12}
						pieSortValues={(a, b) => b.index - a.index}
					>
						{(pie) => (
							<AnimatedPie
								{...pie}
								animate={animate}
								getKey={() => null}
								onClickDatum={() => null}
								getColor={(arc) => getColor(arc)}
							/>
						)}

					</Pie>}
					{innerText && <Text
						// If the string is short put it a bit higher so that it appears in the middle
						y={`${innerText.length < 8 ? 19 : 40}`}
						lineHeight={"3rem"}
						textAnchor="middle"
						width={width - radius - 24}
						// If the string is short make it bold
						fontWeight={`${innerText.length < 8 ? "bold" : ""}`}
						fontSize={`${innerText.length < 8 ? "3.5rem" : "2.5rem"}`}>{innerText}</Text>}
				</Group>
			</svg>
		</div>
	);
};

VariableRadiusDonutBigger.propTypes = {
	animate: PropTypes.bool,
	displayInner: PropTypes.bool,
	height: PropTypes.number,
	width: PropTypes.number,
	dataInfo: PropTypes.array,
	total: PropTypes.number,
	totalCovered: PropTypes.number,
	planedCount: PropTypes.number,
	inProgressCount: PropTypes.number,
	innerText: PropTypes.string
};

export default VariableRadiusDonutBigger;
