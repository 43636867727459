import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import BusinessIcon from "@mui/icons-material/Business";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PinDropIcon from "@mui/icons-material/PinDrop";
import CategoryIcon from "@mui/icons-material/Category";
import ReportIcon from "@mui/icons-material/Report";
import VerifiedIcon from "@mui/icons-material/Verified";

import { filterOptions } from "../../apps/configs/ClarityConfig";
import { customStylesSearchClarity } from "../filters/ClarityFilterStyle";
import FilterMenu from "../filters/FilterMenu";

function ClaritySiteHeader({
	appSlice, dataset, activeDomain, listActiveDomains, contrast = false,
	gradientClassName = "bg-gradient-to-r from-clarity_primary-gradientL via-clarity_primary-gradientV to-clarity_primary-gradientR",
	children, locales
}) {
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store
	const siteData = useSelector(appSlice.selectDatasets)[dataset]?.data?.[0];

	const filterOption = filterOptions.find((option) => option.label === "Modules");

	return (
		<div className={`relative flex flex-col justify-between w-full pt-4 px-4 md:px-16 z-10 h-60 ${ gradientClassName}`}>
			<div className="hidden md:flex space-x-2 z-40 text-small tracking-tight xl:text-sm">
				{Object.keys(listActiveDomains).length > 1 && <div
					onClick={() => dispatch(appSlice.actions.refine([{ key: "domain", value: undefined }]))}
					className={`flex items-center rounded-xl border px-4 py-2  cursor-pointer 
					${(activeDomain === undefined) ?
						"border-transparent bg-white text-clarity_primary-accent" : "border-gray-700"}`}>
					{t("sitePage.header.allDomains")}
				</div>
				}
				{Object.keys(listActiveDomains).map((domainKey, i) => {
					const domain = listActiveDomains[domainKey];

					return (
						<div key={i}
							onClick={() => dispatch(appSlice.actions.refine([{ key: "domain", value: domainKey }]))}
							className={`flex items-center rounded-xl border px-2 py-2 
							 cursor-pointer ${(activeDomain === domainKey || listActiveDomains.length === 1) ?
								"border-transparent bg-white text-clarity_primary-accent " : "border-gray-700 "}`} >
							{t(`domains.${domain?.name}.name`)}
						</div>
					);
				})}
			</div>

			<div className="w-3/4 md:hidden z-20">
				<FilterMenu
					appSlice={appSlice}
					options={filterOption}
					customStyle={customStylesSearchClarity}
					textClasses="text-clarity_primary-accent font-semibold"
					className={"w-full "}
					locales={locales}
				/>
			</div>

			<div className="h-full grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4 space-x-6 w-full mt-8 md:mt-6">
				<div className={`flex flex-col w-full items-start justify-end mb-8  flex-grow  gap-y-6 
                flex-shrink-0 max-w-7xl ${ contrast && "text-white"}` }>
					<div className="flex flex-col w-full gap-y-4 ">
						<div className="absolute -bottom-8 left-6 opacity-40
									text-white" style={{ zIndex: -1 }}>
							<BusinessIcon style={{ fontSize: 240 }} />
						</div>
						<div className="flex gap-x-2">
							<div onClick={() => dispatch(appSlice.actions.refine([{ key: "siteId", value: undefined }]))}
								className="flex items-center text-clarity_primary-accent bg-white rounded-xl
                        text-2xl font-bold px-2 cursor-pointer">
								<ArrowBackIcon fontSize="inherit" />
							</div>
							<p className="font-semibold line-clamp-1 text-3xl xl:text-4xl">{siteData?.auditedCompany}</p>
						</div>
						<div className="flex text-sm space-x-2">
							<div className={`flex items-center space-x-2 border px-3 py-1 rounded-xl 
                            ${ contrast ? "border-white bg-black bg-opacity-40" : "border-gray-500 "}`}>
								<PinDropIcon fontSize="inherit" />
								<p className="text-small line-clamp-1">{siteData?.city && (`${siteData?.city} - `)}{siteData?.country}</p>
							</div>
							{siteData?.product?.[0] !== "nc" &&
							<div className={`flex items-center space-x-2 border px-3 py-1 rounded-xl 
                            ${ contrast ? "border-white bg-black bg-opacity-40" : "border-gray-500 "}`}>
                            	<CategoryIcon fontSize="inherit" />
                            	<p className="text-small line-clamp-1">{siteData?.product}</p>
							</div>
							}
						</div>
					</div>
					<div className="flex gap-x-8">
						<div className="flex items-center alphaBlur rounded-xl px-6 py-4 mt-6 md:mt-0
                                  text-clarity_primary-accent font-semibold">
							<a target="_blank" rel="noopener noreferrer"
								href={`mailto:clarity.info@bureauveritas.com
								?subject=${`${siteData?.auditedCompany} - Ref(${siteData?.siteId})- ${t("sitePage.header.askForAudit")}`}`}>
								{t("sitePage.header.askForAudit")}
							</a>
						</div>
						{
							clientParameters?.BVSites?.length > 0 && clientParameters?.BVSites?.[0] !== 0
								? clientParameters.BVSites.includes(activeRefines.siteId)
									? <div className="flex items-center alphaBlur rounded-xl px-6 py-4 gap-x-2 mt-6 md:mt-0
									text-clarity_primary-accent font-semibold">
										<VerifiedIcon fontSize="medium" />
										<span>{t("sitePage.header.verified")}</span>
									</div>
									: <div className="flex items-center bg-clarity_primary-red rounded-xl px-6 py-4 gap-x-2 mt-6 md:mt-0
									text-white font-semibold">
										<ReportIcon fontSize="medium" />
										<span>{t("sitePage.header.notVerified")}</span>
									</div>
								: ""

						}
					</div>

				</div>
				{children}
			</div>
		</div>
	);
}

ClaritySiteHeader.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	activeDomain: PropTypes.string,
	listActiveDomains: PropTypes.object,
	contrast: PropTypes.bool,
	gradientClassName: PropTypes.string,
	children: PropTypes.object,
	locales: PropTypes.string
};

export default ClaritySiteHeader;
