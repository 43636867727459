/* eslint-disable */
import "leaflet-geometryutil";
import * as L from "leaflet";

/*
	* usage: passer your options to arrowhead which is defined on Geojson component
	* example: <GeoJSON data={data} arrowhead={options} />

	* options (Object)
	* 1.yawn (Number) 
	*   Defines the width of the opening of the arrowhead, given in degrees. The larger the angle, the wider the arrowhead.
	* 2.size (Number | String( Meters | Percent or Pixels ))
	*   Determines the size of the arrowhead.
	* 3.frequency (Number | String( Number of arrowheads | Meters, Pixels, 'allvertices', 'endonly' ))
	*   How many arrowheads are rendered on a polyline.
	* 4.proportionalToTotal (Boolean)
	*   Only relevant when size is given as a percent. Useful when frequency is set to 'endonly'. 
	*   Will render the arrowhead(s) with a size proportional to the entire length of the multi-segmented polyline, 
	*   rather than proportional to the average length of all the segments.
*/		


const modulus = (i, n) => ((i % n) + n) % n;

L.Polyline.include({
	arrowheads(options = {}) {
		// Merge user input options with default options:
		const defaults = {
			yawn: 60,
			size: "15%",
			frequency: "allvertices",
			proportionalToTotal: false
		};

		this.options.noClip = true;

		const actualOptions = { ...defaults, ...options };
		this._arrowheadOptions = actualOptions;

		this._hatsApplied = true;
		return this;
	},

	buildVectorHats(options) {
		// Reset variables from previous this._update()
		if (this._arrowheads) {
			this._arrowheads.remove();
		}

		//  -------------------------------------------------------- //
		//  ------------  FILTER THE OPTIONS ----------------------- //
		/*
		 * The next 3 lines folds the options of the parent polyline into the default options for all polylines
		 * The options for the arrowhead are then folded in as well
		 * All options defined in parent polyline will be inherited by the arrowhead, unless otherwise specified in the arrowhead(options) call
		 */

		const defaultOptionsOfParent = Object.getPrototypeOf(
			Object.getPrototypeOf(this.options)
		);

		// merge default options of parent polyline (this.options's prototype's prototype) with options passed to parent polyline (this.options).
		const parentOptions = { ...defaultOptionsOfParent, ...this.options };

		// now merge in the options the user has put in the arrowhead call
		const hatOptions = { ...parentOptions, ...options };

		// ...with a few exceptions:
		hatOptions.smoothFactor = 1;
		hatOptions.fillOpacity = 1;
		hatOptions.fill = !!options.fill;
		hatOptions.interactive = false;

		//  ------------  FILTER THE OPTIONS END -------------------- //
		//  --------------------------------------------------------- //

		//  --------------------------------------------------------- //
		//  ------ LOOP THROUGH EACH POLYLINE SEGMENT --------------- //
		//  ------ TO CALCULATE HAT SIZES AND CAPTURE IN ARRAY ------ //

		const size = options.size.toString(); // stringify if its a number
		const allhats = []; // empty array to receive hat polylines
		const { frequency } = options;

		this._parts.forEach((peice, index) => {
			// Immutable variables for each peice
			const latlngs = peice.map((point) => this._map.layerPointToLatLng(point));

			const totalLength = (() => {
				let total = 0;
				for (let i = 0; i < peice.length - 1; i++) {
					total += this._map.distance(latlngs[i], latlngs[i + 1]);
				}
				return total;
			})();

			// TBD by options if tree below
			let derivedLatLngs;
			let derivedBearings;
			let spacing;
			let noOfPoints;

			//  Determining latlng and bearing arrays based on frequency choice:
			if (!isNaN(frequency)) {
				spacing = 1 / (frequency + 1);
				noOfPoints = frequency;
			} else if (
				frequency
				.toString()
				.slice(
					frequency.toString().length - 1,
					frequency.toString().length
				) === "%"
			) {
				console.log(
					"Error: arrowhead frequency option cannot be given in percent.  Try another unit."
				);
			} else if (
				frequency
				.toString()
				.slice(
					frequency.toString().length - 1,
					frequency.toString().length
				) === "m"
			) {
				spacing = frequency.slice(0, frequency.length - 1) / totalLength;
				noOfPoints = 1 / spacing;
				// round things out for more even spacing:
				noOfPoints = Math.floor(noOfPoints);
				spacing = 1 / noOfPoints;
			} else if (
				frequency
				.toString()
				.slice(
					frequency.toString().length - 2,
					frequency.toString().length
				) === "px"
			) {
				spacing = (() => {
					const chosenFrequency = frequency.slice(0, frequency.length - 2);
					const refPoint1 = this._map.getCenter();
					const xy1 = this._map.latLngToLayerPoint(refPoint1);
					const xy2 = {
						x: xy1.x + Number(chosenFrequency),
						y: xy1.y
					};
					const refPoint2 = this._map.layerPointToLatLng(xy2);
					const derivedMeters = this._map.distance(refPoint1, refPoint2);
					return derivedMeters / totalLength;
				})();

				noOfPoints = 1 / spacing;

				// round things out for more even spacing:
				noOfPoints = Math.floor(noOfPoints);
				spacing = 1 / noOfPoints;
			}

			if (options.frequency === "allvertices") {
				derivedBearings = (() => {
					const bearings = [];
					for (let i = 1; i < latlngs.length; i++) {
						const bearing =
							L.GeometryUtil.angle(
								this._map,
								latlngs[modulus(i - 1, latlngs.length)],
								latlngs[i]
							) + 180;
						bearings.push(bearing);
					}
					return bearings;
				})();

				derivedLatLngs = latlngs;
				derivedLatLngs.shift();
			} else if (options.frequency === "endonly") {
				derivedLatLngs = [latlngs[latlngs.length - 1]];

				derivedBearings = [
					L.GeometryUtil.angle(
						this._map,
						latlngs[latlngs.length - 2],
						latlngs[latlngs.length - 1]
					) + 180
				];
			} else {
				derivedLatLngs = [];
				const interpolatedPoints = [];
				for (var i = 0; i < noOfPoints; i++) {
					const interpolatedPoint = L.GeometryUtil.interpolateOnLine(
						this._map,
						latlngs,
						spacing * (i + 1)
					);

					interpolatedPoints.push(interpolatedPoint);
					derivedLatLngs.push(interpolatedPoint.latLng);
				}

				derivedBearings = (() => {
					const bearings = [];

					for (let i = 0; i < interpolatedPoints.length; i++) {
						const bearing = L.GeometryUtil.angle(
							this._map,
							latlngs[interpolatedPoints[i].predecessor + 1],
							latlngs[interpolatedPoints[i].predecessor]
						);
						bearings.push(bearing);
					}
					return bearings;
				})();
			}

			const n = latlngs.length - 1;
			const hats = [];

			// Function to build hats based on index and a given hatsize in meters
			const pushHats = (size) => {
				const leftWingPoint = L.GeometryUtil.destination(
					derivedLatLngs[i],
					derivedBearings[i] - options.yawn / 2,
					size
				);

				const rightWingPoint = L.GeometryUtil.destination(
					derivedLatLngs[i],
					derivedBearings[i] + options.yawn / 2,
					size
				);

				const hatPoints = [
					[leftWingPoint.lat, leftWingPoint.lng],
					[derivedLatLngs[i].lat, derivedLatLngs[i].lng],
					[rightWingPoint.lat, rightWingPoint.lng]
				];

				const hat = options.fill
					? L.polygon(hatPoints, hatOptions)
					: L.polyline(hatPoints, hatOptions);

				hats.push(hat);
			}; // pushHats()

			// Function to build hats based on pixel input
			const pushHatsFromPixels = (size) => {
				const sizePixels = size.slice(0, size.length - 2);

				const derivedXY = this._map.latLngToLayerPoint(derivedLatLngs[i]);

				const bearing = derivedBearings[i];

				const thetaLeft = (180 - bearing - options.yawn / 2) * (Math.PI / 180);
				const thetaRight = (180 - bearing + options.yawn / 2) * (Math.PI / 180);

				const dxLeft = sizePixels * Math.sin(thetaLeft);
				const dyLeft = sizePixels * Math.cos(thetaLeft);
				const dxRight = sizePixels * Math.sin(thetaRight);
				const dyRight = sizePixels * Math.cos(thetaRight);

				const leftWingXY = {
					x: derivedXY.x + dxLeft,
					y: derivedXY.y + dyLeft
				};
				const rightWingXY = {
					x: derivedXY.x + dxRight,
					y: derivedXY.y + dyRight
				};

				const leftWingPoint = this._map.layerPointToLatLng(leftWingXY);
				const rightWingPoint = this._map.layerPointToLatLng(rightWingXY);

				const hatPoints = [
					[leftWingPoint.lat, leftWingPoint.lng],
					[derivedLatLngs[i].lat, derivedLatLngs[i].lng],
					[rightWingPoint.lat, rightWingPoint.lng],
					[( leftWingPoint.lat + derivedLatLngs[i].lat + rightWingPoint.lat ) / 3,
					( leftWingPoint.lng + derivedLatLngs[i].lng + rightWingPoint.lng ) / 3]
				];

				const hat = options.fill
					? L.polygon(hatPoints, hatOptions)
					: L.polyline(hatPoints, hatOptions);

				hats.push(hat);
			}; // pushHatsFromPixels()

			//  -------  LOOP THROUGH POINTS IN EACH SEGMENT ---------- //
			for (var i = 0; i < derivedLatLngs.length; i++) {
				// ---- If size is chosen in meters -------------------------
				if (size.slice(size.length - 1, size.length) === "m") {
					const hatSize = size.slice(0, size.length - 1);
					pushHats(hatSize);

					// ---- If size is chosen in percent ------------------------
				} else if (size.slice(size.length - 1, size.length) === "%") {
					const sizePercent = size.slice(0, size.length - 1);
					const hatSize = (() => {
						if (
							options.frequency === "endonly" &&
							options.proportionalToTotal
						) {
							return (totalLength * sizePercent) / 100;
						}
						const averageDistance = totalLength / (peice.length - 1);
						return (averageDistance * sizePercent) / 100;
					})(); // hatsize calculation

					pushHats(hatSize);

					// ---- If size is chosen in pixels --------------------------
				} else if (size.slice(size.length - 2, size.length) === "px") {
					pushHatsFromPixels(options.size);

					// ---- If size unit is not given -----------------------------
				} else {
					console.log(
						"Error: Arrowhead size unit not defined.  Check your arrowhead options."
					);
				} // if else block for Size
			} // for loop for each point witin a peice

			allhats.push(...hats);
		}); // forEach peice

		//  --------- LOOP THROUGH EACH POLYLINE END ---------------- //
		//  --------------------------------------------------------- //

		const arrowheads = L.layerGroup(allhats);
		this._arrowheads = arrowheads;

		return this;
	},

	getArrowheads() {
		if (this._arrowheads) {
			return this._arrowheads;
		}
		return console.log(
			"Error: You tried to call '.getArrowheads() on a shape that does not have a arrowhead.  Use '.arrowheads()' to add a arrowheads before trying to call '.getArrowheads()'"
		);
	},

	deleteArrowheads() {
		if (this._arrowheads) {
			this._arrowheads.remove();
			delete this._arrowheads;
			delete this._arrowheadOptions;
			this._hatsApplied = false;
		}
	},

	_update() {
		if (!this._map) {
			return;
		}

		this._clipPoints();
		this._simplifyPoints();
		this._updatePath();

		if (this._hatsApplied) {
			this.buildVectorHats(this._arrowheadOptions);
			this._map.addLayer(this._arrowheads);
		}
	},

	remove() {
		if (this._arrowheads) {
			this._arrowheads.remove();
		}
		return this.removeFrom(this._map || this._mapToAdd);
	}
});

L.LayerGroup.include({
	removeLayer(layer) {
		const id = layer in this._layers ? layer : this.getLayerId(layer);

		if (this._map && this._layers[id]) {
			if (this._layers[id]._arrowheads) {
				this._layers[id]._arrowheads.remove();
			}
			this._map.removeLayer(this._layers[id]);
		}

		delete this._layers[id];

		return this;
	},

	onRemove(map, layer) {
		for (var layer in this._layers) {
			if (this._layers[layer]) {
				this._layers[layer].remove();
			}
		}

		this.eachLayer(map.removeLayer, map);
	}
});

L.Map.include({
	removeLayer(layer) {
		const id = L.Util.stamp(layer);

		if (layer._arrowheads) {
			layer._arrowheads.remove();
		}

		if (!this._layers[id]) {
			return this;
		}

		if (this._loaded) {
			layer.onRemove(this);
		}

		if (layer.getAttribution && this.attributionControl) {
			this.attributionControl.removeAttribution(layer.getAttribution());
		}

		delete this._layers[id];

		if (this._loaded) {
			this.fire("layerremove", { layer });
			layer.fire("remove");
		}

		layer._map = layer._mapToAdd = null;

		return this;
	}
});

L.GeoJSON.include({
	geometryToLayer(geojson, options) {
		const geometry = geojson.type === "Feature" ? geojson.geometry : geojson;
		const coords = geometry ? geometry.coordinates : null;
		const layers = [];
		const pointToLayer = options && options.pointToLayer;
		const _coordsToLatLng =
				(options && options.coordsToLatLng) || L.GeoJSON.coordsToLatLng;
		let latlng;
		let latlngs;
		let i;
		let len;

		if (!coords && !geometry) {
			return null;
		}

		switch (geometry.type) {
			case "Point":
				latlng = _coordsToLatLng(coords);
				return this._pointToLayer(pointToLayer, geojson, latlng, options);

			case "MultiPoint":
				for (i = 0, len = coords.length; i < len; i++) {
					latlng = _coordsToLatLng(coords[i]);
					layers.push(
						this._pointToLayer(pointToLayer, geojson, latlng, options)
					);
				}
				return new L.FeatureGroup(layers);

			case "LineString":
			case "MultiLineString":
				latlngs = L.GeoJSON.coordsToLatLngs(
					coords,
					geometry.type === "LineString" ? 0 : 1,
					_coordsToLatLng
				);
				var polyline = new L.Polyline(latlngs, options);
				if (options.arrowheads) {
					polyline.arrowheads(options.arrowheads);
				}
				return polyline;

			case "Polygon":
			case "MultiPolygon":
				latlngs = L.GeoJSON.coordsToLatLngs(
					coords,
					geometry.type === "Polygon" ? 1 : 2,
					_coordsToLatLng
				);
				return new L.Polygon(latlngs, options);

			case "GeometryCollection":
				for (i = 0, len = geometry.geometries.length; i < len; i++) {
					const layer = this.geometryToLayer(
						{
							geometry: geometry.geometries[i],
							type: "Feature",
							properties: geojson.properties
						},
						options
					);

					if (layer) {
						layers.push(layer);
					}
				}
				return new L.FeatureGroup(layers);

			default:
				throw new Error("Invalid GeoJSON object.");
		}
	},

	addData(geojson) {
		const features = L.Util.isArray(geojson) ? geojson : geojson.features;
		let i;
		let len;
		let feature;

		if (features) {
			for (i = 0, len = features.length; i < len; i++) {
				// only add this if geometry or geometries are set and not null
				feature = features[i];
				if (
					feature.geometries ||
					feature.geometry ||
					feature.features ||
					feature.coordinates
				) {
					this.addData(feature);
				}
			}
			return this;
		}

		const { options } = this;

		if (options.filter && !options.filter(geojson)) {
			return this;
		}

		const layer = this.geometryToLayer(geojson, options);
		if (!layer) {
			return this;
		}
		layer.feature = L.GeoJSON.asFeature(geojson);

		layer.defaultOptions = layer.options;
		this.resetStyle(layer);

		if (options.onEachFeature) {
			options.onEachFeature(geojson, layer);
		}

		return this.addLayer(layer);
	},

	_pointToLayer(pointToLayerFn, geojson, latlng, options) {
		return pointToLayerFn
			? pointToLayerFn(geojson, latlng)
			: new L.Marker(
				latlng,
				options && options.markersInheritOptions && options
			  );
	}
});
