import React from "react";
import PropTypes from "prop-types";
import HelpIcon from "@mui/icons-material/Help";
import SmsIcon from "@mui/icons-material/Sms";
import RoomIcon from "@mui/icons-material/Room";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LaunchIcon from "@mui/icons-material/Launch";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import BusinessIcon from "@mui/icons-material/Business";
import { useDispatch } from "react-redux";

const ChargeScanNcStatus = ({ appSlice, issue }) => {
	const dispatch = useDispatch();

	return <div className="flex flex-col relative rounded-xl alphaBlur w-full md:h-48 lg:h-52 md:space-y-4 px-4 py-4">
		<div className="flex item-center py-0.5 h-20">
			<HelpIcon fontSize="x-small" className="mt-0.5 mx-1"/>
			<p className="line-clamp-2">
				{ issue?.checkpointReference } - { issue?.question } <span className="text-red-600">No</span>
			</p>
		</div>
		<div className="flex item-center py-0.5 h-24">
			<SmsIcon fontSize="x-small" className="mt-0.5 mx-1"/>
			<p>
				{issue?.comment ?? "No comment"}
			</p>
		</div>

		<div className="flex flex-wrap w-full h-14 justify-end items-end space-x-2 space-y-1 content-end ">
			<div
				className="rounded-xl bg-chargescan_primary-blue flex
				justify-center items-center h-5 px-1 py-2 text-xs text-white"
				onClick={() => dispatch(appSlice.actions.refine([{ key: "label", value: issue?.label }]))}
			>
				<BusinessIcon className="px-0.5 text-sm" />
				{issue?.label}
			</div>
			<div
				className="rounded-xl bg-chargescan_primary-blue flex
				justify-center items-center h-5 px-1 py-2 text-xs text-white"
				onClick={() => dispatch(appSlice.actions.refine([{ key: "country", value: issue?.country }]))}
			>
				<RoomIcon className="px-0.5 text-sm" />
				{ issue?.city } - { issue?.country }
			</div>
			<div className="rounded-xl bg-chargescan_primary-blue flex
				justify-center items-center h-5 px-1 py-2 text-xs text-white">
				<CalendarTodayIcon className="px-0.5 text-sm" />
				{issue?.publicationDate}
			</div>
			<div className="rounded-xl bg-chargescan_primary-purple flex
				justify-center items-center h-5 px-1 py-2 text-xs text-white">
				<LaunchIcon className="px-0.5 text-sm" />
				<a	className="flex-shrink-0"
					rel="noreferrer"
					href={`https://odsdatahub.bureauveritas.com/reports/${issue?.assignmentTechnicalid}`}
					target="_blank"
				>
					{issue?.module}
				</a>
			</div>
			<div className="rounded-xl bg-chargescan_primary-blue flex
				justify-center items-center h-5 px-1 py-2 text-xs text-white">
				<ArrowDownwardIcon className="px-0.5 text-sm" />
				{ issue?.section1Label} - {issue?.section2Label }
			</div>
		</div>
	</div>;
};

ChargeScanNcStatus.propTypes = {
	appSlice: PropTypes.object,
	issue: PropTypes.object
};

export default ChargeScanNcStatus;
