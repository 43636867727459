import React, {
	useState, useEffect, createRef, useRef
} from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useTranslation } from "react-i18next";

import { ParentSize } from "@visx/responsive";
import Div100vh, { use100vh } from "react-div-100vh";

import GavelIcon from "@mui/icons-material/Gavel";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import createAppSlice from "../data/slices/createAppSlice";

import SideBar from "../components/menu/SideBar";
import SupplyRInfoPageHeader from "../components/header/SupplyRInfoPageHeader";
import SupplyRTermPageHeader from "../components/header/SupplyRTermPageHeader";
import SCAHeader from "../components/header/SCAHeader";
import PageHeader from "../components/header/PageHeader";
import SCAPageMenuHeader from "../components/header/SCAPageMenuHeader";
import MapLeaflet from "../components/map/MapLeaflet";
import SupplyRMapMarker from "../components/map/SupplyRMapMarker";
import ProjectScope from "../components/cards/ProjectScope";
import TitleCard from "../components/cards/TitleCard";
import SCAIndicatorCard from "../components/cards/SCAIndicatorCard";
import SCAPieChart from "../components/cards/SCAPieChart";
import SimpleIndicatorCard from "../components/cards/SimpleIndicatorCard";
import SCASupplierDetailedCard from "../components/cards/SCASupplierDetailedCard";

import ColumnGraph from "../components/graphs/ColumnGraph";
import TimeEvolutionCard from "../components/graphs/TimeEvolutionCard";
import StackedAreaChart from "../components/graphs/StackedAreaChart";
import DistributionChart from "../components/graphs/DistributionChart";
import TreeMap from "../components/tree/TreeMap";
// import getTreeMapData from "../components/tree/SCATreeMapChildren";
import HorizontalFilters from "../components/filters/HorizontalFilters";
import { customStylesSCA, customStylesSearchSCA } from "../components/filters/SCAFilterStyle";
import SwitchSelector from "../components/filters/SwitchSelector";
import SimpleSelector from "../components/filters/SimpleSelector";
import MapSelector from "../components/filters/MapSelector";
import SCATable from "../components/table/SCATable";
import FilterMenu from "../components/filters/FilterMenu";
import SCALayer from "../components/map/SCALayer";
import ColorLegend from "../components/legends/ColorLegend";

import { customStylesSupplyR } from "../components/filters/SupplyRFiltersStyle";

import Page401 from "../pages/Page401";
import Tabs from "../components/tabs/Tabs";

import IF from "../utils/IF";
import Loading from "../utils/Loading";

import {
	appDefinition, menuOptions, SCAInitDatasets, SCAInitRefines, filterOptions, nonConformities, tableStructure, clientInitParameters,
	langListValue, termsTabSet, getMarkerColor, fieldsTooltip, ratingsConfig, globalRatingsConfig, overallRatingsConfig,
	shareColorConfig, failureRateHelper, mapFilterOptions, getTreeMapFunc, indicatorTypeOptions
} from "./configs/SCAConfig";

import { requestSignIn, userSelector } from "../data/slices/UserSlice";
import { openSaml } from "../auth/Authenticator";

// Create the App Slice
const appSlice = createAppSlice(
	appDefinition.slice,
	menuOptions.filter((option) => option.initial)[0].menu,
	SCAInitRefines,
	SCAInitDatasets);

// Set Layout size
const mdScreens = 768;
const twoXLScreens = 1536;
const mobileMenuHeight = 60;
const filterMenuHeight = 112;
const sideBarWidth = 114;
const analysisWidth = 476;
const mobileAnalysisWidth = 348;

/**
 * Function to render the application
 */
function SCA() {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	// Set up state for the map to supply chain view switch
	const [clientView, setClientView] = useState(false);
	const [client, setClient] = useState(undefined);

	// Set up state for indicator type
	const [equipmentIndicatorType, setEquipmentIndicatorType] = useState(indicatorTypeOptions[0]);

	const [final, setFinal] = useState(false);

	// Set up state for the map to supply chain view switch
	const [renderAnalysis, setRenderAnalysis] = useState(true);

	// Create and load the app slice in the store
	const store = useStore();

	// Load ref of content section
	const contentRef = useRef(null);
	const bottomRef = useRef(null);
	const equipmentBottomRef = useRef(null);
	const inspectionBottomRef = useRef(null);

	// Load screen layout parameters
	const screenHeight = use100vh();
	const contentHeight = window.innerWidth > mdScreens ? (screenHeight - filterMenuHeight) : (screenHeight - mobileMenuHeight);
	const contentWidth = (document.fullscreenElement ? window.innerWidth : (Math.min(...[1900, window.innerWidth])))
        - ((window.innerWidth < mdScreens) ? 0 : (renderAnalysis ? (window.innerWidth > twoXLScreens ? (analysisWidth
            + sideBarWidth) : (mobileAnalysisWidth + sideBarWidth)) : sideBarWidth));

	// Load the store hook
	const dispatch = useDispatch();

	// Load the reducer and favicon
	useEffect(() => {
		store.injectReducer(appDefinition.slice, appSlice.reducer);
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load user
	const user = useSelector(userSelector);

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);
	const subMenu = useSelector(appSlice.selectSubPage);

	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const isActiveFilter = useSelector(appSlice.isRefined);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);
	// const { mapFilter } = activeRefines;

	// Load client parameters
	useEffect(() => {
		dispatch(appSlice.fetchDataset(clientInitParameters));
	},
	[activeRefines?.client]); // eslint-disable-line react-hooks/exhaustive-deps

	// Load client parameters active indicators as an initial filter for the datasets
	// and load the first space as the initial refine for spaceLabel
	useEffect(() => {
		/* dispatch(appSlice.actions.refine([{ key: "projectName", value: clientParameters?.projectName?.[0] }])); */
	},
	[clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch and refresh data in the store
	useEffect(() => {
		if (activeDatasets === undefined) {
			return;
		}
		dispatch(appSlice.fetchDatasets());
	}, [activeRefines, clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (activeDatasets === undefined) {
			return;
		}

		if (menu === "HOME" || menu === "EQUIPMENT" || menu === "INSPECTION" || menu === "COUNTRY" ||
		(menu !== "INFO" && menu !== "TERMs" && (menu === "SITES" && activeRefines.manufacturer !== undefined))) {
			let dataParam = [];

			const {
				inspectTreeMapByLv1: { data: inspectLv1Data },
				inspectTreeMapByLv12: { data: inspectLv12Data },
				equipCatTreeMapByLv1: { data: equipCatLv1Data },
				equipCatTreeMapByLv12: { data: equipCatLv12Data },
				countryTreeMapByLv1: { data: countryLv1Data },
				countryTreeMapByLv12: { data: countryLv12Data }
			} = activeDatasets;

			const getTreeMapData = getTreeMapFunc.get(menu);

			if (menu === "HOME"
			|| menu === "INSPECTION"
			|| (menu !== "INFO" && menu !== "TERMs" && (menu === "SITES" && activeRefines.manufacturer !== undefined))) {
				dataParam = [inspectLv1Data, inspectLv12Data];
			}
			if (menu === "EQUIPMENT") dataParam = [equipCatLv1Data, equipCatLv12Data];
			if (menu === "COUNTRY") dataParam = [countryLv1Data, countryLv12Data];

			// rawData for treeMap in the store
			const rawTreeMap = dataParam?.length > 0 && getTreeMapData && getTreeMapData(dataParam[0], dataParam[1]);

			dispatch(
				appSlice.actions.fetchCalculatedDataset({
					datasetName: "treeMap",
					datasetData: rawTreeMap
				})
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeDatasets?.inspectTreeMapByLv1, activeDatasets?.inspectTreeMapByLv12,
		activeDatasets?.equipCatTreeMapByLv1, activeDatasets?.equipCatTreeMapByLv12,
		activeDatasets?.countryTreeMapByLv1, activeDatasets?.countryTreeMapByLv12, menu]);

	// Reload window if data expired
	useEffect(() => {
		if (loadDataStatus === "sessionExpired") {
			setTimeout(() => {
				dispatch(appSlice.actions.reset());
				dispatch(requestSignIn());
				openSaml();
			}, 2000);
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (activeRefines?.client) {
			setClient(activeRefines.client);
		}
	}, [activeRefines?.client]);

	// Go top if menu has changed
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		document?.getElementById("svg-scroll")?.scroll({ top: 0, behavior: "smooth" });
	}, [menu, subMenu/* , activeRefines?.supplier, activeRefines?.module */]); // eslint-disable-line react-hooks/exhaustive-deps

	if (loadDataStatus === "sessionExpired") {
		return <Page401 />;
	}

	// Wait for Data to render App
	if (activeDatasets === undefined || activeDatasets?.treeMap?.data?.length === 0 || activeDatasets?.SCAMapStats?.data?.length === 0) {
		return <Loading message="Wait a moment while we load your app." />;
	}

	return (
		<div className="relative h-full w-full">

			<IF condition={loadDataStatus === "loading"}>
				<Loading />
			</IF>

			{/*
            * Load the splashscreen if the user has not yet set it as don't remind me
            */}
			{/* <Splashscreen appName="SCA" notifKey="home" splashScreens={splashScreens}
                theme="supplyr" locales={appDefinition.locales} /> */}

			<div className="relative flex justify-between max-w-screen-3xl mx-auto h-full w-full bg-white" id="full-screen">

				<SideBar
					appSlice={appSlice}
					appDefinition={appDefinition}
					menuOptions={menuOptions}
					menu={menu}
					width={sideBarWidth}
					colorMenu="#3D49AB"
					colorAccent="#6eb8d6"
					indicator={{ menu: "NCs", value: activeDatasets?.totalNonConformities?.data?.nbNC ?? 0 }}
					locales={appDefinition.locales}
					langListValue={langListValue}
				/>
				<div className="flex flex-col md:pl-24 bg-supplyr_primary-bg h-full w-full max-w-full">

					<IF condition={menu !== "INFO" && menu !== "TERMs" && (menu !== "SITES" || activeRefines.manufacturer === undefined)}>

						<SCAHeader clientParameters={clientParameters} locales={appDefinition.locales} />

						<div className="relative flex flex-col md:flex-row justify-between h-full w-full overflow-y-scroll">

							<div className="relative flex w-full h-full rounded">

								{/* HOME page */}
								<IF condition={menu === "HOME"}>
									<div className="w-full h-full">
										<div className="w-full flex flex-row">
											<div className="pt-4 px-4 w-full">
												<p className="text-4xl font-bold mb-1">{t("homePage.header.welcome")} {user.firstName},</p>
												<div className="flex justify-between">
													<p className="pt-1 md:text-lg w-full font-light leading-6">
														{t("homePage.header.introduction")}
													</p>
												</div>
											</div>

											{/* <div className="flex justify-center items-center w-72 h-10 mt-4 mr-4 rounded-full text-md
                                            font-medium hover:cursor-pointer" style={{ boxShadow: "0px 5px 4px rgba(180,180,180)" }}
											onClick={() => bottomRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" })}>
												{t("detailTrends")} <ArrowDownwardIcon className="ml-2" />
											</div> */}

											<div className="w-64 h-10 mt-4 mr-4">
												<SwitchSelector
													onChange={(value) => {
														setClientView(value);

														if (value) {
															dispatch(appSlice.actions.refine([{ key: "client", value: client }]));
														}
														if (activeRefines.client) {
															dispatch(appSlice.actions.clear([{ refine: "client" }]));
														}
													}}
													options={
														[
															{
																label: t("trend.switch.global"),
																value: false,
																selectedBackgroundColor: "#fff",
																selectedfontcolor: "#3D49AB"
															},
															{
																label: t("trend.switch.individual"),
																value: true,
																selectedBackgroundColor: "#fff",
																selectedfontcolor: "#3D49AB"
															}
														]
													}
													initialSelectedIndex={clientView ? 1 : 0}
													backgroundColor="#eee"
													selectedfontcolor="#818593"
													selectionIndicatorMargin={4}
												/>
											</div>
										</div>

										<div className="relative w-full grid grid-cols-2 grid-flow-row gap-x-4 gap-y-6">
											<div className="row-span-2 col-span-2 p-4">

												<div className="grid grid-cols-4 gap-4">
													<div className="mx-1 flex-1">
														<SimpleIndicatorCard
															key={`SimpleIndicatorCard ${0}`}
															label="Overall inspection volume"
															appSlice={appSlice}
															locales={appDefinition.locales}
															value={activeDatasets?.overallStats?.data?.[0]?.overallReports?.toLocaleString("en-US")}
														/>
													</div>
													<div className="mx-1 flex-1">
														<SCAIndicatorCard
															key={`SCAIndicatorCard ${1}`}
															label="Overall failure rate"
															appSlice={appSlice}
															module={"overallStats"}
															ratingsConfig={overallRatingsConfig}
															locales={appDefinition.locales}
															refineKey="_id"
															dataset = "overallStats"
														/>
													</div>
													<div className="mx-1 flex-1">
														<SimpleIndicatorCard
															key={`SimpleIndicatorCard ${2}`}
															label="Overall finding ratio"
															appSlice={appSlice}
															locales={appDefinition.locales}
															value={activeDatasets?.overallStats?.data?.[0]?.overallFindingRatio?.toFixed(2)}
														/>
													</div>
													<div className="mx-1 flex-1">
														<SCAIndicatorCard
															key={`SCAIndicatorCard ${3}`}
															globalStats
															appSlice={appSlice}
															label={"Share of final inspections"}
															module={"Final"}
															ratingsConfig={globalRatingsConfig}
															altRating={failureRateHelper}
															locales={appDefinition.locales}
															refineKey="stage"
															dataset = "stageStats"
														/>
													</div>
												</div>
											</div>
											<div className="row-span-2 col-span-1 p-4">
												<IF condition={(!activeRefines.inspectionTasksL1)}>
													<div>
														<TitleCard titleLocal="inspectionVolume.failureRate.title"
															subTitleLocal="inspectionVolume.failureRate.subTitle"
															small infoLocal="inspectionVolume.failureRate.desc"
															theme={appDefinition.theme} locales={appDefinition.locales} />
													</div>
													<div>
														<ParentSize>
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	isClickable
																	dataset={"inspectionTaskLv1Info"}
																	textSize={20}
																	strokeWidth={16}
																	ncMargin={60}
																	margin={{
																		top: 40, left: 10, right: 30, bottom: 20
																	}}
																	lineHeight={60}
																	width={parent.width}
																	getScore={(d) => d?.nbReports}
																	getModule={(d) => d?.inspectionTasksL1}
																	getNC={(d) => d?.failureRate ? (d.failureRate * 100).toFixed(2) : null}
																	nonConformities
																	sortScoreDimension
																	scoreScaleMax
																	refineKeys={["inspectionTasksL1"]}
																	locales={appDefinition.locales}
																/>)}
														</ParentSize>
													</div>
												</IF>

												<IF condition={activeRefines.inspectionTasksL1 !== undefined}>
													<div className="w-full">
														<div>
															<TitleCard titleLocal="analysis.inspectionOutCome.title"
																subTitleLocal="analysis.inspectionOutCome.subTitle"
																small infoLocal="analysis.inspectionOutCome.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</div>
														<div className="grid grid-cols-2 gap-4">
															{
																Array.isArray(activeRefines?.inspectionTasksL1) ?
																	activeRefines?.inspectionTasksL1?.map((element, i) => (
																		<SCAIndicatorCard
																			key={`SCAIndicatorCard ${i}`}
																			appSlice={appSlice}
																			module={element}
																			ratingsConfig={ratingsConfig}
																			locales={appDefinition.locales}
																			refineKey="inspectionTasksL1"
																			dataset="inspectIndicators"
																			indicators={[
																				{ label: "Inspection", key: "totalReports", digit: 0 },
																				{ label: "Finding ratio", key: "findingRatio", digit: 2 },
																				{ label: "Findings", key: "totalFindings", digit: 0 },
																				{
																					label: "Average Supplier Finding Ratio",
																					key: "avgSupplierpRatio",
																					digit: 2
																				}
																			]}
																		/>
																	))
																	:
																	<SCAIndicatorCard
																		key={"SCAIndicatorCard 1"}
																		appSlice={appSlice}
																		module={activeRefines?.inspectionTasksL1}
																		ratingsConfig={ratingsConfig}
																		locales={appDefinition.locales}
																		refineKey="inspectionTasksL1"
																		dataset="inspectIndicators"
																		indicators={[
																			{ label: "Inspection", key: "totalReports", digit: 0 },
																			{ label: "Finding ratio", key: "findingRatio", digit: 2 },
																			{ label: "Findings", key: "totalFindings", digit: 0 },
																			{ label: "avgSupplierpRatio", key: "avgSupplierpRatio", digit: 2 }
																		]}
																	/>
															}
														</div>
													</div>
												</IF>
											</div>
											<div className="row-span-2 col-span-1 p-4">
												<IF condition={activeRefines.equipmentCategory === undefined}>
													<div>
														<TitleCard titleLocal="analysis.fixedEquipmentCategoryStats.title"
															subTitleLocal="analysis.fixedEquipmentCategoryStats.subTitle"
															small infoLocal="analysis.fixedEquipmentCategoryStats.desc"
															theme={appDefinition.theme} locales={appDefinition.locales} />
													</div>
													<div>
														<ParentSize>
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	isClickable
																	dataset={"fixedEquipmentCategoryStats"}
																	textSize={20}
																	strokeWidth={16}
																	ncMargin={60}
																	margin={{
																		top: 40, left: 10, right: 30, bottom: 20
																	}}
																	lineHeight={60}
																	width={parent.width}
																	getScore={(d) => d?.inspectionCount}
																	getModule={(d) => d?.equipmentCategory}
																	getNC={(d) => d?.findingRatio.toLocaleString("en-US",
																		{ maximumFractionDigits: 2 })}
																	nonConformities
																	sortScoreDimension
																	scoreScaleMax
																	refineKeys={["equipmentCategory"]}
																	locales={appDefinition.locales}
																/>)}
														</ParentSize>
													</div>
												</IF>
												<IF condition={activeRefines.equipmentCategory !== undefined}>
													<div className="row-span-2 col-span-1 p-4">
														<div>
															<TitleCard titleLocal="treemap.title"
																subTitleLocal="treemap.subTitle"
																small infoLocal="treemap.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</div>
														<div className="-mt-5">
															<ParentSize>
																{(parent) => (
																	<TreeMap width={parent.width} height={465} appSlice={appSlice} dataSet="treeMap" />
																)}
															</ParentSize>
														</div>
													</div>
												</IF>

											</div>
											<div className={"row-span-1 col-span-2 p-4"}>
												<div className="flex justify-between">
													<TitleCard titleLocal="map.title"
														subTitleLocal={`map.subTitle.${activeRefines?.mapFilterHome?.value}`}
														small infoLocal="map.desc"
														theme={appDefinition.theme} locales={appDefinition.locales} />
													<div>

														<MapSelector className="z-2xl" locales={appDefinition.locales}
															options={mapFilterOptions}
															onChange={(mapFilter) => (
																dispatch(appSlice.actions.refine([{ key: "mapFilterHome", value: mapFilter }])))}
															placeHolder={"Indicators"}
															defaultValue={mapFilterOptions[0]}
														/>
													</div>

												</div>
												<div>
													<ParentSize >
														{(parent) => (
															<>
																<MapLeaflet
																	CustomLayer={SCALayer}
																	appSlice={appSlice}
																	datasetCountries="SCAMapStats"
																	countryScoreAccessor={activeRefines?.mapFilterHome?.scoreAccessor}
																	refineKey="mapFilterHome"
																	getMarkerColor={getMarkerColor}
																	fieldsTooltip={fieldsTooltip}
																	mapType="jawgLight"
																	minZoom={2}
																	zoom={2}
																	maxClusterRadius={35}
																	scrollWheelZoom={false}
																	defaultColor="#d3d3d3"
																	mapWidth={parent.width}
																	mapHeight={640}
																/>
															</>
														)}
													</ParentSize>
													<div className="flex justify-end relative bottom-12 right-2 z-500">
														<ColorLegend
															locales={appDefinition.locales}
															colorLegend={activeRefines?.mapFilterHome?.colors} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</IF>

								{/* EQUIPMENT page */}
								<IF condition={menu === "EQUIPMENT"}>
									<div className="w-full h-full">
										<div className="w-full flex flex-row">
											<div className="pt-4 px-4 w-full">
												<p className="text-4xl font-bold mb-1">{t("homePage.equipments.header")}</p>
												<div className="flex justify-between">
													<p className="pt-1 md:text-lg w-full md:w-2/3 font-light leading-6">
														{t("homePage.equipments.introduction")}
													</p>
												</div>
											</div>
											<div className="flex justify-center items-center w-72 h-10 mt-4 mr-4 rounded-full text-md
                                            font-medium hover:cursor-pointer" style={{ boxShadow: "0px 5px 4px rgba(180,180,180)" }}
											onClick={() => equipmentBottomRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" })}>
												{t("detailTrends")} <ArrowDownwardIcon className="ml-2" />
											</div>
										</div>
										<div className="relative w-full grid grid-cols-2 grid-flow-row gap-x-4 gap-y-4">
											<div className="row-span-1 col-span-1 p-4">
												<SCAIndicatorCard
													key={`SCAIndicatorCard ${0}`}
													label="Overall Statistics"
													description="Failure Rate"
													appSlice={appSlice}
													module={"overallStats"}
													ratingsConfig={overallRatingsConfig}
													locales={appDefinition.locales}
													refineKey="_id"
													dataset = "overallStats"
													datasetIndicators = ""
													indicators={[
														{ label: "Inspections", key: "overallReports", digit: 0 },
														{ label: "Finding ratio", key: "overallFindingRatio", digit: 2 },
														{ label: "Findings", key: "overallFindings", digit: 0 },
														{
															label: "Average supplier finding ratio",
															key: "overallAvgSpFindingRatio",
															digit: 2
														}
													]}
												/>
											</div>
											<div className="row-span-1 col-span-1 p-4">
												<IF condition={activeRefines.equipmentCategory === undefined}>
													<div className="relative">
														<div className="absolute w-64 right-0 h-10 mr-4">
															<SwitchSelector
																onChange={(v) => {
																	setEquipmentIndicatorType(indicatorTypeOptions?.find((e) => e.value === v));
																}}
																options={indicatorTypeOptions}
																initialSelectedIndex={equipmentIndicatorType.value === "failureRate" ? 0 : 1}
																backgroundColor="#eee"
																selectedfontcolor="#818593"
																selectionIndicatorMargin={4}
															/>
														</div>
														<TitleCard titleLocal = "equipment.reportsFailure.title"
															subTitleLocal = "equipment.reportsFailure.subTitle"
															small infoLocal = "equipment.reportsFailure.desc"
															theme={appDefinition.theme} locales={appDefinition.locales} />

													</div>

													<div>
														<ParentSize>
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	isClickable
																	dataset={"fixedEquipmentCategoryStats"}
																	textSize={16}
																	strokeWidth={12}
																	ncMargin={60}
																	margin={{
																		top: 40, left: 10, right: 30, bottom: 20
																	}}
																	width={parent.width}
																	getScore={(d) => d?.inspectionCount}
																	getModule={(d) => d?.equipmentCategory}
																	getNC={(d) => d?.[equipmentIndicatorType.value] ?
																		equipmentIndicatorType.scoreAccessor(d) : null}
																	nonConformities
																	sortScoreDimension
																	scoreScaleMax
																	refineKeys={["equipmentCategory"]}
																	locales={appDefinition.locales}
																/>)}
														</ParentSize>
													</div>
												</IF>
												<IF condition={activeRefines.equipmentCategory !== undefined}>
													<div>
														<TitleCard titleLocal = "equipment.categoryDetails.title"
															subTitleLocal = "equipment.categoryDetails.subTitle"
															small infoLocal = "equipment.categoryDetails.desc"
															theme={appDefinition.theme} locales={appDefinition.locales} />
													</div>
													<div className="grid grid-cols-2 gap-4">
														{
															Array.isArray(activeRefines?.equipmentCategory) ?
																activeRefines?.equipmentCategory?.map((element, i) => (
																	<SCAIndicatorCard
																		key={`SCAIndicatorCard ${i}`}
																		appSlice={appSlice}
																		module={element}
																		ratingsConfig={ratingsConfig}
																		locales={appDefinition.locales}
																		refineKey="equipmentCategory"
																		dataset = "equipmentCategoryIndicators"
																		indicators={[
																			{ label: "Inspection", key: "totalReports", digit: 0 },
																			{ label: "Finding ratio", key: "findingRatio", digit: 2 },
																			{ label: "Findings", key: "totalFindings", digit: 0 },
																			{
																				label: "Avg. supplier f. ratio",
																				key: "avgSupplierpRatio",
																				digit: 2
																			}
																		]}
																	/>
																))
																:
																<SCAIndicatorCard
																	key={"SCAIndicatorCard 1"}
																	appSlice={appSlice}
																	module={activeRefines?.equipmentCategory}
																	description={"Failure Rate"}
																	ratingsConfig={ratingsConfig}
																	locales={appDefinition.locales}
																	refineKey="equipmentCategory"
																	dataset = "equipmentCategoryIndicators"
																	indicators={[
																		{ label: "Inspection", key: "totalReports", digit: 0 },
																		{ label: "Finding ratio", key: "findingRatio", digit: 2 },
																		{ label: "Findings", key: "totalFindings", digit: 0 },
																		{ label: "avgSupplierpRatio", key: "avgSupplierpRatio", digit: 2 }
																	]}
																/>
														}

													</div>
												</IF>
											</div>
											<div className="row-span-1 col-span-2 p-4">

												<div>
													<TitleCard titleLocal="equipment.findingRatio.title"
														subTitleLocal = "equipment.findingRatio.subTitle"
														small infoLocal = "equipment.findingRatio.desc"
														theme={appDefinition.theme} locales={appDefinition.locales} />
												</div>
												<div>
													<ParentSize>
														{(parent) => (
															<DistributionChart
																appSlice={appSlice}
																dataset={"equipmentCategorySupplierStats"}
																arrayValues={"supplierRatios"}
																category={"equipmentCategory"}
																categoryValueField={"avgSupplierpRatio"}
																width={parent.width}
																height={400}
																binSize={0.25}
																xScaleExponent={0.25}
																refineKey="equipmentCategory"
															/>
														)}
													</ParentSize>
												</div>

											</div>
											<div className="row-span-1 col-span-2 p-4">
												<div className="flex justify-between">
													<TitleCard titleLocal="map.title"
														subTitleLocal={`map.subTitle.${activeRefines?.mapFilterEqpmnt?.value}`}
														small infoLocal="map.desc"
														theme={appDefinition.theme} locales={appDefinition.locales} />
													<div>
														<MapSelector className="z-2xl" locales={appDefinition.locales}
															options={mapFilterOptions}
															onChange={(mapFilter) => (
																dispatch(appSlice.actions.refine([{ key: "mapFilterEqpmnt", value: mapFilter }])))}
															placeHolder={"Indicators"}
															defaultValue={mapFilterOptions[2]}
														/>
													</div>

												</div>

												<div>
													<ParentSize >
														{(parent) => (
															<>
																<MapLeaflet
																	CustomLayer={SCALayer}
																	appSlice={appSlice}
																	datasetCountries="SCAMapStats"
																	countryScoreAccessor={activeRefines?.mapFilterHome?.scoreAccessor}
																	refineKey="mapFilterEqpmnt"
																	getMarkerColor={getMarkerColor}
																	fieldsTooltip={fieldsTooltip}
																	mapType="jawgLight"
																	minZoom={2}
																	zoom={2}
																	maxClusterRadius={35}
																	scrollWheelZoom={false}
																	defaultColor="#d3d3d3"
																	mapWidth={parent.width}
																	mapHeight={640}

																/>
															</>
														)}
													</ParentSize>
													<div className="flex justify-end relative bottom-12 right-2 z-500">
														<ColorLegend
															locales={appDefinition.locales}
															colorLegend={activeRefines?.mapFilterEqpmnt?.colors} />
													</div>

												</div>

											</div>
											<div className="row-span-2 col-span-2 p-4">
												<div>
													<TitleCard titleLocal="treemap.title"
														subTitleLocal="treemap.subTitle"
														small infoLocal="treemap.desc"
														theme={appDefinition.theme} locales={appDefinition.locales} />
												</div>
												<div className="-mt-5">
													<ParentSize>
														{(parent) => (
															<TreeMap
																width={parent.width}
																height={465}
																appSlice={appSlice}
																dataSet="treeMap"
																refineKeyL1="equipmentCategory"
															/>
														)}
													</ParentSize>
												</div>
												<div className="col-span-1 p-4" ref={equipmentBottomRef}>
													<div>
														<TitleCard titleLocal="temporalEvolution.findingRatio.title"
															subTitleLocal="temporalEvolution.findingRatio.subTitle"
															small infoLocal="temporalEvolution.findingRatio.desc"
															theme={appDefinition.theme} locales={appDefinition.locales} />
													</div>
													<div>
														<ParentSize>
															{(parent) => (
																<TimeEvolutionCard appSlice={appSlice}
																	nonConformities={nonConformities} dataset={activeRefines.year
																		? "globalFindingRatioPerMon"
																		: "globalFindingRatioPerQuarter"}
																	dataset2= {activeRefines.year
																		? "findingRatioPerMon"
																		: "findingRatioPerQuarter"} getFrequency={(d) => d.totalReport }
																	locales={appDefinition.locales}
																	showNonConformity={false}
																	theme={appDefinition.theme}
																	dotGroupKey="ratio" year="year" topInfoKey="totalReport" showTitle={false}
																	width={parent.width } height={280}
																	yScaleDomainFactor={4} linesAxisGroupTop={-12}
																	fillBarColor="#7F86C7" graphTop={29} barsGroupTop={-13}
																	isTrimestrial = {!activeRefines.year}
																	legend = {["Overall",
																		activeRefines.equipmentCategory?.length > 1
																			? "Selected Categories"
																			: activeRefines.equipmentCategory]}
																/>
															)}
														</ParentSize>
													</div>
												</div>
												<div className="col-span-1 p-4">
													<div>
														<TitleCard titleLocal="temporalEvolution.supplier.title"
															subTitleLocal="temporalEvolution.supplier.subTitle"
															small infoLocal="temporalEvolution.supplier.desc"
															theme={appDefinition.theme} locales={appDefinition.locales} />
													</div>
													<div>
														<ParentSize>
															{(parent) => (
																<TimeEvolutionCard appSlice={appSlice}
																	nonConformities={nonConformities} dataset={activeRefines.year
																		? "globalSupplierPerMon"
																		: "globalSupplierPerQuarter"}
																	dataset2={activeRefines.year
																		? "supplierPerMon"
																		: "supplierPerQuarter"} getFrequency={(d) => d.nbSuppliers}
																	locales={appDefinition.locales} showNonConformity={false}
																	theme={appDefinition.theme}
																	dotGroupKey="ratio" year="year" topInfoKey="nbSuppliers" showTitle={false}
																	width={parent.width} height={280}
																	yScaleDomainFactor={4.5} linesAxisGroupTop={-12}
																	fillBarColor="#7F86C7" graphTop={29} barsGroupTop={-13}
																	isTrimestrial = {!activeRefines.year}
																	legend = {["Overall",
																		activeRefines.equipmentCategory?.length > 1
																			? "Selected Categories"
																			: activeRefines.equipmentCategory]}
																/>
															)}
														</ParentSize>
													</div>
												</div>
												<div className="col-span-1 p-4">
													<div>
														<TitleCard titleLocal="stackedTrends.title"
															subTitleLocal="stackedTrends.subTitle"
															small infoLocal="stackedTrends.desc"
															theme={appDefinition.theme} locales={appDefinition.locales} />
													</div>
													<div>
														<ParentSize>
															{(parent) => (
																<StackedAreaChart
																	appSlice={appSlice}
																	dataset={activeRefines.year
																		? "findingCategoriesAndRatio"
																		: "findingCategoriesAndRatioPerQuarter"}
																	keys={activeDatasets.listFindingCategories.data?.map((d) => d._id)}
																	width={parent.width}
																	height={400}
																	theme={appDefinition.theme}
																	isTrimestrial = {!activeRefines.year}
																/>
															)}
														</ParentSize>
													</div>
												</div>
											</div>
										</div>
									</div>
								</IF>

								{/* INSPECTION page */}
								<IF condition={menu === "INSPECTION"}>
									<div className="w-full h-full">
										<div className="w-full flex flex-row">
											<div className="pt-4 px-4 w-full">
												<p className="text-4xl font-bold mb-1">{t("homePage.inspections.header")}</p>
												<div className="flex justify-between">
													<p className="pt-1 md:text-lg w-full md:w-2/3 font-light leading-6">
														{t("homePage.inspections.introduction")}
													</p>
												</div>
												<div className="relative w-full grid grid-cols-2 grid-flow-row gap-x-4 gap-y-4">
													<div className="row-span-1 col-span-1 p-4">
														<SCAIndicatorCard
															key={`SCAIndicatorCard ${0}`}
															label="Overall Statistics"
															description="Failure Rate"
															appSlice={appSlice}
															module={"overallStats"}
															ratingsConfig={overallRatingsConfig}
															locales={appDefinition.locales}
															refineKey="_id"
															dataset = "overallStats"
															datasetIndicators = ""
															indicators={[
																{ label: "Inspection", key: "overallReports", digit: 0 },
																{ label: "Finding ratio", key: "overallFindingRatio", digit: 2 },
																{ label: "Findings", key: "overallFindings", digit: 0 },
																{
																	label: "Average Supplier Finding Ratio",
																	key: "overallAvgSpFindingRatio",
																	digit: 2
																}
															]}
														/>
													</div>
													<div className="row-span-1 col-span-1 p-4">
														<IF condition={(!activeRefines.inspectionTasksL1)}>
															<div>
																<TitleCard titleLocal="inspectionVolume.failureRate.title"
																	subTitleLocal="inspectionVolume.failureRate.subTitle"
																	small infoLocal="inspectionVolume.failureRate.desc"
																	theme={appDefinition.theme} locales={appDefinition.locales} />
															</div>
															<div>
																<ParentSize>
																	{(parent) => (
																		<ColumnGraph
																			appSlice={appSlice}
																			isClickable
																			dataset={"inspectionTaskLv1Info"}
																			textSize={16}
																			strokeWidth={12}
																			ncMargin={60}
																			margin={{
																				top: 40, left: 10, right: 30, bottom: 20
																			}}
																			width={parent.width}
																			getScore={(d) => d?.nbReports}
																			getModule={(d) => d?.inspectionTasksL1}
																			getNC={(d) => d?.failureRate ? (d.failureRate * 100).toFixed(2) : null}
																			nonConformities
																			sortScoreDimension
																			scoreScaleMax
																			refineKeys={["inspectionTasksL1"]}
																			locales={appDefinition.locales}
																		/>)}
																</ParentSize>
															</div>
														</IF>

														<IF condition={activeRefines.inspectionTasksL1 !== undefined}>
															<div className="w-full">
																<div>
																	<TitleCard titleLocal="analysis.inspectionOutCome.title"
																		subTitleLocal="analysis.inspectionOutCome.subTitle"
																		small infoLocal="analysis.inspectionOutCome.desc"
																		theme={appDefinition.theme} locales={appDefinition.locales} />
																</div>
																<div className="grid grid-cols-2 gap-4">
																	{
																		Array.isArray(activeRefines?.inspectionTasksL1) ?
																			activeRefines?.inspectionTasksL1?.map((element, i) => (
																				<SCAIndicatorCard
																					key={`SCAIndicatorCard ${i}`}
																					appSlice={appSlice}
																					module={element}
																					ratingsConfig={ratingsConfig}
																					locales={appDefinition.locales}
																					refineKey="inspectionTasksL1"
																					dataset="inspectIndicators"
																					indicators={[
																						{ label: "Inspection", key: "totalReports", digit: 0 },
																						{ label: "Finding ratio", key: "findingRatio", digit: 2 },
																						{ label: "Findings", key: "totalFindings", digit: 0 },
																						{
																							label: "Average Supplier Finding Ratio",
																							key: "avgSupplierpRatio",
																							digit: 2
																						}
																					]}
																				/>
																			))
																			:
																			<SCAIndicatorCard
																				key={"SCAIndicatorCard 1"}
																				appSlice={appSlice}
																				module={activeRefines?.inspectionTasksL1}
																				ratingsConfig={ratingsConfig}
																				locales={appDefinition.locales}
																				refineKey="inspectionTasksL1"
																				dataset="inspectIndicators"
																				indicators={[
																					{ label: "Inspection", key: "totalReports", digit: 0 },
																					{ label: "Finding ratio", key: "findingRatio", digit: 2 },
																					{ label: "Findings", key: "totalFindings", digit: 0 },
																					{ label: "avgSupplierpRatio", key: "avgSupplierpRatio", digit: 2 }
																				]}
																			/>
																	}
																</div>
															</div>
														</IF>
													</div>
													<div className="col-span-2">
														<div>
															<TitleCard titleLocal="equipment.inspectionTaskL1Share.title"
																subTitleLocal="equipment.inspectionTaskL1Share.subTitle"
																small infoLocal="equipment.inspectionTaskL1Share.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</div>
														<div className="w-full grid grid-cols-4 gap-4">

															{activeDatasets?.listEquipmentCategory?.data
															.filter((el) => {
																if (activeRefines?.equipmentCategory?.length > 0) {
																	return activeRefines?.equipmentCategory?.includes(el.equipmentCategory);
																}
																return el;
															})
															.sort((a, b) => a.equipmentCategory > b.equipmentCategory ? 1 : -1)
															.map((item, i) => (
																<SCAPieChart
																	key={`list-${i}-${item.equipmentCategory}`}
																	appSlice={appSlice}
																	locales={appDefinition.locales}
																	dataset="taskL1Share"
																	label={item.equipmentCategory}
																	description={"Reports"}
																	module={item.equipmentCategory}
																	accTotalKey="totalReports"
																	accArrayKey="inspectionTasksL1Count"
																	accFrequencyKey="count"
																	accLabelKey="inspectionTasksL1"
																	accColorKey="inspectionTasksL1"
																	refineKey="equipmentCategory"
																	ratingsConfig={shareColorConfig}
																	hasLabel
																	hasTotal
																/>
															))}

														</div>
														<div className="w-full flex flex-row justify-center items-center mt-8">
															{activeDatasets?.listInspectionTasks?.data?.map((item, i) => (
																<div key={`list-${i}-${item.inspectionTasksL1}`}
																	className="flex justify-center items-center mx-2">
																	<div className="rounded-full w-2 h-2 bg-black"
																		style={{ background: shareColorConfig[item.inspectionTasksL1] }}></div>
																	<span className="text-sm">&nbsp;{item.inspectionTasksL1}&nbsp;</span>
																</div>
															))}

														</div>

													</div>
													<div className="row-span-1 col-span-2 p-4">

														<div>
															<TitleCard titleLocal="inspection.supplierFindingRatio.title"
																subTitleLocal = "inspection.supplierFindingRatio.subTitle"
																small infoLocal = "inspection.supplierFindingRatio.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</div>
														<div>
															<ParentSize>
																{(parent) => (
																	<DistributionChart
																		appSlice={appSlice}
																		dataset={"inspectionSupplierStats"}
																		arrayValues={"supplierRatios"}
																		category={"inspectionTasksL1"}
																		categoryValueField={"avgSupplierpRatio"}
																		width={parent.width}
																		height={400}
																		binSize={0.25}
																		refineKey="inspectionTasksL1"
																	/>
																)}
															</ParentSize>
														</div>

													</div>
													<div className="row-span-1 col-span-2 p-4">
														<div className="flex justify-between">
															<TitleCard titleLocal="map.title"
																subTitleLocal={`map.subTitle.${activeRefines?.mapFilterInspection?.value}`}
																small infoLocal="map.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
															<div>
																<MapSelector className="z-2xl" locales={appDefinition.locales}
																	options={mapFilterOptions}
																	onChange={(mapFilter) => (
																		dispatch(appSlice.actions.refine([{
																			key: "mapFilterInspection",
																			value: mapFilter
																		}])))}
																	placeHolder={"Indicators"}
																	defaultValue={mapFilterOptions[2]}
																/>
															</div>

														</div>
														<div>
															<ParentSize >
																{(parent) => (
																	<>
																		<MapLeaflet
																			CustomLayer={SCALayer}
																			appSlice={appSlice}
																			datasetCountries="SCAMapStats"
																			countryScoreAccessor={activeRefines?.mapFilterHome?.scoreAccessor}
																			refineKey="mapFilterInspection"
																			getMarkerColor={getMarkerColor}
																			fieldsTooltip={fieldsTooltip}
																			mapType="jawgLight"
																			minZoom={2}
																			zoom={2}
																			maxClusterRadius={35}
																			scrollWheelZoom={false}
																			defaultColor="#d3d3d3"
																			mapWidth={parent.width}
																			mapHeight={640}

																		/>
																	</>
																)}
															</ParentSize>
															<div className="flex justify-end relative bottom-12 right-2 z-500">
																<ColorLegend
																	locales={appDefinition.locales}
																	colorLegend={activeRefines?.mapFilterInspection?.colors} />
															</div>
														</div>
													</div>
													<div className="row-span-2 col-span-2 p-4">
														<div>
															<TitleCard titleLocal="treemap.title"
																subTitleLocal="treemap.subTitle"
																small infoLocal="treemap.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</div>
														<div className="-mt-5">
															<ParentSize>
																{(parent) => (
																	<TreeMap
																		width={parent.width}
																		height={465}
																		appSlice={appSlice}
																		dataSet="treeMap"
																		// refineKeyL1="inspectionTasksL1"
																	/>
																)}
															</ParentSize>
														</div>
													</div>
													<div className="col-span-2 p-4" ref={inspectionBottomRef}>
														<div>
															<TitleCard titleLocal="temporalEvolution.findingRatio.title"
																subTitleLocal="temporalEvolution.findingRatio.subTitle"
																small infoLocal="temporalEvolution.findingRatio.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</div>
														<div>
															<ParentSize>
																{(parent) => (
																	<TimeEvolutionCard appSlice={appSlice}
																		nonConformities={nonConformities} dataset={activeRefines.year
																			? "globalFindingRatioPerMon"
																			: "globalFindingRatioPerQuarter"}
																		dataset2= {activeRefines.year
																			? "findingRatioPerMon"
																			: "findingRatioPerQuarter"} getFrequency={(d) => d.totalReport }
																		refineKey={"inspectionTasksL1"}
																		locales={appDefinition.locales}
																		showNonConformity={false}
																		theme={appDefinition.theme}
																		dotGroupKey="ratio" year="year" topInfoKey="totalReport" showTitle={false}
																		width={parent.width } height={280}
																		yScaleDomainFactor={4} linesAxisGroupTop={-12}
																		fillBarColor="#7F86C7" graphTop={29} barsGroupTop={-13}
																		isTrimestrial = {!activeRefines.year}
																		legend = {["Overall",
																			activeRefines.inspectionTasksL1?.length > 1
																				? "Selected Inspection Activities"
																				: activeRefines.inspectionTasksL1]}
																	/>
																)}
															</ParentSize>
														</div>
													</div>
													<div className="col-span-2 p-4">
														<div>
															<TitleCard titleLocal="temporalEvolution.supplier.title"
																subTitleLocal="temporalEvolution.supplier.subTitle"
																small infoLocal="temporalEvolution.supplier.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</div>
														<div>
															<ParentSize>
																{(parent) => (
																	<TimeEvolutionCard appSlice={appSlice}
																		nonConformities={nonConformities} dataset={activeRefines.year
																			? "globalSupplierPerMon"
																			: "globalSupplierPerQuarter"}
																		dataset2={activeRefines.year
																			? "supplierPerMon"
																			: "supplierPerQuarter"} getFrequency={(d) => d.nbSuppliers}
																		refineKey={"inspectionTasksL1"}
																		locales={appDefinition.locales} showNonConformity={false}
																		theme={appDefinition.theme}
																		dotGroupKey="ratio" year="year" topInfoKey="nbSuppliers" showTitle={false}
																		width={parent.width} height={280}
																		yScaleDomainFactor={4.5} linesAxisGroupTop={-12}
																		fillBarColor="#7F86C7" graphTop={29} barsGroupTop={-13}
																		isTrimestrial = {!activeRefines.year}
																		legend = {["Overall",
																			activeRefines.inspectionTasksL1?.length > 1
																				? "Selected Inspection Activities"
																				: activeRefines.inspectionTasksL1]}
																	/>
																)}
															</ParentSize>
														</div>
													</div>
													<div className="col-span-2 p-4">
														<div>
															<TitleCard titleLocal="stackedInspectionTask.title"
																subTitleLocal="stackedInspectionTask.subTitle"
																small infoLocal="stackedInspectionTask.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</div>
														<div>
															<ParentSize>
																{(parent) => (
																	<StackedAreaChart
																		appSlice={appSlice}
																		dataset={activeRefines.year
																			? "inspectionTasksRatio"
																			: "inspectionTasksRatioPerQuarter"}
																		keys={activeDatasets.listInspectionTasks.data?.map((d) => d.inspectionTasksL1)}
																		width={parent.width}
																		height={400}
																		theme={appDefinition.theme}
																		isTrimestrial={!activeRefines.year}
																	/>
																)}
															</ParentSize>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</IF>

								{/* COUNTRY page */}
								<IF condition={menu === "COUNTRY"}>
									<div className="w-full h-full">
										<div className="w-full flex flex-row">
											<div className="pt-4 px-4 w-full">
												<p className="text-4xl font-bold mb-1">{t("homePage.countries.header")}</p>
												<div className="flex justify-between">
													<p className="pt-1 md:text-lg w-full md:w-2/3 font-light leading-6">
														{t("homePage.countries.introduction")}
													</p>
												</div>
												<div className="relative w-full grid grid-cols-2 grid-flow-row gap-x-4 gap-y-4">
													<div className="row-span-1 col-span-1 p-4">
														<div className="grid grid-cols-2 gap-4">
															<div className={!activeRefines.country ? "col-span-2" : "col-span-1"}>
																<SCAIndicatorCard
																	key={`SCAIndicatorCard ${0}`}
																	label="Overall Statistics"
																	description="Failure Rate"
																	appSlice={appSlice}
																	module={"overallStats"}
																	ratingsConfig={overallRatingsConfig}
																	locales={appDefinition.locales}
																	refineKey="_id"
																	dataset = "overallStats"
																	datasetIndicators = ""
																	indicators={[
																		{ label: "Inspection", key: "overallReports", digit: 0 },
																		{ label: "Finding ratio", key: "overallFindingRatio", digit: 2 },
																		{ label: "Findings", key: "overallFindings", digit: 0 },
																		{
																			label: "Average Supplier Finding Ratio",
																			key: "overallAvgSpFindingRatio",
																			digit: 2
																		}
																	]}
																/>
															</div>
															<IF condition={activeRefines?.country}>
																{
																	Array.isArray(activeRefines?.country) ?
																		activeRefines?.country?.map((country, i) => (
																			<div key={`${country}-${i}`}className="mx-1">
																				<SCAIndicatorCard
																					key={`SCAIndicatorCard ${country}-${i}`}
																					label={country || "Overall Stats"}
																					description="Failure Rate"
																					appSlice={appSlice}
																					module={country}
																					ratingsConfig={overallRatingsConfig}
																					locales={appDefinition.locales}
																					refineKey={"country"}
																					dataset = "overallCountryStats"
																					datasetIndicators = ""
																					indicators={[
																						{
																							label: "Inspection",
																							key: "overallReports",
																							digit: 0
																						},
																						{
																							label: "Finding ratio",
																							key: "overallFindingRatio",
																							digit: 2
																						},
																						{
																							label: "Findings",
																							key: "overallFindings",
																							digit: 0
																						},
																						{
																							label: "Average Supplier Finding Ratio",
																							key: "overallAvgSpFindingRatio",
																							digit: 2
																						}
																					]}
																				/>
																			</div>
																		))
																		:
																		<div key={`${activeRefines.country}-indicator`}className="mx-1">
																			<SCAIndicatorCard
																				key={`SCAIndicatorCard ${activeRefines.country}`}
																				label={activeRefines.country || "Overall Stats"}
																				description="Failure Rate"
																				appSlice={appSlice}
																				module={activeRefines.country}
																				ratingsConfig={overallRatingsConfig}
																				locales={appDefinition.locales}
																				refineKey={"country"}
																				dataset = "overallCountryStats"
																				datasetIndicators = ""
																				indicators={[
																					{ label: "Inspection", key: "overallReports", digit: 0 },
																					{ label: "Finding ratio", key: "overallFindingRatio", digit: 2 },
																					{ label: "Findings", key: "overallFindings", digit: 0 },
																					{
																						label: "Average Supplier Finding Ratio",
																						key: "overallAvgSpFindingRatio",
																						digit: 2
																					}
																				]}
																			/>
																		</div>
																}

															</IF>
														</div>
													</div>
													<div className="row-span-1 col-span-1 p-4">
														<div>
															<TitleCard titleLocal="countryBenchMark.title"
																subTitleLocal="countryBenchMark.subTitle"
																small infoLocal="countryBenchMark.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</div>
														<div className="max-h-60 overflow-y-auto"
															style={{
																scrollbarWidth: "thin"
															}}>
															<ParentSize>
																{(parent) => (
																	<ColumnGraph
																		appSlice={appSlice}
																		isClickable
																		highlight
																		dataset="countryBenchMark"
																		textSize={12}
																		strokeWidth={12}
																		ncMargin={30}
																		margin={{
																			top: 40, left: 10, right: 30, bottom: 20
																		}}
																		width={parent.width}
																		getScore={(d) => d?.inspectionCount}
																		getModule={(d) => d?.country}
																		getNC={(d) => d?.findingRatio.toFixed(2)}
																		nonConformities
																		sortScoreDimension
																		scoreScaleMax
																		refineKeys={["country"]}
																		locales={appDefinition.locales}
																	/>)}
															</ParentSize>
														</div>
													</div>
													<div className={"row-span-1 col-span-2 p-4"}>
														<div className="flex justify-between">
															<TitleCard titleLocal="map.title"
																subTitleLocal={`map.subTitle.${activeRefines?.mapFilterCountry?.value}`}
																small infoLocal="map.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
															<div>
																<MapSelector className="z-2xl" locales={appDefinition.locales}
																	options={mapFilterOptions}
																	onChange={(mapFilter) => (
																		dispatch(appSlice.actions.refine([{
																			key: "mapFilterCountry",
																			value: mapFilter
																		}])))}
																	placeHolder={"Indicators"}
																	defaultValue={mapFilterOptions[2]}
																/>
															</div>

														</div>
														<div>
															<ParentSize >
																{(parent) => (
																	<>
																		<MapLeaflet
																			CustomLayer={SCALayer}
																			appSlice={appSlice}
																			datasetCountries="SCAMapStats"
																			countryScoreAccessor={activeRefines?.mapFilterHome?.scoreAccessor}
																			refineKey="mapFilterCountry"
																			getMarkerColor={getMarkerColor}
																			fieldsTooltip={fieldsTooltip}
																			mapType="jawgLight"
																			minZoom={2}
																			zoom={2}
																			maxClusterRadius={35}
																			scrollWheelZoom={false}
																			defaultColor="#d3d3d3"
																			mapWidth={parent.width}
																			mapHeight={640}

																		/>
																	</>
																)}
															</ParentSize>
															<div className="flex justify-end relative bottom-12 right-2 z-500">
																<ColorLegend
																	locales={appDefinition.locales}
																	colorLegend={activeRefines?.mapFilterCountry?.colors} />
															</div>
														</div>
													</div>

													<div className={"col-span-2 row-span-1 p-4"}>

														<div>
															<TitleCard titleLocal="country.findingRatio.title"
																subTitleLocal = "country.findingRatio.subTitle"
																small infoLocal = "country.findingRatio.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</div>
														<div>
															<ParentSize>
																{(parent) => (
																	<DistributionChart
																		appSlice={appSlice}
																		dataset={"countrySupplierStats"}
																		arrayValues={"supplierRatios"}
																		category={"country"}
																		categoryValueField={"avgSupplierpRatio"}
																		refineKey="country"
																		width={parent.width}
																		height={400}
																		binSize={0.05}
																	/>
																)}
															</ParentSize>
														</div>

													</div>

													<div className={"row-span-2 col-span-2 p-4"}>
														<div>
															<TitleCard titleLocal="treemap.title"
																subTitleLocal="treemap.subTitle"
																small infoLocal="treemap.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</div>
														<div className="-mt-5">
															<ParentSize>
																{(parent) => (
																	<TreeMap
																		width={parent.width}
																		height={465}
																		appSlice={appSlice}
																		dataSet="treeMap"
																		highlightKey= "country"
																		highlightColor="#FF999A"
																		refineKeyL1="inspectionTasksL1"
																		refineKeyL3="country"
																	/>
																)}
															</ParentSize>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</IF>

								{/* SITES page */}
								<IF condition={menu === "SITES" && activeRefines.manufacturer === undefined}>

									<div className="flex flex-col px-8 w-full 2xl:px-12" ref={contentRef}>
										<div className="flex justify-between">
											<PageHeader
												page="listSuppliersPage"
												Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
												locales={appDefinition.locales}
											/>
										</div>
										<div className="flex flex-col space-y-4 w-full md:col-span-2">
											<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4">
												<SCATable
													appSlice={appSlice}
													dataset="sites"
													structureTable={tableStructure}
													refineKeys={(row) => [
														{ key: "manufacturer", value: row.original.manufacturer }
														// { key: "equipmentCategory", value: row.original.equipmentCategory }
													]}
													sortByRefineFields={[{ id: "totalFindings", desc: true }]}
													themePalette="supplyr"
													locales={appDefinition.locales} />
											</div>
										</div>
									</div>
								</IF>
							</div>
						</div>
					</IF>

					{/* SITE page */}
					<IF condition={menu !== "INFO" && menu !== "TERMs" && (menu === "SITES" && activeRefines.manufacturer !== undefined)}>
						{/* <SCAHeader clientParameters={clientParameters} locales={appDefinition.locales}/> */}

						<Div100vh className="overflow-y-auto">

							<SCAPageMenuHeader
								appSlice={appSlice}
								Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
								themePalette="supplyr"
								logo={appDefinition.logo}
								locales={appDefinition.locales}
							/>

							<div className="grid grid-cols-1 md:grid-cols-4 grid-flow-row
                                    -mt-36 mx-4 md:mx-10 gap-y-6 gap-x-4 md:gap-x-6 ">
								<div className="md:ml-10 md:mr-20 z-10 md:col-span-2">
									<SCASupplierDetailedCard appSlice={appSlice} dataset="sites" />
								</div>
								<div className="col-span-1 z-10 h-72 alphaBlur rounded-xl shadow-md md:col-span-1">
									<SCAIndicatorCard
										key={`SCAIndicatorCard ${0}`}
										label={"Statistics"}
										description="Failure Rate"
										appSlice={appSlice}
										module={activeRefines.manufacturer}
										ratingsConfig={overallRatingsConfig}
										locales={appDefinition.locales}
										refineKey="manufacturer"
										dataset = "overallSupplierStats"
										datasetIndicators = ""
										indicators={[
											{ label: "Inspection", key: "overallReports", digit: 0 },
											{ label: "Finding ratio", key: "overallFindingRatio", digit: 2 },
											{ label: "Findings", key: "overallFindings", digit: 0 }
										]}
									/>
								</div>
								<div className="relative overflow-hidden shadow-md rounded-xl h-72 bg-white z-10 md:col-span-1">
									<ParentSize debounceTime={10}>
										{(parent) => (
											<MapLeaflet
												appSlice={appSlice}
												dataset="siteGeometries"
												getMarkerColor={getMarkerColor}
												// markersTree={activeDatasets.supplyChainTree.data}
												fieldsTooltip={fieldsTooltip}
												mapType="jawgTerrain"
												minZoom={2}
												zoom={2}
												scrollWheelZoom={true}
												defaultColor="#d3d3d3"
												mapWidth={window.innerWidth > mdScreens ? parent.width : window.innerWidth}
												mapHeight={parent.height}
												CustomMarker={SupplyRMapMarker}
											/>
										)}
									</ParentSize>
									{/* {activeRefines.siteId !== undefined && <div
                                            className="absolute bottom-6 left-1/2 transform -translate-x-1/2 w-4/5 gap-x-2 font-light text-medium
                                               flex justify-center items-center rounded-xl alphaBlur border px-3 py-1 z-500">
                                            <LocationOnIcon fontSize="small" />
                                            <p className="line-clamp-1">
                                                {activeDatasets.sites?.data?.[0]?.country}&nbsp;-&nbsp;
                                                {activeDatasets.sites?.data?.[0]?.city}&nbsp;-&nbsp;
                                                {activeDatasets.sites?.data?.[0]?.address}
                                            </p>
                                        </div>} */}
								</div>

							</div>

							<div className="relative w-full h-full grid grid-cols-2 gap-4">

								<div className="row-span-1 col-span-1 p-4">
									<TitleCard titleLocal="supplier.globalDistribution.title"
										subTitleLocal="supplier.globalDistribution.subTitle"
										small infoLocal="supplier.globalDistribution.desc"
										theme={appDefinition.theme} locales={appDefinition.locales} />

									<div>
										<ParentSize>
											{(parent) => (
												<DistributionChart
													appSlice={appSlice}
													dataset={"supplierGlobalFindingRatios"}
													arrayValues={"findingRatio"}
													category={"manufacturer"}
													categoryValueField={"findingRatio"}
													width={parent.width}
													height={400}
													binSize={0.25}
													xScaleExponent={0.25}
												/>
											)}
										</ParentSize>
									</div>
								</div>
								<div className="row-span-1 col-span-1 p-4">

									<TitleCard titleLocal="supplier.countryDistribution.title"
										subTitleLocal="supplier.countryDistribution.subTitle"
										small infoLocal="supplier.countryDistribution.desc"
										theme={appDefinition.theme} locales={appDefinition.locales} />

									<div className="relative">
										<span className="absolute left-16 -top-4 font-medium">{activeDatasets?.sites?.data?.[0]?.country}</span>
										<ParentSize>
											{(parent) => (
												<DistributionChart
													appSlice={appSlice}
													dataset={"supplierCountryFindingRatios"}
													arrayValues={"findingRatio"}
													category={"manufacturer"}
													categoryValueField={"findingRatio"}
													filterFunc={(d) => d.country === activeDatasets?.sites?.data?.[0]?.country}
													width={parent.width}
													height={400}
													binSize={0.25}
													xScaleExponent={0.25}
												/>
											)}
										</ParentSize>
									</div>
								</div>
								<div className="row-span-1 col-span-2">

									<div className="p-4">
										<TitleCard titleLocal="supplier.productDistribution.title"
											subTitleLocal="supplier.productDistribution.subTitle"
											small infoLocal="supplier.productDistribution.desc"
											theme={appDefinition.theme} locales={appDefinition.locales} />
									</div>
									<div className="flex flex-row flex-auto">
										{activeRefines.manufacturer &&
										[...activeDatasets.sites.data.reduce(
											(acc, d) => d.equipmentCategory ? new Set([...acc, d.equipmentCategory]) : acc, new Set()
										)]?.map((eqpt, i) => (
											<div className="p-4 w-full relative" key={`SupplierProductDistributionChart-${eqpt}`}>
												<span className="absolute left-16 -top-4 font-medium">{eqpt}</span>
												<ParentSize>
													{(parent) => (
														<DistributionChart
															appSlice={appSlice}
															dataset={"supplierProductFindingRatios"}
															arrayValues={"findingRatio"}
															category={"manufacturer"}
															categoryValueField={"findingRatio"}
															filterFunc={(d) => d.equipmentCategory === eqpt}
															width={parent.width}
															height={400}
															binSize={0.25}
															xScaleExponent={0.25}
														/>
													)}
												</ParentSize>
											</div>)
										)}
									</div>
								</div>

								<div className="row-span-1 col-span-2 p-4">
									<div>
										<TitleCard titleLocal="treemap.title"
											subTitleLocal="treemap.subTitle"
											small infoLocal="treemap.desc"
											theme={appDefinition.theme} locales={appDefinition.locales} />
									</div>
									<div className="-mt-5">
										<ParentSize>
											{(parent) => (
												<TreeMap width={parent.width} height={400} appSlice={appSlice} dataSet="treeMap" />
											)}
										</ParentSize>
									</div>
								</div>
								<div className="row-span-1 col-span-2 p-4">
									<div className="row-span-1">
										<div>
											<TitleCard titleLocal="temporalEvolution.findingRatio.title"
												subTitleLocal="temporalEvolution.findingRatio.subTitle"
												small infoLocal="temporalEvolution.findingRatio.desc"
												theme={appDefinition.theme} locales={appDefinition.locales} />
										</div>
										<div>
											<ParentSize>
												{(parent) => (
													<TimeEvolutionCard appSlice={appSlice}
														nonConformities={nonConformities} dataset="globalFindingRatioPerMon"
														dataset2="findingRatioPerMon" getFrequency={(d) => d.totalReport}
														locales={appDefinition.locales} showNonConformity={false} theme={appDefinition.theme}
														dotGroupKey="ratio" year="year" topInfoKey="totalReport" showTitle={false}
														width={parent.width} height={280} yScaleDomainFactor={4} linesAxisGroupTop={-12}
														fillBarColor="#7F86C7" graphTop={29} barsGroupTop={-13}
														legend = {["Overall",
															activeRefines.equipmentCategory?.length > 1
																? "Selected Inspection Activities"
																: activeRefines.equipmentCategory]}
													/>
												)}
											</ParentSize>
										</div>
									</div>
									<div className="row-span-1 col-span-2">
										<div>
											<TitleCard titleLocal="temporalEvolution.inspectionFailure.title"
												subTitleLocal="temporalEvolution.inspectionFailure.subTitle"
												small infoLocal="temporalEvolution.inspectionFailure.desc"
												theme={appDefinition.theme} locales={appDefinition.locales} />
										</div>
										<div>
											<ParentSize>
												{(parent) => (
													<TimeEvolutionCard appSlice={appSlice}
														nonConformities={nonConformities} dataset="globalSupplierPerMon"
														dataset2="supplierPerMon" getFrequency={(d) => d.totalReport}
														locales={appDefinition.locales} showNonConformity={false} theme={appDefinition.theme}
														dotGroupKey="ratio" year="year" topInfoKey="totalReport" showTitle={false}
														width={parent.width} height={280} yScaleDomainFactor={4.5} linesAxisGroupTop={-12}
														fillBarColor="#7F86C7" graphTop={29} barsGroupTop={-13}
														legend = {["Overall",
															activeRefines.equipmentCategory?.length > 1
																? "Selected Categories"
																: activeRefines.equipmentCategory]}
													/>
												)}
											</ParentSize>
										</div>
									</div>
								</div>

								<div className="row-span-1 col-span-1 p-4">
									<div>
										<TitleCard titleLocal="stackedTrends.title"
											subTitleLocal="stackedTrends.subTitle"
											small infoLocal="stackedTrends.desc"
											theme={appDefinition.theme} locales={appDefinition.locales} />
									</div>
									<div>
										<ParentSize>
											{(parent) => (
												<StackedAreaChart
													appSlice={appSlice}
													dataset={activeRefines.year
														? "findingCategoriesAndRatio"
														: "findingCategoriesAndRatioPerQuarter"}
													keys={activeDatasets.listFindingCategories.data?.map((d) => d._id)}
													width={parent.width}
													height={400}
													theme={appDefinition.theme}
													isTrimestrial = {!activeRefines.year}
												/>
											)}
										</ParentSize>
									</div>

								</div>

								<div className="row-span-1 col-span-1 p-4">
									<div>
										<TitleCard titleLocal="stackedInspectionTask.title"
											subTitleLocal="stackedInspectionTask.subTitle"
											small infoLocal="stackedInspectionTask.desc"
											theme={appDefinition.theme} locales={appDefinition.locales} />
									</div>
									<div>
										<ParentSize>
											{(parent) => (
												<StackedAreaChart
													appSlice={appSlice}
													dataset={activeRefines.year
														? "inspectionTasksRatio"
														: "inspectionTasksRatioPerQuarter"}
													keys={activeDatasets.listInspectionTasks.data?.map((d) => d.inspectionTasksL1)}
													width={parent.width}
													height={400}
													theme={appDefinition.theme}
													isTrimestrial={!activeRefines.year}
												/>
											)}
										</ParentSize>
									</div>
								</div>
							</div>

						</Div100vh>

					</IF>

					{/*
                    * INFO PAGE
                    */}
					<IF condition={menu === "INFO"}>
						<Div100vh className="overflow-y-auto ">
							<SupplyRInfoPageHeader
								appSlice={appSlice}
								page="Info"
								Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
								locales={appDefinition.locales}
							>
								<div className="flex flex-row justify-center item-center">
									<img src={appDefinition.logo} className="w-16 max-h-24 object-scale-down mt-2" />
									<div className="flex flex-col mt-6 ml-3">
										<p className="text-3xl text-white font-medium">{t("Info.header.supplyR")}</p>
										<p className="text-medium w-max text-white tracking-widest uppercase">{t("Info.header.bv")}</p>
									</div>
								</div>
							</SupplyRInfoPageHeader>

							<div className="grid grid-cols-1 grid-flow-row my-6 mx-4 md:mx-10 gap-y-6 gap-x-4 md:grid-cols-2 md:gap-x-6">

								<div className="flex flex-col gap-y-2 md:col-span-2">
									<p className="text-lg font-semibold">{t("Info.content.resilienceTitle")}</p>
									<p className="font-light text-sm">{t("Info.content.resilienceDescription")}</p>
								</div>

								<div className="flex flex-col gap-y-2">
									<p className="text-lg font-semibold">{t("Info.content.resistanceTitle")}</p>
									<img src="/images/SupplyR/Info/SupplyRResistance.png" className="-ml-4" />
								</div>

								<div className="flex flex-col gap-y-2">
									<p className="text-lg font-semibold">{t("Info.content.responsivenessTitle")}</p>
									<img src="/images/SupplyR/Info/SupplyRResponsiveness.png" className="-ml-4" />
								</div>

								<p className="text-lg font-semibold md:col-span-2">{t("Info.content.modulesTitle")}</p>

								<img src="/images/SupplyR/Info/BUSINESS CONTINUITY.png" className="h-36 " />
								<img src="/images/SupplyR/Info/DATA INTEGRITY.png" className="h-36" />
								<img src="/images/SupplyR/Info/HSE RESISTANCE.png" className="h-36" />
								<img src="/images/SupplyR/Info/LOGISTICS & INVENTORY PRACTICES.png" className="h-36" />
								<img src="/images/SupplyR/Info/SUPPLIER TECHNICAL ASSESSMENT.png" className="h-36" />
								<img src="/images/SupplyR/Info/SUPPLY CHAIN SECURITY.png" className="h-36" />
								<img src="/images/SupplyR/Info/GOVERNANCE SOCIAL.png" className="h-36" />

							</div>
						</Div100vh>
					</IF>

					{/*
                      * TERMS PAGE
                      */}
					<IF condition={menu === "TERMs"}>
						<Div100vh className="overflow-y-auto ">
							<SupplyRTermPageHeader
								appSlice={appSlice}
								page="TermsPage"
								Picto={GavelIcon}
								locales={appDefinition.locales} />

							<div className="flex flex-col w-full justify-center -mt-12 mb-12 px-4 md:px-8 space-y-8 2xl:px-12">
								<Tabs tabSet={termsTabSet} locales={appDefinition.locales} />
							</div>
						</Div100vh>
					</IF>

				</div>

				<div className="flex flex-col">
					<div className="mt-4">
						<ProjectScope
							appSlice={appSlice}
							title="Mission type"
							dataset="stageType"
							listCategories="stageType"
							categoryField="stageType"
							total={activeDatasets.fixedStats?.data[0]?.totalSuppliers}
							totalCovered={activeDatasets.fixedStats?.data[0]?.totalSuppliers}
							locales={appDefinition.locales}
						/>
					</div>

					<div className="overflow-hidden h-full">
						<HorizontalFilters appSlice={appSlice} theme="compass"
							filterOptions={[{
								label: "Stage",
								refine: "stage",
								placeHolder: "filters.score",
								isSwitchSelector: true,
								swtich: {
									className: "relative w-64 h-10 mr-4",
									onChange: (value) => {
										setFinal(value);
										if (activeRefines?.stage) {
											dispatch(appSlice.actions.clear([{ refine: "stage" }]));
										}
										if (!activeRefines?.stage) {
											dispatch(appSlice.actions.refine([{ key: "stage", value: "Final" }]));
										}
									},
									SwitchSelectorOpt: [{
										label: t("inspectionVolume.switch.all"),
										value: false,
										selectedBackgroundColor: "#fff",
										selectedfontcolor: "#3D49AB"
									},
									{
										label: t("inspectionVolume.switch.final"),
										value: true,
										selectedBackgroundColor: "#fff",
										selectedfontcolor: "#3D49AB"
									}],
									initialSelectedIndex: final ? 1 : 0,
									bgColor: "#eee",
									selectedfontcolor: "#818593",
									selectionIndicatorMargin: 4
								}
							}, ...filterOptions]}
							initRefines={SCAInitRefines}
							classTitle="compass_primary-accent" filterDesktop
							customStyle={customStylesSCA} customStyleSearch={customStylesSearchSCA}
							isActiveFilter={isActiveFilter} locales={appDefinition.locales}>
						</HorizontalFilters>
					</div>

				</div>
			</div>

		</div>
	);
}

export default SCA;
