/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import { ParentSize } from "@visx/responsive";

import NonConformity from "./NonConformity";
import MaturityBars from "./MaturityBars";
import MonthAnalysis from "../graphs/MonthAnalysis";
import MonthAnalysisNC from "../graphs/MonthAnalysisNC";
import TitleCard from "./TitleCard";
import CallToAction from "../button/CallToAction";
import CreateVerkorNCforecastLines from "../../utils/verkorForecast/CreateVerkorNCforecastLines";
import { verkorLinesByQuarter, verkorSitesByQuarter, getLongest } from "../../utils/verkorForecast/VerkorForecastHelpers";

const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

function NonConformitiesCardVerkor({
	appSlice = null, nonConformities, datasetListCriticalities = "listCriticalities", criticalityKey = "criticality",
	dataset = undefined, datasetNCMediumLow = undefined, synthesis = false, noMonthlyAnalysis = false, theme = "clarity",
	locales, showNonConformity = true,
	callToAction = false, ctaContent, ctaAssignementIcon, ctaLink, colorScale, maturityColorScale, showCriticalitySplit
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store
	const listCriticalities = useSelector(appSlice.selectDatasets)[datasetListCriticalities]?.data;
	const nonConformitiesData = useSelector(appSlice.selectDatasets)[dataset]?.data;
	const monthAnalysisMaturityData = useSelector(appSlice.selectDatasets)?.linesMaturity?.data;
	const maturityNbSites = useSelector(appSlice.selectDatasets)?.maturityNbSites?.data;
	const sitePage = useSelector(appSlice.selectPage);
	// client parameters
	const clientParameters = useSelector(appSlice.selectClientParameters);

	// Verkor data
	const nonConformitiesByTargetDate = useSelector(appSlice.selectDatasets).nonConformitiesByTargetDate?.data;
	const nonConformitiesByClosureDate = useSelector(appSlice.selectDatasets).nonConformitiesByClosureDate?.data;
	const actionPlanData = useSelector(appSlice.selectDatasets).getActionPlan?.data;

	if (listCriticalities === undefined || nonConformitiesData === undefined) {
		return null;
	}

	const sortedNonConformitiesData = [...nonConformitiesData]
	.sort((dotA, dotB) => ((dotA.year > dotB.year) ? 1 : ((dotA.year < dotB.year) ? -1 : ((dotA.month > dotB.month) ? 1 : -1))));

	const verkorLines =
	CreateVerkorNCforecastLines(
		sortedNonConformitiesData, nonConformitiesByTargetDate, nonConformitiesByClosureDate, actionPlanData, clientParameters
	);

	const {
		openedNC, criticalNC, nbSites, colorSchema
	} = verkorLines;

	let { linesNonConformities, lineNbSites } = verkorLines;

	const isTrimestral = linesNonConformities[1]?.list.length > 12;
	if (isTrimestral) {
		linesNonConformities = verkorLinesByQuarter(linesNonConformities);
		lineNbSites = verkorSitesByQuarter(lineNbSites, linesNonConformities[1]);
	}

	if (openedNC < 1) {
		return null;
	}

	return (

		synthesis
			?
			<div className={`relative flex items-center h-full gap-x-8
					${showNonConformity ? "w-full justify-between" : "w-44 h-28 justify-end" }`}>
				<div className="flex flex-shrink-0 flex-col items-center justify-center h-full">
					<p className="text-5xl font-light text-red-500">{openedNC}</p>
					<p className="text-medium font-semibold tracking-tight">{t("NCsComponent.synthesis.areasOfConcern")}</p>
					<p className="text-medium font-light tracking-tight">{nbSites} {t("NCsComponent.synthesis.sites")}</p>
				</div>
				{callToAction &&
					<CallToAction ctaContent={ctaContent} assignementIcon={ctaAssignementIcon} ctaLink={ctaLink}/>}
				{showNonConformity &&	<div className="w-52">

					{
						clientParameters?.maturityColors
							? <MaturityBars
								appSlice={appSlice}
								dataset="maturityRatios"
								noTitle
								locales={locales} />
							: <NonConformity
								appSlice={appSlice}
								noTitle
								refineKey={clientParameters?.threeLevelNC ? "criticalityLevel" : "critical"}
								refineValueHigh={clientParameters?.threeLevelNC ? "High" : "Y"}
								refineValueMedium={clientParameters?.threeLevelNC ? "Medium" : "N"}
								refineValueLow={clientParameters?.threeLevelNC && "Low"}
								ncHigh={criticalNC}
								ncMedium={openedNC - criticalNC}
								ncLow={0}
								locales={locales} />
					}

				</div>}
			</div>
			:
			<div className="w-full">
				<ParentSize>
					{(parent) => (
						<>
							{showCriticalitySplit &&
							<>
								<TitleCard titleLocal="NCsComponent.graph.titleSplitByCrit" // eslint-disable-next-line no-mixed-spaces-and-tabs
	                        		subTitleLocal="" xsmall theme={theme} // eslint-disable-next-line no-mixed-spaces-and-tabs
	                        		infoLocal="NCsComponent.graph.infoSplitByCrit" locales={locales} />
								<NonConformity
									appSlice={appSlice}
									noTitle
									refineKey={clientParameters?.threeLevelNC ? "criticalityLevel" : "critical"}
									refineValueHigh={clientParameters?.threeLevelNC ? "High" : "Y"}
									refineValueMedium={clientParameters?.threeLevelNC ? "Medium" : "N"}
									refineValueLow={clientParameters?.threeLevelNC && "Low"}
									ncHigh={criticalNC}
									ncMedium={openedNC - criticalNC}
									ncLow={0}
									locales={locales} />
							</>
							}
							<div className="flex justify-between items-center mb-2 w-full">
								<div className="flex items-center text-small font-light">
									{clientParameters?.maturityColors
										? clientParameters.maturityColors.map((element, i) => (
											<div key={i} className="flex items-center mr-5">
												<span className={`block w-3 h-3 rounded-full mr-1 md:mr-2 bg-clarity_primary-${element.color}`}></span>
												<p>{element.label}</p>
											</div>
										))
										: listCriticalities.map((element, i) => (
											<div key={i} className="flex items-center mr-5">
												<span className="block w-3 h-3 rounded-full mr-1 md:mr-2"
													style={{ backgroundColor: nonConformities?.[element?.[criticalityKey]] }}></span>
												<p>{t(`NCsComponent.legend.${element?.[criticalityKey]}`)}</p>
											</div>
										))}
									{!noMonthlyAnalysis && linesNonConformities[0].list.length >= 1 &&
								<div className="flex items-center">
									<span className={"block w-[5px] h-3 rounded-t-md bg-[#cecece] mr-1 md:mr-2"}></span>
									{/* if length of data is 1 it means we are looking on a single site */}
									{nonConformitiesData.length === 1 ?
										<p>{t("NCsComponent.legend.nbNCs")}</p> :
										<p>{t("NCsComponent.legend.nbSuppliers")}</p>}
									<div className="w-8 h-2 ml-5 mr-2 border-t-2 border-b-2 border-t-[#ffc362] border-b-[#ff475c] border-dashed"></div>
									<p>{t("NCsComponent.legend.dashedLine")}</p>
								</div>}
								</div>
								{ sitePage !== "NCs"
									&& <p className={`flex-shrink-0 text-sm font-semibold text-${theme}_primary-accent cursor-pointer`}
										onClick={() => dispatch(appSlice.actions.setPage("NCs"))}>
										{t("NCsComponent.seeThemAll")}
									</p>}
							</div>
							{!noMonthlyAnalysis && linesNonConformities[0].list.length >= 1 &&
	                        <div className="flex flex-col">
	                        	<TitleCard titleLocal="NCsComponent.graph.title" // eslint-disable-next-line no-mixed-spaces-and-tabs
	                        		subTitleLocal="" small theme={theme} // eslint-disable-next-line no-mixed-spaces-and-tabs
	                        		infoLocal="NCsComponent.graph.info" locales={locales} />
	                        	<MonthAnalysisNC
	                        		appSlice={appSlice}
	                        		colorScale={colorSchema}
	                        		maturityColorScale={maturityColorScale}
	                        		linesNonConformities={clientParameters?.maturityColors
	                        			? monthAnalysisMaturityData
	                        			: linesNonConformities}
	                        		maturityDisplay={!!clientParameters?.maturityColors}
	                        		lineNbSites={clientParameters?.maturityColors ? maturityNbSites : lineNbSites}
	                        		width={parent.width} locales={locales} />
	                        </div>
							}
						</>
					)}
				</ParentSize>
			</div>

	);
}

NonConformitiesCardVerkor.propTypes = {
	appSlice: PropTypes.object,
	nonConformities: PropTypes.object,
	datasetListCriticalities: PropTypes.string,
	criticalityKey: PropTypes.string,
	dataset: PropTypes.string,
	datasetNCMediumLow: PropTypes.string,
	synthesis: PropTypes.bool,
	noMonthlyAnalysis: PropTypes.bool,
	theme: PropTypes.string,
	locales: PropTypes.string,
	showNonConformity: PropTypes.bool,
	callToAction: PropTypes.bool,
	ctaLink: PropTypes.string,
	ctaContent: PropTypes.string,
	ctaAssignementIcon: PropTypes.bool,
	colorScale: PropTypes.array,
	maturityColorScale: PropTypes.array,
	showCriticalitySplit: PropTypes.bool
};

export default NonConformitiesCardVerkor;
