import React from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import { typeSelector, currentFieldOptions } from "../../apps/configs/AdminConfig";
import ArrayFieldEditor from "./ArrayFieldEditor";
import SimpleSelectFieldEditor from "./SelectFieldEditor";

const transform = {
	input: (value) => Number.isNaN(value) ? "" : value?.toString(),
	output: (e) => {
		const output = parseInt(e.target.value, 10);

		return Number.isNaN(output) ? 0 : output;
	}
};

const stringField = ({
	label, value, field, register
}) => (
	<div className="flex flex-row items-center">
		<label htmlFor={`${label}`} className="uppercase text-medium mr-2">{label}:</label>
		<input
			{...register(`${field}`)}
			autoComplete="off"
			defaultValue={`${value}`}
			className="pl-2 py-1 border text-black focus:outline-none rounded-lg focus:ring-2 focus:ring-admin_primary-default
			focus:border-transparent text-sm focus:text-base"
		/>
	</div>
);

const arrayField = ({
	label, value, control, field, register, activeRefines
}) => (
	<div className="flex flex-col">
		<label htmlFor={`${field}`} className="uppercase text-medium mr-2">{label}:</label>
		<ArrayFieldEditor
			control={control}
			field={field}
			register={register}
			activeRefines={activeRefines}
		/>
	</div>
);

const numberField = ({
	label, value, field, register
}) => (
	<div className="flex flex-col">
		<label htmlFor={`${label}`} className="uppercase text-medium mr-2">{label}:</label>
		<input
			{...register(`${field}`)}
			defaultValue={field.value}
			type="number"
			className="pl-2 py-1 border text-black focus:outline-none rounded-lg focus:ring-2
			focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"
			onChange={(e) => field.onChange(transform.output(e))} value={transform.input(field.value)} />
	</div>
);

const booleanField = ({
	label, value, field, register, control
}) => (
	<div className="flex flex-row items-center">
		<label htmlFor={`${label}`} className="uppercase text-medium mr-2">{label}:</label>
		<span>
			<SimpleSelectFieldEditor
				field={field}
				control={control}
				options={currentFieldOptions(field)}
				styles={{
					control: (base) => ({
						...base,
						border: "1px solid lightgray", // default border color
						"&:hover": { borderColor: "gray" }, // border style on hover
						boxShadow: "none",
						margin: "0.5rem 0",
						borderRadius: "0.5rem"
					})
				}}
			/>
		</span>
	</div>
);

const objectFieldBuilder = ({
	entry, field, control, register, remove, activeRefines
}) => {
	const [label, value] = entry;

	const type = typeSelector(entry, activeRefines);

	switch (type) {
		case "string":
			return stringField({
				label, value, field, register
			});

		case "array":
			return arrayField({
				label, value, control, field, register, activeRefines
			});

		case "number":
			return numberField({
				label, value, field, register
			});

		case "boolean":
			return booleanField({
				label, value, field, register, control
			});

		case "select":
			return booleanField({
				label, value, field, register, control
			});

		default:
			break;
	}
};

const ObjectEditor = ({
	control, field: formField = "", register, data, activeRefines, requiredFields = []
}) => {
	const objEntries = Object.entries(data);

	// NB: never register a field in input
	return (
		<ul className="w-max space-y-2 my-2">
			{objEntries.map((entry, index) => (
				<li key={`list-${nanoid()}-${index}`} className="flex justify-between items-center rounded">
					{objectFieldBuilder({
						entry, control, activeRefines, register, field: `${formField}.${entry[0]}`
					})}
				</li>)
			)}
		</ul>
	);
};

ObjectEditor.propTypes = {
	control: PropTypes.object,
	field: PropTypes.string,
	register: PropTypes.func,
	data: PropTypes.any,
	requiredFields: PropTypes.array,
	activeRefines: PropTypes.object
};

export default ObjectEditor;
