import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import SpeedIcon from "@mui/icons-material/Speed";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import BusinessIcon from "@mui/icons-material/Business";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PinDropIcon from "@mui/icons-material/PinDrop";
import ProgressBarFlat from "../graphs/ProgressBarFlat";
import { appDefinition } from "../../apps/configs/SupplyRConfig";

import IF from "../../utils/IF";

function SupplyRTreeTooltipSupplier({
	appSlice, companiesInfo, width = 182, transparent = false
}) {
	// Load data from the store
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	// Internationalization hook
	const { t, i18n } = useTranslation(appDefinition.locales);

	// Load the store hook
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);

	const arrProducts = Array.isArray(companiesInfo?.product) ? companiesInfo?.product : [companiesInfo?.product];

	const handleRedirect = () => {
		const url = "/supplyr";
		// put the params in the localstorage so we could get them in Supply-R app for refining.
		window.localStorage.setItem("ataglanceURLparams", JSON.stringify({
			projectname: activeRefines?.projectName[0],
			supplier: companiesInfo?.supplier,
			siteId: companiesInfo.siteId
		}));
		navigate(url);
	};

	return (
		<div className={`relative border-red-400 flex flex-col items-center
		gap-y-1.5 rounded-[10px] px-6 py-2.5 ${ !transparent && "bg-white"} text-black` }
		>

			<div id="company" className={`flex ${companiesInfo?.supplierCode ? "items-center" : ""} gap-x-2 w-full text-xl`} >
				<BusinessIcon />
				<div className="flex flex-col w-full" >
					<p className={` ${companiesInfo.supplierCode ? "leading-normal" : "leading-6"} line-clamp-2 font-semibold`}>
						<span className="line-clamp-1">
							{companiesInfo.supplier}
						</span>
						<span className="text-base line-clamp-1">
							{companiesInfo?.supplierCode ?? ""}
						</span>
					</p>
				</div>
			</div>

			<div className={`flex ${companiesInfo?.supplierCode ? "items-center" : ""} w-full gap-x-2.5`}>
				<MapsHomeWorkIcon className="text-sm"/>

				<p className="text-sm line-clamp-3" >
					<span className="line-clamp-2">
						{companiesInfo.auditedCompany}
					</span>
					<span className="text-sm line-clamp-1">
						{companiesInfo?.factoryCode ?? ""}
					</span>
				</p>
			</div>

			{!activeRefines?.supplyAudits && arrProducts && <div id="company-product" className="flex items-center gap-x-1 text-base w-full">
				<div className="h-4 w-4 rounded-full mr-2
                bg-black text-white text-xs font-bold leading-none
                flex items-center justify-center">
					<span>P</span>
				</div>
				<div className="flex">
					{arrProducts?.map((product, i) => {
						const productName = i18n.exists(`${appDefinition.locales}:product.${product}`) ?
							t(`product.${product}`)
							: (product || companiesInfo.scope || "");
						const sep = (i >= 1) ? ", " : "";
						return <p key={`product ${ i}`}>{sep}{productName}</p>;
					})}
				</div>
			</div>}

			<div id="location" className="flex border items-center gap-x-2 px-2 py-1 border-gray-200 rounded-md ">
				<PinDropIcon />
				<p className="text-sm leading-4 line-clamp-1 font-bold" style={{ maxWidth: width - 25 }}>
					{activeRefines?.supplyAudits ? companiesInfo.location : `${companiesInfo.city} - ${companiesInfo.country}`}
				</p>
			</div>

			<div className="flex flex-col items-center text-xl gap-y-1 justify-center w-full cursor-default m-4">
				{
					companiesInfo && companiesInfo?.resilience && companiesInfo?.resilience !== 0
						? <>
							<div className="flex justify-between items-center gap-x-2"
								title={t("Info.content.resilienceTitle")}>

								<SpeedIcon fontSize="inherit" />

								<ProgressBarFlat
									endPoint ={(companiesInfo?.resilience?.toFixed(0)) || "na"}
									width={width / 2.5}
									height={14}
									strokeColor="#5862B6"
									strokeColorEnd="#6373F6"
									showPercentage={true}
									fontSize={14}
									fontWeight={600}
									duration="0.75s"
								/>
							</div>
							<div className={`flex justify-between items-center gap-x-2 ${activeRefines?.supplyAudits && "hidden"}`}
								title={t("Info.content.resistanceTitle")}>

								<VerticalAlignCenterIcon fontSize="inherit" />

								<ProgressBarFlat
									endPoint ={(companiesInfo?.resistance?.toFixed(0)) || "na"}
									width={width / 2.5}
									height={14}
									strokeColor="#5862B6"
									strokeColorEnd="#6373F6"
									showPercentage={true}
									fontSize={14}
									duration="1.05s"
								/>
							</div>
							<div className={`flex justify-between items-center gap-x-2 ${activeRefines?.supplyAudits && "hidden"}`}
								title={t("Info.content.responsivenessTitle")}>

								<TimelapseIcon fontSize="inherit"/>

								<ProgressBarFlat
									endPoint ={(companiesInfo?.responsiveness?.toFixed(0)) || "na"}
									width={width / 2.5}
									height={14}
									strokeColor="#5862B6"
									strokeColorEnd="#6373F6"
									showPercentage={true}
									fontSize={14}
									duration="1.35s"
								/>
							</div>

						</>
						: <p className="text-center text-sm font-bold mt-2" >
							{t("DetailCard.notAudited")}
						</p>
				}
			</div>

			<IF condition={companiesInfo
            && companiesInfo?.resilience
           && companiesInfo?.resilience !== 0}>
				<div className="flex items-center justify-center gap-x-2">
					<div className="self-center flex rounded-lg text-sm bg-supplyr_primary-menu text-white px-3 py-2 cursor-pointer"
						onClick={() => {
							if (menu === "MAP") {
								handleRedirect();
							} else {
								dispatch(appSlice.actions.setPage("SITES"));
								dispatch(appSlice.actions.setSubPage("SITE"));
								dispatch(appSlice.actions.refine([
									{ key: "supplier", value: companiesInfo?.supplier },
									{ key: "siteId", value: companiesInfo?.siteId }
								]));
							}
						}
						}>
						{t("tooltip.sitePage")}
					</div>
					{!clientParameters?.noCompare
					&& <div className="self-center flex rounded-lg text-sm bg-supplyr_primary-menu text-white px-3 py-2 cursor-pointer"
						onClick={() => {
							dispatch(appSlice.actions.setPage("SITES"));
							dispatch(appSlice.actions.setSubPage("ALTERNATIVES"));
							dispatch(appSlice.actions.refine([
								{ key: "supplier", value: companiesInfo?.supplier },
								{ key: "siteId", value: companiesInfo?.siteId }
							]));
						}
						}>
						{t("tooltip.alternativesPage")}
					</div>}
				</div>
			</IF>
		</div>
	);
}

SupplyRTreeTooltipSupplier.propTypes = {
	appSlice: PropTypes.object,
	height: PropTypes.number,
	width: PropTypes.number,
	transparent: PropTypes.bool,
	companiesInfo: PropTypes.object
};

export default SupplyRTreeTooltipSupplier;
