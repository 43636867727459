/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, {
	useMemo, forwardRef, useState
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
	useAsyncDebounce,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable
} from "react-table";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownUp from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";

import { Pagination } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import HelpIcon from "@mui/icons-material/Help";
import EditIcon from "@mui/icons-material/Edit";

import * as XLSX from "xlsx";
import CreatFormBtnGroup from "../button/CreatFormBtnGroup";

import exportToExcel from "../../utils/ExportToExcel";

import NoDataAvailable from "../../pages/NoDataAvailable";
import AlphaTooltip from "../tooltips/AlphaTooltip";

import { deleteManyDocs, updateProject } from "../../data/slices/createAdminSlice";
import { executeAdminPipeline, clarityV4ReferentialDeleteEntry } from "../form/AdminFormOnConfirm";
import Popconfirm from "../modal/Popconfirm";
import { formatClarityV4Referential } from "../../apps/configs/AdminConfig";
import UploadFile from "../form/UploadFile";

// Define a default UI for filtering
const GlobalFilter = ({
	placeHolder,
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter
}) => {
	const count = preGlobalFilteredRows.length;

	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="flex justify-between items-center bg-gray-100 py-2 px-2 md:px-6 space-x-4 rounded-xl ">
			<input
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={placeHolder}
				className="bg-transparent outline-none "
			/>
			<SearchIcon />
		</div>
	);
};

GlobalFilter.propTypes = {
	placeHolder: PropTypes.string,
	preGlobalFilteredRows: PropTypes.array,
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
	const defaultRef = React.useRef();
	const resolvedRef = ref || defaultRef;

	React.useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<>
			<input type="checkbox" ref={resolvedRef} {...rest} />
		</>
	);
});

IndeterminateCheckbox.propTypes = {
	indeterminate: PropTypes.any
};

const ClarityV4ReferentialTable = ({
	appSlice,
	appDefinition,
	structureTable = undefined,
	sortByRefineFields = [],
	refineKeys,
	setAlert,
	isAlpha,
	onSuccessfulDelete
}) => {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	const [confirmModal, setconfirmModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState(undefined);
	const [isUploaded, setIsUploaded] = useState(false);
	const [isUploadedError, setIsUploadedError] = useState(false);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store of the App
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const data = useMemo(() => activeDatasets.clarityV4Referential?.data, [ activeDatasets, activeRefines]);

	const columns = useMemo(
		() => structureTable,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		rows,
		gotoPage,
		preGlobalFilteredRows,
		setGlobalFilter,
		selectedFlatRows,
		state: { pageIndex, pageSize, selectedRowIds }
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: 16,
				sortBy: sortByRefineFields
			}
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((col) => [
				{
					id: "selection",
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					Cell: ({ row }) => (
						<div className="flex items-center">
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					)
				},
				{
					id: "edit",
					Cell: ({ row }) => (
						<div className="flex items-center">
							<EditIcon
								fontSize="small"
								className="text-admin_primary-default cursor-pointer"
								onClick={() => {
									dispatch(appSlice.actions.refine(refineKeys(row)));
									dispatch(appSlice.actions.refine([
										{ key: "clarityV4Referential", value: "updateV4RefEntry" }]));
								}}
							/>
						</div>
					)
				},
				...col
			]);
		}
	);

	const generateTableToExport = () => {
		const sortedTable = new Array(rows.length);
		const headers = headerGroups[0]?.headers?.filter((e) => e.id !== "edit" && e.id !== "selection").map((e) => e.id);
		rows.forEach((row, i) => {
			const formattedRow = {};
			prepareRow(row);
			row.cells
			.filter((e) => headers.includes(e.column.Header))
			.forEach(
				(cell, j) => cell.column.Header === "updateDate"
					? (formattedRow[cell.column.Header] = new Date(cell?.value?.toString()))
					: (formattedRow[cell.column.Header] = cell?.value?.toString())
			);
			sortedTable[i] = formattedRow;
		});
		return sortedTable;
	};

	const onConfirmDelete = (selectedDeleteNodes) => {
		if (selectedDeleteNodes.length === 0) {
			window.confirm("Please select an entry to delete");
			return;
		}

		return clarityV4ReferentialDeleteEntry({
			appSlice,
			dispatch,
			deleteManyDocs,
			activeRefines,
			deleteNodes: selectedDeleteNodes,
			setAlert,
			setconfirmModal,
			onSuccessfulDelete
		});
	};

	if (data.length === 0) {
		return 	<NoDataAvailable appSlice={appSlice} locales={appDefinition.locales} refineKey={[{ key: "supplyChainAction", value: "createBranch" }]} />;
	}

	// selected node id in table
	const selectedDeleteNodes = selectedFlatRows
	.map((d) => d.original)
	.map((item) => item._id);

	return (
		<div className="flex flex-col text-sm space-y-4 max-x-full w-full overflow-x-auto">
			{
				confirmModal && (

					<Popconfirm
						title="Delete referential"
						description="Are you sure you want to delete this referential?"
						confirmBtnText="Confirm"
						onClickConfirm={() => { onConfirmDelete(selectedDeleteNodes); }}
						onClickCancel={() => setconfirmModal(false)}
					/>
				)
			}
			{isUploadedError && (
				<Popconfirm
					title="Something went wrong"
					description={"The file upload has failed, possibly due to insufficient permissions or an incompatible file format."}
					showConfirm={false}
					iconsType="exclamationMark"
					onClickCancel={() => {
						setIsUploadedError(false);
					}}
				/>
			)}
			<div className="self-center flex items-center w-full">
				<div className="flex flex-row items-center space-x-4">
					<CreatFormBtnGroup appSlice={appSlice} locales={appDefinition.locales} renderBack renderAdd={true}
						clearRefineKeys={[{ refine: "fourthLevelMenu" }, { refine: "appAction" },
							{ refine: "projectName" }, { refine: "spaceLabel" }]}
						refineKey={[{ key: "clarityV4Referential", value: "createV4RefEntry" }]}
						addText="addEntry" deleteText="deleteEntry" renderDelete={true}
						onDelete={() => { setconfirmModal(true); }}
						renderValidate={false}
					/>

					<GlobalFilter
						placeHolder={"Search for ..."}
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
				</div>

				<div className="flex space-x-2 items-center ml-4">
					<p className="hidden md:block">
						{rows.length} Results
					</p>
					{/* download files */}
					<button
						className={`text-admin_primary-accent rounded-xl border border-gray-100 
                                    shadow-sm p-1 bg-white hover:border-gray-200 cursor-pointer`}
						title="Download data in Excel format"
						onClick={(e) => exportToExcel(
							generateTableToExport(),
							`ClarityV4Referential Export ${new Date().toISOString().split("T")[0]}`
						)}
					>
						<DownloadIcon className="text-admin_primary-default" />
					</button>

					{/* upload file */}
					<>
						<UploadFile
							appSlice={appSlice}
							appDefinition={appDefinition}
							appName={"admin"}
							collection={formatClarityV4Referential.sourceCollection}
							isIcon={true}
							refineKeys={{
								onSuccess: [
									{ key: "fourthLevelMenu", value: "V4 Referential" },
									{ key: "appAction", value: "readClarityV4Referential" }
								]
							}}
							onSuccess={() => {
								executeAdminPipeline({
									appSlice,
									activeRefines,
									dispatch,
									updateProject,
									sourceCollection: formatClarityV4Referential.sourceCollection,
									targetCollection: formatClarityV4Referential.targetCollection,
									query: formatClarityV4Referential.query
								});
							}}
							numbersToStrings
						/>
						<AlphaTooltip placement="top-start"
							title={
								<div className={"flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90 bg-admin_primary-default rounded-2xl w-48"}>
									<span className="font-bold">{t("tooltip.uploadFile.title")}</span>
									{t("tooltip.uploadFile.description")}
								</div>
							}
						>
							<HelpIcon fontSize="small" className={" text-admin_primary-default "} />
						</AlphaTooltip>
					</>
				</div>
			</div>
			<table {...getTableProps()} className="w-full">
				<thead>
					{headerGroups.map((headerGroup, i) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							key={`headerGroup${i}`}
						>
							{headerGroup.headers.map((column, j) => (
								<th
									key={`header${j}`}
									{...column.getHeaderProps(column.getSortByToggleProps())}
									className="first:pl-6 last:pr-6 text-left font-medium p-2 max-w-xs"
								>
									<div className="flex items-center space-x-2">
										{column.render("Header")}
										<span>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<KeyboardArrowDownUp fontSize="inherit" />
												) : (
													<KeyboardArrowDownIcon fontSize="inherit" />
												)
											) : (
												""
											)}
										</span>
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, k) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps()}
								key={`row${k}`}
								className={`hover:bg-opacity-50 h-5
								${row?.values?.validated === "False"
									? "odd:bg-opacity-80 bg-risk-high"
									: row?.values?.validated === "On Hold"
										? " bg-risk-low odd:bg-opacity-80"
										: "odd:bg-gray-100 hover:bg-admin_primary-default"}`}
							>
								{row.cells.map((cell, m) => (
									<td
										key={`cell${m}`}
										{...cell.getCellProps()}
										className="first:pl-6 last:pr-6 p-2 max-w-xs text-ellipsis"
									>
										{cell.render("Cell")}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			{rows.length > pageSize && (
				<Pagination
					page={pageIndex + 1}
					count={Math.floor(rows.length / pageSize + 1)}
					className="md:self-center px-4 md:px-6"
					onChange={async (event, value) => {
						const page = value ? Number(value) - 1 : 0;
						gotoPage(page);
					}}
				/>
			)}
		</div>
	);
};

ClarityV4ReferentialTable.propTypes = {
	appSlice: PropTypes.object,
	appDefinition: PropTypes.object,
	structureTable: PropTypes.array,
	sortByRefineFields: PropTypes.array,
	refineKeys: PropTypes.func,
	setAlert: PropTypes.func,
	isAlpha: PropTypes.bool,
	onSuccessfulDelete: PropTypes.func
};

export default ClarityV4ReferentialTable;
