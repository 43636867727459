import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import FilterMenu from "../filters/FilterMenu";

const PageMenuHeader = ({
	appSlice,
	Picto,
	themePalette,
	filterOption,
	customStylesSearch,
	bgClass = "bg-gradient-to-r from-supplyr_primary-gradientL via-supplyr_primary-gradientV to-supplyr_primary-gradientR",
	logo = undefined,
	locales
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();
	const appName = useSelector(appSlice.selectAppName);
	const activeModule = useSelector(appSlice.selectActiveRefines)?.module;
	const currentModules = useSelector(appSlice.selectDatasets)?.listModules?.data;

	return (
		<div className={`relative w-full h-72 overflow-hidden ${ bgClass}`}>

			<div className="absolute top-4 left-4 w-3/4 md:hidden z-20">
				<FilterMenu
					appSlice={appSlice}
					options={filterOption}
					customStyle={customStylesSearch}
					textClasses={`text-${themePalette}_primary-accent font-semibold`}
					className={"w-full "}
				/>
			</div>

			<div className="relative flex flex-col px-4 md:px-16 pt-4 md:pt-8 h-full w-full z-0">

				<div className="absolute -bottom-8 left-5 opacity-40 text-white" style={{ zIndex: -1 }}>
					<Picto style={{ fontSize: 240 }} />
				</div>

				<div className="flex justify-between items-center w-full">
					<div className="hidden md:flex items-center text-sm gap-x-3 leading-4 text-white ">
						<button className={`flex items-center text-center rounded-xl border border-white h-10 px-3 py-1
                         ${ activeModule === undefined && `text-${themePalette}_primary-accent bg-white`}`}
						onClick={() => dispatch(appSlice.actions.refine([{ key: "module", value: undefined }]))}>
                            All
						</button>
						{currentModules?.map((element, i) => <div key={`Module ${i}`} className="">
							<button className={`flex items-center text-center  rounded-xl border border-white h-10 px-3 py-1"
								${ activeModule === element.module && `text-${themePalette}_primary-accent bg-white`}`}
							onClick={() => dispatch(appSlice.actions.refine([{ key: "module", value: element.module }]))}>
								{t(`module.${element.module}`)}
							</button>
						</div>
						)}
					</div>

					{logo && <div className="hidden 2xl:flex flex-row gap-x-2 justify-center item-center">
						<div className="flex flex-col mt-6 ml-3">
							<p className="text-3xl text-white font-medium">{t(`Info.header.${appName}`)}</p>
							<p className="text-medium w-max text-white tracking-widest uppercase">{t("Info.header.bv")}</p>
						</div>
						<img src={logo} className="w-16 max-h-24 object-scale-down mt-2" />
					</div>
					}
				</div>
			</div>

		</div>
	);
};

PageMenuHeader.propTypes = {
	appSlice: PropTypes.object,
	Picto: PropTypes.object,
	themePalette: PropTypes.string,
	filterOption: PropTypes.object,
	customStylesSearch: PropTypes.func,
	bgClass: PropTypes.string,
	logo: PropTypes.string,
	locales: PropTypes.string
};

export default PageMenuHeader;
