import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PublicIcon from "@mui/icons-material/Public";

import IF from "../../utils/IF";
import getValueNestedObject from "../../utils/GetValueNestedObject";

function RYBSearchMenu({
	appSlice, loadOptions, placeHolder = "", labelSiteKey = "", valueSiteKey = "", labelCityKey = "",
	valueCityKey = "", labelCountryKey = "", valueCountryKey = "", rybColorMap, customStyle = null, className
}) {
	const [inputValue, setValue] = useState("");
	const [selectedValue, setSelectedValue] = useState(null);

	const dispatch = useDispatch();
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	useEffect(() => {
		if (activeRefines[valueSiteKey] && activeDatasets.listSites.data
            && activeDatasets.listSites.data.length === 1
            && getValueNestedObject(activeDatasets.listSites.data[0], valueSiteKey) === activeRefines[valueSiteKey]) {
			setSelectedValue(activeDatasets.listSites.data[0]);
		}
	}, [activeRefines[valueSiteKey], activeDatasets.listSites]);// eslint-disable-line react-hooks/exhaustive-deps

	// handle input change event
	const handleInputChange = (value) => {
		setValue(value);
	};

	// handle selection
	const handleChange = (value) => {
		setSelectedValue(value);

		if (value === null) {
			dispatch(appSlice.actions.refine([
				{ key: valueSiteKey, value: undefined },
				{ key: valueCityKey, value: undefined },
				{ key: valueCountryKey, value: undefined }]));
		} else if (value.type === "country") {
			dispatch(appSlice.actions.refine([{ key: valueCountryKey, value: { $regex: `^${value._id.country}$`, $options: "i" } }]));
		} else if (value.type === "city") {
			dispatch(appSlice.actions.refine([{ key: valueCityKey, value: { $regex: `^${value._id.city}$`, $options: "i" } }]));
		} else {
			dispatch(appSlice.actions.refine([{ key: valueSiteKey, value: getValueNestedObject(value, valueSiteKey) }]));
		}
	};

	// render method for the placeholder
	const renderPlaceholder = (
		<div className="flex items-center space-x-1 pt-1.5">
			<SearchIcon fontSize="small" className="text-gray-400" />
			<p className="hidden text-md text-gray-400 sm:inline">{placeHolder}</p>
		</div>
	);

	// render method for the list results
	const renderLocation = (site) => (
		<div className="flex items-center">
			<p className="flex items-center self-center rounded-sm bg-gray-400 text-white text-xs p-1 mr-2">
				<IF condition={site.type === "country"}><PublicIcon fontSize="inherit" /></IF>
				<IF condition={site.type === "city"}><LocationOnIcon fontSize="inherit" /></IF>
			</p>
			<p className="leading-4 font-normal">
				{getValueNestedObject(site, labelCountryKey)}
				<IF condition={site.type === "city"}>, {getValueNestedObject(site, labelCityKey)}</IF>
			</p>
		</div>
	);

	// render method for the list results (if no type location)
	const renderListResults = (site) => {
		const TagPicto = (site.type && site.type === "location") ?
			LocationOnIcon
			: rybColorMap[site.businessSegment] ?
				rybColorMap[site.businessSegment]?.picto
				: rybColorMap.Other.picto;

		return (
			<div className="flex items-center">
				<p className="flex items-center self-center rounded-full text-white text-xs p-1 mr-2"
					style={{ backgroundColor: rybColorMap[site.businessSegment]?.color }}>
					<TagPicto fontSize="inherit" />
				</p>
				<p className="leading-4 font-normal">
					{getValueNestedObject(site, labelSiteKey)}
				</p>
			</div>
		);
	};

	return (
		<AsyncSelect
			className = {className}
			defaultOptions={{}}
			styles={customStyle !== null ? customStyle(null, null) : null}
			value={selectedValue}
			placeholder={renderPlaceholder}
			components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
			getOptionLabel={(site) => (site.type && (site.type === "city" || site.type === "country")) ? renderLocation(site) : renderListResults(site)}
			getOptionValue={(site) => getValueNestedObject(site, valueSiteKey)}
			loadOptions={loadOptions}
			openMenuOnClick={false}
			onInputChange={handleInputChange}
			onChange={handleChange}
			isClearable={true}
		/>

	);
}

RYBSearchMenu.propTypes = {
	appSlice: PropTypes.object,
	loadOptions: PropTypes.func,
	placeHolder: PropTypes.string,
	labelSiteKey: PropTypes.string,
	valueSiteKey: PropTypes.string,
	labelCityKey: PropTypes.string,
	valueCityKey: PropTypes.string,
	labelCountryKey: PropTypes.string,
	valueCountryKey: PropTypes.string,
	rybColorMap: PropTypes.object,
	customStyle: PropTypes.func,
	className: PropTypes.string
};

export default RYBSearchMenu;
