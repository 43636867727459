import * as FileSaver from "file-saver";
import Excel from "exceljs";

const exportChangeReports = (fileName, rejectData, normLog, t) => {
	const formatData = (data, dateFields, fieldsToExclude) => data.map((node, i) => {
		const temp = {};
		const entries = Object.entries(node)?.filter((e) => !fieldsToExclude?.includes(e[0]) || e[0] !== fieldsToExclude);
		entries.forEach((e) => {
			if (dateFields.includes(e[0]) || dateFields === e[0]) e[1] = new Date(e[1]);
			// eslint-disable-next-line prefer-destructuring
			temp[t(`exports.${e[0]}`)] = e[1];
		});
		return temp;
	});

	const rejectDataRename = formatData(rejectData, "rejectDate");
	const normLogDataRename = formatData(normLog, "publicationDate");

	const createHeader = (data, [fieldsToExclude]) => {
		if (data?.length === 0) {
			return [];
		}

		const result = Object.keys(data[0]).map((el) => ({ header: el, key: el }));
		const filtered = result.filter((item) => !fieldsToExclude?.includes(item.header));
		return filtered;
	};

	const workbook = new Excel.Workbook();

	// Header is the top row with the columns titles
	const headerForRejectedPAQs = createHeader(rejectDataRename, []);
	const headerForNormLog = createHeader(normLogDataRename, ["exports._id"]);

	const rejectedPAQsSheet = workbook.addWorksheet("rejectedPAQs");
	const normalizationLogSheet = workbook.addWorksheet("normalizationLog");

	normalizationLogSheet.columns = headerForNormLog;
	rejectedPAQsSheet.columns = headerForRejectedPAQs;

	// fill the rows of the rejectedPAQs sheet
	rejectedPAQsSheet.addRows(rejectDataRename);

	// Fill the rows of the normalizationLogSheet sheet while checking for inconsistencies.
	// All the items in the array "normLogDataRename" have two versions - BEFORE and AFTER.
	// We find both versions by ID (ID is the same for the item with property BEFORE and the
	// item with property "AFTER" and compare them with each other.
	normLogDataRename.forEach((el) => {
		let incFound;
		let columnTitles = [];
		let pairs = normLogDataRename.filter((item) => (
			item?.["exports._id"]
			&& el?.["exports._id"]
			&& item["exports._id"] === el["exports._id"]
		));

		// compare pairs only if there are two elements present
		if (pairs?.length === 2) {
			// We do not need this field in xls file
			delete pairs[0]?.["exports._id"];
			delete pairs[1]?.["exports._id"];

			if (pairs[0]?.Address !== pairs[1]?.Address) {
				incFound = true;
				columnTitles.push("Address");
			}
			if (pairs[0]?.City !== pairs[1]?.City) {
				incFound = true;
				columnTitles.push("City");
			}
			if (pairs[0]?.Country !== pairs[1]?.Country) {
				incFound = true;
				columnTitles.push("Country");
			}
			if (pairs[0]?.Factory !== pairs[1]?.Factory) {
				incFound = true;
				columnTitles.push("Factory");
			} if (pairs[0]?.Supplier !== pairs[1]?.Supplier) {
				incFound = true;
				columnTitles.push("Supplier");
			} if (pairs[0]?.["Next Supplier"] !== pairs[1]?.["Next Supplier"]) {
				incFound = true;
				columnTitles.push("Next Supplier");
			} else {
				incFound = false;
			}
			const currentRow = normalizationLogSheet.addRow(pairs[0]);
			const nextRow = normalizationLogSheet.addRow(pairs[1]);

			pairs = [];

			const applyCellColor = (row, columnTitles, color) => {
				columnTitles?.forEach((title) => {
					const currentCellMatch = row._cells.find((item) => item._column._key === title);
					currentCellMatch.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: color }
					};
				});
			};

			applyCellColor(currentRow, columnTitles, "FFFF00"); // yellow
			applyCellColor(nextRow, columnTitles, "32CD32"); // green

			// reset columnTitles because we'll need new set of titles for the next row
			columnTitles = [];
		}
	});

	workbook.xlsx.writeBuffer().then((buffer) => FileSaver.saveAs(new Blob([buffer]), `${fileName}.xlsx`));
};

export default exportChangeReports;
