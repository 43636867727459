import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PinDropIcon from "@mui/icons-material/PinDrop";
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import HelpIcon from "@mui/icons-material/Help";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LegalNotice from "../../termsLocales/en/RYBLegalNotice";
import Terms from "../../termsLocales/en/PrivateAppTerms";
import Privacy from "../../termsLocales/en/RYBPrivacy";
import Cookies from "../../termsLocales/en/RYBCookies";

import VerkorSplash from "../../components/content/VerkorSplash";
import VerkorApi from "../../components/content/VerkorApi";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} slice Name of the slice related to the app created and references in the store ./store.js
 * @param {String} logo Label of the menu, if none, only the picto
 */
export const appDefinition = {
	name: "Supply-R",
	subName: "",
	launcherID: "VERKOR",
	slice: "verkor",
	locales: "translationsVerkor",
	theme: "supplyr",
	favicon: "/images/SupplyR/favicon.ico",
	logo: "/images/SupplyR/icon-menu-supply-r.svg",
	clientAccessor: "projectName"
};

/**
 * Set up  available language to display
 */
export const langListValue = [
	{ value: "en", label: "EN" },
	{ value: "fr", label: "FR" },
	{ value: "zh-Hans", label: "简体" },
	{ value: "zh-Hant", label: "繁體" }
];

/**
 * Set up  menu options to display
 *
 * @param {String} menu ID of the menu (use for the initial menu definition and the render of the app)
 * @param {String} type Route or App = Route is a link to jump to a new url, App is a internal link to the App to display specific information
 * @param {String} url [OPTIONAL] If type == url, the new url to link the menu (it has to be routed in ./App.js)
 * @param {String} label [OPTIONAL] Label of the menu, if none, only the picto
 * @param {String} sectionTitleWhite [OPTIONAL] Section title in white displayed on top of the page when the menu is clicked
 * @param {String} sectionTitleBlack [OPTIONAL] Section title in black displayed on top of the page when the menu is clicked
 * @param {Component} picto Material UI Icon to represent the field in the select, to be imported in this file
 */
export const menuOptions = [
	{
		menu: "HOME",
		type: "App",
		initial: true,
		label: "Home",
		clearRefine: [ "section1Label", "criticality", "auditStatus", "assignmentDelayed", "actionPlanNcStatus"],
		picto: AccountTreeIcon
	},
	{
		menu: "NCs",
		type: "App",
		initial: false,
		label: "Areas of concern",
		clearRefine: ["section1Label", "criticality", "auditStatus", "assignmentDelayed", "actionPlanNcStatus"],
		picto: FlagOutlinedIcon
	},
	{
		menu: "SITES",
		type: "App",
		initial: false,
		label: "My suppliers",
		clearRefine: ["supplier", "siteId", "section1Label", "criticality", "auditStatus", "assignmentDelayed", "actionPlanNcStatus"],
		picto: BusinessIcon
	},
	{
		menu: "SITES",
		type: "App",
		initial: false,
		label: "My Auditees",
		clearRefine: ["supplier", "siteId", "section1Label", "criticality", "auditStatus", "assignmentDelayed", "actionPlanNcStatus"],
		picto: BusinessIcon
	},
	{
		menu: "INFO",
		type: "App",
		label: "Info",
		picto: ErrorOutlineIcon
	}
];

/**
 * Name of the dataset with the client parameters
 */
const VerkorClientParameters = "VerkorClientParameters";

/**
 * Name of the dataset for the supplyR Public web app
 *
 * @param {String} VerkorChain Name of the Document DB collection for the supplyR Public web app
 */
const VerkorChain = "VerkorChainScores";

/**
 * Name of the dataset for the supplyR Public web app
 *
 * @param {String} VerkorChain Name of the Document DB collection for the supplyR Public web app
 */
const VerkorModules = "VerkorAssignmentScores";

/**
 * Name of the dataset for the supplyR Public web app
 *
 * @param {String} VerkorSections Name of the Document DB collection for the supplyR Public web app
 */
const VerkorSections = "VerkorSectionScores";

/**
 * Name of the dataset Checkpoint for the supplyR web app
 */
export const VerkorCheckpoints = "VerkorCheckpoints";

/**
 * Name of the dataset Sites for the Clarity web app
 */

const VerkorSites = "VerkorSiteScores";

/**
 * Query to load and update the non conformities
 */
export const listNonConformityQuery = [
	{
		$match: {
			last: true,
			nbOpenedNc: 1
		}
	}, {
		$project: {
			publicationDate: 1,
			question: 1,
			answer: "$auditorResult",
			supplierResult: 1,
			supplierComment: 1,
			auditorResult: 1,
			auditorComment: 1,
			siteId: 1,
			siteType: "$supplierType",
			supplier: 1,
			auditedCompany: 1,
			critical: 1,
			criticality: 1,
			product: 1,
			country: 1,
			city: "$site.city",
			domain: "$module",
			section1Label: 1,
			assignmentTechnicalid: 1,
			actionPlanItem: 1,
			actionPlanned: 1,
			actionPlanNcStatus: 1,
			actionPlanItemTargetDate: 1,
			actionPlanTargetDate: 1,
			criticalityLevel: 1,
			areaName: 1,
			areaNumber: 1,
			areaOwner: 1,
			siteName: 1,
			siteNumber: 1,
			siteSegmentation: 1,
			location: 1
		}
	}
];

/**
 * Arry of the full list of facets to refine
 */
export const listFacetsToRefine = ["projectName", "module", "tierLevel", "type", "finalProduct",
	"product", "city", "region", "country", "supplier", "siteId", "supplierCriticality", "areaOwner", "areaName", "location"];

/**
 * Client parameters
 */
export const clientInitParameters = {
	name: "clientParameters",
	collection: VerkorClientParameters,
	facetsToRefine: ["projectName"],
	fetch: "find",
	limit: 1
};

/**
 * Function to refresh the list of nonConformities after a click on a new page
 *
 * @param {Number} value Page to refresh the list of sites
 */
export const updateListNonConformities = (dataset, limit, value) => ({
	name: dataset,
	collection: VerkorCheckpoints,
	fetch: "aggregation",
	facetsToRefine: listFacetsToRefine.concat("criticality", "checkpointAnswer", "actionPlanItemDelayed", "criticalityLevel", "actionPlanNcStatus"),
	query: listNonConformityQuery,
	limit,
	skip: ((value - 1) * limit)
});

/**
  * Active DataSets definition for the app
  *
  * @param {String} name
  * @param {String} collection
  * @param {String} fetch
  * @param {String[]} facetsToRefine
  * @param {Object[]} query
  * @param {Number} limit
  * @param {Object[]} data
  */
export const verkorInitDatasets = {
	clientParameters: {
		name: "clientParameters",
		collection: VerkorClientParameters,
		facetsToRefine: ["projectName"],
		fetch: "find",
		limit: 1
	},
	getActionPlan: {
		collection: VerkorModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$lookup: {
					from: "maia_action_plan",
					localField: "_id.assignmentId",
					foreignField: "assignmentId",
					as: "actionPlan"
				}
			}, {
				$match: {
					actionPlan: {
						$ne: null
					},
					last: true
				}
			}, {
				$addFields: {
					actionPlan: {
						$arrayElemAt: [
							"$actionPlan", 0
						]
					}
				}
			}, {
				$match: {
					"actionPlan.actionPlanId": {
						$ne: null
					}
				}
			}, {
				$project: {
					project: 1,
					actionPlanTargetDate: 1,
					spaceId: {
						$substr: [
							"$spaceId", 0, -1
						]
					},
					actionPlanId: {
						$substr: [
							"$actionPlan.actionPlanId", 0, -1
						]
					},
					assignmentId: {
						$substr: [
							"$_id.assignmentId", 0, -1
						]
					},
					urlPrefix: "https://maiaplus.bureauveritas.com/browse/actionplan/detail/"
				}
			}, {
				$project: {
					project: 1,
					spaceId: 1,
					actionPlanTargetDate: 1,
					actionPlanId: "$actionPlanId",
					assignmentId: "$assignmentId",
					urlActionPlan: {
						$concat: [
							"$urlPrefix", "$project", "/", "$spaceId", "/", "$actionPlanId", "/", "$assignmentId"
						]
					}
				}
			}
		]
	},
	listProjects: {
		collection: VerkorClientParameters,
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						projectName: "$projectName"
					},
					projectLabel: { $first: "$projectLabel" },
					nbProjectName: { $sum: 1 }
				}
			},
			{
				$project: {
					projectName: "$_id.projectName",
					projectLabel: 1,
					nbProjectName: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listModules: {
		collection: VerkorModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "module"),
		query: [
			{
				$group: {
					_id: {
						module: "$module"
					},
					nbAssignements: { $sum: 1 }
				}
			},
			{
				$project: {
					module: "$_id.module",
					nbAssignements: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listTiers: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "tierLevel"),
		query: [
			{
				$group: {
					_id: {
						tierLevel: "$tierLevel"
					},
					tierLabel: { $first: "$tierLabel" },
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					tierLevel: "$_id.tierLevel",
					tierLabel: "$tierLabel",
					nbSites: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listContractSegmentations: {
		collection: VerkorModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "contractSegmentation"),
		query: [
			{
				$group: {
					_id: "",
					contractSegmentation: {
						$addToSet: "$contractSegmentation"
					}
				}
			}, {
				$addFields: {
					contractSegmentation: {
						$filter: {
							input: "$contractSegmentation",
							as: "e",
							cond: {
								$ne: [
									"$$e", null
								]
							}
						}
					}
				}
			}, {
				$unwind: {
					path: "$contractSegmentation"
				}
			}, {
				$project: {
					_id: 0,
					contractSegmentation: 1
				}
			}
		]
	},
	listTypes: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "type"),
		query: [
			{
				$group: {
					_id: {
						type: "$type"
					},
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					type: "$_id.type",
					nbSites: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listProducts: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "product" && element !== "finalProduct"),
		query: [{
			$project: {
				"option.label": "$product",
				"option.value": "$product",
				finalProduct: "$finalProduct"
			}
		}, {
			$group: {
				_id: "$finalProduct",
				options: { $addToSet: "$option" }
			}
		}, {
			$addFields: {
				label: "$_id",
				value: "$_id",
				refine: "finalProduct"
			}
		}],
		limit: 500
	},
	listGeographies: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "country" &&
        element !== "region"),
		query: [{
			$project: {
				"option.label": "$country",
				"option.value": "$country",
				region: "$region"
			}
		}, {
			$group: {
				_id: "$region",
				options: {
					$addToSet: "$option"
				}
			}
		}, {
			$addFields: {
				label: "$_id",
				value: "$_id",
				refine: "region"
			}
		}, {
			$match: {
				_id: {
					$ne: "undefined"
				}
			}
		}
		],
		limit: 500
	},
	listAreas: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$project: {
					"option.label": "$areaName",
					"option.value": "$areaName",
					areaOwner: "$areaOwner"
				}
			}, {
				$group: {
					_id: "$areaOwner",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					refine: "areaOwner"
				}
			}, {
				$match: {
					_id: {
						$ne: "undefined"
					}
				}
			}
		]
	},
	listPlots: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "plot"),
		query: [
			{
				$group: {
					_id: "",
					plot: {
						$addToSet: "$plot"
					}
				}
			}, {
				$addFields: {
					plot: {
						$filter: {
							input: "$plot",
							as: "e",
							cond: {
								$not: [
									{
										$in: [
											"$$e", [
												"", null, undefined
											]
										]
									}
								]
							}
						}
					}
				}
			}, {
				$unwind: {
					path: "$plot"
				}
			}, {
				$project: {
					plot: "$plot",
					_id: 0
				}
			}
		],
		limit: 500
	},
	listEntities: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "supplier" &&
        element !== "siteId"),
		query: [
			{
				$project: {
					"option.label": "$auditedCompany",
					"option.value": "$siteId",
					supplier: "$supplier"
				}
			}, {
				$group: {
					_id: "$supplier",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					refine: "supplier"
				}
			}, {
				$match: {
					_id: {
						$ne: "undefined"
					}
				}
			}
		],
		limit: 500
	},
	listSections: {
		collection: VerkorSections,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "section1Label"),
		query: [{
			$project: {
				"option.index": "$sectionIndex",
				"option.label": "$section1Label",
				"option.value": "$section1Label",
				module: "$module"
			}
		}, {
			$group: {
				_id: "$module",
				options: { $addToSet: "$option" }
			}
		}, {
			$addFields: {
				label: "$_id",
				value: "$_id",
				refine: "module"
			}
		}],
		limit: 500
	},
	listCriticalities: {
		collection: VerkorCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "criticality"),
		query: [
			{
				$match: { last: true }
			},
			{
				$group: {
					_id: {
						criticality: "$criticality"
					},
					nbCheckpoints: { $sum: 1 }
				}
			},
			{
				$project: {
					criticality: "$_id.criticality",
					nbCheckpoints: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listCriticalityLevels: {
		collection: VerkorCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "criticalityLevel")
		.concat(["section1Label", "checkpointAnswer", "actionPlanItemDelayed",
			"criticality", "actionPlanNcStatus"]),
		query: [
			{
				$group: {
					_id: "",
					criticalityLevel: {
						$addToSet: "$criticalityLevel"
					}
				}
			}, {
				$unwind: {
					path: "$criticalityLevel"
				}
			}, {
				$project: {
					_id: 0,
					criticalityLevel: 1,
					weight: {
						$cond: [
							{
								$eq: [
									"$criticalityLevel", "High"
								]
							}, 100, {
								$cond: [
									{
										$eq: [
											"$criticalityLevel", "Medium"
										]
									}, 10, {
										$cond: [
											{
												$eq: [
													"$criticalityLevel", "Low"
												]
											}, 1, 0
										]
									}
								]
							}
						]
					}
				}
			}, {
				$sort: {
					weight: -1
				}
			}
		],
		limit: 500
	},
	listSupplierCriticalities: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "supplierCriticality"),
		query: [
			{
				$group: {
					_id: {
						supplierCriticality: "$supplierCriticality"
					}
				}
			},
			{
				$project: {
					supplierCriticality: "$_id.supplierCriticality",
					_id: 0
				}
			}
		],
		limit: 500
	},
	listGradingsLabels: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: "$gradingsLabel"
				}
			}, {
				$project: {
					gradingsLabel: "$_id",
					_id: 0
				}
			}
		],
		limit: 500
	},
	listNCStatus: {
		collection: VerkorCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "actionPlanNcStatus"),
		query: [
			{
				$match: {
					actionPlanNcStatus: {
						$nin: [
							null, undefined
						]
					}
				}
			}, {
				$group: {
					_id: "$actionPlanNcStatus"
				}
			}, {
				$project: {
					_id: 0,
					actionPlanNcStatus: "$_id",
					label: {
						$cond: [
							{
								$eq: [
									"$_id", "1"
								]
							}, "Open", {
								$cond: [
									{
										$eq: [
											"$_id", "2"
										]
									}, "Closed", {
										$cond: [
											{
												$eq: [
													"$_id", "3"
												]
											}, "Submitted", "NA"
										]
									}
								]
							}
						]
					}
				}
			}
		]
	},
	listCheckpointAnswer: {
		collection: VerkorCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "checkpointAnswer"),
		query: [
			{
				$match: {
					last: true,
					nbOpenedNc: 1
				}
			}, {
				$group: {
					_id: "$checkpointAnswer"
				}
			}, {
				$project: {
					checkpointAnswer: "$_id",
					_id: 0
				}
			}
		]
	},
	listAuditStatus: {
		collection: "VerkorAuditStatus",
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						state: "$state",
						auditStatus: "$auditStatus"
					},
					nbStates: {
						$sum: 1
					}
				}
			}, {
				$project: {
					state: "$_id.state",
					auditStatus: "$_id.auditStatus",
					nbStates: 1,
					_id: 0
				}
			}
		]
	},
	listAssignmentDelayed: {
		collection: "VerkorChainScoresWithDateStatus",
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						assignmentDelayed: "$assignmentDelayed"
					},
					nbAssignmentDelayed: {
						$sum: 1
					}
				}
			}, {
				$project: {
					assignmentDelayed: "$_id.assignmentDelayed",
					nbStates: 1,
					_id: 0
				}
			}, {
				$addFields: {
					assignmentDelayed: {
						$cond: [
							{
								$eq: [
									"$assignmentDelayed", "undefined"
								]
							}, "undefined", {
								$cond: [
									{
										$eq: [
											"$assignmentDelayed", "on time"
										]
									}, "on time", "delayed"
								]
							}
						]
					},
					assignmentDelayedToStr: {
						$cond: [
							{
								$eq: [
									"$assignmentDelayed", "undefined"
								]
							}, "Undefined", {
								$cond: [
									{
										$eq: [
											"$assignmentDelayed", "on time"
										]
									}, "On time", "Delayed"
								]
							}
						]
					}
				}
			}
		]
	},
	listActionPlanItemDelayedList: {
		collection: "VerkorCheckpoints",
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						actionPlanItemDelayed: "$actionPlanItemDelayed"
					},
					nbActionPlanItemDelayed: {
						$sum: 1
					}
				}
			}, {
				$project: {
					actionPlanItemDelayed: "$_id.actionPlanItemDelayed",
					nbStates: 1,
					_id: 0
				}
			}, {
				$addFields: {
					actionPlanItemDelayed: {
						$cond: [
							{
								$eq: [
									"$actionPlanItemDelayed", "on time"
								]
							}, "on time", "delayed"
						]
					},
					actionPlanItemDelayedStr: {
						$cond: [
							{
								$eq: [
									"$actionPlanItemDelayed", "on time"
								]
							}, "On time", "Delayed"
						]
					}
				}
			}
		]
	},
	listLocations: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: "",
					locations: {
						$addToSet: "$location"
					}
				}
			}, {
				$addFields: {
					locations: {
						$filter: {
							input: "$locations",
							cond: {
								$and: [
									{
										$ne: [
											"$$this", ""
										]
									}, {
										$ne: [
											"$$this", null
										]
									}, {
										$ne: [
											"$$this", undefined
										]
									}
								]
							}
						}
					}
				}
			}, {
				$unwind: {
					path: "$locations"
				}
			}, {
				$project: {
					_id: 0,
					location: "$locations"
				}
			}
		]

	},
	supplyChain: {
		collection: VerkorChain,
		fetch: "find",
		facetsToRefine: ["projectName"],
		query: [],
		limit: 5000
	},
	mapSupplyChainSiteId: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						siteId: "$siteId"
					}
				}
			},
			{
				$project: {
					siteId: "$_id.siteId",
					_id: 0
				}
			}
		],
		limit: 5000
	},
	supplyChainActiveFinalProducts: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			},
			{
				$project: {
					finalProduct: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 500
	},
	overallResultScope: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: "",
					gradingsLabel: {
						$push: "$gradingsLabel"
					}
				}
			}, {
				$addFields: {
					totalAudits: {
						$size: "$gradingsLabel"
					}
				}
			}, {
				$unwind: {
					path: "$gradingsLabel"
				}
			}, {
				$group: {
					_id: "$gradingsLabel",
					frequency: {
						$sum: 1
					},
					totalAudits: {
						$first: "$totalAudits"
					}
				}
			}, {
				$project: {
					_id: 0,
					totalAudits: 1,
					frequency: 1,
					gradingsLabel: "$_id"
				}
			}
		]
	},
	actionPlanStatusDistribPerSites: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: "$siteId",
					siteId: {
						$first: "$siteId"
					},
					totalActionPlanNcStatus: {
						$first: "$totalActionPlanNcStatus"
					},
					actionPlanStatus: {
						$first: "$actionPlanStatus"
					}
				}
			}, {
				$addFields: {
					totalActionPlanNcStatus: {
						$ifNull: [
							"$totalActionPlanNcStatus", []
						]
					}
				}
			}, {
				$project: {
					_id: 0,
					siteId: "$siteId",
					totalActionPlanNcStatus: "$totalActionPlanNcStatus",
					totalNCs: {
						$size: "$totalActionPlanNcStatus"
					},
					actionPlanStatus: "$actionPlanStatus"
				}
			}, {
				$unwind: {
					path: "$totalActionPlanNcStatus"
				}
			}, {
				$group: {
					_id: {
						siteId: "$siteId",
						actionPlanId: "$assignmentId",
						ncStatus: "$totalActionPlanNcStatus"
					},
					totalNCs: {
						$first: "$totalNCs"
					},
					actionPlanStatus: {
						$first: "$actionPlanStatus"
					},
					count: {
						$sum: 1
					}
				}
			}, {
				$project: {
					_id: 0,
					siteId: "$_id.siteId",
					ncStatus: "$_id.ncStatus",
					frequency: "$count",
					totalNCs: "$totalNCs"
				}
			}
		]
	},
	actionPlanDelayCount: {
		collection: VerkorCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					actionPlanItem: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: "$supplier",
					totalItems: {
						$sum: 1
					},
					totalDelayed: {
						$sum: {
							$cond: [
								{
									$eq: [
										"$actionPlanItemDelayed", "delayed"
									]
								}, 1, 0
							]
						}
					}
				}
			}, {
				$project: {
					_id: 0,
					supplier: "$_id",
					totalItems: 1,
					totalDelayed: 1,
					delayRate: {
						$multiply: [
							{
								$divide: [
									"$totalDelayed", "$totalItems"
								]
							}, 100
						]
					}
				}
			}
		]
	},
	scores: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {},
					resilience: {
						$avg: "$maturity"
					},
					resistance: {
						$avg: "$businessContinuity"
					},
					responsiveness: {
						$avg: "$safetyRegulation"
					}
				}
			},
			{
				$project: {
					_id: 0,
					resilienceScore: "$resilience",
					resistanceScore: "$resistance",
					responsivenessScore: "$responsiveness"
				}
			}
		],
		limit: 500
	},
	scoresModule: {
		collection: VerkorModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: { last: true }
			},
			{
				$group: {
					_id: {},
					resilience: {
						$avg: "$maturity"
					},
					resistance: {
						$avg: "$businessContinuity"
					},
					responsiveness: {
						$avg: "$safetyRegulation"
					}
				}
			},
			{
				$addFields: {
					resilienceScore: "$resilience",
					resistanceScore: "$resistance",
					responsivenessScore: "$responsiveness"
				}
			}
		],
		limit: 500
	},
	modulesDistribution: {
		collection: VerkorModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: { last: true }
			},
			{
				$group: {
					_id: {
						module: "$module"
					},
					auditType: {
						$first: "$auditType"
					},
					auditMode: {
						$first: "$auditMode"
					},
					resilienceAvg: {
						$avg: "$maturity"
					},
					resistanceAvg: {
						$avg: "$businessContinuity"
					},
					responsivenessAvg: {
						$avg: "$safetyRegulation"
					},
					reports: { $addToSet: "$assignmentTechnicalId" }
				}
			},
			{
				$project: {
					key: "module",
					module: "$_id.module",
					auditType: 1,
					auditMode: 1,
					reports: 1,
					value: "$resilienceAvg",
					resistance: "$resistanceAvg",
					responsiveness: "$responsivenessAvg",
					_id: 0
				}
			}
		],
		limit: 500
	},
	sectionsDistribution: {
		collection: VerkorSections,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$addFields: {
					resilience: {
						$cond: [
							{
								$eq: [
									"$scoringBySection", true
								]
							}, {
								$multiply: [
									{
										$divide: [
											"$score", {
												$multiply: [
													"$totalQuestion", "$scoringWeight", 10
												]
											}
										]
									}, 100
								]
							}, {
								$multiply: [
									{
										$divide: [
											"$score", "$maxScore"
										]
									}, 100
								]
							}
						]
					}
				}
			}, {
				$sort: {
					sectionIndex: 1
				}
			}, {
				$group: {
					_id: {
						module: "$module",
						section: "$section1Label",
						sectionIndex: "$sectionIndex"
					},
					resilienceAvg: {
						$avg: "$resilience"
					}
				}
			}, {
				$project: {
					key: "section",
					section: "$_id.section",
					sectionIndex: "$_id.sectionIndex",
					module: "$_id.module",
					value: "$resilienceAvg",
					_id: 0
				}
			}
		],
		limit: 500
	},
	finalProductDistribution: {
		collection: VerkorModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: { last: true }
			},
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					},
					resilienceAvg: {
						$avg: "$maturity"
					}
				}
			},
			{
				$project: {
					key: "finalProduct",
					finalProduct: "$_id.finalProduct",
					value: "$resilienceAvg",
					_id: 0
				}
			}
		],
		limit: 500
	},
	finalProductBenchmark: {
		collection: VerkorModules,
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$match: { last: true }
			},
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					},
					resilienceAvg: {
						$avg: "$maturity"
					}
				}
			},
			{
				$project: {
					key: "finalProduct",
					finalProduct: "$_id.finalProduct",
					value: "$resilienceAvg",
					_id: 0
				}
			}
		],
		limit: 500
	},
	areaOwnersDistribution: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					maturity: {
						$exists: true
					}
				}
			}, {
				$group: {
					_id: {
						$ifNull: [
							"$areaOwner", "TBD"
						]
					},
					resilienceScore: {
						$avg: "$maturity"
					}
				}
			}, {
				$project: {
					areaOwner: "$_id",
					resilienceScore: 1,
					_id: 0
				}
			}
		]
	},
	sitesDistribution: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					maturity: { $exists: true }
				}
			},
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId",
						auditedCompany: "$auditedCompany"
					},
					resilienceScore: {
						$avg: "$maturity"
					}
				}
			},
			{
				$project: {
					key: "sites",
					supplier: "$_id.supplier",
					siteId: "$_id.siteId",
					auditedCompany: "$_id.auditedCompany",
					resilienceScore: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	sitesBenchmark: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$match: {
					maturity: { $exists: true }
				}
			},
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId",
						auditedCompany: "$auditedCompany"
					},
					resilienceScore: {
						$avg: "$maturity"
					}
				}
			},
			{
				$project: {
					key: "sites",
					supplier: "$_id.supplier",
					siteId: "$_id.siteId",
					auditedCompany: "$_id.auditedCompany",
					resilienceScore: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	criticalityDistribution: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						supplierCriticality: "$supplierCriticality"
					},
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					supplierCriticality: "$_id.supplierCriticality",
					frequency: "$nbSites",
					_id: 0
				}
			}
		],
		limit: 10
	},
	listNonConformities: {
		collection: VerkorCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["criticality", "section1Label", "checkpointAnswer", "actionPlanItemDelayed",
			"criticalityLevel", "actionPlanNcStatus"]),
		query: listNonConformityQuery,
		skip: 0,
		limit: 10
	},
	nonConformitiesMediumLowDistribution: {
		collection: VerkorCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["criticality", "section1Label", "checkpointAnswer", "actionPlanItemDelayed",
			"criticalityLevel", "actionPlanNcStatus"]),
		query: [
			{
				$match: {
					last: true,
					threeLevelNC: true
				}
			}, {
				$group: {
					_id: {
						year: {
							$year: "$publicationDate"
						},
						month: {
							$month: "$publicationDate"
						},
						siteId: "$siteId"
					},
					nbLowNC: {
						$sum: "$nbLowNC"
					},
					nbMediumNC: {
						$sum: "$nbMediumNC"
					},
					nbLowNCOpen: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "1"
											]
										}, {
											$eq: [
												"$nbLowNC", 1
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbMediumNCOpen: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "1"
											]
										}, {
											$eq: [
												"$nbMediumNC", 1
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbLowNCClosed: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "2"
											]
										}, {
											$eq: [
												"$nbLowNC", 1
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbMediumNCClosed: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "2"
											]
										}, {
											$eq: [
												"$nbMediumNC", 1
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbLowNCSubmitted: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "3"
											]
										}, {
											$eq: [
												"$nbLowNC", 1
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbMediumNCSubmitted: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "3"
											]
										}, {
											$eq: [
												"$nbMediumNC", 1
											]
										}
									]
								}, 1, 0
							]
						}
					}
				}
			}, {
				$group: {
					_id: {
						year: "$_id.year",
						month: "$_id.month"
					},
					sites: {
						$addToSet: "$_id.siteId"
					},
					nbSites: {
						$sum: 1
					},
					nbLowNC: {
						$sum: "$nbLowNC"
					},
					nbMediumNC: {
						$sum: "$nbMediumNC"
					},
					nbLowNCOpen: {
						$sum: "$nbLowNCOpen"
					},
					nbLowNCClosed: {
						$sum: "$nbLowNCClosed"
					},
					nbLowNCSubmitted: {
						$sum: "$nbLowNCSubmitted"
					},
					nbMediumNCOpen: {
						$sum: "$nbMediumNCOpen"
					},
					nbMediumNCClosed: {
						$sum: "$nbMediumNCClosed"
					},
					nbMediumNCSubmitted: {
						$sum: "$nbMediumNCSubmitted"
					}
				}
			}, {
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					sites: 1,
					nbSites: 1,
					nbLowNC: 1,
					nbMediumNC: 1,
					tooltipLowNCStatus: {
						nbLowNCOpen: "$nbLowNCOpen",
						nbLowNCSubmitted: "$nbLowNCSubmitted",
						nbLowNCClosed: "$nbLowNCClosed"
					},
					tooltipMediumNCStatus: {
						nbMediumNCOpen: "$nbMediumNCOpen",
						nbMediumNCSubmitted: "$nbMediumNCSubmitted",
						nbMediumNCClosed: "$nbMediumNCClosed"
					},
					_id: 0
				}
			}
		],
		skip: 0,
		limit: 10
	},
	nonConformities: {
		collection: VerkorCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["criticality", "section1Label", "checkpointAnswer", "actionPlanItemDelayed",
			"criticalityLevel", "actionPlanNcStatus"]),
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						year: {
							$year: "$publicationDate"
						},
						month: {
							$month: "$publicationDate"
						},
						siteId: "$siteId"
					},
					answer: {
						$addToSet: "$checkpointAnswer"
					},
					nbNC: {
						$sum: "$nbOpenedNc"
					},
					nbNonCriticalNC: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$nbOpenedNc", 1
											]
										}, {
											$eq: [
												"$critical", false
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNC: {
						$sum: "$nbCnc"
					},
					nbLowNC: {
						$sum: "$nbLowNC"
					},
					nbMediumNC: {
						$sum: "$nbMediumNC"
					},
					nbNCNoAP: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", false
											]
										}, {
											$eq: [
												"$actionPlanned", null
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNCNoAP: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", true
											]
										}, {
											$eq: [
												"$actionPlanned", null
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbSites: {
						$sum: 1
					},
					nbCNCOpen: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "1"
											]
										}, {
											$eq: [
												"$nbCnc", 1
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbNCOpen: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "1"
											]
										}, {
											$eq: [
												"$critical", false
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNCClosed: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "2"
											]
										}, {
											$eq: [
												"$nbCnc", 1
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbNCClosed: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "2"
											]
										}, {
											$eq: [
												"$critical", false
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNCSubmitted: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "3"
											]
										}, {
											$eq: [
												"$nbCnc", 1
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbNCSubmitted: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "3"
											]
										}, {
											$eq: [
												"$critical", false
											]
										}
									]
								}, 1, 0
							]
						}
					}
				}
			}, {
				$group: {
					_id: {
						year: "$_id.year",
						month: "$_id.month"
					},
					sites: {
						$addToSet: "$_id.siteId"
					},
					nbSites: {
						$sum: 1
					},
					nbNC: {
						$sum: "$nbNC"
					},
					nbCNC: {
						$sum: "$nbCNC"
					},
					nbNCOpen: {
						$sum: "$nbNCOpen"
					},
					nbNonCriticalNC: {
						$sum: "$nbNonCriticalNC"
					},
					nbLowNC: {
						$sum: "$nbLowNC"
					},
					nbMediumNC: {
						$sum: "$nbMediumNC"
					},
					nbCNCOpen: {
						$sum: "$nbCNCOpen"
					},
					nbNCSubmitted: {
						$sum: "$nbNCSubmitted"
					},
					nbCNCSubmitted: {
						$sum: "$nbCNCSubmitted"
					},
					nbNCClosed: {
						$sum: "$nbNCClosed"
					},
					nbCNCClosed: {
						$sum: "$nbCNCClosed"
					},
					nbCNCNoAP: {
						$sum: "$nbCNCNoAP"
					},
					nbNCNoAP: {
						$sum: "$nbNCNoAP"
					}
				}
			}, {
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					sites: 1,
					nbSites: 1,
					nbNC: 1,
					nbCNC: 1,
					nbNCOpen: 1,
					nbLowNC: 1,
					nbMediumNC: 1,
					tooltipNCStatus: {
						nbNCOpen: "$nbNCOpen",
						nbNCSubmitted: "$nbNCSubmitted",
						nbNCClosed: "$nbNCClosed",
						nbNCNoAP: "$nbNCNoAP"
					},
					tooltipCNCStatus: {
						nbCNCOpen: "$nbCNCOpen",
						nbCNCSubmitted: "$nbCNCSubmitted",
						nbCNCClosed: "$nbCNCClosed",
						nbCNCNoAP: "$nbCNCNoAP"
					},
					_id: 0
				}
			}
		],
		limit: 12
	},
	nonConformitiesByTargetDate: {
		collection: "VerkorCheckpointsBeforeTargetDate",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["criticality", "section1Label"]),
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						year: {
							$year: "$actionPlanItemTargetDate"
						},
						month: {
							$month: "$actionPlanItemTargetDate"
						},
						siteId: "$siteId"
					},
					answer: {
						$addToSet: "$checkpointAnswer"
					},
					nbNC: {
						$sum: "$nbOpenedNc"
					},
					nbNonCriticalNC: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$ne: [
												"$critical", true
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNC: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", true
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbNCNoAP: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", false
											]
										}, {
											$eq: [
												"$actionPlanned", null
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNCNoAP: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", true
											]
										}, {
											$eq: [
												"$actionPlanned", null
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbSites: {
						$sum: 1
					},
					nbCNCOpen: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "1"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", true
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbNCOpen: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "1"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", false
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNCClosed: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "2"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To Improve", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", true
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbNCClosed: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "2"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To Improve", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", false
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNCSubmitted: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "3"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To Improve", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", true
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbNCSubmitted: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "3"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To Improve", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", false
											]
										}
									]
								}, 1, 0
							]
						}
					}
				}
			}, {
				$group: {
					_id: {
						year: "$_id.year",
						month: "$_id.month"
					},
					sites: {
						$addToSet: "$_id.siteId"
					},
					nbSites: {
						$sum: 1
					},
					nbNC: {
						$sum: "$nbNC"
					},
					nbCNC: {
						$sum: "$nbCNC"
					},
					nbNCOpen: {
						$sum: "$nbNCOpen"
					},
					nbNonCriticalNC: {
						$sum: "$nbNonCriticalNC"
					},
					nbCNCOpen: {
						$sum: "$nbCNCOpen"
					},
					nbNCSubmitted: {
						$sum: "$nbNCSubmitted"
					},
					nbCNCSubmitted: {
						$sum: "$nbCNCSubmitted"
					},
					nbNCClosed: {
						$sum: "$nbNCClosed"
					},
					nbCNCClosed: {
						$sum: "$nbCNCClosed"
					},
					nbCNCNoAP: {
						$sum: "$nbCNCNoAP"
					},
					nbNCNoAP: {
						$sum: "$nbNCNoAP"
					}
				}
			}, {
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					sites: 1,
					nbSites: 1,
					nbNC: 1,
					nbCNC: 1,
					tooltipNCStatus: {
						nbNCOpen: "$nbNCOpen",
						nbNCSubmitted: "$nbNCSubmitted",
						nbNCClosed: "$nbNCClosed",
						nbNCNoAP: "$nbNCNoAP"
					},
					tooltipCNCStatus: {
						nbCNCOpen: "$nbCNCOpen",
						nbCNCSubmitted: "$nbCNCSubmitted",
						nbCNCClosed: "$nbCNCClosed",
						nbCNCNoAP: "$nbCNCNoAP"
					},
					_id: 0
				}
			}
		]
		// limit: 12
	},
	nonConformitiesByClosureDate: {
		collection: "VerkorCheckpointsBeforeClosureDate",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["criticality", "section1Label"]),
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						year: {
							$year: "$actionPlanItemClosureDate"
						},
						month: {
							$month: "$actionPlanItemClosureDate"
						},
						siteId: "$siteId"
					},
					answer: {
						$addToSet: "$checkpointAnswer"
					},
					nbNC: {
						$sum: "$nbOpenedNc"
					},
					nbNonCriticalNC: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$ne: [
												"$critical", true
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNC: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", true
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbNCNoAP: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", false
											]
										}, {
											$eq: [
												"$actionPlanned", null
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNCNoAP: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", true
											]
										}, {
											$eq: [
												"$actionPlanned", null
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbSites: {
						$sum: 1
					},
					nbCNCOpen: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "1"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", true
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbNCOpen: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "1"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", false
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNCClosed: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "2"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To Improve", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", true
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbNCClosed: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "2"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To Improve", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", false
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbCNCSubmitted: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "3"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To Improve", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", true
											]
										}
									]
								}, 1, 0
							]
						}
					},
					nbNCSubmitted: {
						$sum: {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$actionPlanNcStatus", "3"
											]
										}, {
											$in: [
												"$checkpointAnswer", [
													"NOK", "To Improve", "To improve"
												]
											]
										}, {
											$eq: [
												"$critical", false
											]
										}
									]
								}, 1, 0
							]
						}
					}
				}
			}, {
				$group: {
					_id: {
						year: "$_id.year",
						month: "$_id.month"
					},
					sites: {
						$addToSet: "$_id.siteId"
					},
					nbSites: {
						$sum: 1
					},
					nbNC: {
						$sum: "$nbNC"
					},
					nbCNC: {
						$sum: "$nbCNC"
					},
					nbNCOpen: {
						$sum: "$nbNCOpen"
					},
					nbNonCriticalNC: {
						$sum: "$nbNonCriticalNC"
					},
					nbCNCOpen: {
						$sum: "$nbCNCOpen"
					},
					nbNCSubmitted: {
						$sum: "$nbNCSubmitted"
					},
					nbCNCSubmitted: {
						$sum: "$nbCNCSubmitted"
					},
					nbNCClosed: {
						$sum: "$nbNCClosed"
					},
					nbCNCClosed: {
						$sum: "$nbCNCClosed"
					},
					nbCNCNoAP: {
						$sum: "$nbCNCNoAP"
					},
					nbNCNoAP: {
						$sum: "$nbNCNoAP"
					}
				}
			}, {
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					sites: 1,
					nbSites: 1,
					nbNC: 1,
					nbCNC: 1,
					tooltipNCStatus: {
						nbNCOpen: "$nbNCOpen",
						nbNCSubmitted: "$nbNCSubmitted",
						nbNCClosed: "$nbNCClosed",
						nbNCNoAP: "$nbNCNoAP"
					},
					tooltipCNCStatus: {
						nbCNCOpen: "$nbCNCOpen",
						nbCNCSubmitted: "$nbCNCSubmitted",
						nbCNCClosed: "$nbCNCClosed",
						nbCNCNoAP: "$nbCNCNoAP"
					}
				}
			}
		]
		// limit: 12
	},
	nonConformitiesByModules: {
		collection: VerkorCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["criticality", "section1Label", "criticalityLevel", "actionPlanNcStatus"]),
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						domain: "$module"
					},
					nbNC: { $sum: "$nbOpenedNc" },
					nbCNC: { $sum: "$nbCnc" }
				}
			}, {
				$project: {
					domain: "$_id.domain",
					nbNC: 1,
					nbCNC: 1,
					_id: 0
				}
			}
		],
		limit: 30
	},
	sectionComments: {
		collection: VerkorCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					sectionComment: {
						$ne: null
					},
					last: true
				}
			}, {
				$group: {
					_id: {
						siteId: "$siteId",
						assignmentId: "$assignmentId",
						section1Label: "$section1Label"
					},
					sectionComment: {
						$first: "$sectionComment"
					}
				}
			}, {
				$project: {
					siteId: "$_id.siteId",
					assignmentId: "$_id.assignmentId",
					section1Label: "$_id.section1Label",
					sectionComment: "$sectionComment",
					_id: 0
				}
			}, {
				$sort: {
					section1Label: 1
				}
			}
		]
	},
	overallComment: {
		collection: VerkorCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						siteId: "$siteId",
						assignmentId: "$assignmentId"
					},
					overallComment: {
						$first: "$overallComment"
					}
				}
			}, {
				$project: {
					siteId: "$_id.siteId",
					assignmentId: "$_id.assignmentId",
					overallComment: "$overallComment",
					_id: 0
				}
			}
		]
	},
	suppliers: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "siteId" && element !== "product"),
		query: [
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId"
					},
					siteId: { $first: "$siteId" },
					supplier: { $first: "$supplier" },
					product: { $addToSet: "$product" },
					module: { $addToSet: "$module" }
				}
			}
		],
		limit: 1000
	},
	sites: {
		collection: "VerkorChainScoresWithDateStatus",
		fetch: "aggregation",
		facetsToRefine: [...listFacetsToRefine, "assignmentDelayed"],
		facetsToTransform: { auditStatus: "state" },
		insertIndex: 1,
		transformLogic: "and",
		query: [],
		limit: 5000
	},
	allSites: {
		collection: VerkorChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId"
					},
					project: { $first: "$project" },
					spaceId: { $first: "$spaceId" },
					supplier: { $first: "$supplier" },
					siteId: { $first: "$siteId" },
					auditedCompany: { $first: "$auditedCompany" },
					address: { $first: "$address" },
					country: { $first: "$country" },
					city: { $first: "$city" },
					resilience: { $avg: "$maturity" },
					resistance: { $avg: "$businessContinuity" },
					responsiveness: { $avg: "$safetyRegulation" },
					sumNbOpenedNc: { $sum: "$sumNbOpenedNc" },
					product: { $addToSet: "$product" },
					module: { $addToSet: "$module" },
					resilienceScores: { $addToSet: "$resilienceScores" },
					report: { $addToSet: "$report" },
					geometry: { $first: "$geometry" },
					location: { $first: "$location" }
				}
			}
		],
		limit: 1000
	}
};

/**
 * Define the color code and name for non conformities
 */
export const nonConformities = { Critical: "#FF475C", Noncritical: "#FFC362" };
export const threeLvlNCs = { High: "#FF475C", Medium: "#FFC362", Low: "#CCDB73" };

export const nonConformitiesColorScale = [
	{ label: "high", color: "#FF475C" },
	{ label: "medium", color: "#FFC362" }
];
export const threeLvlNCsColorScale = [
	{ label: "high", color: "#FF475C" },
	{ label: "medium", color: "#FFC362" },
	{ label: "low", color: "#CCDB73" }
];
/**
 * List of key indicators / modules to be shown on the supplyR app
 */
export const domainsOptions = {
	"Business Continuity": {
		locale: "businessContinuity",
		logo: "/images/SupplyR/Modules/module-supplyr-business@2x.png"
	},
	"Data Integrity": {
		locale: "dataIntegrity",
		logo: "/images/SupplyR/Modules/module-supplyr-data@2x.png"
	},
	"HSE Resistance": {
		locale: "HSEResistance",
		logo: "/images/SupplyR/Modules/module-supplyr-hse@2x.png"
	},
	"HSE Supply Chain Audits": {
		locale: "HSESupplyChainAudits",
		logo: "/images/SupplyR/Modules/module-supplyr-hse@2x.png"
	},
	"Information Security": {
		locale: "informationSecurity",
		logo: "/images/SupplyR/Modules/module-supplyr-security@2x.png"
	},
	"Logistics & Inventory Practices": {
		locale: "logisticsInventoryPractices",
		logo: "/images/SupplyR/Modules/module-supplyr-logistic@2x.png"
	},
	"Supplier Technical Assessment": {
		locale: "supplierTechnicalAssessment",
		logo: "/images/SupplyR/Modules/module-supplyr-technical@2x.png"
	},
	"Food safety": {
		locale: "foodSafety",
		logo: "/images/SupplyR/Modules/module-supplyr-technical@2x.png"
	},
	"Business Governance & Social Engagement": {
		locale: "businessGovernance",
		logo: "/images/SupplyR/Modules/module-supplyr-social@2x.png"
	},
	"Maturity Assessment": {
		locale: "maturityAssessment",
		logo: "/images/SupplyR/Modules/module-supplyr-technical@2x.png"
	},
	"Camp Audit": {
		locale: "campAudit",
		logo: "/images/SupplyR/Modules/module-supplyr-technical@2x.png"
	}
};

/**
 * SupplyR AuditMode Icons
 */
const auditModes = {
	Remote: {
		locale: "auditMode.remote",
		picto: HeadsetMicIcon
	},
	"On Site": {
		locale: "auditMode.onSite",
		picto: VisibilityIcon
	},
	"Self Assessment": {
		locale: "auditMode.self",
		picto: PlaylistAddCheckIcon
	}
};

export function getAuditMode(auditType, auditMode) {
	// TO DO: handle PDO Audit modes & uncomment this section
	if (auditType === "Field Audit" || auditType === "Final Audit") {
		// Hardcode for pdo
		// return auditModes[auditMode];
	}
	return auditModes["Self Assessment"];
}

/**
 * SupplyR SplashScreen screens
 */
export const splashScreens = [
	{
		name: "welcome",
		type: "full",
		img: "/images/SupplyR/Splashscreen/Splashscreen-001.png"
	},
	/* {
		name: "page1",
		type: "page",
		img: "/images/SupplyR/Splashscreen/Splashscreen-002.png"
	},
	 */{
		name: "page2",
		type: "page",
		img: "/images/SupplyR/Splashscreen/Splashscreen-003.png"
	},
	{
		name: "page3",
		type: "page",
		img: "/images/SupplyR/Splashscreen/Splashscreen-004.png"
	},
	{
		name: "page4",
		type: "page",
		img: "/images/SupplyR/Splashscreen/Splashscreen-005.png"
	}
];

/**
 * nc status mapping
 */
const ncStatusMapping = {
	1: { status: "open", color: "#EF4444", picto: ReportGmailerrorredIcon },
	2: { status: "closed", color: "#6E8F57", picto: ForwardToInboxIcon },
	3: { status: "submitted", color: "#E1A544", picto: CheckCircleIcon },
	undefined: { status: "undefined", color: undefined, picto: HelpIcon }
};

const targetDateMapping = {
	true: { color: "#EF4444" },
	false: { color: undefined }
};

const isMoreThanNDaysAgo = (date, delay = 30) => {
	const daysInMs = delay * 24 * 60 * 60 * 1000;
	const timestampNDaysAgo = new Date().getTime() - daysInMs;

	if (timestampNDaysAgo > date) {
		// console.log("date is more than 30 days into the past");
		return true;
	}
	// console.log("date is NOT more than 30 days into the past");
	return false;
};

/**
 * Array of tags to be displayed for each non conformities
 */
export const listTagsNonConformities = {
	supplier: {
		accessor(d, t) { return d.supplier; },
		picto(d) { return BusinessIcon; },
		refine: [{ key: "supplier", valueKey: (d) => d.supplier }]
	},
	auditedCompany: {
		accessor(d, t) { return d.auditedCompany; },
		picto(d) { return HomeWorkIcon; },
		refine: [{ key: "siteId", valueKey: (d) => d.siteId }]
	},
	location: {
		accessor(d, t) { return ((d.city && `${d.city }-`) + d.country); },
		picto(d) { return PinDropIcon; }
	},
	siteType: {
		accessor(d, t) { return d.siteType; },
		picto(d) { return CompareArrowsIcon; }
	},
	module: {
		accessor(d, t) { return t(`domains.${ domainsOptions[d.domain]?.locale }.name`); },
		picto(d) { return domainsOptions[d.domain]?.logo; },
		refine: [{ key: "module", valueKey: (d) => d.module }]
	},
	section: {
		accessor(d, t) { return d.section1Label; },
		picto(d) { return VerticalSplitOutlinedIcon; }
	},
	product: {
		accessor(d, t) { return d.product; },
		picto(d) { return AccountTreeOutlinedIcon; },
		refine: [{ key: "product", valueKey: (d) => d.product }]
	},
	ncStatus: {
		accessor(d) { return `Action plan item ${ncStatusMapping[d.actionPlanNcStatus]?.status}`; },
		color(d) { return ncStatusMapping[d?.actionPlanNcStatus]?.color; },
		picto(d) { return ncStatusMapping[d?.actionPlanNcStatus]?.picto; },
		cond(d) { return !!d.actionPlanNcStatus; }
	},
	targetDate: {
		accessor(d) { return `Due on: ${d.actionPlanItemTargetDate ? new Date(d.actionPlanItemTargetDate).toLocaleDateString("fr") : undefined }`; },
		color(d) {
			if (ncStatusMapping[d.actionPlanNcStatus]?.status === "open") {
				return targetDateMapping[isMoreThanNDaysAgo(new Date(d.actionPlanItemTargetDate), 30)]?.color;
			}
			if (ncStatusMapping[d.actionPlanNcStatus]?.status === "submitted") {
				return targetDateMapping[isMoreThanNDaysAgo(new Date(d.actionPlanItemTargetDate), 1)]?.color;
			}

			return undefined;
		},
		picto(d) { return AccessAlarmIcon; },
		cond(d) { return !!d.actionPlanNcStatus; }
	}
};

export const supplyAuditsListTagsNC = {
	siteName: {
		accessor(d, t) { return d.siteName; },
		picto(d) { return BusinessIcon; },
		refine: [{ key: "siteId", valueKey: (d) => d.siteId }]
	},
	siteNumber: {
		accessor(d, t) { return d.siteNumber; },
		picto(d) { return HomeWorkIcon; }
	},
	location: {
		accessor(d, t) { return d.location; },
		picto(d) { return PinDropIcon; }
	},
	module: {
		accessor(d, t) { return t(`domains.${ domainsOptions[d.domain]?.locale }.name`); },
		picto(d) { return domainsOptions[d.domain]?.logo; },
		refine: [{ key: "module", valueKey: (d) => d.module }]
	},
	section: {
		accessor(d, t) { return d.section1Label; },
		picto(d) { return VerticalSplitOutlinedIcon; }
	},
	ncStatus: {
		accessor(d) { return `Action plan item ${ncStatusMapping[d.actionPlanNcStatus]?.status}`; },
		color(d) { return ncStatusMapping[d?.actionPlanNcStatus]?.color; },
		picto(d) { return ncStatusMapping[d?.actionPlanNcStatus]?.picto; },
		cond(d) { return !!d.actionPlanNcStatus; }
	},
	targetDate: {
		accessor(d) { return `Due on: ${d.actionPlanItemTargetDate ? new Date(d.actionPlanItemTargetDate).toLocaleDateString("fr") : undefined }`; },
		color(d) {
			if (ncStatusMapping[d.actionPlanNcStatus]?.status === "open") {
				return targetDateMapping[isMoreThanNDaysAgo(new Date(d.actionPlanItemTargetDate), 30)]?.color;
			}
			if (ncStatusMapping[d.actionPlanNcStatus]?.status === "submitted") {
				return targetDateMapping[isMoreThanNDaysAgo(new Date(d.actionPlanItemTargetDate), 1)]?.color;
			}

			return undefined;
		},
		picto(d) { return AccessAlarmIcon; },
		cond(d) { return !!d.actionPlanNcStatus; }
	}
};

/**
 * Init Refine of the Application
 *
 * @param {Object} supplyRInitRefines
 * Object representing the initial refine of the datasets
 * in the App slice, the key being the name of the field
 * in the DB Collection to filter on, the value being the value to filter on
 */
export const verkorInitRefines = { projectName: "" };

/**
 * Set up refine filter options to display
 *
 * @param {String} refine name of the field in the DB Collection to filter on
 * @param {String} loadFromDataset name of the collection to filter on the field
 * @param {String} placeHolder [OPTIONAL] text when nothing is selected
 * @param {Component} tag Material UI Icon to represent the field in the select, to be imported in this file
 * @param {Boolean} clear will be cleared if the button clear is pushed
 * @param {Boolean} toClearOnChange execute a clear of other clearable fields (see above) if the value has changed
 */
export const filterOptions = [
	{
		refine: "projectName",
		loadFromDataset: "listProjects",
		hiddenIfUnique: true,
		isSearchable: false,
		clear: false,
		toClearOnChange: true,
		displayField: "projectLabel"
	},
	{
		label: "Modules",
		refine: "module",
		loadFromDataset: "listModules",
		placeHolder: "filters.modules",
		hiddenIfUnique: true,
		isMulti: false,
		isSearchable: false,
		isClearable: true,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Tier Level",
		refine: "tierLevel",
		displayField: "tierLabel",
		loadFromDataset: "listTiers",
		placeHolder: "filters.tier",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isClearable: false,
		clear: true,
		toClearOnChange: false,
		hiddenIfUnique: true
	},
	{
		refine: "type",
		loadFromDataset: "listTypes",
		placeHolder: "filters.type",
		hiddenIfUnique: true,
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isClearable: false,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "product",
		loadFromDataset: "listProducts",
		placeHolder: "filters.products",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isGrouped: true,
		clear: true,
		toClearOnChange: false,
		hiddenIfUnique: true,
		onlyIfRefine: (d) => !d.supplyAudits
	},
	{
		refine: "country",
		loadFromDataset: "listGeographies",
		placeHolder: "filters.geographies",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isGrouped: true,
		clear: true,
		toClearOnChange: false,
		hiddenIfUnique: true
	},
	{
		refine: "city",
		loadFromDataset: "listGeographiesPerLocations",
		placeHolder: "filters.locations",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isGrouped: true,
		clear: true,
		toClearOnChange: false,
		hiddenIfUnique: true
	},
	{
		refine: "areaName",
		loadFromDataset: "listAreas",
		placeHolder: "filters.areas",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isClearable: true,
		isGrouped: true,
		clear: true,
		toClearOnChange: false
		// hiddenIfUnique: true
	},
	{
		refine: "plot",
		loadFromDataset: "listPlots",
		placeHolder: "filters.plot",
		manualRenderOption: true,
		isMulti: true,
		clear: true,
		toClearOnChange: false,
		hiddenIfUnique: true
	},
	// {
	// 	refine: "contractSegmentation",
	// 	loadFromDataset: "listContractSegmentations",
	// 	placeHolder: "filters.contractSegmentations",
	// 	manualRenderOption: true,
	// 	isMulti: true,
	// 	clear: true,
	// 	toClearOnChange: false,
	// 	hiddenIfUnique: true
	// },
	{
		label: "Scope",
		refine: "supplierCriticality",
		loadFromDataset: "listSupplierCriticalities",
		placeHolder: "filters.supplierCriticality",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isClearable: true,
		clear: true,
		toClearOnChange: false,
		onlyIfRefine: (d) => d.projectName === "Verkor",
		hiddenIfUnique: true
	},
	{
		label: "Site Segmentation",
		refine: "supplierCriticality",
		loadFromDataset: "listSupplierCriticalities",
		placeHolder: "filters.siteSegmentation",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isClearable: true,
		clear: true,
		toClearOnChange: false,
		onlyIfRefine: (d) => d.supplyAudits === true
	},
	{
		label: "Locations",
		refine: "location",
		loadFromDataset: "listLocations",
		placeHolder: "filters.locations",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isClearable: true,
		clear: true,
		toClearOnChange: false,
		hiddenIfUnique: true,
		onlyIfRefine: (d) => d.supplyAudits === true
	},
	{
		label: "Supplier",
		refine: "siteId",
		refineGroup: "supplier",
		loadFromDataset: "listEntities",
		placeHolder: "filters.searchAnEntity",
		manualPosition: true,
		isSearchable: true,
		isClearable: true,
		isGrouped: true,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Section",
		refine: "section1Label",
		loadFromDataset: "listSections",
		placeHolder: "filters.sections",
		isClearable: true,
		isSearchable: false,
		isGrouped: true,
		groupSelection: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		label: "Criticality",
		refine: "criticality",
		loadFromDataset: "listCriticalities",
		placeHolder: "filters.criticality",
		isClearable: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		label: "CriticalityLevel",
		refine: "criticalityLevel",
		loadFromDataset: "listCriticalityLevels",
		placeHolder: "filters.criticalityLevel",
		isClearable: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		label: "NCStatus",
		refine: "actionPlanNcStatus",
		displayField: "label",
		loadFromDataset: "listNCStatus",
		placeHolder: "filters.NCStatus",
		isClearable: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		label: "Answer",
		refine: "checkpointAnswer",
		loadFromDataset: "listCheckpointAnswer",
		placeHolder: "filters.checkpointAnswer",
		isClearable: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		refine: "supplier",
		manualPosition: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "region",
		placeHolder: "filters.region",
		manualPosition: true,
		isMulti: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "finalProduct",
		placeHolder: "filters.finalProducts",
		manualPosition: true,
		isMulti: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "areaOwner",
		manualPosition: true,
		isMulti: true,
		clear: true,
		toClearOnChange: false,
		onlyIfRefine: (d) => d.supplyAudits
	},
	{
		label: "Audit Status",
		refine: "auditStatus",
		displayField: "state",
		loadFromDataset: "listAuditStatus",
		placeHolder: "filters.auditStatus",
		isClearable: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		label: "Delayed",
		refine: "assignmentDelayed",
		displayField: "assignmentDelayedToStr",
		loadFromDataset: "listAssignmentDelayed",
		placeHolder: "filters.assignmentDelayed",
		isClearable: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		label: "Plan Item Delayed",
		refine: "actionPlanItemDelayed",
		displayField: "actionPlanItemDelayedStr",
		loadFromDataset: "listActionPlanItemDelayedList",
		placeHolder: "filters.actionPlanItemDelayed",
		isClearable: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	}
];

export const fieldsTooltip = [
	{ label: "Supplier", key: "supplier" },
	{ label: "Product", key: "product" },
	{ label: "Address", key: "address" },
	{ label: "Location", key: "location" },
	{ label: "Resilience", key: "resilience" },
	{ label: "Resistance", key: "resistance" },
	{ label: "Responsiveness", key: "responsiveness" }
];

/**
 * Define general supply-R ranking rules
 */
export const colorLegend = {
	unsatisfactory: {
		color: "#EB4C72",
		range: [0, 60]
	},
	average: {
		color: "#FFC362",
		range: [60, 80]
	},
	good: {
		color: "#CCDB73",
		range: [80, 100]
	}
};

export const PDOColorLegend = {
	majorGaps: {
		color: "#FF475C",
		range: [0, 50]
	},
	minorGaps: {
		color: "#FFC362",
		range: [50, 80]
	},
	acceptable: {
		color: "#CCDB73",
		range: [80, 100]
	}
};

export const supplyAuditsColorLegend = {
	poor: {
		color: "#FF475C",
		range: [0, 50]
	},
	supplyAuditsAverage: {
		color: "#FFC362",
		range: [50, 80]
	},
	acceptable: {
		color: "#CCDB73",
		range: [80, 100]
	}
};

/**
 * Define the rule for the Marker color on the map
 */
export const getMarkerColor = (score) => {
	if (!score) return "#c9bbbd";

	if (score >= colorLegend.unsatisfactory.range[0]
        && score < colorLegend.unsatisfactory.range[1]) {
		return colorLegend.unsatisfactory.color;
	} // Unsatisfactory

	if (score >= colorLegend.average.range[0]
        && score < colorLegend.average.range[1]) {
		return colorLegend.average.color;
	} // average

	if (score >= colorLegend.good.range[0]
        && score <= colorLegend.good.range[1]) {
		return colorLegend.good.color;
	} // good

	// if (score >= colorLegend.veryGood.range[0]
	//     && score <= colorLegend.veryGood.range[1]) {
	// 	return colorLegend.veryGood.color;
	// } // veryGood
};

export const getPDOMarkerColor = (score) => {
	if (!score) return "#c9bbbd";

	if (score >= PDOColorLegend.majorGaps.range[0]
        && score < PDOColorLegend.majorGaps.range[1]) {
		return PDOColorLegend.majorGaps.color;
	} // Unsatisfactory

	if (score >= PDOColorLegend.minorGaps.range[0]
        && score < PDOColorLegend.minorGaps.range[1]) {
		return PDOColorLegend.minorGaps.color;
	} // average

	if (score >= PDOColorLegend.acceptable.range[0]
        && score <= PDOColorLegend.acceptable.range[1]) {
		return PDOColorLegend.acceptable.color;
	}
};

export const getSupplyAuditsMarkerColor = (score) => {
	if (!score) return "#c9bbbd";

	if (score >= supplyAuditsColorLegend.poor.range[0]
        && score < supplyAuditsColorLegend.poor.range[1]) {
		return supplyAuditsColorLegend.poor.color;
	} // Unsatisfactory

	if (score >= supplyAuditsColorLegend.supplyAuditsAverage.range[0]
        && score < supplyAuditsColorLegend.supplyAuditsAverage.range[1]) {
		return supplyAuditsColorLegend.supplyAuditsAverage.color;
	} // average

	if (score >= supplyAuditsColorLegend.acceptable.range[0]) {
		return supplyAuditsColorLegend.acceptable.color;
	} // acceptable
};

export const subFilters = [
	{ name: "Final product", field: "finalProduct" },
	{ name: "Product", field: "product" },
	{ name: "Country", field: "country" }
];

export const tableStructure = (activeRefines, t) => [
	{
		Header: "Entity",
		accessor: "supplier"
	},
	{
		Header: "Factory",
		accessor: "auditedCompany"
	},
	{
		Header: "City",
		accessor: "city"
	},
	{
		Header: "Country",
		accessor: "country"
	},
	{
		Header: "Product",
		accessor: "product",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => Array.from(new Set(value))
		.reduce((acc, value, currentIndex) => `${acc} ${(currentIndex !== 0) ? "-" : ""} ${value}`, "")
	},
	{
		Header: "Target Date",
		accessor: "targetDate",
		Cell: ({ value }) => value ? new Date(value).toLocaleDateString("fr") : null
	},
	{
		Header: "Audit Date",
		accessor: "auditDateAndDelay",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => value.assignmentDelayed
			// eslint-disable-next-line react/prop-types
			? <span className="text-red-500 font-semibold">{new Date(value.auditDate).toLocaleDateString("fr")}</span>
			// eslint-disable-next-line react/prop-types
			: value.auditDate && !value.assignmentDelayed
				// eslint-disable-next-line react/prop-types
				? new Date(value.auditDate).toLocaleDateString("fr")
				: null,
		processedValue: (value) => value.auditDate ? new Date(value.auditDate).toLocaleDateString("fr") : null
	},
	{
		Header: "Next Audit Date",
		accessor: "nextAudit",
		Cell: ({ value }) => {
			const auditDate = value[0];
			const validityPeriodNumberOfMonths = Number(value[1]);
			if (auditDate && validityPeriodNumberOfMonths) {
				const nextAudit = new Date(auditDate).setMonth(new Date(auditDate).getMonth() + validityPeriodNumberOfMonths);
				return new Date(nextAudit).toLocaleDateString("fr");
			}
			return "";
		},
		processedValue: (value) => {
			const auditDate = value[0];
			const validityPeriodNumberOfMonths = Number(value[1]);
			if (auditDate && validityPeriodNumberOfMonths) {
				const nextAudit = new Date(auditDate).setMonth(new Date(auditDate).getMonth() + validityPeriodNumberOfMonths);
				return new Date(nextAudit).toLocaleDateString("fr");
			}
			return "";
		}
	},
	{
		Header: "Status",
		accessor: "state"
	},
	{
		Header: "Overall Result",
		accessor: "gradingsLabel"
	},
	{
		Header: "Action Plan Status",
		accessor: "actionPlanStatus"
	},
	{
		Header: "Action Plan Progress",
		accessor: (row) => row?.actionPlanStatus ? row?.closedRate : null,
		Cell: ({ value }) => value !== null ? `${value.toFixed(0)}%` : ""
	},
	{
		Header: "Score",
		accessor: activeRefines.module ? "resilienceScores" : "resilience",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => {
			if (typeof (value) === "number") {
				// eslint-disable-next-line react/prop-types
				return <p className="font-semibold">{value?.toFixed(0)}</p>;
			}

			return (
			// eslint-disable-next-line react/prop-types
				<p className="font-semibold">{value?.[0]?.[0]?.toFixed(0)}</p>
			);
		}
	},
	{
		Header: "NCs",
		accessor: "sumNbOpenedNc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-red-500 rounded-full h-7 w-7 text-white">{value}</p> : <p/>
	}
];
export const supplyAuditsTableStructure = (activeRefines, t) => [
	{
		Header: "Area Number",
		accessor: "areaNumber"
	},
	{
		Header: "Area Name",
		accessor: "areaName"
	},
	{
		Header: "Area Owner",
		accessor: "areaOwner"
	},
	{
		Header: "Site",
		accessor: "auditedCompany"
	},
	{
		Header: "Site No.",
		accessor: "siteNumber"
	},
	{
		Header: "Site Address",
		accessor: "address"
	},
	{
		Header: "Target Date",
		accessor: "targetDate",
		Cell: ({ value }) => value ? new Date(value).toLocaleDateString("fr") : null
	},
	{
		Header: "Audit Date",
		accessor: "auditDateAndDelay",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => value.assignmentDelayed
		// eslint-disable-next-line react/prop-types
			? <span className="text-red-500 font-semibold">{new Date(value.auditDate).toLocaleDateString("fr")}</span>
		// eslint-disable-next-line react/prop-types
			: value.auditDate && !value.assignmentDelayed
			// eslint-disable-next-line react/prop-types
				? new Date(value.auditDate).toLocaleDateString("fr")
				: null,
		processedValue: (value) => value.auditDate ? new Date(value.auditDate).toLocaleDateString("fr") : null
	},
	{
		Header: "Next Audit Date",
		accessor: "nextAudit",
		Cell: ({ value }) => {
			const auditDate = value[0];
			const validityPeriodNumberOfMonths = Number(value[1]);
			if (auditDate && validityPeriodNumberOfMonths) {
				const nextAudit = new Date(auditDate).setMonth(new Date(auditDate).getMonth() + validityPeriodNumberOfMonths);
				return new Date(nextAudit).toLocaleDateString("fr");
			}
			return "";
		},
		processedValue: (value) => {
			const auditDate = value[0];
			const validityPeriodNumberOfMonths = Number(value[1]);
			if (auditDate && validityPeriodNumberOfMonths) {
				const nextAudit = new Date(auditDate).setMonth(new Date(auditDate).getMonth() + validityPeriodNumberOfMonths);
				return new Date(nextAudit).toLocaleDateString("fr");
			}
			return "";
		}
	},
	{
		Header: "Status",
		accessor: "state"
	},
	{
		Header: "Overall Result",
		accessor: "gradingsLabel"
	},
	{
		Header: "Score",
		accessor: activeRefines.module ? "resilienceScores" : "resilience",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => {
			if (typeof (value) === "number") {
				// eslint-disable-next-line react/prop-types
				return <p className="font-semibold">{value?.toFixed(0)}</p>;
			}

			return (
			// eslint-disable-next-line react/prop-types
				<p className="font-semibold">{value?.[0]?.[0]?.toFixed(0)}</p>
			);
		}
	},
	{
		Header: "NCs",
		accessor: "sumNbOpenedNc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-red-500 rounded-full h-7 w-7 text-white">{value}</p> : <p/>
	}
];
// export const PDOTableStructure = (activeRefines, t) => [
// 	{
// 		Header: "Vendor Code",
// 		accessor: "supplier"
// 	},
// 	{
// 		Header: "Auditee",
// 		accessor: "auditedCompany"
// 	},
// 	{
// 		Header: "Location",
// 		accessor: "city"
// 	},
// 	{
// 		Header: "Region",
// 		accessor: "region"
// 	},
// 	{
// 		Header: "Directorate",
// 		accessor: "finalProduct"
// 	},
// 	{
// 		Header: "Target Date",
// 		accessor: "targetDate",
// 		Cell: ({ value }) => value ? new Date(value).toLocaleDateString("fr") : null
// 	},
// 	{
// 		Header: "Audit Date",
// 		accessor: "auditDateAndDelay",
// 		// eslint-disable-next-line react/prop-types
// 		Cell: ({ value }) => value.assignmentDelayed
// 		// eslint-disable-next-line react/prop-types
// 			? <span className="text-red-500 font-semibold">{new Date(value.auditDate).toLocaleDateString("fr")}</span>
// 		// eslint-disable-next-line react/prop-types
// 			: value.auditDate && !value.assignmentDelayed
// 			// eslint-disable-next-line react/prop-types
// 				? new Date(value.auditDate).toLocaleDateString("fr")
// 				: null,
// 		processedValue: (value) => value.auditDate ? new Date(value.auditDate).toLocaleDateString("fr") : null
// 	},
// 	{
// 		Header: "Next Audit Date",
// 		accessor: "nextAudit",
// 		Cell: ({ value }) => {
// 			const auditDate = value[0];
// 			const validityPeriodNumberOfMonths = Number(value[1]);
// 			if (auditDate && validityPeriodNumberOfMonths) {
// 				const nextAudit = new Date(auditDate).setMonth(new Date(auditDate).getMonth() + validityPeriodNumberOfMonths);
// 				return new Date(nextAudit).toLocaleDateString("fr");
// 			}
// 			return "";
// 		},
// 		processedValue: (value) => {
// 			const auditDate = value[0];
// 			const validityPeriodNumberOfMonths = Number(value[1]);
// 			if (auditDate && validityPeriodNumberOfMonths) {
// 				const nextAudit = new Date(auditDate).setMonth(new Date(auditDate).getMonth() + validityPeriodNumberOfMonths);
// 				return new Date(nextAudit).toLocaleDateString("fr");
// 			}
// 			return "";
// 		}
// 	},
// 	{
// 		Header: "Status",
// 		accessor: "state"
// 	},
// 	{
// 		Header: "Overall Result",
// 		accessor: "gradingsLabel"
// 	},
// 	{
// 		Header: "Action Plan Status",
// 		accessor: "actionPlanStatus"
// 	},
// 	{
// 		Header: "Action Plan Progress",
// 		accessor: (row) => row?.actionPlanStatus ? row?.closedRate : null,
// 		Cell: ({ value }) => value !== null ? `${value.toFixed(0)}%` : ""
// 	},
// 	{
// 		Header: "Score",
// 		accessor: activeRefines.module ? "resilienceScores" : "resilience",
// 		// eslint-disable-next-line react/prop-types
// 		Cell: ({ value }) => {
// 			if (typeof (value) === "number") {
// 				// eslint-disable-next-line react/prop-types
// 				return <p className="font-semibold">{value?.toFixed(0)}</p>;
// 			}

// 			return (
// 			// eslint-disable-next-line react/prop-types
// 				<p className="font-semibold">{value?.[0]?.[0]?.toFixed(0)}</p>
// 			);
// 		}
// 	},
// 	{
// 		Header: "NCs",
// 		accessor: "sumNbOpenedNc",
// 		// eslint-disable-next-line react/prop-types
// 		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
//         bg-red-500 rounded-full h-7 w-7 text-white">{value}</p> : <p/>
// 	},
// 	{
// 		Header: "Mode",
// 		accessor: "auditMode"
// 	},
// 	{
// 		Header: "Contract Number",
// 		accessor: "contractNumber"
// 	},
// 	{
// 		Header: "Contract Holder",
// 		accessor: "contractHolder"
// 	},
// 	{
// 		Header: "Contract Owner",
// 		accessor: "contractOwner"
// 	}
// ];

/**
 *
 * @param {String} tab list
 */
export const termsTabSet = {
	"Legal Notice": {
		en: <LegalNotice/>,
		fr: <LegalNotice/>,
		"zh-Hans": <LegalNotice/>,
		"zh-Hant": <LegalNotice/>
	},
	Terms: {
		en: <Terms/>,
		fr: <Terms/>,
		"zh-Hans": <Terms/>,
		"zh-Hant": <Terms/>
	},
	Privacy: {
		en: <Privacy/>,
		fr: <Privacy/>,
		"zh-Hans": <Privacy/>,
		"zh-Hant": <Privacy/>
	},
	Cookies: {
		en: <Cookies/>,
		fr: <Cookies/>,
		"zh-Hans": <Cookies/>,
		"zh-Hant": <Cookies/>
	}
};

export const infoTabSet = {
	/* Info: {
		en: <VerkorInfoDesk />,
		fr: <VerkorInfoDesk />,
		"zh-Hans": <VerkorInfoDesk />,
		"zh-Hant": <VerkorInfoDesk />
	},
	 */Tutorial: {
		en: <VerkorSplash />,
		fr: <VerkorSplash />,
		"zh-Hans": <VerkorSplash />,
		"zh-Hant": <VerkorSplash />
	},
	Api: {
		en: <VerkorApi />,
		fr: <VerkorApi />,
		"zh-Hans": <VerkorApi />,
		"zh-Hant": <VerkorApi />
	}
};
