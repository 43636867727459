import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function InfoCard({ infoKey, locales }) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<div data-test="InfoCardTitle" className="flex flex-col w-full bg-white rounded-xl px-6 py-4">
			<div className="flex justify-between items-center">
				<p>{t(`InfoPage.${infoKey}.question`)}</p>
			</div>
			<p className="mt-8 text-sm font-light">
				{t(`InfoPage.${infoKey}.answer`)}
			</p>
		</div>
	);
}

InfoCard.propTypes = {
	infoKey: PropTypes.string,
	locales: PropTypes.string
};

export default InfoCard;
