import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { AuthState } from "@aws-amplify/ui-components";
import { stepButtonClasses } from "@mui/material";

export const fetchUser = createAsyncThunk("users/fetchUser", async (args, { getState }) => Auth.currentAuthenticatedUser({
	bypassCache: true
}));

export const userSlice = createSlice({
	name: "user",
	initialState: {
		username: "",
		type: "",
		name: "",
		firstName: "",
		initials: "",
		company: "",
		email: "",
		groups: undefined,
		rights: undefined,
		loginRequested: false,
		loginState: "anonymous",
		loadDataStatus: "idle"
	},
	reducers: {
		requestSignIn: (state, action) => {
			state.loginRequested = true;
		},
		updateLoginState: (state, action) => {
			state.loginState = action.payload;
			state.loginRequested = false;
		}
	},
	extraReducers: {
		[fetchUser.pending]: (state) => {
			state.loadDataStatus = "loading";
		},
		[fetchUser.rejected]: (state, action) => {
			state.loadDataStatus = "idle";
			state.loginState = "noAuth";
		},
		[fetchUser.fulfilled]: (state, action) => {
			state.loadDataStatus = "idle";
			state.loginState = AuthState.SignedIn;
			state.loginRequested = false;
			state.username = action.payload.username;
			const key = action?.payload?.signInUserSession?.idToken?.payload;

			if (action?.payload?.signInUserSession?.idToken?.payload !== undefined) {
				state.name = key.name;
				state.firstName = (state.name).substr(0, state.name.indexOf(" "));
				// eslint-disable-next-line prefer-regex-literals
				const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

				const initials = [...state.name.matchAll(rgx)] || [];
				state.initials = (
					(initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
				).toUpperCase();
				state.email = key.email;
				state.type = state.email.toLowerCase().endsWith("@bureauveritas.com") ? "bv" : "customer";
				state.company = key.company;
				state.rightslist = decodeURIComponent(key["custom:roleslist"]).split(", ");
				state.rights = state.rightslist.reduce((acc, value) => {
					const item = value?.split(",");

					if (item?.[1] === "ou=ods") {
						const right = item?.[0]?.split("=")?.[1]?.toUpperCase();
						const rightApp = right?.split("_")?.[0];
						const rightScope = right?.split("_")?.[1] || "";
						const rightObject = {};
						rightObject.app = rightApp;
						rightObject.scope = rightScope;
						acc.push(rightObject);
					}
					return acc;
				}, []);
				if (state.rights.length === 0) {
					state.rights = state.rightslist.reduce((acc, value) => {
						const item = value?.split(",");
						if (item?.[1] === "ou=bvreport" && item?.[0].includes("cn=customer")) {
							acc.push({ app: "CLARITY", scope: "supplier" });
							acc.push({ app: "SUPPLYR", scope: "supplier" });
							acc.push({ app: "VERKOR", scope: "supplier" });
						}
						return acc;
					}, []);
				}
			}
		}
	}
});

export const { requestSignIn, updateLoginState } = userSlice.actions;
export const userSelector = (state) => state.user;
