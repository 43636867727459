import { useRef, useEffect } from "react";

// Return previous value (happens before update in useEffect above)
const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
};

export default usePrevious;
