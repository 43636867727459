import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import ClearButton from "../filters/ClearButton";

import FilterMenu from "../filters/FilterMenu";
import { customStylesSearchSupplyR, customStylesSupplyR, customStylesSupplyRMobile } from "../filters/SupplyRFiltersStyle";
import { filterOptions, supplyRInitRefines } from "../../apps/configs/SupplyRConfig";
import HorizontalFilters from "../filters/HorizontalFilters";
import AlphaTooltip from "../tooltips/AlphaTooltip";
import { downloadFromS3 } from "../../api/client";
import ImgWithFallbackSrc from "../content/ImgWithFallbackSrc";

function SupplyRHeader({ appSlice, locales, appDefinition }) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Find related products to current auditedcompany
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const isActiveFilter = useSelector(appSlice.isRefined);
	const activeDatasets = useSelector(appSlice.selectDatasets);

	const clientAccessorValue = Array.isArray(clientParameters?.[appDefinition?.clientAccessor])
		? clientParameters?.[appDefinition?.clientAccessor]?.[0]
		: clientParameters?.[appDefinition?.clientAccessor];

	const [previewUrl, setPreviewUrl] = useState(null);
	const fetchLogo = async () => {
		const url = await downloadFromS3({
			filePath: `public/projectLogos/${clientAccessorValue}/${clientParameters?.logo}`,
			appName: appDefinition?.slice
		});
		setPreviewUrl(url);
	};
	useEffect(() => {
		fetchLogo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<header className="relative hidden md:flex items-center justify-between w-full bg-white z-600">
				<div className="absolute -left-24 top-0 w-72 h-full  rounded-b-full bg-black overflow-hidden z-0">
					<img onError={(ev) => ev.target.src = "/images/Clarity/Markets/default.jpg" }
						src={`/images/SupplyR/Markets/${clientParameters?.market}`} alt="Market Img" />
				</div>

				<div className="flex items-center gap-x-6 ml-6 h-24 my-2" >
					<div className="flex flex-shrink-0 items-center justify-center z-20
                              bg-white w-28 h-16 text-sm text-black rounded-lg font-semibold ">
						{clientParameters?.logo ?
							<ImgWithFallbackSrc
								src1={`/images/CompanyLogos/${clientParameters?.logo}`}
								src2={previewUrl}
								alt={`${clientParameters?.client} logo`}
								styleString="h-16 max-w-full object-contain p-1"/>
							: <p className="font-semibold text-lg tracking-tight uppercase">{clientParameters?.client ?? "CLIENT"}</p>}
					</div>

					<div className="flex flex-col justify-center mt-3 space-y-2 w-full">

						<div className="flex flex-grow items-center justify-start h-full">
							{/* main filters */}
							<div className="flex flex-grow items-center gap-x-2 ">
								{filterOptions.filter((option) => !option.manualPosition).map((options, i) => (

									<FilterMenu
										key={i}
										appSlice={appSlice}
										options={options}
										customStyle={customStylesSupplyR}
										cleanStyle
										menuSelectedColor="#3D49AB"
										displayNb
										manualRenderOption
										clearOnChange={filterOptions.filter((option) => option.clear)}
										locales={locales}
									/>

								))}

								<AlphaTooltip placement="top-start"
									title={<div className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90
                                        bg-supplyr_primary-accent rounded-2xl w-48">
                                			<span className="font-bold">{t("tooltip.title")}</span>
                                			{t("filters.tooltip")}
                                		</div>}>
                                	<HelpIcon data-test="filters-tooltip" fontSize="small" className="text-supplyr_primary-accent" />
								</AlphaTooltip>

								<div className="self-end ml-2">
									<ClearButton appSlice={appSlice} isActiveFilter={isActiveFilter}
										options={filterOptions.filter((option) => option.clear)}
										inLine={false} locales={locales}/>
								</div>

							</div>
						</div>

						{/* subfilter */}
						<div className="flex flex-wrap gap-x-4 gap-y-1  ">
							{filterOptions.filter((option) => option.isMulti
                            && Object.keys(activeRefines).includes(option.refine))
							.map((option, i) => {
								if (!activeRefines[option.refine]) {
									return null;
								}
								const listOptions = activeDatasets[option.loadFromDataset];

								const refine = Array.isArray(activeRefines[option.refine]) ? activeRefines[option.refine] : [activeRefines[option.refine]];
								return (
									<div key={i} className="flex justify-center items-center text-medium">
										{t(option.placeHolder)}:
										{refine.map((item, i) => (<button key={i} type="button"
											className="bg-supplyr_primary-menu text-white rounded-lg p-1.5 ml-2"
											onClick={() => {
												dispatch(appSlice.actions.deleteFromSubFilter({ field: option.refine, item }));
											}}>
											{option.displayField ?
												// if displayField, retrieve the associated value
												listOptions?.data?.find((e) => e[option.refine] === item)?.[option.displayField]
												// else directly display the refined value
												: item }
											<CloseIcon fontSize="x-small" className="ml-3 text-gray-400" />
										</button>))}
									</div>
								);
							})}
						</div>

					</div>
				</div>

				{/* search supplier */}
				<FilterMenu
					appSlice={appSlice}
					options={filterOptions.find((option) => option.refine === "siteId")}
					customStyle={customStylesSearchSupplyR}
					cleanStyle
					isSearch
					manualRenderOption
					clearOnChange={[filterOptions.find((option) => option.refine === "siteId").clear]}
					className="w-64 2xl:w-96 self-center mr-2 mb-2 "
					locales={locales}
				/>
			</header>

			{/*
            * MOBILE VERSION OF THE FILTERS
            */}
			<div className="inline md:hidden">
				<HorizontalFilters appSlice={appSlice} filterOptions={filterOptions} initRefines={supplyRInitRefines}
					siteMenuLabel="Supplier" customStyle={customStylesSupplyRMobile} customStyleSearch={customStylesSearchSupplyR}
					classTitle="supplyr_primary-menu" noTitle isActiveFilter={isActiveFilter} locales={locales} filterDesktop >
					<div className="absolute top-0 left-3 w-2/3 h-32 overflow-hidden" >
						<img onError={(ev) => ev.target.src = "/images/SupplyR/Markets/default.jpg" }
							src={`/images/SupplyR/Markets/${clientParameters?.market}`} alt="Market Img"
							className="absolute top-0 w-full h-full rounded-b-full" style={{ zIndex: -1 }}/>
					</div>
				</HorizontalFilters>
			</div>
		</>
	);
}

SupplyRHeader.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	appDefinition: PropTypes.object
};

export default SupplyRHeader;
