import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";

function RYBAbout({ locales }) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<div className="flex flex-col">
			<div className="w-full h-128 pt-0 lg:h-screen
                        flex flex-col justify-center content-center items-center
                        text-white text-sm
                        bg-ryb-about bg-center bg-cover bg-no-repeat bg-scroll">
				<img
					src="/images/RYB/RestartYourBusiness-txt-white-1000px.png"
					className="w-1/2"
				/>
				<p className="mt-6 w-14 border-t-2 border-ryb_primary-default">&nbsp;</p>
				<p className="mb-6 mx-6 text-center text-sm lg:mx-20 lg:text-base">{t("about.head.description")}</p>
				<a className="bg-ryb_primary-default rounded-full font-semibold px-6 py-4" href="#about">
					{t("about.head.findmore")}
				</a>
			</div>

			<section id="about" className="flex flex-col justify-center content-center items-center bg-ryb_primary-bg text-white p-10">
				<h2 className="text-3xl font-semibold">{t("about.about.title")}</h2>
				<p className="mt-6 w-14 border-t-2 border-white ">&nbsp;</p>
				<p className="mb-6 mx-6 text-center text-sm lg:mx-20 lg:text-base">{t("about.about.description")}</p>
				<a className="bg-white text-ryb_primary-bg rounded-full font-semibold px-6 py-4" href="#get">{t("about.about.getstarted")}</a>
			</section>

			<section id="priorities" className="flex flex-col justify-center content-center items-center bg-white text-ryb_primary-bg text-white p-10">
				<h2 className="text-3xl font-semibold">{t("about.priorities.title")}</h2>
				<p className="mt-6 w-14 border-t-2 border-ryb_primary-default ">&nbsp;</p>
				<div className="grid grid-cols-1 grid-flow-row gap-6 md:grid-cols-2 xl:grid-cols-4">
					<div className="flex flex-col justify-start content-center items-center
                                    text-center text-2xl font-semibold p-4">
						<AssignmentTurnedInOutlinedIcon style={{ fontSize: "80px" }} className="text-ryb_primary-default" />
						<h3 className="h4">{t("about.priorities.safe")}</h3>
					</div>
					<div className="flex flex-col justify-start content-center items-center
                                    text-center text-2xl font-semibold p-4">
						<ThumbUpAltOutlinedIcon style={{ fontSize: "80px" }} className="text-ryb_primary-default" />
						<h3 className="h4">{t("about.priorities.reassurance")}</h3>
					</div>
					<div className="flex flex-col justify-start content-center items-center
                                    text-center text-2xl font-semibold p-4">
						<ListAltOutlinedIcon style={{ fontSize: "80px" }} className="text-ryb_primary-default" />
						<h3 className="h4">{t("about.priorities.compliance")}</h3>
					</div>
					<div className="flex flex-col justify-start content-center items-center
                                    text-center text-2xl font-semibold p-4">
						<PublicOutlinedIcon style={{ fontSize: "80px" }} className="text-ryb_primary-default" />
						<h3 className="h4">{t("about.priorities.consistent")}</h3>
					</div>
				</div>
			</section>

			<section id="scope" className="flex flex-col justify-center content-center items-center bg-ryb_primary-bg text-white p-10">
				<h2 className="text-3xl font-semibold">{t("about.scope.title")}</h2>
				<p className="mt-6 w-14 border-t-2 border-white ">&nbsp;</p>
				<p className="mb-6 mx-6 text-center text-sm lg:mx-20 lg:text-base">{t("about.scope.description")}</p>
				<img src="/images/RYB/checklist.png" width="60%" />
			</section>

			<section id="how" className="flex flex-col justify-start content-center items-center bg-white text-ryb_primary-bg text-white p-10">
				<h2 className="text-3xl font-semibold">{t("about.how.title")}</h2>
				<p className="mt-6 w-14 border-t-2 border-ryb_primary-default ">&nbsp;</p>
				<div className="grid grid-cols-1 grid-flow-row gap-6 md:grid-cols-2 xl:grid-cols-4">
					<div className="flex flex-col justify-start content-center items-center
                                    text-center p-4">
						<img src="/images/RYB/define.png" width="40%" />
						<h3 className="font-semibold text-2xl">{t("about.how.define.title")}</h3>
						<p className="font-light">{t("about.how.define.description")}</p>
					</div>
					<div className="flex flex-col justify-start content-center items-center
                                    text-center p-4">
						<img src="/images/RYB/conduct.png" width="40%" />
						<h3 className="font-semibold  text-2xl">{t("about.how.conduct.title")}</h3>
						<p className="font-light">{t("about.how.conduct.description")}</p>
					</div>
					<div className="flex flex-col justify-start content-center items-center
                                    text-center p-4">
						<img src="/images/RYB/grant.png" width="40%" />
						<h3 className="font-semibold  text-2xl">{t("about.how.grant.title")}</h3>
						<p className="font-light">{t("about.how.grant.description")}</p>
					</div>
					<div className="flex flex-col justify-start content-center items-center
                                    text-center p-4">
						<img src="/images/RYB/repository.png" width="40%" />
						<h3 className="font-semibold  text-2xl">{t("about.how.traceability.title")}</h3>
						<p className="font-light">{t("about.how.traceability.description")}</p>
					</div>
				</div>
			</section>

			<section id="get" className="flex flex-col justify-start content-center items-center bg-gray-600 text-white p-10">
				<h2 className="text-3xl font-semibold">{t("about.get.title")}</h2>
				<p className="mt-6 w-14 border-t-2 border-white ">&nbsp;</p>
				<p className="mb-6 mx-6 text-center text-sm lg:mx-20 lg:text-base">{t("about.get.description")}</p>
				<a className="bg-white text-ryb_primary-bg rounded-full font-semibold px-6 py-4"
					href="https://onboardwith.bureauveritas.com/">
					{t("about.get.subscribe")}
				</a>
				<img src="/images/RYB/process - en.png" width="60%" />
			</section>

			<section id="dashboard" className="flex flex-col justify-start content-center items-center bg-white text-ryb_primary-bg p-10">
				<img src="/images/RYB/dashboard.png" width="40%" />
				<h2 className="text-2xl font-semibold text-center">
					{t("about.dashboard.title")}
				</h2>
				<p className="mt-6 w-14 border-t-2 border-ryb_primary-default">&nbsp;</p>
				<ul className="list-disc list-inside font-light">
					<li>{t("about.dashboard.description.realtime")}</li>
					<li>{t("about.dashboard.description.nc")}</li>
					<li>{t("about.dashboard.description.kpis")}</li>
				</ul>
			</section>

			<section id="traceability" className="flex flex-col justify-start content-center items-center bg-white text-ryb_primary-bg p-10">
				<h2 className="text-2xl font-semibold text-center">{t("about.traceability.title")}</h2>
				<p className="mt-6 w-14 border-t-2 border-ryb_primary-default">&nbsp;</p>
				<ul className="list-disc list-inside font-light">
					<li>{t("about.traceability.description.openportal")}</li>
					<li>{t("about.traceability.description.opendata")}</li>
				</ul>
				<img src="/images/RYB/traceability.png" width="40%" />

			</section>

		</div>
	);
}

RYBAbout.propTypes = {
	locales: PropTypes.string
};

export default RYBAbout;
