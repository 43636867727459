import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { filterOptions } from "../../apps/configs/ClarityConfig";
import { customStylesSearchClarity } from "../filters/ClarityFilterStyle";
import FilterMenu from "../filters/FilterMenu";

function ClarityDomainHeader({
	appSlice, selectedDomain, listActiveDomains, contrast = false, logoClassName = "w-80 h-80 ml-4 filter brightness-200 contrast-200",
	gradientClassName = "bg-gradient-to-r from-clarity_primary-gradientL via-clarity_primary-gradientV to-clarity_primary-gradientR",
	locales, children
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	const filterOption = { ...filterOptions.find((option) => option.label === "Modules") };
	filterOption.isClearable = false;

	let infoSelectedDomain;
	let Logo;

	return (
		<div className={`flex flex-col  w-full pt-4 pb-12 px-4 md:px-8 overflow-hidden h-80 ${gradientClassName}`}>
			<div className="hidden md:flex space-x-1 md:space-x-2  text-smaller md:text-small  xl:text-sm z-40">
				{Object.keys(listActiveDomains).map((domainKey, i) => {
					const domain = listActiveDomains[domainKey];
					if (domainKey === selectedDomain) { infoSelectedDomain = domain; Logo = domain?.domainlogo; }
					return (
						<div key={i}
							onClick={() => dispatch(appSlice.actions.refine(
								filterOption?.clearRefines?.map((e) => ({ key: e, value: undefined })).concat([{ key: "domain", value: domainKey }])))}
							className={`flex items-center rounded-xl border px-1 md:px-2 py-2 cursor-pointer leading-4 border-white 
							${contrast && "text-white"}
							${(infoSelectedDomain === domain) ?
								"bg-white text-clarity_primary-accent " : (!contrast && "border-gray-700")}`}>
							{t(`domains.${domain?.name}.name`)}
						</div>
					);
				})}
			</div>
			<div className="w-3/4 md:hidden z-20">
				<FilterMenu
					appSlice={appSlice}
					options={filterOption}
					customStyle={customStylesSearchClarity}
					textClasses="text-clarity_primary-accent"
					clearOnChange={filterOption.clear}
					className={"w-full"}
					locales={locales}
				/>
			</div>
			<div data-test="clarity-domain-header-domain-name"
				className="flex items-center flex-grow justify-between">
				<div className="relative flex flex-col z-10">
					<div className="absolute -top-20 -left-12 opacity-60
									text-white" style={{ zIndex: -1 }}>
						{typeof Logo === "object" ?
							<Logo style={{ fontSize: 240 }} /> :
							<img src={infoSelectedDomain.domainLogo} alt={infoSelectedDomain.domainLogo} className={logoClassName} />
						}

					</div>

					<div className="flex gap-x-2 items-center">
						<div
							onClick={() => dispatch(appSlice.actions.refine([{ key: "domain", value: undefined }]))}
							className="flex items-center text-clarity_primary-accent bg-white rounded-xl text-2xl font-bold p-2 cursor-pointer">
							<ArrowBackIcon fontSize="inherit" />
						</div>
						<p className={`font-semibold line-clamp-2 text-2xl md:text-3xl xl:text-4xl ${contrast && "text-white"}`}>
							{t(`domains.${infoSelectedDomain?.name}.name`)}
						</p>
					</div>

					<p className={`pt-3 font-light text-small md:text-lg 2xl:text-xl ${contrast && "text-white"}`}>
						{t(`domains.${infoSelectedDomain?.locale}.description`)}
					</p>
				</div>
				{children}
			</div>
		</div>
	);
}

ClarityDomainHeader.propTypes = {
	appSlice: PropTypes.object,
	selectedDomain: PropTypes.string,
	listActiveDomains: PropTypes.object,
	contrast: PropTypes.bool,
	logoClassName: PropTypes.string,
	gradientClassName: PropTypes.string,
	children: PropTypes.object,
	locales: PropTypes.string
};

export default ClarityDomainHeader;
