import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import ClarityActionPlanScope from "./ClarityActionPlanScopeCard";

const ClarityAPScopeWrapper = ({
	appDefinition = undefined, appSlice, datasets, refineOnClick = false, clientParams, theme
}) => {
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const actionPlanStatus = activeDatasets?.[datasets[0]]?.data[0]?.actionPlanStatus;
	const itemsDelayedCounter = activeDatasets?.[datasets[1]]?.data[0]?.totalDelayed;

	const targetDate = activeDatasets?.[datasets[0]]?.data[0]?.actionPlanTargetDate;

	const projectScopeList = clientParams?.actionPlanStatusScope;

	// If there are more than one action plan the text in the donut would be different
	const isAggregatedView = activeDatasets?.[datasets[0]]?.data?.length > 1;

	const totalNCs = activeDatasets?.getActionPlan?.data?.reduce((acc, currentValue) => {
		if (typeof currentValue.nbNC === "number") {
			return acc + currentValue.nbNC;
		}
		console.log(`Skipped non-numeric value: ${currentValue}`);
		return acc;
	}, 0);

	const [folderOpened, setFolderOpened] = useState(false);

	return (
		<div className="alphaBlur p-4 rounded-xl drop-shadow-xl">
			<ClarityActionPlanScope
				appSlice={appSlice}
				dataset={[datasets[0]]}
				total={totalNCs}
				totalCovered={activeDatasets?.[datasets[0]]?.data[0]?.totalNCs}
				titleLocale="analysis.actionPlanOverview"
				scopeList={projectScopeList}
				categoriesListDataset="listGradingsLabels"
				categoriesKey="ncStatus"
				categoriesLocale="analysis.scope.ncStatus"
				totalLocaleKey = {activeDatasets?.getActionPlan?.data.length > 1 ? "actionPlans" : "actionPlan"}
				refineOnClick={refineOnClick}
				innerText={isAggregatedView ? `${totalNCs} non conformities` : `Action plan ${actionPlanStatus}`}
				displayInner={false}
				counterPills={[
					{
						id: "delayed",
						counter: itemsDelayedCounter,
						color: "bg-scope-delayed",
						textWhite: true
					}
				]}
				locales={appDefinition.locales}
				apTargetDate={targetDate}
				theme={theme}
				actionPlanList={activeDatasets?.getActionPlan?.data}
			></ClarityActionPlanScope>
			{isAggregatedView && <div>
				<div className="cursor-pointer" onClick={() => setFolderOpened(!folderOpened)}>
					<span className="font-semibold my-2 text-sm">More Details</span>
					<KeyboardArrowDownRoundedIcon className={`${folderOpened ? "rotate-180" : "rotate-0"}`}/>
				</div>
				{folderOpened && <div className="rounded-lg drop-shadow w-full p-2 bg-gray-100 m-2">
					<div className="grid grid-cols-4 px-2 text-sm">
						<div className="font-semibold">Domain</div>
						<div className=" font-semibold">Status</div>
						<div className="font-semibold">Progress</div>
						<div className="font-semibold">Nb NCs</div>
					</div>
					{activeDatasets?.getActionPlan?.data?.map((ap, i) => (
						<div className={`grid grid-cols-4 px-2 py-2 bg-white rounded-sm
						${i === 0 && "mt-2"} items-center text-sm`}
						key={`listItem${i}`}>
							<div className="">{ap.domain}</div>
							<div className="">{ap.actionPlanStatus}</div>
							<div className="">{ap.actionPlanProgressRate}</div>
							<div className="">{ap.nbNC}</div>
						</div>
					))}
				</div>}
			</div>}

		</div>);
};

ClarityAPScopeWrapper.propTypes = {
	appSlice: PropTypes.object,
	appDefinition: PropTypes.object,
	refineOnClick: PropTypes.bool,
	datasets: PropTypes.array,
	clientParams: PropTypes.object,
	theme: PropTypes.string
};

export default ClarityAPScopeWrapper;
