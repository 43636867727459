import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Controller } from "react-hook-form";

import { useDispatch } from "react-redux";

// TODO: require validator
const SelectFieldEditor = ({
	field,
	control,
	options,
	appSlice = {},
	placeholder = "Select...",
	styles,
	defaultValue,
	defaultInputValue,
	isDispatch = false,
	customLabel,
	requiredFields = []
}) => {
	const dispatch = useDispatch();

	return (
		<Controller
			name={`${field}`}
			control={control}
			rules={{ required: requiredFields.includes(field) }}
			render={({
				field: {
					onChange, value, name, ref
				}
			}) => (
				<Select
					defaultInputValue={defaultInputValue}
					value={options?.find((c) => c.value === value)}
					inputRef={ref}
					options={options}
					placeholder={placeholder}
					onChange={(selectedOption) => {
						onChange(selectedOption.value);

						if (isDispatch) {
							Object.entries(selectedOption?.dispatchField)?.forEach(
								([key, value]) => {
									dispatch(appSlice.actions.refine([{ key, value }]));
								}
							);
						}
					}}
					defaultValue={defaultValue}
					styles={styles}
					getOptionLabel={customLabel}
				/>
			)}
		/>
	);
};

SelectFieldEditor.propTypes = {
	control: PropTypes.object,
	field: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.array,
	appSlice: PropTypes.object,
	styles: PropTypes.object,
	defaultValue: PropTypes.object,
	defaultInputValue: PropTypes.object,
	isDispatch: PropTypes.bool,
	customLabel: PropTypes.object,
	requiredFields: PropTypes.array
};

export default SelectFieldEditor;
