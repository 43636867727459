/* eslint-disable react/no-unescaped-entities */
import React from "react";

const RYBPrivacyFR = () => (
	<div className="text-sm">
		<p className="text-tab-legalNotice-title text-2xl font-medium">
				POLITIQUE DE PROTECTION DES DONNEES A CARACTERE PERSONNEL DU GROUPE
				BUREAU VERITAS POUR LES UTILISATEURS{" "}
		</p>
		<p className="text-gray-500 text-sm">
				(Dernière mise à jour: Mai 23, 2018)
		</p>

		<p className="mt-4">
				Bureau Veritas (ci-après, « Bureau Veritas », « nous », « notre »),
				reconnait l’importance d’une protection efficace et pertinente des
				Données à Caractère Personnel qu’elle collecte auprès de ses
				Utilisateurs (tel que ce terme est défini ci-dessous) et qu’elle
				utilise.
		</p>
		<p className="mt-4">
				L’intégrité est une valeur à laquelle nous accordons une grande
				importante. Nous entendons construire avec vous une relation solide et
				durable, fondée sur la confiance et les intérêts mutuels. Nous estimons
				que la protection de la vie privée est essentielle. La présente
				Politique de Protection des Données à Caractère Personnel exprime
				l’engagement ferme du Groupe Bureau Veritas visant au respect et à la
				protection des Données à Caractère Personnel de chacun et à se conformer
				dans le monde entier aux lois relatives à la protection des données.
		</p>
		<p className="mt-4">
				La présente Politique de Protection des Données à Caractère Personnel
				s’applique à toutes les Données à Caractère Personnel collectées et
				utilisées par Bureau Veritas à travers le monde.
		</p>

		<p className="mt-4">
				La présente Politique de Protection des Données à Caractère Personnel
				est destinée à vous informer des différents types de Données à Caractère
				Personnel vous concernant que nous pouvons être amenés à collecter ou
				conserver, de leur utilisation, des personnes auxquelles elles peuvent
				être communiquées, des modalités de protection et de sécurisation, et
				des droits portant sur vos Données à Caractère Personnel. Pour l’adapter
				à de nouvelles pratiques ou offres de services éventuelles, la présente
				Politique de Protection des Données à Caractère Personnel pourra être
				mise à jour à tout moment. Dans un tel cas, nous modifierons la date de
				« dernière mise à jour » et indiquerons la date à laquelle les
				modifications auront été effectuées. La présente Politique de Protection
				des Données à Caractère Personnel peut également être consultée sur les
				sites et applications de Bureau Veritas. Nous vous invitons à consulter
				régulièrement les potentielles mises à jour de la présente Politique de
				Protection des Données à Caractère Personnel disponible sur&nbsp;
			<a
				className="hover:underline"
				href="https://personaldataprotection.bureauveritas.com/privacypolicy"
			>
					https://personaldataprotection.bureauveritas.com/privacypolicy
			</a>
		</p>
		<p className="mt-4">
				« Données à Caractère Personnel » désigne toute information ou tous
				éléments d’information qui pourrai(en)t permettre de vous identifier
				directement ou indirectement. Les Données à Caractère Personnel
				comprennent ainsi, notamment, les adresses physiques / électroniques,
				noms d’utilisateur, contenus générés par les utilisateurs, informations
				d’ordre financier, adresse IP, etc.
		</p>
		<p className="mt-4">
				« Utilisateur(s) » désigne nos prospects, clients, prestataires de
				service, partenaires, sous-traitants, candidats et de façon générale
				toute personne avec laquelle nous sommes en contact (ci-après «vous » ou
				« votre »).
		</p>
		<p className="mt-4">
				«Traitement» désigne toute opération ou tout ensemble d'opérations,
				effectuée(s) de façon automatisée ou non, sur des Données à Caractère
				Personnel ou des ensembles de Données à Caractère Personnel, telle(s)
				que la collecte, l'enregistrement, l'organisation, la structuration, la
				conservation, l'adaptation ou la modification, l'extraction, la
				consultation, l'utilisation, la divulgation par transmission, diffusion
				ou toute autre forme de mise à disposition, le rapprochement ou
				l'interconnexion, la limitation, l'effacement ou la destruction.
		</p>
		<p className="font-medium mt-4">
				1. Quels sont les principes applicables au traitement de Données à
				Caractère Personnel ?
		</p>
		<p className="mt-4">
				Chaque entité juridique de Bureau Veritas qui collecte ou utilise vos
				Données à Caractère Personnel pour les besoins de son activité agit en
				qualité de « responsable du traitement ». Conformément aux lois
				relatives à la protection des données, le responsable du traitement doit
				définir les finalités et les moyens du traitement de vos Données à
				Caractère Personnel. Cela signifie que nous sommes responsables des
				Données à Caractère Personnel que vous partagez avec nous.
		</p>
		<p className="mt-4">
				Nous traitons vos Données à Caractère Personnel conformément aux
				exigences légales applicables et, en particulier, au règlement général
				européen sur la protection des données 2016/679 (« RGPD ») en date du 27
				avril 2016 applicable à partir du 25 mai 2018 dans l’Union Européenne.
		</p>
		<p className="mt-4">En particulier, nous nous engageons à :</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
					Obtenir et traiter vos Données à Caractère Personnel de manière licite
					et loyale ;
			</li>
			<li>
					Obtenir vos Données à Caractère Personnel pour des finalités
					spécifiques, explicites et légitimes, et en conséquence ne pas les
					traiter d’une manière incompatible avec ces finalités;
			</li>
			<li>
					Ne traiter que les Données à Caractère Personnel qui sont adéquates,
					pertinentes et limitées à ce qui est nécessaire au regard des
					finalités pour lesquelles elles ont été obtenues et sont traitées ;
			</li>
			<li>
					Nous assurer que vos Données à Caractère Personnel sont exactes,
					exhaustives et si nécessaires tenues à jour ;
			</li>
			<li>
					Stocker vos Données à Caractère Personnel pendant une durée n’excédant
					pas celle nécessaire au regard des finalités pour lesquelles elles ont
					été obtenues et sont traitées, et conformément aux lois applicables en
					matière de prescription.
			</li>
		</ul>
		<p className="font-medium mt-4">
				2. Quelles Données à Caractère Personnel collectons-nous ? Pourquoi et
				comment les utilisons-nous ?
		</p>
		<p className="mt-4">
				Afin de vous donner une idée plus précise de l’utilisation qui est faite
				de vos Données à Caractère Personnel, nous avons conçu un tableau dans
				lequel vous pouvez trouver l’information en recherchant à l’aide du
				.contexte / de la finalité de la collecte de données.
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
					1) Colonne 1 - Dans quelles hypothèses vos données sont-elles
					collectées ? Cette colonne détaille les activités ou les hypothèses
					dans lesquelles vous pouvez être impliqué lorsque nous utilisons ou
					collections vos Données à Caractère Personnel.
			</li>
			<li>
					2) Colonne 2 – Quels types de Données à Caractère Personnel vous
					concernant pouvons-nous détenir? Cette colonne détaille les types de
					Données à Caractère Personnel que nous collectons.
			</li>
			<li>
					3) Colonne 3 – Comment et à quelles fins les utilisons-nous ? Cette
					colonne détaille l’utilisation qui est faite de vos Données à
					Caractère Personnel, et les finalités de leur collecte.
			</li>
			<li>
					4) Colonne 4 – Quel est le fondement juridique nous permettant
					d’utiliser vos Données à Caractère Personnel ? Cette colonne recense
					les raisons pour lesquelles nous pouvons utiliser vos Données à
					Caractère Personnel
			</li>
		</ul>
		<p className="mt-4">
				Lorsque nous collectons des données par le biais de formulaires (y
				compris électroniques), nous vous indiquerons les champs obligatoires à
				l’aide d’un astérisque. L’absence de réponse à ces champs pourrait vous
				empêcher d’accéder à un service.
		</p>
		<p className="mt-4">
				En qualité d'Utilisateur de nos services et de nos sites web, vous
				pouvez partager vos Données à Caractère Personnel avec nous et nous
				pouvons être amenés à les collecter de différentes manières.
		</p>
		<p className="mt-4">
				Nous pouvons collecter vos Données à Caractère Personnel ou les recevoir
				de votre part via nos sites web, nos formulaires, nos prestations de
				service ou autrement. Dans certains cas, vous nous fournirez vos Données
				à Caractère Personnel directement (par exemple, en nous contactant
				depuis nos sites web, en nous demandant un devis, ou en vous abonnant à
				l’un de nos services), dans d’autres cas, nous les collecterons
				indirectement (par exemple, à l'aide de cookies nous permettant de
				comprendre votre utilisation de nos sites web), et parfois, nous
				obtiendrons vos données de la part de tiers, y compris d'autres entités
				de Bureau Veritas.
		</p>
		<table className="table-auto alphaBlur w-full my-4">
			<thead className="border-b-2">
				<tr className="bg-gray-100">
					<th className="w-1/4 font-normal px-2 py-2">
							Dans quel cadre collectons-nous vos Données à Caractère Personnel
							?
					</th>
					<th className="w-1/4 font-normal px-2 py-2">
							Quels types de Données à Caractère Personnel vous concernant
							pouvons-nous détenir ?{" "}
					</th>
					<th className="w-1/4 font-normal px-2 py-2">
							Comment et pour quelles finalités les utilisons-nous ?{" "}
					</th>
					<th className="w-1/4 font-normal px-2 py-2">
							Quel est le fondement juridique nous permettant de traiter vos
							Données à Caractère Personnel ?
					</th>
				</tr>
			</thead>
			<tbody className="divide-y-2">
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<p className="font-semibold">Abonnement à un service</p>
							Informations collectées lors de l'abonnement à l'un de nos
							services
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Nom et prénom</li>
							<li>Adresse email</li>
							<li>Adresse postale (pour la facturation)</li>
							<li>Numéro de téléphone</li>
							<li>
									Détails de la transaction (détails relatifs à l'abonnement
									souscrit, au numéro de transaction, à l'historique des
									services, etc.)
							</li>
							<li>Informations relatives au paiement</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">Pour:</span>
						<ul className="list-disc pl-7">
							<li>Vous adresser un devis</li>
							<li>Traiter et suivre votre abonnement</li>
							<li>Gérer le paiement de votre abonnement</li>
							<li>
									Gérer tout contact que vous avez avec nous concernant votre
									abonnement
							</li>
							<li>Gérer tout litige relatif à un abonnement</li>
							<li>Réaliser des statistiques</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">
										Exécution d’un contrat :
								</span>
									Pour vous fournir le service demandé (abonnement){" "}
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<p className="font-semibold">Fourniture d’un service</p>
							Informations collectées durant la fourniture d’un service auxquel
							vous avez souscrit
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Nom et prénom</li>
							<li>Adresse email</li>
							<li>Numéro de téléphone</li>
							<li>
									Données relatives à la relation commerciale : informations sur
									le service demandé, la durée de la relation, les
									correspondances échangées avec le client, etc.)
							</li>
							<li>
									Informations sur le compte personnel permettant d’accéder à la
									plateforme Building in One
							</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">Pour:</span>
						<ul className="list-disc pl-7">
							<li>Vous fournir le service demandé</li>
							<li>
									Vous permettre d’accéder à la Plateforme Building in One et de
									l’utiliser, lorsque vous avez demandé ce service
							</li>
							<li>
									Gérer tout contact que vous avez avec nous pendant la durée de
									la prestation de service
							</li>
							<li>Gérer la relation commerciale que nous avons avec vous</li>
							<li>Gérer toute demande ou tout litige relatif à un service</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">
										Exécution d’un contrat :
								</span>
									Pour vous fournir le service demandé (abonnement)
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<p className="font-semibold">
								Conclusion d'un contrat spécifique
						</p>
							Informations collectées lorsque vous nous fournissez un produit ou
							un service
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Nom et prénom</li>
							<li>Adresse email</li>
							<li>Adresse postale</li>
							<li>Titre</li>
							<li>Raison sociale de la société</li>
							<li>Numéro de téléphone</li>
							<li>Détails de facturation</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">Pour:</span>
						<ul className="list-disc pl-7">
							<li>Créer et gérer nos dossiers fournisseur/prestataire</li>
							<li>
									Gérer les contrats, les bons de commande, les livraisons, les
									factures et la comptabilité
							</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">
										Exécution d’un contrat :
								</span>
									Pour gérer la relation contractuelle qui nous lie à vous{" "}
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<span className="font-semibold">
								Abonnement à la lettre d'information et aux messages commerciaux
						</span>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Nom et prénom</li>
							<li>Adresse email</li>
							<li>Titre</li>
							<li>Raison sociale de la société</li>
							<li>Numéro de téléphone</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">Pour:</span>
						<ul className="list-disc pl-7">
							<li>
									Vous envoyer des messages publicitaires (lorsque vous nous
									l'avez demandé)
							</li>
							<li>
									Maintenir une liste à jour des contacts ne souhaitant pas être
									contactés, si vous nous en avez fait la demande
							</li>
							<li>Réaliser des analyses ou collecter des statistiques</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">Consentement:</span>&nbsp;
									Aucun message publicitaire ne vous sera envoyé si vous n'avez
									pas accepté d'en recevoir
							</li>
							<li>
								<span className="font-semibold">Intérêt légitime :</span>
									Pour personnaliser nos messages publicitaires et comprendre
									leur efficacité, nous aider à mieux comprendre vos besoins et
									améliorer nos services, développer notre activité
							</li>
							<li>
								<span className="font-semibold">
										Respect d’une obligation légale :
								</span>
									Pour conserver vos coordonnées sur une liste de contacts ne
									souhaitant pas être contactés, si vous nous avez demandé de ne
									plus vous envoyer de messages publicitaires
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<p className="font-semibold">Navigation en ligne</p>
							Les informations collectées par des cookies ou des technologies
							similaires « Cookies » * lors de votre navigation sur les sites
							web de Bureau Veritas.
						<p className="mt-12">
								* Les cookies sont de petits fichiers textes stockés sur votre
								terminal (ordinateur, tablette ou mobile) lorsque vous naviguez
								sur Internet.{" "}
						</p>
					</td>
					<td className="w-1/4 pt-8 pb-5 align-top">
							Données relatives à votre utilisation de notre site web :
						<ul className="list-disc pl-10 py-3">
							<li>La page consultée avant d’atterrir sur notre site</li>
							<li>Les pages consultées sur notre site</li>
							<li>La durée de votre visite</li>
						</ul>
							Informations techniques :
						<ul className="list-disc pl-10 py-3">
							<li>Adresse IP</li>
							<li>Informations sur votre navigateur</li>
							<li>Informations sur votre terminal</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">
								Pour personnaliser les services que nous vous proposons :
						</span>
						<ul className="list-disc pl-7 my-4">
							<li>
									pour vous envoyer des suggestions ou des messages
									publicitaires sur la base de vos centres d'intérêt
							</li>
							<li>pour afficher notre site web de façon personnalisée</li>
						</ul>
						<span className="font-semibold">
								Pour permettre le bon fonctionnement de notre site web :
						</span>
						<ul className="list-disc pl-7 my-4">
							<li>afficher correctement le contenu,</li>
							<li>personnaliser l'interface, comme le choix de langue</li>
							<li>
									paramètres de votre terminal, y compris la résolution de votre
									écran
							</li>
							<li>
									amélioration de notre site web, par exemple en testant de
									nouvelles idées
							</li>
						</ul>
						<span className="font-semibold">
								Pour faire en sorte que notre site web soit sécurisé et sûr et
								pour vous protéger de la fraude ou d'une mauvaise utilisation de
								nos sites web ou de nos services
						</span>
						<br />
						<br />
						<span className="font-semibold">
								Pour réaliser des statistiques :
						</span>
						<ul className="list-disc pl-7 my-4">
							<li>Pour améliorer nos offres</li>
							<li>Pour savoir comment vous avez découvert notre site web</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">Intérêt légitime :</span>
									Pour nous assurer que nous vous fournissons des sites web et
									des messages qui fonctionnent correctement, qui sont sécurisés
									et sûrs et qui font l’objet d’améliorations en continu, pour
									développer notre activité
							</li>
							<li>
								<span className="font-semibold">Consentement : </span>
									Pour les cookies qui ne sont pas nécessaires au fonctionnement
									de notre site web
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<p className="font-semibold">Demandes d’information</p>
							Informations collectées lorsque vous nous posez des questions au
							sujet de nos services
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Nom et prénom</li>
							<li>Adresse email</li>
							<li>Titre</li>
							<li>Raison sociale de la société</li>
							<li>Numéro de téléphone</li>
							<li>
									Autres informations que vous avez partagées avec nous au sujet
									de votre demande d’information
							</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">Pour:</span>
						<ul className="list-disc pl-7">
							<li>Pour répondre à vos demandes d’information</li>
							<li>
									Si nécessaire, pour vous connecter aux services appropriés
							</li>
							<li>Pour des besoins statistiques</li>
							<li>
									Pour vous adresser un devis lorsque vous nous le demandez
							</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">Consentement : </span>Pour
									traiter votre demande d’information
							</li>
							<li>
								<span className="font-semibold">
										Exécution d’un contrat :
								</span>
									Pour vous transmettre les informations requises dans le cadre
									d'un contrat conclu entre vous et Bureau Veritas
							</li>
							<li>
								<span className="font-semibold">Intérêt légitime :</span>
									Pour nous permettre de mieux comprendre les besoins et
									attentes de nos Utilisateurs et ainsi améliorer nos services
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<p className="font-semibold">Candidature à un emploi</p>
							Informations collectées lorsque vous postulez auprès de Bureau
							Veritas pour un emploi
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Renseignements permettant votre identification</li>
							<li>Coordonnées</li>
							<li>CV (diplômes, emplois précédents, etc.)</li>
							<li>Notes prises lors d’entretiens</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">Pour:</span>
						<ul className="list-disc pl-7">
							<li>Revoir les candidatures</li>
							<li>Gérer le processus de recrutement</li>
							<li>Recruter des candidats</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">Consentement : </span> Pour
									traiter votre candidature
							</li>
							<li>
								<span className="font-semibold">Intérêt légitime :</span>
									Pour trouver le meilleur candidat correspondant à nos offres
									d'emploi
							</li>
						</ul>
					</td>
				</tr>
			</tbody>
		</table>
		<p className="font-medium">3. Notification et Consentement</p>
		<p className="mt-4">
				Les lois relatives à la protection des Données à Caractère Personnel de
				nombreuses juridictions ne requièrent généralement pas que nous
				obtenions votre consentement préalablement à la collecte, l'utilisation
				ou la divulgation de vos Données à Caractère Personnel pour les
				finalités listées dans le tableau ci-dessus. Cependant, lorsque la loi
				le requiert, nous nous efforcerons d'obtenir votre consentement.
		</p>
		<p className="font-medium mt-4">
				4. Quand divulguons-nous vos Données à Caractère Personnel ?
		</p>
		<p className="mt-4">
				Certaines de vos Données à Caractère Personnel pourront être
				accessibles:
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
					Au sein de Bureau Veritas, à tout membre du Groupe Bureau ; =&gt;
					Seules les personnes ayant à en connaître auront accès à vos Données à
					Caractère Personnel et seulement dans la mesure du nécessaire pour
					vous fournir les services que vous avez demandés, ou dans le cadre
					d'un contrat conclu entre vous et Bureau Veritas, ou avec votre
					consentement (en particulier pour les messages publicitaires).
			</li>
			<li>
					A des prestataires de service de confiance agissant en qualité de
					sous-traitants (c'est-à-dire des sous-traitants de Données à Caractère
					Personnel) qui fourniront certains services nécessaires à la poursuite
					des finalités énoncées ci-dessus pour notre compte (services
					marketing, services d’hébergement, maintenance de base de données,
					etc.).  =&gt; Nous ne leur fournirons que les informations dont ils
					auront besoin pour fournir ces services, et exigerons qu’ils
					n’utilisent pas vos Données à Caractère Personnel à d'autres fins. Ces
					fournisseurs de services n'agiront que sur instruction de Bureau
					Veritas et seront contractuellement tenus d'assurer le même niveau de
					sécurité et de confidentialité pour vos Données à Caractère Personnel
					que celui que Bureau Veritas est tenu d'assurer et de se conformer aux
					lois et règlements relatifs à la protection des données à caractère
					personnel applicables.
			</li>
		</ul>
		<p className="mt-4">
				Par ailleurs, Bureau Veritas pourra être amenée à partager vos Données à
				caractère personnel avec des tiers :
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
					Afin de protéger les droits, les biens ou la sécurité de Bureau
					Veritas, de ses Utilisateurs, salariés ou d'autres personnes, ou
			</li>
			<li>
					Dans l’hypothèse d’une fusion ou de la vente d'actifs de la société
					(dans ce cas, vos Données à Caractère Personnel seront divulguées à
					l'acquéreur potentiel) ; ou
			</li>
			<li>
					Pour se conformer à une obligation légale ou répondre à une action en
					justice d’une nature quelconque, à l'ordonnance d'un tribunal, à une
					action judiciaire ou pour mettre en œuvre les mesures exécutoires
					requises par les autorités compétentes ; ou
			</li>
			<li>
					A toutes autres fins requises par la législation applicable ou avec
					votre accord préalable.
			</li>
		</ul>
		<p className="font-medium mt-4">
				5. Où stockons-nous vos Données à Caractère Personnel ?
		</p>
		<p className="mt-4">
				Le Groupe Bureau Veritas disposant d’entités à travers le monde entier,
				et certains de nos prestataires de service étant situés à l'étranger,
				nous pouvons être amenés à transférer les données collectées auprès de
				vous, d'un pays situé dans l'Espace Economique Européen (« EEE ») vers
				un pays hors de l'EEE.
		</p>
		<p className="mt-4">
				Lorsque Bureau Veritas transfère des Données à Caractère Personnel hors
				de l'EEE, ce transfert sera toujours effectué de manière légale et
				sécurisée :
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
					Soit en transférant les Données à Caractère Personnel à un
					destinataire aux Etats-Unis qui est certifié au Privacy Shield (ou «
					bouclier de protection des données »), un tel mécanisme étant réputé
					assurer un niveau de protection adéquat de vos données par décision
					d'adéquation de la Commission européenne ;
			</li>
			<li>
					Soit en signant les clauses contractuelles types (signées par Bureau
					Veritas et ses filiales ou par Bureau Veritas et ses prestataires de
					service) qui ont été approuvées par la Commission européenne comme
					fournissant un niveau de protection adéquat de vos Données à Caractère
					Personnel
			</li>
		</ul>
		<p className="font-medium mt-4">
				6. Quelle est la durée de conservation de vos Données à Caractère
				Personnel ?
		</p>
		<p className="mt-4">
				Nous conserverons vos Données à Caractère Personnel seulement pendant la
				durée nécessaire pour la réalisation des finalités du traitement pour
				lesquelles elles ont été collectées (typiquement, la durée du contrat).
				Nous pouvons cependant être amenés à conserver vos données pendant un
				délai plus long, notamment en application de dispositions légales ou
				réglementaires spécifiques et/ou afin de nous conformer aux délais de
				prescription applicables. Dans le cas où nous conserverions vos données
				plus longtemps pour d’autres raisons, nous vous informerons de ces
				raisons et du délai de conservation applicable lors de la collecte de
				vos Données à Caractère Personnel.
		</p>
		<p className="mt-4">
				Pour déterminer la durée de conservation de vos Données à Caractère
				Personnel, nous utilisons notamment les critères suivants :
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
					Lorsque vous vous abonnez à un service, nous conservons vos Données à
					Caractère Personnel pendant la durée de la relation contractuelle qui
					nous unit, puis conformément aux lois en matière de prescription ;
			</li>
			<li>
					Lorsque vous nous contactez pour une demande d’information, nous
					conservons vos Données à Caractère Personnel le temps nécessaire au
					traitement de votre demande ;
			</li>
			<li>
					Lorsque vous avez accepté de recevoir des messages publicitaires, nous
					conservons vos Données à Caractère Personnel jusqu'à ce que vous vous
					désabonniez ou nous demandiez de les supprimer ou après une période
					d'inactivité (absence d'interaction active avec nous) définie
					conformément aux réglementations et recommandations locales ;
			</li>
			<li>
					Lorsque des cookies sont déposés sur votre ordinateur, nous les
					conservons aussi longtemps que nécessaire pour la réalisation de leur
					finalité et pour une période définie conformément aux réglementations
					et recommandations locales. Pour les candidats, nous conservons vos
					Données à Caractère Personnel conformément aux lois locales
					applicables en matière de prescription.
			</li>
		</ul>
		<p className="font-medium mt-4">
				7. Comment vos Données à Caractère Personnel sont-elles protégées ?
		</p>
		<p className="mt-4">
				Nous nous engageons à conserver vos Données à Caractère Personnel en
				sécurité et à prendre toutes les précautions raisonnables à cette fin.
				Nous mettons en œuvre toutes les mesures organisationnelles et
				techniques nécessaires au regard de cette Politique de Protection des
				Données à Caractère Personnel et des lois et règlements applicables pour
				protéger vos Données à Caractère Personnel contre tout accès non
				autorisé, toute modification, toute divulgation, toute perte ou toute
				destruction. Nous exigeons contractuellement de nos prestataires de
				service qui traitent vos Données à Caractère Personnel pour nous, qu’ils
				s’engagent de la même façon.
		</p>
		<p className="font-medium mt-4">8. Vos droits</p>
		<p className="mt-4">
				Conformément aux lois et règlements relatif à la protection des Données
				à Caractère Personnel applicables, vous bénéficiez d'un certain nombre
				de droits en ce qui concerne vos Données à Caractère Personnel, à savoir
				:
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
				<span className="font-semibold">
						Un droit d'accès et d'information :{" "}
				</span>
					vous avez le droit d'être informé du traitement auquel sont soumises
					vos Données à Caractère Personnel, de façon concise, transparente,
					intelligible et facilement accessible. Vous avez également le droit
					(i) de savoir si, oui ou non, vos Données à Caractère Personnel sont
					traitées, et dans l'affirmative, (ii) d'avoir accès à ces Données à
					Caractère Personnel et d'en obtenir une copie.
			</li>
			<li>
				<span className="font-semibold">Un droit de rectification : </span>
					vous avez le droit d'obtenir la rectification de vos Données à
					Caractère Personnel erronées. Vous avez également le droit de
					compléter des Données à Caractère Personnel incomplètes, y compris en
					fournissant une déclaration supplémentaire.
			</li>
			<li>
				<span className="font-semibold">
						Un droit à l'effacement (« droit à l'oubli ») :{" "}
				</span>
					dans certains cas, vous avez le droit d'obtenir l'effacement de vos
					Données à Caractère Personnel. Cependant, cela ne constitue pas un
					droit absolu et Bureau Veritas peut s'appuyer sur un fondement
					juridique ou avoir une raison légitime pour conserver vos Données à
					Caractère Personnel.
			</li>
			<li>
				<span className="font-semibold">
						Un droit à la limitation du traitement :{" "}
				</span>
					dans certains cas, vous avez le droit de limiter le traitement de vos
					Données à Caractère Personnel.
			</li>
			<li>
				<span className="font-semibold">
						Un droit à la portabilité des données :{" "}
				</span>
					vous avez le droit de recevoir les Données à Caractère Personnel que
					vous avez fournies à Bureau Veritas, dans un format structuré,
					couramment utilisé et lisible par une machine, et vous avez le droit
					de transférer ces données à un autre responsable du traitement sans
					entrave de la part de Bureau Veritas. Le présent droit ne s'applique
					que lorsque le traitement de vos Données à Caractère Personnel est
					fondé sur votre consentement ou sur un contrat et que ce traitement
					est automatisé.
			</li>
			<li>
				<span className="font-semibold">
						Un droit de s’opposer au traitement :{" "}
				</span>
					vous avez le droit de vous opposer à tout moment au traitement de vos
					Données à Caractère Personnel. Lorsque vous exercez votre droit
					d’opposition, pour des raisons tenant à votre situation particulière,
					à un traitement fondé sur l'intérêt légitime de Bureau Veritas, Bureau
					Veritas pourra toutefois invoquer des motifs légitimes et impérieux
					pour poursuivre le traitement auquel vous vous opposez.
			</li>
			<li>
				<span className="font-semibold">
						Le droit de révoquer, à tout moment, votre consentement au
						traitement fondé sur votre consentement :
				</span>
					&nbsp;vous pouvez révoquer votre consentement au traitement de vos
					Données à Caractère Personnel, lorsque ce traitement est fondé sur
					votre consentement. La révocation de votre consentement n'affecte pas
					la légalité du traitement effectué sur la base de ce consentement
					préalablement à sa révocation.
			</li>
			<li>
				<span className="font-semibold">
						Le droit d’effectuer une réclamation auprès de l’autorité de
						contrôle :
				</span>
					&nbsp;vous avez le droit de contacter votre Autorité de Contrôle pour
					la Protection des Données à Caractère Personnel pour vous plaindre des
					pratiques relatives à la protection des Données à Caractère Personnel
					de Bureau Veritas.
			</li>
			<li>
				<span className="font-semibold">
						Le droit de donner des instructions en ce qui concerne l'utilisation
						de vos données après votre décès :
				</span>
					&nbsp;vous avez le droit de donner des instructions à Bureau Veritas
					en ce qui concerne l’utilisation de vos Données à Caractère Personnel
					après votre décès.
			</li>
			<li>
				<span className="font-semibold">
						Le droit d'obtenir une copie des clauses contractuelles types
				</span>
					&nbsp;signées par Bureau Veritas lorsque vos Données à Caractère
					Personnel sont transférées en dehors de l'EEE.
			</li>
		</ul>
		<p className="mt-4">
				Pour de plus amples informations sur vos droits et pour les exercer,
				veuillez consulter le point de contact défini à l'Article 9 de la
				présente Politique de Protection des Données à Caractère Personnel.
		</p>
		<p className="mt-4">
				Veuillez noter que nous pourrons être amenés à vous demander de prouver
				votre identité et de justifier de manière complète les informations
				figurant dans votre demande, avant de traiter votre demande.
		</p>
		<p className="font-medium mt-4">9. Contact</p>
		<p className="mt-4">
				Pour toutes questions ou inquiétudes portant sur notre traitement et
				utilisation de vos Données à Caractère Personnel, ou si vous souhaitez
				exercer l’un de vos droits mentionnés ci-dessus, veuillez nous contacter
				à l'adresse suivante :
			<a
				className="hover:underline"
				href="https://personaldataprotection.bureauveritas.com/home/1.1.14/#/home"
			>
					https://personaldataprotection.bureauveritas.com
			</a>
		</p>
	</div>
);

export default RYBPrivacyFR;
