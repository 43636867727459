import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";

const data = [
	{ letter: "F", frequency: 0.02288 },
	{ letter: "G", frequency: 0.02015 },
	{ letter: "H", frequency: 0.06094 },
	{ letter: "I", frequency: 0.06966 },
	{ letter: "J", frequency: 0.00153 },
	{ letter: "K", frequency: 0.00772 },
	{ letter: "L", frequency: 0.04025 },
	{ letter: "M", frequency: 0.02406 },
	{ letter: "N", frequency: 0.06749 },
	{ letter: "O", frequency: 0.07507 },
	{ letter: "P", frequency: 0.01929 },
	{ letter: "Q", frequency: 0.00095 },
	{ letter: "R", frequency: 0.02758 },
	{ letter: "S", frequency: 0.00978 },
	{ letter: "T", frequency: 0.0236 }
];
const verticalMargin = 30;

// accessors
const getLetter = (d) => d.letter;
const getLetterFrequency = (d) => Number(d.frequency) * 100;

function TrendChart({ width, height, events = false }) {
	// bounds
	const xMax = width;
	const yMax = height - verticalMargin;

	// scales, memoize for performance
	const xScale = useMemo(
		() => scaleBand({
			range: [0, xMax],
			round: true,
			domain: data.map(getLetter),
			padding: 0.4
		}),
		[xMax]
	);
	const yScale = useMemo(
		() => scaleLinear({
			range: [yMax, 0],
			round: true,
			domain: [0, Math.max(...data.map(getLetterFrequency))]
		}),
		[yMax]
	);

	return width < 10 ? null : (
		<svg width={width} height={height}>
			<rect width={width} height={height} fill="url(#teal)" rx={14} />
			<Group top={verticalMargin}>
				{data.map((d) => {
					const letter = getLetter(d);
					const barWidth = xScale.bandwidth();
					const barHeight = yMax - (yScale(getLetterFrequency(d)) ?? 0);
					const barX = xScale(letter);
					const barY = yMax - barHeight;
					return (
						<Bar
							key={`bar-${letter}`}
							x={barX}
							y={barY}
							width={barWidth}
							height={barHeight}
							fill="rgba(28, 63, 170)"
							onClick={() => {
								if (events) alert(`clicked: ${JSON.stringify(Object.values(d))}`);
							}}
						/>
					);
				})}
			</Group>
		</svg>
	);
}

TrendChart.propTypes = {
	events: PropTypes.bool,
	height: PropTypes.any,
	width: PropTypes.number
};

export default TrendChart;
