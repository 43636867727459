import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import SpeedIcon from "@mui/icons-material/Speed";
import HelpIcon from "@mui/icons-material/Help";

import { hierarchy } from "@visx/hierarchy";
import { Switch } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

import { mean } from "d3";

import VerticalGauge from "../graphs/VerticalGauge";
import ProgressBarFlat from "../graphs/ProgressBarFlat";

import { getMarkerColor } from "../../apps/configs/SupplyRConfig";
import SupplyRIndicator from "../graphs/SupplyRIndicator";
import AlphaTooltip from "../tooltips/AlphaTooltip";

const SupplyRSwitch = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase.Mui-checked": {
		color: "#3D49AB",
		"&:hover": {
			backgroundColor: alpha("#3D49AB", theme.palette.action.hoverOpacity)
		}
	},
	"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
		backgroundColor: "#3D49AB"
	}
}));

const SupplyRRiskIndex = ({
	appSlice,
	dataset,
	locales,
	consolidated,
	setConsolidated,
	domainsOptions,
	resilienceText = "resilience",
	resistanceText = "resistance",
	responsivenessText = "responsiveness",
	getMaturityColor = getMarkerColor,
	noAdditionalProgressBars
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const { data } = useSelector(appSlice.selectDatasets)[dataset];
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const supplyChainDataTree = useSelector(appSlice.selectDatasets)?.supplyChainTree?.data;
	const clientParameters = useSelector(appSlice.selectClientParameters);

	const hierarchyRootNode = useMemo(() => {
		if (!supplyChainDataTree ||
			supplyChainDataTree.length === 0) {
			return null;
		}

		return hierarchy(supplyChainDataTree);
	}, [supplyChainDataTree]);

	const selectedNode = hierarchyRootNode?.descendants()
	.find((d) => Object.keys(activeRefines)
	.reduce((result, refine) => {
		if (refine !== "isCleared") {
			return (
				result && (refine === "projectName" ||
							(Array.isArray(activeRefines[refine]) ? (activeRefines[refine]
							.indexOf(d.data[refine]) !== -1) : (d.data[refine] === activeRefines[refine]))
				));
		}

		return result;
	}, true));

	const resilience = consolidated
		? ((selectedNode?.sum((d) => d.resilience)?.value ?? 0) /
		(selectedNode?.descendants().filter((d) => d.data.resilience).length ?? 0))?.toFixed(0) ?? 0
		: data?.[0]?.resilienceScore?.toFixed(0) ?? "NaN";

	const resistance = consolidated
		? ((selectedNode?.sum((d) => d.resistance)?.value ?? 0) /
		(selectedNode?.descendants().filter((d) => d.data.resilience).length ?? 0))?.toFixed(0) ?? 0
		: data?.[0]?.resistanceScore?.toFixed(0) ?? "NaN";

	const responsiveness = consolidated
		? ((selectedNode?.sum((d) => d.responsiveness)?.value ?? 0) /
		(selectedNode?.descendants().filter((d) => d.data.resilience).length ?? 0))?.toFixed(0) ?? 0
		: data?.[0]?.responsivenessScore?.toFixed(0) ?? "NaN";

	return (
		<div data-test="SupplyRRiskIndex" className="flex flex-col">
			{(!clientParameters?.supplyAudits && activeRefines.module === undefined) && <div className="self-end flex items-center gap-x-2">
				<p className="text-small">{t("analysis.globalriskindex.consolidated.label")}</p>
				<AlphaTooltip placement="top-start"
					title={
						<div className={"flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90 bg-supplyr_primary-accent rounded-2xl w-48"}>
							<span className="font-bold">{t("tooltip.title")}</span>
							{t("analysis.globalriskindex.consolidated.info")}
						</div>
					}
				>
					<HelpIcon data-test="analysis-globalriskindex-consolidated" fontSize="small" className={" text-supplyr_primary-accent "} />
				</AlphaTooltip>
				<SupplyRSwitch
					checked={consolidated}
					onChange={() => setConsolidated(!consolidated)}
				/>
			</div>}
			<div className="flex">
				<div className="relative">
					{activeRefines.module !== undefined ?
						<SupplyRIndicator
							score={resilience}
							logo={domainsOptions[activeRefines.module]?.logo}
							locale={domainsOptions[activeRefines.module]?.locale}
							locales={locales} />
						:
						<VerticalGauge score={resilience}>
							<div className="flex flex-col items-center justify-center text-white font-light h-full">
								<SpeedIcon className="text-xl text-white" />
								<p className="text-sm">{t(`analysis.globalriskindex.${resilienceText}`)}</p>
								<p className="text-3xl">{resilience}%</p>
							</div>
						</VerticalGauge>
					}
				</div>
				{!noAdditionalProgressBars && <div className="relative ml-6 flex flex-col mt-4">
					<div className="mb-2">
						<span className="flex items-center mb-1">
							<VerticalAlignCenterIcon fontSize="small" className="pl-1 mr-2" />
							<p className="text-medium font-semibold ">
								{t(`analysis.globalriskindex.${resistanceText}`)}
							</p>
						</span>
						<span className="flex items-center">
							<ProgressBarFlat strokeColor={getMaturityColor(resistance)} endPoint={resistance} />
							<p className="h-full ml-2 text-medium font-normal">
								{resistance}%
							</p>
						</span>
					</div>

					<div>
						<span className="flex items-center mb-1">
							<TimelapseIcon fontSize="small" className="pl-1 mr-2" />
							<span className="text-medium font-semibold">
								{t(`analysis.globalriskindex.${responsivenessText}`)}
							</span>
						</span>
						<span className="flex items-center">
							<ProgressBarFlat strokeColor={getMaturityColor(responsiveness)} endPoint={responsiveness} />
							<span className="h-full ml-2 text-medium font-normal">
								{responsiveness}%
							</span>
						</span>
					</div>
				</div>
				}

			</div>
		</div>
	);
};

SupplyRRiskIndex.propTypes = {
	appSlice: PropTypes.object,
	consolidated: PropTypes.bool,
	dataset: PropTypes.string,
	locales: PropTypes.string,
	domainsOptions: PropTypes.object,
	resilienceText: PropTypes.string,
	resistanceText: PropTypes.string,
	responsivenessText: PropTypes.string,
	setConsolidated: PropTypes.func,
	getMaturityColor: PropTypes.func,
	noAdditionalProgressBars: PropTypes.bool
};

export default SupplyRRiskIndex;
