import DashboardIcon from "@mui/icons-material/Dashboard";
import MapIcon from "@mui/icons-material/Map";
import BusinessIcon from "@mui/icons-material/Business";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PinDropIcon from "@mui/icons-material/PinDrop";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import StarHalfIcon from "@mui/icons-material/StarHalf";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} slice Name of the slice related to the app created and references in the store ./store.js
 * @param {String} logo Label of the menu, if none, only the picto
 */
export const appDefinition = {
	name: "COMPASS",
	subName: "",
	launcherID: "COMPASS",
	slice: "compass",
	locales: "translationsCompass",
	theme: "compass",
	favicon: "/images/Compass/favicon.ico",
	logo: "/images/Compass/CompassAppIconSideBar.png",
	clientAccessor: "project"
};

/**
 * Set up  menu options to display
 *
 * @param {String} menu ID of the menu (use for the initial menu definition and the render of the app)
 * @param {String} type Route or App = Route is a link to jump to a new url, App is a internal link to the App to display specific information
 * @param {String} url [OPTIONAL] If type == url, the new url to link the menu (it has to be routed in ./App.js)
 * @param {String} label [OPTIONAL] Label of the menu, if none, only the picto
 * @param {String} sectionTitleWhite [OPTIONAL] Section title in white displayed on top of the page when the menu is clicked
 * @param {String} sectionTitleBlack [OPTIONAL] Section title in black displayed on top of the page when the menu is clicked
 * @param {Component} picto Material UI Icon to represent the field in the select, to be imported in this file
 */
export const menuOptions = [
	{
		menu: "HOME",
		type: "App",
		initial: true,
		label: "Home",
		clearRefine: ["module", "siteId", "critical"],
		picto: DashboardIcon
	},
	{
		menu: "MAP",
		type: "App",
		initial: true,
		label: "Map",
		clearRefine: ["siteId", "critical"],
		picto: MapIcon
	},
	{
		menu: "NCs",
		type: "App",
		initial: true,
		label: "Nonconformities",
		clearRefine: ["siteId", "critical"],
		picto: FlagOutlinedIcon
	},
	{
		menu: "SITEs",
		type: "App",
		initial: true,
		label: "Your Sites",
		clearRefine: ["critical"],
		picto: PinDropIcon
	}
	// ,
	// {
	//	menu: "INFO",
	//	type: "App",
	//	label: "Info",
	//	noMobileMenu: true,
	//	picto: ContactSupportIcon
	// }
];

/**
 * Name of the dataset with the client parameters
 */
const CompassClientParameters = "CompassClientParameters";

/**
 * Name of the dataset Form for the  web app
 */
const CompassModuleAggregates = "CompassModuleAggregates";

/**
 * Name of the dataset Checkpoint for the web app
 */
export const CompassCheckpoints = "CompassCheckpoints";

/**
  * Query to load and update the non conformities
  */
export const listNonConformityQuery = [
	{
		$match: {
			nc: { $ne: 0 }
		}
	},
	{
		$project: {
			publicationDate: 1,
			assignmentTechnicalid: 1,
			question: 1,
			answer: "$rating",
			observation: "$observation",
			auditedCompany: "$site.auditedCompany",
			siteType: "$additionalInfo.siteType",
			critical: 1,
			module: 1,
			criticality: { $cond: [{ $eq: ["$critical", true] }, "Critical", "Non Critical"] },
			productCategory: "$additionalInfo.productCategoryFilter",
			country: 1,
			city: 1,
			section1Label: 1,
			proposedActions: "$checkpoint.proposedActions"
		}
	}
];

/**
 * Array of the full list of facets to refine
 */
const listFacetsToRefine = [
	"project",
	"additionalInfo.finalRating",
	"additionalInfo.siteType",
	"additionalInfo.productCategoryFilter",
	"module",
	"site.region",
	"country",
	"siteId",
	"subregion"
];

/**
 * Client parameters
 */
export const clientInitParameters = {
	name: "clientParameters",
	collection: CompassClientParameters,
	facetsToRefine: ["project"],
	fetch: "find",
	limit: 1
};

/**
 * Function to refresh the list of nonConformities after a click on a new page
 *
 * @param {Number} value Page to refresh the list of sites
 */
export const updateListNonConformities = (dataset, limit, value) => ({
	name: dataset,
	collection: CompassCheckpoints,
	fetch: "aggregation",
	query: listNonConformityQuery,
	facetsToRefine: listFacetsToRefine.concat(["critical", "section1Label", "section2Label"]),
	limit,
	skip: ((value - 1) * limit)
});

/**
  * Active DataSets definition for the app
  *
  * @param {String} name
  * @param {String} collection
  * @param {String} fetch
  * @param {String[]} facetsToRefine
  * @param {Object[]} query
  * @param {Number} limit
  * @param {Object[]} data
  */
export const compassInitDatasets = {
	listClients: {
		collection: CompassClientParameters,
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						client: "$client",
						project: "$project"
					},
					nbClients: { $sum: 1 }
				}
			},
			{
				$project: {
					client: "$_id.client",
					project: "$_id.project",
					nbClients: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listFinalRatings: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "additionalInfo.finalRating"),
		query: [
			{
				$group: {
					_id: {
						finalRating: "$additionalInfo.finalRating"
					},
					nbModules: { $sum: 1 }
				}
			},
			{
				$project: {
					additionalInfo: {
						finalRating: "$_id.finalRating"
					},
					nbModules: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listModules: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "module"),
		query: [
			{
				$group: {
					_id: {
						module: "$module"
					},
					nbModules: { $sum: 1 }
				}
			},
			{
				$project: {
					module: "$_id.module",
					nbModules: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listProducts: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "additionalInfo.productCategoryFilter"),
		query: [
			{
				$unwind: {
					path: "$additionalInfo.productCategoryFilter",
					preserveNullAndEmptyArrays: false
				}
			},
			{
				$group: {
					_id: {
						product: "$additionalInfo.productCategoryFilter"
					},
					nbProducts: { $sum: 1 }
				}
			},
			{
				$project: {
					additionalInfo: {
						productCategoryFilter: "$_id.product"
					},
					nbProducts: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listCountries: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "country"),
		query: [
			{
				$group: {
					_id: {
						country: "$country"
					},
					nbCountries: { $sum: 1 }
				}
			},
			{
				$project: {
					country: "$_id.country",
					nbCountries: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listRegions: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "site.region"),
		query: [
			{
				$group: {
					_id: {
						region: "$site.region"
					},
					nbAssessments: { $sum: 1 }
				}
			},
			{
				$project: {
					site: {
						region: "$_id.region"
					},
					nbAssessments: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listRegionsGrouped: {
		collection: "CompassFormAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => !["site.region", "subregion"].includes(e)),
		query: [
			{
				$project: {
					"option.label": "$subregion",
					"option.value": "$subregion",
					region: "$site.region"
				}
			}, {
				$group: {
					_id: "$region",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					refine: "site.region"
				}
			}, {
				$match: {
					_id: {
						$ne: "undefined"
					}
				}
			}
		]
	},
	listSites: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "siteId"),
		query: [
			{
				$group: {
					_id: {
						siteId: "$siteId",
						auditedCompany: "$site.auditedCompany"
					},
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					siteId: "$_id.siteId",
					auditedCompany: "$_id.auditedCompany",
					nbSites: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listCriticalities: {
		collection: CompassCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						critical: { $ifNull: ["$critical", false] },
						criticality: { $cond: [{ $eq: ["$critical", true] }, "Critical", "Non Critical"] }
					},
					nbQuestions: { $sum: 1 }
				}
			},
			{
				$project: {
					critical: "$_id.critical",
					criticality: "$_id.criticality",
					nbQuestions: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	avgScore: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: "$module",
					score: {
						$avg: "$score"
					}
				}
			}
		]
	},
	avgScoreBySection: {
		collection: "CompassCheckpoints",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "section2Label"]),
		query: [
			{
				$match: {
					scoringBySection: true
				}
			}, {
				$group: {
					_id: {
						module: "$module"
					},
					nc: {
						$sum: "$nc"
					},
					cnc: {
						$sum: "$cnc"
					},
					sumQuestionScore: {
						$sum: "$questionScore"
					},
					maxScore: {
						$sum: "$scoringWeight"
					}
				}
			}, {
				$project: {
					_id: 0,
					module: "$_id.module",
					nc: 1,
					cnc: 1,
					score: {
						$cond: [
							{
								$ne: [
									"$maxScore", 0
								]
							}, {
								$divide: [
									"$sumQuestionScore", "$maxScore"
								]
							}, null
						]
					}
				}
			}
		]
	},
	scoreCounter: {
		collection: CompassCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "section2Label"]),
		query: [
			{
				$match: {
					state: "PUBLISHED"
				}
			}, {
				$group: {
					_id: {
						module: "$module",
						rating: "$rating"
					},
					count: {
						$sum: 1
					},
					nbNc: {
						$sum: "$nc"
					},
					nbCNc: {
						$sum: "$cnc"
					}
				}
			}, {
				$project: {
					module: "$_id.module",
					rating: "$_id.rating",
					count: 1,
					nbNc: 1,
					nbCNc: 1,
					_id: 0
				}
			}
		],
		limit: 50
	},
	modulesRatings: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						module: "$module",
						finalRating: "$additionalInfo.finalRating"
					},
					totalAssignments: { $sum: 1 },
					uniqueSites: { $addToSet: "$siteId" },
					moduleNbNC: {
						$sum: "$nc"
					},
					moduleNbCNC: {
						$sum: "$cnc"
					}
				}
			},
			{
				$project: {
					module: "$_id.module",
					additionalInfo: {
						finalRating: "$_id.finalRating"
					},
					totalAssignments: 1,
					uniqueSites: 1,
					moduleNbNC: 1,
					moduleNbCNC: 1,
					_id: 0
				}
			}
		],
		limit: 50
	},
	listSiteTypes: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "additionalInfo.siteType"),
		query: [{
			$group: {
				_id: {
					siteType: "$additionalInfo.siteType"
				},
				nbAssessments: {
					$sum: 1
				}
			}
		},
		{
			$project: {
				additionalInfo: {
					siteType: "$_id.siteType"
				},
				nbAssessments: 1,
				_id: 0
			}
		}
		],
		limit: 10
	},
	scope: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						siteType: "$additionalInfo.siteType"
					},
					nbAssessments: { $sum: 1 }
				}
			},
			{
				$project: {
					additionalInfo: {
						siteType: "$_id.siteType"
					},
					nbAssessments: 1,
					_id: 0
				}
			}
		],
		limit: 10
	},
	subregionDistrib: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						subregion: "$subregion"
					},
					nbAssessments: { $sum: 1 }
				}
			},
			{
				$project: {
					subregion: "$_id.subregion",
					nbAssessments: 1,
					_id: 0
				}
			}
		],
		limit: 10
	},
	sites: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$project: {
					auditedCompany: "$site.auditedCompany",
					geometry: "$site.geometry",
					publicationDate: 1,
					site: {
						city: "$site.city",
						country: "$country",
						address: "$site.address"
					},
					additionalInfo: {
						siteType: "$additionalInfo.siteType",
						productCategoryFilter: "$additionalInfo.productCategoryFilter",
						finalRating: "$additionalInfo.finalRating"
					},
					siteId: 1,
					nc: 1,
					cnc: 1,
					score: 1,
					region: "$site.region",
					subregion: 1
				}
			}
		],
		limit: 1000
	},
	sectionNCsList: {
		collection: CompassCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						section1Label: "$section1Label",
						section2Label: "$section2Label"
					},
					nc: {
						$sum: "$nc"
					},
					cnc: {
						$sum: "$cnc"
					},
					sumQuestionScore: {
						$sum: "$questionScore"
					},
					maxScore: {
						$sum: "$scoringWeight"
					},
					partially: {
						$sum: "$partially"
					}
				}
			}, {
				$project: {
					section1Label: "$_id.section1Label",
					section2Label: "$_id.section2Label",
					sectionScore: {
						$cond: [
							{
								$ne: [
									"$maxScore", 0
								]
							}, {
								$divide: [
									"$sumQuestionScore", "$maxScore"
								]
							}, null
						]
					},
					nc: 1,
					cnc: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listSubClients: {
		collection: "CompassFormAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "subclient"),
		query: [
			{
				$match: {
					client: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: "$client",
					count: {
						$sum: 1
					}
				}
			}, {
				$project: {
					client: "$_id",
					count: 1,
					_id: 0
				}
			}
		]
	},
	listNonConformities: {
		collection: CompassCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["critical", "section1Label", "section2Label"]),
		query: listNonConformityQuery,
		skip: 0,
		limit: 10
	},
	nonConformities: {
		collection: CompassCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat("critical"),
		query: [
			{
				$match: {
					last: true
				}
			},
			{
				$group: {
					_id: {
						year: { $year: "$publicationDate" },
						month: { $month: "$publicationDate" },
						siteId: "$siteId"
					},
					nbNC: { $sum: "$nc" },
					nbCNC: { $sum: "$cnc" },
					nbSites: { $sum: 1 }
				}
			},
			{
				$group: {
					_id: {
						year: "$_id.year",
						month: "$_id.month"
					},
					nbSites: { $sum: 1 },
					sites: { $addToSet: "$_id.siteId" },
					nbNC: { $sum: "$nbNC" },
					nbCNC: { $sum: "$nbCNC" }
				}
			},
			{
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					sites: 1,
					nbSites: 1,
					nbNC: 1,
					nbCNC: 1,
					_id: 0
				}
			}
		],
		limit: 1000
	},
	listSections: {
		collection: "CompassCheckpoints",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "section1Label"),
		query: [
			{
				$project: {
					"option.label": "$section2Label",
					"option.value": "$section2Label",
					section: "$section1Label"
				}
			}, {
				$group: {
					_id: "$section",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					refine: "section1Label"
				}
			}, {
				$match: {
					_id: {
						$ne: "undefined"
					}
				}
			}
		]
	},
	listSubsections: {
		collection: "CompassCheckpoints",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "section1Label"),
		query: [
			{
				$project: {
					"option.label": "$section2Label",
					"option.value": "$section2Label",
					section: "$section2Label"
				}
			}, {
				$group: {
					_id: "$section",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					refine: "section2Label"
				}
			}, {
				$match: {
					_id: {
						$ne: "undefined"
					}
				}
			}
		]
	}
};

/**
 * Init Refine of the Application
 *
 * @param {Object} compassInitRefines
 * Object representing the initial refine of the datasets
 * in the App slice, the key being the name of the field
 * in the DB Collection to filter on, the value being the value to filter on
 */
export const compassInitRefines = { project: undefined };

// Define the MapToolTip
export const compassMapTooltip = [
	{ label: "Audited Company", key: "auditedCompany" },
	{ label: "Final Rating", key: "additionalInfo.finalRating" }
];

/**
 * Set up refine filter options to display
 *
 * @param {String} refine name of the field in the DB Collection to filter on
 * @param {String} loadFromDataset name of the collection to filter on the field
 * @param {String} placeHolder [OPTIONAL] text when nothing is selected
 * @param {Component} tag Material UI Icon to represent the field in the select, to be imported in this file
 * @param {Boolean} clear will be cleared if the button clear is pushed
 * @param {Boolean} toClearOnChange execute a clear of other clearable fields (see above) if the value has changed
 */
export const filterOptions = [
	{
		refine: "project",
		displayField: "client",
		label: "Client Projects",
		loadFromDataset: "listClients",
		placeHolder: "Clients",
		isMulti: false,
		isSearchable: false,
		tag: AccountTreeIcon,
		hiddenIfUnique: true,
		clear: false,
		toClearOnChange: true
	},
	{
		refine: "client",
		displayField: "client",
		label: "Client Group",
		loadFromDataset: "listSubClients",
		placeHolder: "Clients",
		isMulti: false,
		isSearchable: false,
		tag: AccountTreeIcon,
		// hiddenIfUnique: true,
		clear: false,
		toClearOnChange: true,
		onlyIfRefine: (r, cp) => cp?.multiClient
	},
	{
		label: "Final Rating",
		refine: "additionalInfo.finalRating",
		loadFromDataset: "listFinalRatings",
		placeHolder: "filters.ratings",
		isMulti: true,
		isClearable: true,
		isSearchable: false,
		tag: StarHalfIcon,
		hiddenIfUnique: true,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Site Type",
		refine: "additionalInfo.siteType",
		loadFromDataset: "listSiteTypes",
		placeHolder: "filters.siteType",
		isMulti: true,
		isClearable: true,
		isSearchable: false,
		tag: BusinessIcon,
		hiddenIfUnique: true,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Modules",
		refine: "module",
		loadFromDataset: "listModules",
		placeHolder: "filters.modules",
		isMulti: false,
		isClearable: true,
		isSearchable: false,
		tag: VerifiedUserIcon,
		clear: true,
		toClearOnChange: false,
		hiddenIfUnique: true
	},
	{
		label: "Products",
		refine: "additionalInfo.productCategoryFilter",
		loadFromDataset: "listProducts",
		placeHolder: "filters.products",
		isMulti: true,
		isClearable: true,
		isSearchable: false,
		tag: PinDropOutlinedIcon,
		clear: true,
		toClearOnChange: false,
		hiddenIfUnique: true
	},
	{
		label: "Regions",
		refine: "site.region",
		loadFromDataset: "listRegions",
		placeHolder: "filters.regions",
		isMulti: true,
		isClearable: true,
		isSearchable: true,
		tag: PinDropOutlinedIcon,
		clear: true,
		toClearOnChange: false,
		hiddenIfUnique: true,
		onlyIfRefine: (refine, clientParams) => !clientParams?.geoScope
	},
	{
		label: "Regions",
		refine: "subregion",
		loadFromDataset: "listRegionsGrouped",
		placeHolder: "filters.regions",
		isGrouped: true,
		groupSelection: true,
		isClearable: true,
		isSearchable: true,
		tag: PinDropOutlinedIcon,
		clear: true,
		toClearOnChange: false,
		onlyIfRefine: (refine, clientParams) => clientParams?.geoScope
	},
	{
		label: "Countries",
		refine: "country",
		loadFromDataset: "listCountries",
		placeHolder: "filters.countries",
		isMulti: true,
		isClearable: true,
		isSearchable: true,
		tag: PinDropOutlinedIcon,
		clear: true,
		toClearOnChange: false,
		hiddenIfUnique: true
	},
	{
		label: "Site",
		refine: "siteId",
		displayField: "auditedCompany",
		loadFromDataset: "listSites",
		placeHolder: "filters.searchForASite",
		isClearable: true,
		isSearchable: true,
		tag: BusinessIcon,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		label: "Criticality",
		refine: "critical",
		displayField: "criticality",
		loadFromDataset: "listCriticalities",
		placeHolder: "filters.NCLevel",
		isClearable: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		label: "Section",
		refine: "section2Label",
		loadFromDataset: "listSections",
		placeHolder: "filters.sections",
		isClearable: true,
		isSearchable: false,
		isGrouped: true,
		// groupSelection: true,
		clear: true,
		toClearOnChange: false,
		// manualPosition: true,
		clearRefines: ["section1Label"],
		onlyIfRefine: (r, cp) => cp?.scoringBySection
	},
	{
		refine: "section1Label",
		manualPosition: true,
		clearRefines: ["section2Label"],
		clear: true,
		toClearOnChange: false,
		onlyIfRefine: (r, cp) => cp?.scoringBySection
	}
];

export const ratingsConfig = [
	{ rating: "Satisfactory", color: "#6DC3A0" },
	{ rating: "Needs Refinement", color: "#6BD0FF" },
	{ rating: "Needs Improvement", color: "#FF9900" },
	{ rating: "Conditionally Approved", color: "#FF475C" },
	{ rating: "Not approved", color: "#FF475C" }
];

/**
 * Define the rule for the Marker color on the map
 */
export const getMarkerColor = (finalRating) => {
	if (!finalRating) return "#c9bbbd";
	return ratingsConfig.find((element) => finalRating === element.rating)?.color;
};

export const getScoreColor = (score) => {
	if (!score) return "#c9bbbd";
	if (score >= 0 && score < 0.20) return "#eb4c72";
	if (score >= 0.20 && score < 0.35) return "#fe8f71";
	if (score >= 0.35 && score < 0.50) return "#fec461";
	if (score >= 0.50 && score < 0.75) return "#e7e401";
	if (score >= 0.75 && score <= 0.90) return "#ccdb74";
	if (score >= 0.90 && score <= 1) return "#70ad46";
};

/**
 * Define the color code and name for non conformities
 */
export const nonConformities = { Critical: "#FF475C", "Non Critical": "#FFC362" };

/**
 * Array of tags to be displayed for each non conformities
 */
export const listTagsNonConformities = {
	auditedCompany: {
		accessor(d, t) { return d.auditedCompany; },
		picto(d) { return BusinessIcon; },
		refine: [{ key: "siteId", valueKey: (d) => d.siteId }]
	},
	location: {
		accessor(d, t) { return d.city ? `${d.city} - ${d.country}` : d.country; },
		picto(d) { return PinDropIcon; }
	},
	siteType: {
		accessor(d, t) { return d.siteType; },
		picto(d) { return CompareArrowsIcon; }
	},
	module: {
		accessor(d, t) { return d.module; },
		picto(d) { return VerifiedUserIcon; }
	},
	section: {
		accessor(d, t) { return d.section1Label; },
		picto(d) { return VerticalSplitOutlinedIcon; }
	},
	product: {
		accessor(d, t) { return d?.productCategory?.map((element, i) => (element) + ((i < (d.productCategory.length - 1)) ? "," : "")); },
		picto(d) { return AccountTreeOutlinedIcon; }
	},
	questionIndex: {
		accessor(d, t) { return d.questionIndex; },
		picto(d) { return VerticalSplitOutlinedIcon; }
	}
};

export const tableStructure = (activeRefines, t) => [
	{
		Header: "Audited Company",
		accessor: "auditedCompany"
	},
	{
		Header: "City",
		accessor: "site.city"
	},
	{
		Header: "Country",
		accessor: "site.country"
	},
	{
		Header: "Site Type",
		accessor: "additionalInfo.siteType"
	},
	{
		Header: "Product",
		accessor: "additionalInfo.productCategoryFilter",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => Array.from(new Set(value))
		.reduce((acc, value, currentIndex) => `${acc} ${(currentIndex !== 0) ? "-" : ""} ${value}`, "")
	},
	{
		Header: "Ranking",
		accessor: "additionalInfo.finalRating",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (
			<p className="font-semibold" style={{ color: getMarkerColor(value) }}>
				{value}
			</p>)
	},
	{
		Header: "NCs",
		accessor: "nc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-risk-low rounded-full h-7 w-7">{value}</p> : <p/>
	},
	{
		Header: "CNCs",
		accessor: "cnc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-risk-high rounded-full h-7 w-7 text-white">{value}</p> : <p/>
	}
];

export const tableStructureScoreBasedProjects = (activeRefines, t) => [
	{
		Header: "Audited Company",
		accessor: "auditedCompany"
	},
	{
		Header: "City",
		accessor: "site.city"
	},
	{
		Header: "Country",
		accessor: "site.country"
	},
	{
		Header: "Region",
		accessor: "region"
	},
	{
		Header: "Subregion",
		accessor: "subregion"
	},
	{
		Header: "Score",
		accessor: "score",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (
			<span className="font-semibold p-2 rounded-lg" style={{ backgroundColor: getScoreColor(value) }}>
				{value && !Number.isNaN(value) && (value * 100).toFixed(2)}
			</span>)
	},
	{
		Header: "NCs",
		accessor: "nc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-risk-low rounded-full h-7 w-7">{value}</p> : <p/>
	},
	{
		Header: "CNCs",
		accessor: "cnc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-risk-high rounded-full h-7 w-7 text-white">{value}</p> : <p/>
	}
];

export const tableSectionsStructure = (activeRefines, t) => [
	{
		Header: "Section Level 1",
		accessor: "section1Label"
	},
	{
		Header: "Section Level 2",
		accessor: "section2Label"
	},
	{
		Header: "NCs",
		accessor: "nc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-risk-low rounded-full h-7 w-7">{value}</p> : <p/>
	},
	{
		Header: "CNCs",
		accessor: "cnc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-risk-high rounded-full h-7 w-7 text-white">{value}</p> : <p/>
	},
	{
		Header: "Score",
		accessor: "sectionScore",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <span>{(value * 100).toFixed(1)}%</span> : <p/>
	}
];
