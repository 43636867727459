import React from "react";
import PropTypes from "prop-types";

const mockData = [
	{ circumference: 45 * 2 * Math.PI, percent: 80, improvement: 60 },
	{ circumference: 45 * 2 * Math.PI, percent: 20, improvement: 5 },
	{ circumference: 45 * 2 * Math.PI, percent: 50, improvement: 40 }
];

const indexSelector = (value) => {
	if (value > 50) { return "text-green-400"; }
	if (value === 50 || (value < 50 && value > 20)) { return "text-yellow-400"; }

	return "text-red-500";
};

const CircleIndex = (props) => (
	<div className="flex flex-col sm:flex-row md:flex-col justify-center items-center
				w-auto h-full space-y-14 sm:space-x-4 sm:space-y-0 md:space-x-0 md:space-y-12 border rounded-lg">
		{mockData.map((item, i) => (
			<div key={i} className="flex justify-center items-center flex-nowrap max-w-sm px-5
			lg:px-6 xl:px-10 bg-white rounded-2xl h-14 md:h-14 lg:h-16 xl:h-20 border">
				<div className="relative flex items-center justify-center -m-11 lg:-m-6 overflow-hidden rounded-full">
					<svg className=" w-28 h-28 xl:w-32 xl:h-32 transform scale-75 md:scale-75 lg:scale-75 xl:scale-100
					-translate-y-0.5 sm:-tranlate-y-3 md:-translate-y-0.5
					lg:-translate-y-1 xl:translate-x-1 xl:translate-y-1">
						<circle
							className="text-gray-300"
							strokeWidth="10"
							stroke="currentColor"
							fill="white"
							r="45"
							cx="60"
							cy="60"
						/>
						<circle
							className={`${indexSelector(item.percent)}`}
							strokeWidth="10"
							strokeDasharray={item.circumference}
							strokeDashoffset={item.circumference - ((item.percent / 100) * item.circumference)}
							strokeLinecap="round"
							stroke="currentColor"
							fill="transparent"
							r="45"
							cx="60"
							cy="60"
						/>
					</svg>
					<span className={`absolute text-sm lg:text-lg xl:text-2xl ${indexSelector(item.percent)}`} >{item.percent}%</span>
				</div>
				<p className="ml-10 lg:ml-5 font-medium text-gray-600 text-smaller lg:text-medium xl:text-base">Performance</p>

				<p className={`ml-1 w-2 text-smaller lg:text-medium xl:text-lg font-medium  ${indexSelector(item.improvement)}`}>
					{item.improvement}%
				</p>
			</div>
		))}
	</div>

);

CircleIndex.propTypes = {

};

export default CircleIndex;
