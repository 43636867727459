/* eslint-disable react/no-unescaped-entities */
import React from "react";

const RYBCookiesFR = () => (
	<div className="text-sm text-tab-legalNotice-title">
		<p className="text-2xl font-medium">Politique Cookies</p>
		<p className="text-gray-500 text-sm">(Dernière mise à jour: Octobre, 2021)</p>
		<p className="text-base font-medium mt-4">
			Utilisation des cookies par Bureau Veritas
		</p>
		<p className="mt-4">
			Vous êtes informé que la société Bureau Veritas Services (« Bureau Veritas
			», « nous ») utilise des cookies ou autres traceurs sur le site
			&nbsp;<a
				className="hover:underline"
				href="https://restartwith.bureauveritas.com/"
			>
				restartyourbusinesswith.bureauveritas.com
			</a>
			&nbsp;(le « Site »).
		</p>
		<p className="mt-4">
			Ces derniers sont susceptibles d’être installés dans votre terminal sous
			réserve des choix et options que vous avez exprimés ou pouvez exprimer à
			tout moment, conformément à la présente politique.
		</p>
		<p className="mt-4">
			Dans un souci d’information et de transparence, Bureau Veritas a défini la
			présente politique afin de vous permettre d’en savoir plus sur :
		</p>
		<ul className="list-disc ml-10 mt-4">
			<li>
				l’origine et la finalité des informations traitées à l'occasion de votre
				navigation sur le Site ;
			</li>
			<li>
				vos droits quant aux cookies et autres traceurs utilisés par Bureau
				Veritas.
			</li>
		</ul>
		<p className="text-base font-medium mt-4">Qu’est-ce qu’un cookie ?</p>
		<p className="mt-4">
			Les cookies, ou autres traceurs similaires, sont des données utilisées par
			un serveur pour envoyer des informations d’état au navigateur d’un
			utilisateur, et par ce navigateur pour renvoyer des informations d’état au
			serveur d’origine.
		</p>
		<p className="mt-4">
			Les informations d’état peuvent être par exemple un identifiant de
			session, une langue, une date d’expiration, etc.
		</p>
		<p className="mt-4">
			Les cookies permettent de conserver, pendant leur durée de validité, des
			informations d'état lorsqu’un navigateur accède aux différentes pages d'un
			site Web ou lorsque ce navigateur retourne ultérieurement sur ce site Web.
		</p>
		<p className="text-base font-medium mt-4">
			A quoi servent les cookies émis sur le Site?
		</p>
		<p className="mt-4">
			Nous utilisons des cookies et autres traceurs, notamment pour les
			principales finalités suivantes :
		</p>
		<table className="table-auto alphaBlur w-full mt-4">
			<thead className="border-b-2">
				<tr className="bg-gray-100 text-left">
					<th className="w-min font-normal px-2 py-2">Nom du cookie</th>
					<th className="w-min font-normal px-2 py-2">Finalité du cookie</th>
					<th className="w-min font-normal px-2 py-2">Description</th>
				</tr>
			</thead>
			<tbody className="divide-y-2">
				<tr>
					<td className="w-min px-3 py-3 align-top">
						<span className="font-semibold">i18nextLng</span>
					</td>
					<td className="w-min px-3 py-3 align-top">
						Traduire la langue de l'utilisateur
					</td>
					<td className="w-min px-3 py-3 align-top">
						Il s'agit d'un plugin de détection de langue i18next utilisé pour
						détecter la langue de l'utilisateur dans le navigateur avec la prise
						en charge de: EN, FR, ES, PT.
					</td>
				</tr>
			</tbody>
		</table>
		<p>&nbsp;</p>
		<p className="mt-4">
			Les applications réseaux sociaux présentes sur le Site comme mentionnées
			ci-dessus peuvent dans certains cas permettre aux réseaux sociaux
			correspondants de vous identifier et ce, même si vous n’avez pas cliqué
			sur les boutons applicatifs. En effet, ce type de bouton peut permettre au
			réseau social concerné de suivre votre navigation sur le Site, du seul
			fait que votre compte au réseau social concerné est activé sur votre
			terminal (session ouverte) durant votre navigation.
		</p>
		<p className="mt-4">
			Nous vous recommandons de consulter les politiques de ces réseaux sociaux
			afin de prendre connaissance des finalités d'utilisation, notamment
			publicitaires, des informations de navigation qu'ils peuvent recueillir.
			Ces politiques doivent en particulier vous permettre d'exercer vos choix
			auprès de ces réseaux sociaux, notamment en paramétrant vos comptes
			d'utilisation de chacun d’entre eux.
		</p>
		<p className="text-base font-medium mt-4">
			Cookies soumis à votre consentement
		</p>
		<p className="mt-4">
			L’installation de certains cookies est soumise à votre consentement.
			Aussi, lors de votre première visite sur le Site, il vous est demandé, par
			la présence d’un bandeau d’information sur la page d’accueil du Site, si
			vous acceptez l’installation de ce type de cookies. Les cookies ne seront
			activés qu’après votre acceptation en poursuivant votre navigation sur le
			Site.
		</p>
		<p className="mt-4">
			Vous pourrez à tout moment revenir sur votre choix par les différents
			moyens décrits dans la section « Comment gérer les cookies ? ».
		</p>
		<p className="text-base font-medium mt-4">Comment gérer les cookies ?</p>
		<p className="mt-4">
			Plusieurs possibilités vous sont offertes pour gérer les cookies et autres
			traceurs.
		</p>
		<p className="mt-4">Vous pouvez configurer votre navigateur afin:</p>
		<ul className="list-disc mt-4 ml-10">
			<li>d’accepter tous les cookies, ou</li>
			<li>de les rejeter systématiquement, ou</li>
			<li>encore de choisir ceux que vous acceptez selon leurs émetteurs.</li>
		</ul>
		<p className="mt-4">
			Vous pouvez également paramétrer votre navigateur pour accepter ou refuser
			au cas par cas les cookies préalablement à leur installation. Vous pouvez
			également régulièrement supprimer les cookies de votre terminal via votre
			navigateur.
		</p>
		<p className="mt-4">
			N’oubliez pas de paramétrer l’ensemble des navigateurs de vos différents
			terminaux (tablettes, smartphones, ordinateurs..).
		</p>
		<p className="mt-4">
			Pour la gestion des cookies et de vos choix, la configuration de chaque
			navigateur est différente. Elle est décrite dans le menu d'aide de votre
			navigateur, qui vous permettra de savoir de quelle manière modifier vos
			souhaits en matière de cookies.
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
				Pour Internet Explorer:{" "}
				<a
					className="hover:underline"
					href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies"
				>
					http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
				</a>
			</li>
			<li>
				Pour Safari:{" "}
				<a
					className="hover:underline"
					href="http://docs.info.apple.com/article.html?path=Safari/3.0/en/9277.html"
				>
					http://docs.info.apple.com/article.html?path=Safari/3.0/en/9277.html
				</a>
			</li>
			<li>
				Pour Chrome:{" "}
				<a
					className="hover:underline"
					href="https://support.google.com/chrome/answer/95647?hl=en&amp;hlrm=en"
				>
					http://support.google.com/chrome/bin/answer.py?hl=en&amp;hlrm=en&amp;answer=95647
				</a>
			</li>
			<li>
				Pour Firefox:{" "}
				<a
					className="hover:underline"
					href="https://support.mozilla.org/fr/kb/Activer%20and%20d%C3%A9sactivate%20the%20cookies"
				>
					http://support.mozilla.org/fr/kb/Activer%20and%20d%C3%A9sactivate%20the%20cookies
				</a>
			</li>
			<li>
				Pour Opera:{" "}
				<a
					className="hover:underline"
					href="https://help.opera.com/en/latest/web-preferences/#cookies"
				>
					http://help.opera.com/Windows/10.20/fr/cookies.html
				</a>
			</li>
		</ul>
		<p className="mt-4">
			Vous trouverez des boutons de partage de réseau social sur le site. Pour
			chacun d'entre eux, vous trouverez ci-dessous un lien vers une page où
			vous pourrez en savoir plus sur l'utilisation des trackers par ces réseaux
			et sur la manière de configurer votre compte :
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
				LinkedIn:{" "}
				<a
					className="hover:underline"
					href="https://www.linkedin.com/legal/cookie-policy"
				>
					https://www.linkedin.com/legal/cookie-policy
				</a>
			</li>
			<li>
				Twitter:{" "}
				<a
					className="hover:underline"
					href="https://help.twitter.com/en/rules-and-policies/twitter-cookies"
				>
					https://help.twitter.com/en/rules-and-policies/twitter-cookies
				</a>
			</li>
		</ul>
		<p className="mt-4">
			L’enregistrement d’un cookie dans votre terminal est essentiellement
			subordonné à votre volonté, que vous pouvez exprimer et modifier à tout
			moment et gratuitement à travers les choix qui vous sont offerts par votre
			navigateur.
		</p>
		<p className="mt-4">
			Si votre navigateur est paramétré pour accepter les cookies dans votre
			terminal, les cookies intégrés dans les pages et contenus que vous avez
			consultés pourront être stockés temporairement dans un espace dédié de
			votre terminal. Ils y seront lisibles uniquement par leur émetteur.
		</p>
		<p className="my-4">
			A l’inverse, vous pouvez paramétrer votre navigateur pour refuser les
			cookies. A cet égard, votre attention est attirée sur le fait qu’en
			paramétrant votre navigateur pour refuser les cookies, certaines
			fonctionnalités, pages, espaces du Site ne seront pas accessibles (ex. :
			si vous tentiez d'accéder à nos contenus ou services nécessitant de vous
			identifier, ou encore nous ou nos prestataires ne pourrions pas
			reconnaître, à des fins de compatibilité technique, le type de navigateur
			utilisé par votre terminal, ses paramètres de langue et d'affichage ou le
			pays depuis lequel votre terminal semble connecté à Internet). Le cas
			échéant, nous déclinons toute responsabilité pour les conséquences liées
			au fonctionnement dégradé de nos services résultant de l'impossibilité
			pour nous d'enregistrer ou de consulter les cookies nécessaires à leur
			fonctionnement et que vous auriez refusés ou supprimés.
		</p>
		<p>
			Pour prendre connaissance de la Charte Groupe de Protection des Données
			Personnelles de Bureau Veritas, cliquez . To read the Bureau Veritas Group
			Data Protection Policy, click
			&nbsp;<a
				className="hover:underline"
				href="https://personaldataprotection.bureauveritas.com/home/1.1.14/#/privacypolicy"
			>
				ici
			</a>.
		</p>
	</div>
);

export default RYBCookiesFR;
