import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PinDropIcon from "@mui/icons-material/PinDrop";

import Moment from "react-moment";
import { getRankColor } from "../../apps/configs/VendorDDConfig";

function VendorDDSiteCard({
	appSlice, siteData, demoReportLink = undefined, locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	if (!siteData) {
		return null;
	}

	return (
		<div className="flex justify-between flex-col w-full h-full gap-y-2">
			<div className="gap-y-1 ">
				<div className="flex items-end gap-x-1 w-full">
					<AddBusinessIcon fontSize="large" className="text-chargescan_primary-default"/>
					<p className="font-semibold line-clamp-2 w-full text-lg xl:text-xl">
						{siteData?.vendorId}
					</p>
				</div>
				<div className="flex items-center gap-x-2 font-light text-sm rounded-lg px-2 py-1 border">
					<PinDropIcon fontSize="small" />
					<p>{siteData?.siteLabel}</p>
				</div>
			</div>
			<div className="flex gap-y-2 justify-center pt-2 w-full">
				<p className="rounded px-4 py-1 font-black text-2xl text-white uppercase"
					style={{ backgroundColor: getRankColor(siteData?.siteRank) }}>
					{siteData?.siteRank}
				</p>
			</div>
			<div className="flex justify-between items-baseline">
				<p className="text-medium pt-4">
					{t("siteComponent.lastAudit")} <Moment format="YYYY/MM/DD">{siteData.publicationDate}</Moment>
				</p>
				{demoReportLink && <a target="_blank" rel="noopener noreferrer"
					href={`https://odsdatahub.bureauveritas.com/reports/${demoReportLink}`}
					className="flex justify-center items-center space-x-1
                                    border border-gray-200 rounded-lg px-3 py-2 text-medium font-light w-28
                                    hover:border-gray-400" >
					<InsertDriveFileIcon style={{ fontSize: "1rem" }} />
					<p className="flex flex-shrink-0">{t("siteComponent.viewReport")}</p>
				</a>}
			</div>
		</div>
	);
}

VendorDDSiteCard.propTypes = {
	appSlice: PropTypes.object,
	siteData: PropTypes.object,
	demoReportLink: PropTypes.string,
	locales: PropTypes.string
};

export default VendorDDSiteCard;
