import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import NonConformity from "./NonConformity";
import MaturityBars from "./MaturityBars";

function NCByModules({
	appSlice, dataset, domainsOptions, noPicto = false, locales, translateDomainName = true
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);
	// refine stuff
	const dispatch = useDispatch();
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	// Load data from the store
	const nonConformitiesByModules = useSelector(appSlice.selectDatasets)[dataset];
	// client parameters
	const clientParameters = useSelector(appSlice.selectClientParameters);

	return (
		<div className="flex flex-col space-y-6">

			{nonConformitiesByModules?.data?.map((domain, i) => {
				const domainOption = domainsOptions[domain.domain];
				const Picto = domainOption?.domainLogo;

				return (
					<div key={i} className="flex flex-col space-y-8">
						<div className="flex items-center gap-x-2 cursor-pointer"
							onClick={() => activeRefines?.domain
								? dispatch(appSlice.actions.clear([{ refine: "domain" }]))
								: dispatch(appSlice.actions.refine([{ key: "domain", value: domain.domain }]))}>
							{!noPicto && <p className="hidden md:flex">
								{typeof Picto === "object" ?
									<Picto fontSize="inherit" />
									: <img src={Picto} alt={Picto} className="w-4 h-4 opacity-100 filter brightness-0" />
								}
							</p>}
							<p
								className="font-light text-sm 2xl:text-base leading-4 line-clamp-2">
								{translateDomainName ? t(`domains.${domainOption?.locale}.name`) : domainOption.name}
							</p>
						</div>

						{
							clientParameters?.maturityColors
								? <MaturityBars
									appSlice={appSlice}
									dataset="maturityRatiosByDomain"
									additionalRefineKey="domain"
									additionalRefineValue={domain.domain}
									noTitle
									locales={locales} />
								: <NonConformity
									appSlice={appSlice}
									noTitle
									refineKey="critical"
									additionalRefineKey="domain"
									additionalRefineValue={domain.domain}
									refineValueHigh="Y"
									refineValueMedium="N"
									ncHigh={domain?.nbCNC}
									ncMedium={(domain?.nbNC ?? 0) - (domain?.nbCNC ?? 0)}
									ncLow={0}
									thickness="thin"
									locales={locales} />
						}
					</div>
				);
			})}

		</div>
	);
}

NCByModules.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	domainsOptions: PropTypes.object,
	noPicto: PropTypes.bool,
	locales: PropTypes.string,
	translateDomainName: PropTypes.bool
};

export default NCByModules;
