/* src/Profile.js */
import React from "react";
import { useSelector } from "react-redux";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { userSelector } from "../data/slices/UserSlice";

// TODO: comment this npart when local development is no longer needed
// Launcher / Authenticator / App / UserProfile / package.json @aws-amplify/ui-react / .env
const Profile = () => {
	// Load the user data from the store of the App
	const user = useSelector(userSelector);

	return (
		<div className="flex flex-col content-center items-center justify-center h-screen">
			<div className="border border-gray-300 p-4">
				<h1 className="font-bold mb-3">Profile</h1>
				<hr />
				<h3 className="font-light">{user.username}</h3>
				<hr />
				<h2 className="mt-3">Name: {user.name}</h2>
				<h2>Company: {user.company}</h2>
				<h2>Email: {user.email}</h2>
				<h2>
					Rights:{" "}
					{user.rights
						? user.rights.filter((e) => e.app === "ALPHA").length >= 1
							? "ALPHA"
							: user.rights.reduce((acc, right, index) => acc[right.app].push(right.scope))
						: "None"}
				</h2>
				<div className="mt-6">
					<AmplifySignOut />
				</div>
			</div>
		</div>
	);
};

export default withAuthenticator(Profile);

// /* src/Profile.js */
// import React from "react";
// import { useSelector } from "react-redux";
// import { Authenticator } from "@aws-amplify/ui-react";

// import { userSelector } from "../data/slices/UserSlice";

// const Profile = () => {
// 	// Load the user data from the store of the App
// 	const user = useSelector(userSelector);

// 	return (
// 		<Authenticator>
// 			{({ signOut }) => (
// 				<div className="flex flex-col content-center items-center justify-center h-screen">
// 					<div className="border border-gray-300 p-4">
// 						<h1 className="font-bold mb-3">Profile</h1>
// 						<hr />
// 						<h3 className="font-light">{user.username}</h3>
// 						<hr />
// 						<h2 className="mt-3">Name: {user.name}</h2>
// 						<h2>Company: {user.company}</h2>
// 						<h2>Email: {user.email}</h2>
// 						<h2>
// 					Rights:{" "}
// 							{user.rights
// 								? user.rights.filter((e) => e.app === "ALPHA").length >= 1
// 									? "ALPHA"
// 									: user.rights.reduce((acc, right, index) => acc[right.app].push(right.scope))
// 								: "None"}
// 						</h2>
// 						<div className="mt-6">
// 							<button onClick={signOut}>Sign out</button>
// 						</div>
// 					</div>
// 				</div>
// 			)}
// 		</Authenticator>
// 	);
// };

// export default Profile;
