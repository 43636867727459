/**
 * Styles for the filters
 */
export const customStylesChargeScan = (refineValue) => ({
	control: (provided, state) => ({
		...provided,
		width: provided.width < 120 ? 76 : provided.width,
		borderRadius: "0.8rem",
		borderWidth: "1px",
		borderColor:
			state.isFocused
				? "none"
				: "none",
		"&:hover": {
			borderColor: state.isFocused
				? "none"
				: "none"
		},
		boxShadow: refineValue !== undefined ?
			"0 2px 3px 0 rgba(0, 146, 209, 10), 0 2px 2px 0 rgba(0, 146, 209, 6)"
			: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
		fontSize: "14px"
	}),

	singleValue: (provided, state) => ({
		...provided,
		fontWeight: (refineValue !== undefined) ? "bold" : "normal"
	}),

	option: (provided, state) => ({
		...provided,
		borderRadius: "0.8rem",
		backgroundColor: state.isSelected ? "#F6F6F6" : "white",
		fontWeight: (state.isSelected) ? "bold" : "normal",
		color: state.isSelected ? "#232D63" : provided.color
	}),

	menu: (provided, state) => ({
		...provided,
		zIndex: 9999,
		minWidth: 160,
		mawWidth: 250,
		borderRadius: "0.8rem",
		padding: "10px"
	}),

	menuList: (provided) => ({
		...provided,
		"::-webkit-scrollbar": {
			display: "none"
		}
	}),

	placeholder: (provided) => ({
		...provided,
		fontWeight: (refineValue !== undefined) ? "bold" : "normal",
		color: "gray"
	}),

	indicatorSeparator: (provided) => ({
		...provided
	}),

	loadingIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "0px",
		paddingRight: "0px"
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "6px",
		paddingRight: "6px"
	})

});

/**
 * Style for the filters
 */

export const customStylesSearchChargeScan = (refineValue) => ({
	control: (provided, state) => ({
		...provided,
		height: "50px",
		backgroundColor: "#F2F2F2",
		borderRadius: "0.8rem",
		boxShadow: "none",
		borderWidth: refineValue !== undefined ? "2px" : "0px",
		borderColor: refineValue !== undefined ? "#0092d1"
			: (state.isFocused
				? "none"
				: "none"),
		"&:hover": {
			borderColor: state.isFocused
				? "none"
				: "none"
		},
		fontSize: "14px"
	}),

	singleValue: (provided, state) => ({
		...provided,
		width: "100%",
		paddingRight: "20px",
		fontWeight: (refineValue !== undefined) ? "bold" : "normal",
		color: "black"
	}),

	option: (provided, state) => ({
		...provided,
		borderRadius: "0.8rem",
		backgroundColor: state.isSelected ? "#F6F6F6" : "white",
		fontWeight: (state.isSelected) ? "bold" : "normal",
		color: state.isSelected ? "#5FC3BF" : provided.color
	}),

	menu: (provided, state) => ({
		...provided,
		zIndex: 9999,
		minWidth: 160,
		mawWidth: 250,
		borderRadius: "0.8rem",
		padding: "10px"
	}),

	menuList: (provided) => ({
		...provided,
		"::-webkit-scrollbar": {
			display: "none"
		}
	}),

	indicatorSeparator: (provided) => ({
		...provided,
		display: "none"
	}),

	placeholder: (provided) => ({
		...provided,
		width: "100%",
		paddingRight: "20px"
	}),

	loadingIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "0px",
		paddingRight: "0px"
	})

});
