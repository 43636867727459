import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TabsControl from "./TabsControl";
import { appDefinition } from "../../apps/configs/RYBConfig";

const Tabs = ({ tabSet, locales, page = "TermsPage" }) => {
	const tabs = Object.keys(tabSet);
	// Internationalization hook

	const { t, i18n } = useTranslation(locales);

	return (
		<TabsControl>
			{tabs.map((tab, i) => (
				<div
					key={`tab ${tab}`}
					name={t(`${page}.header.${tab}`)}
				>
					{tabSet?.[tab]?.[i18n?.language]}
				</div>
			))}
		</TabsControl>
	);
};

Tabs.propTypes = {
	tabSet: PropTypes.object,
	locales: PropTypes.string,
	page: PropTypes.string
};

export default Tabs;
