import PropTypes from "prop-types";
import React, { useMemo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Group } from "@visx/group";
import { AreaStack } from "@visx/shape";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { scaleTime, scaleLinear } from "@visx/scale";
import { withTooltip, useTooltipInPortal } from "@visx/tooltip";
import * as allCurves from "@visx/curve";
import IF from "../../utils/IF";
import SVGNoDataAvailable from "../../pages/SVGNoDataAvailable";

const defaultColorScale = ["#6CABE6", "#9757c9", "#CF8084", "#dbb564", "#6c7cf7", "#fad390", "#f8c291", "#6a89cc",
	"#82ccdd", "#b8e994", "#f6b93b", "#e55039", "#4a69bd", "#60a3bc", "#78e08f", "#fa983a", "#eb2f06", "#1e3799", "#3c6382",
	"#38ada9", "#e58e26", "#b71540", "#0c2461", "#0a3d62", "#079992", "darksalmon"];

const StackedAreaChart = ({
	appSlice,
	dataset,
	width,
	height,
	margin = {
		top: 10, right: 25, bottom: 25, left: 25
	},
	year = "year",
	keys = undefined,
	isTrimestrial = false,
	fromOffset = "0%",
	fromOpacity = 1,
	toOffset = "100%",
	toOpacity = 1,
	colorScale = defaultColorScale,
	isVertical = true,
	gradientId = "stacked-area",
	showTooltip,
	hideTooltip,
	tooltipOpen,
	tooltipLeft,
	tooltipTop,
	tooltipData
}) => {
	const [labelDisplay, setLabelDisplay] = useState(false);
	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

	let tooltipTimeout;

	// bounds
	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;

	// load dataset
	const graphData = useSelector(appSlice.selectDatasets)[dataset]?.data;

	// Objectify keys array
	const keysObj = useMemo(() => keys.reduce((acc, cur) => ({ ...acc, [cur]: 0 }), {}), [keys]);

	// timepoint accessor
	const timepoint = useCallback((d) => {
		if (isTrimestrial) {
			return d.quarter;
		}
		return d.month;
	}, [isTrimestrial]);

	let sortedGraphData = useMemo(() => (
		[...graphData]
		// sort data
		.sort((dotA, dotB) => dotA[`${year}`] > dotB[`${year}`]
			? 1
			: dotA[`${year}`] < dotB[`${year}`]
				? -1
				: timepoint(dotA) > timepoint(dotB)
					? 1
					: -1
		)
		// add formated date & merge keysObj
		.map((item) => ({
			...keysObj,
			...item,
			date: isTrimestrial && graphData.length === 1
				? new Date(item.year, (timepoint(item) * 3) - 1, 1).toISOString()
				: isTrimestrial
					? new Date(item.year, (timepoint(item) * 3) - 1).toISOString()
					: new Date(item.year, timepoint(item) - 1).toISOString()
		}))
	), [graphData, year, keysObj, isTrimestrial, timepoint]);

	// create a duplicate if only 1 date/element => [1st day of the month, 2nd last day of the month]. This is needed in order to have a regular domain and a time visualization
	if (sortedGraphData.length === 1) {
		sortedGraphData = [
			...sortedGraphData,
			{
				...sortedGraphData[0],
				date: isTrimestrial ?
					new Date(sortedGraphData[0].year, (timepoint(sortedGraphData[0]) * 3) + 1, 0)
					: new Date(sortedGraphData[0].year, (timepoint(sortedGraphData[0])), 0)
			}];
	}

	// scales
	const xScale = useMemo(() => (
		scaleTime({
			range: [0, xMax],
			domain: isTrimestrial
				? [Math.min(...sortedGraphData.map((d) => new Date(d.date))),
					Math.max(...sortedGraphData.map((d) => new Date(d.date)))]
				: [Math.min(...sortedGraphData.map((d) => new Date(d.date))),
					Math.max(...sortedGraphData.map((d) => new Date(d.date)))]
		})
	), [sortedGraphData, xMax, isTrimestrial]);

	const yScale = useMemo(() => (
		scaleLinear({ range: [yMax, 0] })
	), [yMax]);

	const { containerRef, TooltipInPortal } = useTooltipInPortal({
		scroll: true,
		detectBounds: true
	});

	if (graphData.length === 0) {
		return <SVGNoDataAvailable width={width} height={height} />;
	}
	return (
		<div ref={containerRef}>

			<svg width={width} height={height}>
				<Group top={margin.top} left={margin.left}>

					<AreaStack
						keys={keys}
						data={sortedGraphData}
						stroke="#6373F6"
						strokeWidth={2}
						curve={allCurves.curveMonotoneX}
						x={(d) => xScale(new Date(d.data.date))}
						y0={(d) => yScale(d[0])}
						y1={(d) => yScale(d[1])}
					>
						{({ stacks, path }) => stacks.map((stack, i) => (
							<path
								className="hover:opacity-80"
								key={`stack-${stack.key}`}
								d={path(stack)}
								stroke="white"
								fill={colorScale[i]}
								onClick={(e) => {
									console.log(e);
								}}
								onMouseOut={() => {
									tooltipTimeout = window.setTimeout(() => {
										hideTooltip();
									}, 500);
								}}
								onMouseOver={(e) => {
									if (tooltipTimeout) clearTimeout(tooltipTimeout);

									showTooltip({
										tooltipData: stack.key,
										tooltipTop,
										tooltipLeft: margin.left
									});
								}}
								onMouseMove={(e) => {
									setCursorPosition({ x: e.nativeEvent.layerX, y: e.nativeEvent.layerY });
								}}
							/>
						))}
					</AreaStack>
					{tooltipOpen && tooltipData && (
						<foreignObject x={`${cursorPosition.x}`} y={`${cursorPosition.y}`} width="100" height="100" >
							<TooltipInPortal
								key={Math.random()}
								left={cursorPosition.x}
								top={cursorPosition.y}
							>
								<span className="text-md">{tooltipData}</span>
							</TooltipInPortal>

						</foreignObject>
					)}
					<AxisLeft scale={yScale} />
					<AxisBottom top={yMax} scale={xScale} />

				</Group>
			</svg>
			<div className="flex justify-end items-center col-span-1 flex-wrap">

				<IF condition={keys.length < 6 }>

					{keys.map((k, i) => (
						<div key={`${k}-${i}`} className="flex justify-center items-center mx-2">
							<div className="rounded-full w-2 h-2 bg-black" style={{ background: defaultColorScale[i] }}></div>
							<span className="text-sm">&nbsp;{k}&nbsp;</span>
						</div>
					))}
				</IF>
				<IF condition={keys.length >= 6 && !labelDisplay}>
					<div className="flex cursor-pointer hover:text-gray-500" onClick={() => setLabelDisplay(!labelDisplay)}>
						<span className="mx-2">Click for more information</span>
						{keys.slice(0, 4).map((k, i) => (
							<div key={`${k}-${i}`} className="flex justify-center items-center -mx-1" >
								<div className="rounded-full w-4 h-4 bg-black" style={{ background: defaultColorScale[i] }}></div>
							</div>
						))}
					</div>
				</IF>
				<IF condition={keys.length >= 6 && labelDisplay}>
					<div className="flex flex-wrap flex-row-reverse cursor-pointer hover:text-gray-500" onClick={() => setLabelDisplay(!labelDisplay)}>
						{keys.map((k, i) => (
							<div key={`${k}-${i}`} className="flex justify-center items-center mx-2">
								<div className="rounded-full w-2 h-2 bg-black" style={{ background: defaultColorScale[i] }}></div>
								<span className="text-sm">&nbsp;{k}&nbsp;</span>
							</div>
						))}
					</div>
				</IF>
			</div>
		</div>
	);
};

StackedAreaChart.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	isTrimestrial: PropTypes.bool,
	gradientId: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
	isVertical: PropTypes.bool,
	year: PropTypes.string,
	keys: PropTypes.array,
	margin: PropTypes.object,
	colorScale: PropTypes.array,
	fromOffset: PropTypes.string,
	fromOpacity: PropTypes.number,
	toOffset: PropTypes.string,
	toOpacity: PropTypes.number,
	showTooltip: PropTypes.func,
	hideTooltip: PropTypes.func,
	tooltipData: PropTypes.any,
	tooltipLeft: PropTypes.number,
	tooltipOpen: PropTypes.bool,
	tooltipTop: PropTypes.number
};

export default withTooltip(StackedAreaChart);
