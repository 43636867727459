import React, { useState, forwardRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import "../../styles/filter.css";

const CustomInput = forwardRef(({
	value, onClick, locales, customDatePickerStyles
}, ref) => {
	const { t } = useTranslation(locales);

	return <button
		className={`w-full h-9 text-sm border rounded-xl font-bold
        ${value ? customDatePickerStyles?.selectedShadow ?? "" : customDatePickerStyles.nonSelectedShadow ?? ""}`}
		onClick={onClick}
		ref={ref}
	>
		{value || <span className="text-gray-500">{t("filters.date")}</span> }
	</button>;
});

CustomInput.propTypes = {
	onClick: PropTypes.func,
	value: PropTypes.any,
	locales: PropTypes.string,
	customDatePickerStyles: PropTypes.object
};

const SimpleDatePicker = ({
	appSlice, locales, label, customDatePickerStyles
}) => {
	const dispatch = useDispatch();

	const { t } = useTranslation(locales);

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const onChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);

		// Do not use any js native date function such as toDateLocalString, the date generated and passed in lambda is not garanteed'
		const startConcat = `${start.getMonth() + 1}/${start.getDate()}/${start.getFullYear()}`;
		const endConcat = `${end.getMonth() + 1}/${end.getDate()}/${end.getFullYear()}`;

		dispatch(appSlice.actions.refine([
			{
				key: "date",
				value: {
					start: startConcat,
					end: endConcat
				}
			}]));
	};

	const onCalendarClose = () => {
		if (startDate && !endDate) 		{
			// Do not use any js native date function such as toDateLocalString, the date generated and passed in lambda is not garanteed'
			const startConcat = `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`;
			const endConcat = null;

			dispatch(appSlice.actions.refine([
				{
					key: "date",
					value: {
						start: startConcat,
						end: endConcat
					}
				}]));
		}
	};

	const closeIconSelector = document?.querySelector(".react-datepicker__close-icon");

	// add customer style to close icon base on project
	useEffect(() => {
		const closeIcon = document?.querySelector(".react-datepicker__close-icon");
		closeIcon?.setAttribute("id", customDatePickerStyles.id);
	}, [customDatePickerStyles, closeIconSelector ]);

	useEffect(() => {
		if (!startDate) {
			dispatch(appSlice.actions.clear([{ refine: "date" }]));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate]);

	return (
		<div className="flex flex-col md:space-y-4">
			<p className="relative font-semibold text-sm">
				{t(`filters.${label}`)}
			</p>
			<ReactDatePicker
				dateFormat="dd/MM/yyyy"
				selected={startDate}
				onCalendarClose={onCalendarClose}
				onChange={onChange}
				startDate={startDate}
				endDate={endDate}
				selectsRange
				isClearable
				customInput={<CustomInput locales={locales} customDatePickerStyles={customDatePickerStyles} />}
			/>
		</div>
	);
};

SimpleDatePicker.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	label: PropTypes.string,
	customDatePickerStyles: PropTypes.object
};

export default SimpleDatePicker;
