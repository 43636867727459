import PropTypes from "prop-types";

/**
 * Used in: ChargeScan.js, VendorDD.js, ChargeScanArcChart.js
 * description: Animated ArcChart with value displayed in the center
 * @category Graphs
 * @component
 *
 */

const ArcChart = ({
	percentage,
	theme = "alpha",
	className,
	showScoreText = true,
	scoretextClass = "fill-current text-alpha_text-default text-3xl font-black",
	percentOverLap = false,
	sideCicle = "fill-current text-alpha_primary-light",
	centralCicle = "fill-current text-alpha_primary-default"
}) => {
	const score = (198 * (percentage / 100)).toFixed(0);

	return (
		<div className={`h-full w-full ${className}`}>
			<svg viewBox="0 0 100 100" className="arc-chart">
				<defs>
					<linearGradient id="lineararc" x1="0%" y1="100%" x2="0%" y2="0%">
						<stop offset="0%" className={`chart-initcolor ${theme}`} />
						<stop offset="100%" className={`chart-endcolor ${theme}`} />
					</linearGradient>
				</defs>
				<line x1="10" y1="15" x2="98" y2="98" className="stroke-current stroke-1 text-gray-100" />
				<path className="circle-bg-border" d="M30,90 A40,40 0 1,1 80,90" />
				<path className="circle-bg" d="M30,90 A40,40 0 1,1 80,90" />
				<path
					className="circle-border"
					strokeDashoffset={score}
					strokeDasharray={`${score}, 198`}
					d="M30,90 A40,40 0 1,1 80,90"
				/>
				<path className="circle" strokeDashoffset={score} strokeDasharray={`${score}, 198`} d="M30,90 A40,40 0 1,1 80,90" />
				<path className="dot" strokeDashoffset={score} strokeDasharray={`0, ${score}`} d="M30,90 A40,40 0 1,1 80,90" />
				{percentOverLap
					? <text x="40" y="68" className="text-4xl font-light" fill="#F8F8F8">
						%
					</text>
					: <text x="64" y="40" className="text-gray-400 text-4 font-light">
						%
					</text>
				}
				<text x="54" y="65" textAnchor="middle" className={`${scoretextClass}`}>
					{percentage && percentage !== "NaN" ? percentage : "na"}
				</text>
				{showScoreText && <text x="41" y="76" className="fill-current text-alpha_text-default text-xs font-light">
					score
				</text>}
				<circle cx="48" cy="93" r="2" className={sideCicle} />
				<circle cx="55" cy="93" r="3" className={centralCicle} />
				<circle cx="62" cy="93" r="2" className={sideCicle} />
			</svg>
		</div>
	);
};

ArcChart.propTypes = {
	/**
	 * class string used for side the center circle/dot at the bottom of the design
	 */
	centralCicle: PropTypes.string,
	/**
	 * className string to append to first div
	*/
	className: PropTypes.string,
	/**
	 * if present, the % symbol appears in 4xl fontsize behind percentage value, else is displayed in small in right corner
	 */
	percentOverLap: PropTypes.bool,
	/**
	 * percentage value. this is the main info
	 */
	percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * class string for percentage value display
	 */
	scoretextClass: PropTypes.string,
	/**
	 * show "score" as legend under percentage
	 */
	showScoreText: PropTypes.bool,
	/**
	 * class string used for side the side circles/dots at the bottom of the design
	 */
	sideCicle: PropTypes.string,
	/**
	 * color theme. has to be present in tailwind / css as it is used as a className for arc gradient.
	 */
	theme: PropTypes.string
};

export default ArcChart;
