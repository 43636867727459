import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { chainRefineFieldBuilder } from "../../apps/configs/AdminConfig";
import { getFromS3 } from "../../api/adminControl";
import ImgWithFallbackSrc from "../content/ImgWithFallbackSrc";
import Popconfirm from "../modal/Popconfirm";

const AdminCollectionCard = ({
	collection, locales, appSlice, supplychainView, setSupplychainView, isAlpha, setAlert, onConfirm
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const dispatch = useDispatch();

	const [confirmModal, setConfirmModal] = useState(false);

	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const hasDuplicate = (activeDatasets?.[`${activeRefines.secLevelMenu}ClientParameters`]?.data
	?.filter((item) => item.projectLabel === collection.projectLabel)?.length) > 1;

	const showChainBtn = collection?.supplyChain ?? true;

	// refine on spaceLabel or projectName based on application
	const chainRefineKey = chainRefineFieldBuilder[activeRefines?.secLevelMenu];
	const chainRefineValue = typeof collection[chainRefineKey] === "object" ? collection[chainRefineKey]?.[0] : collection[chainRefineKey];

	const [previewUrl, setPreviewUrl] = useState(null);
	useEffect(() => {
		const fetchLogo = async () => {
			const url = await getFromS3(`public/projectLogos/${chainRefineValue}/${collection?.logo}`);
			setPreviewUrl(url);
		};
		fetchLogo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			{
				confirmModal && (
					<Popconfirm
						title="Delete client"
						description={isAlpha
							? `Do you confirm to delete client '${collection.projectLabel.toUpperCase()}'?`
							: `Sorry, you are not allowed to delete.
							Please click cancel to leave.`}
						confirmBtnText="Confirm"
						showConfirm={isAlpha}
						onClickConfirm={() => { onConfirm(collection._id, setConfirmModal); }}
						onClickCancel={() => setConfirmModal(false)}
					/>
				)}
			<div className="bg-gray-200 rounded-xl py-4 border bg-opacity-90 cursor-pointer hover:border-gray-400 z-xl">
				<div className="relative w-full text-center m-auto z-10">
					<div className="w-full flex justify-center items-center -mt-2 text-5xl text-gray-200">
						<ImgWithFallbackSrc
							src1={`/images/CompanyLogos/${collection?.logo}`}
							src2={previewUrl}
							alt={collection?.logo}
							styleString="object-contain w-20 h-18 mt-6 "
						/>
						<div className="absolute w-full h-32 -top-2 z-3xl"
							onClick={() => dispatch(appSlice.actions.refine([
								{ key: "fourthLevelMenu", value: chainRefineValue },
								{ key: chainRefineKey, value: chainRefineValue },
								{ key: "appAction", value: "updateParams" }
							]))}>
						</div>
					</div>
				</div>

				<p className="text-sm text-center mt-3 uppercase font-semibold">{
					hasDuplicate
						? `${collection.projectLabel}
					(${collection.client})`
						: collection.projectLabel
				}</p>

				<div className="flex items-center space-x-1 justify-center mt-4 divide-x divide-admin_primary-light">
					{
						showChainBtn && (
							<button
								className="bg-admin_primary-default text-white hover:bg-admin_primary-dark w-14
									font-bold uppercase text-smaller md:text-xs lg:text-smaller xl:text-xs 2xl:text-smaller px-3
									md:px-1 lg:px-2 xl:px-1.5 2xl:px-3 py-1 rounded ease-linear transition-all duration-150 z-3xl"
								type="button"
								onClick={() => {
									if (supplychainView) { setSupplychainView(false); }
									dispatch(appSlice.actions.refine([
										{ key: chainRefineKey, value: chainRefineValue },
										{ key: "appAction", value: "view" }
									]));
								}}
							>
								{t("project.btn.supplychain")}
							</button>
						)}
					<button
						className="bg-admin_primary-default text-white hover:bg-admin_primary-dark w-14
								 font-bold uppercase text-smaller md:text-xs lg:text-smaller xl:text-xs 2xl:text-smaller px-3
								 md:px-1 lg:px-2 xl:px-1.5 2xl:px-3 py-1 rounded ease-linear transition-all duration-150 z-3xl"
						type="button"
						onClick={(e) => {
							e.preventDefault();
							setConfirmModal(true);
							dispatch(appSlice.actions.refine([
								{ key: "appAction", value: "deleteParams" }]));
						}}
					>
						{t("project.btn.delete")}
					</button>
				</div>
			</div>
		</>
	);
};

AdminCollectionCard.propTypes = {
	collection: PropTypes.object,
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	supplychainView: PropTypes.bool,
	setSupplychainView: PropTypes.func,
	isAlpha: PropTypes.bool,
	setAlert: PropTypes.func,
	onConfirm: PropTypes.func
};

export default AdminCollectionCard;
