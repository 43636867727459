import { useState, useEffect, createRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import ShareIcon from "@mui/icons-material/Share";
import MapIcon from "@mui/icons-material/Map";
import InfoIcon from "@mui/icons-material/Info";
import LabelIcon from "@mui/icons-material/Label";
import MenuIcon from "@mui/icons-material/Menu";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Pagination from "@mui/material/Pagination";

import { ParentSize } from "@visx/responsive";
import { use100vh } from "react-div-100vh";

import createAppSlice from "../data/slices/createAppSlice";

import MapLeaflet from "../components/map/MapLeaflet";
import RYBToggleSwitch from "../components/filters/RYBToggleSwitch";

import RYBMapMarker from "../components/map/RYBMapMarker";
import RYBFilterMenu from "../components/filters/RYBFilterMenu";
import RYBAbout from "../components/content/RYBAbout";
import RYBLegalTooltip from "../components/content/RYBLegalTooltip";
import RYBFooter from "../components/footer/RYBFooter";
import RYBSites from "../components/content/RYBSites";
import RYBNoValidSite from "../components/cards/RYBNoValidSite";
import Tabs from "../components/tabs/Tabs";

import IF from "../utils/IF";
import Loading from "../utils/Loading";

import {
	appDefinition, initialMenu, mapMarkers, listSites, updateListSites, rybInitDatasets, rybColorMap, termsTabSet, RYBCollection
} from "./configs/RYBConfig";
import exportToExcel from "../utils/ExportToExcel";
import { queryDataset } from "../api/client";

// Create the App Slice
const appSlice = createAppSlice(appDefinition.slice,
	initialMenu,
	{},
	rybInitDatasets,
	appDefinition.openData);

/**
 * Function to render the application
 */
const RYB = () => {
	// Load optional parameters
	const urlQuery = new URLSearchParams(useLocation().search);
	const siteCheck = urlQuery.get("sitecheck");
	const exportLabel = urlQuery.get("export");
	window.localStorage.removeItem("searchParams");

	// Set up state for mobile menu
	const [mobileMenu, setMobileMenu] = useState(false);

	// Set up state for pagination
	const [page, setPage] = useState(1);

	const screenHeight = use100vh();
	const headerFooterSize = window.innerWidth > 1280 ? 365 : 240;

	// Internationalization hook
	const { t, i18n } = useTranslation(appDefinition.locales);

	// Create and load the app slice in the store
	const store = useStore();

	// Load the store hook
	const dispatch = useDispatch();

	// Load ref of list of sites
	const listRef = createRef();

	// set default lang when loaded
	useEffect(() => {
		i18n.changeLanguage("en");
	}, []);// eslint-disable-line react-hooks/exhaustive-deps

	// Load the reducer and favicon
	useEffect(() => {
		store.injectReducer(appDefinition.slice, appSlice.reducer);
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	// if query param with siteID then refine
	useEffect(() => {
		if (!siteCheck) {
			return;
		}
		dispatch(appSlice.actions.refine([{ key: "siteTechnicalId", value: parseInt(siteCheck, 10) }]));
	}, [siteCheck]); // eslint-disable-line react-hooks/exhaustive-deps

	// Generate full list of NC if export requested
	async function generateTableToExport(activeRefines, exportLabel) {
		try {
			const datasetResponse = await queryDataset(
				appDefinition.name,
				{
					collection: RYBCollection,
					fetch: "aggregation",
					query: [
						{
							$match: {
								label: exportLabel
							}
						},
						{
							$project: {
								_id: 0,
								siteTechnicalId: 1,
								label: 1,
								site: 1,
								siteAddress: 1,
								sitePostalCode: 1,
								siteCity: 1,
								siteCountry: 1,
								publicPhone: 1,
								publicEmail: 1,
								publicWebSite: 1,
								status: 1,
								businessSegment: 1,
								validityDate: 1,
								publicationdatetime: 1
							}
						}
					],
					facetsToRefine: Object.keys(activeRefines),
					limit: 5000,
					skip: 0
				},
				activeRefines,
				true);

			return datasetResponse;
		} catch (error) {
			console.log(`Query Dataset:${error}`);
			return Promise.reject();
		}
	}

	// if query param with export then export
	useEffect(() => {
		if (!exportLabel) {
			return;
		}
		generateTableToExport(activeRefines, exportLabel).then((datasetResponse) => exportToExcel(datasetResponse.data,
            `BV RYB Export ${exportLabel} ${new Date().toISOString().split("T")[0]}`));
	}, [exportLabel, activeRefines]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch chain in the store - initial and if selects change only
	useEffect(() => {
		if (activeDatasets === undefined) {
			return;
		}
		dispatch(appSlice.fetchDatasets());
		setPage(1);
	}, [activeRefines]); // eslint-disable-line react-hooks/exhaustive-deps

	// Update list when a new page is requested
	const handleChange = async (event, value) => {
		setPage(value);
		dispatch(appSlice.fetchDataset(updateListSites(value)));
		listRef.current.scrollTo(0, 0);
	};

	// Wait for Data to render App
	if (activeDatasets === undefined
        || !activeDatasets.listBusinessSegments
        || !activeDatasets.listBusinessSegments.data
        || !activeDatasets.listBusinessSegments.data.length > 0) {
		return <Loading message="Wait a moment while we load your app." />;
	}

	// Counter of the number of filtered sites
	const nbResults = activeDatasets[mapMarkers].data ? activeDatasets[mapMarkers].data.length : 0;

	return (
		<div className="h-full bg-gray-100">
			<IF condition={loadDataStatus === "loading"}>
				<Loading />
			</IF>
			<IF condition={loadDataStatus !== "loading" && nbResults === 0}>
				<RYBNoValidSite siteCheck={siteCheck} locales={appDefinition.locales} />
			</IF>
			<div
				className={`relative flex flex-col max-w-screen-2xl h-full mx-auto bg-white
		                    ${loadDataStatus === "loading" || nbResults === 0 ? "filter grayscale blur-xs contrast-20" : ""}`}
			>
				<header className="relative">
					<div className="absolute hidden lg:flex items-center text-ryb_primary-bg
                    bg-ryb_primary-default text-sm font-bold right-20 rounded-b-md px-2 pb-1"
					>
						<ShareIcon className="px-1 stroke-2" />
						{t("header.opendata")}
					</div>
					<div className="flex bg-ryb_primary-bg">
						<img src={appDefinition.logo} alt={appDefinition.logo} className="h-24 py-2 px-4 lg:h-28 lg:px-10" />
						<p className="hidden md:inline self-center border-l-1 text-xl text-white my-2 pl-4 w-full leading-6 tracking-tight lg:w-80">
							<Trans t={t} i18nKey="header.headline">
                                    Find the sites with the label <span className="text-ryb_primary-default">SafeGuard</span> and affiliates.
							</Trans>
						</p>
						<div className="flex flex-grow justify-center content-center lg:space-x-5 font-semibold text-white items-center lg:relative">
							<p className={`hidden lg:flex items-center cursor-pointer hover:text-ryb_primary-default${
								menu === "HOME" ? " text-ryb_primary-default" : ""
							}`} onClick={() => dispatch(appSlice.actions.setPage("HOME"))}>
								<MapIcon fontSize="small" className="mr-2" /> {t("header.menu.search")}
							</p>
							<p className={`hidden lg:flex items-center cursor-pointer hover:text-ryb_primary-default${
								menu === "ABOUT" ? " text-ryb_primary-default" : ""
							}`} onClick={() => dispatch(appSlice.actions.setPage("ABOUT"))}>
								<InfoIcon fontSize="small" className="mr-2" />
								{t("header.menu.about")}
							</p>
							<p className={`hidden lg:flex items-center cursor-pointer hover:text-ryb_primary-default${
								menu === "GET" ? " text-ryb_primary-default" : ""
							}`} >
								<a href="https://onboardwith.bureauveritas.com/" target="_blank" rel="noreferrer">
									<LabelIcon fontSize="small" className="mr-2" />{t("header.menu.get")}
								</a>
							</p>
							<IF condition={menu === "HOME" && !mobileMenu}>
								<RYBFilterMenu appSlice={appSlice} locales={appDefinition.locales} rybColorMap={rybColorMap} />
							</IF>
						</div>
						<div className="flex items-center self-center mr-4 p-4 rounded-lg bg-ryb_primary-default text-white lg:hidden"
							onClick={() => setMobileMenu(!mobileMenu)}>
							<MenuIcon />
						</div>
					</div>
				</header>
				<main className="relative flex flex-grow overflow-y-scroll" >
					<IF condition={mobileMenu}>
						<div className="flex flex-col justify-center items-end w-full space-y-20
                         bg-ryb_primary-bg pr-6 text-2xl font-semibold text-white z-20">
							<div className={`flex items-center hover:text-ryb_primary-default${menu === "HOME" ? " text-ryb_primary-default" : ""}`}
								onClick={() => { setMobileMenu(false); dispatch(appSlice.actions.setPage("HOME")); }}>
								<MapIcon fontSize="large" className="mr-4 mt-1" />
								<p>{t("header.menu.search")}</p>
							</div>
							<div className={`flex items-center hover:text-ryb_primary-default${menu === "ABOUT" ? " text-ryb_primary-default" : ""}`}
								onClick={() => { setMobileMenu(false); dispatch(appSlice.actions.setPage("ABOUT")); }}>
								<InfoIcon fontSize="large" className="mr-4 mt-1" />
								<p>{t("header.menu.about")}</p>
							</div>
							<a className="flex items-center hover:text-ryb_primary-default"
								href="https://onboardwith.bureauveritas.com/" rel="noreferrer">
								<LabelIcon fontSize="large" className="mr-4 mt-1" />
								<p>{t("header.menu.get")}</p>
							</a>
							<div className="flex self-center space-x-6 ">
								<a href="https://www.linkedin.com/company/bureau-veritas-group" target="_blank" rel="noreferrer">
									<LinkedInIcon style={{ fontSize: 60 }} />
								</a>
								<a href="https://twitter.com/bureauveritas" target="_blank" rel="noreferrer">
									<TwitterIcon style={{ fontSize: 60 }} />
								</a>
							</div>
						</div>
					</IF>
					<IF condition={menu === "HOME"}>
						<div className="hidden h-full pl-3 pr-2 py-2 lg:flex md:flex-col xl:pl-6 lg:w-96 xl:w-112">
							<div className="flex justify-between items-center text-sm pt-2 pr-4">
								<p className="">{nbResults} {t("list.results")}</p>
								<div className="flex">
									<p className="font-bold pr-2">{t("list.ongoing")}</p>
									<RYBToggleSwitch initialState={false}
										handleChange={(e) => e.checkbox
											? dispatch(appSlice.actions.refine([{ key: "status", value: "Labeled" }]))
											: activeRefines.status ?
												dispatch(appSlice.actions.refine([{ key: "status", value: undefined }]))
												: null} />
								</div>
							</div>
							<p className="text-xl font-semibold border-b py-2">{t("list.title")}</p>
							<div className="flex flex-col  justify-between overflow-y-auto xl:large "
								style={{
									height: (screenHeight ? (screenHeight - headerFooterSize) : `calc(100vh - ${headerFooterSize})`),
									mawHeight: (screenHeight ? (screenHeight - headerFooterSize) : `calc(100vh - ${headerFooterSize})`)
								}}
								ref={listRef}>
								{activeDatasets[listSites].data.map(
									(site, i) => <RYBSites key={`site ${site}-${i}`} site={site} appSlice={appSlice}
										rybColorMap={rybColorMap} locales={appDefinition.locales} />
								)}
								<div className="self-center px-0 -mx-2 py-3 border-0">
									<Pagination page={page} count={Math.floor(nbResults / 10)} color="primary" onChange={handleChange} />
								</div>

							</div>
						</div>
						<div className="relative flex h-full flex-grow bg-alpha_map-street ryb colorCluster z-0"
						>
							<ParentSize className="graph-container" debounceTime={10}>
								{(parent) => (
									<MapLeaflet
										appSlice={appSlice}
										dataset={mapMarkers}
										geopointField="geometry.coordinates"
										maxClusterRadius={40}
										CustomMarker={RYBMapMarker}
										mapType="jawgStreet"
										noClusterScore={true}
										defaultColor="#d3d3d3"
										colorMap={rybColorMap}
										scrollWheelZoom={true}
										colorKey="businessSegment"
										mapHeight={parent.height}
									/>
								)}
							</ParentSize>
						</div>

						{!mobileMenu && <div className="absolute flex items-center justify-center text-3xl font-black rounded-full
                        bg-ryb_primary-default cursor-pointer text-white w-12 h-12 bottom-6 right-6 z-10">
							<RYBLegalTooltip mobileMenu={mobileMenu} locales={appDefinition.locales} />
						</div>}

					</IF>
					<IF condition={menu === "ABOUT" && !mobileMenu}>
						<RYBAbout locales={appDefinition.locales} />
					</IF>
					<IF condition={menu === "TERMS" && !mobileMenu}>
						<div className="px-2 py-4">
							<Tabs tabSet={termsTabSet} locales={appDefinition.locales}/>
						</div>
					</IF>
				</main>
				<footer className="flex flex-col justify-between flex-shrink-0
                    bg-ryb_primary-bg text-white text-sm xl:h-40 py-1 px-2 lg:px-10 lg-py-2">
					<RYBFooter locales={appDefinition.locales} setMobileMenu={setMobileMenu} appSlice={appSlice} />
				</footer>
			</div>
		</div>
	);
};

export default RYB;
