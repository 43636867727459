import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const SupplyRTermPageHeader = ({
	page, Picto, locales, children
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<div className="flex justify-center items-center w-full pt-4 pb-12 px-4 md:pl-16 md:pr-12 h-80
        bg-gradient-to-r from-supplyr_primary-gradientL via-supplyr_primary-gradientV to-supplyr_primary-gradientR">

			<div className="flex flex-col md:flex-row justify-between items-center w-full h-full py-4">
				<div className="flex flex-grow items-center justify-between">
					<div className="relative flex flex-col z-10">
						<div className="absolute -top-20 -left-12 opacity-60
                                    text-white" style={{ zIndex: -1 }}>
							<Picto style={{ fontSize: 240 }} />
						</div>
						<p className="font-semibold text-3xl md:text-5xl">{t(`${page}.header.title`)}</p>
						<p className="pt-3 font-light text-medium md:text-base">{t(`${page}.header.subTitle`)}</p>
					</div>
				</div>
				{children &&
                    <div className="md:self-end">
                    	{children}
                    </div>
				}
			</div>
		</div>

	);
};

SupplyRTermPageHeader.propTypes = {
	page: PropTypes.string,
	Picto: PropTypes.object,
	locales: PropTypes.string,
	children: PropTypes.object
};

export default SupplyRTermPageHeader;
