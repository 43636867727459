import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Controller } from "react-hook-form";

import { useDispatch } from "react-redux";
import { refreshSiteLabelId } from "./FormUtils";

const AdminSupplierSelectFieldEditor = ({
	field,
	control,
	options,
	appSlice,
	placeholder = "Select...",
	styles,
	defaultValue,
	requiredFields,
	disabledFields,
	setValue,
	activeRefines,
	getValues,
	activeDatasets,
	reset,
	setMarkerPosition
}) => {
	const dispatch = useDispatch();

	const [searchKey, setSearchKey] = useState("");

	const isDisabled = disabledFields?.includes(field);

	return <Controller
		name={`${field}`}
		control={control}
		render={({
			field: {
				onChange, value, name, ref
			}
		}) => (
			<Select
				inputRef={ref}
				placeholder={placeholder}
				defaultValue={defaultValue}
				styles={styles}
				value={options?.find((c) => c.value === value)}
				inputValue={searchKey}
				options={options?.map((option) => ({ label: option.displayField, value: option.displayField, dispatchField: option.dispatchField }))}
				onChange={(selectedOption) => {
					setValue(`${field}`, selectedOption.value);

					if (field === "supplier" || field === "auditedCompany" || field === "company") {
						Object.entries(selectedOption?.dispatchField)?.forEach(([key, value]) => {
							dispatch(appSlice.actions.refine([{ key, value }]));
						});
					}
				}}
				onInputChange={(query, { action }) => {
					if (action === "input-blur" || action === "menu-close") {
						return;
					}

					setSearchKey(query);
					onChange(query);
					setValue(`${field}`, query);

					// rerender siteLabelId when auditedCompany modified for supplyr
					if ((field === "auditedCompany" || field === "supplier")
						&& getValues("supplier")
						&& getValues("auditedCompany")
						&& (activeRefines.secLevelMenu === "supplyR" || activeRefines.secLevelMenu === "ataglance")
					) {
						setValue(`${field}`, query);

						refreshSiteLabelId({
							activeDatasets, activeRefines, getValues, setValue, reset, setMarkerPosition
						});
					}

					// rerender siteId when company modified for clarity
					if ((field === "company" || field === "supplier")
						&& getValues("supplier")
						&& getValues("company")
						&& activeRefines.secLevelMenu === "clarity"
					) {
						setValue(`${field}`, query);

						refreshSiteLabelId({
							activeDatasets, activeRefines, getValues, setValue, reset, setMarkerPosition
						});
					}

					// rerender supplierCode when change supplier's input (not select from list)
					if (getValues("supplier") && (!getValues("supplierCode"))) {
						const supplierCode = activeDatasets?.supplierCode?.data
						?.find((item) => item.supplier === getValues("supplier").toUpperCase())?.supplierCode
						?? "";

						setValue("supplierCode", supplierCode);
					}

					// rerender factorycode when change auditedCompany's input (not select from list)
					if (getValues("auditedCompany") && (!getValues("factoryCode"))) {
						const factoryCode = activeDatasets?.factoryCode?.data
						?.find((item) => item.auditedCompany === getValues("auditedCompany"))?.factoryCode
						?? "";

						setValue("factoryCode", factoryCode);
					}
				}}
				isDisabled={isDisabled}
			/>)}
	/>;
};

AdminSupplierSelectFieldEditor.propTypes = {
	control: PropTypes.object,
	field: PropTypes.string,
	placeholder: PropTypes.string,
	appSlice: PropTypes.object,
	options: PropTypes.array,
	styles: PropTypes.object,
	defaultValue: PropTypes.object,
	requiredFields: PropTypes.array,
	disabledFields: PropTypes.array,
	setValue: PropTypes.func,
	activeRefines: PropTypes.object,
	getValues: PropTypes.func,
	activeDatasets: PropTypes.object,
	reset: PropTypes.func,
	setMarkerPosition: PropTypes.func
};

export default AdminSupplierSelectFieldEditor;
