import { Marker, Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import L from "leaflet";
import { useTranslation } from "react-i18next";

import getValueNestedObject from "../../utils/GetValueNestedObject";
import SupplyRTreeTooltipSupplier from "../cards/SupplyRTreeTooltipSupplier";
import { appDefinition, getMarkerColor } from "../../apps/configs/SupplyRConfig";

const getIcon = (pathFillColor) => {
	const svgTemplate = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 16 16" xml:space="preserve">
   <path fill="${pathFillColor}" class="path1" stroke="black" stroke-width= "0.3px"
   d="M8 2.1c1.1 0 2.2 0.5 3 1.3 0.8 0.9 1.3 1.9 1.3 3.1s-0.5 2.5-1.3 3.3l-3
   3.1-3-3.1c-0.8-0.8-1.3-2-1.3-3.3 0-1.2 0.4-2.2 1.3-3.1 0.8-0.8 1.9-1.3 3-1.3z"></path>

</svg>`;

	const icon = L.divIcon({
		className: "marker",
		html: svgTemplate,
		iconSize: [48, 48],
		iconAnchor: [24, 36],
		popupAnchor: [7, -16]
	});
	return icon;
};

const SupplyRMapMarker = ({
	appSlice,
	marker,
	geopointField,
	filtered = false
}) => {
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	// need it for tooltip render in SupplyRTreeTooltipSupplier
	const { t } = useTranslation(appDefinition.locales);

	const geopoint = getValueNestedObject(marker, geopointField);

	if (!geopoint || geopoint?.[0] === null || geopoint?.[0] === "") {
		return null;
	}

	const score = (!filtered && marker.resilience) ?
		marker.resilience
		: (marker.score && marker.score[0] && marker.score[0].resilience);
	return (
		<Marker score={(activeRefines?.module && marker.moduleScores)
			? marker.moduleScores[activeRefines.module] : (marker?.resilience || 0)}
		position={[Number(geopoint[1]), Number(geopoint[0])]}
		icon={getIcon(getMarkerColor((activeRefines?.module && marker.moduleScores)
			? marker.moduleScores[activeRefines.module]?.toFixed(0) : (score?.toFixed(0) || 0)))}
			// eventHandlers={{
			//	click: (e) => {
			//		dispatch(appSlice.actions.refine([
			//			{ key: "supplier", value: marker.supplier },
			//			{ key: "siteId", value: marker.siteId }
			//		]));
			//	}
			// }}
		>
			<Popup direction="top" offset={[-8, -8]} opacity={1} >
				<div className="-my-2 -mx-4 z-2xl">
					<SupplyRTreeTooltipSupplier appSlice={appSlice} companiesInfo={marker} transparent />
				</div>
			</Popup>
		</Marker>

	);
};

SupplyRMapMarker.propTypes = {
	marker: PropTypes.object,
	geopointField: PropTypes.string,
	fieldsTooltip: PropTypes.array,
	defaultColor: PropTypes.string,
	dataset: PropTypes.string,
	identicalProducts: PropTypes.array,
	product: PropTypes.string,
	appSlice: PropTypes.object,
	filtered: PropTypes.bool
};

export default SupplyRMapMarker;
