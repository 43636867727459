import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import BusinessIcon from "@mui/icons-material/Business";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import CloseIcon from "@mui/icons-material/Close";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { appDefinition } from "../../apps/configs/SupplyRConfig";

import IF from "../../utils/IF";
import Loading from "../../utils/Loading";

const SCASupplierDetailedCard = ({
	appSlice, dataset
}) => {
	const { t } = useTranslation(appDefinition.locales);

	// Load the store hook
	const dispatch = useDispatch();
	const currentView = useSelector(appSlice.selectSubPage);

	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const currentSupplierDetail = useSelector(appSlice.selectDatasets)[dataset]?.data;

	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	const currentSelectedSite = activeRefines.manufacturer !== undefined
		? currentSupplierDetail?.filter((node) => (node.manufacturer === activeRefines.manufacturer))
		: undefined;

	return (
		<div className="flex flex-col w-full">

			<div className="flex items-center gap-x-1 text-sm -mb-2">
				<button className={`bg-white shadow-lg rounded-t-lg py-2 px-3 hover:underline 
                cursor-pointer ${currentView !== "ALTERNATIVES" ? "underline" : "text-gray-600"}`}
				onClick={() => { dispatch(appSlice.actions.setPage("SITES")); dispatch(appSlice.actions.setSubPage("SITE")); }}>
					{t("DetailCard.tabs.sitePage")}
				</button>

			</div>

			<div className="bg-white w-auto h-auto rounded-xl shadow-lg px-2 py-2">
				<div className="flex flex-col gap-y-1">
					<div className="flex justify-between pb-2">
						<BusinessIcon fontSize="large" className="pl-1 mr-2" />
						<div className="flex flex-growclose flex-col pt-2 text-ellipsis w-full">
							<p>{activeRefines.manufacturer}</p>
						</div>
						<CloseIcon className="cursor-pointer mr-1" fontSize="inherit"
							onClick={() => dispatch(appSlice.actions.clear([ { refine: "manufacturer" }, { refine: "equipmentCategory" }]))} />
					</div>

					<IF condition={loadDataStatus !== "idle"}>
						<Loading isComponent />
					</IF>

					{/* TODO: 8.resume product */}
					{
						loadDataStatus === "idle"
						&& currentSelectedSite
						?.map((node, i) => <div
							key={`node ${i}`}
							className={`flex justify-between ${activeRefines.siteId === undefined ? "px-2" : "pl-1 pr-2"}`}>
							{
								activeRefines.siteId
								&& <div className="cursor-pointer" onClick={() => dispatch(appSlice.actions.clear([{ refine: "siteId" }]))}>
									<ArrowBackIcon />
								</div>
							}
							<HomeWorkIcon fontSize="medium" className="ml-1 mr-2" />
							<div className="flex flex-col flex-grow w-full">
								<div className="text-medium text-ellipsis w-full cursor-pointer"
									onClick={() => dispatch(appSlice.actions.refine([{ key: "siteId", value: node.siteId }]))}>
									{/* {node.auditedCompany} */}
								</div>
								<div className="flex justify-between items-center">
									<div className="flex flex-col">
										<p className="text-smaller font-light text-ellipsis w-36">{node.city} - {node.country}</p>
									</div>
								</div>
							</div>
						</div>
						)
					}
				</div>
			</div>

			{/* TODO: 8.resume product */}
			{
				loadDataStatus === "idle"
					&& currentSelectedSite?.map((item, i) => (
						<div key={`item ${i}`} className={`relative flex ml-2 pb-4  ${i === 0 ? "mt-6" : "mt-2"}`}>
							<div className={`absolute  border-gray-400 border-l border-b w-2  ${i === 0 ? "h-6 -top-5" : "h-11 -top-10"}`} />
							<div className="flex item-center bg-white  justify-between w-11/12 -mt-4  h-10 rounded-lg shadow-md ml-2">
								<div className="flex item-center cursor-pointer "
									onClick={() => activeRefines.equipmentCategory
										? dispatch(appSlice.actions.clear([{ refine: "equipmentCategory" }]))
										: dispatch(appSlice.actions.refine([{ key: "equipmentCategory", value: [item.equipmentCategory] }]))}>
									<DeviceHubIcon fontSize="small" className="m-auto ml-3" />
									<p className="m-auto ml-1.5 text-sm line-clamp-1 ">{item.equipmentCategory} - ({item.city}-{item.country})</p>
								</div>
							</div>
						</div>
					))}
		</div>

	);
};

SCASupplierDetailedCard.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string
};

export default SCASupplierDetailedCard;
