/* eslint-disable react/prop-types */
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PinDropIcon from "@mui/icons-material/PinDrop";
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";

import LegalNotice from "../../termsLocales/en/RYBLegalNotice";
import Terms from "../../termsLocales/en/PrivateAppTerms";
import Privacy from "../../termsLocales/en/RYBPrivacy";
import Cookies from "../../termsLocales/en/RYBCookies";

import SupplyRInfoDesk from "../../components/content/SupplyRInfoDesk";
import SupplyRSplash from "../../components/content/SupplyRSplash";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} slice Name of the slice related to the app created and references in the store ./store.js
 * @param {String} logo Label of the menu, if none, only the picto
 */
export const appDefinition = {
	name: "Supply-R",
	subName: "",
	launcherID: "SUPPLYR",
	slice: "supplyR",
	locales: "translationsSupplyR",
	theme: "supplyr",
	favicon: "/images/SupplyR/favicon.ico",
	logo: "/images/SupplyR/icon-menu-supply-r.svg",
	clientAccessor: "projectName"
};

/**
 * Set up  available language to display
 */
export const langListValue = [
	{ value: "en", label: "EN" },
	{ value: "fr", label: "FR" },
	{ value: "zh-Hans", label: "简体" },
	{ value: "zh-Hant", label: "繁體" }
];

/**
 * Set up  menu options to display
 *
 * @param {String} menu ID of the menu (use for the initial menu definition and the render of the app)
 * @param {String} type Route or App = Route is a link to jump to a new url, App is a internal link to the App to display specific information
 * @param {String} url [OPTIONAL] If type == url, the new url to link the menu (it has to be routed in ./App.js)
 * @param {String} label [OPTIONAL] Label of the menu, if none, only the picto
 * @param {String} sectionTitleWhite [OPTIONAL] Section title in white displayed on top of the page when the menu is clicked
 * @param {String} sectionTitleBlack [OPTIONAL] Section title in black displayed on top of the page when the menu is clicked
 * @param {Component} picto Material UI Icon to represent the field in the select, to be imported in this file
 */
export const menuOptions = [
	{
		menu: "HOME",
		type: "App",
		initial: true,
		label: "Home",
		clearRefine: [ "section1Label", "criticality"],
		picto: AccountTreeIcon
	},
	{
		menu: "NCs",
		type: "App",
		initial: false,
		label: "Areas of concern",
		clearRefine: ["section1Label", "criticality"],
		picto: FlagOutlinedIcon
	},
	{
		menu: "SITES",
		type: "App",
		initial: false,
		label: "My suppliers",
		clearRefine: ["supplier", "siteId", "section1Label", "criticality"],
		picto: BusinessIcon
	},
	{
		menu: "REPORTS",
		type: "App",
		initial: false,
		label: "My reports",
		clearRefine: ["section1Label", "criticality"],
		picto: AssignmentRoundedIcon
	},
	{
		menu: "INFO",
		type: "App",
		label: "Info",
		picto: ErrorOutlineIcon
	}
];

/**
 * Name of the dataset with the client parameters
 */
const SupplyRClientParameters = "SupplyRClientParameters";

/**
 * Name of the dataset for the supplyR Public web app
 *
 * @param {String} SupplyRChain Name of the Document DB collection for the supplyR Public web app
 */
const SupplyRChain = "SupplyRChainScores";

/**
 * Name of the dataset for the supplyR Public web app
 *
 * @param {String} SupplyRChain Name of the Document DB collection for the supplyR Public web app
 */
const SupplyRModules = "SupplyRAssignmentScores";

/**
 * Name of the dataset for the supplyR Public web app
 *
 * @param {String} SupplyRSections Name of the Document DB collection for the supplyR Public web app
 */
const SupplyRSections = "SupplyRSectionScores";

/**
 * Name of the dataset Checkpoint for the supplyR web app
 */
export const SupplyRCheckpoints = "SupplyRCheckpoints";

/**
 * Name of the dataset Sites for the Clarity web app
 */

const SupplyRSites = "SupplyRSiteScores";

/** Total Demo collection */
const TotalDemo = "SupplyRDemoTotalEnergiesCapabilities";
/**
 * Query to load and update the non conformities
 */
export const listNonConformityQuery = [
	{
		$match: {
			last: true,
			answer: 0
		}
	},
	{
		$project: {
			publicationDate: 1,
			question: 1,
			answer: "$auditorResult",
			supplierResult: 1,
			supplierComment: 1,
			auditorResult: 1,
			auditorComment: 1,
			siteId: 1,
			siteType: "$supplierType",
			supplier: 1,
			auditedCompany: 1,
			critical: 1,
			criticality: 1,
			product: 1,
			country: 1,
			city: "$site.city",
			domain: "$module",
			section1Label: 1,
			assignmentTechnicalid: 1
		}
	}
];

/**
 * Arry of the full list of facets to refine
 */
export const listFacetsToRefine = ["projectName", "module", "tierLevel", "type", "finalProduct",
	"product", "region", "country", "supplier", "siteId", "supplierCriticality"];

/**
 * Client parameters
 */
export const clientInitParameters = {
	name: "clientParameters",
	collection: SupplyRClientParameters,
	facetsToRefine: ["projectName"],
	fetch: "find",
	limit: 1
};

export const getClientParametersByProjectName = (projectName) => ({
	name: "clientParameters",
	collection: SupplyRClientParameters,
	facetsToRefine: ["spaceLabel"],
	fetch: "aggregation",
	query: [
		{
			$match: {
				projectName
			}
		}
	]
});

/**
 * Function to refresh the list of nonConformities after a click on a new page
 *
 * @param {Number} value Page to refresh the list of sites
 */
export const updateListNonConformities = (dataset, limit, value) => ({
	name: dataset,
	collection: SupplyRCheckpoints,
	fetch: "aggregation",
	facetsToRefine: listFacetsToRefine.concat("criticality"),
	query: listNonConformityQuery,
	limit,
	skip: ((value - 1) * limit)
});

/**
  * Active DataSets definition for the app
  *
  * @param {String} name
  * @param {String} collection
  * @param {String} fetch
  * @param {String[]} facetsToRefine
  * @param {Object[]} query
  * @param {Number} limit
  * @param {Object[]} data
  */
export const supplyRInitDatasets = {
	clientParameters: {
		name: "clientParameters",
		collection: SupplyRClientParameters,
		facetsToRefine: ["projectName"],
		fetch: "find",
		limit: 1
	},
	listProjects: {
		collection: SupplyRClientParameters,
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						projectName: "$projectName"
					},
					projectLabel: { $first: "$projectLabel" },
					nbProjectName: { $sum: 1 }
				}
			},
			{
				$project: {
					projectName: "$_id.projectName",
					projectLabel: 1,
					nbProjectName: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listModules: {
		collection: SupplyRModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "module"),
		query: [
			{
				$group: {
					_id: {
						module: "$module"
					},
					nbAssignements: { $sum: 1 }
				}
			},
			{
				$project: {
					module: "$_id.module",
					nbAssignements: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listTiers: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "tierLevel"),
		query: [
			{
				$group: {
					_id: {
						tierLevel: "$tierLevel"
					},
					tierLabel: { $first: "$tierLabel" },
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					tierLevel: "$_id.tierLevel",
					tierLabel: "$tierLabel",
					nbSites: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listTypes: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "type"),
		query: [
			{
				$group: {
					_id: {
						type: "$type"
					},
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					type: "$_id.type",
					nbSites: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listProducts: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "product" && element !== "finalProduct"),
		query: [
			{
				$project: {
					"option.label": "$product",
					"option.value": "$product",
					productFamilyCp: "$productFamilyCp"
				}
			}, {
				$group: {
					_id: "$productFamilyCp",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id"
				}
			}
		],
		limit: 500
	},
	listFinalProducts: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "product" && element !== "finalProduct"),
		query: [
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			}, {
				$project: {
					finalProduct: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 500
	},
	listGeographies: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "country" &&
        element !== "region"),
		query: [
			{
				$project: {
					"option.label": "$country",
					"option.value": "$country",
					region: "$region"
				}
			}, {
				$group: {
					_id: "$region",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					refine: "region"
				}
			}, {
				$match: {
					_id: {
						$ne: "undefined"
					}
				}
			}
		],
		limit: 500
	},
	listEntities: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "supplier" &&
        element !== "siteId"),
		query: [
			{
				$project: {
					"option.label": "$auditedCompany",
					"option.value": "$siteId",
					supplier: "$supplier"
				}
			}, {
				$group: {
					_id: "$supplier",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					refine: "supplier"
				}
			}, {
				$match: {
					_id: {
						$ne: "undefined"
					}
				}
			}
		],
		limit: 500
	},
	listSections: {
		collection: SupplyRSections,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "section1Label"),
		query: [{
			$project: {
				"option.index": "$sectionIndex",
				"option.label": "$section1Label",
				"option.value": "$section1Label",
				module: "$module"
			}
		}, {
			$group: {
				_id: "$module",
				options: { $addToSet: "$option" }
			}
		}, {
			$addFields: {
				label: "$_id",
				value: "$_id",
				refine: "module"
			}
		}],
		limit: 500
	},
	listCriticalities: {
		collection: SupplyRCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "criticality"),
		query: [
			{
				$match: { last: true }
			},
			{
				$group: {
					_id: {
						criticality: "$criticality"
					},
					nbCheckpoints: { $sum: 1 }
				}
			},
			{
				$project: {
					criticality: "$_id.criticality",
					nbCheckpoints: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listSupplierCriticalities: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "supplierCriticality"),
		query: [
			{
				$group: {
					_id: {
						supplierCriticality: "$supplierCriticality"
					}
				}
			}, {
				$project: {
					supplierCriticality: "$_id.supplierCriticality",
					_id: 0
				}
			}, {
				$match: {
					$and: [
						{
							supplierCriticality: {
								$exists: true
							}
						}, {
							supplierCriticality: {
								$ne: ""
							}
						}
					]
				}
			}
		],
		limit: 500
	},
	supplyChain: {
		collection: SupplyRChain,
		fetch: "find",
		facetsToRefine: ["projectName"],
		query: [],
		limit: 5000
	},
	filteredSupplyChain: {
		collection: SupplyRChain,
		fetch: "find",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "module"),
		query: [],
		limit: 5000
	},
	mapSupplyChainSiteId: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						siteId: "$siteId"
					}
				}
			},
			{
				$project: {
					siteId: "$_id.siteId",
					_id: 0
				}
			}
		],
		limit: 5000
	},
	supplyChainActiveFinalProducts: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			},
			{
				$project: {
					finalProduct: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 500
	},
	scores: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {},
					resilience: {
						$avg: "$resilience"
					},
					resistance: {
						$avg: "$resistance"
					},
					responsiveness: {
						$avg: "$responsiveness"
					}
				}
			},
			{
				$project: {
					_id: 0,
					resilienceScore: "$resilience",
					resistanceScore: "$resistance",
					responsivenessScore: "$responsiveness"
				}
			}
		],
		limit: 500
	},
	scoresModule: {
		collection: SupplyRModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: { last: true }
			},
			{
				$group: {
					_id: {},
					resilience: {
						$avg: "$resilience"
					},
					resistance: {
						$avg: "$resistance"
					},
					responsiveness: {
						$avg: "$responsiveness"
					}
				}
			},
			{
				$addFields: {
					resilienceScore: "$resilience",
					resistanceScore: "$resistance",
					responsivenessScore: "$responsiveness"
				}
			}
		],
		limit: 500
	},
	modulesDistribution: {
		collection: SupplyRModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: { last: true }
			},
			{
				$group: {
					_id: {
						module: "$module"
					},
					auditType: {
						$first: "$auditType"
					},
					auditMode: {
						$first: "$auditMode"
					},
					resilienceAvg: {
						$avg: "$resilience"
					},
					resistanceAvg: {
						$avg: "$resistance"
					},
					responsivenessAvg: {
						$avg: "$responsiveness"
					},
					reports: { $addToSet: "$assignmentTechnicalId" }
				}
			},
			{
				$project: {
					key: "module",
					module: "$_id.module",
					auditType: 1,
					auditMode: 1,
					reports: 1,
					value: "$resilienceAvg",
					resistance: "$resistanceAvg",
					responsiveness: "$responsivenessAvg",
					_id: 0
				}
			}
		],
		limit: 500
	},
	sectionsDistribution: {
		collection: SupplyRSections,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: { last: true }
			},
			{
				$group: {
					_id: {
						module: "$module",
						section: "$section1Label",
						sectionIndex: "$sectionIndex"
					},
					resilienceAvg: {
						$avg: "$resilience"
					}
				}
			},
			{
				$project: {
					key: "section",
					section: "$_id.section",
					sectionIndex: "$_id.sectionIndex",
					module: "$_id.module",
					value: "$resilienceAvg",
					_id: 0
				}
			}
		],
		limit: 500
	},
	placeholderSectionsList: {
		collection: "SupplyRModulesBeforeNAFilter",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						module: "$module",
						section: "$section1Label",
						sectionIndex: "$sectionIndex"
					}
				}
			}, {
				$addFields: {
					value: null
				}
			}, {
				$project: {
					key: "section",
					section: "$_id.section",
					sectionIndex: "$_id.sectionIndex",
					module: "$_id.module",
					value: "$value",
					placeholder: "N/A",
					_id: 0
				}
			}
		],
		limit: 500
	},
	finalProductDistribution: {
		collection: SupplyRModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: { last: true }
			},
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					},
					resilienceAvg: {
						$avg: "$resilience"
					}
				}
			},
			{
				$project: {
					key: "finalProduct",
					finalProduct: "$_id.finalProduct",
					value: "$resilienceAvg",
					_id: 0
				}
			}
		],
		limit: 500
	},
	finalProductBenchmark: {
		collection: SupplyRSections,
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$match: { last: true }
			},
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					},
					resilienceAvg: {
						$avg: "$resilience"
					}
				}
			},
			{
				$project: {
					key: "finalProduct",
					finalProduct: "$_id.finalProduct",
					value: "$resilienceAvg",
					_id: 0
				}
			}
		],
		limit: 500
	},
	reportsTable: {
		collection: SupplyRModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		pages: ["REPORTS"],
		query: [
			{
				$unwind: {
					path: "$assignedFormId"
				}
			}, {
				$lookup: {
					from: "SupplyRAssignedForm",
					localField: "assignedFormId",
					foreignField: "assignedFormId",
					as: "assignedForm"
				}
			}, {
				$addFields: {
					assignedForm: {
						$arrayElemAt: [
							"$assignedForm", 0
						]
					}
				}
			}, {
				$project: {
					supplier: "$supplier",
					auditedCompany: "$assignedForm.site.auditedCompany",
					country: "$country",
					city: "$city",
					module: "$module",
					product: "$product",
					auditMode: "$auditMode",
					auditType: "$auditType",
					endDate: "$endDate",
					url: {
						$concat: [
							"https://odsdatahub.bureauveritas.com/reports/", "$assignmentTechnicalId"
						]
					},
					deliverableId: {
						$arrayElemAt: [
							"$assignedForm.deliverableIds.deliverableId", 0
						]
					},
					_id: 0
				}
			}
		]
	},
	sitesDistribution: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					resilience: { $exists: true }
				}
			},
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId",
						auditedCompany: "$auditedCompany"
					},
					resilienceScore: {
						$avg: "$resilience"
					}
				}
			},
			{
				$project: {
					key: "sites",
					supplier: "$_id.supplier",
					siteId: "$_id.siteId",
					auditedCompany: "$_id.auditedCompany",
					resilienceScore: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	sitesDistributionByFilter: {
		collection: SupplyRModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					last: true,
					resilience: {
						$exists: true
					}
				}
			}, {
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId",
						auditedCompany: "$auditedCompany"
					},
					resilienceScore: {
						$avg: "$resilience"
					}
				}
			}, {
				$project: {
					key: "sites",
					supplier: "$_id.supplier",
					siteId: "$_id.siteId",
					auditedCompany: "$_id.auditedCompany",
					resilienceScore: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	resilienceScoreAvgSupplier: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					resilience: {
						$exists: true
					}
				}
			}, {
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId",
						auditedCompany: "$auditedCompany"
					},
					resilienceScore: {
						$avg: "$resilience"
					}
				}
			}, {
				$project: {
					key: "sites",
					supplier: "$_id.supplier",
					siteId: "$_id.siteId",
					auditedCompany: "$_id.auditedCompany",
					resilienceScore: 1,
					_id: 0
				}
			}, {
				$group: {
					_id: "$supplier",
					resilienceScore: {
						$avg: "$resilienceScore"
					}
				}
			}, {
				$project: {
					supplier: "$_id",
					resilienceScore: 1,
					_id: 0
				}
			}
		]
	},
	resilienceScoreAvgSupplierByFilter: {
		collection: SupplyRModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					last: true,
					resilience: {
						$exists: true
					}
				}
			}, {
				$group: {
					_id: {
						supplier: "$supplier"
					},
					resilienceAvg: {
						$avg: "$resilience"
					}
				}
			}, {
				$project: {
					resilienceScore: "$resilienceAvg",
					supplier: "$_id.supplier",
					_id: 0
				}
			}
		]
	},
	sitesBenchmark: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$match: {
					resilience: { $exists: true }
				}
			},
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId",
						auditedCompany: "$auditedCompany"
					},
					resilienceScore: {
						$avg: "$resilience"
					}
				}
			},
			{
				$project: {
					key: "sites",
					supplier: "$_id.supplier",
					siteId: "$_id.siteId",
					auditedCompany: "$_id.auditedCompany",
					resilienceScore: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	criticalityDistribution: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						supplierCriticality: "$supplierCriticality"
					},
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					supplierCriticality: "$_id.supplierCriticality",
					frequency: "$nbSites",
					_id: 0
				}
			}
		],
		limit: 10
	},
	listNonConformities: {
		collection: SupplyRCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["criticality", "section1Label"]),
		query: listNonConformityQuery,
		skip: 0,
		limit: 10
	},
	nonConformities: {
		collection: SupplyRCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["criticality", "section1Label"]),
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						year: { $year: "$publicationDate" },
						month: { $month: "$publicationDate" },
						siteId: "$siteId"
					},
					nbNC: { $sum: "$nbOpenedNc" },
					nbCNC: { $sum: "$nbCnc" },
					nbSites: { $sum: 1 }
				}
			},
			{
				$group: {
					_id: {
						year: "$_id.year",
						month: "$_id.month"
					},
					sites: { $addToSet: "$_id.siteId" },
					nbSites: { $sum: 1 },
					nbNC: { $sum: "$nbNC" },
					nbCNC: { $sum: "$nbCNC" }
				}
			},
			{
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					sites: 1,
					nbSites: 1,
					nbNC: 1,
					nbCNC: 1,
					_id: 0
				}
			}
		],
		limit: 5000
	},
	nonConformitiesByModules: {
		collection: SupplyRCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["criticality", "section1Label"]),
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						domain: "$module"
					},
					nbNC: { $sum: "$nbOpenedNc" },
					nbCNC: { $sum: "$nbCnc" }
				}
			}, {
				$project: {
					domain: "$_id.domain",
					nbNC: 1,
					nbCNC: 1,
					_id: 0
				}
			}
		],
		limit: 30
	},
	suppliers: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "siteId" && element !== "product"),
		query: [
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId"
					},
					siteId: { $first: "$siteId" },
					supplier: { $first: "$supplier" },
					product: { $addToSet: "$product" },
					module: { $addToSet: "$module" }
				}
			}
		],
		limit: 1000
	},
	sites: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId"
					},
					project: {
						$first: "$project"
					},
					spaceId: {
						$first: "$spaceId"
					},
					supplier: {
						$first: "$supplier"
					},
					supplierCode: {
						$first: "$supplierCode"
					},
					siteLabelId: {
						$first: "$siteLabelId"
					},
					siteId: {
						$first: "$siteId"
					},
					auditedCompany: {
						$first: "$auditedCompany"
					},
					factoryCode: {
						$first: "$factoryCode"
					},
					address: {
						$first: "$address"
					},
					country: {
						$first: "$country"
					},
					city: {
						$first: "$city"
					},
					resilience: {
						$avg: "$resilience"
					},
					resistance: {
						$avg: "$resistance"
					},
					responsiveness: {
						$avg: "$responsiveness"
					},
					openedNcByProduct: {
						$addToSet: {
							product: "$product",
							sumNbOpenedNc: "$sumNbOpenedNc"
						}
					},
					product: {
						$addToSet: "$product"
					},
					module: {
						$addToSet: "$module"
					},
					resilienceScores: {
						$addToSet: "$resilienceScores"
					},
					report: {
						$addToSet: "$report"
					},
					geometry: {
						$first: "$geometry"
					},
					upStream: {
						$addToSet: "$nextSupplier"
					},
					factoryBranch: {
						$first: "$factoryBranch"
					},
					factoryIndex: {
						$first: "$factoryIndex"
					}
				}
			}, {
				$addFields: {
					sumNbOpenedNc: {
						$sum: "$openedNcByProduct.sumNbOpenedNc"
					},
					products: {
						$reduce: {
							input: "$product",
							initialValue: "",
							in: {
								$concat: [
									"$$value", "$$this", "-"
								]
							}
						}
					},
					upStreamCount: {
						$size: "$upStream"
					},
					module: {
						$arrayElemAt: [
							"$module", 0
						]
					},
					resilienceScores: {
						$arrayElemAt: [
							"$resilienceScores", 0
						]
					}
				}
			}, {
				$addFields: {
					moduleScores: {
						$map: {
							input: "$module",
							as: "res",
							in: {
								k: "$$res",
								v: {
									$arrayElemAt: [
										"$resilienceScores", {
											$indexOfArray: [
												"$module", "$$res"
											]
										}
									]
								}
							}
						}
					}
				}
			}, {
				$addFields: {
					moduleScores: {
						$arrayToObject: [
							"$moduleScores"
						]
					}
				}
			}
		],
		limit: 1000
	},
	allSites: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId"
					},
					project: {
						$first: "$project"
					},
					spaceId: {
						$first: "$spaceId"
					},
					supplier: {
						$first: "$supplier"
					},
					siteId: {
						$first: "$siteId"
					},
					auditedCompany: {
						$first: "$auditedCompany"
					},
					address: {
						$first: "$address"
					},
					country: {
						$first: "$country"
					},
					city: {
						$first: "$city"
					},
					resilience: {
						$avg: "$resilience"
					},
					resistance: {
						$avg: "$resistance"
					},
					responsiveness: {
						$avg: "$responsiveness"
					},
					openedNcByProduct: {
						$addToSet: {
							product: "$product",
							sumNbOpenedNc: "$sumNbOpenedNc"
						}
					},
					product: {
						$addToSet: "$product"
					},
					module: {
						$addToSet: "$module"
					},
					resilienceScores: {
						$addToSet: "$resilienceScores"
					},
					report: {
						$addToSet: "$report"
					},
					geometry: {
						$first: "$geometry"
					}
				}
			}, {
				$addFields: {
					sumNbOpenedNc: {
						$sum: "$openedNcByProduct.sumNbOpenedNc"
					}
				}
			}, {
				$project: {
					openedNcByProduct: 0
				}
			}
		],
		limit: 1000
	},
	downStream: {
		collection: SupplyRChain,
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						nextSupplier: "$nextSupplier"
					},
					nextSupplier: {
						$first: "$nextSupplier"
					},
					downStream: {
						$push: "$$ROOT"
					}
				}
			}, {
				$project: {
					_id: 1,
					nextSupplier: 1,
					downStream: 1,
					downStreamCount: {
						$size: "$downStream"
					}
				}
			}
		],
		limit: 1000
	},
	totalDemo: {
		collection: TotalDemo,
		fetch: "aggregation",
		query: [
			{
				$addFields: {
					totalManhours: "$Total manhours",
					complianceRate: "$Compliance rate",
					Compliant: "$Compliance rate",
					"Not Compliant": {
						$subtract: [
							1, "$Compliance rate"
						]
					}
				}
			}, {
				$project: {
					"Total manhours": 0,
					"Compliance rate": 0
				}
			}
		]
	},
	totalMockColumnGraph: {
		collection: TotalDemo,
		fetch: "aggregation",
		query: [
			{
				$addFields: {
					categories: [
						{
							label: "Offshore",
							score: "$Offshore"
						}, {
							label: "Floating",
							score: "$Floating"
						}, {
							label: "Subsea",
							score: "$Subsea"
						}, {
							label: "Renewables",
							score: "$Renewables"
						}
					]
				}
			}, {
				$project: {
					auditedCompany: 1,
					categories: 1,
					projectName: 1,
					totalManhours: "$Total manhours",
					complianceRate: "$Compliance rate"
				}
			}, {
				$unwind: {
					path: "$categories"
				}
			}, {
				$addFields: {
					label: "$categories.label",
					score: "$categories.score"
				}
			}, {
				$project: {
					categories: 0,
					_id: 0
				}
			}
		]
	}
};

/**
 * Define the color code and name for non conformities
 */
export const nonConformities = { Critical: "#FF475C", Noncritical: "#FFC362" };

/**
 * List of key indicators / modules to be shown on the supplyR app
 */
export const domainsOptions = {
	"Business Continuity": {
		locale: "businessContinuity",
		logo: "/images/SupplyR/Modules/module-supplyr-business@2x.png"
	},
	"Data Integrity": {
		locale: "dataIntegrity",
		logo: "/images/SupplyR/Modules/module-supplyr-data@2x.png"
	},
	"HSE Resistance": {
		locale: "HSEResistance",
		logo: "/images/SupplyR/Modules/module-supplyr-hse@2x.png"
	},
	"Information Security": {
		locale: "informationSecurity",
		logo: "/images/SupplyR/Modules/module-supplyr-security@2x.png"
	},
	"Logistics & Inventory Practices": {
		locale: "logisticsInventoryPractices",
		logo: "/images/SupplyR/Modules/module-supplyr-logistic@2x.png"
	},
	"Supplier Technical Assessment": {
		locale: "supplierTechnicalAssessment",
		logo: "/images/SupplyR/Modules/module-supplyr-technical@2x.png"
	},
	"Food safety": {
		locale: "foodSafety",
		logo: "/images/SupplyR/Modules/module-supplyr-technical@2x.png"
	},
	"Business Governance & Social Engagement": {
		locale: "businessGovernance",
		logo: "/images/SupplyR/Modules/module-supplyr-social@2x.png"
	}
};

/**
 * SupplyR AuditMode Icons
 */
const auditModes = {
	Remote: {
		locale: "auditMode.remote",
		picto: HeadsetMicIcon
	},
	"On Site": {
		locale: "auditMode.onSite",
		picto: VisibilityIcon
	},
	"Self Assessment": {
		locale: "auditMode.self",
		picto: PlaylistAddCheckIcon
	}
};

export function getAuditMode(auditType, auditMode) {
	if (auditType === "Field Audit" || auditType === "Final Audit") {
		return auditModes[auditMode];
	}

	return auditModes["Self Assessment"];
}

/**
 * SupplyR SplashScreen screens
 */
export const splashScreens = [
	{
		name: "welcome",
		type: "full",
		img: "/images/SupplyR/Splashscreen/Splashscreen-001.png"
	},
	{
		name: "page1",
		type: "page",
		img: "/images/SupplyR/Splashscreen/Splashscreen-002.png"
	},
	{
		name: "page2",
		type: "page",
		img: "/images/SupplyR/Splashscreen/Splashscreen-003.png"
	},
	{
		name: "page3",
		type: "page",
		img: "/images/SupplyR/Splashscreen/Splashscreen-004.png"
	},
	{
		name: "page4",
		type: "page",
		img: "/images/SupplyR/Splashscreen/Splashscreen-005.png"
	}
];

/**
 * Array of tags to be displayed for each non conformities
 */
export const listTagsNonConformities = {
	supplier: {
		accessor(d, t) { return d.supplier; },
		picto(d) { return BusinessIcon; },
		refine: [{ key: "supplier", valueKey: (d) => d.supplier }]
	},
	auditedCompany: {
		accessor(d, t) { return d.auditedCompany; },
		picto(d) { return HomeWorkIcon; },
		refine: [{ key: "siteId", valueKey: (d) => d.siteId }]
	},
	location: {
		accessor(d, t) { return ((d.city && `${d.city }-`) + d.country); },
		picto(d) { return PinDropIcon; }
	},
	siteType: {
		accessor(d, t) { return d.siteType; },
		picto(d) { return CompareArrowsIcon; }
	},
	module: {
		accessor(d, t) { return t(`domains.${ domainsOptions[d.domain]?.locale }.name`); },
		picto(d) { return domainsOptions[d.domain]?.logo; },
		refine: [{ key: "module", valueKey: (d) => d.module }]
	},
	section: {
		accessor(d, t) { return d.section1Label; },
		picto(d) { return VerticalSplitOutlinedIcon; }
	},
	product: {
		accessor(d, t) { return d.product; },
		picto(d) { return AccountTreeOutlinedIcon; },
		refine: [{ key: "product", valueKey: (d) => d.product }]
	}
};

/**
 * Init Refine of the Application
 *
 * @param {Object} supplyRInitRefines
 * Object representing the initial refine of the datasets
 * in the App slice, the key being the name of the field
 * in the DB Collection to filter on, the value being the value to filter on
 */
export const supplyRInitRefines = {};

/**
 * Set up refine filter options to display
 *
 * @param {String} refine name of the field in the DB Collection to filter on
 * @param {String} loadFromDataset name of the collection to filter on the field
 * @param {String} placeHolder [OPTIONAL] text when nothing is selected
 * @param {Component} tag Material UI Icon to represent the field in the select, to be imported in this file
 * @param {Boolean} clear will be cleared if the button clear is pushed
 * @param {Boolean} toClearOnChange execute a clear of other clearable fields (see above) if the value has changed
 */
export const filterOptions = [
	{
		refine: "projectName",
		loadFromDataset: "listProjects",
		hiddenIfUnique: true,
		isSearchable: false,
		clear: false,
		toClearOnChange: true,
		displayField: "projectLabel"
	},
	{
		refine: "finalProduct",
		loadFromDataset: "listFinalProducts",
		placeHolder: "filters.finalProducts",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Modules",
		refine: "module",
		loadFromDataset: "listModules",
		placeHolder: "filters.modules",
		isMulti: false,
		isSearchable: false,
		isClearable: true,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Tier Level",
		refine: "tierLevel",
		displayField: "tierLabel",
		loadFromDataset: "listTiers",
		placeHolder: "filters.tier",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isClearable: false,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "type",
		loadFromDataset: "listTypes",
		placeHolder: "filters.type",
		hiddenIfUnique: true,
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isClearable: false,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "product",
		loadFromDataset: "listProducts",
		placeHolder: "filters.products",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: true,
		isGrouped: true,
		clear: true,
		toClearOnChange: false,
		groupSelection: false
	},
	{
		refine: "country",
		loadFromDataset: "listGeographies",
		placeHolder: "filters.geographies",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isGrouped: true,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Scope",
		refine: "supplierCriticality",
		loadFromDataset: "listSupplierCriticalities",
		placeHolder: "filters.supplierCriticality",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isClearable: true,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Supplier",
		refine: "siteId",
		refineGroup: "supplier",
		loadFromDataset: "listEntities",
		placeHolder: "filters.searchAnEntity",
		manualPosition: true,
		isSearchable: true,
		isClearable: true,
		isGrouped: true,
		tag: BusinessIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Section",
		refine: "section1Label",
		loadFromDataset: "listSections",
		placeHolder: "filters.sections",
		isClearable: true,
		isSearchable: false,
		isGrouped: true,
		groupSelection: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		label: "Criticality",
		refine: "criticality",
		loadFromDataset: "listCriticalities",
		placeHolder: "filters.criticality",
		isClearable: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		refine: "supplier",
		manualPosition: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "region",
		placeHolder: "filters.region",
		manualPosition: true,
		isMulti: true,
		clear: true,
		toClearOnChange: false
	}
];

export const fieldsTooltip = [
	{ label: "Supplier", key: "supplier" },
	{ label: "Product", key: "product" },
	{ label: "Address", key: "address" },
	{ label: "Location", key: "location" },
	{ label: "Resilience", key: "resilience" },
	{ label: "Resistance", key: "resistance" },
	{ label: "Responsiveness", key: "responsiveness" }
];

/**
 * Define general supply-R ranking rules
 */
export const colorLegend = {
	unsatisfactory: {
		color: "#eb4c72",
		range: [0, 30]
	},
	subStandard: {
		color: "#fe8f71",
		range: [30, 55]
	},
	inProgress: {
		color: "#fec461",
		range: [55, 65]
	},
	average: {
		color: "#e7e401",
		range: [65, 80]
	},
	good: {
		color: "#ccdb74",
		range: [80, 95]
	},
	veryGood: {
		color: "#70ad46",
		range: [95, 100]
	}

};
export const manHoursCS = {
	unsatisfactory: {
		color: "#EB4C72",
		range: [0, 3000000]
	},
	average: {
		color: "#FFC362",
		range: [3000000, 3499999]
	},
	veryGood: {
		color: "#CCDB73",
		range: [3500000, 99999999]
	}
};

export const getManHoursColor = (score) => {
	if (!score) return "#c9bbbd";

	if (score >= manHoursCS.unsatisfactory.range[0]
        && score < manHoursCS.unsatisfactory.range[1]) {
		return manHoursCS.unsatisfactory.color;
	}
	if (score >= manHoursCS.average.range[0]
        && score < manHoursCS.average.range[1]) {
		return manHoursCS.average.color;
	}
	if (score >= manHoursCS.veryGood.range[0]
        && score <= manHoursCS.veryGood.range[1]) {
		return manHoursCS.veryGood.color;
	}
};

export const ratingsConfig = [
	{ rating: "Compliant", color: "#6DC3A0" },
	{ rating: "Not Compliant", color: "#FF475C" }
];
/**
 * Define the rule for the Marker color on the map
 */
export const getMarkerColor = (score) => {
	if (!score) return "#c9bbbd";

	if (score >= colorLegend.unsatisfactory.range[0]
        && score < colorLegend.unsatisfactory.range[1]) {
		return colorLegend.unsatisfactory.color;
	} // Unsatisfactory

	if (score >= colorLegend.subStandard.range[0]
        && score < colorLegend.subStandard.range[1]) {
		return colorLegend.subStandard.color;
	} // subStandard

	if (score >= colorLegend.inProgress.range[0]
        && score < colorLegend.inProgress.range[1]) {
		return colorLegend.inProgress.color;
	} // inProgress

	if (score >= colorLegend.average.range[0]
        && score < colorLegend.average.range[1]) {
		return colorLegend.average.color;
	} // average

	if (score >= colorLegend.good.range[0]
        && score < colorLegend.good.range[1]) {
		return colorLegend.good.color;
	} // good

	if (score >= colorLegend.veryGood.range[0]
        && score <= colorLegend.veryGood.range[1]) {
		return colorLegend.veryGood.color;
	} // veryGood
};

export const subFilters = [
	{ name: "Final product", field: "finalProduct" },
	{ name: "Product", field: "product" },
	{ name: "Country", field: "country" }
];

export const tableStructure = (activeRefines, t, clientParams) => {
	let shouldReturn = [];
	const base = [
		{
			Header: "Entity",
			accessor: "supplier"
		},
		{
			Header: "Factory",
			accessor: "auditedCompany"
		},
		{
			Header: "City",
			accessor: "city"
		},
		{
			Header: "Country",
			accessor: "country"
		},
		{
			Header: "Product",
			accessor: "products",
			Cell: ({ value }) => value.slice(0, -1)
		},
		{
			Header: "TotalStreamCount",
			accessor: "totalStreamCount"
		},
		{
			Header: "Score",
			id: "score",
			accessor: (row) => activeRefines.module ? row?.moduleScores?.[activeRefines.module] : row?.resilience,
			Cell: ({ value }) => <p className="font-semibold">{value?.toFixed(0)}{value !== null ? "%" : ""}</p>
		},
		{
			Header: "NCs",
			accessor: "sumNbOpenedNc",
			Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-red-500 rounded-full h-7 w-7 text-white">{value}</p> : <p/>
		}
	];

	shouldReturn = [...base];

	if (clientParams?.displaySupplierCode) {
		shouldReturn = [
			...shouldReturn.slice(0, 1),
			{
				Header: "SupplierCode",
				accessor: "supplierCode"
			},
			...shouldReturn.slice(1)
		];
	}

	if (clientParams?.displayFactoryCode) {
		// if column supplierCode exists
		if (clientParams?.displaySupplierCode) {
			shouldReturn = [
				...shouldReturn.slice(0, 3),
				{
					Header: "FactoryCode",
					accessor: "factoryCode"
				},
				...shouldReturn.slice(3)
			];

			return shouldReturn;
		}

		// if column supplierCode not exist
		shouldReturn = [
			...shouldReturn.slice(0, 2),
			{
				Header: "FactoryCode",
				accessor: "factoryCode"
			},
			...shouldReturn.slice(2)
		];

		return shouldReturn;
	}

	return shouldReturn;
};

export const reportsTableStructure = (activeRefines, t) => [
	{
		Header: "supplier",
		accessor: "supplier"
	},
	{
		Header: "auditedCompany",
		accessor: "auditedCompany"
	},
	{
		Header: "country",
		accessor: "country"
	},
	{
		Header: "city",
		accessor: "city"
	},
	{
		Header: "module",
		accessor: "module"
	},
	{
		Header: "product",
		accessor: "product"
	},
	{
		Header: "auditMode",
		accessor: "auditMode"
	},
	{
		Header: "auditType",
		accessor: "auditType"
	},
	{
		Header: "auditDate",
		accessor: "endDate",
		Cell: ({ value }) => new Date(value).toLocaleDateString("fr")
	},
	{
		Header: "deliverableId",
		accessor: "deliverableId"
	},
	{
		Header: "url",
		accessor: "url",
		Cell: ({ value }) => <span>
			<a
				className="relative z-xl compassHovering text-gray-600 hover:font-semibold visited:text-gray-400"
				href={value}
				target="_blank" rel="noreferrer">Download</a>
		</span>
	}
];

/**
 *
 * @param {String} tab list
 */
export const termsTabSet = {
	"Legal Notice": {
		en: <LegalNotice/>,
		fr: <LegalNotice/>,
		"zh-Hans": <LegalNotice/>,
		"zh-Hant": <LegalNotice/>
	},
	Terms: {
		en: <Terms/>,
		fr: <Terms/>,
		"zh-Hans": <Terms/>,
		"zh-Hant": <Terms/>
	},
	Privacy: {
		en: <Privacy/>,
		fr: <Privacy/>,
		"zh-Hans": <Privacy/>,
		"zh-Hant": <Privacy/>
	},
	Cookies: {
		en: <Cookies/>,
		fr: <Cookies/>,
		"zh-Hans": <Cookies/>,
		"zh-Hant": <Cookies/>
	}
};

export const infoTabSet = {
	Info: {
		en: <SupplyRInfoDesk />,
		fr: <SupplyRInfoDesk />,
		"zh-Hans": <SupplyRInfoDesk />,
		"zh-Hant": <SupplyRInfoDesk />
	},
	Tutorial: {
		en: <SupplyRSplash />,
		fr: <SupplyRSplash />,
		"zh-Hans": <SupplyRSplash />,
		"zh-Hant": <SupplyRSplash />
	}
};
