import prepareTargetData from "./VerkorForecastHelpers";

const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
const colorSchema = [{ label: "medium", color: "orange" }, { label: "high", color: "#FF475C" }]; // #FF475C - variation of red

function CreateVerkorNCforecastLines(
	sortedNonConformitiesData, nonConformitiesByTargetDate, nonConformitiesByClosureDate, actionPlanData, clientParameters) {
	// if there is no NC data or if there is no action plan
	if (sortedNonConformitiesData.length < 1 || actionPlanData.length < 1) {
		return {
			linesNonConformities: [], lineNbSites: [], openedNC: 0, criticalNC: 0, nbSites: 0, colorSchema
		};
	}
	// lines for single site
	if (clientParameters.projectLabel === "Verkor" || clientParameters.supplyAudits) {
		const sortedTargetData = [...nonConformitiesByTargetDate]
		.sort((dotA, dotB) => ((dotA.year > dotB.year) ? 1 : ((dotA.year < dotB.year) ? -1 : ((dotA.month > dotB.month) ? 1 : -1))));

		const sortedClosureData = [...nonConformitiesByClosureDate]
		.sort((dotA, dotB) => ((dotA.year > dotB.year) ? 1 : ((dotA.year < dotB.year) ? -1 : ((dotA.month > dotB.month) ? 1 : -1))));

		// Sort by date to be able to pick the highest date for building the time span.
		const sortedActionPlanData = [...actionPlanData]
		.sort((a, b) => (new Date(b.actionPlanTargetDate) - new Date(a.actionPlanTargetDate)));

		const targetData = prepareTargetData(sortedTargetData, sortedNonConformitiesData, sortedActionPlanData);
		const closureData = prepareTargetData(sortedClosureData, sortedNonConformitiesData, sortedActionPlanData);

		// The data regarding the months before the publication date is located on the last position.
		const targetsBeforePublication = targetData.pop();
		const closuresBeforePublication = closureData.pop();

		let lineNbSites;
		let openedNC;
		let criticalNC;
		let nbSites;

		// BUILD THE LINES FOR A SINGLE SITE VIEW
		if (sortedNonConformitiesData.length === 1) {
			const singleViewOpenNC = closureData
			.reduce((acc, currentValue, currentIndex) => {
				if (currentIndex === 0) {
					const vl1 = sortedNonConformitiesData[currentIndex].nbNC ? sortedNonConformitiesData[currentIndex].nbNC : 0;
					const vl2 = sortedNonConformitiesData[currentIndex].nbCNC ? sortedNonConformitiesData[currentIndex].nbCNC : 0;
					const vl3 = (currentValue.nbNC ? currentValue.nbNC : 0) - (currentValue.nbCNC ? currentValue.nbCNC : 0);
					// If nbNC = 0 and we substract the nbCNC from 0 we'll have a negative value. If this is the case
					// return 0 instead of negative number.
					const vl = vl1 - vl2 - vl3 - closuresBeforePublication.nonCriticalNC < 0 ? 0 :
						vl1 - vl2 - vl3 - closuresBeforePublication.nonCriticalNC;
					// set up initial value (the first item of the line);
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						value: vl,
						color: "orange",
						stroke: "transparent",
						strokeDasharray: false,
						tooltip: sortedNonConformitiesData[currentIndex].tooltipNCStatus
							? Object.entries(sortedNonConformitiesData[currentIndex].tooltipNCStatus).reduce((tNCAcc, tNCCur) => {
							// eslint-disable-next-line prefer-destructuring
								tNCAcc[tNCCur[0]] = tNCCur[1] - closuresBeforePublication.tooltipNCStatus[tNCCur[0]]
							- (currentValue.tooltipNCStatus ? currentValue.tooltipNCStatus[tNCCur[0]] : 0);
								return tNCAcc;
							}, {})
							: null,
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				} else {
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						// value: currentValue.nbNC - currentValue.nbCNC + (currentIndex > 0 ? acc[currentIndex - 1].value : 0),
						value: acc[currentIndex - 1].value - (currentValue.nbNC === undefined ? 0 : currentValue.nbNC - currentValue.nbCNC),
						color: "orange",
						stroke: "transparent",
						strokeDasharray: false,
						tooltip: acc[currentIndex - 1].tooltip
							? Object.entries(acc[currentIndex - 1].tooltip).reduce((tNCAcc, tNCCur) => {
							// eslint-disable-next-line prefer-destructuring
								tNCAcc[tNCCur[0]] = tNCCur[1] - (currentValue.tooltipNCStatus === undefined ? 0 : currentValue.tooltipNCStatus[tNCCur[0]]);
								return tNCAcc;
							}, {}) : 0,

						// The flag to identify what would be the last item in the graph line, we only want to go to
						// the current month with this line.
						lastOne: currentValue.month === new Date().getMonth() + 1 && currentValue.year === new Date().getFullYear(),
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				}
				return acc;
			}, [{}]);

			// Build the line only till the current (ongoing month) included
			// If the current month is not present (found) make sure the line does not exceed
			// 8 elements otherwise it looks bad.
			let endIndex = singleViewOpenNC.indexOf(singleViewOpenNC.find((elem) => elem.lastOne));
			// const slicedTestSingleViewOpenNC = testSingleViewOpenNC.slice(0, endIndex + 1);
			const slicedSingleViewOpenNC = singleViewOpenNC.slice(0, (endIndex !== -1 ? endIndex + 1 : 8));

			const singleViewOpenCNC = closureData
			.reduce((acc, currentValue, currentIndex) => {
				if (currentIndex === 0) {
				// set up initial value (the first item of the line);
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						value: sortedNonConformitiesData[currentIndex].nbCNC - closuresBeforePublication.criticalNC,
						color: "orange",
						stroke: "transparent",
						strokeDasharray: false,
						tooltip: sortedNonConformitiesData[currentIndex].tooltipCNCStatus
							? Object.entries(sortedNonConformitiesData[currentIndex].tooltipCNCStatus).reduce((tNCAcc, tNCCur) => {
							// eslint-disable-next-line prefer-destructuring
								tNCAcc[tNCCur[0]] = tNCCur[1] - closuresBeforePublication.tooltipCNCStatus[tNCCur[0]]
						- (currentValue.tooltipCNCStatus ? currentValue.tooltipCNCStatus[tNCCur[0]] : 0);
								return tNCAcc;
							}, {})
							: null,
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				} else {
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						// value: currentValue.nbNC - currentValue.nbCNC + (currentIndex > 0 ? acc[currentIndex - 1].value : 0),
						value: acc[currentIndex - 1].value - (currentValue.nbCNC === undefined ? 0 : currentValue.nbCNC),
						color: "orange",
						stroke: "transparent",
						strokeDasharray: false,
						tooltip: acc[currentIndex - 1].tooltip
							? Object.entries(acc[currentIndex - 1].tooltip).reduce((tNCAcc, tNCCur) => {
								// eslint-disable-next-line prefer-destructuring
								tNCAcc[tNCCur[0]] = tNCCur[1] - (currentValue.tooltipCNCStatus === undefined ? 0
									: currentValue.tooltipCNCStatus[tNCCur[0]]);
								return tNCAcc;
							}, {}) : 0,
						// The flag to identify what would be the last item in the graph line, we only want to go to
						// the current month with this line.
						lastOne: currentValue.month === new Date().getMonth() + 1 && currentValue.year === new Date().getFullYear(),
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				}
				return acc;
			}, [{}]);

			// Build the line only till the current (ongoing month) included
			// If the current month is not present (found) make sure the line does not exceed
			// 8 elements otherwise it looks bad.
			endIndex = singleViewOpenCNC.indexOf(singleViewOpenCNC.find((elem) => elem.lastOne));
			const slicedSingleViewOpenCNC = singleViewOpenCNC.slice(0, (endIndex !== -1 ? endIndex + 1 : 8));

			const singleViewTargetNC = targetData
			.reduce((acc, currentValue, currentIndex) => {
				if (currentIndex === 0) {
				// set up initial value (the first item of the line) which is:
				// the actual value of opened NCs minus target values for the month
				// and minus all those with target values before the publication date.
					const vl1 = sortedNonConformitiesData[currentIndex].nbNC - sortedNonConformitiesData[currentIndex].nbCNC;
					const vl2 = (currentValue.nbNC ? currentValue.nbNC : 0) - (currentValue.nbCNC ? currentValue.nbCNC : 0);
					const vl = vl1 - vl2 - targetsBeforePublication.nonCriticalNC;
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						// value: currentValue.nbNC - currentValue.nbCNC + (currentIndex > 0 ? acc[currentIndex - 1].value : 0),
						value: vl,
						color: "orange",
						stroke: "transparent",
						strokeDasharray: true,
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				} else {
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						// value: currentValue.nbNC - currentValue.nbCNC + (currentIndex > 0 ? acc[currentIndex - 1].value : 0),
						value: acc[currentIndex - 1].value - (currentValue.nbNC === undefined ? 0 : currentValue.nbNC - currentValue.nbCNC),
						color: "orange",
						stroke: "transparent",
						strokeDasharray: true,
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				}
				return acc;
			}, [{}]);

			const singleViewTargetCNC = targetData
			.reduce((acc, currentValue, currentIndex) => {
				if (currentIndex === 0) {
				// set up initial value (the first item of the line) which is:
				// the actual value of opened critical NCs minus target values for the month
				// and minus all those with target values before the publication date.
					const vl1 = sortedNonConformitiesData[currentIndex].nbCNC ? sortedNonConformitiesData[currentIndex].nbCNC : 0;
					const vl2 = currentValue.nbCNC ? currentValue.nbCNC : 0;
					const vl = vl1 - vl2 - targetsBeforePublication.criticalNC;
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						// value: currentValue.nbNC - currentValue.nbCNC + (currentIndex > 0 ? acc[currentIndex - 1].value : 0),
						value: vl,
						color: "red",
						stroke: "transparent",
						strokeDasharray: true,
						tooltip: sortedNonConformitiesData[currentIndex].tooltipNCStatus
							? Object.entries(sortedNonConformitiesData[currentIndex].tooltipNCStatus).reduce((tNCAcc, tNCCur) => {
							// eslint-disable-next-line prefer-destructuring
								tNCAcc[tNCCur[0]] = currentIndex > 0
									? acc[currentIndex - 1].tooltip[tNCCur[0]] + tNCCur[1]
									: tNCCur[1];
								return tNCAcc;
							}, {})
							: null,
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				} else {
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						// value: currentValue.nbNC - currentValue.nbCNC + (currentIndex > 0 ? acc[currentIndex - 1].value : 0),
						value: acc[currentIndex - 1].value - (currentValue.nbCNC === undefined ? 0 : currentValue.nbCNC),
						color: "red",
						stroke: "transparent",
						strokeDasharray: true,
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				}
				return acc;
			}, [{}]);

			// For the forecast view of the single site the vertical bars will show the total number of nonConformities instead
			// of number of sites.
			lineNbSites = slicedSingleViewOpenNC
			.map((dot, index) => ({ month: dot.month, frequency: slicedSingleViewOpenNC[index].value + slicedSingleViewOpenCNC[index].value }));

			openedNC = slicedSingleViewOpenNC[slicedSingleViewOpenNC.length - 1]?.value;
			criticalNC = slicedSingleViewOpenCNC[slicedSingleViewOpenCNC.length - 1]?.value;
			nbSites = lineNbSites.length;

			openedNC += criticalNC;

			const linesNonConformities = [(slicedSingleViewOpenNC.reduce((a, b) => a + b.value, 0) > 0
				? { _id: "medium", list: slicedSingleViewOpenNC }
				: null),
			(singleViewTargetNC.reduce((a, b) => a + b.value, 0) > 0
				? { _id: "medium", list: singleViewTargetNC }
				: null),
			(slicedSingleViewOpenCNC.reduce((a, b) => a + b.value, 0) > 0
				? { _id: "high", list: slicedSingleViewOpenCNC }
				: null),
			(singleViewTargetCNC.reduce((a, b) => a + b.value, 0) > 0
				? { _id: "high", list: singleViewTargetCNC }
				: null)].filter((e) => e !== null);

			return {
				linesNonConformities, lineNbSites, openedNC, criticalNC, nbSites, colorSchema
			};
		}

		// BUILD THE LINES FOR THE AGGREGATED VIEW (many sites)
		if (sortedNonConformitiesData.length > 1) {
			const lineCriticalNC = closureData
			.reduce((acc, currentValue, currentIndex) => {
				if (currentIndex === 0) {
					const vl1 = sortedNonConformitiesData[currentIndex].nbCNC ? sortedNonConformitiesData[currentIndex].nbCNC : 0;
					const vl2 = closureData[currentIndex].nbCNC ? closureData[currentIndex].nbCNC : 0;
					const vl = vl1 - vl2 - closuresBeforePublication.criticalNC;
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						// Substract the closures that happened before the publication date and on the publication date
						// (i.e. on the zero index).
						value: vl,
						tooltip: sortedNonConformitiesData[currentIndex]?.tooltipCNCStatus
							? Object.entries(sortedNonConformitiesData[currentIndex].tooltipCNCStatus).reduce((tNCAcc, tNCCur) => {
								// eslint-disable-next-line prefer-destructuring
								tNCAcc[tNCCur[0]] = tNCCur[1] - closuresBeforePublication.tooltipCNCStatus[tNCCur[0]]
								- (closureData[currentIndex].tooltipCNCStatus ? closureData[currentIndex].tooltipCNCStatus[tNCCur[0]] : 0);
								return tNCAcc;
							}, {})
							: null,
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				} else {
					const vl1 = sortedNonConformitiesData[currentIndex]?.nbCNC ? sortedNonConformitiesData[currentIndex].nbCNC : 0;
					const vl2 = closureData[currentIndex].nbCNC ? closureData[currentIndex].nbCNC : 0;
					const vl3 = acc[currentIndex - 1].value;
					const vl = vl1 - vl2 + vl3;
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						value: vl,
						tooltip: Object.entries(acc[currentIndex - 1].tooltip).reduce((tNCAcc, tCNCCur) => {
							const vl1 = sortedNonConformitiesData[currentIndex]?.tooltipNCStatus ?
								sortedNonConformitiesData[currentIndex].tooltipCNCStatus[tCNCCur[0]] : 0;
							const vl2 = closureData[currentIndex]?.tooltipCNCStatus ? closureData[currentIndex].tooltipCNCStatus[tCNCCur[0]] : 0;
							const vl3 = acc[currentIndex - 1].tooltip[tCNCCur[0]];
							const vl = vl1 - vl2 + vl3;
							// eslint-disable-next-line prefer-destructuring
							tNCAcc[tCNCCur[0]] = vl;
							return tNCAcc;
						}, {}),
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				}

				return acc;
			}, [{}]);

			// Build the line only till the current (ongoing month) included
			// If the current month is not present (found) make sure the line does not exceed
			// 8 elements otherwise it looks bad.
			let endIndex = lineCriticalNC.indexOf(lineCriticalNC.find((elem) => elem.lastOne));
			const slicedLineCriticalNC = lineCriticalNC.slice(0, (endIndex !== -1 ? endIndex + 1 : 8));

			const lineOpenedNC = closureData
			.reduce((acc, currentValue, currentIndex) => {
				if (currentIndex === 0) {
					const vl1 = sortedNonConformitiesData[currentIndex].nbNC ? sortedNonConformitiesData[currentIndex].nbNC
					- sortedNonConformitiesData[currentIndex].nbCNC : 0;
					const vl2 = closureData[currentIndex].nbNC ? closureData[currentIndex].nbNC
					- closureData[currentIndex].nbCNC : 0;
					const vl = vl1 - vl2 - closuresBeforePublication.nonCriticalNC;
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						value: vl,
						tooltip: sortedNonConformitiesData[currentIndex]?.tooltipNCStatus
							? Object.entries(sortedNonConformitiesData[currentIndex].tooltipNCStatus).reduce((tNCAcc, tNCCur) => {
								// eslint-disable-next-line prefer-destructuring
								tNCAcc[tNCCur[0]] = tNCCur[1] - closuresBeforePublication.tooltipNCStatus[tNCCur[0]]
								- (closureData[currentIndex].tooltipNCStatus ? closureData[currentIndex].tooltipNCStatus[tNCCur[0]] : 0);
								return tNCAcc;
							}, {})
							: null,
						// The flag to identify what would be the last item in the graph line, we only want to go to
						// the current month with this line.
						lastOne: currentValue.month === new Date().getMonth() + 1 && currentValue.year === new Date().getFullYear(),
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				} else {
					const vl1 = sortedNonConformitiesData[currentIndex]?.nbNC ? sortedNonConformitiesData[currentIndex].nbNC
					- sortedNonConformitiesData[currentIndex].nbCNC : 0;
					const vl2 = closureData[currentIndex].nbNC ? closureData[currentIndex].nbNC
					- closureData[currentIndex].nbCNC : 0;
					const vl3 = acc[currentIndex - 1].value;
					const vl = vl1 - vl2 + vl3;
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						value: vl,
						tooltip: Object.entries(acc[currentIndex - 1].tooltip).reduce((tNCAcc, tNCCur) => {
							const vl1 = sortedNonConformitiesData[currentIndex]?.tooltipNCStatus ?
								sortedNonConformitiesData[currentIndex].tooltipNCStatus[tNCCur[0]] : 0;
							const vl2 = closureData[currentIndex]?.tooltipNCStatus ? closureData[currentIndex].tooltipNCStatus[tNCCur[0]] : 0;
							const vl3 = acc[currentIndex - 1].tooltip[tNCCur[0]];
							const vl = vl1 - vl2 + vl3;
							// eslint-disable-next-line prefer-destructuring
							tNCAcc[tNCCur[0]] = vl;
							return tNCAcc;
						}, {}),
						// The flag to identify what would be the last item in the graph line, we only want to go to
						// the current month with this line.
						lastOne: currentValue.month === new Date().getMonth() + 1 && currentValue.year === new Date().getFullYear(),
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				}

				return acc;
			}, [{}]);

			// Build the line only till the current (ongoing month) included
			// If the current month is not present (found) make sure the line does not exceed
			// 8 elements otherwise it looks bad.
			endIndex = lineOpenedNC.indexOf(lineOpenedNC.find((elem) => elem.lastOne));
			const slicedLineOpenedNC = lineOpenedNC.slice(0, (endIndex !== -1 ? endIndex + 1 : 8));

			const lineTargetNC = targetData
			.reduce((acc, currentValue, currentIndex) => {
				if (currentIndex === 0) {
				// set up initial value (the first item of the line) which is:
				// the actual value of opened NCs minus target values for the month
				// and minus all those with target values before the publication date.
					const vl1 = sortedNonConformitiesData[currentIndex].nbNC - sortedNonConformitiesData[currentIndex].nbCNC;
					const vl2 = (currentValue.nbNC ? currentValue.nbNC : 0) - (currentValue.nbCNC ? currentValue.nbCNC : 0);
					const vl = vl1 - vl2 - targetsBeforePublication.nonCriticalNC;
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						value: vl,
						tooltip: sortedNonConformitiesData[currentIndex].tooltipNCStatus
							? Object.entries(sortedNonConformitiesData[currentIndex].tooltipNCStatus).reduce((tNCAcc, tNCCur) => {
								// eslint-disable-next-line prefer-destructuring
								tNCAcc[tNCCur[0]] = tNCCur[1] - targetsBeforePublication.tooltipNCStatus[tNCCur[0]]
								- (targetData[currentIndex].tooltipNCStatus ? targetData[currentIndex].tooltipNCStatus[tNCCur[0]] : 0);
								return tNCAcc;
							}, {})
							: null,
						strokeDasharray: true,
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				} else {
					// Logic: previous accumulative value + actual nonConformity value for specific month
					// minus those with target dates for specific month.
					const vl1 = acc[currentIndex - 1].value;
					const vl2 = (sortedNonConformitiesData[currentIndex] ? sortedNonConformitiesData[currentIndex].nbNC
						- sortedNonConformitiesData[currentIndex].nbCNC : 0);
					const vl3 = (currentValue.nbNC ? currentValue.nbNC : 0) - (currentValue.nbCNC ? currentValue.nbCNC : 0);
					const vl = vl1 + vl2 - vl3;

					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						value: vl,
						strokeDasharray: true,
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				}

				return acc;
			}, [{}]);

			const lineTargetCNC = targetData
			.reduce((acc, currentValue, currentIndex) => {
				if (currentIndex === 0) {
				// set up initial value (the first item of the line) which is:
				// the actual value of opened NCs minus target values for the month
				// and minus all those with target values before the publication date.
					const vl1 = sortedNonConformitiesData[currentIndex].nbCNC;
					const vl2 = currentValue.nbCNC ? currentValue.nbCNC : 0;
					const vl = vl1 - vl2 - targetsBeforePublication.criticalNC;
					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						value: vl,
						tooltip: sortedNonConformitiesData[currentIndex].tooltipCNCStatus
							? Object.entries(sortedNonConformitiesData[currentIndex].tooltipCNCStatus).reduce((tNCAcc, tNCCur) => {
								// eslint-disable-next-line prefer-destructuring
								tNCAcc[tNCCur[0]] = tNCCur[1] - targetsBeforePublication.tooltipCNCStatus[tNCCur[0]]
								- (targetData[currentIndex].tooltipCNCStatus ? targetData[currentIndex].tooltipCNCStatus[tNCCur[0]] : 0);
								return tNCAcc;
							}, {})
							: null,
						strokeDasharray: true,
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				} else {
					// Logic: previous accumulative value + actual nonConformity value for specific month
					// minus those with target dates for specific month.
					const vl1 = acc[currentIndex - 1].value;
					const vl2 = sortedNonConformitiesData[currentIndex] ? sortedNonConformitiesData[currentIndex].nbCNC : 0;
					const vl3 = currentValue.nbCNC ? currentValue.nbCNC : 0;
					const vl = vl1 + vl2 - vl3;

					acc[currentIndex] = {
						month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
						value: vl,
						strokeDasharray: true,
						date: (new Date(currentValue.year, currentValue.month - 1))
					};
				}

				return acc;
			}, [{}]);

			lineNbSites = sortedNonConformitiesData
			.map((dot, i) => ({
				month: `${monthString[dot.month - 1]}-${dot.year?.toString()?.substring(2)}`,
				sites: dot.sites,
				frequency: (dot.nbSites)
			}));

			openedNC = lineOpenedNC[lineOpenedNC.length - 1]?.value;
			const criticalNC = lineCriticalNC[lineCriticalNC.length - 1]?.value;
			nbSites = new Set(lineNbSites.flatMap((el) => el.sites)).size;
			openedNC += criticalNC;

			const linesNonConformities = [
				(slicedLineOpenedNC.reduce((a, b) => a + b.value, 0) > 0
					? { _id: "medium", list: slicedLineOpenedNC }
					: null),
				(lineTargetNC.reduce((a, b) => a + b.value, 0) > 0
					? { _id: "medium", list: lineTargetNC }
					: null),
				(slicedLineCriticalNC.reduce((a, b) => a + b.value, 0) > 0
					? { _id: "high", list: slicedLineCriticalNC }
					: null),
				(lineTargetCNC.reduce((a, b) => a + b.value, 0) > 0
					? { _id: "high", list: lineTargetCNC }
					: null)].filter((e) => e !== null);
			return {
				linesNonConformities, lineNbSites, openedNC, criticalNC, nbSites, colorSchema
			};
		}
	}
	return undefined;
}

export default CreateVerkorNCforecastLines;
