/**
 * Component to display or not Components based on a condition
 * used for the sake of clarity of the code
 *
 * USAGE :
 * <IF condition={condition}>
 *   <Greeter username={user.name} />
 * </IF>
 *
 * @param {Component} children Components to be rendered if the condition is true
 * @param {boolean} condition Condiiton to render the children
 */
const IF = ({ children, condition }) => {
	if (condition) {
		// render children if the condition is truthy
		return children;
	}

	return null;
};
export default IF;
