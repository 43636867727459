import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Notification from "../notifications/Notification";
import { downloadFromS3 } from "../../api/client";
import ImgWithFallbackSrc from "../content/ImgWithFallbackSrc";

function ClientHeader({ clientParameters, appDefinition }) {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	const clientAccessorValue = Array.isArray(clientParameters?.[appDefinition?.clientAccessor])
		? clientParameters?.[appDefinition?.clientAccessor]?.[0]
		: clientParameters?.[appDefinition?.clientAccessor];
	const [previewUrl, setPreviewUrl] = useState(null);
	const fetchLogo = async () => {
		const url = await downloadFromS3({
			filePath: `public/projectLogos/${clientAccessorValue}/${clientParameters?.logo}`,
			appName: appDefinition?.slice
		});
		setPreviewUrl(url);
	};
	useEffect(() => {
		fetchLogo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="relative flex flex-col md:flex-row px-4 md:px-8 w-full pt-4 2xl:px-12">
			<div className="flex flex-col flex-shrink-0 justify-start w-40 z-10">
				<div className="flex items-center justify-center h-12 w-full text-sm p-1
                      bg-white text-black rounded-xl z-10">
					{clientParameters?.logo ?
						<ImgWithFallbackSrc
							src1={`/images/CompanyLogos/${clientParameters?.logo}`}
							src2={previewUrl}
							alt={`${clientParameters?.client} logo`}
							styleString="h-10 object-contain"/>
						: <p className="font-semibold text-lg tracking-tight uppercase">{clientParameters?.client}</p>}
				</div>
				<div className="rounded-md w-3/4 text-sm text-center -mt-3 px-2 pt-3 pb-1 bg-black text-white font-extralight">
					{clientParameters?.market ? t(`markets.${clientParameters?.market}`) : <p>market</p>}
				</div>
			</div>
			<div className="hidden md:block flex-shrink-0 -ml-12 -mt-4 w-64 h-24 rounded-b-full bg-black overflow-hidden z-0">
				<img onError={(ev) => ev.target.src = "/images/Clarity/Markets/default.jpg" }
					src={`/images/Clarity/Markets/${clientParameters?.market}.jpg`} alt="Market Img" />
			</div>

			<div className="absolute md:hidden w-full h-28 -mx-4 -mt-4 rounded-b-full overflow-hidden z-0" >
				<img onError={(ev) => ev.target.src = "/images/Clarity/Markets/default.jpg" }
					src={`/images/Clarity/Markets/${clientParameters?.market}.jpg`} alt="Market Img" />
			</div>

			<Notification appName="Clarity" notifKey="didYouKnow" noClose>
				<div className="hidden xl:flex flex-col justify-between items-start rounded-xl pl-4 pr-2 md:ml-6 py-3
                            bg-white text-black text-sm">
					<p className="font-bold">{t("didYouKnow.title")}</p>
					<div className="flex items-end justify-between">
						<p className="font-light leading-4">
							{t("didYouKnow.message1")}
						</p>
						<a href="https://group.bureauveritas.com/expertise-sustainability"
							className="flex flex-shrink-0 underline text-small w-16">
							{t("didYouKnow.learnMore")}
						</a>
					</div>
				</div>
			</Notification>

		</div>
	);
}

ClientHeader.propTypes = {
	clientParameters: PropTypes.object,
	appDefinition: PropTypes.object
};

export default ClientHeader;
