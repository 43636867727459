import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import WidgetsIcon from "@mui/icons-material/Widgets";
import PinDropIcon from "@mui/icons-material/PinDrop";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import BusinessIcon from "@mui/icons-material/Business";

import IF from "../../utils/IF";
import { netZeroAssetsOptions } from "../../apps/configs/ClarityConfig";
import Loading from "../../utils/Loading";

function RenderNetZeroScore({ nbAssets, nbCO2, reliabilityIndex }) {
	if (!nbAssets || nbAssets === 0) {
		return <div className="w-24 h-18" />;
	}

	return 	<div className="flex flex-col gap-y-1 text-medium font-light">
		{(nbAssets !== -1) && <p className="px-3  bg-black text-white rounded-lg ">
			<span className="font-semibold">{nbAssets}</span> assets
		</p>
		}
		<p className="px-3 bg-clarity_primary-menu rounded-lg">
			<span className="font-semibold">{nbCO2 > 1000 ? (`${(nbCO2 / 1000).toFixed(1) }k`) : nbCO2}</span> tCO<sub>2</sub>eq
		</p>
		<p className="px-3 bg-clarity_primary-netZeroAlt rounded-lg  ">
			<span className="font-semibold">{reliabilityIndex}%</span> reliability
		</p>
	</div>;
}

RenderNetZeroScore.propTypes = {
	nbAssets: PropTypes.number,
	nbCO2: PropTypes.number,
	reliabilityIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

function groupBy(res, value, key, initObject, keys = ["totalAssets", "value", "sites"]) {
	if (!res[value[key]]) {
		res[value[key]] = initObject;
	}
	keys.forEach((acc) => {
		if (acc === "sites") {
			value[acc]?.forEach(res[value[key]][acc].add, res[value[key]][acc]);
		} else res[value[key]][acc] += value[acc];
	});
	if (res[value[key]].sites.size !== 0) res[value[key]].totalAssets = res[value[key]].sites.size;
	return res;
}

function ClarityNetZeroIDCard({
	appSlice, perAsset = false, height, className, locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	const loadStatus = useSelector(appSlice.selectLoadDataStatus);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const netZeroAssetsDistribution = useSelector(appSlice.selectDatasets)?.netZeroAssetsDistribution?.data;
	const netZeroAssetsScoresDistribution = useSelector(appSlice.selectDatasets)?.netZeroAssetsScoresDistribution?.data;
	const netZeroGeoDistribution = useSelector(appSlice.selectDatasets)?.netZeroGeoDistribution?.data;
	const netZeroGeoScoresDistribution = useSelector(appSlice.selectDatasets)?.netZeroGeoScoresDistribution?.data;
	const netZeroDetailedKPIs = useSelector(appSlice.selectDatasets)?.netZeroCO2perSite?.data;
	const siteScores = useSelector(appSlice.selectDatasets)?.sites?.data;

	let netZeroRegionDistribution = {};
	let netZeroCountryDistribution = {};
	let netZeroAssetDistribution = {};
	let netZeroSiteDistribution = {};

	if (loadStatus !== "idle") {
		return 	<div className={`flex flex-col rounded-xl text-sm
        gap-y-8 pl-3 pt-4 border border-white z-20 text-black alphaBlur ${className} `}
		>
			<Loading isComponent />
		</div>;
	}

	if (!perAsset) {
		netZeroRegionDistribution = netZeroGeoDistribution?.reduce((res, value) => groupBy(res, value, "region", {
			region: value.region, totalAssets: 0, value: 0, sites: new Set()
		}), {});

		netZeroRegionDistribution = netZeroGeoScoresDistribution?.filter((country) => country.domain?.indexOf("NET ZERO") !== -1)
		.reduce((res, value) => {
			if (res[value.region]) {
				res[value.region].nbCountries = (res[value.region]?.nbCountries ?? 0) + 1;
				res[value.region].score = (res[value.region]?.score ?? 0) + (value?.countryScore ?? 0);
			}
			return res;
		}, netZeroRegionDistribution);
	}

	if (!perAsset && activeRefines.region !== undefined) {
		netZeroCountryDistribution = netZeroGeoDistribution?.reduce((res, value) => groupBy(res, value, "country", {
			country: value.country, iso2Code: value.iso2Code, totalAssets: 0, value: 0, score: 0, sites: new Set()
		}), {});

		netZeroCountryDistribution = netZeroGeoScoresDistribution?.filter((country) => country.domain?.indexOf("NET ZERO") !== -1)
		.reduce((res, value) => {
			if (res[value.country]) {
				res[value.country].score = value?.countryScore ?? 0;
			}
			return res;
		}, netZeroCountryDistribution);
	}

	if (perAsset) {
		netZeroAssetDistribution = netZeroAssetsDistribution?.reduce((res, value) => groupBy(res, value, "marketSegment", {
			marketSegment: value.marketSegment, totalAssets: 0, value: 0, score: 0, sites: new Set()
		}), {});

		netZeroAssetDistribution = netZeroAssetsScoresDistribution?.reduce((res, value) => {
			if (res[value.marketSegment]) {
				res[value.marketSegment].score = value.assetScore;
			}
			return res;
		}, netZeroAssetDistribution);
	}

	if (perAsset && activeRefines.marketSegment !== undefined) {
		netZeroSiteDistribution = siteScores?.reduce((res, value) => groupBy(res, value, "siteId", {
			siteId: value.siteId, auditedCompany: value.auditedCompany, value: 0, siteScore: 0, sites: new Set()
		}, ["siteScore"]), {});

		netZeroSiteDistribution = netZeroDetailedKPIs?.reduce((res, value) => groupBy(res, value, "siteId", {
			siteId: value.siteId, auditedCompany: value.auditedCompany, value: 0, score: 0, sites: new Set()
		}, ["value"]), netZeroSiteDistribution);
	}

	return (
		<div className={`flex flex-col rounded-xl text-sm
          gap-y-8 pl-3 pt-4 border border-white z-20 text-black alphaBlur ${className} `}
		style={{ height }}
		>

			<IF condition={!perAsset}>
				<div className="flex justify-between items-center text-sm ">
					<div className="flex items-center gap-x-1 ">
						<PinDropIcon fontSize="inherit" />
						<p>ID Card per geography</p>
					</div>
				</div>
			</IF>

			<IF condition={perAsset}>
				<div className="flex justify-between items-center text-sm ">
					<div className="flex items-center gap-x-1 text-sm ">
						<WidgetsIcon fontSize="inherit" />
						<p>ID Card per asset</p>
					</div>
				</div>
			</IF>

			<IF condition={!perAsset}>

				<div className="flex flex-col gap-y-4 overflow-y-auto"
					style={{ height: height - 80 }}>
					{Object.keys(netZeroRegionDistribution).map((region, i, { length }) => <div key={`marketSegment ${ i}`}
						className="flex justify-center items-center gap-x-6 text-small 2xl:gap-x-10">
						<div className={"flex flex-col items-center gap-y-1 justify-end w-10 2xl:w-16 cursor-pointer"}
							onClick={() => region === activeRefines.region ?
								dispatch(appSlice.actions.refine([{ key: "region", value: undefined }]))
								: dispatch(appSlice.actions.refine([{ key: "region", value: region }]))
							}>
							<img src={`/images/GeoIcons/Region/${region}.svg`} alt={region} className="w-10 2xl:w-16" />
							<p className="text-center leading-3">{region}</p>
							{(activeRefines.region !== undefined)
                            && <DoubleArrowIcon fontSize="small" className="transform rotate-90" />}
						</div>
						<RenderNetZeroScore
							nbAssets={netZeroRegionDistribution[region]?.totalAssets}
							nbCO2={netZeroRegionDistribution[region]?.value?.toFixed(1)}
							// eslint-disable-next-line no-unsafe-optional-chaining
							reliabilityIndex={((netZeroRegionDistribution[region]?.score /
							// eslint-disable-next-line no-unsafe-optional-chaining
                            netZeroRegionDistribution[region]?.nbCountries) * 100)?.toFixed(0)} />
					</div>
					)}
					{Object.keys(netZeroCountryDistribution).map((country, i, { length }) => <div key={`marketSegment ${ i}`}
						className="flex justify-center items-center gap-x-6 text-small 2xl:gap-x-10 cursor-pointer"
						onClick={() => dispatch(appSlice.actions.refine([{ key: "region", value: undefined }]))}>
						<div className={"flex flex-col items-center gap-y-1 justify-end w-10 2xl:w-16"}>
							<img src={`/images/GeoIcons/Country/${netZeroCountryDistribution[country]?.iso2Code}.png`}
								alt={netZeroCountryDistribution[country]?.iso2Code} className="w-10 2xl:w-16" />
							<p className="text-center leading-3">{country}</p>
						</div>
						<RenderNetZeroScore
							nbAssets={netZeroCountryDistribution[country]?.totalAssets}
							nbCO2={netZeroCountryDistribution[country]?.value?.toFixed(1)}
							reliabilityIndex={((netZeroCountryDistribution[country]?.score ?? 0) * 100)?.toFixed(0)} />
					</div>
					)}
				</div>
			</IF>

			<IF condition={perAsset}>

				<div className="flex flex-col gap-y-4 overflow-y-auto"
					style={{ height: height - 80 }}>
					{Object.keys(netZeroAssetsOptions)
					?.filter((marketSegment) => activeRefines.marketSegment === undefined || activeRefines.marketSegment === marketSegment)
					?.map((marketSegment, i, { length }) => <div key={`marketSegment ${ i}`}
						className="flex justify-center items-center gap-x-6 text-small 2xl:gap-x-10">
						<div className={`flex flex-col items-center justify-end w-8 2xl:w-10 cursor-pointer 
                        ${ i !== (length - 1) && "-mb-6"}`}
						onClick={() => marketSegment === activeRefines.marketSegment ?
							dispatch(appSlice.actions.refine([{ key: "marketSegment", value: undefined }]))
							: dispatch(appSlice.actions.refine([{ key: "marketSegment", value: marketSegment }]))
						}>
							<img src={netZeroAssetsOptions[marketSegment]?.picto} className="w-8 2xl:w-10" />
							<p className="text-center leading-3">{marketSegment}</p>
							{(i !== (length - 1) || activeRefines.marketSegment !== undefined)
                            && <DoubleArrowIcon fontSize="small" className="transform rotate-90" />}
						</div>
						<RenderNetZeroScore
							nbAssets={netZeroAssetDistribution[marketSegment]?.totalAssets}
							nbCO2={netZeroAssetDistribution[marketSegment]?.value?.toFixed(1)}
							reliabilityIndex={((netZeroAssetDistribution[marketSegment]?.score ?? 0) * 100)?.toFixed(0)} />
					</div>
					)}

					<IF condition={activeRefines.marketSegment !== undefined && netZeroSiteDistribution}>
						{Object.keys(netZeroSiteDistribution ?? {})?.map((siteId, i, { length }) => <div key={`site ${ i}`}
							className="flex justify-center items-center gap-x-6 text-small 2xl:gap-x-10">
							<div className="flex flex-col items-center justify-end w-8 2xl:w-10 cursor-pointer"
								onClick={() => dispatch(appSlice.actions.refine([{ key: "siteId", value: Number.parseInt(siteId, 10) }]))
								}>
								<BusinessIcon fontSize="large" />
								<p className="text-center leading-3">{netZeroSiteDistribution?.[siteId]?.auditedCompany}</p>
							</div>
							<RenderNetZeroScore
								nbAssets={-1}
								nbCO2={netZeroSiteDistribution?.[siteId]?.value?.toFixed(1)}
								reliabilityIndex={((netZeroSiteDistribution?.[siteId]?.siteScore ?? 0) * 100)?.toFixed(0)} />
						</div>
						)}
					</IF>
				</div>
			</IF>

		</div>
	);
}

ClarityNetZeroIDCard.propTypes = {
	appSlice: PropTypes.object,
	perAsset: PropTypes.bool,
	height: PropTypes.number,
	className: PropTypes.string,
	locales: PropTypes.string
};

export default ClarityNetZeroIDCard;
