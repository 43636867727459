import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";

import BusinessIcon from "@mui/icons-material/Business";
import CompassSiteCard from "../cards/CompassSiteCard";

function CompassTooltip({ appSlice, marker, locales }) {
	// Load the store hook
	const dispatch = useDispatch();

	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<div className="flex flex-col gap-y-2 w-56">
			<CompassSiteCard appSlice={appSlice} siteData={marker} locales={locales} />
			<button className="flex items-center justify-center rounded-lg bg-compass_primary-menu text-white px-3 py-2 gap-x-1 cursor-pointer"
				onClick={() => dispatch(appSlice.actions.refine([{ key: "siteId", value: marker.siteId }]))}>
				<BusinessIcon fontSize="normal" />
				<p className="text-xs pl-1 cursor-pointer">{t("mapPage.tooltip.viewSite")}</p>
			</button>
		</div>
	);
}

CompassTooltip.propTypes = {
	appSlice: PropTypes.object,
	marker: PropTypes.object,
	locales: PropTypes.string
};

export default CompassTooltip;
