import PropTypes from "prop-types";
import React from "react";
import { useFieldArray, Controller } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";
import SelectFieldEditor from "./SelectFieldEditor";

const NestedArray = ({
	nestIndex, nestedField, control, register, activeDatasets, appSlice, watch
}) => {
	let projectOptions = [];

	const { fields, remove, append } = useFieldArray({
		control,
		name: `AppScope.${nestIndex}.nestedArray`
	});

	const app = watch(`AppScope.${nestIndex}.name`);

	// conditionally render option based on app value
	projectOptions = activeDatasets?.selectedAppProjectOptions?.data?.[0]?.res[app];

	return (
		<div className="relative flex flex-col font-medium gap-y-2.5 mt-4 capitalize text-admin_primary-default border px-2 py-3 rounded-lg">

			{fields.map((item, k) => (
				<Controller
					key={item.id}
					name={`AppScope.${nestIndex}.nestedArray.${k}.k`}
					control={control}
					render={({ field }) => (
						<label className="relative flex flex-col font-medium gap-y-1 capitalize border px-2 py-3 rounded-lg">
							<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
								{`Project ${k}`}
							</span>
							<div className="flex flex-row items-center mt-1">
								<SelectFieldEditor
									field={field.name}
									control={control}
									appSlice={appSlice}
									options={projectOptions}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem 0",
											borderRadius: "0.5rem",
											height: "min-content"
										}),
										menuList: (base) => ({
											...base,
											height: "200px" // your desired height
										})
									}}
								/>
								<button
									type="button"
									className="w-min h-min ml-2 py-0 border bg-admin_primary-default hover:bg-admin_primary-dark
									text-white font-bold px-2 rounded"
									onClick={() => remove(k)}
								>
									<BlockIcon className="text-base" />
								</button>
							</div>
						</label>
					)}
					rules={{ required: true }}
				/>
			)
			)}
			<div className="mt-1">
				<button
					type="button"
					className="border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold px-2 rounded"
					onClick={() => { append(""); }}
				>
					<AddIcon className="text-base" />
				</button>
			</div>

		</div>
	);
};

NestedArray.propTypes = {
	control: PropTypes.object,
	nestIndex: PropTypes.any,
	register: PropTypes.func,
	nestedField: PropTypes.string,
	activeDatasets: PropTypes.object,
	appSlice: PropTypes.object,
	watch: PropTypes.func

};

const AdminUserNestedArray = ({
	control,
	register,
	searchOptionBuilder,
	appSlice,
	activeRefines,
	activeDatasets,
	getValues,
	setValue,
	watch
}) => {
	const {
		fields, append, remove
	} = useFieldArray({
		control,
		name: "AppScope"
	});

	return (
		<>
			<ul className="space-y-10 ">
				{fields.map((item, index) => (
					<fieldset key={item.id} className="border p-3  rounded-lg" >
						<label className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							text-admin_primary-default border px-2 py-3 rounded-lg">
							<span className="w-min h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
								{"AppScope"}
							</span>
							<li>
								<SelectFieldEditor
									field={`AppScope.${index}.name`}
									control={control}
									appSlice={appSlice}
									options={activeDatasets.AppOptions.data}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem 0",
											borderRadius: "0.5rem"
										}),
										menuList: (base) => ({
											...base,
											height: "200px" // your desired height
										})
									}}
									placeholder={getValues(`AppScope.${index}.name`)}
									// isDispatch
								/>
								<button
									type="button"
									className="w-min border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold px-2 rounded"
									onClick={() => remove(index)}>
									<BlockIcon className="text-base" />
								</button>

								<NestedArray {...{
									nestIndex: index, nestedField: item, control, register, activeDatasets, appSlice, watch
								}} />
							</li>
						</label>
					</fieldset>
				))}
			</ul>
			<section>
				<button
					type="button"
					className="border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold px-2 rounded"
					onClick={() => {
						append({ name: "" });
					}}
				>
					<AddIcon className="text-base" />
				</button>

			</section>

		</>
	);
};

AdminUserNestedArray.propTypes = {
	activeDatasets: PropTypes.object,
	activeRefines: PropTypes.object,
	appSlice: PropTypes.object,
	control: PropTypes.object,
	getValues: PropTypes.func,
	register: PropTypes.func,
	searchOptionBuilder: PropTypes.func,
	setValue: PropTypes.func,
	watch: PropTypes.func
};

export default AdminUserNestedArray;
