import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Tooltip } from "@mui/material";
import { active } from "d3";

// color names for tailwind = bg-[deepskyblue] bg-[palevioletred] bg-[turquoise] bg-[lightsalmon] bg-[indianred]
// bg-clarity_primary-red bg-clarity_primary-orange bg-clarity_primary-yellow bg-clarity_primary-green
const MaturityBars = ({
	dataset = undefined, appSlice = null, noTitle = false, thickness = undefined,
	additionalRefineKey = undefined,
	additionalRefineValue = undefined, locales
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const dispatch = useDispatch();
	// data
	const maturityData = useSelector(appSlice.selectDatasets)[dataset]?.data;
	// client parameters
	const clientParameters = useSelector(appSlice.selectClientParameters);
	// active refines
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	// color accessor
	const colorAcc = (d) => d?.color;

	return <React.Fragment>
		{!noTitle &&
		<div className="flex justify-between items-center">
			<p className="flex items-center">
				<span className="mr-2">{t("NCsComponent.title")}</span>
				<ReportProblemOutlinedIcon fontSize="inherit" className="text-red-500"/>
			</p>
			<p className="font-bold text-red-500 text-3xl">{maturityData
			.reduce((prev, cur) => prev + cur.count, 0)}</p>
		</div>
		}
		<div className="relative flex flex-row h-5 mt-10 mb-5">
			{
				// add rank from client parameters to maturityData array
				maturityData
				.filter((node) => node.domain === additionalRefineValue)
				.map((node) => {
					const filteredParam = clientParameters?.maturityColors.filter((p) => p.label === node.maturity);
					const rank = filteredParam[0]?.rank;
					return { ...node, rank };
				})
				// sort by rank
				.sort((a, b) => a.rank - b.rank)
				// map bars
				.map((e, i) => <Tooltip key={`tooltip-${e.maturity}`} title={e.maturity} followCursor>
					<div
						key={`mbar-${i}`}
						onClick={(additionalRefineKey
							? () => ((activeRefines?.maturity && activeRefines?.[additionalRefineKey])
								? dispatch(appSlice.actions.clear([
									{ refine: "maturity" },
									{ refine: additionalRefineKey }
								]))
								: (!activeRefines?.maturity && activeRefines?.[additionalRefineKey])
									? dispatch(appSlice.actions.refine([
										{ key: "maturity", value: e.maturity }
									]))
									: (activeRefines?.maturity && !activeRefines?.[additionalRefineKey])
										? dispatch(appSlice.actions.clear([
											{ refine: "maturity" }
										]))
										: dispatch(appSlice.actions.refine([
											{ key: "maturity", value: e.maturity },
											{ key: additionalRefineKey, value: additionalRefineValue }
										])))
							: (additionalRefineKey === undefined)
								? () => activeRefines?.maturity
									? dispatch(appSlice.actions.clear([{ refine: "maturity" }]))
									: dispatch(appSlice.actions.refine([{ key: "maturity", value: e.maturity }]))
								: "")}
						className={`bg-clarity_primary-${colorAcc(clientParameters?.maturityColors
						?.filter((color) => e.maturity === color.label)[0])} cursor-pointer rounded-3xl h-3 mx-0.5 flex 
				justify-center ${thickness === "thin" ? "h-2" : (thickness === "large" ? "h-4" : (thickness === "thin" ? "h-2" : "h-3"))}`}
						style={{
							width: `${e.rate}%`
						}}
					>
						<span className="absolute -top-6 text-medium font-bold">
							{e.count}
						</span>
					</div>
				</Tooltip>)}
		</div>
	</React.Fragment>;
};

MaturityBars.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	noTitle: PropTypes.bool,
	thickness: PropTypes.string,
	additionalRefineKey: PropTypes.string,
	additionalRefineValue: PropTypes.string,
	locales: PropTypes.string
};

export default MaturityBars;
