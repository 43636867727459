import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	ThemeProvider, StyledEngineProvider, createTheme
} from "@mui/material/styles";

import { nanoid } from "nanoid";
import Moment from "react-moment";
import PropTypes from "prop-types";

import Pagination from "@mui/material/Pagination";

import ClearIcon from "@mui/icons-material/Clear";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import DownloadIcon from "@mui/icons-material/Download";
import ChatIcon from "@mui/icons-material/Chat";

import TitleCard from "./TitleCard";
import { queryDataset } from "../../api/client";
import exportToExcel from "../../utils/ExportToExcel";
import FilterMenu from "../filters/FilterMenu";
import SearchBar from "../search/SearchBar";

const theme = createTheme({
	palette: {
		default: {
			main: "lightblue"
		},
		compass: {
			main: "#F9CA48"
		},
		clarity: {
			main: "#CCDB73"
		},
		supplyr: {
			main: "#E3E5F5"
		}
	}
});

const filterContent = (arr, searchKey) => arr?.filter((obj) => Object.keys(obj).some((key) => {
	if ((typeof obj[key]) === "string") {
		return obj[key].toLowerCase().includes(searchKey.toLowerCase());
	}
	return false;
}
));

function ListOfNonConformities({
	appSlice, appNCConfig, dataset, totalNonConformities, contentRef, locales, NCsPage = "NCsPage",
	demoReportLink = undefined, noTitle = false, forceObservation = false,
	accessorCriticality = (d) => d?.criticality,
	accessorColor = (d) => d?.color,
	themePalette = "default", showSearchBar = false, displayActionPlan = false,
	observations = [{
		locale: "observation",
		accessorResult: (d) => d.answer,
		accessorObs: (d) => d.observation,
		picto: ChatIcon
	}],
	activeDataSets = {}
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Set up state for pagination
	const [page, setPage] = useState(1);

	// searchbar
	const [searchContent, setSearchContent] = useState("");

	const {
		nonConformities, listNonConformityQuery, nonConformityDataset,
		updateListNonConformities, customStylesSubFilter = undefined, filterOptions, listTagsNonConformities
	} = appNCConfig;

	const appName = useSelector(appSlice.selectAppName);
	const clientParameters = useSelector(appSlice.selectClientParameters);

	// Generate full list of NC if export requested
	async function generateTableToExport(activeRefines) {
		try {
			// in case error when switch between home and supplier page and use clear filter button
			const res = { ...activeRefines };
			if (res.isCleared) {
				delete res.isCleared;
			}
			if (res.supplyAudits) {
				delete res.supplyAudits;
			}
			const datasetResponse = await queryDataset(
				appName,
				{
					collection: nonConformityDataset,
					fetch: "aggregation",
					query: listNonConformityQuery,
					facetsToRefine: Object.keys(res),
					limit: 5000,
					skip: 0
				},
				res);
			return datasetResponse;
		} catch (error) {
			console.log(`Query Dataset:${error}`);
			return Promise.reject();
		}
	}

	// Load data from the store
	const nonConformitiesList = useSelector(appSlice.selectDatasets)[dataset];
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	// hide listNonConformities when totalNbNc = 0
	const nonConformitiesData = useSelector(appSlice.selectDatasets).nonConformities;

	const totalNbNC = nonConformitiesData && nonConformitiesData.data.reduce((acc, cur) => acc + cur.nbNC, 0);

	// filter nonConformitiesList content if searchbar applied
	const filteredNonConformitiesListContent = filterContent(nonConformitiesList.data, searchContent);

	const nbNCsPerPage = nonConformitiesList.limit;

	// Update list when a new page is requested
	const handleChange = async (event, value) => {
		setPage(value);
		dispatch(appSlice.fetchDataset(updateListNonConformities("listNonConformities", nbNCsPerPage, value)));
		contentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	return (

		<div className="flex flex-col w-full space-y-10" >
			<div className={`flex flex-wrap justify-between items-center  ${noTitle && "justify-start"}`}>
				{!noTitle &&
					<TitleCard titleLocal={`${NCsPage}.listNCs.header.criticalConcern`} subTitleLocal="" theme={themePalette}
						small infoLocal={`${NCsPage}.listNCs.header.info`} locales={locales} />
				}

				{showSearchBar && <SearchBar setSearchContent={setSearchContent} />}

				<div className={`flex flex-wrap items-center space-x-2 justify-between ${noTitle && "w-full"}`}>
					<div className="flex flex-wrap items-center space-x-2 ">
						{filterOptions.map(((filterOption, k) => <FilterMenu
							key={`filterMenu ${nanoid()}`}
							appSlice={appSlice}
							options={filterOption}
							customStyle={customStylesSubFilter}
							clearOnChange={filterOption.clear}
							cleanStyle
							locales={locales}
						/>
						))}
					</div>

					<button data-test="file_download" className={`text-${themePalette}_primary-accent rounded-xl border border-transparent 
                    shadow-sm p-1 bg-white hover:border-gray-200 cursor-pointer`}
					title="Download data in Excel format"
					onClick={(e) => generateTableToExport(activeRefines).then((datasetResponse) => exportToExcel(datasetResponse.data.map((d) => {
						// prevent the export of _id in excel table
						const obj = { ...d };
						delete obj._id;
						if (clientParameters?.hideActionPlan) {
							delete obj.actionPlanItem;
							delete obj.actionPlanNcStatus;
							delete obj.actionPlanTargetDate;
							delete obj.actionPlanItemTargetDate;
						}
						return obj;
					}),
                    `${t("NCsComponent.list.export")} ${new Date().toISOString().split("T")[0]}`))}>
						<DownloadIcon />
					</button>
				</div>

			</div>

			{(!totalNbNC || totalNonConformities === 0) && <p className="text-sm font-light">
				{t("NCsComponent.list.noNC")}
			</p>}

			{
				filteredNonConformitiesListContent
				.map((nonConformity, i) => <div key={`nc ${nonConformity}-${nanoid()}`} className="w-full flex flex-col space-y-2 text-medium">
					<p className={`text-${themePalette}_primary-accent font-semibold`}>
						<Moment format="YYYY/MM/DD">{nonConformity.publicationDate}</Moment>
					</p>
					<div className="flex flex-col xl:flex-row justify-between items-start w-full max-w-full">
						<div className="flex flex-col space-y-2 order-last xl:order-first ">
							<p className="flex-grow text-sm font-semibold ">{nonConformity.question}</p>
						</div>
						<div className="flex flex-shrink-0 space-x-2 mb-2 xl:mb-0 xl:ml-2">
							{clientParameters?.allowReportLink !== false && <a target="_blank" rel="noopener noreferrer"
								href={`https://odsdatahub.bureauveritas.com/reports/${demoReportLink ?? nonConformity.assignmentTechnicalid}`}
								className="flex justify-center items-center space-x-1
                                    border border-gray-200 rounded-lg px-3 py-2 text-medium font-light w-28
                                    hover:border-gray-400" >
								<InsertDriveFileIcon style={{ fontSize: "1rem" }} />
								<p className="flex flex-shrink-0">{t(`${NCsPage}.listNCs.nonConformity.viewReports`)}</p>
							</a>}
							<div className={`flex items-center justify-center rounded-lg px-3 py-2
                                font-light text-white border-0 border-transparent ${
									clientParameters?.maturityColors
										&& `bg-clarity_primary-${accessorColor(clientParameters?.maturityColors
										.filter((el) => el.label === nonConformity?.maturity)[0])}
										`
								}
								${
									clientParameters?.threeLevelNC &&
										(nonConformity.criticalityLevel === "High"
											? "bg-risk-high"
											: nonConformity.criticalityLevel === "Medium"
												? "bg-risk-low"
												: nonConformity.criticalityLevel === "Low"
													? "bg-risk-medium"
													: "")
								}`}
							style={(!clientParameters?.maturityColors && !clientParameters?.threeLevelNC)
								? {
									backgroundColor: nonConformities[accessorCriticality(nonConformity)]
								}
								: {}}
							>
								{clientParameters?.maturityColors
									? nonConformity.maturity
									: clientParameters?.threeLevelNC
										? nonConformity.criticalityLevel
										: t(`NCsComponent.legend.${accessorCriticality(nonConformity)}`)}
							</div>
						</div>
					</div>

					{observations.map((element, j) => {
						const answer = element.accessorResult && element.accessorResult(nonConformity);
						const observation = element.accessorObs && element.accessorObs(nonConformity);
						const actionPlanned = element.accessorActionPlanned && element.accessorActionPlanned(nonConformity);
						if (!forceObservation && !element.accessorObs(nonConformity)) {
							return 	<div key={`Obs ${nanoid()}`} className="flex items-center space-x-2 text-red-500">
								<ClearIcon />
								<p className="text-sm">{answer}</p>
							</div>;
						}

						const Picto = element.picto;
						return (<>
							<div key={`observations ${nanoid()}`}
								className={`flex gap-x-2 
								${
									(element.locale === "actionPlanned" && !nonConformity.actionPlanNcStatus) ||
									(element.locale === "proposedActions" && !actionPlanned)
										? "hidden"
										: ""
								}`}>
								<Picto fontSize="small" className="text-gray-800" />
								<p className="text-sm">
									<span className="uppercase">
										{t(`${NCsPage}.listNCs.nonConformity.${element.locale}`)}:
									</span>
									<span className={answer === "Yes"
										? "text-green-500"
										: ["A", "B"].includes(answer)
											? "text-risk-medium font-semibold text-base"
											: answer === "C"
												? "text-risk-low font-semibold text-base"
												: answer === "D"
													? "text-risk-high font-semibold text-base"
													: (element.locale === "actionPlanned" || element.locale === "proposedActions")
														? "font-light "
														: "text-red-500"}>
                                    &nbsp;{
											(element.locale === "actionPlanned" || element.locale === "proposedActions")
												? (actionPlanned || t(`${NCsPage}.listNCs.nonConformity.toBeSubmitted`))
												: answer || "No"}.
									</span>
									<span className="font-light">
                                    &nbsp;{observation?.replace(/[\r\n]+/gm, "")}
									</span>
								</p>
							</div>
						</>);
					}
					)}

					<div className="flex flex-wrap items-center gap-2">

						{Object.keys(listTagsNonConformities)?.map((tag, k) => {
							if (listTagsNonConformities[tag]?.display !== undefined && !listTagsNonConformities[tag]?.display(clientParameters)) {
								return null;
							}

							const tagLabel = listTagsNonConformities[tag]?.useDBData === true ?
								listTagsNonConformities[tag]?.accessor(nonConformity, t, activeDataSets)
								: listTagsNonConformities[tag]?.accessor(nonConformity, t);

							const tagColor = listTagsNonConformities[tag].color ? listTagsNonConformities[tag]?.color(nonConformity) : null;

							const TagPicto = listTagsNonConformities[tag]?.useDBData === true ?
								listTagsNonConformities[tag]?.picto(nonConformity, activeDataSets)
								: listTagsNonConformities[tag]?.picto(nonConformity);

							let tagModule;

							if (tag === "module") {
								tagModule = listTagsNonConformities[tag]?.useDBData === true ?
									listTagsNonConformities[tag]?.accessor(nonConformity, t, activeDataSets)
									: listTagsNonConformities[tag]?.accessor(nonConformity, t);
							}

							const refinesTag = listTagsNonConformities[tag]?.refine?.map(
								(element) => ({ key: element?.key, value: (element?.valueKey(nonConformity)) })
							);

							if (!tagLabel || tagLabel === "nc") {
								return null;
							}

							if (listTagsNonConformities[tag]?.cond && listTagsNonConformities[tag]?.cond(nonConformity) === false) {
								return null;
							}

							return (
								<div key={`tag ${nanoid()}`} className={`flex justify-center items-center rounded-lg 
										border px-4 py-2 space-x-1 ${ refinesTag && "cursor-pointer"}`}
								style={{ borderColor: `${tagColor || "#E5E7EB"}` }}
								onClick={() => refinesTag && dispatch(appSlice.actions.refine(refinesTag))}>
									{typeof TagPicto === "object" ?
										<TagPicto style={{ fontSize: "1rem", color: `${tagColor}` }} />
										:
										<img src={TagPicto} alt={TagPicto}
											className="w-4 h-4 opacity-100 filter brightness-0" />
									}
									{/* Translate domain/module tags for Clarity only */}
									{tagModule && appSlice.selectAppName() === "clarity" ?
										<p style={{ color: `${tagColor}` }}>{t(`domains.${tagModule}.name`)}</p>
										: <p style={{ color: `${tagColor}` }}>{tagLabel}</p> }
								</div>
							);
						})}

					</div>

				</div>)
			}

			{totalNonConformities > nbNCsPerPage &&
			<div className="self-center px-0 -mx-2 py-3  border-0">
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<Pagination page={page} count={Math.ceil((totalNonConformities / nbNCsPerPage))}
							color={themePalette} onChange={handleChange} />
					</ThemeProvider>
				</StyledEngineProvider>
			</div>
			}

		</div>
	);
}

ListOfNonConformities.propTypes = {
	appSlice: PropTypes.object,
	appNCConfig: PropTypes.object,
	dataset: PropTypes.string,
	noTitle: PropTypes.bool,
	forceObservation: PropTypes.bool,
	observations: PropTypes.array,
	accessorCriticality: PropTypes.func,
	accessorColor: PropTypes.func,
	totalNonConformities: PropTypes.number,
	contentRef: PropTypes.object,
	locales: PropTypes.string,
	NCsPage: PropTypes.string,
	themePalette: PropTypes.string,
	demoReportLink: PropTypes.string,
	showSearchBar: PropTypes.bool,
	displayActionPlan: PropTypes.bool,
	activeDataSets: PropTypes.object
};

export default ListOfNonConformities;
