import React from "react";
import PropTypes from "prop-types";
import Loading from "../../utils/Loading";

function ProgressBarFlat({
	label,
	showPercentage = false,
	endPoint,
	strokeColor,
	strokeColorEnd = strokeColor,
	fillColor = "#EEEEEE",
	fontSize = 14,
	fontWeight = 400,
	width = 115,
	height = 17,
	radius = 9,
	duration = "1.5s",
	loadStatus = "idle"
}) {
	const labelSize = fontSize * 3;
	const xMax = width - (showPercentage ? labelSize : 0);

	if (loadStatus !== "idle") {
		return 	<div style={{ height }}>
			<Loading isComponent />
		</div>;
	}

	return (
		<svg width={width} height={height} className="rounded-xl" xmlns="http://www.w3.org/2000/svg">

			<defs>
				<linearGradient id={`lineprogressbarflat-${ strokeColor}`}
					x1="0" y1={height / 2} x2={`${(endPoint / 100) * xMax}`} y2={height / 2}
					gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor={strokeColor} />
					<stop offset="1" stopColor={strokeColorEnd} />
				</linearGradient>
			</defs>

			<rect
				x={0}
				y={0}
				width={xMax}
				height={height}
				rx={radius}
				ry={radius}
				fill={fillColor}
			/>

			<line x1="0" y1={height / 2} x2={`${(endPoint / 100) * xMax}`} y2={height / 2}
				strokeWidth={height} stroke={`url(#lineprogressbarflat-${ strokeColor })`} >
				<animate attributeName="x2" from="0" calcMode="spline"
					to={`${(endPoint / 100) * xMax}`} dur={duration} keySplines="0.65 0.05 0.32 1" keyTimes="0; 1" />
			</line>

			{label && <text x="5" y={height / 2 + fontSize / 2 - 2}
				className="fill-current text-white font-semibold"
				style={{ fontSize }}>
            	{label}
			</text>
			}

			{showPercentage && <text x={xMax + 10} y={height / 2 + fontSize / 2 - 2}
				className="fill-current text-black"
				style={{ fontSize, fontWeight }}>
            	{endPoint}%
			</text>
			}

		</svg>
	);
}

ProgressBarFlat.propTypes = {
	label: PropTypes.string,
	showPercentage: PropTypes.bool,
	endPoint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	strokeColor: PropTypes.string,
	strokeColorEnd: PropTypes.string,
	fillColor: PropTypes.string,
	fontSize: PropTypes.number,
	fontWeight: PropTypes.number,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	radius: PropTypes.number,
	duration: PropTypes.string,
	loadStatus: PropTypes.string
};

export default ProgressBarFlat;
