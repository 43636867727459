import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
	useTable, useGlobalFilter, useSortBy, usePagination, useAsyncDebounce
} from "react-table";

import { Pagination, Tooltip } from "@mui/material";

import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownUp from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import exportToExcel from "../../utils/ExportToExcel";
import FilterMenu from "../filters/FilterMenu";

const theme = createTheme({
	palette: {
		default: {
			main: "lightblue"
		},
		compass: {
			main: "#F9CA48"
		},
		clarity: {
			main: "#CCDB73"
		},
		supplyr: {
			main: "#E3E5F5"
		},
		vendorDD: {
			main: "#E3E5F5"
		}
	}
});

// Define a default UI for filtering
function GlobalFilter({
	placeHolder,
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter
}) {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="flex justify-between items-center bg-gray-100 py-4 px-2 md:px-6 space-x-4 rounded-xl ">
			<input
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={placeHolder}
				className="bg-transparent outline-none "
			/>
			<SearchIcon />
		</div>
	);
}

GlobalFilter.propTypes = {
	placeHolder: PropTypes.string,
	preGlobalFilteredRows: PropTypes.array,
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func
};

function Table({
	pageTable = "SitesPage",
	appSlice,
	dataset = undefined,
	structureTable = undefined,
	filterData = (d) => d,
	refineKeys = (row) => [{ key: "siteId", value: row.original.siteId }],
	sortByRefineFields = [{ id: "auditedCompany", desc: false }],
	themePalette = "default",
	excelLocales = undefined,
	locales,
	enableDispatch = (row) => true,
	renderTableFilter = false,
	filterOptions = [],
	customStylesSubFilter = undefined,
	exportFileName = undefined,
	headerStyle = undefined,
	translateHeaders = true
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Get activeRefines
	const appName = useSelector(appSlice.selectAppName);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);

	// Set up state for opened form
	const [tableData, setTableData] = useState([]);

	// Load data from the store
	const tableRawData = useSelector(appSlice.selectDatasets)[dataset]?.data;

	const tableColumns = useMemo(
		() => structureTable({ activeRefines, clientParameters, t }),
		// eslint-disable-next-line
		[activeRefines]
	);

	useEffect(() => {
		setTableData([...tableRawData].filter(filterData));
		// eslint-disable-next-line
	}, [tableRawData]); 

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		rows,
		gotoPage,
		preGlobalFilteredRows,
		setGlobalFilter,
		state: { pageIndex, pageSize }
	} = useTable({
		columns: tableColumns,
		data: tableData,
		initialState: {
			pageIndex: 0,
			pageSize: 10,
			sortBy: sortByRefineFields
		}
	},
	useGlobalFilter,
	useSortBy,
	usePagination);

	if (dataset === undefined || structureTable === undefined) {
		console.log("You need to define your dataset and table structure to display this component");
		return null;
	}

	function generateFileName() {
		return `${activeRefines?.projectName
			? (Array.isArray(activeRefines?.projectName)
				? activeRefines?.projectName[0] : activeRefines?.projectName)
			: appName} Sites Export ${new Date().toISOString().split("T")[0]}`;
	}

	function generateTableToExport() {
		const sortedTable = new Array(rows.length);
		rows.forEach((row, i) => {
			const formattedRow = {};
			prepareRow(row);
			row.cells.forEach((cell, j) => excelLocales
				? formattedRow[t(`${excelLocales}.${cell.column.Header}`)] = cell.column?.processedValue
					? cell.column.processedValue(cell.value) : cell?.value?.toString()
				: formattedRow[cell.column.Header] = cell.column?.processedValue
					? cell.column.processedValue(cell.value) : cell?.value?.toString());
			sortedTable[i] = formattedRow;
		});
		rows.forEach((row, i) => {
			const formattedRow = {};
			row.cells.forEach((cell, j) => {
				if (cell.column.Header === "Active") {
					if (cell.value === true) {
						cell.value = "No";
					}
					if (cell.value === false) {
						cell.value = "Yes";
					}
				}
				formattedRow[cell.column.Header] = cell.value;
			});
			sortedTable[i] = formattedRow;
		});

		return sortedTable;
	}

	return (<div data-test="Table" className="flex flex-col text-sm space-y-4 max-x-full  w-full overflow-x-auto">
		<div className="self-center flex justify-between items-center w-full px-4 md:px-6">
			<GlobalFilter
				placeHolder={t(`${pageTable}.table.searchFor`)}
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
			/>

			<div className={`${renderTableFilter ? "flex justify-between items-center w-fit h-full space-x-4" : ""}`}>
				{renderTableFilter && filterOptions.map(((filterOption, k) => (
					<FilterMenu
						key={`filterMenu ${ k}`}
						appSlice={appSlice}
						options={filterOption}
						customStyle={customStylesSubFilter}
						clearOnChange={filterOption.clear}
						cleanStyle
						locales={locales}
					/>
				)))}
				<div className="flex space-x-2 items-center self-end h-full">
					<p className="self-end hidden md:block my-auto">
						{rows.length === 1 ? `1 ${ t(`${pageTable}.table.result`)}` : `${rows.length} ${ t(`${pageTable}.table.results`)}`}
					</p>
					<button data-test="file_download" className={`text-${themePalette}_primary-accent rounded-xl border border-gray-100 
                	shadow-sm p-1 bg-white hover:border-gray-200 cursor-pointer`}
					title="Download data in Excel format"
					onClick={(e) => exportToExcel(generateTableToExport(), `${!exportFileName ? generateFileName() : exportFileName}`)}>
						<DownloadIcon />
					</button>
				</div>
			</div>
		</div>
		<table {...getTableProps()} className="w-full">
			<thead>
				{headerGroups.map((headerGroup, i) => (
					<tr key={`headerGroup ${i}`} {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column, j) => (
							<th key={`header ${j}`}
								{...column.getHeaderProps(column.getSortByToggleProps())}
								className="first:pl-6 last:pr-6 text-left font-medium p-2 max-w-xs"
							>
								<div className={`${!headerStyle ? "flex space-x-2" : headerStyle}`}>
									{translateHeaders ? t(`${pageTable}.table.${column.render("Header")}`) : column.render("Header") }
									<span>
										{column.isSorted
											? column.isSortedDesc
												? <KeyboardArrowDownUp fontSize="inherit" />
												: <KeyboardArrowDownIcon fontSize="inherit" />
											: ""}
									</span>
								</div>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{page.map((row, i) => {
					prepareRow(row);
					return (
						<Tooltip title={enableDispatch(row) ? "" : "Scores not yet available"} followCursor key={`tooltip ${i}`}>
							<tr key={`row ${i}`} {...row.getRowProps()}
								onClick={() => {
									const isEnableDispatch = enableDispatch(row);
									if (isEnableDispatch) dispatch(appSlice.actions.refine(refineKeys(row)));
								}}
								onHover={() => {}}
								className={`odd:bg-gray-100 hover:bg-${themePalette}_primary-default hover:bg-opacity-20 cursor-pointer`}>
								{row.cells.map((cell, j) => (
									<td key={`cell ${j}`}
										{...cell.getCellProps()}
										className="first:pl-6 last:pr-6 p-2 max-w-xs"
									>
										{cell.render("Cell")}
									</td>
								))}
							</tr>
						</Tooltip>

					);
				})}
			</tbody>
		</table>
		{rows.length > pageSize &&
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Pagination page={pageIndex + 1} count={Math.floor((rows.length / pageSize) + 1)}
						className="md:self-center px-4 md:px-6" color={themePalette} onChange={async (event, value) => {
							const page = value ? Number(value) - 1 : 0;
							gotoPage(page);
						}} />
				</ThemeProvider>
			</StyledEngineProvider>
		}
	</div>
	);
}

Table.propTypes = {
	pageTable: PropTypes.string,
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	filterData: PropTypes.func,
	structureTable: PropTypes.func,
	refineKeys: PropTypes.func,
	sortByRefineFields: PropTypes.array,
	themePalette: PropTypes.string,
	locales: PropTypes.string,
	excelLocales: PropTypes.string,
	enableDispatch: PropTypes.func,
	renderTableFilter: PropTypes.bool,
	filterOptions: PropTypes.array,
	customStylesSubFilter: PropTypes.func,
	exportFileName: PropTypes.string,
	headerStyle: PropTypes.string,
	translateHeaders: PropTypes.bool
};

export default Table;
