import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import ClarityIndicator from "../graphs/ClarityIndicator";
import { getScoreColor } from "../../apps/configs/AtAGlanceConfig";

function AtAGlanceGlobalIndexCard({
	appSlice, moduleKey, module, isPushed = false,
	moduleScore = {
		score: 0, nbSites: 0, nbNC: 0, trend: ""
	}, reportsLink = false, externalLink = false, displayNbSites = true, isPercent = false,
	description = false, title = "Global Index", locales, program, site = undefined
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	const navigate = useNavigate();

	// Load data from the store
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const clarityClientParameters = activeDatasets?.clarityClientParameters?.data[0];

	const siteData = activeDatasets?.sites;
	if (!moduleScore) {
		return null;
	}
	const Logo = module?.logo ?? undefined;

	const scoreColor = getScoreColor(moduleScore.score, program);

	const handleRedirect = () => {
		const projectName = clientParameters.client;
		if (program === "supplyr") {
			const url = "/supplyr";
			// put the params in the localstorage so we could get them in Supply-R app for refining.
			window.localStorage.setItem("ataglanceURLparams", JSON.stringify(
				{
					projectName,
					supplier: site?.supplier,
					product: activeRefines?.product,
					type: activeRefines?.type,
					tierLevel: activeRefines?.tierLevel,
					country: activeRefines?.country
				}));
			navigate(url);
		}
		if (program === "clarity") {
			const url = "/clarity";
			// put the params in the localstorage so we could get them in Clarity app for refining.
			const { spaceLabel } = clarityClientParameters;
			window.localStorage.setItem("ataglanceURLparams", JSON.stringify({ spaceLabel, siteId: site?.siteId, country: activeRefines?.country }));
			navigate(url);
		}
	};
	return (
		<div data-test={`ataglance-global-index-card-${program?.replaceAll(" ", "-").toLowerCase()}`}
			onClick={handleRedirect}
			className={`flex p-4 bg-white rounded-xl py-4 w-full border border-transparent 
            bg-opacity-90 shadow-xs cursor-pointer hover:border-gray-300`}>
			<div
				className="absolute font-semibold top-8 left-8">
				{title}
			</div>
			<ClarityIndicator label={t(`domains.${module?.locale}.name`)} moduleScore={moduleScore} color = {scoreColor} description
				isPercent={isPercent} width={180} height={180} displayNbSites={displayNbSites} halo/>

		</div>
	);
}

AtAGlanceGlobalIndexCard.propTypes = {
	appSlice: PropTypes.object,
	moduleKey: PropTypes.string,
	module: PropTypes.object,
	isPushed: PropTypes.bool,
	moduleScore: PropTypes.object,
	reportsLink: PropTypes.bool,
	externalLink: PropTypes.bool,
	displayNbSites: PropTypes.bool,
	isPercent: PropTypes.bool,
	description: PropTypes.bool,
	locales: PropTypes.string,
	title: PropTypes.string,
	program: PropTypes.string,
	site: PropTypes.object
};

export default AtAGlanceGlobalIndexCard;
