import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ParentSize } from "@visx/responsive";

import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

function SimpleIndicatorCard({
	appSlice, large = false, locales, label = undefined, subLabel = undefined, description, value
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store
	/* const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines); */

	return (
		<>
			<div
				className="flex flex-col alphaBlur rounded-xl px-2 py-3 w-full h-full gap-y-2"
			><div className="flex justify-between items-center w-full">
					<div className="flex flex-col w-full">
						<div className="flex gap-x-1">
							<VerifiedUserIcon className={`text-compass_primary-accent 
							${large ? "text-3xl" : "text-lg md:text-xl 2xl:text-3xl"}`} />
							<div className="flex flex-col w-full">
								<p className={`font-semibold line-clamp-1 text-compass_primary-accent
							${large ? "text-2xl" : "text-medium md:text-base 2xl:text-lg"}`}>
									{label}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="flex h-full justify-center items-center w-full">
					<div className="flex flex-col gap-y-1 h-full justify-center items-center">
						{description &&
                        <div className="text-small md:text-medium 2xl:text-base line-clamp-1 leading-3 w-full flex text-center">
                        	{description}
                        </div>}
						<div className="flex flex-col justify-center items-center h-40 mb-6 text-5xl ">
							{value}
							{subLabel &&
								<span className="text-base">{subLabel}</span>}
							{/* <ParentSize debounceTime={10}>

                            {(parent) => (
                                <div className="flex justify-center">
                                    <SimpleDonutGraph
                                        value={donutData}
                                        donutThickness={10}
                                        // donutThickness={globalStats ? 25 : 10}
                                        // donutInnerThickness={globalStats ? 30 : 18}
                                        donutInnerThickness={18}
                                        // width={300}
                                        // height={globalStats ? 330 : 150}
                                        height={150}
                                        values={true}
                                        donutColor={ratingsConfig.map((rating) => rating.color)}
                                        // fontSizeTextCenter={globalStats ? 80 : 60}
                                        fontSizeTextCenter={47}
                                        // fontSizeTextCenter={60}
                                        subTextCenter="%"
                                        subTextCenterSize={18}
                                        // subTextCenterSize={globalStats ? 40 : 18}
                                        margin={{
                                            top: 10,
                                            right: 0,
                                            bottom: -10,
                                            left: 0
                                        }}
                                        textInnerColor={"black"}
                                        innerValueDigits={2}
                                        calOverAllScore={calOverAllScore} />
                                </div>
                            )}
                        </ParentSize> */}
						</div>

					</div>
				</div>
			</div></>

	);
}

SimpleIndicatorCard.propTypes = {
	appSlice: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.string,
	subLabel: PropTypes.string,
	description: PropTypes.string,
	large: PropTypes.bool,
	locales: PropTypes.string,
	indicators: PropTypes.array
};

export default SimpleIndicatorCard;
