// Create the App Slice
import React, { useEffect, createRef } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useTranslation } from "react-i18next";

import { ParentSize } from "@visx/responsive";
import { use100vh } from "react-div-100vh";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ChatIcon from "@mui/icons-material/Chat";
import ForwardIcon from "@mui/icons-material/Forward";

import createAppSlice from "../data/slices/createAppSlice";
import { requestSignIn, userSelector } from "../data/slices/UserSlice";

import SideBar from "../components/menu/SideBar";
import ClientHeader from "../components/header/ClientHeader";
import HorizontalFilters from "../components/filters/HorizontalFilters";
import { customStylesCompass, customStylesSearchCompass } from "../components/filters/CompassFilterStyle";
import TitleCard from "../components/cards/TitleCard";
import MapLeaflet from "../components/map/MapLeaflet";
import NonConformitiesCard from "../components/cards/NonConformitiesCard";
import ListOfNonConformities from "../components/cards/ListOfNonConformities";
import CompassScopeCard from "../components/cards/CompassScopeCard";
import PageHeader from "../components/header/PageHeader";
import Table from "../components/table/Table";
import PageMenuHeader from "../components/header/PageMenuHeader";
import CompassIndicatorCard from "../components/cards/CompassIndicatorCard";

import Page401 from "../pages/Page401";
import IF from "../utils/IF";
import Loading from "../utils/Loading";

import {
	appDefinition, menuOptions, compassInitDatasets, compassInitRefines, filterOptions, clientInitParameters,
	compassMapTooltip, nonConformities, listNonConformityQuery, CompassCheckpoints, updateListNonConformities,
	listTagsNonConformities, getMarkerColor, ratingsConfig, tableStructure, tableSectionsStructure, tableStructureScoreBasedProjects, getScoreColor
} from "./configs/CompassConfig";
import CompassSiteCard from "../components/cards/CompassSiteCard";
import CompassTooltip from "../components/tooltips/CompassTooltip";
import { openSaml } from "../auth/Authenticator";

const appSlice = createAppSlice(appDefinition.slice,
	menuOptions.filter((option) => option.initial)[0].menu,
	compassInitRefines,
	compassInitDatasets);

const mdScreens = 768;

function Compass() {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	// Create and load the app slice in the store
	const store = useStore();

	// Load the store hook
	const dispatch = useDispatch();

	// Load user
	const user = useSelector(userSelector);

	// Get the screen height
	const screenHeight = use100vh();

	// Load ref of content section
	const topRef = createRef();

	// Load the reducer and favicon
	useEffect(() => {
		store.injectReducer(appDefinition.slice, appSlice.reducer);
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const isActiveFilter = useSelector(appSlice.isRefined);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	// Load client parameters as a filter for the indicators
	useEffect(() => {
		dispatch(appSlice.fetchDataset(clientInitParameters));
	},
	[activeRefines?.project]); // eslint-disable-line react-hooks/exhaustive-deps

	// Load client parameters active indicators as an initial filter for the datasets
	// and load the first space as the initial refine for spaceLabel
	useEffect(() => {
		if (clientParameters === undefined || loadDataStatus !== "idle") {
			return;
		}

		if (JSON.stringify(clientParameters?.project) !== JSON.stringify(activeRefines?.project)) {
			dispatch(appSlice.actions.refine([{ key: "project", value: clientParameters?.project }]));
		}
	},
	[clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch and refresh data in the store
	useEffect(() => {
		if (clientParameters === undefined || activeDatasets === undefined
            || activeRefines.project === undefined
            || JSON.stringify(clientParameters?.project) !== JSON.stringify(activeRefines?.project)) {
			return;
		}
		dispatch(appSlice.fetchDatasets());
	}, [activeRefines, clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch calculated Data to be put in the store
	useEffect(() => {
		// Fetch total number of non conformities in the store
		if (activeDatasets?.nonConformities?.data?.length > 0) {
			const totalNonConformities = activeDatasets.nonConformities
			.data?.reduce((acc, element) => { acc.nbNC += element.nbNC; acc.nbCNC += element.nbCNC; return acc; },
				{ nbNC: 0, nbCNC: 0 });
			// Dispatch the number of non conformities in the store
			dispatch(
				appSlice.actions.fetchCalculatedDataset({
					datasetName: "totalNonConformities",
					datasetData: totalNonConformities
				})
			);
		}
	}, [activeDatasets?.nonConformities]); // eslint-disable-line react-hooks/exhaustive-deps

	// Reload window if data expired
	useEffect(() => {
		if (loadDataStatus === "sessionExpired") {
			setTimeout(() => {
				dispatch(appSlice.actions.reset());
				dispatch(requestSignIn());
				openSaml();
			}, 2000);
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	// Go top if menu has changed
	useEffect(() => {
		document?.getElementById("content-scroll")?.scroll({ top: 0, behavior: "smooth" });
	}, [menu, activeRefines?.siteId, activeRefines?.module]); // eslint-disable-line react-hooks/exhaustive-deps

	if (loadDataStatus === "sessionExpired") {
		return <Page401 />;
	}

	// Wait for Data to render App
	if (activeDatasets === undefined) {
		return <Loading message="Wait a moment while we load your app." />;
	}

	// Accessor for the siteScore - use the domain score if a domain is selected
	const scoreAccessor = (d) => clientParameters?.scoreBased ? d.score : d.additionalInfo.finalRating;

	return (
		<div className="relative h-full w-full">

			<IF condition={loadDataStatus === "loading"}>
				<Loading />
			</IF>

			<div className="relative flex justify-between max-w-screen-3xl mx-auto h-full bg-clarity_primary-bg"
				id="full-screen">

				{/*
				* Menu Bar of the COMPASS APP
				*/}
				<SideBar
					appSlice={appSlice}
					appDefinition={appDefinition}
					menuOptions={menuOptions}
					menu={menu}
					noTitle
					colorMenu="#F9CA48"
					colorBgMenu="#232D63"
					textColor="text-white"
					colorAccent="#F9CA48"
					indicator={{ menu: "NCs", value: activeDatasets?.totalNonConformities?.data?.nbCNC ?? 0 }}
					locales={appDefinition.locales}
				/>

				{/*
				* Content of the APP
				*/}
				<div id="content-scroll" className="w-full overflow-y-auto"
					style={{ height: screenHeight }}>
					<div ref={topRef} className="flex-grow md:pl-24">

						<IF condition={(menu !== "HOME" || activeRefines.module === undefined)
                        && activeRefines.siteId === undefined}>
							<div className="full-screen-hide">
								<ClientHeader
									clientParameters={clientParameters}
									appDefinition={appDefinition}
								/>
							</div>
						</IF>

						<IF condition={(menu === "HOME" && activeRefines.module !== undefined)
                        || activeRefines.siteId !== undefined}>
							<div className="md:-ml-4" style={{ width: (window.innerWidth > mdScreens ? "calc(100% + 16px)" : "100%") }}>
								<PageMenuHeader
									appSlice={appSlice}
									Picto={activeRefines.module !== undefined ? VerifiedUserIcon : BusinessIcon}
									filterOption={filterOptions.find((option) => option.label === "Modules")}
									customStylesSearch={customStylesSearchCompass}
									themePalette="compass"
									locales={appDefinition.locales}
								/>
							</div>
						</IF>

						<div className="grid grid-cols-1 grid-flow-row gap-y-4 px-4 py-6 md:px-8 md:grid-cols-4 md:gap-x-8 2xl:px-12 full-screen-hide">

							<IF condition={menu === "HOME" && activeRefines.module === undefined && activeRefines.siteId === undefined}>

								<div className="mb-6 md:mb-10 mt-2 md:col-span-4">
									<p className="text-4xl font-bold mb-1">{t("homePage.header.welcome")} {user.firstName},</p>
									<div className="flex justify-between">
										<p className="pt-1 md:text-lg w-full md:w-2/3 font-light leading-6">
											{t("homePage.header.introduction")}
										</p>
									</div>
								</div>

								<div className="w-full md:col-span-4 ">
									<TitleCard titleLocal="homePage.modules.title" subTitleLocal="homePage.details.subTitle"
										infoLocal="homePage.modules.info" theme="compass" locales={appDefinition.locales} />
								</div>

								{activeDatasets.listModules.data.map((element, i) => <CompassIndicatorCard
									key={`CompassIndicatorCard ${ i}`}
									appSlice={appSlice}
									module={element.module}
									ratingsConfig={clientParameters?.scoreBased
										? clientParameters?.scoring?.values : clientParameters?.rating}
									locales={appDefinition.locales}/>
								)}

								<div className='flex flex-wrap mt-4 justify-center items-center gap-x-4 md:col-span-3 text-sm' >
									<div className="font-semibold pr-4 border-r">Legend</div>
									{clientParameters?.scoreBased
										? clientParameters?.scoring?.values?.map((element, i) => <div key={`legendRating ${ i}`}
											className="flex items-center gap-x-2 ">
											<div className="h-4 w-4 rounded-full" style={{ backgroundColor: element.color }} />
											<p className="text-medium">{element.answer}</p>
										</div>
										)
										: clientParameters?.rating?.map((element, i) => <div key={`legendRating ${ i}`}
											className="flex items-center gap-x-2 ">
											<div className="h-4 w-4 rounded-full" style={{ backgroundColor: element.color }} />
											<p className="text-medium">{element.answer}</p>
										</div>
										)}
								</div>

								<div className="w-full mt-6 md:mt-10 md:col-span-4 ">
									<TitleCard titleLocal="homePage.details.title" subTitleLocal="homePage.details.subTitle"
										infoLocal="homePage.details.info" theme="compass" locales={appDefinition.locales} />
								</div>

								<div className="w-full bg-white rounded-lg p-2 px-6 py-4 md:col-span-2" >
									<NonConformitiesCard appSlice={appSlice}
										nonConformities={nonConformities} dataset="nonConformities"
										locales={appDefinition.locales}colorScale={[
											{
												label: "high",
												color: "red"
											}, {
												label: "medium",
												color: "orange"
											}, {
												label: "low",
												color: "green"
											}
										]}/>
								</div>
								<div className="relative flex flex-col w-full h-full overflow-hidden rounded-lg md:col-span-2">
									<MapLeaflet
										appSlice={appSlice}
										dataset="sites"
										getMarkerColor={clientParameters?.scoreBased ? getScoreColor : getMarkerColor}
										scoreAccessor={scoreAccessor}
										fieldsTooltip={compassMapTooltip}
										customTooltip={CompassTooltip}
										mapType="jawgTerrain"
										maxClusterRadius={30}
										minZoom={2}
										maxZoom={10}
										mapHeight={440}
										locales={appDefinition.locales}
									/>
								</div>

							</IF>

							{/*
                            * MAP PAGE
                            */}
							<IF condition={menu === "MAP" && activeRefines.siteId === undefined}>
								<div className="hidden md:flex flex-col w-full md:col-span-4">
									<PageHeader
										page="mapPage"
										Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
										locales={appDefinition.locales}
									/>
								</div>

								<div className="flex flex-col w-full rounded-3xl overflow-hidden mt-4 md:mt-0 md:col-span-4">
									<MapLeaflet
										appSlice={appSlice}
										dataset="sites"
										getMarkerColor={clientParameters?.scoreBased ? getScoreColor : getMarkerColor}
										scoreAccessor={scoreAccessor}
										fieldsTooltip={compassMapTooltip}
										customTooltip={CompassTooltip}
										mapType="jawgTerrain"
										maxClusterRadius={30}
										minZoom={3}
										maxZoom={10}
										scrollWheelZoom={true}
										mapHeight={ screenHeight - (window.innerWidth > mdScreens ? 350 : 200)}
										locales={appDefinition.locales}
									/>
								</div>

							</IF>

							{/*
                            * NON CONFORMITIES PAGE
                            */}
							<IF condition={menu === "NCs" && activeRefines.siteId === undefined}>

								<div className="hidden md:flex flex-col  w-full md:col-span-4">
									<PageHeader
										page="NCsPage"
										Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
										locales={appDefinition.locales}
									/>
								</div>
								<div className="flex flex-col w-full alphaBlur rounded-lg mt-4 md:mt-0 px-4 md:px-6 py-4 md:col-span-4">
									<ListOfNonConformities
										appSlice={appSlice}
										appNCConfig={{
											nonConformities,
											listNonConformityQuery,
											nonConformityDataset: CompassCheckpoints,
											updateListNonConformities,
											listTagsNonConformities,
											customStylesSubFilter: undefined,
											filterOptions: filterOptions.filter((option) => option.label === "Section" || option.label === "Criticality")
										}}
										dataset="listNonConformities"
										totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
										forceObservation
										observations={[
											{
												locale: "observation",
												accessorResult: (d) => d.answer,
												accessorObs: (d) => d.observation,
												picto: ChatIcon
											},
											{
												locale: "proposedActions",
												accessorActionPlanned: (d) => d.proposedActions,
												picto: ForwardIcon,
												pictoColor: ""
											}
										]}
										contentRef={topRef}
										themePalette="compass"
										locales={appDefinition.locales} />
								</div>

							</IF>

							{/*
                            * SITES TABLE PAGE
                            */}
							<IF condition={menu === "SITEs" && activeRefines.siteId === undefined}>
								<div className="hidden md:flex flex-col  w-full md:col-span-4">
									<PageHeader
										page="SitesPage"
										Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
										locales={appDefinition.locales}
									/>
								</div>
								<div className="flex flex-col w-full alphaBlur rounded-lg mt-4 md:mt-0  py-4  md:col-span-4">
									<Table
										appSlice={appSlice}
										dataset="sites"
										structureTable={clientParameters?.scoreBased ? tableStructureScoreBasedProjects : tableStructure}
										sortByRefineFields={[{ id: "cnc", desc: true },
											{ id: "nc", desc: true }]}
										themePalette="compass"
										locales={appDefinition.locales} />
								</div>

							</IF>

							{/*
                            * SELECTED MODULE or SITE PAGE
                            */}
							<IF condition={(menu === "HOME" && activeRefines.module !== undefined)
                                            || activeRefines.siteId !== undefined}>

								<div className="-mt-28 md:-mt-36 md:col-span-2 flex gap-x-2 h-64 w-full">
									<div onClick={() => dispatch(appSlice.actions
									.refine([{ key: (activeRefines.siteId === undefined ? "module" : "siteId"), value: undefined }]))}
									className="flex items-center text-clarity_primary-accent bg-white rounded-xl
                                                   text-2xl font-bold px-2 cursor-pointer h-10 w-10 z-40">
										<ArrowBackIcon fontSize="inherit" />
									</div>
									{ activeRefines.siteId === undefined && <CompassIndicatorCard
										appSlice={appSlice}
										module={activeRefines.module}
										ratingsConfig={clientParameters?.scoreBased
											? clientParameters?.scoring?.values : clientParameters?.rating}
										large
										locales={appDefinition.locales}/>
									}
									{ activeRefines.siteId !== undefined && <div className="alphaBlur rounded-xl px-2 py-3 w-full h-full">
										<CompassSiteCard appSlice={appSlice} siteData={activeDatasets.sites?.data?.[0]} locales={appDefinition.locales} />
									</div>
									}
								</div>

								<div className="relative md:-mt-36 h-52 md:h-64 flex flex-col w-full  overflow-hidden rounded-lg md:col-span-2">
									<ParentSize debounceTime={10}>
										{(parent) => (
											<MapLeaflet
												appSlice={appSlice}
												dataset="sites"
												getMarkerColor={clientParameters?.scoreBased ? getScoreColor : getMarkerColor}
												scoreAccessor={scoreAccessor}
												fieldsTooltip={compassMapTooltip}
												customTooltip={CompassTooltip}
												mapType="jawgTerrain"
												maxClusterRadius={30}
												minZoom={2}
												maxZoom={10}
												mapHeight={parent.height}
												locales={appDefinition.locales}
											/>
										)}
									</ParentSize>
									{activeRefines.siteId !== undefined && <div
										className="absolute bottom-6 left-1/2 transform -translate-x-1/2 w-4/5 gap-x-2 font-light text-medium
                                               flex justify-center items-center rounded-xl alphaBlur border px-3 py-1 z-500">
										<LocationOnIcon fontSize="small" />
										<p className="line-clamp-1">
											{activeDatasets.sites?.data?.[0]?.site.country}&nbsp;-&nbsp;
											{activeDatasets.sites?.data?.[0]?.site.city}&nbsp;-&nbsp;
											{activeDatasets.sites?.data?.[0]?.site.address}
										</p>
									</div>}
								</div>

								<div className="w-full md:col-span-4 mt-6">
									<TitleCard titleLocal="modulePage.sections.title" subTitleLocal="modulePage.sections.subTitle"
										infoLocal="modulePage.sections.info" theme="compass" locales={appDefinition.locales} />
								</div>

								<div className="flex flex-col w-full alphaBlur rounded-lg py-4 md:col-span-4 ">
									<Table
										pageTable="modulePage"
										appSlice={appSlice}
										dataset="sectionNCsList"
										structureTable={tableSectionsStructure}
										sortByRefineFields={clientParameters?.scoringBySection
											? [{ id: "section1Label", desc: false }, { id: "section2Label", desc: false }]
											: [{ id: "cnc", desc: true },
												{ id: "nc", desc: true }]}
										themePalette="compass"
										locales={appDefinition.locales} />
								</div>

								<div className="w-full md:col-span-4 mt-6">
									<TitleCard titleLocal="modulePage.nonconformities.title" subTitleLocal="modulePage.nonconformities.subTitle"
										infoLocal="modulePage.nonconformities.info" theme="compass" locales={appDefinition.locales} />
								</div>

								<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 md:col-span-4 ">
									<ListOfNonConformities
										appSlice={appSlice}
										appNCConfig={{
											nonConformities,
											listNonConformityQuery,
											nonConformityDataset: CompassCheckpoints,
											updateListNonConformities,
											listTagsNonConformities,
											customStylesSubFilter: undefined,
											filterOptions: filterOptions.filter((option) => option.label === "Section" || option.label === "Criticality")
										}}
										dataset="listNonConformities"
										totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
										forceObservation
										observations={[
											{
												locale: "observation",
												accessorResult: (d) => d.answer,
												accessorObs: (d) => d.observation,
												picto: ChatIcon
											},
											{
												locale: "proposedActions",
												accessorActionPlanned: (d) => d.proposedActions,
												picto: ForwardIcon,
												pictoColor: ""
											}
										]}
										contentRef={topRef}
										themePalette="compass"
										locales={appDefinition.locales} />
								</div>

							</IF>

						</div>

					</div>
				</div>

				{/*
				* FILTER SECTION OF THE PAGE
				*/}

				<HorizontalFilters appSlice={appSlice} theme="compass" filterOptions={filterOptions} initRefines={compassInitRefines}
					siteMenuLabel="Site" classTitle="compass_primary-accent" filterDesktop
					customStyle={customStylesCompass} customStyleSearch={customStylesSearchCompass}
					isActiveFilter={isActiveFilter} locales={appDefinition.locales}>
					<CompassScopeCard appSlice={appSlice} dataset={clientParameters?.geoScope ? "subregionDistrib" : "scope"}
						expectedTotal={clientParameters?.expectedTotal}
						geoScope={clientParameters?.geoScope}
						locales={appDefinition.locales} />
				</HorizontalFilters>

			</div>

		</div>
	);
}

export default Compass;
