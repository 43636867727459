import { API, Auth } from "aws-amplify";
import PropTypes from "prop-types";
import awsconfig from "../aws-exports";

API.configure(awsconfig);

const apiParams = {
	private: {
		apiName: "audits",
		path: "/chain"
	},
	opendata: {
		apiName: "opendata",
		path: "/opendata"
	},
	gsca: {
		apiName: "gsca",
		path: "/gsca"
	}
};

const querySliderFilterFactory = ({ criteriaQueryFilter = "$lte", activeRefines }) => (field) => {
	try {
		if (criteriaQueryFilter === "$lte") {
			return { $match: { [field]: { $lte: activeRefines[field] } } };
		}
	} catch (error) {
		console.log("Error in querySliderFilterFactory ", error);
	}
};
const querySlider = ({
	AggregQuery, sliderInsertIndex, activeRefines, sliderFields, criteriaQueryFilter
}) => {
	try {
		return sliderInsertIndex ? [
			...AggregQuery.slice(0, sliderInsertIndex),
			...sliderFields.map(querySliderFilterFactory({ activeRefines })),
			...AggregQuery.slice(sliderInsertIndex)
		] : [
			...sliderFields.map(querySliderFilterFactory({ activeRefines })),
			...AggregQuery
		];
	} catch (error) {
		console.log("Error in querySliderFactory ", error);
	}
};

/**
 * Async Launcher of the query through the API with the right authorization
 *
 * @param {Object} queryStringParameters Set of parameters to execute the proper DB query - see amplify/backend/function/index.js to see the details
 */
async function apiRequest(queryStringParameters, opendata = false) {
	try {
		const headers = opendata ? {} : { headers: { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() } };
		const { apiName, path } = opendata ? apiParams.opendata : apiParams.private;
		// copy current queryStringParameters and delete query from copy
		const modifiedQueryStringParameters = { ...queryStringParameters };
		delete modifiedQueryStringParameters.query;

		const requestInfo = {
			...headers,
			body: queryStringParameters.query,
			queryStringParameters: modifiedQueryStringParameters
		};

		const resultAPI = await API.post(apiName, path, requestInfo);

		return resultAPI;
	} catch (error) {
		console.log("API Request error: ", error);
		throw new Error(403);
	}
}

export async function gscaRequest(queryStringParameters, method) {
	try {
		const headers = {
			headers: { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() },
			body: queryStringParameters?.data || queryStringParameters?.action
		};
		// const headerBody = { ...headers, body: queryStringParameters.data };
		const { apiName, path } = apiParams.gsca;
		const requestInfo = { ...headers, ...{ queryStringParameters } };

		let resultAPI;
		switch (method) {
			case "post":
				resultAPI = await API.post(apiName, path, requestInfo);
				break;
			case "put":
				resultAPI = await API.put(apiName, path, requestInfo);
				break;
			default:
				resultAPI = await API.get(apiName, path, requestInfo);
		}

		return resultAPI;
	} catch (error) {
		console.log("API Request error: ", error);
		throw new Error(403);
	}
}

export async function downloadFromS3({
	filePath, directDownload = false, api = "getAuditsChain", appName
}) {
	const queryStringParameters = {
		app: appName,
		action: "download",
		type: "download",
		filePath
	};
	const url = await (api === "gsca" ? gscaRequest(queryStringParameters) : apiRequest(queryStringParameters));
	if (directDownload) {
		const a = document.createElement("a");
		a.href = url;
		a.download = url.split("/").pop();
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
	return url;
}

export async function uploadToDatabase(appName, collection, data) {
	const queryStringParameters = {
		app: appName,
		action: "import",
		collection,
		data
	};
	return gscaRequest(queryStringParameters, "put");
}

/**
 * API MongoDB Find Request to get result from a param collection with a query param as an Object and a limit param as an Integer
 * if no query and limit param, then the query is {} and limit=500
 *
 * @param {String} collection Name of the collection in the DB to perform the query
 * @param {Object} queryP JSON of the filter to be applied on the result (see Mongodb find documentation for more details)
 * @param {Number} limitP Maximum number of results expected
 */
export async function getResults(appName, collection, query = {}, options = {}, limit = 500, skip = 0, opendata = false, callFromApp = "") {
	const queryStringParameters = {
		app: appName,
		type: "find",
		collection,
		query,
		options: encodeURIComponent(JSON.stringify(options)),
		limit,
		skip,
		callFromApp
	};

	return apiRequest(queryStringParameters, opendata);
}

/**
 * API MongoDB Aggregate Request to get result from a param collection with a query param as an Object and a limit param as an Integer
 * if no query and limit param, then the query is {} and limit=500
 *
 * @param {String} collection Name of the collection in the DB to perform the query
 * @param {Object} queryP JSON of the filter to be applied on the result (see Mongodb find documentation for more details)
 * @param {Number} limitP Maximum number of results expected
 */
export async function getAggregation(appName, collection, query = {}, limit = 500, skip = 0,
	opendata = false, callFromApp = "", dateToTransform = {}) {
	const queryStringParameters = {
		app: appName,
		type: "aggregate",
		collection,
		query,
		limit,
		skip,
		callFromApp,
		dateToTransform: encodeURIComponent(JSON.stringify(dateToTransform))
	};

	return apiRequest(queryStringParameters, opendata);
}

/**
 * API MongoDB Find Request to get distinct value of a param field from a param collection
 *
 * @param {String} collection Name of the collection in the DB to perform the query
 * @param {String} facet Name of the field in the collection to query distinct value
 */
export async function getFacet(appName, collection, facet, opendata = false, callFromApp = "") {
	const queryStringParameters = {
		app: appName,
		type: "distinct",
		collection,
		facet,
		callFromApp
	};

	return apiRequest(queryStringParameters, opendata);
}

/**
 * Construct the additional refine MongoDB query if refine on object key
 *
 * @param {String} accumulator Accumulator to aggregate the result query
 * @param {String} refineKey Collection Key to refine
 * @param {String} refines Refines to filter
 */
export const getAdditionalRefineQuery = (activeRefines, dataset) => {
	if (dataset.transformLogic === "or") {
		let arr = [];

		Object.keys(activeRefines).forEach((k) => {
			if (dataset.facetsToTransform[k]) {
				if (!Array.isArray(activeRefines[k])) {
					arr = [...arr, { [dataset.facetsToTransform[k]]: activeRefines[k] }];
					return;
				}

				if (
					Array.isArray(activeRefines[k]) &&
					activeRefines[k].length === 1
				) {
					arr = [...arr, { [dataset.facetsToTransform[k]]: activeRefines[k][0] }];
					return;
				}

				const newRefineToAdd = activeRefines[k].map((refine) => ({
					[dataset.facetsToTransform[k]]: refine
				}));

				arr = [...arr, ...newRefineToAdd];
			}
		});

		if (arr.length === 0) return {};

		return { $or: arr };
	}

	if (dataset.transformLogic === "and") {
		return Object.keys(activeRefines).reduce((acc, cur) => {
			if (dataset.facetsToTransform[cur]) {
				if (!Array.isArray(activeRefines[cur])) {
					return { ...acc, [dataset.facetsToTransform[cur]]: activeRefines[cur] };
				}

				if (
					Array.isArray(activeRefines[cur]) &&
					activeRefines[cur].length === 1
				) {
					return {
						...acc,
						[dataset.facetsToTransform[cur]]: activeRefines[cur][0]
					};
				}

				const newRefineToAdd = activeRefines[cur].map((refine) => ({
					[dataset.facetsToTransform[cur]]: refine
				}));

				acc.$or = newRefineToAdd;
			}
			return { ...acc };
		}, {});
	}
};

/**
 * Construct the refine MongoDB query based on an array of refines
 *
 * @param {String} accumulator Accumulator to aggregate the result query
 * @param {String} refineOldKey Collection Key to refine before transformed
 * @param {String} refineKey Collection Key to refine
 * @param {String} refines Refines to filter
 */
function getRefineQuery(accumulator, refineKey, refines, isGroupFilter = false, groupFields = []) {
	if (!refines) {
		return accumulator;
	}

	if (!Array.isArray(refines)) {
		if (isGroupFilter && groupFields.includes(refineKey)) {
			if (!accumulator.$or) accumulator.$or = [];

			accumulator.$or = [...accumulator.$or, { [refineKey]: refines }];
			return accumulator;
		}

		accumulator[refineKey] = refines;

		return accumulator;
	}

	if (Array.isArray(refines) && refines.length === 1) {
		if (isGroupFilter && groupFields.includes(refineKey)) {
			if (!accumulator.$or) accumulator.$or = [];

			accumulator.$or = [...accumulator.$or, { [refineKey]: refines[0] }];
			return accumulator;
		}

		/* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
		accumulator[refineKey] = refines[0];

		return accumulator;
	}

	const newRefineToAdd = (refines.map((refine) => ({ [refineKey]: refine })));

	if (accumulator.$and) {
		accumulator.$and = (accumulator.$and).concat({ $or: newRefineToAdd });

		return accumulator;
	}

	if (accumulator.$or) {
		if (isGroupFilter && groupFields.includes(refineKey)) {
			accumulator.$or = [...accumulator.$or, ...newRefineToAdd];
			return accumulator;
		}

		accumulator.$and = [{ $or: accumulator.$or }, { $or: newRefineToAdd }];

		delete accumulator.$or;

		return accumulator;
	}

	accumulator.$or = newRefineToAdd;

	return accumulator;
}

/**
 * Async function to execute the query to fetch a dataset
 *
 * @param {Object} dataset Datasets parameters to execute a query must have as keys
 * @param {String} dataset.collection Name of the collection
 * @param {String} dataset.fetch Type of fetch to execute :
 *                               - facet to get a list of distinct value of a field in a collection,
 *                               - find : to get all the value on a filter,
 *                               - aggregation : to get the results of an aggregation in the query parameter
 * @param {Object[]} dataset.query MongoDB Query to execute depending on the fetch
 * @param {Number} dataset.limit Limit of results to be returned - default : 500
 * @param {Object} activeRefines Active Refines of the state
 * @param {Boolean} resetSkip Reset Skip if refine
 */
export async function queryDataset(appName, dataset, activeRefines, openData = false, resetSkip = false) {
	const datasetResponse = { ...dataset };
	const limit = dataset.limit ? dataset.limit : 500;
	const skip = dataset.skip && !resetSkip ? dataset.skip : 0;

	const applyRefines = dataset.facetsToRefine && dataset.facetsToRefine.length > 0
		? Object.keys(activeRefines)
		.filter((key) => dataset?.isSliderFilter
			? dataset.facetsToRefine.includes(key) && !dataset?.sliderFields.includes(key)
			: dataset.facetsToRefine.includes(key))
		// eslint-disable-next-line max-len
		.reduce((accumulator, key) => {
			let newKey;

			// refine on another key instead of active refine key if facetsToTransform is specified
			if (dataset?.facetsToTransform) {
				newKey = dataset.facetsToTransform?.[key];
			}

			if (newKey) return getRefineQuery(accumulator, newKey, activeRefines[key], dataset.isGroupFilter, dataset.groupFields);

			return getRefineQuery(accumulator, key, activeRefines[key], dataset.isGroupFilter, dataset.groupFields);
		}, {})
		: {};

	// console.log("applyRefines", applyRefines);

	try {
		switch (dataset.fetch) {
			case "facet":
				datasetResponse.data = await getFacet(appName, dataset.collection, dataset.query, openData, dataset.callFromApp);
				break;
			case "find":
				const findQuery = { ...applyRefines, ...dataset.query };
				datasetResponse.data = await getResults(
					appName, dataset.collection, findQuery, dataset.options, limit, skip, openData, dataset.callFromApp);
				break;
			case "aggregation":
				let AggregQuery = [{ $match: applyRefines }].concat(dataset.query);

				// console.log("***********start*************");
				// console.log("AggregQuery before", AggregQuery);

				// for facet transform or filter the value which is type object in mongoDB
				if (dataset.insertIndex) {
					const addtionalRefine = getAdditionalRefineQuery(activeRefines, dataset);

					// dataset.facetsToTransform
					AggregQuery = [...AggregQuery.slice(0, dataset.insertIndex), { $match: addtionalRefine }, ...AggregQuery.slice(dataset.insertIndex)];
				}

				// for slider filter, insert or concatenate it depends on whether sliderInsertIndex is defined
				if (dataset?.isSliderFilter) {
					AggregQuery = querySlider({
						AggregQuery, ...dataset, activeRefines
					});
				}

				// console.log("AggregQuery after", AggregQuery);
				// console.log("***********end*************");

				datasetResponse.data = await getAggregation(
					appName, dataset.collection, AggregQuery, limit, skip, openData, dataset.callFromApp, dataset.dateToTransform);
				break;
			default:
				break;
		}
		return datasetResponse;
	} catch (error) {
		throw new Error(403);
	}
}

queryDataset.propTypes = {
	appName: PropTypes.string,
	dataset: PropTypes.object,
	activeRefines: PropTypes.object,
	openData: PropTypes.bool,
	resetSkip: PropTypes.bool
};
