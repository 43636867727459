import React, {
	useState, useEffect, useRef, useMemo
} from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import BlockIcon from "@mui/icons-material/Block";
import { Restore } from "@mui/icons-material";
import Alert from "@mui/material/Alert";

import { nanoid } from "nanoid";
import { getFromS3 } from "../../api/adminControl";
import ArrayFieldEditor from "./ArrayFieldEditor";
import FieldTransformerController from "./FieldTransformerController";
import CheckboxController from "./CheckboxController";
import SelectFieldEditor from "./SelectFieldEditor";
import MultiSelectFieldEditor from "./MultiSelectFieldEditor";
import ObjectEditor from "./ObjectEditor";
import ObjectGroupEditor from "./ObjectGroupEditor";
import ConditionalRenderField from "./ConditionalRenderField";

import UpdateFormBtnGroup from "../button/UpdateFormBtnGroup";
import Popconfirm from "../modal/Popconfirm";

import IF from "../../utils/IF";

import {
	appDefinition, currentFieldOptions, typeSelector, fieldAttributeBuilder, newProject, fieldOrderBuilder, chainRefineFieldBuilder
} from "../../apps/configs/AdminConfig";

import {
	projectAllFieldsBuilder, projectAllKeyTypePairBuilder, transformValueWithK, FieldNameAccessor, mergeObjects, RemoveEmptyKey
} from "./FormUtils";

// remove k key when field type is array
const RemoveKey = (submitValue) => {
	if (typeof submitValue !== "object" || submitValue === null || submitValue instanceof File) {
		return submitValue;
	}

	if (Array.isArray(submitValue)) {
		return submitValue.map(RemoveKey);
	}

	return Object.entries(submitValue).reduce((acc, [key, value]) => {
		if (key === "k") {
			return RemoveKey(value);
		}
		return { ...acc, [key]: RemoveKey(value) };
	}, {});
};

const AdminProjectUpdateForm = ({
	data,
	appSlice,
	clearRefineKeys,
	targetDocAccessor,
	renderButtonGroup = true,
	screenHeight,
	onConfirm,
	requiredFields,
	disabledFields = []
}) => {
	const dispatch = useDispatch();
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const [rollBack, setRollBack] = useState(false);
	const [confirmModal, setconfirmModal] = useState(false);

	const targetDocument = useMemo(() => (
		mergeObjects(newProject({ project: activeRefines.secLevelMenu }), data.find(targetDocAccessor))),
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[data, targetDocAccessor]);

	// sort field by its priority
	const fieldOrder = fieldOrderBuilder(activeRefines);

	// get all the field from mongoDB
	const allFieldsOfCurrentCollection = [...new Set(
		projectAllFieldsBuilder({ project: activeRefines.secLevelMenu, data })
	)]
	.sort((a, b) => {
		if (disabledFields.includes(a) && !disabledFields.includes(b)) {
			return 1;
		}

		if (!disabledFields.includes(a) && disabledFields.includes(b)) {
			return -1;
		}

		return fieldOrder.indexOf(a) - fieldOrder.indexOf(b);
	});

	// all the fields in key type pair
	const AllKeyTypePair = Object.entries(
		Object.fromEntries(
			new Map(
				projectAllKeyTypePairBuilder({ project: activeRefines.secLevelMenu, data })
			))
	)
	?.reduce((acc, cur) => ({ ...acc, [cur[0]]: typeSelector(cur, activeRefines) }), {});

	// all key in target document in update mode
	// transform all flat array to array of object with key k
	const targetDocumentWithK = mergeObjects(
		transformValueWithK(newProject({ project: activeRefines.secLevelMenu })),
		transformValueWithK(targetDocument)
	);
	const lastVersionRef = useRef(targetDocumentWithK);
	const chainRefineKey = chainRefineFieldBuilder[activeRefines?.secLevelMenu];
	const chainRefineValue = typeof targetDocumentWithK[chainRefineKey] === "object"
		? targetDocumentWithK[chainRefineKey]?.[0].k : targetDocumentWithK[chainRefineKey];

	const {
		control, register, handleSubmit, formState: { errors }, reset, getValues, setValue, watch
	} = useForm({
		defaultValues: targetDocumentWithK,
		criteriaMode: "firstError",
		shouldFocusError: true
	});

	// select on sidebar and refresh form content
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => reset(targetDocumentWithK), [activeRefines.fourthLevelMenu]);

	useEffect(() => {
		if (rollBack) {
			reset(lastVersionRef.current);
			setRollBack(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rollBack]);

	// prevent enter event from triggering form to submit
	const checkKeyDown = (e) => {
		if (e.key === "Enter") e.preventDefault();
	};
	// logo upload
	const [selectedImage, setSelectedImage] = useState(null);
	const [previewUrl, setPreviewUrl] = useState(null);
	const [uploadMsg, setUploadMsg] = useState(null);
	const [inputKey, setInputKey] = useState(Date.now()); // timestamp used to force React to create a new input after deletion
	const MAX_IMAGE_SIZE_BYTES = 600 * 1024; // 600KB
	const fetchLogo = async () => {
		const url = await getFromS3(`public/projectLogos/${chainRefineValue}/${targetDocumentWithK?.logo}`);
		setPreviewUrl(url);
		setSelectedImage({ url, name: targetDocumentWithK?.logo });
	};
	useEffect(() => {
		fetchLogo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fetch img if its in repo
	const getImgRepoBlob = async (src) => {
		try {
			const response = await fetch(src);
			if (!response.ok) {
				throw new Error("Network response not ok");
			}
			const blob = await response.blob();
			return blob;
		} catch (err) {
			console.log("Error fetching image: ", err);
			return null;
		}
	};
	// when logo in repo fetch or restore
	if (previewUrl && !selectedImage) {
		getImgRepoBlob(`/images/CompanyLogos/${targetDocumentWithK?.logo}`)
		.then((blob) => {
			// eslint-disable-next-line max-len
			if (blob.type.startsWith("image")) {
				setSelectedImage(blob);
				setPreviewUrl(`/images/CompanyLogos/${targetDocumentWithK?.logo}`);
			}
		});
	}
	const handleFileInputChange = (e) => {
		e.preventDefault();
		const file = e.target.files[0];
		if (file) {
			if (file.size <= MAX_IMAGE_SIZE_BYTES) {
				setSelectedImage(file);
				setValue("logo", file.name);
				const previewUrl = URL.createObjectURL(file);
				setPreviewUrl(previewUrl);
				setUploadMsg({ severity: "success", msg: "Ready to upload" });
			} else { setUploadMsg({ severity: "error", msg: `File size limit exceeded (${Math.round(MAX_IMAGE_SIZE_BYTES / 1024)}KB).` }); }
		}
	};

	const handleDrop = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		if (file) {
			setSelectedImage(file);
			setValue("logo", file.name);
			const previewUrl = URL.createObjectURL(file);
			setPreviewUrl(previewUrl);
		}
	};
	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handleClear = () => {
		setSelectedImage(null);
		setPreviewUrl(null);
		setInputKey(Date.now());
		setUploadMsg(null);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		setconfirmModal(true);
	};

	const submit = (submitValue) => {
		let res = submitValue;

		res = RemoveKey(res);
		res = RemoveEmptyKey(res);

		const resWithoutId = { ...res };
		delete resWithoutId._id;

		onConfirm(res, resWithoutId, setconfirmModal, selectedImage);
	};

	// get domains options for active and pushed indicators from clarityDomains collection
	const clarityDomains = activeDatasets?.clarityDomains.data;
	const domainsOptions = clarityDomains.map((domain) => {
		const formatedDomain = {
			value: domain.name,
			label: domain.name
		};
		return formatedDomain;
	}, {});

	return (
		<>
			{
				confirmModal &&
				<Popconfirm
					title="Update current project"
					description="Are you sure you want to update this project?"
					confirmBtnText="Confirm"
					onClickConfirm={handleSubmit(submit)}
					onClickCancel={() => setconfirmModal(false)}
				/>
			}
			<UpdateFormBtnGroup appSlice={appSlice} locales={appDefinition.locales} renderButtonGroup={renderButtonGroup}
				defaultValue={targetDocumentWithK} reset={reset} clearRefineKeys={clearRefineKeys} />

			<form
				className="flex flex-col px-2 my-4 space-y-6 overflow-y-auto"
				style={{ height: screenHeight - 260 }}
				onSubmit={onSubmit}
				onKeyDown={(e) => checkKeyDown(e)}
			>
				{allFieldsOfCurrentCollection
				?.filter((item) => !fieldAttributeBuilder(activeRefines.secLevelMenu, "hiddenFields")?.includes(item))
				?.map((field, i) => (
					<div key={`field-${targetDocument._id}-${i}`}>
						<IF condition={AllKeyTypePair[field] === "checkbox"}>
							<label key={field + i} className={`relative w-1/3 flex font-medium gap-y-1 mt-4 capitalize
                            text-admin_primary-default border px-2 py-3 rounded-lg items-center`}>
								<span className="flex justify-center items-center bg-white uppercase text-sm">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<CheckboxController {...{ control, name: `${field}`, className: "ml-4" }} />
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>
						<IF condition={AllKeyTypePair[field] === "array" && (Object.prototype.hasOwnProperty.call(targetDocument, field))}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<ArrayFieldEditor
									control={control}
									field={field}
									register={register}
									requiredFields={requiredFields}
									activeRefines={activeRefines}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "object" && (Object.prototype.hasOwnProperty.call(targetDocument, field))}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<ObjectEditor
									control={control}
									field={field}
									register={register}
									data={targetDocument?.[`${field}`]}
									requiredFields={requiredFields}
									activeRefines={activeRefines}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "objectGroup" && (Object.prototype.hasOwnProperty.call(targetDocument, field))}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								{targetDocument?.[`${field}`] &&
								Object.entries(targetDocument?.[`${field}`]).map(([subField, fieldValue]) => (
									<ObjectGroupEditor
										key={nanoid()}
										field={`${field}.${subField}`}
										// field={subField}
										fieldValue={fieldValue}
										control={control}
										register={register}
										activeRefines={activeRefines}
										appSlice={appSlice}
										getValues={getValues}
										setValue={setValue}
									/>
								))}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "number" && (Object.prototype.hasOwnProperty.call(targetDocument, field))}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							 text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								{/* transform str to num */}
								<FieldTransformerController
									transform={{
										input: (value) => Number.isNaN(value) || value === 0 ? "" : value?.toString(),
										output: (e) => {
											const output = parseInt(e.target.value, 10);
											return Number.isNaN(output) ? 0 : output;
										}
									}}
									control={control}
									name={`${field}`}
									defaultValue=""
									placeholder="enter a number"
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "select" && (Object.prototype.hasOwnProperty.call(targetDocument, field))}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
								text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<SelectFieldEditor
									field={field}
									control={control}
									appSlice={appSlice}
									options={currentFieldOptions(field)}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem",
											borderRadius: "0.5rem"
										})
									}}
									activeRefines={activeRefines}
									placeholder={getValues(field)}
									setValue={setValue}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>
						<IF condition={AllKeyTypePair[field] === "multiSelect"}>
							<label
								key={field + i}
								className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
											text-admin_primary-default border px-2 py-3 rounded-lg"
							>
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<MultiSelectFieldEditor
									field={field}
									control={control}
									appSlice={appSlice}
									options={domainsOptions}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											borderRadius: "0.5rem"
										}),
										multiValue: (styles) => ({
											...styles,
											borderRadius: "0.4rem",
											backgroundColor: "#deebff", // custom bgColor to align admin
											margin: "0.4rem"
										}),
										multiValueLabel: (styles) => ({
											...styles,
											color: "#1c3faa", // custom color to align admin
											paddingTop: "0.3rem"
										})
									}}
									activeRefines={activeRefines}
									defaultValue={getValues(field)}
									getValues={getValues}
									requiredFields={requiredFields}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "conditional" && (Object.prototype.hasOwnProperty.call(targetDocument, field))}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
								text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								{targetDocument?.[`${field}`] &&
								Object.entries(targetDocument?.[`${field}`]).map(([subField, fieldValue]) => (
									<ConditionalRenderField key={nanoid()} 	field={`${field}.${subField}`} fieldValue={fieldValue}
										register={register} watch={watch} setValue={setValue} activeRefines={activeRefines}
										options={currentFieldOptions(`${field}.${subField}`)}
									/>

								))}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "textarea" && (Object.prototype.hasOwnProperty.call(targetDocument, field))}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
									text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex
										justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<textarea {...register(`${field}`)} className="h-52 p-2 my-2 text-black border rounded-lg bg-white
										focus:outline-none focus:ring-2 focus:ring-admin_primary-default
										focus:border-transparent text-sm focus:text-base"/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "upload" && (Object.prototype.hasOwnProperty.call(targetDocument, field))}>
							<div key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
											text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white
												uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<label
									className="p-2 my-2 text-gray-400 border rounded-lg bg-white focus:outline-none focus:ring-2
								focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"
									htmlFor="uploadInput">
									<div className={`flex flex-col items-center ${!selectedImage && "opacity-50"}
									hover:opacity-100 hover:cursor-pointer`}
									onDrop={handleDrop}
									onDragOver={handleDragOver}
									>
										{!previewUrl && <UploadRoundedIcon
											className="text-admin_primary-default"/>}
										{(previewUrl) && (
											<img src={previewUrl} alt="logo" className="object-contain w-20 h-18"/>
										)}
										<span className={`normal-case ${(selectedImage || targetDocumentWithK?.logo) && "opacity-100 mt-2"}`}>
											{!previewUrl
												? "Drag & drop here or click to browse from your device"
												: selectedImage?.name || targetDocumentWithK?.logo}</span>
									</div>
								</label>
								{uploadMsg && <Alert severity={uploadMsg?.severity}>{uploadMsg?.msg}</Alert>}
								{(previewUrl) && <button
									type="button"
									onClick={handleClear}
									className="mx-2 border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold
												px-2 py-1 rounded"
								>
									<BlockIcon className="text-base"/>
								</button>}
								{(!previewUrl) && <button
									type="button"
									onClick={() => fetchLogo()}
									className="mx-2 border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold
												px-2 py-1 rounded"
								>
									<Restore className="text-base"/>
								</button>}
								<input {...register(`${field}`, { required: requiredFields?.includes(field) })}
									className="hidden"
									id="uploadInput"
									key={inputKey}
									type="file"
									accept="image/*"
									onChange={handleFileInputChange}
									placeholder="Drag & drop here or browse file from your device"/>

								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</div>
						</IF>

						<IF condition={
							AllKeyTypePair[field] !== "array"
						&& AllKeyTypePair[field] !== "object"
						&& AllKeyTypePair[field] !== "objectGroup"
						&& AllKeyTypePair[field] !== "conditional"
						&& AllKeyTypePair[field] !== "number"
						&& AllKeyTypePair[field] !== "select"
						&& AllKeyTypePair[field] !== "multiSelect"
						&& AllKeyTypePair[field] !== "textarea"
						&& AllKeyTypePair[field] !== "upload"
						&& AllKeyTypePair[field] !== "checkbox"
						&& (Object.prototype.hasOwnProperty.call(targetDocument, field))
						}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
											text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white
												uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<input {...register(`${field}`, { required: requiredFields?.includes(field) })}
									className="p-2 my-2 text-black border rounded-lg bg-white focus:outline-none focus:ring-2
									focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>
					</div>
				))}

				<div className="flex flex-col space-y-4 mt-10">
					<button className="border bg-admin_primary-default hover:bg-admin_primary-dark
                    text-white font-bold py-1 px-3 rounded uppercase" type="submit">
						<DoneAllIcon />
					</button>
				</div>

			</form>
		</>
	);
};

AdminProjectUpdateForm.propTypes = {
	data: PropTypes.array.isRequired,
	appSlice: PropTypes.object,
	clearRefineKeys: PropTypes.array,
	targetDocAccessor: PropTypes.func,
	renderButtonGroup: PropTypes.bool,
	screenHeight: PropTypes.number,
	onConfirm: PropTypes.func,
	requiredFields: PropTypes.array,
	disabledFields: PropTypes.array
};

export default AdminProjectUpdateForm;
