import React, {
	useMemo, useState, useEffect, useRef
} from "react";
import { Group } from "@visx/group";
import { hierarchy, Tree } from "@visx/hierarchy";
// import { Zoom } from "@visx/zoom";
import { localPoint } from "@visx/event";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import GetAppIcon from "@mui/icons-material/GetApp";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Zoom from "./Zoom";
import TreeType from "./TreeType";
import "../../styles/tree.css";
import TreeNode from "./TreeNode";
import BezierLink from "./BezierLink";
import exportToExcel from "../../utils/ExportToExcel";
import Loading from "../../utils/Loading";

// Number of children to expand initally
const nbChildrenToInitialExpand = 10;

// node size
const rectW = 105;
const rectH = 50;

const origin = { x: 0, y: 0 };

const subTreesBetweenDistance = 80;
const collapsedSubTreesBetweenDistance = 10;

const verticalSeparationNodes = 0.23;
const verticalSeparationParentNodes = 0.25;

const subTreeMargin = {
	top: 56,
	left: -130,
	right: 0,
	bottom: 0
};

const TreeNodesGraph = ({
	appDefinition,
	appSlice,
	dataset = "supplyChain",
	datasetTree = "supplyChainTree",
	datasetFinalProduct,
	width: parentWidth,
	height: parentHeight,
	currentNodeDetail,
	margin,
	subTreeMargin,
	nodeIdKey = "siteId",
	refineHighlightKeys = ["siteId"],
	refineSiteKeys = ["siteId"],
	fieldsToExport = ["auditedCompany"],
	onlyAudited = false,
	getMarkerColor,
	consolidated,
	displayTooltip,
	TreeTooltip,
	titleCase = true,
	enableNodeDispatch
}) => {
	// hierarchically list all data with less infos  (name/ product / score /supplier)
	const appName = useSelector(appSlice.selectAppName);
	const loadStatus = useSelector(appSlice.selectLoadDataStatus);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const { t } = useTranslation(appDefinition.locales);

	const [dynamicWidth, setDynamicWidth] = useState(0);
	const [dynamicHeight, setDynamicHeight] = useState(0);

	const [expandedNodeDepths, setExpandedNodeDepths] = useState([
		0, 1, 2, 3, 4, 5
	]);
	const [collapseAll, setCollapseAll] = useState(undefined);

	const [selected, setSelected] = useState(null);

	// handle node ref from TreeNode components. Smooth scroll to highlighted node when there is a refine
	const [position, setPosition] = useState(0);
	// set auto scroll to the first search result
	const [isScrollInit, setIsScrollInit] = useState(false);

	const currentNodeAllAuditedChildrenId = useMemo(() => selected?.descendants()
	.filter((node, index) => node.data.data.resilience > 0 && index !== 0)
	.map((item) => item.data.data.siteId), [selected]);

	const supplyChainExportData = useSelector(appSlice.selectDatasets)[dataset]
	?.data?.map((element) => {
		const lineToExport = {};
		fieldsToExport.forEach((key) => (
			["resilience", "resistance", "responsiveness"].includes(key)
				? lineToExport[t(`exports.${key}`)] = element[key] != null ? { t: "n", v: element[key] / 100, z: "0.00%" } : null
				: lineToExport[t(`exports.${key}`)] = element[key]
		));
		return lineToExport;
	}) ?? undefined;
	const supplyChainData = useSelector(appSlice.selectDatasets)[datasetTree];
	const activeFinalProducts = useSelector(appSlice.selectDatasets)[datasetFinalProduct]?.data;

	const [isFullScreen, setIsFullScreen] = useState(false);

	// Automatic Scale depending on the width of the parent
	const initialTransform = {
		scaleX: Math.min(Math.round(parentWidth / 87) / 10, 1.3),
		scaleY: Math.min(Math.round(parentWidth / 87) / 10, 1.3),
		translateX: 0,
		translateY: 0,
		skewX: 0,
		skewY: 0
	};

	const treeData = useMemo(() => {
		if (
			!supplyChainData ||
			!supplyChainData.data ||
			supplyChainData.data.children.length === 0
		) {
			return null;
		}

		return hierarchy(supplyChainData.data);
	}, [supplyChainData]);

	// save all highlighted nodes doms
	const childrenRefs = Array.from({ length: treeData?.descendants()?.length }, () => React.createRef(null));

	// This collection includes divs based on the page's filter, we need it for scrolling when clicking "next" btn.
	// The collection is "Map()" with the IDs of of nodes as "keys" to avoid duplicates.
	const divsPerFilterRefs = useRef(new Map());

	// As soon as activeRefines have been updated (if filter values have changed)
	// the collection is reset to make room for new divs as per chosen filters.
	useEffect(() => {
		divsPerFilterRefs.current.clear();
		setPosition(0);
	}, [activeRefines]);

	// for searching node in collapsed tree
	// filter sites in supplychain
	const searchNodes = useMemo(() => {
		const refineCopy = { ...activeRefines };
		delete refineCopy.projectName;
		delete refineCopy.isCleared;

		return Object.keys(refineCopy).length > 0 ? activeDatasets?.filteredSupplyChain?.data?.map((item) => item._id) : [];
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeDatasets?.filteredSupplyChain]);

	// for searching node in collapsed tree
	// find a given node within a hierarchy/layout and return their ancestor id
	// all the ancestor id will be paired in the following step when define a node is collapsed or not
	const searchNodesInTree = useMemo(() => treeData
	?.descendants()
	.slice(1)
	.filter((node) => {
		if (node?.data?._id) {
			return searchNodes?.includes(node?.data?._id);
		}
		return false;
	})
	.map((node) => node.ancestors().map((parentNode) => parentNode?.data?._id))
	.flat()
	.filter((id) => id), [searchNodes, treeData]);

	// use for svg dynamic height and width
	// we only setState when the last tree renders otherwise error
	const maxIndex =
		treeData && treeData.children && treeData.children.length - 1;

	const onFullScreen = () => {
		const fs = document.getElementById("full-screen");
		if (fs.webkitRequestFullscreen) {
			console.log("enter fullscreen");
			fs.webkitRequestFullscreen();
			setIsFullScreen(true);
		}
	};

	const onExitFullScreen = () => {
		if (isFullScreen && document.exitFullscreen) {
			setIsFullScreen(false);
			document
			.exitFullscreen()
			.then(() => {
				console.log("exit fullscreen");
			})
			.catch((error) => {
				console.log("error", error);
			});
		}
	};

	useEffect(() => {
		if (Object.keys(activeRefines).some((k) => refineHighlightKeys.includes(k)) && !isScrollInit) {
			setIsScrollInit(true);
		}
		// Do not add isScrollInit to array
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeRefines]);

	useEffect(() => {
		const counter = childrenRefs?.filter((item) => item.current !== null)?.length;

		if (counter > 0 && isScrollInit && loadStatus === "idle") {
			childrenRefs.filter((item) => item.current !== null)[0].current.scrollIntoView({
				behavior: "smooth",
				block: "center"
			});

			// set false to disable the auto scroll to first node after search in avoid to have conflict to the function applied to <next> button
			setIsScrollInit(false);
		}
	}, [childrenRefs, isScrollInit, loadStatus]);

	if (loadStatus !== "idle" || !treeData?.children?.[0]?.data?.name || treeData?.children?.[0]?.data?.name === "") {
		return <Loading isComponent />;
	}

	// for position: selected?.data.depth/height === treeData node.depth/height === d.depth/height
	return parentWidth < 10 ? null : (
		<Zoom
			width={dynamicWidth}
			height={dynamicHeight}
			// constrain={constrain}
			initialTransformMatrix={initialTransform}
			scaleXMin={1 / 2}
			scaleXMax={4}
			scaleYMin={1 / 2}
			scaleYMax={4}
		>
			{(zoom) => {
				// currentshifting = accumulated previous sub trees' heights + top half heigt of current subtree + 1/2 rectH
				// if set subTreesBetweenDistance = 0, all the sub trees will stay on its margin (not gap between others but never overlap)
				// subTreeHeightArr starts with 0

				const subTreeHeightArr = [0];
				const currentSubTreeTopHalfHeight = [];
				const subTreeWidthArr = [];

				const svgWidth =
					dynamicWidth - 2 * rectW > parentWidth
						? dynamicWidth - 2 * rectW
						: parentWidth;

				const svgHeight =
					dynamicHeight - 2 * rectH > parentHeight
						? (dynamicHeight - 2 * rectH) * initialTransform.scaleY
						: parentHeight * initialTransform.scaleY;

				return (
					<div className="snap-y">
						<svg
							width={svgWidth}
							height={svgHeight}
							className="svgTree pointer-events-auto"
							ref={zoom.containerRef}
							style={{ cursor: zoom.isDragging ? "grabbing" : "grab" }}
							onDoubleClick={(event) => {
								const point = localPoint(event) || { x: 0, y: 0 };
								zoom.scale({ scaleX: 1.2, scaleY: 1.2, point });
							}}
							onMouseDown={zoom.dragStart}
							onMouseMove={zoom.dragMove}
							onMouseUp={zoom.dragEnd}
							onMouseLeave={() => {
								if (zoom.isDragging) zoom.dragEnd();
							}}
						>
							<g transform={zoom.toString()}>
								<Group top={margin.top} left={margin.left}>
									{
										treeData?.children?.map((tree, i) => (
											<Tree
												key={i}
												root={hierarchy(tree, (d) => {
													// initialize isExpanded, node with more than 5 children will collapse
													if (
														d.depth >= 2 &&
														d.isExpanded === undefined &&
														collapseAll === undefined &&
														d.children &&
														d.children.length > nbChildrenToInitialExpand
													) {
														d.isExpanded = true;
													}

													if (d.depth >= 2 && collapseAll) {
														d.isExpanded = true;
													}

													if (
														d.depth >= 2 &&
														collapseAll !== undefined &&
														!collapseAll
													) {
														d.isExpanded = false;
													}

													if (currentNodeAllAuditedChildrenId?.includes(d.data.siteId)) {
														d.isHighlighted = true;
													}

													return (searchNodesInTree.length > 0 && searchNodesInTree.includes(d.data._id))
														? d.children
														: expandedNodeDepths.includes(d.depth) &&
														!d.isExpanded
															? d.children
															: null;
												})}
												nodeSize={[240, 270]}
												separation={(a, b) => a.parent === b.parent
													? verticalSeparationNodes
													: verticalSeparationParentNodes
												}
											>
												{(tree) => {
													// get all the nodes' coodinates of current sub tree
													const treeCoordinateYArr = tree
													.descendants()
													.map((node) => node.x);

													const treeCoordinateXArr = tree
													.descendants()
													.map((node) => node.y);

													// get tree lowest y/ top y / right x / left x
													const treeYMax = Math.max(...treeCoordinateYArr) + rectH;
													const treeYmin = Math.min(...treeCoordinateYArr);

													const treeXMax = Math.max(...treeCoordinateXArr);
													const treeXmin = Math.min(...treeCoordinateXArr);

													// get current sub tree height / width
													const subTreeH = treeYMax - treeYmin;
													const subTreeW = treeXMax - treeXmin;

													// calculate current sub tree shifting
													subTreeHeightArr.push(
														subTreeH + subTreesBetweenDistance
													);
													currentSubTreeTopHalfHeight.push(
														Math.abs(treeYmin) + rectH / 2
													);

													// rendre subtree base on collapse
													const isRendered = activeFinalProducts.find(
														(element) => element.finalProduct === tree?.data?.data?.name
													);

													// collapse or not
													if (!isRendered) {
														subTreeHeightArr[i + 1] =
															rectH / 2 + collapsedSubTreesBetweenDistance;
													}

													// current tree cumulative shifting
													const currentTreeShifting =
														subTreeHeightArr.reduce((acc, cur, key) => i !== key - 1 ? acc + cur : acc
														) + currentSubTreeTopHalfHeight[i];

													// calculate dynamic width

													subTreeWidthArr.push(subTreeW + 2 * rectW);

													// console.log(`*********${i} DEBUG START***********`);
													// console.log("isRendered", isRendered);
													// console.log("currentTreeShifting", currentTreeShifting);
													// console.log("single branch num",
													// 	currentSubTreeTopHalfHeight.filter((item, index) => item === rectH / 2));
													// console.log("treeCoordinateYArr", treeCoordinateYArr);
													// console.log("treeYax", treeYMax);
													// console.log("treeYmin", treeYmin);
													// console.log("subTreeH", subTreeH);
													// console.log("treeXMax", treeXMax);
													// console.log("treeXmin", treeXmin);
													// console.log("subTreeW", subTreeW);
													// console.log("subTreeHeightArr", subTreeHeightArr);
													// console.log("subTreeWidthArr", subTreeWidthArr);
													// console.log("currentSubTreeTopHalfHeight", currentSubTreeTopHalfHeight);
													// console.log("maxIndex", maxIndex);
													// console.log(`----------${i} DEBUG END----------`);

													// svg height = last sub tree shifting + last sub tree lower half height + margin bottom
													// svg width =maximum sub tree width + 2 * rectW   (default 1050)
													if (i === maxIndex) {
														setDynamicWidth(Math.max(...subTreeWidthArr));
														setDynamicHeight(
															currentTreeShifting +
															Math.abs(treeYMax) +
															margin.bottom
														);
													}

													let currentFinalProduct = "";

													return (
														<Group
															top={origin.y + subTreeMargin.top}
															left={origin.x + subTreeMargin.left}
														>
															{tree
															.links()
															.map(
																(link, i) => link.source.depth !== 0 &&
																		isRendered && (
																	<BezierLink
																		key={i}
																		data={link}
																		rectW={rectW}
																		currentTreeShifting={
																			currentTreeShifting
																		}
																	/>
																)
															)}

															{tree.descendants().map((node, index) => (
																<Group top={node.x} left={node.y} key={index}>
																	{node.depth === 0 && (
																		<TreeType
																			appSlice={appSlice}
																			treeTypeHeight={
																				node.depth === 0 && treeYmin - rectH / 2
																			}
																			name={node.data.data.name}
																			currentTreeShifting={currentTreeShifting}
																			activeFinalProducts={activeFinalProducts}
																			nbTotalFinalProducts={
																				supplyChainData?.data?.children?.length
																			}
																			locales={appDefinition.locales}
																			titleCase={titleCase}
																		/>
																	)}

																	{node.depth === 0 &&
																		(currentFinalProduct =
																			node?.data?.data?.name)}
																	{node.depth !== 0 && isRendered && (
																		<Group className="group snap-y transform-gpu">
																			<TreeNode
																				rectW={rectW}
																				rectH={rectH}
																				node={node}
																				currentFinalProduct={
																					currentFinalProduct
																				}
																				currentTreeShifting={
																					currentTreeShifting
																				}
																				currentNodeDetail={currentNodeDetail}
																				appSlice={appSlice}
																				nodeIdKey={nodeIdKey}
																				refineHighlightKeys={refineHighlightKeys}
																				refineSiteKeys={refineSiteKeys}
																				onlyAudited={onlyAudited}
																				getMarkerColor={getMarkerColor}
																				setExpandedNodeDepths={
																					setExpandedNodeDepths
																				}
																				setCollapseAll={setCollapseAll}
																				locales={appDefinition.locales}
																				setSelected={setSelected}
																				consolidated={consolidated}
																				inactive={node.data.data.inactive}
																				ref={childrenRefs}
																				divsPerFilterRefs={divsPerFilterRefs}
																				index={index}
																				TreeTooltip={TreeTooltip}
																				displayTooltip={displayTooltip}
																				enableNodeDispatch={enableNodeDispatch}
																			/>
																		</Group>

																	)}
																</Group>
															))}
														</Group>
													);
												}}
											</Tree>
										))}
								</Group>
							</g>

						</svg>
						<div
							className="absolute top-5 right-8 flex flex-col align-baseline text-xs rounded-xl shadow-lg
                        bg-white text-supplyr_primary-menu py-1 px-1 z-20"
						>
							{isFullScreen ? (
								<button type="button" onClick={onExitFullScreen}>
									<FullscreenExitIcon style={{ fontSize: "23.5px" }} />
								</button>
							) : (
								<button type="button" onClick={onFullScreen}>
									<FullscreenIcon style={{ fontSize: "23.5px" }} />
								</button>
							)}
						</div>
						<div
							className="absolute top-16 right-9 flex flex-col align-baseline rounded-xl shadow-lg
                        bg-white text-supplyr_primary-menu px-2 z-20"
						>
							<button
								type="button"
								className="text-lg"
								onClick={() => zoom.scale({ scaleX: 1.2, scaleY: 1.2 })}
							>
								+
							</button>
							<hr />
							<button
								type="button"
								className="text-lg"
								onClick={() => zoom.scale({ scaleX: 0.8, scaleY: 0.8 })}
							>
								-
							</button>
						</div>

						<button
							data-test="supply-chain-download"
							type="button"
							onClick={() => supplyChainExportData
                                    && exportToExcel(supplyChainExportData,
                                        `BV ${
											activeRefines?.projectName
												? (Array.isArray(activeRefines?.projectName)
													? activeRefines?.projectName[0] : activeRefines?.projectName)
												: appName} - SupplyChain Export ${new Date().toISOString().split("T")[0]}`)}
							className="absolute hidden md:block top-32 right-8  text-small rounded-xl shadow-lg
                        bg-white text-supplyr_primary-menu py-1 px-1 z-20"
						>
							<GetAppIcon style={{ fontSize: "23.5px" }} />
						</button>

						{Object.keys(activeRefines).some((k) => refineHighlightKeys.includes(k)) && (
							<>
								<p className="absolute hidden md:block top-48 right-8  text-small
                       			 text-supplyr_primary-menu py-1 px-1 z-20 animate-bounce">
									{t("supplyChainGraph.next")}
								</p>
								<button
									type="button"
									onClick={() => {
										const counter = divsPerFilterRefs.current.size;

										if (position < counter - 1) {
											setPosition((prevPos) => prevPos + 1);
										} else {
											setPosition(0);
										}

										const arrayOfDivs = Array.from(divsPerFilterRefs.current);

										arrayOfDivs.filter((item) => item !== null)[position][1].scrollIntoView({
											behavior: "smooth",
											block: "center"
										});
									}}
									className="absolute hidden md:block top-56 right-8  text-small rounded-xl shadow-lg
                       			 bg-white text-supplyr_primary-menu py-1 px-1 z-20 animate-bounce"
								>
									<NavigateNextIcon style={{ fontSize: "23.5px" }} />
								</button>
							</>)}

						<button
							type="button"
							onClick={zoom.reset}
							className="absolute hidden md:block top-5 right-60 text-small rounded-xl shadow-lg
                        bg-white text-supplyr_primary-menu py-2 px-2 z-20 w-18"
						>
							{t("supplyChainGraph.clearZoom")}
						</button>

						<button
							type="button"
							onClick={() => setCollapseAll(false)}
							className="absolute hidden md:block top-5 right-40 text-small rounded-xl shadow-lg
                                bg-white text-supplyr_primary-menu py-2 px-2 z-20 w-18"
						>
							{t("supplyChainGraph.expandAll")}
						</button>

						<button
							type="button"
							onClick={() => setCollapseAll(true)}
							className="absolute hidden md:block top-5 right-20 text-small rounded-xl shadow-lg
                                   bg-white text-supplyr_primary-menu py-2 px-2 z-20 w-18"
						>
							{t("supplyChainGraph.CollapseAll")}
						</button>

					</div>
				);
			}}
		</Zoom>
	);
};

TreeNodesGraph.propTypes = {
	appDefinition: PropTypes.object,
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	datasetTree: PropTypes.string,
	datasetFinalProduct: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	currentNodeDetail: PropTypes.any,
	nodeIdKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	refineHighlightKeys: PropTypes.array,
	refineSiteKeys: PropTypes.array,
	onlyAudited: PropTypes.bool,
	fieldsToExport: PropTypes.array,
	getMarkerColor: PropTypes.func,
	margin: PropTypes.object,
	subTreeMargin: PropTypes.object,
	consolidated: PropTypes.bool,
	displayTooltip: PropTypes.bool,
	titleCase: PropTypes.bool,
	TreeTooltip: PropTypes.func,
	enableNodeDispatch: PropTypes.bool
};

export default TreeNodesGraph;
