/* eslint-disable max-len */
import { API, Auth } from "aws-amplify";
import PropTypes from "prop-types";
import awsconfig from "../aws-exports";

API.configure(awsconfig);
const apiParams = {
	admin: {
		apiName: "admin",
		path: "/admin"
	},
	postprocessing: {
		apiName: "admin",
		path: "/postprocessing"
	}
};
const querySliderFilterFactory = ({ criteriaQueryFilter = "$lte", activeRefines }) => (field) => {
	try {
		if (criteriaQueryFilter === "$lte") {
			return { $match: { [field]: { $lte: activeRefines[field] } } };
		}
	} catch (error) {
		console.log("Error in querySliderFilterFactory ", error);
	}
};

const querySlider = ({
	AggregQuery, sliderInsertIndex, activeRefines, sliderFields, criteriaQueryFilter
}) => {
	try {
		return sliderInsertIndex ? [
			...AggregQuery.slice(0, sliderInsertIndex),
			...sliderFields.map(querySliderFilterFactory({ activeRefines })),
			...AggregQuery.slice(sliderInsertIndex)
		] : [
			...sliderFields.map(querySliderFilterFactory({ activeRefines })),
			...AggregQuery
		];
	} catch (error) {
		console.log("Error in querySliderFactory ", error);
	}
};

/**
 * Construct the additional refine MongoDB query if refine on object key
 *
 * @param {String} accumulator Accumulator to aggregate the result query
 * @param {String} refineKey Collection Key to refine
 * @param {String} refines Refines to filter
 */
export const getAdditionalRefineQuery = (activeRefines, dataset) => {
	if (dataset.transformLogic === "or") {
		let arr = [];

		Object.keys(activeRefines).forEach((k) => {
			if (dataset.facetsToTransform[k]) {
				if (!Array.isArray(activeRefines[k])) {
					arr = [...arr, { [dataset.facetsToTransform[k]]: activeRefines[k] }];
					return;
				}

				if (
					Array.isArray(activeRefines[k]) &&
					activeRefines[k].length === 1
				) {
					arr = [...arr, { [dataset.facetsToTransform[k]]: activeRefines[k][0] }];
					return;
				}

				const newRefineToAdd = activeRefines[k].map((refine) => ({
					[dataset.facetsToTransform[k]]: refine
				}));

				arr = [...arr, ...newRefineToAdd];
			}
		});

		if (arr.length === 0) return {};

		return { $or: arr };
	}

	if (dataset.transformLogic === "and") {
		return Object.keys(activeRefines).reduce((acc, cur) => {
			if (dataset.facetsToTransform[cur]) {
				if (!Array.isArray(activeRefines[cur])) {
					return { ...acc, [dataset.facetsToTransform[cur]]: activeRefines[cur] };
				}

				if (
					Array.isArray(activeRefines[cur]) &&
					activeRefines[cur].length === 1
				) {
					return {
						...acc,
						[dataset.facetsToTransform[cur]]: activeRefines[cur][0]
					};
				}

				const newRefineToAdd = activeRefines[cur].map((refine) => ({
					[dataset.facetsToTransform[cur]]: refine
				}));

				acc.$or = newRefineToAdd;
			}
			return { ...acc };
		}, {});
	}
};

/**
 * Construct the refine MongoDB query based on an array of refines
 *
 * @param {String} accumulator Accumulator to aggregate the result query
 * @param {String} refineKey Collection Key to refine
 * @param {String} refines Refines to filter
 */
function getRefineQuery(accumulator, refineKey, refines, isGroupFilter = false, groupFields = []) {
	if (!refines) {
		return accumulator;
	}

	if (!Array.isArray(refines)) {
		if (isGroupFilter && groupFields.includes(refineKey)) {
			if (!accumulator.$or) accumulator.$or = [];

			accumulator.$or = [...accumulator.$or, { [refineKey]: refines }];
			return accumulator;
		}

		accumulator[refineKey] = refines;

		return accumulator;
	}

	if (Array.isArray(refines) && refines.length === 1) {
		if (isGroupFilter && groupFields.includes(refineKey)) {
			if (!accumulator.$or) accumulator.$or = [];

			accumulator.$or = [...accumulator.$or, { [refineKey]: refines[0] }];
			return accumulator;
		}

		/* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
		accumulator[refineKey] = refines[0];

		return accumulator;
	}

	const newRefineToAdd = (refines.map((refine) => ({ [refineKey]: refine })));

	if (accumulator.$and) {
		accumulator.$and = (accumulator.$and).concat({ $or: newRefineToAdd });

		return accumulator;
	}

	if (accumulator.$or) {
		if (isGroupFilter && groupFields.includes(refineKey)) {
			accumulator.$or = [...accumulator.$or, ...newRefineToAdd];
			return accumulator;
		}

		accumulator.$and = [{ $or: accumulator.$or }, { $or: newRefineToAdd }];

		delete accumulator.$or;

		return accumulator;
	}

	accumulator.$or = newRefineToAdd;

	return accumulator;
}

/**
 * Async Launcher of the query through the API with the right authorization
 *
 * @param {Object} queryStringParameters Set of parameters to execute the proper DB query - see amplify/backend/function/index.js to see the details
 */
async function adminApiGetRequest(queryStringParameters) {
	try {
		const headers = { headers: { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() } };
		const { apiName, path } = apiParams.admin;

		const requestInfo = { ...headers, ...{ queryStringParameters } };

		const resultAPI = await API.get(apiName, path, requestInfo);

		return resultAPI;
	} catch (error) {
		console.log("API Request error: ", error);
		throw new Error(403);
	}
}

async function adminApiPostRequest(queryStringParameters, api = "admin") {
	try {
		const headers = { headers: { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() } };
		const headerBody = { ...headers, body: queryStringParameters.data };
		const { apiName, path } = apiParams[api];

		const requestInfo = { ...headerBody, ...{ queryStringParameters } };

		const resultAPI = await API.post(apiName, path, requestInfo);
		return resultAPI;
	} catch (error) {
		console.log("API Request error: ", error);
		throw new Error(403);
	}
}

async function adminApiPutRequest(queryStringParameters) {
	try {
		const headers = { headers: { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() } };
		const headerBody = { ...headers, body: queryStringParameters.data };
		const { apiName, path } = apiParams.admin;

		const requestInfo = { ...headerBody, ...{ queryStringParameters } };

		const resultAPI = await API.put(apiName, path, requestInfo);

		return resultAPI;
	} catch (error) {
		console.log("API Request error: ", error);
		throw new Error(403);
	}
}

async function adminApiDelRequest(queryStringParameters) {
	try {
		const headers = { headers: { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() } };
		const headerBody = { ...headers, body: queryStringParameters.data };
		const { apiName, path } = apiParams.admin;

		const requestInfo = { ...headerBody, ...{ queryStringParameters } };

		const resultAPI = await API.del(apiName, path, requestInfo);

		return resultAPI;
	} catch (error) {
		console.log("API Request error: ", error);
		throw new Error(403);
	}
}
/**
 * Get a signed url from a file stored in s3 bucket
 */

export const getFromS3 = async (fileKey) => {
	const queryStringParameters = {
		app: "ADMIN",
		type: "s3Get",
		fileKey
	};
	const url = await adminApiGetRequest(queryStringParameters);
	return url;
};
/**
 * API MongoDB Find Request to get result from a param collection with a query param as an Object and a limit param as an Integer
 * if no query and limit param, then the query is {} and limit=500
 *
 * @param {String} collection Name of the collection in the DB to perform the query
 * @param {Object} queryP JSON of the filter to be applied on the resuadminGetResultslt (see Mongodb find documentation for more details)
 * @param {Number} limitP Maximum number of results expected
 */
export async function adminRetrieveAllDocument(appName, collection, query = {}, limit = 500, skip = 0, adminCallFromApp = "") {
	const queryStringParameters = {
		app: appName,
		type: "findAll",
		collection,
		query: encodeURIComponent(JSON.stringify(query)),
		limit,
		skip,
		adminCallFromApp
	};

	return adminApiGetRequest(queryStringParameters);
}

/**
 * API MongoDB Find Request to get result from a param collection with a query param as an Object and a limit param as an Integer
 * if no query and limit param, then the query is {} and limit=500
 *
 * @param {String} collection Name of the collection in the DB to perform the query
 * @param {Object} queryP JSON of the filter to be applied on the resuadminGetResultslt (see Mongodb find documentation for more details)
 * @param {Number} limitP Maximum number of results expected
 */
export async function adminRetrieveDocument(appName, collection, id, query = {}, limit = 500, skip = 0, adminCallFromApp = "") {
	const queryStringParameters = {
		app: appName,
		type: "findOne",
		collection,
		id,
		query: encodeURIComponent(JSON.stringify(query)),
		limit,
		skip,
		adminCallFromApp
	};

	return adminApiGetRequest(queryStringParameters);
}

/**
 * API MongoDB Find Request to get result from a param collection with a query param as an Object and a limit param as an Integer
 * if no query and limit param, then the query is {} and limit=500
 *
 * @param {String} collection Name of the collection in the DB to perform the query
 * @param {Object} queryP JSON of the filter to be applied on the resuadminGetResultslt (see Mongodb find documentation for more details)
 * @param {Number} limitP Maximum number of results expected
 */
export async function adminAddOneDocument(appName, collection, data, query = {}, limit = 500, skip = 0, adminCallFromApp = "") {
	const queryStringParameters = {
		app: appName,
		type: "insertOne",
		collection,
		data,
		query: encodeURIComponent(JSON.stringify(query)),
		limit,
		skip,
		adminCallFromApp
	};

	return adminApiPostRequest(queryStringParameters);
}

export async function adminUpdateOneDocument(appName, collection, id, data, query = {}, limit = 500, skip = 0, adminCallFromApp = "", isObjectId = true) {
	const queryStringParameters = {
		app: appName,
		type: "updateOne",
		collection,
		id,
		data,
		query: encodeURIComponent(JSON.stringify(query)),
		limit,
		skip,
		adminCallFromApp,
		isObjectId
	};

	return adminApiPutRequest(queryStringParameters);
}

export async function adminReplaceOneDocument(appName, collection, id, data, query = {}, limit = 500, skip = 0, adminCallFromApp = "", isObjectId = true) {
	const queryStringParameters = {
		app: appName,
		type: "replaceOne",
		collection,
		id,
		data,
		query: encodeURIComponent(JSON.stringify(query)),
		limit,
		skip,
		adminCallFromApp,
		isObjectId
	};

	return adminApiPutRequest(queryStringParameters);
}

export async function adminDeleteOneDocument(appName, collection, id, query = {}, limit = 500, skip = 0, adminCallFromApp = "", isObjectId = true) {
	const queryStringParameters = {
		app: appName,
		type: "deleteOne",
		collection,
		id,
		query: encodeURIComponent(JSON.stringify(query)),
		limit,
		skip,
		adminCallFromApp,
		isObjectId
	};

	return adminApiDelRequest(queryStringParameters);
}

export async function adminUpdateManyocument(appName, collection, id, data, query = {}, limit = 500, skip = 0, adminCallFromApp = "") {
	const queryStringParameters = {
		app: appName,
		type: "updateMany",
		collection,
		id: encodeURIComponent(JSON.stringify(id)),
		data,
		query: encodeURIComponent(JSON.stringify(query)),
		limit,
		skip,
		adminCallFromApp
	};

	return adminApiPutRequest(queryStringParameters);
}

export async function adminDeleteManyDocument(appName, collection, id, query = {}, limit = 500, skip = 0, adminCallFromApp = "", isObjectId = true) {
	const queryStringParameters = {
		app: appName,
		type: "deleteMany",
		collection,
		id: encodeURIComponent(JSON.stringify(id)),
		query: encodeURIComponent(JSON.stringify(query)),
		limit,
		skip,
		adminCallFromApp,
		isObjectId
	};

	return adminApiDelRequest(queryStringParameters);
}

/**
 * API MongoDB Find Request to get result from a param collection with a query param as an Object and a limit param as an Integer
 * if no query and limit param, then the query is {} and limit=500
 *
 * @param {String} collection Name of the collection in the DB to perform the query
 * @param {Object} queryP JSON of the filter to be applied on the result (see Mongodb find documentation for more details)
 * @param {Number} limitP Maximum number of results expected
 */
export async function adminGetResults(appName, collection, query = {}, limit = 500, skip = 0, adminCallFromApp = "") {
	const queryStringParameters = {
		app: appName,
		type: "find",
		collection,
		query,
		data: query,
		limit,
		skip,
		adminCallFromApp
	};

	return adminApiPostRequest(queryStringParameters);
}

/**
 * API MongoDB Aggregate Request to get result from a param collection with a query param as an Object and a limit param as an Integer
 * if no query and limit param, then the query is {} and limit=500
 *
 * @param {String} collection Name of the collection in the DB to perform the query
 * @param {Object} queryP JSON of the filter to be applied on the result (see Mongodb find documentation for more details)
 * @param {Number} limitP Maximum number of results expected
 */
export async function adminGetAggregation(appName, collection, query = {}, limit = 500, skip = 0, adminCallFromApp = "") {
	const queryStringParameters = {
		app: appName,
		type: "aggregate",
		collection,
		query,
		data: query,
		limit,
		skip,
		adminCallFromApp
	};

	return adminApiPostRequest(queryStringParameters);
}

/**
 * API MongoDB request to execute a pipeline
 *
 * @param {String} sourceCollection Name of the collection in the DB to perform the query
 * @param {String} targetCollection Name of the collection in the DB to persist the result of the pipeline execution
 * @param {Object} queryP JSON of the filter to be applied on the result (see Mongodb find documentation for more details)
 * @param {Number} limitP Maximum number of results expected
 */
export async function executePipeline(appName, sourceCollection, targetCollection, query = {}, limit = 10000, skip = 0, adminCallFromApp = "") {
	const queryStringParameters = {
		app: appName,
		type: "executePipeline",
		collection: sourceCollection,
		targetCollection,
		query: encodeURIComponent(JSON.stringify(query)),
		limit,
		skip,
		adminCallFromApp
	};

	return adminApiGetRequest(queryStringParameters);
}

/**
 * API MongoDB Find Request to get distinct value of a param field from a param collection
 *
 * @param {String} collection Name of the collection in the DB to perform the query
 * @param {String} facet Name of the field in the collection to query distinct value
 */
export async function adminGetFacet(appName, collection, facet, adminCallFromApp = "") {
	const queryStringParameters = {
		app: appName,
		type: "distinct",
		collection,
		facet,
		adminCallFromApp
	};

	return adminApiGetRequest(queryStringParameters);
}

//* *********************************************** Main ************************************************/

/**
 * Async function to execute the query to fetch a dataset
 *
 * @param {Object} dataset Datasets parameters to execute a query must have as keys
 * @param {String} dataset.collection Name of the collection
 * @param {String} dataset.fetch Type of fetch to execute :
 *                               - facet to get a list of distinct value of a field in a collection,
 *                               - find : to get all the value on a filter,
 *                               - aggregation : to get the results of an aggregation in the query parameter
 * @param {Object[]} dataset.query MongoDB Query to execute depending on the fetch
 * @param {Number} dataset.limit Limit of results to be returned - default : 500
 * @param {Object} activeRefines Active Refines of the state
 * @param {Boolean} resetSkip Reset Skip if refine
 */
export async function adminQueryDataset(appName, dataset, activeRefines, resetSkip = false) {
	// console.log("dataset", dataset);
	const datasetResponse = { ...dataset };
	const limit = dataset.limit ? dataset.limit : 500;
	const skip = dataset.skip && !resetSkip ? dataset.skip : 0;
	const applyRefines = dataset.facetsToRefine && dataset.facetsToRefine.length > 0
		? Object.keys(activeRefines)
		.filter((key) => dataset?.isSliderFilter
			? dataset.facetsToRefine.includes(key) && !dataset?.sliderFields.includes(key)
			: dataset.facetsToRefine.includes(key))
		// eslint-disable-next-line max-len
		.reduce((accumulator, key) => {
			let newKey;

			// refine on another key instead of active refine key if facetsToTransform is specified
			if (dataset?.facetsToTransform) {
				newKey = dataset.facetsToTransform?.[key];
			}

			if (newKey) return getRefineQuery(accumulator, newKey, activeRefines[key], dataset.isGroupFilter, dataset.groupFields);

			return getRefineQuery(accumulator, key, activeRefines[key], dataset.isGroupFilter, dataset.groupFields);
		}, {})
		: {};
	const findQuery = { ...applyRefines, ...dataset.query };

	try {
		switch (dataset.fetch) {
			case "findOne":
				datasetResponse.data = await adminRetrieveDocument(appName, dataset.collection, dataset.id, findQuery, limit, skip, dataset.adminCallFromApp);
				break;
			/* case "findAll":
				datasetResponse.data = await adminRetrieveAllDocument(appName, dataset.collection, findQuery, limit, skip);
				break; */
			case "insertOne":
				datasetResponse.data = await adminAddOneDocument(appName, dataset.collection, dataset.data, findQuery, limit, skip, dataset.adminCallFromApp);
				break;
			case "updateOne":
				datasetResponse.data = await adminUpdateOneDocument(appName, dataset.collection, dataset.id, dataset.data, findQuery, limit, skip, dataset.adminCallFromApp, dataset.isObjectId);
				break;
			case "updateMany":
				datasetResponse.data = await adminUpdateManyocument(appName, dataset.collection, dataset.id, dataset.data, findQuery, limit, skip, dataset.adminCallFromApp);
				break;
			case "replaceOne":
				datasetResponse.data = await adminReplaceOneDocument(appName, dataset.collection, dataset.id, dataset.data, findQuery, limit, skip, dataset.adminCallFromApp, dataset.isObjectId);
				break;
			case "deleteOne":
				datasetResponse.data = await adminDeleteOneDocument(appName, dataset.collection, dataset.id, findQuery, limit, skip, dataset.adminCallFromApp, dataset.isObjectId);
				break;
			case "deleteMany":
				datasetResponse.data = await adminDeleteManyDocument(appName, dataset.collection, dataset.id, findQuery, limit, skip, dataset.adminCallFromApp, dataset.isObjectId);
				break;
			case "facet":
				datasetResponse.data = await adminGetFacet(appName, dataset.collection, dataset.query, dataset.adminCallFromApp, dataset.adminCallFromApp);
				break;
			case "find":
				datasetResponse.data = await adminGetResults(appName, dataset.collection, findQuery, limit, skip, dataset.adminCallFromApp);
				break;
			case "aggregation":
				let AggregQuery = [{ $match: applyRefines }].concat(dataset.query);
				// console.log("***********start*************");
				// console.log("AggregQuery before", AggregQuery);
				// for facet transform or filter the value which is type object in mongoDB
				if (dataset.insertIndex) {
					const addtionalRefine = getAdditionalRefineQuery(activeRefines, dataset);
					// dataset.facetsToTransform
					AggregQuery = [...AggregQuery.slice(0, dataset.insertIndex), { $match: addtionalRefine }, ...AggregQuery.slice(dataset.insertIndex)];
				}
				// for slider filter, insert or concatenate it depends on whether sliderInsertIndex is defined
				if (dataset?.isSliderFilter) {
					AggregQuery = querySlider({
						AggregQuery, ...dataset, activeRefines
					});
				}
				// console.log("AggregQuery after", AggregQuery);
				// console.log("***********end*************");
				datasetResponse.data = await adminGetAggregation(appName, dataset.collection, AggregQuery, limit, skip, dataset.adminCallFromApp);
				break;
			case "executePipeline":
				datasetResponse.data = await executePipeline(appName, dataset.sourceCollection, dataset.targetCollection, dataset.query, limit, skip, dataset.adminCallFromApp);
				break;

			default:
				break;
		}

		return datasetResponse;
	} catch (error) {
		throw new Error(403);
	}
}

/**
 * API MongoDB Find the project which a particular user with the admin rights can access
 *
 * @param {String} appName Name of the application calling the api which is admin
 * @param {String} type operation desired in the api
 * @param {Number} limit Maximum number of results expected
 */
export async function adminMenu({
	appName, limit = 20, type = "getAdminMenu"
}) {
	const queryStringParameters = {
		app: appName,
		type,
		limit
	};
	return adminApiGetRequest(queryStringParameters);
}

export async function adminUpload(appName, dataset) {
	const {
		collection, data, adminCallFromApp = "", spaceInfo = {}
	} = dataset;

	const queryStringParameters = {
		app: appName,
		type: Object.keys(spaceInfo).length !== 0 ? "bulkWrite" : "bulkWriteNoSpacelabel",
		collection,
		data,
		adminCallFromApp,
		spaceInfo: encodeURIComponent(JSON.stringify(spaceInfo))
	};

	return adminApiPutRequest(queryStringParameters);
}

export async function adminPostProcessing({ appName, data, api }) {
	const queryStringParameters = {
		app: appName,
		type: "postprocessing",
		data
	};

	return adminApiPostRequest(queryStringParameters, api);
}

adminQueryDataset.propTypes = {
	appName: PropTypes.string,
	dataset: PropTypes.object,
	activeRefines: PropTypes.object,
	resetSkip: PropTypes.bool
};
