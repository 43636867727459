import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";
import { ParentSize } from "@visx/responsive";

import MonthAnalysis from "./MonthAnalysis";
import TitleCard from "../cards/TitleCard";

import SVGNoDataAvailable from "../../pages/SVGNoDataAvailable";

// To move to locales
const monthString = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec"
];

const quarterString = [
	"Q1",
	"Q2",
	"Q3",
	"Q4"
];

const getDefaultFrequency = (d) => d.frequency;

const TimeEvolutionCard = ({
	appSlice = null,
	dataset = undefined,
	dataset2 = undefined,
	noMonthlyAnalysis = false,
	theme = "clarity",
	locales,
	dotGroupKey = "",
	topInfoKey = "",
	refineKey = "equipmentCategory",
	year = "publicationYear",
	showTitle = true,
	width = 500,
	height = 250,
	margin = {
		top: 0, left: 0, right: 0, bottom: 50
	},
	showPoints = true,
	linesColor = ["#FF475C", "#FFC362", "#CCDB73"],
	tooltipOpen,
	tooltipLeft,
	tooltipTop,
	tooltipData,
	hideTooltip,
	showTooltip,
	fillCircleColor = "#CECECE",
	fillBarColor = "#CECECE",
	graphTop = 15,
	barsGroupTop = 0,
	barTopInfoTop = -7,
	barTopInfoLeft = -2,
	linesGroupTop = 0,
	linesAxisGroupTop = 0,
	yScaleDomainFactor = 1,
	colScaleDomainFactor = 2,
	getFrequency = getDefaultFrequency,
	isTrimestrial = false,
	legend = undefined
}) => {
	// conditionnal time accessors
	const timepoint = useCallback((d) => {
		if (isTrimestrial) {
			return d.quarter;
		}
		return d.month;
	}, [isTrimestrial]);

	const timekey = isTrimestrial ? "quarter" : "month";

	const timeString = isTrimestrial ? quarterString : monthString;
	// Internationalization hook
	const { t } = useTranslation(locales);

	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	// Load data from the store
	let graphData = useSelector(appSlice.selectDatasets)[dataset]?.data;
	let graphData2 = useSelector(appSlice.selectDatasets)[dataset2]?.data;

	if (graphData === undefined || graphData.length === 0) {
		return <SVGNoDataAvailable width={width} height={height} />;
	}

	// alignment in terms of axis x between two curves
	if (dataset2 && activeRefines[refineKey] && (graphData.length !== graphData2.length)) {
		const difference = graphData.length > graphData2.length
			? graphData?.filter((dot) => !graphData2.some((dot2) => timepoint(dot2) === timepoint(dot)))
			: graphData2?.filter((dot2) => !graphData.some((dot) => timepoint(dot) === timepoint(dot2)));

		const additionalInfo = difference?.map((item) => {
			const newRes = { ...item, [timekey]: timepoint(item), [dotGroupKey]: "0" };
			return newRes;
		});

		if (graphData.length > graphData2.length) graphData2 = [...graphData2, ...additionalInfo];
		if (graphData.length < graphData2.length) graphData = [...graphData, ...additionalInfo];
	}

	const sortedGraphData = [...graphData].sort((dotA, dotB) => dotA[`${year}`] > dotB[`${year}`]
		? 1
		: dotA[`${year}`] < dotB[`${year}`]
			? -1
			: timepoint(dotA) > timepoint(dotB)
				? 1
				: -1
	);

	const sortedGraphData2 = graphData2 && [...graphData2].sort((dotA, dotB) => dotA[`${year}`] > dotB[`${year}`]
		? 1
		: dotA[`${year}`] < dotB[`${year}`]
			? -1
			: timepoint(dotA) > timepoint(dotB)
				? 1
				: -1
	);

	const dotGroup = sortedGraphData?.reduce(
		(acc, currentValue, currentIndex) => {
			acc[currentIndex] = {
				[timekey]: `${timeString[timepoint(currentValue) - 1]}-${currentValue[`${year}`]
				?.toString()
				?.substring(2)}`,
				value: currentValue?.[`${dotGroupKey}`] > 0 ? currentValue?.[`${dotGroupKey}`].toFixed(1) : 0
			};
			return acc;
		},
		[{}]
	);

	const dotGroup2 = sortedGraphData2?.reduce(
		(acc, currentValue, currentIndex) => {
			acc[currentIndex] = {
				[timekey]: `${timeString[timepoint(currentValue) - 1]}-${currentValue[`${year}`]
				?.toString()
				?.substring(2)}`,
				value: currentValue?.[`${dotGroupKey}`] > 0 ? currentValue?.[`${dotGroupKey}`].toFixed(1) : 0
			};
			return acc;
		},
		[{}]
	);

	const barGroup = sortedGraphData.map((bar, i) => ({
		[timekey]: `${timeString[timepoint(bar) - 1]}-${bar[`${year}`]
		?.toString()
		?.substring(2)}`,
		frequency: getFrequency(bar),
		topInfo: bar?.[`${topInfoKey}`] ? bar?.[`${topInfoKey}`].toFixed(0) : null
	}));

	const linesNonConformities = dataset2 && activeRefines[refineKey] ? [dotGroup, dotGroup2] : [dotGroup];

	// TODO: verify more condition like  manufacturer:"Stainless Steel Impex"
	if (activeDatasets.listYear.data.length === 0) {
		return <SVGNoDataAvailable width={width} height={height}/>;
	}

	return (
		<div className="w-full">
			<ParentSize>
				{(parent) => (
					<>
						{!noMonthlyAnalysis && dotGroup.length > 1 && (
							<div className="flex flex-col">
								{showTitle && <TitleCard
									titleLocal="NCsComponent.graph.title"
									subTitleLocal=""
									xsmall
									theme={theme}
									infoLocal="NCsComponent.graph.info"
									locales={locales}
								/>}
								<MonthAnalysis
									appSlice={appSlice}
									linesNonConformities={linesNonConformities}
									lineNbSites={barGroup}
									width={width}
									barsGroupTop={barsGroupTop}
									fillBarColor={fillBarColor}
									graphTop={graphTop}
									linesAxisGroupTop={linesAxisGroupTop}
									yScaleDomainFactor={yScaleDomainFactor}
									colScaleDomainFactor={colScaleDomainFactor}
									height={height}
									margin={margin}
									showPoints={showPoints}
									linesColor={linesColor}
									tooltipOpen={tooltipOpen}
									tooltipLeft={tooltipLeft}
									tooltipTop={tooltipTop}
									tooltipData={tooltipData}
									hideTooltip={hideTooltip}
									showTooltip={showTooltip}
									fillCircleColor={fillCircleColor}
									barTopInfoTop={barTopInfoTop}
									barTopInfoLeft={barTopInfoLeft}
									linesGroupTop={linesGroupTop}
									isRounded={false}
									isTrimestrial={isTrimestrial}
									getX={(d) => isTrimestrial ? d.quarter : d.month}
									legend={legend}
								/>
							</div>
						)}
					</>
				)}
			</ParentSize>
		</div>
	);
};

TimeEvolutionCard.propTypes = {
	appSlice: PropTypes.object,
	barTopInfoLeft: PropTypes.number,
	barTopInfoTop: PropTypes.number,
	barsGroupTop: PropTypes.number,
	colScaleDomainFactor: PropTypes.number,
	dataset: PropTypes.string,
	dataset2: PropTypes.string,
	dotGroupKey: PropTypes.string.isRequired,
	fillBarColor: PropTypes.string,
	fillCircleColor: PropTypes.string,
	getFrequency: PropTypes.func,
	graphTop: PropTypes.number,
	height: PropTypes.number,
	hideTooltip: PropTypes.func,
	linesAxisGroupTop: PropTypes.number,
	linesColor: PropTypes.array,
	linesGroupTop: PropTypes.number,
	locales: PropTypes.string,
	margin: PropTypes.shape({
		bottom: PropTypes.number,
		left: PropTypes.number,
		right: PropTypes.number,
		top: PropTypes.number
	}),
	noMonthlyAnalysis: PropTypes.bool,
	refineKey: PropTypes.string,
	showPoints: PropTypes.bool,
	showTitle: PropTypes.bool,
	showTooltip: PropTypes.func,
	theme: PropTypes.string,
	tooltipData: PropTypes.any,
	tooltipLeft: PropTypes.number,
	tooltipOpen: PropTypes.bool,
	tooltipTop: PropTypes.number,
	topInfoKey: PropTypes.string,
	width: PropTypes.number,
	yScaleDomainFactor: PropTypes.number,
	year: PropTypes.string,
	isTrimestrial: PropTypes.bool,
	legend: PropTypes.array
};

export default TimeEvolutionCard;
