import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import LanguageIcon from "@mui/icons-material/Language";
import AssignmentIcon from "@mui/icons-material/Assignment";

import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function RYBFooter({ locales, setMobileMenu, appSlice }) {
	// Internationalization hook
	const { t, i18n } = useTranslation(locales);
	const dispatch = useDispatch();

	return (
		<>
			<div className="hidden xl:flex space-x-32">
				<div className="flex justify-between flex-col space-y-2 ">
					<p className="font-bold">{t("links.links")}</p>
					<a href="https://group.bureauveritas.com/" target="_blank" rel="noreferrer">
                                Bureau Veritas
					</a>
					<a href="https://group.bureauveritas.com/magazine/restart-your-business-bv" target="_blank" rel="noreferrer">
                                RestartYourBusinessWithBV
					</a>
					<a href="restartwithbv@bureauveritas.com" target="_blank" rel="noreferrer">
						{t("links.contact")}
					</a>
				</div>
				<div className="flex flex-col space-y-2">
					<p className="font-bold">{t("links.health")}</p>
					<a href="https://www.who.int/" target="_blank" rel="noreferrer">
                                World Health Organization
					</a>
					<a href="https://www.ilo.org/global/topics/coronavirus/lang--en/index.htm" target="_blank" rel="noreferrer">
                                International Labour Organization
					</a>
					<a href="https://www.ema.europa.eu/en/news/guidance-regulatory-requirements-context-covid-19-pandemic"
						target="_blank" rel="noreferrer">
                                European Medicines Agency
					</a>
				</div>
				<div className="flex flex-col flex-grow">
					<p className="font-bold">{t("links.followus")}</p>
					<div className="flex space-x-2 pt-3">
						<a href="https://www.linkedin.com/company/bureau-veritas-group" target="_blank" rel="noreferrer">
							<LinkedInIcon fontSize="large" />
						</a>
						<a href="https://twitter.com/bureauveritas" target="_blank" rel="noreferrer">
							<TwitterIcon fontSize="large" />
						</a>
					</div>
				</div>
				<div className="flex">
					<p className="font-bold">{t("links.by")}</p>
					<img src="/images/CompanyLogos/BVLogo-white.png" width="82px" />
				</div>
			</div>
			<div className="flex justify-between items-center text-xs  xl:ryb-border-t-1 py-1 xl:text-sm">
				<p className="flex items-center">
				© {new Date().getFullYear()} {t("footer.bv")}
					<span className="hidden pl-1 sm:inline">{t("footer.rights")}</span>
				</p>
				<div className="flex items-center space-x-2">
					<div className="flex items-center space-x-1">
						<LanguageIcon fontSize="inherit" />
						<p className={`cursor-pointer hover:underline${i18n.language === "en" ? " font-semibold text-ryb_primary-default" : ""}`}
							onClick={() => i18n.changeLanguage("en")}>EN</p>
						<p className={`cursor-pointer hover:underline${i18n.language === "fr" ? " font-semibold text-ryb_primary-default" : ""}`}
							onClick={() => i18n.changeLanguage("fr")}>FR</p>
						<p className={`cursor-pointer hover:underline${i18n.language === "es" ? " font-semibold text-ryb_primary-default" : ""}`}
							onClick={() => i18n.changeLanguage("es")}>ES</p>
						<p className={`cursor-pointer hover:underline${i18n.language === "pt" ? " font-semibold text-ryb_primary-default" : ""}`}
							onClick={() => i18n.changeLanguage("pt")}>PT</p>
					</div>
					<div className="flex items-center space-x-1">
							    <AssignmentIcon fontSize="inherit" />
						<p
							className="cursor-pointer hover:underline"
							onClick={() => { setMobileMenu(false); dispatch(appSlice.actions.setPage("TERMS")); }}
						>{t("footer.terms")}</p>
					</div>
				</div>
			</div>
		</>
	);
}

RYBFooter.propTypes = {
	locales: PropTypes.string,
	setMobileMenu: PropTypes.func,
	appSlice: PropTypes.object
};

export default RYBFooter;
