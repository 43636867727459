import React from "react";

const PrivateAppTerms = () => (
	<div className="text-sm text-tab-legalNotice-title">
		{/* header */}
		<p className="text-2xl font-medium">Terms of Use</p>
		<p className="text-gray-500 text-sm">(Last update: December, 2021)</p>

		<p className="mt-4">
			Any access to and use of the website&nbsp;
			<span className="font-medium">(the “Website”)</span> imply the user’s&nbsp;
			<span className="font-medium">(the “User”) </span>
			acceptance of the following terms of use. If access to the Website is
			granted to the User with a login and a password, these terms of use shall
			be governed by the relevant specific terms of use of the Website or by the
			relevant Bureau Veritas General Conditions of Service, accessible on the
			Website prior to the restricted access or upon first connection.
		</p>

		<p className="my-4">
			<span className="font-semibold">Website use: </span>
			The conditions of use of the Website
			 are described in the terms and conditions accepted by the User as part of the contractual
			 engagement with Bureau Veritas.
			Access to and use of the Website shall terminate in the event the User does not comply with these terms of use.</p>

		<p className="mt-4">
			<span className="font-semibold">Availability: </span>
			The Website functions 24/24 hours and 7/7 days. However, if a force
			majeure event occurs, the Website is overloaded or unavailable due to
			electronic communication networks, access to all or part of the Website
			may be slowed down or interrupted. Furthermore, Bureau Veritas may
			undertake corrective or preventive maintenance on the Website or hosting
			facilities. Accordingly, Bureau Veritas does not guarantee availability or
			continuous access to the Website.
		</p>

		<p className="mt-4">
			<span className="font-semibold">Trademarks: </span>
			The “Bureau Veritas” trademarks and logo or any other names or trademarks
			registered or otherwise under which Bureau Veritas or its affiliates carry
			on their businesses are the exclusive ownership of Bureau Veritas or its
			affiliates. Any use or reproduction by the User is strictly forbidden
			without the express prior written authorization of Bureau Veritas or its
			affiliates.
		</p>

		<p className="mt-4">
			<span className="font-semibold">Liability: </span>
			The Content accessible via the Website is provided “as is” by Bureau
			Veritas or its affiliates. Bureau Veritas or its affiliates as applicable,
			disclaim any warranties, whether express or implied, regarding the
			relevance, timeliness, completeness or the use of the information by the
			User for a particular purpose. The User is responsible for the use of the
			Content freely available on the Website.
		</p>

		<p className="mt-4">Bureau Veritas and its affiliates
			shall not be liable for any direct or indirect damages (including but not
			limited to any financial loss, commercial loss, loss of profits, loss of
			operation, loss of programs or loss of data), resulting from the use of
			the Website and the information contained therein. Bureau Veritas and its
			affiliates shall not be liable in the event of viruses, malware or spam
			resulting from the consultation of the Website.
		</p>

		<p className="mt-4">
			<span className="font-semibold">Hypertext links: </span>
			The Website may include hypertext links to third party websites. Bureau
			Veritas has no means of controlling the content of such third party
			websites redirecting to the Website and therefore declines all
			responsibility to their content.
		</p>

		<p className="mt-4">
			<span className="font-semibold">Applicable Jurisdiction and Law: </span>
			All disputes related to the Website and its Content shall be referred to
			the courts of Nanterre, France and governed by French law.
		</p>

	</div>
);

export default PrivateAppTerms;
