import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ArrayFieldEditor from "./ArrayFieldEditor";
import ObjectEditor from "./ObjectEditor";
import IF from "../../utils/IF";
import FieldTransformerController from "./FieldTransformerController";
import SelectFieldEditor from "./SelectFieldEditor";
import MultiSelectFieldEditor from "./MultiSelectFieldEditor";
import { typeSelector, currentFieldOptions, newBranchFirstNode } from "../../apps/configs/AdminConfig";
import { joinKeys } from "./FormUtils";

const customFieldBuilder = ({
	field, originFieldValue, targetDocument, activeRefines, setValue
}) => {
	const [lv1Field, lv2Field] = field.split(".");

	// for createBranch
	if (activeRefines?.supplyChainAction === "createBranch") {
		return targetDocument[lv1Field][lv2Field];
	}

	// for createNode
	if (activeRefines?.supplyChainAction === "createNode") {
		let fieldValue;
		switch (field) {
			case "supplyR.tierLabel":
				fieldValue = `Tier ${(parseInt(targetDocument.tierLevel, 10) + 2).toString(10)}`;
				setValue(field, fieldValue);
				break;
			default:
				fieldValue = newBranchFirstNode({ activeRefines })[lv1Field][lv2Field];
				setValue(field, fieldValue);
				break;
		}
		return fieldValue;
	}

	return originFieldValue;
};

const ObjectGroupEditor = ({
	field,
	fieldValue: originFieldValue,
	key: index,
	control,
	register,
	activeRefines,
	appSlice,
	getValues,
	setValue,
	requiredFields = [],
	errors = {},
	targetDocument
}) => {
	const activeDatasets = useSelector(appSlice.selectDatasets);
	let fieldValue;

	const fieldValueType = typeSelector([field, originFieldValue], activeRefines);

	if (fieldValueType === "array") {
		fieldValue = originFieldValue.map((item) => ({ k: item }));
	} else {
		fieldValue = customFieldBuilder({
			field, originFieldValue, targetDocument, activeRefines, setValue
		});
	}

	const flattenKeys = joinKeys({ obj: errors, keys: ["clarity", "supplyR"], depth: 2 });

	// get domains options for active and pushed indicators from clarityDomains collection
	const clarityDomains = activeDatasets?.clarityDomains.data;
	const domainsOptions = clarityDomains.map((domain) => {
		const formatedDomain = {
			value: domain.name,
			label: domain.name
		};
		return formatedDomain;
	}, {});

	return (
		<>
			<IF condition={fieldValueType === "array"}>
				<label
					key={field + index}
					className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							text-admin_primary-default border px-2 py-3 rounded-lg"
				>
					<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
						{`${requiredFields.includes(field) ? "*" : ""} ${field}`}
					</span>
					<ArrayFieldEditor
						control={control}
						field={field}
						register={register}
						activeRefines={activeRefines}
						requiredFields={requiredFields}
					/>
					{flattenKeys.includes(field) && <span className="text-red-600 text-sm">This field is required</span>}
				</label>
			</IF>

			<IF condition={fieldValueType === "object"}>
				<label
					key={field + index}
					className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							text-admin_primary-default border px-2 py-3 rounded-lg"
				>
					<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
						{`${requiredFields.includes(field) ? "*" : ""} ${field}`}
					</span>
					<ObjectEditor
						control={control}
						field={field}
						register={register}
						data={fieldValue}
						activeRefines={activeRefines}
						requiredFields={requiredFields}
					/>
					{flattenKeys.includes(field) && <span className="text-red-600 text-sm">This field is required</span>}
				</label>
			</IF>

			<IF condition={fieldValueType === "number"}>
				<label
					key={field + index}
					className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							 text-admin_primary-default border px-2 py-3 rounded-lg"
				>
					<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
						{`${requiredFields.includes(field) ? "*" : ""} ${field}`}
					</span>
					{/* transform str to num */}
					<FieldTransformerController
						transform={{
							input: (value) => Number.isNaN(value) || value === 0 ? "" : value?.toString(),
							output: (e) => {
								const output = parseInt(e.target.value, 10);
								return Number.isNaN(output) ? 0 : output;
							}
						}}
						control={control}
						name={`${field}`}
						defaultValue=""
						placeholder="enter a number"
						requiredFields={requiredFields}
					/>
					{flattenKeys.includes(field) && <span className="text-red-600 text-sm">This field is required</span>}
				</label>
			</IF>

			<IF condition={fieldValueType === "select"}>
				<label
					key={field + index}
					className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
								text-admin_primary-default border px-2 py-3 rounded-lg"
				>
					<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
						{`${requiredFields.includes(field) ? "*" : ""} ${field}`}
					</span>
					<SelectFieldEditor
						field={field}
						control={control}
						appSlice={appSlice}
						options={currentFieldOptions(field)}
						styles={{
							control: (base) => ({
								...base,
								border: "1px solid lightgray", // default border color
								"&:hover": { borderColor: "gray" }, // border style on hover
								boxShadow: "none",
								margin: "0.5rem",
								borderRadius: "0.5rem"
							})
						}}
						activeRefines={activeRefines}
						placeholder={getValues(field)}
						setValue={setValue}
						requiredFields={requiredFields}
						defaultValue={currentFieldOptions(field)?.find((e) => e?.default)}
					/>
					{flattenKeys.includes(field) && <span className="text-red-600 text-sm">This field is required</span>}
				</label>
			</IF>
			<IF condition={fieldValueType === "multiSelect"}>
				<label
					key={field + index}
					className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
								text-admin_primary-default border px-2 py-3 rounded-lg"
				>
					<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
						{`${requiredFields.includes(field) ? "*" : ""} ${field}`}
					</span>
					<MultiSelectFieldEditor
						field={field}
						control={control}
						appSlice={appSlice}
						options={domainsOptions}
						styles={{
							control: (base) => ({
								...base,
								border: "1px solid lightgray", // default border color
								"&:hover": { borderColor: "gray" }, // border style on hover
								boxShadow: "none",
								borderRadius: "0.5rem"
							}),
							multiValue: (styles) => ({
								...styles,
								borderRadius: "0.4rem",
								backgroundColor: "#deebff", // custom bgColor to align admin
								margin: "0.4rem"
							}),
							multiValueLabel: (styles) => ({
								...styles,
								color: "#1c3faa", // custom color to align admin
								paddingTop: "0.3rem"
							})
						}}
						activeRefines={activeRefines}
						getValues={getValues}
						requiredFields={requiredFields}
						defaultValue={getValues(field)}
					/>
					{flattenKeys.includes(field) && <span className="text-red-600 text-sm">This field is required</span>}
				</label>
			</IF>

			<IF
				condition={
					fieldValueType !== "array" &&
					fieldValueType !== "object" &&
					fieldValueType !== "objectGroup" &&
					fieldValueType !== "number" &&
					fieldValueType !== "select" &&
					fieldValueType !== "multiSelect" &&
					fieldValueType !== "textarea"
				}
			>
				<label
					key={field + index}
					className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
											text-admin_primary-default border px-2 py-3 rounded-lg"
				>
					<span
						className="h-min p-0.5 absolute -top-3 bg-white
												uppercase text-sm flex justify-center items-center"
					>
						{`${requiredFields.includes(field) ? "*" : ""} ${field}`}
					</span>
					<input
						{...register(`${field}`, { required: requiredFields?.includes(field) })}
						className="p-2 my-2 text-black border rounded-lg bg-white focus:outline-none focus:ring-2
									focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"
					/>
					{flattenKeys.includes(field) && <span className="text-red-600 text-sm">This field is required</span>}
				</label>
			</IF>
		</>
	);
};

ObjectGroupEditor.propTypes = {
	activeRefines: PropTypes.object,
	appSlice: PropTypes.object,
	control: PropTypes.func,
	field: PropTypes.string,
	fieldValue: PropTypes.string,
	getValues: PropTypes.func,
	key: PropTypes.string,
	register: PropTypes.func,
	setValue: PropTypes.func,
	requiredFields: PropTypes.array,
	errors: PropTypes.object,
	targetDocument: PropTypes.object
};

export default ObjectGroupEditor;
