import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import BusinessIcon from "@mui/icons-material/Business";
import { useTranslation } from "react-i18next";
import { appDefinition, filterOptions } from "../../apps/configs/ChargeScanConfig";

import SwitchSelector from "../filters/SwitchSelector";

const ChargeScanMobileFilterHeader = ({ appSlice }) => {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	const dispatch = useDispatch();

	const isActiveFilter = useSelector(appSlice.isRefined);

	return <header className="relative flex items-center justify-between w-full bg-white z-20 ">

		<div className="absolute top-0 -mt-8 left-0 -ml-4 w-4/5 h-24 overflow-hidden" >
			<img onError={(ev) => ev.target.src = "/images/SupplyR/Markets/default.jpg" }
				src={"/images/ChargeScan/ChargeScanBg.png"} alt="Market Img"
				className="rounded-b-full" style={{ zIndex: -1 }}/>
		</div>

		<div className="flex flex-col justify-center space-y-2 w-full z-20">
			<div className="h-10 w-48">
				<SwitchSelector
					onChange={(value) => {
						dispatch(appSlice.actions.clear(filterOptions.filter((option) => option.clear)));
						dispatch(appSlice.actions.refine([{ key: "type", value }]));
					}}
					options={
						[
							{
								label: "Construction",
								value: "construction",
								selectedBackgroundColor: "#fff",
								selectedfontcolor: "#0092D1"
							},
							{
								label: "Operation",
								value: "operation",
								selectedBackgroundColor: "#fff",
								selectedfontcolor: "#0092D1"
							}
						]
					}
					initialSelectedIndex={"construction"}
					backgroundColor={"#eee"}
					selectedfontcolor={"#0092D1"}
					selectionIndicatorMargin={3}
					wrapperBorderRadius={14}
				/>
			</div>
		</div>
	</header>;
};

ChargeScanMobileFilterHeader.propTypes = {
	appSlice: PropTypes.object
};

export default ChargeScanMobileFilterHeader;
