import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import SwitchSelector from "../filters/SwitchSelector";
import { breadcrumbsOptions, thirdLevelMenuClearRefineKeys, fourthLevelMenuClearRefineKeys } from "../../apps/configs/AdminConfig";
import IF from "../../utils/IF";

const GlobalFilter = ({
	placeHolder = "Search..."
}) => {
	const [value, setValue] = useState();
	const onChange = (value) => {
		setValue(value || undefined);
	};

	return (
		<div className="hidden sm:flex justify-between items-center border py-4 px-2 md:px-6 space-x-4 rounded-xl h-10">
			<input
				value={value || ""}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				placeholder={placeHolder}
				className="bg-transparent outline-none "
			/>
			<SearchIcon />
		</div>
	);
};

GlobalFilter.propTypes = {
	placeHolder: PropTypes.string
};

const AdminHeader = ({
	appSlice, children, renderNav = true, renderFilter = true, renderSwitch = false, clearRefineKeys = [],
	switchStatus, switchFunc, switchLabelLeft, switchLabelRight, renderMapBtn = false, chainRefineKey = ""
}) => {
	const dispatch = useDispatch();

	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const rootPage = useSelector(appSlice.selectPage);

	return (
		<div className="flex flex-col w-full">
			<div className="flex flex-row justify-between items-center bg-white rounded-tl-3xl rounded-tr-3xl px-4 ml-4 border-b-2">

				{
					renderNav
					&& <nav
						className="text-sm text-left text-admin_primary-default
                         bg-opacity-10 h-18 flex items-center rounded-md"
					>
						<ol className="list-reset flex text-admin_primary-default">
							<li>
								<a href="#" className="flex flex-row justify-center items-center text-small sm:text-base font-bold uppercase">
									<FolderOpenIcon className="text-small sm:text-base mr-1"/>  {rootPage}
								</a>
							</li>
							<li>
								<span className="mx-2">/</span>
							</li>
							<li onClick={() => dispatch(appSlice.actions.clear(thirdLevelMenuClearRefineKeys))}>
								<a href="#" className="flex flex-row justify-center items-center text-small sm:text-base font-bold uppercase">
									<FolderOpenIcon className="text-small sm:text-base mr-1"/>  {breadcrumbsOptions[activeRefines?.secLevelMenu]
									?? activeRefines?.secLevelMenu}
								</a>
							</li>
							{/* {activeRefines?.[chainRefineKey] && <>
								<li>
									<span className="mx-2">/</span>
								</li>
								<li>
									<a href="#" className="flex flex-row justify-center items-center text-small sm:text-base font-bold uppercase">
										<FolderOpenIcon className="text-small sm:text-base mr-1"/>  {activeRefines?.[chainRefineKey]}
									</a>
								</li>
							</>} */}
							{activeRefines?.thirdLevelMenu && <>
								<li>
									<span className="mx-2">/</span>
								</li>
								<li onClick={() => dispatch(appSlice.actions.clear(thirdLevelMenuClearRefineKeys))}>
									<a href="#" className="flex flex-row justify-center items-center text-small sm:text-base font-bold uppercase">
										<FolderOpenIcon className="text-small sm:text-base mr-1"/>  {activeRefines?.thirdLevelMenu}
									</a>
								</li>
							</>}
							{activeRefines?.fourthLevelMenu && <>
								<li>
									<span className="mx-2">/</span>
								</li>
								<li onClick={() => dispatch(appSlice.actions.clear(fourthLevelMenuClearRefineKeys))}>
									<a href="#" className="flex flex-row justify-center items-center text-small sm:text-base font-bold uppercase">
										<FolderOpenIcon className="text-small sm:text-base mr-1"/>  {activeRefines?.fourthLevelMenu}
									</a>
								</li>
							</>}
							{activeRefines?.[chainRefineKey] && <>
								<li>
									<span className="mx-2">/</span>
								</li>
								<li>
									<a href="#" className="flex flex-row justify-center items-center text-small sm:text-base font-bold uppercase">
										<FolderOpenIcon className="text-small sm:text-base mr-1"/>  {activeRefines?.[chainRefineKey]}
									</a>
								</li>
							</>}
						</ol>
					</nav>
				}
				<div className="flex flex-row space-x-2">
					{renderMapBtn && <button className="hover:bg-admin_primary-default hover:text-white uppercase
					text-small sm:text-base font-medium px-4 border rounded-xl"
					onClick={() => dispatch(appSlice.actions.refine([{ key: "mapView", value: !activeRefines.mapView }]))}>Map</button>}
					{
						renderSwitch
						&& <div className="h-7 sm:h-10 w-30 sm:w-48 relative ml-4">
							<SwitchSelector
								onChange={(value) => {
									switchFunc(value);
									if (activeRefines.mapView) {
										dispatch(appSlice.actions.refine([{ key: "mapView", value: false }]));
									}
								}}
								options={
									[
										{
											label: switchLabelLeft,
											value: false,
											selectedBackgroundColor: "#fff",
											selectedfontcolor: "#b2b2b2"
										},
										{
											label: switchLabelRight,
											value: true,
											selectedBackgroundColor: "#fff",
											selectedfontcolor: "#b2b2b2"
										}
									]
								}
								initialSelectedIndex={switchStatus ? 1 : 0}
								backgroundColor={"#eee"}
								selectedfontcolor={"#b2b2b2"}
								selectionIndicatorMargin={3}
								wrapperBorderRadius={14}
							/>
						</div>
					}
					<div className="w-full h-18 hidden"></div>
					{renderFilter && <GlobalFilter />}
				</div>
			</div>

			{children}
		</div>
	);
};

AdminHeader.propTypes = {
	appSlice: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	renderFilter: PropTypes.bool,
	renderNav: PropTypes.bool,
	renderSwitch: PropTypes.bool,
	switchStatus: PropTypes.bool,
	switchFunc: PropTypes.func,
	clearRefineKeys: PropTypes.array,
	switchLabelLeft: PropTypes.string,
	switchLabelRight: PropTypes.string,
	renderMapBtn: PropTypes.bool,
	chainRefineKey: PropTypes.string
};

export default AdminHeader;
