import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Moment from "react-moment";
import PropTypes from "prop-types";

import PhoneIcon from "@mui/icons-material/Phone";
import LaunchIcon from "@mui/icons-material/Launch";

import { getAggregation } from "../../api/client";
import IF from "../../utils/IF";

import {
	appDefinition, mapTooltipQuery
} from "../../apps/configs/RYBConfig";

function RYBTooltip({
	marker, colorMarker, isOpenPopup = true
}) {
	const { t } = useTranslation(appDefinition.locales);
	const [tooltipData, setTooltipData] = useState(undefined);

	useEffect(() => {
		if (!isOpenPopup) {
			return null;
		}
		const aggregation = mapTooltipQuery(marker.siteTechnicalId);
		getAggregation("ryb", aggregation.collection, aggregation.query, 1, 0, true).then((value) => setTooltipData(value));
	}, [marker.siteTechnicalId, isOpenPopup]);

	// Regex literal that checks if string is prefixed with http(s)://
	const httpRegex = /^https?:\/\//;
	return (
		<div className="flex flex-col w-56 -my-4 -m-5 rounded-xl bg-white">
			{tooltipData && tooltipData.length === 1 ? <>
				<div className="px-3 -mb-1 w-full">
					<IF condition={tooltipData[0].status === "OnGoing"}>
						<p className="self-center text-center rounded bg-gray-500 font-bold text-white p-1">{t("map.tooltip.ongoing")}</p>
					</IF>
					<IF condition={tooltipData[0].status !== "OnGoing"}>
						<div className="flex py-2">
							<img src={`/images/RYB/Labels/${tooltipData[0].label}.png`}
								alt={tooltipData[0].label} className="h-20 mr-2 self-center"/>
							<div className="flex flex-col justify-between space-y-1 border-l-1 px-2 flex-grow tooltip-small">
								<div className="text-ryb_primary-bg font-semibold
                                                        self-center border border-1 border-gray-200 px-2 py-1 rounded">
									<IF condition={tooltipData[0].stage !== "None" && tooltipData[0].stage !== "nc"}>
										{t(`map.tooltip.${tooltipData[0].stage}`)}
									</IF>
									<IF condition={tooltipData[0].stage === "None" || tooltipData[0].stage === "nc"}>
										{t("map.tooltip.compliant")}</IF>
								</div>
								{/*
								<div className="pt-1">
									<span className="text-ryb_primary-bg font-semibold">{t("map.tooltip.audit")}:</span>
									<Moment format="YYYY/MM/DD">{tooltipData[0].publicationDateTime}</Moment>
								</div> */}
								<IF condition={tooltipData[0].validityDate}>
									<div className="pb-1 self-center">
										<div className="text-ryb_primary-bg font-semibold">{t("map.tooltip.valid")}:</div>
										<Moment format="YYYY/MM/DD">{tooltipData[0].validityDate}</Moment>
									</div>
								</IF>
								<div className="text-white w-28  self-center text-center px-2 py-1 rounded"
									style={{ backgroundColor: colorMarker }}>
									{tooltipData[0].businessSegment}
								</div>
							</div>
						</div>
					</IF>
				</div>

				<div className="flex flex-col space-y-0 bg-ryb_primary-bg text-white rounded-b-xl py-2 px-3">
					<div className="font-semibold leading-3 pb-1">{tooltipData[0].site}</div>
					<div className="font-light tooltip-small pb-2">
						{tooltipData[0].siteAddress ? (`${tooltipData[0].siteAddress} -`) : ""}
						{tooltipData[0].siteCity ? (`${tooltipData[0].siteCity} - `) : ""}
						{tooltipData[0].siteCountry ? tooltipData[0].siteCountry : ""}
					</div>
					<IF condition={tooltipData[0].publicPhone && tooltipData[0].publicPhone !== "nc"}>
						<div className="pb-2 tooltip-small"><PhoneIcon fontSize="inherit" />{tooltipData[0].publicPhone}</div>
					</IF>
					<IF condition={tooltipData[0].publicWebSite && tooltipData[0].publicWebSite !== "nc"}>
						<a href={(tooltipData[0].publicWebSite !== null && tooltipData[0].publicWebSite.match(httpRegex))
							? tooltipData[0].publicWebSite
							: `https://${tooltipData[0].publicWebSite}`}
						className="flex items-center self-center justify-center w-32
                                    bg-ryb_primary-default cursor-pointer text-white rounded p-2"
						target="_blank" rel="noreferrer" >
							{t("map.tooltip.website")}<LaunchIcon fontSize="inherit" className="ml-1"/>
						</a>
					</IF>
				</div>
			</> : null}
		</div>
	);
}

RYBTooltip.propTypes = {
	marker: PropTypes.object,
	colorMarker: PropTypes.string,
	isOpenPopup: PropTypes.bool
};

export default RYBTooltip;
