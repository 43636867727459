import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const defaultStructure = [
	{ headerLocale: "SectionLvl1", accessor: (d) => d.section1label },
	{ headerLocale: "SectionLvl2", accessor: (d) => d.section2label },
	{ headerLocale: "SectionLvl3", accessor: (d) => d.section3label }
];

const defaultAlert = { headerLocale: "#Alerts", accessor: (d) => d.alert };

const SectionsTable = ({
	appSlice, dataset, structureSections = defaultStructure, alert = defaultAlert, removeNoAlertLines = false, locales
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();
	const activeDatasets = useSelector(appSlice.selectDatasets);

	if (structureSections.length < 1) {
		return null;
	}

	// Load data from the store
	const sectionAlertListUnsorted = activeDatasets?.[dataset]?.data;
	const sectionAlertList = [...sectionAlertListUnsorted]
	?.sort((a, b) => {
		const { accessor } = (structureSections?.[0] ?? {});
		return ((accessor(a) > accessor(b)) ? 1 : -1);
	});

	return (
		<table className="table-auto w-full rounded-r">
			<thead>
				<tr className="bg-chargescan_primary-default text-white h-12 text-left text-xs md:text-base">
					{structureSections?.map((element, i) => (
						<th key={`header ${i}`} className={`pl-5 ${i === 0 && "rounded-tl-xl "}`} >
							<p>{t(element.headerLocale)}</p>
						</th>
					))}
					<th className="rounded-tr-xl text-center px-5" >
						<p>{alert.headerLocale}</p>
					</th>
				</tr>
			</thead>
			<tbody>
				{sectionAlertList?.map((section, i) => {
					if (removeNoAlertLines && alert.accessor(section) === 0) {
						return <></>;
					}
					// TODO: dispatch action refine something in the future
					return (
						<tr key={`chargingStation${i}`} className="text-left border-b text-chargescan_primary-default
							border-chargescan_primary-default last:rounded-b-xl last:border-b-0 h-8 text-xs md:text-sm"
						onClick={() => { dispatch(appSlice.actions.setPage("NCs")); }}>
							{structureSections?.map((element, j) => {
								const sectionLabel = element.accessor(section);
								return (
									<td key={`rowAlertSection ${j}`} className="pl-5">
										{(!sectionLabel || sectionLabel === "na") ? "" : sectionLabel}
									</td>
								);
							})}
							<td className={`${alert.accessor(section) > 0 && "text-red-500 font-semibold"} text-center cursor-pointer`}>
								{alert.accessor(section)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

SectionsTable.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	structureSections: PropTypes.array,
	alert: PropTypes.object,
	removeNoAlertLines: PropTypes.bool,
	locales: PropTypes.string
};

export default SectionsTable;
