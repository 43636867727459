import React from "react";

const RYBPrivacy = () => (
	<div className="text-sm">
		<p className="text-tab-legalNotice-title text-2xl font-medium">
				BUREAU VERITAS GROUP PERSONAL DATA PROTECTION POLICY FOR USERS
		</p>
		<p className="text-gray-500 text-sm">(Last update: May 18th, 2021)</p>
		<p className="mt-4">
				Bureau Veritas (hereinafter &ldquo;Bureau Veritas&rdquo;,
				&ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo;), recognizes the
				importance of effective and meaningful Personal Data protections when it
				collects and uses the Personal Data of its Users (as defined below).
		</p>
		<p className="mt-4">
				We place great value on integrity and we are committed to building
				strong and lasting relationships with you based on trust and mutual
				benefit. Privacy protection is essential to us. This Personal Data
				Protection Policy expresses the strong commitment of the Bureau Veritas
				Group to respect and protect Personal Data of every individual and to
				ensure international compliance with data protection laws.
		</p>
		<p className="mt-4">
				This Personal Data Protection Policy covers all Personal Data collected
				and used by Bureau Veritas worldwide.
		</p>
		<p className="mt-4">
				This Personal Data Protection Policy is intended to inform you about
				what types of Personal Data we might collect or hold about you, how we
				use it, who we share it with, how we protect it and keep it secure, and
				your rights about your Personal Data.
		</p>
		<p className="mt-4">
				Please note that we may update this Personal Data Protection Policy at
				any time to adapt it to potential new practices and service offerings.
				In such case, we will change the &ldquo;last update&rdquo; date and we
				will indicate the date on which the changes have been made. This
				Personal Data Protection Policy is attached to the agreements we have
				entered into with our customers, service providers or partners. It is
				also available on Bureau Veritas websites and solutions. We encourage
				you to review regularly the potential updates of this Personal Data
				Protection Policy available on{" "}
			<a
				className="hover:underline"
				href="https://personaldataprotection.bureauveritas.com/privacypolicy"
			>
					https://personaldataprotection.bureauveritas.com/privacypolicy
			</a>
		</p>
		<p className="mt-4">
				&ldquo;Personal data&rdquo; means any information or pieces of
				information that could identify you either directly or indirectly. This
				means that Personal Data includes things like email/home addresses,
				usernames, user generated content, financial information, IP address,
				etc.
		</p>
		<p className="mt-4">
				&ldquo;User(s)&rdquo; means any prospects, customers, service providers,
				partners, subcontractors, candidates and more generally anyone who is in
				contact with us. (hereinafter &ldquo;you&rdquo; or &ldquo;your&rdquo;).
		</p>
		<p className="mt-4">
				&ldquo;Processing&rdquo; means any operation or set of operations which
				is performed on Personal Data or on sets of Personal Data, whether or
				not by automated means, such as collection, recording, organisation,
				structuring, storage, adaptation or alteration, retrieval, consultation,
				use, disclosure by transmission, dissemination or otherwise making
				available, alignment or combination, restriction, erasure or
				destruction.
		</p>
		<p className="font-medium mt-4">
				1. What are the principles for processing Personal Data?
		</p>
		<p className="mt-4">
				Each Bureau Veritas legal entity that collects or uses your Personal
				Data for its business needs acts as the &ldquo;data controller&rdquo;.
				According to data protection laws, it must determine the purposes and
				the means of the processing of your Personal Data. This means we have
				responsibility for the Personal Data that you share with us
		</p>
		<p className="mt-4">
				We process your Personal Data in compliance with the applicable laws and
				regulations and, in particular, the European General Data Protection
				Regulation 2016 / 679(&ldquo;GDPR&rdquo;) dated on April 27, 2016 the Lei Geral de
				Proteção de Dados in Brazil(&ldquo;LGPD&rdquo;) of 2018, the South Africa’ s Protection
				of Personal Information Act(POPIA Act) of 2013, the Australian Privacy Act of
				1988, the Cybersecurity Law of the People’s Republic of China of 2016, the
				Chinese Personal Information Protection Law of 2020, etc.
		</p>
		<p className="mt-4">In particular, we undertake to:</p>
		<ul className="list-disc mt-4 ml-10">
			<li>Obtain and process your Personal Data fairly and lawfully;</li>
			<li>
					Obtain your Personal data for specified, explicit and legitimate
					purposes, and not process it subsequently in a manner that is
					incompatible with those purposes;
			</li>
			<li>
					Process only Personal Data that is adequate, relevant and not
					excessive in relation to the purposes for which it is obtained and its
					further processing;
			</li>
			<li>
					Ensure that your Personal Data is accurate, complete and, where
					necessary, kept up-to-date;
			</li>
			<li>
					Store your Personal Data for a period no longer than is necessary for
					the purposes for which it is obtained and processed and in accordance
					with applicable legislation and statute of limitations.
			</li>
		</ul>
		<p className="font-medium mt-4">
				2. What Personal Data do we collect, why and how do we use it?
		</p>
		<p className="mt-4">
				In order for you to have the clearest view on how we use your Personal
				Data, we have imagined a table where you can find information by
				searching with the context/purposes of the data collection.
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
					1) Column 1 - In what scenario is your data collected? This column
					explains what activity or scenario you are involved in when we use or
					collect your Personal Data.
			</li>
			<li>
					2) Column 2 - What types of Personal Data may we hold about you? This
					column explains what types of Personal Data we collect.
			</li>
			<li>
					3) Column 3 - How and why we use it? This column explains what we do
					with your Personal Data, and the purposes for collecting it.
			</li>
			<li>
					4) Column 4 - What is the legal basis for using your Personal Data?
					This column explains the reason why we may use your Personal Data.
			</li>
		</ul>
		<p className="mt-4">
				When we collect data through forms including electronic forms, we will
				indicate the mandatory fields via asterisks. Failure to provide the data
				marked with an asterisk could prevent you from accessing to a service.
		</p>
		<p className="mt-4">
				As a User of our services and our websites, there are many ways that you
				might share your Personal Data with us, and that we might collect it.
		</p>
		<p className="mt-4">
				We might collect or receive Personal Data from you via our websites,
				forms, services, or otherwise. Sometimes you will provide your Personal
				Data to us directly (e.g. when you contact us via our websites, when you
				ask for a quote estimate, when you subscribe to one of our services,
				sometimes we collect it indirectly (e.g. using cookies to understand how
				you use our websites) or sometimes we receive your data from other third
				parties, including other Bureau Veritas entities.
		</p>
		<table className="table-auto alphaBlur w-full my-4">
			<thead className="border-b-2">
				<tr className="bg-gray-100">
					<th className="w-1/4 font-normal px-2 py-2">
							In which context is your Personal Data collected?
					</th>
					<th className="w-1/4 font-normal px-2 py-2">
							What types of Personal Data may we hold about you?
					</th>
					<th className="w-1/4 font-normal px-2 py-2">
							How and why we may use it?
					</th>
					<th className="w-1/4 font-normal px-2 py-2">
							What is our legal basis for processing your Personal Data?
					</th>
				</tr>
			</thead>
			<tbody className="divide-y-2">
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<p className="font-semibold">Subscription to a service</p>
							Information collected during the subscription to one of our
							services
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Name and surname</li>
							<li>Email address</li>
							<li>Postal address (invoicing)</li>
							<li>Phone number</li>
							<li>
									Transaction information (details regarding the service
									subscribed, transaction number, services history, etc.)
							</li>
							<li>Payment information</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">To:</span>
						<ul className="list-disc pl-7">
							<li>Send you a quote estimate</li>
							<li>Process and follow your subscription</li>
							<li>Manage the payment of your subscription</li>
							<li>
									Manage any contact you have with us regarding your
									subscription
							</li>
							<li>Manage any dispute relating to a subscription</li>
							<li>Run statistics</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">
										Performance of a contract:&nbsp;
								</span>
									To provide you with the service you requested (subscription)
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<p className="font-semibold">Provision of a service</p>
							Information collected during the provision of the services you
							have subscribed to
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Name and surname</li>
							<li>Email address</li>
							<li>Phone number</li>
							<li>
									Data relating to the commercial relationship: details
									regarding the service subscribed, the duration, correspondence
									with the client, etc.)
							</li>
							<li>
									Identification data allowing access to some of our platforms (e.g.Building in One)
							</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">To:</span>
						<ul className="list-disc pl-7">
							<li>Provide you with the service requested</li>
							<li>
									Allow you to access and use the Building in One Platform when
									such service is requested
							</li>
							<li>
									Manage any contact you have with us during the provision of
									the service
							</li>
							<li>Manage the commercial relationship with you</li>
							<li>Manage any request or dispute relating to a service</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">
										Performance of a contract:&nbsp;
								</span>
									To provide you with the service you requested (subscription)
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<p className="font-semibold">
								Conclusion of a specific agreement
						</p>
							Information collected when you provide us with a product or
							service
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Name and surname</li>
							<li>Email address</li>
							<li>Postal address</li>
							<li>Function</li>
							<li>Company name</li>
							<li>Phone number</li>
							<li>Billing data</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">To:</span>
						<ul className="list-disc pl-7">
							<li>Create and manage supplier/vendor files</li>
							<li>
									Manage contracts, orders, deliveries, invoices and accountings
							</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">
										Performance of a contract:&nbsp;
								</span>
									To manage the contractual relationship with you
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<span className="font-semibold">
								Newsletter and commercial communications subscription
						</span>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Name and surname</li>
							<li>Email address</li>
							<li>Function</li>
							<li>Company name</li>
							<li>Phone number</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">To:</span>
						<ul className="list-disc pl-7">
							<li>
									Send you marketing communications (where you have asked us to)
							</li>
							<li>
									Keep an up to date suppression list if you have asked not to
									be contacted
							</li>
							<li>Run analytics or collect statistics</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">Consent:</span>&nbsp;No direct
									marketing communication is sent to you if you have not
									consented to receive it
							</li>
							<li>
								<span className="font-semibold">
										Legitimate interest:&nbsp;
								</span>
									To tailor our marketing communications and understand their
									effectiveness, to help us better understand your needs and
									improve our services, to develop our business
							</li>
							<li>
								<span className="font-semibold">
										Compliance with a legal obligation:&nbsp;
								</span>
									To keep your details on a suppression list if you have asked
									us not to send you any direct marketing anymore
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<span className="font-semibold">Online browsing</span>
						<br />
							Information collected by cookies or similar technologies
							(&ldquo;Cookies&rdquo;*) as part of your browsing on Bureau
							Veritas websites.
						<br />
						<br />* Cookies are small text files stored on your device
							(computer, tablet or mobile) when you are on the Internet.
					</td>
					<td className="w-1/4 pt-8 pb-5 align-top">
							Data related to your use of our website:
						<ul className="list-disc pl-10 py-3">
							<li>Where you came from</li>
							<li>Pages you looked at</li>
							<li>Duration of your visit</li>
						</ul>
							Technical information:
						<ul className="list-disc pl-10 py-3">
							<li>IP address</li>
							<li>browser information</li>
							<li>device information</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">
								To tailor our services for you:
						</span>
						<ul className="list-disc pl-7 my-4">
							<li>
									to send you recommendations or marketing communications based
									on your interests
							</li>
							<li>to display our website in a tailored way</li>
						</ul>
						<span className="font-semibold">
								To allow proper functioning of our website:
						</span>
						<ul className="list-disc pl-7 my-4">
							<li>proper display of the content,</li>
							<li>interface personalisation such as language</li>
							<li>
									parameters attached to your device including your screen
									resolution
							</li>
							<li>
									improvement of our website for example, by testing new ideas.
							</li>
						</ul>
						<span className="font-semibold">
								To ensure the website is secure and safe and protect you against
								fraud or misuse of our websites or services:
						</span>
						<br />
						<br />
						<span className="font-semibold">To run statistics:</span>
						<ul className="list-disc pl-7 my-4">
							<li>To improve our offers</li>
							<li>To know how you discovered our website</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">
										Legitimate Interest:&nbsp;
								</span>
									To ensure we are providing you with websites and
									communications that are working properly, are safe and secure
									and are continually improving, to develop our business
							</li>
							<li>
								<span className="font-semibold">Consent:&nbsp;</span>For
									cookies that are not necessary for the functioning of our
									website
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<p className="font-semibold">Enquiries</p>
							Information collected when you ask questions relating to our
							services
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Name and surname</li>
							<li>Email address</li>
							<li>Function</li>
							<li>Company name</li>
							<li>Phone number</li>
							<li>
									Other information you have shared with us regarding your
									enquiry
							</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">To:</span>
						<ul className="list-disc pl-7">
							<li>To answer your enquiries</li>
							<li>Where needed, to connect you with the relevant services</li>
							<li>For statistics purposes</li>
							<li>To send you quote estimates when you ask for it</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">Consent:&nbsp;</span>To
									process your enquiry
							</li>
							<li>
								<span className="font-semibold">
										Performance of a contract:&nbsp;
								</span>
									To provide you with the information requested in the context
									of the contract between you and Bureau Veritas
							</li>
							<li>
								<span className="font-semibold">
										Legitimate Interest:&nbsp;
								</span>
									To help us better understand our Users&rsquo; needs and
									expectations and therefore improve our services
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="w-1/4 px-3 py-3 align-top">
						<p className="font-semibold">Application for a job</p>
							Information collected when you apply for a job in Bureau Veritas
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>Identification information</li>
							<li>Contact details</li>
							<li>Resume (qualifications, previous jobs, etc.);</li>
							<li>Interview notes</li>
						</ul>
					</td>
					<td className="w-1/4 px-3 pt-3 pb-5 align-top">
						<span className="font-semibold">To:</span>
						<ul className="list-disc pl-7">
							<li>Review candidates&rsquo; applications</li>
							<li>Manage the recruitment processes</li>
							<li>Hire candidates</li>
						</ul>
					</td>
					<td className="w-1/4 pl-5 pt-8 pb-5 align-top">
						<ul className="list-disc">
							<li>
								<span className="font-semibold">Consent:&nbsp;</span>To
									process your application
							</li>
							<li>
								<span className="font-semibold">
										Legitimate Interest:&nbsp;
								</span>
									To find the best candidates for our job offers
							</li>
						</ul>
					</td>
				</tr>
			</tbody>
		</table>
		<p className="mt-4">
			Bureau Veritas does not process your Personal Data
			for secondary purposes other than those described in this
			Personal Data Protection Policy and that would be different
			from those you have been informed of.
		</p>
		<p className="font-medium mt-4">3. Notification and Consent</p>
		<p className="mt-4">
			In certain circumstances, as set out in the table above, we ask
			for your consent prior to collecting, using or disclosing your Personal Data, in particular when:
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>You wish to subscribe to our newsletter or receive commercial communications;</li>
			<li>We use non - essential cookies, or cookie - like technology, and / or collect information
					about the device you use to access our websites;</li>
			<li>You ask us questions about our services;</li>
			<li>You apply to Bureau Veritas for a job.</li>
		</ul>
		<p className="mt-4">
			Where we collect your prior consent, you will be informed at the time of collection of your Personal
			Data.You may withdraw your consent at any time by using the provided opt - out mechanism and indicated
			at the time of collection of your Personal Data, or by contacting us at the contact details provided in
			Article 9 &ldquo;Contact&rdquo; of this Personal Data Protection Policy.
		</p>
		<p className="font-medium mt-4">
				4. When do we disclose your Personal Data?
		</p>
		<p className="mt-4">Some of your Personal Data may be accessed:</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
					Within Bureau Veritas, and by any member of the Bureau Veritas Group;
					=&gt; This will only be done on a need-to-know basis and where
					necessary to provide you with the services you have asked for, or in
					the context of a contract between you and Bureau Veritas, or with your
					consent (in particular for marketing purpose).
			</li>
			<li>
					By trusted service providers acting as subcontractors (i.e. data
					processors), which will carry out certain services necessary for the
					purposes indicated above on our behalf (marketing services, hosting
					services, database maintenance, etc.). =&gt; We only provide them with
					the information they need to perform such services, and we require
					that they do not use your Personal Data for any other purpose. These
					service providers will only act upon Bureau Veritas&rsquo;
					instructions and will be contractually bound to ensure a level of
					security and confidentiality for your Personal Data that is the same
					as the level Bureau Veritas is bound to ensure and to comply with
					applicable personal data protection laws and regulations.
			</li>
		</ul>
		<p className="mt-4">
				Besides, Bureau Veritas may share your Personal Data with third parties:
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
					To protect the rights, property or safety of Bureau Veritas, our
					Users, our employees or others; or
			</li>
			<li>
					In the event of a merger or sale of the company&rsquo;s assets (in
					such case your Personal Data will be disclosed to the prospective
					buyer); or
			</li>
			<li>
					To comply with a legal obligation or to respond to legal proceedings
					of any nature, Court orders, any legal action or implementing
					enforcement measures that are required by the competent authorities;
					or
			</li>
			<li>
					For other purposes required by applicable legislation or with your
					prior consent.
			</li>
		</ul>
		<p className="font-medium mt-4">
				5. Where do we store your Personal Data?
		</p>
		<p className="mt-4">
				Since Bureau Veritas Group has entities all over the world and since
				some of our service providers are located abroad, the data that we
				collect from you may be transferred from a country located within the
				European Economic Area (&ldquo;EEA&rdquo;) to a country located outside
				of the EEA.
		</p>
		<p className="mt-4">
				Where Bureau Veritas transfers Personal Data outside of the EEA, this
				will always be done in a secure and lawful way
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
					Either by transferring Personal Data to a data recipient in the USA
					that is Privacy Shield certified, such mechanism being considered as
					ensuring an adequate level of protection for your data by an adequacy
					decision of the European Commission;
			</li>
			<li>
					Or by executing the European Standard Contractual Clauses (signed
					between Bureau Veritas and its subsidiaries or between Bureau Veritas
					and its service providers) which have been approved by the European
					Commission as providing an adequate level of protection for your
					Personal Data.
			</li>
		</ul>
		<p className="font-medium mt-4">
				6. How long is your Personal Data retained?
		</p>
		<p className="mt-4">
				We will keep your Personal Data only as long as necessary for the
				purposes of the processing for which it was collected (typically the
				length of the contract). We may, however, keep your data for a longer
				period of time in application of specific legal or regulatory provisions
				and/or to comply with applicable statute of limitations periods. In case
				of longer data retention for other reasons, we will inform you of such
				reasons and of the applicable retention period upon collecting your
				Personal Data.
		</p>
		<p className="mt-4">
				To determine the data retention period of your Personal Data, we use in
				particular the following criteria:
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
					Where you subscribe to a service, we keep your Personal Data for the
					duration of our contractual relationship and then in accordance with
					the statute of limitations;
			</li>
			<li>
					Where you contact us for an enquiry we keep your personal data for the
					duration needed for the processing of your enquiry;
			</li>
			<li>
					Where you have consented to direct marketing we keep your Personal
					Data until you unsubscribe or require us to delete it or after a
					period of inactivity (no active interaction with us) defined in
					accordance with local regulations and guidance;
			</li>
			<li>
					Where cookies are placed on your computer, we keep them for as long as
					necessary to achieve their purposes) and for a period defined in
					accordance with local regulations and guidance. For candidates, we
					keep your Personal Data in accordance with the applicable statute of
					limitations under local legislation.
			</li>
			<li>
				For candidates, we keep your Personal Data in accordance with the applicable
				statute of limitations under local legislation.
			</li>
		</ul>
		<p className="font-medium mt-4">
				7. How is your Personal Data protected?
		</p>
		<p className="mt-4">
				We are committed to keeping your Personal Data secure, and taking all
				reasonable precautions to do so. We implement all necessary
				organisational and technical measures in accordance with this Personal
				Data Protection Policy and applicable laws and regulations to protect
				your Personal Data against any unauthorized access and modification,
				disclosure, loss or destruction. We contractually require that service
				providers who handle your Personal Data for us do the same.
		</p>
		<p className="font-medium mt-4">8. Your rights</p>
		<p className="mt-4">
				In accordance with applicable Personal Data protection laws and
				regulations, you benefit from a certain number of rights in respect of
				your Personal Data, namely:
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
				<span className="font-semibold">
						A right of access and information
				</span>
					: you have the right to be informed in a concise, transparent,
					intelligible and easily accessible form of the way in which your
					Personal Data is processed. You also have the right to obtain (i)
					confirmation as to whether or not Personal Data concerning you are
					being processed, and, where that is the case (ii) to access such
					Personal Data and obtain a copy thereof.
			</li>
			<li>
				<span className="font-semibold">A right to rectification</span>: you
					have the right to obtain the rectification of inaccurate your Personal
					Data. You also have the right to have incomplete Personal Data
					completed, including by means of supplying a supplementary statement.
			</li>
			<li>
				<span className="font-semibold">
						A right to erasure (&lsquo;right to be forgotten&rsquo;)
				</span>
					:&nbsp;in some cases, you have the right to obtain the erasure of your
					Personal Data. However, this is not an absolute right and Bureau
					Veritas may have legal or legitimate grounds for keeping such Personal
					Data
			</li>
			<li>
				<span className="font-semibold">
						A right to restriction of processing
				</span>
					: in some cases, you have the right to obtain restriction of the
					processing of your Personal Data.
			</li>
			<li>
				<span className="font-semibold">A right to data portability</span>:you
					have the right to receive your Personal Data which you have provided
					to Bureau Veritas, in a structured, commonly used and machine-readable
					format, and you have the right to transmit those data to another
					controller without hindrance from Bureau Veritas. This right only
					applies when the processing of your Personal Data is based on your
					consent or on a contract and such processing is carried out by
					automated means.
			</li>
			<li>
				<span className="font-semibold">A right to object to processing</span>
					:&nbsp;you have the right to object at any time to the processing of
					your Personal Data. When you exercise your right to object, on grounds
					relating to your particular situation, to a processing based on the
					legitimate interest of Bureau Veritas, Bureau Veritas may, however,
					invoke compelling legitimate grounds to continue the processing.
			</li>
			<li>
				<span className="font-semibold">
						The right to revoke your consent, at any time, to processing that is
						based on your consent
				</span>
					: you may revoke your consent to the processing of your Personal Data
					when such processing is based on your consent. The revoking of consent
					does not affect the lawfulness of the processing carried out on the
					basis of such consent prior to the revocation of consent.
			</li>
			<li>
				<span className="font-semibold">
						The right to file a complaint with the supervisory authority
				</span>
					:&nbsp;you have the right to contact your Data Protection Authority to
					complain about Bureau Veritas&rsquo; Personal Data protection
					practices.
			</li>
			<li>
				<span className="font-semibold">
						The right to give instructions concerning the use of your data after
						your death
				</span>
					:&nbsp;you have the right to give Bureau Veritas instructions
					concerning the use of your Personal Data after your death.
			</li>
			<li>
				<span className="font-semibold">
						The right to obtain a copy of the standard contractual clauses
				</span>
					:&nbsp;signed by Bureau Veritas when your Personal Data is transferred
					outside of the EEA.
			</li>
		</ul>
		<p className="mt-4">
				For further information on your rights and to exercise them, contact the
				point of contact indicated in section 9 of this Personal Data Protection
				Policy.
		</p>
		<p className="mt-4">
				Note that we may require proof of your identity and full details of your
				request, before we process your request above.
		</p>
		<p className="font-medium mt-4">9. Contact</p>
		<p className="mt-4">
				If you have any questions or concerns about how we treat and use your
				Personal Data, or would like to exercise any of your rights above,
				please contact us on our portal for the exercise of data subjects&rsquo;
				rights, available at&nbsp;
			<a
				className="hover:underline"
				href="https://personaldataprotection.bureauveritas.com/home/1.1.14/#/home"
			>
					https://personaldataprotection.bureauveritas.com
			</a>
		</p>
	</div>
);

export default RYBPrivacy;
