import React, {
	useState, useMemo, useEffect, useRef
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import SearchOffIcon from "@mui/icons-material/SearchOff";

import { debounce } from "lodash";
import ArrayFieldEditor from "./ArrayFieldEditor";
import ObjectEditor from "./ObjectEditor";
import ObjectGroupEditor from "./ObjectGroupEditor";
import FieldTransformerController from "./FieldTransformerController";
import SelectFieldEditor from "./AdminSupplierSelectFieldEditor";
import CalendarController from "./CalendarController";
import CheckboxController from "./CheckboxController";
import SimpleSelectFieldEditor from "./SelectFieldEditor";
import AsyncSelectFieldEditor from "./AdminSupplierAsyncSelect";
import CreatFormBtnGroup from "../button/CreatFormBtnGroup";
import Popconfirm from "../modal/Popconfirm";
import AlphaTooltip from "../tooltips/AlphaTooltip";

import IF from "../../utils/IF";

import {
	appDefinition, typeSelector, fieldAttributeBuilder, searchSelector, newBranchFirstNode, convertRegion, convertCountry, setValueKeyBuilder,
	fieldOrderBuilder, currentFieldOptions
} from "../../apps/configs/AdminConfig";

import {
	RemoveKey, AllFieldsBuilder, AllKeyTypePairBuilder, isCoordinates, searchOptionBuilder, getGeoPointByClick, clearRefines, FieldNameAccessor
} from "./FormUtils";

// initial value builder for special field and keep the value of those has not been defined default value
// in AdminConfig, ex supplyRDefaultFields/ supplyRFieldDefaultValue
const customFieldBuilder = ({
	field, targetDocument, project, activeRefines
}) => {
	// for createBranch
	if (activeRefines?.supplyChainAction === "createBranch") {
		return targetDocument[field];
	}

	// for createNode
	let fieldValue;
	switch (field) {
		case "nextSupplier":
			if (project === "supplyR" || project === "verkor") { fieldValue = targetDocument.siteLabelId; break; }
			if (project === "ataglance") { fieldValue = targetDocument.siteUniqueId; break; }
			if (project === "clarity") { fieldValue = targetDocument.siteId; break; }
			break;
		case "tierLabel":
			fieldValue = `Tier ${(parseInt(targetDocument.tierLevel, 10) + 2).toString(10)}`;
			break;
		case "tierLevel":
			fieldValue = (parseInt(targetDocument.tierLevel, 10) + 1);
			break;
		case "targetProduct":
			fieldValue = targetDocument?.product ?? "";
			break;
		default:
			fieldValue = targetDocument[field];
			break;
	}
	return fieldValue;
};

// in case current field is type array, use k as key and make it array of object (react-hook-form required) ["a","b"]->[{k:"a"},{k:"b"}]
const appendKToArrayField = (targetDocument, activeRefines) => Object.keys(targetDocument)?.reduce((acc, cur) => {
	let objWithK;
	if (Array.isArray(targetDocument[cur])) { objWithK = targetDocument[cur].map((el) => ({ k: el })); return { ...acc, [cur]: objWithK }; }
	return {
		...acc,
		[cur]: fieldAttributeBuilder(activeRefines.secLevelMenu, "defaultFields", activeRefines)[0].includes(cur)
			? fieldAttributeBuilder(activeRefines.secLevelMenu, "defaultFields", activeRefines)[1][cur]
			: customFieldBuilder({
				field: cur, targetDocument, project: activeRefines.secLevelMenu, activeRefines
			})
	};
}, {});

const AdminSupplierCreateForm = ({
	data,
	appSlice,
	clearRefineKeys,
	renderButtonGroup = true,
	screenHeight,
	onConfirm,
	onReset,
	targetDocAccessor,
	setMarkerPosition,
	disabledFields = [],
	requiredFields
}) => {
	const dispatch = useDispatch();

	const { t } = useTranslation(appDefinition.locales);

	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	// save the parent node info (for certain fields in new child node should stick to parent's info)
	const originTargetDoc = useRef(undefined);

	const [confirmModal, setconfirmModal] = useState(false);

	// on key generate coordinate based on address
	const [geoList, setGeoList] = useState([]);

	// prefill the form with parent's info
	const targetDocument = useMemo(() => ((activeRefines?.supplyChainAction === "createBranch")
		? newBranchFirstNode({ activeRefines, activeDatasets, source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0] })
		: {
			...newBranchFirstNode({ activeRefines, activeDatasets, source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0] }),
			...data?.find(targetDocAccessor)
		}),
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[data, targetDocAccessor, activeRefines.secLevelMenu]);

	// sort field by its priority
	const fieldOrder = fieldOrderBuilder(activeRefines);

	// get all the field from mongoDB
	// data's length equals 0 means no data for current project in Tor which means it should create a new branch
	const allFieldsOfCurrentCollection = ((activeRefines?.supplyChainAction === "createBranch")
		// if there are no data available in current project when creating a new branch, then the 2nd argument is provided
		? Object.keys(newBranchFirstNode({ activeRefines, activeDatasets }))
		: [...new Set(AllFieldsBuilder({
			project: activeRefines.secLevelMenu,
			data,
			activeRefines,
			activeDatasets,
			source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0]
		}))]
		.sort((a, b) => fieldOrder.indexOf(a) - fieldOrder.indexOf(b)))
	.sort((a, b) => {
		if (disabledFields.includes(a) && !disabledFields.includes(b)) {
			return 1;
		}

		if (!disabledFields.includes(a) && disabledFields.includes(b)) {
			return -1;
		}

		return fieldOrder.indexOf(a) - fieldOrder.indexOf(b);
	});

	// all the fields in key type pair
	const AllKeyTypePair = (activeRefines?.supplyChainAction === "createBranch")
		// if there are no data available in current project when creating a new branch, then the 2nd argument is provided
		? Object.entries(newBranchFirstNode({ activeRefines, activeDatasets, source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0] }))
		.reduce((acc, cur) => ({ ...acc, [cur[0]]: typeSelector(cur, activeRefines) }), {})
		: Object.entries(
			Object.fromEntries(
				new Map(AllKeyTypePairBuilder({
					project: activeRefines.secLevelMenu,
					data,
					activeRefines,
					activeDatasets,
					source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0]
				}))
			))
		?.reduce((acc, cur) => ({ ...acc, [cur[0]]: typeSelector(cur, activeRefines) }), {});

	// transform all flat array to array of object with key k (react-hook-form required)
	const targetDocumentWithK = targetDocument && appendKToArrayField(targetDocument, activeRefines);

	const {
		control, register, handleSubmit, formState: { errors }, reset, setValue, getValues
	} = useForm({
		// if targetDocumentWithK equals undefined then it means creating a new branch and the first node otherwise it means creating a new node of current branch
		defaultValues: !targetDocumentWithK
			? data.length === 0
				// if there are no data available in current project when creating a new branch, then the 2nd argument is provided
				? newBranchFirstNode({ activeRefines, activeDatasets })
				: newBranchFirstNode({ activeRefines, activeDatasets, source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0] })
			: targetDocumentWithK,
		criteriaMode: "firstError",
		shouldFocusError: true
	});

	// for reassigning the value when restructure the submit data
	useEffect(() => {
		originTargetDoc.current = targetDocument;
		return () => {
			originTargetDoc.current = undefined;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// form will be filled with values based on selected option in dropdown
	useEffect(() => {
		// clear refined values before dispatch or reset
		// otherwise this will lead to bug if not clean when selecting an option from a dropdown and then another which will
		// autocomplete some fields including the previous selected field and its value will stay unchanged.
		// ex：select from county then select from supplier, country will stay the same

		// rerender all fields when selecte from supplier list
		if ((activeRefines.supplyChainAction === "createNode" || activeRefines.supplyChainAction === "createBranch")
			&& activeRefines.siteLabelId && activeRefines.secLevelMenu === "supplyR") {
			clearRefines(appSlice, activeRefines, dispatch, ["supplier", "auditedCompany", "siteLabelId"]);

			const targetSupplier = data.find((item) => (
				activeRefines.supplier === item.supplier
				&& activeRefines.auditedCompany === item.auditedCompany
				&& activeRefines.siteLabelId === item.siteLabelId
			));

			// convert value of specific key, ex: South Korea -> Korea
			const convertTargetSupplier = {
				...targetSupplier,
				country: convertCountry[targetSupplier?.country] ? convertCountry[targetSupplier?.country] : targetSupplier?.country,
				region: convertRegion[targetSupplier?.region] ? convertRegion[targetSupplier?.region] : targetSupplier?.region
			};

			const newSupplierData = onReset({ ...convertTargetSupplier });

			reset(newSupplierData);

			// FIXME: same supplier and auditedCompany may be reusable, be careful there will be only one rendered in supplyRListSuppliers
			// ex: supplier:SKF in XDEMO WIND, one with empty supplierCode and factoryCode, the other with specific value
			const supplierCode = activeDatasets?.supplierCode?.data
			?.filter((item) => item?.supplier === targetSupplier?.supplier)?.[0]?.supplierCode ?? "";

			setValue("supplierCode", supplierCode);

			const factoryCode = activeDatasets?.factoryCode?.data
			?.filter((item) => item?.auditedCompany === targetSupplier?.auditedCompany)?.[0]?.factoryCode ?? "";

			setValue("factoryCode", factoryCode);

			// fill the form with existing data and set the map view for preview if possible
			if (newSupplierData.geopointToR) { setMarkerPosition(newSupplierData.geopointToR); }
		}

		// rerender factoryCode and auditedCompany when select from auditedCompany list
		if ((activeRefines.supplyChainAction === "createNode" || activeRefines.supplyChainAction === "createBranch")
			&& activeRefines.auditedCompany && !activeRefines.supplier && activeRefines.secLevelMenu === "supplyR") {
			const factoryCode = activeDatasets?.factoryCode?.data
			?.filter((item) => item?.auditedCompany === activeRefines?.auditedCompany)?.[0]?.factoryCode ?? "";

			setValue("factoryCode", factoryCode);

			clearRefines(appSlice, activeRefines, dispatch, ["auditedCompany"]);
			setValue("auditedCompany", activeRefines.auditedCompany);
		}

		if ((activeRefines.supplyChainAction === "createNode" || activeRefines.supplyChainAction === "createBranch")
			&& activeRefines.supplier && activeRefines.company && activeRefines.secLevelMenu === "clarity") {
			clearRefines(appSlice, activeRefines, dispatch, ["supplier", "company"]);

			const targetSupplier = data.find((item) => (activeRefines.supplier === item.supplier)
				&& (activeRefines.company === item.company));

			// convert value of specific key, ex: South Korea -> Korea
			const convertTargetSupplier = {
				...targetSupplier,
				country: convertCountry[targetSupplier.country] ? convertCountry[targetSupplier.country] : targetSupplier.country
			};

			const newSupplierData = onReset({ ...convertTargetSupplier });

			reset(newSupplierData);
		}

		// rerender all fields when selecte from supplier list for ataglance
		if ((activeRefines.supplyChainAction === "createNode" || activeRefines.supplyChainAction === "createBranch")
		&& activeRefines.siteUniqueId && activeRefines.secLevelMenu === "ataglance") {
			clearRefines(appSlice, activeRefines, dispatch, ["supplier", "auditedCompany", "siteUniqueId"]);

			const targetSupplier = data.find((item) => (
				activeRefines.supplier === item.supplier
				&& activeRefines.auditedCompany === item.auditedCompany
				&& activeRefines.siteUniqueId === item.siteUniqueId
			));

			// convert value of specific key, ex: South Korea -> Korea
			const convertTargetSupplier = {
				...targetSupplier,
				country: convertCountry[targetSupplier?.country] ? convertCountry[targetSupplier?.country] : targetSupplier?.country,
				region: convertRegion[targetSupplier?.region] ? convertRegion[targetSupplier?.region] : targetSupplier?.region
			};

			const newSupplierData = onReset({ ...convertTargetSupplier });

			reset(newSupplierData);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeRefines.supplyChainAction, activeRefines.supplier, activeRefines.auditedCompany, activeRefines.company,
		activeDatasets?.supplierCode?.data, activeDatasets?.factoryCode?.data]);

	const onSubmit = (e) => {
		e.preventDefault();
		setconfirmModal(true);
	};

	// remove id in case error rewrite _id in mongoDb
	const submit = (submitValue) => {
		const res = RemoveKey(submitValue);

		const resWithoutId = { ...res };

		// remove key in avoid overwriting existing
		["_id", "key", "updateDate"].forEach((e) => delete resWithoutId[e]);

		if (activeRefines.secLevelMenu !== "supplyR" && activeRefines.secLevelMenu !== "clarity") {
			["supplierCode", "factoryCode"].forEach((e) => delete resWithoutId[e]);
		}

		// define final submit info by using current form info and parent node info
		onConfirm({ currentNode: resWithoutId, originTargetDoc: originTargetDoc.current, setconfirmModal });
	};

	// prevent enter event from submitting form
	const checkKeyDown = (e) => {
		if (e.key === "Enter") e.preventDefault();
	};

	const stringFieldInput = (e, field) => {
		if (field === "geopointToR") {
			if (
				isCoordinates(e.target.value)
			) {
				// update marker postion
				setMarkerPosition(e.target.value);
				setValue(`${field}`, e.target.value);
				if (geoList?.length) {
					setGeoList([]);
				}
			} else {
				console.log("bad coordination", e.target.value);
				setValue(`${field}`, "");
			}
		} else {
			setValue(`${field}`, e.target.value);
		}
	};

	const debouncedStrInput = debounce(stringFieldInput, 2000);

	return (
		<>
			{
				confirmModal &&
				<Popconfirm
					title="Add new supplier"
					description="Are you sure you want to add a new supplier?"
					confirmBtnText="Confirm"
					onClickConfirm={handleSubmit(submit)}
					onClickCancel={() => setconfirmModal(false)}
				/>
			}
			{renderButtonGroup &&
				<div className="flex flex-row space-x-1 border-b border-admin_primary-default pb-2">
					<CreatFormBtnGroup appSlice={appSlice} locales={appDefinition.locales} renderGroup
						renderBack renderAdd={false} clearRefineKeys={clearRefineKeys} setMarkerPosition={setMarkerPosition} />
				</div>}

			<form
				className="flex flex-col px-2 my-4 space-y-6 overflow-y-auto"
				style={{ height: screenHeight - 260 }}
				onSubmit={onSubmit}
				onKeyDown={(e) => checkKeyDown(e)}
			>
				{allFieldsOfCurrentCollection
				.filter((item) => !fieldAttributeBuilder(activeRefines.secLevelMenu, "hiddenFields")?.includes(item))
				.map((field, i) => (
					<div key={`field-${nanoid()}-${i}`}>
						<IF condition={AllKeyTypePair[field] === "checkbox"}>
							<label key={field + i} className={`relative w-1/3 flex font-medium gap-y-1 mt-4 capitalize
                            text-admin_primary-default border px-2 py-3 rounded-lg items-center`}>
								<span className="flex justify-center items-center bg-white uppercase text-sm">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<CheckboxController {...{ control, name: `${field}`, className: "ml-4" }} />
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "array"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                            text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<ArrayFieldEditor
									control={control}
									field={field}
									register={register}
									requiredFields={requiredFields}
									activeRefines={activeRefines}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "object"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                            text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<ObjectEditor
									control={control}
									field={field}
									register={register}
									data={targetDocument?.[`${field}`]}
									requiredFields={requiredFields}
									activeRefines={activeRefines}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "objectGroup"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								{targetDocument?.[`${field}`] &&
								Object.entries(targetDocument?.[`${field}`]).map(([subField, fieldValue]) => (
									<ObjectGroupEditor
										key={nanoid()}
										field={`${field}.${subField}`}
										fieldValue={fieldValue}
										control={control}
										register={register}
										activeRefines={activeRefines}
										appSlice={appSlice}
										getValues={getValues}
										setValue={setValue}
										requiredFields={requiredFields}
										errors={errors}
										targetDocument={targetDocument}
									/>
								))}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "select"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                    text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<SimpleSelectFieldEditor
									defaultInputValue={[currentFieldOptions(field)?.filter((e) => e?.default)[0]?.value]}
									field={field}
									control={control}
									appSlice={appSlice}
									options={currentFieldOptions(field)}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem 0",
											borderRadius: "0.5rem"
										})
									}}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "search"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<SelectFieldEditor
									field={field}
									control={control}
									appSlice={appSlice}
									options={searchOptionBuilder(activeRefines, field, searchSelector, activeDatasets)}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem 0",
											borderRadius: "0.5rem"
										})
									}}
									requiredFields={requiredFields}
									disabledFields={disabledFields}
									activeRefines={activeRefines}
									placeholder={getValues(field)}
									setValue={setValue}
									getValues={getValues}
									activeDatasets={activeDatasets}
									reset={reset}
									setMarkerPosition={setMarkerPosition}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>
						<IF condition={AllKeyTypePair[field] === "asyncsearch"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<AsyncSelectFieldEditor
									field={field}
									control={control}
									appSlice={appSlice}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem 0",
											borderRadius: "0.5rem"
										})
									}}
									requiredFields={requiredFields}
									disabledFields={disabledFields}
									setValue={setValue}
									setValueKey={(d) => setValueKeyBuilder(d, activeDatasets, activeRefines)}
									setMarkerPosition={setMarkerPosition}
									placeholder={getValues(field)}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "number"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                 text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								{/* transform str to num */}
								<FieldTransformerController
									transform={{
										input: (value) => Number.isNaN(value) ? "" : value?.toString(),
										output: (e) => {
											const output = parseInt(e.target.value, 10);
											return Number.isNaN(output) ? 0 : output;
										}
									}}
									control={control}
									register={register}
									requiredFields={requiredFields}
									disabledFields={disabledFields}
									name={`${field}`}
									defaultValue=""
									placeholder="enter a number"
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "date"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<CalendarController control={control} name={`${field}`} />
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={
							AllKeyTypePair[field] !== "array"
								&& AllKeyTypePair[field] !== "object"
								&& AllKeyTypePair[field] !== "objectGroup"
								&& AllKeyTypePair[field] !== "select"
								&& AllKeyTypePair[field] !== "search"
								&& AllKeyTypePair[field] !== "asyncsearch"
								&& AllKeyTypePair[field] !== "number"
								&& AllKeyTypePair[field] !== "checkbox"
								&& AllKeyTypePair[field] !== "date"
						}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                    text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 space-x-2 absolute -top-3 bg-white
                                                    uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
									{
										field === "geopointToR"
											&& <AlphaTooltip placement="top-start"
												title={<div className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90
                                              bg-admin_primary-default rounded-2xl w-48">
													<span className="font-bold">{t("tooltip.getCoordinates.title")}</span>
													{t("tooltip.getCoordinates.description")}
												</div>}>
												<MyLocationIcon className="text-lg mx-1.5 hover:cursor-pointer"
													onClick={() => {
														const addr = getValues("address"); console.log("addr", addr);
														getGeoPointByClick(addr, setGeoList);
													}}
												/>
											</AlphaTooltip>
									}
									{
										field === "geopointToR"
											&&
											<AlphaTooltip placement="top-start"
												title={<div className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90
                                              bg-admin_primary-default rounded-2xl w-48">
													<span className="font-bold">{t("tooltip.clearGeoList.title")}</span>
													{t("tooltip.clearGeoList.description")}
												</div>}>
												<SearchOffIcon className="text-lg mx-1.5 hover:cursor-pointer"
													onClick={() => {
														if (geoList.length > 0) {
															setGeoList([]);
														}
													}}
												/>
											</AlphaTooltip>
									}
								</span>
								<input {...register(`${field}`, { required: requiredFields.includes(field) })}
									disabled={disabledFields.includes(field)}
									autoComplete="off"
									className={`p-2 my-2 text-black border rounded-lg focus:outline-none focus:ring-2 
                                        focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base
                                        ${disabledFields.includes(field) ? "bg-gray-200" : "bg-white"}`}
									onChange={(e) => {
										debouncedStrInput(e, field);
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											stringFieldInput(e, field);
										}
									}}
									onBlur={(e) => {
										stringFieldInput(e, field);
									}}
								/>
								<ul className="flex flex-col w-max space-y-3">
									{field === "geopointToR"
											&& geoList?.map((item, index) => (
												<li key={`geoList-${nanoid()}-${index}`} className="cursor-pointer"
													onClick={() => setValue("geopointToR", `${item?.geometry?.point[1]},${item?.geometry?.point[0]}`)}>
													{item?.geometry?.point?.length === 2
														// eslint-disable-next-line max-len
														? `[ ${item?.geometry?.point[1]},${item?.geometry?.point[0]} ] - ${item?.country ?? ""} - ${item?.municipality ?? ""}`
														: ""}
												</li>
											))}
								</ul>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

					</div>
				))}

				<div className="flex flex-col space-y-4 mt-10">
					<button className="border bg-admin_primary-default hover:bg-admin_primary-dark
                    text-white font-bold py-1 px-3 rounded uppercase" type="submit">
						<DoneAllIcon />
					</button>
				</div>

			</form>
		</>
	);
};

AdminSupplierCreateForm.propTypes = {
	appSlice: PropTypes.object,
	clearRefineKeys: PropTypes.array,
	data: PropTypes.array.isRequired,
	renderButtonGroup: PropTypes.bool,
	screenHeight: PropTypes.number,
	onConfirm: PropTypes.func,
	onReset: PropTypes.func,
	targetDocAccessor: PropTypes.func,
	setMarkerPosition: PropTypes.func,
	disabledFields: PropTypes.array,
	requiredFields: PropTypes.array
};

export default AdminSupplierCreateForm;
