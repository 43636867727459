/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useMemo, forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
	useAsyncDebounce,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable
} from "react-table";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownUp from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Pagination } from "@mui/material";
import * as XLSX from "xlsx";
import moment from "moment";
import Switch from "@mui/material/Switch";
import Popconfirm from "../modal/Popconfirm";
import { replaceDoc, deleteManyDocs } from "../../data/slices/createAdminSlice";

import CreatFormBtnGroup from "../button/CreatFormBtnGroup";

import exportToExcel from "../../utils/ExportToExcel";
import { adminTranslationActivityUpdate, adminTranslationDelete } from "../form/AdminFormOnConfirm";
import { codeToLanguage, languageToCode } from "../../apps/configs/AdminConfig";

// Define a default UI for filtering
const GlobalFilter = ({
	placeHolder,
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter
}) => {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="flex justify-between items-center bg-gray-100 py-2 px-2 md:px-6 space-x-4 rounded-xl ">
			<input
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={placeHolder}
				className="bg-transparent outline-none "
			/>
			<SearchIcon />
		</div>
	);
};

GlobalFilter.propTypes = {
	placeHolder: PropTypes.string,
	preGlobalFilteredRows: PropTypes.array,
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func
};

const AdminTranslationsTable = ({
	appSlice,
	locales,
	dataset = undefined,
	structureTable = undefined,
	sortByRefineFields = [{ id: "language", desc: false }],
	renderGroup,
	renderBack,
	clearRefineKeys
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	const [selectedFile, setSelectedFile] = useState(undefined);
	const [isUploaded, setIsUploaded] = useState(false);
	const [isUploadedError, setIsUploadedError] = useState(false);
	const [confirmModal, setconfirmModal] = useState(false);
	const [activityToggleError, setActivityToggleError] = useState(false);

	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const data = useMemo(() => activeDatasets[dataset]?.data, [activeDatasets, dataset]);
	const translationsActivity = activeDatasets?.clarityTranslationsActivity?.data;

	const columns = useMemo(
		() => structureTable(),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data, translationsActivity]
	);

	// Translations is the collection of the nested objects. This method
	// recursively iterates of the objects to create the key-value pairs like this:
	// domains.BusinessPerformance.name: Business Performance
	// domains.BusinessPerformance.description: Lorem Ipsum
	const buildKeysValues = (obj, parentKey = "") => {
		let result = [];
		Object.keys(obj).forEach((key) => {
			if (key in obj) {
				const currentKey = parentKey + (parentKey ? "." : "") + key;

				if (typeof obj[key] === "object" && obj[key] !== null) {
					// Recursively iterate over nested objects
					result = result.concat(buildKeysValues(obj[key], currentKey));
				} else {
					// Add key-value pair to the result array
					result.push({ key: currentKey, value: obj[key] });
				}
			}
		});
		return result;
	};

	const generateTableToExport = (rowIndex) => {
		try {
			// Remove _id (_id is the language code, e.g. "en", "fr" etc.) and "updatedAt"
			// and insert language property instead of it for more readibility for users.
			const language = codeToLanguage(data[rowIndex]?._id);
			const dataToExport = { language, ...data[rowIndex] };
			dataToExport.language = language;
			delete dataToExport._id;
			delete dataToExport.updatedAt;
			return buildKeysValues(dataToExport);
		} catch (error) {
			console.log(error);
		}
	};

	const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<>
				<input type="checkbox" ref={resolvedRef} {...rest} />
			</>
		);
	});

	IndeterminateCheckbox.propTypes = {
		indeterminate: PropTypes.any
	};

	const getActivityStatus = (data) => {
		const language = translationsActivity?.find((el) => el._id === data._id);
		if (language) {
			return language.active;
		}
		return false;
	};

	const onToggleActivity = (data) => {
		const language = translationsActivity?.find((el) => el._id === data._id);
		const res = { ...language, active: !language.active };
		const resWithoutId = { ...res };
		delete resWithoutId._id;

		return adminTranslationActivityUpdate({
			res,
			resWithoutId,
			appSlice,
			dispatch,
			replaceDoc,
			activeRefines,
			setActivityToggleError
		});
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		rows,
		gotoPage,
		preGlobalFilteredRows,
		setGlobalFilter,
		selectedFlatRows,
		state: { pageIndex, pageSize, selectedRowIds }
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: 9,
				sortBy: sortByRefineFields
			}
		},
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				{
					id: "edit",
					Header: () => (
						<div>
							<p>Edit</p>
						</div>
					),
					Cell: ({ row }) => (
						<div className="flex items-center">
							<EditIcon
								fontSize="small"
								className="text-admin_primary-default cursor-pointer"
								onClick={() => {
									dispatch(appSlice.actions.refine([{ key: "id", value: row.original._id }]));
									dispatch(appSlice.actions.refine([
										{ key: "appAction", value: "updateCLarityTranslations" }]));
								}}
							/>
						</div>
					)
				},
				{
					id: "selection",
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					Cell: ({ row }) => (
						<div className="flex items-center">
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					)
				},
				...columns,
				{
					id: "download",
					Header: () => (
						<div>
							<p>Download</p>
						</div>
					),
					Cell: ({ row }) => (
						<div className="flex items-center">
							<DownloadIcon
								fontSize="small"
								className="text-admin_primary-default cursor-pointer"
								onClick={(e) => exportToExcel(
									generateTableToExport(row?.index),
									`ClarityTranslations Export ${row?.original?._id} ${new Date().toISOString().split("T")[0]}`
								)}
							/>
						</div>
					)
				},
				{
					id: "upload",
					Header: () => (
						<div>
							<p>Upload</p>
						</div>
					),
					Cell: ({ row }) => (
						<div className="flex items-center">
							<label
								htmlFor="uploadFile"
							>
								<FileUploadIcon
									fontSize="small"
									className="text-admin_primary-default cursor-pointer"
								/>
							</label>
							<input
								type="file"
								id="uploadFile"
								className={"Hidden invisible"}
								title="Import data in Excel format"
								onChange={(e) => {
									setSelectedFile(e);
									setIsUploaded(true);
								}}
							/>
						</div>
					)
				},
				{
					id: "action",
					Header: () => (
						<div>
							<p>Activation</p>
						</div>
					),
					Cell: ({ row }) => (
						<div className="flex items-center">
							<Switch checked={getActivityStatus(row?.original)} onChange={() => onToggleActivity(row?.original)} />
						</div>
					)
				}
			]);
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect
	);

	if (dataset === undefined || structureTable === undefined) {
		console.log("You need to define your dataset and table structure to display this component");
		return null;
	}

	// Example: the key-value "GSCAPage.riskOverview.title": "Overview"
	// is transformed into object
	// GSCAPage: {
	//     riskOverview: {
	//         title: "Overview"
	//     }
	// }
	const transformKeysToObject = (data) => {
		const result = {};
		data.forEach((line) => {
			const parts = line.key.split(".");
			let curr = result;
			parts.forEach((part, index) => {
				if (index === parts.length - 1) {
					curr[part] = line.value;
				} else if (!curr[part]) {
					curr[part] = {};
				}
				curr = curr[part];
			});
		});
		return result;
	};

	const readUploadFile = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = e.target.result;
				const workbook = XLSX.read(data, { type: "array" });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json = XLSX.utils.sheet_to_json(worksheet);

				const transformedData = transformKeysToObject(json);

				const currentDateAndTime = moment();

				const transformedDataWithoutId = { ...transformedData, updatedAt: currentDateAndTime.format("YYYY-MM-DD HH:mm:ss") };
				delete transformedDataWithoutId._id;

				// Transform the language to the language code and use the language code as _id.
				// Remove the language, we do not need it in the db, the underscore id with the
				// language code is enough.
				let languageCode;
				try {
					languageCode = languageToCode(transformedDataWithoutId.language);
					const existsInTable = rows.find((row) => (row?.original?._id === languageCode));
					// If the user is trying to uplad the file with the language that does not exist
					// in the table abort the upload.
					if (!existsInTable) {
						setIsUploadedError(true);
						return;
					}
					delete transformedDataWithoutId.language;
				} catch (error) {
					console.log(error);
					setIsUploadedError(true);
					return;
				}

				// for invoking the refresh of database
				dispatch(appSlice.actions.clear([{ refine: "translationAction" }]));
				dispatch(
					replaceDoc({
						appName: "admin",
						dataset: {
							collection: "ClarityTranslations",
							fetch: "replaceOne",
							data: { ...transformedDataWithoutId },
							id: languageCode,
							limit: 1,
							adminCallFromApp: "",
							isObjectId: false
						},
						resetSkip: false
					})
				)
				.unwrap()
				.then((originalPromiseResult) => {
					if (originalPromiseResult.data.res.n) {
						console.log("Upload file success");
						// for invoking the refresh of database
						dispatch(appSlice.actions.refine([{ key: "translationAction", value: "upload" }]));
						setSelectedFile(undefined);
						setIsUploaded(false);
					} else {
						// return error item position in file
						const res = originalPromiseResult.res.reduce((acc, cur, i) => {
							if (cur !== 1) return [...acc, i];
							return [...acc];
						}, []);

						console.log("upload file to database fail", res);
					}
				})
				.catch((rejectedValueOrSerializedError) => {
					console.log("Upload error", rejectedValueOrSerializedError);
					setIsUploaded(false);
					setIsUploadedError(true);
				});
			};

			reader.readAsArrayBuffer(e.target.files[0]);
		}
	};

	const onConfirmDelete = (selectedDeleteNodes) => {
		// When we delete translation we should also delete the translationActivity object
		// associated with it. So here we have to collect ids for translations and its activity.
		const translationNamesToDelete = selectedDeleteNodes.map((node) => (node.id));
		const translationActivityToDelete = translationsActivity.filter((value) => translationNamesToDelete.includes(value._id));

		const translationIdsToDelete = selectedDeleteNodes.map((node) => (node.id));
		const translationActivityIdsToDelete = translationActivityToDelete.map((node) => (node._id));

		const dataToDelete = {
			translations: translationIdsToDelete,
			activities: translationActivityIdsToDelete
		};

		if (selectedDeleteNodes.length === 0) {
			window.confirm("Please select an entry to delete");
			return;
		}
		return adminTranslationDelete({
			appSlice,
			dispatch,
			deleteManyDocs,
			activeRefines,
			deleteNodes: dataToDelete,
			setconfirmModal
		});
	};

	// selected node id in table
	const selectedDeleteNodes = selectedFlatRows
	.map((d) => d.original)
	.map((item) => ({ id: item._id, language: item.language }));

	return (
		<div className="flex flex-col text-sm space-y-4 max-x-full w-full overflow-x-auto">
			<div className="self-center flex justify-between items-center w-full">
				<div className="flex flex-row items-center">
					<CreatFormBtnGroup appSlice={appSlice} locales={locales} renderGroup={renderGroup} renderBack={renderBack} renderAdd={true}
						refineKey={[{ key: "appAction", value: "createTranslation" }]} addText="addTranslation" clearRefineKeys={clearRefineKeys}
						renderDelete={true} onDelete={() => setconfirmModal(true)} deleteText="deleteTranslation" />
				</div>
				{/* upload confirm modal */}
				{isUploaded && (
					<Popconfirm
						title="Upload translation file"
						description="You are about to upload this file to the database.
						The previous translation will be replaced by the new one."
						confirmBtnText="Confirm"
						iconsType="exclamationMark"
						onClickConfirm={() => { readUploadFile(selectedFile); }}
						onClickCancel={() => {
							setIsUploaded(false);
						}}
					/>
				)}
				{isUploadedError && (
					<Popconfirm
						title="Something went wrong"
						description={`The file upload has failed, possibly due to insufficient permissions, incompatible file format or the language
						mismatch between the file and the table.`}
						showConfirm={false}
						iconsType="exclamationMark"
						onClickCancel={() => {
							setIsUploadedError(false);
						}}
					/>
				)}
				{confirmModal && (
					<Popconfirm
						title="Delete"
						description="Are you sure you want to delete this translation?"
						confirmBtnText="Confirm"
						onClickConfirm={() => { onConfirmDelete(selectedDeleteNodes); }}
						onClickCancel={() => setconfirmModal(false)}
					/>
				)
				}
				{activityToggleError && (
					<Popconfirm
						title="Something went wrong"
						description={"The activity status of the translation was not changed due to an error."}
						showConfirm={false}
						iconsType="exclamationMark"
						onClickCancel={() => {
							setActivityToggleError(false);
						}}
					/>
				)}
				<div className="flex space-x-2 items-center">
					<p className="hidden md:block">
						{rows.length} Results
					</p>
				</div>
			</div>
			<table {...getTableProps()} className="w-full">
				<thead>
					{headerGroups.map((headerGroup, i) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							key={`headerGroup${i}`}
						>
							{headerGroup.headers.map((column, j) => (
								<th
									key={`header${j}`}
									{...column.getHeaderProps(column.getSortByToggleProps())}
									className="first:pl-6 last:pr-6 text-left font-medium p-2 max-w-xs"
								>
									<div className="flex items-center space-x-2">
										{column.render("Header")}
										<span>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<KeyboardArrowDownUp fontSize="inherit" />
												) : (
													<KeyboardArrowDownIcon fontSize="inherit" />
												)
											) : (
												""
											)}
										</span>
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, k) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps()}
								key={`row${k}`}
								className={
									"odd:bg-gray-100 hover:bg-admin_primary-default hover:bg-opacity-20 cursor-pointer"
								}
							>
								{row.cells.map((cell, m) => (
									<td
										key={`cell${m}`}
										{...cell.getCellProps()}
										className="first:pl-6 last:pr-6 p-2 max-w-xs"
									>
										{cell.render("Cell")}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			{rows.length > pageSize && (
				<Pagination
					page={pageIndex + 1}
					count={Math.floor(rows.length / pageSize + 1)}
					className="md:self-center px-4 md:px-6"
					onChange={async (event, value) => {
						const page = value ? Number(value) - 1 : 0;
						gotoPage(page);
					}}
				/>
			)}
		</div>
	);
};

AdminTranslationsTable.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	dataset: PropTypes.string,
	structureTable: PropTypes.func,
	renderGroup: PropTypes.bool,
	renderBack: PropTypes.bool,
	renderAdd: PropTypes.bool,
	addForm: PropTypes.string,
	clearRefineKeys: PropTypes.array
};

export default AdminTranslationsTable;
