import React, {
	useState, useEffect, createRef, useRef, useMemo
} from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useTranslation } from "react-i18next";

import { sum } from "d3";
import { rollups } from "d3-array";

import { ParentSize } from "@visx/responsive";
import Div100vh, { use100vh } from "react-div-100vh";

import ChatIcon from "@mui/icons-material/Chat";
import CommentBankIcon from "@mui/icons-material/CommentBank";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SpeedIcon from "@mui/icons-material/Speed";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GavelIcon from "@mui/icons-material/Gavel";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";

import { Tooltip } from "@mui/material";

import { userSelector, requestSignIn, userSlice } from "../data/slices/UserSlice";
import createAppSlice from "../data/slices/createAppSlice";

import SwitchSelector from "../components/filters/SwitchSelector";
import SideBar from "../components/menu/SideBar";
import TreeNodesGraph from "../components/tree/TreeNodesGraph";
import SupplyRToolTips from "../components/tooltips/SupplyRToolTips";
import MapLeaflet from "../components/map/MapLeaflet";
import SupplyRMapMarker from "../components/map/SupplyRMapMarker";
import SupplyRLayer from "../components/map/SupplyRLayer";
import ColorLegend from "../components/legends/ColorLegend";
// import MapLinksLegend from "../components/legends/MapLinksLegend";
import SupplyRSupplierDetailedCard from "../components/cards/SupplyRSupplierDetailedCard";
import SupplyRAlternativeList from "../components/cards/SupplyRAlternativeList";
import ListOfNonConformities from "../components/cards/ListOfNonConformities";
import PageHeader from "../components/header/PageHeader";
import SupplyRInfoPageHeader from "../components/header/SupplyRInfoPageHeader";
import SupplyRTermPageHeader from "../components/header/SupplyRTermPageHeader";
import NonConformitiesCard from "../components/cards/NonConformitiesCard";
import Table from "../components/table/Table";
import SupplyRTable from "../components/table/SupplyRTable";
import Splashscreen from "../components/notifications/SplashScreen";
import SupplyRProjectScope from "../components/cards/SupplyRProjectScope";
import SupplyRRiskIndex from "../components/cards/SupplyRRiskIndex";
import ColumnGraph from "../components/graphs/ColumnGraph";
import SupplyRHeader from "../components/header/SupplyRHeader";
import TitleCard from "../components/cards/TitleCard";
import NCByModules from "../components/cards/NCByModules";
import ColumnGroupGraph from "../components/graphs/ColumnGroupGraph";
import Page401 from "../pages/Page401";
import PageMenuHeader from "../components/header/PageMenuHeader";
import SupplyRIndicator from "../components/graphs/SupplyRIndicator";
import { customStylesSearchSupplyR, customStylesSupplyR } from "../components/filters/SupplyRFiltersStyle";
import getSupplychainChildren from "../components/tree/SupplyRSupplychainChildren";
import VerticalGauge from "../components/graphs/VerticalGauge";
import TotalMHGauge from "../components/graphs/TotalMHGauge";
import Tabs from "../components/tabs/Tabs";
import { isAllowedToSeeSupplyChain, isUserAlphaRole } from "../utils/helpers";
import SimpleDonutGraph from "../components/graphs/SimpleDonutGraph";
import SupplyRTreeTooltipSupplier from "../components/cards/SupplyRTreeTooltipSupplier";

import IF from "../utils/IF";
import Loading from "../utils/Loading";
import kFormatter from "../utils/ThousandsToK";

import {
	appDefinition, menuOptions, supplyRInitDatasets, supplyRInitRefines, filterOptions,
	fieldsTooltip, nonConformities, listTagsNonConformities, clientInitParameters,
	listNonConformityQuery, SupplyRCheckpoints, updateListNonConformities, domainsOptions,
	splashScreens, colorLegend, getMarkerColor, tableStructure, reportsTableStructure, getAuditMode,
	langListValue, termsTabSet, infoTabSet, ratingsConfig, listFacetsToRefine, getClientParametersByProjectName
} from "./configs/SupplyRConfig";
import ProgressBarFlat from "../components/graphs/ProgressBarFlat";

import { openSaml } from "../auth/Authenticator";

// Create the App Slice
const appSlice = createAppSlice(appDefinition.slice,
	menuOptions.filter((option) => option.initial)[0].menu,
	supplyRInitRefines,
	supplyRInitDatasets);

// Set Layout size
const mdScreens = 768;
const twoXLScreens = 1536;
const mobileMenuHeight = 60;
const filterMenuHeight = 112;
const sideBarWidth = 114;
const analysisWidth = 476;
const mobileAnalysisWidth = 348;

/**
 * Function to render the application
 */
function SupplyR() {
	// Internationalization hook
	const { t, i18n } = useTranslation(appDefinition.locales);

	// Load optional URL parameters
	const urlQuery = new URLSearchParams(window.localStorage.getItem("searchParams"));
	const supplierSiteId = urlQuery.get("siteId");
	const supplierProjectName = urlQuery.get("projectName");

	// const dimensionUrl = urlQuery.get("dimension");
	// const projectNameUrl = urlQuery.get("projectname");

	const atAglanceURLparams = JSON.parse(window.localStorage.getItem("ataglanceURLparams"));

	// Set up state for the map to supply chain view switch
	const [mapView, setMapView] = useState(false);

	// Set up state for the map to supply chain view switch
	const [renderAnalysis, setRenderAnalysis] = useState(true);

	// Set up state for the SupplyRRiskIndex switch
	const [consolidated, setConsolidated] = useState(false);

	// Create and load the app slice in the store
	const store = useStore();

	// project name ref for lazy loadingIndicator
	const lastProject = useRef(undefined);

	// Load ref of content section
	const contentRef = createRef();

	// map adress paragraph ref
	const addressRef = useRef();

	// Load screen layout parameters
	const screenHeight = use100vh();
	const contentHeight = window.innerWidth > mdScreens ? (screenHeight - filterMenuHeight) : (screenHeight - mobileMenuHeight);
	const contentWidth = (document.fullscreenElement ? window.innerWidth : (Math.min(...[1900, window.innerWidth])))
    - ((window.innerWidth < mdScreens) ? 0 : (renderAnalysis ? (window.innerWidth > twoXLScreens ? (analysisWidth
        + sideBarWidth) : (mobileAnalysisWidth + sideBarWidth)) : sideBarWidth));

	// Load the store hook
	const dispatch = useDispatch();

	// Load the reducer and favicon
	useEffect(() => {
		store.injectReducer(appDefinition.slice, appSlice.reducer);
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);
	const subMenu = useSelector(appSlice.selectSubPage);

	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	const rollupGroupKey = clientParameters?.supplychainParams?.groupKey;

	// Load the user data from the store of the App
	const user = useSelector(userSelector);
	const supplyChainVisibility = isUserAlphaRole(user) ? true : isAllowedToSeeSupplyChain({
		user, appsCollection: ["Supplyr"], restrictedScopeValue: "Supplier", projectName: supplierProjectName || activeRefines?.projectName
	});

	// Used for redirection from "At A Glance"
	useEffect(() => {
		if (atAglanceURLparams) {
			// Reset all the refines that stayed from the previous visit
			// and apply the refines received from "At A Glance".
			const atAGlanceRefineKeys = Object.keys(atAglanceURLparams);
			const supplyRRefineKeys = Object.keys(activeRefines ?? []);

			dispatch(appSlice.actions.refine(supplyRRefineKeys.map((e) => ({ key: e, value: undefined }))));
			dispatch(appSlice.actions.setPage("HOME"));
			dispatch(appSlice.actions.refine(atAGlanceRefineKeys.map((e) => ({ key: e, value: atAglanceURLparams[e] }))));
			if (atAglanceURLparams?.supplier !== undefined) {
				dispatch(appSlice.actions.setPage("SITES"));
			}

			window.localStorage.removeItem("ataglanceURLparams");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [atAglanceURLparams]);

	useEffect(() => {
		if (activeRefines?.projectName && (lastProject.current !== activeRefines?.projectName)) {
			lastProject.current = activeRefines?.projectName;
		}
	}, [activeRefines?.projectName]);

	// Load client parameters
	useEffect(() => {
		// if supplied projectName, refine on it
		if (supplierProjectName) {
			dispatch(appSlice.actions.refine([{ key: "projectName", value: supplierProjectName }]));
			window.localStorage.removeItem("searchParams");
		}
		// in any case, load first available clientParameters
		if (!clientParameters) {
			dispatch(appSlice.fetchDataset(clientInitParameters));
		}

		// and load other datasets
		if (activeRefines?.projectName) {
			dispatch(appSlice.fetchDatasets());
		}
	}, [activeRefines]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (clientParameters?.projectName?.[0] && !supplierProjectName) {
			dispatch(appSlice.actions.refine([{ key: "projectName", value: clientParameters?.projectName?.[0] }]));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientParameters?.projectName?.[0]]);

	// if query param with siteID then refine
	useEffect(() => {
		if (!supplierSiteId) {
			return;
		}
		dispatch(appSlice.actions.setPage("SITES"));
		dispatch(appSlice.actions.refine([{
			key: "siteId",
			value: parseInt(supplierSiteId, 10)
		}]));

		window.localStorage.removeItem("searchParams");
	}, [supplierSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch supplyChain Data to be put in the store
	useEffect(() => {
		const totalNonConformities = (activeDatasets?.nonConformities?.data?.length > 0) ?
			activeDatasets.nonConformities
			.data?.reduce((acc, element) => { acc.nbNC += element.nbNC; acc.nbCNC += element.nbCNC; return acc; },
				{ nbNC: 0, nbCNC: 0 })
			:
			{ nbNC: 0, nbCNC: 0 };

		// Dispatch the number of non conformities in the store
		dispatch(
			appSlice.actions.fetchCalculatedDataset({
				datasetName: "totalNonConformities",
				datasetData: totalNonConformities
			})
		);

		// Fetch Supply-R scores in the store - initial and if selects change only
		if (activeDatasets?.supplyChain?.data?.length > 0) {
			// Fetch SupplyChain in the store
			const supplyChain = rollups(
				activeDatasets.supplyChain.data,
				(v) => ({
					resilience: sum(v, (d) => activeRefines.module === undefined ?
						(d?.resilience ?? 0)
						: (d?.resilienceScores?.[d?.module?.indexOf(activeRefines.module)] ?? 0)
					),
					resistance: sum(v, (d) => activeRefines.module === undefined ?
						(d?.resistance ?? 0)
						: (d?.resistanceScores?.[d?.module?.indexOf(activeRefines.module)] ?? 0)
					),
					responsiveness: sum(v, (d) => activeRefines.module === undefined ?
						(d?.responsiveness ?? 0)
						: (d?.responsivenessScores?.[d?.module?.indexOf(activeRefines.module)] ?? 0)
					)
				}),
				(d) => d.finalProduct,
				(d) => Number.parseInt(d.tierLevel, 10),
				(d) => d.targetProduct,
				(d) => d.nextSupplier,
				(d) => [d[rollupGroupKey], d.supplier, d.auditedCompany, d.city, d.country,
					d.product, d.geometry, d.countryRisk, d.supplierCriticality, d.siteId, d.type,
					d.region, d.tierLevel, d.tierLevelDisplay, d.client, d.finalProduct,
					d.targetProduct, d.nextSupplier, d.logisticAgent, d.tierLabel, d._id, d.siteLabelId,
					d.supplierCode, d.factoryCode, d.module, d.criticality, d.factoryBranch, d.factoryIndex]

			);

			const rawSupplychain = {
				name: "client",
				children: supplyChain
				.sort((a, b) => a[0] > b[0] ? 1 : -1)
				.map((finalProduct, i) => ({
					name: finalProduct[0],
					children: getSupplychainChildren(finalProduct[1], "", 0, finalProduct[0])
				}))
			};

			// Dispatch a non filtered version of the supply chain in the store
			dispatch(
				appSlice.actions.fetchCalculatedDataset({
					datasetName: "supplyChainTree",
					datasetData: rawSupplychain
				})
			);
		} else {
			// Dispatch a void dataset
			dispatch(
				appSlice.actions.fetchCalculatedDataset({
					datasetName: "supplyChainTree",
					datasetData: {
						name: "client",
						children: []
					}
				})
			);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ clientParameters, activeDatasets?.supplyChain, activeDatasets?.nonConformities, rollupGroupKey ]);

	// if query param with siteID then refine
	useEffect(() => {
		if (!supplierSiteId) {
			return;
		}
		dispatch(appSlice.actions.setPage("SITES"));
		dispatch(appSlice.actions.refine([{
			key: "siteId",
			value: parseInt(supplierSiteId, 10)
		}]));
	}, [supplierSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// Set supplier refine in case of a selection of siteid refine
		if (activeRefines?.siteId !== undefined
            && loadDataStatus !== "loading"
            && activeRefines?.supplier === undefined) {
			dispatch(appSlice.actions.refine([{ key: "supplier", value: activeDatasets.sites?.data?.[0]?.supplier }]));
		}

		// Reset siteid refine section selection if another supplier is selected
		if (activeRefines?.siteId !== undefined
            && loadDataStatus !== "loading" && activeRefines?.supplier !== undefined
            && (activeDatasets.sites?.data?.[0]?.supplier !== activeRefines?.supplier)) {
			dispatch(appSlice.actions.refine([{ key: "siteId", value: undefined }]));
		}
	}, [loadDataStatus, activeRefines]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// Reset criticality filter if not in NCs page
		if (activeRefines?.criticality !== undefined && menu !== "NCs") {
			dispatch(appSlice.actions.refine([{ key: "criticality", value: undefined }]));
		}
	}, [menu]); // eslint-disable-line react-hooks/exhaustive-deps

	// Reload window if data expired
	useEffect(() => {
		if (loadDataStatus === "sessionExpired") {
			setTimeout(() => {
				dispatch(appSlice.actions.reset());
				dispatch(requestSignIn());
				openSaml();
			}, 2000);
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (loadDataStatus === "loading") {
			const defautLanguages = ["en", "fr", "zh-Hans", "zh-Hant"];
			const isDefaultLanguage = defautLanguages.includes(i18n.language);

			if (!isDefaultLanguage) {
				i18n.changeLanguage("en");
			}
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	if (loadDataStatus === "sessionExpired") {
		return <Page401/>;
	}

	// Go top if menu has changed
	useEffect(() => {
		document?.getElementById("svg-scroll")?.scroll({ top: 0, behavior: "smooth" });
	}, [menu, subMenu, activeRefines?.supplier, activeRefines?.module]); // eslint-disable-line react-hooks/exhaustive-deps

	// Wait for Data to render App
	if (activeDatasets === undefined || (lastProject.current !== activeRefines?.projectName)) {
		return <Loading message="Wait a moment while we load your app." />;
	}

	const selectedSiteResilience = activeDatasets.scores.data?.[0]?.resilienceScore?.toFixed(0);
	const selectedSiteResistance = activeDatasets.scores.data?.[0]?.resistanceScore?.toFixed(0);
	const selectedSiteResponsiveness = activeDatasets.scores.data?.[0]?.responsivenessScore?.toFixed(0);

	// totalEnergies donut data
	const moduleData = activeDatasets?.totalDemo?.data
	?.filter((d) => d.auditedCompany === activeRefines?.supplier);
	const donutData = ratingsConfig.reduce(
		(acc, curValue, index) => {
			acc[index] = {
				label: curValue.rating,
				score: moduleData?.[0] ? moduleData[0][`${curValue.rating}`] : 0
			};
			return acc;
		}, [{}]);
	const calOverAllScore = (value) => {
		const nbSuccess = value?.[0]?.score;
		const nbFailed = value?.[1]?.score;

		if ((nbSuccess + nbFailed) === 0) return 0;

		return (nbSuccess / (nbSuccess + nbFailed)) * 100;
	};
	return (
		<div className="relative h-full w-full">

			<IF condition={loadDataStatus === "loading"}>
				<Loading />
			</IF>

			{/*
            * Load the splashscreen if the user has not yet set it as don't remind me
            */}
			<Splashscreen appName="SupplyR" notifKey="home" splashScreens={splashScreens}
				theme="supplyr" locales={appDefinition.locales} />

			<div className="relative flex justify-between max-w-screen-3xl mx-auto h-full w-full bg-white" id="full-screen">

				<SideBar
					appSlice={appSlice}
					appDefinition={appDefinition}
					// menuOptions={menuOptions}
					menuOptions={menuOptions.filter((option) => (option.menu !== "HOME") || (supplyChainVisibility && option.menu === "HOME"))}
					menu={menu}
					width={sideBarWidth}
					colorMenu="#3D49AB"
					colorAccent="#6eb8d6"
					indicator={{ menu: "NCs", value: activeDatasets?.totalNonConformities?.data?.nbNC ?? 0 }}
					locales={appDefinition.locales}
					langListValue={langListValue}
				/>
				<div className="flex flex-col md:pl-24 bg-supplyr_primary-bg h-full w-full max-w-full overflow-y-auto">

					<IF condition={menu !== "INFO" && menu !== "TERMs" && (menu !== "SITES" || activeRefines.supplier === undefined)}>

						<SupplyRHeader
							appSlice={appSlice}
							locales={appDefinition.locales}
							clientAccessor={clientParameters?.[appDefinition?.clientAccessor]}
							appDefinition={appDefinition}/>

						<div className="relative flex flex-col md:flex-row justify-between h-full w-full">

							<div className="relative flex w-full h-full rounded overflow-y-auto">
								{/*
                                * SUPPLY CHAIN & MAP PAGE
                                */}
								<IF condition={menu === "HOME" && supplyChainVisibility}>
									<div className="relative h-full ">
										<div id="svg-scroll"
											className={`flex rounded transition-width duration-700 ease-in-out
                                        ${mapView ? " overflow-hidden" : " overflow-x-auto overflow-y-auto no-scrollbar"}`}
											style={{
												height: contentHeight,
												maxHeight: contentHeight,
												width: contentWidth,
												maxWidth: contentWidth
											}}
										>

											<div className="absolute top-6 left-8 z-500">

												<div className="font-semibold" style={{ width: 250, height: 44 }}>
													<SwitchSelector
														onChange={(value) => setMapView(value)}
														options={
															[
																{
																	label: t("supplyChainGraph.supplyChain"),
																	value: false,
																	selectedBackgroundColor: "#fff",
																	selectedfontcolor: "#3D49AB"
																},
																{
																	label: t("supplyChainGraph.map"),
																	value: true,
																	selectedBackgroundColor: "#fff",
																	selectedfontcolor: "#3D49AB"
																}
															]
														}
														initialSelectedIndex={mapView ? 1 : 0}
														backgroundColor="#eee"
														selectedfontcolor="#818593"
														selectionIndicatorMargin={4}
													/>
												</div>

											</div>

											<div className="absolute z-500 bottom-4 left-4 flex flex-row">
												{
													mapView
													/* && <span className="mr-48">
                                                <MapLinksLegend />
                                            </span> */
												}
											</div>

											<div className="absolute z-500 bottom-4 right-20 flex flex-row">
												<ColorLegend
													locales={appDefinition.locales}
													colorLegend={colorLegend}
													scale="scale-125"
													legendFontSize="text-8"
													indicatorFontSize={12}
												/>
											</div>

											{/* TODO: fix dataset */}
											<ParentSize debounceTime={10}>
												{(parent) => (
													mapView
														? <MapLeaflet
															appSlice={appSlice}
															// dataset="sites"
															dataset="allSites"
															markersTree={activeDatasets.supplyChainTree.data}
															fieldsTooltip={fieldsTooltip}
															getMarkerColor={getMarkerColor}
															mapType="jawgTerrain"
															minZoom={3}
															zoom={3}
															maxClusterRadius={35}
															scrollWheelZoom={true}
															defaultColor="#d3d3d3"
															mapWidth={window.innerWidth > mdScreens ? contentWidth : window.innerWidth}
															mapHeight={parent.height}
															CustomMarker={SupplyRMapMarker}
															CustomLayer={SupplyRLayer}
															zoomSnap={0}
															wheelPxPerZoomLevel={110}
														/>
														: <TreeNodesGraph
															appDefinition={appDefinition}
															appSlice={appSlice}
															dataset="supplyChain"
															datasetTree="supplyChainTree"
															datasetFinalProduct="supplyChainActiveFinalProducts"
															width={window.innerWidth > mdScreens ? contentWidth : window.innerWidth}
															height={parent.height}
															refineHighlightKeys={[
																"supplier",
																"siteId",
																"tierLevel",
																"module",
																"type",
																"product",
																"region",
																"country",
																"supplierCriticality"
															]}
															refineSiteKeys={[
																"supplier",
																"siteId",
																"product"
															]}
															fieldsToExport={["projectName", "supplier", "auditedCompany",
																"address", "city", "country", "region",
																"finalProduct", "product", "tierLevel", "tierLabel",
																"resilience", "resistance", "responsiveness", "sumNbOpenedNc", "sumNbCnc"]}
															getMarkerColor={getMarkerColor}
															margin={{
																top: 10, left: 0, right: 0, bottom: 300
															}}
															subTreeMargin={{
																top: 130, left: -130, right: 0, bottom: 0
															}}
															consolidated={consolidated}
															displayTooltip
															TreeTooltip={SupplyRTreeTooltipSupplier}
														/>
												)}
											</ParentSize>
										</div>
									</div>
								</IF>

								<IF condition={menu === "NCs" || (menu === "SITES" && activeRefines.supplier === undefined)}>
									<div id="svg-scroll"
										className={"flex rounded w-full overflow-y-auto"}
										style={{ height: contentHeight, maxHeight: contentHeight }}
									>
										{/*
                                        * NON CONFORMITIES PAGE
                                        */}
										<IF condition={menu === "NCs"}>
											<div className="flex flex-col px-8 w-full 2xl:px-12" ref={contentRef}>
												<div className="flex justify-between mt-1">
													<PageHeader
														page="NCsPage"
														Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
														locales={appDefinition.locales}
													>
														<NonConformitiesCard
															appSlice={appSlice}
															nonConformities={nonConformities}
															dataset="nonConformities"
															synthesis
															locales={appDefinition.locales}
															showNonConformity={false}
														/>
													</PageHeader>
												</div>
												<div className="grid grid-cols-1 grid-flow-row gap-y-1 pb-16
                                                            md:grid-cols-4 md:gap-x-8 ">
													<div className="flex flex-col space-y-4 w-full  md:col-span-3">
														<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 ">
															<ListOfNonConformities
																appSlice={appSlice}
																appNCConfig={{
																	nonConformities,
																	listNonConformityQuery,
																	nonConformityDataset: SupplyRCheckpoints,
																	updateListNonConformities,
																	customStylesSubFilter: customStylesSupplyR,
																	listTagsNonConformities,
																	filterOptions: filterOptions.filter((option) => option.label === "Section"
                                                                    || option.label === "Criticality")
																}}
																forceObservation
																observations = {[
																	{
																		locale: "supplierComment",
																		accessorResult: (d) => d.supplierResult,
																		accessorObs: (d) => d.supplierComment,
																		picto: ChatIcon
																	},
																	{
																		locale: "auditorComment",
																		accessorResult: (d) => d.auditorResult,
																		accessorObs: (d) => d.auditorComment,
																		picto: CommentBankIcon
																	}
																]}
																totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
																contentRef={contentRef}
																dataset="listNonConformities"
																locales={appDefinition.locales}
																themePalette="supplyr"
															/>
														</div>
													</div>
													<div className="hidden md:flex flex-col space-y-4 w-full">
														<div className="flex flex-col w-full alphaBlur rounded-lg px-4 xl:px-6 py-4 space-y-8">
															<NonConformitiesCard nonConformities={nonConformities} theme="supplyr"
																appSlice={appSlice} dataset="nonConformities"
																locales={appDefinition.locales}/>
														</div>
														<div className="flex flex-col w-full alphaBlur rounded-lg px-4 xl:px-6 py-4 space-y-8">
															<TitleCard titleLocal="NCsPage.byModules.title"
																subTitleLocal="" small theme={appDefinition.theme}
																infoLocal="NCsPage.byModules.info" locales={appDefinition.locales} />
															<NCByModules appSlice={appSlice} dataset="nonConformitiesByModules"
																domainsOptions={domainsOptions} noPicto locales={appDefinition.locales} />
														</div>
													</div>
												</div>
											</div>
										</IF>

										{/*
                                        * SITES TABLE PAGE
                                        */}
										<IF condition={menu === "SITES" && activeRefines.supplier === undefined}>
											<div className="flex flex-col px-8 w-full 2xl:px-12" ref={contentRef}>
												<div className="flex justify-between">
													<PageHeader
														page="listSuppliersPage"
														Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
														locales={appDefinition.locales}
													/>
												</div>
												<div className="flex flex-col  space-y-4 w-full md:col-span-2">
													<div className="flex flex-col w-full  alphaBlur rounded-lg px-6 py-4">
														<SupplyRTable
															appSlice={appSlice}
															dataset1="sites"
															dataset2="downStream"
															structureTable={tableStructure}
															refineKeys = {(row) => [
																{ key: "supplier", value: row.original.supplier },
																{ key: "siteId", value: row.original.siteId }
															]}
															sortByRefineFields={[{ id: "score", desc: true }]}
															themePalette="supplyr"
															locales={appDefinition.locales} />
													</div>
												</div>
											</div>
										</IF>
									</div>
								</IF>
								{/*
								* REPORTS PAGE
								*/}
								<IF condition={menu === "REPORTS"}>

									<div className="flex flex-col px-8 w-full 2xl:px-12  " ref={contentRef}>
										<div className="flex justify-between">
											<PageHeader
												page="reportsPage"
												Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
												locales={appDefinition.locales}
											/>
										</div>

										<div className="flex flex-col w-full  alphaBlur rounded-lg px-6 py-4">
											<Table
												appSlice={appSlice}
												pageTable={"reportsPage"}
												dataset="reportsTable"
												structureTable={reportsTableStructure}
												refineKeys = {(row) => [
													{ key: "supplier", value: row.original.supplier }
												]}
												sortByRefineFields={[{ id: "endDate", desc: true }
													// { id: "supplier", desc: false }
												]}
												themePalette="supplyr"
												locales={appDefinition.locales} />
										</div>

									</div>

								</IF>
							</div>

							{/*
                            * Supply-R Analysis section
                            */}
							<IF condition={menu === "HOME"}>

								<div className={`absolute md:right-0 bottom-0 flex flex-col md:flex-row md:justify-end 
                                transition-all duration-700 ease-in-out flex-shrink-0 w-0 z-500
                                 ${ renderAnalysis ? "max-h-full pt-20 md:pt-0 w-full  md:w-96 2xl:w-112" : " w-full md:w-4 h-24  md:h-full"}` }
								style={{ height: contentHeight, maxHeight: contentHeight }}>

									<div className="absolute hidden md:flex h-full justify-center md:justify-start
                                    items-start md:items-center -left-3  z-2xl">
										<button className="flex flex-shrink-0 items-center justify-center h-6 w-6 bg-white border rounded-full"
											onClick={() => {
												setRenderAnalysis(!renderAnalysis);
											}}>
											{renderAnalysis ?
												<KeyboardArrowRightIcon fontSize="inherit" className=" text-supplyr_primary-accent" />
												:
												<KeyboardArrowLeftIcon fontSize="inherit" className="text-supplyr_primary-menu" />

											}
										</button>
									</div>

									<div className={`relative flex flex-col gap-y-6 border-t md:border-0
                                                       rounded-t-3xl md:rounded-tr-none md:rounded-tl-3xl
                                                       bg-white pt-2 md:pt-4  shadow-leftxl overflow-y-auto
                                                       transition-all duration-700 ease-in-out
                                                       ${ renderAnalysis ? "w-full h-full px-4 pb-20 " : " md:w-4"}`}>

										<div className="flex md:hidden w-full h-10 justify-center cursor-pointer"
											onClick={(event) => setRenderAnalysis(!renderAnalysis)}>
											<div className="w-24 h-1 bg-gray-600 rounded-full animate-bounce" />
										</div>

										<IF condition={renderAnalysis}>
											{<IF condition={ activeRefines?.supplier === undefined}>
												<SupplyRProjectScope
													appSlice={appSlice}
													dataset="criticalityDistribution"
													total={activeDatasets.sites?.data?.length}
													totalCovered={activeDatasets.sitesDistribution?.data?.length}
													locales={appDefinition.locales}
												/>
											</IF>}

											<IF condition={activeRefines.supplier !== undefined}>
												<SupplyRSupplierDetailedCard appSlice={appSlice} dataset="sites" />
											</IF>

											<IF condition={activeDatasets.scores?.data?.[0]?.resilienceScore
                                                   && activeDatasets.scores?.data?.[0]?.resilienceScore !== 0}>
												<div className="flex items-center justify-between ">
													<p className="text-supplyr_primary-menu font-bold text-lg">{t("analysis.switch.seeResultsBy")}</p>
													<div className="font-semibold" style={{ width: 220, height: 44 }}>
														<SwitchSelector
															onChange={(newValue) => dispatch(appSlice.actions.setSubPage(newValue))}
															options={
																[
																	{
																		label: t("analysis.switch.KPIs"),
																		value: "KPIs",
																		selectedBackgroundColor: "#fff",
																		selectedfontcolor: "#3D49AB"
																	},
																	{
																		label: t("analysis.switch.Distribution"),
																		value: "DISTRIB",
																		selectedBackgroundColor: "#fff",
																		selectedfontcolor: "#3D49AB"
																	}
																]
															}
															initialSelectedIndex={subMenu === "DISTRIB" ? 1 : 0}
															backgroundColor={"#eee"}
															fontColor={"#818593"}
															selectionIndicatorMargin={4}
														/>
													</div>
												</div>

												{/*
                                                * KPIs ANALYSIS
                                                */}
												<IF condition={subMenu !== "DISTRIB"}>
													<div className={`flex flex-col ${(activeRefines.module !== undefined) && "gap-y-4"}`}>
														<IF condition={activeRefines.module === undefined}>
															<TitleCard titleLocal="analysis.globalriskindex.title" subTitleLocal=""
																small infoLocal="analysis.globalriskindex.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</IF>
														<IF condition={activeRefines.module !== undefined}>
															<TitleCard titleLocal="analysis.moduleriskindex.title" subTitleLocal=""
																picto={getAuditMode(activeDatasets.modulesDistribution?.data?.[0]?.auditType,
																	activeDatasets.modulesDistribution?.data?.[0]?.auditMode)}
																prefix={`module.${activeRefines.module}`}
																small infoLocal="analysis.moduleriskindex.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</IF>
														<div className="px-4">
															<SupplyRRiskIndex appSlice={appSlice}
																dataset={activeRefines.module === undefined ? "scores" : "scoresModule"}
																locales={appDefinition.locales} consolidated={consolidated}
																setConsolidated={setConsolidated} domainsOptions={domainsOptions}
															/>
														</div>
													</div>

													<IF condition={
														activeRefines.module === undefined
													}>
														<TitleCard titleLocal="analysis.splitPerModule.title" subTitleLocal=""
															small infoLocal="analysis.splitPerModule.desc"
															theme={appDefinition.theme} locales={appDefinition.locales} />
														<div>
															<ParentSize>
																{(parent) => (
																	<ColumnGraph
																		appSlice={appSlice}
																		dataset={"modulesDistribution"}
																		color={"#1876bd"}
																		strokeWidth={8}
																		width={parent.width}
																		margin={{
																			top: 25,
																			left: 10,
																			right: 30,
																			bottom: 0
																		}}
																		getScore={(d) => d.value}
																		getModule={(d) => d.module}
																		getPicto={ activeRefines.siteId
																			? (d) => getAuditMode(d.auditType, d.auditMode)
																			: undefined }
																		refineKeys={["module"]}
																		locales={appDefinition.locales}
																		prefix="module"
																	/>
																)}
															</ParentSize>
														</div>
													</IF>

													<IF condition={activeRefines.module !== undefined}>
														<TitleCard titleLocal="analysis.splitPerSection.title" subTitleLocal=""
															small infoLocal="analysis.splitPerSection.desc"
															theme={appDefinition.theme} locales={appDefinition.locales} />
														<div>
															<ParentSize>
																{(parent) => (
																	<ColumnGraph
																		appSlice={appSlice}
																		dataset={"sectionsDistribution"}
																		sortData={(a, b) => Number.isNaN(parseInt(a.sectionIndex, 10)) ?
																			((a.section < b.section) ? 1 : -1)
																			: ((parseInt(a.sectionIndex, 10) < parseInt(b.sectionIndex, 10)) ? 1 : -1)}
																		color={"#1876bd"}
																		strokeWidth={8}
																		width={parent.width}
																		margin={{
																			top: 25,
																			left: 10,
																			right: 30,
																			bottom: 0
																		}}
																		placeholderDataset="placeholderSectionsList"
																		getScore={(d) => d.value}
																		getModule={(d) => d.section}
																		hideAvgLine
																		locales={appDefinition.locales}
																	/>
																)}
															</ParentSize>
														</div>
													</IF>

													<NonConformitiesCard nonConformities={nonConformities} theme="supplyr"
														appSlice={appSlice} dataset="nonConformities" noMonthlyAnalysis
														locales={appDefinition.locales}/>

												</IF>

												{/*
                                                * DISTRIBUTION ANALYSIS
                                                */}
												<IF condition={subMenu === "DISTRIB" && activeRefines.supplier === undefined}>

													<TitleCard titleLocal="analysis.finalProduct.benchmarkAll.title" subTitleLocal=""
														small infoLocal="analysis.finalProduct.benchmarkAll.desc"
														theme={appDefinition.theme} locales={appDefinition.locales} />

													<div>
														<ParentSize className="graph-container" debounceTime={10}>
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	dataset={"finalProductDistribution"}
																	color={"#1876bd"}
																	strokeWidth={8}
																	width={parent.width}
																	margin={{
																		top: 25,
																		left: 10,
																		right: 30,
																		bottom: 0
																	}}
																	sortScoreDimension
																	getScore={(d) => d.value}
																	getModule = {(d) => d.finalProduct}
																	refineKeys={["finalProduct"]}
																	locales={appDefinition.locales}
																	prefix="product"
																/>
															)}
														</ParentSize>
													</div>

													<TitleCard titleLocal="analysis.benchmark.supplierAll.title" subTitleLocal=""
														small infoLocal="analysis.benchmark.supplierAll.desc"
														theme={appDefinition.theme} locales={appDefinition.locales} />

													<div>
														<ParentSize className="graph-container" debounceTime={10}>
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	dataset={
																		Object.keys(activeRefines)
																		.filter((item) => item !== "projectName")
																		.some((item) => listFacetsToRefine.includes(item))
																			? "resilienceScoreAvgSupplierByFilter"
																			: "resilienceScoreAvgSupplier"
																	}
																	color={"#1876bd"}
																	strokeWidth={8}
																	width={parent.width}
																	margin={{
																		top: 25,
																		left: 10,
																		right: 30,
																		bottom: 0
																	}}
																	sortScoreDimension
																	getScore={(d) => d.resilienceScore}
																	getModule = {(d) => d.supplier}
																	refineKeys={["supplier"]}
																	locales={appDefinition.locales}
																/>
															)}
														</ParentSize>
													</div>

													<TitleCard titleLocal="analysis.benchmark.factoryAll.title" subTitleLocal=""
														small infoLocal="analysis.benchmark.factoryAll.desc"
														theme={appDefinition.theme} locales={appDefinition.locales} />

													<div>
														<ParentSize className="graph-container" debounceTime={10}>
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	dataset={
																		Object.keys(activeRefines)
																		.filter((item) => item !== "projectName")
																		.some((item) => listFacetsToRefine.includes(item))
																			? "sitesDistributionByFilter"
																			: "sitesDistribution"
																	}
																	color={"#1876bd"}
																	strokeWidth={8}
																	width={parent.width}
																	margin={{
																		top: 25,
																		left: 10,
																		right: 30,
																		bottom: 0
																	}}
																	sortScoreDimension
																	getScore={(d) => d.resilienceScore}
																	getModule = {(d) => d.auditedCompany}
																	refineKeys={["supplier", "siteId"]}
																	locales={appDefinition.locales}
																/>
															)}
														</ParentSize>
													</div>
												</IF>

												{/*
                                                * DISTRIBUTION BENCHMARK ANALYSIS
                                                */}
												<IF condition={subMenu === "DISTRIB" && activeRefines.supplier !== undefined}>
													<TitleCard titleLocal="analysis.finalProduct.benchmarkSelected.title" subTitleLocal=""
														small infoLocal="analysis.finalProduct.benchmarkSelected.desc"
														theme={appDefinition.theme} locales={appDefinition.locales} />
													<div className="flex flex-col">
														<ParentSize className="graph-container" debounceTime={10}>
															{(parent) => (
																<ColumnGroupGraph
																	appSlice={appSlice}
																	dataset1={"finalProductDistribution"}
																	dataset2={"finalProductBenchmark"}
																	color={"#1876bd"}
																	width={parent.width}
																	getAbs={(d) => d.value}
																	getOrd = {(d) => d.finalProduct}
																	refineKeys={["finalProduct"]}
																	locales={appDefinition.locales}
																	prefix="product"
																/>
															)}
														</ParentSize>
														<p className="flex items-center text-medium gap-x-6">
															<div className="flex items-center gap-x-2">
																<p className="h-2 w-6 bg-supplyr_primary-accent rounded-full" />
																<p>{t("analysis.legend.selectedFactory")}</p>
															</div>
															<div className="flex items-center gap-x-2">
																<p className="h-2 w-6 bg-gray-500 rounded-full" />
																<p>{t("analysis.legend.allFactories")}</p>
															</div>
														</p>
													</div>

													<TitleCard titleLocal="analysis.benchmark.supplierSelected.title" subTitleLocal=""
														small infoLocal="analysis.benchmark.supplierSelected.desc"
														theme={appDefinition.theme} locales={appDefinition.locales} />

													<div className="flex flex-col">
														<ParentSize className="graph-container" debounceTime={10}>
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	dataset={
																		Object.keys(activeRefines)
																		.filter((item) => item !== "projectName")
																		.some((item) => listFacetsToRefine.includes(item))
																			? "sitesDistributionByFilter"
																			: "sitesBenchmark"
																	}
																	color={"#1876bd"}
																	mapData={(d) => {
																		const element = { ...d };
																		element.inactive = !(element.supplier === activeRefines.supplier &&
                                                                ((activeRefines.siteId === undefined) || (element.siteId === activeRefines.siteId)));
																		return element;
																	}}
																	strokeWidth={8}
																	width={parent.width}
																	margin={{
																		top: 25,
																		left: 10,
																		right: 30,
																		bottom: 0
																	}}
																	sortScoreDimension
																	getScore={(d) => d.resilienceScore}
																	getModule = {(d) => d.auditedCompany}
																	refineKeys={["supplier"]}
																	locales={appDefinition.locales}
																/>
															)}
														</ParentSize>
														<p className="flex items-center text-medium gap-x-6">
															<div className="flex items-center gap-x-2">
																<p className="h-2 w-6 bg-supplyr_primary-menu rounded-full" />
																<p>{t("analysis.legend.selectedFactory")}</p>
															</div>
															<div className="flex items-center gap-x-2">
																<p className="h-2 w-6 bg-gray-500 rounded-full" />
																<p>{t("analysis.legend.allFactories")}</p>
															</div>
														</p>
													</div>
												</IF>
											</IF>
										</IF>
									</div>
								</div>
							</IF>
						</div>
					</IF>

					{/*
                    * SITE SELECTED PAGE
                    */}
					<IF condition={menu === "SITES"
                    && (activeRefines.supplier !== undefined)}>
						<Div100vh className="overflow-y-auto">
							<PageMenuHeader
								appSlice={appSlice}
								Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
								filterOption={filterOptions.find((option) => option.label === "Modules")}
								customStylesSearch={customStylesSearchSupplyR}
								themePalette="supplyr"
								logo={appDefinition.logo}
								locales={appDefinition.locales}
							/>

							<div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row
                                    -mt-36 mx-4 md:mx-10 gap-y-6 gap-x-4 md:gap-x-6 ">
								<div className="md:ml-10 md:mr-20 z-10 md:col-span-1">
									<SupplyRSupplierDetailedCard appSlice={appSlice} dataset="sites"/>
								</div>
								<div className="relative overflow-hidden shadow-md rounded-xl h-68 bg-white z-10 md:col-span-1">
									<ParentSize debounceTime={10}>
										{(parent) => (
											<MapLeaflet
												appSlice={appSlice}
												dataset="sites"
												getMarkerColor={getMarkerColor}
												markersTree={activeDatasets.supplyChainTree?.data}
												fieldsTooltip={fieldsTooltip}
												mapType="jawgTerrain"
												minZoom={2}
												zoom={2}
												scrollWheelZoom={true}
												defaultColor="#d3d3d3"
												mapWidth={window.innerWidth > mdScreens ? parent.width : window.innerWidth}
												mapHeight={parent.height}
												CustomMarker={SupplyRMapMarker}
												zoomSnap={0}
												wheelPxPerZoomLevel={110}
											/>
										)}
									</ParentSize>
									{activeRefines.siteId !== undefined && <div
										className="absolute bottom-6 left-1/2 transform -translate-x-1/2 w-4/5 gap-x-2 font-light text-medium
                                               flex justify-center items-center rounded-xl alphaBlur border px-3 py-1 z-500">
										<LocationOnIcon fontSize="small" />
										<div ref={addressRef} className="line-clamp-1">
											{addressRef?.current?.scrollHeight > addressRef?.current?.clientHeight
												? <Tooltip
													title={<div className="flex flex-col">
														<span>{activeDatasets.sites?.data?.[0]?.country}</span>
														<span>{activeDatasets.sites?.data?.[0]?.city}</span>
														<span>{activeDatasets.sites?.data?.[0]?.address}</span>
													</div>}
													followCursor>
													<div>
														<span>{activeDatasets.sites?.data?.[0]?.country}&nbsp;-&nbsp;</span>
														<span>{activeDatasets.sites?.data?.[0]?.city}&nbsp;-&nbsp;</span>
														<span>{activeDatasets.sites?.data?.[0]?.address}</span>
													</div>
												</Tooltip>
												: <div>
													<span>{activeDatasets.sites?.data?.[0]?.country}&nbsp;-&nbsp;</span>
													<span>{activeDatasets.sites?.data?.[0]?.city}&nbsp;-&nbsp;</span>
													<span>{activeDatasets.sites?.data?.[0]?.address}</span>
												</div>}
										</div>
									</div>}
								</div>

								<IF condition={clientParameters?.allowRequestAudit && (!activeDatasets.scores?.data?.[0]?.resilienceScore
                                    || activeDatasets.scores?.data?.[0]?.resilienceScore === 0)}>
									<div className="w-full flex justify-between items-start md:col-span-2">
										<a target="_blank" rel="noopener noreferrer"
											href={(`mailto:
                                            supplyr-${activeRefines.projectName}-${activeDatasets.sites?.data?.[0]?.country}@bureauveritas.com
                                            ?subject=${`${clientParameters?.client} - ${activeRefines.supplier} - ${t("sitePage.requestAudit")}`}`)
											.replace(/\s/g, "")}
											className="flex items-center px-10 py-3 font-semibold rounded-xl
                                                       bg-white shadow-lg text-supplyr_primary-menu text-sm">
											{t("sitePage.requestAudit")}
										</a>
									</div>
								</IF>

								<IF condition={subMenu !== "ALTERNATIVES" && subMenu !== "TOTALDEMO"
                                && (activeDatasets.scores?.data?.[0]?.resilienceScore && activeDatasets.scores?.data?.[0]?.resilienceScore !== 0)}>
									<div className="w-full flex flex-col md:flex-row justify-between gap-x-6 items-start md:col-span-2">

										<TitleCard titleLocal="sitePage.discover.title" subTitleLocal="sitePage.discover.subTitle"
											infoLocal="sitePage.discover.info" small theme={appDefinition.theme} locales={appDefinition.locales} />

										{clientParameters?.allowRequestAudit && <a target="_blank" rel="noopener noreferrer"
											href={(`mailto:
                                            supplyr-${activeRefines.projectName}-${activeDatasets.sites?.data?.[0]?.country}@bureauveritas.com
                                            ?subject=${`${clientParameters?.client} - ${activeRefines.supplier} - ${t("sitePage.refreshAudit")}`}`)
											.replace(/\s/g, "")}
											className="flex flex-shrink-0 items-center px-10 py-3 font-semibold rounded-xl
                                                    bg-white shadow-lg text-supplyr_primary-menu text-sm order-first mb-6 md-mb-0 md:order-last">
											{t("sitePage.refreshAudit")}
										</a>}
									</div>
									<div className="relative flex  flex-wrap gap-1 md:gap-3 md:col-span-2 -mt-6 2xl:gap-5">
										<IF condition={activeRefines.module === undefined}>
											<div className="flex flex-col items-center px-3 py-4 gap-y-2 bg-white rounded-xl">
												<VerticalGauge score={selectedSiteResilience ?? 0} height={120} width={102}>
													<div className="-mt-1 flex flex-col items-center justify-center gap-y-2 text-white font-light h-full">
														<SpeedIcon className="text-xl text-white" />
														<p className="text-3xl leading-4 mt-1">
															{(`${selectedSiteResilience}%`) ?? "n/a"}
														</p>
														<p className="text-medium">{t("sitePage.discover.Resilience")}</p>
													</div>

												</VerticalGauge>
												<div className="flex flex-col w-full">
													<Tooltip title={t("sitePage.indicators.resistance")} placement="top">
														<div className="flex items-center w-full justify-between gap-x-1">
															<VerticalAlignCenterIcon style={{ fontSize: 14 }} className="mx-1" />
															<ProgressBarFlat
																strokeColor={getMarkerColor(selectedSiteResistance)}
																endPoint={selectedSiteResistance}
																width={74}
																height={9} />
															<p className="text-medium">{(`${selectedSiteResistance}%`) ?? "n/a"}</p>
														</div>
													</Tooltip>
													<Tooltip title={t("sitePage.indicators.responsiveness")} placement="top">
														<div className="flex items-center w-full justify-between gap-x-1">
															<TimelapseIcon style={{ fontSize: 14 }} className="mx-1"/>
															<ProgressBarFlat
																strokeColor={getMarkerColor(selectedSiteResponsiveness)}
																endPoint={selectedSiteResponsiveness}
																width={74}
																height={9} />
															<p className="text-medium">{(`${selectedSiteResponsiveness}%`) ?? "n/a"}</p>
														</div>
													</Tooltip>
												</div>
											</div>
											<div className="hidden md:inline border-r border-gray-300" />
										</IF>
										{activeDatasets?.modulesDistribution?.data?.map((dimension, i) => {
											const moduleScore = dimension?.value?.toFixed(0);
											const resistanceScore = dimension?.resistance?.toFixed(0);
											const responsivenessScore = dimension?.responsiveness?.toFixed(0);
											const moduleOption = domainsOptions[dimension.module];
											const auditMode = getAuditMode(dimension.auditType, dimension.auditMode);
											const Picto = auditMode.picto;

											return (
												<div key={`indicator ${ i}`}
													className="flex flex-col items-center px-2 md:px-4 py-4 gap-y-2 bg-white rounded-xl">
													<SupplyRIndicator
														score={parseFloat(moduleScore)}
														logo={moduleOption?.logo}
														locale={moduleOption?.locale}
														locales={appDefinition.locales} />

													<div className="flex flex-col w-full">
														<Tooltip title={t("sitePage.indicators.resistance")} placement="top">
															<div className="flex items-center w-full justify-between gap-x-1">
																<VerticalAlignCenterIcon style={{ fontSize: 14 }} className="mx-1" />
																<ProgressBarFlat
																	strokeColor={getMarkerColor(resistanceScore)}
																	endPoint={resistanceScore}
																	width={74}
																	height={9} />
																<p className="text-medium">{(`${resistanceScore}%`) ?? "n/a"}</p>
															</div>
														</Tooltip>
														<Tooltip title={t("sitePage.indicators.responsiveness")} placement="top">
															<div className="flex items-center w-full justify-between gap-x-1">
																<TimelapseIcon style={{ fontSize: 14 }} className="mx-1"/>
																<ProgressBarFlat
																	strokeColor={getMarkerColor(responsivenessScore)}
																	endPoint={responsivenessScore}
																	width={74}
																	height={9} />
																<p className="text-medium">{(`${responsivenessScore}%`) ?? "n/a"}</p>
															</div>
														</Tooltip>
													</div>

													{clientParameters?.allowReportLink && <div className="flex justify-center items-center w-full">
														<a target="_blank" rel="noopener noreferrer"
															href={`https://odsdatahub.bureauveritas.com/reports/${dimension?.reports?.[0]}`}
															className="flex justify-center items-center space-x-1
                                                                    border border-gray-200 rounded-lg px-3 py-2 text-medium font-light w-28
                                                                    hover:border-gray-400" >
															<Tooltip title={t(auditMode.locale)} placement="top">
																<Picto fontSize="small" />
															</Tooltip>
															<p className="flex flex-shrink-0">{t("NCsPage.listNCs.nonConformity.viewReports")}</p>
														</a>
													</div>}
												</div>
											);
										})}
									</div>

									<div className="flex flex-col w-full">

										<TitleCard titleLocal="sitePage.detailedScore.title" subTitleLocal="sitePage.detailedScore.subTitle" small
											infoLocal="sitePage.detailedScore.info" theme={appDefinition.theme} locales={appDefinition.locales} />

										<div className="flex flex-col space-y-6 w-full ">
											{activeDatasets.modulesDistribution?.data?.map((dimension, i) => {
												const moduleScore = dimension?.value?.toFixed(0);
												const moduleOption = domainsOptions[dimension.module];
												const auditMode = getAuditMode(dimension.auditType, dimension.auditMode);
												const Picto = auditMode.picto;

												return ((moduleScore >= 0) &&
													<div key={`sectionScore ${i}`}
														className="flex flex-col space-y-6 rounded-xl alphaBlur w-full px-6 pt-4">
														<div className="flex items-center gap-x-2">
															<Tooltip title={t(auditMode.locale)} placement="top">
																<Picto fontSize="small" />
															</Tooltip>
															<p>{t(`domains.${moduleOption?.locale}.name`)}</p>
														</div>
														<ParentSize >
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	dataset={"sectionsDistribution"}
																	placeholderDataset="placeholderSectionsList"
																	filterData={(d) => d.module === dimension.module}
																	sortData = {
																		(a, b) => Number.isNaN(parseInt(a.sectionIndex, 10)) ?
																			((a.section < b.section) ? 1 : -1) :
																			((parseInt(a.sectionIndex, 10) < parseInt(b.sectionIndex, 10)) ? 1 : -1)
																	}
																	color={"#1876bd"}
																	width={parent.width}
																	strokeWidth={8}
																	gradientStartBarColor="#CBDA72"
																	gradientEndBarColor="#6E8F57"
																	nonConformities
																	getScore = {(d) => d.value}
																	getModule = {(d) => d.section}
																	locales={appDefinition.locales}
																/>
															)}
														</ParentSize>
													</div>
												);
											})}
										</div>
									</div>

									<div className="flex flex-col w-full">

										<TitleCard titleLocal="sitePage.nonConformity.title" subTitleLocal="sitePage.nonConformity.subTitle" small
											infoLocal="sitePage.nonConformity.info" theme={appDefinition.theme} locales={appDefinition.locales} />

										<div className="rounded-xl alphaBlur w-full px-6 py-4">
											<NonConformitiesCard nonConformities={nonConformities} theme="supplyr"
												appSlice={appSlice} dataset="nonConformities"
												synthesis locales={appDefinition.locales}/>
										</div>

										<div className="rounded-xl alphaBlur w-full px-6 py-4 mt-6">
											<ListOfNonConformities
												appSlice={appSlice}
												appNCConfig={{
													nonConformities,
													listNonConformityQuery,
													nonConformityDataset: SupplyRCheckpoints,
													updateListNonConformities,
													customStylesSubFilter: customStylesSupplyR,
													listTagsNonConformities,
													filterOptions: filterOptions.filter((option) => option.label === "Section"
                                                    || option.label === "Criticality")
												}}
												forceObservation
												observations = {[
													{
														locale: "supplierComment",
														accessorResult: (d) => d.supplierResult,
														accessorObs: (d) => d.supplierComment,
														picto: ChatIcon
													},
													{
														locale: "auditorComment",
														accessorResult: (d) => d.auditorResult,
														accessorObs: (d) => d.auditorComment,
														picto: CommentBankIcon
													}
												]}
												totalNonConformities={activeDatasets.totalNonConformities?.data.nbNC ?? 0}
												contentRef={contentRef}
												dataset="listNonConformities"
												locales={appDefinition.locales}
												themePalette="supplyr"
											/>
										</div>
									</div>

								</IF>

								<IF condition={subMenu === "ALTERNATIVES"
                                && (activeDatasets.scores?.data?.[0]?.resilienceScore && activeDatasets.scores?.data?.[0]?.resilienceScore !== 0)}>

									<div className="flex flex-col w-full md:pr-10">

										<TitleCard titleLocal="comparePage.benchmark.title"
											infoLocal="comparePage.benchmark.info" theme={appDefinition.theme} small locales={appDefinition.locales} />

										<div className="flex flex-col space-y-6 w-full pt-6">
											<div className="rounded-xl alphaBlur w-full px-6 py-4">
												<TitleCard titleLocal="analysis.finalProduct.benchmarkSelected.title" subTitleLocal=""
													small infoLocal="analysis.finalProduct.benchmarkSelected.desc"
													theme={appDefinition.theme} locales={appDefinition.locales} />
												<div className="flex flex-col">
													<ParentSize className="graph-container" debounceTime={10}>
														{(parent) => (
															<ColumnGroupGraph
																appSlice={appSlice}
																dataset1={"finalProductDistribution"}
																dataset2={"finalProductBenchmark"}
																color={"#1876bd"}
																width={parent.width}
																getAbs={(d) => d.value}
																getOrd = {(d) => d.finalProduct}
															/>
														)}
													</ParentSize>
													<p className="flex items-center text-medium gap-x-6">
														<div className="flex items-center gap-x-2">
															<p className="h-2 w-6 bg-supplyr_primary-menu rounded-full" />
															<p>{t("analysis.legend.selectedFactory")}</p>
														</div>
														<div className="flex items-center gap-x-2">
															<p className="h-2 w-6 bg-gray-500 rounded-full" />
															<p>{t("analysis.legend.allFactories")}</p>
														</div>
													</p>
												</div>
											</div>

											<div className="rounded-xl alphaBlur w-full px-6 py-4">

												<TitleCard titleLocal="analysis.benchmark.supplierSelected.title" subTitleLocal=""
													small infoLocal="analysis.benchmark.supplierSelected.desc"
													theme={appDefinition.theme} locales={appDefinition.locales} />

												<div className="flex flex-col">
													<ParentSize className="graph-container" debounceTime={10}>
														{(parent) => (
															<ColumnGraph
																appSlice={appSlice}
																dataset={"sitesBenchmark"}
																color={"#1876bd"}
																mapData={(d) => {
																	const element = { ...d };
																	element.inactive = !(element.supplier === activeRefines.supplier &&
                                                                ((activeRefines.siteId === undefined) || (element.siteId === activeRefines.siteId)));
																	return element;
																}}
																strokeWidth={8}
																width={parent.width}
																margin={{
																	top: 25,
																	left: 10,
																	right: 30,
																	bottom: 0
																}}
																sortScoreDimension
																getScore={(d) => d.resilienceScore}
																getModule = {(d) => d.auditedCompany}
																refineKeys={["supplier"]}
																locales={appDefinition.locales}
															/>
														)}
													</ParentSize>
													<p className="flex items-center text-medium gap-x-6">
														<div className="flex items-center gap-x-2">
															<p className="h-2 w-6 bg-supplyr_primary-menu rounded-full" />
															<p>{t("analysis.legend.selectedFactory")}</p>
														</div>
														<div className="flex items-center gap-x-2">
															<p className="h-2 w-6 bg-gray-500 rounded-full" />
															<p>{t("analysis.legend.allFactories")}</p>
														</div>
													</p>
												</div>
											</div>
										</div>
									</div>

									<div className="flex flex-col w-full" ref={contentRef}>

										<TitleCard titleLocal="comparePage.alternatives.title"
											infoLocal="comparePage.alternatives.info" theme={appDefinition.theme} small locales={appDefinition.locales} />

										<div className="flex flex-col w-full pt-6">
											<SupplyRAlternativeList
												appSlice={appSlice}
												datasetSupplyChain="supplyChain"
												locales={appDefinition.locales}
											/>
										</div>
									</div>
								</IF>
								<IF condition={subMenu === "TOTALDEMO"}>
									<div className="grid row-start-2 row-span-1 justify-end -mt-4">
										{
											activeDatasets?.totalDemo?.data
											?.filter((el) => el.auditedCompany === activeRefines?.supplier)[0]?.assignmentTechnicalId
												? <a href={`https://odsdatahub.bureauveritas.com/reports/${activeDatasets?.totalDemo?.data
												?.filter((el) => el.auditedCompany === activeRefines?.supplier)[0]?.assignmentTechnicalId}`}
												target="_blank" rel="noreferrer"
												className="flex justify-center items-center
													font-semibold text-gray-500 opacity-80 text-sm
													hover:text-supplyr_primary-accent hover:cursor-pointer hover:opacity-100" >
													<FileDownloadRoundedIcon fontSize="inherit" className="mr-2" />
													<p>Download full capabilities report</p>
												</a>
												: <Tooltip title="Report unavailable" followCursor>
													<div
														className="flex justify-center items-center
								font-semibold text-gray-500 opacity-80 text-sm" >
														<FileDownloadRoundedIcon fontSize="inherit" className="mr-2" />
														<p>Download full capabilities report</p>
													</div>
												</Tooltip>
										}
									</div>
									<div className="col-span-3 gap-4 grid grid-cols-4">
										<div className="bg-white col-span-1 rounded-xl px-6 py-4">
											<TitleCard titleLocal="Manhours completed over the last 10 years"
												infoLocal="comparePage.benchmark.info" theme={appDefinition.theme} small locales={appDefinition.locales} />
											<div className="flex justify-center items-center mt-4">
												<TotalMHGauge score={activeDatasets?.totalDemo?.data
												?.filter((d) => d?.auditedCompany === activeRefines?.supplier)[0]?.totalManhours ?? 0}
												height={180} width={1800} >
													<div className="-mt-1 flex flex-col items-center justify-center gap-y-2 text-white font-light h-full">
														<ManageAccountsRoundedIcon className="text-xl text-white" />
														<p className="text-3xl leading-4 mt-1">
															{kFormatter(activeDatasets?.totalDemo?.data
															?.filter((d) => d?.auditedCompany === activeRefines?.supplier)[0]?.totalManhours) ?? "n/a"}
														</p>
														<p className="text-medium">{t("sitePage.discover.manhours")}</p>
													</div>

												</TotalMHGauge>
											</div>
										</div>
										<div className="bg-white col-span-1 rounded-xl px-6 py-4">
											<TitleCard titleLocal="Working condition compliance rate"
												infoLocal="comparePage.benchmark.info" theme={appDefinition.theme} small locales={appDefinition.locales} />
											<ParentSize className="mt-4" debounceTime={10}>
												{(parent) => (
													<div className="flex justify-center items-end">
														<SimpleDonutGraph
															value={donutData}
															innerValue={true}
															index={false}
															donutThickness={10}
															donutInnerThickness={10}
															donutColor={ratingsConfig.map((rating) => rating.color)}
															width={parent.width / 1.2}
															height={200}
															values={false}
															fontSizeTextCenter={60}
															subTextCenter={"%"}
															subTextCenterSize={18}
															textInnerColor={"black"}
															calOverAllScore={calOverAllScore}
														/>
													</div>)}
											</ParentSize>
										</div>
										<div className="bg-white col-span-2 rounded-xl px-6 py-4">
											<TitleCard titleLocal="Segment and Material group capabilities rate"
												infoLocal="comparePage.benchmark.info" theme={appDefinition.theme} small locales={appDefinition.locales} />
											<ParentSize className="graph-container mt-4" debounceTime={10}>
												{(parent) => (
													<ColumnGraph
														appSlice={appSlice}
														dataset={"totalMockColumnGraph"}
														color={"#1876bd"}
														filterData={(d) => d.auditedCompany === activeRefines?.supplier}
														strokeWidth={8}
														width={parent.width}
														margin={{
															top: 25,
															left: 10,
															right: 30,
															bottom: 0
														}}
														sortScoreDimension
														getScore={(d) => d.score}
														getModule = {(d) => d.label}
														locales={appDefinition.locales}
														unit="%"
													/>
												)}
											</ParentSize>
										</div>
									</div>
								</IF>

							</div>

						</Div100vh>
					</IF>

					{/*
                    * INFO PAGE
                    */}
					<IF condition={menu === "INFO"}>
						<Div100vh className="overflow-y-auto ">
							<SupplyRInfoPageHeader
								appSlice={appSlice}
								page="Info"
								Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
								locales={appDefinition.locales}
							>
								<div className="flex flex-row justify-center item-center">
									<img src={appDefinition.logo} className="w-16 max-h-24 object-scale-down mt-2" />
									<div className="flex flex-col mt-6 ml-3">
										<p className="text-3xl text-white font-medium">{t("Info.header.supplyR")}</p>
										<p className="text-medium w-max text-white tracking-widest uppercase">{t("Info.header.bv")}</p>
									</div>
								</div>
							</SupplyRInfoPageHeader>

							<div data-test="Info" className="-mt-9">
								<Tabs tabSet={infoTabSet} locales={appDefinition.locales} page="Info"/>
							</div>

						</Div100vh>
					</IF>

					{/*
                      * TERMS PAGE
                      */}
					<IF condition={menu === "TERMs"}>
						<Div100vh className="overflow-y-auto ">
							<SupplyRTermPageHeader
								appSlice={appSlice}
								page="TermsPage"
								Picto={GavelIcon}
								locales={appDefinition.locales} />

							<div className="flex flex-col w-full justify-center -mt-12 mb-12 px-4 md:px-8 space-y-8 2xl:px-12">
								<Tabs tabSet={termsTabSet} locales={appDefinition.locales}/>
							</div>
						</Div100vh>
					</IF>

				</div>
			</div>

		</div>
	);
}

export default SupplyR;
