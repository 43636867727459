/* eslint-disable react/prop-types */
import BusinessIcon from "@mui/icons-material/Business";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SettingsIcon from "@mui/icons-material/Settings";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PublicIcon from "@mui/icons-material/Public";

import LegalNotice from "../../termsLocales/en/RYBLegalNotice";
import Terms from "../../termsLocales/en/PrivateAppTerms";
import Privacy from "../../termsLocales/en/RYBPrivacy";
import Cookies from "../../termsLocales/en/RYBCookies";

import getTreeMapData from "../../components/tree/SCATreeMapChildren";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} slice Name of theS slice related to the app created and references in the store ./store.js
 * @param {String} logo Label of the menu, if none, only the picto
 */
export const appDefinition = {
	name: "Supply Chain Analytics",
	subName: "",
	launcherID: "SCA",
	slice: "sca",
	locales: "translationsSCA",
	theme: "supplyr",
	favicon: "/images/SupplyR/favicon.ico",
	logo: "/images/SupplyR/icon-menu-supply-r.svg"
};

/**
 * Set up  available language to display
 */
export const langListValue = [{
	value: "en",
	label: "EN"
},
{
	value: "fr",
	label: "FR"
},
{
	value: "zh-Hans",
	label: "简体"
},
{
	value: "zh-Hant",
	label: "繁體"
}
];

/**
 * Set up  menu options to display
 *
 * @param {String} menu ID of the menu (use for the initial menu definition and the render of the app)
 * @param {String} type Route or App = Route is a link to jump to a new url, App is a internal link to the App to display specific information
 * @param {String} url [OPTIONAL] If type == url, the new url to link the menu (it has to be routed in ./App.js)
 * @param {String} label [OPTIONAL] Label of the menu, if none, only the picto
 * @param {String} sectionTitleWhite [OPTIONAL] Section title in white displayed on top of the page when the menu is clicked
 * @param {String} sectionTitleBlack [OPTIONAL] Section title in black displayed on top of the page when the menu is clicked
 * @param {Component} picto Material UI Icon to represent the field in the select, to be imported in this file
 */
export const menuOptions = [{
	menu: "HOME",
	type: "App",
	initial: true,
	label: "Home",
	clearRefine: ["equipmentCategory", "inspectionTasksL1", "manufacturer"],
	picto: AccountTreeIcon
},
{
	menu: "EQUIPMENT",
	type: "App",
	initial: false,
	label: "Equipments",
	clearRefine: ["equipmentCategory", "inspectionTasksL1", "manufacturer"],
	picto: SettingsIcon
},
{
	menu: "INSPECTION",
	type: "App",
	initial: false,
	label: "Inspection activities",
	clearRefine: ["equipmentCategory", "inspectionTasksL1", "manufacturer"],
	picto: EngineeringIcon
},
{
	menu: "COUNTRY",
	type: "App",
	initial: false,
	label: "Countries",
	clearRefine: ["equipmentCategory", "inspectionTasksL1", "manufacturer"],
	picto: PublicIcon
},
{
	menu: "SITES",
	type: "App",
	initial: false,
	label: "Suppliers",
	clearRefine: ["equipmentCategory", "inspectionTasksL1", "manufacturer"],
	picto: BusinessIcon
},
{
	menu: "INFO",
	type: "App",
	label: "Info",
	picto: ErrorOutlineIcon
}
];

/**
 * Name of the dataset with the client parameters
 */
const SCAClientParameters = "SCAClientParameters";

/**
 * Name of the dataset Sites for the SCA web app
 */

const SCASites = "SCASites";
const SCAAssignedForm = "SCAAssignedForm";
const SCACVTSData = "SCACVTSData";
const SCACVTSCountries = "SCACVTSCountries";

/**
 * Arry of the full list of facets to refine
 */
export const listFacetsToRefine = ["client", "region", "country", "date", "equipmentCategory", "inspectionTasksL1", "manufacturer", "year", "stage"];

/**
 * Client parameters
 */
export const clientInitParameters = {
	name: "clientParameters",
	collection: SCAClientParameters,
	facetsToRefine: [],
	fetch: "find",
	limit: 1
};

/**
 * Function to refresh the list of nonConformities after a click on a new page
 *
 * @param {Number} value Page to refresh the list of sites
 */

/**
 * Active DataSets definition for the app
 *
 * @param {String} name
 * @param {String} collection
 * @param {String} fetch
 * @param {String[]} facetsToRefine
 * @param {Object[]} query
 * @param {Number} limit
 * @param {Object[]} data
 */
export const SCAInitDatasets = {
	/* listProjects: {
		collection: SCAClientParameters,
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						projectName: "$projectName"
					},
					nbProjectName: { $sum: 1 }
				}
			},
			{
				$project: {
					projectName: "$_id.projectName",
					nbProjectName: 1,
					_id: 0
				}
			}
		],
		limit: 500
	}, */
	listGeographies: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "country" &&
			element !== "region"),
		query: [{
			$project: {
				"option.label": "$country",
				"option.value": "$country",
				region: "$region"
			}
		}, {
			$group: {
				_id: "$region",
				options: {
					$addToSet: "$option"
				}
			}
		}, {
			$addFields: {
				label: "$_id",
				value: "$_id",
				refine: "region"
			}
		}, {
			$match: {
				_id: {
					$nin: ["undefined", null]
				}
			}
		}],
		limit: 500
	},
	listClients: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "client"),
		query: [{
			$match: {
				client: {
					$ne: null
				}
			}
		}, {
			$group: {
				_id: {
					client: "$client"
				},
				nbClients: {
					$sum: 1
				}
			}
		}, {
			$project: {
				client: "$_id.client",
				nbSites: "$nbClients",
				_id: 0
			}
		}, {
			$sort: {
				nbSites: -1
			}
		}],
		limit: 100
	},
	// listDates: {
	// 	collection: SCACVTSData,
	// 	fetch: "aggregation",
	// 	facetsToRefine: listFacetsToRefine.filter((element) => element !== "date"),
	// 	query: [{
	// 		$match: {
	// 			date: { $ne: null }
	// 		}
	// 	}, {
	// 		$group: {
	// 			_id: "$date",
	// 			date: {
	// 				$first: "$date"
	// 			},
	// 			inspectionCount: {
	// 				$sum: 1
	// 			},
	// 			nbFindings: {
	// 				$sum: "$nbFindings"
	// 			},
	// 			nbFailed: {
	// 				$sum: "$result"
	// 			}
	// 		}
	// 	}, {
	// 		$addFields: {
	// 			findingRatio: {
	// 				$divide: [
	// 					"$nbFindings",
	// 					"$inspectionCount"
	// 				]
	// 			},
	// 			nbFailed: {
	// 				$subtract: [
	// 					0,
	// 					"$nbFailed"
	// 				]
	// 			}
	// 		}
	// 	}],
	// 	limit: 100
	// },
	listYear: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "year"),
		query: [{
			$group: {
				_id: {
					year: { $year: "$inspectionDate" }
				},
				count: {
					$sum: 1
				}
			}
		},
		{
			$project: {
				year: "$_id.year",
				_id: 0
			}
		},
		{
			$match: {
				year: {
					$nin: [
						"undefined", null
					]
				}
			}
		}],
		limit: 500
	},
	listEquipmentCategory: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "equipmentCategory"),
		query: [{
			$unwind: {
				path: "$equipmentCategory",
				preserveNullAndEmptyArrays: false
			}
		}, {
			$match: {
				equipmentCategory: { $ne: null }
			}
		}, {
			$group: {
				_id: "$equipmentCategory",
				equipmentCategory: {
					$first: "$equipmentCategory"
				},
				inspectionCount: {
					$sum: 1
				}
			}
		}, {
			$sort: {
				inspectionCount: -1
			}
		}],
		limit: 50
	},
	listFindingCategories: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$unwind: {
					path: "$findings",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$group: {
					_id: {
						$ifNull: [
							"$findings.defectLevel2", "$findings.defectLevel1"
						]
					}
				}
			}, {
				$project: {
					_id: {
						$ifNull: [
							"$_id", "Unspecified"
						]
					}
				}
			}
		]
	},
	listInspectionTasks: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$unwind: {
					path: "$inspectionTasksL1",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$match: {
					inspectionTasksL1: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: {
						$ifNull: [
							"$inspectionTasksL1", "Unspecified"
						]
					},
					count: {
						$sum: 1
					}
				}
			}, {
				$project: {
					inspectionTasksL1: "$_id",
					count: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	findingCategoriesAndRatio: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$match: {
				year: {
					$ne: null
				}
			}
		}, {
			$unwind: {
				path: "$findings",
				preserveNullAndEmptyArrays: false
			}
		}, {
			$project: {
				_id: 0,
				year: {
					$year: "$inspectionDate"
				},
				month: {
					$month: "$inspectionDate"
				},
				categories: {
					$ifNull: [
						"$findings.defectLevel2",
						"$findings.defectLevel1"
					]
				}
			}
		}, {
			$group: {
				_id: {
					year: "$year",
					month: "$month"
				},
				totalFindings: {
					$sum: 1
				},
				categories: {
					$push: {
						$ifNull: [
							"$categories", "Unspecified"
						]
					}
				}
			}
		}, {
			$unwind: {
				path: "$categories",
				preserveNullAndEmptyArrays: false
			}
		}, {
			$group: {
				_id: {
					year: "$_id.year",
					month: "$_id.month",
					categories: "$categories",
					totalFindings: "$totalFindings"
				},
				sumFindings: {
					$sum: 1
				}
			}
		}, {
			$project: {
				_id: 0,
				year: "$_id.year",
				month: "$_id.month",
				totalFindings: "$_id.totalFindings",
				categories: "$_id.categories",
				ratio: {
					$divide: [
						"$sumFindings",
						"$_id.totalFindings"
					]
				}
			}
		}, {
			$project: {
				_id: 0,
				year: "$year",
				month: "$month",
				totalFindings: "$totalFindings",
				categories: [
					"$categories",
					"$ratio"
				]
			}
		}, {
			$group: {
				_id: {
					year: "$year",
					month: "$month",
					totalFindings: "$totalFindings"
				},
				categories: {
					$push: "$categories"
				}
			}
		}, {
			$project: {
				_id: 0,
				year: "$_id.year",
				month: "$_id.month",
				totalFindings: "$_id.totalFindings",
				categories: {
					$arrayToObject: "$categories"
				}
			}
		}, {
			$replaceRoot: {
				newRoot: {
					$mergeObjects: [{
						year: "$year",
						month: "$month",
						totalFindings: "$totalFindings"
					},
					"$categories"
					]
				}
			}
		}],
		limit: 500
	},
	findingCategoriesAndRatioPerQuarter: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$match: {
				year: {
					$ne: null
				}
			}
		}, {
			$unwind: {
				path: "$findings",
				preserveNullAndEmptyArrays: false
			}
		}, {
			$project: {
				_id: 0,
				client: 1,
				year: {
					$year: "$inspectionDate"
				},
				quarter: {
					$cond: [{
						$lte: [{
							$month: "$inspectionDate"
						},
						3
						]
					},
					1,
					{
						$cond: [{
							$lte: [{
								$month: "$inspectionDate"
							},
							6
							]
						},
						2,
						{
							$cond: [{
								$lte: [{
									$month: "$inspectionDate"
								},
								9
								]
							},
							3,
							4
							]
						}
						]
					}
					]
				},
				categories: {
					$ifNull: [
						"$findings.defectLevel2",
						"$findings.defectLevel1"
					]
				}
			}
		}, {
			$group: {
				_id: {
					year: "$year",
					quarter: "$quarter"
				},
				totalFindings: {
					$sum: 1
				},
				categories: {
					$push: {
						$ifNull: [
							"$categories", "Unspecified"
						]
					}
				}
			}
		}, {
			$unwind: {
				path: "$categories",
				preserveNullAndEmptyArrays: false
			}
		}, {
			$group: {
				_id: {
					year: "$_id.year",
					quarter: "$_id.quarter",
					categories: "$categories",
					totalFindings: "$totalFindings"
				},
				sumFindings: {
					$sum: 1
				}
			}
		}, {
			$project: {
				_id: 0,
				year: "$_id.year",
				quarter: "$_id.quarter",
				totalFindings: "$_id.totalFindings",
				categories: "$_id.categories",
				ratio: {
					$divide: [
						"$sumFindings",
						"$_id.totalFindings"
					]
				}
			}
		}, {
			$project: {
				_id: 0,
				year: "$year",
				quarter: "$quarter",
				totalFindings: "$totalFindings",
				categories: [
					"$categories",
					"$ratio"
				]
			}
		}, {
			$group: {
				_id: {
					year: "$year",
					quarter: "$quarter",
					totalFindings: "$totalFindings"
				},
				categories: {
					$push: "$categories"
				}
			}
		}, {
			$project: {
				_id: 0,
				year: "$_id.year",
				quarter: "$_id.quarter",
				totalFindings: "$_id.totalFindings",
				categories: {
					$arrayToObject: "$categories"
				}
			}
		}, {
			$replaceRoot: {
				newRoot: {
					$mergeObjects: [{
						year: "$year",
						quarter: "$quarter",
						totalFindings: "$totalFindings"
					},
					"$categories"
					]
				}
			}
		}],
		limit: 500
	},
	inspectionTasksRatio: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					year: {
						$ne: null
					}
				}
			}, {
				$unwind: {
					path: "$inspectionTasksL1",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$project: {
					_id: 0,
					year: {
						$year: "$inspectionDate"
					},
					month: {
						$month: "$inspectionDate"
					},
					categories: "$inspectionTasksL1"
				}
			}, {
				$group: {
					_id: {
						year: "$year",
						month: "$month"
					},
					totalActivities: {
						$sum: 1
					},
					categories: {
						$push: "$categories"
					}
				}
			}, {
				$unwind: {
					path: "$categories",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$group: {
					_id: {
						year: "$_id.year",
						month: "$_id.month",
						categories: "$categories"
					},
					sumActivities: {
						$sum: 1
					},
					totalActivities: {
						$first: "$totalActivities"
					}
				}
			}, {
				$project: {
					_id: 0,
					year: "$_id.year",
					month: "$_id.month",
					totalActivities: "$totalActivities",
					categories: "$_id.categories",
					ratio: {
						$divide: [
							"$sumActivities", "$totalActivities"
						]
					}
				}
			}, {
				$project: {
					_id: 0,
					year: "$year",
					month: "$month",
					totalActivities: "$totalActivities",
					categories: [
						{
							$ifNull: [
								"$categories", "Unspecified"
							]
						}, "$ratio"
					]
				}
			}, {
				$group: {
					_id: {
						year: "$year",
						month: "$month",
						totalActivities: "$totalActivities"
					},
					categories: {
						$push: "$categories"
					}
				}
			}, {
				$project: {
					_id: 0,
					year: "$_id.year",
					month: "$_id.month",
					totalActivities: "$_id.totalActivities",
					categories: {
						$arrayToObject: "$categories"
					}
				}
			}, {
				$replaceRoot: {
					newRoot: {
						$mergeObjects: [
							{
								year: "$year",
								month: "$month",
								totalActivities: "$totalActivities"
							}, "$categories"
						]
					}
				}
			}
		],
		limit: 500
	},
	inspectionTasksRatioPerQuarter: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					year: {
						$ne: null
					}
				}
			}, {
				$unwind: {
					path: "$inspectionTasksL1",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$match: {
					inspectionTasksL1: {
						$ne: null
					}
				}
			}, {
				$project: {
					_id: 0,
					year: {
						$year: "$inspectionDate"
					},
					quarter: {
						$cond: [
							{
								$lte: [
									{
										$month: "$inspectionDate"
									}, 3
								]
							}, 1, {
								$cond: [
									{
										$lte: [
											{
												$month: "$inspectionDate"
											}, 6
										]
									}, 2, {
										$cond: [
											{
												$lte: [
													{
														$month: "$inspectionDate"
													}, 9
												]
											}, 3, 4
										]
									}
								]
							}
						]
					},
					categories: {
						$ifNull: [
							"$inspectionTasksL1", "Unspecified"
						]
					}
				}
			}, {
				$group: {
					_id: {
						year: "$year",
						quarter: "$quarter"
					},
					totalActivities: {
						$sum: 1
					},
					categories: {
						$push: "$categories"
					}
				}
			}, {
				$unwind: {
					path: "$categories",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$group: {
					_id: {
						year: "$_id.year",
						quarter: "$_id.quarter",
						categories: "$categories"
					},
					sumActivities: {
						$sum: 1
					},
					totalActivities: {
						$first: "$totalActivities"
					}
				}
			}, {
				$project: {
					_id: 0,
					year: "$_id.year",
					quarter: "$_id.quarter",
					totalActivities: "$totalActivities",
					categories: "$_id.categories",
					ratio: {
						$divide: [
							"$sumActivities", "$totalActivities"
						]
					}
				}
			}, {
				$project: {
					_id: 0,
					year: "$year",
					quarter: "$quarter",
					totalActivities: "$totalActivities",
					categories: [
						"$categories", "$ratio"
					]
				}
			}, {
				$group: {
					_id: {
						year: "$year",
						quarter: "$quarter",
						totalActivities: "$totalActivities"
					},
					categories: {
						$push: "$categories"
					}
				}
			}, {
				$project: {
					_id: 0,
					year: "$_id.year",
					quarter: "$_id.quarter",
					totalActivities: "$_id.totalActivities",
					categories: {
						$arrayToObject: "$categories"
					}
				}
			}, {
				$replaceRoot: {
					newRoot: {
						$mergeObjects: [
							{
								year: "$year",
								quarter: "$quarter",
								totalActivities: "$totalActivities"
							}, "$categories"
						]
					}
				}
			}
		],
		limit: 500
	},
	fixedStats: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: ["client"],
		query: [
			{
				$match: {
					manufacturer: {
						$nin: [
							"undefined", null
						]
					},
					site: {
						$nin: [
							"undefined", null
						]
					}
				}
			}, {
				$group: {
					_id: "",
					totalReport: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					},
					inspectionHours: {
						$sum: "$inspectionHours"
					},
					suppliers: {
						$addToSet: {
							manufacturer: "$manufacturer",
							site: "$site"
						}
					}
				}
			}, {
				$project: {
					totalReport: 1,
					totalFindings: 1,
					inspectionHours: 1,
					totalSuppliers: {
						$size: "$suppliers"
					}
				}
			}
		]
	},
	overallStats: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: ["client", "stage"],
		query: [
			{
				$group: {
					_id: {
						manufacturer: "$manufacturer",
						site: "$site"
					},
					totalReports: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					},
					nbFailed: {
						$sum: "$result"
					}
				}
			}, {
				$addFields: {
					findingRatio: {
						$divide: [
							"$totalFindings", "$totalReports"
						]
					}
				}
			}, {
				$addFields: {
					nbFailed: {
						$subtract: [
							0, "$nbFailed"
						]
					}
				}
			}, {
				$group: {
					_id: "",
					totalSuppliers: {
						$sum: 1
					},
					overallReports: {
						$sum: "$totalReports"
					},
					overallFindings: {
						$sum: "$totalFindings"
					},
					overallFailed: {
						$sum: "$nbFailed"
					},
					findingRatioArray: {
						$push: "$findingRatio"
					}
				}
			}, {
				$addFields: {
					_id: "overallStats",
					overallSuccess: {
						$subtract: [
							"$overallReports", "$overallFailed"
						]
					},
					overallFindingRatio: {
						$divide: [
							"$overallFindings", "$overallReports"
						]
					},
					overallAvgSpFindingRatio: {
						$avg: "$findingRatioArray"
					},
					overallFailureRate: {
						$divide: [
							"$overallFailed", "$overallReports"
						]
					}
				}
			}, {
				$project: {
					findingRatioArray: 0
				}
			}
		]
	},
	overallCountryStats: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: ["country"],
		query: [
			{
				$group: {
					_id: "$country",
					totalReports: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					},
					nbFailed: {
						$sum: "$result"
					}
				}
			}, {
				$addFields: {
					findingRatio: {
						$divide: [
							"$totalFindings", "$totalReports"
						]
					}
				}
			}, {
				$addFields: {
					nbFailed: {
						$subtract: [
							0, "$nbFailed"
						]
					}
				}
			}, {
				$group: {
					_id: "$_id",
					totalSuppliers: {
						$sum: 1
					},
					overallReports: {
						$sum: "$totalReports"
					},
					overallFindings: {
						$sum: "$totalFindings"
					},
					overallFailed: {
						$sum: "$nbFailed"
					},
					findingRatioArray: {
						$push: "$findingRatio"
					}
				}
			}, {
				$addFields: {
					country: "$_id",
					overallSuccess: {
						$subtract: [
							"$overallReports", "$overallFailed"
						]
					},
					overallFindingRatio: {
						$divide: [
							"$overallFindings", "$overallReports"
						]
					},
					overallAvgSpFindingRatio: {
						$avg: "$findingRatioArray"
					},
					overallFailureRate: {
						$divide: [
							"$overallFailed", "$overallReports"
						]
					}
				}
			}, {
				$project: {
					findingRatioArray: 0,
					_id: 0
				}
			}
		]
	},
	// equipmentCategoryStats: {
	// 	collection: SCACVTSData,
	// 	fetch: "aggregation",
	// 	facetsToRefine: listFacetsToRefine,
	// 	query: [{
	// 		$unwind: {
	// 			path: "$equipmentCategory",
	// 			preserveNullAndEmptyArrays: false
	// 		}
	// 	}, {
	// 		$group: {
	// 			_id: "$equipmentCategory",
	// 			equipmentCategory: {
	// 				$first: "$equipmentCategory"
	// 			},
	// 			inspectionCount: {
	// 				$sum: 1
	// 			},
	// 			nbFindings: {
	// 				$sum: "$nbFindings"
	// 			},
	// 			nbFailed: {
	// 				$sum: "$result"
	// 			},
	// 			nbManufacturers: {
	// 				$addToSet: "$manufacturer"
	// 			}
	// 		}
	// 	}, {
	// 		$addFields: {
	// 			findingRatio: {
	// 				$divide: [
	// 					"$nbFindings",
	// 					"$inspectionCount"
	// 				]
	// 			},
	// 			nbFailed: {
	// 				$subtract: [
	// 					0,
	// 					"$nbFailed"
	// 				]
	// 			},
	// 			nbManufacturers: {
	// 				$size: "$nbManufacturers"
	// 			}
	// 		}
	// 	}],
	// 	limit: 500
	// },
	fixedEquipmentCategoryStats: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$unwind: {
				path: "$equipmentCategory",
				preserveNullAndEmptyArrays: false
			}
		}, {
			$match: {
				equipmentCategory: {
					$ne: null
				}
			}
		}, {
			$group: {
				_id: "$equipmentCategory",
				equipmentCategory: {
					$first: "$equipmentCategory"
				},
				inspectionCount: {
					$sum: 1
				},
				nbFindings: {
					$sum: "$nbFindings"
				},
				nbFailed: {
					$sum: "$result"
				},
				nbManufacturers: {
					$addToSet: "$manufacturer"
				}
			}
		}, {
			$addFields: {
				findingRatio: {
					$divide: [
						"$nbFindings",
						"$inspectionCount"
					]
				},
				nbFailed: {
					$subtract: [
						0,
						"$nbFailed"
					]
				},
				nbManufacturers: {
					$size: "$nbManufacturers"
				}
			}
		}, {
			$addFields: {
				nbSuccess: {
					$subtract: [
						"$inspectionCount",
						"$nbFailed"
					]
				},
				failureRate: {
					$divide: [
						"$nbFailed",
						"$inspectionCount"
					]
				}
			}
		}, {
			$sort: {
				inspectionCount: -1
			}
		}],
		limit: 10
	},
	countryStats: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$group: {
				_id: "$countryUpperCase",
				inspectionCount: {
					$sum: 1
				},
				nbFindings: {
					$sum: "$nbFindings"
				},
				nbActivities: {
					$sum: "$nbActivites"
				},
				failedInspections: {
					$sum: "$result"
				},
				manufacturers: {
					$addToSet: "$manufacturer"
				},
				equipmentCategories: {
					$addToSet: "$equipmentCategory"
				}
			}
		}, {
			$addFields: {
				findingRatio: {
					$divide: [
						"$nbFindings", "$inspectionCount"
					]
				},
				nbManufacturers: {
					$size: "$manufacturers"
				},
				failedInspections: {
					$multiply: [
						"$failedInspections", -1
					]
				}
			}
		}, {
			$lookup: {
				from: "worldGeoJsonCountries",
				localField: "_id",
				foreignField: "countryUpperCase",
				as: "countryGeometry"
			}
		}, {
			$addFields: {
				geometry: {
					$arrayElemAt: [
						"$countryGeometry.geometry", 0
					]
				},
				region: {
					$arrayElemAt: [
						"$countryGeometry.region", 0
					]
				}
			}
		}, {
			$project: {
				countryGeometry: 0
			}
		}],
		limit: 500
	},
	stageType: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$group: {
				_id: "$stageType",
				stageType: {
					$first: "$stageType"
				},
				frequency: {
					$sum: 1
				}
			}
		}]
	},
	stageStats: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: ["client"],
		query: [
			{
				$group: {
					_id: "",
					totalDocs: {
						$sum: 1
					},
					nbFailed: {
						$sum: "$result"
					},
					stage: {
						$push: "$stage"
					}
				}
			}, {
				$addFields: {
					nbFailed: {
						$subtract: [
							0, "$nbFailed"
						]
					}
				}
			}, {
				$unwind: {
					path: "$stage"
				}
			}, {
				$group: {
					_id: {
						stage: "$stage",
						totalDocs: "$totalDocs",
						nbFailed: "$nbFailed"
					},
					count: {
						$sum: 1
					}
				}
			}, {
				$project: {
					stage: "$_id.stage",
					stageCount: "$count",
					percentage: {
						$multiply: [
							{
								$divide: [
									"$count", "$_id.totalDocs"
								]
							}, 100
						]
					},
					totalFailed: "$_id.nbFailed",
					totalDocs: "$_id.totalDocs",
					diffCount: {
						$subtract: [
							"$_id.totalDocs", "$count"
						]
					},
					_id: 0
				}
			}
		]
	},
	inspectTreeMapByLv1: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		facetsToTransform: { inspectionTasksL1: "findings.inspectionTaskL1" },
		insertIndex: 3,
		transformLogic: "and",
		pages: ["HOME", "INSPECTION", "SITES"],
		query: [
			{
				$unwind: {
					path: "$findings",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$match: {
					findings: {
						$exists: true,
						$not: {
							$size: 0
						}
					},
					"findings.defectLevel1": {
						$nin: [
							null, "Refer"
						]
					},
					"findings.inspectionTaskL2": {
						$ne: null
					}
				}
			}, {
				$project: {
					"findings.inspectionTaskL1": "$findings.inspectionTaskL1",
					"findings.inspectionTaskL2": "$findings.inspectionTaskL2",
					"findings.defectLevel1": "$findings.defectLevel1"
				}
			}, {
				$match: {
					"findings.inspectionTaskL2": {
						$nin: [
							"undefined", null
						]
					}
				}
			}, {
				$group: {
					_id: {
						inspectionTaskL2: "$findings.inspectionTaskL2"
					},
					defectLevel1: {
						$addToSet: "$findings.defectLevel1"
					},
					nbDoc: {
						$sum: 1
					}
				}
			}, {
				$project: {
					inspectionTasksL2: "$_id.inspectionTaskL2",
					lv1Children: "$defectLevel1",
					_id: 0
				}
			}
		],
		limit: 100
	},
	inspectTreeMapByLv12: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		facetsToTransform: { inspectionTasksL1: "findings.inspectionTaskL1" },
		insertIndex: 4,
		transformLogic: "and",
		pages: ["HOME", "INSPECTION", "SITES"],
		query: [
			{
				$unwind: {
					path: "$findings",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$match: {
					findings: {
						$exists: true,
						$not: {
							$size: 0
						}
					},
					"findings.defectLevel1": {
						$nin: [
							null, "Refer"
						]
					},
					"findings.inspectionTaskL2": {
						$ne: null
					}
				}
			}, {
				$project: {
					"findings.inspectionTaskL1": "$findings.inspectionTaskL1",
					"findings.inspectionTaskL2": "$findings.inspectionTaskL2",
					defectLevel1: "$findings.defectLevel1",
					defectLevel2: "$findings.defectLevel2"
				}
			}, {
				$group: {
					_id: {
						inspectionTaskL2: "$findings.inspectionTaskL2",
						defectLevel1: "$defectLevel1"
					},
					defectLevel2: {
						$push: "$defectLevel2"
					},
					nbLvl2: {
						$sum: 1
					}
				}
			}, {
				$project: {
					inspectionTasksL2: "$_id.inspectionTaskL2",
					defectLevel1: "$_id.defectLevel1",
					lv2Children: "$defectLevel2",
					nbLvl2: 1,
					_id: 0
				}
			}
		],
		limit: 20000
	},
	equipCatTreeMapByLv1: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		facetsToTransform: { inspectionTasksL1: "findings.inspectionTaskL1" },
		insertIndex: 6,
		transformLogic: "and",
		pages: ["EQUIPMENT"],
		query: [
			{
				$match: {
					"findings.defectLevel1": {
						$nin: [
							null, "Refer"
						]
					},
					equipmentCategory: {
						$exists: true,
						$not: {
							$size: 0
						}
					},
					findings: {
						$exists: true,
						$not: {
							$size: 0
						}
					}
				}
			}, {
				$unwind: {
					path: "$findings",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$project: {
					equipmentCategory: "$equipmentCategory",
					"findings.defectLevel1": "$findings.defectLevel1"
				}
			}, {
				$unwind: {
					path: "$equipmentCategory",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$match: {
					equipmentCategory: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: {
						equipmentCategory: "$equipmentCategory"
					},
					defectLevel1: {
						$addToSet: "$findings.defectLevel1"
					},
					nbDoc: {
						$sum: 1
					}
				}
			}, {
				$project: {
					equipmentCategory: "$_id.equipmentCategory",
					lv1Children: "$defectLevel1",
					_id: 0
				}
			}
		],
		limit: 100
	},
	equipCatTreeMapByLv12: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		facetsToTransform: { inspectionTasksL1: "findings.inspectionTaskL1", equipmentCategory: "equipmentCategory" },
		insertIndex: 4,
		transformLogic: "and",
		pages: ["EQUIPMENT"],
		query: [
			{
				$match: {
					"findings.defectLevel1": {
						$nin: [
							null, "Refer"
						]
					},
					equipmentCategory: {
						$exists: true,
						$not: {
							$size: 0
						}
					},
					findings: {
						$exists: true,
						$not: {
							$size: 0
						}
					}
				}
			}, {
				$unwind: {
					path: "$findings",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$unwind: {
					path: "$equipmentCategory",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$project: {
					equipmentCategory: "$equipmentCategory",
					defectLevel1: "$findings.defectLevel1",
					defectLevel2: "$findings.defectLevel2"
				}
			}, {
				$group: {
					_id: {
						equipmentCategory: "$equipmentCategory",
						defectLevel1: "$defectLevel1"
					},
					defectLevel2: {
						$push: "$defectLevel2"
					},
					nbLvl2: {
						$sum: 1
					}
				}
			}, {
				$project: {
					equipmentCategory: "$_id.equipmentCategory",
					defectLevel1: "$_id.defectLevel1",
					lv2Children: "$defectLevel2",
					nbLvl2: 1,
					_id: 0
				}
			}
		],
		limit: 20000
	},
	countryTreeMapByLv1: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "country"),
		facetsToTransform: { inspectionTasksL1: "findings.inspectionTaskL1", equipmentCategory: "equipmentCategory" },
		insertIndex: 3,
		transformLogic: "and",
		pages: ["COUNTRY"],
		query: [
			{
				$match: {
					findings: {
						$exists: true,
						$not: {
							$size: 0
						}
					},
					"findings.defectLevel2": {
						$nin: [
							null, "Refer"
						]
					},
					"findings.inspectionTaskL1": {
						$ne: null
					},
					country: {
						$nin: [
							"undefined", null, ""
						]
					}
				}
			}, {
				$unwind: {
					path: "$findings",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$project: {
					"findings.inspectionTaskL1": "$findings.inspectionTaskL1",
					"findings.defectLevel2": "$findings.defectLevel2"
				}
			}, {
				$match: {
					"findings.inspectionTaskL1": {
						$nin: [
							"undefined", null
						]
					}
				}
			}, {
				$group: {
					_id: {
						inspectionTaskL1: "$findings.inspectionTaskL1"
					},
					defectLevel2: {
						$push: "$findings.defectLevel2"
					},
					nbDoc: {
						$sum: 1
					}
				}
			}, {
				$project: {
					inspectionTaskL1: "$_id.inspectionTaskL1",
					lv1Children: "$defectLevel2",
					_id: 0
				}
			}
		],
		limit: 100
	},
	countryTreeMapByLv12: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "country"),
		facetsToTransform: { inspectionTasksL1: "findings.inspectionTaskL1" },
		insertIndex: 3,
		transformLogic: "and",
		pages: ["COUNTRY"],
		query: [
			{
				$match: {
					findings: {
						$exists: true,
						$not: {
							$size: 0
						}
					},
					"findings.defectLevel2": {
						$nin: [
							null, "Refer"
						]
					},
					"findings.inspectionTaskL1": {
						$ne: null
					},
					country: {
						$nin: [
							"undefined", null, ""
						]
					}
				}
			}, {
				$unwind: {
					path: "$findings",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$project: {
					"findings.inspectionTaskL1": "$findings.inspectionTaskL1",
					defectLevel2: "$findings.defectLevel2",
					country: "$country"
				}
			}, {
				$group: {
					_id: {
						inspectionTaskL1: "$findings.inspectionTaskL1",
						defectLevel2: "$defectLevel2"
					},
					country: {
						$push: "$country"
					},
					nbLvl2: {
						$sum: 1
					}
				}
			}, {
				$project: {
					inspectionTaskL1: "$_id.inspectionTaskL1",
					defectLevel2: "$_id.defectLevel2",
					lv2Children: "$country",
					nbLvl2: 1,
					_id: 0
				}
			}
		],
		limit: 20000
	},
	findingRatioPerMon: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$group: {
				_id: {
					month: {
						$month: "$inspectionDate"
					},
					year: {
						$year: "$inspectionDate"
					}
				},
				totalReport: {
					$sum: 1
				},
				totalFindings: {
					$sum: "$nbFindings"
				}
			}
		}, {
			$project: {
				month: "$_id.month",
				year: "$_id.year",
				totalReport: 1,
				totalFindings: 1,
				ratio: {
					$divide: [
						"$totalFindings", "$totalReport"
					]
				},
				_id: 0
			}
		}]
	},
	findingRatioPerQuarter: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						quarter: {
							$cond: [
								{
									$lte: [
										{
											$month: "$inspectionDate"
										}, 3
									]
								}, 1, {
									$cond: [
										{
											$lte: [
												{
													$month: "$inspectionDate"
												}, 6
											]
										}, 2, {
											$cond: [
												{
													$lte: [
														{
															$month: "$inspectionDate"
														}, 9
													]
												}, 3, 4
											]
										}
									]
								}
							]
						},
						year: {
							$year: "$inspectionDate"
						}
					},
					totalReport: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					}
				}
			}, {
				$project: {
					quarter: "$_id.quarter",
					year: "$_id.year",
					totalReport: 1,
					totalFindings: 1,
					ratio: {
						$divide: [
							"$totalFindings", "$totalReport"
						]
					},
					_id: 0
				}
			}, {
				$match: {
					year: {
						$ne: null
					},
					quarter: {
						$ne: null
					}
				}
			}
		]
	},
	// TODO: 4.fix percentage times 100?
	globalFindingRatioPerMon: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "equipmentCategory"
			&& element !== "inspectionTasksL1" && element !== "country"),
		query: [
			{
				$group: {
					_id: {
						month: {
							$month: "$inspectionDate"
						},
						year: {
							$year: "$inspectionDate"
						}
					},
					totalReport: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					}
				}
			}, {
				$project: {
					totalReport: 1,
					totalFindings: 1,
					month: "$_id.month",
					year: "$_id.year",
					ratio: {
						$divide: [
							"$totalFindings", "$totalReport"
						]
					},
					_id: 0
				}
			}
			// {
			// 	$project: {
			// 		totalReport: 1,
			// 		totalFindings: 1,
			// 		month: 1,
			// 		year: 1,
			// 		ratio: {
			// 			$multiply: [
			// 				"$ratio", 100
			// 			]
			// 		},
			// 		_id: 0
			// 	}
			// }
		]
	},
	globalFindingRatioPerQuarter: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "equipmentCategory"
			&& element !== "inspectionTasksL1" && element !== "country"),
		query: [
			{
				$group: {
					_id: {
						quarter: {
							$cond: [
								{
									$lte: [
										{
											$month: "$inspectionDate"
										}, 3
									]
								}, 1, {
									$cond: [
										{
											$lte: [
												{
													$month: "$inspectionDate"
												}, 6
											]
										}, 2, {
											$cond: [
												{
													$lte: [
														{
															$month: "$inspectionDate"
														}, 9
													]
												}, 3, 4
											]
										}
									]
								}
							]
						},
						year: {
							$year: "$inspectionDate"
						}
					},
					totalReport: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					}
				}
			}, {
				$project: {
					totalReport: 1,
					totalFindings: 1,
					quarter: "$_id.quarter",
					year: "$_id.year",
					ratio: {
						$divide: [
							"$totalFindings", "$totalReport"
						]
					},
					_id: 0
				}
			}, {
				$match: {
					year: {
						$ne: null
					},
					quarter: {
						$ne: null
					}
				}
			}
		]
	},
	// TODO: 3.check usage push or addtoset in supplier part and confirm the ratio formula
	supplierPerMon: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						month: {
							$month: "$inspectionDate"
						},
						year: {
							$year: "$inspectionDate"
						}
					},
					suppliers: {
						$addToSet: "$manufacturer"
					},
					totalReport: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					}
				}
			}, {
				$project: {
					month: "$_id.month",
					year: "$_id.year",
					nbSuppliers: {
						$size: "$suppliers"
					},
					totalReport: 1,
					totalFindings: 1,
					_id: 0
				}
			}, {
				$addFields: {
					ratio: {
						$divide: [
							"$totalFindings", "$nbSuppliers"
						]
					}
				}
			}, {
				$match: {
					year: {
						$ne: null
					},
					quarter: {
						$ne: null
					}
				}
			}
		]
	},
	supplierPerQuarter: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						quarter: {
							$cond: [
								{
									$lte: [
										{
											$month: "$inspectionDate"
										}, 3
									]
								}, 1, {
									$cond: [
										{
											$lte: [
												{
													$month: "$inspectionDate"
												}, 6
											]
										}, 2, {
											$cond: [
												{
													$lte: [
														{
															$month: "$inspectionDate"
														}, 9
													]
												}, 3, 4
											]
										}
									]
								}
							]
						},
						year: {
							$year: "$inspectionDate"
						}
					},
					suppliers: {
						$addToSet: "$manufacturer"
					},
					totalReport: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					}
				}
			}, {
				$project: {
					quarter: "$_id.quarter",
					year: "$_id.year",
					nbSuppliers: {
						$size: "$suppliers"
					},
					totalReport: 1,
					totalFindings: 1,
					_id: 0
				}
			}, {
				$addFields: {
					ratio: {
						$divide: [
							"$totalFindings", "$nbSuppliers"
						]
					}
				}
			}, {
				$match: {
					year: {
						$ne: null
					},
					quarter: {
						$ne: null
					}
				}
			}
		]
	},
	// TODO: 3.check usage push or addtoset in supplier part and confirm the ratio formula
	globalSupplierPerMon: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "equipmentCategory"
			&& element !== "inspectionTasksL1" && element !== "country"),
		query: [
			{
				$group: {
					_id: {
						month: {
							$month: "$inspectionDate"
						},
						year: {
							$year: "$inspectionDate"
						}
					},
					suppliers: {
						$addToSet: "$manufacturer"
					},
					totalReport: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					}
				}
			}, {
				$project: {
					month: "$_id.month",
					year: "$_id.year",
					nbSuppliers: {
						$size: "$suppliers"
					},
					totalReport: 1,
					totalFindings: 1,
					_id: 0
				}
			}, {
				$addFields: {
					ratio: {
						$divide: [
							"$totalFindings", "$nbSuppliers"
						]
					}
				}
			}
		]
	},
	globalSupplierPerQuarter: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "equipmentCategory"
			&& element !== "inspectionTasksL1" && element !== "country"),
		query: [
			{
				$group: {
					_id: {
						quarter: {
							$cond: [
								{
									$lte: [
										{
											$month: "$inspectionDate"
										}, 3
									]
								}, 1, {
									$cond: [
										{
											$lte: [
												{
													$month: "$inspectionDate"
												}, 6
											]
										}, 2, {
											$cond: [
												{
													$lte: [
														{
															$month: "$inspectionDate"
														}, 9
													]
												}, 3, 4
											]
										}
									]
								}
							]
						},
						year: {
							$year: "$inspectionDate"
						}
					},
					suppliers: {
						$addToSet: "$manufacturer"
					},
					totalReport: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					}
				}
			}, {
				$project: {
					quarter: "$_id.quarter",
					year: "$_id.year",
					nbSuppliers: {
						$size: "$suppliers"
					},
					totalReport: 1,
					totalFindings: 1,
					_id: 0
				}
			}, {
				$addFields: {
					ratio: {
						$divide: [
							"$totalFindings", "$nbSuppliers"
						]
					}
				}
			}, {
				$match: {
					year: {
						$ne: null
					},
					quarter: {
						$ne: null
					}
				}
			}
		]
	},
	// TODO: 5.check correctness of aggregation
	sites: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					manufacturer: {
						$nin: [
							"undefined", null
						]
					},
					site: {
						$nin: [
							"undefined", null
						]
					}
				}
			}, {
				$group: {
					_id: {
						manufacturer: "$manufacturer",
						site: "$site",
						equipmentCategory: "$equipmentCategory"
					},
					country: {
						$first: "$country"
					},
					city: {
						$first: "$city"
					},
					totalFindings: {
						$sum: "$nbFindings"
					},
					totalReports: {
						$sum: 1
					},
					nbFailed: {
						$sum: "$result"
					}
				}
			}, {
				$sort: {
					totalReports: -1
				}
			}, {
				$group: {
					_id: {
						manufacturer: "$_id.manufacturer",
						site: "$_id.site"
					},
					country: {
						$first: "$country"
					},
					city: {
						$first: "$city"
					},
					equipmentCategory: {
						$first: "$_id.equipmentCategory"
					},
					totalFindings: {
						$sum: "$totalFindings"
					},
					totalReports: {
						$sum: "$totalReports"
					},
					nbFailed: {
						$sum: "$nbFailed"
					},
					allEqpmntCats: {
						$push: "$_id.equipmentCategory"
					}
				}
			}, {
				$project: {
					manufacturer: "$_id.manufacturer",
					site: "$_id.site",
					country: 1,
					city: 1,
					equipmentCategory: {
						$ifNull: [
							"$equipmentCategory", {
								$arrayElemAt: [
									"$allEqpmntCats", 0
								]
							}
						]
					},
					totalFindings: 1,
					totalReports: 1,
					nbFailed: {
						$subtract: [
							0, "$nbFailed"
						]
					},
					findingRatio: {
						$divide: [
							"$totalFindings", "$totalReports"
						]
					},
					failureRate: {
						$subtract: [
							0, {
								$divide: [
									"$nbFailed", "$totalReports"
								]
							}
						]
					},
					_id: 0
				}
			}
		],
		limit: 6000
	},
	siteGeometries: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$match: {
				manufacturer: {
					$nin: [
						"undefined",
						null
					]
				},
				site: {
					$nin: [
						"undefined",
						null
					]
				},
				"geometry.coordinates": {
					$ne: [
						null,
						null
					]
				}
			}
		}, {
			$group: {
				_id: {
					manufacturer: "$manufacturer",
					site: "$site",
					geometry: "$geometry"
				},
				country: {
					$first: "$country"
				},
				city: {
					$first: "$city"
				},
				equipmentCategory: {
					$addToSet: "$equipmentCategory"
				},
				totalFindings: {
					$sum: "$nbFindings"
				},
				totalReports: {
					$sum: 1
				},
				nbFailed: {
					$sum: "$result"
				},
				geometry: {
					$first: "$geometry"
				}
			}
		}, {
			$project: {
				manufacturer: "$_id.manufacturer",
				site: "$_id.site",
				country: 1,
				city: 1,
				equipmentCategory: 1,
				totalFindings: 1,
				totalReports: 1,
				nbFailed: {
					$subtract: [
						0,
						"$nbFailed"
					]
				},
				findingRatio: {
					$divide: [
						"$totalFindings",
						"$totalReports"
					]
				},
				failureRate: {
					$subtract: [
						0,
						{
							$divide: [
								"$nbFailed",
								"$totalReports"
							]
						}
					]
				},
				geometry: 1,
				_id: 0
			}
		}],
		limit: 100
	},
	inspectionTaskLv1Info: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$addFields: {
				inspectionTasksL1: {
					$setIntersection: [
						"$inspectionTasksL1",
						"$inspectionTasksL1"
					]
				}
			}
		}, {
			$unwind: {
				path: "$inspectionTasksL1",
				preserveNullAndEmptyArrays: false
			}
		}, {
			$match: {
				inspectionTasksL1: {
					$ne: null
				}
			}
		}, {
			$group: {
				_id: "$inspectionTasksL1",
				nbReports: {
					$sum: 1
				},
				nbFailed: {
					$sum: "$result"
				},
				nbFindings: {
					$sum: "$nbFindings"
				}
			}
		}, {
			$project: {
				inspectionTasksL1: "$_id",
				nbReports: 1,
				nbFailed: {
					$subtract: [
						0,
						"$nbFailed"
					]
				},
				failureRate: {
					$subtract: [
						0,
						{
							$divide: [
								"$nbFailed",
								"$nbReports"
							]
						}
					]
				},
				findingRatio: {
					$divide: [
						"$nbFindings",
						"$nbReports"
					]
				},
				_id: 0
			}
		}],
		limit: 500
	},
	inspectIndicators: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$match: {
				inspectionTasksL1: {
					$exists: true,
					$not: {
						$size: 0
					}
				}
			}
		}, {
			$addFields: {
				inspectionTasksL1: {
					$setIntersection: [
						"$inspectionTasksL1",
						"$inspectionTasksL1"
					]
				}
			}
		}, {
			$unwind: {
				path: "$inspectionTasksL1",
				preserveNullAndEmptyArrays: false
			}
		}, {
			$group: {
				_id: {
					manufacturer: "$manufacturer",
					site: "$site",
					inspectionTasksL1: "$inspectionTasksL1"
				},
				totalReport: {
					$sum: 1
				},
				totalFindings: {
					$sum: "$nbFindings"
				},
				inspectionTasksL1: {
					$first: "$inspectionTasksL1"
				},
				nbFailed: {
					$sum: "$result"
				}
			}
		}, {
			$project: {
				supplier: "$_id",
				inspectionTasksL1: 1,
				supplierRatio: {
					$divide: [
						"$totalFindings",
						"$totalReport"
					]
				},
				nbFailed: {
					$subtract: [
						0,
						"$nbFailed"
					]
				},
				totalReport: 1,
				totalFindings: 1,
				_id: 0
			}
		}, {
			$addFields: {
				nbSuccess: {
					$subtract: [
						"$totalReport",
						"$nbFailed"
					]
				}
			}
		}, {
			$group: {
				_id: "$inspectionTasksL1",
				supplierRatios: {
					$push: "$supplierRatio"
				},
				totalFindings: {
					$sum: "$totalFindings"
				},
				totalReports: {
					$sum: "$totalReport"
				},
				nbFailed: {
					$sum: "$nbFailed"
				},
				nbSuccess: {
					$sum: "$nbSuccess"
				}
			}
		}, {
			$project: {
				inspectionTasksL1: "$_id",
				totalFindings: 1,
				totalReports: 1,
				findingRatio: {
					$divide: [
						"$totalFindings",
						"$totalReports"
					]
				},
				avgSupplierpRatio: {
					$avg: "$supplierRatios"
				},
				nbFailed: 1,
				nbSuccess: 1,
				_id: 0
			}
		}],
		limit: 500
	},
	inspectionSupplierStats: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "inspectionTasksL1"),
		query: [{
			$match: {
				inspectionTasksL1: {
					$exists: true,
					$not: {
						$size: 0
					}
				}
			}
		}, {
			$addFields: {
				inspectionTasksL1: {
					$setIntersection: [
						"$inspectionTasksL1",
						"$inspectionTasksL1"
					]
				}
			}
		}, {
			$unwind: {
				path: "$inspectionTasksL1",
				preserveNullAndEmptyArrays: false
			}
		}, {
			$group: {
				_id: {
					manufacturer: "$manufacturer",
					site: "$site",
					inspectionTasksL1: "$inspectionTasksL1"
				},
				totalReport: {
					$sum: 1
				},
				totalFindings: {
					$sum: "$nbFindings"
				},
				inspectionTasksL1: {
					$first: "$inspectionTasksL1"
				},
				nbFailed: {
					$sum: "$result"
				}
			}
		}, {
			$project: {
				supplier: "$_id",
				inspectionTasksL1: 1,
				supplierRatio: {
					$divide: [
						"$totalFindings",
						"$totalReport"
					]
				},
				nbFailed: {
					$subtract: [
						0,
						"$nbFailed"
					]
				},
				totalReport: 1,
				totalFindings: 1,
				_id: 0
			}
		}, {
			$addFields: {
				nbSuccess: {
					$subtract: [
						"$totalReport",
						"$nbFailed"
					]
				}
			}
		}, {
			$group: {
				_id: "$inspectionTasksL1",
				supplierRatios: {
					$push: "$supplierRatio"
				},
				totalFindings: {
					$sum: "$totalFindings"
				},
				totalReports: {
					$sum: "$totalReport"
				},
				nbFailed: {
					$sum: "$nbFailed"
				},
				nbSuccess: {
					$sum: "$nbSuccess"
				}
			}
		}, {
			$project: {
				inspectionTasksL1: "$_id",
				supplierRatios: 1,
				totalFindings: 1,
				totalReports: 1,
				findingRatio: {
					$divide: [
						"$totalFindings",
						"$totalReports"
					]
				},
				avgSupplierpRatio: {
					$avg: "$supplierRatios"
				},
				nbFailed: 1,
				nbSuccess: 1,
				_id: 0
			}
		}],
		limit: 500
	},
	equipmentCategoryIndicators: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$match: {
				equipmentCategory: {
					$ne: null
				}
			}
		}, {
			$group: {
				_id: {
					manufacturer: "$manufacturer",
					site: "$site",
					equipmentCategory: "$equipmentCategory"
				},
				totalReport: {
					$sum: 1
				},
				totalFindings: {
					$sum: "$nbFindings"
				},
				equipmentCategory: {
					$first: "$equipmentCategory"
				},
				nbFailed: {
					$sum: "$result"
				}
			}
		}, {
			$match: {
				equipmentCategory: { $ne: null }
			}
		}, {
			$project: {
				supplier: {
					manufacturer: "$_id.manufacturer",
					site: "$_id.site"
				},
				equipmentCategory: 1,
				supplierRatio: {
					$divide: [
						"$totalFindings",
						"$totalReport"
					]
				},
				nbFailed: {
					$subtract: [
						0,
						"$nbFailed"
					]
				},
				totalReport: 1,
				totalFindings: 1,
				_id: 0
			}
		}, {
			$addFields: {
				nbSuccess: {
					$subtract: [
						"$totalReport",
						"$nbFailed"
					]
				}
			}
		}, {
			$group: {
				_id: "$equipmentCategory",
				supplierRatios: {
					$push: "$supplierRatio"
				},
				totalFindings: {
					$sum: "$totalFindings"
				},
				totalReports: {
					$sum: "$totalReport"
				},
				nbFailed: {
					$sum: "$nbFailed"
				},
				nbSuccess: {
					$sum: "$nbSuccess"
				}
			}
		}, {
			$project: {
				equipmentCategory: "$_id",
				totalFindings: 1,
				totalReports: 1,
				findingRatio: {
					$divide: [
						"$totalFindings",
						"$totalReports"
					]
				},
				avgSupplierpRatio: {
					$avg: "$supplierRatios"
				},
				nbFailed: 1,
				nbSuccess: 1,
				failureRate: {
					$divide: [
						"$nbFailed",
						"$totalReports"
					]
				},
				_id: 0
			}
		}],
		limit: 500
	},
	equipmentCategorySupplierStats: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "equipmentCategory"),
		query: [
			{
				$match: {
					equipmentCategory: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: {
						manufacturer: "$manufacturer",
						site: "$site",
						equipmentCategory: "$equipmentCategory"
					},
					totalReport: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					},
					equipmentCategory: {
						$first: "$equipmentCategory"
					},
					nbFailed: {
						$sum: "$result"
					}
				}
			}, {
				$project: {
					supplier: {
						manufacturer: "$_id.manufacturer",
						site: "$_id.site"
					},
					equipmentCategory: 1,
					supplierRatio: {
						$divide: [
							"$totalFindings", "$totalReport"
						]
					},
					nbFailed: {
						$subtract: [
							0, "$nbFailed"
						]
					},
					totalReport: 1,
					totalFindings: 1,
					_id: 0
				}
			}, {
				$addFields: {
					nbSuccess: {
						$subtract: [
							"$totalReport", "$nbFailed"
						]
					}
				}
			}, {
				$group: {
					_id: "$equipmentCategory",
					supplierRatios: {
						$push: "$supplierRatio"
					},
					totalFindings: {
						$sum: "$totalFindings"
					},
					totalReports: {
						$sum: "$totalReport"
					},
					nbFailed: {
						$sum: "$nbFailed"
					},
					nbSuccess: {
						$sum: "$nbSuccess"
					}
				}
			}, {
				$project: {
					equipmentCategory: "$_id",
					supplierRatios: 1,
					totalFindings: 1,
					totalReports: 1,
					findingRatio: {
						$divide: [
							"$totalFindings", "$totalReports"
						]
					},
					avgSupplierpRatio: {
						$avg: "$supplierRatios"
					},
					nbFailed: 1,
					nbSuccess: 1,
					failureRate: {
						$divide: [
							"$nbFailed", "$totalReports"
						]
					},
					_id: 0
				}
			}
		],
		limit: 500
	},
	SCAMapStats: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$match: {
				country: {
					$nin: [
						"",
						"-",
						null
					]
				},
				manufacturer: {
					$nin: [
						null,
						"",
						"-"
					]
				}
			}
		}, {
			$group: {
				_id: {
					manufacturer: "$manufacturer",
					site: "$site"
				},
				totalReport: {
					$sum: 1
				},
				totalFindings: {
					$sum: "$nbFindings"
				},
				country: {
					$first: "$country"
				},
				totalFailed: {
					$sum: "$result"
				}
			}
		}, {
			$project: {
				supplier: "$_id",
				country: 1,
				supplierRatio: {
					$divide: [
						"$totalFindings",
						"$totalReport"
					]
				},
				totalReport: 1,
				totalFindings: 1,
				totalFailed: {
					$subtract: [
						0,
						"$totalFailed"
					]
				},
				_id: 0
			}
		}, {
			$group: {
				_id: "$country",
				nbSupp: {
					$sum: 1
				},
				supplierRatios: {
					$push: "$supplierRatio"
				},
				totalFindings: {
					$sum: "$totalFindings"
				},
				totalReports: {
					$sum: "$totalReport"
				},
				totalFailed: {
					$sum: "$totalFailed"
				}
			}
		}, {
			$project: {
				country: "$_id",
				supplierRatios: 1,
				totalFindings: 1,
				totalReports: 1,
				totalFailed: 1,
				failureRate: {
					$divide: [
						"$totalFailed",
						"$totalReports"
					]
				},
				avgSpRatio: {
					$avg: "$supplierRatios"
				},
				_id: 0
			}
		}, {
			$addFields: {
				diff: {
					$map: {
						input: "$supplierRatios",
						as: "ratio",
						in: {
							$subtract: [
								"$$ratio",
								"$avgSpRatio"
							]
						}
					}
				}
			}
		}, {
			$addFields: {
				sqrFromMean: {
					$map: {
						input: "$diff",
						as: "diff",
						in: {
							$multiply: [
								"$$diff",
								"$$diff"
							]
						}
					}
				}
			}
		}, {
			$addFields: {
				variance: {
					$divide: [{
						$sum: "$sqrFromMean"
					},
					{
						$size: "$sqrFromMean"
					}
					]
				}
			}
		}, {
			$lookup: {
				from: "worldGeoJsonCountries",
				localField: "country",
				foreignField: "country",
				as: "countryGeometry"
			}
		}, {
			$addFields: {
				geometry: {
					$arrayElemAt: [
						"$countryGeometry.geometry",
						0
					]
				},
				region: {
					$arrayElemAt: [
						"$countryGeometry.region",
						0
					]
				}
			}
		}, {
			$project: {
				country: 1,
				avgSpRatio: 1,
				failureRate: 1,
				totalFindings: 1,
				inspectionVolume: "$totalReports",
				findingRatio: {
					$divide: [
						"$totalFindings",
						"$totalReports"
					]
				},
				variance: 1,
				geometry: 1,
				region: 1
			}
		}],
		limit: 500
	},
	countryBenchMark: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "country" &&
			element !== "region"),
		query: [{
			$match: {
				country: {
					$nin: [
						"undefined",
						null,
						"",
						"-"
					]
				}
			}
		}, {
			$group: {
				_id: {
					country: "$country"
				},
				country: {
					$first: "$country"
				},
				region: {
					$first: "$region"
				},
				inspectionCount: {
					$sum: 1
				},
				nbFindings: {
					$sum: "$nbFindings"
				},
				nbFailed: {
					$sum: "$result"
				}
			}
		}, {
			$project: {
				country: 1,
				region: 1,
				inspectionCount: 1,
				nbFindings: 1,
				nbFailed: {
					$subtract: [
						0,
						"$nbFailed"
					]
				},
				failureRate: {
					$subtract: [
						0,
						{
							$divide: [
								"$nbFailed",
								"$inspectionCount"
							]
						}
					]
				},
				findingRatio: {
					$divide: [
						"$nbFindings",
						"$inspectionCount"
					]
				},
				_id: 0
			}
		}, {
			$sort: {
				inspectionCount: -1
			}
		}],
		limit: 30
	},
	overallSupplierStats: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						manufacturer: "$manufacturer",
						site: "$site"
					},
					totalReports: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					},
					nbFailed: {
						$sum: "$result"
					}
				}
			}, {
				$addFields: {
					findingRatio: {
						$divide: [
							"$totalFindings", "$totalReports"
						]
					}
				}
			}, {
				$addFields: {
					nbFailed: {
						$subtract: [
							0, "$nbFailed"
						]
					}
				}
			}, {
				$group: {
					_id: {
						manufacturer: "$_id.manufacturer"
					},
					totalSuppliers: {
						$sum: 1
					},
					overallReports: {
						$sum: "$totalReports"
					},
					overallFindings: {
						$sum: "$totalFindings"
					},
					overallFailed: {
						$sum: "$nbFailed"
					},
					findingRatioArray: {
						$push: "$findingRatio"
					},
					sites: {
						$push: "$_id.site"
					}
				}
			}, {
				$addFields: {
					overallSuccess: {
						$subtract: [
							"$overallReports", "$overallFailed"
						]
					},
					overallFindingRatio: {
						$divide: [
							"$overallFindings", "$overallReports"
						]
					},
					overallAvgSpFindingRatio: {
						$avg: "$findingRatioArray"
					},
					overallFailureRate: {
						$divide: [
							"$overallFailed", "$overallReports"
						]
					}
				}
			}, {
				$project: {
					findingRatioArray: 0
				}
			}, {
				$project: {
					manufacturer: "$_id.manufacturer",
					totalSuppliers: 1,
					overallReports: 1,
					overallFindings: 1,
					overallFailed: 1,
					sites: 1,
					overallSuccess: 1,
					overallFindingRatio: 1,
					overallAvgSpFindingRatio: 1,
					overallFailureRate: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	overallSupplierFindingRatios: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "equipmentCategory"),
		query: [{
			$group: {
				_id: "$site",
				nbFindings: {
					$sum: "$nbFindings"
				},
				nbReports: {
					$sum: 1
				}
			}
		}, {
			$addFields: {
				supplierFindingRatio: {
					$divide: [
						"$nbFindings",
						"$nbReports"
					]
				}
			}
		}, {
			$group: {
				_id: "",
				supplierFindingRatios: {
					$push: "$supplierFindingRatio"
				},
				avgSupplierFindingRatio: {
					$avg: "$supplierFindingRatio"
				}
			}
		}, {
			$addFields: {
				diff: {
					$map: {
						input: "$supplierFindingRatios",
						as: "ratio",
						in: {
							$subtract: [
								"$$ratio",
								"$avgSupplierFindingRatio"
							]
						}
					}
				}
			}
		}, {
			$addFields: {
				sqrFromMean: {
					$map: {
						input: "$diff",
						as: "diff",
						in: {
							$multiply: [
								"$$diff",
								"$$diff"
							]
						}
					}
				}
			}
		}, {
			$addFields: {
				variance: {
					$divide: [{
						$sum: "$sqrFromMean"
					},
					{
						$size: "$sqrFromMean"
					}
					]
				}
			}
		}, {
			$project: {
				_id: 0,
				supplierFindingRatios: 1,
				avgSupplierFindingRatio: 1,
				variance: 1
			}
		}],
		limit: 1
	},
	countrySupplierStats: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "country"),
		query: [
			{
				$match: {
					equipmentCategory: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: {
						manufacturer: "$manufacturer",
						site: "$site",
						country: "$country"
					},
					totalReport: {
						$sum: 1
					},
					totalFindings: {
						$sum: "$nbFindings"
					},
					nbFailed: {
						$sum: "$result"
					}
				}
			}, {
				$project: {
					supplier: {
						manufacturer: "$_id.manufacturer",
						site: "$_id.site"
					},
					supplierRatio: {
						$divide: [
							"$totalFindings", "$totalReport"
						]
					},
					nbFailed: {
						$subtract: [
							0, "$nbFailed"
						]
					},
					totalReport: 1,
					totalFindings: 1,
					country: "$_id.country",
					_id: 0
				}
			}, {
				$addFields: {
					nbSuccess: {
						$subtract: [
							"$totalReport", "$nbFailed"
						]
					}
				}
			}, {
				$group: {
					_id: "$country",
					supplierRatios: {
						$push: "$supplierRatio"
					},
					totalFindings: {
						$sum: "$totalFindings"
					},
					totalReports: {
						$sum: "$totalReport"
					},
					nbFailed: {
						$sum: "$nbFailed"
					},
					nbSuccess: {
						$sum: "$nbSuccess"
					}
				}
			}, {
				$project: {
					country: "$_id",
					supplierRatios: 1,
					totalFindings: 1,
					totalReports: 1,
					findingRatio: {
						$divide: [
							"$totalFindings", "$totalReports"
						]
					},
					avgSupplierpRatio: {
						$avg: "$supplierRatios"
					},
					nbFailed: 1,
					nbSuccess: 1,
					failureRate: {
						$divide: [
							"$nbFailed", "$totalReports"
						]
					},
					_id: 0
				}
			}
		]
	},
	overallCountryFindingRatios: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "country"),
		query: [{
			$match: {
				country: {
					$nin: [
						"-",
						"",
						null
					]
				}
			}
		}, {
			$group: {
				_id: "$country",
				nbFindings: {
					$sum: "$nbFindings"
				},
				nbReports: {
					$sum: 1
				}
			}
		}, {
			$addFields: {
				countryFindingRatio: {
					$divide: [
						"$nbFindings",
						"$nbReports"
					]
				}
			}
		}, {
			$project: {
				country: "$_id",
				countryFindingRatio: 1,
				_id: 0
			}
		}, {
			$sort: {
				countryFindingRatio: -1
			}
		}],
		limit: 12
	},
	taskL1Share: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "inspectionTasksL1"),
		facetsToTransform: { equipmentCategory: "equipmentCategory" },
		insertIndex: 5,
		transformLogic: "and",
		pages: ["INSPECTION"],
		query: [
			{
				$match: {
					equipmentCategory: {
						$ne: null
					},
					inspectionTasksL1: {
						$ne: null
					}
				}
			}, {
				$addFields: {
					inspectionTasksL1: {
						$setIntersection: [
							"$inspectionTasksL1", "$inspectionTasksL1"
						]
					}
				}
			}, {
				$unwind: {
					path: "$equipmentCategory",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$unwind: {
					path: "$inspectionTasksL1",
					preserveNullAndEmptyArrays: false
				}
			}, {
				$group: {
					_id: {
						equipmentCategory: "$equipmentCategory",
						inspectionTasksL1: "$inspectionTasksL1"
					},
					equipmentCategory: {
						$first: "$equipmentCategory"
					},
					countReports: {
						$sum: 1
					}
				}
			}, {
				$addFields: {
					inspectionTasksL1Count: {
						inspectionTasksL1: "$_id.inspectionTasksL1",
						count: "$countReports"
					}
				}
			}, {
				$group: {
					_id: "$equipmentCategory",
					equipmentCategory: {
						$first: "$equipmentCategory"
					},
					inspectionTasksL1Count: {
						$push: "$inspectionTasksL1Count"
					},
					totalReports: {
						$sum: "$countReports"
					}
				}
			}, {
				$sort: {
					equipmentCategory: 1
				}
			}
		],
		limit: 500
	},
	supplierGlobalFindingRatios: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "manufacturer"),
		pages: ["SITES"],
		query: [{
			$match: {
				manufacturer: {
					$nin: [
						null,
						"",
						" ",
						"-"
					]
				}
			}
		}, {
			$group: {
				_id: {
					manufacturer: "$manufacturer"
				},
				totalReports: {
					$sum: 1
				},
				totalFindings: {
					$sum: "$nbFindings"
				},
				nbFailed: {
					$sum: "$result"
				}
			}
		}, {
			$project: {
				manufacturer: "$_id.manufacturer",
				supplierRatio: {
					$divide: [
						"$totalFindings",
						"$totalReports"
					]
				},
				nbFailed: {
					$subtract: [
						0,
						"$nbFailed"
					]
				},
				totalReports: 1,
				totalFindings: 1,
				_id: 0
			}
		}, {
			$addFields: {
				nbSuccess: {
					$subtract: [
						"$totalReports",
						"$nbFailed"
					]
				}
			}
		}, {
			$project: {
				equipmentCategory: 1,
				manufacturer: 1,
				supplierRatios: 1,
				totalFindings: 1,
				totalReports: 1,
				findingRatio: {
					$divide: [
						"$totalFindings",
						"$totalReports"
					]
				},
				nbFailed: 1,
				nbSuccess: 1,
				failureRate: {
					$divide: [
						"$nbFailed",
						"$totalReports"
					]
				},
				_id: 0
			}
		}, {
			$sort: {
				totalReports: -1
			}
		}],
		limit: 6000
	},
	supplierProductFindingRatios: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "manufacturer"),
		pages: ["SITES"],
		query: [{
			$match: {
				equipmentCategory: {
					$exists: true,
					$ne: null
				},
				manufacturer: {
					$nin: [
						null,
						"",
						" ",
						"-"
					]
				}
			}
		}, {
			$group: {
				_id: {
					manufacturer: "$manufacturer",
					equipmentCategory: "$equipmentCategory"
				},
				totalReports: {
					$sum: 1
				},
				totalFindings: {
					$sum: "$nbFindings"
				},
				equipmentCategory: {
					$first: "$equipmentCategory"
				},
				nbFailed: {
					$sum: "$result"
				}
			}
		}, {
			$project: {
				supplier: {
					manufacturer: "$_id.manufacturer"
				},
				manufacturer: "$_id.manufacturer",
				equipmentCategory: 1,
				supplierRatio: {
					$divide: [
						"$totalFindings",
						"$totalReports"
					]
				},
				nbFailed: {
					$subtract: [
						0,
						"$nbFailed"
					]
				},
				totalReports: 1,
				totalFindings: 1,
				_id: 0
			}
		}, {
			$addFields: {
				nbSuccess: {
					$subtract: [
						"$totalReports",
						"$nbFailed"
					]
				}
			}
		}, {
			$project: {
				equipmentCategory: 1,
				manufacturer: 1,
				supplierRatios: 1,
				totalFindings: 1,
				totalReports: 1,
				findingRatio: {
					$divide: [
						"$totalFindings",
						"$totalReports"
					]
				},
				nbFailed: 1,
				nbSuccess: 1,
				failureRate: {
					$divide: [
						"$nbFailed",
						"$totalReports"
					]
				},
				_id: 0
			}
		}, {
			$sort: {
				totalReports: -1
			}
		}],
		limit: 6000
	},
	supplierCountryFindingRatios: {
		collection: SCACVTSData,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "manufacturer"),
		pages: ["SITES"],
		query: [{
			$match: {
				country: {
					$nin: [
						null,
						"",
						" ",
						"-"
					]
				},
				manufacturer: {
					$nin: [
						null,
						"",
						" ",
						"-"
					]
				}
			}
		}, {
			$group: {
				_id: {
					manufacturer: "$manufacturer",
					country: "$country"
				},
				totalReports: {
					$sum: 1
				},
				totalFindings: {
					$sum: "$nbFindings"
				},
				country: {
					$first: "$country"
				},
				nbFailed: {
					$sum: "$result"
				}
			}
		}, {
			$project: {
				manufacturer: "$_id.manufacturer",
				country: 1,
				supplierRatio: {
					$divide: [
						"$totalFindings",
						"$totalReports"
					]
				},
				nbFailed: {
					$subtract: [
						0,
						"$nbFailed"
					]
				},
				totalReports: 1,
				totalFindings: 1,
				_id: 0
			}
		}, {
			$addFields: {
				nbSuccess: {
					$subtract: [
						"$totalReports",
						"$nbFailed"
					]
				}
			}
		}, {
			$project: {
				country: 1,
				manufacturer: 1,
				supplierRatios: 1,
				totalFindings: 1,
				totalReports: 1,
				findingRatio: {
					$divide: [
						"$totalFindings",
						"$totalReports"
					]
				},
				nbFailed: 1,
				nbSuccess: 1,
				failureRate: {
					$divide: [
						"$nbFailed",
						"$totalReports"
					]
				},
				_id: 0
			}
		}, {
			$sort: {
				totalReports: -1
			}
		}],
		limit: 6000
	}
};

/**
 * Define the color code and name for non conformities
 */
export const nonConformities = {
	Critical: "#FF475C",
	Noncritical: "#FFC362"
};

/**
 * AuditMode Icons
 */
const auditModes = {
	Remote: {
		locale: "auditMode.remote",
		picto: HeadsetMicIcon
	},
	"On Site": {
		locale: "auditMode.onSite",
		picto: VisibilityIcon
	},
	"Self Assessment": {
		locale: "auditMode.self",
		picto: PlaylistAddCheckIcon
	}
};

export function getAuditMode(auditType, auditMode) {
	if (auditType === "Field Audit" || auditType === "Final Audit") {
		return auditModes[auditMode];
	}

	return auditModes["Self Assessment"];
}

/**
 * SupplyR SplashScreen screens
 */
export const splashScreens = [{
	name: "welcome",
	type: "full",
	img: "/images/SupplyR/Splashscreen/Splashscreen-001.png"
}];

export const mapColorScales = {
	findingRatioCS: {
		unsatisfactory: {
			color: "#B81D7E",
			range: [0.3, 10]
		},
		subStandard: {
			color: "#EB4C72",
			range: [0.2, 0.3]
		},
		inProgress: {
			color: "#FF8585",
			range: [0.15, 0.2]
		},
		average: {
			color: "#FFC362",
			range: [0.1, 0.15]
		},
		good: {
			color: "#CCDB73",
			range: [0.05, 0.1]
		},
		veryGood: {
			color: "#87D2CF",
			range: [0, 0.05]
		}

	},
	failureRateCS: {
		unsatisfactory: {
			color: "#B81D7E",
			range: [50, 105]
		},
		subStandard: {
			color: "#EB4C72",
			range: [10, 50]
		},
		inProgress: {
			color: "#FF8585",
			range: [5, 10]
		},
		average: {
			color: "#FFC362",
			range: [2, 5]
		},
		good: {
			color: "#CCDB73",
			range: [1, 2]
		},
		veryGood: {
			color: "#87D2CF",
			range: [0, 1]
		}
	},
	inspectionVolCS: {
		xs: {
			color: "#ACAFC2",
			range: [0, 100]
		},
		s: {
			color: "#7C84C2",
			range: [101, 200]
		},
		m: {
			color: "#4E5BC2",
			range: [201, 500]
		},
		l: {
			color: "#3A4AC2",
			range: [501, 5000]
		},
		xl: {
			color: "#1427C1",
			range: [5001, 50000]
		}
	},
	stdDevCS: {
		s: {
			color: "#1AC9E7",
			range: [0, 1]
		},
		m: {
			color: "#18ABDD",
			range: [1, 3]
		},
		l: {
			color: "#176CA1",
			range: [3, 5]
		},
		xl: {
			color: "#14245A",
			range: [5, 9999]
		}
	}
};

export const mapFilterOptions = [
	{
		label: "Inspection Volumes",
		value: "mapInspectionVolumes",
		colors: mapColorScales.inspectionVolCS,
		scoreAccessor: (d) => d?.inspectionVolume,
		digit: 0
	}, {
		label: "Failure Rate",
		value: "mapFailureRate",
		colors: mapColorScales.failureRateCS,
		scoreAccessor: (d) => (d.failureRate && (d.failureRate * 100)),
		digit: 2
	}, {
		label: "Finding Ratio",
		value: "mapFindingRatio",
		colors: mapColorScales.findingRatioCS,
		scoreAccessor: (d) => d?.findingRatio,
		digit: 2
	}, {
		label: "Average Supplier Finding Ratio",
		value: "mapAvgSupplierFindingRatio",
		colors: mapColorScales.findingRatioCS,
		scoreAccessor: (d) => d?.avgSpRatio,
		digit: 2
	},
	{
		label: "Standard deviation supplier finding ratio ",
		value: "mapStdDev",
		colors: mapColorScales.stdDevCS,
		scoreAccessor: (d) => Math.sqrt(d?.variance),
		digit: 2
	}
];

/**
 * Init Refine of the Application
 *
 * @param {Object} supplyRInitRefines
 * Object representing the initial refine of the datasets
 * in the App slice, the key being the name of the field
 * in the DB Collection to filter on, the value being the value to filter on
 */
export const SCAInitRefines = {
	mapFilterHome: mapFilterOptions[0],
	mapFilterEqpmnt: mapFilterOptions[1],
	mapFilterInspection: mapFilterOptions[1],
	mapFilterCountry: mapFilterOptions[1]
};

/**
 * Set up refine filter options to display
 *
 * @param {String} refine name of the field in the DB Collection to filter on
 * @param {String} loadFromDataset name of the collection to filter on the field
 * @param {String} placeHolder [OPTIONAL] text when nothing is selected
 * @param {Component} tag Material UI Icon to represent the field in the select, to be imported in this file
 * @param {Boolean} clear will be cleared if the button clear is pushed
 * @param {Boolean} toClearOnChange execute a clear of other clearable fields (see above) if the value has changed
 */
export const filterOptions = [{
	label: "Client",
	refine: "client",
	placeHolder: "filters.clients",
	loadFromDataset: "listClients",
	hiddenIfUnique: false,
	isSearchable: true,
	isClearable: true,
	clear: true,
	toClearOnChange: false
},
{
	label: "Equipment category",
	refine: "equipmentCategory",
	loadFromDataset: "listEquipmentCategory",
	placeHolder: "filters.equipmentCategory",
	isMulti: true,
	isSearchable: true,
	isClearable: true,
	clear: true,
	toClearOnChange: false
}, {
	label: "Inspection activity",
	refine: "inspectionTasksL1",
	loadFromDataset: "listInspectionTasks",
	placeHolder: "filters.inspectionTasks",
	isMulti: true,
	isSearchable: true,
	isClearable: true,
	clear: true,
	toClearOnChange: false
},
{
	label: "Geographies",
	refine: "country",
	refineGroup: "region",
	loadFromDataset: "listGeographies",
	placeHolder: "filters.geographies",
	isMulti: true,
	isSearchable: false,
	isClearable: true,
	isGrouped: true,
	clear: true,
	toClearOnChange: false
},
{
	label: "Year",
	refine: "year",
	loadFromDataset: "listYear",
	placeHolder: "filters.year",
	isMulti: true,
	isSearchable: true,
	isClearable: true,
	clear: false,
	toClearOnChange: false
},
{
	label: "State",
	refine: "state",
	clear: true,
	toClearOnChange: false,
	manualPosition: true
},
{
	label: "Region",
	refine: "region",
	clear: true,
	isClearable: true,
	toClearOnChange: false,
	manualPosition: true
}
	// {
	// 	label: "Date",
	// 	refine: "date",
	// 	loadFromDataset: "listDates",
	// 	placeHolder: "filters.dates",
	// 	isMulti: true,
	// 	isSearchable: false,
	// 	isClearable: true,
	// 	clear: true,
	// 	toClearOnChange: false
	// }
];

export const fieldsTooltip = [{
	label: "Manufacturer",
	key: "manufacturer"
},
{
	label: "Address",
	key: "address"
}
];

export const ratingsConfig = [
	{ rating: "nbSuccess", color: "#6DC3A0" },
	{ rating: "nbFailed", color: "#FF475C" }
];

export const shareColorConfig = {
	Metrology: "#6CABE6",
	Assurance: "#9757c9",
	People: "#CF8084",
	"Quality Controls": "#dbb564",
	"Manufacturing Process": "#6c7cf7",
	null: "pink"
};

export const globalRatingsConfig = [
	{ rating: "stageCount", color: "#6DC3A0" },
	{ rating: "diffCount", color: "#FF475C" }
];

export const overallRatingsConfig = [
	{ rating: "overallSuccess", color: "#6DC3A0" },
	{ rating: "overallFailed", color: "#FF475C" }
];

export const failureRateHelper = [
	{ rating: "totalFailed", color: "#6DC3A0" }, { rating: "totalDocs", color: "#FF475C" }
];
/**
 * Define general supply-R ranking rules
 */
export const colorLegend = {
	unsatisfactory: {
		color: "#B81D7E",
		range: [0.3, 10]
	},
	subStandard: {
		color: "#EB4C72",
		range: [0.2, 0.3]
	},
	inProgress: {
		color: "#FF8585",
		range: [0.15, 0.2]
	},
	average: {
		color: "#FFC362",
		range: [0.1, 0.15]
	},
	good: {
		color: "#CCDB73",
		range: [0.05, 0.1]
	},
	veryGood: {
		color: "#87D2CF",
		range: [0, 0.05]
	}

};

/**
 * Define the rule for the Marker color on the map
 */
export const getMarkerColor = (score, legend = colorLegend) => {
	if (score === undefined) return "#c9bbbd";

	// eslint-disable-next-line no-restricted-syntax
	for (const key in legend) {
		if (score >= legend[key].range[0] &&
			score < legend[key].range[1]) {
			return legend[key].color;
		}
	}
};

// export const getColorScale = (score, colorScale) => {
// 	if (score === undefined) return "#c9bbbd";

// 	for (const key in mapColorScales[colorScale]) {
// 		if (score >= mapColorScales[colorScale][key].range[0] &&
// 			score < mapColorScales[colorScale][key].range[1]) {
// 			return mapColorScales[colorScale][key].color;
// 		}
// 	}
// };

export const subFilters = [{
	name: "Final product",
	field: "finalProduct"
},
{
	name: "Product",
	field: "product"
},
{
	name: "Country",
	field: "country"
}
];

export const tableStructure = (activeRefines, t) => [
	{
		Header: "Factory",
		accessor: "manufacturer"
	},
	{
		Header: "City",
		accessor: "city"
	},
	{
		Header: "Country",
		accessor: "country"
	},
	{
		Header: "Equipments",
		accessor: "equipmentCategory"
	},
	{
		Header: "Nb reports",
		accessor: "totalReports"
	},
	{
		Header: "Findings",
		accessor: "totalFindings",
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-red-500 rounded-full h-7 w-7 text-white">{value}</p> : <p />
	},
	{
		Header: "Finding ratio",
		accessor: "findingRatio",
		Cell: ({ value }) => {
			if (typeof (value) === "number") {
				return <p className="font-semibold">{value?.toFixed(2)}</p>;
			}

			return (
				<p className="font-semibold">{value?.[0]?.[0]?.toFixed(2)}</p>
			);
		}
	},
	{
		Header: "Failure rate",
		accessor: "failureRate",
		Cell: ({ value }) => value !== undefined ? <p className="font-semibold">{(value * 100).toLocaleString("en-US", {
			minimumSignificantDigits: 3,
			maximumSignificantDigits: 3
		})}</p> : <p />
	}
];
/**
 *
 * @param {String} tab list
 */
export const termsTabSet = {
	"Legal Notice": {
		en: < LegalNotice />,
		fr: < LegalNotice />,
		"zh-Hans": < LegalNotice />,
		"zh-Hant": < LegalNotice />
	},
	Terms: {
		en: < Terms />,
		fr: < Terms />,
		"zh-Hans": < Terms />,
		"zh-Hant": < Terms />
	},
	Privacy: {
		en: < Privacy />,
		fr: < Privacy />,
		"zh-Hans": < Privacy />,
		"zh-Hant": < Privacy />
	},
	Cookies: {
		en: < Cookies />,
		fr: < Cookies />,
		"zh-Hans": < Cookies />,
		"zh-Hant": < Cookies />
	}
};

export const arr = {
	sum: (array) => {
		let num = 0;
		for (let i = 0, l = array.length; i < l; i++) num += array[i];
		return num;
	},

	mean: (array) => arr.sum(array) / array.length,

	variance: (array) => {
		const mean = arr.mean(array);
		return arr.mean(array.map((num) => (num - mean) ** 2));
	},

	standardDeviation: (array) => Math.sqrt(arr.variance(array))
};

export const getTreeMapFunc = new Map([
	["HOME", getTreeMapData.InspectionTreeMapData],
	["EQUIPMENT", getTreeMapData.EquipmentTreeMapData],
	["INSPECTION", getTreeMapData.InspectionTreeMapData],
	["COUNTRY", getTreeMapData.CountryTreeMapData],
	["SITES", getTreeMapData.InspectionTreeMapData]
]);

export const indicatorTypeOptions = [{
	label: "Failure rate",
	value: "failureRate",
	selectedBackgroundColor: "#fff",
	selectedfontcolor: "#3D49AB",
	scoreAccessor: (d) => d?.failureRate ? (d.failureRate * 100).toFixed(2) : null
},
{
	label: "Finding ratio",
	value: "findingRatio",
	selectedBackgroundColor: "#fff",
	selectedfontcolor: "#3D49AB",
	scoreAccessor: (d) => d?.findingRatio ? d.findingRatio.toFixed(2) : null
}
];
