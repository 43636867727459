import PropTypes from "prop-types";
import React from "react";

const SVGNoDataAvailable = ({ width, height }) => (
	<svg width={width} height={height}>
		<foreignObject width={width} height={height} >
			<div className="w-full h-full flex justify-center items-center text-lg uppercase">
				<p>Sorry, no data available.</p>
			</div>
		</foreignObject>
	</svg>
);

SVGNoDataAvailable.propTypes = {
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default SVGNoDataAvailable;
