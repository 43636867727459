import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const ClearButton = ({
	appSlice, isActiveFilter, options, inLine = true, locales
}) => {
	const dispatch = useDispatch();

	// Internationalization hook
	const { t } = useTranslation(locales);

	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const handleClear = () => {
		dispatch(appSlice.actions.refine([{ key: "isCleared", value: true }]));
		dispatch(appSlice.actions.clear(options));
		console.log("clear");
	};

	return (
		<div
			className={`flex justify-center items-center rounded-md  text-xl
            ${options.map((e) => e.refine).some((element) => Object.keys(activeRefines).includes(element))
				? " cursor-pointer" : " text-gray-300"}`}
			onClick={() => handleClear()}
		>
			<DeleteOutlineIcon fontSize={inLine ? "inherit" : "large"} className={inLine ? "mr-1" : ""} />
			<span className={`text-medium xl:inline leading-4 ${inLine ? " inline" : " hidden"}`}>
				{t("filters.reset")}
			</span>
		</div>
	);
};

ClearButton.propTypes = {
	appSlice: PropTypes.object,
	isActiveFilter: PropTypes.bool,
	options: PropTypes.array,
	inLine: PropTypes.bool,
	locales: PropTypes.string
};

export default ClearButton;
