import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSpring, animated, config } from "react-spring";
import PropTypes from "prop-types";

const localesKeyScope = ["internalSAQ", "internalAudits", "externalSAQ", "externalAudits"];

function RenderNbAssessments({ nbAssessments }) {
	// animation conf for the  assignemnets nb
	const { number } = useSpring({
		from: { number: 0 },
		number: nbAssessments,
		delay: 200,
		config: config.molasses
	});

	return (
		<animated.p className="text-2xl font-black leading-9 z-10">
			{number.to((n) => n.toFixed(0))}
		</animated.p>
	);
}

RenderNbAssessments.propTypes = {
	nbAssessments: PropTypes.number
};

function RenderScopeBar({ targetWidth, colorClass = "bg-clarity_primary-menu" }) {
	// animation conf for the scope bar
	const scopeWidth = useSpring({
		from: { width: 0 },
		to: { width: targetWidth },
		delay: 200,
		config: config.molasses
	});

	return (
		<animated.div
			style={scopeWidth}
			className={`h-3 mr-1 rounded-xl ${ colorClass}`} />
	);
}

RenderScopeBar.propTypes = {
	targetWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	colorClass: PropTypes.string
};

const getNbAssessments = (d) => d.nbAssessments;

function ClarityScopeCard({
	appSlice, dataset, expectedTotal = 1000, locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load data from the store of the App
	const scopeDataset = useSelector(appSlice.selectDatasets)[dataset];
	const nbSites = useSelector(appSlice.selectDatasets)?.sites?.data?.length ?? 0;

	if (!scopeDataset || !scopeDataset.data || scopeDataset.data.length < 0) {
		return null;
	}

	const detailedScope = {
		iSAQ: 0, iAudits: 0, eSAQ: 0, eAudits: 0
	};

	scopeDataset.data.forEach((element) => {
		if (element.siteType === "INTERNAL" && element.assessmentType !== "FIELD AUDIT") {
			detailedScope.iSAQ += getNbAssessments(element);
		} else if (element.siteType !== "EXTERNAL" && element.assessmentType === "FIELD AUDIT") {
			detailedScope.iAudits += getNbAssessments(element);
		} else if (element.assessmentType !== "FIELD AUDIT") {
			detailedScope.eSAQ += getNbAssessments(element);
		} else {
			detailedScope.eAudits += getNbAssessments(element);
		}
	});

	const nbAssessments = detailedScope.iSAQ + detailedScope.iAudits + detailedScope.eSAQ + detailedScope.eAudits;

	const percentageDetailedInternalScope = [
		((detailedScope.iSAQ / nbAssessments) * 100).toFixed(0),
		((detailedScope.iAudits / nbAssessments) * 100).toFixed(0)

	];

	const percentageDetailedExternalScope = [
		((detailedScope.eSAQ / nbAssessments) * 100).toFixed(0),
		((detailedScope.eAudits / nbAssessments) * 100).toFixed(0)
	];

	const xMax = Math.max(...percentageDetailedInternalScope, ...percentageDetailedExternalScope);

	const strDetailedScope = { internal: percentageDetailedInternalScope, external: percentageDetailedExternalScope };
	const colorScope = { internal: "bg-clarity_primary-accent", external: "bg-clarity_primary-highlight" };

	return (
		<div className="flex justify-between items-center w-full ">
			<div className="relative flex flex-col flex-shrink-0 justify-center items-center rounded-xl
               bg-clarity_primary-menu bg-opacity-60 h-20 w-14 overflow-hidden">
				<RenderNbAssessments nbAssessments={nbSites} />
				<hr className="text-white w-3 border-t-2 border-gray-400 border-opacity-80 z-10" />
				<p className="text-xs leading-6 z-10">{expectedTotal}</p>
				<div className="absolute bottom-0 w-full bg-clarity_primary-menu" style={{ height: "65%" }} />
			</div>
			<div className="flex flex-grow h-full justify-around  flex-col px-2">
				{Object.keys(strDetailedScope).map((typeScope, j) => <div key={`scopeType ${j}`} className="flex gap-x-1">
					<p className="flex items-center text-right text-xs font-semibold border-r pr-1">{t(`scope.type.${typeScope}`)}</p>
					<div className="flex h-full flex-col text-xs font-semibold">
						{strDetailedScope[typeScope].map((scope, i) => (
							<div key={i} className="flex  w-full">
								<p className="flex-shrink-0 text-left w-6 mr-2">{t(`scope.type.${localesKeyScope[i]}`)}</p>
								{scope > 0 && <RenderScopeBar targetWidth={(scope / xMax) * 100} colorClass={colorScope[typeScope]} />}
								<p className="text-center flex-shrink-0 w-6">{scope}%</p>
							</div>
						))}
					</div>
				</div>
				)}
			</div>
		</div>
	);
}

ClarityScopeCard.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	getNbAssessments: PropTypes.func,
	expectedTotal: PropTypes.number,
	locales: PropTypes.string
};

export default ClarityScopeCard;
