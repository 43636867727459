import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/**
 * Used in: ChargeScan.js
 * Description: Animated ArcChart with value displayed in the center
 * @category Graphs
 * @component
 *
 */

const ChargeScanArcChart = ({
	appDefinition,
	sitesNb,
	compliantNb,
	percentage,
	theme = "alpha",
	className,
	showScoreText = true,
	scoretextClass = "fill-current text-alpha_text-default text-3xl font-black",
	sideCicle = "fill-current text-alpha_primary-light",
	centralCicle = "fill-current text-alpha_primary-default"
}) => {
	const { t } = useTranslation(appDefinition.locales);

	const score = (198 * ((100 - percentage) / 100)).toFixed(0);

	return (
		<div className={`h-full w-full ${className}`}>
			<svg viewBox="0 0 100 100" className="chargeScan-arc-chart">
				<defs>
					<linearGradient id="lineararc" x1="0%" y1="100%" x2="0%" y2="0%">
						<stop offset="0%" className={`chart-initcolor ${theme}`} />
						<stop offset="100%" className={`chart-endcolor ${theme}`} />
					</linearGradient>
				</defs>
				<line x1="10" y1="15" x2="98" y2="98" className="stroke-current stroke-1 text-gray-100" />

				<text x="22" y="3" className="text-4">
					{sitesNb - compliantNb}
				</text>
				<text x="10" y="7" className="text-4">
					{t("homePage.moduleCard.notCompliant")}
				</text>
				<text x="20" y="12" className="text-4">
					{(((sitesNb - compliantNb) / sitesNb) * 100).toFixed(0)}%

				</text>

				<text x="82" y="3" className="text-4">
					{compliantNb}
				</text>
				<text x="75" y="7" className="text-4">
					{t("homePage.moduleCard.compliant")}
				</text>
				<text x="80" y="12"className="text-4">
					{((compliantNb / sitesNb) * 100).toFixed(0)}%

				</text>

				<path className="circle-bg-border" d="M30,90 A40,40 0 1,1 80,90" />
				<path className="circle-bg" d="M30,90 A40,40 0 1,1 80,90" />
				<path
					className="circle-border"
					strokeDashoffset={score}
					strokeDasharray={`${score}, 198`}
					d="M30,90 A40,40 0 1,1 80,90"
				/>
				<path className="circle" strokeDashoffset={score} strokeDasharray={`${score}, 198`} d="M30,90 A40,40 0 1,1 80,90" />
				<path className="dot" strokeDashoffset={score} strokeDasharray={`0, ${score}, 0, 300`} d="M30,90 A40,40 0 1,1 80,90" />

				<text x="54" y="65" textAnchor="middle" className={`${scoretextClass}`}>
					{sitesNb && sitesNb !== "NaN" ? sitesNb : "na"}
				</text>
				{showScoreText && <text x="29" y="76" className="fill-current text-alpha_text-default text-xs font-light">
					{t("homePage.moduleCard.chargingsite", { count: sitesNb })}
				</text>}
				<circle cx="48" cy="93" r="2" className={sideCicle} />
				<circle cx="55" cy="93" r="3" className={centralCicle} />
				<circle cx="62" cy="93" r="2" className={sideCicle} />
			</svg>
		</div>
	);
};

ChargeScanArcChart.propTypes = {
	/**
	 * class string used for side the center circle/dot at the bottom of the design
	 */
	centralCicle: PropTypes.string,
	/**
	 * className string to append to first div
	 */
	className: PropTypes.string,
	/**
	 * percentage value. this is the main info
	 */
	percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * class string for percentage value display
	 */
	scoretextClass: PropTypes.string,
	/**
	 * show "score" as legend under percentage
	 */
	showScoreText: PropTypes.bool,
	/**
	 * class string used for side the side circles/dots at the bottom of the design
	 */
	sideCicle: PropTypes.string,
	/**
	 * color theme. has to be present in tailwind / css as it is used as a className for arc gradient.
	 */
	theme: PropTypes.string,
	/**
	 * number of sites
	 */
	sitesNb: PropTypes.number,
	/**
	 * defines appDefinition
	 */
	appDefinition: PropTypes.object,
	/**
	 * number of Compliants
	 */
	compliantNb: PropTypes.number
};

export default ChargeScanArcChart;
