import Div100vh from "react-div-100vh";

function Page401() {
	return (
		<Div100vh className="flex gap-x-2 font-sm flex-grow w-full h-full justify-center items-center m-auto
        animate-fade-in-long bg-white rounded-xl gap-y-2 px-4 py-2 z-3xl">
			<svg
				className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
			>
				<circle
					className="opacity-25"
					cx="12"
					cy="12"
					r="10"
					stroke="currentColor"
					strokeWidth="4"
				/>
				<path
					className="opacity-75"
					fill="currentColor"
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
				/>
			</svg>
			<div className="flex flex-col">
				<p>You have been disconnected.</p>
				<p className="font-light ">Reconnecting in 2 seconds...</p>
			</div>
		</Div100vh>
	);
}

export default Page401;
