import React from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";

const debounce = (func, wait) => {
	let timeout;
	return function (...args) {
		const context = this;
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => {
			timeout = null;
			func.apply(context, args);
		}, wait);
	};
};

const SearchBar = ({ placeholder = "Search for..", setSearchContent }) => (
	<div className="relative">
		<input
			type="text"
			className="h-10 pl-5 rounded-xl bg-searchbar-default"
			placeholder={placeholder}
			onChange={(e) => debounce(setSearchContent(e.target.value), 500)}
		/>
		<SearchIcon fontSize="small" className="absolute right-3 top-2.5" />
	</div>
);

SearchBar.propTypes = {
	placeholder: PropTypes.string,
	setSearchContent: PropTypes.func
};

export default SearchBar;
