import React, {
	useState, useMemo
} from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";

import DoneAllIcon from "@mui/icons-material/DoneAll";

import NestedArray from "./AdminAppNestedArray";

import UpdateFormBtnGroup from "../button/UpdateFormBtnGroup";
import Popconfirm from "../modal/Popconfirm";

import { appDefinition, searchSelector } from "../../apps/configs/AdminConfig";

import { deleteDoc } from "../../data/slices/createAdminSlice";
import { adminAppDelete } from "./AdminFormOnConfirm";

const flattenObj = (obj, parent, res = {}) => {
	Object.keys(obj).forEach((key) => {
		const propName = parent ? `${parent}.${key}` : key;

		if (typeof obj[key] === "object") {
			flattenObj(obj[key], propName, res);
		}

		res[propName] = obj[key];
	});

	return res;
};

const appScopeStructureBuilder = (res) => {
	const apps = res.appScopes.reduce((acc, cur) => {
		const { name, nestedArray } = cur;
		return {
			...acc,
			[name.toUpperCase()]: {
				field: nestedArray.field,
				scope: nestedArray.scope.map((item) => item.k)
			}
		};
	}, {});

	return {
		_id: res._id,
		...apps
	};
};

const AdminAppScopeForm = ({
	mode,
	data,
	appSlice,
	clearRefineKeys,
	targetDocAccessor,
	renderButtonGroup = true,
	screenHeight,
	onConfirm,
	setAlert
}) => {
	const dispatch = useDispatch();
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const [confirmModal, setconfirmModal] = useState(false);

	const targetDocument = useMemo(() => (mode === "updateApplication" ? data.find(targetDocAccessor) : data), [data, targetDocAccessor, mode]);

	const appScopes = Object.entries(targetDocument).filter((item) => item[0] !== "_id")
	.map(([name, nestedArray]) => ({ name, nestedArray: { ...nestedArray, scope: nestedArray.scope.map((el) => ({ k: el })) } }));

	const defaultValue = {
		_id: targetDocument._id ?? "",
		appScopes
	};

	const {
		control, register, handleSubmit, formState: { errors }, reset, getValues, setValue
	} = useForm({
		defaultValues: defaultValue,
		criteriaMode: "firstError",
		shouldFocusError: true
	});

	const onSubmit = (e) => {
		e.preventDefault();
		setconfirmModal(true);
	};

	const submit = (submitValue) => {
		onConfirm({ res: appScopeStructureBuilder(submitValue), setconfirmModal });
	};

	// prevent enter event from triggering form to submit
	const checkKeyDown = (e) => {
		if (e.key === "Enter") e.preventDefault();
	};

	// dropdown content builder
	const searchOptionBuilder = (activeRefines, field) => {
		if (typeof searchSelector(activeRefines.secLevelMenu, field) === "string") {
			return activeDatasets[searchSelector(activeRefines.secLevelMenu, field)]?.data;
		}

		// if not return string type then return type is array, which means we concatenate response from multiple collections
		const res = searchSelector(activeRefines.secLevelMenu, field)?.map((collection) => activeDatasets[collection].data).flat()
		?.filter((item, i, self) => self?.findIndex((item2) => (item2?.label === item?.label)) === i);

		return res;
	};

	return (
		<>
			{
				confirmModal && (
					<Popconfirm
						title="Add / Update scope"
						description="Are you sure you want to add / update an application scope?"
						confirmBtnText="Confirm"
						onClickConfirm={handleSubmit(submit)}
						onClickCancel={() => setconfirmModal(false)}
					/>
				)
			}
			<UpdateFormBtnGroup appSlice={appSlice} locales={appDefinition.locales} renderButtonGroup={renderButtonGroup}
				defaultValue={defaultValue} reset={reset} clearRefineKeys={clearRefineKeys} renderRollBackBtn={false} renderDeleteBtn={false}
				deleteFunction={deleteDoc} deleteFuncProps={{ id: targetDocument._id, setAlert }} onDelete={adminAppDelete}/>

			<form className="flex flex-col px-2 my-4 space-y-6 overflow-y-auto" style={{ height: screenHeight - 260 }}
				onSubmit={onSubmit} onKeyDown={(e) => checkKeyDown(e)}>

				<label key={"_id"} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
					text-admin_primary-default border px-2 py-3 rounded-lg">
					<span className="w-min h-min p-0.5 absolute -top-3 bg-white
						uppercase text-sm flex justify-center items-center">
						{"Application"}
					</span>
					<input {...register("_id", { required: true })}
						className="p-2 my-2 text-black border rounded-lg bg-white focus:outline-none focus:ring-2
							focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"/>
					{errors?._id && <span className="text-red-600 text-sm">This field is required</span>}
				</label>

				<label key={"project"} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
								text-admin_primary-default border px-2 py-3 rounded-lg">
					<span className="w-min h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
						{"PROJECT(S)"}
					</span>
					<NestedArray {...{
						control,
						register
					}} />

				</label>

				<div className="flex flex-col space-y-4 mt-10">
					<button className="border bg-admin_primary-default hover:bg-admin_primary-dark
                    text-white font-bold py-1 px-3 rounded uppercase" type="submit">
						<DoneAllIcon />
					</button>
				</div>

			</form>
		</>
	);
};

AdminAppScopeForm.propTypes = {
	mode: PropTypes.string.isRequired,
	data: PropTypes.any.isRequired,
	appSlice: PropTypes.object,
	clearRefineKeys: PropTypes.array,
	targetDocAccessor: PropTypes.func,
	renderButtonGroup: PropTypes.bool,
	screenHeight: PropTypes.number,
	onConfirm: PropTypes.func,
	setAlert: PropTypes.func
};

export default AdminAppScopeForm;
