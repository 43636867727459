import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "../../styles/switch.css";

function ToggleSwitch({ initialState, handleChange, className = "" }) {
	const [state, setState] = useState({ checkbox: initialState });

	const onSelectCheckbox = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	useEffect(() => {
		setTimeout(() => {
			handleChange(state);
		}, 100);
	}, [state]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={className} key={state}>
			<div className="button r" id="button-3">
				<input type="checkbox" name="checkbox" className="checkbox" checked={state.checkbox}
					onChange={onSelectCheckbox} />
				<div className="knobs"></div>
				<div className="layer"></div>
			</div>
		</div>
	);
}

ToggleSwitch.propTypes = {
	initialState: PropTypes.bool,
	handleChange: PropTypes.func,
	className: PropTypes.string
};

export default ToggleSwitch;
