import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import FlagIcon from "@mui/icons-material/Flag";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SecurityIcon from "@mui/icons-material/Security";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionIcon from "@mui/icons-material/Description";
import BuildIcon from "@mui/icons-material/Build";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import EvStationIcon from "@mui/icons-material/EvStation";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { moduleOptions } from "../../apps/configs/ChargeScanConfig";

const ChargeScanTable = ({
	appSlice, locales
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Get activeRefines
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const activeDatasets = useSelector(appSlice.selectDatasets);

	const allModules = activeDatasets?.allModules?.data.map((item) => item.module);
	const chargingStationData = activeDatasets?.chargingStationData?.data;
	const selectedModule = activeRefines?.module;

	const chargingStationScore = chargingStationData.map((item, i) => {
		const combineModuleScore = item.module.reduce((acc, cur, j) => {
			const temp = { [cur]: item.nbNC[j] };
			acc = { ...acc, ...temp };

			return acc;
		}, {});

		return {
			chargingStationId: item.chargingStationId,
			assignmentTechnicalid: item.assignmentTechnicalid,
			...combineModuleScore
		};
	}).sort((a, b) => a.chargingStationId - b.chargingStationId);

	// FIXME: ml reponsive
	return (
		<table className="table-auto w-full rounded-r">
			<thead>
				<tr className="bg-chargescan_primary-default text-white h-18 text-xs md:text-base">
					<th className="empty-space-holder rounded-tl-xl"></th>
					{allModules?.map((module, i) => {
						const MaterialUIIcon = moduleOptions[module]?.picto ?? ErrorOutlineIcon;
						return <th
							key={`module${i}`}
							className={`${i === 3 && "rounded-tr-xl"} cursor-pointer w-1/5
							${selectedModule !== undefined && selectedModule !== module && "text-gray-400"}`}
							onClick={() => {
								if (!activeRefines?.module) { dispatch(appSlice.actions.refine([{ key: "module", value: module }])); }
								if (activeRefines?.module) { dispatch(appSlice.actions.clear([{ refine: "module" }])); }
							}}
						>
							<p><MaterialUIIcon/></p>
							<p className="hidden md:block">	{module.toUpperCase()}</p>
						</th>;
					})}
				</tr>
			</thead>
			<tbody>
				{chargingStationScore?.map((chargingStation, i) => (
					<tr
						key={`chargingStation${i}`}
						className="text-center border-b text-chargescan_primary-default
								border-chargescan_primary-default last:rounded-b-xl last:border-b-0 h-12 text-xs md:text-base"
						onClick={() => {
							if (activeRefines?.chargingStationId) {
								dispatch(appSlice.actions.clear([{ refine: "chargingStationId" }]));
							}
							if (!activeRefines?.chargingStationId) {
								dispatch(appSlice.actions.refine([{ key: "chargingStationId", value: chargingStation.chargingStationId }]));
							}
						}}

					>
						<td className="text-left pl-10 text-xs md:text-base">
							<div className="flex items-end gap-x-2">
								<div className="flex gap-x-1 h-full">
									<EvStationIcon fontSize="small" className="hidden md:block"/>
									{chargingStation.chargingStationId === "0" ?
										<p className=" h-full text-md md:text-base">Site</p>
										: <p className="h-full text-xs md:text-base">
											<span className="text-xs md:text-base">STATION </span>{`${chargingStation.chargingStationId}`}
										</p>}
								</div>
								<a
									className="flex-shrink-0"
									rel="noreferrer"
									href={`https://odsdatahub.bureauveritas.com/reports/${chargingStation.assignmentTechnicalid}`}
									target="_blank"
								>
									<DescriptionIcon className="text-sm md:text-2xl"/>
								</a>
							</div>
						</td>
						<td className={
							allModules[0]
								? selectedModule !== undefined && selectedModule !== allModules[0] ? "invisible" : undefined
								: "hidden"}>
							{(chargingStation[allModules[0]] === 0 || !chargingStation[allModules[0]])
								? <CheckCircleOutlineIcon />
								: <span className="border-2 p-1.5 rounded-xl border-red-500 text-red-500 font-semibold flex w-min mx-auto">
									{chargingStation[allModules[0]]} <FlagIcon className="hidden md:block"/>
								</span>}
						</td>
						<td className={
							allModules[1]
								? selectedModule !== undefined && selectedModule !== allModules[1] ? "invisible" : undefined
								: "hidden"}>
							{(chargingStation[allModules[1]] === 0 || !chargingStation[allModules[1]])
								? <CheckCircleOutlineIcon />
								: <div className="border-2 p-1.5 rounded-xl border-red-500 text-red-500 font-semibold flex w-min mx-auto">
									{chargingStation[allModules[1]]} <FlagIcon className="hidden md:block"/>
								</div>}
						</td>
						<td className={
							allModules[2]
								? selectedModule !== undefined && selectedModule !== allModules[2] ? "invisible" : undefined
								: "hidden"}>
							{(chargingStation[allModules[2]] === 0 || !chargingStation[allModules[2]])
								? <CheckCircleOutlineIcon />
								: <span className="border-2 p-1.5 rounded-xl border-red-500 text-red-500 font-semibold flex w-min mx-auto">
									{chargingStation[allModules[2]]} <FlagIcon className="hidden md:block"/>
								</span>}
						</td>
						<td className={
							allModules[3]
								? selectedModule !== undefined && selectedModule !== allModules[3] ? "invisible" : undefined
								: "hidden"}>
							{(chargingStation[allModules[3]] === 0 || !chargingStation[allModules[3]])
								? <CheckCircleOutlineIcon />
								: <span className="border-2 p-1.5 rounded-xl border-red-500 text-red-500 font-semibold flex w-min mx-auto">
									{chargingStation[allModules[3]]} <FlagIcon className="hidden md:block"/>
								</span>}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

ChargeScanTable.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string
};

export default ChargeScanTable;
