import { Pie } from "@visx/shape";
import { scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { useSpring, animated, config } from "react-spring";
import PropTypes from "prop-types";

const defaultThickness = 25;
const defaultMargin = {
	top: 0,
	right: 10,
	bottom: -10,
	left: 10
};

// Temp DonutGraph to be reviewed
function SimpleDonutGraph({
	value = [{ label: "", score: "NaN" }],
	altValue = undefined,
	index = true,
	donutThickness = defaultThickness,
	donutInnerThickness = donutThickness,
	width = 200,
	height = 100,
	labels = false,
	values = false,
	innerValue = true,
	innerValueDigits = 0,
	donutColor = "#1876BD",
	subTextCenter = undefined,
	subTextCenterSize = 9,
	subTextOffset = ".33em",
	fontSizeTextCenter = undefined,
	textInnerColor = "black",
	backgroundColor = undefined,
	margin = defaultMargin,
	calOverAllScore,
	isPercent = false,
	halo = false
}) {
	const labelHeight = 14;
	const innerWidth = width - margin.left - margin.right;
	const innerHeight = height - margin.top - margin.bottom - labelHeight;
	const radius = Math.min(innerWidth, innerHeight) / 2;
	const centerY = innerHeight / 2;
	const centerX = innerWidth / 2;

	const overAllScore = calOverAllScore(value);
	// const overAllScore = 8;

	const { number } = useSpring({
		from: { number: 0 },
		number: Number.isNaN(overAllScore) ? 0 : overAllScore,
		delay: 200,
		config: config.molasses
	});

	const donutData =
		index && value.length === 1
			? [{ label: value?.[0].label, score: value?.[0].score },
				{ label: "index", score: 100 - (value?.[0].score ?? 0) }]
			: value;

	const donutColorArray = Array.isArray(donutColor) ? donutColor : [donutColor];
	const getDomainColor = scaleOrdinal({
		domain: value?.map((d) => d.label),
		range: [...donutColorArray, "transparent"]
	});

	if (radius < 10) {
		return null;
	}

	if (donutData?.[0]?.score === "NaN") {
		return (
			<svg width={width} height={height}>
				<Group top={centerY + margin.top} left={centerX + margin.left}>
					<circle
						className="donut-ring"
						r={radius - donutThickness / 2}
						fill="transparent"
						stroke="#EEEEEE"
						strokeWidth={donutInnerThickness}
					/>
					<text fill="#EEEEEE" x="0" y="0" dy=".33em"
						fontWeight="bold" fontSize={innerWidth / 4} textAnchor="middle" pointerEvents="none">
						N/A
					</text>
				</Group>
			</svg>
		);
	}

	return (
		<svg width={width} height={height}>
			<Group top={centerY + margin.top} left={centerX + margin.left}>
				{halo && <circle
					className="donut-ring blur-[24px] opacity-30"
					r={radius / 1.5 - donutThickness / 2}
					fill={donutColor}
					// stroke={donutColor}
					strokeWidth={donutInnerThickness}
				/>}
				<circle
					className="donut-ring"
					r={radius - donutThickness / 2}
					fill="transparent"
					stroke="#EEEEEE"
					strokeWidth={donutInnerThickness}
				/>
				{backgroundColor !== undefined && (<circle
					className="donut-ring"
					r={radius - donutThickness / 2 - 8}
					fill={backgroundColor}
					stroke="white"
					strokeWidth={4}
				/>)}
				<Pie
					data={donutData}
					pieValue={(d) => d.score}
					outerRadius={radius}
					innerRadius={radius - donutThickness}
					cornerRadius={donutThickness / 2}
					pieSort={null}
					padAngle={0.005}
				>
					{({ arcs, path }) => (
						<g>
							{arcs.map((arc, key) => {
								const [centroidX, centroidY] = path.centroid(arc);
								const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
								return (
									<g key={`pie-arc-${key}`}>
										<path className={`arc${key}`} d={path(arc)} fill={getDomainColor(arc.data.label)} />
										{labels && hasSpaceForLabel && arc.data.label !== "index" && (
											<text
												fill="black"
												x={centroidX}
												y={centroidY}
												dy=".33em"
												fontSize={9}
												textAnchor="middle"
												pointerEvents="none"
											>
												{arc.data.label}
											</text>
										)}
										{values && arc.data.score > 0 && arc.data.label !== "index" && (
											<text
												fill="black"
												x={centroidX}
												y={centroidY}
												dy=".33em"
												fontSize={9}
												textAnchor="middle"
												pointerEvents="none"
											>
												{arc.data.score}
											</text>
										)}
									</g>
								);
							})}
							{innerValue && (
								<g>
									<animated.text
										fill={textInnerColor}
										y={(fontSizeTextCenter ? fontSizeTextCenter / 3 : innerWidth / 9)
                                        - (subTextCenter ? subTextCenterSize / 3 : 0)}
										fontSize={fontSizeTextCenter || innerWidth / 3}
										x={isPercent ? ((width > 120 ? -16 : -10)) : 0}
										fontWeight="normal"
										textAnchor="middle"
										pointerEvents="none"
									>
										{number.to((n) => n.toFixed(innerValueDigits))}
									</animated.text>
									{isPercent && <text
										fill={textInnerColor}
										y={(fontSizeTextCenter ? fontSizeTextCenter / 3 : innerWidth / 9)
                                        - (subTextCenter ? subTextCenterSize / 3 : 0)}
										fontSize={fontSizeTextCenter || innerWidth / 3}
										x={overAllScore > 9 ? (width > 120 ? 12 : 10) : 3}
										fontWeight="normal"
										pointerEvents="none"
									>
										%
									</text>}
									<text
										fill="black"
										y={(fontSizeTextCenter ? fontSizeTextCenter / 3 : innerWidth / 9)
                                            + (subTextCenter ? subTextCenterSize / 3 : 0)}
										dy=".33em"
										fontSize={subTextCenterSize}
										textAnchor="middle"
										pointerEvents="none"
									>
										{subTextCenter}
									</text>

								</g>
							)}
						</g>
					)}
				</Pie>
			</Group>
		</svg>
	);
}

SimpleDonutGraph.propTypes = {
	value: PropTypes.array,
	altValue: PropTypes.array,
	index: PropTypes.bool,
	slice: PropTypes.string,
	donutThickness: PropTypes.number,
	donutInnerThickness: PropTypes.number,
	scoreLabel: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	labels: PropTypes.bool,
	values: PropTypes.bool,
	innerValue: PropTypes.bool,
	innerValueDigits: PropTypes.number,
	donutColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	fontSizeTextCenter: PropTypes.number,
	textInnerColor: PropTypes.string,
	subTextCenter: PropTypes.string,
	subTextCenterSize: PropTypes.number,
	subTextOffset: PropTypes.string,
	backgroundColor: PropTypes.string,
	margin: PropTypes.object,
	calOverAllScore: PropTypes.func,
	isPercent: PropTypes.bool,
	halo: PropTypes.bool
};

export default SimpleDonutGraph;
