import DashboardIcon from "@mui/icons-material/Dashboard";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import MapIcon from "@mui/icons-material/Map";
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined";
import SearchIcon from "@mui/icons-material/Search";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} slice Name of the slice related to the app created and references in the store ./store.js
 * @param {String} logo Label of the menu, if none, only the picto
 */
export const appDefinition = {
	name: "VendorDD",
	subName: "",
	launcherID: "VENDORDD",
	slice: "vendorDD",
	locales: "translationsVendorDD",
	theme: "vendorDD",
	favicon: "/images/VendorDD/favicon.ico",
	logo: "/images/VendorDD/icon-menu-cpsvendor.svg"
};

/**
 * Set up  available language to display
 */
export const langListValue = [
	{ value: "en", label: "EN" }
];

/**
 * Set up  menu options to display
 *
 * @param {String} menu ID of the menu (use for the initial menu definition and the render of the app)
 * @param {String} type Route or App = Route is a link to jump to a new url, App is a internal link to the App to display specific information
 * @param {String} url [OPTIONAL] If type == url, the new url to link the menu (it has to be routed in ./App.js)
 * @param {String} label [OPTIONAL] Label of the menu, if none, only the picto
 * @param {String} sectionTitleWhite [OPTIONAL] Section title in white displayed on top of the page when the menu is clicked
 * @param {String} sectionTitleBlack [OPTIONAL] Section title in black displayed on top of the page when the menu is clicked
 * @param {Component} picto Material UI Icon to represent the field in the select, to be imported in this file
 */
export const menuOptions = [
	{
		menu: "HOME",
		type: "App",
		initial: true,
		label: "Home",
		picto: DashboardIcon
	},
	{
		menu: "MAP",
		type: "App",
		initial: true,
		label: "Map",
		picto: MapIcon
	},
	{
		menu: "INFO",
		type: "App",
		label: "Info",
		picto: ContactSupportIcon
	}
];

/**
 * Name of the dataset with the client parameters
 */
const VDDClientParameters = "VDDClientParameters";

/**
 * Arry of the full list of facets to refine
 */
export const listFacetsToRefine = ["spaceId", "module", "product", "region", "country", "vendorId", "siteId" ];

/**
 * Client parameters
 */
export const clientInitParameters = {
	name: "clientParameters",
	collection: VDDClientParameters,
	facetsToRefine: ["spaceId"],
	fetch: "find",
	limit: 1
};

/**
  * Active DataSets definition for the app
  *
  * @param {String} name
  * @param {String} collection
  * @param {String} fetch
  * @param {String[]} facetsToRefine
  * @param {Object[]} query
  * @param {Number} limit
  * @param {Object[]} data
  */
export const vendorDDInitDatasets = {
	listClients: {
		collection: VDDClientParameters,
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						client: "$client",
						spaceId: "$spaceId"
					},
					nbSpaces: { $sum: 1 }
				}
			},
			{
				$project: {
					client: "$_id.client",
					spaceId: "$_id.spaceId",
					nbSpaces: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listModules: {
		collection: "VDDModuleAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "module"),
		query: [
			{
				$group: {
					_id: {
						module: "$module"
					},
					nbAssignements: { $sum: 1 }
				}
			},
			{
				$project: {
					module: "$_id.module",
					nbAssignements: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listProducts: {
		collection: "VDDModuleAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "product"),
		query: [
			{
				$match: {
					module: "Product evaluation"
				}
			},
			{
				$group: {
					_id: {
						product: "$product",
						assignmentTechnicalid: "$assignmentTechnicalid"
					},
					nbAssignements: { $sum: 1 }
				}
			},
			{
				$project: {
					product: "$_id.product",
					assignmentTechnicalid: "$_id.assignmentTechnicalid",
					nbAssignements: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listGeographies: {
		collection: "VDDSiteAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "country" &&
        element !== "region"),
		query: [
			{
				$project: {
					"option.label": "$country",
					"option.value": "$country",
					region: "$region"
				}
			}, {
				$group: {
					_id: "$region",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					refine: "region"
				}
			}, {
				$match: {
					_id: {
						$ne: "undefined"
					}
				}
			}
		],
		limit: 500
	},
	listVendors: {
		collection: "VDDVendorAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "vendorId"),
		query: [
			{
				$group: {
					_id: {
						vendorId: "$vendorId"
					}
				}
			},
			{
				$project: {
					vendorId: "$_id.vendorId",
					_id: 0
				}
			}
		],
		limit: 2000
	},
	listFacilities: {
		collection: "VDDSiteAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "siteId"),
		query: [
			{
				$match: {
					module: "Site evaluation"
				}
			},
			{
				$group: {
					_id: {
						siteId: "$siteId",
						siteLabel: "$siteLabel",
						assignmentTechnicalid: "$assignmentTechnicalid"
					},
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					siteId: "$_id.siteId",
					siteLabel: "$_id.siteLabel",
					assignmentTechnicalid: "$_id.assignmentTechnicalid",
					nbSites: 1,
					_id: 0
				}
			}
		],
		limit: 2000
	},
	vendorsDistrib: {
		collection: "VDDVendorAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						vendorRank: "$vendorRank"
					},
					nbVendors: { $sum: 1 }
				}
			},
			{
				$project: {
					label: "$_id.vendorRank",
					score: "$nbVendors",
					_id: 0
				}
			}
		],
		limit: 1000
	},
	sitesDistrib: {
		collection: "VDDSiteAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						siteRank: "$siteRank"
					},
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					label: "$_id.siteRank",
					score: "$nbSites",
					_id: 0
				}
			}
		],
		limit: 1000
	},
	sites: {
		collection: "VDDSiteAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$project: {
					siteLabel: 1,
					vendorId: 1,
					geometry: 1,
					siteRank: 1,
					product: 1,
					siteOverallScore: 1,
					siteId: "$_id.siteId"
				}
			}
		],
		limit: 1000
	},
	lastVendors: {
		collection: "VDDVendorAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						vendorId: "$vendorId",
						vendorRank: "$vendorRank",
						webPercentage: "$webPercentage",
						productPercentage: "$productPercentage",
						sitePercentage: "$sitePercentage",
						vendorScore: "$vendorScore",
						publicationDate: "$publicationDate",
						nbSites: {
							$size: "$siteId"
						},
						nbProducts: {
							$size: {
								$filter: {
									input: "$product",
									as: "item",
									cond: {
										$ne: [
											"$$item", null
										]
									}
								}
							}
						}
					}
				}
			}, {
				$project: {
					vendorId: "$_id.vendorId",
					vendorRank: "$_id.vendorRank",
					webPercentage: "$_id.webPercentage",
					productPercentage: "$_id.productPercentage",
					sitePercentage: "$_id.sitePercentage",
					vendorScore: "$_id.vendorScore",
					publicationDate: "$_id.publicationDate",
					nbSites: "$_id.nbSites",
					nbProducts: "$_id.nbProducts",
					_id: 0
				}
			}, {
				$sort: {
					publicationDate: -1
				}
			}
		],
		limit: 10
	},
	sectionsWebList: {
		collection: "VDDCheckpoints",
		fetch: "aggregation",
		pages: ["HOME/VENDOR_WEB" ],
		facetsToRefine: listFacetsToRefine,
		query: [{
			$match: {
				module: "Web evaluation"
			}
		}, {
			$group: {
				_id: {
					module: "$module",
					assignmentTechnicalid: "$assignmentTechnicalid",
					checkpointReference: "$checkpointReference",
					section1Label: "$section1Label",
					question: "$question"
				},
				questionScore: { $avg: "$questionScore" }
			}
		}, {
			$project: {
				module: "$_id.module",
				assignmentTechnicalid: "$_id.assignmentTechnicalid",
				checkpointReference: "$_id.checkpointReference",
				section1Label: "$_id.section1Label",
				question: "$_id.question",
				questionScore: 1,
				_id: 0
			}
		}],
		limit: 500
	},
	sectionsProductList: {
		collection: "VDDCheckpoints",
		fetch: "aggregation",
		pages: ["HOME/VENDOR_PRODUCT" ],
		facetsToRefine: listFacetsToRefine,
		query: [{
			$match: {
				module: "Product evaluation"
			}
		}, {
			$group: {
				_id: {
					module: "$module",
					assignmentTechnicalid: "$assignmentTechnicalid",
					product: "$product",
					checkpointReference: "$checkpointReference",
					section1Label: "$section1Label",
					question: "$question"
				},
				questionScore: { $avg: "$questionScore" }
			}
		}, {
			$project: {
				module: "$_id.module",
				assignmentTechnicalid: "$_id.assignmentTechnicalid",
				product: "$_id.product",
				checkpointReference: "$_id.checkpointReference",
				section1Label: "$_id.section1Label",
				question: "$_id.question",
				questionScore: 1,
				_id: 0
			}
		}],
		limit: 500
	},
	sectionsSiteList: {
		collection: "VDDCheckpoints",
		fetch: "aggregation",
		pages: ["HOME/VENDOR_SITE" ],
		facetsToRefine: listFacetsToRefine,
		query: [{
			$match: {
				module: "Site evaluation"
			}
		}, {
			$group: {
				_id: {
					module: "$module",
					assignmentTechnicalid: "$assignmentTechnicalid",
					siteId: "$siteId",
					siteLabel: "$siteLabel",
					checkpointReference: "$checkpointReference",
					section1Label: "$section1Label",
					question: "$question"
				},
				questionScore: { $avg: "$questionScore" }
			}
		}, {
			$project: {
				module: "$_id.module",
				assignmentTechnicalid: "$_id.assignmentTechnicalid",
				siteId: "$_id.siteId",
				siteLabel: "$_id.siteLabel",
				checkpointReference: "$_id.checkpointReference",
				section1Label: "$_id.section1Label",
				question: "$_id.question",
				questionScore: 1,
				_id: 0
			}
		}],
		limit: 500
	}
};

/**
 * Init Refine of the Application
 *
 * @param {Object} vendorDDInitRefines
 * Object representing the initial refine of the datasets
 * in the App slice, the key being the name of the field
 * in the DB Collection to filter on, the value being the value to filter on
 */
export const vendorDDInitRefines = { spaceId: undefined };

// Define the MapToolTip
export const vendorDDMapTooltip = [
	{ label: "Vendor", key: "vendorId" },
	{ label: "Site", key: "siteLabel" },
	{ label: "Ranking", key: "siteRank" }
];

/**
 * Set up refine filter options to display
 *
 * @param {String} refine name of the field in the DB Collection to filter on
 * @param {String} loadFromDataset name of the collection to filter on the field
 * @param {String} placeHolder [OPTIONAL] text when nothing is selected
 * @param {Component} tag Material UI Icon to represent the field in the select, to be imported in this file
 * @param {Boolean} clear will be cleared if the button clear is pushed
 * @param {Boolean} toClearOnChange execute a clear of other clearable fields (see above) if the value has changed
 */
export const filterOptions = [
	{
		label: "Client",
		refine: "spaceId",
		displayField: "client",
		loadFromDataset: "listClients",
		hiddenIfUnique: true,
		isMulti: false,
		isClearable: false,
		isSearchable: false,
		tag: DeviceHubOutlinedIcon,
		clear: false,
		toClearOnChange: true
	},
	{
		label: "Vendors",
		refine: "vendorId",
		loadFromDataset: "listVendors",
		placeHolder: "filters.searchForAVendor",
		isMulti: false,
		isSearchable: true,
		tag: SearchIcon,
		isClearable: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "module",
		loadFromDataset: "listModules",
		placeHolder: "filters.modules",
		onlyIfRefine: (d) => d?.vendorId !== undefined,
		isMulti: false,
		isSearchable: true,
		isClearable: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "product",
		loadFromDataset: "listProducts",
		placeHolder: "filters.products",
		isMulti: false,
		isSearchable: false,
		isClearable: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "country",
		loadFromDataset: "listGeographies",
		placeHolder: "filters.geographies",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isGrouped: true,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Facilities",
		refine: "siteId",
		displayField: "siteLabel",
		loadFromDataset: "listFacilities",
		placeHolder: "filters.searchForASite",
		onlyIfRefine: (d) => d?.vendorId !== undefined,
		isMulti: false,
		isSearchable: true,
		isClearable: true,
		clear: true,
		toClearOnChange: false
	}
];

export const stateConfig = [
	{ rating: "Good", minScore: 80, color: "#6DC3A0" },
	{ rating: "Fair", minScore: 50, color: "#6BD0FF" },
	{ rating: "Poor", minScore: 0, color: "#FF9900" }
];

/**
 * Define the rule for the Marker color on the map
 */
export const getRankColor = (vendorRank) => {
	if (!vendorRank) return "#c9bbbd";
	return stateConfig.find((element) => vendorRank === element.rating).color;
};

/**
 * Define the rule for the Marker color on the map
 */
export const getMarkerColor = (vendorScore) => {
	if (!vendorScore) return "#c9bbbd";
	return stateConfig.find((element) => vendorScore >= element.minScore).color;
};

export const tableSectionsStructure = (activeRefines, t) => [
	{
		Header: "Section Level 1",
		accessor: "section1Label"
	},
	{
		Header: "Question",
		accessor: "question"
	},
	{
		Header: "Result",
		accessor: "questionScore",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value !== null || value !== undefined) ? <p className="flex items-center text-white justify-center text-sm uppercase
         rounded-lg h-7 w-16" style={{ backgroundColor: getMarkerColor(value) }}>
			{stateConfig.find((element) => value >= element.minScore).rating}
		</p> : <p/>
	}
];
