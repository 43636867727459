import React, { useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import { localPoint } from "@visx/event";
import { useGesture } from "@use-gesture/react";
import {
	composeMatrices,
	inverseMatrix,
	applyMatrixToPoint,
	applyInverseMatrixToPoint,
	translateMatrix,
	identityMatrix,
	scaleMatrix
} from "./Matrix";

// default prop values
const defaultInitialTransformMatrix = {
	scaleX: 1,
	scaleY: 1,
	translateX: 0,
	translateY: 0,
	skewX: 0,
	skewY: 0
};

const defaultWheelDelta = (event) => -event.deltaY > 0 ? { scaleX: 1.1, scaleY: 1.1 } : { scaleX: 0.9, scaleY: 0.9 };

const defaultPinchDelta = ({ offset: [s], lastOffset: [lastS] }) => ({
	scaleX: s - lastS < 0 ? 0.9 : 1.1,
	scaleY: s - lastS < 0 ? 0.9 : 1.1
});

// every time when update @visx/zoom version please update this file as well
// and please leave the handleWheel logic intact

const Zoom = ({
	scaleXMin = 0,
	scaleXMax = Infinity,
	scaleYMin = 0,
	scaleYMax = Infinity,
	initialTransformMatrix = defaultInitialTransformMatrix,
	wheelDelta = defaultWheelDelta,
	pinchDelta = defaultPinchDelta,
	width,
	height,
	constrain,
	children
}) => {
	const containerRef = useRef(null);
	const matrixStateRef = useRef(initialTransformMatrix);

	const [transformMatrix, setTransformMatrixState] = useState(
		initialTransformMatrix
	);
	const [isDragging, setIsDragging] = useState(false);
	const [startTranslate, setStartTranslate] = useState(undefined);
	const [startPoint, setStartPoint] = useState(undefined);
	const [, forceUpdate] = useState(undefined);

	const defaultConstrain = useCallback(
		(newTransformMatrix, prevTransformMatrix) => {
			if (constrain) return constrain(newTransformMatrix, prevTransformMatrix);
			const { scaleX, scaleY } = newTransformMatrix;
			const shouldConstrainScaleX = scaleX > scaleXMax || scaleX < scaleXMin;
			const shouldConstrainScaleY = scaleY > scaleYMax || scaleY < scaleYMin;

			if (shouldConstrainScaleX || shouldConstrainScaleY) {
				return prevTransformMatrix;
			}
			return newTransformMatrix;
		},
		[constrain, scaleXMin, scaleXMax, scaleYMin, scaleYMax]
	);

	const setTransformMatrix = useCallback(
		(newTransformMatrix) => {
			setTransformMatrixState((prevTransformMatrix) => {
				const updatedTransformMatrix = defaultConstrain(newTransformMatrix, prevTransformMatrix);
				matrixStateRef.current = updatedTransformMatrix;
				return updatedTransformMatrix;
			});
		},
		[defaultConstrain]
	);

	const applyToPoint = useCallback(
		({ x, y }) => applyMatrixToPoint(transformMatrix, { x, y }),
		[transformMatrix]
	);

	const applyInverseToPoint = useCallback(
		({ x, y }) => applyInverseMatrixToPoint(transformMatrix, { x, y }),
		[transformMatrix]
	);

	const reset = useCallback(() => {
		setTransformMatrix(initialTransformMatrix);
	}, [initialTransformMatrix, setTransformMatrix]);

	const scale = useCallback(
		({ scaleX, scaleY: maybeScaleY, point }) => {
			const scaleY = maybeScaleY || scaleX;
			const cleanPoint = point || { x: width / 2, y: height / 2 };
			// need to use ref value instead of state here because wheel listener does not have access to latest state
			const translate = applyInverseMatrixToPoint(matrixStateRef.current, cleanPoint);
			const nextMatrix = composeMatrices(
				matrixStateRef.current,
				translateMatrix(translate.x, translate.y),
				scaleMatrix(scaleX, scaleY),
				translateMatrix(-translate.x, -translate.y)
			);
			setTransformMatrix(nextMatrix);
			if (isDragging) {
				const { translateX, translateY } = matrixStateRef.current;
				setStartPoint(point);
				setStartTranslate({ translateX, translateY });
			}
		},
		[height, width, isDragging, setTransformMatrix]
	);

	const translate = useCallback(
		({ translateX, translateY }) => {
			const nextMatrix = composeMatrices(transformMatrix, translateMatrix(translateX, translateY));
			setTransformMatrix(nextMatrix);
		},
		[setTransformMatrix, transformMatrix]
	);

	const setTranslate = useCallback(
		({ translateX, translateY }) => {
			const nextMatrix = {
				...transformMatrix,
				translateX,
				translateY
			};
			setTransformMatrix(nextMatrix);
		},
		[setTransformMatrix, transformMatrix]
	);

	const translateTo = useCallback(
		({ x, y }) => {
			const point = applyInverseMatrixToPoint(transformMatrix, { x, y });
			setTranslate({ translateX: point.x, translateY: point.y });
		},
		[setTranslate, transformMatrix]
	);

	const invert = useCallback(() => inverseMatrix(transformMatrix), [transformMatrix]);

	const toStringInvert = useCallback(() => {
		const {
			translateX, translateY, scaleX, scaleY, skewX, skewY
		} = invert();
		return `matrix(${scaleX}, ${skewY}, ${skewX}, ${scaleY}, ${translateX}, ${translateY})`;
	}, [invert]);

	const dragStart = useCallback(
		(event) => {
			const { translateX, translateY } = transformMatrix;
			setStartPoint(localPoint(event) || undefined);
			setStartTranslate({ translateX, translateY });
			setIsDragging(true);
		},
		[transformMatrix]
	);

	const dragMove = useCallback(
		(
			event,
			options
		) => {
			if (!isDragging || !startPoint || !startTranslate) return;
			const currentPoint = localPoint(event);
			const dx = currentPoint ? -(startPoint.x - currentPoint.x) : -startPoint.x;
			const dy = currentPoint ? -(startPoint.y - currentPoint.y) : -startPoint.y;

			let translateX = startTranslate.translateX + dx;
			if (options?.offsetX) translateX += options.offsetX;
			let translateY = startTranslate.translateY + dy;
			if (options?.offsetY) translateY += options.offsetY;
			setTranslate({
				translateX,
				translateY
			});
		},
		[isDragging, setTranslate, startPoint, startTranslate]
	);

	const dragEnd = useCallback(() => {
		setStartPoint(undefined);
		setStartTranslate(undefined);
		setIsDragging(false);
	}, []);

	// FIXME: change @visx/zoom 2.0.0 logic
	// Everytime there is a update please change the logic for the part below
	const handleWheel = useCallback(
		(event) => {
			if (event.shiftKey) {
				const point = localPoint(event) || undefined;
				const { scaleX, scaleY } = wheelDelta(event);
				scale({ scaleX, scaleY, point });
			}
			forceUpdate({});
		},
		[scale, wheelDelta, forceUpdate]
	);

	// const origin_handleWheel = useCallback(
	// 	(event) => {
	// 		event.preventDefault();
	// 		const point = localPoint(event) || undefined;
	// 		const { scaleX, scaleY } = wheelDelta(event);
	// 		scale({ scaleX, scaleY, point });
	// 	},
	// 	[scale, wheelDelta]
	// );

	const handlePinch = useCallback(
		(state) => {
			const {
				origin: [ox, oy],
				memo
			} = state;
			let currentMemo = memo;
			if (containerRef.current) {
				const { top, left } = currentMemo ?? containerRef.current.getBoundingClientRect();
				if (!currentMemo) {
					currentMemo = { top, left };
				}
				const { scaleX, scaleY } = pinchDelta(state);
				scale({
					scaleX,
					scaleY,
					point: { x: ox - left, y: oy - top }
				});
			}
			return currentMemo;
		},
		[scale, pinchDelta]
	);

	const toString = useCallback(() => {
		const {
			translateX, translateY, scaleX, scaleY, skewX, skewY
		} = transformMatrix;
		return `matrix(${scaleX}, ${skewY}, ${skewX}, ${scaleY}, ${translateX}, ${translateY})`;
	}, [transformMatrix]);

	const center = useCallback(() => {
		const centerPoint = { x: width / 2, y: height / 2 };
		const inverseCentroid = applyInverseToPoint(centerPoint);
		translate({
			translateX: inverseCentroid.x - centerPoint.x,
			translateY: inverseCentroid.y - centerPoint.y
		});
	}, [height, width, applyInverseToPoint, translate]);

	const clear = useCallback(() => {
		setTransformMatrix(identityMatrix());
	}, [setTransformMatrix]);

	useGesture(
		{
			onDragStart: ({ event }) => {
				if (!(event instanceof KeyboardEvent)) dragStart(event);
			},
			onDrag: ({ event, pinching, cancel }) => {
				if (pinching) {
					cancel();
					dragEnd();
				} else if (!(event instanceof KeyboardEvent)) {
					dragMove(event);
				}
			},
			onDragEnd: dragEnd,
			onPinch: handlePinch,
			onWheel: ({ event, active }) => {
				// currently onWheelEnd emits one final wheel event which causes 2x scale
				// updates for the last tick. ensuring that the gesture is active avoids this
				if (event.shiftKey) {
					handleWheel(event);
				}
			}
		},
		{ target: containerRef, eventOptions: { passive: false }, drag: { filterTaps: true } }
	);

	const zoom = {
		initialTransformMatrix,
		transformMatrix,
		isDragging,
		center,
		clear,
		scale,
		translate,
		translateTo,
		setTranslate,
		setTransformMatrix,
		reset,
		handleWheel,
		handlePinch,
		dragEnd,
		dragMove,
		dragStart,
		toString,
		invert,
		toStringInvert,
		applyToPoint,
		applyInverseToPoint,
		containerRef
	};

	return <>{children(zoom)}</>;
};

Zoom.propTypes = {
	scaleXMin: PropTypes.number,
	scaleXMax: PropTypes.any,
	scaleYMin: PropTypes.number,
	scaleYMax: PropTypes.any,
	initialTransformMatrix: PropTypes.any,
	wheelDelta: PropTypes.any,
	pinchDelta: PropTypes.any,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number

	]),
	height: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number

	]),
	constrain: PropTypes.any,
	children: PropTypes.any
};

export default Zoom;
