import React, {
	useState, useRef, useMemo
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import DoneAllIcon from "@mui/icons-material/DoneAll";

import TranslationsNestedObjectEditor from "./TranslationsNestedObjectEditor";

import CreatFormBtnGroup from "../button/CreatFormBtnGroup";
import Popconfirm from "../modal/Popconfirm";

import IF from "../../utils/IF";

import {
	appDefinition, typeSelector, fieldAttributeBuilder
} from "../../apps/configs/AdminConfig";

// remove k key when field type is array
const RemoveKey = (submitValue) => {
	// remove key k in case field is type of array of object
	const sumbitKeyValuePair = Object.entries(submitValue);

	const removeKeyK = sumbitKeyValuePair.reduce((arr, cur) => {
		let getValue;
		if (Array.isArray(cur[1])) {
			getValue = cur[1].map((el) => (el.k));
			return { ...arr, [cur[0]]: getValue };
		}

		return { ...arr, [cur[0]]: cur[1] };
	}, {});

	return removeKeyK;
};

const AdminTranslationsUpdateForm = ({
	data,
	appSlice,
	clearRefineKeys,
	targetDocAccessor,
	renderButtonGroup = true,
	screenHeight,
	requiredFields,
	onConfirm
}) => {
	const dispatch = useDispatch();
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const [confirmModal, setconfirmModal] = useState(false);

	const targetDocument = data.find(targetDocAccessor);

	const englishTranslationDocument = data.find((e) => e._id === "en");

	// get all the field from mongoDB
	const allFieldsOfCurrentCollection = [...new Set(data?.flatMap((el) => Object.keys(el)))];

	// all the fields in key type pair
	const AllKeyTypePair = Object.entries(Object.fromEntries(new Map(data?.flatMap((el) => Object.entries(el)))))
	?.reduce((acc, cur) => ({ ...acc, [cur[0]]: typeSelector(cur, activeRefines) }), {});

	const {
		control, register, handleSubmit, formState: { errors }
	} = useForm({
		criteriaMode: "firstError",
		shouldFocusError: true
	});

	const onSubmit = (e) => {
		e.preventDefault();
		setconfirmModal(true);
	};

	const submit = (submitValue) => {
		const resRemoveKey = RemoveKey(submitValue);

		const res = {
			...resRemoveKey,
			_id: targetDocument._id,
			language: targetDocument.language
		};

		const resWithoutId = { ...res };

		delete resWithoutId._id;

		onConfirm(res, resWithoutId, setconfirmModal);
	};

	// prevent enter event from triggering form to submit
	const checkKeyDown = (e) => {
		if (e.key === "Enter") e.preventDefault();
	};

	return (
		<>
			{confirmModal &&
				<Popconfirm
					title="Update current translation"
					description="Are you sure you want update this translation?"
					confirmBtnText="Confirm"
					onClickConfirm={handleSubmit(submit)}
					onClickCancel={() => setconfirmModal(false)}
				/>}
			{renderButtonGroup &&
				<div className="flex flex-row space-x-1 border-b border-admin_primary-default pb-2">

					<CreatFormBtnGroup appSlice={appSlice} locales={appDefinition.locales}
						renderGroup={true} renderBack={true} renderAdd={false} clearRefineKeys={clearRefineKeys}/>
				</div>}

			<div>
				<p className="font-medium gap-y-1 mt-4 ml-2 uppercase text-admin_primary-default">Language: {targetDocument._id}</p>
			</div>
			<form
				className="flex flex-col px-2 my-4 space-y-6 overflow-y-auto"
				style={{ height: screenHeight - 260 }}
				onSubmit={onSubmit}
				onKeyDown={(e) => checkKeyDown(e)}
			>
				{allFieldsOfCurrentCollection
				?.filter((item) => !fieldAttributeBuilder(activeRefines.secLevelMenu, "translationsHiddenFields")?.includes(item))
				?.map((field, i) => (
					<div key={`field-${field}-${i}`}>

						<IF condition={AllKeyTypePair[field] === "string"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${field}`}
								</span>
								<input {...register(`${field}`)}
									className="p-2 my-2 text-black border rounded-lg bg-white focus:outline-none focus:ring-2
									focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "object"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields?.includes(field) ? "*" : ""} ${field}`}
								</span>
								<TranslationsNestedObjectEditor
									control={control}
									field={field}
									register={register}
									data={targetDocument[field]}
									requiredFields={requiredFields}
									activeRefines={activeRefines}
									englishTranslationDocument={englishTranslationDocument[field]}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

					</div>
				))}

				<div className="flex flex-col space-y-4 mt-10">
					<button className="border bg-admin_primary-default hover:bg-admin_primary-dark
                    text-white font-bold py-1 px-3 rounded uppercase" type="submit">
						<DoneAllIcon />
					</button>
				</div>

			</form>
		</>
	);
};

AdminTranslationsUpdateForm.propTypes = {
	appSlice: PropTypes.object,
	clearRefineKeys: PropTypes.array,
	targetDocAccessor: PropTypes.func,
	data: PropTypes.array.isRequired,
	renderButtonGroup: PropTypes.bool,
	screenHeight: PropTypes.number,
	requiredFields: PropTypes.array,
	onConfirm: PropTypes.func

};

export default AdminTranslationsUpdateForm;
