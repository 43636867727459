import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import AppsIcon from "@mui/icons-material/Apps";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ShortTextIcon from "@mui/icons-material/ShortText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

import AlphaTooltip from "../tooltips/AlphaTooltip";

import "../../styles/globals.css";

import { userSelector } from "../../data/slices/UserSlice";

import { mainMenuClearRefineKeys, secLevelMenuClearRefineKeys, thirdLevelMenuClearRefineKeys } from "../../apps/configs/AdminConfig";

const setAccordion = (menu, subMenuClass, arrowClass) => {
	const allSubMenus = document.querySelectorAll(subMenuClass);
	const arrows = arrowClass && document.querySelectorAll(arrowClass);

	allSubMenus.forEach((subMenu) => {
		if (subMenu.classList.contains(`${menu}-accordion`)) {
			subMenu.classList.toggle("accordionHide");
		} else {
			subMenu.classList.add("accordionHide");
		}
	});

	arrows.forEach((arrow) => {
		if (arrow.classList.contains(`${menu}-arrow`)) {
			arrow.classList.toggle("rotate180");
		} else {
			arrow.classList.remove("rotate180");
		}
	});
};

const clearAccordion = (subMenuClass, arrowClass) => {
	const allSubMenus = document.querySelectorAll(subMenuClass);
	const arrows = document.querySelectorAll(arrowClass);

	allSubMenus.forEach((subMenu) => {
		subMenu.classList.add("accordionHide");
	});

	arrows.forEach((arrow) => {
		arrow.classList.remove("rotate180");
	});
};

const menuNameBuilder = (origin) => {
	switch (origin) {
		default:
			return origin;
	}
};

const AdminSideMenu = ({
	appSlice, appDefinition, menuOptions, menu, noTitle = false, indicator = undefined, locales, AppIconColor = "text-white",
	colorMenu = "#3D49AB", colorBgMenu = "white", colorAccent = "#1876BD", textColor = "text-gray-500", subMenuTextColor = "#1C3FAA", onClick = undefined
}) => {
	async function signOut() {
		try {
			await Auth.signOut();
			window.location.reload();
		} catch (error) {
			console.log("error signing out: ", error);
		}
	}

	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Set up state for mobile Filter Menu
	const [mobileDetails, setMobileDetails] = useState(false);

	// Load user
	const user = useSelector(userSelector);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const currentPage = useSelector(appSlice.selectPage);

	// Avoid getting stuck on a blank page when returning from the launcher.
	useEffect(() => {
		if (Object.keys(activeRefines).length !== 0) {
			const allKeys = [...mainMenuClearRefineKeys, ...secLevelMenuClearRefineKeys, ...thirdLevelMenuClearRefineKeys];
			dispatch(appSlice.actions.clear(allKeys));
		}
		clearAccordion(".sub-menu", ".arrow");
		clearAccordion(".sub-sub-menu", ".sub-arrow");
		dispatch(appSlice.actions.setPage(menuOptions[0].menu));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{/*
            * Menu Desktop
            */}
			<div className="md:flex flex-col flex-shrink-0 box-content z-xl h-full overflow-hidden
            bg-opacity-80 shadow-lg backdrop-filter backdrop-blur-md hidden md:w-28 lg:w-64 transition-all duration-700"
			style={{ backgroundColor: colorBgMenu }}>

				<div className="flex flex-col z-20 -mb-1"
					style={{ backgroundColor: colorBgMenu }}>
					<Link to="/">
						<AppsIcon className={`ml-9 mt-5 2xl:mt-10 cursor-pointer ${AppIconColor}`} onClick={onClick} />
					</Link>
					<div className="flex flex-col justify-center items-center mt-5 2xl:mt-6 -mb-2 2xl:mb-4 w-24 ">
						<img src={appDefinition.logo} className="w-16 max-h-16 object-scale-down" />
						{!noTitle &&
							<>
								<p className="hidden 2xl:inline font-semibold pt-1">{appDefinition.name}<br /></p>
								<p className="hidden 2xl:inline text-sm">{appDefinition.subName}</p>
							</>
						}
					</div>
				</div>
				<div className="relative flex flex-col justify-between h-full"
					style={{ backgroundColor: colorBgMenu, borderColor: colorMenu }} >
					<div className="absolute -top-6 -left-2 w-16 h-16 rounded-full z-10"
						style={{ backgroundColor: colorBgMenu }} />
					<div className="flex flex-col">
						<div className="flex flex-col w-full pl-4 pt-4 mt-6 space-y-8 2xl:space-y-6">
							{menuOptions.map((option, i) => {
								const Picto = option.picto;
								return (
									<>
										<div key={`menu ${i}`}
											className="relative pl-9 list-none w-full rounded-tl-full rounded-bl-full
                                            flex flex-row items-center pr-5"
											style={{
												backgroundColor: (menu === option.menu) && (activeRefines.secLevelMenu === undefined)
													? colorMenu : ""
											}}
											onClick={() => {
												if (activeRefines.secLevelMenu) {
													dispatch(appSlice.actions.clear(mainMenuClearRefineKeys));
												}

												dispatch(appSlice.actions.setPage(option.menu));

												option.clearRefine?.map((clearRefine) => (dispatch(appSlice.actions
												.refine([{ key: clearRefine, value: undefined }]))));

												// close all accordion when switch menu
												if (!option.secLevelMenu) clearAccordion(".sub-menu", ".arrow");
												if (!option.thirdLevelMenu) clearAccordion(".sub-sub-menu", ".sub-arrow");

												//  open up submenus by default
												if (option.secLevelMenu) {
													const secLevelMenuRefine = option?.secLevelMenu[0];

													// Extract the object from thirdLevelMenu using the secLevelMenuRefine as a key
													const thirdLevelMenuRefine =
													option?.thirdLevelMenu?.find((obj) => obj[secLevelMenuRefine])[secLevelMenuRefine]?.[0];

													dispatch(appSlice.actions.refine([
														{ key: "secLevelMenu", value: secLevelMenuRefine },
														{ key: "thirdLevelMenu", value: thirdLevelMenuRefine }
													]));

													setAccordion(option.label, ".sub-menu", ".arrow");
												}

												if (option.thirdLevelMenu) {
													setAccordion(
														`${option.label}-${option.secLevelMenu[0]}-sub`,
														".sub-sub-menu",
														".sub-arrow");
												}
											}}>

											{menu === option.menu && !option.secLevelMenu && (
												<>
													<div className="absolute -top-12 right-0 w-12 h-12 rounded-full"
														style={{ backgroundColor: colorBgMenu, boxShadow: `24px 24px 0 ${colorMenu}` }} />
													<div className="absolute -bottom-12 right-0 w-12 h-12 rounded-full"
														style={{ backgroundColor: colorBgMenu, boxShadow: `24px -24px 0 ${colorMenu}` }} />
												</>
											)}
											<a href="#" className="relative w-full flex justify-start items-center text-white">
												{(indicator?.menu === option.menu) &&
													<div className="absolute left-5 w-5 h-5 top-2 leading-5 flex justify-center items-center rounded-full
                                                            bg-red-500 text-white text-smaller">{indicator.value}
													</div>}
												<p className={`hidden lg:flex items-center h-10 z-40 
                                                ${menu === option.menu && !activeRefines?.secLevelMenu ? textColor : ""}`}><Picto /></p>

												<AlphaTooltip placement="right" arrow
													title={<div className="bg-black bg-opacity-50 px-2 py-2 ml-4 rounded-lg uppercase">
														{option.label}
													</div>}>
													<p className={`flex lg:hidden items-center h-10 z-40 
                                                        ${menu === option.menu && !activeRefines?.secLevelMenu ? textColor : ""}`}><Picto /></p>
												</AlphaTooltip>

												<p className={`hidden lg:flex items-center pl-4 h-14 font-semibold whitespace-nowrap uppercase
                                                         ${menu === option.menu && !activeRefines?.secLevelMenu ? textColor : ""}`}>
													{option.label}
												</p>

											</a>
											{option.secLevelMenu && <KeyboardArrowDownIcon className={`${option.label}-arrow arrow text-white`} />}
										</div>

										{/** ******** secLevelMenu ********* */}
										{option.secLevelMenu
											&& <ul className={`${option.label}-accordion accordionHide sub-menu space-y-2 mr-5 pl-8 pt-3 mb-5 rounded-lg
                                            z-3xl text-white text-medium`}>
												{option.secLevelMenu.map((el, i) => (
													<>
														<li key={`${el}-${i}`} className="-mt-5 -mr-3 lg:mr-0 cursor-pointer rounded-lg pl-3
                                                         lg:pr-0.5 py-0.5 font-medium"
														onClick={() => {
															dispatch(appSlice.actions.clear(secLevelMenuClearRefineKeys));
															dispatch(appSlice.actions.setPage(option.menu));
															dispatch(appSlice.actions.refine([{ key: "secLevelMenu", value: el }]));

															if (option.thirdLevelMenu) {
																// Open the first sub-menu by default
																const refineValue =
																option?.thirdLevelMenu?.find((obj) => obj[el])[el];
																dispatch(appSlice.actions
																.refine([{ key: "thirdLevelMenu", value: refineValue?.[0] }]));

																setAccordion(
																		`${option.label}-${el?.replaceAll(" ", "-")}-sub`,
																		".sub-sub-menu",
																		".sub-arrow");
															}
														}}>
															<span
																className="flex flex-row justify-between items-center rounded-lg transition duration-400
                                                                        ease-in-out transform hover:-translate-y-0.5 hover:scale-105"
																style={{
																	backgroundColor: (activeRefines?.secLevelMenu === el) ? colorMenu : "",
																	color: activeRefines?.secLevelMenu === el ? subMenuTextColor : ""
																}}
															>
																<div className="flex justify-center items-center">
																	<AlphaTooltip placement="right" arrow
																		title={<div className="bg-black bg-opacity-50 px-2 py-2 ml-4 rounded-lg">
																			{menuNameBuilder(el).toUpperCase()}
																		</div>}>
																		<ShortTextIcon className="text-base block lg:hidden" />
																	</AlphaTooltip>
																	<span className="hidden lg:block uppercase ml-1">{menuNameBuilder(el)}</span>
																</div>

																{option.thirdLevelMenu &&
																	<KeyboardArrowDownIcon
																		className={`${option.label}-${el?.replaceAll(" ", "-")}-sub-arrow sub-arrow`} />}

															</span>
														</li>

														{/** ******** thirdLevelMenu ********* */}
														{/* Only if there are actual values in the thirdLevelMenu and not just an empty array.
														The values for the secLevelMenu and thirdLevelMenu for PROJECTS tab come from admin lambda.
														*/}
														{option?.thirdLevelMenu?.find((obj) => obj[el])[el]?.length > 0
															&& <ul className=
																{`${option.label}-${el?.replaceAll(" ", "-")}-sub-accordion accordionHide sub-sub-menu
																mr-5 mt-1 mb-2 pl-6 rounded-lg z-3xl pb-1 text-white text-medium`}>
																{option?.thirdLevelMenu?.find((obj) => obj[el])[el]?.map((el, i) => (
																	<li key={`${el}-${i}`}
																		className="cursor-pointer font-medium rounded-lg  transition duration-400
                                                                        whitespace-nowrap ease-in-out transform hover:-translate-y-0.5 hover:scale-105
																		mt-2 text-[0.6rem]"
																		onClick={() => {
																			dispatch(appSlice.actions.clear(thirdLevelMenuClearRefineKeys));
																			dispatch(appSlice.actions.refine([
																				{ key: "thirdLevelMenu", value: el }]));
																		}}
																		style={{
																			backgroundColor: activeRefines?.thirdLevelMenu === el ? colorMenu : "",
																			color: activeRefines?.thirdLevelMenu === el ? subMenuTextColor : ""
																		}}
																	>
																		<div className="flex flex-row items-center">
																			<AlphaTooltip placement="right" arrow
																				title={<div className="bg-black bg-opacity-50 px-2 py-2 ml-4 rounded-lg">
																					{el.toUpperCase()}
																				</div>}>
																				<HorizontalRuleIcon
																					className="text-base block lg:hidden rounded lg:rounded-lg "
																					style={{
																						backgroundColor: activeRefines?.thirdLevelMenu === el
																							? colorMenu : "",
																						color: activeRefines?.thirdLevelMenu === el
																							? subMenuTextColor : ""
																					}}
																				/>
																			</AlphaTooltip>
																			<span className="hidden lg:block uppercase ml-1">{el}</span>
																		</div>
																	</li>
																))}
															</ul>}
													</>
												))}
											</ul>}
									</>
								);
							})}

						</div>
					</div>

					<div className={`flex flex-col ml-5 mb-12 ${textColor}`}>
						<div className="flex items-center space-x-3">
							<p className="flex flex-shrink-0 items-center justify-center mb-2 rounded-full w-14 h-14 text-white font-semibold"
								style={{ backgroundColor: colorAccent }}>
								{user.initials}
							</p>
							<p className="flex flex-shrink-0  text-sm">{user.name}</p>
						</div>
						<p className="text-sm underline font-semibold pl-1 cursor-pointer"
							onClick={() => signOut()}>
							Logout
						</p>
					</div>
				</div>
			</div>

			{/*
            * Menu Mobile
            */}
			<div className={`absolute bottom-0 w-full flex flex-col shadow-2xl
                md:hidden rounded-tl-3xl rounded-tr-3xl overflow-hidden z-3xl
                transition-height  duration-500 ease-out border-t
                ${!mobileDetails ? "h-16 bg-white" : "h-full alphaBlur"}`}>

				{mobileDetails && <div className="relative flex flex-col justify-center h-full pt-4 px-10 gap-y-6 overflow-y-auto">

					<div className="self-center flex items-center pb-6 gap-x-4 gap-y-1">
						<img src={appDefinition.logo} className="h-24" />
					</div>

					{menuOptions.map((option, i) => {
						const Picto = option.picto;
						return (
							<>
								<div key={`menu ${i}`}
									className="flex list-none w-full pl-4"
									onClick={() => {
										if (activeRefines.secLevelMenu) { dispatch(appSlice.actions.clear(mainMenuClearRefineKeys)); }

										dispatch(appSlice.actions.setPage(option.menu));

										option.clearRefine?.map((clearRefine) => (dispatch(appSlice.actions
										.refine([{ key: clearRefine, value: undefined }]))));

										// close all accordion when switch menu
										if (!option.secLevelMenu) clearAccordion(".sub-menu", ".arrow");
										if (!option.thirdLevelMenu) clearAccordion(".sub-sub-menu", ".sub-arrow");

										if (option.secLevelMenu) {
											if (!activeRefines.secLevelMenu || option.menu !== currentPage) {
												dispatch(appSlice.actions.refine([{ key: "secLevelMenu", value: option.secLevelMenu[0] }]));
											}
											setAccordion(option.label, ".sub-menu", ".arrow");
										}

										if (option.thirdLevelMenu) {
											setAccordion(
												`${option.label}-${option.secLevelMenu[0]}-sub`,
												".sub-sub-menu",
												".sub-arrow");
										}
									}}>
									<a href="#" className="relative w-full flex justify-start items-center gap-x-8">
										<p className={`flex items-center text-gray-500 
                                        ${menu === option.menu && !activeRefines?.secLevelMenu ? "text-gray-500" : ""}`}><Picto /></p>
										<p className={"items-center text-2xl font-semibold whitespace-nowrap text-gray-500"}>
											{option.label}
										</p>
									</a>
									{option.secLevelMenu && <KeyboardArrowDownIcon className={`${option.label}-arrow arrow text-gray-500`} />}

								</div>

								{/** ********mobile secLevelMenu ********* */}
								{option.secLevelMenu
									&& <ul className={`${option.label}-accordion accordionHide sub-menu space-y-2 mr-5 pl-8 py-3 rounded-lg
                                    z-3xl text-white text-medium`}>
										{option.secLevelMenu.map((el, i) => (
											<>
												<li key={`${el}-${i}`} className="-mt-5 flex items-center w-min cursor-pointer
                                                rounded-lg px-3 py-0.5 font-medium"
												onClick={() => {
													dispatch(appSlice.actions.clear(secLevelMenuClearRefineKeys));
													dispatch(appSlice.actions.setPage(option.menu));
													dispatch(appSlice.actions.refine([{ key: "secLevelMenu", value: el }]));

													if (option.thirdLevelMenu) {
														setAccordion(
																`${option.label}-${el}-sub`,
																".sub-sub-menu",
																".sub-arrow");
													}
												}}
												style={{
													backgroundColor: activeRefines?.secLevelMenu === el ? "#1C3FAA" : "",
													color: activeRefines?.secLevelMenu === el ? "#FFFFFF" : "#6b7280"
												}}
												>
													<ShortTextIcon className="text-base mr-1" />
													<span className="uppercase">{menuNameBuilder(el)}</span>
													{option.thirdLevelMenu &&
														<KeyboardArrowDownIcon className={`${option.label}-${el}-sub-arrow sub-arrow`} />}

												</li>

												{/** ******** mobile thirdLevelMenu ********* */}
												{/* {option.thirdLevelMenu
													&& <ul className={`${option.label}-${el}-sub-accordion accordionHide sub-sub-menu
                                                    space-y-1.5 mr-5 pl-6 py-3 rounded-lg z-3xl text-white text-medium`}>
														{option?.thirdLevelMenu
														?.[option?.secLevelMenu?.indexOf(el)]?.[el]
														.map((el1, i) => (
															<li key={`${el1}-${i}`} className="flex items-center w-min whitespace-nowrap cursor-pointer
                                                            rounded-lg px-3 py-0.5 font-medium"
															onClick={() => {
																dispatch(appSlice.actions.clear([{ refine: "finalProduct" }]));
																dispatch(appSlice.actions.refine([
																	{ key: "secLevelMenu", value: el },
																	{ key: "thirdLevelMenu", value: el1 },
																	{ key: "projectName", value: el1 },
																	{ key: "spaceLabel", value: el1 },
																	{ key: "appAction", value: "updateParams" }]));
															}}
															style={{
																backgroundColor: activeRefines?.thirdLevelMenu === el1 ? "#1C3FAA" : "",
																color: activeRefines?.thirdLevelMenu === el1 ? "#FFFFFF" : "#6b7280"
															}}>
																<HorizontalRuleIcon className="text-base mr-1" />
																<span className="uppercase">{el1}</span>
															</li>

														))}
													</ul>} */}
											</>))}
									</ul>}

							</>
						);
					})}
					<div className="flex items-center mt-6 gap-x-4 gap-y-1 ">
						<p className="flex flex-shrink-0 items-center justify-center mb-2 rounded-full w-14 h-14 text-white font-semibold"
							style={{ backgroundColor: colorAccent }}>
							{user.initials}
						</p>
						<div className="flex flex-col justify-center h-full text-gray-500 ">
							<p className="flex flex-shrink-0 text-sm ">{user.name}</p>
							<p className="text-medium font-semibold pl-1 cursor-pointer"
								onClick={() => signOut()}>
								Logout
							</p>
						</div>
					</div>
				</div>
				}

				<div className={"flex"}>
					<div className="flex items-center between w-full h-full px-4 pt-2">
						<div className="flex items-center gap-x-2 h-full w-full ">
							{!mobileDetails ?
								<img src={appDefinition.logo} className="h-10 pr-2" />
								:
								<Link to="/" className="flex items-center gap-x-2 text-gray-500 cursor-pointer">
									<AppsIcon />
									<p className="pt-1 text-sm">Launcher</p>
								</Link>
							}

							{!mobileDetails && menuOptions.filter((element) => !element.noMobileMenu).map((option, i) => {
								const Picto = option.picto;
								return (
									<>
										<div key={`menuMobile ${i}`}
											className="relative px-3 w-12 h-full list-none  rounded-tl-full rounded-tr-full "
											style={{ backgroundColor: (menu === option.menu) ? colorMenu : "" }}
											onClick={() => {
												if (activeRefines.secLevelMenu) {
													dispatch(appSlice.actions.clear([{ refine: "secLevelMenu" }]));
												}

												dispatch(appSlice.actions.setPage(option.menu));

												option.clearRefine?.map((clearRefine) => (dispatch(appSlice.actions
												.refine([{ key: clearRefine, value: undefined }]))));

												// close all accordion when switch menu
												if (!option.secLevelMenu) clearAccordion(".sub-menu", ".arrow");

												if (option.secLevelMenu) {
													dispatch(appSlice.actions.refine([{ key: "secLevelMenu", value: option.secLevelMenu[0] }]));
													setAccordion(option.label, ".sub-menu", ".arrow");
												}
											}}>
											<a href="#" className="relative w-full flex justify-start items-center text-white">
												<p className={`flex items-center h-14 ${menu === option.menu ? textColor : "text-gray-500"}`}>
													<Picto />
												</p>
											</a>

										</div>

									</>
								);
							})}
						</div>

						<button className="py-4" onClick={() => setMobileDetails(!mobileDetails)}>
							{mobileDetails ?
								<CloseIcon className="text-gray-500 cursor-pointer" />
								: <MenuIcon className="text-gray-500 cursor-pointer" />
							}
						</button>
					</div>
				</div>

			</div>

		</>

	);
};

AdminSideMenu.propTypes = {
	AppIconColor: PropTypes.string,
	appDefinition: PropTypes.object,
	appSlice: PropTypes.object,
	colorAccent: PropTypes.string,
	colorBgMenu: PropTypes.string,
	colorMenu: PropTypes.string,
	indicator: PropTypes.object,
	locales: PropTypes.string,
	menu: PropTypes.string,
	menuOptions: PropTypes.array,
	noTitle: PropTypes.bool,
	onClick: PropTypes.func,
	subMenuTextColor: PropTypes.string,
	textColor: PropTypes.string
};

export default AdminSideMenu;
