import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";

const Input = styled(MuiInput)`
  width: 42px;
`;

// const debounce = (func, timeout = 1000) => {
// 	let timer;
// 	return (...args) => {
// 		clearTimeout(timer);
// 		timer = setTimeout(() => { func.apply(this, args); }, timeout);
// 	};
// };

const InputSlider = ({
	appSlice, sliderColor = "#ABB1CF", refineKey = undefined, options = undefined, locales = undefined
}) => {
	const { t } = useTranslation(locales);
	const label = t(`filters.${options?.label}`);
	const [value, setValue] = React.useState(100);
	const styleRef = useRef();

	const dispatch = useDispatch();

	const activeRefines = useSelector(appSlice.selectActiveRefines);

	useEffect(() => {
		if (activeRefines.isCleared) {
			setValue(100);
			dispatch(appSlice.actions.refine([{ key: "isCleared", value: false }, { key: refineKey, value: 1 }]));
		}
	}, [activeRefines.isCleared, dispatch, appSlice.actions, refineKey]);

	useEffect(() => {
		if ((typeof sliderColor === "string") && styleRef.current) { styleRef.current.style.color = sliderColor; }
	}, [sliderColor]);

	useEffect(() => {

	}, [activeRefines]);

	const handleSliderChange = (event, newValue) => {
		// debounce(setValue(newValue), 100);
		setValue(newValue);
	};

	const handleInputChange = (event) => {
		setValue(event.target.value === "" ? "" : Number(event.target.value));
		// debounce(dispatch(appSlice.actions.refine([{ key: refineKey, value: event.target.value === "" ? 1 : Number(event.target.value) / 100 }])));
		dispatch(appSlice.actions.refine([{ key: refineKey, value: event.target.value === "" ? 1 : Number(event.target.value) / 100 }]));
	};
	const handleSliderQuery = (event, newValue) => {
		// debounce(dispatch(appSlice.actions.refine([{ key: refineKey, value: newValue / 100 }])), 1500);
		dispatch(appSlice.actions.refine([{ key: refineKey, value: newValue / 100 }]));
	};

	const handleBlur = () => {
		if (value < 0) {
			setValue(0);
			// debounce(dispatch(appSlice.actions.refine([{ key: refineKey, value: 0 }])));
			dispatch(appSlice.actions.refine([{ key: refineKey, value: 0 }]));
		} else if (value > 100) {
			setValue(100);
			// debounce(dispatch(appSlice.actions.refine([{ key: refineKey, value: 1 }])));
			dispatch(appSlice.actions.refine([{ key: refineKey, value: 1 }]));
		}
	};
	return (
		<Box sx={{ width: 250 }}>
			<Typography id="input-slider" gutterBottom>
				<p className="font-semibold text-sm pb-2">
					{label}
				</p>
			</Typography>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs>
					<Slider
						ref={styleRef}
						value={typeof value === "number" ? value : 0}
						onChange={handleSliderChange}
						onChangeCommitted={handleSliderQuery}
						aria-labelledby="input-slider"
						valueLabelDisplay="auto"
					/>
				</Grid>
				<Grid item>
					<Input
						value={value}
						size="small"
						onChange={handleInputChange}
						onBlur={handleBlur}
						inputProps={{
							step: 1,
							min: 0,
							max: 100,
							type: "number",
							"aria-labelledby": "input-slider"
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

InputSlider.propTypes = {
	appSlice: PropTypes.object,
	sliderColor: PropTypes.string,
	refineKey: PropTypes.string,
	options: PropTypes.object,
	locales: PropTypes.object
};

export default InputSlider;
