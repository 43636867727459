import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import IF from "../../utils/IF";

function RYBSites({
	site, appSlice, rybColorMap, locales
}) {
	// Load the store hook
	const dispatch = useDispatch();

	// Internationalization hook
	const { t } = useTranslation(locales);

	// Refine on a site when a list click
	const handleSiteClick = (value) => {
		dispatch(appSlice.actions.refine(
			[{ key: "siteTechnicalId", value }]));
	};

	const TagPicto = rybColorMap[site.businessSegment] ?
		rybColorMap[site.businessSegment].picto
		: rybColorMap.Other.picto;

	return (
		<div
			className="flex border-b-1 text-ryb_primary-bg py-3 pr-4 space-x-2  hover:bg-gray-100"
		>
			<div
				className="flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center text-white"
				style={{ backgroundColor: rybColorMap[site.businessSegment].color }}
			>
				<TagPicto fontSize="small" />
			</div>
			<div className="flex flex-col flex-grow text-medium">
				<div className="cursor-pointer hover:underline"
					onClick={() => handleSiteClick(site.siteTechnicalId)}>
					<p className="text-sm font-semibold uppercase ">{site.site}</p>
					<p className="flex items-center line-clamp-2">
						<LocationOnIcon fontSize="inherit" className="mr-1" />
						{site.siteAddress}&nbsp;{site.sitePostalCode}&nbsp;{site.siteCity}&nbsp;{site.siteCountry}
					</p>
				</div>
				<div className="flex justify-between pt-3">
					<div className="flex flex-col ">
						<IF condition={site.publicPhone && site.publicPhone !== "nc"}>
							<a href={`tel:${site.publicPhone}`}
								className="flex items-center w-40 xl:w-64 truncate hover:underline"
								target="_blank" rel="noreferrer">
								<PhoneIcon fontSize="inherit" className="mr-1" />
								{site.publicPhone}
							</a>
						</IF>
						<IF condition={site.publicEmail && site.publicEmail !== "nc"}>
							<a href={`mailto:${site.publicEmail}`}
								className="flex items-center w-40 xl:w-64 truncate hover:underline"
								target="_blank" rel="noreferrer">
								<EmailIcon fontSize="inherit" className="mr-1" />
								{site.publicEmail}
							</a>
						</IF>
					</div>
					<IF condition={site.publicWebSite && site.publicWebSite !== "nc"}>
						<a href={site.publicWebSite}
							className="flex flex-shrink-0 items-center bg-ryb_primary-default
                                       text-sm text-white font-bold px-4 py-1 rounded"
							target="_blank" rel="noreferrer" >
							{t("list.website")}
							<OpenInNewIcon fontSize="small" className="pl-1" />{" "}
						</a>
					</IF>
				</div>
			</div>
		</div>
	);
}

RYBSites.propTypes = {
	site: PropTypes.object,
	appSlice: PropTypes.object,
	rybColorMap: PropTypes.object,
	locales: PropTypes.string
};

export default RYBSites;
