import React, {
	useEffect, createRef, useState, useMemo
} from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useTranslation } from "react-i18next";

import { sum } from "d3";
import { rollups } from "d3-array";

import { ParentSize } from "@visx/responsive";
import { use100vh } from "react-div-100vh";

import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LockIcon from "@mui/icons-material/Lock";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { userSelector } from "../data/slices/UserSlice";

import AdminSideMenu from "../components/menu/AdminSideMenu";
import { adminMenu } from "../api/adminControl";
import AdminCollectionCard from "../components/cards/AdminCollectionCard";
import AdminSimpleCard from "../components/cards/AdminSimpleCard";
import AdminCreateForm from "../components/form/AdminCreateForm";
import AdminProjectCreateForm from "../components/form/AdminProjectCreateForm";
import AdminSupplierCreateForm from "../components/form/AdminSupplierCreateForm";
import AdminDomainCreateForm from "../components/form/AdminDomainCreateForm";
import AdminTranslationsUpdateForm from "../components/form/AdminTranslationsUpdateForm";
import AdminProjectUpdateForm from "../components/form/AdminProjectUpdateForm";
import AdminSupplierUpdateForm from "../components/form/AdminSupplierUpdateForm";
import AdminDomainUpdateForm from "../components/form/AdminDomainUpdateForm";
import AdminuUserForm from "../components/form/AdminuUserForm";
import AdminTranslationCreateForm from "../components/form/AdminTranslationCreateForm";
import AdminAppScopeForm from "../components/form/AdminAppScopeForm";
import AdminHeader from "../components/header/AdminHeader";
import AdminRequestTable from "../components/table/AdminRequestTable";
import AdminUserTable from "../components/table/AdminUserTable";
import AdminAppScopeTable from "../components/table/AdminAppScopeTable";
import AdminSupplyChainTable from "../components/table/AdminSupplyChainTable";
import AdminDomainsTable from "../components/table/AdminDomainsTable";
import AdminTranslationsTable from "../components/table/AdminTranslationsTable";
import AdminTreeNodesGraph from "../components/tree/AdminTreeNodesGraph";
import MapLeaflet from "../components/map/MapLeaflet";
import AdminMapMarker from "../components/map/AdminMapMarker";
import AdminLayer from "../components/map/AdminLayer";

import SectionIndexs from "../components/cards/SectionIndexs";
import CircleIndex from "../components/cards/CircleIndex";

import VariableRadiusDonut from "../components/graphs/VariableRadiusDonut";
import TrendChart from "../components/graphs/TrendChart";

import Page401 from "../pages/Page401";
import IF from "../utils/IF";
import Loading from "../utils/Loading";
import { isUserAlphaRole } from "../utils/helpers";

import {
	appDefinition, menuOptions, adminInitDatasets, adminInitRefines, projectSecLevelMenu, getMarkerColor, fieldsTooltip, geoDataset,
	TORCollection, clientCollection, fieldAttributeBuilder, chainTableStructure, getSupplychainChildren,
	supplierCreateBuilder, supplierUpdateNodeBuilder, supplierUpdateLocBuilder, childrenAccessorBuilder, clientParamsDatasetBuilder, clearRefineKeys,
	alertTextBuilder, newProject, chainRefineFieldBuilder, formatClarityV4Referential, newConfigTemplate
} from "./configs/AdminConfig";

import {
	userTableStructure, appScopeTableStructure, requestTableStructure, domainsTableStructure,
	clarityV4ReferentialTableStructure, adminTranslationsTableStructure
} from "../components/table/AdminTableStructure";

import createAdminSlice, {
	addOneProject, updateProject, updateManyProject, replaceDoc, deleteDoc, addOneDoc
} from "../data/slices/createAdminSlice";

import {
	projectCreate, projectUpdate, projectDelete, adminUserCreate, adminUserUpdate, adminAppCreate, adminAppReplace, adminDomainCreate,
	clarityV4ReferentialUpdateEntry, clarityV4ReferentialCreateEntry, executeAdminPipeline, adminDomainUpdate, adminTranslationUpdate,
	adminTranslationCreate
} from "../components/form/AdminFormOnConfirm";

import Popconfirm from "../components/modal/Popconfirm";
import ClarityV4ReferentialTable from "../components/table/ClarityV4ReferentialTable";
import ClarityV4ReferentialForm from "../components/form/ClarityV4ReferentialForm";
import UploadFile from "../components/form/UploadFile";
import ClientParametersInfoCard from "../components/cards/ClientParametersInfoCard";

const appSlice = createAdminSlice(
	appDefinition.slice,
	menuOptions.filter((option) => option.initial)[0].menu,
	adminInitRefines,
	adminInitDatasets
);

const Admin = () => {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	// Create and load the app slice in the store
	const store = useStore();

	// Load the store hook
	const dispatch = useDispatch();

	// Load user
	const user = useSelector(userSelector);

	// Check user right for example upload
	const isAlpha = isUserAlphaRole(user);

	// Load screen layout parameters
	const screenHeight = use100vh();

	// Load ref of content section
	const topRef = createRef();

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const isActiveFilter = useSelector(appSlice.isRefined);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	// Set supply chain view
	const [supplychainView, setSupplychainView] = useState(false);

	// Set Marker position in create/update form
	const [markerPosition, setMarkerPosition] = useState("");

	// strategic page admin
	const [isAdmin, setIsAdmin] = useState(false);

	// set alert modal
	const [alert, setAlert] = useState({ isDiplayed: false, msg: "" });

	const [showReadMe, setShowReadMe] = useState(false);

	// get refine key on spaceLabel or projectName based on application
	const chainRefineKey = chainRefineFieldBuilder[activeRefines?.secLevelMenu];

	const groupKey = useMemo(() => {
		if (menu === "PROJECT" && activeRefines?.appAction === "view") {
			const clientrollupsParams = activeDatasets?.[`${activeRefines.secLevelMenu}ClientParameters`]?.data[0]?.supplychainParams;

			if (clientrollupsParams) return clientrollupsParams?.isCommon ? "siteUniqueId" : clientrollupsParams?.groupKey;

			return "siteUniqueId";
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeRefines, activeDatasets]);

	// Load the reducer and favicon
	useEffect(() => {
		store.injectReducer(appDefinition.slice, appSlice.reducer);
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load the allowed menu field
	useEffect(() => {
		async function MenuBuilder() {
			const menuData = await adminMenu({ appName: "admin" });
			menuOptions[1] = Object.assign(menuOptions[1], menuData);
		}
		MenuBuilder().catch(console.error);
	}, []);

	// Fetch and refresh data in the store
	useEffect(() => {
		if (activeDatasets === undefined) {
			return;
		}
		dispatch(appSlice.fetchDatasets());
	}, [activeRefines]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch supplychain info (initial only if project page only)
	useEffect(() => {
		if (
			menu === "PROJECT"
			&& activeRefines?.secLevelMenu
			&& activeDatasets?.[`${activeRefines.secLevelMenu}ChainToR`]?.data
		) {
			let supplyChain;

			if (activeRefines.secLevelMenu === "clarity") {
				supplyChain = rollups(
					activeDatasets?.clarityChainToR?.data,
					(v) => ({
						score: sum(v, (d) => activeRefines.domain === undefined ?
							(d.siteScore ? (d.siteScore * 100).toFixed(0) : 0)
							: (d.domainScores?.[d.domain?.indexOf(activeRefines.domain)]
								? (d.domainScores[d.domain.indexOf(activeRefines.domain)] * 100).toFixed(0)
								: 0)
						)
					}),
					(d) => d.finalProduct,
					(d) => Number.parseInt(d.tierLevel, 10),
					(d) => d.targetProduct,
					(d) => typeof d.nextSupplier === "string" ? d.nextSupplier : JSON.stringify(d.nextSupplier),
					(d) => [d[groupKey]?.toString(), d.supplier, d.company, d.city, d.country,
						d.product, d.geopointToR, d.countryRisk, d.criticality, d.siteId, d.type, d.region,
						d._id, d.nextSupplier, d.finalProduct, d.targetProduct, d.tierLevel, d.inactive, d.domains, d.spaceLabel]
				);
			}

			if (activeRefines.secLevelMenu === "supplyR" || activeRefines.secLevelMenu === "verkor") {
				supplyChain = rollups(
					activeDatasets?.[`${activeRefines.secLevelMenu}ChainToR`]?.data,
					(v) => ({
						resilience: sum(v, (d) => (d?.resilience ?? 0)),
						resistance: sum(v, (d) => (d?.resistance ?? 0)),
						responsiveness: sum(v, (d) => (d?.responsiveness ?? 0))
					}),
					(d) => d.finalProduct,
					(d) => Number.parseInt(d.tierLevel, 10),
					(d) => d.targetProduct,
					(d) => d.nextSupplier,
					(d) => [d[groupKey], d.supplier, d.auditedCompany, d.city, d.country,
						d.product, d.geometry, d.countryRisk, d.criticality, d.siteId, d.type,
						d.region, d.tierLevel, d.tierLevelDisplay, d.client, d.finalProduct,
						d.targetProduct, d.nextSupplier, d.logisticAgent, d.tierLabel, d._id, d.siteLabelId,
						d.supplierCode, d.factoryCode, d.module, d.criticality, d.factoryBranch, d.factoryIndex, d.validated]
				);
			}

			if (activeRefines.secLevelMenu === "ataglance") {
				supplyChain = rollups(
					activeDatasets?.[`${activeRefines.secLevelMenu}ChainToR`]?.data,
					(v) => ({
						resilience: sum(v, (d) => (d?.resilience ?? 0)),
						resistance: sum(v, (d) => (d?.resistance ?? 0)),
						responsiveness: sum(v, (d) => (d?.responsiveness ?? 0))
					}),
					(d) => d.finalProduct,
					(d) => Number.parseInt(d.tierLevel, 10),
					(d) => d.targetProduct,
					(d) => d.nextSupplier,
					(d) => [d[groupKey], d.supplier, d.auditedCompany, d.city, d.country, d.product, d.countryRisk, d.criticality,
						d.siteId, d.type, d.region, d.tierLevel, d.client, d.finalProduct, d.targetProduct, d.nextSupplier, d._id,
						d.siteLabelId, d.criticality
					]
				);
			}

			const rawSupplychain = {
				name: "client",
				children: supplyChain
				?.sort((a, b) => a[0] > b[0] ? 1 : -1)
				?.map((finalProduct, i) => ({
					name: finalProduct[0],
					children: getSupplychainChildren(activeRefines.secLevelMenu, finalProduct)
				}))
			};

			// inject calculated dataset in the store
			dispatch(
				appSlice.actions.fetchCalculatedDataset({
					datasetName: `${activeRefines.secLevelMenu}SupplyChainTree`,
					datasetData: rawSupplychain
				})
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeDatasets?.supplyRChainToR, activeDatasets?.clarityChainToR, activeDatasets?.compassChainToR, activeDatasets?.verkorChainToR, groupKey]);

	// Reload window if data expired
	useEffect(() => {
		if (loadDataStatus === "sessionExpired") {
			setTimeout(() => {
				window.location.reload();
			}, 2000);
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	// Go top if menu has changed
	useEffect(() => {
		document?.getElementById("content-scroll")?.scroll({ top: 0, behavior: "smooth" });
	}, [menu, activeRefines?.siteId, activeRefines?.module]); // eslint-disable-line react-hooks/exhaustive-deps

	if (loadDataStatus === "sessionExpired") {
		return <Page401 />;
	}

	// Wait for Data to render App
	if (activeDatasets === undefined) {
		return <Loading message="Wait a moment while we load your app." />;
	}

	return (
		<div className="relative h-full w-full">
			<IF condition={loadDataStatus === "loading"}>
				<Loading />
			</IF>

			<div
				className="relative flex justify-between max-w-screen-3xl mx-auto h-full bg-admin_primary-default" id="full-screen">
				{/*
				* Menu Bar of the Admin APP
				*/}
				<AdminSideMenu
					appSlice={appSlice}
					appDefinition={appDefinition}
					menuOptions={menuOptions}
					menu={menu}
					noTitle
					colorMenu="#FFFFFF"
					colorBgMenu="#1C3FAA"
					textColor="text-admin_primary-default"
					colorAccent="#C8D3D5"
					locales={appDefinition.locales}
					// clear refine when return to launch
					onClick={() => {
						if (Object.keys(activeRefines)?.length > 0) {
							const clearRefineKeys = Object?.keys(activeRefines)?.map((e) => ({ refine: e }));
							dispatch(appSlice.actions.clear(clearRefineKeys));
						}
					}}
				/>

				{/*
				* Content of the APP
				*/}
				<div
					id="content-scroll"
					className="w-full overflow-y-auto pt-3 px-3 md:pt-10 md:pr-8 md:pl-0 "
					style={{ height: screenHeight }}
				>
					<div ref={topRef} className="flex-grow w-full h-full bg-white rounded-t-3xl">
						<IF condition={(menu === "HOME")} >
							<div className="flex flex-col justify-start items-center bg-white rounded-3xl px-4 pt-8 mt-4 space-y-5">
								<SectionIndexs />

								<div className="flex flex-col md:flex-row justify-around w-full md:space-x-1">
									<div className="flex flex-col md:flex-row w-full md:w-2/3 md:space-x-1">
										<div className="flex flex-col justify-center items-center h-64
										 md:h-96 w-full md:w-1/3 space-y-5 px-5 border rounded-lg">

											{/* TODO:remove mock data */}
											<div>
												<VariableRadiusDonut dataInfo={[
													{
														label: "standard",
														value: 50,
														color: "#5363E0"
													},
													{
														label: "strategic",
														value: 40,
														color: "#963DD8"
													},
													{
														label: "commodities",
														value: 30,
														color: "#4DC490"
													},
													{
														label: "bottleneck",
														value: 20,
														color: "#F16548"
													}
												]} total={100} totalCovered={50} showTitleCard={false} />
											</div>

											<div className="flex flex-col space-y-2 w-full">
												<div className="flex flex-row justify-between items-center">
													<div className="flex flex-row items-center space-x-2">
														<div className="w-2 h-2 bg-red-500 rounded-full"></div>
														<span className="text-sm">info1</span>
													</div>
													<span className="text-sm">50%</span>
												</div>
												<div className="flex flex-row justify-between items-center">
													<div className="flex flex-row items-center space-x-2">
														<div className="w-2 h-2 bg-yellow-500 rounded-full"></div>
														<span className="text-sm">info2</span>
													</div>
													<span className="text-sm">40%</span>
												</div>
												<div className="flex flex-row justify-between items-center">
													<div className="flex flex-row items-center space-x-2">
														<div className="w-2 h-2 bg-green-500 rounded-full"></div>
														<span className="text-sm">info3</span>
													</div>
													<span className="text-sm">30%</span>
												</div>
											</div>
										</div>

										<div className="flex flex-col justify-center items-center w-full md:w-2/3 h-96 mt-1 md:mt-0
															space-y-5 px-5 border rounded-lg">
											<div className="flex flex-col space-y-2 w-full">
												<div className="flex flex-col">
													<p className="text-lg">Realtime active users</p>
													<span className="text-xl font-medium">214</span>
												</div>

												<div className="flex flex-col divide-y w-full h-36 space-y-2">
													<p className="flex flex-row justify-between items-center">
														<span className="text-sm">Page views per second</span>
														<span className="text-sm font-medium text-admin_primary-default">
															2 50% <KeyboardArrowUpIcon className="text-sm" />
														</span>
													</p>
													<ParentSize debounceTime={10}>
														{(parent) => (
															<TrendChart width={parent.width} height={parent.height} />
														)}
													</ParentSize>
												</div>

												<div className="flex flex-col divide-y w-full space-y-2">
													<p className="flex flex-row justify-between">
														<span className="text-sm font-medium">Top Active Pages</span>
														<span className="text-sm font-medium">Active Users</span>
													</p>
													<ul className="flex flex-col h-18">
														<li className="flex flex-row justify-between text-medium mt-1">
															<span>/letz-lara…review/2653</span>
															<span>472</span>
														</li>
														<li className="flex flex-row justify-between text-medium mt-1">
															<span>/rubick…review/1674</span>
															<span>294</span>
														</li>
														<li className="flex flex-row justify-between text-medium mt-1">
															<span>/profile…review/46789</span>
															<span>83</span>
														</li>
													</ul>

												</div>
											</div>
										</div>
									</div>

									<div className="flex flex-col w-full md:w-1/3 h-80 sm:h-40 md:h-auto mt-1 md:mt-0">
										<CircleIndex />
									</div>
								</div>

								<div className="flex flex-col w-full mb-14 md:mb-5 px-6 pt-4 pb-20 md:pb-4 mt-4 border rounded-lg">
									<AdminUserTable
										appSlice={appSlice}
										structureTable={userTableStructure}
										dataset="adminUser"
										locales={appDefinition.locales}
										exportName="User"
										renderGroup={false}
										renderBack={false}
										renderAdd={false}
									/>
								</div>
							</div>
						</IF>

						<IF condition={menu === "PROJECT" &&
							projectSecLevelMenu.includes(activeRefines.secLevelMenu) &&
							activeRefines.thirdLevelMenu === "Clients"}>

							<IF condition={activeRefines?.appAction !== "view"}>
								<div className="flex flex-row justify-between items-center bg-white rounded-3xl px-4">
									<AdminHeader appSlice={appSlice} clearRefineKeys={[{ refine: "fourthLevelMenu" }, { refine: "appAction" },
										{ refine: "projectName" }, { refine: "spaceLabel" }]} chainRefineKey={chainRefineKey}>

										<div className="flex flex-row space-x-2">
											<IF condition={activeRefines.appAction !== "updateParams" && activeRefines.appAction !== "createParams"}>
												<div className="bg-white w-full md:w-3/4 xl:w-1/2 px-4 py-4 border rounded-lg mt-4">
													<div className="grid grid-cols-1 sm:grid-cols-3 grid-flow-row text-sm gap-3">
														{activeDatasets?.[`${activeRefines.secLevelMenu}ClientParameters`]?.data.map((collection, i) => (
															<AdminCollectionCard
																key={i}
																collection={collection}
																locales={appDefinition.locales}
																appSlice={appSlice}
																supplychainView={supplychainView}
																setSupplychainView={setSupplychainView}
																isAlpha={isAlpha}
																setAlert={setAlert}
																onConfirm={(collectionId, setconfirmModal) => {
																	projectDelete({
																		isAlpha,
																		appSlice,
																		dispatch,
																		activeRefines,
																		deleteDoc,
																		clientCollection,
																		collectionId,
																		setconfirmModal,
																		setAlert
																	});
																}}
															/>
														))}
														{alert.isDiplayed && <Popconfirm
															title="Something went wrong"
															description={alert?.msg ?? ""}
															onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
															iconsType="exclamationMark"
															cancelBtnText="Back"
															showConfirm={false}
														/>}

														<IF condition={activeRefines.appAction !== "updateParams" &&
															activeRefines.appAction !== "createParams"}>
															<button
																type="button"
																className="flex justify-self-center self-center w-min h-min mt-4 text-gray-400
																           font-bold py-12 px-24 rounded-xl uppercase transform -translate-y-2"
																onClick={() => {
																	dispatch(appSlice.actions.refine([
																		{ key: "appAction", value: "createParams" }]));
																}}>
																<AddIcon className="w-12 h-16" />
															</button>
														</IF>
													</div>
												</div>
											</IF>

											<IF condition={activeRefines.appAction === "createParams"}>
												<div className="flex w-full relative h-full overflow-hidden">
													<div className="bg-white md:border w-full h-full md:h-auto md:w-1/2 px-4 py-4
															    mt-4 mb-16 md:mb-5 rounded-3xl shadow-3xl z-10">
														<AdminProjectCreateForm
															appSlice={appSlice}
															screenHeight={screenHeight}
															data={newProject({ project: activeRefines.secLevelMenu })}
															clearRefineKeys={[{ refine: "fourthLevelMenu" }, { refine: "projectName" },
																{ refine: "spaceLabel" }, { refine: "appAction" }]}
															onConfirm={(resWithoutId, setconfirmModal, logo) => {
																projectCreate({
																	resWithoutId,
																	appSlice,
																	dispatch,
																	activeRefines,
																	addOneProject,
																	clientCollection,
																	setconfirmModal,
																	setAlert,
																	clientParamsDatasetBuilder,
																	logo,
																	refineKey: chainRefineKey
																});
															}}
															requiredFields={
																fieldAttributeBuilder(activeRefines.secLevelMenu, "createProjectRequiredFields")}
														/>
														{alert.isDiplayed && <Popconfirm
															title="Something went wrong"
															description={alert?.msg ?? ""}
															onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
															iconsType="exclamationMark"
															cancelBtnText="Back"
															showConfirm={false}
														/>}
													</div>
													{/* Info button/icon */}
													<button
														type="button"
														className="hidden md:inline-block h-9 border bg-admin_primary-default hover:bg-admin_primary-dark
														 text-white font-bold py-1 px-3 rounded uppercase absolute z-500
														 top-[34px] right-[52%] 2xl:right-[51%]"
														onClick={() => setShowReadMe(!showReadMe)}>
														<InfoOutlined />
													</button>
													{/* ReadMe screen */}
													<div className={`bg-white h-full md:h-auto px-4 py-4
															    mt-4 mb-16 md:mb-5 shadow-3xl absolute
																${!showReadMe ?
														"left-0 md:w-1/2 border rounded-3xl" :
														"left-[50%] md:w-1/2 border-t border-b border-r rounded-r-3xl"}
																transition-all duration-700`}>
														<div className="flex flex-col px-2 my-4 space-y-6 overflow-y-auto"
															style={{ height: screenHeight - 215 }}>
															<h5 className="text-center border-b border-admin_primary-default pb-[3px]
															font-bold uppercase text-admin_primary-default">Reference</h5>
															<ClientParametersInfoCard
																appSlice={appSlice}
																data={newProject({ project: activeRefines.secLevelMenu })}
																locales={appDefinition.locales} />
														</div>
													</div>
													<div className={`hidden md:flex md:items-center md:w-1/2
														${showReadMe ? "translate-x-[120%]" : "translate-x-0"} transition-transform duration-700`}>
														<img src="/images/Admin/project.svg" alt="project" />
													</div>
													<div className={`absolute top-1/2 text-admin_primary-default animate-pulse z-3xl
															cursor-pointer left-1/2 ${showReadMe ? "rotate-180" : ""} transition-rotate duration-700`}
													onClick={() => setShowReadMe(!showReadMe)}>
														<ArrowRightIcon />
													</div>
												</div>

											</IF>

											<IF condition={activeRefines.appAction === "updateParams"}>
												<div className="flex w-full relative h-full overflow-hidden">
													<div className="bg-white md:border w-full h-full md:h-auto md:w-1/2 px-4 py-4
															mt-4 mb-16 md:mb-5 rounded-3xl shadow-3xl z-10">
														<AdminProjectUpdateForm
															appSlice={appSlice}
															screenHeight={screenHeight}
															data={activeDatasets?.[`${activeRefines.secLevelMenu}ClientParameters`]?.data ?? []}
															clearRefineKeys={[{ refine: "fourthLevelMenu" }, { refine: "projectName" },
																{ refine: "spaceLabel" }, { refine: "appAction" }]}
															targetDocAccessor={(item) => (activeRefines[chainRefineKey]
															=== item[chainRefineKey]?.[0]) || (activeRefines[chainRefineKey]
																=== item[chainRefineKey])}
															onConfirm={(res, resWithoutId, setconfirmModal, logo) => {
																projectUpdate({
																	res,
																	resWithoutId,
																	appSlice,
																	dispatch,
																	activeRefines,
																	updateProject: replaceDoc,
																	clientCollection,
																	setconfirmModal,
																	setAlert,
																	clientParamsDatasetBuilder,
																	logo,
																	refineKey: chainRefineKey
																});
															}}
															requiredFields={
																fieldAttributeBuilder(activeRefines.secLevelMenu, "updateProjectRequiredFields")}
														/>
														{alert.isDiplayed && <Popconfirm
															title="Something went wrong"
															description={alert?.msg ?? ""}
															onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
															iconsType="exclamationMark"
															cancelBtnText="Back"
															showConfirm={false}
														/>}
													</div>
													{/* Info button/icon */}
													<button
														type="button"
														className="hidden md:inline-block h-9 border bg-admin_primary-default hover:bg-admin_primary-dark
														 text-white font-bold py-1 px-3 rounded uppercase absolute z-500
														 top-[34px] right-[52%] 2xl:right-[51%]"
														onClick={() => setShowReadMe(!showReadMe)}>
														<InfoOutlined />
													</button>
													{/* ReadMe screen */}
													<div className={`bg-white h-full md:h-auto px-4 py-4
															    mt-4 mb-16 md:mb-5 shadow-3xl absolute
																${!showReadMe ?
														"left-0 md:w-1/2 border rounded-3xl" :
														"left-[50%] md:w-1/2 border-t border-b border-r rounded-r-3xl"}
																transition-all duration-700`}>
														<div className="flex flex-col px-2 my-4 space-y-6 overflow-y-auto"
															style={{ height: screenHeight - 215 }}>
															<h5 className="text-center border-b border-admin_primary-default pb-[3px]
															font-bold uppercase text-admin_primary-default">Reference</h5>
															<ClientParametersInfoCard
																appSlice={appSlice}
																data={newProject({ project: activeRefines.secLevelMenu })}
																locales={appDefinition.locales} />
														</div>
													</div>
													<div className={`hidden md:flex md:items-center md:w-1/2
														${showReadMe ? "translate-x-[120%]" : "translate-x-0"} transition-transform duration-700`}>
														<img src="/images/Admin/project.svg" alt="project" />
													</div>
													<div className={`absolute top-1/2 text-admin_primary-default animate-pulse z-3xl
															cursor-pointer left-1/2 ${showReadMe ? "rotate-180" : ""} transition-rotate duration-700`}
													onClick={() => setShowReadMe(!showReadMe)}>
														<ArrowRightIcon />
													</div>
												</div>
											</IF>
										</div>
									</AdminHeader>
								</div>
							</IF>

							<IF condition={activeRefines.appAction === "view"}>
								<div className="flex flex-row justify-between items-center bg-white rounded-3xl px-4">

									<AdminHeader appSlice={appSlice} renderFilter={false} renderSwitch switchStatus={supplychainView}
										switchLabelLeft="List" switchLabelRight="SupplyChain" renderMapBtn chainRefineKey={chainRefineKey}
										clearRefineKeys={[...clearRefineKeys, { refine: "projectName" },
											{ refine: "spaceLabel" }, { refine: "appAction" }]}
										switchFunc={(v) => {
											setSupplychainView(v);
											dispatch(appSlice.actions.clear([
												{ refine: "supplyChainAction" },
												{ refine: "site" },
												{ refine: "supplier" },
												{ refine: "auditedCompany" },
												{ refine: "country" },
												{ refine: "region" },
												{ refine: "product" },
												{ refine: "type" },
												{ refine: "siteLabelId" }]
											));
										}} >

										<ParentSize>
											{(parent) => (
												<>
													{/* supplychain tree view */}
													<IF condition={supplychainView}>
														{/* new node creation */}
														<IF condition={activeRefines.site && activeRefines.supplyChainAction === "createNode"}>
															<div className="h-full flex flex-row justify-between items-center bg-white rounded-3xl px-4">
																<div className="flex flex-col px-4 py-4 mt-4 mb-16 md:mb-5 md:border bg-white
															 		 w-full h-full md:h-auto md:w-1/2 rounded-3xl">
																	<AdminSupplierCreateForm
																		appSlice={appSlice}
																		screenHeight={screenHeight}
																		data={activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data}
																		clearRefineKeys={clearRefineKeys}
																		// if supplier selected from drop down then change accessor
																		targetDocAccessor={(item) => activeRefines?.supplier
																			? activeRefines.supplier === item.supplier
																			: activeRefines.site === item._id}
																		onConfirm={({ currentNode, originTargetDoc, setconfirmModal }) => {
																			const supplierCreate = supplierCreateBuilder(activeRefines.secLevelMenu);

																			supplierCreate({
																				currentNode,
																				originTargetDoc,
																				setconfirmModal,
																				activeDatasets,
																				activeRefines,
																				dispatch,
																				appSlice,
																				addOneProject,
																				TORCollection,
																				setMarkerPosition,
																				setAlert
																			});
																		}}
																		onReset={(targetDocumentWithK) => {
																			// it refers to the fields which should never be overwritten
																			// in any case when select from supplier drop down
																			// const ignoredField = ["nextSupplier", "finalProduct", "tierLevel",
																			// 	"tierLabel", "projectName", "targetProduct"];
																			const ignoredField = ["_id", "key"];

																			const res = Object.entries(targetDocumentWithK)
																			.filter((item) => !ignoredField.includes(item[0]))
																			.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {});

																			return res;
																		}}
																		setMarkerPosition={setMarkerPosition}
																		disabledFields={
																			fieldAttributeBuilder(activeRefines.secLevelMenu, "createNodeDisabledFields")}
																		requiredFields={
																			fieldAttributeBuilder(activeRefines.secLevelMenu, "createNodeRequiredFields")}
																		setAlert={setAlert}
																	/>
																	{alert.isDiplayed && <Popconfirm
																		title="Something went wrong"
																		description={alert?.msg ?? ""}
																		onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
																		iconsType="exclamationMark"
																		cancelBtnText="Back"
																		showConfirm={false}
																	/>}
																</div>
																<div className="hidden md:block md:w-1/2 pl-4">
																	<ParentSize debounceTime={10}>
																		{(parent) => (
																			<MapLeaflet
																				appSlice={appSlice}
																				CustomLayer={AdminLayer}
																				CustomMarker={AdminMapMarker}
																				fieldsTooltip={fieldsTooltip}
																				mapType="jawgTerrain"
																				maxClusterRadius={30}
																				defaultPosition={[47.221, 2.672]}
																				zoom={5}
																				minZoom={3}
																				maxZoom={20}
																				scrollWheelZoom={true}
																				mapHeight={screenHeight - 150}
																				getMarkerColor={getMarkerColor}
																				locales={appDefinition.locales}
																				markersFilter={(d) => d._id === activeRefines.site}
																				markerPosition={markerPosition}
																			/>
																		)}
																	</ParentSize>
																</div>
															</div>
														</IF>
														{/* new branch creation */}
														<IF condition={!activeRefines.site && activeRefines.supplyChainAction === "createBranch"}>
															<div className="h-full flex flex-row justify-between items-center bg-white rounded-3xl px-4">
																<div className="flex flex-col px-4 py-4 mt-4 mb-16 md:mb-5 md:border bg-white
															 		 w-full h-full md:h-auto md:w-1/2 rounded-3xl">
																	<AdminSupplierCreateForm
																		appSlice={appSlice}
																		screenHeight={screenHeight}
																		data={activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data}
																		clearRefineKeys={clearRefineKeys}
																		// if supplier selected from drop down then change accessor
																		targetDocAccessor={(item) => activeRefines?.supplier
																			? activeRefines.supplier === item.supplier
																			: activeRefines.site === item._id}
																		onConfirm={({ currentNode, originTargetDoc, setconfirmModal }) => {
																			const supplierCreate = supplierCreateBuilder(activeRefines.secLevelMenu);

																			supplierCreate({
																				currentNode,
																				originTargetDoc,
																				setconfirmModal,
																				activeDatasets,
																				activeRefines,
																				dispatch,
																				appSlice,
																				addOneProject,
																				TORCollection,
																				setMarkerPosition,
																				setAlert
																			});
																		}}
																		onReset={(targetDocumentWithK) => {
																			// it refers to the fields which should never be overwritten
																			// in any case when select from supplier drop down
																			// const ignoredField = ["nextSupplier", "finalProduct", "tierLevel",
																			// 	"tierLabel", "projectName", "targetProduct"];
																			const ignoredField = ["_id", "key"];

																			const res = Object.entries(targetDocumentWithK)
																			.filter((item) => !ignoredField.includes(item[0]))
																			.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {});

																			return res;
																		}}
																		setMarkerPosition={setMarkerPosition}
																		disabledFields={
																			activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]
																			?.data.length === 0
																				? []
																				: fieldAttributeBuilder(activeRefines.secLevelMenu,
																					"createBranchDisabledFields")
																		}
																		requiredFields={
																			fieldAttributeBuilder(activeRefines.secLevelMenu, "createBranchRequiredFields")
																		}
																		setAlert={setAlert}
																	/>
																	{alert.isDiplayed && <Popconfirm
																		title="Something went wrong"
																		description={alert?.msg ?? ""}
																		onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
																		iconsType="exclamationMark"
																		cancelBtnText="Back"
																		showConfirm={false}
																	/>}
																</div>
																<div className="hidden md:block md:w-1/2 pl-4">
																	<ParentSize debounceTime={10}>
																		{(parent) => (
																			<MapLeaflet
																				appSlice={appSlice}
																				CustomLayer={AdminLayer}
																				CustomMarker={AdminMapMarker}
																				fieldsTooltip={fieldsTooltip}
																				mapType="jawgTerrain"
																				maxClusterRadius={30}
																				defaultPosition={[47.221, 2.672]}
																				zoom={5}
																				minZoom={3}
																				maxZoom={20}
																				scrollWheelZoom={true}
																				mapHeight={screenHeight - 150}
																				getMarkerColor={getMarkerColor}
																				locales={appDefinition.locales}
																				markersFilter={(d) => d._id === activeRefines.site}
																				markerPosition={markerPosition}
																			/>
																		)}
																	</ParentSize>
																</div>
															</div>
														</IF>
														<IF condition={activeRefines.site && activeRefines.supplyChainAction === "updateNode"}>
															<div className="h-full flex flex-row justify-between items-center
																	bg-white rounded-3xl px-4">

																<div className="flex flex-col px-4 py-4 mt-4 mb-16 md:mb-5 md:border bg-white
							  												w-full h-full md:h-auto md:w-1/2 rounded-3xl">
																	<AdminSupplierUpdateForm
																		data={activeDatasets[`${activeRefines?.secLevelMenu}ChainToR`]?.data}
																		appSlice={appSlice}
																		clearRefineKeys={clearRefineKeys}
																		targetDocAccessor={(item) => activeRefines.site === item._id}
																		screenHeight={screenHeight}
																		onConfirm={({
																			res, resWithoutId, setconfirmModal,
																			currentTarProduct = "", childrenNode = [], currentTarget = {},
																			currentTarSupplierCode = "", currentTarFactoryCode = "", currentTarSiteId = "",
																			currentTarFactoryBranch = "", currentTarSiteLabelIdRef = "",
																			currentTarSiteUniqueIdRef = ""
																		}) => {
																			// eslint-disable-next-line max-len
																			const supplierUpdateNode = supplierUpdateNodeBuilder(activeRefines.secLevelMenu);

																			supplierUpdateNode({
																				res,
																				resWithoutId,
																				setconfirmModal,
																				currentTarProduct,
																				childrenNode,
																				activeRefines,
																				activeDatasets,
																				dispatch,
																				appSlice,
																				updateProject,
																				updateManyProject,
																				TORCollection,
																				setMarkerPosition,
																				currentTarget,
																				setAlert,
																				currentTarSupplierCode,
																				currentTarFactoryCode,
																				currentTarFactoryBranch,
																				currentTarSiteId,
																				currentTarSiteLabelIdRef,
																				currentTarSiteUniqueIdRef
																			});
																		}}
																		setMarkerPosition={setMarkerPosition}
																		disabledFields={
																			fieldAttributeBuilder(activeRefines.secLevelMenu, "updateNodeDisabledFields")}
																		requiredFields={
																			fieldAttributeBuilder(activeRefines.secLevelMenu, "updateNodeRequiredFields")}
																		// eslint-disable-next-line max-len
																		alertFields={fieldAttributeBuilder(activeRefines.secLevelMenu, "updateSiteLabelIdAlertFields")}
																		// eslint-disable-next-line max-len
																		alertText={(field, activeRefines) => alertTextBuilder(field, activeRefines)}
																		setAlert={setAlert}
																		childrenDocsAccessor={childrenAccessorBuilder}
																		supplychainView={supplychainView}
																		setSupplychainView={setSupplychainView}
																	/>
																	{alert.isDiplayed && <Popconfirm
																		title="Something went wrong"
																		description={alert?.msg ?? ""}
																		onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
																		iconsType="exclamationMark"
																		cancelBtnText="Back"
																		showConfirm={false}
																	/>}
																</div>

																<div className="hidden md:block md:w-1/2 pl-4">
																	<ParentSize debounceTime={10}>
																		{(parent) => (
																			<MapLeaflet
																				appSlice={appSlice}
																				dataset={geoDataset[activeRefines.secLevelMenu]}
																				CustomLayer={AdminLayer}
																				CustomMarker={AdminMapMarker}
																				fieldsTooltip={fieldsTooltip}
																				mapType="jawgTerrain"
																				maxClusterRadius={30}
																				defaultPosition={[47.221, 2.672]}
																				zoom={5}
																				minZoom={3}
																				maxZoom={20}
																				scrollWheelZoom={true}
																				mapHeight={screenHeight - 150}
																				getMarkerColor={getMarkerColor}
																				locales={appDefinition.locales}
																				markersFilter={(d) => d._id === activeRefines.site}
																				markerPosition={markerPosition}
																			/>
																		)}
																	</ParentSize>
																</div>
															</div>
														</IF>
														<IF condition={activeRefines.site && activeRefines.supplyChainAction === "updateLocation"}>
															<div className="h-full flex flex-row justify-between items-center
																	bg-white rounded-3xl px-4">

																<div className="flex flex-col px-4 py-4 mt-4 mb-16 md:mb-5 md:border bg-white
							  												w-full h-full md:h-auto md:w-1/2 rounded-3xl">
																	<AdminSupplierUpdateForm
																		data={activeDatasets[`${activeRefines?.secLevelMenu}ChainToR`]?.data}
																		appSlice={appSlice}
																		clearRefineKeys={clearRefineKeys}
																		targetDocAccessor={(item) => activeRefines.site === item._id}
																		screenHeight={screenHeight}
																		onConfirm={({
																			res, resWithoutId, setconfirmModal,
																			currentTarProduct = "", childrenNode = [], currentTarget = {}
																		}) => {
																			const supplierUpdateLoc = supplierUpdateLocBuilder(activeRefines.secLevelMenu);

																			supplierUpdateLoc({
																				res,
																				resWithoutId,
																				setconfirmModal,
																				currentTarProduct,
																				childrenNode,
																				activeRefines,
																				activeDatasets,
																				dispatch,
																				appSlice,
																				updateProject,
																				updateManyProject,
																				TORCollection,
																				setMarkerPosition,
																				currentTarget,
																				setAlert
																			});
																		}}
																		setMarkerPosition={setMarkerPosition}
																		// eslint-disable-next-line max-len
																		disabledFields={fieldAttributeBuilder(activeRefines.secLevelMenu, "updateLocationDisabledFields")}
																		// eslint-disable-next-line max-len
																		requiredFields={fieldAttributeBuilder(activeRefines.secLevelMenu, "updateLocationRequiredFields")}
																		// eslint-disable-next-line max-len
																		alertFields={fieldAttributeBuilder(activeRefines.secLevelMenu, "updateLocationAlertFields")}
																		// eslint-disable-next-line max-len
																		alertText={(field, activeRefines) => alertTextBuilder(field, activeRefines)}
																		setAlert={setAlert}
																		childrenDocsAccessor={childrenAccessorBuilder}
																		supplychainView={supplychainView}
																		setSupplychainView={setSupplychainView}
																	/>
																	{alert.isDiplayed && <Popconfirm
																		title="Something went wrong"
																		description={alert?.msg ?? ""}
																		onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
																		iconsType="exclamationMark"
																		cancelBtnText="Back"
																		showConfirm={false}
																	/>}
																</div>

																<div className="hidden md:block md:w-1/2 pl-4">
																	<ParentSize debounceTime={10}>
																		{(parent) => (
																			<MapLeaflet
																				appSlice={appSlice}
																				dataset={geoDataset[activeRefines.secLevelMenu]}
																				CustomLayer={AdminLayer}
																				CustomMarker={AdminMapMarker}
																				fieldsTooltip={fieldsTooltip}
																				mapType="jawgTerrain"
																				maxClusterRadius={30}
																				defaultPosition={[47.221, 2.672]}
																				zoom={5}
																				minZoom={3}
																				maxZoom={20}
																				scrollWheelZoom={true}
																				mapHeight={screenHeight - 150}
																				getMarkerColor={getMarkerColor}
																				locales={appDefinition.locales}
																				markersFilter={(d) => d._id === activeRefines.site}
																				markerPosition={markerPosition}
																			/>
																		)}
																	</ParentSize>
																</div>
															</div>
														</IF>
														<IF condition={!activeRefines.site && (activeRefines.supplyChainAction !== "createNode")
															&& (activeRefines.supplyChainAction !== "createBranch") &&
															(activeRefines.supplyChainAction !== "updateNode")}>
															<IF condition={activeRefines.mapView}>
																<ParentSize debounceTime={10}>
																	{(parent) => (
																		<div className="mt-4">
																			<MapLeaflet
																				appSlice={appSlice}
																				dataset={geoDataset[activeRefines.secLevelMenu]}
																				CustomMarker={AdminMapMarker}
																				fieldsTooltip={fieldsTooltip}
																				mapType="jawgTerrain"
																				maxClusterRadius={30}
																				defaultPosition={[47.221, 2.672]}
																				zoom={5}
																				minZoom={3}
																				maxZoom={20}
																				scrollWheelZoom={true}
																				mapHeight={screenHeight - 150}
																				getMarkerColor={getMarkerColor}
																				locales={appDefinition.locales}
																			/>
																		</div>
																	)}
																</ParentSize>
															</IF>
															<IF condition={!activeRefines.mapView}>
																<AdminTreeNodesGraph appSlice={appSlice} width={parent.width} height={parent.height}
																	datasetTree={`${activeRefines.secLevelMenu}SupplyChainTree`}
																	supplychainView={supplychainView} setSupplychainView={setSupplychainView}
																	setAlert={setAlert}
																/>
																{alert.isDiplayed && <Popconfirm
																	title="Something went wrong"
																	description={alert?.msg ?? ""}
																	onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
																	iconsType="exclamationMark"
																	cancelBtnText="Back"
																	showConfirm={false}
																/>}
															</IF>
														</IF>
													</IF>
													{/* supplychain table view */}
													<IF condition={!supplychainView}>
														<div className="flex flex-col justify-between items-center bg-white rounded-3xl">
															<div className="flex flex-col w-full" >
																<IF condition={!activeRefines.supplyChainAction}>
																	<div className="flex flex-col w-full mb-14 md:mb-5 px-6 py-4 mt-4 border rounded-lg">
																		<IF condition={chainTableStructure(activeRefines?.secLevelMenu,
																			activeDatasets?.[`${activeRefines.secLevelMenu}ClientParameters`]
																			?.data?.find((item) => activeRefines[chainRefineKey]
																			=== item[chainRefineKey]?.[0])) && !activeRefines.mapView}>
																			<AdminSupplyChainTable
																				appSlice={appSlice}
																				locales={appDefinition.locales}
																				structureTable={chainTableStructure(activeRefines?.secLevelMenu,
																					activeDatasets?.[`${activeRefines.secLevelMenu}ClientParameters`]
																					?.data?.find((item) => activeRefines[chainRefineKey]
																					=== item[chainRefineKey]?.[0]))}
																				exportName="SupplyChain"
																				refineKeys={(row) => [{ key: "site", value: row.original._id }]}
																				setAlert={setAlert}
																				supplychainView={supplychainView}
																				isAlpha={isAlpha}
																			/>
																		</IF>
																		<IF condition={activeRefines.mapView}>
																			<ParentSize debounceTime={10}>
																				{(parent) => (
																					<MapLeaflet
																						appSlice={appSlice}
																						dataset={geoDataset[activeRefines.secLevelMenu]}
																						CustomMarker={AdminMapMarker}
																						fieldsTooltip={fieldsTooltip}
																						mapType="jawgTerrain"
																						maxClusterRadius={30}
																						defaultPosition={[47.221, 2.672]}
																						zoom={5}
																						minZoom={3}
																						maxZoom={20}
																						scrollWheelZoom={true}
																						mapHeight={screenHeight - 150}
																						getMarkerColor={getMarkerColor}
																						locales={appDefinition.locales}
																					/>
																				)}
																			</ParentSize>
																		</IF>
																	</div>
																</IF>
																<IF condition={activeRefines.supplyChainAction === "createBranch"}>
																	<div className="h-full flex flex-row justify-between items-center bg-white
																					rounded-3xl px-4">
																		<div className="flex flex-col px-4 py-4 mt-4 mb-16 md:mb-5 md:border bg-white
															 					        w-full h-full md:h-auto md:w-1/2 rounded-3xl">
																			<AdminSupplierCreateForm
																				appSlice={appSlice}
																				screenHeight={screenHeight}
																				data={activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data}
																				clearRefineKeys={clearRefineKeys}
																				// if supplier selected from drop down then change accessor
																				targetDocAccessor={(item) => activeRefines?.supplier
																					? activeRefines.supplier === item.supplier
																					: activeRefines.site === item._id}
																				onConfirm={({ currentNode, originTargetDoc, setconfirmModal }) => {
																					const supplierCreate = supplierCreateBuilder(
																						activeRefines.secLevelMenu);

																					supplierCreate({
																						currentNode,
																						originTargetDoc,
																						setconfirmModal,
																						activeDatasets,
																						activeRefines,
																						dispatch,
																						appSlice,
																						addOneProject,
																						TORCollection,
																						setMarkerPosition,
																						setAlert
																					});
																				}}
																				onReset={(targetDocumentWithK) => {
																					// it refers to the fields which should never be overwritten
																					// in any case when select from supplier drop down
																					// const ignoredField = ["nextSupplier", "finalProduct", "tierLevel",
																					// 	"tierLabel", "projectName", "targetProduct"];
																					const ignoredField = ["_id", "key"];

																					const res = Object.entries(targetDocumentWithK)
																					.filter((item) => !ignoredField.includes(item[0]))
																					.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {});

																					return res;
																				}}
																				setMarkerPosition={setMarkerPosition}
																				disabledFields={
																					activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]
																					?.data.length === 0
																						? []
																						: fieldAttributeBuilder(activeRefines.secLevelMenu,
																							"createBranchDisabledFields")
																				}
																				requiredFields={
																					fieldAttributeBuilder(activeRefines.secLevelMenu,
																						"createFirstNodeRequiredFields")
																				}
																				setAlert={setAlert}
																			/>
																			{alert.isDiplayed && <Popconfirm
																				title="Something went wrong"
																				description={alert?.msg ?? ""}
																				onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
																				iconsType="exclamationMark"
																				cancelBtnText="Back"
																				showConfirm={false}
																			/>}
																		</div>
																		<div className="hidden md:block md:w-1/2 pl-4">
																			<ParentSize debounceTime={10}>
																				{(parent) => (
																					<MapLeaflet
																						appSlice={appSlice}
																						CustomLayer={AdminLayer}
																						CustomMarker={AdminMapMarker}
																						fieldsTooltip={fieldsTooltip}
																						mapType="jawgTerrain"
																						maxClusterRadius={30}
																						defaultPosition={[47.221, 2.672]}
																						zoom={5}
																						minZoom={3}
																						maxZoom={20}
																						scrollWheelZoom={true}
																						mapHeight={screenHeight - 150}
																						getMarkerColor={getMarkerColor}
																						locales={appDefinition.locales}
																						markersFilter={(d) => d._id === activeRefines.site}
																						markerPosition={markerPosition}
																					/>
																				)}
																			</ParentSize>
																		</div>
																	</div>
																</IF>

																{/* update from table item */}
																<IF condition={activeRefines.site && activeRefines.supplyChainAction === "updateNode"}>
																	<div className="h-full flex flex-row justify-between items-center
																	bg-white rounded-3xl px-4">

																		<div className="flex flex-col px-4 py-4 mt-4 mb-16 md:mb-5 md:border bg-white
							  												w-full h-full md:h-auto md:w-1/2 rounded-3xl">
																			<AdminSupplierUpdateForm
																				data={activeDatasets[`${activeRefines?.secLevelMenu}ChainToR`]?.data}
																				appSlice={appSlice}
																				clearRefineKeys={clearRefineKeys}
																				targetDocAccessor={(item) => activeRefines.site === item._id}
																				screenHeight={screenHeight}
																				onConfirm={({
																					res, resWithoutId, setconfirmModal,
																					currentTarProduct = "", childrenNode = [], currentTarget = {},
																					currentTarSupplierCode = "", currentTarFactoryCode = "",
																					currentTarFactoryBranch = "", currentTarSiteId = "",
																					currentTarSiteLabelIdRef = "", currentTarSiteUniqueIdRef = ""
																				}) => {
																					// eslint-disable-next-line max-len
																					const supplierUpdateNode = supplierUpdateNodeBuilder(activeRefines.secLevelMenu);

																					supplierUpdateNode({
																						res,
																						resWithoutId,
																						setconfirmModal,
																						currentTarProduct,
																						childrenNode,
																						activeRefines,
																						activeDatasets,
																						dispatch,
																						appSlice,
																						updateProject,
																						updateManyProject,
																						TORCollection,
																						setMarkerPosition,
																						currentTarget,
																						setAlert,
																						currentTarSupplierCode,
																						currentTarFactoryCode,
																						currentTarFactoryBranch,
																						currentTarSiteId,
																						currentTarSiteLabelIdRef,
																						currentTarSiteUniqueIdRef
																					});
																				}}
																				setMarkerPosition={setMarkerPosition}
																				// eslint-disable-next-line max-len
																				disabledFields={fieldAttributeBuilder(activeRefines.secLevelMenu, "updateNodeDisabledFields")}
																				// eslint-disable-next-line max-len
																				requiredFields={fieldAttributeBuilder(activeRefines.secLevelMenu, "updateNodeRequiredFields")}
																				// eslint-disable-next-line max-len
																				alertFields={fieldAttributeBuilder(activeRefines.secLevelMenu, "updateSiteLabelIdAlertFields")}
																				// eslint-disable-next-line max-len
																				alertText={(field, activeRefines) => alertTextBuilder(field, activeRefines)}
																				setAlert={setAlert}
																				childrenDocsAccessor={childrenAccessorBuilder}
																				supplychainView={supplychainView}
																				setSupplychainView={setSupplychainView}
																			/>
																			{alert.isDiplayed && <Popconfirm
																				title="Something went wrong"
																				description={alert?.msg ?? ""}
																				onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
																				iconsType="exclamationMark"
																				cancelBtnText="Back"
																				showConfirm={false}
																			/>}
																		</div>

																		<div className="hidden md:block md:w-1/2 pl-4">
																			<ParentSize debounceTime={10}>
																				{(parent) => (
																					<MapLeaflet
																						appSlice={appSlice}
																						dataset={geoDataset[activeRefines.secLevelMenu]}
																						CustomLayer={AdminLayer}
																						CustomMarker={AdminMapMarker}
																						fieldsTooltip={fieldsTooltip}
																						mapType="jawgTerrain"
																						maxClusterRadius={30}
																						defaultPosition={[47.221, 2.672]}
																						zoom={5}
																						minZoom={3}
																						maxZoom={20}
																						scrollWheelZoom={true}
																						mapHeight={screenHeight - 150}
																						getMarkerColor={getMarkerColor}
																						locales={appDefinition.locales}
																						markersFilter={(d) => d._id === activeRefines.site}
																						markerPosition={markerPosition}
																					/>
																				)}
																			</ParentSize>
																		</div>
																	</div>
																</IF>

															</div>
														</div>
													</IF>
												</>
											)}
										</ParentSize>
									</AdminHeader >
								</div>
							</IF>
						</IF>

						<IF condition={menu === "CONFIG" && activeRefines.secLevelMenu === "Admin Users"}>

							<div className="flex flex-col justify-between items-center bg-white rounded-3xl px-4">
								<AdminHeader appSlice={appSlice} chainRefineKey={chainRefineKey}>
									<IF condition={!activeRefines.user && !activeRefines.action}>
										<div className="flex flex-col w-full mb-14 md:mb-5 px-6 py-4 mt-4 border rounded-lg">
											<AdminUserTable
												appSlice={appSlice}
												structureTable={userTableStructure}
												dataset="adminUser"
												locales={appDefinition.locales}
												exportName="User"
												renderGroup
												renderBack={false}
												renderAdd
											/>
										</div>
									</IF>

									<IF condition={activeRefines.user && activeRefines.action === "updateUser"}>
										<div className="h-full flex flex-row justify-between items-center bg-white rounded-3xl px-4">

											<div className="flex flex-col px-4 py-4 mt-4 mb-16 md:mb-5 md:border bg-white
															w-full h-full md:h-auto md:w-1/2 rounded-3xl">
												<AdminuUserForm
													mode={activeRefines.action}
													data={activeDatasets?.adminUser?.data}
													appSlice={appSlice}
													clearRefineKeys={[{ refine: "user" }, { refine: "action" }]}
													targetDocAccessor={(item) => activeRefines.user === item._id}
													screenHeight={screenHeight}
													onConfirm={({ res, setconfirmModal }) => {
														const resWithoutId = { ...res };

														delete resWithoutId._id;

														adminUserUpdate({
															res,
															resWithoutId,
															appSlice,
															dispatch,
															updateProject,
															setconfirmModal,
															setAlert
														});
													}}
													setAlert={setAlert}
												/>
												{alert.isDiplayed && <Popconfirm
													title="Something went wrong"
													description={alert?.msg ?? ""}
													onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
													iconsType="exclamationMark"
													cancelBtnText="Back"
													showConfirm={false}
												/>}
											</div>

											<div className="hidden md:block md:w-1/2">
												<img src="/images/Admin/user.svg" alt="user" />
											</div>
										</div>
									</IF>

									<IF condition={!activeRefines.user && activeRefines.action === "createUser"}>
										<div className="h-full flex flex-row justify-between items-center bg-white rounded-3xl px-4">

											<div className="flex flex-col px-4 py-4 mt-4 mb-16 md:mb-5 md:border bg-white
															w-full h-full md:h-auto md:w-1/2 rounded-3xl">
												<AdminuUserForm
													mode={activeRefines.action}
													data={{}}
													appSlice={appSlice}
													clearRefineKeys={[{ refine: "action" }]}
													screenHeight={screenHeight}
													onConfirm={({ res, setconfirmModal }) => {
														adminUserCreate({
															res,
															appSlice,
															dispatch,
															addOneProject,
															setconfirmModal,
															setAlert
														});
													}}
													setAlert={setAlert}
												/>
												{alert.isDiplayed && <Popconfirm
													title="Something went wrong"
													description={alert?.msg ?? ""}
													onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
													iconsType="exclamationMark"
													cancelBtnText="Back"
													showConfirm={false}
												/>}
											</div>

											<div className="hidden md:block md:w-1/2">
												<img src="/images/Admin/user.svg" alt="user" />
											</div>
										</div>
									</IF>
								</AdminHeader>
							</div>
						</IF>

						<IF condition={menu === "CONFIG" && activeRefines.secLevelMenu === "App Scopes"}>
							<div className="flex flex-col justify-between items-center bg-white rounded-3xl px-4">
								<AdminHeader appSlice={appSlice} chainRefineKey={chainRefineKey}>
									<IF condition={!activeRefines.application && !activeRefines.action}>
										<div className="flex flex-col w-full mb-14 md:mb-5 px-6 py-4 mt-4 border rounded-lg">
											<AdminAppScopeTable
												appSlice={appSlice}
												structureTable={appScopeTableStructure}
												dataset="AppScopes"
												locales={appDefinition.locales}
												exportName="AppScope"
												renderGroup
												renderBack={false}
												renderAdd
											/>
										</div>
									</IF>

									<IF condition={activeRefines.application && activeRefines.action === "updateApplication"}>
										<div className="h-full flex flex-row justify-between items-center bg-white rounded-3xl px-4">

											<div className="flex flex-col px-4 py-4 mt-4 mb-16 md:mb-5 md:border bg-white
															w-full h-full md:h-auto md:w-1/2 rounded-3xl">
												<AdminAppScopeForm
													mode={activeRefines.action}
													data={activeDatasets?.AppScopes?.data}
													appSlice={appSlice}
													clearRefineKeys={[{ refine: "application" }, { refine: "action" }]}
													targetDocAccessor={(item) => activeRefines.application === item._id}
													screenHeight={screenHeight}
													onConfirm={({ res, setconfirmModal }) => {
														const resWithoutId = { ...res };

														delete resWithoutId._id;

														adminAppReplace({
															res,
															resWithoutId,
															appSlice,
															dispatch,
															replaceDoc,
															setconfirmModal,
															setAlert
														});
													}}
													setAlert={setAlert}
												/>
												{alert.isDiplayed && <Popconfirm
													title="Something went wrong"
													description={alert?.msg ?? ""}
													onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
													iconsType="exclamationMark"
													cancelBtnText="Back"
													showConfirm={false}
												/>}
											</div>

											<div className="hidden md:block md:w-1/2">
												<img src="/images/Admin/group.svg" alt="group" />
											</div>
										</div>
									</IF>

									<IF condition={!activeRefines.application && activeRefines.action === "createApplication"}>
										<div className="h-full flex flex-row justify-between items-center bg-white rounded-3xl px-4">

											<div className="flex flex-col px-4 py-4 mt-4 mb-16 md:mb-5 md:border bg-white
															w-full h-full md:h-auto md:w-1/2 rounded-3xl">
												<AdminAppScopeForm
													mode={activeRefines.action}
													data={{}}
													appSlice={appSlice}
													clearRefineKeys={[{ refine: "action" }]}
													screenHeight={screenHeight}
													onConfirm={({ res, setconfirmModal }) => {
														adminAppCreate({
															res,
															appSlice,
															dispatch,
															addOneProject,
															setconfirmModal,
															setAlert
														});
													}}
													setAlert={setAlert}
												/>
												{alert.isDiplayed && <Popconfirm
													title="Something went wrong"
													description={alert?.msg ?? ""}
													onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
													iconsType="exclamationMark"
													cancelBtnText="Back"
													showConfirm={false}
												/>}
											</div>

											<div className="hidden md:block md:w-1/2">
												<img src="/images/Admin/group.svg" alt="group" />
											</div>
										</div>
									</IF>
								</AdminHeader>
							</div>
						</IF>

						{/* TODO:remove mock data */}
						<IF condition={menu === "CONFIG" && activeRefines.secLevelMenu === "Access Requests"}>

							<div className="flex flex-col justify-between items-center bg-white rounded-3xl px-4">
								<AdminHeader appSlice={appSlice} renderFilter={false} renderSwitch switchStatus={isAdmin}
									switchFunc={setIsAdmin} switchLabelLeft="User" switchLabelRight="Admin" chainRefineKey={chainRefineKey}>

									<IF condition={isAdmin} >
										<div className="w-full text-left my-3">
											<p className="text-4xl text-admin_primary-default font-medium">Requests</p>
										</div>
										<div className="flex flex-col w-full mb-14 md:mb-5 px-6 py-4 border rounded-lg">
											<AdminRequestTable
												appSlice={appSlice}
												structureTable={requestTableStructure}
												sortByRefineFields={[{ id: "createDate", desc: false }]}
												locales={appDefinition.locales}
												exportName="Request"
											/>
										</div>
									</IF>

									<IF condition={!isAdmin} >
										<div className="flex flex-row flex-wrap lg:flex-nowrap">
											<div className="w-full lg:w-1/3 rounded-lg mt-2">
												<div className="w-full px-4 mx-auto">
													<div className="relative flex flex-col min-w-0 break-words
													bg-white w-full my-6 border rounded-lg">
														<div className="px-6">
															<div className="flex flex-wrap justify-center">
																<div className="w-full px-4 flex justify-center">
																	<div className="flex items-center space-x-3">
																		<p className="flex flex-shrink-0 items-center justify-center mt-6 text-4xl
																				sm:text-5xl w-24 h-24 sm:w-28 sm:h-28 text-white font-semibold rounded-lg"
																		style={{ backgroundColor: "#1C3FAA" }}>
																			{user.initials}
																		</p>
																	</div>
																</div>
															</div>
															<div className="text-center mt-6">
																<h3 className="text-lg sm:text-xl font-semibold leading-normal mb-2">
																	{user.name}
																</h3>
																<div className="text-medium sm:text-sm leading-normal mt-0 mb-2
																				text-blueGray-400 font-bold uppercase">
																	<LocationOnIcon className="text-admin_primary-default" />
																	<span className="ml-2">Paris, France</span>
																</div>
																<div className="text-medium sm:text-sm mb-2 text-blueGray-600 mt-10">
																	<WorkOutlineIcon className="text-admin_primary-default" />
																	<span className="ml-2">Digital Team - Bureau Veritas</span>
																</div>
																<div className="text-medium sm:text-sm mb-2 text-blueGray-600">
																	<BorderColorIcon className="text-admin_primary-default" />
																	<span className="ml-2">Admin / Member (Read and Write)</span>
																</div>
															</div>
															<div className="mt-10 py-5 border-t border-blueGray-200 text-center
																			space-y-4 text-medium sm:text-sm 2xl:text-base">
																<div className="flex items-center">
																	<PersonalVideoIcon className="text-admin_primary-default" />
																	<span className="ml-2">Personal Infomation</span>
																</div>
																<div className="flex items-center">
																	<ManageAccountsIcon className="text-admin_primary-default" />
																	<span className="ml-2">User Settings</span>
																</div>
																<div className="flex items-center">
																	<LockIcon className="text-admin_primary-default" />
																	<span className="ml-2">Change Password</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="flex flex-col w-full lg:w-2/3 mb-14 md:mb-5">
												<div className="border rounded-lg p-4 mt-2">
													<p className="text-admin_primary-default text-base sm:text-2xl font-semibold mt-2 uppercase">
														Active Modules
													</p>
													<div className="flex flex-row flex-wrap 2xl:flex-nowrap space-y-4 2xl:space-y-0 2xl:space-x-8
																   justify-start rounded-lg p-4 mt-2">
														<div className="flex max-w-md bg-white shadow-lg rounded-lg overflow-hidden">
															<img
																src="https://images.unsplash.com/photo-1585399000684-d2f72660f092?ixid=MnwxMjA3
																fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;
																fit=crop&amp;w=1951&amp;q=80"
																className=" w-1/2 bg-cover object-scale-down lg:object-cover lg:h-48 rounded-2xl" />
															<div className="w-2/3 p-4">
																<h1 className="text-admin_primary-default font-bold text-sm sm:text-2xl">
																	Animal Welfare
																</h1>
																<p className="mt-2 text-gray-600 text-medium sm:text-sm line-clamp-2 sm:line-clamp-5">
																	Lorem ipsum dolor sit amet consectetur adipisicing
																</p>
																<div className="h-2 sm:h-5 lg:h-10"></div>
																<div className="flex flex-col sm:flex-row item-center justify-between mt-3">
																	<h1 className="text-admin_primary-default font-bold text-sm sm:text-xl">$1000</h1>
																	<button className="w-min px-2 py-1 sm:px-3 sm:py-2 bg-admin_primary-default
																	hover:bg-admin_primary-dark text-white text-xs font-bold uppercase rounded">
																		Unsubscribe
																	</button>
																</div>
															</div>
														</div>

														<div className="flex max-w-md bg-white shadow-lg rounded-lg overflow-hidden">
															<img
																src="https://images.unsplash.com/photo-1585399000684-d2f72660f092?ixid=MnwxMjA3
																fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;
																fit=crop&amp;w=1951&amp;q=80"
																className=" w-1/2 bg-cover object-scale-down lg:object-cover lg:h-48 rounded-2xl" />
															<div className="w-2/3 p-4">
																<h1 className="text-admin_primary-default font-bold text-sm sm:text-2xl">
																	Traceability
																</h1>
																<p className="mt-2 text-gray-600 text-medium sm:text-sm line-clamp-2 sm:line-clamp-5">
																	Lorem ipsum dolor sit amet consectetur adipisicing
																</p>
																<div className="h-2 sm:h-5 lg:h-10"></div>
																<div className="flex flex-col sm:flex-row item-center justify-between mt-3">
																	<h1 className="text-admin_primary-default font-bold text-sm sm:text-xl">$1000</h1>
																	<button className="w-min px-2 py-1 sm:px-3 sm:py-2 bg-admin_primary-default
																	hover:bg-admin_primary-dark text-white text-xs font-bold uppercase rounded">
																		Unsubscribe
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="border rounded-lg p-4  mt-2">
													<p className="text-admin_primary-default text-base sm:text-2xl font-semibold mt-2 uppercase">
														Available Modules
													</p>
													<div className="flex flex-row flex-wrap 2xl:flex-nowrap space-y-4 2xl:space-y-0 2xl:space-x-2
																	justify-start rounded-lg p-4 mt-2">
														<div className="flex max-w-md bg-white shadow-lg rounded-lg overflow-hidden">
															<img
																src="https://images.unsplash.com/photo-1585399000684-d2f72660f092?ixid=MnwxMjA3
																fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;
																fit=crop&amp;w=1951&amp;q=80"
																className=" w-1/2 bg-cover object-scale-down lg:object-cover lg:h-48 rounded-2xl" />
															<div className="w-2/3 p-4">
																<h1 className="text-admin_primary-default font-bold text-sm sm:text-2xl">Net Zero</h1>
																<p className="mt-2 text-gray-600 text-medium sm:text-sm line-clamp-2 sm:line-clamp-5">
																	Lorem ipsum dolor sit amet consectetur adipisicing
																</p>
																<div className="h-2 sm:h-5 lg:h-10"></div>
																<div className="flex flex-col sm:flex-row item-center justify-between mt-3">
																	<h1 className="text-admin_primary-default font-bold text-sm sm:text-xl">$1000</h1>
																	<button className="w-min px-2 py-1 sm:px-3 sm:py-2 bg-admin_primary-default
																	hover:bg-admin_primary-dark text-white text-xs font-bold uppercase rounded">
																		Unsubscribe
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</IF>
								</AdminHeader>
							</div>
						</IF>
						<IF condition={menu === "PROJECT" && activeRefines.thirdLevelMenu === "Clarity settings"}>

							<div className="flex flex-col justify-between items-center bg-white rounded-3xl px-4">
								<AdminHeader appSlice={appSlice}
									renderFilter={false}
									clearRefineKeys={[{ refine: "fourthLevelMenu" }, { refine: "appAction" }, { refine: "clarityV4Referential" }]}
									chainRefineKey={chainRefineKey}>
									<div className="flex flex-row space-x-2">
										<div className="flex flex-col w-full mb-14 md:mb-5 px-6 py-4 mt-4 border rounded-lg">

											<IF condition={activeRefines?.appAction === undefined}>
												<div className="grid grid-cols-1 sm:grid-cols-5 grid-flow-row text-sm gap-3">
													<AdminSimpleCard
														appSlice={appSlice}
														locales={appDefinition.locales}
														cardTitle={"Domains options"}
														button1={{ name: "show", action: "readListDomains" }}
														button2={{ name: "add", action: "createNewDomain" }}
													>
													</AdminSimpleCard>
													<AdminSimpleCard
														appSlice={appSlice}
														locales={appDefinition.locales}
														cardTitle={"Clarity Referential"}
														button1={{ name: "show", action: "readClarityV4Referential" }}
													>
														<UploadFile
															appSlice={appSlice}
															appDefinition={appDefinition}
															appName={"admin"}
															collection={formatClarityV4Referential.sourceCollection}
															isIcon={false}
															refineKeys={{
																onSuccess: [
																	{ key: "fourthLevelMenu", value: "V4 Referential" },
																	{ key: "appAction", value: "readClarityV4Referential" }
																]
															}}
															onSuccess={() => {
																executeAdminPipeline({
																	appSlice,
																	activeRefines,
																	dispatch,
																	updateProject,
																	sourceCollection: formatClarityV4Referential.sourceCollection,
																	targetCollection: formatClarityV4Referential.targetCollection,
																	query: formatClarityV4Referential.query
																});
															}}
															numbersToStrings
														/>
													</AdminSimpleCard>
													<AdminSimpleCard
														appSlice={appSlice}
														locales={appDefinition.locales}
														cardTitle={"clarity translations"}
														button1={{ name: "show", action: "readClarityTranslations" }}
													>
													</AdminSimpleCard>
												</div>
											</IF>
											<IF condition={activeRefines?.appAction === "readListDomains" && activeRefines?.domainsOptions === undefined}>
												<div className="flex flex-col w-full mb-14 md:mb-5 px-6 py-4 mt-4 border rounded-lg">
													<AdminDomainsTable
														appSlice={appSlice}
														dataset="clarityDomains"
														structureTable={domainsTableStructure}
														locales={appDefinition.locales}
														renderGroup
														renderBack={true}
														renderAdd
														addForm="createNewDomain"
														clearRefineKeys={[{ refine: "fourthLevelMenu" }, { refine: "appAction" }]}
													>
													</AdminDomainsTable>
												</div>
											</IF>
											<IF condition={activeRefines?.appAction === "createNewDomain"}>
												<AdminDomainCreateForm
													data={newConfigTemplate({ appAction: "createNewDomain" })}
													appSlice={appSlice}
													clearRefineKeys={[{ refine: "fourthLevelMenu" }, { refine: "appAction" }]}
													renderButtonGroup={true}
													screenHeight={screenHeight}
													requiredFields={
														fieldAttributeBuilder(activeRefines.secLevelMenu, "createDomainRequiredFields")}
													setAlert={setAlert}
													onConfirm={(resWithoutId, setconfirmModal) => {
														adminDomainCreate({
															resWithoutId,
															appSlice,
															dispatch,
															addOneDoc,
															setconfirmModal,
															setAlert
														});
													}}
												/>
												{alert.isDiplayed && <Popconfirm
													title="Something went wrong"
													description={alert?.msg ?? ""}
													onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
													iconsType="exclamationMark"
													cancelBtnText="Back"
													showConfirm={false}
												/>}
											</IF>
											<IF condition={activeRefines?.appAction === "readClarityV4Referential" &&
										activeRefines?.clarityV4Referential === undefined}>
												<div className="flex flex-col w-full mb-14 md:mb-5 px-6 py-4 mt-4 border rounded-lg">
													<ClarityV4ReferentialTable
														appSlice={appSlice}
														appDefinition={appDefinition}
														structureTable={clarityV4ReferentialTableStructure()}
														exportName="ClarityV4Referential Table"
														refineKeys={(row) => [{ key: "clarityV4RefEntry", value: row.original }]}
														setAlert={setAlert}
														isAlpha={isAlpha}
														// If the data in ClarityV4ReferentialRaw changes we should also update
														// the data in ClarityV4Referential collection by executing the pipeline.
														onSuccessfulDelete={() => {
															executeAdminPipeline({
																appSlice,
																activeRefines,
																dispatch,
																updateProject,
																sourceCollection: formatClarityV4Referential.sourceCollection,
																targetCollection: formatClarityV4Referential.targetCollection,
																query: formatClarityV4Referential.query
															});
														}}
													/>
												</div>
											</IF>
											<IF condition={activeRefines?.domainsOptions === "updateDomain"}>
												<AdminDomainUpdateForm
													data={activeDatasets?.clarityDomains?.data}
													appSlice={appSlice}
													clearRefineKeys={[
														{ refine: "id" },
														{ refine: "domainsOptions" }
													]}
													targetDocAccessor={(item) => activeRefines.id === item._id}
													renderButtonGroup={true}
													screenHeight={screenHeight}
													requiredFields={
														fieldAttributeBuilder(activeRefines.secLevelMenu, "createDomainRequiredFields")}
													setAlert={setAlert}
													onConfirm={(res, resWithoutId, setconfirmModal) => {
														adminDomainUpdate({
															res,
															resWithoutId,
															appSlice,
															dispatch,
															replaceDoc,
															setconfirmModal,
															setAlert
														});
													}}
												/>
												{alert.isDiplayed && <Popconfirm
													title="Something went wrong"
													description={alert?.msg ?? ""}
													onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
													iconsType="exclamationMark"
													cancelBtnText="Back"
													showConfirm={false}
												/>}
											</IF>
											<IF condition={activeRefines?.clarityV4Referential === "updateV4RefEntry"}>
												<ClarityV4ReferentialForm
													mode={"update"}
													data={activeDatasets?.clarityV4Referential?.data}
													appSlice={appSlice}
													clearRefineKeys={[{ refine: "clarityV4Referential" }]}
													targetDocAccessor={(item) => activeRefines.site === item._id}
													screenHeight={screenHeight}
													onConfirm={({
														res, resWithoutId, setconfirmModal
													}) => {
														clarityV4ReferentialUpdateEntry({
															res,
															resWithoutId,
															setconfirmModal,
															dispatch,
															updateProject,
															setAlert,
															appSlice
														});
													}}
													// If the data in ClarityV4ReferentialRaw changes we should also update
													// the data in ClarityV4Referential collection by executing the pipeline.
													onSuccess={() => {
														executeAdminPipeline({
															appSlice,
															activeRefines,
															dispatch,
															updateProject,
															sourceCollection: formatClarityV4Referential.sourceCollection,
															targetCollection: formatClarityV4Referential.targetCollection,
															query: formatClarityV4Referential.query
														});
													}}
													setMarkerPosition={setMarkerPosition}
													disabledFields={["_id"]}
													requiredFields={["source"]}
													alertFields={["module"]}
													alertText={(field, activeRefines) => alertTextBuilder(field, activeRefines)}
													setAlert={setAlert}
													supplychainView={false} // related to btngroup inside
													setSupplychainView={setSupplychainView} // related to btngroup inside
												/>
												{alert.isDiplayed && <Popconfirm
													title="Something went wrong"
													description={alert?.msg ?? ""}
													onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
													iconsType="exclamationMark"
													cancelBtnText="Back"
													showConfirm={false}
												/>}
											</IF>
											<IF condition={activeRefines?.clarityV4Referential === "createV4RefEntry"}>
												<ClarityV4ReferentialForm
													mode={"create"}
													data={activeDatasets?.clarityV4Referential?.data}
													appSlice={appSlice}
													clearRefineKeys={[{ refine: "clarityV4Referential" }]}
													targetDocAccessor={(item) => activeRefines.site === item._id}
													screenHeight={screenHeight}
													onConfirm={({
														res, resWithoutId, setconfirmModal
													}) => {
														clarityV4ReferentialCreateEntry({
															res,
															resWithoutId,
															setconfirmModal,
															dispatch,
															updateProject,
															setAlert,
															appSlice
														});
													}}
													// If the data in ClarityV4ReferentialRaw changes we should also update
													// the data in ClarityV4Referential collection by executing the pipeline.
													onSuccess={() => {
														executeAdminPipeline({
															appSlice,
															activeRefines,
															dispatch,
															updateProject,
															sourceCollection: formatClarityV4Referential.sourceCollection,
															targetCollection: formatClarityV4Referential.targetCollection,
															query: formatClarityV4Referential.query
														});
													}}
													setMarkerPosition={setMarkerPosition}
													disabledFields={["_id"]}
													requiredFields={["source"]}
													alertFields={["module"]}
													alertText={(field, activeRefines) => alertTextBuilder(field, activeRefines)}
													setAlert={setAlert}
													childrenDocsAccessor={childrenAccessorBuilder}
													supplychainView={false} // related to btngroup inside
													setSupplychainView={setSupplychainView} // related to btngroup inside
												/>
												{alert.isDiplayed && <Popconfirm
													title="Something went wrong"
													description={alert?.msg ?? ""}
													onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
													iconsType="exclamationMark"
													cancelBtnText="Back"
													showConfirm={false}
												/>}
											</IF>
											<IF condition={activeRefines?.appAction === "readClarityTranslations"}>
												<div className="flex flex-col w-full mb-14 md:mb-5 px-6 py-4 mt-4 border rounded-lg">
													<AdminTranslationsTable
														appSlice={appSlice}
														appDefinition={appDefinition}
														dataset="clarityTranslations"
														structureTable={adminTranslationsTableStructure}
														locales={appDefinition.locales}
														renderGroup
														renderBack={true}
														clearRefineKeys={[{ refine: "fourthLevelMenu" }, { refine: "appAction" }]}
													>
													</AdminTranslationsTable>
												</div>
											</IF>
											<IF condition={activeRefines?.appAction === "updateCLarityTranslations"}>
												<div className="flex flex-col w-full mb-14 md:mb-5 px-6 py-4 mt-4 border rounded-lg">
													<AdminTranslationsUpdateForm
														data={activeDatasets?.clarityTranslations?.data}
														appSlice={appSlice}
														clearRefineKeys={[{ refine: "fourthLevelMenu" }, { refine: "appAction" }, { refine: "id" }]}
														targetDocAccessor={(item) => activeRefines.id === item._id}
														renderButtonGroup={true}
														screenHeight={screenHeight}
														requiredFields={
															fieldAttributeBuilder(activeRefines.secLevelMenu, "createTranslationsRequiredFields")}
														setAlert={setAlert}
														onConfirm={(res, resWithoutId, setconfirmModal) => {
															adminTranslationUpdate({
																res,
																resWithoutId,
																appSlice,
																dispatch,
																replaceDoc,
																setconfirmModal,
																setAlert
															});
														}}
													/>
													{alert.isDiplayed && <Popconfirm
														title="Something went wrong"
														description={alert?.msg ?? ""}
														onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
														iconsType="exclamationMark"
														cancelBtnText="Back"
														showConfirm={false}
													/>}
												</div>
											</IF>
											<IF condition={activeRefines?.appAction === "createTranslation"}>
												<AdminTranslationCreateForm
													data={newConfigTemplate({ appAction: "createNewTranslation" })}
													appSlice={appSlice}
													clearRefineKeys={[{ refine: "fourthLevelMenu" }, { refine: "appAction" }]}
													renderButtonGroup={true}
													screenHeight={screenHeight}
													requiredFields={
														fieldAttributeBuilder(activeRefines.secLevelMenu, "createTranslationRequiredFields")}
													setAlert={setAlert}
													onConfirm={(resWithoutId, setconfirmModal) => {
														adminTranslationCreate({
															resWithoutId,
															appSlice,
															dispatch,
															addOneDoc,
															setconfirmModal,
															setAlert,
															defaultTranslationData: activeDatasets?.clarityTranslations?.data?.[0]
														});
													}}
												/>
												{alert.isDiplayed && <Popconfirm
													title="Something went wrong"
													description={alert?.msg ?? ""}
													onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
													iconsType="exclamationMark"
													cancelBtnText="Back"
													showConfirm={false}
												/>}
											</IF>
										</div>
									</div>
								</AdminHeader>
							</div>

						</IF>

						{/* TODO:remove mock data */}
						<IF condition={menu === "CONTACT"}>
							<div className="h-full flex flex-row justify-between items-center bg-white rounded-3xl px-4">
								<div className="bg-white md:border w-full h-full md:h-auto md:w-1/2 px-4 py-4 my-4 rounded-3xl shadow-3xl">
									<AdminCreateForm
										data={[
											{
												fullName: "",
												telephone: "",
												email: "",
												message: ""
											}
										]}
										appSlice={appSlice}
										clearRefineKeys={[{ refine: "user" }]}
										renderButtonGroup={false}
										screenHeight={screenHeight}
									/>
								</div>
								<div className="hidden md:block md:w-1/2">
									<img src="/images/Admin/contact.svg" alt="contact" />
								</div>
							</div>
						</IF>

					</div>
				</div>
			</div>
		</div>
	);
};

export default Admin;
