import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { max } from "d3-array";
import { Bar, LinePath } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Text } from "@visx/text";
import * as allCurves from "@visx/curve";
import { AxisBottom } from "@visx/axis";
import { active } from "d3";
// accessors
const getDefaultX = (d) => d.campaignId;
const getDefaultY = (d) => d.score;
const OneLineEvolutionGraph = ({
	appSlice,
	dataset,
	width,
	height,
	margin = {
		top: 0, left: 0, right: 15, bottom: 150
	}
}) => {
	// bounds
	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;

	// data
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const graphData = useSelector(appSlice.selectDatasets)[dataset]?.data?.slice()?.sort((a, b) => a.campaignId - b.campaignId);
	const lastScore = graphData?.filter((node) => node.campaignId === Math.max(...graphData.map(getDefaultX)))[0];
	const scoreToDisplay = graphData?.filter((e) => e.campaignId === (activeRefines?.campaignId
		? activeRefines.campaignId
		: Math.max(...graphData.map(getDefaultX))))[0];

	// scales
	const xScale = useMemo(
		() => scaleBand({
			range: [0, xMax / 0.85],
			domain: graphData.map((e) => e.campaignId)
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[width]
	);
	const yScale = useMemo(
		() => scaleLinear({
			range: [0, yMax * 0.75],
			domain: [Math.max(...graphData.map(getDefaultY)), Math.min(...graphData.map(getDefaultY))]
		}), [graphData, yMax]
	);

	return (
		<div
			// className="border-2 border-pink-600"
		>
			<svg className="h-full w-full" >
				<Group top={15} left={15}>
					<LinePath
						curve={allCurves.curveMonotoneX}
						data={graphData}
						x={(d) => xScale(getDefaultX(d)) ?? 0}
						y={(d) => yScale(getDefaultY(d)) ?? 0}
						stroke={"white"}
						strokeWidth={3}
						// className={maturityDisplay && `stroke-clarity_primary-${lineData[0].color}`}
					/>
					{graphData.map((node) => <g key={`score-campaign${node.campaignId}`}>
						<circle
							className="fill-white"
							r={11}
							cx={xScale(getDefaultX(node))}
							cy={yScale(getDefaultY(node))}/>
						<circle
							className="fill-[#44B9B4]"
							r={8}
							cx={xScale(getDefaultX(node))}
							cy={yScale(getDefaultY(node))}/>
						<Text
							x={(xMax > (xScale(getDefaultX(node)) + 60)
								? (xScale(getDefaultX(node)) + 16)
								: (xScale(getDefaultX(node)) - 60))}
							y={yScale(getDefaultY(node)) - 8}
							width="50"
							verticalAnchor="start"
							style={{ fontSize: "1.5rem" }}
							// fill="#44B9B4"
						>
							{typeof getDefaultY(node) === "number"
								? getDefaultY(node).toFixed(1)
								: getDefaultY(node)}

						</Text>
						<Text
							x={(xMax < (xScale(getDefaultX(node)) + 16)
								? (xScale(getDefaultX(node)) + 16)
								: (xScale(getDefaultX(node)) - 16))}
							y={yScale(getDefaultY(node)) + 18}
							// scaleToFit
							width="10"
							verticalAnchor="start"
							style={{ fontSize: ".8rem" }}
							// fill="#44B9B4"
						>
							{node.campaignLabel}

						</Text>
					</g>)}
				</Group>
			</svg>
			<div className="flex flex-col mt-4">
				<span className="text-3xl">{typeof scoreToDisplay.score === "number"
					? scoreToDisplay.score.toFixed(1)
					: scoreToDisplay.score}</span>
				<span>{scoreToDisplay.campaignLabel}</span>
			</div>
		</div>
	);
};

OneLineEvolutionGraph.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	margin: PropTypes.object
};

export default OneLineEvolutionGraph;
