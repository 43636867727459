import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import VerkorActionPlanScope from "./VerkorActionPlanScope";

const ActionPlanScopeCard = ({
	appDefinition = undefined, appSlice, datasets, refineOnClick = false, clientParams, theme
}) => {
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const actionPlanStatus = activeDatasets?.[datasets[0]]?.data[0]?.actionPlanStatus;
	const itemsDelayedCounter = activeDatasets?.[datasets[1]]?.data[0]?.totalDelayed;

	const targetDate = activeDatasets?.[datasets[0]]?.data[0]?.actionPlanTargetDate;

	const projectScopeList = clientParams?.actionPlanStatusScope;

	// If there are more than one action plan the text in the donut would be different
	const isAggregatedView = activeDatasets?.[datasets[0]]?.data?.length > 1;

	const totalNCs = activeDatasets?.[datasets[2]]?.data?.reduce((acc, currentValue) => {
		if (typeof currentValue.totalNCs === "number") {
			return acc + currentValue.totalNCs;
		}
		console.log(`Skipped non-numeric value: ${currentValue}`);
		return acc;
	}, 0);

	return (
		<div className="alphaBlur p-4 rounded-xl drop-shadow-xl">
			<VerkorActionPlanScope
				appSlice={appSlice}
				dataset={[datasets[2]]}
				total={totalNCs}
				totalCovered={activeDatasets?.[datasets[2]]?.data[0]?.totalNCs}
				titleLocale="analysis.actionPlanOverview"
				scopeList={projectScopeList}
				categoriesListDataset="listGradingsLabels"
				categoriesKey="ncStatus"
				categoriesLocale="analysis.scope.ncStatus"
				totalLocaleKey = "items"
				refineOnClick={refineOnClick}
				innerText={isAggregatedView ? `${activeDatasets?.[datasets[0]]?.data?.length} APs` : `Action plan ${actionPlanStatus}`}
				displayInner={false}
				counterPills={[
					{
						id: "delayed",
						counter: itemsDelayedCounter,
						color: "bg-scope-delayed"
					}
				]}
				locales={appDefinition.locales}
				apTargetDate={targetDate}
				theme={theme}
			></VerkorActionPlanScope>
		</div>);
};

ActionPlanScopeCard.propTypes = {
	appSlice: PropTypes.object,
	appDefinition: PropTypes.object,
	refineOnClick: PropTypes.bool,
	datasets: PropTypes.array,
	clientParams: PropTypes.object,
	theme: PropTypes.string
};

export default ActionPlanScopeCard;
