import { useSpring, animated, config } from "react-spring";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

function AssessementProgressBar({
	appSlice, locales, program
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const activeDatasets = useSelector(appSlice.selectDatasets);

	const totalSupplyRSites = activeDatasets?.supplyRSitesCount?.data?.length;
	const assessedSupplyR = activeDatasets?.supplyRSitesDistribution?.data?.length;

	const assessementsParCampaignClarity = activeDatasets?.clarityAssessementsParCampaign?.data;

	const calculate = () => {
		if (program === "SupplyR") {
			return assessedSupplyR === 0 ? 0 : ((assessedSupplyR / totalSupplyRSites) * 100).toFixed(0);
		}
		if (program === "Clarity") {
			const totalSitesPerLatestCampaign = assessementsParCampaignClarity[0]?.sitesPerCampaign ?? 0;
			const assessedPerLatestCampaign = assessementsParCampaignClarity[0]?.assessed ?? 0;
			return assessedPerLatestCampaign === 0 ? 0 : ((assessedPerLatestCampaign / totalSitesPerLatestCampaign) * 100).toFixed(0);
		}
	};

	const styles = {
		Clarity: {
			barColor: "bg-[#a1d67c]"
		},
		SupplyR: {
			barColor: "bg-[#44b9b4]"
		},
		Common: {
			bgColor: "bg-[#e7e7e7]"
		}
	};

	function RenderBar({ targetWidth }) {
		// animation conf for the progress  bar
		const progressSpring = useSpring({
			from: { width: "0%" },
			to: { width: `${targetWidth}%` },
			delay: 200,
			config: config.molasses
		});

		return (
			<animated.div style={progressSpring}>
				<div className="flex justify-start text-base font-medium text-center p-0.5 rounded-lg"
					style={{ background: "linear-gradient(90deg, rgba(204,208,237,1) 0%, rgba(132,147,248,1) 35%)" }}>
					<span className="ml-3">{`${calculate()}%`}</span>
				</div>
			</animated.div>
		);
	}

	RenderBar.propTypes = {
		targetWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		colorClass: PropTypes.string
	};

	return (
		<div data-test="assessment-progress-bar" className="flex lg:justify-start lg1:justify-end">
			<div className={`flex items-center px-2 ${styles.Common.bgColor} rounded-l-xl font-bold text-sm`}>
				{t("AssessmentProgressComp.title")}
			</div>
			<div className={`flex w-1/4 items-center px-2 py-1 ${styles.Common.bgColor} rounded-r-xl`}>
				<div className="w-full bg-gray-400 rounded-lg overflow-hidden">
					<RenderBar targetWidth={calculate()}/>
				</div>
			</div>
		</div>
	);
}

AssessementProgressBar.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	program: PropTypes.string
};

export default AssessementProgressBar;
