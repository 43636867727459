import PropTypes from "prop-types";

import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

import SimpleDonutGraph from "./SimpleDonutGraph";

import IF from "../../utils/IF";

/**
 * Indicator used on Clarity app
 * @category Graphs
 * @component
 *
 */

function ClarityIndicator({
	label = "label",
	moduleScore = {
		score: 0, nbSites: 0, nbNC: 0, nbCNC: 0, trend: ""
	},
	color = "black",
	textInnerColor = "black",
	donutThickness = 8,
	donutInnerThickness = 4,
	width = 120,
	height = 120,
	noTrend = false,
	subTextOffset = undefined,
	isPercent = false,
	halo = false,
	description = false,
	displayNbSites = true
}) {
	// const donutData = [{ label, score: ((moduleScore?.nbSites > 0 && displayNbSites) ? moduleScore?.score : "NaN") }];
	const donutData = [{
		label,
		score: ((moduleScore?.nbSites > 0 || !displayNbSites) ? moduleScore?.score : "NaN")
		// score: displayNbSites ||
	}];

	const calOverAllScore = (value) => value.reduce((acc, currentValue) => acc
	+ Number((!currentValue.score || Number.isNaN(currentValue.score)) ? 0 : currentValue.score), 0);

	return (
		<div className="relative w-full flex flex-col justify-center items-center mt-3">
			<IF condition={moduleScore?.score !== undefined && moduleScore?.score !== "NaN"
			&& (!displayNbSites || moduleScore?.nbSites > 0)
			}>
				{/* NCS count bubble */}
				<div className="absolute flex items-end pr-1 flex-col justify-between"
					style={{ width, height: ((height * 4) / 5) }}>
					<div className="self-end"><div className="rounded-full shadow-sm
                            flex justify-center items-center font-light
                            text-sm text-white  bg-red-500"
					style={{ width: (width / 5), height: (height / 5) }}>
						<span>{moduleScore?.nbCNC}</span>
					</div>
					{description && width === 180 &&
						<div className="text-smaller text-black absolute -right-24 -top-4">
							<p>Critical</p>
							<p>Non-Conformities</p>
						</div>}
					</div>
					{/* trend indicator */}
					{!noTrend &&
					<div className="rounded-full  shadow-sm
					flex justify-center items-center
					text-md   bg-white"
					style={{ width: (width / 5), height: (height / 5) }}>
						{(moduleScore?.trend === "") ?
							<TrendingFlatIcon fontSize="inherit" className="text-gray-500"/>
							: ((moduleScore?.trend === "Down") ?
								<TrendingDownIcon fontSize="inherit" className="text-red-500" />
								: <TrendingUpIcon fontSize="inherit" className="text-green-500" />)
						}
					</div>
					}
				</div>
				{/* {description && width === 180 && <div className="absolute w-1/6 right-10 top-6 text-smaller"><span>Critical Non-Conformities</span></div>} */}
			</IF>
			<SimpleDonutGraph
				value={donutData}
				donutThickness={donutThickness}
				donutInnerThickness={donutInnerThickness}
				width={width}
				height={height}
				donutColor={color}
				textInnerColor={textInnerColor}
				calOverAllScore={calOverAllScore}
				subTextOffset={subTextOffset}
				isPercent={isPercent}
				halo={halo}
			/>
		</div>
	);
}

ClarityIndicator.propTypes = {
	/**
	 * defines label. passed to donutData
	 */
	label: PropTypes.string,
	/**
	 * Object used for scoring
	 */
	moduleScore: PropTypes.object,
	/**
	 * defines color passed to the SimpleDonutGraph
	 */
	color: PropTypes.string,
	/**
	 * defines inner text color passed to the SimpleDonutGraph
	 */
	textInnerColor: PropTypes.string,
	/**
	 * thickness of the donut
	 */
	donutThickness: PropTypes.number,
	/**
	 * thickness of donut background track
	 */
	donutInnerThickness: PropTypes.number,
	/**
	 * defines width
	 */
	width: PropTypes.number,
	/**
	 * defines height
	 */
	height: PropTypes.number,
	/**
	 * hide trends
	 */
	noTrend: PropTypes.bool,
	/**
	 * display score in percent
	 */
	isPercent: PropTypes.bool,
	/**
	 * subtext offset for the donut subtitle. Please provide a unit
	*/
	subTextOffset: PropTypes.string,
	/**
	 * display a blur halo inside the donut
	 */
	halo: PropTypes.bool,
	/**
	 * displays a description next to the red bubble
	 */
	description: PropTypes.bool,
	/**
	 * take into account the nb of sites for rendering
	 */
	displayNbSites: PropTypes.bool
};

export default ClarityIndicator;
