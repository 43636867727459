import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSpring, animated, config } from "react-spring";
import PropTypes from "prop-types";

const localesKeyScope = ["internalSAQ", "internalAudits", "externalSAQ", "externalAudits"];

function RenderNbAssessments({ nbAssessments }) {
	// animation conf for the  assignemnets nb
	const { number } = useSpring({
		from: { number: 0 },
		number: nbAssessments,
		delay: 200,
		config: config.molasses
	});

	return (
		<animated.p className="text-2xl font-black leading-9 z-10">
			{number.to((n) => n.toFixed(0))}
		</animated.p>
	);
}

RenderNbAssessments.propTypes = {
	nbAssessments: PropTypes.number
};

function RenderScopeBar({ targetWidth, colorClass = "bg-compass_primary-accent" }) {
	// animation conf for the scope bar
	const scopeWidth = useSpring({
		from: { width: 0 },
		to: { width: targetWidth },
		delay: 200,
		config: config.molasses
	});

	return (
		<animated.div
			style={scopeWidth}
			className={`h-3 mr-1 rounded-xl ${ colorClass}`} />
	);
}

RenderScopeBar.propTypes = {
	targetWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	colorClass: PropTypes.string
};

const getNbAssessments = (d) => d.nbAssessments;

function CompassScopeCard({
	appSlice, dataset, expectedTotal = 1000, locales, geoScope = false
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load data from the store of the App
	const scopeDataset = useSelector(appSlice.selectDatasets)[dataset].data;
	const nbSites = useSelector(appSlice.selectDatasets)?.sites?.data?.length ?? 0;

	if (scopeDataset?.length <= 0) {
		return null;
	}

	return (
		<div className="flex justify-between items-center w-full ">
			<div className="relative flex flex-col flex-shrink-0 text-white justify-center items-center rounded-xl
               bg-compass_primary-accent bg-opacity-60 h-20 w-14 overflow-hidden ">
				<RenderNbAssessments nbAssessments={nbSites} />
				<hr className="text-white w-3 border-t-2 border-gray-400 border-opacity-80 z-10" />
				<p className="text-xs leading-6 z-10">{expectedTotal}</p>
				<div className="absolute bottom-0 w-full bg-compass_primary-accent" style={{ height: "65%" }} />
			</div>
			<div className="flex flex-grow h-full justify-around  flex-col px-2">
				{scopeDataset.map((scope, i) => {
					const score = ((scope.nbAssessments / nbSites) * 100);
					return (
						<div key={i} className="flex items-center">
							<p className="flex-shrink-0 text-left text-medium w-2/5 mr-2 leading-3 line-clamp-2">
								{geoScope ? scope?.subregion : scope?.additionalInfo?.siteType}</p>
							{score > 0 && <RenderScopeBar targetWidth={score} />}
							<p className="text-center text-medium font-semibold flex-shrink-0 w-6">{score.toFixed(0)}%</p>
						</div>
					);
				})}
			</div>
		</div>
	);
}

CompassScopeCard.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	getNbAssessments: PropTypes.func,
	expectedTotal: PropTypes.number,
	locales: PropTypes.string,
	geoScope: PropTypes.bool
};

export default CompassScopeCard;
