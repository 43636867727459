import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { BarGroupHorizontal, Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { Text } from "@visx/text";
import Loading from "../../utils/Loading";

const defaultLineHeight = 60;

const defaultMargin = {
	top: 0,
	left: 10,
	right: 30,
	bottom: 0
};

const getY = (d) => d.Y;
/**
 * Column graph allowing two datasets. Resulting in two "bars" for one label
 * @category Graphs
 * @component
 */
function ColumnGroupGraph({
	appSlice, dataset1, dataset2, lineHeight = defaultLineHeight, strokeWidth = 8,	textColor = "Black",
	color = "#1876bd", backgroundBarColor = "#F6F6F6",	gradientStartBarColor = "#8C92CD", gradientEndBarColor = "#4C57B1",
	margin = defaultMargin, width, height,
	getAbs = (d) => d.score,
	getOrd = (d) => d.label,
	refineKey = "",
	locales,
	prefix = ""
}) {
	const { t } = useTranslation(locales);

	// Load data from the store
	const graphData1 = useSelector(appSlice.selectDatasets)[dataset1]?.data;
	const graphData2 = useSelector(appSlice.selectDatasets)[dataset2]?.data;
	const loadStatus = useSelector(appSlice.selectLoadDataStatus);

	const dispatch = useDispatch();

	const graphData = graphData1?.map((element) => (
		{ Y: getOrd(element), X0: getAbs(element), X1: getAbs(graphData2?.find((element2) => getOrd(element) === getOrd(element2))) }
	));

	// calculate dynamic height if height not specified
	if (height === undefined) {
		height =
			Array.from(new Set(graphData.map(getY))).length * (lineHeight) +
			margin.bottom +
			margin.top;
	}

	if (loadStatus !== "idle") {
		return <Loading isComponent />;
	}

	if (graphData?.length === 0) {
		return <p className="text-sm mb-2">No data to return</p>;
	}

	// bounds
	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;
	const compensationY = defaultLineHeight / 2 - strokeWidth;

	const keys = Object.keys(graphData[0])?.filter((d) => d !== "Y");

	const XScale = scaleLinear({
		domain: [0, 100],
		nice: true
	});

	const YScale = scaleBand({
		domain: graphData.map(getY),
		padding: 0.4
	});

	const typeScale = scaleBand({
		domain: keys,
		padding: 0.1
	});

	const colorScale = scaleOrdinal({
		domain: keys,
		range: ["url(#colGraph)", "grey"]
	});

	YScale.rangeRound([0, yMax]);
	typeScale.rangeRound([0, YScale.bandwidth()]);
	XScale.rangeRound([0, xMax]);

	return width < 10 ? null : (
		<svg width={width} height={height}>
			<rect x={0} y={0} width={width} height={height} fill="transparent" rx={14} />
			<Group top={margin.top} left={margin.left}>
				<BarGroupHorizontal
					data={graphData}
					keys={keys}
					width={xMax}
					y0={getY}
					y0Scale={YScale}
					y1Scale={typeScale}
					xScale={XScale}
					color={colorScale}
				>
					{(barGroups) => barGroups.map((barGroup) => (
						<Group
							key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`}
							top={barGroup.y0}
						>
							{barGroup.bars.map((bar) => (
								<g key={`${barGroup.index}-${bar.index}-${bar.key}`}>
									<rect
										x={bar.x}
										y={bar.y}
										rx={strokeWidth / 2}
										width={xMax}
										height={strokeWidth}
										fill={backgroundBarColor}
									/>

									<defs>
										<linearGradient
											id="colGraph"
											x1={bar.x}
											y1={bar.y}
											x2={bar.width}
											y2={bar.y}
											gradientUnits="userSpaceOnUse"
										>
											<stop offset="0%" stopColor={gradientStartBarColor} />
											<stop offset="100%" stopColor={gradientEndBarColor} />
										</linearGradient>
									</defs>

									<line
										x1={bar.x}
										y1={bar.y + strokeWidth / 2}
										x2={bar.width}
										y2={bar.y + strokeWidth / 2}
										strokeWidth={strokeWidth}
										stroke={bar.color}
										strokeLinecap="round"
										onClick={() => {
											if (refineKey) {
												dispatch(appSlice.actions.refine([{ key: refineKey, value: graphData[barGroup?.index]?.Y }]));
											} else {
												alert("You can not refine current domain or key does not exist");
											}
										}}
									>

										<animate
											attributeName="x2"
											from="0"
											to={`${bar.width}`}
											dur="1s"
										/>
									</line>

									<Text
										x={((bar.x + bar.width + 10) > (xMax - 20)) ? (xMax - 20) : (bar.x + bar.width + 10) }
										y={bar.y}
										width="50"
										verticalAnchor="start"
										style={{
											fill: ((bar.x + bar.width + 10) > (xMax - 20)) ? "white" : textColor,
											fontSize: `${(strokeWidth - 1) / 10}rem`,
											fontWeight: "normal"
										}}
									>
										{((bar.width / xMax) * 100).toFixed(0)}
									</Text>
								</g>
							))}
						</Group>
					))
					}
				</BarGroupHorizontal>
				<AxisLeft
					hideAxisLine
					hideTicks
					numTicks={graphData.length}
					scale={YScale}
					top={-1 * (compensationY)}
					stroke="black"
					tickStroke="black"
					tickLabelProps={() => ({
						fill: "black",
						fontSize: 11,
						fontWeight: 400,
						textAnchor: "start",
						dx: "0.5em"
					})}
					tickClassName="axisLeftLabel cursor-pointer"
					tickComponent={ ({ formattedValue, ...tickProps }) => (
						<text {...tickProps}
							onClick={() => {
								if (refineKey) {
									dispatch(appSlice.actions.refine([{ key: refineKey, value: formattedValue }]));
								} else {
									alert("You can not refine current domain or key does not exist");
								}
							}}>
							{/* {prefix ? t(`${prefix}.${formattedValue}`) : t(`${formattedValue}`)} */}
							{formattedValue}
						</text>)}
				/>
			</Group>
		</svg>
	);
}

ColumnGroupGraph.propTypes = {
	/** Defines appslice */
	appSlice: PropTypes.object,
	/** Defines dataset1 */
	dataset1: PropTypes.string,
	/** Defines dataset2 */
	dataset2: PropTypes.string,
	/** Defines line height. */
	lineHeight: PropTypes.number,
	/** Defines stroke width. */
	strokeWidth: PropTypes.number,
	/** Defines bar color. */
	color: PropTypes.string,
	/** Defines text color. */
	textColor: PropTypes.string,
	/** Defines color for the background bar track. */
	backgroundBarColor: PropTypes.string,
	/** Defines gradient start color. */
	gradientStartBarColor: PropTypes.string,
	/** Defines gradient end color. */
	gradientEndBarColor: PropTypes.string,
	/** abs accessor. */
	getAbs: PropTypes.func,
	/** ord accessor. */
	getOrd: PropTypes.func,
	/** Defines all four margins. */
	margin: PropTypes.object,
	/** Defines width. */
	width: PropTypes.number,
	/** Defines height. */
	height: PropTypes.number,
	/** Defines keys used for onClick refines. */
	refineKey: PropTypes.string,
	/** locales */
	locales: PropTypes.string,
	/** prefix to access locales if needed for labels in left axis */
	prefix: PropTypes.string
};
export default ColumnGroupGraph;
