import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ParentSize } from "@visx/responsive";

import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import SimpleDonutGraph from "../graphs/SimpleDonutGraph";

function CompassIndicatorCard({
	appSlice, module, ratingsConfig, large = false, locales, synthesis = false
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);

	const moduleData = activeDatasets.modulesRatings.data
	.filter((filterValue) => filterValue.module === module);
	const scoreData = activeDatasets?.scoreCounter?.data?.filter((filterValue) => filterValue.module === module);

	const donutData = ratingsConfig?.reduce(
		(acc, curValue, index) => {
			const moduleRatingData = clientParameters?.scoreBased
				? scoreData.find((element) => element.rating === curValue.answer)
				: moduleData.find((element) => element.additionalInfo.finalRating === curValue.answer);

			acc[index] = {
				label: curValue.answer,
				score: clientParameters?.scoreBased ? moduleRatingData?.count : moduleRatingData?.totalAssignments ?? 0,
				nbNC: clientParameters?.scoreBased ? (moduleRatingData?.nbNc ?? 0) : (moduleRatingData?.moduleNbNC ?? 0),
				nbCNC: clientParameters?.scoreBased ? (moduleRatingData?.nbCNc ?? 0) : (moduleRatingData?.moduleNbCNC ?? 0)
			};
			return acc;
		}, [{}]);
	// const scoreBasedDataset = clientParameters?.scoringBySection ? "avgScoreBySection" : "avgScore";
	const scoreBasedOverallScore = activeDatasets && (
		activeRefines?.section1Label !== undefined || activeRefines?.section2Label !== undefined
			? activeDatasets?.avgScoreBySection?.data[0]
			: activeDatasets?.avgScore?.data[0]
	);
	// const scoreBasedDataset = clientParameters?.scoringBySection ? "avgScoreBySection" : "avgScore";
	// const scoreBasedOverallScore = activeDatasets && activeDatasets[scoreBasedDataset]?.data[0];

	const calOverAllScore = (value) => {
		if (clientParameters?.scoreBased && scoreBasedOverallScore?.score) {
			return scoreBasedOverallScore.score * 100;
		}

		return value.reduce((acc, currentValue) => acc + Number((currentValue.score && !Number.isNaN(currentValue.score)) ? currentValue.score : 0), 0);
	};

	return (<div className="flex flex-col alphaBlur rounded-xl px-2 py-3 w-full h-full
                   gap-y-2 cursor-pointer"
	onClick={() => activeRefines.module === undefined ?
		dispatch(appSlice.actions.refine([{ key: "module", value: module }]))
		: dispatch(appSlice.actions.refine([{ key: "module", value: undefined }]))
	}>

		<div className="flex justify-between items-center w-full">
			<div className="flex gap-x-1">
				<VerifiedUserIcon className={`text-compass_primary-accent 
                     ${ large ? "text-3xl" : "text-lg md:text-xl 2xl:text-3xl"}` } />
				<div className="flex flex-col">
					<p className={`font-semibold line-clamp-1 text-compass_primary-accent
                     ${ large ? "text-2xl" : "text-medium md:text-base 2xl:text-lg"}`}>
						{module}
					</p>
					{/* <p className="font-light text-small md:text-medium 2xl:text-base line-clamp-1 leading-3">
                        description
					</p> */}
				</div>
			</div>
		</div>
		<div className="flex justify-around items-center w-full">
			<div className="flex flex-col gap-y-3">
				<ParentSize debounceTime={10}>
					{(parent) => (
						<SimpleDonutGraph
							value={donutData}
							donutThickness={10}
							donutInnerThickness={18}
							width={parent.width}
							height={150}
							values={true}
							donutColor={ratingsConfig?.map((rating) => rating.color)}
							fontSizeTextCenter={clientParameters?.scoreBased ? undefined : 60}
							subTextCenter={clientParameters?.scoreBased ? "%" : "AUDITS"}
							subTextCenterSize={18}
							margin = {{
								top: 10,
								right: 0,
								bottom: -10,
								left: 0
							}}
							textInnerColor={"black"}
							calOverAllScore={calOverAllScore}
							innerValueDigits={clientParameters?.scoreBased && 2}
						/>
					)}
				</ParentSize>

				<div className="flex justify-center gap-x-6 items-center text-sm">
					<p className="flex items-center px-2 bg-red-100 rounded-lg">
                NC : {donutData?.reduce((acc, v) => acc + (v.nbNC ?? 0), 0)}
					</p>
					<p className="flex items-center px-2 bg-red-500 rounded-lg text-white">
                CNC : {donutData?.reduce((acc, v) => acc + (v.nbCNC ?? 0), 0)}
					</p>
				</div>
			</div>

			{large && <div className="flex flex-col h-full pt-3">
				<div className="font-semibold pb-2 border-r">Legend</div>
				{ratingsConfig?.map((element, i) => <div key={`legendRating ${ i}`}
					className="flex items-center gap-x-2 ">
					<div className="h-4 w-4 rounded-full" style={{ backgroundColor: element.color }} />
					<p className="text-medium">{element.answer}</p>
				</div>
				)}

			</div>}
		</div>

	</div>);
}

CompassIndicatorCard.propTypes = {
	appSlice: PropTypes.object,
	module: PropTypes.string,
	ratingsConfig: PropTypes.array,
	large: PropTypes.bool,
	locales: PropTypes.string,
	synthesis: PropTypes.bool
};

export default CompassIndicatorCard;
