import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import deepEqual from "deep-equal";
import PropTypes from "prop-types";

import { queryDataset, uploadToDatabase } from "../../api/client";

/**
 * Load an empty data field for all the datasets to avoid undefined tests everywhere
 *
 * @param {Object} initialDatasets Datasets parameters of a new application
 */
function initData(initialDatasets) {
	const newInitialDatasets = { ...initialDatasets };
	Object.keys(initialDatasets).forEach((datasetName) => {
		if (!newInitialDatasets[datasetName].data) {
			newInitialDatasets[datasetName].data = [];
		}
	});

	return newInitialDatasets;
}

initData.propTypes = {
	initialDatasets: PropTypes.object
};

// FIXME: remove useless currentNodeDetail/allSitesOnCurrentNode/allSuppliersOnCurrentNode
// Generic App Slice to be created and called for each App
export default function createAppSlice(appName = "", initialPage = "", initialRefines = {},
	initialDatasets = {}, openData = false, groupFilter = {}, sliderFilter = {}) {
	const initialState = {
		page: initialPage,
		subPage: undefined,
		clientParameters: undefined,
		initialRefines: undefined,
		activeRefines: initialRefines,
		isRefined: false,
		datasets: initData(initialDatasets),
		openData,
		loadDataStatus: "notLoaded",
		groupFilter,
		sliderFilter
	};

	// Async methods to fetch a single dataset from a collection in param
	const fetchDataset = createAsyncThunk(`${appName}/fetchDataset`, async (args, { getState }) => {
		const {
			name, collection, fetch, query, options = {}, limit, skip = 0, facetsToRefine = undefined,
			dateToTransform = undefined, selectedReload = undefined
		} = args;

		const state = getState();
		const { initialRefines, activeRefines, datasets } = state[appName];
		const facets = facetsToRefine === undefined
			? (name in datasets && datasets[name].facetsToRefine ? datasets[name].facetsToRefine : [])
			: facetsToRefine;

		try {
			const datasetData = await queryDataset(
				appName,
				{
					collection,
					fetch,
					query,
					options,
					facetsToRefine: facets,
					dateToTransform,
					limit,
					skip
				},
				{ ...initialRefines, ...activeRefines },
				state[appName].openData,
				false);
			if (selectedReload !== undefined) {
				datasetData.selectedReload = selectedReload;
			}
			return { name, activeRefines: { ...initialRefines, ...activeRefines }, datasetData };
		} catch (error) {
			console.log(`Query Dataset:${error}`);
			return Promise.reject();
		}
	});

	// Async methods to fetch and updates all the datasets of the state (state.datasets)
	const fetchDatasets = createAsyncThunk(`${appName}/fetchDatasets`, async (args, { getState, rejectWithValue }) => {
		const state = getState();
		const response = {};
		const { initialRefines, activeRefines } = state[appName];

		// if query is not open, test if user is authenticated
		try {
			const user = state[appName]?.openData ? undefined : await Auth.currentAuthenticatedUser();
		} catch (error) {
			console.log("unauthenticated", error);
			return rejectWithValue(new Error(401));
		}

		try {
			await Promise.allSettled(
				Object.keys(state[appName].datasets)
				.map(async (collection) => {
					let dataset = { ...state[appName].datasets[collection] };

					// apply cross group filter
					if (initialState?.groupFilter?.isGroupFilter && initialState?.groupFilter?.groupFields.length > 0) {
						dataset = { ...dataset, ...initialState?.groupFilter };
					}

					// apply slider filter
					if (initialState?.sliderFilter?.isSliderFilter
						&& initialState?.sliderFilter?.sliderFields.length > 0
						&& (!initialState?.sliderFilter?.excludedCollections.includes(collection))
					) {
						dataset = { ...dataset, ...initialState?.sliderFilter };
					}

					const currentRefines = { ...initialRefines, ...activeRefines };

					if (dataset && dataset.data && dataset.data.length !== 0 && (dataset.selectedReload && !activeRefines[dataset.selectedReload])) {
						dataset.data = undefined;
					}

					if (dataset.fetch === "calculated"
                    || (dataset.selectedReload && !activeRefines[dataset.selectedReload])
                    || (dataset.pages !== undefined && dataset.pages.length > 0
                        && (dataset.pages?.indexOf(state[appName].page) === -1
                        && dataset.pages?.indexOf(`${state[appName].page}/${state[appName].subPage}`) === -1))
                    || (deepEqual(dataset.activeRefines, currentRefines))
                    || (dataset.facetsToRefine === undefined && dataset.data && dataset.data.length !== 0)) {
						response[collection] = dataset;
						response[collection].refreshed = false;
					} else {
						response[collection] = await queryDataset(
							appName,
							dataset,
							currentRefines,
							state[appName].openData,
							true
						);
						response[collection].activeRefines = currentRefines;
						response[collection].refreshed = true;
					}
				})
			);
			return response;
		} catch (error) {
			console.log("error here");
			return rejectWithValue(error);
		}
	});

	const uploadFileToDatabase = createAsyncThunk(
		`${appName}/uploadFileToDatabase`,
		async ({
			appName, collection, data
		}, { rejectWithValue }) => {
			try {
				return uploadToDatabase(appName, collection, data);
			} catch (err) {
				return rejectWithValue("an error occurs when upload file to database");
			}
		}
	);
	// Creation of the slice based of the appName with the initialState in param
	const slice = createSlice({
		name: appName,
		initialState,
		reducers: {
			setPage: (state, action) => {
				state.page = action.payload;
				state.subPage = undefined;
			},
			setSubPage: (state, action) => {
				state.subPage = action.payload;
			},
			reset: (state, action) => {
				state.datasets = undefined;
			},
			refine: (state, action) => {
				const newRefine = { ...state.activeRefines };
				action.payload.forEach((facet) => ((facet.value !== null && facet.value !== undefined)
                    && (!Array.isArray(facet.value) || ((Array.isArray(facet.value))
                    && facet.value.length > 0 && (facet.value[0] || facet.value[0] === 0))))
					? newRefine[facet.key] = facet.value : delete newRefine[facet.key]);
				state.activeRefines = newRefine;
				state.loadDataStatus = "loading";
				state.isRefined = (Object.keys(newRefine)?.filter((key) => newRefine[key] !== undefined)?.length !== 1);
			},
			clear: (state, action) => {
				const newRefine = { ...state.activeRefines };
				action.payload.forEach((element) => {
					delete newRefine[element.refine];
				});
				state.activeRefines = newRefine;
				state.isRefined = false;
			},
			setInitialRefines: (state, action) => {
				state.initialRefines = action.payload;
			},
			fetchCalculatedDataset: (state, action) => {
				const newDatasets = { ...state.datasets };
				newDatasets[action.payload.datasetName] = {
					fetch: "calculated",
					data: action.payload.datasetData
				};
				state.datasets = newDatasets;
			},
			deleteFromSubFilter: (state, action) => {
				const { field, item } = action.payload;

				const newRefine = Array.isArray(state.activeRefines[field]) ?
					state.activeRefines[field].filter((e) => e !== item)
					: undefined;

				if (newRefine === undefined || newRefine === null || newRefine.length === 0) {
					delete state.activeRefines[field];
					state.loadDataStatus = "loading";
					return;
				}

				state.activeRefines[field] = newRefine;
				state.loadDataStatus = "loading";
			}
		},
		extraReducers: {
			[fetchDataset.pending]: (state) => {
				state.loadDataStatus = "loading";
			},
			[fetchDataset.fulfilled]: (state, action) => {
				if (action.payload.name === "clientParameters") {
					state.clientParameters = action?.payload?.datasetData?.data?.[0];
				} else {
					const newDatasets = { ...state.datasets };
					newDatasets[action.payload.name] = action.payload.datasetData;
					// newDatasets[action.payload.name].activeRefines = action?.payload?.activeRefines;
					newDatasets[action.payload.name] = { ...newDatasets[action.payload.name], activeRefines: action?.payload?.activeRefines };

					state.datasets = newDatasets;
				}
				state.loadDataStatus = "idle";
			},
			[fetchDataset.rejected]: (state) => {
				state.loadDataStatus = "rejected";
			},
			[fetchDatasets.pending]: (state) => {
				state.loadDataStatus = "loading";
			},
			[fetchDatasets.fulfilled]: (state, action) => {
				state.datasets = action.payload;
				// console.log(Object.keys(state.datasets).filter((element) => state.datasets[element].refreshed));
				state.loadDataStatus = "idle";

				if (action?.payload?.clientParameters?.data?.[0]) {
					state.clientParameters = action?.payload?.clientParameters?.data?.[0];
				}
			},
			[fetchDatasets.rejected]: (state, action) => {
				state.errorMsg = action;
				if (action?.payload?.message === "401" || action?.payload?.message === 401) {
					state.loadDataStatus = "sessionExpired";
				} else {
					state.loadDataStatus = "rejected";
				}
			},
			[uploadFileToDatabase.fulfilled]: (state, action) => {
				const isSuccess = action?.payload?.res?.ok === 1;

				if (isSuccess) {
					console.log("upload file to database successfully");
				} else {
					// return error item position in file
					const res = action.payload.res.reduce((acc, cur, i) => {
						if (cur !== 1) return [...acc, i];
						return [...acc];
					}, []);
					console.log("upload file to database fail");
				}
			}
			// [uploadFileToDatabase.rejected]: (state, action) => {
			// 	console.log({ error: "rejected", state, action });
			// 	state.error = state;
			// }
		}
	});

	// selectors of the state
	const selectAppName = (state) => appName;
	const selectPage = (state) => (state[appName] ? state[appName].page : undefined);
	const selectSubPage = (state) => (state[appName] ? state[appName].subPage : undefined);
	const selectActiveRefines = (state) => (state[appName] ? state[appName].activeRefines : undefined);
	const isRefined = (state) => (state[appName] ? state[appName].isRefined : undefined);
	const selectClientParameters = (state) => (state[appName] ? state[appName].clientParameters : undefined);
	const selectDatasets = (state) => (state[appName] ? state[appName].datasets : undefined);
	const selectLoadDataStatus = (state) => (state[appName] ? state[appName].loadDataStatus : undefined);

	// return all the elements of the created slice
	return {
		fetchDataset,
		fetchDatasets,
		uploadFileToDatabase,
		reducer: slice.reducer,
		actions: slice.actions,
		selectAppName,
		selectPage,
		selectSubPage,
		selectActiveRefines,
		isRefined,
		selectClientParameters,
		selectDatasets,
		selectLoadDataStatus
	};
}
