import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ParentSize } from "@visx/responsive";

import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import SimplePieChart from "../graphs/SimplePieChart";

function SCAPieChart({
	appSlice, dataset, ratingsConfig, description, large = false, locales, refineKey = undefined, label = undefined, module = undefined,
	accTotalKey = "", accArrayKey = "", accFrequencyKey = "", accLabelKey = "", accColorKey = "", hasLabel = false, hasTotal = false
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load data from the store
	const { data } = useSelector(appSlice.selectDatasets)[dataset];
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	// get exact data(outer) which will be diplayed based on activeRefine
	const moduleData = useMemo(() => data
	?.filter((filterValue) => filterValue[refineKey] === module), [data, module, refineKey]);

	// get pie data(inner) based on activeRefine
	const pieData = useMemo(() => (
		moduleData?.[0]?.[accArrayKey].reduce(
			(acc, curValue, index) => {
				acc[index] = {
					...curValue,
					label: curValue[refineKey],
					[accFrequencyKey]: curValue[accFrequencyKey] ?? 0,
					color: ratingsConfig[curValue[accColorKey]]
				};
				return acc;
			}, [{}])
		.filter((el) => {
			if (activeRefines.inspectionTasksL1?.length > 0) {
				return activeRefines.inspectionTasksL1?.includes(el?.[accLabelKey]);
			}
			return el;
		})
	), [moduleData, accArrayKey, refineKey, accFrequencyKey, ratingsConfig,
		accColorKey, activeRefines.inspectionTasksL1, accLabelKey]);

	const totalReports = useMemo(() => (moduleData?.[0]?.[accTotalKey]), [moduleData, accTotalKey]);

	return (
		<div className="flex flex-col alphaBlur rounded-xl px-2 py-3 w-full h-full gap-y-2 cursor-pointer">
			<div className="flex justify-between items-center w-full">
				<div className="flex flex-col w-full">
					<div className="flex gap-x-1">
						<VerifiedUserIcon className={`text-compass_primary-accent 
							${ large ? "text-3xl" : "text-lg md:text-xl 2xl:text-3xl"}` } />
						<div className="flex flex-col w-full">
							<p className={`font-semibold line-clamp-1 text-compass_primary-accent
							${ large ? "text-2xl" : "text-medium md:text-base 2xl:text-lg"}`}>
								{label}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="flex h-full justify-center items-center w-full">
				<div className="flex flex-col gap-y-1 h-full justify-center items-center">
					<div>
						<ParentSize debounceTime={10}>
							{(parent) => (
								<div className="flex justify-center">
									<SimplePieChart appSlice={appSlice} pieValue={pieData} hasLegend={false} total={totalReports}
										accFrequencyKey={accFrequencyKey} accLabelKey={accLabelKey} hasLabel={hasLabel} hasTotal={hasTotal} />
								</div>
							)}
						</ParentSize>
					</div>
					{description &&
						<div className="text-small md:text-medium 2xl:text-base line-clamp-1 leading-3 w-full flex text-center">
							{description}
						</div>}

				</div>
			</div>

		</div>

	);
}

SCAPieChart.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	ratingsConfig: PropTypes.object,
	description: PropTypes.string,
	label: PropTypes.string,
	module: PropTypes.string,
	large: PropTypes.bool,
	locales: PropTypes.string,
	refineKey: PropTypes.string,
	accTotalKey: PropTypes.string,
	accArrayKey: PropTypes.string,
	accFrequencyKey: PropTypes.string,
	accColorKey: PropTypes.string,
	accLabelKey: PropTypes.string,
	hasLabel: PropTypes.bool,
	hasTotal: PropTypes.bool
};

export default SCAPieChart;
