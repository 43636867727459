import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";

const customStyles = (themeColor) => ({
	control: (provided, state) => ({
		...provided,
		width: provided.width,
		borderRadius: "0.4rem",
		borderWidth: "1.5px",
		height: "25px",
		minHeight: "25px",
		borderColor: themeColor?.controlBorderColor
			? themeColor?.controlBorderColor
			: "none",
		"&:hover": {
			borderColor: state.isFocused
				? themeColor?.controlBorderColor
					? themeColor?.controlBorderColor
					: "none"
				: "none"
		},
		boxShadow: "none",
		textAlign: "center",
		fontSize: "10px",
		padding: "0px",
		margin: "0px"
	}),

	singleValue: (provided, state) => ({
		...provided,
		width: "100%",
		textAlign: "center",
		fontWeight: "normal",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		margin: "0px",
		padding: "0px"
	}),

	option: (provided, state) => ({
		...provided,
		borderRadius: "0.8rem",
		backgroundColor: state.isFocused
			? themeColor?.optionBgColor
				? themeColor?.optionBgColor
				: "white"
			: "white",
		color: state.isFocused ?
			themeColor?.optionBgColor === "white" ?
				"none"
				: "white"
			: "gray",
		fontWeight: (state.isFocused || state.isSelected) ? "bold" : "normal"
	}),

	menu: (provided, state) => ({
		...provided,
		zIndex: 200,
		width: "200px",
		borderRadius: "0.8rem",
		padding: "10px",
		fontSize: "12px"
	}),

	menuList: (provided) => ({
		...provided,
		"::-webkit-scrollbar": {
			display: "none"
		}
	})
});

const Control = ({ children, ...rest }) => (
	<components.Control {...rest}>
		<div className="flex flex-row justify-between items-center">
			<LanguageIcon className="text-sm ml-1 -mr-1"/>
			<span className="mt-0.8">{children}</span>
		</div>
	</components.Control>
);

Control.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const LanguageFilter = ({
	className = "",
	themeColor,
	menuPlacement = "top",
	langListValue,
	appDefinition
}) => {
	const { t, i18n } = useTranslation((appDefinition.locales === "translationsClarity") ? "" : appDefinition.locales);

	useEffect(() => {
		if (i18n.language === "en-US" || i18n.language === "" || i18n.language === undefined) {
			i18n.changeLanguage("en");
		}
	}, [i18n]);

	return (
		<Select
			className={className}
			defaultValue={langListValue.find((lang) => lang.value === i18n.language) ?? { value: "en", label: "EN" }}
			options={langListValue}
			styles={customStyles(themeColor)}
			menuPlacement ={ menuPlacement}
			onChange={(target) => { i18n.changeLanguage(target.value); }}
			isSearchable = {false}
			components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Control }}
			value={langListValue.find((lang) => lang.value === i18n.language)}
		/>
	);
};

LanguageFilter.propTypes = {
	className: PropTypes.string,
	themeColor: PropTypes.object,
	menuPlacement: PropTypes.string,
	langListValue: PropTypes.array,
	appDefinition: PropTypes.object
};

export default LanguageFilter;
