import React from "react";
import PropTypes from "prop-types";
import { getManHoursColor } from "../../apps/configs/SupplyRConfig";
import lightenDarkenColor from "../../utils/LightenDarkenColor";

const VerticalGauge = ({
	score, width = 96, height = 112, children
}) => {
	const color = getManHoursColor(score) ?? "transparent";
	const colorDarker = lightenDarkenColor(color, -30);
	const scoreScale = (score) => score < 3000000
		? 0.33
		: score >= 3000000 && score < 3500000
			? 0.6
			: score >= 3500000
				? 0.8
				: null;
	return (
		<div>
			<svg viewBox="0 0 24 28" className="rounded-3xl"
				style={{ width, height }}
				xmlns="http://www.w3.org/2000/svg">
				<defs>
					<linearGradient id="verticalrect" x1="0" y1="28" x2="24" y2="0" gradientUnits="userSpaceOnUse">
						{/* <stop stopColor="white" offset="0" /> */}
						<stop stopColor={colorDarker} offset="0.1" />
						<stop stopColor={colorDarker} offset="1" />
					</linearGradient>

					<linearGradient id="verticalrect3D" x1="0" y1="28" x2="24" y2="0" gradientUnits="userSpaceOnUse">
						<stop stopColor="white" offset="0" stopOpacity="0.8"/>
						<stop stopColor="white" offset="0.1" stopOpacity="0.8"/>
						<stop stopColor="white" offset="0.5" stopOpacity="0"/>
						<stop stopColor="white" offset="1" stopOpacity="0"/>
					</linearGradient>
				</defs>

				<rect
					x={0}
					y={0}
					width={24}
					height={28}
					rx="6" ry="6"
					className="fill-current text-gray-600"
				/>

				<rect
					x={0}
					y={0}
					width={19}
					height={24}
					rx="5" ry="5"
					className="fill-current text-gray-300 opacity-10"
				/>

				<line x1="12" y1="28" x2="12" y2={28 - scoreScale(score) * 28} strokeWidth="24" stroke="url(#verticalrect)" >
					<animate attributeName="y2" from="28" to={28 - scoreScale(score) * 28} dur="1.5s" />
				</line>

				<rect
					x={0}
					y={-4}
					width={28}
					height={32}
					rx="6" ry="6"
					fill="transparent"
					stroke="url(#verticalrect3D)"
					strokeWidth="4" opacity="0.3"
				/>

				<foreignObject x="0" y="0" width="72" height="84" transform="translate(4,4) scale(0.25,0.25)">
					{children}
                    damn
				</foreignObject>

			</svg>
		</div>
	);
};

VerticalGauge.propTypes = {
	score: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.object
};

export default VerticalGauge;
