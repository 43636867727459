/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useMemo, useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
	useAsyncDebounce, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable
} from "react-table";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownUp from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import { Pagination } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import exportToExcel from "../../utils/ExportToExcel";

const mockData = [
	{
		requestNumber: "N 205176503",
		creationDate: "25 April, 2020",
		dueDate: "23 May, 2020",
		client: "Bureau Veritas",
		status: "Rejection",
		action: ""
	},
	{
		requestNumber: "N 205176504",
		creationDate: "26 April, 2020",
		dueDate: "24 May, 2020",
		client: "Bureau Veritas",
		status: "Awaiting",
		action: ""
	},
	{
		requestNumber: "N 205176505",
		creationDate: "27 April, 2020",
		dueDate: "25 May, 2020",
		client: "Bureau Veritas",
		status: "Done",
		action: ""
	},
	{
		requestNumber: "N 205176506",
		creationDate: "28 April, 2020",
		dueDate: "26 May, 2020",
		client: "Bureau Veritas",
		status: "Closed",
		action: ""
	},
	{
		requestNumber: "N 205176507",
		creationDate: "25 April, 2020",
		dueDate: "23 May, 2020",
		client: "Bureau Veritas",
		status: "Rejection",
		action: ""
	},
	{
		requestNumber: "N 205176508",
		creationDate: "26 April, 2020",
		dueDate: "24 May, 2020",
		client: "Bureau Veritas",
		status: "Awaiting",
		action: ""
	},
	{
		requestNumber: "N 205176509",
		creationDate: "27 April, 2020",
		dueDate: "25 May, 2020",
		client: "Bureau Veritas",
		status: "Done",
		action: ""
	},
	{
		requestNumber: "N 205176510",
		creationDate: "28 April, 2020",
		dueDate: "26 May, 2020",
		client: "Bureau Veritas",
		status: "Closed",
		action: ""
	},
	{
		requestNumber: "N 205176511",
		creationDate: "25 April, 2020",
		dueDate: "23 May, 2020",
		client: "Bureau Veritas",
		status: "Rejection",
		action: ""
	},
	{
		requestNumber: "N 205176512",
		creationDate: "26 April, 2020",
		dueDate: "24 May, 2020",
		client: "Bureau Veritas",
		status: "Awaiting",
		action: ""
	},
	{
		requestNumber: "N 205176513",
		creationDate: "27 April, 2020",
		dueDate: "25 May, 2020",
		client: "Bureau Veritas",
		status: "Done",
		action: ""
	},
	{
		requestNumber: "N 205176514",
		creationDate: "28 April, 2020",
		dueDate: "26 May, 2020",
		client: "Bureau Veritas",
		status: "Closed",
		action: ""
	}
];

const statusColorSelector = (status) => {
	if (status === "Rejection") return "bg-red-400";
	if (status === "Awaiting") return "bg-yellow-400";
	if (status === "Done") return "bg-green-400";
	if (status === "Closed") return "bg-gray-400";
};

// Define a default UI for filtering
const GlobalFilter = ({
	placeHolder,
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter
}) => {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="flex justify-between items-center bg-gray-100 py-2 px-2 md:px-6 space-x-4 rounded-xl ">
			<input
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={placeHolder}
				className="bg-transparent outline-none "
			/>
			<SearchIcon />
		</div>
	);
};

GlobalFilter.propTypes = {
	placeHolder: PropTypes.string,
	preGlobalFilteredRows: PropTypes.array,
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func
};

const IndeterminateCheckbox = forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<>
				<input type="checkbox" ref={resolvedRef} {...rest} />
			</>
		);
	}
);

IndeterminateCheckbox.propTypes = {
	indeterminate: PropTypes.any
};

const AdminRequestTable = ({
	appSlice,
	locales,
	dataset = undefined,
	structureTable = undefined,
	sortByRefineFields = [{ id: "creationDate", desc: false }],
	exportName
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	const [tableData, setTableData] = useState([...mockData]);

	const data = useMemo(() => [...mockData], []);

	const columns = useMemo(
		() => [
			{
				Header: "Request Number",
				accessor: "requestNumber"
			},
			{
				Header: "Creation Date",
				accessor: "creationDate"
			},
			{
				Header: "Due Date",
				accessor: "dueDate"
			},
			{
				Header: "Client",
				accessor: "client"
			},
			{
				Header: "Status",
				accessor: "status",
				// eslint-disable-next-line react/prop-types
				Cell: ({ value }) => (value && value !== 0)
					?
					<p className={`flex items-center justify-center text-small
                    ${statusColorSelector(value)} rounded-full h-5 w-16 text-white`}>
						{value}
					</p>
					: ""
			},
			{
				Header: "Action",
				accessor: (rowData, rowIndex) => (
					<select name="pets" id="action-select" onChange={(e) => {
						const tempData = [...tableData];
						tempData[rowIndex].action = e.target.value;
						setTableData(tempData);
					}}>
						<option value="">Select your option</option>
						<option value="Rejection">Rejection</option>
						<option value="Awaiting">Awaiting</option>
						<option value="Done">Done</option>
						<option value="Closed">Closed</option>
					</select>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		rows,
		gotoPage,
		preGlobalFilteredRows,
		setGlobalFilter,
		state: { pageIndex, pageSize, selectedRowIds }
	} = useTable({
		columns,
		data,
		initialState: {
			pageIndex: 0,
			pageSize: 10,
			sortBy: sortByRefineFields
		}
	},
	useGlobalFilter,
	useSortBy,
	usePagination,
	useRowSelect,
	(hooks) => {
		hooks.visibleColumns.push((columns) => [
			{
				id: "selection",
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				Cell: ({ row }) => (
					<div>
						<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				)
			},
			...columns
		]);
	}
	);

	const generateTableToExport = () => {
		const sortedTable = new Array(rows.length);
		rows.forEach((row, i) => {
			const formattedRow = {};
			prepareRow(row);
			row.cells.forEach((cell, j) => (
				formattedRow[cell.column.Header] = cell?.value?.toString()
			));
			sortedTable[i] = formattedRow;
		});

		return sortedTable;
	};

	// TODO:
	// if (dataset === undefined || structureTable === undefined) {
	// 	console.log("You need to define your dataset and table structure to display this component");
	// 	return null;
	// }

	return (
		<div className="flex flex-col text-sm space-y-4 max-x-full  w-full overflow-x-auto">
			<div className="self-center flex justify-between items-center w-full px-4 md:px-6">
				<GlobalFilter
					placeHolder={"Search for ..."}
					preGlobalFilteredRows={preGlobalFilteredRows}
					globalFilter={state.globalFilter}
					setGlobalFilter={setGlobalFilter}
				/>
				<div className="flex space-x-2 items-center">
					<p className="self-end hidden md:block">
						{rows.length} Results
					</p>
					<button className={`text-admin_primary-accent rounded-xl border border-gray-100 
                                    shadow-sm p-1 bg-white hover:border-gray-200 cursor-pointer`}
					title="Download data in Excel format"
					onClick={(e) => exportToExcel(generateTableToExport(), `${exportName} Export ${new Date().toISOString().split("T")[0]}`)}>
						<DownloadIcon className="text-admin_primary-default"/>
					</button>
				</div>
			</div>
			<table {...getTableProps()} className="w-full">
				<thead>
					{headerGroups.map((headerGroup, i) => (
						<tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup${i}`}>
							{headerGroup.headers.map((column, j) => (
								<th key={`header${j}`} {...column.getHeaderProps(column.getSortByToggleProps())}
									className="first:pl-6 last:pr-6 text-left font-medium p-2 max-w-xs"
								>
									<div className="flex items-center space-x-2">
										{column.render("Header")}
										<span>
											{column.isSorted
												? column.isSortedDesc
													? <KeyboardArrowDownUp fontSize="inherit" />
													: <KeyboardArrowDownIcon fontSize="inherit" />
												: ""}
										</span>
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, k) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()} key={`row${k}`}
								className={"odd:bg-gray-100 hover:bg-admin_primary-default hover:bg-opacity-20 cursor-pointer"}
							>
								{row.cells.map((cell, m) => (
									<td key={`cell${m}`}
										{...cell.getCellProps()}
										className="first:pl-6 last:pr-6 p-2 max-w-xs"
									>
										{cell.render("Cell")}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			{rows.length > pageSize
				&&
					<Pagination
						page={pageIndex + 1}
						count={Math.floor((rows.length / pageSize) + 1)}
						className="md:self-center px-4 md:px-6"
						onChange={async (event, value) => {
							const page = value ? Number(value) - 1 : 0;
							gotoPage(page);
						}}
					/>
			}
		</div>
	);
};

AdminRequestTable.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	structureTable: PropTypes.func,
	sortByRefineFields: PropTypes.array,
	locales: PropTypes.string,
	exportName: PropTypes.string
};

export default AdminRequestTable;
