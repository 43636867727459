import { useState, useMemo } from "react";
import PropTypes from "prop-types";

import L from "leaflet";
import { Marker, Popup } from "react-leaflet";

import getValueNestedObject from "../../utils/GetValueNestedObject";

import RYBTooltip from "../tooltips/RYBTooltip";

function getIcon(pathFillColor = "#000000") {
	const svgTemplate = `<svg id="svg-vectormarker-marker" xmlns:svg="http://www.w3.org/2000/svg" 
	  xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 56" width="40px" height="40px">
      <path id="svg_2" stroke="rgb(201,120,163)" fill="${pathFillColor}" 
      d="M16 0C7.222 0 0 7.217 0 16.157c0 4.86 1.03 8.446 2.66 12.027L16 56l13.34-27.816c1.63-3.58 2.66-7.167 2.66-12.027C32 7.217 24.778 0 16 0z"></path>
  </svg>`;

	const icon = L.divIcon({
		className: "marker",
		html: svgTemplate,
		iconSize: [40, 40],
		iconAnchor: [20, 28],
		popupAnchor: [7, -16]
	});
	return icon;
}

// Specific Map Marker for RYB
function RYBMapMarker({
	marker, geopointField, fieldsTooltip, defaultColor, colorMap, colorKey, openPopup = false
}) {
	const [isOpenPopup, setIsOpenPopup] = useState(openPopup);
	const geopoint = getValueNestedObject(marker, geopointField);

	const eventHandlers = useMemo(() => ({
		click() {
			setIsOpenPopup(true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), []);

	if (!geopoint) {
		return null;
	}

	const colorMarker = marker.status === "OnGoing"
		? defaultColor
		: colorMap !== {} && colorKey !== ""
			? colorMap[getValueNestedObject(marker, colorKey)]
				? colorMap[getValueNestedObject(marker, colorKey)].color
				: defaultColor
			: defaultColor;

	const initMarker = (ref) => {
		if (ref && openPopup) {
			setTimeout(() => {
				ref.openPopup();
			}, 100);
		}
	};

	return (
		<Marker ref={initMarker} position={[Number(geopoint[1]), Number(geopoint[0])]} eventHandlers={eventHandlers} icon={getIcon(colorMarker)} >
			<Popup direction="top" offset={[-8, -5]} opacity={0.8} >
				<RYBTooltip marker={marker} colorMarker={colorMarker} isOpenPopup={isOpenPopup} />
			</Popup>
		</Marker>
	);
}

RYBMapMarker.propTypes = {
	marker: PropTypes.object,
	geopointField: PropTypes.string,
	fieldsTooltip: PropTypes.array,
	defaultColor: PropTypes.string,
	colorMap: PropTypes.object,
	colorKey: PropTypes.string,
	openPopup: PropTypes.bool
};

export default RYBMapMarker;
