export const asyncMap = async (arrayLike, fn) => Promise.all(arrayLike.map(async (item) => {
	try {
		return await fn(item);
	} catch (error) {
		return Promise.resolve("");
	}
}));

const getTargetScopeBaseOnApp = ({ appCollection, userRights }) => {
	const targetRightsMap = new Map();
	userRights.forEach(({ app, scope }) => {
		if (appCollection.includes(app.toLowerCase())) {
			const appScopesCollection = targetRightsMap.get(app.toLowerCase()) ? targetRightsMap.get(app.toLowerCase()) : [];
			if (scope) {
				appScopesCollection.push(scope.toLowerCase());
			}
			targetRightsMap.set(app.toLowerCase(), appScopesCollection);
		}
	});
	return targetRightsMap;
};

const checkScopes = ({
	appRightsMap, appsCollection, restrictedScopeValue, projectName
}) => {
	let supplyChainVisibilityAuthorized = true; // default to true as we try to restrict only if the restrictedScopeValue is present
	appRightsMap.forEach((value, key) => {
		if (appsCollection.includes(key)) {
			// eslint-disable-next-line max-len
			supplyChainVisibilityAuthorized = value.some((project) => (!!project.trim() && (project.toLowerCase() !== restrictedScopeValue?.toLowerCase())));
		}
	});
	return supplyChainVisibilityAuthorized;
};

export const isAllowedToSeeSupplyChain = ({
	user, appsCollection, restrictedScopeValue, projectName
}) => {
	// Ghislain : WHY????
	if (!projectName) {
		return true;
	}
	if (!!user && Array.isArray(user.rights)) {
		const appsCollectionFormatted = appsCollection.map((app) => app.toLowerCase());
		const appRightsMap = getTargetScopeBaseOnApp({ appCollection: appsCollectionFormatted, userRights: user.rights });
		return checkScopes({
			appRightsMap, appsCollection: appsCollectionFormatted, restrictedScopeValue, projectName
		});
	}
	return false;
};

export const isUserAlphaRole = (user) => {
	if (!!user && Array.isArray(user.rights)) {
		return user.rights.some(({ app, scope }) => app.toLowerCase() === "alpha");
	}
	return false;
};

export default asyncMap;
