import DirectionsRailwayIcon from "@mui/icons-material/DirectionsRailway";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import HotelIcon from "@mui/icons-material/Hotel";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import SportsIcon from "@mui/icons-material/Sports";
import BuildIcon from "@mui/icons-material/Build";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SchoolIcon from "@mui/icons-material/School";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import LegalNotice from "../../termsLocales/en/RYBLegalNotice";
import Terms from "../../termsLocales/en/RYBTerms";
import Privacy from "../../termsLocales/en/RYBPrivacy";
import PrivacyFR from "../../termsLocales/fr/RYBPrivacyFR";
import Cookies from "../../termsLocales/en/RYBCookies";
import CookiesFR from "../../termsLocales/fr/RYBCookiesFR";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} slice Name of the slice related to the app created and references in the store ./store.js
 * @param {String} logo Label of the menu, if none, only the picto
 */
export const appDefinition = {
	name: "Restart Your Business",
	subName: "by Bureau Veritas",
	launcherID: "RYB",
	slice: "ryb",
	theme: "alpha",
	locales: "translationsRYB",
	favicon: "/images/RYB/favicon.ico",
	logo: "/images/RYB/RestartYourBusinessB2B.png",
	openData: true
};

/**
 * Set up inital value for menu
 *
 * @param {String} initialMenu
 * Initial Menu that will be selected by default when entering the app,
 * has to be a Menu of the menuOptions defined above
 */
export const initialMenu = "HOME";

/**
  * Name of the dataset for the RYB Public map
  *
  * @param {String} mapMarkers Name of the Document DB collection for the RYB Public map
  */
export const mapMarkers = "mapMarkers";

/**
  * Name of the dataset for the RYB Public list
  *
  * @param {String} listSites Name of the Document DB collection for the RYB Public list
  */
export const listSites = "listSites";

export const RYBCollection = "RYBOpenData";

/**
  *
  * @param {String} tab list
  */
export const termsTabSet = {
	"Legal Notice": {
		en: <LegalNotice />,
		fr: <LegalNotice />,
		es: <LegalNotice />,
		pt: <LegalNotice />
	},
	Terms: {
		en: <Terms />,
		fr: <Terms />,
		es: <Terms />,
		pt: <Terms />
	},
	Privacy: {
		en: <Privacy />,
		fr: <PrivacyFR />,
		es: <Privacy />,
		pt: <Privacy />
	},
	Cookies: {
		en: <Cookies />,
		fr: <CookiesFR />,
		es: <Cookies />,
		pt: <Cookies />
	}
};

export const listSitesQuery = [{
	$project: {
		siteTechnicalId: 1,
		site: 1,
		siteAddress: 1,
		sitePostalCode: 1,
		siteCity: 1,
		siteCountry: 1,
		publicPhone: 1,
		publicEmail: 1,
		publicWebSite: 1,
		status: 1,
		businessSegment: 1,
		"geometry.coordinates": 1
	}
}];

/**
 * Active DataSet (DocumentDB Collection) to load
 *
 * @param {String[]} rybInitDatasets Array of the name of collections to load for the App
 */
export const rybInitDatasets = {
	mapMarkers: {
		collection: RYBCollection,
		fetch: "aggregation",
		facetsToRefine: ["site", "businessSegment", "siteTechnicalId",
			"siteCountry", "siteCity", "status"],
		query: [{
			$project: {
				siteTechnicalId: 1,
				status: 1,
				businessSegment: 1,
				"geometry.coordinates": 1
			}
		}],
		limit: 10000
	},
	listSites: {
		collection: RYBCollection,
		fetch: "aggregation",
		facetsToRefine: ["site", "businessSegment", "siteTechnicalId",
			"siteCountry", "siteCity", "status"],
		query: listSitesQuery,
		skip: 0,
		limit: 10
	},
	listBusinessSegments: {
		collection: RYBCollection,
		fetch: "facet",
		query: ["businessSegment"],
		limit: 500
	}
};

/**
 * Function to refresh the list of sites after a click on a new page
 *
 * @param {Number} value Page to refresh the list of sites
 */
export const updateListSites = (value) => ({
	name: "listSites",
	collection: RYBCollection,
	fetch: "aggregation",
	query: listSitesQuery,
	limit: 10,
	skip: ((value - 1) * 10)
});

/**
 * Query to search for site and location in the RYB Header
 *
 * @param {Object} activeRefines Current active refines from the store
 * @param {String} inputValue String to look for
 */
export const fieldsToSearch = (activeRefines, inputValue) => ([
	{
		collection: RYBCollection,
		aggregation: [
			{
				$match: {
					...activeRefines,
					...{ siteCountry: { $regex: `.*${inputValue}.*`, $options: "i" } }
				}
			},
			{ $group: { _id: { country: "$siteCountry" } } },
			{ $addFields: { type: "country" } }
		],
		limit: 2
	},
	{
		collection: RYBCollection,
		aggregation: [
			{
				$match: {
					...activeRefines,
					...{ siteCity: { $regex: `.*${inputValue}.*`, $options: "i" } }
				}
			},
			{ $group: { _id: { country: "$siteCountry", city: "$siteCity" } } },
			{ $addFields: { type: "city" } }
		],
		limit: 2
	},
	{
		collection: RYBCollection,
		aggregation: [
			{ $match: { ...activeRefines, ...{ site: { $regex: `.*${inputValue}.*`, $options: "i" } } } },
			{
				$project: {
					site: 1,
					siteCity: 1,
					siteCountry: 1,
					siteTechnicalId: 1,
					businessSegment: 1
				}
			},
			{
				$addFields: { type: "site" }
			}
		],
		limit: 6
	}
]);

/**
 * Parameter for the filter type in RYB Headers
 *
 * @param {Object} activeRefines Current active refines from the store
 * @param {String} inputValue String to look for
 */
export const filterTypeOptions = (placeHolder) => ({
	refine: "businessSegment",
	loadFromDataset: "listBusinessSegments",
	placeHolder,
	tag: LocalActivityIcon,
	isSearchable: false,
	clear: false,
	isClearable: true,
	toClearOnChange: false
});

/**
 * Parameter for the Map tooltip data load
 *
 * @param {String} siteId ID of the site to be loaded
 */
export const mapTooltipQuery = (siteId) => ({
	collection: RYBCollection,
	query: [
		{
			$match: { siteTechnicalId: siteId }
		},
		{
			$project: {
				site: 1,
				status: 1,
				siteAddress: 1,
				siteCity: 1,
				siteCountry: 1,
				publicPhone: 1,
				publicWebSite: 1,
				publicationDateTime: "$publicationdatetime",
				validityDate: 1,
				label: 1,
				stage: 1,
				businessSegment: 1
			}
		}
	]
});

/**
 * Definition of the color & picto Map for the list, the search and the map
 */
export const rybColorMap = {
	"Railway Station": { color: "#55b07f", picto: DirectionsRailwayIcon },
	"Railway Train": { color: "#55b07f", picto: DirectionsRailwayIcon },
	"Tram & Bus": { color: "#55b07f", picto: DirectionsBusIcon },
	"Ship Passenger Terminal": { color: "#55b07f", picto: DirectionsBoatIcon },
	Airport: { color: "#55b07f", picto: LocalAirportIcon },
	Entertainment: { color: "#55b07f", picto: SportsEsportsIcon },
	"Car Rental": { color: "#55b07f", picto: EmojiTransportationIcon },
	Sports: { color: "#e8bd6e", picto: SportsIcon },
	Hospitality: { color: "#e8bd6e", picto: HotelIcon },
	Restaurant: { color: "#5c1240", picto: RestaurantIcon },
	Foodservice: { color: "#5c1240", picto: RestaurantIcon },
	"Corporate Buildings": { color: "#36a8ce", picto: BusinessIcon },
	Offices: { color: "#36a8ce", picto: BusinessIcon },
	Construction: { color: "#666699", picto: BuildIcon },
	"Bank Agencies": { color: "#55b07f", picto: AccountBalanceIcon },
	Banking: { color: "#55b07f", picto: AccountBalanceIcon },
	"Public building": { color: "#a655b0", picto: AccountBalanceIcon },
	"Schools & Universities": { color: "#a655b0", picto: SchoolIcon },
	Retail: { color: "#5b62ff", picto: StoreMallDirectoryIcon },
	Malls: { color: "#5b62ff", picto: StoreMallDirectoryIcon },
	"Business System Audit": { color: "#a655b0", picto: VerifiedUserIcon },
	"Industrial Site": { color: "#5b62ff", picto: LocationCityIcon },
	Industry: { color: "#5b62ff", picto: LocationCityIcon },
	Other: { color: "#5b62ff", picto: LocationOnIcon }
};
