/* eslint-disable max-len */
import React, {
	useState, useEffect, createRef, useRef
} from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useTranslation } from "react-i18next";
import { sum } from "d3";
import { rollups } from "d3-array";
import isEqual from "lodash/isEqual";

import { ParentSize } from "@visx/responsive";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GavelIcon from "@mui/icons-material/Gavel";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ChatIcon from "@mui/icons-material/Chat";
import ForwardIcon from "@mui/icons-material/Forward";

import Div100vh, { use100vh } from "react-div-100vh";

import createAppSlice from "../data/slices/createAppSlice";
import { requestSignIn, userSelector } from "../data/slices/UserSlice";

import IF from "../utils/IF";
import Loading from "../utils/Loading";
import filterObjects from "../utils/FilterObjects";
import "../styles/tree.css";

import {
	appDefinition, menuOptions, clarityInitDatasets, getMarkerColor, clientInitParameters,
	clarityInitRefines, clarityMapTooltip, domainsOptions, sdgsOptions, nonConformities,
	listNonConformityQuery, ClarityCheckpoints, updateListNonConformities, filterOptions,
	listTagsNonConformities, listNCAPTags, splashScreens, tableStructure, auchanWoodTableStructure, termsTabSet,
	defaultNetZeroSortOptions, netZeroSortOptions, getClaritySupplierSiteId, pieColors, monthAnalysisNCLinesColors,
	getClientParametersByProjectName,
	gscaTableStructure, gscaColorScale, getGSCAMarkerColor, sitesPerModulesTableStructure, colorsAndBreakdowns
} from "./configs/ClarityConfig";

import getSupplychainChildren from "../components/tree/ClaritySupplychainChildren";
import SideBar from "../components/menu/SideBar";
import ClarityDomainHeader from "../components/header/ClarityDomainHeader";
import ColumnGraph from "../components/graphs/ColumnGraph";
import ClarityTooltip from "../components/tooltips/ClarityTooltip";
import ClarityGSCATooltip from "../components/tooltips/ClarityGSCATooltip";
import ClarityIndicatorCard from "../components/cards/ClarityIndicatorCard";
import TitleCard from "../components/cards/TitleCard";
import NonConformitiesCard from "../components/cards/NonConformitiesCard";
import TreeNodesGraph from "../components/tree/TreeNodesGraph";
import ClarityPageHeader from "../components/header/ClarityPageHeader";
import InfoCard from "../components/cards/InfoCard";
import ListOfNonConformities from "../components/cards/ListOfNonConformities";
import NCByModules from "../components/cards/NCByModules";
import Table from "../components/table/Table";
import ColorGradientLegend from "../components/legends/ColorGradientLegend";
import ClaritySiteHeader from "../components/header/ClaritySiteHeader";
import ClarityNetZeroIndicatorCard from "../components/cards/ClarityNetZeroIndicatorCard";
import ClarityHeader from "../components/header/ClientHeader";
import ClarityIndicator from "../components/graphs/ClarityIndicator";
import MapLeaflet from "../components/map/MapLeaflet";
import ClarityNetZeroScopeCard from "../components/cards/ClarityNetZeroScopeCard";
import ClaritySDGCard from "../components/cards/ClaritySDGCard";
import ClaritySDGHeader from "../components/header/ClaritySDGHeader";
import ClaritySDGIndicatorCard from "../components/cards/ClaritySDGIndicatorCard";
import Splashscreen from "../components/notifications/SplashScreen";
import ClarityNetZeroIDCard from "../components/cards/ClarityNetZeroIDCard";
import Page401 from "../pages/Page401";
import Page403 from "../pages/Page403";
import ClarityScopeCard from "../components/cards/ClarityScopeCard";
import {
	customStylesClarity, customStylesSearchClarity, customStylesSubFilterClarity, customStylesClaritySelect, customDatePickerStyles
} from "../components/filters/ClarityFilterStyle";
import HorizontalFilters from "../components/filters/HorizontalFilters";
import { openSaml } from "../auth/Authenticator";
import ClarityKPIsList from "../components/cards/ClarityKPIsList";
import Tabs from "../components/tabs/Tabs";
import ClarityNetZeroKPIs from "../components/cards/ClarityNetZeroKPIs";
import SimpleSelector from "../components/filters/SimpleSelector";
import SimplePieChart from "../components/graphs/SimplePieChart";
import ScoreEvolutionCard from "../components/cards/ScoreEvolutionCard";

import { isAllowedToSeeSupplyChain, isUserAlphaRole } from "../utils/helpers";
import usePrevious from "../utils/usePrevious";
import TableGSCA from "../components/table/TableGSCA";
import NonConformity from "../components/cards/NonConformity";
import SimpleRepartitionBar from "../components/cards/SimpleRepartitionBar";
import GscaSaqScoreCard from "../components/cards/GscaSaqScoreCard";
import ColorLegend from "../components/legends/ColorLegend";
import RiskAssessmentScoreCard from "../components/cards/RiskAssessmentScoreCard";
import ClarityAPScopeWrapper from "../components/cards/ClarityAPScopeWrapper";
// Create the App Slice
const appSlice = createAppSlice(appDefinition.slice,
	menuOptions.filter((option) => option.initial)[0].menu,
	clarityInitRefines,
	clarityInitDatasets);

// Function to calculate the module score based on the index
function getModuleScore(domain, data, prevData) {
	if (!domain) {
		return null;
	}

	const moduleScore = {
		score: 0, nbSites: 0, nbNC: 0, nbCNC: 0, trend: undefined
	};
	let nbSections = 0;
	let uniqueSitesArray = [];

	data.filter((module) => module?.domain === domain).forEach((section) => {
		uniqueSitesArray = Array.from(new Set([...uniqueSitesArray, ...section.uniqueSites]));
		nbSections += 1;
		moduleScore.score += section.moduleScore;
		moduleScore.nbNC += section.moduleNbNC;
		moduleScore.nbCNC += section.moduleNbCNC;
	});

	moduleScore.nbSites = uniqueSitesArray.length;

	moduleScore.score = ((moduleScore.score
        / (nbSections > 1 ? nbSections : 1)) * 100).toFixed(0);

	if (prevData?.length > 0) {
		moduleScore.trend = Number(moduleScore.score).toFixed(0) > (prevData[0].moduleScore * 100).toFixed(0)
			? "Up"
			: Number(moduleScore.score).toFixed(0) < (prevData[0].moduleScore * 100).toFixed(0)
				? "Down"
				: "";
	}
	return moduleScore;
}

// Function to get the tCO2eq of the NET ZERO Domain
function getNetZeroTCO2eq(sectionKPIsData) {
	const tCO2eq = sectionKPIsData?.reduce((previousValue, currentValue) => {
		previousValue[currentValue.moduleIndex] += currentValue.emissionValue;
		return previousValue;
	}, { NZ1: 0, NZ2: 0, NZ3: 0 });

	const totalTCO2eq = (tCO2eq?.NZ1 ?? 0) + (tCO2eq?.NZ2 ?? 0) + (tCO2eq?.NZ3 ?? 0);

	return (totalTCO2eq > 1000 ? (`${(totalTCO2eq / 1000).toFixed(0) }k`) : totalTCO2eq.toFixed(1));
}

// Function to get the good color for the donut graph in ClarityIndicator
function getIndicatorColor(activeRefines, activeDatasets, clarityDomains) {
	const getColorObject = getModuleScore(activeRefines.domain, activeDatasets.moduleScoresBis.data?.filter((e) => e.campaignId === activeRefines.campaignId), activeDatasets.moduleScoresBis.data?.filter((e) => e.campaignId === activeRefines.campaignId - 1).filter((e) => e.domain === activeRefines.domain));
	if (!getColorObject) {
		return clarityDomains[activeRefines.domain]?.domainColor;
	}
	const indicatorColor = getMarkerColor(getColorObject.score);

	return indicatorColor;
}

function Clarity() {
	// Internationalization hook
	const { t, i18n } = useTranslation();

	// Load optional URL parameters
	const urlQuery = new URLSearchParams(window.localStorage.getItem("searchParams"));
	const supplierSiteId = urlQuery.get("siteId");

	// These are the refines we use when a user arrives from "At A Glance" app. These refines are handeled
	// in 3 different useEffects.
	const atAglanceURLparams = JSON.parse(window.localStorage.getItem("ataglanceURLparams"));

	// Create and load the app slice in the store
	const store = useStore();

	// Load the store hook
	const dispatch = useDispatch();

	const screenHeight = use100vh();

	// Load ref of content section
	const SCRef = createRef();
	const topRef = createRef();
	const contentRef = createRef();
	const sitesByModulesTableRef = createRef();

	// Load the Clarity Reducer & Favicon
	useEffect(() => {
		store.injectReducer(appDefinition.slice, appSlice.reducer);
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load user
	const user = useSelector(userSelector);

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);
	const subMenu = useSelector(appSlice.selectSubPage);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const isActiveFilter = useSelector(appSlice.isRefined);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	const tableDataset = clientParameters?.supplyChain ? "siteListSupplyChain" : "siteListWithoutSupplyChain";
	const rollupGroupKey = clientParameters?.supplychainParams?.groupKey;

	// Define state for Net Zero KPI sort options
	const [netZeroKPIsortOptions, setNetZeroKPIsortOptions] = useState(defaultNetZeroSortOptions);

	// SupplyChain visibility validator
	const supplyChainVisibility = useRef(!!isUserAlphaRole(user));

	// Store previous state before reselect
	const lastClientParameters = usePrevious(clientParameters);
	const lastActiveRefines = usePrevious(activeRefines);
	const lastSupplyChain = usePrevious(activeDatasets?.supplyChain?.data);
	const lastMenu = usePrevious(menu);
	const lastSubMenu = usePrevious(subMenu);

	// const translationsFromData = activeDatasets?.clarityTranslations?.data;
	const formatedTranslations = activeDatasets?.clarityTranslations?.data.reduce((acc, translations) => {
		acc[translations._id] = { ...translations };
		return acc;
	}, {});

	const languageList = activeDatasets?.clarityTranslations?.data?.reduce((acc, translation) => {
		acc.push({ value: translation?._id?.toLowerCase(), label: translation?._id?.toUpperCase() });
		return acc;
	}, []);

	// Inject translations into the app
	useEffect(() => {
		if (formatedTranslations !== null &&
			formatedTranslations !== undefined &&
			Object.keys(formatedTranslations).length > 0) {
			Object.keys(formatedTranslations).forEach((key) => (i18n.addResourceBundle(key, "translation", formatedTranslations?.[key])));
		}
	}, [i18n, formatedTranslations, t]);

	// Load client parameters as a filter for the indicators
	useEffect(() => {
		if (!supplierSiteId) dispatch(appSlice.fetchDataset(clientInitParameters));
	},
	[activeRefines?.spaceLabel]); // eslint-disable-line react-hooks/exhaustive-deps

	// Load client parameters active indicators as an initial filter for the datasets
	// and load the first space as the initial refine for spaceLabel
	useEffect(() => {
		if (clientParameters === undefined || loadDataStatus !== "idle") {
			return;
		}

		dispatch(appSlice.actions.setInitialRefines({ domain: clientParameters?.activeIndicators }));
		// only dispatch when no siteId in Url
		if (!supplierSiteId && JSON.stringify(clientParameters?.spaceLabel) !== JSON.stringify(activeRefines?.spaceLabel)) {
			// If a user came from "At A Glance" app refine by spaceLabel used in "At A Glance".
			// The rest of "At A Glance" refines are used in the useEffect below.
			if (atAglanceURLparams?.spaceLabel?.length > 0) {
				dispatch(appSlice.actions.refine([{ key: "spaceLabel", value: atAglanceURLparams.spaceLabel }]));
				return;
			}
			dispatch(appSlice.actions.refine([
				{ key: "spaceLabel", value: clientParameters?.spaceLabel },
				{ key: "campaignId", value: clientParameters?.maxCampaignId }
			]));
		}
	},
	[clientParameters, supplierSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

	// Manage specific actions based on active refines
	useEffect(() => {
		// Set SubPages
		if (activeRefines?.siteId === undefined && menu === "HOME" && activeRefines?.domain !== undefined && activeRefines?.domain !== "NET ZERO") {
			dispatch(appSlice.actions.setSubPage("DOMAINs"));
		}
		if (activeRefines?.siteId === undefined && menu === "HOME" && activeRefines?.domain === "NET ZERO") {
			dispatch(appSlice.actions.setSubPage("NETZERO"));
		}
		if (activeRefines?.siteId === undefined && menu === "SDGs" && activeRefines?.SDG !== undefined) {
			dispatch(appSlice.actions.setSubPage("SDG"));
		}
		if (activeRefines?.siteId !== undefined) {
			dispatch(appSlice.actions.setSubPage("SITE"));
		}

		// Reset subPages if needed
		if (subMenu !== undefined
            && ((menu !== "HOME" || activeRefines?.domain === undefined)
            && (menu !== "SDGs" || activeRefines?.SDG === undefined))
            && (activeRefines?.siteId === undefined)) {
			dispatch(appSlice.actions.setSubPage(undefined));
		}

		// Reset NET ZERO marketSegment selection if another domain is selected
		if (menu === "HOME" && activeRefines?.marketSegment !== undefined && activeRefines?.domain !== "NET ZERO") {
			dispatch(appSlice.actions.refine([{ key: "marketSegment", value: undefined }]));
		}
	}, [activeRefines]); // eslint-disable-line react-hooks/exhaustive-deps

	// if query param with siteID then refine
	useEffect(() => {
		function getSpaceLabelBySiteId(claritySupplierSiteId) {
			dispatch(appSlice.fetchDataset(getClaritySupplierSiteId(
				"claritySupplierSiteId",
				[
					{
						$match: {
							siteId: parseInt(claritySupplierSiteId, 10)
						}
					}
				]
			)));

			const data = activeDatasets?.claritySupplierSiteId?.data;
			if (data?.[0]?.spaceLabel && data?.[0]?.siteId) {
				window.localStorage.removeItem("searchParams");
				if (!supplyChainVisibility.current && !isUserAlphaRole(user)) {
					supplyChainVisibility.current = isAllowedToSeeSupplyChain({
						user,
						appsCollection: ["Clarity"],
						restrictedScopeValue: "Supplier",
						projectName: data[0].spaceLabel
					});
					// eslint-disable-next-line no-unused-expressions
					!supplyChainVisibility.current && dispatch(appSlice.actions.fetchCalculatedDataset({ datasetName: "listSpaces", datasetData: [{ client: "spaceLabel", nbSpaces: 1, spaceLabel: [data[0].spaceLabel] }] }));
				}

				const { spaceLabel, siteId } = data[0];
				dispatch(appSlice.actions.refine([
					{ key: "spaceLabel", value: spaceLabel },
					{ key: "siteId", value: siteId },
					{ key: "domain", value: clientParameters?.activeIndicators }
				]));
				dispatch(appSlice.actions.setPage("SITEs"));
			} else return -1;
		}

		if (supplierSiteId) {
			if (getSpaceLabelBySiteId(supplierSiteId) === -1) {
				return <Page403/>;
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supplierSiteId, activeDatasets?.claritySupplierSiteId, clientParameters]);

	useEffect(() => {
		if (atAglanceURLparams) {
			// When a user arrives from "At A Glance" at this point we do not have to refine by spaceLabel or
			// campaignId because it has already been done above.
			// Remove the refines that may remain from the previous visit of Clarity (except spaceLabel
			// and campaignId) and apply the refines we received from "At A Glance".
			// atAglanceURLparams will be removed from localStorage in one of the useEffects below.
			const clarityRefineKeys = Object.keys(activeRefines ?? [])?.filter((e) => (e !== "spaceLabel" && e !== "campaignId"));
			dispatch(appSlice.actions.refine(clarityRefineKeys.map((e) => ({ key: e, value: undefined }))));

			const atAGlanceRefineKeys = Object.keys(atAglanceURLparams).filter((e) => (e !== "spaceLabel" && e !== "campaignId"));
			dispatch(appSlice.actions.refine(atAGlanceRefineKeys.map((e) => ({ key: e, value: atAglanceURLparams[e] }))));
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientParameters]);

	// Used by "Site Status" filter
	useEffect(() => {
		if (activeRefines?.siteActivity === "All Sites") {
			dispatch(appSlice.actions.refine([{ key: "siteActivity", value: undefined }]));
		}
	}, [activeRefines?.siteActivity]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch and refresh data in the store
	useEffect(() => {
		if (clientParameters === undefined || activeDatasets === undefined || activeRefines.spaceLabel === undefined) {
			return;
		}
		if (!isUserAlphaRole(user)) {
			supplyChainVisibility.current = isAllowedToSeeSupplyChain({
				user,
				appsCollection: ["Clarity"],
				restrictedScopeValue: "Supplier",
				projectName: clientParameters.spaceLabel[0]
			});
		}

		if (
			!isActiveFilter
		|| !isEqual(lastMenu, menu)
		|| (isActiveFilter && lastMenu && menu && isEqual(lastMenu, menu))
		|| !isEqual(lastSubMenu, subMenu)) {
			dispatch(appSlice.fetchDatasets());
		}
	}, [menu, activeRefines, isActiveFilter]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch calculated Data to be put in the store
	useEffect(() => {
		// Fetch SupplyChain in the store
		if (activeDatasets?.supplyChain?.data.length > 0) {
			// clarityV4ToRSupplyChain - It's just the data from ClarityV4ToR. The point is to get the data
			// without campaigns and scores so that SC always remains the same regardless of campaignId refine.
			const supplyChainDataWithoutCampaigns = activeDatasets?.clarityV4ToRSupplyChain?.data;
			const supplyChainDataWithCampaigns = activeDatasets?.supplyChain?.data;

			// Match the data between two collections to extract the campaign scores to add them to the collection used
			// for displaying the supply chain.
			const supplyChainDataWithScoresAndCampaigns = supplyChainDataWithoutCampaigns.map((site) => {
				const found = supplyChainDataWithCampaigns.find((e) => (
					e.auditedCompany === site.company
					&& e.tierLevel === site.tierLevel
					&& e.campaignId === activeRefines?.campaignId));
				if (found) {
					return { ...site, siteScore: found.siteScore };
				}
				return { ...site, siteScore: null };
			});

			const supplyChain = rollups(
				supplyChainDataWithScoresAndCampaigns,
				(v) => ({
					score: sum(v, (d) => activeRefines.domain === undefined ?
						(d.siteScore ? (d.siteScore * 100).toFixed(0) : -999)
						: (d.domainScores?.[d.domain?.indexOf(activeRefines.domain)]
							? (d.domainScores[d.domain.indexOf(activeRefines.domain)] * 100).toFixed(0)
							: -999)
					)
				}),
				(d) => d.finalProduct,
				(d) => d.tierLevel,
				(d) => d.targetProduct,
				(d) => typeof d.nextSupplier === "string" ? d.nextSupplier : JSON.stringify(d.nextSupplier),
				(d) => [d[rollupGroupKey]?.toString(), d.supplier, d.company, d.city, d.country,
					d.product, d.geometry, d.countryRisk, d.criticality, d.siteId, d.type, d.region, d._id,
					d.nextSupplier, d.finalProduct, d.targetProduct, d.tierLevel, d.inactive, d.domains, d.spaceLabel,
					d.siteActivity]
			);
			const rawSupplychain = {
				name: clientParameters?.client,
				children: supplyChain
				.sort((a, b) => a[0] > b[0] ? 1 : -1)
				.map((finalProduct, i) => ({
					name: finalProduct[0],
					children: getSupplychainChildren(finalProduct[1], "", 0, finalProduct[0], activeRefines?.campaignId, clientParameters?.maxCampaignId)
				}))
			};
			// Dispatch the supply chain in the store
			dispatch(
				appSlice.actions.fetchCalculatedDataset({
					datasetName: "supplyChainTree",
					datasetData: rawSupplychain
				})
			);
		}
	}, [ activeDatasets?.supplyChain ]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch calculated Data to be put in the store
	useEffect(() => {
		// Fetch total number of non conformities in the store
		const totalNonConformities = activeDatasets?.nonConformities?.data?.reduce((acc, element) => { acc.nbNC += element.nbNC; acc.nbCNC += element.nbCNC; return acc; },
			{ nbNC: 0, nbCNC: 0 });
			// Dispatch the number of non conformities in the
		if (totalNonConformities) {
			dispatch(
				appSlice.actions.fetchCalculatedDataset({
					datasetName: "totalNonConformities",
					datasetData: totalNonConformities
				})
			);
		}
	}, [activeDatasets?.nonConformities]); // eslint-disable-line react-hooks/exhaustive-deps

	// Reload window if data expired
	useEffect(() => {
		if (loadDataStatus === "sessionExpired") {
			setTimeout(() => {
				dispatch(appSlice.actions.reset());
				dispatch(requestSignIn());
				openSaml();
			}, 2000);
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	// Go top if menu has changed
	useEffect(() => {
		document?.getElementById("content-scroll")?.scroll({ top: 0, behavior: "smooth" });
	}, [menu, activeRefines?.siteId, activeRefines?.domain]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// ataglanceURLparams (refines received from At A Glance) has been used above, at this point we can delete them
		// otherwise we will be stuck with the same refines.
		if (loadDataStatus === "idle") {
			window.localStorage.removeItem("ataglanceURLparams");
		}
	}, [loadDataStatus]);

	if (loadDataStatus === "sessionExpired") {
		return <Page401 />;
	}

	// Wait for Data to render App
	// the final condition check : if the supplychain are not empty for before and after, then check the supplychain status or then check the filter status
	if (
		!activeDatasets?.listSpaces?.data?.length > 0
		|| !isEqual(clientParameters, lastClientParameters)
		|| !isEqual(activeDatasets?.supplyChain.data, lastSupplyChain)
		|| typeof clientParameters?.maxCampaignId !== "number"
		|| clientParameters?.maxCampaignId === 0
		|| clientParameters?.maxCampaignId === undefined
		|| clientParameters?.maxCampaignId === null
	) {
		return <Loading message="Wait a moment while we load your app." />;
	}

	// Accessor for the siteScore - use the domain score if a domain is selected
	const scoreAccessor = (d) => activeRefines.domain === undefined
		? (d.siteScore * 100)
		: ((d.domainScores?.[d.domain?.indexOf(activeRefines.domain)] ?? 0) * 100);

	const scoreCountryAccessor = (d) => activeRefines.domain === undefined
		? (d.countryScore * 100)
		: ((d.domainScores?.[d.domain?.indexOf(activeRefines.domain)] ?? 0) * 100);

	const geocodingPending = activeDatasets?.ClarityGSCAUploads.data?.filter((d) => !d.geometry?.coordinates)?.length > 0;

	const clarityDomainsDataset = activeDatasets?.clarityDomains.data;
	// get clarityDomains formated
	const clarityDomains = clarityDomainsDataset.reduce((acc, domain) => {
		acc[domain.name] = { ...domain };
		return acc;
	}, {});

	return (
		<div className="relative h-full w-full">

			{/*
			* Loading if data are under refining
			*/}
			<IF condition={loadDataStatus === "loading"}>
				<Loading />
			</IF>

			{/*
			* Load the splashscreen if the user has not yet set it as don't remind me
			*/}
			<Splashscreen appName="Clarity" notifKey="home" splashScreens={splashScreens}
				theme="clarity" locales={appDefinition.locales} />

			<div className="relative flex justify-between max-w-screen-3xl mx-auto h-full bg-clarity_primary-bg"
				id="full-screen">

				{/*
				* Menu Bar of the CLARITY APP
				*/}
				<SideBar
					appSlice={appSlice}
					appDefinition={appDefinition}
					// eslint-disable-next-line max-len
					menuOptions={menuOptions.filter((option) =>	((option.menu !== "SC" && option.menu !== "HOME") || (option.menu === "SC" && clientParameters?.supplyChain && supplyChainVisibility.current) || (option.menu === "HOME" && supplyChainVisibility.current))
					&& (option.menu !== "KPIs" || clientParameters?.kpis)
					&& (option.menu !== "TRACEABILITY" || clientParameters?.productTraceability)
					&& (option.menu !== "GSCA" || clientParameters?.riskAssessment)
					)}
					menu={menu}
					colorMenu="#CCDB73"
					colorAccent="#5FC3BF"
					indicator={{ menu: "NCs", value: activeDatasets?.totalNonConformities?.data?.nbCNC ?? 0 }}
					locales={appDefinition.locales}
					langListValue={languageList}/>

				{/*
				* Content of the CLARITY APP
				*/}
				<div id="content-scroll" className="w-full overflow-y-auto"
					style={{ height: screenHeight }}>
					<div ref={topRef} className="flex-grow md:pl-24">
						{/*
					  * SDGs and HOME pages
					  */}
						<IF condition={((menu === "HOME" && activeRefines.domain === undefined) || (menu === "SDGs" && activeRefines.SDG === undefined))
                         && activeRefines.siteId === undefined}>

							<div className="full-screen-hide">
								<ClarityHeader
									clientParameters={clientParameters}
									appDefinition={appDefinition}
								/>
							</div>

							<div className="grid grid-cols-1 grid-flow-row gap-y-1 px-4 md:px-8 pt-4 md:grid-cols-2 md:gap-x-8 2xl:px-12 full-screen-hide mb-6">
								<IF condition={menu === "HOME"}>
									<div className="mb-6 md:mb-10 mt-2 md:col-span-2 pt-4">
										<p className="text-4xl font-bold mb-1">{t("homePage.header.welcome")} {user.firstName},</p>
										<div className="flex justify-between">
											<p className="pt-1 md:text-lg w-full md:w-2/3 font-light leading-6">
												{t("homePage.header.introduction")}
											</p>
											<IF condition={(menu === "SDGs") && clientParameters?.supplyChain && supplyChainVisibility.current}>
												<div className="hidden md:flex items-center text-clarity_primary-accent rounded-lg shadow-lg
													transition-all duration-700 cursor-pointer bg-white text-sm font-semibold px-4 h-14"
												onClick={() => SCRef.current.scrollIntoView({ behavior: "smooth", block: "start" }) }>
													{t("homePage.header.supplyChainButton")}
													<ArrowDownwardIcon fontSize="small" className="pl-1 animate-bounce" />
												</div>
											</IF>
										</div>
									</div>

									<div className="w-full md:col-span-1">
										<TitleCard titleLocal="homePage.discover.title" subTitleLocal="homePage.discover.subTitle"
											infoLocal="homePage.discover.info" locales={appDefinition.locales} />
									</div>
									<div className="w-40 md:col-span-1 justify-self-end">
										<div onClick={() => {
											sitesByModulesTableRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
										}}
										className="hidden md:flex flex-shrink-0 items-center text-clarity_primary-accent rounded-lg shadow-lg
												   transition-all duration-700 cursor-pointer bg-white text-sm font-semibold px-4 h-11">
											{t("homePage.sitesByDomains.seeScoresTable")}
											<ArrowDownwardIcon fontSize="small" className="pl-1  animate-bounce" />
										</div>
									</div>
									<div className="relative flex flex-wrap gap-2 md:col-span-2 2xl:gap-4">
										{clientParameters?.activeIndicators && clientParameters?.activeIndicators.map((indicator, i) => {
											const moduleScore = getModuleScore(indicator, activeDatasets.moduleScoresBis?.data.filter((e) => e.campaignId === activeRefines.campaignId), activeDatasets.moduleScoresBis.data?.filter((e) => e.campaignId === activeRefines.campaignId - 1).filter((e) => e.domain === indicator));
											return (
												<>
													<IF condition={indicator === "NET ZERO"}>
														<ClarityNetZeroIndicatorCard key={`indicator ${i}`} appSlice={appSlice}
															moduleKey={indicator} module={clarityDomains[indicator]}
															moduleScore={moduleScore} locales={appDefinition.locales} />
													</IF>
													<IF condition={clientParameters?.riskAssessment}>
														{(indicator === "German Supply Chain Act" && activeDatasets.gscaRiskData?.data.length > 0) &&
														// eslint-disable-next-line radix
														<RiskAssessmentScoreCard appSlice={appSlice} locales={appDefinition.locales} />
														}
														{/* If there are no scores for German Supply Chain Act -> show pieChart (No SAQ Score Card) */}
														{(indicator === "German Supply Chain Act" && activeDatasets.gscaRiskData?.data.length === 0) &&
														<div className="rounded-xl bg-gradient-to-r from-clarity_primary-gradientL to-clarity_primary-gradientR
														cursor-pointer hover:scale-[1.01] hover:ease-out duration-300"
														title={t("GSCAPage.riskOverview.navigateToGSCARiskTable")}
														onClick={() => dispatch(appSlice.actions.setPage("GSCA"))}>
															<div className="relative">
																<p className="font-bold pt-4 text-md text-center px-[18px] relative z-500 tracking-tight">
																Suppliers Risk Assessment
																</p>
																<img src="/images/Clarity/GSCAHandshakeIcon.svg" alt="gsca_handshake_icon"
																	className="absolute left-[92px] top-[8px] h-9"></img>
															</div>
															<p className="text-[11px] text-center mt-1">
																{t("GSCAPage.riskOverview.breakdown")}
															</p>
															<div className="flex justify-center -mt-4">
																<SimplePieChart appSlice={appSlice}
																	width={140}
																	height={140}
																	donutThickness={15}
																	pieValue={activeDatasets?.gscaOverallRisksBreakdown?.data}
																	pieColors={gscaColorScale}
																	hasLegend={false}
																	total={activeDatasets?.gscaOverallRisksBreakdown.data?.[0]?.nbSuppliers}
																	unit=""
																	caption=""
																	accFrequencyKey="count"
																	accLabelKey="riskCategory"
																	colorHasScale
																	accColorKey="color"
																	hasLabel={true}
																	labelColor="white"
																	convertToKs
																	hasTotal
																	hoverAnimate
																	styles={
																		{
																			fontSize: 25,
																			fontWeight: 600,
																			backgroundColor: "bg-[#c2d6ad] bg-opacity-0",
																			legendDisplay: "column"
																		}
																	}
																/>
															</div>
															<p className="font-bold text-md text-center -mt-3">
																{t("GSCAPage.riskOverview.suppliers")}
															</p>
														</div>
														}
														{/* END No SAQ Score card */}
													</IF>
													<IF condition={indicator !== "NET ZERO"}>
														<ClarityIndicatorCard key={`indicator ${i}`} appSlice={appSlice}
															moduleKey={indicator} module={clarityDomains[indicator]}
															moduleScore={moduleScore} locales={appDefinition.locales} />
													</IF>
												</>
											);
										})}
										{clientParameters?.pushedIndicators && clientParameters?.pushedIndicators.map((indicator, i) => (
											<ClarityIndicatorCard key={`indicator ${i}`} moduleKey={indicator} appSlice={appSlice}
												module={clarityDomains[indicator]} isPushed locales={appDefinition.locales} />
										))}

									</div>
								</IF>
								<IF condition={ menu === "SDGs" && activeRefines.SDG === undefined }>
									<div className="w-full mb-6 md:mb-10 mt-2 md:col-span-2 pt-4">
										<p className="text-4xl font-bold mb-1">{t("SDGsPage.headerSDG.title")}</p>
										<p className="w-full pt-1 text-lg md:w-2/3 font-light leading-6">
											{t("SDGsPage.headerSDG.description")}
										</p>
									</div>
									<div className="w-full md:col-span-2">
										<TitleCard titleLocal="SDGsPage.sdgReporting.title" subTitleLocal="SDGsPage.sdgReporting.subTitle"
											infoLocal="SDGsPage.sdgReporting.info" locales={appDefinition.locales} />
									</div>
									<div className="relative flex flex-wrap gap-2 md:gap-4 md:col-span-2 ">
										{activeDatasets?.listSDGs?.data && activeDatasets.listSDGs.data.map((SDG, i) => {
											const SDGScore = activeDatasets.SDGsScores?.data?.find((SDGScore) => SDGScore.SDG === SDG.SDG)?.SDGScore;
											return (
												<ClaritySDGCard key={`sdgOption ${i}`} appSlice={appSlice} appDefinition={appDefinition}
													SDG={SDG.SDG} SDGScore={SDGScore * 100} SDGOption={sdgsOptions[SDG.SDG]} />
											);
										})}
									</div>
								</IF>

								<div className="w-full mt-6 md:mt-10 md:col-span-1">
									<TitleCard titleLocal="homePage.details.title" subTitleLocal="homePage.details.subTitle"
										infoLocal="homePage.details.info" locales={appDefinition.locales} />
								</div>
								<div className="flex w-full md:col-span-2">
									<div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full h-full md:space-x-2 ">
										<div className="w-full bg-white rounded-lg p-2 px-6 py-4" >
											<NonConformitiesCard appSlice={appSlice}
												nonConformities={nonConformities} dataset="nonConformities"
												colorScale={monthAnalysisNCLinesColors} maturityColorScale={clientParameters?.maturityColors}
												locales={appDefinition.locales}/>
										</div>
										<div className="relative flex flex-col w-full h-full overflow-hidden rounded-lg">
											<MapLeaflet
												appSlice={appSlice}
												dataset="sites"
												datasetCountries="countries"
												scoreAccessor={scoreAccessor}
												countryScoreAccessor={scoreCountryAccessor}
												fieldsTooltip={clarityMapTooltip}
												mapType="jawgTerrain"
												minZoom={2}
												getMarkerColor={getMarkerColor}
												customTooltip={ClarityTooltip}
												mapHeight={440}
												locales={appDefinition.locales}
											/>
											<div className="absolute z-500 bottom-[40px] self-center flex flex-row -mb-[24px] w-auto">
												<ColorLegend
													locales={appDefinition.locales}
													colorLegend={colorsAndBreakdowns}
													scale="scale-100"
													legendFontSize="text-8"
													indicatorFontSize={12}
												/>
											</div>
										</div>
									</div>
								</div>
								{/* If the project does not have any active indicators it means that active indicators array will include only one empty object.
									Without active indicators this table is irrelevant.
								*/}
								<IF condition={ menu === "HOME" && Object.keys(clientParameters?.activeIndicators[0]).length !== 0}>
									{/* TABLE SITES BY DOMAINS */}
									<div ref={sitesByModulesTableRef} className="w-full mt-6 md:mt-10 md:col-span-2 ">
										<TitleCard titleLocal="homePage.sitesByDomains.title" subTitleLocal="homePage.sitesByDomains.subTitle"
											infoLocal="homePage.sitesByDomains.info" locales={appDefinition.locales} />
									</div>
									<div className="flex w-full md:col-span-2">
										<div className="flex flex-col w-full alphaBlur rounded-lg py-4">
											<Table
												appSlice={appSlice}
												dataset={clientParameters?.supplyChain ? "sitesByModulesSupplyChain" : "sitesByModulesWithoutSupplyChain"}
												structureTable={sitesPerModulesTableStructure}
												sortByRefineField="supplier"
												themePalette="clarity"
												locales={appDefinition.locales}
												enableDispatch={(row) => true}
												exportFileName={`Clarity_${Array.isArray(activeRefines?.spaceLabel)
													? activeRefines?.spaceLabel[0] : activeRefines?.spaceLabel}_${new Date().toISOString().split("T")[0]}`}
												headerStyle="flex space-x-2 justify-center"
												translateHeaders={true}
											/>
										</div>
									</div>
								</IF>
							</div>

							<IF condition={(menu === "HOME" || menu === "SC") && clientParameters?.supplyChain && supplyChainVisibility.current}>
								<Div100vh id="supplyChain" ref={SCRef}
									className="relative flex flex-col md:col-span-2 max-h-full pt-3 md:pt-5 px-4 md:px-8 2xl:px-12">
									<div className="flex justify-between items-baseline font-semibold  full-screen-hide pt-3 md:pt-5">
										<div className="flex flex-col">
											<TitleCard titleLocal="homePage.supplyChain.title" subTitleLocal="homePage.supplyChain.subTitle"
												infoLocale="" locales={appDefinition.locales} />
										</div>
										<div onClick={() => {
											topRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
										}}
										className="hidden md:flex flex-shrink-0 items-center text-clarity_primary-accent rounded-lg shadow-lg
												   transition-all duration-700 cursor-pointer bg-white text-sm font-semibold px-4 h-14">
											{t("homePage.supplyChain.goTopButton")}
											<ArrowUpwardIcon fontSize="small" className="pl-1  animate-bounce" />
										</div>
									</div>
									<div className="relative flex-grow  w-full rounded-xl alphaBlur"
										style={{ height: (screenHeight - 200) }}>
										<div id="svg-scroll" className="w-full h-full overflow-x-auto overflow-y-auto no-scrollbar">
											{activeDatasets?.supplyChain?.data?.length > 0 &&
											<ParentSize debounceTime={10}>
												{(parent) => (
													<TreeNodesGraph
														appDefinition={appDefinition}
														appSlice={appSlice}
														dataset={"supplyChain"}
														datasetTree="supplyChainTree"
														datasetFinalProduct="supplyChainAllFinalProducts"
														margin={{
															top: 20, left: 0, right: 0, bottom: 180
														}}
														subTreeMargin = {{
															top: 56, left: -130, right: 0, bottom: 0
														}}
														refineHighlightKeys={[
															"siteId",
															"product",
															"region",
															"country"
														]}
														refineSiteKeys={["siteId"]}
														fieldsToExport={["supplier", "auditedCompany",
															"address", "city", "country", "region",
															"finalProduct", "product", "tierLevel"]}
														onlyAudited
														getMarkerColor={getMarkerColor}
														height={parent.height}
														width={parent.width}
														titleCase={false}
													/>
												)}
											</ParentSize>
											}
										</div>
									</div>
									<p>&nbsp;</p>
								</Div100vh>
							</IF>

						</IF>

						{/*
                        * SUPPLY CHAIN PAGE
                        */}
						<IF condition={menu === "SC" && supplyChainVisibility.current && clientParameters.supplyChain}>

							<ClarityPageHeader
								appSlice={appSlice}
								page="SCPage"
								Picto={menuOptions.filter((option) => option.menu === menu)[0]?.picto}
								locales={appDefinition.locales} />

							<div className="px-4 md:px-8 -mt-16 mb-6 2xl:px-12">
								<div className="relative flex-grow  w-full rounded-xl alphaBlur "
									style={{ height: `calc(${screenHeight}px - 280px)` }}>
									<div id="svg-scroll" className="w-full h-full overflow-x-auto overflow-y-auto no-scrollbar">
										{activeDatasets?.supplyChain?.data?.length > 0 &&
											<ParentSize debounceTime={10}>
												{(parent) => (
													<TreeNodesGraph
														appDefinition={appDefinition}
														appSlice={appSlice}
														dataset={"supplyChain"}
														datasetTree="supplyChainTree"
														datasetFinalProduct="supplyChainAllFinalProducts"
														margin={{
															top: 20, left: 0, right: 0, bottom: 180
														}}
														subTreeMargin = {{
															top: 56, left: -130, right: 0, bottom: 0
														}}
														refineHighlightKeys={[
															"siteId",
															"product",
															"region",
															"country"
														]}
														refineSiteKeys={["siteId"]}
														fieldsToExport={["supplier", "auditedCompany",
															"address", "city", "country", "region",
															"finalProduct", "product", "tierLevel", "tierLabel",
															"siteScore", "sumNbOpenedNc"]}
														onlyAudited
														getMarkerColor={getMarkerColor}
														height={parent.height}
														width={parent.width}
														titleCase={false}
													/>
												)}
											</ParentSize>
										}
									</div>
								</div>
							</div>
						</IF>

						{/*
                        * MY KPIs
                        */}
						<IF condition={menu === "KPIs" && clientParameters.kpis}>
							<ClarityPageHeader
								appSlice={appSlice}
								page="KPIsPage"
								Picto={menuOptions.filter((option) => option.menu === menu)[0]?.picto}
								locales={appDefinition.locales} />

							<div className="flex flex-col px-4 md:px-8  mb-6 2xl:px-12">
								<ClarityKPIsList appSlice={appSlice} dataset="BVKPIs" locales={appDefinition.locales}
									filterStyle={customStylesClaritySelect}/>
							</div>

						</IF>

						{/*
                        * DISTRIBUTION PAGE
                        */}
						<IF condition={menu === "DISTRIB" && activeRefines.siteId === undefined}>

							<ClarityPageHeader
								appSlice={appSlice}
								page="DistribPage"
								Picto={menuOptions.filter((option) => option.menu === menu)[0]?.picto}
								locales={appDefinition.locales} />

							<div className="grid grid-cols-1 grid-flow-row gap-y-4 pt-4 px-4 md:px-8 -mt-16 mb-16
                        md:grid-cols-2 md:gap-x-8 2xl:px-12">
								<div className="flex flex-col space-y-4">
									<div className="flex flex-col alphaBlur rounded-lg px-6 py-4 ">
										<TitleCard titleLocal="DistribPage.distribPerSites.title"
											subTitleLocal="DistribPage.distribPerSites.subTitle"
											infoLocale="" locales={appDefinition.locales} />
										<ParentSize>
											{(parent) => (
												<ColumnGraph
													appSlice={appSlice}
													dataset={"siteScores"}
													color={"#1876bd"}
													width={parent.width}
													strokeWidth={8}
													gradientStartBarColor="#CBDA72"
													gradientEndBarColor="#6E8F57"
													nonConformities
													sortScoreDimension
													margin={{
														top: 20,
														left: 10,
														right: 40,
														bottom: 20
													}}
													getScore = {(d) => d.siteScore }
													getModule = {(d) => d.auditedCompany}
													getNC = {(d) => d.sumNbCnc}
													// getNCbis = {(d) => d.sumNbOpenedNc}
													sortData = {
														(a, b) => (a.siteScore > b.siteScore) ? 1 : -1
													}
													refineKeys={["siteId"]}
													locales={appDefinition.locales}
												/>
											)}
										</ParentSize>
									</div>
								</div>
								<div className="flex flex-col space-y-4 w-full">
									<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 ">
										<TitleCard titleLocal="DistribPage.distribPerCountries.title"
											subTitleLocal="DistribPage.distribPerCountries.subTitle"
											infoLocale="" locales={appDefinition.locales} />
										<ParentSize>
											{(parent) => (
												<ColumnGraph
													appSlice={appSlice}
													dataset={"countryScores"}
													color={"#1876bd"}
													width={parent.width}
													strokeWidth={8}
													gradientStartBarColor="#CBDA72"
													gradientEndBarColor="#6E8F57"
													nonConformities
													sortScoreDimension
													getScore = {(d) => d.siteScore }
													getModule = {(d) => d.country}
													getNC = {(d) => d.sumNbCnc}
													sortData = {
														(a, b) => (a.siteScore > b.siteScore) ? 1 : -1
													}
													refineKeys={["country"]}
													locales={appDefinition.locales}
												/>
											)}
										</ParentSize>
									</div>
								</div>
								<div className="flex flex-col space-y-4 w-full">
									<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 ">
										<TitleCard titleLocal="DistribPage.topTenQuestions.title"
											subTitleLocal="DistribPage.topTenQuestions.subTitle"
											infoLocale="" locales={appDefinition.locales} />
										<ParentSize>
											{(parent) => (
												<ColumnGraph
													appSlice={appSlice}
													dataset={"topTenQuestions"}
													color={"#1876bd"}
													width={parent.width}
													strokeWidth={8}
													gradientStartBarColor="#CBDA72"
													gradientEndBarColor="#5F9EA0"
													nonConformities
													sortScoreDimension
													margin={{
														top: 20,
														left: 10,
														right: 40,
														bottom: 20
													}}
													getScore = {(d) => d.sumNbCnc }
													getModule = {(d) => d.question}
													getNC = {(d) => Math.round(d.cncVsAnswerTotal * 100)}
													// getNCbis = {(d) => d.sumNbOpenedNc}
													sortData = {
														(a, b) => (a.sumNbCnc > b.sumNbCnc) ? 1 : -1
													}
													refineKeys={["question"]}
													locales={appDefinition.locales}
													bubbleColor="white"
													bubbleTextColor="black"
													bubblePercent={true}
													getColor={(d) => clarityDomains[d.dm]?.domainColor}
													colorsByModule={true}
													legend={true}
													scoreScaleMax
													niceScale={false}
												/>
											)}
										</ParentSize>
									</div>
								</div>
								<div className="flex flex-col space-y-4 w-full">
									<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 ">
										<TitleCard titleLocal="DistribPage.assessmentProgress.title"
											subTitleLocal="DistribPage.assessmentProgress.subTitle"
											infoLocale="" locales={appDefinition.locales} />
										<ParentSize>
											{(parent) => (
												<ColumnGraph
													appSlice={appSlice}
													dataset={"progressScores"}
													color={"#1876bd"}
													width={parent.width}
													strokeWidth={8}
													gradientStartBarColor="#CBDA72"
													gradientEndBarColor="#6E8F57"
													nonConformities
													sortScoreDimension
													getScore = {(d) => d.progressPercentAssessments}
													getModule = {(d) => d.country}
													sortData = {
														(a, b) => (a.progressPercentAssessments > b.progressPercentAssessments) ? -1 : 1
													}
													refineKeys={["country"]}
													locales={appDefinition.locales}
												/>
											)}
										</ParentSize>
									</div>
								</div>
								<IF condition={clientParameters?.productTraceability}>
									<div className="flex flex-col space-y-4 w-full">
										<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 ">
											<TitleCard titleLocal="DistribPage.distribPerSpecies.title"
												subTitleLocal="DistribPage.distribPerSpecies.subTitle"
												infoLocale="" locales={appDefinition.locales} />
											<ParentSize>
												{(parent) => (
													<ColumnGraph
														appSlice={appSlice}
														dataset={"auchanWoodSpeciesDistribution"}
														color={"#1876bd"}
														width={parent.width}
														strokeWidth={8}
														gradientStartBarColor="#CBDA72"
														gradientEndBarColor="#6E8F57"
														sortScoreDimension
														scoreScaleMax
														getScore = {(d) => d.producedQty}
														getModule = {(d) => d.nameOfTimberSpecies}
														// getNC = {(d) => d.producedQty}
														// sortData = {
														// 	(a, b) => (scoreAccessor(a) > scoreAccessor(b)) ? 1 : -1
														// }
														refineKeys={["nameOfTimberSpecies"]}
														locales={appDefinition.locales}
													/>
												)}
											</ParentSize>
										</div>
									</div>
									<div className="flex flex-col space-y-4 w-full">
										<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 ">
											<TitleCard titleLocal="DistribPage.distribPerWoodCountries.title"
												subTitleLocal="DistribPage.distribPerWoodCountries.subTitle"
												infoLocale="" locales={appDefinition.locales} />
											<ParentSize>
												{(parent) => (
													<ColumnGraph
														appSlice={appSlice}
														dataset={"auchanWoodCountriesDistribution"}
														color={"#1876bd"}
														width={parent.width}
														strokeWidth={8}
														gradientStartBarColor="#CBDA72"
														gradientEndBarColor="#6E8F57"
														sortScoreDimension
														scoreScaleMax
														getScore = {(d) => d.producedQty}
														getModule = {(d) => d.countryOfOrigin}
														refineKeys={["countryOfOrigin"]}
														locales={appDefinition.locales}
													/>
												)}
											</ParentSize>
										</div>
									</div>
								</IF>
								<IF condition={activeDatasets.listDimensions.data.some((dimension) => dimension.domain === "NET ZERO")}>
									{
										activeDatasets?.netZeroCO2ScopesPerSites?.data[0]?.scopes?.map((scope, i) => <>
											<div className="flex flex-col space-y-4 w-full">
												<div className="flex flex-col rounded-xl
          gap-y-4 pl-3 pt-4 border border-white z-20 bg-clarity_primary-netZero text-white ">
													<TitleCard titleLocal={`DistribPage.distribNetZero.titleScope${i + 1}`}
														subTitleLocal="DistribPage.distribNetZero.subtitleSite"
														infoLocale="" locales={appDefinition.locales} />
													<ParentSize>
														{(parent) => (
															<ColumnGraph
																appSlice={appSlice}
																dataset={"netZeroCO2perSite"}
																color={"#1876bd"}
																textColor="white"
																width={parent.width}
																strokeWidth={8}
																backgroundBarColor="#4C938F"
																gradientStartBarColor="#FFC362"
																gradientEndBarColor="#CC7E00"
																avgLineColor="white"
																sortScoreDimension
																scoreScaleMax
																getScore = {(d) => {
																	if (d.moduleIndex === `NZ${ i + 1}`) {
																		return d.value;
																	}
																}}
																getModule = {(d) => d.auditedCompany}
																sortData = {
																	(a, b) => (a.value > b.value) ? 1 : -1
																}
																filterData={(d) => d.moduleIndex === `NZ${ i + 1}`}
																refineKeys={["siteId"]}
																locales={appDefinition.locales}
															/>
														)}
													</ParentSize>
												</div>
											</div>
											<div className="flex flex-col space-y-4 w-full">
												<div className="flex flex-col rounded-xl
          										gap-y-4 pl-3 pt-4 border border-white z-20 bg-clarity_primary-netZero text-white ">
													<TitleCard titleLocal={`DistribPage.distribNetZero.titleScope${i + 1}`}
														subTitleLocal="DistribPage.distribNetZero.subtitleCountry"
														infoLocale="" locales={appDefinition.locales} />
													<ParentSize>
														{(parent) => (
															<ColumnGraph
																appSlice={appSlice}
																dataset={"netZeroCO2perCountry"}
																color={"#1876bd"}
																textColor="white"
																backgroundBarColor="#4C938F"
																gradientStartBarColor="#FFC362"
																gradientEndBarColor="#CC7E00"
																avgLineColor="white"
																width={parent.width}
																strokeWidth={8}
																sortScoreDimension
																scoreScaleMax
																getScore = {(d) => {
																	if (d.moduleIndex === `NZ${ i + 1}`) {
																		return d.sumEmissions;
																	}
																}}
																getModule = {(d) => d.country}
																sortData = {
																	(a, b) => (a.sumEmissions > b.sumEmissions) ? 1 : -1
																}
																filterData={(d) => d.moduleIndex === `NZ${ i + 1}`}
																refineKeys={["country"]}
																locales={appDefinition.locales}
															/>
														)}
													</ParentSize>
												</div>
											</div>
										</>) || <div/>
									}

								</IF>
							</div>
						</IF>

						{/*
					  * NON CONFORMITIES PAGE
					  */}
						<IF condition={menu === "NCs"}>

					  <div ref={contentRef}>
								<ClarityPageHeader
									appSlice={appSlice}
									page="NCsPage"
									Picto={menuOptions.filter((option) => option.menu === menu)[0]?.picto}
									locales={appDefinition.locales}
								>
									<div className="flex items-center alphaBlur rounded-xl px-4 py-2 -mr-4 2xl:mr-0">
										<NonConformitiesCard appSlice={appSlice}
											nonConformities={nonConformities} dataset="nonConformities"
											synthesis locales={appDefinition.locales}/>
									</div>
								</ClarityPageHeader>
							</div>
							<div className="grid grid-cols-1 grid-flow-row gap-y-4 pt-4 px-4 md:px-8 -mt-16 mb-16
                        md:grid-cols-3 md:gap-x-8 2xl:px-12">
								<div className="flex flex-col space-y-4 w-full  md:col-span-2">
									<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 ">
										<ListOfNonConformities
											appSlice={appSlice}
											appNCConfig={{
												nonConformities,
												listNonConformityQuery,
												nonConformityDataset: ClarityCheckpoints,
												updateListNonConformities,
												listTagsNonConformities: (clientParameters?.trackActionPlan ? { ...listTagsNonConformities, ...listNCAPTags } : listTagsNonConformities),
												customStylesSubFilter: customStylesSubFilterClarity,
												filterOptions: filterOptions.filter((option) => option.label === "Sections" ||
													option.label === "Subsections" || option.label === "Maturities"
													|| option.label === "NCStatus" || option.label === "Plan Item Delayed")
											}}
											forceObservation
											observations = {[{
												locale: "observation",
												accessorResult: (d) => d.answer,
												accessorObs: (d) => d.observation,
												picto: ChatIcon
											}, {
												locale: "actionPlanned",
												accessorActionPlanned: (d) => d.correctiveAction,
												picto: ForwardIcon,
												pictoColor: ""
											}]}
											dataset="listNonConformities"
											totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
											contentRef={contentRef}
											themePalette="clarity"
											locales={appDefinition.locales}
											activeDataSets={activeDatasets} />
									</div>
								</div>
								<div className="flex flex-col space-y-4 w-full order-first md:order-last">
									<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 space-y-8">
										<TitleCard titleLocal="NCsPage.byModules.title" subTitleLocal="" small
											infoLocal="NCsPage.byModules.info" locales={appDefinition.locales} />
										<NCByModules appSlice={appSlice} dataset="nonConformitiesByModules"
											domainsOptions={clarityDomains} locales={appDefinition.locales} translateDomainName={true} />
									</div>
								</div>
							</div>
						</IF>

						{/*
					  * TRACEABILITY PAGE
					  */}
						<IF condition={menu === "TRACEABILITY"}>

							<ClarityPageHeader
								appSlice={appSlice}
								page="TraceabilityPage"
								Picto={menuOptions.filter((option) => option.menu === menu)[0]?.picto}
								locales={appDefinition.locales} />

							<div className="-mt-16 mb-16 px-4 md:px-8 2xl:px-12">
								<div className="flex flex-col w-full alphaBlur rounded-lg py-4">
									<Table
										appSlice={appSlice}
										pageTable="TraceabilityPage"
										dataset="auchandWoodProductTable"
										structureTable={auchanWoodTableStructure}
										sortByRefineFields={[{ id: "productCommercialName", desc: true }]}
										refineKeys={(row) => [{ key: "productCommercialName", value: row.original.productCommercialName }]}
										themePalette="clarity"
										excelLocales="TraceabilityPage.table"
										locales={appDefinition.locales} />
								</div>
							</div>
						</IF>
						{/*
					  * GSCA data PAGE
					  */}
						<IF condition={menu === "GSCA"}>
							<ClarityPageHeader
								appSlice={appSlice}
								page="GSCAPage"
								Picto={menuOptions.filter((option) => option.menu === menu)[0]?.picto}
								locales={appDefinition.locales} />

							<div className="mt-10 mb-16 px-4 md:px-8 2xl:px-12">
								{/* If there is no GSCA risk data we show the table with the message
								that the data is not available. The div with the message is inside of
								TableGSCA component. */}
								{activeDatasets?.gscaRiskData?.data.length > 0 &&
								<>
									<div className="-mt-4">
										<TitleCard titleLocal="GSCAPage.riskOverview.title" subTitleLocal="GSCAPage.riskOverview.subTitle" small
											infoLocal="NCsPage.byModules.info" locales={appDefinition.locales} />
									</div>
									<div className="flex w-full alphaBlur rounded-lg justify-evenly gap-10 py-5 px-12
									bg-gradient-to-r from-clarity_primary-gradientL to-clarity_primary-gradientR mb-4">
										<div className="rounded-xl bg-[#c2d6ad] w-1/2">
											<p className="font-bold pt-3 pl-5 text-[14px]">
												{t("GSCAPage.riskOverview.breakdown")}
											</p>
											<ParentSize debounceTime={10}>
												{(parent) => (
													<div className="flex justify-center">
														<SimplePieChart appSlice={appSlice}
															width={180}
															height={180}
															pieValue={activeDatasets?.gscaOverallRisksBreakdown?.data}
															pieColors={gscaColorScale}
															hasLegend={true}
															total={activeDatasets?.gscaOverallRisksBreakdown.data?.[0]?.nbSuppliers}
															unit=""
															caption=""
															accFrequencyKey="count"
															accLabelKey="riskCategory"
															colorHasScale
															accColorKey="color"
															hasLabel={true}
															labelColor="white"
															convertToKs
															hasTotal
															hoverAnimate
															styles={
																{
																	fontSize: 40,
																	fontWeight: 600,
																	backgroundColor: "bg-[#c2d6ad]",
																	legendDisplay: "column"
																}
															}
														/>
													</div>
												)}
											</ParentSize>
										</div>
										<div className="flex flex-col gap-4 w-full">
											<div className="rounded-xl bg-[#c2d6ad]/75 px-5">
												<p className="font-bold pt-3 pl-5 text-[14px] -mb-2">
													{t("GSCAPage.riskOverview.envRiskBreakdown")}
												</p>
												<SimpleRepartitionBar
													appSlice={appSlice}
													dataset="gscaEnvRiskDistribution"
													accLabel="riskCategory"
													colorScale={gscaColorScale}
													locales={appDefinition.locales} />
											</div>
											<div className="rounded-xl bg-[#c2d6ad]/75 px-5">
												<p className="font-bold pt-3 pl-5 text-[14px] -mb-2">
													{t("GSCAPage.riskOverview.hRightsRiskBreakdown")}
												</p>
												<SimpleRepartitionBar
													appSlice={appSlice}
													dataset="gscaHRRiskDistribution"
													accLabel="riskCategory"
													colorScale={gscaColorScale}
													locales={appDefinition.locales} />
											</div>
										</div>
									</div>
								</>
								}
								{activeDatasets?.ClarityGSCAUploads.data?.filter((d) => d)?.length > 0 && <div className="relative flex flex-col w-full h-full overflow-hidden rounded-lg col-span-2 my-4">
									<MapLeaflet
										appSlice={appSlice}
										dataset="ClarityGSCAUploads"
										scoreAccessor={(d) => d.riskCombinationLabel}
										fieldsTooltip={clarityMapTooltip}
										mapType="jawgTerrain"
										minZoom={2}
										getMarkerColor={getGSCAMarkerColor}
										customTooltip={ClarityGSCATooltip}
										mapHeight={440}
										locales={appDefinition.locales}
										warningOverlay={geocodingPending}
										warningMsg="Geocoding is pending."
									/>
								</div>}
								<div className="flex flex-col w-full alphaBlur rounded-lg py-4">
									<TableGSCA
										appSlice={appSlice}
										dataset={"gscaRiskData"}
										structureTable={gscaTableStructure}
										sortByRefineField="supplier"
										themePalette="clarity"
										locales={appDefinition.locales}
										enableDispatch={(row) => {
											// If there are no risks -> show tooltip and do not redirect to the site page.
											if (!row.original?.riskCombination) {
												return false;
											}
											return true;
										}}
									/>
								</div>
							</div>
						</IF>
						{/*
					  * SITES PAGE
					  */}
						<IF condition={menu === "SITEs" && activeRefines.siteId === undefined}>

							<ClarityPageHeader
								appSlice={appSlice}
								page="SitesPage"
								Picto={menuOptions.filter((option) => option.menu === menu)[0]?.picto}
								locales={appDefinition.locales} />

							<div className="-mt-16 mb-16 px-4 md:px-8 2xl:px-12">
								<div className="flex flex-col w-full alphaBlur rounded-lg py-4">
									<Table
										appSlice={appSlice}
										dataset={tableDataset}
										structureTable={tableStructure}
										sortByRefineField="auditedCompany"
										themePalette="clarity"
										locales={appDefinition.locales}
										enableDispatch={(row) => {
											// If there are no scores -> show tooltip and do not redirect to the site page.
											if (!row.original?.domainScores) {
												return false;
											}
											return true;
										}}
									/>
								</div>
							</div>
						</IF>

						{/*
					  * INFO PAGE
					  */}
						<IF condition={menu === "INFO"}>

						 <ClarityPageHeader
								appSlice={appSlice}
								page="InfoPage"
								Picto={menuOptions.filter((option) => option.menu === menu)[0]?.picto}
								locales={appDefinition.locales} />

							<div className="flex flex-col w-full justify-center -mt-12 mb-12 px-4 md:px-8 space-y-8 2xl:px-12">
								<InfoCard infoKey="info1" locales={appDefinition.locales} />
								<InfoCard infoKey="info2" locales={appDefinition.locales} />
								<InfoCard infoKey="info3" locales={appDefinition.locales} />
							</div>
						</IF>

						{/*
					  * TERMS PAGE
					  */}
						<IF condition={menu === "TERMs"}>

							<ClarityPageHeader
								appSlice={appSlice}
								page="TermsPage"
								Picto={GavelIcon}
								locales={appDefinition.locales} />

							<div className="flex flex-col w-full justify-center -mt-12 mb-12 px-4 md:px-8 space-y-8 2xl:px-12">
								<Tabs tabSet={termsTabSet} locales={appDefinition.locales}/>
							</div>
						</IF>

						{/*
                    * DOMAIN SELECTED PAGE (EXCLUDING NET ZERO)
                    */}
						<IF condition={menu === "HOME" && activeRefines.siteId === undefined
                            && activeRefines.domain !== undefined && activeRefines.domain !== "NET ZERO" }>

							<ClarityDomainHeader
								appSlice={appSlice}
								selectedDomain={activeRefines.domain}
								listActiveDomains={filterObjects(clarityDomains,
									activeDatasets.listDimensions.data.reduce((acc, element) => acc.concat(element.domain), []))}
								locales={appDefinition.locales}>
								<ClarityIndicator label={activeRefines.domain}
									moduleScore={getModuleScore(activeRefines.domain, activeDatasets.moduleScoresBis.data?.filter((e) => e.campaignId === activeRefines.campaignId), activeDatasets.moduleScoresBis.data?.filter((e) => e.campaignId === activeRefines.campaignId - 1).filter((e) => e.domain === activeRefines.domain))}
									color={getIndicatorColor(activeRefines, activeDatasets, clarityDomains)}
									donutThickness={10} donutInnerThickness={5}
									width={window.innerWidth > 768 ? 140 : 110}
									height={window.innerWidth > 768 ? 140 : 110} />
							</ClarityDomainHeader>

							<div className="-mt-16 px-4 md:px-8 grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4 2xl:px-12">
								<div className="flex flex-col space-y-4 w-full">
									<div className="flex flex-col w-full alphaBlur z-20 rounded-lg px-6 py-4">
										<TitleCard titleLocal="domain.detailedScore.title" subTitleLocal="domain.detailedScore.subTitle" small
											infoLocal="domain.detailedScore.info" locales={appDefinition.locales} />

										<div className="w-full xl:px-4">
											<ParentSize>
												{(parent) => (
													<ColumnGraph
														appSlice={appSlice}
														dataset={"sectionsScore"}
														color={"#1876bd"}
														width={parent.width}
														strokeWidth={8}
														gradientStartBarColor="#CBDA72"
														gradientEndBarColor="#6E8F57"
														nonConformities
														getNC={(d) => d.sectionNbCNC}
														getScore = {(d) => d.score * 100}
														getModule = {(d) => d.section1Label}
														sortData = {(a, b) => Number(a.sectionIndex) < Number(b.sectionIndex) ? 1 : -1}
														locales={appDefinition.locales}
													/>
												)}
											</ParentSize>
										</div>
									</div>
									{activeDatasets?.campaignScores?.data?.length > 1 && <ScoreEvolutionCard dataset={"campaignScores"} appSlice={appSlice}/>}
									<div className=" flex flex-col w-full">
										<div className="relative overflow-hidden rounded-lg drop-shadow-xl">
											<MapLeaflet
												appSlice={appSlice}
												dataset="sites"
												datasetCountries="countries"
												scoreAccessor={scoreAccessor}
												countryScoreAccessor={scoreCountryAccessor}
												fieldsTooltip={clarityMapTooltip}
												mapType="jawgTerrain"
												minZoom={2}
												getMarkerColor={getMarkerColor}
												customTooltip={ClarityTooltip}
												mapHeight={440}
												locales={appDefinition.locales}
											/>
											<ColorGradientLegend />
										</div>
									</div>
								</div>

								<div className="flex flex-col w-full space-y-6">
									<div className="rounded-xl alphaBlur w-full px-6 py-4">
										<NonConformitiesCard appSlice={appSlice}
											nonConformities={nonConformities} dataset="nonConformities"
											synthesis locales={appDefinition.locales}/>
									</div>

									<div className="rounded-xl alphaBlur w-full px-6 py-4">
										<ListOfNonConformities
											appSlice={appSlice}
											appNCConfig={{
												nonConformities,
												listNonConformityQuery,
												nonConformityDataset: ClarityCheckpoints,
												updateListNonConformities,
												listTagsNonConformities: (clientParameters?.trackActionPlan ? { ...listTagsNonConformities, ...listNCAPTags } : listTagsNonConformities),
												customStylesSubFilter: customStylesSubFilterClarity,
												filterOptions: filterOptions.filter((option) => option.label === "Sections" ||
													option.label === "Subsections" || option.label === "Maturities"
													|| option.label === "NCStatus" || option.label === "Plan Item Delayed")
											}}
											forceObservation
											observations = {[{
												locale: "observation",
												accessorResult: (d) => d.answer,
												accessorObs: (d) => d.observation,
												picto: ChatIcon
											}, {
												locale: "actionPlanned",
												accessorActionPlanned: (d) => d.correctiveAction,
												picto: ForwardIcon,
												pictoColor: ""
											}]}
											dataset="listNonConformities"
											totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
											contentRef={contentRef}
											themePalette="clarity"
											locales={appDefinition.locales}
											activeDataSets={activeDatasets} />
									</div>
								</div>

							</div>
						</IF>

						{/*
					  * NET ZERO DOMAIN SELECTED PAGE
					  */}
						<IF condition={menu === "HOME" && activeRefines.siteId === undefined &&
                    activeRefines?.domain === "NET ZERO" /* && activeRefines?.section1Label === undefined */}>

							<ClarityDomainHeader
								appSlice={appSlice}
								selectedDomain={activeRefines.domain}
								listActiveDomains={filterObjects(clarityDomains,
									activeDatasets.listDimensions.data.reduce((acc, element) => acc.concat(element.domain), []))}
								contrast logoClassName="w-112 h-112 filter brightness-200 contrast-200 opacity-40"
								gradientClassName="bg-gradient-to-r from-clarity_primary-netZeroGradientL to-clarity_primary-netZeroGradientR"
								locales={appDefinition.locales}>
								<div className="flex flex-col items-center mb-4">
									<ClarityIndicator label={activeRefines.domain}
										moduleScore={getModuleScore(activeRefines.domain, activeDatasets.moduleScoresBis.data?.filter((e) => e.campaignId === activeRefines.campaignId), activeDatasets.moduleScoresBis.data?.filter((e) => e.campaignId === activeRefines.campaignId - 1).filter((e) => e.domain === activeRefines.domain))}
										color={ getIndicatorColor(activeRefines, activeDatasets, clarityDomains)}
										donutThickness={10} donutInnerThickness={5}
										width={window.innerWidth > 768 ? 140 : 110}
										height={window.innerWidth > 768 ? 140 : 110} />
									<div className="flex items-center justify-center rounded-md bg-clarity_primary-menu
								text-medium space-x-2 py-1 w-max px-4">
										<p className="text-black">
                                        tCO<sub>2</sub>eq <span className="font-semibold">
												{getNetZeroTCO2eq(activeDatasets.netZeroCO2perScope?.data)}
											</span>
										</p>
										<TrendingFlatIcon fontSize="inherit" className="text-gray-500 "/>
									</div>
								</div>
							</ClarityDomainHeader>

							<div className="-mt-16 px-4 md:px-8 grid grid-flow-row grid-cols-1 gap-2 md:gap-4 md:grid-cols-4  2xl:px-12">

								<ClarityNetZeroIDCard appSlice={appSlice} height={440} locales={appDefinition.locales}/>
								<ClarityNetZeroIDCard appSlice={appSlice} height={440} perAsset locales={appDefinition.locales}/>

								<div className="relative flex flex-col w-full h-full overflow-hidden rounded-lg col-span-2">
									<MapLeaflet
										appSlice={appSlice}
										dataset="sites"
										datasetCountries="countries"
										scoreAccessor={scoreAccessor}
										countryScoreAccessor={scoreCountryAccessor}
										fieldsTooltip={clarityMapTooltip}
										mapType="jawgTerrain"
										minZoom={2}
										getMarkerColor={getMarkerColor}
										customTooltip={ClarityTooltip}
										mapHeight={440}
										locales={appDefinition.locales}
									/>
									<ColorGradientLegend />
								</div>
								<div className="col-span-2 flex flex-col">
									<TitleCard titleLocal="homePage.discover.title" subTitleLocal="homePage.discover.subTitle"
										infoLocale="" small locales={appDefinition.locales} />
									<ClarityNetZeroIndicatorCard appSlice={appSlice}
										moduleKey={activeRefines.domain} module={clarityDomains[activeRefines.domain]}
										reportsLink
										moduleScore={getModuleScore(activeRefines.domain, activeDatasets.modulesScores.data)}
										locales={appDefinition.locales} />
								</div>
								<IF condition={activeDatasets?.netZeroBUEmissionValueShare?.data?.length !== 0}>
									<div className="flex flex-col col-span-2">
										<TitleCard titleLocal="homePage.impactPie.title" subTitleLocal="homePage.impactPie.subtitle"
											infoLocale="" small locales={appDefinition.locales} />
										<div className="drop-shadow-md flex ">
											<div className="flex bg-white rounded-xl w-fit">
												<SimplePieChart appSlice={appSlice}
													pieValue={activeDatasets?.netZeroBUEmissionValueShare?.data}
													pieColors={pieColors}
													hasLegend
													total={activeDatasets?.netZeroBUEmissionValueShare?.data?.reduce((prev, cur) => prev + cur.sumEmissionValue, 0)}
													height={210} width={240}
													unit="$"
													caption="1 tCO₂eq = $100"
													accFrequencyKey={"sumEmissionValue"}
													accLabelKey={"BU"}
													hasLabel
													labelColor="black"
													convertToKs
													hasTotal
													hoverAnimate
													refineKey="emissionOwner"
												/>
											</div>
										</div>
									</div>
								</IF>

								<div className="col-span-4">

									<TitleCard titleLocal="netZero.detailedScore.title" subTitleLocal="netZero.detailedScore.subTitle" small
										infoLocal="netZero.detailedScore.info" locales={appDefinition.locales} />
								</div>

								<IF condition={activeRefines.section1Label === undefined}>
									<div className="grid grid-flow-row grid-cols-1 gap-2 md:gap-4 md:grid-cols-3 col-span-4">
										<ClarityNetZeroScopeCard appSlice={appSlice} nbScope={1}
											moduleIndex="NZ1" locales={appDefinition.locales} />
										<ClarityNetZeroScopeCard appSlice={appSlice} nbScope={2}
											moduleIndex="NZ2" locales={appDefinition.locales} />
										<ClarityNetZeroScopeCard appSlice={appSlice} nbScope={3}
											moduleIndex="NZ3" locales={appDefinition.locales} />
									</div>
								</IF>

								<IF condition={activeRefines.section1Label !== undefined}>
									<div className="flex flex-col col-span-4 ">

										<div className="flex justify-between gap-x-2 items-center mb-4">
											<div className="flex items-center">
												<span
													onClick={() => dispatch(appSlice.actions.refine([{ key: "section1Label", value: undefined }]))}
													className="flex align-middle text-white z-20 bg-clarity_primary-netZero
																rounded-xl text-xl font-bold p-1 cursor-pointer">
													<ArrowBackIcon fontSize="inherit" />
												</span>
												<span className="font-semibold text-xl xl:text-2xl
															text-clarity_primary-netZero z-20 mx-1">
													{activeRefines?.section1Label}
												</span>
											</div>
											<div className="flex flex-row items-center space-x-2">
												<p>{t("netZero.KPI.sortBy")}</p>
												<SimpleSelector
													locales={appDefinition.locales}
													options={netZeroSortOptions}
													onChange={(sortOption) => setNetZeroKPIsortOptions(
														{ attribute: sortOption.value, order: sortOption.order })}
													placeHolder={netZeroSortOptions[0]?.label}
													styles={customStylesClarity}
												/>
											</div>
										</div>

										<div className="w-full">
											<ClarityNetZeroKPIs appSlice={appSlice} locales={appDefinition.locales}
												sortOptions={netZeroKPIsortOptions} />
										</div>

									</div>

								</IF>

								<div className="flex flex-col w-full space-y-6 col-span-4">
									<div className="rounded-xl alphaBlur w-full px-6 py-4 mt-2">
										<NonConformitiesCard appSlice={appSlice}
											nonConformities={nonConformities} dataset="nonConformities"
											synthesis locales={appDefinition.locales}/>
									</div>

									<div className="rounded-xl alphaBlur w-full px-6 py-4">
										<ListOfNonConformities
											appSlice={appSlice}
											appNCConfig={{
												nonConformities,
												listNonConformityQuery,
												nonConformityDataset: ClarityCheckpoints,
												updateListNonConformities,
												listTagsNonConformities: (clientParameters?.trackActionPlan ? { ...listTagsNonConformities, ...listNCAPTags } : listTagsNonConformities),
												customStylesSubFilter: customStylesSubFilterClarity,
												filterOptions: filterOptions.filter((option) => option.label === "Sections" ||
													option.label === "Subsections" || option.label === "Maturities"
													|| option.label === "NCStatus" || option.label === "Plan Item Delayed")
											}}
											forceObservation
											observations = {[{
												locale: "observation",
												accessorResult: (d) => d.answer,
												accessorObs: (d) => d.observation,
												picto: ChatIcon
											}, {
												locale: "actionPlanned",
												accessorActionPlanned: (d) => d.correctiveAction,
												picto: ForwardIcon,
												pictoColor: ""
											}]}
											dataset="listNonConformities"
											totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
											contentRef={contentRef}
											themePalette="clarity"
											locales={appDefinition.locales}
											activeDataSets={activeDatasets} />
									</div>
								</div>

							</div>
						</IF>

						{/*
					  * SDG SELECTED PAGE
					  */}
						<IF condition={menu === "SDGs" && activeRefines.siteId === undefined && activeRefines.SDG !== undefined }>
							<ClaritySDGHeader
								appSlice={appSlice}
								selectedSDG={activeRefines.SDG}
								listActiveSDGs={activeDatasets.listSDGs.data}
								locales={appDefinition.locales}>
								<ClaritySDGIndicatorCard
									score={(activeDatasets?.SDGsScores?.data?.[0]?.SDGScore ?? 0) * 100}
									color={sdgsOptions?.[activeRefines.SDG]?.color} />
							</ClaritySDGHeader>
							<div className="-mt-16 px-4 md:px-8 grid grid-flow-row grid-cols-1 gap-4 md:grid-cols-2 2xl:px-12">
								<div className="flex flex-col space-y-4 w-full">

									<div className="flex flex-col space-y-6 w-full ">
										{activeDatasets.listDimensions.data?.map((domain, i) => {
											const moduleScore = getModuleScore(domain.domain, activeDatasets.modulesScores.data);
											const Logo = clarityDomains[domain.domain]?.domainLogo;

											return ((moduleScore?.nbSites > 0) &&
											<div key={`sectionScore ${i}`}
												className="flex flex-col space-y-6 rounded-xl alphaBlur w-full px-6 pt-4">
												<div className="flex items-center gap-x-2">
													{Logo && typeof Logo === "object" ?
														<Logo fontSize="inherit" />
														: <img src={Logo} alt={Logo} className="w-6 h-6" />
													}

													<p>{t(`domains.${clarityDomains[domain.domain]?.locale}.name`)}</p>
												</div>
												<ParentSize >
													{(parent) => (
														<ColumnGraph
															appSlice={appSlice}
															dataset={"sectionsScore"}
															filterData={(d) => d.domain === domain.domain}
															color={"#1876bd"}
															width={parent.width}
															strokeWidth={8}
															gradientStartBarColor="#CBDA72"
															gradientEndBarColor="#6E8F57"
															nonConformities
															getNC={(d) => d.sectionNbCNC}
															getScore = {(d) => d.score * 100}
															getModule = {(d) => d.section1Label}
															locales={appDefinition.locales}
														/>
													)}
												</ParentSize>
											</div>
											);
										})}
									</div>
									<div className=" flex flex-col w-full h-full ">
										<div className="relative overflow-hidden rounded-lg">
											<MapLeaflet
												appSlice={appSlice}
												dataset="sites"
												datasetCountries="countries"
												scoreAccessor={scoreAccessor}
												countryScoreAccessor={scoreCountryAccessor}
												fieldsTooltip={clarityMapTooltip}
												mapType="jawgTerrain"
												minZoom={2}
												getMarkerColor={getMarkerColor}
												customTooltip={ClarityTooltip}
												mapHeight={440}
												locales={appDefinition.locales}
											/>
											<ColorGradientLegend />
										</div>
									</div>
								</div>
								<div className="flex flex-col w-full space-y-6">
									<div className="rounded-xl alphaBlur w-full px-6 py-4">
										<NonConformitiesCard appSlice={appSlice}
											nonConformities={nonConformities} dataset="nonConformities"
											synthesis locales={appDefinition.locales}/>
									</div>

									<div className="rounded-xl alphaBlur w-full px-6 py-4">
										<ListOfNonConformities
											appSlice={appSlice}
											appNCConfig={{
												nonConformities,
												listNonConformityQuery,
												nonConformityDataset: ClarityCheckpoints,
												updateListNonConformities,
												listTagsNonConformities: (clientParameters?.trackActionPlan ? { ...listTagsNonConformities, ...listNCAPTags } : listTagsNonConformities),
												customStylesSubFilter: customStylesSubFilterClarity,
												filterOptions: filterOptions.filter((option) => option.label === "Sections" ||
													option.label === "Subsections" || option.label === "Maturities"
													|| option.label === "NCStatus" || option.label === "Plan Item Delayed")
											}}
											forceObservation
											observations = {[{
												locale: "observation",
												accessorResult: (d) => d.answer,
												accessorObs: (d) => d.observation,
												picto: ChatIcon
											}, {
												locale: "actionPlanned",
												accessorActionPlanned: (d) => d.correctiveAction,
												picto: ForwardIcon,
												pictoColor: ""
											}]}
											dataset="listNonConformities"
											totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
											contentRef={contentRef}
											themePalette="clarity"
											locales={appDefinition.locales}
											activeDataSets={activeDatasets} />
									</div>
								</div>
							</div>
						</IF>

						{/*
					  * SITE SELECTED PAGE
					  */}
						<IF condition={(menu === "HOME" || menu === "SDGs" || menu === "SITEs" || menu === "DISTRIB")
                        && activeRefines.siteId !== undefined}>

							<ClaritySiteHeader
								appSlice={appSlice}
								dataset="sites"
								page="SitePage"
								activeDomain={activeRefines.domain}
								contrast={activeRefines.domain === "NET ZERO"}
								gradientClassName={activeRefines.domain === "NET ZERO" ?
									"bg-gradient-to-r from-clarity_primary-netZeroGradientL to-clarity_primary-netZeroGradientR"
									: undefined}
								listActiveDomains={filterObjects(clarityDomains,
									activeDatasets.listDimensions.data.reduce((acc, element) => acc.concat(element.domain), []))}
								locales={appDefinition.locales}>
								<div className="hidden relative flex-col w-full overflow-hidden rounded-lg md:flex">
									<MapLeaflet
										appSlice={appSlice}
										dataset="sites"
										scoreAccessor={scoreAccessor}
										fieldsTooltip={clarityMapTooltip}
										mapType="jawgTerrain"
										minZoom={2}
										getMarkerColor={getMarkerColor}
										customTooltip={ClarityTooltip}
										mapHeight={440}
										locales={appDefinition.locales}
									/>
									{activeRefines.siteId !== undefined && <div
										className="absolute bottom-6 left-1/2 transform -translate-x-1/2 w-4/5 gap-x-2 font-light text-medium
                                               flex justify-center items-center rounded-xl alphaBlur border px-3 py-1 z-500">
										<LocationOnIcon fontSize="small" />
										<p className="line-clamp-1">
											{activeDatasets.sites?.data?.[0]?.country}&nbsp;-&nbsp;
											{activeDatasets.sites?.data?.[0]?.city}&nbsp;-&nbsp;
											{activeDatasets.sites?.data?.[0]?.address}
										</p>
									</div>}
								</div>
							</ClaritySiteHeader>

							<div className="pt-16 mb-6 px-4 md:px-8 grid grid-flow-row gap-4 grid-cols-1 md:grid-cols-2 2xl:px-12">

								<div className="w-full md:col-span-2">
									<TitleCard titleLocal="homePage.discover.title" subTitleLocal="homePage.discover.subTitle"
										infoLocale="" small locales={appDefinition.locales} />
								</div>
								<div className="relative flex  flex-wrap gap-3 md:col-span-2 2xl:gap-5">
									{activeDatasets?.listDimensions?.data
									.filter((e, i) => activeRefines?.domain
										? activeRefines?.domain === e.domain
										: e)
									.map((dimension, i) => {
										const moduleScore = getModuleScore(
											dimension.domain,
											activeDatasets.moduleScoresBis.data?.filter((e) => e.campaignId === activeRefines.campaignId)
										);
										return (
											<IF key={`indicator ${i}`} condition={moduleScore && moduleScore?.score}>
												<IF condition={dimension.domain === "NET ZERO"}>
													<ClarityNetZeroIndicatorCard appSlice={appSlice}
														moduleKey={dimension.domain} module={clarityDomains[dimension.domain]}
														reportsLink moduleScore={moduleScore} locales={appDefinition.locales} />
												</IF>
												<IF condition={dimension.domain !== "NET ZERO"}>
													<ClarityIndicatorCard appSlice={appSlice}
														moduleKey={dimension.domain} module={clarityDomains[dimension.domain]}
														reportsLink moduleScore={moduleScore} locales={appDefinition.locales} />
												</IF>
											</IF>
										);
									})}
								</div>

								<IF condition={activeRefines.domain === "NET ZERO"}>
									<div className="flex flex-col w-full mt-2 md:col-span-2">
										<TitleCard titleLocal="netZero.detailedScore.title" subTitleLocal="netZero.detailedScore.subTitle" small
											infoLocal="netZero.detailedScore.info" locales={appDefinition.locales} />
										<div className="grid grid-flow-row grid-cols-1 gap-2 md:gap-4 md:grid-cols-3 w-full">

											<IF condition={activeRefines.section1Label === undefined}>
												<ClarityNetZeroScopeCard appSlice={appSlice} nbScope={1}
													moduleIndex="NZ1" locales={appDefinition.locales} />
												<ClarityNetZeroScopeCard appSlice={appSlice} nbScope={2}
													moduleIndex="NZ2" locales={appDefinition.locales} />
												<ClarityNetZeroScopeCard appSlice={appSlice} nbScope={3}
													moduleIndex="NZ3" locales={appDefinition.locales} />
											</IF>

										</div>

										<IF condition={activeRefines.section1Label !== undefined}>
											<div className="flex flex-col w-full md:col-span-2">

												<div className="flex justify-between gap-x-2 items-center mb-4">
													<div className="flex items-center">
														<span
															onClick={() => dispatch(appSlice.actions.refine([{ key: "section1Label", value: undefined }]))}
															className="flex align-middle text-white z-20 bg-clarity_primary-netZero
																rounded-xl text-xl font-bold p-1 cursor-pointer">
															<ArrowBackIcon fontSize="inherit" />
														</span>
														<span className="font-semibold text-xl xl:text-2xl
															text-clarity_primary-netZero z-20 mx-1">
															{activeRefines?.section1Label}
														</span>
													</div>
													<div className="flex flex-row items-center space-x-2">
														<p>{t("netZero.KPI.sortBy")}</p>
														<SimpleSelector
															locales={appDefinition.locales}
															options={netZeroSortOptions}
															onChange={(sortOption) => setNetZeroKPIsortOptions(
																{ attribute: sortOption.value, order: sortOption.order })}
															placeHolder={netZeroSortOptions[0]?.label}
															styles={customStylesClarity}
														/>
													</div>
												</div>

												<div className="flex flex-wrap gap-4 md:gap-8 w-full">
													<ClarityNetZeroKPIs appSlice={appSlice} locales={appDefinition.locales}
														sortOptions={netZeroKPIsortOptions} />
												</div>

											</div>

										</IF>
									</div>

								</IF>
								<IF condition={activeRefines.domain !== "NET ZERO"}>
									<div className={"flex flex-col w-full mt-2"}>
										{(activeDatasets?.campaignScores?.data?.length > 1 && activeRefines.domain) && <div className="mb-4">
											<ScoreEvolutionCard dataset={"campaignScores"} appSlice={appSlice} locales={appDefinition.locales}/>
										</div>}
										<TitleCard titleLocal="domain.detailedScore.title" subTitleLocal="domain.detailedScore.subTitle" small
											infoLocal="domain.detailedScore.info" locales={appDefinition.locales} />
										<div className="flex flex-col space-y-6 w-full ">
											{clientParameters?.activeIndicators && clientParameters?.activeIndicators.map((indicator, i) => {
												const moduleScore = getModuleScore(indicator, activeDatasets.modulesScores.data);
												const Logo = clarityDomains[indicator]?.domainLogo;

												return ((moduleScore?.nbSites > 0) &&
											<div key={`sectionScore ${i}`}
												className="flex flex-col space-y-6 rounded-xl alphaBlur w-full px-6 pt-4">
												<div className="flex items-center gap-x-2">
													{Logo && typeof Logo === "object" ?
														<Logo fontSize="inherit" />
														: <img src={Logo} alt={Logo} className="w-6 h-6" />
													}
													{/* <p>{clarityDomains[indicator]?.name}</p> */}
													<p>{t(`domains.${clarityDomains[indicator]?.name}.name`)}</p>
												</div>
												<ParentSize >
													{(parent) => (
														<ColumnGraph
															appSlice={appSlice}
															dataset={"sectionsScore"}
															filterData={(d) => d.domain === indicator}
															color={"#1876bd"}
															width={parent.width}
															strokeWidth={8}
															gradientStartBarColor="#CBDA72"
															gradientEndBarColor="#6E8F57"
															nonConformities={true}
															getScore = {(d) => d.score * 100}
															getModule = {(d) => d.section1Label}
															getNC = {(d) => d.sectionNbCNC}
															locales={appDefinition.locales}
														/>
													)}
												</ParentSize>
											</div>
												);
											})}
										</div>
									</div>
								</IF>

								<div className={`flex flex-col w-full mt-2 ${ (activeRefines.domain === "NET ZERO") && "col-span-2"}`}>
									<TitleCard titleLocal="domain.nonConformity.title" subTitleLocal="domain.nonConformity.subTitle" small
										infoLocal="domain.nonConformity.info" locales={appDefinition.locales} />

									<div className="rounded-xl alphaBlur w-full px-6 py-4">
										<NonConformitiesCard appSlice={appSlice}
											nonConformities={nonConformities} dataset="nonConformities"
											callToAction={ clientParameters?.trackActionPlan && activeDatasets?.getActionPlan?.data.length > 0 }
											ctaLink={activeDatasets?.getActionPlan?.data}
											ctaContent={t("NCsPage.actionPlan.fillActionPlan")}
											ctaAssignementIcon
											ctaOptionLabelAccessor="domain"
											ctaOptionUrlAccessor="urlActionPlan"
											synthesis locales={appDefinition.locales}/>
									</div>
									<IF condition={clientParameters?.trackActionPlan === true}>
										{activeDatasets?.actionPlanStatusDistribPerSites?.data?.length > 0 &&
										<div className="mt-4 mb-2">
											<ClarityAPScopeWrapper
												appSlice={appSlice}
												appDefinition={appDefinition}
												datasets={["actionPlanStatusDistribPerSites", "actionPlanDelayCount"]}
												refineOnClick={true}
												clientParams={clientParameters}
												theme={"clarity"}/>
										</div>
										}
									</IF>
									<div className="rounded-xl alphaBlur w-full px-6 py-4 mt-6">
										<ListOfNonConformities
											noTitle
											appSlice={appSlice}
											appNCConfig={{
												nonConformities,
												listNonConformityQuery,
												nonConformityDataset: ClarityCheckpoints,
												updateListNonConformities,
												listTagsNonConformities: (clientParameters?.trackActionPlan ? { ...listTagsNonConformities, ...listNCAPTags } : listTagsNonConformities),
												customStylesSubFilter: customStylesSubFilterClarity,
												filterOptions: filterOptions.filter((option) => option.label === "Sections" ||
													option.label === "Subsections" || option.label === "Maturities"
													|| option.label === "NCStatus" || option.label === "Plan Item Delayed")
											}}
											forceObservation
											observations = {[{
												locale: "observation",
												accessorResult: (d) => d.answer,
												accessorObs: (d) => d.observation,
												picto: ChatIcon
											}, {
												locale: "actionPlanned",
												accessorActionPlanned: (d) => d.correctiveAction,
												picto: ForwardIcon,
												pictoColor: ""
											}]}
											dataset="listNonConformities"
											totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
											contentRef={contentRef}
											themePalette="clarity"
											locales={appDefinition.locales}
											activeDataSets={activeDatasets} />
									</div>
								</div>

							</div>
						</IF>

					</div>
				</div>

				{/*
				* FILTER SECTION OF THE CLARITY PAGE
				*/}
				<IF condition={menu !== "INFO" && menu !== "TERMs"}>
					<HorizontalFilters appSlice={appSlice} filterOptions={filterOptions} initRefines={clarityInitRefines}
						siteMenuLabel="Sites" customStyle={customStylesClarity} customStyleSearch={customStylesSearchClarity}
						customDatePickerStyles={customDatePickerStyles} isActiveFilter={isActiveFilter} locales={appDefinition.locales} filterDesktop >
						<div className="flex flex-col w-full gap-y-6 -mt-4 md:mt-0 ">
							<TitleCard titleLocal="scope.title" small classTitle="text-clarity_primary-accent"
								infoLocal="scope.info" locales={appDefinition.locales} />
							<ClarityScopeCard appSlice={appSlice} dataset="scope"
								expectedTotal={clientParameters?.targetAssignements}
								locales={appDefinition.locales} />
						</div>
					</HorizontalFilters>
				</IF>

			</div>
		</div>
	);
}

export default Clarity;
