/**
 * Recursive function to create the supply chain tree structure
 *
 * @param {String[]} supplyChainData The Supply Chain data coming from the rollup
 * @param {String} parentChildren The parent to get the children, "" if tier == 0
 * @param {Number} tierLevel Current Level of children processing
 * @param {String} targetProduct Target Product of the current child
 */
function getSupplychainChildren(supplyChainData, parentChildren, tierLevel, targetProduct, supplyAudits) {
	let children = [];

	supplyChainData
	.sort((a, b) => a[0] - b[0])
	.filter((tier) => tier[0] === tierLevel)
	.forEach((tier, k) => {
		tier[1].filter((parentProduct) => parentProduct[0] === targetProduct || targetProduct === "Transportation")
		.forEach((parentProduct, l) => {
			parentProduct[1]
			.filter((parent) => (tierLevel === 0 ? true : parent[0] === parentChildren))
			.forEach((parentSite, l) => {
				children = parentSite[1].map((site, m) => tierLevel === supplyChainData.length
					? {
						name: site[0][0],
						supplier: site[0][1],
						auditedCompany: site[0][2],
						location: supplyAudits ? site[0][34] : `${site[0][3]} - ${site[0][4]}`,
						city: site[0][3],
						country: site[0][4],
						product: site[0][5],
						geometry: site[0][6],
						countryRisk: site[0][7],
						supplierCriticality: site[0][8],
						siteId: site[0][9],
						type: site[0][10],
						region: site[0][11],
						tierLevel: site[0][12],
						tierLevelDisplay: site[0][13],
						client: site[0][14],
						finalProduct: site[0][15],
						targetProduct: site[0][16],
						nextSupplier: site[0][17],
						logisticAgent: site[0][18],
						tierLabel: site[0][19],
						_id: site[0][20],
						siteLabelId: site[0][21],
						supplierCode: site[0][22],
						factoryCode: site[0][23],
						module: site[0][24],
						criticality: site[0][25],
						factoryBranch: site[0][26],
						factoryIndex: site[0][27],
						validated: site[0][28],
						siteSegmentation: site[0][29],
						areaName: site[0][30],
						areaNumber: site[0][31],
						areaOwner: site[0][32],
						address: site[0][33],
						siteName: site[0][34],
						score: site[1].resilience,
						resilience: site[1].resilience,
						resistance: site[1].resistance,
						responsiveness: site[1].responsiveness
					}
					: {
						name: site[0][0],
						supplier: site[0][1],
						auditedCompany: site[0][2],
						location: supplyAudits ? site[0][34] : `${site[0][3]} - ${site[0][4]}`,
						city: site[0][3],
						country: site[0][4],
						product: site[0][5],
						geometry: site[0][6],
						countryRisk: site[0][7],
						supplierCriticality: site[0][8],
						siteId: site[0][9],
						type: site[0][10],
						region: site[0][11],
						tierLevel: site[0][12],
						tierLevelDisplay: site[0][13],
						client: site[0][14],
						finalProduct: site[0][15],
						targetProduct: site[0][16],
						nextSupplier: site[0][17],
						logisticAgent: site[0][18],
						tierLabel: site[0][19],
						_id: site[0][20],
						siteLabelId: site[0][21],
						supplierCode: site[0][22],
						factoryCode: site[0][23],
						module: site[0][24],
						criticality: site[0][25],
						factoryBranch: site[0][26],
						factoryIndex: site[0][27],
						validated: site[0][28],
						siteSegmentation: site[0][29],
						areaName: site[0][30],
						areaNumber: site[0][31],
						areaOwner: site[0][32],
						address: site[0][33],
						siteName: site[0][34],
						score: site[1].resilience,
						resilience: site[1].resilience,
						resistance: site[1].resistance,
						responsiveness: site[1].responsiveness,
						children: getSupplychainChildren(supplyChainData, site[0][0], tierLevel + 1, site[0][5])
					}
				);
			});
		});
	});

	return children;
}

export default getSupplychainChildren;
