import React from "react";
import { useTranslation } from "react-i18next";
import { appDefinition } from "../../apps/configs/SupplyRConfig";

const SupplyRInfoDesk = () => {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);
	return 	<div className="grid grid-cols-1 lg:grid-cols-2 grid-flow-row my-6 mx-4 gap-y-6 gap-x-4 lg:gap-x-6">

		<div className="flex flex-col gap-y-2 col-span-full">
			<p className="text-lg font-semibold">{t("Info.content.resilienceTitle")}</p>
			<p className="font-light text-sm">{t("Info.content.resilienceDescription")}</p>
		</div>

		<div className="flex flex-col gap-y-2">
			<p className="text-lg font-semibold">{t("Info.content.resistanceTitle")}</p>
			<img src="/images/SupplyR/Info/SupplyRResistance.png" className=""/>
		</div>

		<div className="flex flex-col gap-y-2">
			<p className="text-lg font-semibold">{t("Info.content.responsivenessTitle")}</p>
			<img src="/images/SupplyR/Info/SupplyRResponsiveness.png" className="" />
		</div>

		<p className="text-lg font-semibold col-span-full">{t("Info.content.modulesTitle")}</p>

		<img src="/images/SupplyR/Info/BUSINESS CONTINUITY.png" className="h-36 " />
		<img src="/images/SupplyR/Info/DATA INTEGRITY.png" className="h-36" />
		<img src="/images/SupplyR/Info/HSE RESISTANCE.png" className="h-36" />
		<img src="/images/SupplyR/Info/LOGISTICS & INVENTORY PRACTICES.png" className="h-36" />
		<img src="/images/SupplyR/Info/SUPPLIER TECHNICAL ASSESSMENT.png" className="h-36" />
		<img src="/images/SupplyR/Info/SUPPLY CHAIN SECURITY.png" className="h-36" />
		<img src="/images/SupplyR/Info/GOVERNANCE SOCIAL.png" className="h-36" />
	</div>;
};

export default SupplyRInfoDesk;
