import React from "react";
import { Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import PropTypes from "prop-types";

const CalendarController = ({ control, name, inputStyle = "pl-2 py-2 mt-2 mb-3" }) => (
	<Controller
		control={control}
		name={name}
		render = {({ field: { onChange, value } }) => (
			<ReactDatePicker
				onChange={onChange}
				selected={value ? new Date(value) : null}
				placeholderText="Select date"
				customInput={
					<input
						className={`${inputStyle} border text-black focus:outline-none rounded-lg focus:ring-2 focus:ring-admin_primary-default
                        focus:border-transparent text-sm focus:text-base`}
					/>}
				popperModifiers={[
					{
						name: "arrow",
						options: { padding: 200 }
					}
				]}
			/>
		)}
	/>
);

CalendarController.propTypes = {
	control: PropTypes.object,
	name: PropTypes.string.isRequired,
	inputStyle: PropTypes.string
};

export default CalendarController;
