import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
	useTable, useGlobalFilter, useSortBy, usePagination, useAsyncDebounce
} from "react-table";

import { Pagination } from "@mui/material";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownUp from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import exportToExcel from "../../utils/ExportToExcel";

const theme = createTheme({
	palette: {
		default: {
			main: "lightblue"
		},
		compass: {
			main: "#F9CA48"
		},
		clarity: {
			main: "#CCDB73"
		},
		supplyr: {
			main: "#E3E5F5"
		},
		vendorDD: {
			main: "#E3E5F5"
		}
	}
});

// Define a default UI for filtering
function GlobalFilter({
	placeHolder,
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter
}) {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="flex justify-between items-center bg-gray-100 py-4 px-2 md:px-6 space-x-4 rounded-xl ">
			<input
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={placeHolder}
				className="bg-transparent outline-none "
			/>
			<SearchIcon />
		</div>
	);
}

GlobalFilter.propTypes = {
	placeHolder: PropTypes.string,
	preGlobalFilteredRows: PropTypes.array,
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func
};

function SupplyRTable({
	pageTable = "SitesPage",
	appSlice,
	dataset1 = undefined,
	dataset2 = undefined,
	structureTable = undefined,
	filterData = (d) => d,
	refineKeys = (row) => [{ key: "siteId", value: row.original.siteId }],
	sortByRefineFields = [{ id: "auditedCompany", desc: false }],
	themePalette = "default",
	locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Get activeRefines
	const appName = useSelector(appSlice.selectAppName);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);

	// Set up state for opened form
	const [tableData, setTableData] = useState([]);

	// Load data from the store
	const tableSitesData = useSelector(appSlice.selectDatasets)[dataset1]?.data;
	const tableDownStreamData = useSelector(appSlice.selectDatasets)[dataset2]?.data;

	const tableRawData = useMemo(() => {
		const res = tableSitesData.reduce((siteAcc, siteCur) => {
			const target = tableDownStreamData.find((site) => site.nextSupplier === siteCur.siteLabelId);

			if (target) {
				return [...siteAcc, {
					...siteCur,
					downStreamCount: target.downStreamCount,
					totalStreamCount: siteCur.upStreamCount + target.downStreamCount
				}];
			}

			return [...siteAcc, { ...siteCur, downStreamCount: 0, totalStreamCount: siteCur.upStreamCount }];
		}, []);

		return res;
	}, [tableSitesData, tableDownStreamData]);

	const tableColumns = useMemo(
		() => structureTable(activeRefines, t, clientParameters),
		// eslint-disable-next-line
		[activeRefines]
	);

	useEffect(() => {
		setTableData([...tableRawData].filter(filterData));
		// eslint-disable-next-line
	}, [tableRawData]); 

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		rows,
		gotoPage,
		preGlobalFilteredRows,
		setGlobalFilter,
		state: { pageIndex, pageSize }
	} = useTable({
		columns: tableColumns,
		data: tableData,
		initialState: {
			pageIndex: 0,
			pageSize: 10,
			sortBy: sortByRefineFields
		}
	},
	useGlobalFilter,
	useSortBy,
	usePagination);

	if (dataset1 === undefined || dataset2 === undefined || structureTable === undefined) {
		console.log("You need to define your dataset and table structure to display this component");
		return null;
	}

	function generateTableToExport() {
		const sortedTable = new Array(rows.length);
		rows.forEach((row, i) => {
			const formattedRow = {};
			prepareRow(row);
			row.cells.forEach((cell, j) => (
				cell.column.Header === "Score"
					? (formattedRow[cell.column.Header] = cell.value != null ? { t: "n", v: cell.value / 100, z: "0.00%" } : null)
					: formattedRow[cell.column.Header] = cell?.value?.toString()
			));

			sortedTable[i] = formattedRow;
		});

		return sortedTable;
	}

	return (<div data-test="SupplyRTable" className="flex flex-col text-sm space-y-4 max-x-full  w-full overflow-x-auto">
		<div className="self-center flex justify-between items-center w-full px-4 md:px-6">
			<GlobalFilter
				placeHolder={t(`${pageTable}.table.searchFor`)}
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
			/>
			<div className="flex space-x-2 items-center h-full">
				<p className="self-end hidden md:block my-auto">
					{rows.length} {t(`${pageTable}.table.results`)}
				</p>
				<button className={`text-${themePalette}_primary-accent rounded-xl border border-gray-100 
                shadow-sm p-1 bg-white hover:border-gray-200 cursor-pointer`}
				title="Download data in Excel format"
				onClick={(e) => exportToExcel(generateTableToExport(), `${appName } Sites Export ${new Date().toISOString().split("T")[0]}`)}>
					<DownloadIcon />
				</button>
			</div>
		</div>
		<table {...getTableProps()} className="w-full">
			<thead>
				{headerGroups.map((headerGroup, i) => (
					<tr key={`headerGroup ${i}`} {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column, j) => (
							<th key={`header ${j}`}
								{...column.getHeaderProps(column.getSortByToggleProps())}
								className="first:pl-6 last:pr-6 text-left font-medium p-2 max-w-xs"
							>
								<div className="flex items-center space-x-2">
									{t(`${pageTable}.table.${column.render("Header")}`)}
									<span>
										{column.isSorted
											? column.isSortedDesc
												? <KeyboardArrowDownUp fontSize="inherit" />
												: <KeyboardArrowDownIcon fontSize="inherit" />
											: ""}
									</span>
								</div>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{page.map((row, i) => {
					prepareRow(row);
					return (
						<tr key={`row ${i}`} {...row.getRowProps()}
							onClick={() => dispatch(appSlice.actions.refine(refineKeys(row)))}
							className={`odd:bg-gray-100 hover:bg-${themePalette}_primary-default hover:bg-opacity-20 cursor-pointer`}>
							{row.cells.map((cell, j) => (
								<td key={`cell ${j}`}
									{...cell.getCellProps()}
									className="first:pl-6 last:pr-6 p-2 max-w-xs"
								>
									{cell.render("Cell")}
								</td>
							))}
						</tr>
					);
				})}
			</tbody>
		</table>
		{rows.length > pageSize &&
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Pagination page={pageIndex + 1} count={Math.floor((rows.length / pageSize) + 1)}
						className="md:self-center px-4 md:px-6" color={themePalette} onChange={async (event, value) => {
							const page = value ? Number(value) - 1 : 0;
							gotoPage(page);
						}} />
				</ThemeProvider>
			</StyledEngineProvider>
		}
	</div>
	);
}

SupplyRTable.propTypes = {
	pageTable: PropTypes.string,
	appSlice: PropTypes.object,
	dataset1: PropTypes.string,
	dataset2: PropTypes.string,
	filterData: PropTypes.func,
	structureTable: PropTypes.func,
	refineKeys: PropTypes.func,
	sortByRefineFields: PropTypes.array,
	themePalette: PropTypes.string,
	locales: PropTypes.string
};

export default SupplyRTable;
