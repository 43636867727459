import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import CloseIcon from "@mui/icons-material/Close";

const defaultClassNameClose = "absolute top-2 right-2 text-clarity_primary-accent font-semibold";

function Notification({
	appName, notifKey = "0001",
	classNameClose = defaultClassNameClose, noClose = false, children
}) {
	const storageKey = "notificationViewed";
	const [open, setOpen] = useState(false);

	const isPermanentlyClosed = () => localStorage.getItem(`${storageKey }-${ appName}-${notifKey}`);

	const saveClosedState = () => {
		localStorage.setItem(`${storageKey }-${ appName}-${ notifKey}`, true);
	};

	const openNotification = () => {
		if (isPermanentlyClosed()) return;
		setOpen(true);
	};

	const closeNotification = (e) => {
		if (e) e.preventDefault();
		setOpen(false);
		saveClosedState();
	};

	useEffect(() => {
		openNotification();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={` ${open ? "flex" : "hidden"} w-full  z-xl`}>
			{children}
			{!noClose && <div className={classNameClose}>
				<CloseIcon fontSize="small" onClick={closeNotification}/>
			</div>
			}
		</div>
	);
}

Notification.propTypes = {
	appName: PropTypes.string,
	notifKey: PropTypes.string,
	classNameClose: PropTypes.string,
	noClose: PropTypes.bool,
	children: PropTypes.any
};

export default Notification;
