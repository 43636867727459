/* eslint-disable react/no-unescaped-entities */
import React from "react";

const RYBCookies = () => (
	<div className="text-sm text-tab-legalNotice-title">
		<p className="text-2xl font-medium">Cookies Policy</p>
		<p className="text-gray-500 text-sm">(Last update: October, 2021)</p>
		<p className="text-base font-medium mt-4">
      Use of cookies by Bureau Veritas
		</p>
		<p className="mt-4">
      You are informed that Bureau Veritas Services ("Bureau Veritas", "we") uses
      cookies or other tracers on the site &nbsp;
			<a
				className="hover:underline"
				href="https://restartwith.bureauveritas.com/"
			>
        restartyourbusinesswith.bureauveritas.com
			</a>
      &nbsp;(the "Site").
		</p>
		<p className="mt-4">
      These may be installed in your terminal subject to the choices and options
      you have made or may express at any time in accordance with this policy.
		</p>
		<p className="mt-4">
      Bureau Veritas has defined this policy for your information and
      transparency, so that you can learn more about:
		</p>
		<ul className="list-disc ml-10 mt-4">
			<li>
        the origin and purpose of the information processed when you browse the
        Site;
			</li>
			<li>your rights to cookies and other tracers used by Bureau Veritas.</li>
		</ul>
		<p className="text-base font-medium mt-4">What is a cookie?</p>
		<p className="mt-4">
      Cookies, or similar tracers, are data used by a server to send status
      information to a user's browser, and by that browser to send status
      information back to the original server.
		</p>
		<p className="mt-4">
      Status information can be, for example, a session ID, language, expiration
      date, etc.
		</p>
		<p className="mt-4">
      Cookies are used to store status information for the duration of their
      validity when a browser accesses different pages on a website or when the
      browser subsequently returns to that website.
		</p>
		<p className="text-base font-medium mt-4">
      What are the cookies issued on the Site used for?
		</p>
		<p className="mt-4">
      We use cookies and other tracers, in particular for the following main
      purposes:
		</p>
		<table className="table-auto alphaBlur w-full mt-4">
			<thead className="border-b-2">
				<tr className="bg-gray-100 text-left">
					<th className="w-min font-normal px-2 py-2">Cookie name</th>
					<th className="w-min font-normal px-2 py-2">Usage</th>
					<th className="w-min font-normal px-2 py-2">Description</th>
				</tr>
			</thead>
			<tbody className="divide-y-2">
				<tr>
					<td className="w-min px-3 py-3 align-top">
						<span className="font-semibold">i18nextLng</span>
					</td>
					<td className="w-min px-3 py-3 align-top">Translate user language</td>
					<td className="w-min px-3 py-3 align-top">
            This is a i18next language detection plugin use to detect user
            language in the browser with support for: EN, FR, ES, PT.
					</td>
				</tr>
			</tbody>
		</table>
		<p>&nbsp;</p>
		<p className="mt-4">
      The social networking applications on the Site as mentioned above may in
      some cases allow the corresponding social networks to identify you, even
      if you have not clicked on the application buttons. Indeed, this type of
      button can allow the social network concerned to follow your navigation on
      the Site, just because your account to the social network concerned is
      activated on your device (open session) during your browsing.
		</p>
		<p className="mt-4">
      We recommend that you review the policies of these social networks in
      order to be aware of the purposes for use, including advertising, of the
      browsing information they may collect. These policies must allow you to
      exercise your choices with these social networks, in particular by setting
      up your usage accounts for each of them.
		</p>
		<p className="text-base font-medium mt-4">
      Cookies subject to your consent
		</p>
		<p className="mt-4">
      The installation of certain cookies is subject to your consent. Also, when
      you first visit the Site, you are asked, by the presence of an information
      banner on the Site's home page, if you accept the installation of this
      type of cookie. Cookies will only be activated after your acceptance by
      continuing your browsing on the Site.
		</p>
		<p className="mt-4">
      You will be able to return to your choice at any time by the various means
      described in the section "How to manage cookies?".
		</p>
		<p className="text-base font-medium mt-4">How to manage cookies?</p>
		<p className="mt-4">
      There are several ways to manage cookies and other tracers.
		</p>
		<p className="mt-4">You can configure your browser to:</p>
		<ul className="list-disc mt-4 ml-10">
			<li>accept all cookies, or</li>
			<li>reject them systematically, or</li>
			<li>choose the ones you accept according to their emitters.</li>
		</ul>
		<p className="mt-4">
      You can also set your browser to accept or refuse cookies on a
      case-by-case basis before they are installed. You can also regularly
      delete cookies from your device via your browser.
		</p>
		<p className="mt-4">
      Do not forget to configure all the browsers of your different terminals
      (tablets, smartphones, computers...).
		</p>
		<p className="mt-4">
      For the management of cookies and your choices, the configuration of each
      browser is different. It is described in the help menu of your browser,
      which will let you know how to change your cookie preferences.
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
        For Internet Explorer:{" "}
				<a
					className="hover:underline"
					href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies"
				>
          http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
				</a>
			</li>
			<li>
        For Safari:{" "}
				<a
					className="hover:underline"
					href="http://docs.info.apple.com/article.html?path=Safari/3.0/en/9277.html"
				>
          http://docs.info.apple.com/article.html?path=Safari/3.0/en/9277.html
				</a>
			</li>
			<li>
        For Chrome:{" "}
				<a
					className="hover:underline"
					href="https://support.google.com/chrome/answer/95647?hl=en&amp;hlrm=en"
				>
          http://support.google.com/chrome/bin/answer.py?hl=en&amp;hlrm=en&amp;answer=95647
				</a>
			</li>
			<li>
        For Firefox:{" "}
				<a
					className="hover:underline"
					href="https://support.mozilla.org/fr/kb/Activer%20and%20d%C3%A9sactivate%20the%20cookies"
				>
          http://support.mozilla.org/fr/kb/Activer%20and%20d%C3%A9sactivate%20the%20cookies
				</a>
			</li>
			<li>
        For Opera:{" "}
				<a
					className="hover:underline"
					href="https://help.opera.com/en/latest/web-preferences/#cookies"
				>
          http://help.opera.com/Windows/10.20/fr/cookies.html
				</a>
			</li>
		</ul>
		<p className="mt-4">
      You will find social network sharing buttons on the Site. For each of
      them, you will find below a link to a page where you can learn more about
      how these networks use trackers and how you can set up your account:
		</p>
		<ul className="list-disc mt-4 ml-10">
			<li>
        LinkedIn:{" "}
				<a
					className="hover:underline"
					href="https://www.linkedin.com/legal/cookie-policy"
				>
          https://www.linkedin.com/legal/cookie-policy
				</a>
			</li>
			<li>
        Twitter:{" "}
				<a
					className="hover:underline"
					href="https://help.twitter.com/en/rules-and-policies/twitter-cookies"
				>
          https://help.twitter.com/en/rules-and-policies/twitter-cookies
				</a>
			</li>
		</ul>
		<p className="mt-4">
      The registration of a cookie in your terminal is essentially subject to
      your will, which you can express and modify at any time and free of charge
      through the choices offered by your browser.
		</p>
		<p className="mt-4">
      If your browser is set to accept cookies in your device, the cookies
      embedded in the pages and content you have viewed may be temporarily
      stored in a dedicated area of your device. They will only be readable by
      their transmitter.
		</p>
		<p className="my-4">
      Conversely, you can set your browser to refuse cookies. In this regard,
      your attention is drawn to the fact that by setting your browser to refuse
      cookies, certain features, pages and spaces on the Site will not be
      accessible (e. g., if you try to access our content or services requiring
      your identification, or if we or our service providers cannot recognize,
      for technical compatibility purposes, the type of browser used by your
      terminal, its language and display settings or the country from which your
      terminal is located). We cannot be held responsible for the consequences
      of degraded functioning of our services resulting from the impossibility
      for us to record or consult the cookies necessary for their functioning
      and which you would have refused or deleted.
		</p>
		<p>
      To read the Bureau Veritas Group Data Protection Policy, click{" "}
			<a
				className="hover:underline"
				href="https://personaldataprotection.bureauveritas.com/home/1.1.14/#/privacypolicy"
			>
        here
			</a>
		</p>
	</div>
);

RYBCookies.propTypes = {};

export default RYBCookies;
