import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import {
	useTooltip, useTooltipInPortal, TooltipWithBounds, Tooltip
} from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { color } from "d3";
import thousandsToK from "../../utils/ThousandsToK";

const defaultMargin = {
	top: 20, right: 20, bottom: 20, left: 20
};

const defaultStyles = {
	fontSize: 24, fontWeight: 400, backgroundColor: "bg-white", legendDisplay: "grid"
};

function SimplePieChart({
	appSlice, width = 160, height = 160, pieValue = [], pieColors,
	colorHasScale = false, hasTotal = false, total = 0, unit = "", accTotalKey = "", caption = "",
	accFrequencyKey = "", accLabelKey = "", hasLabel = false, hasLegend = false, labelColor = "#ffffff", digit = 0, margin = defaultMargin,
	convertToKs, children, hoverAnimate = false, refineKey = "", styles = defaultStyles
}) {
	const innerWidth = width - margin.left - margin.right;
	const innerHeight = height - margin.top - margin.bottom;
	const radius = Math.min(innerWidth, innerHeight) / 2;
	const centerY = innerHeight / 2;
	const centerX = innerWidth / 2;
	const donutThickness = 25;
	const top = centerY + margin.top;
	const left = centerX + margin.left;

	const pieSortValues = (a, b) => b - a;
	const getfrequency = (d) => d[accFrequencyKey];

	const {
		tooltipLeft,
		tooltipTop,
		tooltipOpen,
		showTooltip,
		hideTooltip
	} = useTooltip();

	const dispatch = useDispatch();
	// get active refines
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	// If you don't want to use a Portal, simply replace `TooltipInPortal` below with
	// `Tooltip` or `TooltipWithBounds` and remove `containerRef`
	const { containerRef, TooltipInPortal } = useTooltipInPortal({
		// use TooltipWithBounds
		detectBounds: true,
		// when tooltip containers are scrolled, this will correctly update the Tooltip position
		scroll: true
	});
	// add colors to pie dataset
	const addColorsToObj = (arrOfObj, colorArr) => {
		if (colorHasScale) {
			return arrOfObj.map((obj) => {
				const objCopy = { ...obj };
				const foundObject = colorArr.find((color) => obj[accLabelKey] === color?.label);
				return { ...objCopy, color: foundObject?.color, rank: foundObject?.rank };
			});
		}
		// if no color scale and simply use an array of color codes
		const colors = colorArr.slice(0, arrOfObj.length + 1);
		return arrOfObj.map((obj, i) => {
			const objCopy = obj;
			return { ...objCopy, color: colors[i] };
		});
	};

	const handleMouseOver = (event, datum) => {
		const coords = localPoint(event.target.ownerSVGElement, event);
		showTooltip({
			tooltipLeft: coords.x,
			tooltipTop: coords.y,
			tooltipData: datum
		});
	};

	const addOthersObj = (arr) => {
		if (arr.length < 10) return arr;

		if (arr.length >= 10) {
			const tail = arr.sort((a, b) => a[accFrequencyKey] < b[accFrequencyKey] ? 1 : -1).slice(9, arr.length);
			const othersObj = { [accLabelKey]: "Others", [accFrequencyKey]: tail.reduce((acc, cur) => acc + cur[accFrequencyKey], 0) };
			return [...arr.slice(0, 9), othersObj];
		}
	};

	const processedValue = pieColors
		? addColorsToObj(addOthersObj(pieValue), pieColors)
		: addOthersObj(pieValue);

	const [hoveredEl, setHoveredEl] = React.useState();
	return (
		<div className={`${styles.backgroundColor} rounded-xl flex justify-center items-center`}>
			<div className="flex flex-col">
				<svg width={width} height={height} ref={containerRef}>
					<Group top={top} left={left}>
						<Pie
							data={processedValue}
							pieValue={getfrequency}
							pieSortValues={pieSortValues}
							outerRadius={radius}
							innerRadius={radius - donutThickness}
							onMouseOver={handleMouseOver} onMouseOut={hideTooltip}
						>
							{(pie) => pie.arcs.map((arc, index) => {
								const [centroidX, centroidY] = pie.path.centroid(arc);
								const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
								const arcPath = pie.path(arc);
								const arcFill = arc?.data?.color ?? "#ffffff";
								return (
									<g
										onMouseEnter={() => hoverAnimate &&
											setHoveredEl({ label: arc.data[accLabelKey], score: arc.data[accFrequencyKey], index })}
										onMouseLeave={() => hoverAnimate &&
											setHoveredEl(null)}
										className="" key={`arc-${index}-${arc.data[accFrequencyKey]}`}>
										<path className={`${hoverAnimate &&
											`transition-all ${refineKey && "cursor-pointer"} hover:scale-110 hover:drop-shadow-md`}
											${hoveredEl?.label === arc.data[accLabelKey] && "transition-all scale-110 drop-shadow-md"}`}
										onClick={() => refineKey && (activeRefines?.[refineKey]
											? dispatch(appSlice.actions.clear([{ refine: refineKey }]))
											: dispatch(appSlice.actions.refine([{ key: refineKey, value: arc.data[accLabelKey] }])))}
										d={arcPath} fill={arcFill} />
										{hasTotal && (
											<g>
												<text
													x={0}
													y={0}
													dy={unit ? ".24em" : ".33em"}
													fontSize={hoveredEl ? 20 : styles.fontSize}
													fontWeight={styles.fontWeight}
													textAnchor="middle"
													pointerEvents="none"
												>
													{
														hoveredEl?.label || (convertToKs
															? thousandsToK(total || arc.data[accTotalKey])
															: total || arc.data[accTotalKey])
													}
												</text>
												{ (hoveredEl || unit) &&
									<text
										x={0}
										y={0}
										dy="1.75em"
										fontSize={16}
										fontWeight={400}
										textAnchor="middle"
										pointerEvents="none"
									>
										{hoveredEl ? thousandsToK(hoveredEl?.score) : unit}
									</text>}
											</g>
										)}
										{hasLabel && hasSpaceForLabel && (
											<text
												x={centroidX}
												y={centroidY}
												dy=".33em"
												fill={labelColor}
												fontSize={10}
												textAnchor="middle"
												pointerEvents="none"
											>
												{convertToKs && typeof arc.data[accFrequencyKey] === "number"
													? thousandsToK(arc.data[accFrequencyKey])
													: arc.data[accFrequencyKey]}
											</text>
										)}
										{tooltipOpen && (
											<TooltipInPortal
											// set this to random so it correctly updates with parent bounds
												key={Math.random()}
												top={tooltipTop}
												left={tooltipLeft}
											>
          									{arc.data.label}
											</TooltipInPortal>
										)}
									</g>
								);
							})}
						</Pie>
					</Group>
				</svg>
				{
					caption && <caption className="text-sm text-gray-400 italic -mt-2">{caption}</caption>
				}
			</div>
			<div className={styles.legendDisplay === "column" ? "flex flex-col gap-3" : "gap-1 grid grid-cols-2 grid-rows-5"}>
				{hasLegend &&
					processedValue?.sort((a, b) => (a.rank !== undefined || a.rank !== null) ? a.rank - b.rank
						: (a[accLabelKey] > b[accLabelKey] ? 1 : -1))?.map((val, i) => (
						<>
							<div key={`legend${i}`}
								className={`
									${hoveredEl?.label === val[accLabelKey] && "font-semibold"}
									${refineKey && "hover:cursor-pointer hover:font-semibold"}
									h-1/5 flex justify-start items-center
									`}
								onMouseEnter={() => hoverAnimate &&
										setHoveredEl({ label: val[accLabelKey], score: val[accFrequencyKey], i })}
								onMouseLeave={() => hoverAnimate &&
										setHoveredEl(null)}
								onClick={() => activeRefines?.[refineKey]
									? dispatch(appSlice.actions.clear([{ refine: refineKey }]))
									: dispatch(appSlice.actions.refine([{ key: refineKey, value: val[accLabelKey] }]))}
							>
								<div className="w-3 h-3 rounded-full"
									style={{ backgroundColor: val.color }}
								></div>
								<span className="mx-2 text-small">{val[accLabelKey]}</span>
							</div>
						</>
					))
				}

			</div>
		</div>
	);
}

SimplePieChart.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	pieValue: PropTypes.array,
	pieColors: PropTypes.array,
	colorHasScale: PropTypes.boolean,
	width: PropTypes.number,
	height: PropTypes.number,
	hasTotal: PropTypes.bool,
	total: PropTypes.number,
	totalLabel: PropTypes.string,
	unit: PropTypes.string,
	accFrequencyKey: PropTypes.string,
	accTotalKey: PropTypes.string,
	accLabelKey: PropTypes.string,
	hasCovered: PropTypes.bool,
	totalCovered: PropTypes.number,
	digit: PropTypes.number,
	hasLabel: PropTypes.bool,
	hasLegend: PropTypes.bool,
	labelColor: PropTypes.string,
	margin: PropTypes.object,
	children: PropTypes.object,
	convertToKs: PropTypes.bool,
	caption: PropTypes.string,
	hoverAnimate: PropTypes.bool,
	refineKey: PropTypes.string,
	styles: PropTypes.object
};

export default SimplePieChart;
