import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import BusinessIcon from "@mui/icons-material/Business";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { appDefinition } from "../../apps/configs/SupplyRConfig";

import IF from "../../utils/IF";
import Loading from "../../utils/Loading";

function SupplyRSupplierDetailedCard({
	appSlice, dataset
}) {
	const [isViewReportDetail, setIsViewReportDetail] = useState(true);
	const [currentProductIndex, setCurrentProductIndex] = useState(null);
	const [supplierCodeCopy, setSupplierCode] = useState(undefined);

	const { t } = useTranslation(appDefinition.locales);

	// Load the store hook
	const dispatch = useDispatch();
	const currentView = useSelector(appSlice.selectSubPage);

	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const currentSupplierNoFilter = useSelector(appSlice.selectDatasets).suppliers?.data;
	const currentSupplierDetail = useSelector(appSlice.selectDatasets)[dataset]?.data;
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	const totalSitesAssessed = currentSupplierDetail.filter((element) => element.resilience).length;

	const currentSelectedSite = activeRefines.siteId !== undefined ?
		currentSupplierDetail?.find((node) => node.siteId === activeRefines.siteId)
		: undefined;

	const existingSupplierCode = activeDatasets?.supplyChain?.data
	?.filter((item) => item?.supplier === activeRefines?.supplier)
	?.map((item) => item?.supplierCode)
	?.filter((item) => item);

	return (
		<div className="flex flex-col w-full">
			<div className="flex items-center gap-x-1 text-sm -mb-2">
				<button className={`bg-white shadow-lg rounded-t-lg py-2 px-3 hover:underline 
                cursor-pointer ${ currentView === "SITE" ? "underline" : "text-gray-600"}`}
				onClick={() => { dispatch(appSlice.actions.setPage("SITES")); dispatch(appSlice.actions.setSubPage("SITE")); }}>
					{t("DetailCard.tabs.sitePage")}
				</button>
				{!clientParameters?.noCompare && totalSitesAssessed > 0 &&
				<button className={`bg-white shadow-lg rounded-t-lg py-2 px-3 hover:underline 
                cursor-pointer ${ currentView === "ALTERNATIVES" ? "underline" : "text-gray-600"}`}
				onClick={() => { dispatch(appSlice.actions.setPage("SITES")); dispatch(appSlice.actions.setSubPage("ALTERNATIVES")); }}>
					{t("DetailCard.tabs.alternativesPage")}
				</button>
				}
				{
					activeRefines?.projectName === "TotalEnergies" &&
					<button className={`bg-white shadow-lg rounded-t-lg py-2 px-3 hover:underline 
                cursor-pointer ${ currentView === "TOTALDEMO" ? "underline" : "text-gray-600"}`}
					onClick={() => { dispatch(appSlice.actions.setPage("SITES")); dispatch(appSlice.actions.setSubPage("TOTALDEMO")); }}>
						{t("DetailCard.tabs.capabilities")}
					</button>
				}
			</div>
			<div className="bg-white w-auto h-auto rounded-xl shadow-lg px-2 py-2">
				<div className="flex flex-col gap-y-1">
					<div className="flex justify-between pb-2">
						<BusinessIcon fontSize="large" className="pl-1 mr-2" />
						<div className="flex flex-growclose flex-col pt-2 text-ellipsis w-full">
							<p className="space-x-2">
								<span>{activeRefines.supplier}</span>
								{existingSupplierCode?.length > 0 && <span>{`(${existingSupplierCode?.[0]})`}</span>}
							</p>
							<div className="flex items-center justify-center rounded text-xs font-semibold bg-yellow-400 px-2 w-28">
								{totalSitesAssessed}/{currentSupplierNoFilter.length} {t("DetailCard.main.siteAssessed", { count: totalSitesAssessed })}
							</div>
						</div>

						<CloseIcon className="cursor-pointer mr-1" fontSize="inherit"
							onClick={() => dispatch(appSlice.actions.clear([
								{ refine: "supplier" },
								{ refine: "siteId" },
								{ refine: "product" }
							]))} />

					</div>

					<IF condition={	loadDataStatus !== "idle"}>
						<Loading isComponent />
					</IF>

					{loadDataStatus === "idle" && currentSupplierDetail
					.filter((node) => activeRefines.siteId === undefined || node.siteId === activeRefines.siteId)
					.map((node, i) => <div
						key={`node ${ i}`}
						className={`flex justify-between ${ activeRefines.siteId === undefined ? "px-2" : "pl-1 pr-2"}`}>
						{activeRefines.siteId &&
                    <div className="cursor-pointer" onClick={() => dispatch(appSlice.actions.clear([{ refine: "siteId" }]))}>
                    	<ArrowBackIcon />
                    </div>
						}
						<HomeWorkIcon fontSize="medium" className="ml-1 mr-2" />
						<div className="flex flex-col flex-grow w-full">
							<div className="text-medium text-ellipsis w-full cursor-pointer space-x-2"
								onClick={() => dispatch(appSlice.actions.refine([{ key: "siteId", value: node.siteId }])) }>
								<span>{node.auditedCompany}</span>
								{node?.factoryCode && <span>{`(${node?.factoryCode})`}</span>}
							</div>
							<div className="flex justify-between items-center">
								<div className="flex flex-col">
									<p className="text-smaller font-light text-ellipsis w-36">{node.city} - {node.country}</p>
									<div className="flex items-center justify-center max-w-content rounded text-xs font-semibold bg-yellow-400 px-2 w-28">
										{node.module?.length}/
										{currentSupplierNoFilter.find((element) => element.siteId === node.siteId).product.length}
										{t("DetailCard.main.productAssessed", { count: node.module?.length })}
									</div>
								</div>
								{clientParameters?.allowReportLink && node.spaceId !== null &&
							<a href={`https://maiaplus.bureauveritas.com/browse/${node.project}/${node.spaceId}/SITE/${node.siteId}`}
								target="_blank" rel="noreferrer"
								className="flex items-center border border-gray-300 rounded-md text-smaller  px-2 py-1 cursor-pointer
                                hover:border-gray-400" >
                    	        <FolderOpenIcon fontSize="inherit" />
								<p className="ml-1.5">{t("DetailCard.main.evidenceLibrary")}</p>
							</a>
								}
							</div>
						</div>
					</div>
					)}
				</div>
			</div>
			{
				(
					loadDataStatus === "idle"
                    && (activeRefines.siteId !== undefined || currentSupplierDetail?.length === 1))
&& currentSelectedSite?.product.map((product, i) => (<div key={`product ${i}`}
	className={`relative flex ml-2 pb-4  ${i === 0 ? "mt-6" : "mt-2"}`}>
	<div className={`absolute  border-gray-400 border-l border-b w-2  ${i === 0 ? "h-6 -top-5" : "h-11 -top-10"}`} />
	<div className="flex item-center bg-white w-full justify-between items-center -mt-4  h-10 rounded-lg shadow-md ml-2">
		<div className="flex item-center cursor-pointer "
			onClick={() => 	activeRefines.product ?
				dispatch(appSlice.actions.clear([{ refine: "product" }])) :
				dispatch(appSlice.actions.refine([{ key: "product", value: [product] }]))}>
			<DeviceHubIcon fontSize="small" className="m-auto ml-3" />
			<p className="m-auto ml-1.5 text-sm line-clamp-1 ">{product}</p>
		</div>
		{currentSelectedSite && currentSelectedSite.gradingsLabel &&
			<div className="w-1/4 text-xs">
				<span>{currentSelectedSite?.gradingsLabel}</span>
			</div>
		}
		{clientParameters?.allowReportLink && currentSelectedSite?.module?.length > 0 &&
		<div className="relative flex flex-col justify-center items-center">
			<div
				className="flex flex-col border rounded-md  mr-2 h-auto w-22 bg-white
                border-gray-300 hover:border-gray-400"
				style={{ zIndex: 2000 - i }}
			>
				<div
					className="flex cursor-pointer py-1.5 pl-1 gap-x-2 "
					onClick={() => {
						setIsViewReportDetail(!isViewReportDetail);
						setCurrentProductIndex(i);
					}}
				>
					<InsertDriveFileIcon style={{ fontSize: "0.7rem" }} />
					<p className="text-xs">{t("DetailCard.childrenInfos.viewReport")}</p>
				</div>

				{isViewReportDetail
						&& (currentProductIndex === i)
						&& currentSelectedSite?.report[i]?.map((item, j) => (
							<div
								key={`report-${j}`}
								className={`hover:bg-gray-100 text-xs py-1 pl-2 cursor-pointer ${j === 0 ? "mt-3" : null}`}
							>
								<a target="_blank" rel="noopener noreferrer"
									href={`https://odsdatahub.bureauveritas.com/reports/${currentSelectedSite?.report[i][j]}`}>
									{currentSelectedSite?.module?.[j]}
								</a>
							</div>
						))}
			</div>

		</div>
		}
	</div>
</div>))
			}
		</div>

	);
}

SupplyRSupplierDetailedCard.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string
};

export default SupplyRSupplierDetailedCard;
