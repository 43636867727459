import { Marker, Popup } from "react-leaflet";
import PropTypes from "prop-types";
import React from "react";
import L from "leaflet";
import { useDispatch, useSelector } from "react-redux";
import getValueNestedObject from "../../utils/GetValueNestedObject";

const setDefaultColor = (score) => "#1876bd";

const getIcon = (pathFillColor) => {
	const svgTemplate = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 16 16" xml:space="preserve">
   <path fill="${pathFillColor}" class="path1" stroke="black" stroke-width= "0.3px"
   d="M8 2.1c1.1 0 2.2 0.5 3 1.3 0.8 0.9 1.3 1.9 1.3 3.1s-0.5 2.5-1.3 3.3l-3
   3.1-3-3.1c-0.8-0.8-1.3-2-1.3-3.3 0-1.2 0.4-2.2 1.3-3.1 0.8-0.8 1.9-1.3 3-1.3z"></path>

</svg>`;

	const icon = L.divIcon({
		className: "marker",
		html: svgTemplate,
		iconSize: [48, 48],
		iconAnchor: [24, 36],
		popupAnchor: [7, -16]
	});
	return icon;
};

const ChargeScanMapMarker = ({
	appSlice,
	dataset,
	marker,
	scoreAccessor,
	geopointField,
	fieldsTooltip = undefined,
	getMarkerColor = setDefaultColor,
	CustomTooltip = undefined,
	locales
}) => {
	const activePage = useSelector(appSlice.selectPage);

	const dispatch = useDispatch();
	const geopoint = getValueNestedObject(marker, geopointField);

	if (!geopoint) {
		return null;
	}

	return (
		<Marker
			score={scoreAccessor(marker) === "Compliant"}
			position={[Number(geopoint[1]), Number(geopoint[0])]}
			icon={getIcon(getMarkerColor(scoreAccessor(marker)))}
			eventHandlers={{
				click: (e) => {
					dispatch(appSlice.actions.refine([{ key: "label", value: marker?.label }]));
					if (activePage !== "HOME") dispatch(appSlice.actions.setPage("HOME"));
				}
			}}
		>

			{(CustomTooltip !== undefined || (fieldsTooltip !== undefined && fieldsTooltip.length > 0)) &&
			<Popup direction="top" offset={[-8, -5]} opacity={1} >
				{CustomTooltip === undefined ?
					<ul>
						{fieldsTooltip.map((field, i) => (
							<li key={i}>{getValueNestedObject(marker, field.key)}</li>
						))}
					</ul>
					: <CustomTooltip appSlice={appSlice} marker={marker} locales={locales} />
				}
			</Popup>
			}
		</Marker>
	);
};

ChargeScanMapMarker.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	marker: PropTypes.object,
	scoreAccessor: PropTypes.func,
	geopointField: PropTypes.string,
	fieldsTooltip: PropTypes.array,
	getMarkerColor: PropTypes.func,
	CustomTooltip: PropTypes.func,
	locales: PropTypes.string
};

export default ChargeScanMapMarker;
