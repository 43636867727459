/**
	 * Recursive function to create the supply chain tree structure
	 *
	 * @param {String[]} supplyChainData The Supply Chain data coming from the rollup
	 * @param {String} parentChildren The parent to get the children, "" if tier == 0
	 * @param {Number} tierLevel Current Level of children processing
	 * @param {String} targetProduct Target Product of the current child
	 */
function getSupplychainChildren(supplyChainData, parentChildren, tierLevel, targetProduct, campaignId, maxCampaignId) {
	let children = [];
	supplyChainData
	.sort((a, b) => a[0] - b[0])
	.filter((tier) => tier[0] === tierLevel)
	.forEach((tier, k) => {
		tier[1].filter((parentProduct) => parentProduct[0] === targetProduct)
		.forEach((parentProduct, l) => {
			parentProduct[1]
			.filter((parent) => (tierLevel === 0 ? true : parent[0] === parentChildren)
			)
			.forEach((parentSite, l) => {
				children = parentSite[1]
				.map((site, m) => tierLevel === supplyChainData.length
					? {
						name: site[0][0],
						supplier: site[0][1],
						auditedCompany: site[0][2],
						location: `${site[0][3]} - ${site[0][4]}`,
						country: site[0][4],
						product: site[0][5],
						geometry: site[0][6],
						countryRisk: site[0][7],
						criticality: site[0][8],
						siteId: site[0][9],
						type: site[0][10],
						region: site[0][11],
						_id: site[0][12],
						nextSupplier: site[0][13],
						finalProduct: site[0][14],
						targetProduct: site[0][15],
						tierLevel: site[0][16],
						inactive: site[0][17],
						domains: site[0][18],
						spaceLabel: site[0][19],
						campaignId: site[0][20],
						score: site[1].score
					}
					: {
						name: site[0][0],
						supplier: site[0][1],
						auditedCompany: site[0][2],
						location: `${site[0][3]} - ${site[0][4]}`,
						country: site[0][4],
						product: site[0][5],
						geometry: site[0][6],
						countryRisk: site[0][7],
						criticality: site[0][8],
						siteId: site[0][9],
						type: site[0][10],
						region: site[0][11],
						_id: site[0][12],
						nextSupplier: site[0][13],
						finalProduct: site[0][14],
						targetProduct: site[0][15],
						tierLevel: site[0][16],
						inactive: site[0][17],
						domains: site[0][18],
						spaceLabel: site[0][19],
						campaignId: site[0][20],
						score: site[1].score,
						children: getSupplychainChildren(supplyChainData, site[0][0], tierLevel + 1, site[0][5], campaignId, maxCampaignId)
					}
				)
				.filter((item) => item);
			});
		});
	});

	return children;
}

export default getSupplychainChildren;
