import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Tooltip } from "@mui/material";

const SimpleRepartitionBar = ({
	appSlice = null,
	refineKey = undefined,
	additionalRefineKey = undefined,
	additionalRefineValue = undefined,
	refineValue = undefined,
	dataset = undefined,
	accLabel = "label",
	accCount = "count",
	colorScale = undefined,
	thickness = undefined,
	label = true,
	locales
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);
	// active refines
	const dispatch = useDispatch();
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const activeDatasets = useSelector(appSlice.selectDatasets);

	const calculateShare = (objects) => {
		// Calculate the total count
		const totalCount = objects.reduce((sum, obj) => sum + obj[accCount], 0);

		// Calculate the share for each object
		const result = objects.reduce((shareObj, obj) => {
			// Calculate the share by dividing the count by the total count
			const share = obj[accCount] / totalCount;

			// Add the share to the result object
			shareObj[obj[accLabel]] = { share, count: obj[accCount] };
			// shareObj.sort((a, b) => a.rank)
			return shareObj;
		}, {});

		return result;
	};

	const getColorByLabel = (colors, label, rank = false) => {
		const foundObject = colors.find((color) => color.label === label);
		return foundObject ? (rank ? foundObject.rank : foundObject.color) : null;
	};

	let data = calculateShare(activeDatasets?.[dataset]?.data);
	if (colorScale) {
		// Map over the entries of the object and update the ranks
		data = Object.fromEntries(
			Object.entries(data).map(([key, value]) => {
				// Find the corresponding rank object for the current key
				const rankObj = colorScale.find((item) => item.label === key);
				if (rankObj) {
					// Add the rank property to the value object using spread syntax
					return [
						key,
						{ ...value, rank: rankObj.rank }
					];
				}
				// If no rank object is found, keep the original key-value pair
				return [key, value];
			})
		);
	}

	const getThickness = (thickness) => {
		switch (thickness) {
			case "extraThin":
				return "h-[5px]";
			case "thin":
				return "h-2";
			case "large":
				return "h-3";
			default:
				return "h-3";
		}
	};

	return <React.Fragment>
		<div className={`relative flex flex-row h-5 ${label ? "mt-10 mb-5" : ""}`}>
			{Object.entries(data)
			// sort by rank in the color scale or by properties in alphabetical order
			?.sort((a, b) => colorScale ? a[1].rank - b[1].rank : a[0] - b[0])
			?.map((node, i) => (
				<Tooltip key={`simpleRepartitionBar-${i}`} title={node[0]} followCursor>
					<div
						className={`bg-rect-horizontalleft rounded-3xl mx-0.5 flex 
						justify-center ${getThickness(thickness)}
				${refineValue && "cursor-pointer"}`}
						style={{
							width: `${node[1].share * 100}%`,
							backgroundColor: getColorByLabel(colorScale, node[0])
						}}
						// TO DO ! REWORK THE REFINE ON CLICK BEHAVIOUR AS THIS CODE SEEMS IRRELEVANT
						onClick={
							// case refineValue && additional refine
							((refineValue && additionalRefineValue)
								// clear refines if they are active
								? () => ((activeRefines?.[refineKey] && activeRefines?.[additionalRefineKey])
									? dispatch(appSlice.actions.clear([
										{ refine: refineKey },
										{ refine: additionalRefineKey }
									]))
									// refine on main filter if refineKey is inactive & additional refine is active
									: (!activeRefines?.[refineKey] && activeRefines?.[additionalRefineKey])
										? dispatch(appSlice.actions.refine([
											{ key: refineKey, value: refineValue }
										]))
										// clear main filter if active and additional refine inactive
										: (activeRefines?.[refineKey] && !activeRefines?.[additionalRefineKey])
											? dispatch(appSlice.actions.clear([{ refine: refineKey }]))
											// else dispatch both
											: dispatch(appSlice.actions.refine([
												{ key: refineKey, value: refineValue },
												{ key: additionalRefineKey, value: additionalRefineValue }
											])))
								// case refineValue only
								: (refineValue && additionalRefineValue === undefined)
									? () => (activeRefines?.[refineKey]
										? dispatch(appSlice.actions.clear([
											{ refine: refineKey }
										]))
										: dispatch(appSlice.actions.refine([
											{ key: refineKey, value: refineValue }
										]))
									)
									: "")
						}
					>
						{label &&
							<span className="absolute -top-6 text-medium font-bold">
								{node[1].count}
							</span>
						}
					</div>
				</Tooltip>
			))

			}
		</div>
	</React.Fragment>;
};

SimpleRepartitionBar.propTypes = {
	appSlice: PropTypes.object,
	refineKey: PropTypes.string,
	additionalRefineKey: PropTypes.string,
	additionalRefineValue: PropTypes.string,
	dataset: PropTypes.string,
	accLabel: PropTypes.string,
	accCount: PropTypes.string,
	colorScale: PropTypes.array,
	refineValue: PropTypes.string,
	thickness: PropTypes.string,
	label: PropTypes.bool,
	locales: PropTypes.string
};

export default SimpleRepartitionBar;
