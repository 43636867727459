import { useTranslation } from "react-i18next";
import {
	useRef, createRef
} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { netZeroEmissionPractice } from "../../apps/configs/ClarityConfig";
import Loading from "../../utils/Loading";
import thousandsToK from "../../utils/ThousandsToK";

function ClarityNetZeroKPIs({ appSlice, locales, sortOptions }) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load data from the store of the App
	const netZeroKPIsData = useSelector(appSlice.selectDatasets)?.netZeroKPIs?.data;
	const netZeroKPIs = [...netZeroKPIsData];
	netZeroKPIs.sort(
		(a, b) => ((a[sortOptions.attribute] > b[sortOptions.attribute]) * 2 - 1) * sortOptions.order
	);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	const nz1EmObjRefs = useRef([]);
	nz1EmObjRefs.current = netZeroKPIs?.map((_, i) => nz1EmObjRefs.current[i] ?? createRef());
	const nz1EmSourceRefs = useRef([]);
	nz1EmSourceRefs.current = netZeroKPIs?.map((_, i) => nz1EmSourceRefs.current[i] ?? createRef());

	if (loadDataStatus !== "idle") {
		return 	<div style={{ height: "5rem" }}>
			<Loading isComponent />
		</div>;
	}
	return (
		<div className="flex flex-1 flex-wrap gap-4 justify-start">
			{netZeroKPIs?.map((kpi, j) => {
				const param = 0;
				if (kpi.moduleIndex !== "NZ2") {
					return (
						<div key={`kpi ${ j}`}
							className="flex flex-col items-center alphaBlur rounded-xl justify-between p-2 w-44">
							<p className="h-1/5 mb-2 text-xs text-gray-600 text-center align-middle font-light line-clamp-1 leading-4">
								{kpi.siteLabel}
							</p>
							<div className="flex flex-col">
								{nz1EmObjRefs?.current[j]?.current?.scrollHeight > nz1EmObjRefs?.current[j]?.current?.clientHeight
									? <Tooltip title={kpi.emissionObject}>
										<p ref={nz1EmObjRefs.current[j]} className="text-sm text-center align-middle line-clamp-1 leading-4"
											onClick={() => console.log(
												nz1EmObjRefs.current[j].current.scrollHeight > nz1EmObjRefs.current[j].current.clientHeight)}>
											{kpi.emissionObject}
										</p>

									</Tooltip>
									: <p ref={nz1EmObjRefs.current[j]} className="text-sm text-center align-middle line-clamp-1 leading-4"
										onClick={() => console.log(
											nz1EmObjRefs.current[j].current.scrollHeight > nz1EmObjRefs.current[j].current.clientHeight)}>
										{kpi.emissionObject}
									</p>
								}
								{nz1EmSourceRefs?.current[j]?.current?.scrollHeight > nz1EmSourceRefs?.current[j]?.current?.clientHeight
									? <Tooltip title={kpi.emissionSource}>
										<p ref={nz1EmSourceRefs.current[j]} className = "text-center font-semibold align-middle line-clamp-1 leading-4">
											{kpi.emissionSource}
										</p>
									</Tooltip>
									: <p ref={nz1EmSourceRefs.current[j]} className = "text-center font-semibold align-middle line-clamp-1 leading-4">
										{kpi.emissionSource}
									</p>
								}
							</div>
							<div className="flex justify-center items-center gap-x-2 pt-2 w-full">
								<p className="text-4xl font-bold"
									style={{ color: netZeroEmissionPractice[kpi.emissionPractice] }}>
									{thousandsToK(kpi.emissionValue.toFixed(2))}
								</p>
								<div className="">
									<p className="rounded-md font-semibold text-medium px-1 text-white bg-gray-500">
										{kpi.emissionValueLabel}
									</p>
								</div>
							</div>
							<div>
								<p className="mt-1 text-xs text-gray-600 text-center align-middle font-light line-clamp-1 leading-4">
									{kpi.emissionOwner}
								</p>
							</div>
						</div>
					);
				}
				return (
					<div key={`kpi ${ j}`}
						className="flex flex-col items-center bg-white drop-shadow-md rounded-xl justify-between p-2 m-2 h-full
						">
						<p className=" mb-2 text-xs  text-center align-middle line-clamp-2 leading-4 text-gray-500">
							{kpi.siteLabel}
						</p>
						<div className="flex gap-4">
							<div className="flex flex-col justify-center items-center">
								<div className="flex flex-col">
									<p className="text-sm text-center align-middle leading-4 text-gray-700">
										{kpi.emissionObject}
									</p>
									<p className = "text-center  font-semibold align-middle leading-4">
										{kpi.emissionSource}
									</p>
								</div>
								<div className="flex flex-col justify-center items-center m-4 w-full rounded-xl"
								>
									<p className="text-4xl font-bold "
									>
										{thousandsToK(kpi.emissionValue.toFixed(2))}
									</p>
									<p className="rounded-md px-2 py-1 bg-gray-500
									font-semibold text-medium leading-none text-white"
									>
										{kpi.emissionValueLabel}
									</p>
									<p className="absolute bottom-0 text-sm text-gray-500">{kpi.emissionOwner}</p>
								</div>
							</div>
							<div className="flex flex-col justify-between items-center gap-2 ">
								<div className="flex flex-1 flex-col flex-wrap justify-between items-center
								w-full p-2
								bg-clarity_primary-netZero rounded-md">
									<span className="text-small text-center text-white font-semibold mb-1">Consumption quantification method</span>
									<div className="flex flex-col justify-end items-center rounded-md w-full
								  bg-[#8CB9B8] drop-shadow-lg p-2"
									style={{ backgroundColor: netZeroEmissionPractice[kpi.emissionPractice] }}>
										<span className="text-2xl font-bold"
											style={{ color: kpi.emissionPractice === "Missing Practice" ? "white" : "black" }}
										>{kpi.consumptionValue}</span>
										<span className="text-small"
											style={{ color: kpi.emissionPractice === "Missing Practice" ? "white" : "black" }}
										>{kpi.consumptionValueUnit}</span>
									</div>
								</div>
								<div className="flex flex-1 flex-col flex-wrap justify-between items-center w-full p-2
							bg-clarity_primary-netZero rounded-md">
									<span className="text-small text-center text-white mb-1 font-semibold">Selected Emission Factor</span>
									<div className="flex flex-col justify-end items-center rounded-md w-full
								bg-[#8CB9B8] drop-shadow-lg p-2"
									style={{ backgroundColor: netZeroEmissionPractice[kpi.emissionFactorPractice] }}>
										<span className="text-2xl font-bold"
											style={{ color: kpi.emissionFactorPractice === "Missing Practice" ? "white" : "black" }}
										>{kpi.emissionFactor}</span>
										<span className="text-small"
											style={{ color: kpi.emissionFactorPractice === "Missing Practice" ? "white" : "black" }}
										>{kpi.emissionFactorUnit}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			})}
			{netZeroKPIs?.length === 0 && <p>
				{t("netZero.detailedScore.noKpiData")}
			</p>}
		</div>
	);
}

ClarityNetZeroKPIs.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	sortOptions: PropTypes.object
};

export default ClarityNetZeroKPIs;
