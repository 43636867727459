import MenuBookIcon from "@mui/icons-material/MenuBook";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import CallIcon from "@mui/icons-material/Call";

import {
	clarityChainTableStructure, supplyRChainTableStructure, ataglanceChainTableStructure, verkorChainTableStructure
} from "../../components/table/AdminTableStructure";

import getSupplyRchainChildren from "../../components/tree/SupplyRSupplychainChildren";
import getClaritychainChildren from "../../components/tree/ClaritySupplychainChildren";
import getAtAGlancechainChildren from "../../components/tree/AtAGlanceSupplychainChildren";

import {
	claritySupplierCreate, clarityNodeUpdate, clarityLocationUpdate, supplyRSupplierCreate, supplyRNodeUpdate, supplyRLocationUpdate,
	ataglanceSupplierCreate, ataglanceNodeUpdate, ataglanceLocationUpdate
} from "../../components/form/AdminFormOnConfirm";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} slice Name of the slice related to the app created and references in the store ./store.js
 * @param {String} logo Label of the menu, if none, only the picto
 */
export const appDefinition = {
	name: "ADMIN",
	subName: "",
	launcherID: "ADMIN",
	slice: "admin",
	locales: "translationsAdmin",
	theme: "admin"
};

/**
 * Set up  menu options to display
 *
 * @param {String} menu ID of the menu (use for the initial menu definition and the render of the app)
 * @param {String} type Route or App = Route is a link to jump to a new url, App is a internal link to the App to display specific information
 * @param {String} url [OPTIONAL] If type == url, the new url to link the menu (it has to be routed in ./App.js)
 * @param {String} label [OPTIONAL] Label of the menu, if none, only the picto
 * @param {String} sectionTitleWhite [OPTIONAL] Section title in white displayed on top of the page when the menu is clicked
 * @param {String} sectionTitleBlack [OPTIONAL] Section title in black displayed on top of the page when the menu is clicked
 * @param {Component} picto Material UI Icon to represent the field in the select, to be imported in this file
 */
export const menuOptions = [
	{
		menu: "HOME",
		type: "App",
		initial: true,
		label: "home",
		picto: MenuBookIcon
	},
	{
		menu: "PROJECT",
		type: "App",
		initial: true,
		label: "Apps",
		picto: DashboardIcon
	},
	{
		menu: "CONFIG",
		secLevelMenu: ["Admin Users", "App Scopes", "Access Requests"],
		type: "App",
		initial: true,
		label: "Config",
		picto: PeopleIcon
	},
	{
		menu: "CONTACT",
		type: "App",
		initial: true,
		label: "Contact",
		picto: CallIcon
	}
];

/**
 * Breadcrumbs options
 */
export const breadcrumbsOptions = {};

/**
 * Sub menu list
 */
export const projectSecLevelMenu = ["ataglance", "clarity", "supplyR", "compass", "verkor"];
export const strategySecLevelMenu = ["Admin Users", "groups", "access Requests"];

/**
 * Name of the dataset in Projects
 */
const ClarityClientParameters = "ClarityClientParameters";
const SupplyRClientParameters = "SupplyRClientParameters";
const CompassClientParameters = "CompassClientParameters";
const VerkorClientParameters = "VerkorClientParameters";
const AtAGlanceClientParameters = "AtAGlanceClientParameters";

/**
 * Name of the dataset Supply Chain for aggregation
 */
const ClarityToR = "ClarityV4ToR";
const SupplyRToR = "SupplyR-ToR";
const AtAGlanceToR = "AtAGlance-ToR";
const VerkorToR = "Verkor-ToR";
const ClarityChain = "ClarityChainScores";
const SupplyRChain = "SupplyRChainScores";
const AtAGlanceChain = "AtAGlanceChainScores";
const VerkorChain = "VerkorChainScores";

// select the proper dataset name in adminInitDatasets based on project
export const TORDataset = {
	clarity: "clarityChainToR",
	supplyR: "supplyRChainToR",
	ataglance: "ataglanceChainToR",
	verkor: "verkorChainToR"
};

export const geoDataset = {
	clarity: "clarityChainGeo",
	supplyR: "supplyRChainGeo",
	verkor: "verkorChainGeo"
};

export const TORCollection = {
	clarity: "ClarityV4ToR",
	supplyR: "SupplyR-ToR",
	ataglance: "AtAGlance-ToR",
	verkor: "Verkor-ToR"
};

export const clientCollection = {
	clarity: "ClarityClientParameters",
	supplyR: "SupplyRClientParameters",
	compass: "CompassClientParameters",
	verkor: "VerkorClientParameters",
	ataglance: "AtAGlanceClientParameters"
};

export const clarityClientParameters = {
	collection: ClarityClientParameters,
	fetch: "aggregation",
	facetsToRefine: [],
	query: [
		{
			$sort: {
				projectLabel: 1
			}
		}
	],
	limit: 500,
	adminCallFromApp: "clarity"
};

export const supplyRClientParameters = {
	collection: SupplyRClientParameters,
	fetch: "aggregation",
	facetsToRefine: [],
	query: [
		{
			$sort: {
				projectLabel: 1
			}
		}
	],
	limit: 500,
	adminCallFromApp: "supplyR"
};

export const compassClientParameters = {
	collection: CompassClientParameters,
	fetch: "aggregation",
	facetsToRefine: [],
	query: [
		{
			$sort: {
				projectLabel: 1
			}
		}
	],
	limit: 500,
	adminCallFromApp: "compass"
};

export const verkorClientParameters = {
	collection: VerkorClientParameters,
	fetch: "aggregation",
	facetsToRefine: [],
	query: [
		{
			$sort: {
				projectLabel: 1
			}
		}
	],
	limit: 500,
	adminCallFromApp: "verkor"
};

export const ataglanceClientParameters = {
	collection: AtAGlanceClientParameters,
	fetch: "aggregation",
	facetsToRefine: [],
	query: [
		{
			$sort: {
				projectLabel: 1
			}
		}
	],
	limit: 500,
	adminCallFromApp: "ataglance"
};

export const clientParamsDatasetBuilder = (secLevelMenu) => {
	switch (secLevelMenu) {
		case "clarity":
			return clarityClientParameters;
		case "supplyR":
			return supplyRClientParameters;
		case "compass":
			return compassClientParameters;
		case "verkor":
			return "verkorClientParameters";
		case "ataglance":
			return "ataglanceClientParameters";
		default:
			break;
	}
};

/**
  * Active DataSets definition for the app
  *
  * @param {String} name
  * @param {String} collection
  * @param {String} fetch
  * @param {String[]} facetsToRefine
  * @param {Object[]} query
  * @param {Number} limit
  * @param {Object[]} data
  * @param {string[] required*} adminCallFromApp
  */
export const adminInitDatasets = {
	adminUser: {
		collection: "AdminUser",
		fetch: "find",
		facetsToRefine: [],
		query: [],
		limit: 500,
		adminCallFromApp: ""
	},
	clarityClientParameters: {
		collection: ClarityClientParameters,
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel"],
		query: [
			{
				$sort: {
					projectLabel: 1
				}
			}
		],
		limit: 500,
		adminCallFromApp: "clarity"
	},
	supplyRClientParameters: {
		collection: SupplyRClientParameters,
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$sort: {
					projectLabel: 1
				}
			}
		],
		limit: 500,
		adminCallFromApp: "supplyR"
	},
	compassClientParameters: {
		collection: CompassClientParameters,
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$sort: {
					projectLabel: 1
				}
			}
		],
		limit: 500,
		adminCallFromApp: "compass"
	},
	verkorClientParameters: {
		collection: VerkorClientParameters,
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$sort: {
					projectLabel: 1
				}
			}
		],
		limit: 500,
		adminCallFromApp: "verkor"
	},
	ataglanceClientParameters: {
		collection: AtAGlanceClientParameters,
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$sort: {
					projectLabel: 1
				}
			}
		],
		limit: 500,
		adminCallFromApp: "ataglance"
	},
	clarityChainToR: {
		collection: ClarityToR,
		pages: ["PROJECT"],
		fetch: "find",
		facetsToRefine: ["spaceLabel"],
		query: [],
		limit: 5000,
		adminCallFromApp: "clarity",
		refreshed: true
	},
	supplyRChainToR: {
		collection: SupplyRToR,
		pages: ["PROJECT"],
		fetch: "find",
		facetsToRefine: ["projectName"],
		query: [],
		limit: 5000,
		adminCallFromApp: "supplyR",
		refreshed: true
	},
	ataglanceChainToR: {
		collection: AtAGlanceToR,
		pages: ["PROJECT"],
		fetch: "find",
		facetsToRefine: ["projectName"],
		query: [],
		limit: 5000,
		adminCallFromApp: "ataglance",
		refreshed: true
	},
	verkorChainToR: {
		collection: VerkorToR,
		pages: ["PROJECT"],
		fetch: "find",
		facetsToRefine: ["projectName"],
		query: [],
		limit: 5000,
		adminCallFromApp: "verkor",
		refreshed: true
	},
	clarityChainGeo: {
		collection: ClarityChain,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel"],
		query: [
			{
				$match: {
					"geometry.coordinates": {
						$exists: true,
						$ne: null
					}
				}
			}
		],
		limit: 5000,
		refreshed: true,
		adminCallFromApp: "clarity"
	},
	supplyRChainGeo: {
		collection: SupplyRToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$match: {
					geopointToR: {
						$exists: true,
						$ne: null
					}
				}
			}, {
				$addFields: {
					geometry: {
						type: "Point",
						coordinates: [
							{
								$arrayElemAt: [
									{
										$split: [
											"$geopointToR", ","
										]
									}, 1
								]
							}, {
								$arrayElemAt: [
									{
										$split: [
											"$geopointToR", ","
										]
									}, 0
								]
							}
						]
					}
				}
			}
		],
		limit: 5000,
		refreshed: true,
		adminCallFromApp: "supplyR"
	},
	verkorChainGeo: {
		collection: VerkorChain,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$match: {
					"geometry.coordinates": {
						$exists: true,
						$ne: null
					}
				}
			}
		],
		limit: 5000,
		refreshed: true,
		adminCallFromApp: "verkor"
	},
	supplyRSupplyChainActiveFinalProducts: {
		collection: SupplyRToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName", "finalProduct"],
		query: [
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			},
			{
				$project: {
					finalProduct: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 500,
		adminCallFromApp: "supplyR"
	},
	claritySupplyChainActiveFinalProducts: {
		collection: ClarityToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel", "finalProduct"],
		query: [
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			},
			{
				$project: {
					finalProduct: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 500,
		adminCallFromApp: "clarity"
	},
	ataglanceSupplyChainActiveFinalProducts: {
		collection: AtAGlanceToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName", "finalProduct"],
		query: [
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			},
			{
				$project: {
					finalProduct: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 500,
		adminCallFromApp: "ataglance"
	},
	verkorSupplyChainActiveFinalProducts: {
		collection: VerkorToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName", "finalProduct"],
		query: [
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			},
			{
				$project: {
					finalProduct: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 500,
		adminCallFromApp: "verkor"
	},
	supplyRFinalProducts: {
		collection: SupplyRToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {},
					finalProducts: {
						$addToSet: "$finalProduct"
					}
				}
			}, {
				$project: {
					finalProducts: 1,
					_id: 0
				}
			}
		],
		limit: 500,
		adminCallFromApp: "supplyR"
	},
	clarityFinalProducts: {
		collection: ClarityToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel"],
		query: [
			{
				$group: {
					_id: {},
					finalProducts: {
						$addToSet: "$finalProduct"
					}
				}
			}, {
				$project: {
					finalProducts: 1,
					_id: 0
				}
			}
		],
		limit: 500,
		adminCallFromApp: "clarity"
	},
	verkorFinalProducts: {
		collection: VerkorToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {},
					finalProducts: {
						$addToSet: "$finalProduct"
					}
				}
			}, {
				$project: {
					finalProducts: 1,
					_id: 0
				}
			}
		],
		limit: 500,
		adminCallFromApp: "verkor"
	},
	iso3CodeToCountry: {
		collection: "worldCountries",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {},
					country: {
						$push: "$country"
					},
					iso3Code: {
						$push: "$iso3Code"
					}
				}
			}, {
				$project: {
					_id: 0,
					iso3CodeToCountry: {
						$arrayToObject: {
							$map: {
								input: {
									$range: [
										0, {
											$size: "$country"
										}
									]
								},
								as: "index",
								in: {
									k: {
										$arrayElemAt: [
											"$iso3Code", "$$index"
										]
									},
									v: {
										$arrayElemAt: [
											"$country", "$$index"
										]
									}
								}
							}
						}
					}
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	supplierCode: {
		collection: SupplyRToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$match: {
					supplierCode: {
						$exists: true
					}
				}
			}, {
				$match: {
					supplierCode: {
						$nin: [
							"", null
						]
					}
				}
			}, {
				$project: {
					_id: 0,
					supplier: "$supplier",
					supplierCode: "$supplierCode"
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	factoryCode: {
		collection: SupplyRToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$match: {
					factoryCode: {
						$exists: true
					}
				}
			}, {
				$match: {
					factoryCode: {
						$nin: [
							"", null
						]
					}
				}
			}, {
				$project: {
					_id: 0,
					auditedCompany: "$auditedCompany",
					factoryCode: "$factoryCode"
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	supplierCodeClarity: {
		collection: ClarityToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$match: {
					supplierCode: {
						$exists: true
					}
				}
			}, {
				$match: {
					supplierCode: {
						$nin: [
							"", null
						]
					}
				}
			}, {
				$project: {
					_id: 0,
					supplier: "$supplier",
					supplierCode: "$supplierCode"
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "clarity"
	},
	factoryCodeClarity: {
		collection: ClarityToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$match: {
					factoryCode: {
						$exists: true
					}
				}
			}, {
				$match: {
					factoryCode: {
						$nin: [
							"", null
						]
					}
				}
			}, {
				$project: {
					_id: 0,
					auditedCompany: "$auditedCompany",
					factoryCode: "$factoryCode"
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "clarity"
	},
	AppScopes: {
		collection: "AppScopes",
		pages: ["CONFIG"],
		fetch: "find",
		facetsToRefine: [],
		query: [],
		limit: 100,
		adminCallFromApp: ""
	},
	// below for dropdown list
	AppOptions: {
		collection: "AppScopes",
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {},
					appOptions: {
						$addToSet: "$_id"
					}
				}
			}, {
				$unwind: {
					path: "$appOptions"
				}
			}, {
				$project: {
					label: "$appOptions",
					value: "$appOptions",
					dispatchField: {
						appOptions: "$appOptions"
					},
					displayField: "$appOptions",
					_id: 0
				}
			}
		],
		limit: 100,
		adminCallFromApp: ""
	},
	selectedAppProjectOptions: {
		collection: "AppScopes",
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$project: {
					res: {
						$objectToArray: "$$ROOT"
					}
				}
			}, {
				$project: {
					newObjK: {
						$arrayElemAt: [
							"$res", 0
						]
					},
					newObjV: "$res"
				}
			}, {
				$unwind: {
					path: "$newObjV"
				}
			}, {
				$group: {
					_id: "$_id",
					newObjK: {
						$first: "$newObjK"
					},
					newObjV: {
						$addToSet: "$newObjV.k"
					}
				}
			}, {
				$project: {
					newObjK: "$newObjK.v",
					newObjV: {
						$filter: {
							input: "$newObjV",
							as: "res",
							cond: {
								$not: {
									$eq: [
										"$$res", "_id"
									]
								}
							}
						}
					}
				}
			}, {
				$project: {
					newObjK: 1,
					newObjV: {
						$map: {
							input: "$newObjV",
							as: "res",
							in: {
								label: "$$res",
								value: "$$res",
								dispatchField: {
									project: "$$res"
								},
								displayField: "$$res"
							}
						}
					}
				}
			}, {
				$project: {
					newObj: [
						{
							k: "$newObjK",
							v: "$newObjV"
						}
					]
				}
			}, {
				$project: {
					_id: 0,
					newObj: {
						$arrayElemAt: [
							"$newObj", 0
						]
					}
				}
			}, {
				$group: {
					_id: {},
					res: {
						$addToSet: "$newObj"
					}
				}
			}, {
				$project: {
					_id: 0,
					res: {
						$arrayToObject: "$res"
					}
				}
			}
		],
		limit: 100,
		adminCallFromApp: ""
	},
	ataglanceListSuppliers: {
		collection: AtAGlanceToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						supplier: "$supplier",
						auditedCompany: "$auditedCompany"
					},
					siteUniqueId: {
						$first: "$siteUniqueId"
					}
				}
			}, {
				$project: {
					label: "$_id.supplier",
					value: "$_id.supplier",
					dispatchField: {
						supplier: "$_id.supplier",
						auditedCompany: "$_id.auditedCompany",
						siteUniqueId: "$siteUniqueId"
					},
					displayField: {
						$concat: [
							"$_id.supplier", " ", "-", " ", "$_id.auditedCompany"
						]
					},
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "ataglance"
	},
	supplyRListSuppliers: {
		collection: SupplyRToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						supplier: "$supplier",
						auditedCompany: "$auditedCompany"
					},
					siteLabelId: {
						$first: "$siteLabelId"
					}
				}
			}, {
				$project: {
					label: "$_id.supplier",
					value: "$_id.supplier",
					dispatchField: {
						supplier: "$_id.supplier",
						auditedCompany: "$_id.auditedCompany",
						siteLabelId: "$siteLabelId"
					},
					displayField: {
						$concat: [
							"$_id.supplier", " ", "-", " ", "$_id.auditedCompany"
						]
					},
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	clarityListSuppliers: {
		collection: ClarityToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel"],
		query: [
			{
				$group: {
					_id: {
						supplier: "$supplier",
						company: "$company"
					}
				}
			}, {
				$project: {
					label: "$_id.supplier",
					value: "$_id.supplier",
					dispatchField: {
						supplier: "$_id.supplier",
						company: "$_id.company"
					},
					displayField: {
						$concat: [
							"$_id.supplier", " ", "-", " ", "$_id.company"
						]
					},
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "clarity"
	},
	verkorListSuppliers: {
		collection: VerkorToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						supplier: "$supplier",
						auditedCompany: "$auditedCompany"
					},
					siteLabelId: {
						$first: "$siteLabelId"
					}
				}
			}, {
				$project: {
					label: "$_id.supplier",
					value: "$_id.supplier",
					dispatchField: {
						supplier: "$_id.supplier",
						auditedCompany: "$_id.auditedCompany",
						siteLabelId: "$siteLabelId"
					},
					displayField: {
						$concat: [
							"$_id.supplier", " ", "-", " ", "$_id.auditedCompany"
						]
					},
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "verkor"
	},
	ataglanceListAuditedCompany: {
		collection: AtAGlanceToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						auditedCompany: "$auditedCompany"
					},
					factoryBranch: {
						$push: {
							factoryBranch: "$factoryBranch"
						}
					},
					factoryIndex: {
						$push: {
							factoryIndex: "$factoryIndex"
						}
					},
					projectName: {
						$first: "$projectName"
					}
				}
			}, {
				$project: {
					label: "$_id.auditedCompany",
					value: "$_id.auditedCompany",
					dispatchField: {
						auditedCompany: "$_id.auditedCompany"
					},
					displayField: "$_id.auditedCompany",
					_id: 0,
					allBranchIndex: {
						$map: {
							input: "$factoryBranch",
							in: {
								$mergeObjects: [
									"$$this", {
										$arrayElemAt: [
											"$factoryIndex", {
												$indexOfArray: [
													"$factoryBranch", "$$this"
												]
											}
										]
									}
								]
							}
						}
					},
					projectName: "$projectName"
				}
			}, {
				$unwind: {
					path: "$allBranchIndex",
					preserveNullAndEmptyArrays: true
				}
			}, {
				$addFields: {
					isBranchEmptyStr: {
						$ne: [
							"$allBranchIndex.factoryBranch", ""
						]
					},
					factoryIndex: "$allBranchIndex.factoryIndex"
				}
			}, {
				$addFields: {
					displayField: {
						$cond: [
							{
								$and: [
									{
										$eq: [
											{
												$type: "$allBranchIndex.factoryBranch"
											}, "missing"
										]
									}, {
										$eq: [
											{
												$type: "$allBranchIndex.factoryIndex"
											}, "missing"
										]
									}
								]
							}, "$displayField", {
								$cond: [
									"$isBranchEmptyStr", {
										$concat: [
											"$displayField", " - ", {
												$ifNull: [
													"$allBranchIndex.factoryBranch", "$allBranchIndex.factoryIndex"
												]
											}
										]
									}, {
										$concat: [
											"$displayField", " - ", {
												$substr: [
													"$allBranchIndex.factoryIndex", 0, -1
												]
											}
										]
									}
								]
							}
						]
					},
					dispatchField: {
						$cond: [
							{
								$and: [
									{
										$eq: [
											{
												$type: "$allBranchIndex.factoryBranch"
											}, "missing"
										]
									}, {
										$eq: [
											{
												$type: "$allBranchIndex.factoryIndex"
											}, "missing"
										]
									}
								]
							}, "$dispatchField", {
								$mergeObjects: [
									"$dispatchField", {
										factoryIndex: "$allBranchIndex.factoryIndex"
									}
								]
							}
						]
					}
				}
			}, {
				$group: {
					_id: {
						projectName: "$projectName",
						displayField: "$displayField",
						factoryIndex: "$factoryIndex"
					},
					label: {
						$first: "$label"
					},
					value: {
						$first: "$value"
					},
					dispatchField: {
						$first: "$dispatchField"
					}
				}
			}, {
				$project: {
					label: 1,
					value: 1,
					dispatchField: 1,
					displayField: "$_id.displayField",
					factoryIndex: "$_id.factoryIndex",
					projectName: "$_id.projectName",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "ataglance"
	},
	supplyRListAuditedCompany: {
		collection: SupplyRToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						auditedCompany: "$auditedCompany"
					},
					factoryBranch: {
						$push: {
							factoryBranch: "$factoryBranch"
						}
					},
					factoryIndex: {
						$push: {
							factoryIndex: "$factoryIndex"
						}
					},
					projectName: {
						$first: "$projectName"
					}
				}
			}, {
				$project: {
					label: "$_id.auditedCompany",
					value: "$_id.auditedCompany",
					dispatchField: {
						auditedCompany: "$_id.auditedCompany"
					},
					displayField: "$_id.auditedCompany",
					_id: 0,
					allBranchIndex: {
						$map: {
							input: "$factoryBranch",
							in: {
								$mergeObjects: [
									"$$this", {
										$arrayElemAt: [
											"$factoryIndex", {
												$indexOfArray: [
													"$factoryBranch", "$$this"
												]
											}
										]
									}
								]
							}
						}
					},
					projectName: "$projectName"
				}
			}, {
				$unwind: {
					path: "$allBranchIndex",
					preserveNullAndEmptyArrays: true
				}
			}, {
				$addFields: {
					isBranchEmptyStr: {
						$ne: [
							"$allBranchIndex.factoryBranch", ""
						]
					},
					factoryIndex: "$allBranchIndex.factoryIndex"
				}
			}, {
				$addFields: {
					displayField: {
						$cond: [
							{
								$and: [
									{
										$eq: [
											{
												$type: "$allBranchIndex.factoryBranch"
											}, "missing"
										]
									}, {
										$eq: [
											{
												$type: "$allBranchIndex.factoryIndex"
											}, "missing"
										]
									}
								]
							}, "$displayField", {
								$cond: [
									"$isBranchEmptyStr", {
										$concat: [
											"$displayField", " - ", {
												$ifNull: [
													"$allBranchIndex.factoryBranch", "$allBranchIndex.factoryIndex"
												]
											}
										]
									}, {
										$concat: [
											"$displayField", " - ", {
												$substr: [
													"$allBranchIndex.factoryIndex", 0, -1
												]
											}
										]
									}
								]
							}
						]
					},
					dispatchField: {
						$cond: [
							{
								$and: [
									{
										$eq: [
											{
												$type: "$allBranchIndex.factoryBranch"
											}, "missing"
										]
									}, {
										$eq: [
											{
												$type: "$allBranchIndex.factoryIndex"
											}, "missing"
										]
									}
								]
							}, "$dispatchField", {
								$mergeObjects: [
									"$dispatchField", {
										factoryIndex: "$allBranchIndex.factoryIndex"
									}
								]
							}
						]
					}
				}
			}, {
				$group: {
					_id: {
						projectName: "$projectName",
						displayField: "$displayField",
						factoryIndex: "$factoryIndex"
					},
					label: {
						$first: "$label"
					},
					value: {
						$first: "$value"
					},
					dispatchField: {
						$first: "$dispatchField"
					}
				}
			}, {
				$project: {
					label: 1,
					value: 1,
					dispatchField: 1,
					displayField: "$_id.displayField",
					factoryIndex: "$_id.factoryIndex",
					projectName: "$_id.projectName",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	clarityListCompany: {
		collection: ClarityToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel"],
		query: [
			{
				$group: {
					_id: {
						company: "$company"
					}
				}
			}, {
				$project: {
					label: "$_id.company",
					value: "$_id.company",
					dispatchField: {
						company: "$_id.company"
					},
					displayField: "$_id.company",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "clarity"
	},
	verkorListAuditedCompany: {
		collection: VerkorToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						auditedCompany: "$auditedCompany"
					}
				}
			}, {
				$project: {
					label: "$_id.auditedCompany",
					value: "$_id.auditedCompany",
					dispatchField: {
						auditedCompany: "$_id.auditedCompany"
					},
					displayField: "$_id.auditedCompany",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "verkor"
	},
	supplyRListCountries: {
		collection: "worldCountries",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {
						country: "$country"
					}
				}
			}, {
				$project: {
					label: "$_id.country",
					value: "$_id.country",
					dispatchField: {
						country: "$_id.country"
					},
					displayField: "$_id.country",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	clarityListCountries: {
		collection: "worldCountries",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {
						country: "$country"
					}
				}
			}, {
				$project: {
					label: "$_id.country",
					value: "$_id.country",
					dispatchField: {
						country: "$_id.country"
					},
					displayField: "$_id.country",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "clarity"
	},
	verkorListCountries: {
		collection: "worldCountries",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {
						country: "$country"
					}
				}
			}, {
				$project: {
					label: "$_id.country",
					value: "$_id.country",
					dispatchField: {
						country: "$_id.country"
					},
					displayField: "$_id.country",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "verkor"
	},
	supplyRListRegion: {
		collection: "worldCountries",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {
						region: "$region_un"
					}
				}
			}, {
				$project: {
					label: "$_id.region",
					value: "$_id.region",
					dispatchField: {
						region: "$_id.region"
					},
					displayField: "$_id.region",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	clarityListRegion: {
		collection: "worldCountries",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {
						region: "$region_un"
					}
				}
			}, {
				$project: {
					label: "$_id.region",
					value: "$_id.region",
					dispatchField: {
						region: "$_id.region"
					},
					displayField: "$_id.region",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "clarity"
	},
	verkorListRegion: {
		collection: "worldCountries",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {
						region: "$region_un"
					}
				}
			}, {
				$project: {
					label: "$_id.region",
					value: "$_id.region",
					dispatchField: {
						region: "$_id.region"
					},
					displayField: "$_id.region",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "verkor"
	},
	ataglanceListProduct: {
		collection: AtAGlanceToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						product: "$product"
					}
				}
			}, {
				$project: {
					label: "$_id.product",
					value: "$_id.product",
					dispatchField: {
						product: "$_id.product"
					},
					displayField: "$_id.product",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "ataglance"
	},
	supplyRListProduct: {
		collection: "SupplyRLoVsProducts",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	clarityListProduct: {
		collection: ClarityToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel"],
		query: [
			{
				$group: {
					_id: {
						product: "$product"
					}
				}
			}, {
				$project: {
					label: "$_id.product",
					value: "$_id.product",
					dispatchField: {
						product: "$_id.product"
					},
					displayField: "$_id.product",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "clarity"
	},
	verkorListProduct: {
		collection: "VerkorLoVsProducts",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [],
		limit: 5000,
		adminCallFromApp: "verkor"
	},
	ataglanceFinalProducts: {
		collection: AtAGlanceToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			}, {
				$project: {
					label: "$_id.finalProduct",
					value: "$_id.finalProduct",
					dispatchField: {
						product: "$_id.finalProduct"
					},
					displayField: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 500,
		adminCallFromApp: "ataglance"
	},
	supplyRListFinalProduct: {
		collection: "SupplyRClientParameters",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$unwind: {
					path: "$finalProductLov"
				}
			}, {
				$group: {
					_id: {
						finalProduct: "$finalProductLov"
					}
				}
			}, {
				$project: {
					label: "$_id.finalProduct",
					value: "$_id.finalProduct",
					dispatchField: {
						product: "$_id.finalProduct"
					},
					displayField: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	clarityListFinalProduct: {
		collection: ClarityToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel"],
		query: [
			{
				$unwind: {
					path: "$finalProduct"
				}
			}, {
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			}, {
				$project: {
					label: "$_id.finalProduct",
					value: "$_id.finalProduct",
					dispatchField: {
						product: "$_id.finalProduct"
					},
					displayField: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	verkorListFinalProduct: {
		collection: "VerkorClientParameters",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$unwind: {
					path: "$finalProductLov"
				}
			}, {
				$group: {
					_id: {
						finalProduct: "$finalProductLov"
					}
				}
			}, {
				$project: {
					label: "$_id.finalProduct",
					value: "$_id.finalProduct",
					dispatchField: {
						product: "$_id.finalProduct"
					},
					displayField: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "verkor"
	},
	ataglanceListClient: {
		collection: AtAGlanceClientParameters,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {
						client: "$client"
					}
				}
			}, {
				$project: {
					label: "$_id.client",
					value: "$_id.client",
					dispatchField: {
						client: "$_id.client"
					},
					displayField: "$_id.client",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "ataglance"
	},
	supplyRListClient: {
		collection: "SupplyRClientParameters",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {
						client: "$client"
					}
				}
			}, {
				$project: {
					label: "$_id.client",
					value: "$_id.client",
					dispatchField: {
						client: "$_id.client"
					},
					displayField: "$_id.client",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	clarityListClient: {
		collection: "ClarityClientParameters",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {
						client: "$client"
					}
				}
			}, {
				$project: {
					label: "$_id.client",
					value: "$_id.client",
					dispatchField: {
						client: "$_id.client"
					},
					displayField: "$_id.client",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "clarity"
	},
	verkorListClient: {
		collection: "VerkorClientParameters",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$group: {
					_id: {
						client: "$client"
					}
				}
			}, {
				$project: {
					label: "$_id.client",
					value: "$_id.client",
					dispatchField: {
						client: "$_id.client"
					},
					displayField: "$_id.client",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "verkor"
	},
	supplyRListCriticality: {
		collection: "SupplyRClientParameters",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$unwind: {
					path: "$projectScope"
				}
			}, {
				$project: {
					res: {
						$objectToArray: "$projectScope"
					}
				}
			}, {
				$group: {
					_id: {},
					criticality: {
						$addToSet: "$res.v"
					}
				}
			}, {
				$unwind: {
					path: "$criticality"
				}
			}, {
				$addFields: {
					criticality: {
						$arrayElemAt: [
							"$criticality", 0
						]
					}
				}
			}, {
				$group: {
					_id: {
						criticality: "$criticality"
					}
				}
			}, {
				$project: {
					label: "$_id.criticality",
					value: "$_id.criticality",
					dispatchField: {
						criticality: "$_id.criticality"
					},
					displayField: "$_id.criticality",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	clarityListCriticality: {
		collection: "ClarityAppParameters",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: [],
		query: [
			{
				$unwind: {
					path: "$criticality"
				}
			}, {
				$group: {
					_id: {
						criticality: "$criticality"
					}
				}
			}, {
				$project: {
					label: "$_id.criticality",
					value: "$_id.criticality",
					dispatchField: {
						criticality: "$_id.criticality"
					},
					displayField: "$_id.criticality",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "clarity"
	},
	verkorListCriticality: {
		collection: "VerkorClientParameters",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$unwind: {
					path: "$projectScope"
				}
			}, {
				$project: {
					res: {
						$objectToArray: "$projectScope"
					}
				}
			}, {
				$group: {
					_id: {},
					criticality: {
						$addToSet: "$res.v"
					}
				}
			}, {
				$unwind: {
					path: "$criticality"
				}
			}, {
				$addFields: {
					criticality: {
						$arrayElemAt: [
							"$criticality", 0
						]
					}
				}
			}, {
				$group: {
					_id: {
						criticality: "$criticality"
					}
				}
			}, {
				$project: {
					label: "$_id.criticality",
					value: "$_id.criticality",
					dispatchField: {
						criticality: "$_id.criticality"
					},
					displayField: "$_id.criticality",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "verkor"
	},
	ataglanceListType: {
		collection: AtAGlanceToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						type: "$type"
					}
				}
			}, {
				$project: {
					label: "$_id.type",
					value: "$_id.type",
					dispatchField: {
						type: "$_id.type"
					},
					displayField: "$_id.type",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "ataglance"
	},
	supplyRListType: {
		collection: "SupplyRLoVsTypes",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [],
		limit: 5000,
		adminCallFromApp: "supplyR"
	},
	clarityListType: {
		collection: ClarityToR,
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel"],
		query: [
			{
				$group: {
					_id: {
						type: "$type"
					}
				}
			}, {
				$project: {
					label: "$_id.type",
					value: "$_id.type",
					dispatchField: {
						type: "$_id.type"
					},
					displayField: "$_id.type",
					_id: 0
				}
			}
		],
		limit: 5000,
		adminCallFromApp: "clarity"
	},
	verkorListType: {
		collection: "VerokorLoVsTypes",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [],
		limit: 5000,
		adminCallFromApp: "verkor"
	},
	SupplyRRejectedPAQ: {
		collection: "SupplyRRejectedPAQ",
		pages: ["PROJECT"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		limit: 10000,
		query: [
			{
				$project: {
					_id: 0,
					projectName: 1,
					paqId: 1,
					rootAuditedCompany: 1,
					rootTierLevel: 1,
					rejectedFactory: "$rejectReason.factoryName",
					type: "$type",
					rejectedFactoryTierLevel: "$rejectReason.tierLevel",
					rejectedField: "$rejectReason.field",
					rejectMsg: "$rejectReason.errorMsg",
					rejectValue: "$rejectReason.errorValue",
					rejectDate: {
						$dateFromString: {
							dateString: "$rejectDate"
						}
					}
				}
			}, {
				$sort: {
					rejectedDate: 1,
					rootAuditedCompany: 1,
					rejectedFactory: 1,
					rejectedField: 1
				}
			}
		]
	},
	exportNormalizationLog: {
		collection: "SupplyRPAQAdditionsNormalizationLog",
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		limit: 10000,
		query: [
			{
				$match: {
					originData: {
						$nin: [
							null, undefined, []
						]
					}
				}
			}, {
				$addFields: {
					normalized: "$$ROOT",
					"originData.publicationDate": {
						$ifNull: [
							"$publicationDate", "$normalized.publicationDate"
						]
					},
					"originData.PAQfactory": "$PAQfactory"
				}
			}, {
				$project: {
					"normalized.originData": 0
				}
			}, {
				$addFields: {
					"originData._id": "$_id",
					"normalized.isNormalized": true,
					"originData.isNormalized": false
				}
			}, {
				$addFields: {
					split: [
						"$originData", "$normalized"
					]
				}
			}, {
				$unwind: {
					path: "$split"
				}
			}, {
				$replaceRoot: {
					newRoot: {
						$cond: [
							{
								$eq: [
									"$split.isNormalized", true
								]
							}, "$normalized", "$originData"
						]
					}
				}
			}, {
				$lookup: {
					from: "SupplyR-ToR",
					localField: "nextSupplier",
					foreignField: "siteLabelId",
					as: "tor"
				}
			}, {
				$addFields: {
					nextSupplierId: "$nextSupplier",
					nextSupplier: {
						$arrayElemAt: [
							"$tor.auditedCompany", 0
						]
					}
				}
			}, {
				$project: {
					projectName: {
						$ifNull: [
							"$projectName", undefined
						]
					},
					isNormalized: {
						$cond: [
							{
								$eq: [
									"$isNormalized", true
								]
							}, "AFTER", "BEFORE"
						]
					},
					PAQfactory: {
						$ifNull: [
							"$PAQfactory", undefined
						]
					},
					auditedCompany: {
						$ifNull: [
							"$auditedCompany", undefined
						]
					},
					siteLabelId: {
						$ifNull: [
							"$siteLabelId", undefined
						]
					},
					tierLabel: {
						$ifNull: [
							"$tierLabel", undefined
						]
					},
					address: {
						$ifNull: [
							"$address", undefined
						]
					},
					city: {
						$ifNull: [
							"$city", undefined
						]
					},
					country: {
						$ifNull: [
							"$country", undefined
						]
					},
					region: {
						$ifNull: [
							"$region", undefined
						]
					},
					supplier: {
						$ifNull: [
							"$supplier", undefined
						]
					},
					nextSupplier: {
						$ifNull: [
							"$nextSupplier", undefined
						]
					},
					nextSupplierId: {
						$ifNull: [
							"$nextSupplierId", undefined
						]
					},
					publicationDate: {
						$ifNull: [
							"$publicationDate", undefined
						]
					},
					PAQid: {
						$ifNull: [
							"$PAQid", undefined
						]
					},
					warningMsg: {
						$cond: [
							{
								$eq: [
									"$nextSupplierNotFound", true
								]
							}, "Not able to find previous tier connection", undefined
						]
					}
				}
			}, {
				$sort: {
					publicationDate: -1,
					_id: 1,
					isNormalized: -1,
					PAQid: 1
				}
			}
		]
	},
	clarityDomains: {
		collection: "ClarityDomains",
		fetch: "find",
		facetsToRefine: [],
		limit: 500,
		adminCallFromApp: ""
	},
	clarityV4Referential: {
		collection: "ClarityV4ReferentialRaw",
		fetch: "find",
		limit: 10000,
		facetsToRefine: [],
		query: [],
		adminCallFromApp: "clarity",
		refreshed: true
	},
	clarityTranslations: {
		collection: "ClarityTranslations",
		fetch: "find",
		facetsToRefine: [],
		limit: 500,
		adminCallFromApp: ""
	},
	clarityTranslationsActivity: {
		collection: "ClarityTranslationsActivity",
		fetch: "find",
		facetsToRefine: [],
		limit: 500,
		adminCallFromApp: ""
	}
};

/**
 * Init Refine of the Application
 *
 * @param {Object} adminInitRefines
 * Object representing the initial refine of the datasets
 * in the App slice, the key being the name of the field
 * in the DB Collection to filter on, the value being the value to filter on
 */
export const adminInitRefines = {};

// Define the MapToolTip
export const fieldsTooltip = [
	{ label: "Audited Company", key: "auditedCompany" },
	{ label: "Supplier", key: "supplier" },
	{ label: "Product", key: "product" },
	{ label: "Address", key: "address" }
];

/**
 * Define the rule for the Marker color on the map
 */
export const getMarkerColor = (status) => {
	if (!status) return "#c9bbbd";
	if (status === "Not Compliant") return "#FF475C";
	return "#6DC3A0";
};

/**
 * Menu clear Refine Keys
 */
export const mainMenuClearRefineKeys = [{ refine: "appAction" }, { refine: "supplyChainAction" }, { refine: "projectName" }, { refine: "spaceLabel" },
	{ refine: "secLevelMenu" }, { refine: "thirdLevelMenu" }, { refine: "fourthLevelMenu" }, { refine: "site" }, { refine: "user" },
	{ refine: "finalProduct" }, { refine: "mapView" }, { refine: "application" }, { refine: "clarityV4RefEntry" }
];
export const secLevelMenuClearRefineKeys = [{ refine: "appAction" }, { refine: "supplyChainAction" }, { refine: "projectName" }, { refine: "spaceLabel" },
	{ refine: "site" }, { refine: "user" }, { refine: "thirdLevelMenu" }, { refine: "fourthLevelMenu" }, { refine: "finalProduct" },
	{ refine: "groupName" }, { refine: "action" }, { refine: "mapView" }, { refine: "application" }, { refine: "clarityV4Referential" },
	{ refine: "clarityV4RefEntry" }
];
export const thirdLevelMenuClearRefineKeys = [{ refine: "appAction" }, { refine: "supplyChainAction" }, { refine: "projectName" },
	{ refine: "spaceLabel" }, { refine: "site" }, { refine: "user" }, { refine: "finalProduct" }, { refine: "groupName" },
	{ refine: "action" }, { refine: "mapView" }, { refine: "application" }, { refine: "clarityV4Referential" }, { refine: "fourthLevelMenu" },
	{ refine: "clarityV4RefEntry" }, { refine: "domainsOptions" }
];
export const fourthLevelMenuClearRefineKeys = [{ refine: "appAction" }, { refine: "supplyChainAction" }, { refine: "projectName" },
	{ refine: "spaceLabel" }, { refine: "site" }, { refine: "user" }, { refine: "finalProduct" }, { refine: "groupName" },
	{ refine: "action" }, { refine: "mapView" }, { refine: "application" }, { refine: "clarityV4Referential" }, { refine: "fourthLevelMenu" },
	{ refine: "clarityV4RefEntry" }, { refine: "domainsOptions" }
];

// export const fourthLevelMenuClearRefineKeys = [{ refine: "fourthLevelMenu" }, { refine: "spaceLabel" }, { refine: "appAction" },
// 	{ refine: "clarityV4Referential" }, { refine: "clarityV4RefEntry" }
// ];

// ************************** select field configuration ************************
/**
 * React-select boolean option
 */
export const booleanOptions = [
	{ value: true, label: "true" },
	{ value: false, label: "false" }
];

/**
 * React-select permission option
 */
export const permissionOptions = [
	{ value: "Admin", label: "Admin" },
	{ value: "Member", label: "Member" },
	{ value: "Viewer", label: "Viewer" }
];

/**
 * React-select status option
 */
export const statusOptions = [
	{ value: "Active", label: "Active" },
	{ value: "Inactive", label: "Inactive" }
];

/**
 * React-select validated option
 */
export const validatedOptions = [
	{ value: "True", label: "True", default: true },
	{ value: "On Hold", label: "On Hold" },
	{ value: "False", label: "False" }
];

/**
 * React-select validated option
 */
export const supplychainParamsOptions = [
	{ value: "siteUniqueId", label: "Site Unique ID" },
	{ value: "siteLabelId", label: "Site Label ID" },
	{ value: "siteId", label: "Site ID" }
];

// not used anymore
export const domainsOptions = [
	{ value: "HUMAN CAPITAL", label: "HUMAN CAPITAL" },
	{ value: "Health & Safety", label: "Health & Safety" },
	{ value: "BUSINESS MODEL & INNOVATION", label: "BUSINESS MODEL & INNOVATION" },
	{ value: "Data Security & Privacy", label: "Data Security & Privacy" },
	{ value: "Business Performance", label: "Business Performance" },
	{ value: "Product Quality & Process Control", label: "Product Quality & Process Control" },
	{ value: "Environment", label: "Environment" },
	{ value: "Environment & Biodiversity", label: "Environment & Biodiversity" },
	{ value: "Climate Change", label: "Climate Change" },
	{ value: "SOCIAL CAPITAL", label: "SOCIAL CAPITAL" },
	{ value: "Social", label: "Social" },
	{ value: "LEADERSHIP & GOVERNANCE", label: "LEADERSHIP & GOVERNANCE" },
	{ value: "Business Ethics", label: "Business Ethics" },
	{ value: "BV Code of Conduct", label: "BV Code of Conduct" },
	{ value: "NET ZERO", label: "NET ZERO" },
	{ value: "ANIMAL WELFARE", label: "ANIMAL WELFARE" },
	{ value: "TRACEABILITY", label: "TRACEABILITY" },
	{ value: "Responsible Sourcing", label: "Responsible Sourcing" },
	{ value: "ETHICAL TRADE", label: "ETHICAL TRADE" },
	{ value: "CLARITY ONE", label: "CLARITY ONE" },
	{ value: "Social Compliance", label: "Social Compliance" },
	{ value: "German Supply Chain Act", label: "German Supply Chain Act" },
	{ value: "Achieving Net-Zero - Building & Infrastructure - Organisation", label: "Achieving Net-Zero - Building & Infrastructure - Organisation" },
	{ value: "Achieving Net-Zero - Building CAPEX", label: "Achieving Net-Zero - Building CAPEX" },
	{ value: "Achieving Net-Zero - Infrastructure CAPEX", label: "Achieving Net-Zero - Infrastructure CAPEX" },
	{ value: "Achieving Net-Zero - Building OPEX", label: "Achieving Net-Zero - Building OPEX" },
	{ value: "Achieving Net-Zero - Infrastructure OPEX", label: "Achieving Net-Zero - Infrastructure OPEX" },
	{ value: "GRI - Universal Standards", label: "GRI - Universal Standards" },
	{ value: "GRI - Economic", label: "GRI - Economic" },
	{ value: "GRI - Environment", label: "GRI - Environment" },
	{ value: "GRI - Social", label: "GRI - Social" }
];

/**
 * React-select domains colors options
 */
export const colorOptions = [
	{ value: "#B81D7E", label: "Magenta", color: "#B81D7E" },
	{ value: "#FFC362", label: "Yellow", color: "#FFC362" },
	{ value: "#FF8585", label: "Peach", color: "#FF8585" },
	{ value: "#662CAC", label: "Purple", color: "#662CAC" },
	{ value: "#5963B7", label: "Blue", color: "#5963B7" },
	{ value: "#44B9B4", label: "Mint green", color: "#44B9B4" },
	{ value: "#FF475C", label: "Red", color: "#FF475C" },
	{ value: "#3D49AB", label: "Navy Blue", color: "#3D49AB" },
	{ value: "#CCDB73", label: "Light green", color: "#CCDB73" }
];

/**
 * React-select domains logo options
 */
export const logoOptions = [
	{ value: "../images/Clarity/Modules/icn-business-ethics.svg", label: "ethics" },
	{ value: "../images/Clarity/Modules/icn-planet.svg", label: "planet" },
	{ value: "../images/Clarity/Modules/icn-repsonsible-sourcing.svg", label: "repsonsible-sourcing" },
	{ value: "../images/Clarity/Modules/icn-ethical-trade.svg", label: "ethical-trade" },
	{ value: "../images/Clarity/ClarityOutline.svg", label: "clarity" },
	{ value: "../images/Clarity/Modules/icn-social.svg", label: "social" },
	{ value: "../images/Clarity/Modules/icn-performance-business.svg", label: "performance" },
	{ value: "../images/Clarity/Modules/icn-env-bio-1.svg", label: "env-bio" },
	{ value: "../images/Clarity/Modules/icn-health-safety.svg", label: "health-safety" },
	{ value: "../images/Clarity/Modules/icn-performance.svg", label: "performance" },
	{ value: "../images/Clarity/Modules/icn-cyber.svg", label: "cyber" },
	{ value: "../images/Clarity/Modules/icn-quality.svg", label: "quality" },
	{ value: "../images/Clarity/Modules/icn-climchange.svg", label: "climat-change" },
	{ value: "../images/Clarity/Modules/icn-animal-paw.svg", label: "animal" }
];

export const languageTranslationOptions = [
	{ value: "en", label: "English" },
	{ value: "fr", label: "French" },
	{ value: "es", label: "Spanish" },
	{ value: "de", label: "German" },
	{ value: "it", label: "Italian" },
	{ value: "pt", label: "Portuguese" },
	{ value: "ru", label: "Russian" },
	{ value: "zh-Hant", label: "Chinese Traditional" },
	{ value: "zh-Hans", label: "Chinese Simplified" },
	{ value: "ja", label: "Japanese" },
	{ value: "ko", label: "Korean" },
	{ value: "ar", label: "Arabic" },
	{ value: "hi", label: "Hindi" },
	{ value: "ur", label: "Urdu" },
	{ value: "sw", label: "Swahili" },
	{ value: "nl", label: "Dutch" },
	{ value: "tr", label: "Turkish" },
	{ value: "sv", label: "Swedish" },
	{ value: "pl", label: "Polish" },
	{ value: "fi", label: "Finnish" },
	{ value: "el", label: "Greek" },
	{ value: "no", label: "Norwegian" },
	{ value: "cs", label: "Czech" },
	{ value: "da", label: "Danish" },
	{ value: "ro", label: "Romanian" },
	{ value: "hu", label: "Hungarian" },
	{ value: "th", label: "Thai" },
	{ value: "id", label: "Indonesian" },
	{ value: "ms", label: "Malay" },
	{ value: "vi", label: "Vietnamese" },
	{ value: "he", label: "Hebrew" }
];

export const languageToCode = (language) => {
	const code = languageTranslationOptions.find((opt) => (opt.label.toLocaleLowerCase() === language?.toLocaleLowerCase()));
	if (!code) {
		throw new Error(`Language ${language} not found`);
	} else {
		return code.value;
	}
};

export const codeToLanguage = (code) => {
	const language = languageTranslationOptions.find((opt) => (opt.value.toLocaleLowerCase() === code?.toLocaleLowerCase()));
	if (!language) {
		throw new Error(`Language code ${code} not found`);
	} else {
		return language.label;
	}
};

/**
 * return select options when field type is select
 */
export const currentFieldOptions = (value) => {
	if (value === "permission") return permissionOptions;
	if (value === "status") return statusOptions;
	if (value === "validated" || value === "supplyR.validated") return validatedOptions;
	if (value === "supplychainParams.groupKey") return supplychainParamsOptions;
	if (value === "domainColor") return colorOptions;
	if (value === "domainLogo") return logoOptions;
	if (value === "language") return languageTranslationOptions;
	// not used anymore
	if (value === "clarity.activeIndicators" || value === "activeIndicators"
	|| value === "clarity.pushedIndicators" || value === "pushedIndicators") {
		return domainsOptions;
	}

	return booleanOptions;
};

/**
 * field in this array will be defined as select field
 */
export const selectFields = [
	"permission",
	"status",
	"allowReportLink",
	"allowRequestAudit",
	"isNotDemo",
	"kpis",
	"displaySectionLevel2",
	"supplyChain",
	"displayFactoryCode",
	"displaySupplierCode",
	"productTraceability",
	"PAQpreprocessed",
	"riskAssessment",
	"siteDeactivation",
	"statusTracking",
	"validated",
	"trackActionPlan",
	"clarity.displaySectionLevel2",
	"clarity.inactive",
	"clarity.isNotDemo",
	"clarity.kpis",
	"clarity.productTraceability",
	"clarity.riskAssessment",
	"clarity.siteDeactivation",
	"clarity.supplyChain",
	"clarity.statusTracking",
	"supplyR.allowReportLink",
	"supplyR.allowRequestAudit",
	"supplyR.displayFactoryCode",
	"supplyR.displaySupplierCode",
	"supplyR.validated",
	"supplychainParams.isCommon",
	"supplychainParams.groupKey",
	"scoreBased",
	"geoScope",
	"domainColor",
	"domainLogo",
	"language"
];

/**
 * field in this array will be defined as multi select field
 */
export const multiSelectFields = [
	"clarity.activeIndicators",
	"activeIndicators",
	"clarity.pushedIndicators",
	"pushedIndicators"
];

// ************************** select field configuration end ************************

/**
 * return type of current field
 */
export const typeSelector = (arr, { secLevelMenu, supplyChainAction, appAction }) => {
	const [key, value] = arr;
	if (value === null || value === undefined) return "string";
	if (key === "message") return "textarea";
	if (appAction === "updateCLarityTranslations" && (["description", "info", "answer", "message1", "desc"].includes(key))) {
		return "textarea";
	}
	if (key === "inactive") return "checkbox";
	if (key === "targetDate") return "date";
	if (key === "logo" && (appAction !== "createNewDomain" && appAction !== "updateDomain")) return "upload";
	if (key === "supplychainParams") return "conditional";
	if (supplyChainAction === "createNode" || supplyChainAction === "createBranch") {
		if (["supplier", "auditedCompany", "company", "country", "region", "finalProduct",
			"targetProduct", "product", "type", "client", "criticality", "ADMIN"].includes(key)) return "search";
	}
	if (supplyChainAction === "updateNode" || supplyChainAction === "updateLocation") {
		if (["supplier", "auditedCompany", "company", "country", "region", "finalProduct",
			"targetProduct", "product", "type", "client", "criticality", "ADMIN"].includes(key)) return "search";
	}
	if (["address"].includes(key)) return "asyncsearch";
	if (["clarity", "supplyR"].includes(key)) {
		return "objectGroup";
	}
	if (typeof value === "object") {
		if (key === "clarity.activeIndicators" || key === "activeIndicators"
		|| key === "clarity.pushedIndicators" || key === "pushedIndicators") {
			return "multiSelect";
		}
		return Array.isArray(value) ? "array" : "object";
	}
	if (typeof value !== "object") {
		return selectFields.includes(key) ? appAction !== "updateCLarityTranslations" ? "select" : typeof value : typeof value;
	}
};

/**
 * search field collection selector
 * searchSelector can return an array which contains multiply datasets for a single field
 * so the return value can be either ex "clarityListSuppliers" or [clarityListSuppliers1, clarityListSuppliers2]
 */
export const searchSelector = (app, field) => {
	if (app === "clarity") {
		switch (field) {
			case "supplier":
				return "clarityListSuppliers";
			case "company":
				return "clarityListCompany";
			case "country":
				return "clarityListCountries";
			case "region":
				return "clarityListRegion";
			case "finalProduct":
				return "clarityListFinalProduct";
			case "targetProduct":
				return ["clarityListProduct", "clarityListFinalProduct"];
			case "product":
				return "clarityListProduct";
			case "type":
				return "clarityListType";
			case "client":
				return "clarityListClient";
			case "criticality":
				return "clarityListCriticality";
			default:
				break;
		}
	} else if (app === "supplyR") {
		switch (field) {
			case "supplier":
				return "supplyRListSuppliers";
			case "auditedCompany":
				return "supplyRListAuditedCompany";
			case "country":
				return "supplyRListCountries";
			case "region":
				return "supplyRListRegion";
			case "finalProduct":
				return "supplyRListFinalProduct";
			case "targetProduct":
				return ["supplyRListProduct", "supplyRListFinalProduct"];
			case "product":
				return "supplyRListProduct";
			case "type":
				return "supplyRListType";
			case "client":
				return "supplyRListClient";
			case "criticality":
				return "supplyRListCriticality";
			default:
				break;
		}
	} else if (app === "verkor") {
		switch (field) {
			case "supplier":
				return "verkorListSuppliers";
			case "auditedCompany":
				return "verkorListAuditedCompany";
			case "country":
				return "verkorListCountries";
			case "region":
				return "verkorListRegion";
			case "finalProduct":
				return "verkorListFinalProduct";
			case "targetProduct":
				return ["verkorListProduct", "verkorListFinalProduct"];
			case "product":
				return "verkorListProduct";
			case "type":
				return "verkorListType";
			case "client":
				return "verkorListClient";
			case "criticality":
				return "verkorListCriticality";
			default:
				break;
		}
	} else if (app === "compass") {
		switch (field) {
			case "supplier":
				break;
			case "country":
				break;
			case "region":
				break;
			case "product":
				break;
			case "type":
				break;
			default:
				break;
		}
	} else if (app === "ataglance") {
		switch (field) {
			case "supplier":
				return ["ataglanceListSuppliers"];
			case "auditedCompany":
				return ["ataglanceListAuditedCompany"];
			case "country":
				return ["clarityListCountries", "supplyRListCountries"];
			case "region":
				return ["clarityListRegion", "supplyRListRegion"];
			case "finalProduct":
				return ["ataglanceFinalProducts"];
			case "targetProduct":
				return ["ataglanceListProduct", "ataglanceFinalProducts"];
			case "product":
				return ["ataglanceListProduct"];
			case "type":
				return ["ataglanceListType"];
			case "client":
				return ["ataglanceListClient"];
			case "criticality":
				return ["clarityListCriticality", "supplyRListCriticality"];
			default:
				break;
		}
	} else {
		switch (field) {
			case "ADMIN":
				return "AppOptions";

			default:
				break;
		}
	}
};

/**
 * SupplyR Supplier form fields configuration
 */
export const supplyRCreateNodeDisabledFields = ["_id", "nextSupplier", "finalProduct", "tierLevel", "tierLabel", "projectName", "targetProduct"];
export const supplyRCreateBranchDisabledFields = ["_id", "nextSupplier", "tierLevel", "tierLabel", "projectName"];
export const supplyRUpdateNodeDisabledFields = ["_id", "nextSupplier", "finalProduct", "tierLevel", "tierLabel", "projectName", "targetProduct",
	"siteLabelId", "siteUniqueId"];
export const supplyRUpdateLocationDisabledFields = ["_id", "auditedCompany", "supplier", "address", "city", "client", "country", "countryRisk",
	"criticality", "factoryCode", "geopointToR", "nbModules", "projectName", "region", "siteLabelId", "supplierRiskWeight", "supplierCode",
	"tierLevel", "tierLabel" ];

export const supplyRHiddenFields = ["_id", "key", "updateDate"];

export const supplyRCreateNodeRequiredFields = ["auditedCompany", "supplier", "siteLabelId", "validated"];
export const supplyRCreateBranchRequiredFields = ["supplier", "finalProduct", "targetProduct", "product", "type", "siteLabelId", "validated"];
export const supplyRCreateFirstNodeRequiredFields = [...supplyRCreateBranchDisabledFields, ...supplyRCreateBranchRequiredFields];
export const supplyRUpdateNodeRequiredFields = ["auditedCompany", "supplier", "validated"];
export const supplyRUpdateLocationRequiredFields = ["finalProduct", "nextSupplier", "targetProduct", "product", "tierLevel", "type"];
export const supplyRUpdateSiteLabelIdAlertFields = ["auditedCompany", "supplier", "supplierCode", "factoryCode", "validated"];
export const supplyRUpdateLocAlertFields = ["nextSupplier", "finalProduct", "targetProduct"];

// This part is only for original field
// It should align supplyRFieldDefaultValue as well
export const supplyRDefaultFields = ["address", "auditedCompany", "city", "country", "countryRisk", "criticality", "factoryBranch", "factoryCode",
	"factoryIndex", "geopointToR", "logisticAgent", "nbModules", "product", "region", "siteLabelId", "siteUniqueId", "supplier", "supplierCode",
	"supplierRiskWeight", "type"];

// This part is only for original field
export const supplyRFieldDefaultValue = (activeRefines) => ({
	address: "",
	auditedCompany: "",
	city: "",
	country: "",
	countryRisk: "",
	criticality: "",
	factoryBranch: "",
	factoryCode: "",
	factoryIndex: 1,
	geopointToR: "",
	logisticAgent: "",
	nbModules: activeRefines.projectName === "Under Armour" ? 6 : 0,
	product: "",
	region: "",
	siteLabelId: "",
	siteUniqueId: "",
	supplier: "",
	supplierCode: "",
	supplierRiskWeight: "",
	type: ""
});

/**
 * clarity Supplier form fields configuration
 */
export const clarityCreateNodeDisabledFields = ["client", "finalProduct", "nextSupplier", "projectName", "spaceLabel", "targetProduct", "tierLevel"];
export const clarityCreateBranchDisabledFields = ["client", "nextSupplier", "projectName", "spaceLabel", "tierLevel"];
export const clarityUpdateNodeDisabledFields = ["client", "finalProduct", "nextSupplier", "projectName", "spaceLabel", "targetProduct", "tierLevel",
	"siteId", "siteUniqueId"];
export const clarityUpdateLocationDisabledFields = ["address", "city", "client", "company", "country", "countryRisk", "criticality", "domain",
	"factoryCode", "projectName", "spaceLabel", "strategic", "supplier", "supplierCode", "supplierRiskWeight", "volume", "tierLevel"];

export const clarityHiddenFields = ["_id", "key", "updateDate"];

export const clarityCreateNodeRequiredFields = ["siteId", "supplier", "company", "product", "type"];
export const clarityCreateBranchRequiredFields = ["siteId", "supplier", "company", "finalProduct", "targetProduct", "product"];
export const clarityCreateFirstNodeRequiredFields = [...clarityCreateBranchDisabledFields, ...clarityCreateBranchRequiredFields];
export const clarityUpdateNodeRequiredFields = ["siteId", "supplier", "company", "type", "product"];
export const clarityUpdateLocationRequiredFields = ["finalProduct", "targetProduct", "product", "tierLevel", "type", "nextSupplier"];
export const clarityUpdateSitIdAlertFields = [];
export const clarityUpdateLocAlertFields = ["nextSupplier", "finalProduct", "targetProduct"];

// This part is only for original field
// It should align supplyRFieldDefaultValue as well
export const clarityDefaultFields = ["address", "company", "city", "criticality", "country", "countryRisk", "domain", "product",
	"supplier", "siteId", "siteUniqueId", "strategic", "supplierRiskWeight", "type", "volume"];

// This part is only for original field
export const clarityFieldDefaultValue = (activeRefines) => ({
	address: "",
	company: "",
	city: "",
	criticality: "",
	country: "",
	countryRisk: "",
	domain: "",
	factoryCode: "",
	product: "",
	supplier: "",
	siteId: "",
	siteUniqueId: "",
	strategic: "",
	supplierCode: "",
	supplierRiskWeight: 0,
	type: "",
	volume: ""
});

// This part is only for original field
// It should align verkorFieldDefaultValue as well
export const verkorDefaultFields = ["address", "auditedCompany", "city", "contractHolder", "contractNumber", "contractOwner",
	"country", "countryRisk", "criticality", "geopointToR", "logisticAgent", "nbModules", "product", "region", "scope", "siteLabelId",
	"supplier", "supplierRiskWeight", "type" ];

// This part is only for original field
export const verkorFieldDefaultValue = (activeRefines) => ({
	address: "",
	auditedCompany: "",
	city: "",
	contractHolder: "",
	contractNumber: "",
	contractOwner: "",
	country: "",
	countryRisk: "",
	criticality: "",
	geopointToR: "",
	logisticAgent: "",
	nbModules: 0,
	product: "",
	region: "",
	scope: "",
	siteLabelId: "",
	supplier: "",
	supplierRiskWeight: "",
	type: ""
});

export const ataglanceCreateNodeDisabledFields = ["_id", "nextSupplier", "finalProduct", "tierLevel", "projectName", "targetProduct", "spaceLabel",
	"client"];
export const ataglanceCreateBranchDisabledFields = ["_id", "nextSupplier", "tierLevel", "projectName", "client", "spaceLabel", "client"];
export const ataglanceUpdateNodeDisabledFields = ["_id", "nextSupplier", "finalProduct", "tierLevel", "projectName", "targetProduct",
	"siteLabelId", "siteId", "siteUniqueId", "spaceLabel", "client"];
export const ataglanceUpdateLocationDisabledFields = ["_id", "auditedCompany", "supplier", "address", "city", "client", "country", "countryRisk",
	"criticality", "projectName", "region", "siteLabelId", "siteId", "siteUniqueId", "supplierRiskWeight", "tierLevel", "spaceLabel", "client",
	"inactive", "domain", "volume", "PAQid", "factoryBranch", "factoryIndex", "geopointToR", "logisticAgent", "nbModules", "validated"];

export const ataglanceHiddenFields = ["_id", "key", "updateDate"];

export const ataglanceCreateNodeRequiredFields = ["auditedCompany", "supplier", "siteLabelId", "siteId", "siteUniqueId", "type", "product",
	"clarity.spaceLabel", "supplyR.tierLabel", "supplyR.validated", "finalProduct", "nextSupplier", "targetProduct", "product", "tierLevel" ];
export const ataglanceCreateBranchRequiredFields = ["supplier", "auditedCompany", "finalProduct", "targetProduct", "product", "type", "siteLabelId",
	"siteId", "siteUniqueId", "clarity.spaceLabel", "supplyR.tierLabel", "supplyR.validated"];
export const ataglanceCreateFirstNodeRequiredFields = [...ataglanceCreateBranchDisabledFields, ...ataglanceCreateBranchRequiredFields];
export const ataglanceCreateProjectRequiredFields = ["client", "logo", "projectLabel", "projectName", "PAQpreprocessed", "projectName.0",
	"clarity.spaceLabel"];
export const ataglanceUpdateNodeRequiredFields = ["auditedCompany", "supplier", "tierLevel", "nextSupplier", "finalProduct", "targetProduct", "client",
	"projectName", "siteLabelId", "siteId", "siteUniqueId"];
export const ataglanceUpdateLocationRequiredFields = ["finalProduct", "nextSupplier", "targetProduct", "product", "tierLevel", "type"];
export const ataglanceUpdateProjectRequiredFields = [];
export const ataglanceUpdateSiteLabelIdAlertFields = ["auditedCompany", "supplier"];
export const ataglanceUpdateLocAlertFields = ["nextSupplier", "finalProduct", "targetProduct"];

// This part is only for original field
// It should align compassFieldDefaultValue as well
export const ataglanceDefaultFields = ["address", "auditedCompany", "city", "country", "countryRisk", "criticality", "domain", "factoryBranch",
	"factoryCode", "factoryIndex", "geopointToR", "logisticAgent", "PAQid", "product", "region", "siteLabelId", "siteUniqueId", "siteId", "supplier",
	"supplierRiskWeight", "supplierCode", "strategic", "type", "tierLabel", "validated", "volume"];

// This part is only for original field
export const ataglanceFieldDefaultValue = (activeRefines) => ({
	address: "",
	auditedCompany: "",
	city: "",
	country: "",
	countryRisk: "",
	criticality: "",
	domain: "",
	factoryBranch: "",
	factoryCode: "",
	factoryIndex: 1,
	geopointToR: "",
	logisticAgent: "",
	PAQid: "",
	product: "",
	region: "",
	siteLabelId: "",
	siteUniqueId: "",
	siteId: "",
	supplier: "",
	supplierRiskWeight: 0,
	supplierCode: "",
	strategic: "",
	type: "",
	tierLabel: "Tier 1",
	validated: "True",
	volume: ""
});

/**
 * compass form fields configuration
 */

export const compassHiddenFields = ["_id", "key", "updateDate"];

/**
 * clarity Settings form and fields configuration
 */

// This part is only for domain config
export const clarityConfigDomainRequiredFields = ["name", "domainColor", "domainLogo"];
export const clarityConfigDomainHiddenFields = ["_id", "locale"];

export const clarityCreateTranslationRequiredFields = ["language"];

// This part is only for clarity translations
// export const clarityConfigDomainTranslationsRequiredFields = ["language"];
export const clarityConfigTranslationsHiddenFields = ["_id", "language"];

export const fieldAttributeBuilder = (secLevelMenu, attr, activeRefines = {}) => {
	if (secLevelMenu === "clarity") {
		switch (attr) {
			case "createNodeDisabledFields":
				return clarityCreateNodeDisabledFields;
			case "createNodeRequiredFields":
				return clarityCreateNodeRequiredFields;
			case "createBranchDisabledFields":
				return clarityCreateBranchDisabledFields;
			case "createBranchRequiredFields":
				return clarityCreateBranchRequiredFields;
			case "createFirstNodeRequiredFields":
				return clarityCreateFirstNodeRequiredFields;

			case "updateNodeDisabledFields":
				return clarityUpdateNodeDisabledFields;
			case "updateNodeRequiredFields":
				return clarityUpdateNodeRequiredFields;
			case "updateLocationDisabledFields":
				return clarityUpdateLocationDisabledFields;
			case "updateLocationRequiredFields":
				return clarityUpdateLocationRequiredFields;

			case "updateSiteLabelIdAlertFields":
				return clarityUpdateSitIdAlertFields;
			case "updateLocationAlertFields":
				return clarityUpdateLocAlertFields;

			case "hiddenFields":
				return clarityHiddenFields;

			case "defaultFields":
				return [clarityDefaultFields, clarityFieldDefaultValue(activeRefines)];

			case "createDomainRequiredFields":
				return clarityConfigDomainRequiredFields;
			case "domainsHiddenFields":
				return clarityConfigDomainHiddenFields;

			case "translationsHiddenFields":
				return clarityConfigTranslationsHiddenFields;
			case "createTranslationRequiredFields":
				return clarityCreateTranslationRequiredFields;

			default:
				break;
		}
	}

	if (secLevelMenu === "supplyR") {
		switch (attr) {
			case "createNodeDisabledFields":
				return supplyRCreateNodeDisabledFields;
			case "createNodeRequiredFields":
				return supplyRCreateNodeRequiredFields;
			case "createBranchDisabledFields":
				return supplyRCreateBranchDisabledFields;
			case "createBranchRequiredFields":
				return supplyRCreateBranchRequiredFields;
			case "createFirstNodeRequiredFields":
				return supplyRCreateFirstNodeRequiredFields;

			case "updateNodeDisabledFields":
				return supplyRUpdateNodeDisabledFields;
			case "updateNodeRequiredFields":
				return supplyRUpdateNodeRequiredFields;
			case "updateLocationDisabledFields":
				return supplyRUpdateLocationDisabledFields;
			case "updateLocationRequiredFields":
				return supplyRUpdateLocationRequiredFields;

			case "updateSiteLabelIdAlertFields":
				return supplyRUpdateSiteLabelIdAlertFields;
			case "updateLocationAlertFields":
				return supplyRUpdateLocAlertFields;

			case "hiddenFields":
				return supplyRHiddenFields;

			case "defaultFields":
				return [supplyRDefaultFields, supplyRFieldDefaultValue(activeRefines)];

			default:
				break;
		}
	}

	if (secLevelMenu === "ataglance") {
		switch (attr) {
			case "createNodeDisabledFields":
				return ataglanceCreateNodeDisabledFields;
			case "createNodeRequiredFields":
				return ataglanceCreateNodeRequiredFields;
			case "createBranchDisabledFields":
				return ataglanceCreateBranchDisabledFields;
			case "createBranchRequiredFields":
				return ataglanceCreateBranchRequiredFields;
			case "createFirstNodeRequiredFields":
				return ataglanceCreateFirstNodeRequiredFields;
			case "createProjectRequiredFields":
				return ataglanceCreateProjectRequiredFields;

			case "updateNodeDisabledFields":
				return ataglanceUpdateNodeDisabledFields;
			case "updateNodeRequiredFields":
				return ataglanceUpdateNodeRequiredFields;
			case "updateLocationDisabledFields":
				return ataglanceUpdateLocationDisabledFields;
			case "updateLocationRequiredFields":
				return ataglanceUpdateLocationRequiredFields;
			case "updateProjectRequiredFields":
				return ataglanceUpdateProjectRequiredFields;

			case "updateSiteLabelIdAlertFields":
				return ataglanceUpdateSiteLabelIdAlertFields;
			case "updateLocationAlertFields":
				return ataglanceUpdateLocAlertFields;

			case "hiddenFields":
				return ataglanceHiddenFields;

			case "defaultFields":
				return [ataglanceDefaultFields, ataglanceFieldDefaultValue(activeRefines)];

			default:
				break;
		}
	}

	if (secLevelMenu === "verkor") {
		switch (attr) {
			case "createNodeDisabledFields":
				return supplyRCreateNodeDisabledFields;
			case "createNodeRequiredFields":
				return supplyRCreateNodeRequiredFields;
			case "createBranchDisabledFields":
				return supplyRCreateBranchDisabledFields;
			case "createBranchRequiredFields":
				return supplyRCreateBranchRequiredFields;
			case "createFirstNodeRequiredFields":
				return supplyRCreateFirstNodeRequiredFields;

			case "updateNodeDisabledFields":
				return supplyRUpdateNodeDisabledFields;
			case "updateNodeRequiredFields":
				return supplyRUpdateNodeRequiredFields;
			case "updateLocationDisabledFields":
				return supplyRUpdateLocationDisabledFields;
			case "updateLocationRequiredFields":
				return supplyRUpdateLocationRequiredFields;

			case "updateSiteLabelIdAlertFields":
				return supplyRUpdateSiteLabelIdAlertFields;
			case "updateLocationAlertFields":
				return supplyRUpdateLocAlertFields;

			case "hiddenFields":
				return supplyRHiddenFields;

			case "defaultFields":
				return [verkorDefaultFields, verkorFieldDefaultValue(activeRefines)];

			default:
				break;
		}
	}

	if (secLevelMenu === "compass") {
		switch (attr) {
			case "hiddenFields":
				return compassHiddenFields;

			default:
				break;
		}
	}
};

export const chainTableStructure = (secLevelMenu, clientParams) => {
	if (secLevelMenu === "clarity") { return clarityChainTableStructure(); }
	if (secLevelMenu === "supplyR") { return supplyRChainTableStructure(clientParams); }
	if (secLevelMenu === "ataglance") { return ataglanceChainTableStructure(clientParams); }
	if (secLevelMenu === "verkor") { return verkorChainTableStructure(); }
};

export const getSupplychainChildren = (secLevelMenu, finalProduct) => {
	if (secLevelMenu === "clarity") { return getClaritychainChildren(finalProduct[1], "", 0, finalProduct[0]); }
	if (secLevelMenu === "supplyR") { return getSupplyRchainChildren(finalProduct[1], "", 0, finalProduct[0]); }
	if (secLevelMenu === "ataglance") { return getAtAGlancechainChildren(finalProduct[1], "", 0, finalProduct[0]); }
	if (secLevelMenu === "verkor") { return getSupplyRchainChildren(finalProduct[1], "", 0, finalProduct[0]); }
};

export const supplierCreateBuilder = (secLevelMenu) => {
	if (secLevelMenu === "clarity") { return claritySupplierCreate; }
	if (secLevelMenu === "supplyR") { return supplyRSupplierCreate; }
	if (secLevelMenu === "ataglance") { return ataglanceSupplierCreate; }
	if (secLevelMenu === "verkor") { return supplyRSupplierCreate; }
};

export const supplierUpdateNodeBuilder = (secLevelMenu) => {
	if (secLevelMenu === "clarity") { return clarityNodeUpdate; }
	if (secLevelMenu === "supplyR") { return supplyRNodeUpdate; }
	if (secLevelMenu === "ataglance") { return ataglanceNodeUpdate; }
	if (secLevelMenu === "verkor") { return supplyRNodeUpdate; }
};

export const supplierUpdateLocBuilder = (secLevelMenu) => {
	if (secLevelMenu === "clarity") { return clarityLocationUpdate; }
	if (secLevelMenu === "supplyR") { return supplyRLocationUpdate; }
	if (secLevelMenu === "ataglance") { return ataglanceLocationUpdate; }
	if (secLevelMenu === "verkor") { return supplyRLocationUpdate; }
};

export const childrenAccessorBuilder = (secLevelMenu, targetDocument) => {
	if (secLevelMenu === "clarity") {
		return (d) => {
			if (typeof targetDocument.siteId === "number") {
				return (d.nextSupplier === targetDocument.siteId.toString()) && (d.targetProduct === targetDocument.product);
			}
			return (d.nextSupplier === targetDocument.siteId) && (d.targetProduct === targetDocument.product);
		};
	}
	if (secLevelMenu === "supplyR" || secLevelMenu === "verkor") {
		return (d) => (d.nextSupplier === targetDocument.siteLabelId) && (d.targetProduct === targetDocument.product);
	}

	if (secLevelMenu === "ataglance") {
		return (d) => (d.nextSupplier === targetDocument.siteUniqueId) && (d.targetProduct === targetDocument.product);
	}
};

export const chainRefineFieldBuilder = {
	clarity: "spaceLabel",
	supplyR: "projectName",
	ataglance: "projectName",
	verkor: "projectName",
	compass: "project"
};

// supply chain new node structure to Tor,
// if 2nd argument is not provided, then it means the project has no data available
export const newBranchFirstNode = ({ activeRefines, activeDatasets = {}, source = {} }) => {
	if (activeRefines.secLevelMenu === "clarity") {
		return {
			address: "",
			city: "",
			client: source?.client ?? "",
			company: "",
			country: "",
			countryRisk: "",
			criticality: "",
			domain: "",
			factoryCode: "",
			finalProduct: "",
			inactive: false,
			nextSupplier: activeDatasets.clarityClientParameters?.data
			?.find((item) => item[chainRefineFieldBuilder.clarity][0] === activeRefines[chainRefineFieldBuilder.clarity]).client,
			product: "",
			projectName: activeRefines?.spaceLabel ?? "",
			siteId: "",
			siteUniqueId: "",
			spaceLabel: activeRefines?.spaceLabel ?? "",
			strategic: "",
			supplier: "",
			supplierCode: "",
			supplierRiskWeight: 0,
			targetProduct: "",
			tierLevel: 0,
			type: "",
			volume: "",
			key: {
				siteId: "",
				product: ""
			}
		};
	}

	if (activeRefines.secLevelMenu === "supplyR") {
		return {
			address: "",
			auditedCompany: "",
			city: "",
			client: source.client,
			country: "",
			countryRisk: "",
			criticality: "",
			finalProduct: "",
			factoryBranch: "",
			factoryCode: "",
			factoryIndex: 1,
			geopointToR: "",
			logisticAgent: "",
			nbModules: activeRefines.projectName === "Under Armour" ? 6 : 0,
			nextSupplier: activeDatasets.supplyRClientParameters?.data
			?.find((item) => item[chainRefineFieldBuilder.supplyR][0] === activeRefines[chainRefineFieldBuilder.supplyR]).client,
			PAQid: "",
			product: "",
			projectName: activeRefines.projectName,
			region: "",
			siteLabelId: "",
			siteUniqueId: "",
			supplier: "",
			supplierCode: "",
			supplierRiskWeight: "1",
			targetProduct: "",
			tierLabel: "Tier 1",
			tierLevel: 0,
			type: "",
			validated: "",
			key: {
				client: source.client,
				siteLabelId: "",
				product: ""
			}
		};
	}

	if (activeRefines.secLevelMenu === "ataglance") {
		return {
			address: "",
			auditedCompany: "",
			city: "",
			client: source.client,
			region: "",
			country: "",
			countryRisk: "",
			criticality: "",
			finalProduct: "",
			// TODO: item.projectLabel dynamic way
			nextSupplier: activeDatasets.ataglanceClientParameters?.data
			?.find((item) => item.projectLabel === activeRefines[chainRefineFieldBuilder.ataglance]).client?.toUpperCase(),
			product: "",
			projectName: activeRefines.projectName,
			siteId: "",
			siteLabelId: "",
			siteUniqueId: "",
			supplier: "",
			supplierRiskWeight: 0,
			targetProduct: "",
			tierLevel: 0,
			type: "Manufacturer",
			geopointToR: "",
			// Clarity only
			clarity: {
				domain: "",
				inactive: false,
				volume: "",
				spaceLabel: activeRefines.projectName,
				strategic: ""
			},
			// SupplyR only
			supplyR: {
				factoryBranch: "",
				factoryCode: "",
				factoryIndex: 1,
				logisticAgent: "",
				nbModules: 0,
				PAQid: "",
				supplierCode: "",
				tierLabel: "Tier 1",
				validated: ""
			}
		};
	}

	if (activeRefines.secLevelMenu === "verkor") {
		const base = {
			address: "",
			auditedCompany: "",
			city: "",
			client: source.client,
			country: "",
			countryRisk: "",
			criticality: "",
			finalProduct: "",
			geopointToR: "",
			logisticAgent: "",
			nbModules: 0,
			nextSupplier: activeDatasets.verkorClientParameters?.data
			?.find((item) => item[chainRefineFieldBuilder.verkor][0] === activeRefines[chainRefineFieldBuilder.verkor]).client,
			product: "",
			projectName: activeRefines.projectName,
			region: "",
			siteLabelId: "",
			supplier: "",
			supplierRiskWeight: "1",
			targetProduct: "",
			tierLabel: "Tier 1",
			tierLevel: 0,
			type: "",
			targetDate: "",
			key: {
				client: source.client,
				siteLabelId: "",
				product: ""
			}
		};

		if (activeRefines.projectName === "PDO") {
			return {
				...base,
				contractHolder: "",
				contractNumber: "",
				contractOwner: "",
				scope: ""
			};
		}

		return base;
	}
};

// create new project to clientParameters
// align the arrayFieldDefaults as well
export const newProject = ({ project }) => {
	if (project === "clarity") {
		return {
			activeIndicators: [],
			activeSDGs: [],
			BVSites: [],
			campaigns: [{
				rank: 1,
				label: "",
				startDate: "",
				endDate: ""
			}],
			client: "",
			displayFactoryCode: true,
			displaySupplierCode: true,
			displaySectionLevel2: false,
			formVersion: "",
			isNotDemo: true,
			kpis: false,
			riskAssessment: false,
			siteDeactivation: false,
			logo: "",
			market: "",
			maturityColors: [],
			productTraceability: false,
			projectLabel: "",
			projectName: [],
			pushedIndicators: [],
			scoringMethodology: "",
			spaceLabel: [],
			specificFilters: [],
			supplyChain: false,
			statusTracking: false,
			trackActionPlan: false,
			targetAssignements: 0,
			supplychainParams: {
				isCommon: true,
				groupKey: ""
			},
			actionPlanStatusScope: [{ label: "", color: "" }]
		};
	}

	if (project === "supplyR") {
		return {
			allowReportLink: true,
			allowRequestAudit: true,
			client: "",
			displayFactoryCode: false,
			displaySupplierCode: false,
			finalProductLov: [],
			logo: "",
			maiaProductListId: 0,
			maiaTypeListId: 0,
			market: "",
			projectLabel: "",
			projectName: [],
			projectScope: [{ label: "", color: "" }],
			PAQpreprocessed: false,
			supplychainParams: {
				isCommon: true,
				groupKey: ""
			}
		};
	}

	if (project === "compass") {
		return {
			client: "",
			countryRegionMapping: [],
			expectedTotal: 0,
			logo: "",
			market: "",
			modules: [],
			project: "",
			projectLabel: "",
			rating: [],
			scoring: {
				field: "",
				values: [],
				ncValues: []
			},
			scoreBased: false,
			geoScope: false,
			siteTypePerForm: [{
				formLabel: "",
				siteType: ""
			}],
			supplychainParams: {
				isCommon: true,
				groupKey: ""
			}
		};
	}

	if (project === "ataglance") {
		return {
			client: "",
			logo: "",
			projectLabel: "",
			projectName: [""],
			supplychainParams: {
				isCommon: true,
				groupKey: "siteUniqueId"
			},
			PAQpreprocessed: false,
			clarity: {
				activeIndicators: [],
				activeSDGs: [""],
				BVSites: [0],
				campaigns: [{
					rank: 1,
					label: "",
					startDate: "",
					endDate: ""
				}],
				displaySectionLevel2: false,
				formVersion: "",
				isNotDemo: true,
				kpis: false,
				market: "",
				maturityColors: [
					{
						label: "",
						color: "",
						rank: 0
					}
				],
				productTraceability: false,
				pushedIndicators: [],
				scoringMethodology: "",
				spaceLabel: [""],
				specificFilters: [""],
				supplyChain: true,
				riskAssessment: false,
				siteDeactivation: false,
				statusTracking: false,
				targetAssignements: 0
			},
			supplyR: {
				allowReportLink: true,
				allowRequestAudit: true,
				displayFactoryCode: false,
				displaySupplierCode: false,
				finalProductLov: [""],
				maiaProductListId: 0,
				maiaTypeListId: 0,
				market: "",
				projectScope: [{ label: "", color: "" }]
			}
		};
	}

	if (project === "verkor") {
		return {
			actionPlanStatusScope: [],
			allowReportLink: true,
			allowRequestAudit: true,
			client: "",
			finalProductLov: [],
			geoByAssignmentLocation: true,
			logo: "",
			maiaProductListId: 0,
			maiaTypeListId: 0,
			market: "",
			overallResultScope: [],
			projectLabel: "",
			projectName: [],
			projectScope: [],
			scoringBySection: true,
			threeLevelNC: true,
			supplychainParams: {
				isCommon: true,
				groupKey: ""
			}
		};
	}
};

export const newConfigTemplate = ({ appAction }) => {
	if (appAction === "createNewDomain") {
		return [
			{
				name: "",
				domainColor: "",
				domainLogo: ""
			}
		];
	}
	if (appAction === "createNewTranslation") {
		return [
			{
				language: ""
			}
		];
	}
};

// configuration for all array field
export const arrayFieldDefaults = ({ activeRefines, field }) => {
	if (activeRefines.secLevelMenu === "clarity") {
		switch (field) {
			case "maturityColors":
				return {
					k: {
						label: "",
						color: "",
						rank: 0
					}
				};
			case "campaigns":
				return {
					k: {
						rank: 1,
						label: "",
						startDate: "",
						endDate: ""
					}
				};
			case "BVSites":
				return {
					k: 0
				};
			case "actionPlanStatusScope":
				return {
					k: {
						label: "",
						color: ""
					}
				};

			default:
				return { k: "" };
		}
	}
	if (activeRefines.secLevelMenu === "supplyR") {
		switch (field) {
			case "projectScope":
				return {
					k: {
						label: "",
						color: ""
					}
				};

			default:
				return { k: "" };
		}
	}

	if (activeRefines.secLevelMenu === "ataglance") {
		switch (field) {
			case "clarity.maturityColors":
				return {
					k: {
						label: "",
						color: "",
						rank: 0
					}
				};
			case "clarity.campaigns":
				return {
					k: {
						rank: 1,
						label: "",
						startDate: "",
						endDate: ""
					}
				};
			case "clarity.BVSites":
				return {
					k: 0
				};

			case "supplyR.projectScope":
				return {
					k: {
						label: "",
						color: ""
					}
				};

			default:
				return { k: "" };
		}
	}

	if (activeRefines.secLevelMenu === "verkor") {
		switch (field) {
			case "actionPlanStatusScope":
				return {
					k: {
						label: "",
						color: ""
					}
				};
			case "projectScope":
				return {
					k: {
						label: "",
						color: ""
					}
				};
			case "overallResultScope":
				return {
					k: {
						label: "",
						color: ""
					}
				};
			default:
				return { k: "" };
		}
	}

	if (activeRefines.secLevelMenu === "compass") {
		switch (field) {
			case "countryRegionMapping":
				return {
					k: {
						countryName: "",
						synergyDisplayRegion: ""
					}
				};

			case "modules":
				return {
					k: {
						formLabel: "",
						module: ""
					}
				};

			case "rating":
				return {
					k: {
						answer: "",
						color: "",
						weight: 0
					}
				};

			case "siteTypePerForm":
				return {
					k: {
						formLabel: "",
						siteType: ""
					}
				};

			case "scoring.values":
				return {
					k: {
						answer: "",
						weight: 0,
						isCritical: "no",
						color: ""
					}
				};

			case "scoring.ncValues":
				return {
					k: 0
				};

			default:
				return { k: "" };
		}
	}

	return { k: "" };
};

export const fieldOrderBuilder = (activeRefines) => {
	if (activeRefines.secLevelMenu === "clarity") {
		if (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams") {
			return [];
		}
		// eslint-disable-next-line max-len
		return ["_id", "inactive", "projectName", "spaceLabel", "supplier", "supplierCode", "company", "factoryCode", "siteId", "siteUniqueId", "tierLevel", "type", "nextSupplier",
			"finalProduct", "targetProduct", "product", "client", "key", "address", "city", "country", "domain", "strategic", "volume", "criticality",
			"supplierRiskWeight", "countryRisk"];
	}

	if (activeRefines.secLevelMenu === "supplyR") {
		if (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams") {
			return [];
		}
		return ["_id", "projectName", "PAQid", "supplier", "supplierCode", "auditedCompany", "factoryCode", "factoryBranch", "factoryIndex", "siteLabelId",
			"siteUniqueId", "tierLevel", "tierLabel", "type", "nextSupplier", "finalProduct", "targetProduct", "product", "logisticAgent", "client",
			"key", "address", "geopointToR", "city", "country", "region", "nbModules", "criticality", "supplierRiskWeight", "countryRisk", "validated"];
	}

	if (activeRefines.secLevelMenu === "ataglance") {
		if (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams") {
			return ["client", "projectLabel", "projectName", "PAQpreprocessed", "logo", "supplychainParams", "clarity", "supplyR"];
		}

		return ["_id", "projectName", "supplier", "auditedCompany", "siteLabelId", "siteId", "siteUniqueId", "tierLevel", "type", "nextSupplier",
			"finalProduct", "targetProduct", "product", "client", "key", "address", "city", "country", "region", "criticality", "supplierRiskWeight",
			"countryRisk", "inactive", "domain", "volume", "PAQid", "factoryBranch", "factoryCode", "factoryIndex", "supplierCode", "tierLabel",
			"geopointToR", "logisticAgent", "nbModules", "validated", "clarity", "supplyR"];
	}

	if (activeRefines.secLevelMenu === "verkor") {
		if (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams") {
			return [];
		}
		return ["_id", "projectName", "supplier", "auditedCompany", "siteLabelId", "tierLevel", "tierLabel", "type", "nextSupplier", "finalProduct",
			"targetProduct", "product", "scope", "targetDate", "logisticAgent", "client", "key", "address", "geopointToR", "city", "country", "region",
			"nbModules", "criticality", "supplierRiskWeight", "countryRisk", "contractHolder", "contractNumber", "contractOwner" ];
	}

	if (activeRefines.secLevelMenu === "compass") {
		if (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams") {
			return [];
		}
		return [];
	}
};

// match region from aws location service to region in worldCountries in mongodb
export const convertRegion = {
	Asia: "Asia",
	Africa: "Africa",
	Europe: "Europe",
	America: "Americas",
	Pacific: "Oceania",
	Australia: "Oceania"
};

export const convertCountry = {
	"South Korea": "Korea"
};

// react-select async selector builder for props setValueKey
export const setValueKeyBuilder = (d, activeDatasets, activeRefines) => {
	if (activeRefines.secLevelMenu === "clarity") {
		return [
			{ key: "city", value: d?.municipality ?? "" },
			{ key: "country", value: activeDatasets?.iso3CodeToCountry.data?.[0]?.iso3CodeToCountry?.[d?.country] ?? "" },
			{ key: "address", value: d?.label ?? "" }];
	}

	if (activeRefines.secLevelMenu === "supplyR" || activeRefines.secLevelMenu === "ataglance") {
		return [
			{ key: "city", value: d?.municipality ?? "" },
			{ key: "country", value: activeDatasets?.iso3CodeToCountry.data?.[0]?.iso3CodeToCountry?.[d?.country] ?? "" },
			{ key: "region", value: convertRegion[d?.timeZone?.name?.split("/")?.[0]] ?? "" },
			{ key: "address", value: d?.label ?? "" },
			{ key: "geopointToR", value: `${d?.geometry?.point?.[1]},${d?.geometry?.point?.[0]}` ?? "" }];
	}

	if (activeRefines.secLevelMenu === "verkor") {
		return [
			{ key: "city", value: d?.municipality ?? "" },
			{ key: "country", value: activeDatasets?.iso3CodeToCountry.data?.[0]?.iso3CodeToCountry?.[d?.country] ?? "" },
			{ key: "region", value: convertRegion[d?.timeZone?.name?.split("/")?.[0]] ?? "" },
			{ key: "address", value: d?.label ?? "" },
			{ key: "geopointToR", value: `${d?.geometry?.point?.[1]},${d?.geometry?.point?.[0]}` ?? "" }];
	}
};

export const alertTextBuilder = (field, activeRefines) => {
	if (activeRefines.secLevelMenu === "supplyR" && activeRefines.projectName !== "Under Armour") {
		switch (field) {
			case "auditedCompany":
				return "This field will affect the value of siteLabelId, please make sure before you modify it.";
			case "supplier":
				return "This field will affect the value of siteLabelId, please make sure before you modify it.";
			case "supplierCode":
				return "This field will affect all the same value related to this supplier, please make sure before you modify it.";
			case "factoryCode":
				return "This field will affect all the same value related to this auditedCompany, please make sure before you modify it.";
			case "nextSupplier":
				return "This field is important to update node's position in tree, the other fields will be adapted automatically if necessary.";
			case "finalProduct":
				return "This field is important to update node's position in tree, the other fields will be adapted automatically if necessary.";
			default:
				return "";
		}
	}

	if (activeRefines.supplyChainAction === "updateLocation") {
		switch (field) {
			case "finalProduct":
				return "Please fill in the final product of downstream.";
			case "nextSupplier":
				return "Please fill in the siteLabelId / siteId of downstream.";
			case "targetProduct":
				return "Please fill in the product of downstream.";

			default:
				return "";
		}
	}

	return "";
};

export const clearRefineKeys = [{ refine: "supplyChainAction" }, { refine: "site" }, { refine: "supplier" },
	{ refine: "auditedCompany" }, { refine: "company" }, { refine: "client" }, { refine: "country" }, { refine: "region" },
	{ refine: "criticality" }, { refine: "type" }, { refine: "product" }, { refine: "address" }, { refine: "siteLabelId" },
	{ refine: "siteUniqueId" }];

export const projectMapping = {
	supplyR: "supplyr",
	clarity: "clarity",
	compass: "Compass",
	verkor: "Verkor",
	ataglance: "ataglance"
};

export const targetCollectionMapping = {
	supplyR: ["SupplyRChainBeforeToR", "SupplyRBeforePAQPreprocessing", "SupplyRToRFactories", "SupplyRPAQAdditions",
		"SupplyRPAQAdditionsToLog", "SupplyRPAQAdditionsNormalizationLog", "SupplyRCheckpoints", "SupplyRSectionScores",
		"SupplyRAssignmentScores", "SupplyRSiteScores", "SupplyRChainScores", "SupplyRCheckpointsBeforeNAFilter", "SupplyRModulesBeforeNAFilter"],
	clarity: ["ClarityCheckpointsBeforeClarityV4Referential", "ClarityCheckpointsBeforeClaritySiteWeights",
		"ClarityCheckpointsBeforeClaritySiteWeightsCritical", "ClarityCheckpoints", "ClarityMaxSectionScores",
		"ClaritySectionScores", "ClarityRSWoodAssignmentScores", "ClarityAssignmentScores", "ClaritySDGsScores",
		"ClaritySiteScores", "ClaritySiteScoresAfterSites", "ClarityChainScores", "ClaritySiteListSupplyChain",
		"ClarityCountryScores"],
	ataglance: ["AtAGlanceBeforePAQPreprocessing", "AtAGlancePAQPreprocessing", "AtAGlanceToRFactories", "AtAGlancePAQAdditions",
		"AtAGlancePAQAdditionsToLog", "AtAGlancePAQAdditionsNormalizationLog", "ClarityAtAGlanceToR", "SupplyRAtAGlanceToR",
		"ClarityAtAGlanceClientParameters", "SupplyRAtAGlanceClientParameters"
	],
	compass: [],
	verkor: []
};

export const dropdownRefBuilder = ({ selectedDocument, currentDocument, activeRefines }) => {
	if (activeRefines.secLevelMenu === "clarity") {
		const {
			client, projectName, siteId, supplier, company, address, city, country, type, criticality, countryRisk, supplierRiskWeight,
			supplierCode, factoryCode
		} = selectedDocument;

		return {
			...currentDocument,
			...{
				client,
				projectName,
				siteId,
				supplier,
				company,
				address,
				city,
				country,
				type,
				criticality,
				countryRisk,
				supplierRiskWeight,
				supplierCode,
				factoryCode
			}
		};
	}

	if (activeRefines.secLevelMenu === "supplyR") {
		const {
			client, projectName, siteLabelId, supplier, auditedCompany, address, city, country, region, type,
			criticality, countryRisk, geopointToR, supplierRiskWeight, supplierCode, factoryCode, factoryBranch,
			factoryIndex
		} = selectedDocument;

		return activeRefines.projectName === "Under Armour" ? {
			...currentDocument,
			...{
				client,
				projectName,
				siteLabelId,
				supplier,
				auditedCompany,
				address,
				city,
				country,
				region,
				type,
				criticality,
				countryRisk,
				geopointToR,
				supplierRiskWeight,
				supplierCode,
				factoryCode,
				factoryBranch,
				factoryIndex
			}
		} : {
			...currentDocument,
			...{
				client,
				projectName,
				siteLabelId,
				supplier,
				auditedCompany,
				address,
				city,
				country,
				region,
				type,
				criticality,
				countryRisk,
				geopointToR,
				supplierRiskWeight
			}
		};
	}

	if (activeRefines.secLevelMenu === "compass") {
		return {
			...currentDocument
		};
	}

	if (activeRefines.secLevelMenu === "ataglance") {
		const {
			address, auditedCompany, city, client, region, country, countryRisk, criticality, projectName, siteLabelId,
			siteUniqueId, siteId, supplier, type, supplyR, geopointToR
		} = selectedDocument;

		return {
			...currentDocument,
			address,
			auditedCompany,
			city,
			client,
			region,
			country,
			countryRisk,
			criticality,
			projectName,
			siteLabelId,
			siteUniqueId,
			siteId,
			supplier,
			type,
			geopointToR,
			supplyR: {
				...currentDocument.supplyR,
				factoryBranch: supplyR.factoryBranch,
				factoryCode: supplyR.factoryCode,
				factoryIndex: supplyR.factoryIndex,
				supplierCode: supplyR.supplierCode
			}
		};
	}

	if (activeRefines.secLevelMenu === "verkor") {
		return {
			...currentDocument
		};
	}
};

export const formatClarityV4Referential = {
	sourceCollection: "ClarityV4ReferentialRaw",
	targetCollection: "ClarityV4Referential",
	query: [
		{
			$group: {
				_id: {
					source: "$Source",
					moduleIndex: "$ModuleIndex",
					sectionIndex: "$Section1Index",
					version: "$Version"
				},
				source: {
					$first: "$Source"
				},
				module: {
					$first: "$Module"
				},
				moduleIndex: {
					$first: "$ModuleIndex"
				},
				sectionIndex: {
					$first: "$Section1Index"
				},
				version: {
					$first: "$Version"
				},
				domain: {
					$first: "$Domain"
				},
				nbSection: {
					$first: "$Nb Section"
				},
				SDGs: {
					$first: "$List of SDGs"
				}
			}
		}, {
			$addFields: {
				key: {
					moduleIndex: "$moduleIndex",
					sectionIndex: "$sectionIndex",
					version: "$version"
				},
				// SDG must not be null or undefined otherwise the update form will crash (SDGs must be at least an empty array)
				SDGs: {
					$cond: [
						{
							$eq: [
								"$SDGs", null
							]
						}, [], {
							$split: [
								"$SDGs", ";"
							]
						}
					]
				}
			}
		}, {
			$project: {
				_id: 0,
				source: 1,
				module: 1,
				moduleIndex: 1,
				sectionIndex: 1,
				version: 1,
				domain: 1,
				nbSection: 1,
				SDGs: 1,
				key: 1
			}
		}
	]
};
