import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { fieldOrderBuilder, typeSelector, newProject } from "../../apps/configs/AdminConfig";
import { FieldNameAccessor } from "../form/FormUtils";

function ClientParametersInfoCard({
	appSlice, data, disabledFields = [], locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const activeRefines = useSelector(appSlice.selectActiveRefines);

	// sort field by its priority
	const fieldOrder = fieldOrderBuilder(activeRefines);

	const targetDocument = useMemo(() => newProject({ project: activeRefines.secLevelMenu }), [activeRefines]);

	// Here we extract only those properties that could be considered as objectGroup which means that wewill not
	// need PAQpreprocessed, client, logo, projectLabel, projectName. We'll need only reducedTargetDocument
	// to make the string look like this (just an example): clarity.activeIndicators, supplyR.allowReportLink exportChangeReports...
	const {
		PAQpreprocessed, client, logo, projectLabel, projectName, ...reducedTargetDocument
	} = targetDocument;

	// get all the field from mongoDB
	const allFieldsOfCurrentCollection = [...Object.keys(data)]
	.sort((a, b) => {
		if (disabledFields.includes(a) && !disabledFields.includes(b)) {
			return 1;
		}

		if (!disabledFields.includes(a) && disabledFields.includes(b)) {
			return -1;
		}

		return fieldOrder.indexOf(a) - fieldOrder.indexOf(b);
	});

	// all the fields in key type pair
	const AllKeyTypePair = Object.entries(data)?.reduce((acc, cur) => ({ ...acc, [cur[0]]: typeSelector(cur, activeRefines) }), {});

	const buildFieldsName = (allFieldsOfCurrentColl, keyTypePairs, targetDoc, activeRefines) => {
		const fields = allFieldsOfCurrentColl?.map((field) => {
			if (keyTypePairs[field] === "objectGroup") {
				return Object.keys(targetDoc[`${field}`]).map((subField) => (`${field}.${subField}`));
			}
			return FieldNameAccessor({ activeRefines, field });
		});
		return fields.flat(2);
	};

	const fields = buildFieldsName(allFieldsOfCurrentCollection, AllKeyTypePair, reducedTargetDocument, activeRefines);

	return (
		<div>
			{fields?.map((field, i) => (
				<>
					<ul key={i}>
						<li className="mb-1">
							<span className="font-semibold uppercase text-sm text-admin_primary-default">
								{FieldNameAccessor({ activeRefines, field })}
							</span>
							<span className="text-sm"> - {
								t(`clientParametersDoc.${activeRefines?.secLevelMenu}.${FieldNameAccessor({ activeRefines, field })}.description`)
							}</span>
						</li>
					</ul>
				</>
			))}
		</div>
	);
}

ClientParametersInfoCard.propTypes = {
	appSlice: PropTypes.object,
	data: PropTypes.object.isRequired,
	disabledFields: PropTypes.array,
	locales: PropTypes.string
};

export default ClientParametersInfoCard;
