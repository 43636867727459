/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useMemo, forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as d3 from "d3";

import {
	useAsyncDebounce,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable
} from "react-table";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownUp from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";

import { Pagination } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import HelpIcon from "@mui/icons-material/Help";
import EditIcon from "@mui/icons-material/Edit";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import HistoryIcon from "@mui/icons-material/History";

import * as XLSX from "xlsx";
import CreatFormBtnGroup from "../button/CreatFormBtnGroup";

import exportToExcel from "../../utils/ExportToExcel";
import exportChangeReports from "../../utils/exportChangeReports";

import NoDataAvailable from "../../pages/NoDataAvailable";
import AlphaTooltip from "../tooltips/AlphaTooltip";

import {
	deleteManyDocs, uploadFileToDatabase, postProcessing, validateMany
} from "../../data/slices/createAdminSlice";
import { SupplierDelete, adminValidateNodes } from "../form/AdminFormOnConfirm";
import Popconfirm from "../modal/Popconfirm";

import {
	TORCollection, projectMapping, targetCollectionMapping, chainRefineFieldBuilder, clientCollection
} from "../../apps/configs/AdminConfig";

// Define a default UI for filtering
const GlobalFilter = ({
	placeHolder,
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter
}) => {
	const count = preGlobalFilteredRows.length;

	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="flex justify-between items-center bg-gray-100 py-2 px-2 md:px-6 space-x-4 rounded-xl ">
			<input
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={placeHolder}
				className="bg-transparent outline-none "
			/>
			<SearchIcon />
		</div>
	);
};

GlobalFilter.propTypes = {
	placeHolder: PropTypes.string,
	preGlobalFilteredRows: PropTypes.array,
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
	const defaultRef = React.useRef();
	const resolvedRef = ref || defaultRef;

	React.useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<>
			<input type="checkbox" ref={resolvedRef} {...rest} />
		</>
	);
});

IndeterminateCheckbox.propTypes = {
	indeterminate: PropTypes.any
};

const AdminSupplyChainTable = ({
	appSlice,
	locales,
	structureTable = undefined,
	sortByRefineFields = [],
	refineKeys,
	setAlert,
	supplychainView,
	isAlpha
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const [, forceUpdate] = useState();
	const [confirmModal, setconfirmModal] = useState(false);
	const [validateModal, setValidateModal] = useState(false);
	const [postProcessingModal, setPostProcessingModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState(undefined);
	const [isUploaded, setIsUploaded] = useState(false);
	const [isUploadedError, setIsUploadedError] = useState(false);

	let deleteNodes = [];

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store of the App
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const supplyChainData = useSelector(appSlice.selectDatasets)?.[`${activeRefines.secLevelMenu}SupplyChainTree`];

	const data = useMemo(() => activeDatasets[`${activeRefines?.secLevelMenu}ChainToR`]?.data, [ activeDatasets, activeRefines]);

	const columns = useMemo(
		() => structureTable,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		rows,
		gotoPage,
		preGlobalFilteredRows,
		setGlobalFilter,
		selectedFlatRows,
		state: { pageIndex, pageSize, selectedRowIds }
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: 16,
				sortBy: sortByRefineFields
			}
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((col) => [
				{
					id: "selection",
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					Cell: ({ row }) => (
						<div className="flex items-center">
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					)
				},
				{
					id: "edit",
					Cell: ({ row }) => (
						<div className="flex items-center">
							<EditIcon
								fontSize="small"
								className="text-admin_primary-default cursor-pointer"
								onClick={() => {
									dispatch(appSlice.actions.refine(refineKeys(row)));
									dispatch(appSlice.actions.refine([
										{ key: "supplyChainAction", value: "updateNode" }]));
								}}
							/>
						</div>
					)
				},
				...col
			]);
		}
	);
	const generateTableToExport = () => {
		const sortedTable = new Array(rows.length);
		const headers = headerGroups[0]?.headers?.filter((e) => e.id !== "edit" && e.id !== "selection").map((e) => e.id);
		rows.forEach((row, i) => {
			const formattedRow = {};
			prepareRow(row);
			row.cells
			.filter((e) => headers.includes(e.column.Header))
			.forEach(
				(cell, j) => cell.column.Header === "updateDate"
					? (formattedRow[cell.column.Header] = new Date(cell?.value?.toString()))
					: (formattedRow[cell.column.Header] = cell?.value?.toString())
			);
			sortedTable[i] = formattedRow;
		});
		return sortedTable;
	};

	const onConfirmDelete = (selectedDeleteNodes) => {
		if (selectedDeleteNodes.length === 0) {
			window.confirm("Please select a node to delete");
			return;
		}

		return SupplierDelete({
			appSlice,
			dispatch,
			deleteManyDocs,
			TORCollection,
			activeRefines,
			deleteNodes,
			forceUpdate,
			setAlert,
			supplychainView,
			setconfirmModal
		});
	};

	const onConfirmValidate = (nodesToValidate) => {
		if (nodesToValidate.length === 0) {
			window.confirm("Please select a node to validate. 'On Hold' documents will not be updated");
			return;
		}

		return adminValidateNodes({
			appSlice,
			dispatch,
			validateMany,
			nodesToValidate,
			setValidateModal,
			TORCollection,
			activeRefines,
			setAlert,
			forceUpdate,
			supplychainView
		});
	};

	const readUploadFile = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const reader = new FileReader();
			reader.onload = (e) => {
				let uploadValue;
				const data = e.target.result;
				const workbook = XLSX.read(data, { type: "array" });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json = XLSX.utils.sheet_to_json(worksheet);

				uploadValue = [...json];

				//  append missing field key to every document
				if (TORCollection[activeRefines.secLevelMenu] === "ClarityV4ToR") {
					uploadValue = uploadValue.map((item) => ({
						...item,
						tierLevel: Number(item.tierLevel),
						client: item.client.toUpperCase(),
						supplier: item.supplier.toUpperCase(),
						key: {
							product: item?.product,
							siteId: item?.siteId
						}
					}));
				}

				if (TORCollection[activeRefines.secLevelMenu] === "AtAGlance-ToR") {
					uploadValue = uploadValue.map((item) => ({
						...item,
						tierLevel: Number(item.tierLevel),
						client: item.client.toUpperCase(),
						supplier: item.supplier.toUpperCase()
					}));
				}

				if (TORCollection[activeRefines.secLevelMenu] === "SupplyR-ToR" || TORCollection[activeRefines.secLevelMenu] === "Verkor-ToR") {
					uploadValue = uploadValue.map((item) => ({
						...item,
						tierLevel: Number(item.tierLevel),
						client: item.client.toUpperCase(),
						supplier: item.supplier.toUpperCase(),
						key: {
							client: item?.client.toUpperCase(),
							siteLabelId: item?.siteLabelId,
							product: item?.product
						}
					}));
				}

				dispatch(uploadFileToDatabase({
					appName: "admin",
					dataset: {
						collection: TORCollection[activeRefines.secLevelMenu],
						data: uploadValue,
						adminCallFromApp: activeRefines?.secLevelMenu,
						spaceInfo: {
							spaceKey: chainRefineFieldBuilder[activeRefines.secLevelMenu],
							spaceValue: activeRefines[chainRefineFieldBuilder[activeRefines.secLevelMenu]],
							refClientParametersCol: clientCollection[activeRefines.secLevelMenu]
						}
					},
					resetSkip: false
				}))
				.unwrap()
				.then((originalPromiseResult) => {
					const isSuccess = originalPromiseResult.res.every((item) => item === 1);

					if (isSuccess) {
						console.log("Upload file success");

						// for invoking the refresh of database
						dispatch(appSlice.actions.refine([{ key: "supplyChainAction", value: "upload" }]));
						dispatch(appSlice.actions.clear([{ refine: "supplyChainAction" }]));

						setSelectedFile(undefined);
						setIsUploaded(false);
					} else {
						// return error item position in file
						const res = originalPromiseResult.res.reduce((acc, cur, i) => {
							if (cur !== 1) return [...acc, i];
							return [...acc];
						}, []);

						console.log("upload file to database fail", res);

						setAlert({
							isDiplayed: true,
							msg: "Upload file failed"
						});
					}
				})
				.catch((rejectedValueOrSerializedError) => {
					console.log("Upload error", rejectedValueOrSerializedError);
					setIsUploaded(false);
					setIsUploadedError(true);
				});
			};

			reader.readAsArrayBuffer(e.target.files[0]);
		}
	};

	const onClickPostProcess = () => {
		dispatch(postProcessing({
			appName: "admin",
			data: {
				projectName: projectMapping[activeRefines?.secLevelMenu],
				targetCollections: targetCollectionMapping[activeRefines?.secLevelMenu]
			},
			api: "postprocessing"
		}))
		.unwrap()
		.then((originalPromiseResult) => {
			console.log("originalPromiseResult", originalPromiseResult);
		})
		.catch((rejectedValueOrSerializedError) => {
			if (rejectedValueOrSerializedError.message === "403") {
				console.log("Waiting for processing, it may last for up to 15 mins, please be patient.");
			} else {
				console.log("post processing error", rejectedValueOrSerializedError);
			}
		});

		setPostProcessingModal(true);
	};

	if (data.length === 0) {
		return 	<NoDataAvailable appSlice={appSlice} locales={locales} refineKey={[{ key: "supplyChainAction", value: "createBranch" }]} />;
	}

	// selected node id in table
	const selectedDeleteNodes = selectedFlatRows
	.map((d) => d.original)
	.map((item) => item._id);

	const nodesToValidate = selectedFlatRows.filter((e) => e.original.validated === "False").map((e) => e.original._id);
	// only after selection will raise this part to improve the performance
	if (selectedDeleteNodes?.length > 0) {
		const hierarchyData = d3.hierarchy(supplyChainData?.data);

		// get currrent selected nodes' children and the node itself
		deleteNodes = selectedDeleteNodes.reduce((acc, nodeId) => {
			const currentNodeDescendants = hierarchyData.find((node) => node?.data?._id === nodeId)
			?.descendants();

			// There might be somes nodes that it's targetProduct is not equal to finalProduct in tier 0,
			// this will lead to impossible calculation for its descendants by using d3 and crash down the project
			// basically these nodes will not be reflected in the tree view so we can't delete it from that place
			if (!currentNodeDescendants) {
				return [...acc, nodeId];
			}

			const res = hierarchyData.find((node) => node?.data?._id === nodeId)
			?.descendants()
			?.map((node) => node?.data?._id);
			// For check use
			// ?.map((node) => ({ [node?.data?._id]: node?.data?.auditedCompany }));

			return [...acc, ...res];
		}, []);

		// remove duplicates if select parent and child at the same time
		deleteNodes = deleteNodes.filter((element, index) => deleteNodes.indexOf(element) === index);
	}

	return (
		<div className="flex flex-col text-sm space-y-4 max-x-full w-full overflow-x-auto">
			{
				confirmModal && (
					<Popconfirm
						title="Delete current supplier"
						description="Would you like to delete this node along with all of its upstream nodes (to the right), if they exist?"
						confirmBtnText="Confirm"
						onClickConfirm={() => { onConfirmDelete(selectedDeleteNodes); }}
						onClickCancel={() => setconfirmModal(false)}
					/>
				)
			}
			{
				validateModal && (
					<Popconfirm
						title="Validate current supplier(s)"
						description="Are you certain about validating these suppliers? Documents marked as 'On Hold' will remain unchanged."
						confirmBtnText="Confirm"
						onClickConfirm={() => { onConfirmValidate(nodesToValidate); }}
						onClickCancel={() => setValidateModal(false)}
					/>
				)
			}
			{isUploadedError && (
				<Popconfirm
					title="Something went wrong"
					description={"The file upload has failed, possibly due to insufficient permissions or an incompatible file format."}
					showConfirm={false}
					iconsType="exclamationMark"
					onClickCancel={() => {
						setIsUploadedError(false);
					}}
				/>
			)}
			{
				postProcessingModal && (
					<Popconfirm
						title="Initiate post-processing"
						description="Initiate post-processing; this may take up to 15 minutes. If the outcome does not meet your expectations, kindly submit a SMAX ticket with comprehensive details about the issue."
						showConfirm={false}
						iconsType="exclamationMark"
						onClickCancel={() => setPostProcessingModal(false)}
					/>
				)
			}
			<div className="self-center flex items-center w-full">
				<div className="flex flex-row items-center space-x-4">
					<CreatFormBtnGroup appSlice={appSlice} locales={locales} renderBack renderAdd={true}
						clearRefineKeys={[{ refine: "fourthLevelMenu" }, { refine: "appAction" },
							{ refine: "projectName" }, { refine: "spaceLabel" }]}
						refineKey={[{ key: "supplyChainAction", value: "createBranch" }]}
						addText="addSupplyChain" deleteText="deleteNode" validateText="validateAll" renderDelete={true}
						onDelete={() => { setconfirmModal(true); }}
						renderValidate={true} onValidate={() => { setValidateModal(true); }}
					/>

					<GlobalFilter
						placeHolder={"Search for ..."}
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
				</div>

				<div className="flex space-x-2 items-center ml-4">
					<p className="hidden md:block">
						{rows.length} Results
					</p>
					{/* execute postprocessing api */}
					<button
						className={`text-admin_primary-accent rounded-xl border border-gray-100 
                                    shadow-sm p-1 bg-white hover:border-gray-200 cursor-pointer`}
						title="Reload interface with latest data"
						onClick={() => { onClickPostProcess(); }}
					>
						<PublishedWithChangesIcon className="text-admin_primary-default" />
					</button>
					{/* download files */}
					<button
						className={`text-admin_primary-accent rounded-xl border border-gray-100 
                                    shadow-sm p-1 bg-white hover:border-gray-200 cursor-pointer`}
						title="Download data in Excel format"
						onClick={(e) => exportToExcel(
							generateTableToExport(),
							`Supplychain Export ${new Date().toISOString().split("T")[0]}`
						)}
					>
						<DownloadIcon className="text-admin_primary-default" />
					</button>

					<button
						className={`text-admin_primary-accent rounded-xl border border-gray-100 
                                    shadow-sm p-1 bg-white hover:border-gray-200 cursor-pointer`}
						title="Download previous changes and rejections"
						onClick={(e) => exportChangeReports(
							`Supplychain Export and Rejected PAQs ${new Date().toISOString().split("T")[0]}`,
							activeDatasets?.SupplyRRejectedPAQ?.data,
							activeDatasets?.exportNormalizationLog?.data,
							t
						)}
					>
						<HistoryIcon className="text-admin_primary-default" />
					</button>

					{/* upload file */}
					{isAlpha && (
						<>
							<label
								htmlFor="uploadFile"
								className="text-admin_primary-accent rounded-xl border border-gray-100
                                   shadow-sm p-1 bg-white hover:border-gray-200 cursor-pointer"
							>
								<FileUploadIcon className="text-admin_primary-default" />
							</label>
							<AlphaTooltip placement="top-start"
								title={
									<div className={"flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90 bg-admin_primary-default rounded-2xl w-48"}>
										<span className="font-bold">{t("tooltip.uploadFile.title")}</span>
										{t("tooltip.uploadFile.description")}
									</div>
								}
							>
								<HelpIcon fontSize="small" className={" text-admin_primary-default "} />
							</AlphaTooltip>
							<input
								type="file"
								id="uploadFile"
								className={"Hidden invisible"}
								title="Import data in Excel format"
								onChange={(e) => {
									setSelectedFile(e);
									setIsUploaded(true);
								}}
							/>
						</>
					)}

					{/* upload confirm modal */}
					{isUploaded && (
						<Popconfirm
							title="Upload supplychain"
							description="You are about to upload this file to the database. Caution: If you are not editing the downloaded file but using a new local file, there is a risk of losing all existing data. Are you sure you want to proceed with this action?"
							confirmBtnText="Confirm"
							iconsType="exclamationMark"
							onClickConfirm={() => { readUploadFile(selectedFile); }}
							onClickCancel={() => {
								setconfirmModal(false);
								setIsUploaded(false);
							}}
						/>
					)}
				</div>
			</div>
			<table {...getTableProps()} className="w-full">
				<thead>
					{headerGroups.map((headerGroup, i) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							key={`headerGroup${i}`}
						>
							{headerGroup.headers.map((column, j) => (
								<th
									key={`header${j}`}
									{...column.getHeaderProps(column.getSortByToggleProps())}
									className="first:pl-6 last:pr-6 text-left font-medium p-2 max-w-xs"
								>
									<div className="flex items-center space-x-2">
										{column.render("Header")}
										<span>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<KeyboardArrowDownUp fontSize="inherit" />
												) : (
													<KeyboardArrowDownIcon fontSize="inherit" />
												)
											) : (
												""
											)}
										</span>
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, k) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps()}
								key={`row${k}`}
								className={`hover:bg-opacity-50 h-5
								${((row?.values?.validated === "False") || (row?.values?.["supplyR.validated"] === "False"))
									? "odd:bg-opacity-80 bg-risk-high"
									: ((row?.values?.validated === "On Hold") || (row?.values?.["supplyR.validated"] === "On Hold"))
										? " bg-risk-low odd:bg-opacity-80"
										: "odd:bg-gray-100 hover:bg-admin_primary-default"}`}
							>
								{row.cells.map((cell, m) => (
									<td
										key={`cell${m}`}
										{...cell.getCellProps()}
										className="first:pl-6 last:pr-6 p-2 max-w-xs text-ellipsis"
									>
										{cell.render("Cell")}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			{rows.length > pageSize && (
				<Pagination
					page={pageIndex + 1}
					count={Math.floor(rows.length / pageSize + 1)}
					className="md:self-center px-4 md:px-6"
					onChange={async (event, value) => {
						const page = value ? Number(value) - 1 : 0;
						gotoPage(page);
					}}
				/>
			)}
		</div>
	);
};

AdminSupplyChainTable.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	structureTable: PropTypes.array,
	sortByRefineFields: PropTypes.array,
	refineKeys: PropTypes.func,
	setAlert: PropTypes.func,
	supplychainView: PropTypes.bool,
	isAlpha: PropTypes.bool
};

export default AdminSupplyChainTable;
