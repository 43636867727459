import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import PropTypes from "prop-types";
import IF from "../../utils/IF";

const SimpleCard = ({
	title = "",
	theme = "alpha",
	subTitle = "",
	picto = ScatterPlotIcon,
	type = "Simple",
	className,
	children
}) => {
	const MaterialPicto = picto;

	return (
		<div
			className={`rounded bg-white bg-opacity-50 shadow-lg alphaBlur ${type === "full" ? "p-0 " : "p-2 "}${className || ""}`}
		>
			<IF condition={type !== "full"}>
				<div className="flex justify-between">
					<div className="flex items-center uppercase font-semibold">
						<MaterialPicto className={`text-${theme}_primary-default mr-3`} />
						{title}
						<ArrowDropDownIcon className="text-gray-300" />
					</div>
					<div className="text-gray-300">
						<MoreVertIcon />
					</div>
				</div>
				<div className="font-light text-sm mb-4">{subTitle}</div>
			</IF>
			{children}
		</div>
	);
};

SimpleCard.propTypes = {
	title: PropTypes.string,
	theme: PropTypes.string,
	subTitle: PropTypes.string,
	picto: PropTypes.object,
	type: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object
	])
};

export default SimpleCard;
