import React from "react";

const ColorGradientLegend = () => (
	<div className="absolute flex justify-between items-center w-5/6 bottom-4
    left-1/2 transform -translate-x-1/2 z-xl px-4 py-1 rounded-full
    text-white font-semibold text-xs
    bg-gradient-to-r from-red-500 via-yellow-200 to-green-500">
		<p>0</p>
		<p>25</p>
		<p>50</p>
		<p>75</p>
		<p>100</p>
	</div>
);

export default ColorGradientLegend;
