import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import expandmore from "../../assets/svg/expandmore.svg";

import SupplyRSupplierCard from "./SupplyRSupplierCard";
import Loading from "../../utils/Loading";

const SupplyRAlternativeList = ({
	appSlice, datasetSupplyChain, locales
}) => {
	// Load the store hook
	const dispatch = useDispatch();

	const { t } = useTranslation(locales);

	// Find related products to current auditedcompany
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const listOfProducts = useSelector(appSlice.selectDatasets).listProducts?.data;
	const supplyChain = useSelector(appSlice.selectDatasets)[datasetSupplyChain]?.data;
	const currentSite = useSelector(appSlice.selectDatasets).sites?.data?.[0];
	const loadStatus = useSelector(appSlice.selectLoadDataStatus);

	if (loadStatus !== "idle") {
		return <Loading isComponent />;
	}

	const auditedCompanyRelatedProducts = [...new Set(listOfProducts.flatMap((finalProducts) => finalProducts?.options
	?.map((element) => element.value)))];

	const alternativeCompaniesByProduct = auditedCompanyRelatedProducts
	.map((product) => ({
		[product]: supplyChain.filter((item) => item.product === product)
		.sort((a, b) => {
			const newA = a.resilience ? a.resilience : 0;
			const newB = b.resilience ? b.resilience : 0;

			return newB - newA;
		})
		.filter((item, index, self) => index === self.findIndex((t) => (t.siteLabelId === item.siteLabelId)
		&& (t.product === item.product) && (t.client === item.client)))
	}));

	const handleClick = (e) => {
		const ctnElement = document.getElementById(`content${e.target.id}`);
		const imgElement = document.getElementById(`img${e.target.id}`);

		ctnElement.classList.toggle("max-h-0");
		ctnElement.classList.toggle("overflow-hidden");

		imgElement.classList.toggle("-rotate-90");
	};

	return (
		<div className="relative flex flex-col gap-y-4 w-full h-full  overflow-y-auto">
			{alternativeCompaniesByProduct.map((item, i) => {
				const companiesByProduct = Object.entries(item);
				return <div className="flex flex-col gap-y-2" key={`title${i}`}>
					<div
						id={`${i}`} className="flex">
						<img id={`img${i}`} src={expandmore}
							className={"mr-3 transform"}
						/>
						<button
							id={`${i}`}
							className="text-2xl font-semibold text-gray-500"
							onClick={handleClick}
						>
							{companiesByProduct[0][0]}
						</button>
					</div>
					<div
						id={`content${i}`}
						className={"flex flex-wrap flex-row mb-3"}
					>
						{
							companiesByProduct[0][1].length === 0 &&
                            <div className="mx-4 my-3 cursor-default">
                            	{t("comparePage.alternatives.noAlternative")}&nbsp;
                            	{!activeRefines.siteId && activeRefines.supplier}{activeRefines.siteId && currentSite?.auditedCompany}
                            </div>
						}
						{
							companiesByProduct[0][1].map((companiesInfo, j) => (
								<div key={`companiesInfo${j}`}
									className={`mx-4 my-3 cursor-pointer rounded-xl
                                    ${ ((!activeRefines.siteId && companiesInfo.supplier === activeRefines.supplier)
                                        || (activeRefines.siteId && companiesInfo.siteId === activeRefines.siteId))
                                        && "border border-supplyr_primary-menu"}`}

									onClick={() => dispatch(appSlice.actions.refine([
										{ key: "supplier", value: companiesInfo.supplier },
										{ key: "siteId", value: companiesInfo.siteId }
									]))}>
									<SupplyRSupplierCard appSlice={appSlice} companiesInfo={companiesInfo} />
								</div>
							))}

					</div>
				</div>;
			})}
		</div>
	);
};

SupplyRAlternativeList.propTypes = {
	appSlice: PropTypes.object,
	datasetSupplyChain: PropTypes.string,
	locales: PropTypes.string

};

export default SupplyRAlternativeList;
