import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";

import BusinessIcon from "@mui/icons-material/Business";
import PinDropIcon from "@mui/icons-material/PinDrop";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { getGSCAMarkerColor } from "../../apps/configs/ClarityConfig";

function ClarityGSCATooltip({ appSlice, marker, locales }) {
	// Load the store hook
	const dispatch = useDispatch();

	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<div className="flex flex-col justify-center items-center w-64 pl-2 pt-1 gap-y-3 bg-white -mt-3 -ml-3">
			<div className="flex justify-center items-center text-center text-xl gap-x-2 w-full">
				<BusinessIcon fontSize="inherit" />
				<p className="text-sm font-semibold line-clamp-1 pt-1">
					{marker?.supplier}
				</p>
			</div>
			<div className="flex items-center text-base px-4 h-8 rounded-lg border">
				<PinDropIcon fontSize="normal" />
				<p className="text-xs pl-1">{marker.country}, {marker.city}</p>
			</div>
			<div className="flex flex-col justify-between items-center text-sm w-[80%] rounded-lg border p-2">
				<div className="flex justify-between w-full">
					<span>{t("GSCAPage.table.Overall Risk")}</span>
					<span className="font-semibold"
						style={{
							color: getGSCAMarkerColor(marker?.riskCombinationLabel)
						}}
					>{marker?.riskCombinationLabel}</span>
				</div>
				<div className="flex justify-between w-full">
					<span>{t("GSCAPage.table.Env Risk")}</span>
					<span className="font-semibold"
						style={{
							color: getGSCAMarkerColor(marker?.riskEnvLabel)
						}}>{marker?.riskEnvLabel}</span>
				</div>
				<div className="flex justify-between w-full">
					<span>{t("GSCAPage.table.HR Risk")}</span>
					<span className="font-semibold"
						style={{
							color: getGSCAMarkerColor(marker.riskHRLabel)
						}}>{marker.riskHRLabel}</span>
				</div>
			</div>
			<div className="flex items-center text-base px-4 h-8 rounded-lg border hover:border-alpha_primary-default hover:text-alpha_primary-default"
				onClick={() => dispatch(appSlice.actions.refine([{ key: "siteId", value: marker.siteId }]))}>
				<BusinessIcon fontSize="normal" />
				<p className="text-xs pl-1 cursor-pointer">{t("map.tooltip.viewSite")}</p>
			</div>
		</div>
	);
}

ClarityGSCATooltip.propTypes = {
	appSlice: PropTypes.object,
	marker: PropTypes.object,
	locales: PropTypes.string
};

export default ClarityGSCATooltip;
