const isNum = (value) => /^\d+$/.test(value);

const supplyRPublicValidator = (resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert) => {
	// be sure a node can be updated to another type
	// TODO: not sure if this applicable in the future
	// if (resWithoutId.type !== currentTarget.type) {
	// 	setAlert({
	// 		isDiplayed: true,
	// 		msg: "A node can not be changed to another type"
	// 	});
	// 	return false;
	// }

	const allFinalProducts = activeDatasets[`${activeRefines.secLevelMenu}FinalProducts`]?.data?.[0]?.finalProducts;

	// be sure the final product exists otherwise it should create a new branch
	if (!allFinalProducts.includes(resWithoutId.finalProduct)) {
		setAlert({
			isDiplayed: true,
			msg: "finalProduct not found, please turn to create a new branch"
		});
		return false;
	}

	// TODO: type Producer definition is not defined yet
	if (resWithoutId.type === "PRODUCER") {
		setAlert({
			isDiplayed: true,
			msg: "Data structure with type <PRODUCER> is not allowed yet"
		});
		return false;
	}

	// be sure the node comply with the rules when type Logistic, it should not be conflict with type Manufacturer
	if (resWithoutId.type === "Logistic") {
		if (resWithoutId.tierLevel !== "0" || resWithoutId.tierLabel !== "Tier 1" || resWithoutId.finalProduct !== resWithoutId.targetProduct ||
	resWithoutId.product !== "Transportation" || resWithoutId.nextSupplier !== resWithoutId.client) {
			setAlert({
				isDiplayed: true,
				msg: "When type Logistic, please make sure: tierLevel= 0 / finalProduc= targetProduct / product= Transportation / nextSupplier= client"
			});
			return false;
		}
	}

	// be sure the node comply with the rules when type Manufacturer, it should not be conflict with type Logistic
	if (resWithoutId.type === "Manufacturer") {
		if (resWithoutId.product === "Transportation") {
			setAlert({
				isDiplayed: true,
				msg: "when type Manufacturer, please make sure: product != Transportation"
			});
			return false;
		}

		if (Number(resWithoutId.tierLevel) !== (Number(resWithoutId.tierLabel.split(" ")[1]) - 1)) {
			setAlert({
				isDiplayed: true,
				msg: "when type Manufacturer, please make sure: tierLabel = Tier tierLevel -1"
			});
			return false;
		}

		if (resWithoutId.tierLevel === "0" && (resWithoutId.finalProduct !== resWithoutId.targetProduct
			|| resWithoutId.nextSupplier.toLowerCase() !== resWithoutId.client.toLowerCase())) {
			setAlert({
				isDiplayed: true,
				msg: "when type Manufacturer and tierLevel 0, Please make sure: finalProduct= targetProduct / nextSupplier= client"
			});
			return false;
		}
	}

	// be sure a single field is in conflict with other field based on the type ( Logistic or Manufacturer)
	if (resWithoutId.tierLevel !== "0" && (resWithoutId.product === "Transportation" || resWithoutId.type === "Logistic")) {
		setAlert({
			isDiplayed: true,
			msg: "when tierLevel not 0, please make sure, product!= Transportation / type!= Logistic"
		});
		return false;
	}

	// be sure a single field is in conflict with other field based on the type ( Logistic or Manufacturer)
	if (resWithoutId.product === "Transportation"
		&& (resWithoutId.tierLevel !== "0" || resWithoutId.tierLabel !== "Tier 1" || resWithoutId.finalProduct !== resWithoutId.targetProduct
			|| resWithoutId.type !== "Logistic" || resWithoutId.nextSupplier !== resWithoutId.client)) {
		setAlert({
			isDiplayed: true,
			// eslint-disable-next-line max-len
			msg: "when product Transportation, please make sure: tierLevel=0 / tierLabel= Tier 1/ finalProduct=targetProduct/ type= Logistic / nextSupplier= client"
		});
		return false;
	}

	return true;
};

export const supplyRLocUpdateValidator = (resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert) => {
	// public validator part
	const shouldUpdate = supplyRPublicValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);

	if (!shouldUpdate) return false;

	// start normal processing: it should firstly find the parent node
	const parentNode = activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data
	?.find((node) => node.siteLabelId === resWithoutId.nextSupplier && node.finalProduct === resWithoutId.finalProduct);

	if (!parentNode) {
		setAlert({
			isDiplayed: true,
			msg: "Can not find parent Node or you should create a branch, please check"
		});
		return false;
	}

	if (parentNode
		&& parentNode.product !== "Transportation"
		&& resWithoutId.finalProduct === resWithoutId.targetProduct
		&& resWithoutId.finalProduct === resWithoutId.product
		&& resWithoutId.targetProduct === resWithoutId.product) {
		setAlert({
			isDiplayed: true,
			msg: "final product and target product and product can not be the same when parent node's type is not transportation"
		});
		return false;
	}

	return parentNode;
};

export const supplyRNodeUpdateValidator = (resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert) => {
	// public validator part
	const shouldUpdate = supplyRPublicValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);
	return shouldUpdate;
};

const clarityPublicValidator = (resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert) => {
	// be sure a node can be updated to another type

	const allFinalProducts = activeDatasets[`${activeRefines.secLevelMenu}FinalProducts`]?.data?.[0]?.finalProducts;

	// be sure the final product exists otherwise it should create a new branch
	if (!allFinalProducts.includes(resWithoutId.finalProduct)) {
		setAlert({
			isDiplayed: true,
			msg: "finalProduct not found, please turn to create a new branch"
		});
		return false;
	}

	return true;
};

export const clarityLocUpdateValidator = (resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert) => {
	// public validator part
	const shouldUpdate = clarityPublicValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);

	if (!shouldUpdate) return false;

	// start normal processing: it should firstly find the parent node
	const parentNode = activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data
	?.find((node) => {
		if (isNum(node?.siteId)) {
			return `${node.siteId}` === resWithoutId.nextSupplier &&
                node.finalProduct === resWithoutId.finalProduct;
		}

		return node.siteId === resWithoutId.nextSupplier &&
                node.finalProduct === resWithoutId.finalProduct;
	});

	if (!parentNode) {
		setAlert({
			isDiplayed: true,
			msg: "Can not find parent Node or you should create a branch, please check"
		});
		return false;
	}

	return parentNode;
};

export const clarityNodeUpdateValidator = (resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert) => {
	// public validator part
	const shouldUpdate = clarityPublicValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);
	return shouldUpdate;
};

const ataglancePublicValidator = (resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert) => true;

export const ataglanceNodeUpdateValidator = (resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert) => {
	// public validator part
	const shouldUpdate = ataglancePublicValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);
	return shouldUpdate;
};

export const ataglanceLocUpdateValidator = (resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert) => {
	// public validator part
	const shouldUpdate = ataglancePublicValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);

	if (!shouldUpdate) return false;

	// start normal processing: it should firstly find the parent node
	const parentNode = activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data
	?.find((node) => node.siteLabelId === resWithoutId.nextSupplier && node.finalProduct === resWithoutId.finalProduct);

	if (!parentNode) {
		setAlert({
			isDiplayed: true,
			msg: "Can not find parent Node or you should create a branch, please check"
		});
		return false;
	}

	if (parentNode
		&& parentNode.product !== "Transportation"
		&& resWithoutId.finalProduct === resWithoutId.targetProduct
		&& resWithoutId.finalProduct === resWithoutId.product
		&& resWithoutId.targetProduct === resWithoutId.product) {
		setAlert({
			isDiplayed: true,
			msg: "final product and target product and product can not be the same when parent node's type is not transportation"
		});
		return false;
	}

	return parentNode;
};

export const projectCreateValidator = () => true;

export const projectUpdateValidator = () => true;

export const userCreateValidator = () => true;

export const userUpdateValidator = () => true;

export const appCreateValidator = () => true;

export const appReplaceValidator = () => true;
