import React, { useState } from "react";
import PropTypes from "prop-types";
import { Geo } from "aws-amplify";
import AsyncSelect from "react-select/async";
import { Controller } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";

const AdminSupplierAsyncSelect = ({
	field,
	control,
	appSlice,
	placeholder = "Select...",
	styles,
	defaultValue,
	requiredFields,
	disabledFields,
	setValue,
	setValueKey,
	setMarkerPosition
}) => {
	const dispatch = useDispatch();
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const [searchKey, setSearchKey] = useState("");
	const [selectedValue, setSelectedValue] = useState(defaultValue || undefined);
	const [options, setOptions] = useState(undefined);

	const isDisabled = disabledFields?.includes(field);

	// load options using AWS location service API call
	const loadOptions = (inputValue) => (
		Geo.searchByText(inputValue, {
			maxResults: 10
		})
		.then((res) => {
			console.log("location results loaded", res);
			if (res) { setOptions(res); }
			return res;
		})
		.catch((err) => console.log("err", err))
	);

	const handleInputChange = (query, { action }) => {
		if (
			action === "input-blur" ||
			action === "menu-close") {
			return;
		}
		setSearchKey(query);
		setValue("address", query);
	};

	// handle selection
	const handleChange = (query, { action }) => {
		if (query) {
			const result = setValueKey(query);

			// result example[{key: 'city', value: 'Todtmoos'}, {key: 'country', value: 'Germany'}]
			result?.forEach(({ key, value }) => {
				setValue(key, value);
				if (key === "geopointToR" && value) {
					setMarkerPosition(value);
				}
			});
		}

		if (action === "select-option") setSelectedValue(query);
	};

	return <Controller
		name={field}
		control={control}
		render={({ field }) => (
			<>
				<AsyncSelect
					{...field}
					cacheOptions
					defaultOptions={options?.map((item) => ({ label: item.label, value: item.label }))}
					placeholder={activeRefines?.address || placeholder}
					styles={styles}
					value={selectedValue}
					inputValue={searchKey}
					loadOptions={loadOptions}
					getOptionLabel={(e) => e.label}
					getOptionValue={(e) => e.label}
					onInputChange={handleInputChange}
					onChange={handleChange}
					isDisabled={isDisabled}
				/>
			</>)}
	/>;
};

AdminSupplierAsyncSelect.propTypes = {
	control: PropTypes.object,
	field: PropTypes.string,
	placeholder: PropTypes.string,
	appSlice: PropTypes.object,
	styles: PropTypes.object,
	defaultValue: PropTypes.object,
	requiredFields: PropTypes.array,
	disabledFields: PropTypes.array,
	setValue: PropTypes.func,
	setValueKey: PropTypes.func,
	setMarkerPosition: PropTypes.func
};

export default AdminSupplierAsyncSelect;
