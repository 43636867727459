import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import VariableRadiusDonut from "../graphs/VariableRadiusDonut";
import TitleCard from "./TitleCard";

const capitalize = (dataInfo) => dataInfo.charAt(0).toUpperCase() + dataInfo.slice(1);

const ProjectScope = ({
	title = "Project Scope",
	appSlice = null,
	dataset = "",
	listCategoriesDataset = "",
	categoryField = "",
	total = 0,
	totalCovered = 0,
	locales,
	showTitleCard = true
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const dispatch = useDispatch();

	const { data } = useSelector(appSlice.selectDatasets)[dataset];

	const listCategories = useSelector(appSlice.selectDatasets)[listCategoriesDataset]?.data.map((item) => item[categoryField]);

	// TO MOVE IN CONFIGURATION
	const dataInfo = [
		{
			label: "2P",
			value: (data.find((element) => element[categoryField] === "2P"))?.frequency ?? 0,
			color: "#5363E0"
		},
		{
			label: "3P",
			value: (data.find((element) => element[categoryField] === "3P"))?.frequency ?? 0,
			color: "#963DD8"
		},
		{
			label: "Other",
			value: (data.find((element) => element[categoryField] === "Other"))?.frequency ?? 0,
			color: "#4DC490"
		}
	];

	const totalFrequency = dataInfo.reduce((acc, element) => acc + element.value, 0);

	return <div className="flex justify-start items-center pr-3 ">
		<VariableRadiusDonut dataInfo={dataInfo} total={total} totalCovered={totalCovered} />

		{showTitleCard && <div className="flex flex-grow flex-shrink-0 flex-col pl-3 gap-y-1">

			<TitleCard titleLocal={title} subTitleLocal=""
				small infoLocal="analysis.scope.desc" theme="supplyr" locales={locales} />

			<div className="flex">
				<span className={`flex justify-center items-center text-medium mr-8 
				${listCategories?.includes(capitalize(dataInfo[0].label)) ? "cursor-pointer" : ""}`}
				onClick={() => {
					if (listCategories?.includes(capitalize(dataInfo[0].label))) {
						dispatch(appSlice.actions.refine([
							{ key: categoryField, value: capitalize(dataInfo[0].label) }]));
					}
				}}>
					<div className="w-1.5 h-1.5 rounded-full bg-scope-standard mr-1"></div>
					{t(`analysis.scope.items.${dataInfo[0].label}`)} {((dataInfo[0].value / totalFrequency) * 100).toFixed(0)}%
				</span>
				<span className={`flex justify-center items-center text-medium 
				${listCategories?.includes(capitalize(dataInfo[1].label)) ? "cursor-pointer" : ""}`}
				onClick={() => {
					if (listCategories?.includes(capitalize(dataInfo[1].label))) {
						dispatch(appSlice.actions.refine([
							{ key: categoryField, value: capitalize(dataInfo[1].label) }]));
					}
				}}>
					<div className="w-1.5 h-1.5 rounded-full bg-scope-strategic mr-1"></div>
					{t(`analysis.scope.items.${dataInfo[1].label}`)} {((dataInfo[1].value / totalFrequency) * 100).toFixed(0)}%
				</span>
			</div>
			<div className="flex justify-start">
				<span className={`flex justify-center items-center text-medium mr-4
				${listCategories?.includes(capitalize(dataInfo[2].label)) ? "cursor-pointer" : ""}`}
				onClick={() => {
					if (listCategories?.includes(capitalize(dataInfo[2].label))) {
						dispatch(appSlice.actions.refine([
							{ key: categoryField, value: capitalize(dataInfo[2].label) }]));
					}
				}}>
					<div className="w-1.5 h-1.5 rounded-full bg-scope-commodities mr-1"></div>
					{t(`analysis.scope.items.${dataInfo[2].label}`)} {((dataInfo[2].value / totalFrequency) * 100).toFixed(0)}%
				</span>
				{/* <span className={`flex justify-center items-center text-medium
				${listCategories?.includes(capitalize(dataInfo[3].label)) ? "cursor-pointer" : ""}`}
				onClick={() => {
					if (listCategories?.includes(capitalize(dataInfo[3].label))) {
						dispatch(appSlice.actions.refine([
							{ key: categoryField, value: capitalize(dataInfo[3].label) }]));
					}
				}}>
					<div className="w-1.5 h-1.5 rounded-full bg-scope-bottleneck mr-1"></div>
					{t("analysis.scope.criticality.bottleneck")} {((dataInfo[3].value / totalFrequency) * 100).toFixed(0)}%
				</span> */}
			</div>
			<div className="flex items-baseline">
				<p className="mr-2">
					<span className="text-base font-light mr-1">
						{total}
					</span>
					<span className="text-xs">
						{t("analysis.scope.factory", { count: total })}
					</span>
				</p>
				<p className="text-small bg-scope-assessment px-3 rounded">
					{((totalCovered / total) * 100).toFixed(0)}% {t("analysis.scope.assessed")}
				</p>
			</div>
		</div>}
	</div>;
};

ProjectScope.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	listCategoriesDataset: PropTypes.string,
	categoryField: PropTypes.string,
	title: PropTypes.string,
	total: PropTypes.number,
	totalCovered: PropTypes.number,
	locales: PropTypes.string,
	showTitleCard: PropTypes.bool
};

export default ProjectScope;
