import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import SimpleRepartitionBar from "./SimpleRepartitionBar";
import { appDefinition, gscaColorScale, getMarkerColor } from "../../apps/configs/ClarityConfig";

const RiskAssessmentScoreCard = ({ appSlice, locales }) => {
	// Internationalization hook
	const { t, i18n } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	const activeDatasets = useSelector(appSlice.selectDatasets);

	return (
		<div className="relative w-[26.5rem] min-w-[26.5rem] 2xl:w-[27rem] h-60 rounded-xl border border-transparent
				justify-evenly pt-5 pb-1 bg-origin-border
				bg-gradient-to-r from-clarity_primary-gradientL to-clarity_primary-gradientR
				cursor-pointer hover:border-gray-300"
		onClick={() => dispatch(appSlice.actions.setPage("GSCA"))}>
			<div className="font-bold text-center relative z-10 mb-4">{t("GSCAPage.header.title")}</div>
			<img src="/images/Clarity/GSCAHandshakeIcon.svg" alt="gsca_handshake_icon"
				className="absolute left-[195px] bottom-[187px]"></img>
			<div className="flex mx-10">
				<div className="rounded-xl bg-[#c2d6ad]/75 px-2 w-full cursor-pointer">
					<p className="text-[11px] text-center my-2">{t("GSCAPage.riskOverview.abstractBreakdown")}</p>
					<div className="flex">
						<p className="font-bold text-[12px] w-48">
							{t("GSCAPage.riskOverview.GSCARiskBreakdown")}
						</p>
						<div className="w-full mt-2">
							<SimpleRepartitionBar
								appSlice={appSlice}
								dataset="gscaOverallRisksBreakdown"
								accLabel="riskCategory"
								colorScale={gscaColorScale}
								label={false}
								thickness="extraThin"
								locales={appDefinition.locales} />
						</div>
					</div>
					<div className="flex">
						<p className="font-bold text-[12px] w-48">
							{t("GSCAPage.riskOverview.envRisk")}
						</p>
						<div className="w-full mt-2">
							<SimpleRepartitionBar
								appSlice={appSlice}
								dataset="gscaEnvRiskDistribution"
								accLabel="riskCategory"
								colorScale={gscaColorScale}
								label={false}
								thickness="extraThin"
								locales={appDefinition.locales} />
						</div>
					</div>
					<div className="flex">
						<p className="font-bold text-[12px] w-48">
							{t("GSCAPage.riskOverview.HumanRightsRisk")}
						</p>
						<div className="w-full mt-2">
							<SimpleRepartitionBar
								appSlice={appSlice}
								dataset="gscaHRRiskDistribution"
								accLabel="riskCategory"
								colorScale={gscaColorScale}
								label={false}
								thickness="extraThin"
								locales={appDefinition.locales} />
						</div>
					</div>
				</div>
			</div>
			<p className="text-center text-[12px] font-bold mt-6 cursor-pointer">
				{`${activeDatasets?.gscaRiskData?.data.length} ${t("GSCAPage.riskOverview.suppliers")}`}</p>
		</div>
	);
};

RiskAssessmentScoreCard.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string
};

export default RiskAssessmentScoreCard;
