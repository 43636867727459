import React from "react";
import PropTypes from "prop-types";

const pathBuilder = (type) => {
	switch (type) {
		case "exclamationMark":

			return <path
				strokeLinecap="round"
				strokeLinejoin="round"
				d={`M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 
				3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z`}
			/>;

		default:
			return <path
				strokeLinecap="round"
				strokeLinejoin="round"
				d={`M12 19c.828 0 1.5.672 1.5 1.5S12.828 22 12 22s-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm0-17c3.314 0 6 2.686 6 6
					0 2.165-.753 3.29-2.674 4.923C13.399 14.56 13 15.297 13 17h-2c0-2.474.787-3.695 3.031-5.601C15.548 10.11 16
					9.434 16 8c0-2.21-1.79-4-4-4S8 5.79 8 8v1H6V8c0-3.314 2.686-6 6-6z`}
			/>;
	}
};

const Popconfirm = ({
	title = "Deactivate account",
	description = `Are you sure you want to deactivate your account? All of
			   your data will be permanently removed. This action cannot
			   be undone.`,
	confirmBtnText = "Deactivate",
	cancelBtnText = "Cancel",
	iconsType = "",
	onClickConfirm = () => console.log("confirm"),
	onClickCancel = () => console.log("cancel"),
	showConfirm = true,
	showCancel = true,
	fullscreen = true
}) => (
	<div
		className="absolute z-3xl"
		aria-labelledby="modal-title"
		role="dialog"
		aria-modal="true"
	>
		<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

		<div className={`fixed inset-0 z-10 ${fullscreen ? "w-screen" : ""} overflow-y-auto`}>
			<div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
				<div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl
					transition-all sm:my-8 sm:w-full sm:max-w-lg">
					<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
						<div className="sm:flex sm:items-start">
							<div
								className="mx-auto flex h-12 w-12 flex-shrink-0 items-center
                            justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
							>
								<svg
									className="h-6 w-6 text-red-600"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="currentColor"
									aria-hidden="true"
								>
									{pathBuilder(iconsType)}
								</svg>
							</div>
							<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
								<h3
									className="text-base font-semibold leading-6 text-gray-900"
									id="modal-title"
								>
                   	 					{title}
								</h3>
								<div className="mt-2">
									<p className="text-sm text-gray-500">
										{description}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
						{showConfirm &&
							<button
								type="button"
								className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2
									text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
								onClick={onClickConfirm}
							>
								{confirmBtnText}
							</button>}

						{showCancel &&
							<button
								type="button"
								className="mt-3 inline-flex w-full justify-center rounded-md bg-white
                            px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset
                            ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
								onClick={onClickCancel}
							>
                				{cancelBtnText}
							</button>}
					</div>
				</div>
			</div>
		</div>
	</div>
);

Popconfirm.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	confirmBtnText: PropTypes.string,
	cancelBtnText: PropTypes.string,
	iconsType: PropTypes.string,
	onClickConfirm: PropTypes.func,
	onClickCancel: PropTypes.func,
	showConfirm: PropTypes.bool,
	showCancel: PropTypes.bool,
	fullscreen: PropTypes.bool
};

export default Popconfirm;
