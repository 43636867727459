import React from "react";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Text } from "@visx/text";
import PropTypes from "prop-types";
import "../../styles/tree.css";
import expandmore from "../../assets/svg/expandmore.svg";
import expandmoreright from "../../assets/svg/expandmoreright.svg";

function toTitleCase(str) {
	return str?.replace(
		/\w\S*/g,
		(txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	);
}

function TreeType({
	appSlice,
	treeTypeHeight,
	name,
	currentTreeShifting,
	activeFinalProducts = [],
	nbTotalFinalProducts,
	titleCase = true,
	locales,
	textX = 200,
	arrowX = 170
}) {
	const { t, i18n } = useTranslation(locales);

	const dispatch = useDispatch();

	// rendre subtree base on collapse
	const isRendered = activeFinalProducts.find((element) => element.finalProduct === name) !== undefined;
	const otherActiveFinalProducts = activeFinalProducts.reduce((acc, element) => {
		if (element.finalProduct !== name) {
			acc.push(element.finalProduct);
		}
		return acc;
	}, []);

	const newActiveFinalProducts = [...activeFinalProducts].map((element) => element.finalProduct);

	newActiveFinalProducts.push(name);

	return (<g
		className="cursor-pointer"
		onClick={() => (!isRendered) ?
			newActiveFinalProducts.length === nbTotalFinalProducts ?
				dispatch(appSlice.actions.clear([{ refine: "finalProduct" }]))
				: dispatch(appSlice.actions.refine([{
					key: "finalProduct",
					value: newActiveFinalProducts
				}]))
			:
			dispatch(appSlice.actions.refine([{
				key: "finalProduct",
				value: otherActiveFinalProducts
			}]))
		}>
		<Text
			fontFamily="Fira sans, Arial"
			fontSize={28}
			fontWeight={`${!isRendered ? "normal" : "500"}`}
			angle={0}
			x={textX}
			y={treeTypeHeight - 40 + currentTreeShifting}
			lineHeight="0.5"
			fill={`${!isRendered ? "#CECECE" : "#686C7E"}`}
			verticalAnchor="start"
			textAnchor="start"
			scaleToFit
			className="text-ellipsis "
		>
			{
				i18n.exists(`${locales}:product.${titleCase ? toTitleCase(name) : name}`) ?
					t(`product.${titleCase ? toTitleCase(name) : name}`)
					: titleCase ? toTitleCase(name) : name
			}

		</Text>
		{
			!isRendered
				? <image
					href={expandmoreright}
					x={arrowX}
					y={treeTypeHeight - 30 + currentTreeShifting}
					className="collapsible"
				/>
				: <image
					href={expandmore}
					x={arrowX}
					y={treeTypeHeight - 30 + currentTreeShifting}
					className="collapsible"
				/>
		}
	</g>);
}

TreeType.propTypes = {
	activeFinalProducts: PropTypes.array,
	appSlice: PropTypes.object,
	arrowX: PropTypes.number,
	currentTreeShifting: PropTypes.number,
	locales: PropTypes.string,
	titleCase: PropTypes.bool,
	name: PropTypes.string,
	nbTotalFinalProducts: PropTypes.number,
	textX: PropTypes.number,
	treeTypeHeight: PropTypes.number
};

export default TreeType;
