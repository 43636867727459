import React, {
	useEffect, useRef, useMemo
} from "react";
import PropTypes from "prop-types";

import * as d3 from "d3";
import * as L from "leaflet";

import {
	useMap, GeoJSON, Tooltip, LayerGroup
} from "react-leaflet";
import { Control } from "leaflet";

import { useSelector } from "react-redux";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "./ArrowHead";

import * as helpers from "@turf/helpers";
import greatCircle from "@turf/great-circle";
import DefaultMarker from "./DefaultMarker";
import getValueNestedObject from "../../utils/GetValueNestedObject";

const SupplyRLayer = ({
	geopointField = "geometry.coordinates",
	maxClusterRadius = 15,
	CustomMarker = DefaultMarker,
	scoreAccessor = null,
	fieldsTooltip = [],
	getMarkerColor = undefined,
	customTooltip = undefined,
	colorMap = {},
	colorKey = "",
	appSlice,
	dataset,
	noClusterScore,
	locales
}) => {
	const groupRef = useRef(null);
	const map = useMap();

	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const markers = useSelector(appSlice.selectDatasets)[dataset].data
	.filter((site) => site?.geometry?.coordinates?.length > 0);

	// This one is to check if we are inside of "At a Glance". Collection supplyRSupplyChainTree used in "At a Glance";
	const supplyChainDataSetName = dataset === "supplyRallSites" ? "supplyRSupplyChainTree" : "supplyChainTree";

	const supplyChainData = useSelector(appSlice.selectDatasets)[supplyChainDataSetName];
	const supplyChainFilteredData = useSelector(appSlice.selectDatasets).mapSupplyChainSiteId;

	useEffect(() => {
		if (markers) {
			if (groupRef.current && Object.keys(groupRef?.current?.getBounds()).length === 0) {
				return;
			}
			if (markers.length === 1) {
				const geopoint = getValueNestedObject(markers[0], geopointField);
				map.setView([Number(geopoint[1]), Number(geopoint[0])], 7);
				return;
			}

			if (groupRef?.current?.getBounds()) {
				map?.fitBounds(groupRef?.current?.getBounds());
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [markers, groupRef]);

	useEffect(() => {
		map.on("fullscreenchange", () => {
			if (map.isFullscreen()) {
				console.log("entered fullscreen");
			} else {
				console.log("exited fullscreen");
			}
		});
		map.addControl(
			new Control.Fullscreen({
				position: "topright"
			})
		);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Get node siteId when filter selected from mongoDB
	const filteredSiteId = useMemo(() => supplyChainFilteredData?.data.map((item) => item.siteId), [supplyChainFilteredData?.data]);

	// TreeData[0] -> curves should render on map
	// TreeData[1] -> markers should render on map
	const treeData = useMemo(() => {
		if (
			!supplyChainData ||
			!supplyChainData.data ||
			supplyChainData.data.children.length === 0
		) {
			return null;
		}

		// ******************** defining the curves starts********************
		// Step 1 : get all the links hierarchically with d3
		const allSubTreeLinks = d3.hierarchy(supplyChainData.data)?.children
		.reduce((acc, cur) => {
			if (cur?.data?.children?.length === 0) { return [...acc, undefined]; }

			const allChildrenLinks = cur?.children?.map((item) => item.links());
			return [...acc, allChildrenLinks.flat()];
		}, [])
		.filter((item) => item);

		// If no links return [] and break then no link shows on map
		if (!allSubTreeLinks) { return []; }

		// Step 2 : get links based on filters and the results from all subtree links (previous step) (tier n-1, n)
		// filter source will get sibling child curve / filter target will get the parent curve
		// in supply chain view: source -> target(site)source -> target, in map view is reversed
		const filteredAllSubTreeLinks = allSubTreeLinks.map((item) => item.filter((el) => {
			const isExisted = filteredSiteId.some((e) => (el.source.data.siteId === e || el.target.data.siteId === e)
			&& (el.source.data.type !== "Logistic"));
			// const isExisted = filteredSiteId.some((e) => el.target.data.siteId === e);
			return isExisted;
		}))
		.filter((item) => item?.length > 0);

		// Step 3 : get all children's links of the selected nodes (tier n+1, n+2, n+3...)
		const allDescendantsLinks = d3.hierarchy(supplyChainData.data).descendants().filter((item) => {
			if (item?.data?.siteId) {
				return filteredSiteId.includes(item.data?.siteId);
			}
			return false;
		})
		.map((node) => node.links())
		.filter((item) => item?.length > 0);

		// Links of tier n-1, n, n+1, n+2, n+3 and related markers...
		// If not group elements selected then it only shows filteredAllSubTreeLinks (for product and country filters)
		// Otherwise it shows all allDescendantsLinks and filteredAllSubTreeLinks
		const linksShouldRendered = (activeRefines?.product?.length > 0 || activeRefines?.country?.length > 0)
			? [...filteredAllSubTreeLinks, ...allDescendantsLinks]
			: [...filteredAllSubTreeLinks ];
		// ******************** defining the curves ends********************

		// ******************** defining the markers starts********************
		// Step 1 : Find out all the curves' siteId
		const linksSiteIds = linksShouldRendered.map((item) => {
			const res = item.map((el) => {
				if (el?.source?.data?.geometry && el?.target?.data?.geometry && el?.source?.data?.type !== "Logistic") {
					return [el.source.data.siteId, el.target.data.siteId];
				}
				return null;
			}).filter((item) => item);
			return res;
		}).filter((item) => item.length > 0).flat(2);

		// Step 2 : Remove curves' siteId duplicate
		const linksUniqueSiteIds = [...new Set(linksSiteIds)];

		// Step 3 : Define which marker should render on map
		// if no linked should render then render marker only
		const markersShouldRendered = linksShouldRendered.length
			? markers.filter((item) => linksUniqueSiteIds.includes(item.siteId))
			: markers;
		// ******************** defining the markers ends********************

		// ******************** gererating the geojsons starts********************
		// Get all geometry infos and prepare for generating geojson
		const subTreesGeo = linksShouldRendered
		?.map((item) => item?.map((el, i) => {
			if (el?.source?.data?.geometry
				&& el?.source?.data?.geometry?.coordinates.length > 0
				&& el?.source?.data?.geometry?.coordinates[0] !== null
				&& el?.source?.data?.geometry?.coordinates[0] !== "null"
				&& el?.source?.data?.geometry?.coordinates[0] !== ""
				&& el?.source?.data?.geometry?.coordinates[0] !== undefined
				&& el?.source?.data?.geometry?.coordinates[0] !== "undefined"
				&& el?.source?.data?.geometry?.coordinates[1] !== null
				&& el?.source?.data?.geometry?.coordinates[1] !== "null"
				&& el?.source?.data?.geometry?.coordinates[1] !== ""
				&& el?.source?.data?.geometry?.coordinates[1] !== undefined
				&& el?.source?.data?.geometry?.coordinates[1] !== "undefined"
				&& el?.target?.data?.geometry
				&& el?.target?.data?.geometry?.coordinates.length > 0
				&& el?.target?.data?.geometry?.coordinates[0] !== null
				&& el?.target?.data?.geometry?.coordinates[0] !== "null"
				&& el?.target?.data?.geometry?.coordinates[0] !== ""
				&& el?.target?.data?.geometry?.coordinates[0] !== undefined
				&& el?.target?.data?.geometry?.coordinates[0] !== "undefined"
				&& el?.target?.data?.geometry?.coordinates[1] !== null
				&& el?.target?.data?.geometry?.coordinates[1] !== "null"
				&& el?.target?.data?.geometry?.coordinates[1] !== ""
				&& el?.target?.data?.geometry?.coordinates[1] !== undefined
				&& el?.target?.data?.geometry?.coordinates[1] !== "undefined"
				&& el?.target?.data?.type !== "Logistic") {
				return {
					tierLevels: [el?.source?.data?.tierLevel, el?.target?.data?.tierLevel],
					types: [el?.source?.data?.type, el?.target?.data?.type],
					products: [el?.source?.data?.product, el?.target?.data?.product],
					countrys: [el?.source?.data?.country, el?.target?.data?.country],
					criticalities: [el?.source?.data?.criticality, el?.target?.data?.criticality],
					auditedCompanies: [ el?.source?.data?.auditedCompany, el?.target?.data?.auditedCompany],
					suppliers: [ el?.source?.data?.supplier, el?.target?.data?.supplier],
					siteIds: [ el?.source?.data?.siteId, el?.target?.data?.siteId],
					score: {
						resilience: el?.target?.data?.resilience,
						resistance: el?.target?.data?.resistance,
						responsiveness: el?.target?.data?.responsiveness,
						score: el?.target?.data?.score
					},
					startEndPoint: [
						[parseFloat(el?.source?.data?.geometry?.coordinates[0]), parseFloat(el?.source?.data?.geometry?.coordinates[1])],
						[parseFloat(el?.target?.data?.geometry?.coordinates[0]), parseFloat(el?.target?.data?.geometry?.coordinates[1])]
					],
					geometry: [
						[parseFloat(el?.source?.data?.geometry?.coordinates[0]), parseFloat(el?.source?.data?.geometry?.coordinates[1])],
						[parseFloat(el?.target?.data?.geometry?.coordinates[0]), parseFloat(el?.target?.data?.geometry?.coordinates[1])]
					]
				};
			}

			return null;
		}))
		.map((item) => item.filter((el) => el))
		.filter((el) => el.length > 0);

		// Generate geojson collection and prepare for rendering curves in map
		const geoJsonCollection = subTreesGeo?.map((subtree) => {
			const geoJson = subtree
			// remove the curve which start point equals to end point (geometry level)
			.filter((data) => (data.geometry[0][0] !== data.geometry[1][0]) && (data.geometry[0][1] !== data.geometry[1][1]))
			.map((curve, i) => {
				const start = helpers?.point(curve.geometry[1]);
				const end = helpers?.point(curve.geometry[0]);

				// console.log("***********curve start point and end point***********");
				// console.log("curve start point", start);
				// console.log("curve end point", end);
				// console.log("*****************************************************");

				const greatCirclePath = greatCircle(start, end, {
					properties:
								{
									supplyChain: `${curve.products[1]} : ${curve.auditedCompanies[1]} → ${curve.auditedCompanies[0]}`,
									score: curve.score,
									startEndPoint: curve.startEndPoint
								}
				});
				return greatCirclePath;
			});

			return geoJson;
		});
		// ******************** gererating the geojsons ends********************

		return [geoJsonCollection, markersShouldRendered];
	}, [supplyChainData, activeRefines?.product?.length, activeRefines?.country?.length, markers, filteredSiteId]);

	const createClusterCustomIcon = function (cluster) {
		let clusterScore = 0;
		let nbScores = 0;
		let clusterBool = true;
		let isBool = false;
		let colorMarker = "background-color:grey";
		const count = cluster.getChildCount();
		const childMarkers = cluster.getAllChildMarkers();

		for (let i = 0; i < count; i++) {
			const markerScore = childMarkers?.[i]?.options?.score ?? undefined;

			if (markerScore !== undefined && markerScore !== null) {
				if (typeof markerScore === "boolean") {
					clusterBool = clusterBool && markerScore;
					isBool = true;
				} else {
					clusterScore += markerScore;
				}
				nbScores += 1;
			}
		}

		if (nbScores >= 1) {
			colorMarker = `background-color: ${getMarkerColor(
				clusterScore / nbScores
			)};`;
		}

		if (isBool) {
			colorMarker = `background-color: ${getMarkerColor(
				clusterBool ? "Compliant" : "Not Compliant"
			)};`;
		}

		return L.divIcon({
			html: `<div style="${colorMarker}"><span >${count}</span></div>`,
			className:
				"leaflet-marker-icon marker-cluster marker-cluster-medium leaflet-zoom-animated leaflet-interactive",
			backgroundColor: "red",
			iconSize: L.point(40, 40, true)
		});
	};

	return (
		<LayerGroup>
			{/* curves should be rendered on map  */}
			{/* {console.log(treeData)} */}
			{treeData === null || treeData[0]?.length === 0
				? ""
				: treeData[0].map((item, i) => item.map((el, j) => (
					<GeoJSON
						key={`${el.properties.supplyChain}${i}${j}`}
						data={el}
						style={{
							color: getMarkerColor(el.properties.score.resilience), weight: 3.5, opacity: 0.6
						}}
						arrowheads={{
							frequency: 1, fill: true, size: "20px", yawn: 40
						}}
					>
						<Tooltip sticky key={`toolTip${j}`}>
							{el.properties.supplyChain}
						</Tooltip>
					</GeoJSON>
				)))}

			{/* markers should be rendered on map  */}
			<MarkerClusterGroup
				key={`cluster ${markers.length}`}
				ref={groupRef}
				iconCreateFunction={!noClusterScore && createClusterCustomIcon}
				showCoverageOnHover={false}
				spiderfyDistanceMultiplier={3}
				maxClusterRadius={maxClusterRadius}
				spiderLegPolylineOptions={{
					weight: 1.5,
					color: "#1BA5D0",
					lineCap: "butt",
					dashArray: "10 10",
					opacity: 0.2
				}}
			>
				{treeData ? treeData[1].map((marker, i) => (
					<React.Fragment key={`Marker ${i}`}>
						<CustomMarker
							appSlice={appSlice}
							dataset={dataset}
							marker={marker}
							scoreAccessor={scoreAccessor}
							geopointField={geopointField}
							fieldsTooltip={fieldsTooltip}
							getMarkerColor={getMarkerColor}
							CustomTooltip={customTooltip}
							colorMap={colorMap}
							colorKey={colorKey}
							openPopup={markers.length === 1}
							locales={locales}
						/>
					</React.Fragment>
				)) : ""}
			</MarkerClusterGroup>

		</LayerGroup>
	);
};

SupplyRLayer.propTypes = {
	geopointField: PropTypes.string,
	maxClusterRadius: PropTypes.number,
	CustomMarker: PropTypes.func,
	scoreAccessor: PropTypes.func,
	fieldsTooltip: PropTypes.array,
	getMarkerColor: PropTypes.func,
	customTooltip: PropTypes.func,
	colorMap: PropTypes.object,
	colorKey: PropTypes.string,
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	noClusterScore: PropTypes.bool,
	locales: PropTypes.string
};

export default SupplyRLayer;
