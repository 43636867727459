/**
 * Style for the filters
 */
export const customStylesSupplyR = (refineValue, placeHolder) => ({
	control: (provided, state) => ({
		...provided,
		width: ((state.isMulti || refineValue === undefined) ? (placeHolder.length * 6 + 40) :
			((refineValue !== undefined) ? (refineValue[0].length * 4 + 70) : provided.width)),
		borderRadius: "0.8rem",
		borderWidth: "2px",
		minWidth: "60px",
		maxWidth: "200px",
		borderColor: refineValue !== undefined ? "#3D49AB" :
			(state.isFocused
				? "#3D49AB"
				: "none"),
		"&:hover": {
			borderColor: state.isFocused
				? "#3D49AB"
				: "#3D49AB"
		},
		boxShadow: "none",
		textAlign: "center",
		fontSize: "14px"
	}),

	singleValue: (provided, state) => ({
		...provided,
		width: "100%",
		fontWeight: refineValue !== undefined ? "bold" : "normal",
		color: "black",
		maxWidth: ((state.isMulti || refineValue === undefined) ? (placeHolder.length * 6 + 40) :
			((refineValue !== undefined) ? (refineValue[0].length * 6 + 40) : provided.width)),
		minWidth: "60px",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		marginLeft: "0px",
		marginRight: "0px",
		paddingLeft: "0px",
		paddingRight: "0px"
	}),

	option: (provided, state) => ({
		...provided,
		borderRadius: "0.8rem",
		backgroundColor: (state.isSelected) ? "#dbeafe" : "white",
		fontWeight: (state.isSelected) ? "bold" : "normal",
		color: state.isSelected ? "#3D49AB" : provided.color
	}),

	menu: (provided, state) => ({
		...provided,
		zIndex: 200,
		width: "200px",
		borderRadius: "0.8rem",
		padding: "10px"
	}),

	menuList: (provided) => ({
		...provided,
		"::-webkit-scrollbar": {
			display: "none"
		}
	}),

	indicatorSeparator: (provided) => ({
		...provided,
		display: "none"
	}),

	valueContainer: (provided) => ({
		...provided,
		padding: "0px"
	}),

	clearIndicator: (provided) => ({
		...provided,
		padding: "0px"
	}),

	placeholder: (provided, state) => ({
		...provided,
		width: "100%",
		textAlign: "center",
		fontWeight: refineValue !== undefined ? "bold" : "normal",
		color: refineValue !== undefined ? "black" : provided.color,
		marginLeft: "-8px",
		marginRight: "0px",
		paddingLeft: "0px",
		paddingRight: "0px"
	}),

	loadingIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "0px",
		paddingRight: "0px"
	})

});

/**
 * Styles for the filters
 */
export const customStylesSupplyRMobile = (refineValue) => ({
	control: (provided, state) => ({
		...provided,
		width: provided.width < 120 ? 76 : provided.width,
		borderRadius: "0.8rem",
		borderWidth: "1px",
		borderColor:
			state.isFocused
				? "none"
				: "none",
		"&:hover": {
			borderColor: state.isFocused
				? "none"
				: "none"
		},
		boxShadow: refineValue !== undefined ?
			"0 2px 3px 0 rgba(110, 184, 214, 10), 0 2px 2px 0 rgba(110, 184, 214, 6)"
			: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
		fontSize: "14px"
	}),

	singleValue: (provided, state) => ({
		...provided,
		fontWeight: (refineValue !== undefined) ? "bold" : "normal"
	}),

	option: (provided, state) => ({
		...provided,
		borderRadius: "0.8rem",
		backgroundColor: state.isSelected ? "#E3E5F5" : "white",
		fontWeight: state.isSelected ? "bold" : "normal",
		color: state.isSelected ? "#3D49AB" : provided.color
	}),

	menu: (provided, state) => ({
		...provided,
		zIndex: 9999,
		minWidth: 160,
		mawWidth: 250,
		borderRadius: "0.8rem",
		padding: "10px"
	}),

	menuList: (provided) => ({
		...provided,
		"::-webkit-scrollbar": {
			display: "none"
		}
	}),

	placeholder: (provided) => ({
		...provided,
		fontWeight: (refineValue !== undefined) ? "bold" : "normal",
		color: "gray"
	}),

	indicatorSeparator: (provided) => ({
		...provided
	}),

	loadingIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "0px",
		paddingRight: "0px"
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "6px",
		paddingRight: "6px"
	})

});

export const customStylesSearchSupplyR = (refineValue) => ({
	control: (provided, state) => ({
		...provided,
		height: "50px",
		backgroundColor: "#F2F2F2",
		borderRadius: "0.8rem",
		boxShadow: "none",
		borderWidth: refineValue !== undefined ? "2px" : "0px",
		borderColor: refineValue !== undefined ? "#3D49AB"
			: (state.isFocused
				? "none"
				: "none"),
		"&:hover": {
			borderColor: state.isFocused
				? "none"
				: "none"
		},
		fontSize: "14px"
	}),

	singleValue: (provided, state) => ({
		...provided,
		width: "100%",
		paddingRight: "20px",
		fontWeight: (refineValue !== undefined) ? "bold" : "normal",
		color: "black"
	}),

	option: (provided, state) => ({
		...provided,
		borderRadius: "0.8rem",
		backgroundColor: state.isSelected ? "#F6F6F6" : "white",
		fontWeight: (state.isSelected) ? "bold" : "normal",
		color: state.isSelected ? "#3D49AB" : provided.color
	}),

	menu: (provided, state) => ({
		...provided,
		zIndex: 2000,
		minWidth: 160,
		mawWidth: 250,
		borderRadius: "0.8rem",
		padding: "10px"
	}),

	menuList: (provided) => ({
		...provided,
		"::-webkit-scrollbar": {
			display: "none"
		}
	}),

	indicatorSeparator: (provided) => ({
		...provided,
		display: "none"
	}),

	placeholder: (provided) => ({
		...provided,
		width: "100%",
		paddingRight: "20px"
	}),

	loadingIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "0px",
		paddingRight: "0px"
	})

});
