import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import deepEqual from "deep-equal";
import PropTypes from "prop-types";

import { adminQueryDataset, adminUpload, adminPostProcessing } from "../../api/adminControl";
import uploadToS3 from "../../utils/uploadToS3";
import { asyncMap } from "../../utils/helpers";

export const addOneProject = createAsyncThunk(
	"admin/addOneProject",
	async ({
		appName, dataset, resetSkip = false
	}, { rejectWithValue }) => {
		try {
			const result = await adminQueryDataset(appName, dataset, resetSkip);
			const doc = result.data.res.ops[0];
			uploadToS3("projectLogos", typeof doc?.[dataset?.refineKey] === "object" ? doc?.[dataset?.refineKey]?.[0] : doc?.[dataset?.refineKey], [{
				data: dataset.logo,
				fileName: doc?.logo
			}]);
			return result;
		} catch (err) {
			// You can choose to use the message attached to err or write a custom error
			return rejectWithValue("an error occurs when add project");
		}
	}
);

export const retrieveAllProject = createAsyncThunk(
	"admin/retrieveAllProject",
	async ({
		appName, dataset, resetSkip = false
	}, { rejectWithValue }) => {
		try {
			return adminQueryDataset(appName, dataset, resetSkip);
		} catch (err) {
			return rejectWithValue("an error occurs when retrieve all projects");
		}
	}
);

export const retrieveOneProject = createAsyncThunk(
	"admin/retrieveOneProject",
	async ({
		appName, dataset, resetSkip = false
	}, { rejectWithValue }) => {
		try {
			return adminQueryDataset(appName, dataset, resetSkip);
		} catch (err) {
			return rejectWithValue("an error occurs when retrieve one project");
		}
	}
);

export const updateProject = createAsyncThunk(
	"admin/updateProject",
	async ({
		appName, dataset, resetSkip = false
	}, { rejectWithValue }) => {
		try {
			const result = await adminQueryDataset(appName, dataset, resetSkip);
			uploadToS3("projectLogos",
				typeof dataset?.data?.[dataset?.refineKey] === "object" ? dataset?.data?.[dataset?.refineKey]?.[0] : dataset?.data?.[dataset?.refineKey],
				[{
					data: dataset.logo,
					fileName: dataset?.data?.logo
				}]);
			return result;
		} catch (err) {
			return rejectWithValue("an error occurs when update one project");
		}
	}
);

export const updateManyProject = createAsyncThunk(
	"admin/updateManyProject",
	async ({
		appName, dataset, resetSkip = false
	}, { rejectWithValue }) => {
		try {
			return adminQueryDataset(appName, dataset, resetSkip);
		} catch (err) {
			return rejectWithValue("an error occurs when update many projects");
		}
	}
);

export const replaceDoc = createAsyncThunk(
	"admin/replaceDoc",
	async ({
		appName, dataset, resetSkip = false
	}, { rejectWithValue }) => {
		try {
			if (!dataset?.refineKey && !dataset.logo) {	return adminQueryDataset(appName, dataset, resetSkip); }

			const result = await adminQueryDataset(appName, dataset, resetSkip);
			uploadToS3("projectLogos",
				typeof dataset?.data?.[dataset?.refineKey] === "object" ? dataset?.data?.[dataset?.refineKey]?.[0] : dataset?.data?.[dataset?.refineKey],
				[{
					data: dataset.logo,
					fileName: dataset?.data?.logo
				}]);
			return result;
		} catch (err) {
			return rejectWithValue("an error occurs when replace a document");
		}
	}
);

export const deleteDoc = createAsyncThunk(
	"admin/deleteDoc",
	async ({
		appName, dataset, resetSkip = false
	}, { rejectWithValue }) => {
		try {
			return adminQueryDataset(appName, dataset, resetSkip);
		} catch (err) {
			return rejectWithValue("an error occurs when delete one project");
		}
	}
);

export const deleteManyDocs = createAsyncThunk(
	"admin/deleteManyDocs",
	async ({
		appName, dataset, resetSkip = false
	}, { rejectWithValue }) => {
		try {
			return adminQueryDataset(appName, dataset, resetSkip);
		} catch (err) {
			return rejectWithValue("an error occurs when delete many documents");
		}
	}
);

export const uploadFileToDatabase = createAsyncThunk(
	"admin/uploadFileToDatabase",
	async ({
		appName, dataset, resetSkip = false
	}, { rejectWithValue }) => {
		try {
			return adminUpload(appName, dataset, resetSkip);
		} catch (err) {
			return rejectWithValue("an error occurs when upload file to database");
		}
	}
);

export const postProcessing = createAsyncThunk(
	"admin/postprocessing",
	async ({
		appName, data, api
	}, { rejectWithValue }) => {
		try {
			return adminPostProcessing({ appName, data, api });
		} catch (err) {
			return rejectWithValue("an error occurs when executing post processing");
		}
	}
);

// TRY: VALIDATE ALL
export const validateMany = createAsyncThunk(
	"admin/validateMany",
	async ({
		appName, dataset, resetSkip = false
	}, { rejectWithValue }) => {
		try {
			return adminQueryDataset(appName, dataset, resetSkip);
		} catch (err) {
			return rejectWithValue("an error occurs when validating all nodes");
		}
	}
);

export const addOneDoc = createAsyncThunk(
	"admin/addOneDoc",
	async ({
		appName, dataset, resetSkip = false
	}, { rejectWithValue }) => {
		try {
			const result = await adminQueryDataset(appName, dataset, resetSkip);
			return result;
		} catch (err) {
			// You can choose to use the message attached to err or write a custom error
			return rejectWithValue("an error occurs when add one documents");
		}
	}
);

/**
 * Load an empty data field for all the datasets to avoid undefined tests everywhere
 *
 * @param {Object} initialDatasets Datasets parameters of a new application
 */
function initData(initialDatasets) {
	const newInitialDatasets = { ...initialDatasets };
	Object.keys(initialDatasets).forEach((datasetName) => {
		if (!newInitialDatasets[datasetName].data) {
			newInitialDatasets[datasetName].data = [];
		}
	});

	return newInitialDatasets;
}

initData.propTypes = {
	initialDatasets: PropTypes.object
};

// FIXME: remove useless currentNodeDetail/allSitesOnCurrentNode/allSuppliersOnCurrentNode
// Generic App Slice to be created and called for each App
export default function createAdminSlice(appName = "", initialPage = "", initialRefines = {},
	initialDatasets = {}, openData = false, groupFilter = {}, sliderFilter = {}) {
	const initialState = {
		page: initialPage,
		subPage: undefined,
		clientParameters: undefined,
		initialRefines: undefined,
		activeRefines: initialRefines,
		isRefined: false,
		datasets: initData(initialDatasets),
		openData,
		loadDataStatus: "notLoaded",
		groupFilter,
		sliderFilter
	};

	// Async methods to fetch a single dataset from a collection in param
	const fetchDataset = createAsyncThunk(`${appName}/fetchDataset`, async (args, { getState }) => {
		const {
			name, collection, fetch, query, limit, skip = 0, facetsToRefine = undefined, selectedReload = undefined
		} = args;

		const state = getState();
		const { initialRefines, activeRefines, datasets } = state[appName];
		const facets = facetsToRefine === undefined
			? (name in datasets && datasets[name].facetsToRefine ? datasets[name].facetsToRefine : [])
			: facetsToRefine;

		try {
			const datasetData = await adminQueryDataset(
				appName,
				{
					collection,
					fetch,
					query,
					facetsToRefine: facets,
					limit,
					skip
				},
				{ ...initialRefines, ...activeRefines },
				state[appName].openData,
				false);
			if (selectedReload !== undefined) {
				datasetData.selectedReload = selectedReload;
			}
			return { name, activeRefines: { ...initialRefines, ...activeRefines }, datasetData };
		} catch (error) {
			console.log(`Query Dataset:${error}`);
			return Promise.reject();
		}
	});

	// Async methods to fetch and updates all the datasets of the state (state.datasets)
	const fetchDatasets = createAsyncThunk(`${appName}/fetchDatasets`, async (args, { getState, rejectWithValue }) => {
		const state = getState();
		const response = {};
		const { initialRefines, activeRefines } = state[appName];

		// if query is not open, test if user is authenticated
		try {
			const user = state[appName]?.openData ? undefined : await Auth.currentAuthenticatedUser();
		} catch (error) {
			return rejectWithValue(new Error(401));
		}

		try {
			/* await Promise.all(
				Object.keys(state[appName].datasets)
				.map(async (collection) => {
					let dataset = { ...state[appName].datasets[collection] };

					// apply cross group filter
					if (initialState?.groupFilter?.isGroupFilter && initialState?.groupFilter?.groupFields.length > 0) {
						dataset = { ...dataset, ...initialState?.groupFilter };
					}

					// apply slider filter
					if (initialState?.sliderFilter?.isSliderFilter
						&& initialState?.sliderFilter?.sliderFields.length > 0
						&& (!initialState?.sliderFilter?.excludedCollections.includes(collection))
					) {
						dataset = { ...dataset, ...initialState?.sliderFilter };
					}

					const currentRefines = { ...initialRefines, ...activeRefines };

					if (dataset && dataset.data && dataset.data.length !== 0 && (dataset.selectedReload && !activeRefines[dataset.selectedReload])) {
						dataset.data = undefined;
					}

					if (dataset.fetch === "calculated"
                    || (dataset.selectedReload && !activeRefines[dataset.selectedReload])
                    || (dataset.pages !== undefined && dataset.pages.length > 0
                        && (dataset.pages?.indexOf(state[appName].page) === -1
                        && dataset.pages?.indexOf(`${state[appName].page}/${state[appName].subPage}`) === -1))
                    || (deepEqual(dataset.activeRefines, currentRefines))
                    || (dataset.facetsToRefine === undefined && dataset.data && dataset.data.length !== 0)) {
						response[collection] = dataset;
						response[collection].refreshed = false;
					} else {
						response[collection] = await adminQueryDataset(
							appName,
							dataset,
							currentRefines,
							state[appName].openData,
							true
						);
						response[collection].activeRefines = currentRefines;
						response[collection].refreshed = true;
					}
				})
			) */
			await asyncMap(Object.keys(state[appName].datasets), async (collection) => {
				let dataset = { ...state[appName].datasets[collection] };

				// apply cross group filter
				if (initialState?.groupFilter?.isGroupFilter && initialState?.groupFilter?.groupFields.length > 0) {
					dataset = { ...dataset, ...initialState?.groupFilter };
				}

				// apply slider filter
				if (initialState?.sliderFilter?.isSliderFilter
					&& initialState?.sliderFilter?.sliderFields.length > 0
					&& (!initialState?.sliderFilter?.excludedCollections.includes(collection))
				) {
					dataset = { ...dataset, ...initialState?.sliderFilter };
				}

				const currentRefines = { ...initialRefines, ...activeRefines };

				if (dataset && dataset.data && dataset.data.length !== 0 && (dataset.selectedReload && !activeRefines[dataset.selectedReload])) {
					dataset.data = undefined;
				}

				if (dataset.fetch === "calculated"
				|| (dataset.selectedReload && !activeRefines[dataset.selectedReload])
				|| (dataset.pages !== undefined && dataset.pages.length > 0
					&& (dataset.pages?.indexOf(state[appName].page) === -1
					&& dataset.pages?.indexOf(`${state[appName].page}/${state[appName].subPage}`) === -1))
				|| (deepEqual(dataset.activeRefines, currentRefines))
				|| (dataset.facetsToRefine === undefined && dataset.data && dataset.data.length !== 0)) {
					response[collection] = dataset;
					response[collection].refreshed = false;
				} else {
					response[collection] = await adminQueryDataset(
						appName,
						dataset,
						currentRefines,
						state[appName].openData,
						true
					);
					response[collection].activeRefines = currentRefines;
					response[collection].refreshed = true;
				}
			});
			return response;
		} catch (error) {
			console.log("We get some 401 errors ", error);
			return rejectWithValue(error);
		}
	});

	// Creation of the slice based of the appName with the initialState in param
	const adminSlice = createSlice({
		name: appName,
		initialState,
		reducers: {
			setPage: (state, action) => {
				state.page = action.payload;
				state.subPage = undefined;
			},
			setSubPage: (state, action) => {
				state.subPage = action.payload;
			},
			reset: (state, action) => {
				state.datasets = undefined;
			},
			refine: (state, action) => {
				const newRefine = { ...state.activeRefines };
				action.payload.forEach((facet) => ((facet.value !== null && facet.value !== undefined)
                    && (!Array.isArray(facet.value) || ((Array.isArray(facet.value))
                    && facet.value.length > 0 && facet.value[0])))
					? newRefine[facet.key] = facet.value : delete newRefine[facet.key]);
				state.activeRefines = newRefine;
				state.loadDataStatus = "loading";
				state.isRefined = (Object.keys(newRefine)?.filter((key) => newRefine[key] !== undefined)?.length !== 1);
			},
			clear: (state, action) => {
				const newRefine = { ...state.activeRefines };
				action.payload.forEach((element) => {
					delete newRefine[element.refine];
				});
				state.activeRefines = newRefine;
				state.isRefined = false;
			},
			setInitialRefines: (state, action) => {
				state.initialRefines = action.payload;
			},
			fetchCalculatedDataset: (state, action) => {
				const newDatasets = { ...state.datasets };
				newDatasets[action.payload.datasetName] = {
					fetch: "calculated",
					data: action.payload.datasetData
				};
				state.datasets = newDatasets;
			},
			deleteFromSubFilter: (state, action) => {
				const { field, item } = action.payload;

				const newRefine = Array.isArray(state.activeRefines[field]) ?
					state.activeRefines[field].filter((e) => e !== item)
					: undefined;

				if (newRefine === undefined || newRefine === null || newRefine.length === 0) {
					delete state.activeRefines[field];
					state.loadDataStatus = "loading";
					return;
				}

				state.activeRefines[field] = newRefine;
				state.loadDataStatus = "loading";
			}
		},
		extraReducers: {
			[fetchDataset.pending]: (state) => {
				state.loadDataStatus = "loading";
			},
			[fetchDataset.fulfilled]: (state, action) => {
				if (action.payload.name === "clientParameters") {
					state.clientParameters = action?.payload?.datasetData?.data?.[0];
				} else {
					const newDatasets = { ...state.datasets };
					newDatasets[action.payload.name] = action.payload.datasetData;
					newDatasets[action.payload.name] = { ...newDatasets[action.payload.name], activeRefines: action?.payload?.activeRefines };
					state.datasets = newDatasets;
				}

				state.loadDataStatus = "idle";
			},
			[fetchDataset.rejected]: (state) => {
				state.loadDataStatus = "rejected";
			},
			[fetchDatasets.pending]: (state) => {
				state.loadDataStatus = "loading";
			},
			[fetchDatasets.fulfilled]: (state, action) => {
				state.datasets = action.payload;
				// console.log(Object.keys(state.datasets).filter((element) => state.datasets[element].refreshed));
				state.loadDataStatus = "idle";
			},
			[fetchDatasets.rejected]: (state, action) => {
				if (action?.payload?.message === "401" || action?.payload?.message === 401) {
					state.loadDataStatus = "sessionExpired";
				} else {
					state.loadDataStatus = "rejected";
				}
			},
			[addOneProject.fulfilled]: (state, action) => {
				if (action.payload.data.res.n) { console.log("add one project successfully"); } else { console.log("add one project fail"); }
			},
			[retrieveAllProject.fulfilled]: (state, action) => {
				if (action.payload?.data.length > 0) {
					console.log("retrieve all project successfully");
				} else { console.log("retrieve all project fail"); }
			},
			[retrieveOneProject.fulfilled]: (state, action) => {
				if (action.payload?.data.length > 0) {
					console.log("retrieve a project successfully");
				} else { console.log("retrieve a project fail"); }
			},
			[updateProject.fulfilled]: (state, action) => {
				if (action.payload.data.res.n) { console.log("update a project successfully"); } else { console.log("update a project fail"); }
			},
			[updateManyProject.fulfilled]: (state, action) => {
				if (action.payload.data.res.n) { console.log("update many projects successfully"); } else { console.log("update many projects fail"); }
			},
			[replaceDoc.fulfilled]: (state, action) => {
				if (action.payload.data.res.n) { console.log("replace a document successfully"); } else { console.log("replace a document fail"); }
			},
			[deleteDoc.fulfilled]: (state, action) => {
				if (action.payload.data.res.n) { console.log("delete a project successfully"); } else { console.log("delete a project fail"); }
			},
			[deleteManyDocs.fulfilled]: (state, action) => {
				if (action.payload.data.res.n) { console.log("delete many documents successfully"); } else { console.log("delete many documents fail"); }
			},
			[uploadFileToDatabase.fulfilled]: (state, action) => {
				const isSuccess = action.payload.res.every((item) => item === 1);

				if (isSuccess) {
					console.log("upload file to database successfully");
				} else {
					// return error item position in file
					const res = action.payload.res.reduce((acc, cur, i) => {
						if (cur !== 1) return [...acc, i];
						return [...acc];
					}, []);
					console.log("upload file to database fail");
				}
			},
			[postProcessing.fulfilled]: (state, action) => {
				// TODO:
				console.log("action.payload in postProcessing", action.payload);
			},
			[validateMany.fulfilled]: (state, action) => {
				if (action.payload.data.res.n) { console.log("validate many documents successfully"); } else { console.log("delete many documents fail"); }
			},
			[addOneDoc.fulfilled]: (state, action) => {
				if (action.payload.data.res.n) { console.log("add one document successfully"); } else { console.log("add one document fail"); }
			}
		}
	});

	// selectors of the state
	const selectAppName = (state) => appName;
	const selectPage = (state) => (state[appName] ? state[appName].page : undefined);
	const selectSubPage = (state) => (state[appName] ? state[appName].subPage : undefined);
	const selectActiveRefines = (state) => (state[appName] ? state[appName].activeRefines : undefined);
	const isRefined = (state) => (state[appName] ? state[appName].isRefined : undefined);
	const selectClientParameters = (state) => (state[appName] ? state[appName].clientParameters : undefined);
	const selectDatasets = (state) => (state[appName] ? state[appName].datasets : undefined);
	const selectLoadDataStatus = (state) => (state[appName] ? state[appName].loadDataStatus : undefined);

	// return all the elements of the created slice
	return {
		fetchDataset,
		fetchDatasets,
		reducer: adminSlice.reducer,
		actions: adminSlice.actions,
		selectAppName,
		selectPage,
		selectSubPage,
		selectActiveRefines,
		isRefined,
		selectClientParameters,
		selectDatasets,
		selectLoadDataStatus
	};
}
