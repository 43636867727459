import React from "react";
import PropTypes from "prop-types";
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import { useTranslation } from "react-i18next";
import OneLineEvolutionGraph from "../graphs/OneLineEvolutionGraph";

const ScoreEvolutionCard = ({
	appSlice, locales, dataset, gradientL = "#C8DA75", gradientR = "#44B9B4", picto = "ClarityPicto"
}) => {
	const { t } = useTranslation(locales);

	return (
		<div className="w-full h-fit bg-gray-400 rounded-xl flex drop-shadow-xl"
			style={{
				background: `radial-gradient(at left, ${gradientL}, ${gradientR})`
			}}>
			<div className="h-full w-full rounded-l-xl p-4 z-500">
				<span className="font-semibold">{t("campaignScoresEvolutionCard.title")}</span>
				<div className="w-full h-full flex">
					<div className="mt-4 w-full">
						<ParentSize>
							{(parent) => <OneLineEvolutionGraph width={parent.width} height={parent.height} appSlice={appSlice} dataset={dataset}/>}
						</ParentSize>
					</div>
				</div>
			</div>
			<div className="h-full w-1/4 flex">
				<img src={`images/Clarity/${picto}.png`} alt="" className="drop-shadow-lg p-4 max-w-full max-h-full self-center"/>
			</div>
		</div>
	);
};

ScoreEvolutionCard.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	dataset: PropTypes.string,
	gradientL: PropTypes.string,
	gradientR: PropTypes.string,
	picto: PropTypes.string
};

export default ScoreEvolutionCard;
