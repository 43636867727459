import React from "react";

const RYBLegalNotice = () => (
	<div className="text-sm">
		<h2 className="text-tab-legalNotice-title text-2xl font-medium">
			Legal notice of the web site
		</h2>
		<h3 className="text-gray-500 text-sm">(Last update: June, 2023)</h3>
		<ul className="list-disc mt-4 ml-10 text-tab-legalNotice-title">
			<li>
				<strong>Publisher:&nbsp;</strong>The website&nbsp;
				<a className="hover:underline" href="https://myradar.bureauveritas.com">
					https://myradar.bureauveritas.com
				</a>
				&nbsp;(hereinafter the &ldquo;Website&rdquo;) is published by Bureau
				Veritas Services, soci&eacute;t&eacute; par actions simplifi&eacute;e,
				registered with the Companies and Trade Register of Nanterre under
				number 318 720 653, with registered office at 8 cours du Triangle
				&ndash; 92800 Puteaux &ndash; France and whose intracommunautary VAT
				number is FR88318720653
			</li>
			<li>
				<strong>Tel:&nbsp;</strong>
				<a className="hover:underline" href="tel:+33155247000">
					+33 1 55 24 70 00
				</a>
			</li>
			<li>
				<strong>Email:&nbsp;</strong>
				<a
					className="hover:underline"
					href="mailto:restartwithbv@bureauveritas.com"
				>
					restartwithbv@bureauveritas.com
				</a>
			</li>

			<li>
				<strong>Publishing Director:&nbsp;</strong>Vincent Bourdil
			</li>

			<li>
				<strong>Hosting of the Website:&nbsp;</strong>the Website is hosted by
				Amazon Web Services EMEA SARL, with registered office at 38 avenue John
				Kennedy L 1855 99137 Luxembourg.
			</li>
			<li>
				<strong>Fax.:&nbsp;</strong>
				<a className="hover:underline" href="tel:+352 2789 0057">
					+352 2789 0057
				</a>
			</li>
		</ul>
	</div>
);

export default RYBLegalNotice;
