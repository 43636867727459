import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

import ClarityIndicator from "../graphs/ClarityIndicator";
import ClarityNetZeroLegend from "../legends/ClarityNetZeroLegend";

function RenderScope({
	scope, tCO2, index
}) {
	return (
		<div className={`flex space-x-2 items-center justify-between w-full text-medium 
        ${(index === undefined || Number.isNaN(parseFloat(index))) && "opacity-40"}`}>
			<p className="flex-shrink-0">{scope}</p>
			<div className="relative flex flex-grow flex-col items-center space-y-1 justify-center">
				{(index === undefined || Number.isNaN(parseFloat(index))) &&
                <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-small">Not activated</p>}
				<div className="flex flex-grow rounded-full h-3 bg-white bg-opacity-40 w-full overflow-hidden">
					<div className="h-full bg-clarity_primary-netZeroAlt rounded-full" style={{ width: `${index}%` }} />
				</div>
				<div className="relative flex flex-grow rounded-full h-2 bg-white bg-opacity-40 w-full verflow-hidden">
					<div className="h-full bg-clarity_primary-menu rounded-full" style={{ width: `${!Number.isNaN(tCO2) ? tCO2 : 0}%` }} />
				</div>
			</div>
			<div className="flex flex-col items-center justify-between w-10 h-full">
				{(index !== undefined && !Number.isNaN(parseFloat(index))) &&
				<>
					<div className="flex items-center justify-between space-x-1">
						<div className="flex items-center justify-center w-8 h-3
						 text-smaller font-semibold bg-clarity_primary-netZeroAlt text-black rounded-md">
							<p>{index}</p>
						</div>
						{/* <TrendingUpIcon fontSize="inherit" className="text-white "/> */}
					</div>
					<div className="flex items-center justify-between space-x-1 h-2">
						<p className="w-8 text-center">{!Number.isNaN(tCO2) ? tCO2 : 0}%</p>
						{/* <TrendingFlatIcon fontSize="inherit" className="text-white "/> */}
					</div>
				</>
				}
			</div>
		</div>
	);
}

RenderScope.propTypes = {
	scope: PropTypes.func,
	tCO2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	index: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

function ClarityNetZeroIndicatorCard({
	appSlice, moduleKey, module, isPushed = false,
	moduleScore = {
		score: 0, nbSites: 0, nbNC: 0, trend: ""
	},
	locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store
	const moduleScoresData = useSelector(appSlice.selectDatasets)?.modulesScores?.data;
	const sectionKPIsData = useSelector(appSlice.selectDatasets)?.netZeroCO2perScope?.data;

	const section1Data = moduleScoresData?.find((module) => module.moduleIndex === "NZ1");
	const section2Data = moduleScoresData?.find((module) => module.moduleIndex === "NZ2");
	const section3Data = moduleScoresData?.find((module) => module.moduleIndex === "NZ3");

	const tCO2eq = sectionKPIsData
	.reduce((previousValue, currentValue) => {
		previousValue[currentValue.moduleIndex] += currentValue.emissionValue;
		return previousValue;
	}, { NZ1: 0, NZ2: 0, NZ3: 0 });

	const totalTCO2eq = (tCO2eq?.NZ1 ?? 0) + (tCO2eq?.NZ2 ?? 0) + (tCO2eq?.NZ3 ?? 0);

	if (module === undefined || section1Data === null) {
		return null;
	}

	const {
		score, nbSites, nbNC, trend
	} = moduleScore;

	return (
		<div onClick={() => (!isPushed) ?
			dispatch(appSlice.actions.refine([{ key: "domain", value: moduleKey }])) : null}
		className={`bg-clarity_primary-netZero text-white rounded-xl w-[26.5rem] 2xl:w-[27rem] border border-transparent 
		flex space-between items-center space-x-8 pl-3 md:pl-6 pr-3 py-3 md:py-4
            ${isPushed ?
			"bg-opacity-60 shadow-innerxl"
			: "bg-opacity-90 shadow-xs cursor-pointer hover:border-gray-300"}`}>

			<div className="relative flex flex-col items-center z-10">

				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                  h-36 w-36 pt-1" style={{ zIndex: -1 }}>
					<img src={module?.domainLogo} alt={module?.domainLogo} className="w-full h-full opacity-20 filter brightness-200" />
				</div>

				<div className={`text-left tracking-tight w-36 col-span-2 whitespace-nowrap
                ${nbSites === 0 ? " text-gray-400" : ""}`}>
					{t(`domains.${module.locale}.name`)}
				</div>

				<ClarityIndicator label={module.label} moduleScore={moduleScore} color={module.domainColor} textInnerColor="white" />

				<div className="flex items-center justify-center rounded-md bg-clarity_primary-menu
				text-medium space-x-2 py-1 w-max px-4">
					<p className="text-black">
                        tCO<sub>2</sub>eq <span className="font-semibold">
							{totalTCO2eq > 1000 ? (`${(totalTCO2eq / 1000).toFixed(0) }k`) : totalTCO2eq?.toFixed(1)}
						</span>
					</p>
					<TrendingFlatIcon fontSize="inherit" className="text-gray-500 "/>
				</div>
			</div>
			<div className="flex flex-grow flex-col justify-end space-y-6 w-full h-full">
				<div className="flex flex-col w-full space-y-4 ">
					<RenderScope scope={t("netZero.scopeCards.scope 1")}
						tCO2={totalTCO2eq > 0 ? ((tCO2eq.NZ1 / totalTCO2eq) * 100)?.toFixed(0) : 0}
						index={((section1Data?.moduleScore ?? 0) * 100)?.toFixed(0)} />
					<RenderScope scope={t("netZero.scopeCards.scope 2")}
						tCO2={totalTCO2eq > 0 ? ((tCO2eq.NZ2 / totalTCO2eq) * 100)?.toFixed(0) : 0}
						index={((section2Data?.moduleScore ?? 0) * 100)?.toFixed(0)}/>
					<RenderScope scope={t("netZero.scopeCards.scope 3")}
						tCO3={totalTCO2eq > 0 ? ((tCO2eq.NZ3 / totalTCO2eq) * 100)?.toFixed(0) : 0}
						index={((section3Data?.moduleScore ?? 0) * 100)?.toFixed(0)}/>
				</div>
				<ClarityNetZeroLegend locales={locales} />
			</div>
		</div>
	);
}

ClarityNetZeroIndicatorCard.propTypes = {
	appSlice: PropTypes.object,
	moduleKey: PropTypes.string,
	module: PropTypes.object,
	isPushed: PropTypes.bool,
	moduleScore: PropTypes.object,
	locales: PropTypes.string
};

export default ClarityNetZeroIndicatorCard;
