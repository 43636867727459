import PropTypes from "prop-types";
import React from "react";

import BusinessIcon from "@mui/icons-material/Business";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PinDropIcon from "@mui/icons-material/PinDrop";

const AdminTooltip = ({
	appSlice, node, rectW, currentTreeShifting, transparent = false
}) => {
	const width = rectW + 20;
	const height = 250;
	const padding = 250;

	return (
		<g>
			<foreignObject
				x={-width / 2 + 30}
				y={currentTreeShifting - padding}
				width={width}
				height={height}
				className="invisible hover:visible group-hover:visible "
			>
				<div className="h-full w-full flex items-end p-2">
					(
					<div className={`relative flex flex-col w-full gap-y-1.5 rounded-2xl px-4 py-2.5 border-2 ${!transparent && "alphaBlur"}`}
						style={{ width }}>

						<div id="company" className={"flex gap-x-2 w-full"} >
							<BusinessIcon />
							<div className="flex flex-col w-full" >
								<p className={`text-medium ${node?.data?.data.supplierCode ? "leading-normal" : "leading-6"} line-clamp-2 font-semibold`}>
									<span className="line-clamp-1">
										{node?.data?.data.supplier}
									</span>
									<span className="text-xs line-clamp-1">
										{node?.data?.data?.supplierCode ?? ""}
									</span>
								</p>
							</div>
						</div>

						<div className={`flex ${node?.data?.data?.supplierCode ? "items-center" : ""} w-full gap-x-2.5`}>
							<MapsHomeWorkIcon className="text-sm ml-2" />

							<p className="text-small line-clamp-3" >
								<span className="line-clamp-2">
									{node?.data?.data.auditedCompany} {(node?.data?.data?.factoryBranch || node?.data?.data?.factoryIndex)
									&& `- ${node?.data?.data?.factoryBranch || node?.data?.data?.factoryIndex}`}
								</span>
								<span className="text-xs line-clamp-1">
									{node?.data?.data?.factoryCode ?? ""}
								</span>
							</p>
						</div>

						<div id="location" className="flex border items-center gap-x-2 px-2 py-1 border-gray-200 rounded-md text-medium ">
							<PinDropIcon fontSize="inherit" />
							<p className="text-small leading-4 line-clamp-1 font-light" style={{ maxWidth: width - 25 }}>
								{node?.data?.data.city} - {node?.data?.data.country}
							</p>
						</div>

					</div>
				</div>
			</foreignObject>
		</g>
	);
};

AdminTooltip.propTypes = {
	appSlice: PropTypes.object,
	node: PropTypes.object.isRequired,
	rectW: PropTypes.number,
	currentTreeShifting: PropTypes.number,
	transparent: PropTypes.bool
};

export default AdminTooltip;
