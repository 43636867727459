/* eslint-disable react/prop-types */
import React, { createRef } from "react";
import Select, { createFilter, components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import PropTypes, { element } from "prop-types";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { data } from "autoprefixer";
import IF from "../../utils/IF";
import GetMenuValues from "./GetMenuValues";

const defaultCustomStyles = (refineValue) => ({
	control: (provided, state) => ({
		...provided,
		width: provided.width,
		minWidth: 220,
		backgroundColor: refineValue !== undefined ? "#1876bd" : provided.backgroundColor
	}),

	singleValue: (provided, state) => ({
		...provided,
		color: refineValue !== undefined ? "white" : provided.backgroundColor
	}),

	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#1876bd" : provided.backgroundColor
	}),

	menu: (provided, state) => ({
		...provided,
		zIndex: 2000,
		minWidth: 160
	}),

	indicatorSeparator: (provided) => ({
		...provided
	}),

	loadingIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "0px",
		paddingRight: "0px"
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		color: refineValue !== undefined ? "white" : provided.backgroundColor,
		paddingLeft: "6px",
		paddingRight: "6px"
	})
});

/**
 * Style for the filters
 */
export const defaultCleanCustomStyles = (refineValue, placeHolder, tag, color) => ({
	control: (provided, state) => ({
		...provided,
		width: ((state.isMulti || refineValue === undefined) ? ((placeHolder.length + tag.length) * 7 + 20) :
			((refineValue !== undefined) ? ((refineValue[0].length + tag.length) * 7 + 60) : provided.width)),
		borderRadius: "0.8rem",
		borderWidth: "2px",
		minWidth: "80px",
		maxWidth: "300px",
		borderColor: refineValue !== undefined ? color :
			(state.isFocused
				? "none"
				: "none"),
		"&:hover": {
			borderColor: state.isFocused
				? "none"
				: "none"
		},
		boxShadow: "none",
		textAlign: "center",
		fontSize: "14px"
	}),

	singleValue: (provided, state) => ({
		...provided,
		width: "100%",
		textAlign: "center",
		fontWeight: refineValue !== undefined ? "bold" : "normal",
		color: "black",
		maxWidth: ((state.isMulti || refineValue === undefined) ? ((placeHolder.length + tag.length) * 7 + 20) :
			((refineValue !== undefined) ? ((refineValue[0].lengt + tag.length) * 7 + 60) : provided.width)),
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		marginLeft: "0px",
		marginRight: "0px",
		paddingLeft: "0px",
		paddingRight: "0px"
	}),

	option: (provided, state) => ({
		...provided,
		borderRadius: "0.8rem",
		backgroundColor: state.isFocused ? "#F6F6F6" : "white",
		fontWeight: (state.isFocused || state.isSelected) ? "bold" : "normal",
		color: state.isSelected ? color : provided.color
	}),

	menu: (provided, state) => ({
		...provided,
		zIndex: 200,
		width: "200px",
		borderRadius: "0.8rem",
		padding: "10px"
	}),

	menuList: (provided) => ({
		...provided,
		"::-webkit-scrollbar": {
			display: "none"
		}
	}),

	indicatorSeparator: (provided) => ({
		...provided,
		display: "none"
	}),

	placeholder: (provided, state) => ({
		...provided,
		width: "100%",
		textAlign: "center",
		fontWeight: refineValue !== undefined ? "bold" : "normal",
		color: refineValue !== undefined ? "black" : provided.color,
		marginLeft: "0px",
		marginRight: "0px",
		paddingLeft: "0px",
		paddingRight: "0px"
	}),

	loadingIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "0px",
		paddingRight: "0px"
	})

});

const FilterMenu = ({
	appSlice = null, options, customStyle = undefined, textClasses = undefined, menuSelectedColor = "blue", clearOnChange,
	isSearch = false, cleanStyle = false, displayNb = false, className, locales
}) => {
	const {
		refine = undefined,
		refineGroup = undefined,
		displayField = undefined,
		tag = "",
		placeHolder = "",
		onlyIfRefine = undefined,
		hiddenIfUnique = false,
		hiddenIfEmpty = false,
		isMulti = false,
		clear = false,
		isSearchable = false,
		isClearable = false,
		isGrouped = false,
		manualRenderOption = false,
		groupSelection = true,
		openMenuOnClick = true,
		toClearOnChange,
		loadKey = undefined,
		loadFromDataset,
		clearRefines = undefined, // array of refine strings to clear on change,
		sortKey1 = undefined,
		sortKey2 = undefined,
		translateLabel = undefined,
		translationKeyPrefix = undefined
	} = options;

	const dispatch = useDispatch();

	const { t } = useTranslation(locales);

	let selectRef = createRef();

	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);
	const menuData = useSelector(appSlice.selectDatasets)?.[loadFromDataset]?.data;
	const maxCampaignIDs = useSelector(appSlice.selectDatasets)?.getMaxCampaignId?.data;

	if (refine === undefined || (onlyIfRefine !== undefined && !onlyIfRefine(activeRefines, clientParameters))) {
		return null;
	}

	const activeRefineValue = Array.isArray(activeRefines[refine]) ? activeRefines[refine] : [activeRefines[refine]];
	const activeRefineGroupedValue = Array.isArray(activeRefines[menuData?.[0]?.refine]) ?
		activeRefines[menuData?.[0]?.refine]
		: [activeRefines[menuData?.[0]?.refine]];

	let refineValue = (isGrouped && groupSelection ?
		[...activeRefineGroupedValue, ...activeRefineValue]
		: activeRefineValue).filter((element) => element !== undefined);

	refineValue = ((refineValue.length === 0) ? undefined : Array.from(new Set(refineValue)));

	const listValue =
		isGrouped ?
			[].concat(menuData)
			?.sort((a, b) => a.label > b.label ? 1 : -1)
			?.map((group) => ({
				_id: group?._id,
				label: group?.label,
				value: group?.value,
				refine: group?.refine,
				options: [...(group?.options ?? [])]
				?.sort((a, b) => a.index ? ((Number.parseInt(a.index, 10) > Number.parseInt(b.index, 10)) ? 1 : -1)
					: a.label > b.label ? 1 : -1)
			}))
			: GetMenuValues(menuData, loadKey ?? refine, displayField, sortKey1, sortKey2);

	const translateLabels = (data) => data.map((el) => ({ ...el, label: t(`${translationKeyPrefix}.${el.label}.name`) }));

	const mapLabelValue = listValue.reduce((acc, element) => {
		if (typeof element.label === "number") {
			element.label = element.label.toString();
		}

		if (Array.isArray(element.value)) {
			element?.value?.forEach((v) => { acc[v] = element.label; });
		} else if (translateLabel && translationKeyPrefix !== undefined) {
			// Translate only labels if needed (if specified in filterOptions config
			// by "translateLabel" and "translationKeyPrefix")
			acc[element.value] = t(`${translationKeyPrefix}.${element.label}.name`);
		} else {
			acc[element.value] = element.label;
		}
		element.options?.forEach((option) => {
			acc[option.value] = option.label;
		});
		return acc;
	}, {});

	if (hiddenIfUnique && listValue.length < 2) {
		return null;
	}
	if (hiddenIfEmpty && listValue.length === 0) {
		return null;
	}

	const isLoading = (clear && loadDataStatus === "loading");
	const MaterialPicto = tag;

	const placeholderComponent = (
		<div className={`flex items-center w-full  ${(tag !== "" || (isSearch && !refineValue)) ?
			"justify-between" : "justify-center"} ${ textClasses && textClasses}` }>
			<div className="flex items-center gap-x-1">
				<IF condition={tag !== "" && !isSearch }>
					<MaterialPicto fontSize="inherit" />
				</IF>
				<p>{t(`${placeHolder}`)}</p>
			</div>
			<IF condition={!isLoading && isSearch }>
				<SearchIcon fontSize="small" data-test="react-select-search-icon"/>
			</IF>
		</div>
	);

	const valueComponent = (
		<div className={`flex items-center justify-between  w-full ${ textClasses && textClasses}`} >
			<div className={`flex items-center gap-x-1 w-full ${(tag !== "" || (isSearch && !refineValue)) ?
				"justify-start" : "justify-center"}`}>
				<IF condition={tag !== ""}>
					<MaterialPicto fontSize="inherit" className={!refineValue ? "text-gray-400" : ""} />
				</IF>
				<div className="text-md w-full flex justify-start ml-2">
					{(refineValue !== undefined && refineValue !== null && mapLabelValue) &&
					[...new Set(refineValue?.map((element) => mapLabelValue[element]))]?.join("/")
					}
				</div>
			</div>

		</div>
	);
	const LoadingIndicator = () => (
		<svg className="animate-spin -ml-3 mr-1 h-3 w-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
			<circle className="opacity-25" cx="" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
			<path className="opacity-75" fill="black"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
		</svg>
	);

	const formatOptionLabel = (option) => (
		<div className={`rounded-lg -m-2 p-2 cursor-pointer text-sm
             ${(!refineValue || refineValue?.indexOf(option.value) === -1) ? "hover:bg-blue-100" : "bg-blue-200"}`}>
			{option.label}
		</div>
	);

	const formatGroupLabel = (data) => {
		if (!isGrouped) {
			return null;
		}

		if (!groupSelection) {
			return <span>{data.label}</span>;
		}

		const currentRefine = activeRefines?.[data.refine];
		const toRefine = ((Array.isArray(currentRefine) && currentRefine.indexOf(data.value) === -1)
        || (!Array.isArray(currentRefine) && currentRefine !== data.value));

		return (<div className={`-mt-2 -ml-3 uppercase cursor-pointer font-semibold text-sm text-black rounded-lg py-2 px-1
        ${ toRefine ? "hover:bg-blue-100 " : "bg-blue-200"}`}
		onClick={() => {
			if (toRefine) {
				const newGroupesRefineForKey = (currentRefine !== undefined && isMulti) ?
					(Array.isArray(currentRefine) ? [...currentRefine, data.value]
						: [activeRefines[data.refine], data.value])
					: data.value;
				dispatch(appSlice.actions.refine([{ key: data.refine, value: newGroupesRefineForKey }]));
				selectRef.blur();
			}
		}}>
			{data.label}
		</div>);
	};

	const setValue = (
		refineValue ?
			((isMulti && Array.isArray(refineValue)) ? refineValue
			.map((refine) => ({ label: refine, value: refine }))
				: { label: valueComponent, value: refineValue })
			: null
	);

	// handle selection
	const handleChange = (value, { ...props }) => {
		const newRefineForKey = value
			? (isMulti
				? value.map((element) => element.value).filter((element) => activeRefineGroupedValue.indexOf(element) === -1)
				: value.value)
			: undefined;

		const newRefineGroup = value && isGrouped ?
			(isMulti
				? value.map((element) => element.value).filter((element) => activeRefineGroupedValue.indexOf(element) > -1)
				: ((activeRefineGroupedValue.indexOf(value.value) > -1) ? value.value : undefined))
			: undefined;

		if (((value === undefined || value === null) && isGrouped && !isMulti) ||
			(isMulti && isGrouped && Array.isArray(value) && value.length === 0)) {
			dispatch(appSlice.actions.refine([{ key: refine, value: undefined }, { key: refineGroup, value: undefined }]));
		} else if (isGrouped) {
			dispatch(appSlice.actions.refine([{ key: refine, value: newRefineForKey }, { key: refineGroup, value: newRefineGroup }]));
		} else {
			dispatch(appSlice.actions.refine([{ key: refine, value: newRefineForKey }]));
			// When refining by spaceLabel (Clarity) we also need to dispatch the latest campaignId for this spaceLabel.
			if (refine === "spaceLabel") {
				const maxCampaignId = maxCampaignIDs.find((el) => el._id === value?.value?.[0])?.maxCampaignId;
				dispatch(appSlice.actions.refine([{ key: "campaignId", value: maxCampaignId }]));
			}
		}
		if (!isMulti && props?.removedValues?.length >= 1) dispatch(appSlice.actions.clear([{ refine }, { refine: listValue[0]?.refine }]));
		if (toClearOnChange) {
			dispatch(appSlice.actions.clear(clearOnChange));
		}

		if (clearRefines !== undefined) {
			dispatch(appSlice.actions.refine(clearRefines.map((e) => ({ key: e, value: undefined }))));
		}
	};

	const filterOptions = (candidate, input) => {
		if (candidate.label?.toLocaleLowerCase().includes(input?.toLocaleLowerCase())) {
			return true;
		}

		// check if a group has the filter string as label
		const groupOptions = listValue.filter((group) => group?.label?.toLocaleLowerCase().includes(input?.toLocaleLowerCase())
		);

		if (groupOptions) {
			// eslint-disable-next-line no-restricted-syntax
			for (const groupOption of groupOptions) {
				// Check if current option is in group
				const option = groupOption?.options?.find((opt) => opt?.label?.toLocaleLowerCase() === candidate?.label?.toLocaleLowerCase());
				if (option) {
					return true;
				}
			}
		}

		return false;
	};

	// Overwrite of the dropdown button container to assign test id to use it for clicking in Cypress.
	const IndicatorsContainer = (props) => (
		<div data-test={`${options?.label}-dropdown`}>
			  <components.IndicatorsContainer {...props} />
		</div>
	);

	// Overwrite the options of the dropdown to assign test id to use it for clicking in Cypress.
	const Option = (props) => (
		<div data-test={`${props?.label.replaceAll(" ", "-").toLowerCase()}`}>
			  <components.Option {...props} />
		</div>
	);

	return (
		<div className="relative">
			<Select
				ref={(input) => selectRef = input}
				className={className}
				options={translateLabel && translationKeyPrefix ? translateLabels(listValue) : listValue}
				components={(cleanStyle ? {
					DropdownIndicator: () => null,
					IndicatorSeparator: () => null,
					IndicatorsContainer,
					Option,
					LoadingIndicator
				} : { LoadingIndicator, IndicatorsContainer, Option })
				}
				styles={customStyle !== undefined ? customStyle(refineValue, t(placeHolder)) :
					(cleanStyle ? defaultCleanCustomStyles(refineValue, t(placeHolder), tag, menuSelectedColor)
						: defaultCustomStyles(refineValue))}
				key={`select ${tag}`}
				isMulti={isMulti}
				autosize={true}
				value={setValue}
				isSearchable={isSearchable}
				openMenuOnClick={openMenuOnClick}
				menuPlacement="auto"
				isLoading={isLoading}
				isClearable={isClearable}
				onChange={handleChange}
				controlShouldRenderValue={!manualRenderOption}
				blurInputOnSelect={true}
				menuShouldScrollIntoView={true}
				placeholder={placeholderComponent}
				formatOptionLabel={formatOptionLabel}
				formatGroupLabel={formatGroupLabel}
				filterOption={filterOptions}
			/>
			{displayNb && isMulti && refineValue?.length > 0 &&
				<div
					className="absolute flex justify-center items-center -top-2 -right-1 font-semibold
                               w-5 h-5 bg-supplyr_primary-menu rounded-full text-white text-xs shadow-md"
				>
					{Array.isArray(refineValue) ? refineValue.length : 1}
				</div>
			}
		</div>
	);
};

FilterMenu.propTypes = {
	appSlice: PropTypes.object,
	options: PropTypes.object,
	customStyle: PropTypes.func,
	textClasses: PropTypes.string,
	menuSelectedColor: PropTypes.string,
	clearOnChange: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
	isSearch: PropTypes.bool,
	manualRenderOption: PropTypes.bool,
	cleanStyle: PropTypes.bool,
	displayNb: PropTypes.bool,
	className: PropTypes.string,
	locales: PropTypes.string,
	sortKey1: PropTypes.string,
	sortKey2: PropTypes.string
};

export default FilterMenu;
