import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
	useTable, useGlobalFilter, useSortBy, usePagination, useAsyncDebounce
} from "react-table";

import { Pagination, Tooltip } from "@mui/material";

import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownUp from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArticleIcon from "@mui/icons-material/Article";
// import GSCADataIcon from "../../images/Clarity/GSCADataIcon.svg";
import * as XLSX from "xlsx";
import moment from "moment";
import exportToExcel from "../../utils/ExportToExcel";
import FilterMenu from "../filters/FilterMenu";
import Popconfirm from "../modal/Popconfirm";
import { downloadFromS3 } from "../../api/client";
import uploadToS3 from "../../utils/uploadToS3";

const theme = createTheme({
	palette: {
		default: {
			main: "lightblue"
		},
		compass: {
			main: "#F9CA48"
		},
		clarity: {
			main: "#CCDB73"
		},
		supplyr: {
			main: "#E3E5F5"
		},
		vendorDD: {
			main: "#E3E5F5"
		}
	}
});

// Define a default UI for filtering
function GlobalFilter({
	placeHolder,
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter
}) {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="flex justify-between items-center bg-gray-100 py-4 px-2 md:px-6 space-x-4 rounded-xl ">
			<input
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={placeHolder}
				className="bg-transparent outline-none "
			/>
			<SearchIcon />
		</div>
	);
}

GlobalFilter.propTypes = {
	placeHolder: PropTypes.string,
	preGlobalFilteredRows: PropTypes.array,
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func
};

function TableGSCA({
	pageTable = "GSCAPage",
	appSlice,
	dataset = undefined,
	structureTable = undefined,
	filterData = (d) => d,
	refineKeys = (row) => [{ key: "supplierUniqueId", value: row.original.supplierUniqueId }],
	sortByRefineFields = [{ id: "supplier", desc: false }],
	themePalette = "default",
	excelLocales = undefined,
	locales,
	enableDispatch = (row) => true,
	renderTableFilter = false,
	filterOptions = [],
	customStylesSubFilter = undefined
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Get activeRefines
	const appName = useSelector(appSlice.selectAppName);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);

	// set alert modal
	const [alert, setAlert] = useState({ isDiplayed: false, msg: "" });

	// Set up state for opened form
	const [tableData, setTableData] = useState([]);

	// Load data from the store
	const tableRawData = useSelector(appSlice.selectDatasets)[dataset]?.data;

	// Hooks for file
	const [selectedFile, setSelectedFile] = useState(undefined);
	const [isUploaded, setIsUploaded] = useState(false);
	const [isUploadedError, setIsUploadedError] = useState(false);
	const [confirmModal, setconfirmModal] = useState(false);
	const [templateDownloadCompleted, setTemplateDownloadCompleted] = useState(false);
	const [dataAvailable, setDataAvailable] = useState(false);

	const tableColumns = useMemo(
		() => structureTable({ activeRefines, clientParameters, t }),
		// eslint-disable-next-line
		[activeRefines]
	);

	useEffect(() => {
		const dataWithFormattedDate = tableRawData.map((el) => ({ ...el, uploadDate: moment(el.uploadDate).format("DD/MM/YYYY") }));
		setTableData([...dataWithFormattedDate].filter(filterData));
		setDataAvailable(tableRawData.length > 0);
		// eslint-disable-next-line
	}, [tableRawData]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		rows,
		gotoPage,
		preGlobalFilteredRows,
		setGlobalFilter,
		state: { pageIndex, pageSize }
	} = useTable({
		columns: tableColumns,
		data: tableData,
		initialState: {
			pageIndex: 0,
			pageSize: 10,
			sortBy: sortByRefineFields
		}
	},
	useGlobalFilter,
	useSortBy,
	usePagination);

	if (dataset === undefined || structureTable === undefined) {
		console.log("You need to define your dataset and table structure to display this component");
		return null;
	}

	function generateTableToExport() {
		const sortedTable = new Array(rows.length);
		rows.forEach((row, i) => {
			const formattedRow = {};
			prepareRow(row);
			row.cells.forEach((cell, j) => excelLocales
				? formattedRow[t(`${excelLocales}.${cell.column.Header}`)] = cell.column?.processedValue
					? cell.column.processedValue(cell.value) : cell?.value?.toString()
				: formattedRow[cell.column.Header] = cell.column?.processedValue
					? cell.column.processedValue(cell.value) : cell?.value?.toString());
			sortedTable[i + 1] = formattedRow;
		});

		return sortedTable;
	}

	function downloadTemplate() {
		downloadFromS3({ filePath: "gsca/Suppliers Risk Assessment Template.xlsx", directDownload: true, api: "gsca" });
		setTimeout(() => {
			setTemplateDownloadCompleted(true);
		}, 1000);
	}

	// Générer le nom du sous-dossier en fonction de la date/heure actuelle, par exemple
	const getTimeString = () => {
		const now = new Date();
		const year = now.getFullYear();
		const month = now.getMonth() + 1; // Les mois sont indexés à partir de 0, donc ajoutez 1
		const day = now.getDate();
		const hour = now.getHours();
		const minute = now.getMinutes();
		const second = now.getSeconds();
		return `${year}-${month}-${day}-${hour}:${minute}:${second}`;
	};

	function readUploadFile(e) {
		e.preventDefault();
		if (e.target.files) {
			const reader = new FileReader();
			reader.onload = (e) => {
				let uploadValue;
				const data = e.target.result;
				const workbook = XLSX.read(data, { type: "array" });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json = XLSX.utils.sheet_to_json(worksheet, { range: 6 });
				// eslint-disable-next-line prefer-const
				uploadValue = [...json].map((obj) => ({ ...obj, spaceLabel: activeRefines?.spaceLabel }));
				uploadToS3("gscaUploadBackups", clientParameters?.projectName[0], [{
					data,
					fileName: `GSCASuppliersTable-${clientParameters?.projectName[0]}-${getTimeString()}.xlsx`,
					contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				},
				{
					data: uploadValue,
					fileName: `GSCASuppliersTableJSON-${clientParameters?.projectName[0]}-${getTimeString()}.json`,
					contentType: "application/json"
				}])
				?.then((uploadRes) => {
					console.log("uploadRes", uploadRes);
					const s3KeyJsonFile = uploadRes.filter((e) => e.endsWith(".json"))[0];
					if (s3KeyJsonFile) {
						console.log("Upload file success");
						dispatch(appSlice.uploadFileToDatabase({
							appName: "clarity",
							collection: "ClarityGSCAUploads",
							data: s3KeyJsonFile
						}))
						.unwrap()
						.then((originalPromiseResult) => {
							console.log("originalPromiseResult", originalPromiseResult);
							const isSuccess = originalPromiseResult.res.ok === 1;
							if (isSuccess) {
								console.log("Upload file success");
								// Setting an action (any action) and clearing it will trigger the refresh of the state
								dispatch(appSlice.actions.refine([{ key: "action", value: "upload" }]));
								dispatch(appSlice.actions.clear([{ refine: "action" }]));
								setSelectedFile(undefined);
								setIsUploaded(false);
							} else {
								// return error item position in file
								const res = originalPromiseResult.res.reduce((acc, cur, i) => {
									if (cur !== 1) return [...acc, i];
									return [...acc];
								}, []);

								setAlert({
									isDiplayed: true,
									msg: "The file upload has failed, possibly due to insufficient permissions or an incompatible file format."
								});
							}
						}).catch((rejectedValueOrSerializedError) => {
							console.log("Upload error", rejectedValueOrSerializedError);
							setIsUploaded(false);
							setIsUploadedError(true);
						});
					} else {
						console.log("No JSON file", uploadRes);

						setAlert({
							isDiplayed: true,
							msg: "The file could not be processed"
						});
					}
				})
				.catch((rejectedValueOrSerializedError) => {
					console.log("Upload error", rejectedValueOrSerializedError);
					setIsUploaded(false);
					setIsUploadedError(true);
				});
			};

			reader.readAsArrayBuffer(e.target.files[0]);
		}
	}

	return (<div data-test="RiskTable" className="flex flex-col text-sm space-y-4 max-x-full  w-full overflow-x-auto">
		<div className="self-center flex justify-between items-center w-full px-4 md:px-6">
			<GlobalFilter
				placeHolder={t(`${pageTable}.table.searchFor`)}
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
			/>

			<div className={`${renderTableFilter ? "flex justify-between items-center w-fit h-full space-x-4" : ""}`}>
				{renderTableFilter && filterOptions.map(((filterOption, k) => (
					<FilterMenu
						key={`filterMenu ${ k}`}
						appSlice={appSlice}
						options={filterOption}
						customStyle={customStylesSubFilter}
						clearOnChange={filterOption.clear}
						cleanStyle
						locales={locales}
					/>
				)))}
				<div className="flex space-x-2 items-center self-end h-full">
					<p className="self-end hidden md:block my-auto">
						{rows.length} {t(`${pageTable}.table.results`)}
					</p>
					<button data-test="file_download" className="text-white rounded-xl border border-gray-100
                	shadow-sm p-1 bg-[#3d49ab] hover:border-gray-200 cursor-pointer
					hover:scale-[1.05] hover:ease-out duration-300"
					title="Download data in Excel format"
					onClick={(e) => exportToExcel(generateTableToExport(), `${
						activeRefines?.projectName
							? (Array.isArray(activeRefines?.projectName)
								? activeRefines?.projectName[0] : activeRefines?.projectName)
							: appName} Sites Export ${new Date().toISOString().split("T")[0]}`)}>
						<DownloadIcon />
					</button>
					<>
						<label className="text-white rounded-xl border border-gray-100
                	shadow-sm p-1 bg-[#3d49ab] hover:border-gray-200 cursor-pointer
					hover:scale-[1.05] hover:ease-out duration-300"
						htmlFor="uploadFile"
						title="Upload data in Excel format">
							<FileUploadIcon />
						</label>
						<input
							type="file"
							id="uploadFile"
							className={"hidden"}
							title="Import data in Excel format"
							onChange={(e) => {
								setSelectedFile(e);
								setIsUploaded(true);
							}}
						/>
					</>
					{/* upload confirm modal */}
					{isUploaded && (
						<Popconfirm
							title="Upload"
							// eslint-disable-next-line max-len
							description="You are about to upload this file to the database. Caution: If you are not editing the downloaded file but using a new local file, there is a risk of losing all existing data. Are you sure you want to proceed with this action?"
							confirmBtnText="Confirm"
							iconsType="exclamationMark"
							onClickCancel={() => {
								setconfirmModal(false);
								setIsUploaded(false);
							}}
							onClickConfirm={() => { readUploadFile(selectedFile); }}
							fullscreen={false}
						/>
					)}
					{alert.isDiplayed && <Popconfirm
						title="Something went wrong"
						description={alert?.msg ?? ""}
						onClickCancel={() => setAlert({ isDiplayed: false, msg: "" })}
						iconsType="exclamationMark"
						cancelBtnText="Back"
						showConfirm={false}
						fullscreen={false}
					/>}
					<button data-test="template-download" className="text-white rounded-xl border border-gray-100
                	shadow-sm p-1 bg-[#3d49ab] hover:border-gray-200 cursor-pointer
					hover:scale-[1.05] hover:ease-out duration-300"
					title="Download template in Excel format"
					onClick={(e) => downloadTemplate()}>
						<ArticleIcon />
					</button>
				</div>
			</div>
		</div>
		<table {...getTableProps()} className="w-full text-center">
			<thead>
				{headerGroups.map((headerGroup, i) => (
					<tr key={`headerGroup ${i}`} {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column, j) => (
							<th key={`header ${j}`}
								{...column.getHeaderProps(column.getSortByToggleProps())}
								className="first:pl-6 last:pr-6 text-left font-medium p-2 max-w-xs"
							>
								<div className="flex justify-center space-x-2">
									{t(`${pageTable}.table.${column.render("Header")}`)}
									<span>
										{column.isSorted
											? column.isSortedDesc
												? <KeyboardArrowDownUp fontSize="inherit" />
												: <KeyboardArrowDownIcon fontSize="inherit" />
											: ""}
									</span>
								</div>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{page.map((row, i) => {
					prepareRow(row);
					return (
						<Tooltip title={enableDispatch(row) ? "" : "Risk data is not yet available"} followCursor key={`tooltip ${i}`}>
							<tr key={`row ${i}`} {...row.getRowProps()}
								onClick={() => {
									const isEnableDispatch = enableDispatch(row);
									if (isEnableDispatch) dispatch(appSlice.actions.refine(refineKeys(row)));
								}}
								onHover={() => {}}
								className={`odd:bg-gray-100 hover:bg-${themePalette}_primary-default hover:bg-opacity-20 cursor-pointer`}>
								{row.cells.map((cell, j) => (
									<td key={`cell ${j}`}
										{...cell.getCellProps()}
										className="first:pl-6 last:pr-6 p-2 max-w-xs"
									>
										{cell.render("Cell")}
									</td>
								))}
							</tr>
						</Tooltip>
					);
				})}
			</tbody>
		</table>
		{(!dataAvailable && !templateDownloadCompleted) &&
		<div className="flex bg-slate-100 w-auto mx-auto rounded-lg drop-shadow-lg cursor-pointer
		hover:scale-105 hover:ease-out duration-1000"
		style={{ marginTop: "100px", marginBottom: "200px" }}>
			<img src="/images/Clarity/GSCADataIcon.svg" alt="no_data_icon" className="p-6 pb-3" ></img>
			<div className="flex flex-col justify-center items-center pr-5" onClick={(e) => downloadTemplate()}>
				<p className="text-center">No data available.</p>
				<p className="text-center">Please download the template, fill and upload.</p>
			</div>
		</div>
		}
		{(!dataAvailable && templateDownloadCompleted) &&
			<label htmlFor="uploadFile" className="flex bg-slate-100 w-auto mx-auto rounded-lg drop-shadow-lg cursor-pointer
			hover:scale-105 hover:ease-out duration-1000" style={{ marginTop: "100px", marginBottom: "200px" }}>
				<input
					type="file"
					id="uploadFile"
					className="hidden"
					onChange={(e) => {
						setSelectedFile(e);
						setIsUploaded(true);
					}}
				/>
				<FileUploadIcon className="text-[#3d49ab] mt-3" style={{ fontSize: "112px" }} />
				<div className="flex flex-col justify-center items-center pr-5">
					<p className="text-center">You have successfully downloaded the template.</p>
					<p className="text-center">Click here to upload it.</p>
				</div>
				{/* </div> */}
			</label>
		}
		{/* </div> */}
		{rows.length > pageSize &&
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Pagination page={pageIndex + 1} count={Math.floor((rows.length / pageSize) + 1)}
						className="md:self-center px-4 md:px-6" color={themePalette} onChange={async (event, value) => {
							const page = value ? Number(value) - 1 : 0;
							gotoPage(page);
						}} />
				</ThemeProvider>
			</StyledEngineProvider>
		}
	</div>
	);
}

TableGSCA.propTypes = {
	pageTable: PropTypes.string,
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	filterData: PropTypes.func,
	structureTable: PropTypes.func,
	refineKeys: PropTypes.func,
	sortByRefineFields: PropTypes.array,
	themePalette: PropTypes.string,
	locales: PropTypes.string,
	excelLocales: PropTypes.string,
	enableDispatch: PropTypes.func,
	renderTableFilter: PropTypes.bool,
	filterOptions: PropTypes.array,
	customStylesSubFilter: PropTypes.func
};

export default TableGSCA;
