import PropTypes from "prop-types";
import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Group } from "@visx/group";
import { AreaClosed } from "@visx/shape";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { scaleTime, scaleLinear, scalePower } from "@visx/scale";
import { withTooltip, useTooltipInPortal } from "@visx/tooltip";
import { Text } from "@visx/text";
import * as allCurves from "@visx/curve";
import {
	min, max, range, bin, mean, deviation, extent, nice
} from "d3-array";
import { Tooltip } from "@mui/material";

const defaultColorScale = [
	"#6CABE6",
	"#9757c9",
	"#CF8084",
	"#dbb564",
	"#6c7cf7",
	"#fad390",
	"#f8c291",
	"#6a89cc",
	"#82ccdd",
	"#b8e994",
	"#f6b93b",
	"#e55039",
	"#4a69bd",
	"#60a3bc",
	"#78e08f",
	"#fa983a",
	"#eb2f06",
	"#1e3799",
	"#3c6382",
	"#38ada9", "#e58e26", "#b71540", "#0c2461", "#0a3d62", "#079992"
];

const defaultLineHeight = 0;
/**
 * a graph of the frequencies of different values of a variable in a statistical distribution
 * @category Graphs
 * @component
 */
const DistributionChart = ({
	appSlice,
	dataset,
	filterFunc = (d) => true,
	arrayValues,
	width,
	height = 400,
	margin = {
		top: 10, right: 0, bottom: 25, left: 40
	},
	category,
	categoryValueField,
	refineKey = undefined,
	binSize = 0.5,
	xScaleExponent = 0.5,
	xLabel = "Supplier finding ratio (bins)",
	yLabel = "Nb occurrences",
	fontSize = 12,
	year = "year",
	fromOffset = "0%",
	fromOpacity = 1,
	toOffset = "100%",
	toOpacity = 1,
	colorScale = defaultColorScale,
	isVertical = true,
	gradientId = "stacked-area",
	showTooltip,
	hideTooltip,
	tooltipOpen,
	tooltipLeft,
	tooltipTop,
	tooltipData
}) => {
	let tooltipTimeout;

	const dispatch = useDispatch();
	// bounds
	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;

	// get refines for category if any
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const categoryRefines = category && activeRefines?.[category] !== undefined
		? (Array.isArray(activeRefines?.[category]) ? activeRefines?.[category] : [activeRefines?.[category]])
		: undefined;

	// load dataset
	const filteredData = useSelector(appSlice.selectDatasets)[dataset]?.data
	?.filter(filterFunc);

	const graphData = filteredData
	?.reduce((acc, d) => acc.concat(Array.isArray(d[arrayValues]) ? d[arrayValues] : [d[arrayValues]]), []);

	// simple binning
	const binFunction = bin().domain(extent(graphData)).thresholds(range(min(graphData), max(graphData), binSize));

	const categoryValues = filteredData
	?.map((d) => ({ label: d[category], value: d[categoryValueField], data: binFunction(d[arrayValues]) }))
	?.filter((e) => categoryRefines ? categoryRefines.find((elt) => elt === e.label) !== undefined : true)
	?.sort((a, b) => a.value - b.value);

	const average = graphData && mean(graphData);
	const stddev = graphData && deviation(graphData);

	const sortedGraphData = useMemo(() => (
		binFunction(graphData)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	), [graphData]);

	let [minValue, maxValue] = extent(graphData);
	minValue += 0;
	maxValue += 2 * binSize;

	// scales
	const xScale = useMemo(() => (
		scalePower({
			range: [0, xMax],
			domain: [minValue, maxValue],
			exponent: xScaleExponent
		})
	), [minValue, maxValue, xMax, xScaleExponent]);

	const yScale = useMemo(() => (
		scaleLinear({
			range: [yMax, 0],
			domain: [0,
				Math.max(...sortedGraphData.map((d) => d.length))
			]
		})
	), [sortedGraphData, yMax]);

	const { containerRef, TooltipInPortal } = useTooltipInPortal({
		scroll: true,
		detectBounds: true
	});

	return (
		<div ref={containerRef}>
			<span className="absolute top-4 right-4">&sigma;: {stddev && stddev.toFixed(2)}</span>
			<svg width={width} height={height}>
				<Group top={margin.top} left={margin.left}>

					<AreaClosed
						data={sortedGraphData}
						stroke="#6373F6"
						strokeWidth={2}
						fill={"#6CABE6"}
						curve={allCurves.curveMonotoneX}
						x={(d) => xScale(d.x0)}
						y={(d) => yScale(d.length)}
						yScale = {yScale}
					/>
					<AxisLeft scale={yScale} label={yLabel} />
					<AxisBottom top={yMax} scale={xScale} label={xLabel} tickValues={[1, ...xScale.ticks()]}/>
					{average > 0 && (
						<>
							<line
								x1={xScale(average)}
								y1={0 + defaultLineHeight / 2 }
								x2={xScale(average)}
								y2={yMax - defaultLineHeight / 2 + 2}
								strokeWidth="2"
								stroke={"red"}
								strokeOpacity="1"
							/>
							<Text
								verticalAnchor="start"
								textAnchor="middle"
								dx={xScale(average)}
								dy={-fontSize}
								fontFamily="Fira Sans"
								fontSize={fontSize * 1.5}
								fill={"red"}
								style={{ fontWeight: 500 }}
							>average</Text>
						</>
					)}
					{categoryValues?.length && (categoryValues.map((e, i) => (
						<g key={`categoryLine-${e.label}`}>
							<Tooltip title={e.label} followCursor>
								<line
									className={`${refineKey && "cursor-pointer"}`}
									onClick={() => activeRefines?.[refineKey]
										? dispatch(appSlice.actions.clear([{ refine: refineKey }]))
										: dispatch(appSlice.actions.refine([{ key: refineKey, value: e.label }]))}
									x1={xScale(e.value)}
									y1={0 + defaultLineHeight / 2 }
									x2={xScale(e.value)}
									y2={yMax - defaultLineHeight / 2 + 2}
									strokeWidth="2"
									stroke={"#CCDB73"}
									strokeOpacity=".5"
								/>
							</Tooltip>
							{/* display text only if it fits */}
							{(fontSize * 2 * (i + 1)) < yMax - fontSize * 2 &&
							<Text
								verticalAnchor="start"
								dx={xScale(e.value) + 2}
								dy={fontSize * 2 * (i + 1)}
								fontFamily="Fira Sans"
								fontSize={fontSize}
							>{e.label}</Text>}
							{ categoryRefines?.length &&
								<AreaClosed
									data={e.data}
									stroke="black"
									strokeWidth={1}
									fill={"#CCDB73"}
									curve={allCurves.curveMonotoneX}
									x={(d) => xScale(d.x0)}
									y={(d) => yScale(d.length)}
									yScale = {yScale}
								/>}
						</g>))
					)}

				</Group>
			</svg>

		</div>
	);
};

DistributionChart.propTypes = {
	/** Defines Appslice */
	appSlice: PropTypes.object,
	/** Defines dataset */
	dataset: PropTypes.string,
	/** callback function used to filter data from dataset  */
	filterFunc: PropTypes.func,
	/** values keys to access within data */
	arrayValues: PropTypes.string,
	/** category used for refine. Example: "country"  */
	category: PropTypes.string,
	/** field to access within filteredData as value in const categoryValues */
	categoryValueField: PropTypes.string,
	/** key to refine on */
	refineKey: PropTypes.string,
	/** bin size */
	binSize: PropTypes.number,
	/** x axis scale factor */
	xScaleExponent: PropTypes.number,
	/** x axis label */
	xLabel: PropTypes.string,
	/** y axis label */
	yLabel: PropTypes.string,
	/** unused */
	gradientId: PropTypes.string,
	/** defines height */
	height: PropTypes.number,
	/** defines width */
	width: PropTypes.number,
	/** unused */
	isVertical: PropTypes.bool,
	/** unused */
	year: PropTypes.string,
	/** Defines all four margins. */
	margin: PropTypes.object,
	/** Defines font size */
	fontSize: PropTypes.number,
	/** unused */
	colorScale: PropTypes.array,
	/** unused */
	fromOffset: PropTypes.string,
	/** unused */
	fromOpacity: PropTypes.number,
	/** unused */
	toOffset: PropTypes.string,
	/** unused */
	toOpacity: PropTypes.number,
	/** unused */
	showTooltip: PropTypes.func,
	/** unused */
	hideTooltip: PropTypes.func,
	/** unused */
	tooltipData: PropTypes.any,
	/** unused */
	tooltipLeft: PropTypes.number,
	/** unused */
	tooltipOpen: PropTypes.bool,
	/** unused */
	tooltipTop: PropTypes.number
};

export default withTooltip(DistributionChart);
