import { useState } from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		marginBottom: "6px !important"
	}
}));

function RYBLegalTooltip({ locales }) {
	// Set up state for legal tooltip
	const [legalToolTip, setLegalToolTip] = useState(true);

	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<LightTooltip title={`${t("map.legalinfo.description")}`}
			open={legalToolTip} placement="top-end"
			onClick={() => setLegalToolTip(!legalToolTip)} arrow>
			<InfoOutlinedIcon style={{ fontSize: 58 }} />
		</LightTooltip>
	);
}

RYBLegalTooltip.propTypes = {
	locales: PropTypes.string
};

export default RYBLegalTooltip;
