import PropTypes from "prop-types";
import React from "react";
import CreatFormBtnGroup from "../components/button/CreatFormBtnGroup";

const NoDataAvailable = ({ appSlice, locales, refineKey = [] }) => (
	<div className="relative flex flex-col justify-center items-center w-full h-screen -mt-28 text-lg uppercase overflow-y-hide">
		<div className="absolute left-0 top-28">
			<CreatFormBtnGroup appSlice={appSlice} locales={locales} renderBack renderAdd
				clearRefineKeys={[{ refine: "appAction" }, { refine: "projectName" }, { refine: "spaceLabel" }, { refine: "fourthLevelMenu" }]}
				refineKey={refineKey}/>
		</div>

		<img src="/images/Admin/nodata.svg" alt="no-data" className="relative block" />
		<p>Sorry, no data available.</p>
	</div>
);

NoDataAvailable.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	refineKey: PropTypes.array
};

export default NoDataAvailable;
