import React from "react";
import PropTypes from "prop-types";
import { useFieldArray, Controller } from "react-hook-form";
import BlockIcon from "@mui/icons-material/Block";
import AddIcon from "@mui/icons-material/Add";
import { nanoid } from "nanoid";
import { arrayFieldDefaults } from "../../apps/configs/AdminConfig";

const transform = {
	input: (value) => Number.isNaN(value) ? "" : value?.toString(),
	output: (e) => {
		const output = parseInt(e.target.value, 10);

		return Number.isNaN(output) ? 0 : output;
	}
};

const formatDate = (inputDate) => {
	if (Number.isNaN(inputDate) || inputDate === "") return "";

	const date = new Date(inputDate);

	// Extract year, month, and day
	const year = date.getFullYear().toString();
	const month = (`0${date.getMonth() + 1}`).slice(-2); // Months are zero based
	const day = (`0${date.getDate()}`).slice(-2);

	return `${year}-${month}-${day}`;
};

const ArrayOfObj = ({
	field: formField = "", fields, control, remove, register, requiredFields
}) => (
	fields.map((item, index) => {
		// take the object with longest length (ex:compass fifa score.value)
		const longestObj = fields.reduce((acc, curField) => {
			if (Object.keys(curField.k)?.length > Object.keys(acc.k)?.length) return curField;
			return acc;
		});

		const obj = Object.keys(longestObj.k);

		const inputBuilder = ({ type, field }) => {
			switch (type) {
				case "number":
					return <input
						{...field}
						defaultValue={field.value}
						type="number" className="pl-2 py-1 border text-black focus:outline-none rounded-lg
						focus:ring-2 focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"
						onChange={(e) => field.onChange(transform.output(e))} value={transform.input(field.value)} />;

				case "string":
					return <input
						{...field}
						defaultValue={field.value}
						autoComplete="off"
						className="pl-2 py-1 border text-black focus:outline-none rounded-lg
							   focus:ring-2 focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"/>;
				case "date":
					return <span className="pl-2 text-black focus:outline-none rounded-lg focus:ring-2
							focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base">
						<input
							type="date"
							{...register(`${field.name}`)}
							className={`pl-2 py-2 mt-2 mb-3 border text-black focus:outline-none rounded-lg focus:ring-2 focus:ring-admin_primary-default
                        	focus:border-transparent text-sm focus:text-base`}
						/>
					</span>;

				default:
					break;
			}
		};

		return <li key={`arrayOfObj-${item.id}-${index}-${nanoid()}`} className=" h-full border p-2 rounded-lg gap-x-2">
			{obj.map((el, j) => (
				<Controller
					key={`controller-${item.id}-${index}-${nanoid()}`}
					name={`${formField}.${index}.k.${el}`}
					control={control}
					rules={{
						required: requiredFields.includes(`${formField}.${index}`)
					}}
					render={({ field }) => (
						<div className=" w-64 flex justify-between items-center mb-1.5">
							<label htmlFor={`${el}`} className="uppercase text-medium">{el}</label>
							{ inputBuilder({ type: field.name.toLowerCase().includes("date".toLowerCase()) ? "date" : typeof (field.value), field })}
						</div>
					)}
				/>
			))}

			<button
				type="button"
				onClick={() => remove(index)}
				className="border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold px-2 rounded"
			>
				<BlockIcon className="text-base"/>
			</button>
		</li>;
	})
);

const ArrayOfString = ({
	field: formField = "", fields, control, remove, register, requiredFields
}) => (
	fields.map((item, index) => (
		// NB: never register a field in input
		<li key={`arrayOfString-${item.id}-${index}-${nanoid()}`} className="rounded">
			<Controller
				name={`${formField}.${index}.k`}
				control={control}
				rules={{
					required: requiredFields.includes(`${formField}.${index}`)
				}}
				render={({ field }) => (
					<input
						{...field}
						defaultValue={field.value}
						autoComplete="off"
						className="pl-2 py-1 border text-black focus:outline-none rounded-lg
					   focus:ring-2 focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"/>
				)}
			/>
			<button
				type="button"
				className="ml-2 border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold px-2 rounded"
				onClick={() => remove(index)}
			>
				<BlockIcon className="text-base"/>
			</button>
		</li>
	))
);

const ArrayOfNumber = ({
	field: formField = "", fields, control, remove, register, requiredFields
}) => (
	fields.map((item, index) => (
		// NB: never register a field in input
		<li key={`arrayOfString-${item.id}-${index}-${nanoid()}`} className="rounded">
			<Controller
				name={`${formField}.${index}.k`}
				control={control}
				rules={{
					required: requiredFields.includes(`${formField}.${index}`)
				}}
				render={({ field }) => (
					<input
						{...field}
						defaultValue={field.value}
						type="number" className="pl-2 py-1 border text-black focus:outline-none rounded-lg
						focus:ring-2 focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"
						onChange={(e) => field.onChange(transform.output(e))} value={transform.input(field.value)} />
				)}
			/>
			<button
				type="button"
				className="ml-2 border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold px-2 rounded"
				onClick={() => remove(index)}
			>
				<BlockIcon className="text-base"/>
			</button>
		</li>
	))
);

const arrayFieldBuilder = ({
	type, field, fields, control, remove, register, requiredFields
}) => {
	switch (type) {
		case "string":
			return ArrayOfString({
				field, fields, control, remove, register, requiredFields
			});

		case "object":
			return ArrayOfObj({
				field, fields, control, remove, register, requiredFields
			});

		case "number":
			return ArrayOfNumber({
				field, fields, control, remove, register, requiredFields
			});

		default:
			break;
	}
};

const ArrayFieldEditor = ({
	control, field, register, requiredFields = [], activeRefines
}) => {
	const {
		fields, append, remove
	} = useFieldArray({
		control,
		name: `${field}`
	});

	return <>
		 <ul className="w-full space-y-2 my-2">
			{fields.length > 0 && arrayFieldBuilder({
				type: typeof fields[0].k, field, fields, control, remove, register, requiredFields
			})}

		</ul>

		<div className="mt-1">
			<button
				type="button"
				className="border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold px-2 rounded"
				onClick={() => { append(arrayFieldDefaults({ activeRefines, field }), field); }}
			>
				<AddIcon className="text-base" />
			</button>
		</div>
	</>;
};

ArrayFieldEditor.propTypes = {
	control: PropTypes.object,
	field: PropTypes.string,
	requiredFields: PropTypes.array,
	register: PropTypes.func,
	activeRefines: PropTypes.object
};

export default ArrayFieldEditor;
