import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import VariableRadiusDonut from "../graphs/VariableRadiusDonut";
import TitleCard from "./TitleCard";

const capitalize = (dataInfo) => dataInfo.charAt(0).toUpperCase() + dataInfo.slice(1);

const dataInfoBuilder = ({ data, scopelist, scopeKey }) => {
	const res = scopelist?.map((item) => {
		const [label, color] = Object.values(item);

		return {
			label,
			value: (data?.find((element) => element[scopeKey] === capitalize(label)))?.frequency ?? 0,
			color
		};
	});

	return res;
};

const SupplyRProjectScope = ({
	titleLocale = "analysis.scope",
	appSlice = null,
	dataset = "",
	scopeList = "projectScope",
	categoriesListDataset = "listSupplierCriticalities",
	categoriesKey = "supplierCriticality",
	categoriesLocale = "analysis.scope.criticality",
	totalLocaleKey = "factory",
	displayPills = true,
	displayInner = true,
	total = 0,
	totalCovered = 0,
	planedCount = 0,
	inProgressCount = 0,
	counterPills = [{ id: "assessed", counter: totalCovered, color: "bg-scope-assessment" }],
	locales,
	showTitleCard = true,
	refineOnClick = true,
	refineOnAuditStatus = false,
	unClickableField = []
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const dispatch = useDispatch();
	const activeDatasets = useSelector(appSlice.selectDatasets);

	const { data } = useSelector(appSlice.selectDatasets)[dataset];

	const projectScopeList = activeDatasets?.clientParameters?.data?.[0]?.[scopeList];

	const listSupplierCriticalities = useSelector(appSlice.selectDatasets)?.[categoriesListDataset]?.data.map((item) => item[categoriesKey]);
	const dataInfo = dataInfoBuilder({ data, scopelist: projectScopeList, scopeKey: categoriesKey });

	const totalFrequency = dataInfo?.reduce((acc, element) => acc + (element?.value ?? 0), 0);

	return <div data-test="SupplyRProjectScope" className="flex justify-start items-center pr-3 ">
		<VariableRadiusDonut dataInfo={dataInfo} total={total}
			totalCovered={totalCovered} planedCount={planedCount} inProgressCount={inProgressCount} displayInner={displayInner} />

		{showTitleCard && <div className="flex flex-grow flex-shrink-0 flex-col pl-3 gap-y-1">

			<TitleCard titleLocal={`${titleLocale}.title`} subTitleLocal=""
				small infoLocal={`${titleLocale}.desc`} theme="supplyr" locales={locales} />

			<div className="grid grid-cols-2 grid-flow-row">
				{dataInfo?.map((data, index) => (
					<span key={`scope-${data}-${index}`}
						className={`flex justify-start items-center text-medium ${!refineOnClick && "pointer-events-none"}
               			${listSupplierCriticalities?.includes(capitalize(data.label)) ? "cursor-pointer" : ""}`}
						onClick={() => {
							if (listSupplierCriticalities?.includes(capitalize(data.label))) {
								dispatch(appSlice.actions.refine([
									{ key: "supplierCriticality", value: capitalize(data.label) }]));
							}
						}}>
						<div className={"w-1.5 h-1.5 rounded-full mr-1"} style={{ backgroundColor: data.color }}></div>
						{t(`${categoriesLocale}.${data.label}`)} {((data.value / totalFrequency) * 100).toFixed(0)}%
					</span>
				))}
			</div>

			<div className="flex items-start">
				<div className="flex flex-col items-start 2xl:flex-row 2xl:items-center">
					<p className="mr-2">
						<span className="text-base font-light mr-1">
							{total}
						</span>
						<span className="text-xs">
							{t(`analysis.scope.${totalLocaleKey}`, { count: total })}
						</span>
					</p>
				</div>
				{displayPills && <div className="grid grid-cols-2 gap-2">
					{counterPills.map((pill) => (
						<p key={`pill-${pill.id}`} className={`text-small ${pill.color} px-2.5 rounded 
						${(refineOnAuditStatus && !unClickableField.includes(pill.id)) ? "cursor-pointer" : ""}`}
						onClick={() => {
							if (refineOnAuditStatus && !unClickableField.includes(pill.id) && pill.id !== "delayed") {
								dispatch(appSlice.actions.refine([{ key: "auditStatus", value: (pill.id).toUpperCase() }]));
								dispatch(appSlice.actions.setPage("SITES"));
							}

							if (pill.id === "delayed") {
								dispatch(appSlice.actions.refine([{ key: "assignmentDelayed", value: "delayed" }]));
								dispatch(appSlice.actions.setPage("SITES"));
							}
						}}
						>
							{((pill.counter / total) * 100).toFixed(0)}% {t(`analysis.scope.${pill.id}`)}
						</p>
					))}
				</div>}
			</div>
		</div>
		}
	</div>;
};

SupplyRProjectScope.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	scopeList: PropTypes.string,
	categoriesListDataset: PropTypes.string,
	categoriesKey: PropTypes.string,
	titleLocale: PropTypes.string,
	categoriesLocale: PropTypes.string,
	totalLocaleKey: PropTypes.string,
	total: PropTypes.number,
	totalCovered: PropTypes.number,
	planedCount: PropTypes.number,
	inProgressCount: PropTypes.number,
	counterPills: PropTypes.array,
	locales: PropTypes.string,
	showTitleCard: PropTypes.bool,
	displayPills: PropTypes.bool,
	displayInner: PropTypes.bool,
	refineOnClick: PropTypes.bool,
	refineOnAuditStatus: PropTypes.bool,
	unClickableField: PropTypes.array
};

export default SupplyRProjectScope;
