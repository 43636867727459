import React, { useEffect } from "react";
import PropTypes from "prop-types";

const defaultColors = {
	backgroundColor: "#ecf0f1",
	selectedBackgroundColor: "#2ecc71",
	fontcolor: "#000",
	selectedfontcolor: "#fff"
};

const SwitchSelector = (props) => {
	const {
		onChange = () => { },
		options = [],
		initialSelectedIndex = 0
	} = props;
	const canApplyInitialSelectedIndex = !!options[initialSelectedIndex];
	const [selectedIndex, setSelectedIndex] = React.useState(
		canApplyInitialSelectedIndex ? initialSelectedIndex : 0
	);

	const {
		border = 0,
		backgroundColor = defaultColors.backgroundColor,
		borderColor = backgroundColor,
		selectedBackgroundColor = defaultColors.selectedBackgroundColor,
		wrapperBorderRadius = 20,
		optionBorderRadius = wrapperBorderRadius - 2,

		fontcolor = defaultColors.fontcolor,
		selectedfontcolor = defaultColors.selectedfontcolor,
		selectionIndicatorMargin = 2,
		forcedSelectedIndex,
		disabled = false
	} = props;

	useEffect(() => {
		if (
			forcedSelectedIndex !== undefined &&
			!!options[forcedSelectedIndex] &&
			forcedSelectedIndex !== selectedIndex
		) {
			setSelectedIndex(forcedSelectedIndex);
		}
	}, [forcedSelectedIndex, options, selectedIndex]);

	const handleOnClick = (index) => {
		if (!disabled && index !== selectedIndex) {
			setSelectedIndex(index);
			onChange(options[index].value);
		}
	};

	const renderOptions = () => options.map((option, index) => {
		const _optionId = `rss-option-${index}`;
		const isRawText = typeof option.label === "string";

		const labelRawTextProps = {

			color: option.fontcolor || fontcolor,
			selectedfontcolor: option.selectedfontcolor || selectedfontcolor
		};

		return (
			<div
				key={_optionId}
				className="flex items-center h-full"
				style={{
					width: `${(1 / options.length) * 100}%`,
					borderRadius: (`${optionBorderRadius}px`)
				}}
			>
				<label
					className="flex justify-center items-center w-full h-full text-smaller sm:text-sm z-10"
					style={{
						transition: "color 0.1s linear",
						cursor: (disabled ? "not-allowed" : "pointer"),
						color: ((selectedIndex === index) ? option.selectedfontcolor : option.fontcolor),
						fontWeight: ((selectedIndex === index) ? 700 : 500)
					}}
					aria-disabled={disabled}
					{...(isRawText ? labelRawTextProps : {})}
				>
					<input
						data-test={`switch-selector-${option?.label?.replaceAll(" ", "-").toLowerCase()}`}
						type="radio"
						id={_optionId}
						className="absolute w-0 h-0 opacity-0 z-0 pointer-events-none "
						onChange={() => handleOnClick(index)}
						checked={selectedIndex === index}
					/>
					{option.label}
				</label>
			</div>
		);
	});

	if (!options.length) return null;
	return (
		<div
			className={`relative flex w-full h-full ${disabled ? " opacity-70" : " opacity-100"}`}
			style={{
				borderRadius: (`${wrapperBorderRadius}px`),
				border,
				borderColor,
				background: backgroundColor
			}}
		>
			<div className="absolute top-1/2 box-content transform -translate-y-1/2 z-10"
				style={{
					left: `${(selectedIndex / options.length) * 100}%`,
					width: `calc(${(1 / options.length) * 100}% - ${2 * selectionIndicatorMargin}px)`,
					height: `calc(100% - ${2 * selectionIndicatorMargin}px)`,
					borderRadius: `${optionBorderRadius}px`,
					border: `${selectionIndicatorMargin}px solid ${borderColor}`,
					transition: "left 0.1s linear, background 0.1s linear",
					background: options[selectedIndex]?.selectedBackgroundColor || selectedBackgroundColor
				}}
			>
				&nbsp;
			</div>
			{renderOptions()}
		</div>
	);
};

SwitchSelector.propTypes = {
	onChange: PropTypes.func,
	options: PropTypes.array,
	initialSelectedIndex: PropTypes.any,
	border: PropTypes.number,
	borderColor: PropTypes.string,
	backgroundColor: PropTypes.string,
	selectedBackgroundColor: PropTypes.string,
	wrapperBorderRadius: PropTypes.number,
	optionBorderRadius: PropTypes.number,
	fontSize: PropTypes.number,
	fontcolor: PropTypes.string,
	selectedfontcolor: PropTypes.string,
	selectionIndicatorMargin: PropTypes.number,
	forcedSelectedIndex: PropTypes.number,
	disabled: PropTypes.bool
};

export default SwitchSelector;
