import React, { createRef } from "react";
import {
	MapContainer, TileLayer, ZoomControl, L
} from "react-leaflet";
import PropTypes from "prop-types";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import DefaultLayer from "./DefaultLayer";
import DefaultMarker from "./DefaultMarker";

import "../../styles/mapLeaflet.scss";

const accessToken =
  "92g0NkN7Naw220I1dvS4sqMFvpJTT980itqLgRKhBLF1iQWkal78mZ14HlwQGhGg";

const mapDesign = {
	default: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
	jawgDark: `https://tile.jawg.io/jawg-dark/{z}/{x}/{y}.png?access-token=${accessToken}`,
	jawgLight: `https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=${accessToken}`,
	jawgStreet: `https://tile.jawg.io/jawg-sunny/{z}/{x}/{y}{r}.png?access-token=${accessToken}`,
	jawgTerrain: `https://tile.jawg.io/jawg-terrain/{z}/{x}/{y}{r}.png?access-token=${accessToken}`
};

const MapLeaflet = ({
	appSlice,
	dataset,
	datasetCountries = undefined,
	markersTree = undefined,
	geopointField,
	CustomMarker = DefaultMarker,
	CustomLayer = DefaultLayer,
	defaultPosition = [26.481459, 1.734852],
	scoreAccessor = (d) => d.siteScore,
	countryScoreAccessor = (d) => d.countryScore,
	refineKey,
	fieldsTooltip,
	mapType,
	zoom = 0,
	minZoom = 2,
	maxZoom = 18,
	maxClusterRadius = 15,
	scrollWheelZoom = false,
	getMarkerColor = undefined,
	customTooltip = undefined,
	noClusterScore = false,
	colorMap,
	colorKey,
	mapWidth = "100%",
	mapHeight = "100%",
	locales,
	markersFilter = undefined,
	markerPosition = "",
	worldCopyJump = true,
	zoomSnap = 1,
	wheelPxPerZoomLevel = 60,
	warningOverlay = false,
	warningMsg = undefined
}) => (mapHeight > 10 && (
	<div className="relative">
		{warningOverlay && <div
			className="absolute w-full h-full bg-map-overlay z-900 flex justify-center items-center"
		>
			<span className="text-white font-bold text-3xl">{warningMsg}</span>
		</div>}
		<MapContainer
			key={`mapKeyWidth ${ mapWidth}`}
			center={defaultPosition}
			zoom={zoom}
			minZoom={minZoom}
			maxZoom={maxZoom}
			scrollWheelZoom={scrollWheelZoom}
			style={{ width: mapWidth, height: mapHeight }}
			zoomControl={false}
			worldCopyJump={worldCopyJump}
			zoomSnap={zoomSnap}
			wheelPxPerZoomLevel={wheelPxPerZoomLevel}
		>

			<ZoomControl position="topright" />

			<TileLayer
			// eslint-disable-next-line max-len
				attribution='<a href="https://jawg.io?utm_medium=map&utm_source=attribution" title="Tiles Courtesy of Jawg Maps" target="_blank" class="jawg-attrib" >&copy; <b>Jawg</b>Maps</a> | <a href="https://www.openstreetmap.org/copyright" title="OpenStreetMap is open data licensed under ODbL" target="_blank" class="osm-attrib">&copy; OSM contributors</a>'
				url={mapType ? mapDesign[mapType] : mapDesign.jawgStreet}
			/>

			<CustomLayer
				refineKey={refineKey}
				geopointField={geopointField}
				CustomMarker={CustomMarker}
				maxClusterRadius={maxClusterRadius}
				scoreAccessor = {scoreAccessor}
				countryScoreAccessor={countryScoreAccessor}
				fieldsTooltip={fieldsTooltip}
				getMarkerColor={getMarkerColor}
				customTooltip={customTooltip}
				colorMap={colorMap}
				colorKey={colorKey}
				appSlice={appSlice}
				dataset={dataset}
				noClusterScore={noClusterScore}
				datasetCountries={datasetCountries}
				markersTree={markersTree}
				locales={locales}
				markersFilter={markersFilter}
				markerPosition={markerPosition}
				disableFullscreen={warningOverlay}
			/>

		</MapContainer>
	</div>
));

MapLeaflet.propTypes = {
	appSlice: PropTypes.object,
	refineKey: PropTypes.string,
	dataset: PropTypes.string,
	datasetCountries: PropTypes.string,
	markersTree: PropTypes.object,
	mapType: PropTypes.string,
	mapWidth: PropTypes.number,
	mapHeight: PropTypes.number,
	geopointField: PropTypes.string,
	CustomMarker: PropTypes.func,
	CustomLayer: PropTypes.func,
	scoreAccessor: PropTypes.func,
	countryScoreAccessor: PropTypes.func,
	fieldsTooltip: PropTypes.array,
	defaultPosition: PropTypes.array,
	zoom: PropTypes.number,
	minZoom: PropTypes.number,
	maxZoom: PropTypes.number,
	maxClusterRadius: PropTypes.number,
	scrollWheelZoom: PropTypes.bool,
	getMarkerColor: PropTypes.func,
	customTooltip: PropTypes.func,
	noClusterScore: PropTypes.bool,
	colorMap: PropTypes.object,
	colorKey: PropTypes.string,
	locales: PropTypes.string,
	markersFilter: PropTypes.func,
	markerPosition: PropTypes.string,
	worldCopyJump: PropTypes.bool,
	zoomSnap: PropTypes.number,
	wheelPxPerZoomLevel: PropTypes.number,
	warningOverlay: PropTypes.bool,
	warningMsg: PropTypes.string
};

export default MapLeaflet;
