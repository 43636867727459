/* eslint-disable react/prop-types */
import DashboardIcon from "@mui/icons-material/Dashboard";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import LinkIcon from "@mui/icons-material/Link";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PinDropIcon from "@mui/icons-material/PinDrop";
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import RouteRoundedIcon from "@mui/icons-material/RouteRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PublicIcon from "@mui/icons-material/Public";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import HelpIcon from "@mui/icons-material/Help";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Tooltip } from "@mui/material";
import LegalNotice from "../../termsLocales/en/RYBLegalNotice";
import Terms from "../../termsLocales/en/PrivateAppTerms";
import Privacy from "../../termsLocales/en/RYBPrivacy";
import Cookies from "../../termsLocales/en/RYBCookies";

import formatNumberWithTwoDecimals from "../../utils/formatNumberWithTwoDecimals";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} slice Name of the slice related to the app created and references in the store ./store.js
 * @param {String} logo Label of the menu, if none, only the picto
 */
export const appDefinition = {
	name: "Clarity",
	subName: "",
	launcherID: "CLARITY",
	slice: "clarity",
	locales: "",
	theme: "clarity",
	favicon: "/images/Clarity/favicon.ico",
	logo: "/images/Clarity/icon-menu-clarity.svg",
	clientAccessor: "spaceLabel"
};

/**
 * Set up  menu options to display
 *
 * @param {String} menu ID of the menu (use for the initial menu definition and the render of the app)
 * @param {String} type Route or App = Route is a link to jump to a new url, App is a internal link to the App to display specific information
 * @param {String} url [OPTIONAL] If type == url, the new url to link the menu (it has to be routed in ./App.js)
 * @param {String} label [OPTIONAL] Label of the menu, if none, only the picto
 * @param {String} sectionTitleWhite [OPTIONAL] Section title in white displayed on top of the page when the menu is clicked
 * @param {String} sectionTitleBlack [OPTIONAL] Section title in black displayed on top of the page when the menu is clicked
 * @param {Component} picto Material UI Icon to represent the field in the select, to be imported in this file
 */
export const menuOptions = [
	{
		menu: "HOME",
		subMenu: ["DOMAINs", "NETZERO"],
		type: "App",
		label: "Dashboard",
		initial: true,
		clearRefine: ["SDG", "siteId", "section1Label", "section2Label", "maturity",
			"nameOfTheSupplier", "productCommercialName", "nameOfTimberSpecies", "countryOfOrigin", "actionPlanNcStatus"],
		picto: DashboardIcon
	},
	{
		menu: "SDGs",
		subMenu: ["SDG"],
		type: "App",
		label: "My SDGs",
		clearRefine: ["domain", "siteId", "section1Label", "section2Label", "maturity", "actionPlanNcStatus"],
		picto: DonutLargeOutlinedIcon
	},
	{
		menu: "SC",
		type: "App",
		label: "My Supply Chain",
		noMobileMenu: true,
		picto: LinkIcon
	},
	{
		menu: "KPIs",
		type: "App",
		label: "My KPIs",
		noMobileMenu: true,
		picto: FormatListNumberedIcon
	},
	{
		menu: "DISTRIB",
		type: "App",
		label: "Distribution",
		clearRefine: ["siteId", "section1Label", "section2Label", "maturity", "actionPlanNcStatus"],
		picto: BarChartOutlinedIcon
	},
	{
		menu: "NCs",
		type: "App",
		label: "Nonconformities",
		picto: FlagOutlinedIcon
	},
	{
		menu: "TRACEABILITY",
		// subMenu: ["SITE"],
		type: "App",
		label: "traceability",
		clearRefine: ["section1Label", "section2Label", "maturity", "actionPlanNcStatus"],
		picto: RouteRoundedIcon
	},
	{
		menu: "GSCA",
		type: "App",
		label: "GSCAdata",
		// clearRefine: ["section1Label", "section2Label", "maturity"],
		picto: PublicIcon
	},
	{
		menu: "SITEs",
		subMenu: ["SITE"],
		type: "App",
		label: "Sites",
		clearRefine: ["section1Label", "section2Label", "maturity", "actionPlanNcStatus"],
		picto: PlaylistAddCheckOutlinedIcon
	},
	{
		menu: "INFO",
		type: "App",
		label: "Info",
		noMobileMenu: true,
		picto: InfoOutlinedIcon
	}
];

/**
 * Name of the dataset with the client parameters
 */
const ClarityClientParameters = "ClarityClientParameters";

/**
 * Name of the dataset Modules for the Clarity web app
 */
const ClarityModules = "ClarityAssignmentScores";

/**
 * Name of the dataset Sections for the Clarity web app
 */
const ClaritySections = "ClaritySectionScores";

/**
 * Name of the SDG Scores dataset for the Clarity web app
 */
const ClaritySDGsScores = "ClaritySDGsScores";

/**
 * Name of the Net Zero KPIs dataset for the Clarity web app
 */
const ClarityNetZeroEmissions = "ClarityNetZeroEmissions";
/**
 * Name of the dataset Sites for the Clarity web app
 */

const ClaritySites = "ClaritySiteScores";

/**
 * Name of the dataset Countries for the Clarity web app
 */

const ClarityCountries = "ClarityCountryScores";

/**
 * Name of the dataset Supply Chain for the Clarity web app
 */
const ClarityChain = "ClarityChainScores";

/**
 * Name of the dataset Checkpoint for the Clarity web app
 */
export const ClarityCheckpoints = "ClarityCheckpoints";

/**
 * Query to load and update the non conformities
 */
export const listNonConformityQuery = [
	{
		$match: {
			nbOpenedNc: 1,
			maturity: {
				$ne: "Informative"
			},
			last: true
		}
	},
	{
		$project: {
			publicationDate: 1,
			last: 1,
			question: 1,
			answer: "$checkPointAnswer",
			observation: "$checkpoint.commentsObservations",
			auditedCompany: 1,
			siteType: 1,
			critical: 1,
			criticality: { $cond: [{ $eq: ["$critical", "Y"] }, "High", "Medium"] },
			product: 1,
			country: 1,
			city: "$site.city",
			domain: 1,
			section1Label: 1,
			section2Label: 1,
			assignmentTechnicalid: 1,
			maturity: 1,
			actionPlanItem: 1,
			correctiveAction: 1,
			actionPlanNcStatus: 1,
			actionPlanItemTargetDate: 1,
			actionPlanTargetDate: 1
		}
	}
];

/**
 * Query to load BV KPIs Distrib when needed
 */
/**
 * @param {string} kpi kpi to match - if needed
 * @param {object} groups Array. Fields passed to query $group stage
 * @param {boolean} last Refine on documents marked as "last"
 * @param {*} additionalRefine Additional parameter to add logic if needed. The world is yours !
 * @returns BV KPIs distribution
 */

export const ClarityKPIDistributionQuery = (kpi, groups, last, additionalRefine) => {
	let query = [
		{
			$match: {
				active: "Yes"
			}
		}, {
			$group: {
				_id: {
					kpi: "$kpi",
					aggregation: "$aggregation",
					formatUnit: "$formatUnit"
				},
				lastDate: {
					$max: "$lastDate"
				},
				num: {
					$sum: "$num"
				},
				num2: {
					$sum: "$num2"
				},
				div: {
					$sum: "$div"
				},
				div2: {
					$sum: "$div2"
				},
				BVImportant: {
					$first: "$BVImportant"
				}
			}
		}, {
			$addFields: {
				score: {
					$cond: [
						{
							$eq: [
								"$_id.aggregation", "SUM"
							]
						}, "$num", {
							$cond: [
								{
									$and: [
										{
											$eq: [
												"$_id.aggregation", "AVG"
											]
										}, {
											$ne: [
												"$div", 0
											]
										}
									]
								}, {
									$divide: [
										"$num", "$div"
									]
								}, {
									$cond: [
										{
											$and: [
												{
													$eq: [
														"$_id.aggregation", "RATIO"
													]
												}, {
													$ne: [
														"$div", 0
													]
												}, {
													$ne: [
														"$num2", 0
													]
												}, {
													$ne: [
														"$div2", 0
													]
												}
											]
										}, {
											$divide: [
												{
													$divide: [
														"$num", "$div"
													]
												}, {
													$divide: [
														"$num2", "$div2"
													]
												}
											]
										}, {
											$cond: [
												{
													$and: [
														{
															$eq: [
																"$_id.aggregation", "NPS"
															]
														}, {
															$ne: [
																"$div2", 0
															]
														}
													]
												}, {
													$multiply: [
														{
															$divide: [
																{
																	$subtract: [
																		"$num2", "$num"
																	]
																}, "$div2"
															]
														},
														100
													]
												}, "NA"
											]
										}
									]
								}
							]
						}
					]
				}
			}
		}, {
			$project: {
				kpi: "$_id.kpi",
				formatUnit: "$_id.formatUnit",
				lastDate: 1,
				score: {
					$cond: [
						{
							$and: [
								{
									$ne: [
										"$score", "NA"
									]
								}, {
									$eq: [
										"$_id.formatUnit", "%"
									]
								}
							]
						}, {
							$multiply: [
								"$score", 100
							]
						}, "$score"
					]
				},
				BVImportant: 1,
				_id: 0
			}
		}
	];

	if (last) {
		query[0].$match.last = true;
	}

	if (kpi !== undefined) {
		query[0].$match.kpi = `${kpi}`;
	}

	groups.forEach((group) => {
		query[1].$group._id[group] = `$${group}`;
		query[3].$project[group] = `$_id.${group}`;
	});

	if (additionalRefine?.prevYear) {
		query = [{
			$addFields: {
				year: {
					$year: "$date"
				},
				prevYear: {
					$subtract: [
						{
							$year: {
								$dateFromString: {
									dateString: new Date()
								}
							}
						}, additionalRefine.prevYear
					]
				}
			}
		}, {
			$addFields: {
				cmp: {
					$cmp: [
						"$year", "$prevYear"
					]
				}
			}
		}, {
			$match: {
				cmp: 0
			}
		}, ...query];
	}

	if (additionalRefine?.quarter) {
		query = [{
			$addFields: {
				year: {
					$year: "$date"
				},
				month: {
					$month: "$date"
				},
				currentYear: {
					$year: {
						$dateFromString: {
							dateString: new Date()
						}
					}
				},
				quarter: {
					$cond: [
						{
							$lte: [
								{
									$month: "$date"
								}, 3
							]
						}, 1, {
							$cond: [
								{
									$lte: [
										{
											$month: "$date"
										}, 6
									]
								}, 2, {
									$cond: [
										{
											$lte: [
												{
													$month: "$date"
												}, 9
											]
										}, 3, 4
									]
								}
							]
						}
					]
				}
			}
		}, {
			$addFields: {
				cmpYear: {
					$cmp: [
						"$year", "$currentYear"
					]
				}
			}
		}, {
			$match: {
				cmpYear: 0,
				quarter: additionalRefine.quarter
			}
		}, ...query];
	}
	return query;
};

/**
 * Array of the full list of facets to refine
 */
export const listFacetsToRefine = ["spaceLabel", "SDG", "siteType", "domain", "region", "country",
	"siteId", "OG", "orgUnit", "campaignId", "date", "assessmentType", "siteActivity"];

/**
 * Auchan list of facets
 */
export const listFacetsToRefineAuchan = [
	"spaceLabel", "countryOfOrigin", "region", "siteId",
	"nameOfTheSupplier", "nameOfTimberSpecies", "productCommercialName",
	"date"
];

/**
 * Client parameters
 */
export const clientInitParameters = {
	name: "clientParameters",
	collection: ClarityClientParameters,
	facetsToRefine: ["spaceLabel"],
	fetch: "aggregation",
	query: [
		{
			$lookup: {
				from: "ClaritySiteScoresAfterSites",
				localField: "spaceLabel",
				foreignField: "spaceLabel",
				as: "claritySiteScoresAfterSites"
			}
		}, {
			$unwind: {
				path: "$claritySiteScoresAfterSites",
				preserveNullAndEmptyArrays: true
			}
		}, {
			/*
			If the project does not have any scores and campaigns it means there is no max campaign id.
			Set the default value which is 1.
			*/
			$addFields: {
				"claritySiteScoresAfterSites.campaignId": {
					$cond: [
						{
							$lte: [
								"$claritySiteScoresAfterSites.campaignId", null
							]
						}, 1, "$claritySiteScoresAfterSites.campaignId"
					]
				}
			}
		}, {
			$group: {
				_id: "$_id",
				maxCampaignId: {
					$max: "$claritySiteScoresAfterSites.campaignId"
				},
				document: {
					$first: "$$ROOT"
				}
			}
		}, {
			$addFields: {
				"document.maxCampaignId": "$maxCampaignId"
			}
		}, {
			$replaceRoot: {
				newRoot: "$document"
			}
		}, {
			$addFields: {
				/*
				If the project does not have any active indicators or the active indicators is just an empty array
				set up the default value which would be an array with an empty object inside ([{}]). The active indicators
				are included in the mongodb requests (queries) when we load the initial client parameters and if the
				active indicators array does not exist or if it is empty the mongodb will throw "MongoServerError:
				Expression array must contain at least one selection object" error.
			*/
				activeIndicators: {
					$cond: [
						{
							$or: [
								{
									$lte: [
										"$activeIndicators", null
									]
								}, {
									$eq: [
										{
											$size: "$activeIndicators"
										}, 0
									]
								}
							]
						}, [
							{}
						], "$activeIndicators"
					]
				}
			}
		}, {
			$sort: {
				isNotDemo: 1,
				client: 1
			}
		}
	],
	limit: 1
};

export const getClientParametersByProjectName = (projectName) => ({
	name: "clientParameters",
	collection: ClarityClientParameters,
	facetsToRefine: ["spaceLabel"],
	fetch: "aggregation",
	query: [
		{
			$match: {
				projectName
			}
		}
	]
});

export const getClaritySupplierSiteId = (dataset, query) => ({
	name: dataset,
	collection: "ClaritySites",
	fetch: "aggregation",
	query,
	limit: 10,
	skip: 0
});

/**
 * Function to refresh the list of nonConformities after a click on a new page
 *
 * @param {Number} value Page to refresh the list of sites
 */
export const updateListNonConformities = (dataset, limit, value) => ({
	name: dataset,
	collection: ClarityCheckpoints,
	fetch: "aggregation",
	query: listNonConformityQuery,
	facetsToRefine: listFacetsToRefine.concat(["maturity", "critical", "actionPlanNcStatus", "actionPlanItemDelayed"]),
	dateToTransform: { date: "publicationDate" },
	limit,
	skip: ((value - 1) * limit)
});

/**
  * Active DataSets definition for the app
  *
  * @param {String} name
  * @param {String} collection
  * @param {String} fetch
  * @param {String[]} facetsToRefine
  * @param {Object[]} query
  * @param {Number} limit
  * @param {Object[]} data
  */
export const clarityInitDatasets = {
	listSpaces: {
		collection: ClarityClientParameters,
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						client: "$client",
						spaceLabel: "$spaceLabel"
					},
					nbSpaces: {
						$sum: 1
					},
					isNotDemo: {
						$first: "$isNotDemo"
					}
				}
			}, {
				$project: {
					client: "$_id.client",
					spaceLabel: "$_id.spaceLabel",
					nbSpaces: 1,
					isNotDemo: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listDimensions: {
		collection: ClarityModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "domain"),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$lookup: {
					from: "ClarityClientParameters",
					localField: "spaceLabel",
					foreignField: "spaceLabel",
					as: "clientParameters"
				}
			}, {
				$addFields: {
					clientParameters: {
						$arrayElemAt: [
							"$clientParameters", 0
						]
					}
				}
			}, {
				$addFields: {
					isSelected: {
						$indexOfArray: [
							"$clientParameters.activeIndicators", "$domain"
						]
					}
				}
			}, {
				$match: {
					isSelected: {
						$gte: 0
					}
				}
			},
			{
				$group: {
					_id: {
						domain: "$domain"
					},
					nbDomains: { $sum: 1 }
				}
			},
			{
				$project: {
					domain: "$_id.domain",
					nbDomains: 1,
					_id: 0
				}
			}
		],
		limit: 50
	},
	listMarketSegments: {
		collection: ClarityNetZeroEmissions,
		fetch: "aggregation",
		pages: ["HOME/NETZERO"],
		facetsToRefine: listFacetsToRefine,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						marketSegment: "$marketSegment"
					}
				}
			},
			{
				$project: {
					marketSegment: "$_id.marketSegment",
					_id: 0
				}
			}
		],
		limit: 10
	},
	listSDGs: {
		collection: ClaritySDGsScores,
		pages: ["SDGs", "SDGs/SDG", "KPIs"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "SDG"),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$lookup: {
					from: "ClarityClientParameters",
					localField: "spaceLabel",
					foreignField: "spaceLabel",
					as: "clientParameters"
				}
			}, {
				$addFields: {
					clientParameters: {
						$arrayElemAt: [
							"$clientParameters", 0
						]
					}
				}
			}, {
				$addFields: {
					isSelected: {
						$indexOfArray: [
							"$clientParameters.activeSDGs", "$SDG"
						]
					}
				}
			}, {
				$match: {
					isSelected: {
						$gte: 0
					}
				}
			}, {
				$group: {
					_id: "$SDG"
				}
			}, {
				$project: {
					SDG: "$_id",
					index: {
						$substr: [
							"$_id", 3, 2
						]
					},
					_id: 0
				}
			}
		],
		limit: 50
	},
	listScopes: {
		collection: ClarityModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "siteType"),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$group: {
					_id: {
						siteType: "$siteType"
					}
				}
			},
			{
				$project: {
					siteType: "$_id.siteType",
					_id: 0
				}
			}
		],
		limit: 2
	},
	listAssessmentTypes: {
		collection: ClarityModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "assessmentType"),
		query: [
			{
				$group: {
					_id: {
						assessmentType: "$assessmentType"
					}
				}
			}, {
				$project: {
					assessmentType: "$_id.assessmentType",
					_id: 0
				}
			}
		],
		limit: 50
	},
	listOrgUnits: {
		collection: ClaritySites,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$project: {
					"option.label": "$orgUnit",
					"option.value": "$orgUnit",
					OG: "$OG"
				}
			}, {
				$group: {
					_id: "$OG",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					refine: "OG"
				}
			}, {
				$match: {
					_id: {
						$exists: true,
						$ne: null
					}
				}
			}
		],
		limit: 500
	},
	listCountries: {
		collection: ClarityModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "country"),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$group: {
					_id: {
						country: "$country"
					},
					nbCountries: { $sum: 1 }
				}
			},
			{
				$project: {
					country: "$_id.country",
					nbCountries: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listRegions: {
		collection: ClarityModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "region"),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$group: {
					_id: {
						region: "$region"
					},
					nbAssessments: { $sum: 1 }
				}
			},
			{
				$project: {
					region: "$_id.region",
					nbAssessments: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listMaturities: {
		collection: ClarityCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					maturity: {
						$nin: [
							"Informative", "N/A", "NA", null
						]
					}
				}
			},
			{
				$group: {
					_id: {
						maturity: "$maturity"
					},
					nbQuestions: { $sum: 1 }
				}
			},
			{
				$project: {
					maturity: "$_id.maturity",
					nbQuestions: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listSections: {
		collection: ClaritySections,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "section1Label"),
		dateToTransform: { date: "publicationDate" },
		query: [{
			$project: {
				"option.index": "$sectionIndex",
				"option.label": "$section1Label",
				"option.value": "$section1Label",
				domain: "$domain"
			}
		}, {
			$group: {
				_id: "$domain",
				options: { $addToSet: "$option" }
			}
		}, {
			$addFields: {
				label: "$_id",
				value: "$_id",
				refine: "domain"
			}
		}],
		limit: 500
	},
	listSubsections: {
		collection: ClarityCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "section2Label"),
		dateToTransform: { date: "publicationDate" },
		query: [{
			$project: {
				"option.index": "$section2Index",
				"option.label": "$section2Label",
				"option.value": "$section2Label",
				domain: "$domain"
			}
		}, {
			$group: {
				_id: "$domain",
				options: {
					$addToSet: "$option"
				}
			}
		}, {
			$addFields: {
				label: "$_id",
				value: "$_id",
				refine: "domain"
			}
		}],
		limit: 500
	},
	listCriticalities: {
		collection: ClarityCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			},
			{
				$group: {
					_id: {
						criticality: { $cond: [{ $eq: ["$critical", "Y"] }, "High", "Medium"] }
					},
					nbQuestions: { $sum: 1 }
				}
			},
			{
				$project: {
					criticality: "$_id.criticality",
					nbQuestions: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listSites: {
		collection: ClaritySites,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$group: {
					_id: {
						siteId: "$siteId",
						auditedCompany: "$auditedCompany"
					},
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					siteId: "$_id.siteId",
					auditedCompany: "$_id.auditedCompany",
					nbSites: 1,
					_id: 0
				}
			}
		],
		limit: 2000
	},
	listEmissionOwners: {
		collection: ClarityNetZeroEmissions,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$group: {
					_id: {
						emissionOwner: "$emissionOwner"
					}
				}
			}, {
				$project: {
					emissionOwner: "$_id.emissionOwner",
					_id: 0
				}
			}
		]
	},
	listCampaignIds: {
		collection: "ClaritySiteScoresAfterSites",
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel"],
		dateToTransform: { date: "auditDate" },
		query: [
			{
				$match: {
					campaignId: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: "$campaignId",
					campaignLabel: {
						$first: "$campaign.label"
					},
					rank: {
						$first: "$campaign.rank"
					},
					spaceLabel: {
						$first: "$spaceLabel"
					}
				}
			}, {
				$sort: {
					rank: 1
				}
			}, {
				$project: {
					_id: 0,
					campaignId: "$_id",
					campaignLabel: 1,
					spaceLabel: 1
				}
			}
		]
	},
	listAuchanWoodProducts: {
		collection: "ClarityAssignedFormAuchanWoodUnwind",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefineAuchan.filter((e) => e !== "productCommercialName"),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					productCommercialName: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: "$productCommercialName"
				}
			}, {
				$project: {
					productCommercialName: "$_id",
					_id: 0
				}
			}
		]
	},
	listAuchanWoodSuppliers: {
		collection: "ClarityAssignedFormAuchanWoodUnwind",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefineAuchan.filter((e) => e !== "nameOfTheSupplier"),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					"_id.nameOfTheSupplier": {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: "$_id.nameOfTheSupplier"
				}
			}, {
				$project: {
					nameOfTheSupplier: "$_id",
					_id: 0
				}
			}
		]
	},
	listAuchanWoodCountries: {
		collection: "ClarityAssignedFormAuchanWoodUnwind",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefineAuchan.filter((e) => e !== "countryOfOrigin"),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					countryOfOrigin: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: "$countryOfOrigin"
				}
			}, {
				$project: {
					countryOfOrigin: "$_id",
					_id: 0
				}
			}
		]
	},
	listAuchanWoodSpecies: {
		collection: "ClarityAssignedFormAuchanWoodUnwind",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefineAuchan.filter((e) => e !== "nameOfTimberSpecies"),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					"_id.nameOfTimberSpecies": {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: "$_id.nameOfTimberSpecies"
				}
			}, {
				$project: {
					nameOfTimberSpecies: "$_id",
					_id: 0
				}
			}
		]
	},
	sites: {
		collection: ClaritySites,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["marketSegment"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$project: {
					siteId: 1,
					auditedCompany: 1,
					siteType: 1,
					address: 1,
					country: 1,
					region: 1,
					city: 1,
					siteScore: 1,
					sumNbOpenedNc: 1,
					sumNbCnc: 1,
					domain: 1,
					domainScores: 1,
					product: 1,
					report: 1,
					marketSegment: 1,
					geometry: 1
				}
			}, {
				$addFields: {
					domains: {
						$reduce: {
							input: "$domain",
							initialValue: "",
							in: {
								$concat: [
									"$$value", "$$this", "-"
								]
							}
						}
					}
				}
			}
		],
		limit: 2000
	},
	siteScores: {
		collection: ClarityModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						siteId: "$siteId"
					},
					auditedCompany: {
						$first: "$auditedCompany"
					},
					siteScore: {
						$avg: "$moduleScore"
					},
					sumNbOpenedNc: {
						$sum: "$sumNbOpenedNc"
					},
					sumNbCnc: {
						$sum: "$sumNbCnc"
					},
					domain: {
						$addToSet: "$domain"
					}
				}
			}, {
				$project: {
					siteId: "$_id.siteId",
					auditedCompany: 1,
					siteScore: {
						$multiply: [
							"$siteScore", 100
						]
					},
					sumNbOpenedNc: 1,
					sumNbCnc: 1,
					domain: 1,
					_id: 0
				}
			}
		]
	},
	countryScores: {
		collection: ClarityModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						country: "$country"
					},
					siteScore: {
						$avg: "$moduleScore"
					},
					sumNbOpenedNc: {
						$sum: "$sumNbOpenedNc"
					},
					sumNbCnc: {
						$sum: "$sumNbCnc"
					},
					domain: {
						$addToSet: "$domain"
					}
				}
			}, {
				$project: {
					country: "$_id.country",
					auditedCompany: 1,
					siteScore: {
						$multiply: [
							"$siteScore", 100
						]
					},
					sumNbOpenedNc: 1,
					sumNbCnc: 1,
					domain: 1,
					_id: 0
				}
			}
		]
	},
	progressScores: {
		collection: "ClarityAssignedForm",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "SDG" && e !== "OG" && e !== "domain" && e !== "orgUnit" && e !== "date"),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						country: "$country"
					},
					nbAssessments: {
						$sum: 1
					},
					nbAssessmentsPublished: {
						$sum: {
							$cond: [ { $eq: [ "$state", "PUBLISHED" ] }, 1, 0 ]
						}
					}
				}
			}, {
				$project: {
					country: "$_id.country",
					nbAssessmentsPublished: "$nbAssessmentsPublished",
					percentAssessments: {
						$divide: ["$nbAssessments", 100]
					}
				}
			}, {
				$project: {
					country: "$country",
					progressPercentAssessments: {
						$divide: ["$nbAssessmentsPublished", "$percentAssessments"]
					},
					_id: 0
				}
			}
		]
	},
	countries: {
		collection: ClarityCountries,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			},
			{
				$project: {
					region: 1,
					country: 1,
					countryScore: 1,
					sumNbOpenedNc: 1,
					domain: 1,
					domainScores: 1,
					geometry: 1
				}
			}
		],
		limit: 1000
	},
	scope: {
		collection: ClarityModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$group: {
					_id: {
						siteType: { $toUpper: "$siteType" },
						assessmentType: { $toUpper: "$assessmentType" }
					},
					nbAssessments: { $sum: 1 }
				}
			},
			{
				$project: {
					siteType: "$_id.siteType",
					assessmentType: "$_id.assessmentType",
					nbAssessments: 1,
					_id: 0
				}
			}
		],
		limit: 10
	},
	supplyChain: {
		collection: ClarityChain,
		pages: ["HOME", "SC", "SITEs"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel"],
		query: [
			{
				$match: {
					last: true
				}
			}
		],
		limit: 5000
	},
	clarityV4ToRSupplyChain: {
		collection: "ClarityV4ToR",
		pages: ["HOME", "SC", "SITEs"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel"],
		query: [
			{
				$addFields: {
					spaceLabel: "$projectName"
				}
			}
		],
		limit: 5000
	},
	siteListWithoutSupplyChain: {
		collection: "ClaritySiteScoresAfterSites",
		pages: ["HOME", "SC", "SITEs"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel", "date", "campaignId", "domain", "country", "assessmentType", "siteActivity"],
		dateToTransform: { date: "auditDate" },
		query: [
			{
				$addFields: {
					scoresByDomain: {
						$zip: {
							inputs: [
								"$domain", "$domainScores"
							],
							useLongestLength: true
						}
					}
				}
			}, {
				// overwrite array of arrays by a nice-looking object
				$addFields: {
					scoresByDomain: {
						$arrayToObject: "$scoresByDomain"
					}
				}
			}
		],
		limit: 5000
	},
	siteListSupplyChain: {
		collection: "ClaritySiteListSupplyChain",
		pages: ["HOME", "SC", "SITEs"],
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel", "date", "campaignId", "domain", "country", "assessmentType", "siteActivity"],
		dateToTransform: { date: "auditDate" },
		query: [
			{
				$match: {
					siteId: {
						$type: "int"
					}
				}
			}, {
				$addFields: {
					scoresByDomain: {
						$zip: {
							inputs: [
								"$domain", "$domainScores"
							],
							useLongestLength: true
						}
					}
				}
			}, {
				// overwrite array of arrays by a nice-looking object
				$addFields: {
					scoresByDomain: {
						$arrayToObject: "$scoresByDomain"
					}
				}
			}
		],
		limit: 5000
	},
	supplyChainAllFinalProducts: {
		collection: ClarityChain,
		pages: ["HOME", "SC"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "domain" && e !== "date" && e !== "campaignId").concat("finalProduct"),
		query: [{
			$group: {
				_id: {
					finalProduct: "$finalProduct"
				}
			}
		},
		{
			$project: {
				finalProduct: "$_id.finalProduct",
				_id: 0
			}
		}
		],
		limit: 500
	},
	supplyChainActiveFinalProducts: {
		collection: ClarityChain,
		pages: ["HOME", "SC"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "date").concat("finalProduct"),
		query: [
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			},
			{
				$project: {
					finalProduct: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 500
	},
	campaignScores: {
		collection: ClarityModules,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "campaignId"),
		query: [
			{
				$match: {
					campaignId: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: "$campaignId",
					score: {
						$avg: "$moduleScore"
					},
					campaign: {
						$first: "$campaign"
					}
				}
			}, {
				$project: {
					campaignId: "$_id",
					campaignLabel: "$campaign.label",
					score: {
						$multiply: [
							"$score", 100
						]
					}
				}
			}
		]
	},
	modulesScores: {
		collection: ClarityModules,
		fetch: "aggregation",
		pages: ["HOME", "DISTRIB/SITE", "HOME/NETZERO", "SITEs/SITE"],
		facetsToRefine: listFacetsToRefine.concat(["marketSegment", "emissionOwner"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					assignmentScore: { $ne: "N/A" },
					last: true
				}
			},
			{
				$group: {
					_id: {
						moduleIndex: "$_id.moduleIndex",
						domain: "$domain"
					},
					totalAssignments: { $sum: 1 },
					uniqueSites: { $addToSet: "$siteId" },
					moduleTotalScore: {
						$sum: "$moduleScore"
					},
					moduleNbNC: {
						$sum: "$sumNbOpenedNc"
					},
					moduleNbCNC: {
						$sum: "$sumNbCnc"
					}
				}
			},
			{
				$project: {
					domain: "$_id.domain",
					moduleIndex: "$_id.moduleIndex",
					totalAssignments: 1,
					uniqueSites: 1,
					moduleTotalScore: 1,
					moduleNbNC: 1,
					moduleNbCNC: 1,
					moduleScore: {
						$cond: [{ $eq: ["$totalAssignments", 0] }, 1, { $divide: ["$moduleTotalScore", "$totalAssignments"] }]
					},
					_id: 0
				}
			}
		],
		limit: 50
	},
	moduleScoresBis: {
		collection: ClaritySections,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label"]).filter((e) => e !== "campaignId"),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						assignedFormId: "$_id.assignedFormId",
						domain: "$_id.domain",
						campaignId: "$campaignId"
					},
					weightedSectionsScore: {
						$sum: "$weightedSectionScore"
					},
					sectionWeights: {
						$sum: "$sectionWeight"
					},
					sumNbOpenedNc: {
						$sum: "$sumNbOpenedNc"
					},
					sumNbCnc: {
						$sum: "$sumNbCnc"
					},
					domain: {
						$first: "$domain"
					},
					siteId: {
						$first: "$siteId"
					}
				}
			}, {
				$addFields: {
					moduleScore: {
						$cond: [
							{
								$eq: [
									"$sectionWeights", 0
								]
							}, 1, {
								$divide: [
									"$weightedSectionsScore", "$sectionWeights"
								]
							}
						]
					}
				}
			}, {
				$match: {
					assignmentScore: {
						$ne: "N/A"
					}
				}
			}, {
				$group: {
					_id: {
						moduleIndex: "$_id.moduleIndex",
						domain: "$domain",
						campaignId: "$_id.campaignId"
					},
					totalAssignments: {
						$sum: 1
					},
					uniqueSites: {
						$addToSet: "$siteId"
					},
					moduleTotalScore: {
						$sum: "$moduleScore"
					},
					moduleNbNC: {
						$sum: "$sumNbOpenedNc"
					},
					moduleNbCNC: {
						$sum: "$sumNbCnc"
					}
				}
			}, {
				$project: {
					campaignId: "$_id.campaignId",
					domain: "$_id.domain",
					moduleIndex: "$_id.moduleIndex",
					totalAssignments: 1,
					uniqueSites: 1,
					moduleTotalScore: 1,
					moduleNbNC: 1,
					moduleNbCNC: 1,
					moduleScore: {
						$cond: [
							{
								$eq: [
									"$totalAssignments", 0
								]
							}, 1, {
								$divide: [
									"$moduleTotalScore", "$totalAssignments"
								]
							}
						]
					},
					_id: 0
				}
			}
		]
	},
	SDGsScores: {
		collection: ClaritySDGsScores,
		pages: ["SDGs", "SDGs/SDG"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					assignmentScore: { $ne: "N/A" },
					last: true
				}
			},
			{
				$group: {
					_id: {
						SDG: "$SDG"
					},
					totalAssignments: { $sum: 1 },
					uniqueSites: { $addToSet: "$siteId" },
					SDGTotalScore: {
						$sum: "$SDGScore"
					},
					SDGNbNC: {
						$sum: "$sumNbOpenedNc"
					}
				}
			},
			{
				$addFields: {
					SDGScore: {
						$cond: [{ $eq: ["$totalAssignments", 0] }, 1, { $divide: ["$SDGTotalScore", "$totalAssignments"] }]
					}
				}
			},
			{
				$project: {
					SDG: "$_id.SDG",
					SDGScore: "$SDGScore",
					SDGNbNC: "$SDGNbNC",
					uniqueSites: "$uniqueSites",
					_id: 0
				}
			}
		],
		limit: 50
	},
	netZeroCO2ScopesPerSites: {
		collection: ClarityNetZeroEmissions,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "marketSegment", "emissionOwner"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$match: {
					emissionValueLabel: "tCO2eq/y"
				}
			}, {
				$group: {
					_id: "$spaceLabel",
					scopes: {
						$addToSet: "$moduleIndex"
					}
				}
			}, {
				$project: {
					spaceLabel: "$_id",
					scopes: "$scopes",
					_id: 0
				}
			}
		]
	},
	netZeroCO2perCountry: {
		collection: ClarityNetZeroEmissions,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "marketSegment", "emissionOwner"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true,
					country: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: {
						moduleIndex: "$moduleIndex",
						country: "$country"
					},
					sum: {
						$sum: "$emissionValue"
					}
				}
			}, {
				$project: {
					moduleIndex: "$_id.moduleIndex",
					country: "$_id.country",
					sumEmissions: "$sum",
					_id: 0
				}
			}
		]
	},
	netZeroCO2perScope: {
		collection: ClarityNetZeroEmissions,
		pages: ["HOME", "HOME/NETZERO", "HOME/SITE", "SITEs/SITE"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "marketSegment", "emissionOwner"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						moduleIndex: "$moduleIndex"
					},
					emissionValue: {
						$sum: "$emissionValue"
					}
				}
			}, {
				$project: {
					moduleIndex: "$_id.moduleIndex",
					emissionValue: 1,
					_id: 0
				}
			}
		],
		limit: 50
	},
	netZeroCO2perType: {
		collection: ClarityNetZeroEmissions,
		pages: ["HOME/NETZERO", "HOME/SITE", "SITEs/SITE"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "marketSegment", "emissionOwner"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						moduleIndex: "$moduleIndex",
						emissionType: "$emissionType",
						section1Label: "$section1Label"
					},
					emissionValue: {
						$sum: "$emissionValue"
					}
				}
			}, {
				$project: {
					moduleIndex: "$_id.moduleIndex",
					emissionType: "$_id.emissionType",
					section1Label: "$_id.section1Label",
					emissionValue: 1,
					_id: 0
				}
			}
		],
		limit: 50
	},
	netZeroBUEmissionValueShare: {
		collection: ClarityNetZeroEmissions,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "marketSegment", "emissionOwner"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					domain: "NET ZERO",
					emissionValue: {
						$nin: [
							null, 0
						]
					}
				}
			}, {
				$group: {
					_id: "$emissionOwner",
					sumEmissionValue: {
						$sum: "$emissionValue"
					}
				}
			}, {
				$project: {
					BU: "$_id",
					sumEmissionValue: {
						$multiply: [
							"$sumEmissionValue", 100
						]
					},
					unit: "$/tCO2eq",
					_id: 0
				}
			}
		]
	},
	netZeroCO2perSite: {
		collection: ClarityNetZeroEmissions,
		pages: ["HOME/NETZERO", "HOME/SITE", "SITEs/SITE", "DISTRIB"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "marketSegment", "emissionOwner"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						moduleIndex: "$moduleIndex",
						auditedCompany: "$auditedCompany",
						siteId: "$siteId"
					},
					emissionValue: {
						$sum: "$emissionValue"
					}
				}
			}, {
				$project: {
					moduleIndex: "$_id.moduleIndex",
					auditedCompany: "$_id.auditedCompany",
					siteId: "$_id.siteId",
					value: "$emissionValue",
					_id: 0
				}
			}
		],
		limit: 50
	},
	netZeroKPIs: {
		collection: ClarityNetZeroEmissions,
		pages: ["HOME/NETZERO", "HOME/SITE", "SITEs/SITE"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "marketSegment", "emissionOwner"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true,
					emissionValue: {
						$ne: null
					}
				}
			}, {
				$addFields: {
					emissionFactorValueUnitStartIndex: {
						$indexOfCP: [
							"$emissionFactorLabel", "("
						]
					},
					emissionFactorValueUnitEndIndex: {
						$indexOfCP: [
							"$emissionFactorLabel", ")"
						]
					},
					consumptionValueUnitStartIndex: {
						$indexOfCP: [
							"$consumptionValueLabel", "("
						]
					},
					consumptionValueUnitEndIndex: {
						$indexOfCP: [
							"$consumptionValueLabel", ")"
						]
					}
				}
			}, {
				$addFields: {
					consumptionValueUnit: {
						$cond: [
							"$consumptionValueLabel", {
								$substrCP: [
									"$consumptionValueLabel", {
										$add: [
											"$consumptionValueUnitStartIndex", 1
										]
									}, {
										$subtract: [
											"$consumptionValueUnitEndIndex", {
												$add: [
													"$consumptionValueUnitStartIndex", 1
												]
											}
										]
									}
								]
							}, null
						]
					},
					emissionFactorUnit: {
						$cond: [
							"$emissionFactorLabel", {
								$substrCP: [
									"$emissionFactorLabel", {
										$add: [
											"$emissionFactorValueUnitStartIndex", 1
										]
									}, {
										$subtract: [
											"$emissionFactorValueUnitEndIndex", {
												$add: [
													"$emissionFactorValueUnitStartIndex", 1
												]
											}
										]
									}
								]
							}, null
						]
					}
				}
			}, {
				$project: {
					_id: 0,
					moduleIndex: "$moduleIndex",
					siteLabel: "$siteLabel",
					auditedCompany: "$auditedCompany",
					emissionSource: "$emissionSource",
					emissionObject: "$emissionObject",
					emissionValue: "$emissionValue",
					emissionValueLabel: "$emissionValueLabel",
					emissionFactor: "$emissionFactor",
					emissionFactorUnit: "$emissionFactorUnit",
					emissionFactorPractice: "$emissionFactorPractice",
					consumptionValue: "$consumptionValue",
					consumptionValueUnit: "$consumptionValueUnit",
					emissionPractice: "$emissionPractice",
					emissionOwner: "$emissionOwner"
				}
			}
		],
		limit: 50
	},
	netZeroGeoDistribution: {
		collection: ClarityNetZeroEmissions,
		pages: ["HOME/NETZERO"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "marketSegment", "emissionOwner"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			},
			{
				$group: {
					_id: {
						domain: "$domain",
						moduleIndex: "$moduleIndex",
						region: "$region",
						country: "$country",
						iso2Code: "$iso2Code"
					},
					sites: { $addToSet: "$siteId" },
					emissionValue: {
						$sum: "$emissionValue"
					}
				}
			},
			{
				$project: {
					domain: "$_id.domain",
					moduleIndex: "$_id.moduleIndex",
					region: "$_id.region",
					country: "$_id.country",
					iso2Code: "$_id.iso2Code",
					sites: "$sites",
					totalAssets: {
						$size: "$sites"
					},
					value: "$emissionValue",
					_id: 0
				}
			}
		],
		limit: 500
	},
	netZeroAssetsDistribution: {
		collection: ClarityNetZeroEmissions,
		pages: ["HOME/NETZERO"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "marketSegment", "emissionOwner"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			},
			{
				$group: {
					_id: {
						domain: "$domain",
						moduleIndex: "$moduleIndex",
						marketSegment: "$marketSegment"
					},
					sites: { $addToSet: "$siteId" },
					emissionValue: {
						$sum: "$emissionValue"
					}
				}
			},
			{
				$project: {
					domain: "$_id.domain",
					moduleIndex: "$_id.moduleIndex",
					marketSegment: "$_id.marketSegment",
					sites: "$sites",
					totalAssets: {
						$size: "$sites"
					},
					value: "$emissionValue",
					_id: 0
				}
			}
		],
		limit: 500
	},
	netZeroAssetsScoresDistribution: {
		collection: ClarityModules,
		pages: ["HOME/NETZERO"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "marketSegment", "emissionOwner"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					domain: { $eq: "NET ZERO" },
					last: true
				}
			},
			{
				$group: {
					_id: {
						marketSegment: "$marketSegment"
					},
					assetScore: {
						$avg: "$moduleScore"
					}
				}
			},
			{
				$project: {
					marketSegment: "$_id.marketSegment",
					assetScore: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	netZeroGeoScoresDistribution: {
		collection: ClarityModules,
		pages: ["HOME/NETZERO"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["marketSegment"]),
		dateToTransform: { date: "publicationDate" },
		query: [{
			$match: {
				domain: {
					$eq: "NET ZERO"
				},
				last: true
			}
		},
		{
			$group: {
				_id: {
					country: "$country"
				},
				region: { $first: "$region" },
				countryScore: {
					$avg: "$moduleScore"
				}
			}
		},
		{
			$project: {
				country: "$_id.country",
				region: "$region",
				countryScore: 1,
				_id: 0
			}
		}
		],
		limit: 500
	},
	ClarityKPIs: {
		collection: "ClarityKPIsDetails",
		pages: ["KPIs"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => !["siteId", "date", "campaignId", "domain"].includes(element)),
		query: ClarityKPIDistributionQuery(undefined, ["kpiLabel", "sdgName", "domain", "SDG",
			"precision", "targetWay", "targetCYear", "target", "yearOfTarget", "unit", "note"], true, undefined),
		limit: 100
	},
	ClarityBVKPIsList: {
		collection: "ClarityBVKPIsList",
		pages: ["KPIs"],
		fetch: "aggregation",
		query: [
			{
				$addFields: {
					kpiId: {
						$cond: [
							{
								$eq: [
									"$kpiId", "SDG05_01a"
								]
							}, "SDG05_01a", {
								$cond: [
									{
										$eq: [
											"$kpiId", "SDG05_01"
										]
									}, "SDG05_01b", "$kpiId"
								]
							}
						]
					}
				}
			}
		]
	},
	linesMaturity: {
		collection: ClarityCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "maturity", "critical"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true,
					nbOpenedNc: 1,
					maturity: {
						$nin: [
							"Informative", "NA", "N/A", null
						]
					}
				}
			}, {
				$group: {
					_id: {
						year: {
							$year: "$publicationDate"
						},
						month: {
							$month: "$publicationDate"
						},
						maturity: "$maturity"
					},
					count: {
						$sum: 1
					},
					nbSites: {
						$addToSet: "$siteId"
					}
				}
			}, {
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					maturity: "$_id.maturity",
					count: "$count",
					nbSites: 1,
					_id: 0
				}
			}, {
				$group: {
					_id: "$maturity",
					el: {
						$push: {
							year: "$year",
							month: "$month",
							count: "$count"
						}
					}
				}
			}, {
				$unwind: {
					path: "$el"
				}
			}, {
				$sort: {
					"el.year": 1,
					"el.month": 1
				}
			}, {
				$group: {
					_id: "$_id",
					list: {
						$push: {
							month: {
								$concat: [
									{
										$cond: [
											{
												$eq: [
													"$el.month", 1
												]
											}, "Jan", {
												$cond: [
													{
														$eq: [
															"$el.month", 2
														]
													}, "Fev", {
														$cond: [
															{
																$eq: [
																	"$el.month", 3
																]
															}, "Mar", {
																$cond: [
																	{
																		$eq: [
																			"$el.month", 4
																		]
																	}, "Apr", {
																		$cond: [
																			{
																				$eq: [
																					"$el.month", 5
																				]
																			}, "May", {
																				$cond: [
																					{
																						$eq: [
																							"$el.month", 6
																						]
																					}, "Jun", {
																						$cond: [
																							{
																								$eq: [
																									"$el.month", 7
																								]
																							}, "Jul", {
																								$cond: [
																									{
																										$eq: [
																											"$el.month", 8
																										]
																									}, "Aug", {
																										$cond: [
																											{
																												$eq: [
																													"$el.month", 9
																												]
																											}, "Sep", {
																												$cond: [
																													{
																														$eq: [
																															"$el.month", 10
																														]
																													}, "Oct", {
																														$cond: [
																															{
																																$eq: [
																																	"$el.month", 11
																																]
																															}, "Nov", {
																																$cond: [
																																	{
																																		$eq: [
																																			"$el.month", 12
																																		]
																																	}, "Dec", null
																																]
																															}
																														]
																													}
																												]
																											}
																										]
																									}
																								]
																							}
																						]
																					}
																				]
																			}
																		]
																	}
																]
															}
														]
													}
												]
											}
										]
									}, "-", {
										$substr: [
											"$el.year", 2, 2
										]
									}
								]
							},
							count: "$el.count"
						}
					}
				}
			}, {
				$addFields: {
					list: {
						$map: {
							input: "$list",
							as: "el",
							in: {
								month: "$$el.month",
								value: {
									$sum: {
										$slice: [
											"$list.count", 0, {
												$add: [
													{
														$indexOfArray: [
															"$list", {
																month: "$$el.month",
																count: "$$el.count"
															}
														]
													}, 1
												]
											}
										]
									}
								}
							}
						}
					}
				}
			}, {
				$addFields: {
					list: {
						$filter: {
							input: "$list",
							as: "obj",
							cond: {
								$ne: [
									"$$obj.month", null
								]
							}
						}
					}
				}
			}
		]
	},
	maturityNbSites: {
		collection: ClarityCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "maturity"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true,
					nbOpenedNc: 1,
					maturity: {
						$nin: [
							"Informative", "NA", "N/A", null
						]
					}
				}
			}, {
				$group: {
					_id: {
						year: {
							$year: "$publicationDate"
						},
						month: {
							$month: "$publicationDate"
						},
						siteId: "$siteId"
					},
					count: {
						$sum: 1
					}
				}
			}, {
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					count: "$count",
					siteId: "$_id.siteId",
					_id: 0
				}
			}, {
				$group: {
					_id: {
						year: "$year",
						month: "$month"
					},
					nbSites: {
						$addToSet: "$siteId"
					}
				}
			}, {
				$match: {
					"_id.year": {
						$ne: null
					},
					"_id.month": {
						$ne: null
					}
				}
			}, {
				$sort: {
					"_id.year": 1,
					"_id.month": 1
				}
			}, {
				$addFields: {
					monthArr: [
						"Jan", "Fev", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
					],
					idxMonth: {
						$subtract: [
							"$_id.month", 1
						]
					}
				}
			}, {
				$project: {
					month: {
						$concat: [
							{
								$arrayElemAt: [
									"$monthArr", "$idxMonth"
								]
							}, "-", {
								$substr: [
									"$_id.year", 2, 2
								]
							}
						]
					},
					frequency: {
						$size: "$nbSites"
					},
					_id: 0
				}
			}
		]
	},
	maturityRatios: {
		collection: ClarityCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "maturity", "actionPlanNcStatus", "actionPlanItemDelayed"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true,
					maturity: {
						$nin: [
							"Informative", "N/A", "NA", null
						]
					},
					nbOpenedNc: 1
				}
			}, {
				$group: {
					_id: "$maturity",
					count: {
						$sum: 1
					}
				}
			}, {
				$group: {
					_id: "",
					total: {
						$sum: "$count"
					},
					maturity: {
						$push: {
							id: "$_id",
							count: "$count"
						}
					}
				}
			}, {
				$unwind: {
					path: "$maturity"
				}
			}, {
				$project: {
					total: 1,
					maturity: "$maturity.id",
					count: "$maturity.count",
					rate: {
						$divide: [
							{
								$multiply: [
									"$maturity.count", 100
								]
							}, "$total"
						]
					},
					_id: 0
				}
			}
		]
	},
	maturityRatiosByDomain: {
		collection: ClarityCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "maturity", "actionPlanNcStatus", "actionPlanItemDelayed"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true,
					maturity: {
						$nin: [
							"Informative", "N/A", "NA", null
						]
					},
					nbOpenedNc: 1
				}
			}, {
				$group: {
					_id: {
						maturity: "$maturity",
						domain: "$domain"
					},
					count: {
						$sum: 1
					}
				}
			}, {
				$group: {
					_id: "$_id.domain",
					total: {
						$sum: "$count"
					},
					maturity: {
						$push: {
							id: "$_id",
							count: "$count"
						}
					}
				}
			}, {
				$unwind: {
					path: "$maturity"
				}
			}, {
				$unwind: {
					path: "$maturity"
				}
			}, {
				$project: {
					domain: "$_id",
					total: 1,
					maturity: "$maturity.id.maturity",
					count: "$maturity.count",
					rate: {
						$divide: [
							{
								$multiply: [
									"$maturity.count", 100
								]
							}, "$total"
						]
					},
					_id: 0
				}
			}
		]
	},
	nonConformities: {
		collection: ClarityCheckpoints,
		pages: ["HOME", "HOME/DOMAINs", "HOME/NETZERO", "SDGs", "SDGs/SDG", "NCs", "HOME/SITE", "SITEs/SITE", "DISTRIB/SITE"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "maturity", "critical", "actionPlanNcStatus", "actionPlanItemDelayed"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true,
					maturity: {
						$nin: [
							"Informative", "N/A", "NA", null
						]
					}
				}
			},
			{
				$group: {
					_id: {
						year: { $year: "$publicationDate" },
						month: { $month: "$publicationDate" },
						siteId: "$siteId"
					},
					nbNC: { $sum: "$nbOpenedNc" },
					nbCNC: { $sum: "$nbCnc" }
				}
			},
			{
				$group: {
					_id: {
						year: "$_id.year",
						month: "$_id.month"
					},
					sites: { $addToSet: "$_id.siteId" },
					nbNC: { $sum: "$nbNC" },
					nbCNC: { $sum: "$nbCNC" }
				}
			},
			{
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					sites: 1,
					nbSites: { $size: "$sites" },
					nbNC: 1,
					nbCNC: 1,
					_id: 0
				}
			}
		],
		limit: 5000
	},
	nonConformitiesByDate: {
		collection: ClarityCheckpoints,
		pages: ["HOME", "HOME/DOMAINs", "HOME/NETZERO", "SDGs", "SDGs/SDG", "NCs", "HOME/SITE", "SITEs/SITE"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "maturity"]),
		dateToTransform: { date: "publicationDate" },
		query: [{
			$group: {
				_id: {
					year: {
						$year: "$publicationDate"
					},
					month: {
						$month: "$publicationDate"
					},
					siteId: "$siteId"
				},
				nbNC: {
					$sum: "$nbOpenedNc"
				},
				nbCNC: {
					$sum: "$nbCnc"
				}
			}
		},
		{
			$group: {
				_id: {
					year: "$_id.year",
					month: "$_id.month"
				},
				sites: {
					$addToSet: "$_id.siteId"
				},
				nbNC: {
					$sum: "$nbNC"
				},
				nbCNC: {
					$sum: "$nbCNC"
				}
			}
		},
		{
			$project: {
				year: "$_id.year",
				month: "$_id.month",
				sites: 1,
				nbSites: {
					$size: "$sites"
				},
				nbNC: 1,
				nbCNC: 1,
				_id: 0
			}
		}
		],
		limit: 12
	},
	nonConformitiesByModules: {
		collection: ClarityCheckpoints,
		pages: ["HOME/DOMAINs", "HOME/NETZERO", "SDGs", "SDGs/SDG", "NCs", "HOME/SITE", "SITEs/SITE"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "maturity", "critical", "actionPlanNcStatus", "actionPlanItemDelayed"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						domain: "$domain"
					},
					nbNC: { $sum: "$nbOpenedNc" },
					nbCNC: { $sum: "$nbCnc" }
				}
			},
			{
				$project: {
					domain: "$_id.domain",
					nbNC: 1,
					nbCNC: 1,
					_id: 0
				}
			}
		],
		limit: 30
	},
	listNonConformities: {
		collection: ClarityCheckpoints,
		pages: ["HOME/DOMAINs", "HOME/NETZERO", "SDGs", "SDGs/SDG", "NCs", "HOME/SITE", "SITEs/SITE", "DISTRIB/SITE"],
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(
			["section1Label", "section2Label", "maturity", "critical", "actionPlanNcStatus", "actionPlanItemDelayed"]),
		dateToTransform: { date: "publicationDate" },
		query: listNonConformityQuery,
		skip: 0,
		limit: 10
	},
	sectionsScore: {
		collection: "ClaritySectionScores",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["section1Label", "marketSegment"]),
		dateToTransform: { date: "publicationDate" },
		query: [{
			$match: {
				last: true
			}
		}, {
			$group: {
				_id: {
					domain: "$domain",
					moduleIndex: "$moduleIndex",
					sectionId: "$sectionId",
					section1Label: "$section1Label",
					sectionIndex: "$sectionIndex"
				},
				totalAssignements: {
					$sum: 1
				},
				uniqueSites: {
					$addToSet: "$siteId"
				},
				sectionTotalScore: {
					$sum: "$sectionScore"
				},
				sectionNbNC: {
					$sum: "$sumNbOpenedNc"
				},
				sectionNbCNC: {
					$sum: "$sumNbCnc"
				},
				assignmentTechnicalid: {
					$first: "$assignmentTechnicalid"
				}
			}
		}, {
			$addFields: {
				sectionScore: {
					$cond: [{
						$eq: [
							"$totalAssignements",
							0
						]
					},
					1,
					{
						$divide: [
							"$sectionTotalScore",
							"$totalAssignements"
						]
					}
					]
				}
			}
		}, {
			$project: {
				domain: "$_id.domain",
				moduleIndex: "$_id.moduleIndex",
				uniqueSites: 1,
				sectionId: "$_id.sectionId",
				section1Label: "$_id.section1Label",
				sectionIndex: "$_id.sectionIndex",
				score: "$sectionScore",
				sectionNbNC: "$sectionNbNC",
				sectionNbCNC: "$sectionNbCNC",
				assignmentTechnicalid: "$assignmentTechnicalid",
				_id: 0
			}
		}],
		limit: 300
	},
	auchandWoodProductTable: {
		collection: "ClarityAssignedFormAuchanWoodUnwind",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefineAuchan,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$lookup: {
					from: "SpeciesListCITES",
					localField: "nameOfTimberSpecies",
					foreignField: "Final Species list",
					as: "CITES"
				}
			}, {
				$lookup: {
					from: "site",
					localField: "siteId",
					foreignField: "_id",
					as: "site"
				}
			}, {
				$match: {
					"species.sectionWeightSplitIn": {
						$ne: "NC"
					},
					"species.country.weightedWeight": {
						$ne: 0
					}
				}
			}, {
				$project: {
					product: {
						productCommercialName: "$productCommercialName",
						productNumber: "$productNumber",
						nmDPC: "$nmDPC"
					},
					productCommercialName: "$productCommercialName",
					productNumber: "$productNumber",
					nmDPC: "$nmDPC",
					nameOfTimberSpecies: "$nameOfTimberSpecies",
					nameOfTheSupplier: "$nameOfTheSupplier",
					countryOfOrigin: "$countryOfOrigin",
					gpsCoordinatesOfTheForestOfOrigin: "$species.country.gpsCoordinatesOfTheForestOfOrigin",
					isProductCertified: {
						$cond: [
							{
								$eq: [
									"$isProductCertified", "Yes"
								]
							}, "Certified", "Uncertified"
						]
					},
					isListedInCITES: {
						$cond: [
							{
								$arrayElemAt: [
									"$CITES", 0
								]
							}, "Forbidden", "Allowed"
						]
					},
					unitaryWeight: 1,
					shareEssenceInProduct: "$species.sectionWeightSplitIn",
					weightSplitByCountry: {
						$cond: [
							{
								$eq: [
									"$species.sectionWeightSplitIn", "NC"
								]
							}, "NC", {
								$multiply: [
									"$species.country.weightSplitIn", {
										$divide: [
											"$species.sectionWeightSplitIn", 100
										]
									}
								]
							}
						]
					},
					weightByProductInGrams: {
						$cond: [
							{
								$eq: [
									"$species.sectionWeightSplitIn", "NC"
								]
							}, "NC",
							{
								$multiply: [
									"$unitaryWeight", {
										$divide: [
											{
												$multiply: [
													"$species.country.weightSplitIn", {
														$divide: [
															"$species.sectionWeightSplitIn", 100
														]
													}
												]
											}, 100
										]
									}
								]
							}
						]
					},
					qtyBought: "$quantity",
					productSupplier: {
						$concat: [
							{
								$arrayElemAt: [
									"$site.auditedCompany", 0
								]
							}, " - ", {
								$arrayElemAt: [
									"$site.label", 0
								]
							}
						]
					},
					publicationDate: "$publicationDate",
					nbOfEssences: "$nbOfEssences"
				}
			}, {
				$addFields: {
					shareEssenceByCountryInUW: {
						$multiply: [
							{
								$divide: [
									"$weightByProductInGrams", "$unitaryWeight"
								]
							}, 100
						]
					}
				}
			}, {
				$addFields: {
					shareOfEssByCountryInQty: {
						$ifNull: [
							{
								$multiply: [
									{
										$divide: [
											"$weightByProductInGrams", {
												$multiply: [
													"$qtyBought", 1000000
												]
											}
										]
									}, 100
								]
							}, "NC"
						]
					}
				}
			}, {
				$project: {
					site: 0
				}
			}
		]
	},
	auchanWoodSpeciesDistribution: {
		collection: "ClarityAssignedFormAuchanWoodUnwind",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefineAuchan,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$addFields: {
					quantityInG: {
						$multiply: [
							"$unitaryWeight", {
								$divide: [
									"$species.country.weightedWeight", 100
								]
							}
						]
					}
				}
			}, {
				$group: {
					_id: "$nameOfTimberSpecies",
					producedQty: {
						$sum: "$quantityInG"
					}
				}
			}, {
				$project: {
					nameOfTimberSpecies: "$_id",
					producedQty: 1,
					_id: 0
				}
			}
		]
	},
	auchanWoodCountriesDistribution: {
		collection: "ClarityAssignedFormAuchanWoodUnwind",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefineAuchan,
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$addFields: {
					quantityInG: {
						$multiply: [
							"$unitaryWeight", {
								$divide: [
									"$species.country.weightedWeight", 100
								]
							}
						]
					}
				}
			}, {
				$group: {
					_id: "$countryOfOrigin",
					producedQty: {
						$sum: "$quantityInG"
					}
				}
			}, {
				$project: {
					countryOfOrigin: "$_id",
					producedQty: 1,
					_id: 0
				}
			}
		]
	},
	getMaxCampaignId: {
		collection: "ClaritySiteScoresAfterSites",
		fetch: "aggregation",
		dateToTransform: { date: "auditDate" },
		query: [
			{
				$match: {
					spaceLabel: {
						$ne: null
					},
					campaignId: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: "$spaceLabel",
					maxCampaignId: {
						$max: "$campaignId"
					}
				}
			}
		]
	},
	gscaRiskData: {
		collection: "ClarityGSCAUploads",
		facetsToRefine: ["spaceLabel"],
		fetch: "find",
		limit: 1000
	},
	gscaOverallRisksBreakdown: {
		collection: "ClarityGSCAUploads",
		facetsToRefine: ["spaceLabel"],
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: "overallRiskBreakdown",
					nbSuppliers: {
						$sum: 1
					},
					risks: {
						$push: "$riskCombinationLabel"
					}
				}
			}, {
				$unwind: {
					path: "$risks"
				}
			}, {
				$group: {
					_id: "$risks",
					nbSuppliers: {
						$first: "$nbSuppliers"
					},
					count: {
						$sum: 1
					}
				}
			}, {
				$project: {
					riskCategory: "$_id",
					nbSuppliers: 1,
					count: 1,
					_id: 0
				}
			}
		],
		limit: 1000
	},
	gscaEnvRiskDistribution: {
		collection: "ClarityGSCAUploads",
		facetsToRefine: ["spaceLabel"],
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: "$riskEnvLabel",
					count: {
						$sum: 1
					}
				}
			}, {
				$project: {
					riskCategory: {
						$ifNull: [
							"$_id", "NA"
						]
					},
					nbSuppliers: 1,
					count: 1,
					_id: 0
				}
			}
		]
	},
	gscaHRRiskDistribution: {
		collection: "ClarityGSCAUploads",
		facetsToRefine: ["spaceLabel"],
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: "$riskHRLabel",
					count: {
						$sum: 1
					}
				}
			}, {
				$project: {
					riskCategory: {
						$ifNull: [
							"$_id", "NA"
						]
					},
					nbSuppliers: 1,
					count: 1,
					_id: 0
				}
			}
		]
	},
	ClarityGSCAUploads: {
		collection: "ClarityGSCAUploads",
		facetsToRefine: ["spaceLabel"],
		fetch: "find"
	},
	topTenQuestions: {
		collection: ClarityCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					critical: "Y"
				}
			}, {
				$group: {
					_id: {
						question: "$question",
						assessmentType: "$assessmentType"
					},
					answersTotal: {
						$sum: 1
					},
					domain: {
						$addToSet: "$domain"
					},
					sumNbOpenedNc: {
						$sum: "$nbOpenedNc"
					},
					sumNbCnc: {
						$sum: "$nbCnc"
					}
				}
			}, {
				$addFields: {
					assessmentTypeCodes: [
						{
							fullname: "SELF ASSESSMENT QUESTIONNAIRE",
							codename: "SAQ"
						}, {
							fullname: "FIELD AUDIT",
							codename: "FA"
						}, {
							fullname: "3rd Party Report Conversion",
							codename: "3PRC"
						}
					]
				}
			}, {
				$addFields: {
					codedAssessmentName: {
						$filter: {
							input: "$assessmentTypeCodes",
							as: "codedNames",
							cond: {
								$eq: [
									"$$codedNames.fullname", "$_id.assessmentType"
								]
							}
						}
					}
				}
			}, {
				$addFields: {
					codedAssessmentName: {
						$arrayElemAt: [
							"$codedAssessmentName", 0
						]
					},
					dm: {
						$arrayElemAt: [
							"$domain", 0
						]
					}
				}
			}, {
				$addFields: {
					question: {
						$concat: [
							"$codedAssessmentName.codename", " - ", "$_id.question"
						]
					}
				}
			}, {
				$project: {
					question: 1,
					answersTotal: 1,
					domain: 1,
					dm: 1,
					sumNbOpenedNc: 1,
					sumNbCnc: 1,
					cncVsAnswerTotal: {
						$divide: [
							"$sumNbCnc", "$answersTotal"
						]
					}
				}
			}, {
				$sort: {
					sumNbCnc: -1
				}
			}
		],
		limit: 10
	},
	// The purpose is to get all the module-score data. If the a module does not have score then assign N/A value.
	sitesByModulesWithoutSupplyChain: {
		collection: "ClaritySiteScoresAfterSites",
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel", "date", "campaignId", "domain", "country", "region", "assessmentType"],
		dateToTransform: { date: "auditDate" },
		query: [
			{
				$match: {
					spaceLabel: {
						$ne: null
					}
				}
			}, {
				$lookup: {
					from: "ClarityClientParameters",
					localField: "spaceLabel",
					foreignField: "spaceLabel",
					as: "clientParams"
				}
			}, {
				$addFields: {
					clientParams: {
						$arrayElemAt: [
							"$clientParams", 0
						]
					}
				}
			}, {
				$addFields: {
					activeIndicators: "$clientParams.activeIndicators",
					domain: {
						$ifNull: [
							"$domain", "$clientParams.activeIndicators"
						]
					},
					domainScores: {
						$ifNull: [
							"$domainScores", [
								""
							]
						]
					}
				}
			}, {
				$addFields: {
					extendedDomains: {
						$filter: {
							input: "$activeIndicators",
							as: "indicator",
							cond: {
								$cond: [
									{
										$in: [
											"$$indicator", "$domain"
										]
									}, null, "$$indicator"
								]
							}
						}
					}
				}
			}, {
				$addFields: {
					extendedDomains: {
						$concatArrays: [
							"$domain", "$extendedDomains"
						]
					}
				}
			}, {
				$addFields: {
					scoresByDomain: {
						$zip: {
							inputs: [
								"$extendedDomains", "$domainScores"
							],
							useLongestLength: true
						}
					}
				}
			}, {
				$addFields: {
					scoresByDomain: {
						$map: {
							input: "$scoresByDomain",
							as: "sc",
							in: {
								$cond: [
									{
										$eq: [
											{
												$arrayElemAt: [
													"$$sc", 1
												]
											}, null
										]
									}, [
										{
											$arrayElemAt: [
												"$$sc", 0
											]
										}, "N/A"
									], "$$sc"
								]
							}
						}
					}
				}
			}, {
				$addFields: {
					scoresByDomain: {
						$arrayToObject: "$scoresByDomain"
					},
					audCompanyObj: {
						auditedCompany: "$auditedCompany"
					}
				}
			}, {
				$addFields: {
					scoresAndCompany: {
						$mergeObjects: [
							"$scoresByDomain", "$audCompanyObj"
						]
					}
				}
			}, {
				$addFields: {
					"scoresAndCompany.siteId": "$siteId"
				}
			}, {
				$replaceRoot: {
					newRoot: "$scoresAndCompany"
				}
			}
		],
		limit: 5000
	},
	// The purpose is to get all the module-score data. If the a module does not have score then assign N/A value.
	sitesByModulesSupplyChain: {
		collection: "ClaritySiteListSupplyChain",
		fetch: "aggregation",
		facetsToRefine: ["spaceLabel", "date", "campaignId", "domain", "country", "region", "assessmentType"],
		dateToTransform: { date: "auditDate" },
		query: [
			{
				$match: {
					siteId: {
						$type: "int"
					},
					spaceLabel: {
						$ne: null
					}
				}
			}, {
				$lookup: {
					from: "ClarityClientParameters",
					localField: "spaceLabel",
					foreignField: "spaceLabel",
					as: "clientParams"
				}
			}, {
				$addFields: {
					clientParams: {
						$arrayElemAt: [
							"$clientParams", 0
						]
					}
				}
			}, {
				$addFields: {
					activeIndicators: "$clientParams.activeIndicators",
					domain: {
						$ifNull: [
							"$domain", "$clientParams.activeIndicators"
						]
					},
					domainScores: {
						$ifNull: [
							"$domainScores", [
								""
							]
						]
					}
				}
			}, {
				$addFields: {
					extendedDomains: {
						$filter: {
							input: "$activeIndicators",
							as: "indicator",
							cond: {
								$cond: [
									{
										$in: [
											"$$indicator", "$domain"
										]
									}, null, "$$indicator"
								]
							}
						}
					}
				}
			}, {
				$addFields: {
					extendedDomains: {
						$concatArrays: [
							"$domain", "$extendedDomains"
						]
					}
				}
			}, {
				$addFields: {
					scoresByDomain: {
						$zip: {
							inputs: [
								"$extendedDomains", "$domainScores"
							],
							useLongestLength: true
						}
					}
				}
			}, {
				$addFields: {
					scoresByDomain: {
						$map: {
							input: "$scoresByDomain",
							as: "sc",
							in: {
								$cond: [
									{
										$eq: [
											{
												$arrayElemAt: [
													"$$sc", 1
												]
											}, null
										]
									}, [
										{
											$arrayElemAt: [
												"$$sc", 0
											]
										}, "N/A"
									], "$$sc"
								]
							}
						}
					}
				}
			}, {
				$addFields: {
					scoresByDomain: {
						$arrayToObject: "$scoresByDomain"
					},
					audCompanyObj: {
						auditedCompany: "$auditedCompany"
					}
				}
			}, {
				$addFields: {
					scoresAndCompany: {
						$mergeObjects: [
							"$scoresByDomain", "$audCompanyObj"
						]
					}
				}
			}, {
				$addFields: {
					"scoresAndCompany.siteId": "$siteId"
				}
			}, {
				$replaceRoot: {
					newRoot: "$scoresAndCompany"
				}
			}
		],
		limit: 5000
	},
	listActiveInactive: {
		collection: "ClarityClientParameters",
		facetsToRefine: ["spaceLabel"],
		fetch: "aggregation",
		query: [
			{
				$project: {
					siteActivity: {
						$cond: [
							{
								$eq: [
									"$siteDeactivation", true
								]
							}, [
								"Active", "All Sites"
							], []
						]
					},
					_id: 0
				}
			}, {
				$unwind: {
					path: "$siteActivity"
				}
			}
		]
	},
	listNCStatus: {
		collection: ClarityCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "actionPlanNcStatus"),
		query: [
			{
				$match: {
					last: true,
					actionPlanNcStatus: {
						$nin: [
							null, undefined
						]
					}
				}
			}, {
				$group: {
					_id: "$actionPlanNcStatus"
				}
			}, {
				$project: {
					_id: 0,
					actionPlanNcStatus: "$_id",
					label: {
						$cond: [
							{
								$eq: [
									"$_id", "1"
								]
							}, "Open", {
								$cond: [
									{
										$eq: [
											"$_id", "2"
										]
									}, "Closed", {
										$cond: [
											{
												$eq: [
													"$_id", "3"
												]
											}, "Submitted", "NA"
										]
									}
								]
							}
						]
					}
				}
			}
		]
	},
	listActionPlanItemDelayedList: {
		collection: ClarityCheckpoints,
		facetsToRefine: listFacetsToRefine,
		fetch: "aggregation",
		query: [
			{
				$match: {
					last: true
				}
			},
			{
				$group: {
					_id: {
						actionPlanItemDelayed: "$actionPlanItemDelayed"
					},
					nbActionPlanItemDelayed: {
						$sum: 1
					}
				}
			}, {
				$project: {
					actionPlanItemDelayed: "$_id.actionPlanItemDelayed",
					nbStates: 1,
					_id: 0
				}
			}, {
				$addFields: {
					actionPlanItemDelayed: {
						$cond: [
							{
								$eq: [
									"$actionPlanItemDelayed", "on time"
								]
							}, "on time", "delayed"
						]
					},
					actionPlanItemDelayedStr: {
						$cond: [
							{
								$eq: [
									"$actionPlanItemDelayed", "on time"
								]
							}, "On time", "Delayed"
						]
					}
				}
			}
		]
	},
	getActionPlan: {
		collection: "ClarityAssignmentScores",
		facetsToRefine: listFacetsToRefine,
		fetch: "aggregation",
		query: [
			{
				$lookup: {
					from: "maia_action_plan",
					localField: "_id.assignedFormId",
					foreignField: "assignedFormId",
					as: "actionPlan"
				}
			}, {
				$match: {
					actionPlan: {
						$ne: null
					},
					last: true
				}
			}, {
				$addFields: {
					actionPlan: {
						$arrayElemAt: [
							"$actionPlan", 0
						]
					}
				}
			}, {
				$match: {
					"actionPlan.actionPlanId": {
						$ne: null
					}
				}
			}, {
				$project: {
					actionPlanProgressRate: {
						$multiply: [
							{
								$divide: [
									{
										$add: [
											"$actionPlan.numberClosedNC", "$actionPlan.numberCompletedNC"
										]
									}, {
										$size: "$actionPlan.actionPlanItems"
									}
								]
							}, 100
						]
					},
					nbNC: {
						$size: "$actionPlan.actionPlanItems"
					},
					project: "$actionPlan.project",
					domain: "$domain",
					actionPlanStatus: "$actionPlan.actionPlanStatus",
					actionPlanTargetDate: 1,
					spaceId: {
						$substr: [
							"$spaceId", 0, -1
						]
					},
					actionPlanId: {
						$substr: [
							"$actionPlan.actionPlanId", 0, -1
						]
					},
					assignmentId: {
						$substr: [
							"$assignmentId", 0, -1
						]
					},
					urlPrefix: "https://maiaplus.bureauveritas.com/browse/actionplan/detail/"
				}
			}, {
				$project: {
					domain: 1,
					actionPlanStatus: 1,
					nbNC: 1,
					actionPlanProgressRate: {
						$concat: [
							{
								$substr: [
									"$actionPlanProgressRate", 0, -1
								]
							}, "%"
						]
					},
					urlActionPlan: {
						$concat: [
							"$urlPrefix", "$project", "/", "$spaceId", "/", "$actionPlanId", "/", "$assignmentId"
						]
					}
				}
			}
		]
	},
	actionPlanStatusDistribPerSites: {
		collection: ClarityCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					actionPlanItem: {
						$ne: null
					}
				}
			}, {
				// If there is no target date then use publication date + 30 days, otherwise use target date
				$addFields: {
					ncTargetDate: {
						$cond: [
							{
								$eq: [
									"$actionPlanItemTargetDate", null
								]
							}, {
								$add: [
									"$publicationDate", 2592000000
								]
							}, "$actionPlanItemTargetDate"
						]
					}
				}
			}, {
				$group: {
					_id: {
						siteId: "$siteId",
						assignedFormId: "$assignedFormId"
					},
					targetDates: {
						$push: "$ncTargetDate"
					},
					totalActionPlanNcStatus: {
						$push: "$actionPlanNcStatus"
					},
					actionPlanStatus: {
						$first: "$actionPlanStatus"
					}
				}
			}, {
				$project: {
					totalActionPlanNcStatus: "$totalActionPlanNcStatus",
					totalNCs: {
						$size: "$totalActionPlanNcStatus"
					},
					actionPlanStatus: "$actionPlanStatus",
					actionPlanId: "$actionPlan._id",
					maxTargetDate: {
						$max: "$targetDates"
					}
				}
			}, {
				$unwind: {
					path: "$totalActionPlanNcStatus"
				}
			}, {
				$group: {
					_id: {
						siteId: "$_id.siteId",
						assignedFormId: "$_id.assignedFormId",
						ncStatus: "$totalActionPlanNcStatus"
					},
					totalNCs: {
						$first: "$totalNCs"
					},
					actionPlanStatus: {
						$first: "$actionPlanStatus"
					},
					actionPlanTargetDate: {
						$first: "$maxTargetDate"
					},
					count: {
						$sum: 1
					}
				}
			}, {
				$project: {
					_id: 0,
					siteId: "$_id.siteId",
					ncStatus: "$_id.ncStatus",
					frequency: "$count",
					totalNCs: "$totalNCs",
					actionPlanStatus: 1,
					actionPlanTargetDate: 1
				}
			}
		]
	},
	actionPlanDelayCount: {
		collection: ClarityCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$match: {
					actionPlanItem: {
						$ne: null
					}
				}
			}, {
				$group: {
					_id: "$auditedCompany",
					totalItems: {
						$sum: 1
					},
					totalDelayed: {
						$sum: {
							$cond: [
								{
									$eq: [
										"$actionPlanItemDelayed", "delayed"
									]
								}, 1, 0
							]
						}
					}
				}
			}, {
				$project: {
					_id: 0,
					supplier: "$_id",
					totalItems: 1,
					totalDelayed: 1,
					delayRate: {
						$multiply: [
							{
								$divide: [
									"$totalDelayed", "$totalItems"
								]
							}, 100
						]
					}
				}
			}
		]
	},
	clarityDomains: {
		collection: "ClarityDomains",
		fetch: "find",
		facetsToRefine: [],
		limit: 500,
		adminCallFromApp: ""
	},
	clarityTranslations: {
		collection: "ClarityTranslations",
		fetch: "aggregation",
		facetsToRefine: [],
		// Fetch only "active" translations
		query: [
			{
				$lookup: {
					from: "ClarityTranslationsActivity",
					localField: "_id",
					foreignField: "_id",
					as: "activityObj"
				}
			}, {
				$addFields: {
					activityObj: {
						$arrayElemAt: [
							"$activityObj", 0
						]
					}
				}
			}, {
				$match: {
					"activityObj.active": true
				}
			}, {
				$project: {
					activityObj: 0
				}
			}
		],
		limit: 500,
		adminCallFromApp: ""
	}
};

/**
 * Init Refine of the Application
 *
 * @param {Object} clarityInitRefines
 * Object representing the initial refine of the datasets
 * in the App slice, the key being the name of the field
 * in the DB Collection to filter on, the value being the value to filter on
 */
export const clarityInitRefines = { spaceLabel: undefined };

// Define the MapToolTip
export const clarityMapTooltip = [
	{ label: "Audited Company", key: "auditedCompany" },
	{ label: "Scope", key: "siteType" },
	{ label: "Country", key: "country" },
	{ label: "Score", key: "siteScore" }
];
/**
 * Set up refine filter options to display
 *
 * @param {String} refine name of the field in the DB Collection to filter on
 * @param {String} displayField [optional] name of the field to show on the filter menu (if different than refine)
 * @param {String} loadFromDataset name of the collection to filter on the field
 * @param {String} placeHolder [OPTIONAL] text when nothing is selected
 * @param {Component} tag Material UI Icon to represent the field in the select, to be imported in this file
 * @param {String} type preload or dynamic => preload being a preloading of the distinct value of the field in the DB in the store
 * @param {Boolean} clear will be cleared if the button clear is pushed
 * @param {Boolean} toClearOnChange execute a clear of other clearable fields (see above) if the value has changed
* @param {Boolean} manualPosition [OPTIONAL] false by default, if true, needs to be positionned manually in the page
*/
export const filterOptions = [
	{
		label: "Projects",
		refine: "spaceLabel",
		displayField: "client",
		loadFromDataset: "listSpaces",
		hiddenIfUnique: true,
		isMulti: false,
		isClearable: false,
		isSearchable: true,
		tag: DeviceHubOutlinedIcon,
		clear: false,
		toClearOnChange: true,
		sortKey1: "isNotDemo",
		sortKey2: "client"
	},
	{
		label: "Campaigns",
		refine: "campaignId",
		displayField: "campaignLabel",
		loadFromDataset: "listCampaignIds",
		placeHolder: "All campaigns",
		isMulti: false,
		hiddenIfUnique: false,
		isClearable: false,
		isSearchable: false,
		tag: DeviceHubOutlinedIcon,
		clear: false,
		toClearOnChange: true,
		sortKey1: "campaignId"
	},
	{
		label: "DateRange", // label is used as a key for translationsm this is why there is no space between two words (Date Range => DateRange)
		refine: "date",
		placeHolder: "filters.date",
		isDatePicker: true
	},
	{
		label: "WoodSuppliers",
		refine: "nameOfTheSupplier",
		placeHolder: "All Wood Suppliers",
		loadFromDataset: "listAuchanWoodSuppliers",
		tag: DeviceHubOutlinedIcon,
		clear: true,
		isClearable: true,
		isMulti: true,
		isSearchable: true,
		hiddenIfEmpty: true
	},
	{
		label: "WoodProducts",
		refine: "productCommercialName",
		placeHolder: "All Wood Products",
		loadFromDataset: "listAuchanWoodProducts",
		tag: DeviceHubOutlinedIcon,
		clear: true,
		isClearable: true,
		isMulti: true,
		isSearchable: true,
		hiddenIfEmpty: true
	},
	{
		label: "WoodSpecies",
		refine: "nameOfTimberSpecies",
		placeHolder: "All Wood Species",
		loadFromDataset: "listAuchanWoodSpecies",
		tag: DeviceHubOutlinedIcon,
		isClearable: true,
		clear: true,
		isMulti: true,
		isSearchable: true,
		hiddenIfEmpty: true
	},
	{
		label: "CountriesOfOrigin",
		refine: "countryOfOrigin",
		placeHolder: "All Countries of Origin",
		loadFromDataset: "listAuchanWoodCountries",
		tag: DeviceHubOutlinedIcon,
		clear: true,
		isClearable: true,
		isMulti: true,
		isSearchable: true,
		hiddenIfEmpty: true
	},
	{
		label: "SiteTypes",
		refine: "siteType",
		loadFromDataset: "listScopes",
		placeHolder: "filters.typeOfSite",
		isMulti: false,
		isClearable: true,
		isSearchable: false,
		tag: DeviceHubOutlinedIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "SiteStatus",
		refine: "siteActivity",
		loadFromDataset: "listActiveInactive",
		placeHolder: "filters.allSites",
		isMulti: false,
		isClearable: true,
		isSearchable: false,
		tag: DeviceHubOutlinedIcon,
		clear: true,
		toClearOnChange: false,
		hiddenIfEmpty: true
	},
	{
		label: "Modules",
		refine: "domain",
		loadFromDataset: "listDimensions",
		placeHolder: "filters.modules",
		isMulti: false,
		isClearable: true,
		isSearchable: false,
		tag: DeviceHubOutlinedIcon,
		clear: true,
		toClearOnChange: false,
		clearRefines: ["section1Label", "section2Label"],
		translateLabel: true,
		translationKeyPrefix: "domains"
	},
	{
		label: "BusinessOwners",
		refine: "emissionOwner",
		loadFromDataset: "listEmissionOwners",
		placeHolder: "filters.emissionOwner",
		onlyIfRefine: (d) => d?.domain === "NET ZERO",
		isMulti: true,
		isClearable: true,
		isSearchable: true,
		tag: DeviceHubOutlinedIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "MarketSegments",
		refine: "marketSegment",
		loadFromDataset: "listMarketSegments",
		placeHolder: "filters.marketSegments",
		onlyIfRefine: (d) => d?.domain === "NET ZERO",
		isMulti: true,
		isClearable: true,
		isSearchable: false,
		tag: DeviceHubOutlinedIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Organizations",
		refine: "orgUnit",
		refineGroup: "OG",
		loadFromDataset: "listOrgUnits",
		placeHolder: "filters.units",
		onlyIfRefine: (d, e) => e?.specificFilters?.includes("organization"),
		isMulti: false,
		isClearable: true,
		isSearchable: true,
		isGrouped: true,
		groupSelection: true,
		tag: AccountTreeIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Regions",
		refine: "region",
		loadFromDataset: "listRegions",
		placeHolder: "filters.regions",
		isMulti: true,
		isClearable: true,
		isSearchable: true,
		tag: PinDropOutlinedIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Countries",
		refine: "country",
		loadFromDataset: "listCountries",
		placeHolder: "filters.countries",
		isMulti: true,
		isClearable: true,
		isSearchable: true,
		tag: PinDropOutlinedIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "AssessmentType",
		refine: "assessmentType",
		loadFromDataset: "listAssessmentTypes",
		placeHolder: "filters.assessmentType",
		isMulti: false,
		isClearable: true,
		isSearchable: false,
		tag: DeviceHubOutlinedIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Sections",
		refine: "section1Label",
		loadFromDataset: "listSections",
		placeHolder: "filters.sections",
		isClearable: true,
		isSearchable: false,
		isGrouped: true,
		groupSelection: true,
		clear: true,
		toClearOnChange: false,
		manualPosition: true,
		clearRefines: ["section2Label"]
	}, {
		label: "Subsections",
		refine: "section2Label",
		loadFromDataset: "listSubsections",
		placeHolder: "filters.subsections",
		isClearable: true,
		isSearchable: false,
		isGrouped: true,
		groupSelection: false,
		clear: true,
		toClearOnChange: true,
		manualPosition: true,
		hiddenIfUnique: false,
		onlyIfRefine: (d, p) => d?.section1Label !== undefined && p?.displaySectionLevel2
	},
	{
		label: "Maturities",
		refine: "maturity",
		loadFromDataset: "listMaturities",
		placeHolder: "filters.NCLevel",
		isClearable: true,
		isSearchable: true,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		label: "NCStatus",
		refine: "actionPlanNcStatus",
		displayField: "label",
		loadFromDataset: "listNCStatus",
		placeHolder: "filters.NCStatus",
		isClearable: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true,
		hiddenIfEmpty: true
	},
	{
		label: "PlanItemDelayed",
		refine: "actionPlanItemDelayed",
		displayField: "actionPlanItemDelayedStr",
		loadFromDataset: "listActionPlanItemDelayedList",
		placeHolder: "filters.actionPlanItemDelayed",
		isClearable: true,
		isSearchable: false,
		clear: true,
		toClearOnChange: false,
		manualPosition: true,
		hiddenIfEmpty: true
	},
	{
		label: "Sites",
		refine: "siteId",
		displayField: "auditedCompany",
		loadFromDataset: "listSites",
		placeHolder: "filters.searchForASite",
		isClearable: true,
		isSearchable: true,
		tag: BusinessIcon,
		clear: true,
		toClearOnChange: false,
		manualPosition: true
	},
	{
		refine: "supplier",
		manualPosition: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "OG",
		manualPosition: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "siteId",
		manualPosition: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "finalProduct",
		manualPosition: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "product",
		manualPosition: true,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "critical",
		manualPosition: true,
		clear: true,
		toClearOnChange: false
	}
];

/**
 * Define the rule for the Marker color on the map
 */
export const getMarkerColor = (score) => {
	if (!score) return "#c9bbbd";
	if (score >= 0 && score < 20) return "#eb4c72";
	if (score >= 20 && score < 35) return "#fe8f71";
	if (score >= 35 && score < 50) return "#fec461";
	if (score >= 50 && score < 75) return "#e7e401";
	if (score >= 75 && score <= 90) return "#ccdb74";
	if (score >= 90 && score <= 100) return "#70ad46";
};

// Used by legend on the map
export const colorsAndBreakdowns = {
	unsatisfactory: {
		color: "#eb4c72",
		range: [0, 20]
	},
	subStandard: {
		color: "#fe8f71",
		range: [20, 35]
	},
	inProgress: {
		color: "#fec461",
		range: [35, 50]
	},
	average: {
		color: "#e7e401",
		range: [50, 75]
	},
	good: {
		color: "#ccdb74",
		range: [75, 90]
	},
	veryGood: {
		color: "#70ad46",
		range: [90, 100]
	}
};

/**
 * Define the color code and name for non conformities
 */
export const nonConformities = { High: "#FF475C", Medium: "#FFC362", Low: "#CCDB73" };

/**
 * Define the color code and name for net zero kpis
 */
export const netZeroEmissionPractice = { "Missing Practice": "#EF4444", "Common Practice": "#FFC362", "Best Practice": "#CCDB73" };

/**
 * List of key indicators to be shown on the clarity app
 */

export const domainsOptions = {
	"HUMAN CAPITAL":
	{
		locale: "HumanCapital",
		color: "#5963B7",
		logo: "/images/Clarity/Modules/icn-health-safety.svg"
	},
	"Health & Safety":
	{
		locale: "HealthSafety",
		color: "#5963B7",
		logo: "/images/Clarity/Modules/icn-health-safety.svg"
	},
	"BUSINESS MODEL & INNOVATION":
	{
		locale: "BusinessModelInnovation",
		color: "#44B9B4",
		logo: "/images/Clarity/Modules/icn-performance.svg"
	},
	"Data Security & Privacy":
	{
		locale: "DataSecurityPrivacy",
		color: "#FF475C",
		logo: "/images/Clarity/Modules/icn-cyber.svg"
	},
	"Business Performance":
	{
		locale: "BusinessPerformance",
		color: "#3D49AB",
		logo: "/images/Clarity/Modules/icn-performance.svg"
	},
	"Product Quality & Process Control":
	{
		locale: "ProductQualityProcessControl",
		color: "#FF475C",
		logo: "/images/Clarity/Modules/icn-quality.svg"
	},
	Environment: {
		locale: "Environment",
		color: "#CCDB73",
		logo: "/images/Clarity/Modules/icn-env-bio-1.svg"
	},
	ENVIRONMENT:
	{
		locale: "Environment",
		color: "#CCDB73",
		logo: "/images/Clarity/Modules/icn-env-bio-1.svg"
	},
	"Environment & Biodiversity":
	{
		locale: "EnvironmentBiodiversity",
		color: "#FFC362",
		logo: "/images/Clarity/Modules/icn-env-bio-1.svg"
	},
	"Climate Change":
	{
		locale: "ClimateChange",
		color: "#CCDB73",
		logo: "/images/Clarity/Modules/icn-climchange.svg"
	},
	"SOCIAL CAPITAL":
	{
		locale: "SocialCapital",
		color: "#662CAC",
		logo: "/images/Clarity/Modules/icn-social.svg"
	},
	Social:
	{
		locale: "Social",
		color: "#662CAC",
		logo: "/images/Clarity/Modules/icn-social.svg"
	},
	"LEADERSHIP & GOVERNANCE":
	{
		locale: "LeadershipGovernance",
		color: "#B81D7E",
		logo: "/images/Clarity/Modules/icn-quality.svg"
	},
	"Business Ethics":
	{
		locale: "BusinessEthics",
		color: "#B81D7E",
		logo: "/images/Clarity/Modules/icn-business-ethics.svg"
	},
	"BV Code of Conduct":
	{
		locale: "BVCodeOfConduct",
		color: "#B81D7E",
		logo: "/images/Clarity/Modules/icn-business-ethics.svg"
	},
	"NET ZERO":
	{
		locale: "NetZero",
		color: "#FFC362",
		logo: "/images/Clarity/Modules/icn-planet.svg"
	},
	"ANIMAL WELFARE":
	{
		locale: "AnimalWelfare",
		color: "#FFC362",
		logo: PetsOutlinedIcon
	},
	TRACEABILITY:
	{
		locale: "Traceability",
		color: "#FF8585",
		logo: "/images/Clarity/Modules/icn-repsonsible-sourcing.svg"
	},
	"Responsible Sourcing":
	{
		locale: "ResponsibleSourcing",
		color: "#FF8585",
		logo: "/images/Clarity/Modules/icn-repsonsible-sourcing.svg"
	},
	"ETHICAL TRADE":
	{
		locale: "EthicalTrade",
		color: "#FF8585",
		logo: "/images/Clarity/Modules/icn-ethical-trade.svg"
	},
	"CLARITY ONE": {
		locale: "ClarityOne",
		color: "#FF8585",
		logo: "/images/Clarity/ClarityOutline.svg"
	},
	"Social Compliance": {
		locale: "Social Compliance",
		color: "#662CAC",
		logo: "/images/Clarity/Modules/icn-social.svg"
	},
	"German Supply Chain Act": {
		locale: "German Supply Chain Act",
		color: "#FF8585",
		logo: "/images/Clarity/Modules/icn-repsonsible-sourcing.svg"
	},
	"Achieving Net-Zero - Building & Infrastructure - Organisation": {
		locale: "NetZero B&I - Organisation",
		color: "#FFC362",
		logo: "/images/Clarity/Modules/icn-planet.svg"
	},
	"Achieving Net-Zero - Building CAPEX": {
		locale: "NetZero B&I - Building CAPEX",
		color: "#FFC362",
		logo: "/images/Clarity/Modules/icn-planet.svg"
	},
	"Achieving Net-Zero - Infrastructure CAPEX": {
		locale: "NetZero B&I - Infrastructure CAPEX",
		color: "#FFC362",
		logo: "/images/Clarity/Modules/icn-planet.svg"
	},
	"Achieving Net-Zero - Building OPEX": {
		locale: "NetZero B&I - Building OPEX",
		color: "#FFC362",
		logo: "/images/Clarity/Modules/icn-planet.svg"
	},
	"Achieving Net-Zero - Infrastructure OPEX": {
		locale: "NetZero B&I - Infrastructure OPEX",
		color: "#FFC362",
		logo: "/images/Clarity/Modules/icn-planet.svg"
	},
	"GRI - Universal Standards": {
		locale: "GRI - Universal Standards",
		color: "#FFC362",
		logo: "/images/Clarity/Modules/icn-repsonsible-sourcing.svg"
	},
	"GRI - Economic": {
		locale: "GRI - Economic",
		color: "#FFC362",
		logo: "/images/Clarity/Modules/icn-performance-business.svg"
	},
	"GRI - Environment": {
		locale: "GRI - Environment",
		color: "#FFC362",
		logo: "/images/Clarity/Modules/icn-env-bio-1.svg"
	},
	"GRI - Social": {
		locale: "GRI - Social",
		color: "#FFC362",
		logo: "/images/Clarity/Modules/icn-social.svg"
	}

};

/**
 * List of key SDGs to be shown on the clarity app
 */
export const sdgsOptions = {
	SDG1:
	{
		label: "No poverty",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-01.png",
		color: "#E5243B",
		lightColor: "#EA6C7A"
	},
	SDG2:
	{
		label: "Zero hunger",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-02.png",
		color: "#DDA63A",
		lightColor: "E3C27F"
	},
	SDG3:
	{
		label: "Good health and well-being",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-03.png",
		color: "#4C9F38",
		lightColor: "#7DAE71",
		pillar: "Shaping Better Workplace"
	},
	SDG4:
	{
		label: "Quality education",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-04.png",
		color: "#C5192D",
		lightColor: "#CE5966"
	},
	SDG5:
	{
		label: "Gender equality",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-05.png",
		color: "#FF3A21",
		lightColor: "#FF7F6E",
		pillar: "Shaping Better Workplace"
	},
	SDG6:
	{
		label: "Clean water and sanitation",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-06.png",
		color: "#26BDE2",
		lightColor: "#6DCFE8"
	},
	SDG7:
	{
		label: "Affordable and clean energy",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-07.png",
		color: "#FCC30B",
		lightColor: "#FDD656"
	},
	SDG8:
	{
		label: "Decent work and economic growth",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-08.png",
		color: "#A21942",
		lightColor: "#B3506E",
		pillar: "Shaping Better Workplace"
	},
	SDG9:
	{
		label: "Industry, innovation and infrastrcuture",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-09.png",
		color: "#FD6925",
		lightColor: "#FD9E72"
	},
	SDG10:
	{
		label: "Reduced inequalities",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-10.png",
		color: "#DD1367",
		lightColor: "#E35992"
	},
	SDG11:
	{
		label: "Sustainable cities and communities",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-11.png",
		color: "#FD9D24",
		lightColor: "#FDBD6F"
	},
	SDG12:
	{
		label: "Responsible consumption and production",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-12.png",
		color: "#BF8B2E",
		lightColor: "#CAA86D"
	},
	SDG13:
	{
		label: "Climate action",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-13.png",
		color: "#3F7E44",
		lightColor: "#759378",
		pillar: "Shaping Better Environment"
	},
	SDG14:
	{
		label: "Life below water",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-14.png",
		color: "#0A97D9",
		lightColor: "#4EB1DF"
	},
	SDG15:
	{
		label: "Life on Land",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-15.png",
		color: "#59BA48",
		lightColor: "#92C688"
	},
	SDG16:
	{
		label: "Peace, justice and strong institutions",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-16.png",
		color: "#126A9F",
		lightColor: "#4787AE",
		pillar: "Shaping Better Business Practices"
	},
	SDG17:
	{
		label: "Partnerships for the goals",
		picto: "/images/Clarity/SDGs/SDG_Icons_Inverted_Transparent_WEB-17.png",
		color: "#13496B",
		lightColor: "#5893B8"

	}
};

/**
 * List of Assets to be shown on the net zero domain
 */
export const netZeroAssetsOptions = {
	Upstream:
	{
		index: 1,
		locale: "upstream",
		picto: "/images/Clarity/NetZero/upstream.svg"
	},
	Midstream:
	{
		index: 2,
		locale: "midstream",
		picto: "/images/Clarity/NetZero/midstream.svg"
	},
	Downstream:
	{
		index: 3,
		locale: "downstream",
		picto: "/images/Clarity/NetZero/downstream.svg"
	},
	Retail:
	{
		index: 4,
		locale: "retail",
		picto: "/images/Clarity/NetZero/retail.svg"
	}
};

/**
 * Clarity SplashScreen screens
 */
export const splashScreens = [
	{
		name: "welcome",
		type: "full",
		contrasted: true,
		img: "/images/Clarity/Splashscreen/Splashscreen-001.png"
	},
	{
		name: "page1",
		type: "page",
		img: "/images/Clarity/Splashscreen/Splashscreen-002.png"
	},
	{
		name: "page2",
		type: "page",
		img: "/images/Clarity/Splashscreen/Splashscreen-003.png"
	},
	{
		name: "page3",
		type: "page",
		img: "/images/Clarity/Splashscreen/Splashscreen-004.png"
	}
];

/**
 * Array of tags to be displayed for each non conformities
 */
export const listTagsNonConformities = {
	auditedCompany: {
		accessor(d, t) { return d.auditedCompany; },
		picto(d) { return BusinessIcon; },
		refine: [{ key: "siteId", valueKey: (d) => d.siteId }]
	},
	location: {
		accessor(d, t) { return ((d.city && `${d.city}-`) + d.country); },
		picto(d) { return PinDropIcon; }
	},
	siteType: {
		accessor(d, t) { return d.siteType; },
		picto(d) { return CompareArrowsIcon; }
	},
	module: {
		useDBData: true,
		accessor(d, t, activeDatasets) {
			return activeDatasets?.clarityDomains?.data?.find((domain) => domain.name === d.domain).name;
		},
		picto(d, activeDatasets) {
			return activeDatasets?.clarityDomains?.data?.find((domain) => domain.name === d.domain).domainLogo;
		},
		refine: [{ key: "domain", valueKey: (d) => d.domain }]
	},
	section: {
		accessor(d, t) { return d.section1Label; },
		picto(d) { return VerticalSplitOutlinedIcon; }
	},
	subsection: {
		display(p) {
			return p?.displaySectionLevel2;
		},
		accessor(d, t) {
			return d?.section2Label;
		},
		picto(d) {
			return VerticalSplitOutlinedIcon;
		}
	},
	product: {
		accessor(d, t) { return d.product; },
		picto(d) { return AccountTreeOutlinedIcon; }
	}
};

/**
 * nc status mapping
 */
const ncStatusMapping = {
	1: { status: "open", color: "#EF4444", picto: ReportGmailerrorredIcon },
	2: { status: "closed", color: "#6E8F57", picto: ForwardToInboxIcon },
	3: { status: "submitted", color: "#E1A544", picto: CheckCircleIcon },
	undefined: { status: "undefined", color: undefined, picto: HelpIcon }
};

const targetDateMapping = {
	true: { color: "#EF4444" },
	false: { color: undefined }
};

const isMoreThanNDaysAgo = (date, delay = 30) => {
	const daysInMs = delay * 24 * 60 * 60 * 1000;
	const timestampNDaysAgo = new Date().getTime() - daysInMs;

	if (timestampNDaysAgo > date) {
		// console.log("date is more than 30 days into the past");
		return true;
	}
	// console.log("date is NOT more than 30 days into the past");
	return false;
};

export const listNCAPTags = {
	ncStatus: {
		accessor(d) { return `Action plan item ${ncStatusMapping[d.actionPlanNcStatus]?.status}`; },
		color(d) { return ncStatusMapping[d?.actionPlanNcStatus]?.color; },
		picto(d) { return ncStatusMapping[d?.actionPlanNcStatus]?.picto; },
		cond(d) { return !!d.actionPlanNcStatus; }
	},
	targetDate: {
		accessor(d) { return `Due on: ${d.actionPlanItemTargetDate ? new Date(d.actionPlanItemTargetDate).toLocaleDateString("fr") : undefined }`; },
		color(d) {
			if (ncStatusMapping[d.actionPlanNcStatus]?.status === "open") {
				return targetDateMapping[isMoreThanNDaysAgo(new Date(d.actionPlanItemTargetDate), 30)]?.color;
			}
			if (ncStatusMapping[d.actionPlanNcStatus]?.status === "submitted") {
				return targetDateMapping[isMoreThanNDaysAgo(new Date(d.actionPlanItemTargetDate), 1)]?.color;
			}

			return undefined;
		},
		picto(d) { return AccessAlarmIcon; },
		cond(d) { return !!d.actionPlanNcStatus; }
	}
};

export const auchanWoodTableStructure = () => [
	{
		Header: "product",
		accessor: "productCommercialName"
	},
	{
		Header: "productSupplier",
		accessor: "productSupplier"
	},
	{
		Header: "nmDPC",
		accessor: "nmDPC"
	},
	{
		Header: "species",
		accessor: "nameOfTimberSpecies"
	},
	{
		Header: "woodSupplier",
		accessor: "nameOfTheSupplier"
	},
	{
		Header: "country",
		accessor: "countryOfOrigin"
	},
	{
		Header: "forestCoords",
		accessor: "gpsCoordinatesOfTheForestOfOrigin"
	},
	{
		Header: "qtyBought",
		accessor: "qtyBought",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => value ? (Number.isInteger(value) ? `${value} t` : `${value.toFixed(2)} t`) : "n/a"
	},
	{
		Header: "unitaryWeight",
		accessor: "unitaryWeight",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => value ? (Number.isInteger(value) ? `${value} g` : `${value.toFixed(2)} g`) : "n/a"
	},
	{
		Header: "weightSplit",
		accessor: "weightSplitByCountry",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => `${(typeof value === "number" && !Number.isNaN(value)) ? formatNumberWithTwoDecimals(value) : "nc"} %`
	},
	{
		Header: "weightSplitUW",
		accessor: "shareEssenceByCountryInUW",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => `${(typeof value === "number" && !Number.isNaN(value)) ? formatNumberWithTwoDecimals(value) : "nc"} %`
	},
	{
		Header: "weightSplitQty",
		accessor: "shareOfEssByCountryInQty",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => `${(typeof value === "number" && !Number.isNaN(value)) ? formatNumberWithTwoDecimals(value) : "nc"} %`
	},
	{
		Header: "nbOfEssences",
		accessor: "nbOfEssences"
	},
	{
		Header: "CITES",
		accessor: "isListedInCITES",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => <div className="text-left">{value === "Forbidden"
			? <CancelRoundedIcon className="text-red-400" />
			: value === "Allowed"
				? <CheckCircleRoundedIcon className="text-clarity_primary-menu" />
				: null}</div>
	},
	{
		Header: "publicationDate",
		accessor: "publicationDate",
		Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : null
	},
	{
		Header: "certification",
		accessor: "isProductCertified"
	}
];

export const gscaColorScale =
	[
		{
			label: "Low", color: "#4dc490", rank: 0
		},
		{
			label: "Low - Medium", color: "#ffc362", rank: 1
		},
		{
			label: "Medium - High", color: "#ef8244", rank: 2
		},
		{
			label: "High", color: "#ef4444", rank: 3
		},
		{
			label: "NA", color: "#808080", rank: 4
		}
	];

export const getGSCAMarkerColor = (risk) => risk === "Low"
	? "#4dc490"
	: risk === "Low - Medium"
		? "#ffc362"
		: risk === "Medium - High"
			? "#ef8244"
			: risk === "High"
				? "#ef4444"
				: "#808080";

export const gscaTableStructure = () => [
	{
		Header: "Supplier",
		accessor: "supplier"
	},
	{
		Header: "Address",
		accessor: "address",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value, row }) => (
			<Tooltip title={`${(row.original.geometry?.coordinates === "Invalid address")
				? "Please check the address. No match in geocoding."
				: !row.original.geometry
					? "Geocoding is pending."
					: ""}`}>
				<span className={`${(row.original.geometry?.coordinates === "Invalid address")
					? "text-red-700 font-semibold"
					: !row.original.geometry
						? "italic"
						: ""}`}>{value}</span>
			</Tooltip>
		)
	},
	{
		Header: "Country",
		accessor: "country"
	},
	{
		Header: "City",
		accessor: "city"
	},
	{
		Header: "Nace",
		accessor: "naceCode"
	},
	{
		Header: "Id",
		accessor: "supplierUniqueId"
	},
	{
		Header: "Upload date",
		accessor: "uploadDate"
	},
	{
		Header: "Env Risk",
		accessor: "riskEnvLabel",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => {
			const color = gscaColorScale?.find((el) => (el.label === value))?.color;
			return <p style={{ color }}>{value}</p>;
		}
	},
	{
		Header: "HR Risk",
		accessor: "riskHRLabel",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => {
			const color = gscaColorScale?.find((el) => (el.label === value))?.color;
			return <p style={{ color }}>{value}</p>;
		}
	},
	{
		Header: "Overall Risk",
		accessor: "riskCombinationLabel",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => {
			const color = gscaColorScale?.find((el) => (el.label === value))?.color;
			return <p style={{ color }}>{value}</p>;
		}
	}
];

export const tableStructure = ({ activeRefines, clientParameters, t }) => {
	// The headers (column names) are dependant on the file "translationsClarity.json"
	let shouldReturn = [];
	const base = [
		{
			Header: "Name",
			accessor: clientParameters?.supplyChain ? "company" : "auditedCompany"
		},
		{
			Header: "Address",
			accessor: "address"
		},
		{
			Header: "City",
			accessor: "city"
		},
		{
			Header: "Country",
			accessor: "country"
		},
		{
			Header: "Active",
			accessor: "inactive",
			Cell: ({ value }) => (value === true ? "No" : "Yes")
		},
		{
			Header: activeRefines.domain ? "Module" : "Modules",
			accessor: "domains",
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				value === null
					? ""
					: (activeRefines.domain
						? activeRefines.domain
						: value.slice(0, -1)))
		},
		{
			Header: "Score",
			accessor: activeRefines.domain ? "scoresByDomain" : "siteScore",
			sortType: (rowA, rowB) => {
				if (!activeRefines.domain) {
					if (rowA.original.siteScore > rowB.original.siteScore) return -1;
					if (rowB.original.siteScore > rowA.original.siteScore) return 1;
				}
				if (activeRefines.domain) {
					if ((rowA.original.scoresByDomain?.[activeRefines.domain] ?? 0) >
						(rowB.original.scoresByDomain?.[activeRefines.domain] ?? 0)) return -1;
					if ((rowB.original.scoresByDomain?.[activeRefines.domain] ?? 0) >
						(rowA.original.scoresByDomain?.[activeRefines.domain] ?? 0)) return 1;
				}
			},
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => {
				const vl = value ? ((typeof (value) === "number" ? value : value[activeRefines.domain] ?? 0) * 100).toFixed(0) : 0;
				return <p className="font-semibold">
					{/* Do not show zeroes when the value is null. The null value means we have no data,
					this is why there is nothing to show. */}
					{value === null ? "" : vl}
				</p>;
			}
		},
		{
			Header: "CNCs",
			accessor: "sumNbCnc",
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => value ? <p className="flex items-center justify-center text-medium
			bg-[#FF475C] rounded-full h-7 w-7 text-white font-semibold">{value}</p> : ""
		},
		{
			Header: "Opened NCs",
			accessor: "sumNbOpenedNc",
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => value ? <p className="flex items-center justify-center text-medium
      bg-[#FFC362] rounded-full h-7 w-7 font-semibold">{value}</p> : ""
		}
	];

	shouldReturn = [...base];

	if (clientParameters?.supplyChain) {
		shouldReturn = [
			{
				Header: "Supplier",
				accessor: "supplier"
			},
			{
				Header: "Supplier Code",
				accessor: "supplierCode"
			},
			...shouldReturn
		];

		shouldReturn.splice(3, 0, {
			Header: "Factory Code",
			accessor: "factoryCode"
		});
	}

	if (clientParameters?.statusTracking) {
		shouldReturn = [
			...shouldReturn,
			{
				Header: "End Date",
				accessor: "auditDate",
				Cell: ({ value }) => value ? new Date(value).toLocaleDateString("en") : null
			},
			{
				Header: "Status",
				accessor: "state"
			}
		];
	}

	return shouldReturn;
};

export const sitesPerModulesTableStructure = ({ activeRefines, clientParameters, t }) => {
	let shouldReturn = [];

	const base = clientParameters?.activeIndicators?.map((indicator) => ({
		Header: indicator,
		accessor: indicator,
		sortType: (rowA, rowB) => {
			const a = rowA.values[indicator];
			const b = rowB.values[indicator];

			// Check if both values are numbers
			const aIsNumber = typeof a === "number" && !Number.isNaN(a);
			const bIsNumber = typeof b === "number" && !Number.isNaN(b);

			if (aIsNumber && bIsNumber) {
				// If both values are numbers, sort numerically
				return a - b;
			} if (!aIsNumber && !bIsNumber) {
				// If both values are not numbers, consider them equal
				return 0;
			} if (aIsNumber && !bIsNumber) {
				// If a is a number and b is not, b should come first
				return 1;
			}
			// If b is a number and a is not, a should come first
			return -1;
		},
		Cell: ({ value }) => {
			const vl = typeof (value) === "number" ? (value * 100).toFixed(0) : value;
			// If the score >= 50 it stays black. If there is no score the color is grey.
			const textColor = vl === undefined || vl === "N/A" ? "#bababa" : (vl >= 50 ? "black" : getMarkerColor(vl));
			const textWeight = textColor !== "black" && vl !== "N/A" ? "bold" : "";
			return <p style={{ color: textColor, textAlign: "center", fontWeight: textWeight }}>
				{vl}
			</p>;
		}
	}));

	shouldReturn = [...base];

	shouldReturn = [
		{
			Header: "Name",
			accessor: "auditedCompany",
			Cell: ({ value }) => <p className="font-semibold">
				{value}
			</p>
		},
		...shouldReturn
	];

	return shouldReturn;
};

/**
  *
  * @param {String} tab list
  */
export const termsTabSet = {
	"Legal Notice": {
		en: <LegalNotice />,
		fr: <LegalNotice />,
		es: <LegalNotice />,
		pt: <LegalNotice />
	},
	Terms: {
		en: <Terms />,
		fr: <Terms />,
		es: <Terms />,
		pt: <Terms />
	},
	Privacy: {
		en: <Privacy />,
		fr: <Privacy />,
		es: <Privacy />,
		pt: <Privacy />
	},
	Cookies: {
		en: <Cookies />,
		fr: <Cookies />,
		es: <Cookies />,
		pt: <Cookies />
	}
};

/**
 * Sort options for Net Zero KPI cards
 */
export const defaultNetZeroSortOptions = {
	label: "netZero.KPI.emissionValue",
	attribute: "emissionValue",
	order: -1
};

export const netZeroSortOptions = [
	{
		label: "netZero.KPI.emissionValue",
		value: "emissionValue",
		attribute: "emissionValue",
		order: -1
	}, {
		label: "netZero.KPI.alphabetical",
		value: "emissionSource",
		attribute: "emissionSource",
		order: 1
	}, {
		label: "netZero.KPI.practiceLevel",
		value: "emissionPractice",
		attribute: "emissionPractice",
		order: -1
	}
];

// Get previous quarters
const today = new Date();
const currentQuarter = Math.floor((today.getMonth() / 3) + 1);
const prevQuarters = new Array(currentQuarter - 1).fill(null);
prevQuarters.map((quarter, i) => {
	const start = new Date(today.getFullYear(), (currentQuarter - 1) * 3 - (3 * (i + 1)), 1);
	const end = new Date(today.getFullYear(), start.getMonth() + 3, 0);
	return prevQuarters[i] = {
		key: { quarter: prevQuarters.length - i },
		label: `Q${prevQuarters.length - i} ${today.getFullYear()}`,
		quarterId: prevQuarters.length - i,
		start,
		end
	};
});

/**
 * Options for SimpleSelector on ClarityBVKPIsList
 */
export const clarityKPIsSelectOptions = [
	{
		label: "Today",
		key: "today"
	},
	...prevQuarters,
	{
		label: `FY ${new Date().getFullYear() - 1}`,
		key: { prevYear: 1 }
	},
	{
		label: `FY ${new Date().getFullYear() - 2}`,
		key: { prevYear: 2 }
	}
];

// Color Array for Pie Chart
export const pieColors = ["#CCDB73", "#FFC362", "#5FC3BF", "#BD5C52", "#6F78CF", "#70B3DB", "#D08ADB", "#75DBA7", "#DBA65E", "#A6DB69"];

// add colors to pie dataset
export const addColorsToObj = (arrOfObj, colorArr) => {
	const colors = colorArr.slice(0, arrOfObj.length + 1);
	return arrOfObj.map((obj, i) => {
		const objCopy = obj;
		return { ...objCopy, color: colors[i] };
	});
};

export const monthAnalysisNCLinesColors = [
	{
		label: "high",
		color: "red"
	}, {
		label: "medium",
		color: "orange"
	}, {
		label: "low",
		color: "green"
	}
];

// Hard-coded GSCA test data START
export const GSCAPieChartColors = ["#4dc490", "#ffc362", "#ef8244", "#ef4444"];

export const GSCATestPieData =
	[
		{
			riskCategory: "Low", count: 3
		},
		{
			riskCategory: "Low - Medium", count: 12
		},
		{
			riskCategory: "Medium - High", count: 6
		},
		{
			riskCategory: "High", count: 3
		}
	];

export const GSCATestColorScale =
	[
		{
			label: "high", color: "red"
		},
		{
			label: "medium", color: "orange"
		},
		{
			rlabel: "low", color: "green"
		}
	];

export const GSCATestNonCOnf =
	[
		{
			High: "#ef4444",
			Medium: "#ef8244",
			Low: "#4dc490"
		}
	];

export const GSCATestNonConfDATA =
	[
		{
			month: 7,
			nbCNC: 71,
			nbNC: 441,
			nbSites: 3,
			sites: [11111, 22222, 33333],
			year: 2023
		}
	];
