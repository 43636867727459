import React, {
	useEffect, createRef, useState
} from "react";
import {
	useMap, useMapEvents, LayerGroup, GeoJSON, FeatureGroup, Tooltip, Marker, Popup
} from "react-leaflet";
import { useSelector } from "react-redux";
import MarkerClusterGroup from "react-leaflet-markercluster";
import PropTypes from "prop-types";
import * as L from "leaflet";
import DefaultMarker from "./DefaultMarker";
import getValueNestedObject from "../../utils/GetValueNestedObject";

const getIcon = (pathFillColor) => {
	const svgTemplate = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 16 16" xml:space="preserve">
   <path fill="${pathFillColor}" class="path1" stroke="black" stroke-width= "0.3px"
   d="M8 2.1c1.1 0 2.2 0.5 3 1.3 0.8 0.9 1.3 1.9 1.3 3.1s-0.5 2.5-1.3 3.3l-3
   3.1-3-3.1c-0.8-0.8-1.3-2-1.3-3.3 0-1.2 0.4-2.2 1.3-3.1 0.8-0.8 1.9-1.3 3-1.3z"></path>

</svg>`;

	const icon = L.divIcon({
		className: "marker",
		html: svgTemplate,
		iconSize: [48, 48],
		iconAnchor: [24, 36],
		popupAnchor: [7, -16]
	});
	return icon;
};

const coordinateBuilder = (markerPosition) => {
	const lat = Number(markerPosition.split(",")[0]);
	const lng = Number(markerPosition.split(",")[1]);

	return [lat, lng];
};

const AdminLayer = ({
	geopointField = "geometry.coordinates",
	maxClusterRadius = 15,
	CustomMarker = DefaultMarker,
	scoreAccessor = null,
	countryScoreAccessor = null,
	fieldsTooltip = [],
	getMarkerColor = undefined,
	customTooltip = undefined,
	colorMap = {},
	colorKey = "",
	appSlice,
	dataset = "",
	noClusterScore,
	datasetCountries,
	locales,
	markersFilter = (d) => d,
	markerPosition = ""
}) => {
	const groupRef = createRef();
	const geotagsRef = createRef();
	const map = useMap();
	const markers = useSelector(appSlice.selectDatasets)[dataset]?.data.filter(markersFilter);
	const geoTags = useSelector(appSlice.selectDatasets)?.[datasetCountries]?.data ?? undefined;

	useEffect(() => {
		if (markers) {
			if (groupRef.current && Object.keys(groupRef?.current?.getBounds()).length === 0) {
				return;
			}
			if (markers.length === 1 && !markerPosition) {
				const geopoint = getValueNestedObject(markers[0], geopointField);
				map.setView([Number(geopoint[1]), Number(geopoint[0])], 18);
				return;
			}

			if (groupRef?.current?.getBounds()) {
				map?.fitBounds(groupRef?.current?.getBounds());
			}
		} else if (geoTags?.length) {
			map.fitBounds(geotagsRef?.current?.getBounds());
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [markers, geoTags, groupRef, geotagsRef]);

	// preview marker position on map
	useEffect(() => {
		if (markerPosition) {
			const markerCoordinate = coordinateBuilder(markerPosition);

			map.setView(markerCoordinate, 4);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [markerPosition]);

	useEffect(() => {
		map.on("fullscreenchange", () => {
			if (map.isFullscreen()) {
				console.log("entered fullscreen");
			} else {
				console.log("exited fullscreen");
			}
		});
		map.addControl(new L.Control.Fullscreen({
			position: "topright"
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const createClusterCustomIcon = function (cluster) {
		let clusterScore = 0;
		let nbScores = 0;
		let clusterBool = true;
		let isBool = false;
		let colorMarker = "background-color:grey";
		const count = cluster.getChildCount();
		const childMarkers = cluster.getAllChildMarkers();

		for (let i = 0; i < count; i++) {
			const markerScore = childMarkers?.[i]?.options?.score ?? undefined;

			if (markerScore !== undefined && markerScore !== null) {
				if (typeof markerScore === "boolean") {
					clusterBool = clusterBool && markerScore;
					isBool = true;
				} else { clusterScore += markerScore; }
				nbScores += 1;
			}
		}

		if (nbScores >= 1) {
			colorMarker = `background-color: ${getMarkerColor(clusterScore / nbScores)};`;
		}

		if (isBool) {
			colorMarker = `background-color: ${getMarkerColor(clusterBool ? "Compliant" : "Not Compliant")};`;
		}

		return L.divIcon({
			html: `<div style="${colorMarker}"><span >${count}</span></div>`,
			className: "leaflet-marker-icon marker-cluster marker-cluster-medium leaflet-zoom-animated leaflet-interactive",
			backgroundColor: "red",
			iconSize: L.point(40, 40, true)
		});
	};

	// preview marker position in admin form
	if (markerPosition) {
		const markerCoordinate = coordinateBuilder(markerPosition);
		return <Marker position={markerCoordinate} icon={getIcon(getMarkerColor(0))} />;
	}

	return (
		<LayerGroup >
			{markers && <MarkerClusterGroup
				key={`cluster ${ markers.length}`}
				ref={groupRef}
				iconCreateFunction={!noClusterScore && createClusterCustomIcon}
				showCoverageOnHover={false}
				spiderfyDistanceMultiplier={3}
				maxClusterRadius={maxClusterRadius}
				spiderLegPolylineOptions={{
					weight: 1.5,
					color: "#1BA5D0",
					lineCap: "butt",
					dashArray: "10 10",
					opacity: 0.2
				}}
				chunkedLoading={true}
			>
				{
					markers
					.map((marker, i) => (
						<React.Fragment key={`Marker ${i}`}>
							<CustomMarker
								appSlice={appSlice}
								dataset={dataset}
								marker={marker}
								scoreAccessor={scoreAccessor}
								geopointField={geopointField}
								fieldsTooltip={fieldsTooltip}
								getMarkerColor={getMarkerColor}
								CustomTooltip={customTooltip}
								colorMap={colorMap}
								colorKey={colorKey}
								openPopup={markers.length === 1}
								locales={locales}
							/>
						</React.Fragment>
					))
				}
			</MarkerClusterGroup> }
			<FeatureGroup ref={geotagsRef}>
				{geoTags && geoTags.map((geoTag, i) => (
					<React.Fragment key={geoTag._id}>
						{geoTag && <GeoJSON data={geoTag.geometry}
							style={() => ({
								fillColor: getMarkerColor(countryScoreAccessor(geoTag)),
								weight: 0.3,
								opacity: 1,
								color: "purple",
								dashArray: "3",
								fillOpacity: 0.6
							})}>
							<Tooltip><span className="text-lg">{(countryScoreAccessor(geoTag))?.toFixed(0)}</span></Tooltip>
						</GeoJSON>
						}
					</React.Fragment>
				)
				)}
			</FeatureGroup>

		</LayerGroup>
	);
};

AdminLayer.propTypes = {
	geopointField: PropTypes.string,
	maxClusterRadius: PropTypes.number,
	CustomMarker: PropTypes.func,
	scoreAccessor: PropTypes.func,
	countryScoreAccessor: PropTypes.func,
	fieldsTooltip: PropTypes.array,
	getMarkerColor: PropTypes.func,
	customTooltip: PropTypes.func,
	colorMap: PropTypes.object,
	colorKey: PropTypes.string,
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	datasetCountries: PropTypes.string,
	noClusterScore: PropTypes.bool,
	locales: PropTypes.string,
	markersFilter: PropTypes.func,
	markerPosition: PropTypes.string
};

export default AdminLayer;
