/* eslint-disable max-len */
import React, { useState } from "react";
import PropTypes from "prop-types";
import AssignmentIcon from "@mui/icons-material/Assignment";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

function CallToAction({
	ctaLink = "#", ctaContent, assignementIcon, optionLabelAccessor = "domain", optionUrlAccessor = "urlActionPlan"
}) {
	const [optionsOpened, setOptionsOpened] = useState(false);
	const handleSelectChange = (opt) => {
		if (opt) {
		// Open a new tab with the selected URL
			window.open(opt, "_blank");
		}
		setOptionsOpened(!optionsOpened);
	};

	return (
		<>
			{typeof ctaLink === "string" || (Array.isArray(ctaLink) && ctaLink.length === 1)
				? <a
					href={typeof ctaLink === "string"
						? ctaLink
						: ctaLink[0]?.urlActionPlan} target="_blank" rel="noreferrer"
					className="rounded-xl py-3 px-4 text-center text-sm leading-none cursor-pointer drop-shadow-md bg-[hsla(95,24%,52%,1)] text-white
			hover:scale-105 hover:border-supplyr_primary-accent hover:drop-shadow-xl hover:bg-[hsla(95,24%,45%,1)]
			active:scale-95">
					<div className="flex justify-center items-center">
						<span className="text-white">{ctaContent}</span>
						{assignementIcon &&
				<AssignmentIcon className="ml-2"/>
						}
					</div>
				</a>
				: <div className="z-900">
					<div
						className="rounded-xl py-3 px-4 text-center text-sm leading-none cursor-pointer drop-shadow-md bg-[hsla(95,24%,52%,1)] text-white
						hover:scale-105 hover:border-supplyr_primary-accent hover:drop-shadow-xl hover:bg-[hsla(95,24%,45%,1)]
						active:scale-95
						transition ease-in-out delay-150 hover:-translate-y-[2px] duration-300"
						onClick={() => setOptionsOpened(!optionsOpened)}
					>
						<div className="flex justify-center items-center">
							<span className="text-white">Manage action plans</span>
							<KeyboardArrowDownRoundedIcon className={`${optionsOpened ? "rotate-180" : "rotate-0"}`}/>
						</div>
					</div>
					<div className="">
						{optionsOpened && ctaLink.map((opt, i) => <div
							key={`opt#${i}`}
							className={`${i === 0
								? "mt-1 rounded-t-xl border-b-2 border-b-[hsla(95,24%,85%,1)]"
								: "mt-0 rounded-b-xl border-t-0"}  py-3 px-4
						text-center text-sm leading-none cursor-pointer drop-shadow-md
						bg-white text-black
						hover:text-white hover:drop-shadow-xl hover:bg-[hsla(95,24%,50%,0.8)]`}
							onClick={() => handleSelectChange(opt[optionUrlAccessor])}>{opt[optionLabelAccessor]}</div>)}
					</div>
				</div>
			}
		</>

	);
}
CallToAction.propTypes = {
	ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	ctaContent: PropTypes.string,
	assignementIcon: PropTypes.bool,
	optionLabelAccessor: PropTypes.string,
	optionUrlAccessor: PropTypes.string
};
export default CallToAction;
