import AlphaTooltip from "../tooltips/AlphaTooltip";

export const clarityChainTableStructure = () => [
	{
		Header: "client",
		accessor: "client"
	},
	{
		Header: "projectName",
		accessor: "projectName"
	},
	{
		Header: "spaceLabel",
		accessor: "spaceLabel"
	},
	{
		Header: "active",
		accessor: "inactive",
		Cell: ({ value }) => (value === true ? "No" : "Yes")
	},
	{
		Header: "supplier",
		accessor: "supplier"
	},
	{
		Header: "supplierCode",
		accessor: "supplierCode"
	},
	{
		Header: "company",
		accessor: "company"
	},
	{
		Header: "factoryCode",
		accessor: "factoryCode"
	},
	{
		Header: "nextSupplier",
		accessor: "nextSupplier"
	},
	{
		Header: "siteId",
		accessor: "siteId"
	},
	{
		Header: "siteUniqueId",
		accessor: "siteUniqueId"
	},
	{
		Header: "finalProduct",
		accessor: "finalProduct"
	},
	{
		Header: "targetProduct",
		accessor: "targetProduct"
	},
	{
		Header: "product",
		accessor: "product"
	},
	{
		Header: "tierLevel",
		accessor: "tierLevel"
	},
	{
		Header: "type",
		accessor: "type"
	},
	{
		Header: "country",
		accessor: "country"
	},
	{
		Header: "countryRisk",
		accessor: "countryRisk"
	},
	{
		Header: "city",
		accessor: "city"
	},
	{
		Header: "address",
		accessor: "address"
	},
	{
		Header: "criticality",
		accessor: "criticality"
	},
	{
		Header: "domain",
		accessor: "domain"
	},
	{
		Header: "strategic",
		accessor: "strategic"
	},
	{
		Header: "volume",
		accessor: "volume"
	},
	{
		Header: "supplierRiskWeight",
		accessor: "supplierRiskWeight"
	}
];

export const supplyRChainTableStructure = (clientParams) => {
	let shouldReturn = [];
	const base = [
		{
			Header: "validated",
			accessor: "validated"
		},
		{
			Header: "updateDate",
			accessor: "updateDate",
			Cell: ({ value }) => value.slice(0, 19)
		},
		{
			Header: "projectName",
			accessor: "projectName"
		},
		{
			Header: "client",
			accessor: "client"
		},
		{
			Header: "supplier",
			accessor: "supplier"
		},
		{
			Header: "auditedCompany",
			accessor: "auditedCompany"
		},
		{
			Header: "factoryBranch",
			accessor: "factoryBranch"
		},
		{
			Header: "factoryIndex",
			accessor: "factoryIndex"
		},
		{
			Header: "supplierRiskWeight",
			accessor: "supplierRiskWeight"
		},
		{
			Header: "siteLabelId",
			accessor: "siteLabelId"
		},
		{
			Header: "siteUniqueId",
			accessor: "siteUniqueId"
		},
		{
			Header: "finalProduct",
			accessor: "finalProduct"
		},
		{
			Header: "targetProduct",
			accessor: "targetProduct"
		},
		{
			Header: "product",
			accessor: "product"
		},
		{
			Header: "nextSupplier",
			accessor: "nextSupplier"
		},
		{
			Header: "tierLabel",
			accessor: "tierLabel"
		},
		{
			Header: "tierLevel",
			accessor: "tierLevel"
		},
		{
			Header: "criticality",
			accessor: "criticality"
		},
		{
			Header: "region",
			accessor: "region"
		},
		{
			Header: "country",
			accessor: "country"
		},
		{
			Header: "city",
			accessor: "city"
		},
		{
			Header: "address",
			accessor: "address"
		},
		{
			Header: "countryRisk",
			accessor: "countryRisk"
		},
		{
			Header: "geopointToR",
			accessor: "geopointToR"
		},
		{
			Header: "logisticAgent",
			accessor: "logisticAgent"
		},
		{
			Header: "type",
			accessor: "type"
		},
		{
			Header: "nbModules",
			accessor: "nbModules"
		},
		{
			Header: "PAQid",
			accessor: "PAQid"
		}
	];

	shouldReturn = [...base];
	if (clientParams?.displaySupplierCode) {
		shouldReturn = [
			...shouldReturn.slice(0, 4),
			{
				Header: "supplierCode",
				accessor: "supplierCode"
			},
			...shouldReturn.slice(4)
		];
	}

	if (clientParams?.displayFactoryCode) {
		// if column supplierCode exists
		if (clientParams?.displaySupplierCode) {
			shouldReturn = [
				...shouldReturn.slice(0, 6),
				{
					Header: "factoryCode",
					accessor: "factoryCode"
				},
				...shouldReturn.slice(6)
			];

			return shouldReturn;
		}

		// if column supplierCode not exist
		shouldReturn = [
			...shouldReturn.slice(0, 5),
			{
				Header: "factoryCode",
				accessor: "factoryCode"
			},
			...shouldReturn.slice(5)
		];

		return shouldReturn;
	}

	return shouldReturn;
};

export const ataglanceChainTableStructure = (clientParams) => {
	const { isCommon } = clientParams.supplychainParams;
	return [
		{
			Header: "validated",
			accessor: isCommon ? "supplyR.validated" : "validated"
		},
		{
			Header: "updateDate",
			accessor: "updateDate",
			Cell: ({ value }) => value.slice(0, 19)
		},
		{
			Header: "projectName",
			accessor: "projectName"
		},
		{
			Header: "client",
			accessor: "client"
		},
		{
			Header: "supplier",
			accessor: "supplier"
		},
		{
			Header: "auditedCompany",
			accessor: "auditedCompany"
		},
		{
			Header: "factoryBranch",
			accessor: isCommon ? "supplyR.factoryBranch" : "factoryBranch"
		},
		{
			Header: "factoryIndex",
			accessor: isCommon ? "supplyR.factoryIndex" : "factoryIndex"
		},
		{
			Header: "supplierRiskWeight",
			accessor: "supplierRiskWeight"
		},
		{
			Header: "siteLabelId",
			accessor: "siteLabelId"
		},
		{
			Header: "siteId",
			accessor: "siteId"
		},
		{
			Header: "siteUniqueId",
			accessor: "siteUniqueId"
		},
		{
			Header: "finalProduct",
			accessor: "finalProduct"
		},
		{
			Header: "targetProduct",
			accessor: "targetProduct"
		},
		{
			Header: "product",
			accessor: "product"
		},
		{
			Header: "nextSupplier",
			accessor: "nextSupplier"
		},
		{
			Header: "tierLevel",
			accessor: "tierLevel"
		},
		{
			Header: "criticality",
			accessor: "criticality"
		},
		{
			Header: "region",
			accessor: "region"
		},
		{
			Header: "country",
			accessor: "country"
		},
		{
			Header: "countryRisk",
			accessor: "countryRisk"
		},
		{
			Header: "geopointToR",
			accessor: isCommon ? "supplyR.geopointToR" : "geopointToR"
		},
		{
			Header: "city",
			accessor: "city"
		},
		{
			Header: "address",
			accessor: "address"
		},
		{
			Header: "type",
			accessor: "type"
		},
		{
			Header: "PAQid",
			accessor: isCommon ? "supplyR.PAQid" : "PAQid"
		}
	];
};

export const verkorChainTableStructure = () => [
	{
		Header: "projectName",
		accessor: "projectName"
	},
	{
		Header: "client",
		accessor: "client"
	},
	{
		Header: "supplier",
		accessor: "supplier"
	},
	{
		Header: "auditedCompany",
		accessor: "auditedCompany"
	},
	{
		Header: "targetDate",
		accessor: "targetDate",
		Cell: ({ value }) => value ? new Date(value).toLocaleDateString("fr") : null
	},
	{
		Header: "supplierRiskWeight",
		accessor: "supplierRiskWeight"
	},
	{
		Header: "siteLabelId",
		accessor: "siteLabelId"
	},
	{
		Header: "finalProduct",
		accessor: "finalProduct"
	},
	{
		Header: "targetProduct",
		accessor: "targetProduct"
	},
	{
		Header: "product",
		accessor: "product"
	},
	{
		Header: "nextSupplier",
		accessor: "nextSupplier"
	},
	{
		Header: "tierLabel",
		accessor: "tierLabel"
	},
	{
		Header: "tierLevel",
		accessor: "tierLevel"
	},
	{
		Header: "criticality",
		accessor: "criticality"
	},
	{
		Header: "region",
		accessor: "region"
	},
	{
		Header: "country",
		accessor: "country"
	},
	{
		Header: "countryRisk",
		accessor: "countryRisk"
	},
	{
		Header: "logisticAgent",
		accessor: "logisticAgent"
	},
	{
		Header: "type",
		accessor: "type"
	},
	{
		Header: "nbModules",
		accessor: "nbModules"
	}
];

const defaultColorScale = ["#6CABE6", "#9757c9", "#CF8084", "#dbb564", "#6c7cf7", "#fad390", "#f8c291", "#6a89cc",
	"#82ccdd", "#b8e994", "#f6b93b", "#e55039", "#4a69bd", "#60a3bc", "#78e08f", "#fa983a", "#eb2f06", "#1e3799", "#3c6382",
	"#38ada9", "#e58e26", "#b71540", "#0c2461", "#0a3d62", "#079992", "darksalmon"];

const AppOptionBuilder = ({ app }) => {
	switch (app) {
		case "CLARITY":
			return { abbr: "CL", color: "#CCDB73" };
		case "SUPPLYR":
			return { abbr: "SU", color: "#6eb8d6" };
		case "COMPASS":
			return { abbr: "CO", color: "#f9ca48" };
		case "ATAGLANCE":
			return { abbr: "AG", color: "#20a738" };

		default:
			// randomly choose a color from the list defaultColorScale for a unassigned project
			return { abbr: app.slice(0, 2).toUpperCase(), color: defaultColorScale[Math.floor(Math.random() * defaultColorScale.length)] };
	}
};

const ProjectOptionBuilder = ({ project, index }) => ({ abbr: project.slice(0, 2), color: defaultColorScale[index] });

export const userTableStructure = () => [
	{
		Header: "User",
		accessor: "_id"
	},
	{
		Header: "Application(s)",
		accessor: (d) => d,
		// eslint-disable-next-line react/prop-types
		Cell: ({ value: { ADMIN } }) => {
			const appOptions = Object.keys(ADMIN);

			return (Array.isArray(appOptions) && appOptions.length !== 0)
				?
				<ul className="flex flex-row">
					{appOptions.sort().slice(0, 4).map((item, i) => (
						<AlphaTooltip
							placement="top-start"
							title={
								<div className="flex flex-col items-baseline gap-y-1 p-3 bg-opacity-90 bg-admin_primary-default rounded-xl w-min">
									<span className="font-bold">{item}</span>
								</div>
							}
							key={`app-${item}-${i}`}
						>
							<li className="flex flex-shrink-0 items-center justify-center mb-2 rounded-full w-10 h-10 text-white font-semibold -ml-2"
								style={{ backgroundColor: AppOptionBuilder({ app: item })?.color }}>
								{AppOptionBuilder({ app: item })?.abbr}
							</li>
						</AlphaTooltip>
					))}
					{appOptions.length > 4 && (
						<li className="flex flex-shrink-0 items-center justify-center mb-2 bg-admin_primary-default
							           rounded-full w-10 h-10 text-white font-semibold -ml-2"
						>
								+{appOptions.length - 4}
						</li>
					)}
				</ul>
				: "";
		}
	},
	{
		Header: "Project(s)",
		accessor: (d) => d,
		// eslint-disable-next-line react/prop-types
		Cell: ({ value: { ADMIN } }) => {
			const projectOptions = Object.values(ADMIN)?.flatMap((item) => Object.keys(item));

			return (Array.isArray(projectOptions) && projectOptions.length !== 0)
				? <ul className="flex flex-row">
					{projectOptions.sort().slice(0, 4).map((item, i) => (

						<AlphaTooltip
							placement="top-start"
							title={
								<div className="flex flex-col items-baseline gap-y-1 p-3 bg-opacity-90 bg-admin_primary-default rounded-xl w-min">
									<span className="font-bold">{item}</span>
								</div>
							}
							key={`app-${item}-${i}`}
						>
							<li className="flex flex-shrink-0 items-center justify-center mb-2 rounded-full w-10 h-10 text-white font-semibold -ml-2"
								style={{ backgroundColor: ProjectOptionBuilder({ project: item, index: i })?.color }}>
								{ProjectOptionBuilder({ project: item, index: i })?.abbr}
							</li>
						</AlphaTooltip>
					))}
					{projectOptions.length > 4 && (
						<li className="flex flex-shrink-0 items-center justify-center mb-2 bg-admin_primary-default
							           rounded-full w-10 h-10 text-white font-semibold -ml-2"
						>
								+{projectOptions.length - 4}
						</li>
					)}
				</ul>
				: "";
		}
	}
];

export const appScopeTableStructure = () => [
	{
		Header: "Application",
		accessor: "_id"
	},
	{
		Header: "Project(s)",
		accessor: (d) => d,
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => {
			const projectOptions = Object.keys(value).filter((item) => item !== "_id");

			return (Array.isArray(projectOptions) && projectOptions.length !== 0)
				? <ul className="flex flex-row">
					{projectOptions.sort().slice(0, 10).map((item, i) => (

						<AlphaTooltip
							placement="top-start"
							title={
								<div className="flex flex-col items-baseline gap-y-1 p-3 bg-opacity-90 bg-admin_primary-default rounded-xl w-min">
									<span className="font-bold">{item}</span>
								</div>
							}
							key={`app-${item}-${i}`}
						>
							<li className="flex flex-shrink-0 items-center justify-center mb-2 rounded-full w-10 h-10 text-white font-semibold -ml-2"
								style={{ backgroundColor: ProjectOptionBuilder({ project: item, index: i })?.color }}>
								{ProjectOptionBuilder({ project: item, index: i })?.abbr}
							</li>
						</AlphaTooltip>
					))}
					{projectOptions.length > 10 && (
						<li className="flex flex-shrink-0 items-center justify-center mb-2 bg-admin_primary-default
							           rounded-full w-10 h-10 text-white font-semibold -ml-2"
						>
								+{projectOptions.length - 10}
						</li>
					)}
				</ul>
				: "";
		}
	}
];

export const requestTableStructure = () => [
	{
		Header: "Request Number",
		accessor: "requestNumber"
	},
	{
		Header: "Creation Date",
		accessor: "creationDate"
	},
	{
		Header: "Due Date",
		accessor: "dueDate"
	},
	{
		Header: "Client",
		accessor: "client"
	},
	{
		Header: "Status",
		accessor: "status"
	}
];

export const domainsTableStructure = () => [
	{
		Header: "Name",
		accessor: "name"
	},
	{
		Header: "Color",
		accessor: "domainColor"
	},
	{
		Header: "Logo",
		accessor: "domainLogo"
	}
];

export const clarityV4ReferentialTableStructure = () => [
	{
		Header: "Source",
		accessor: "Source"
	},
	{
		Header: "Module",
		accessor: "Module"
	},
	{
		Header: "ModuleIndex",
		accessor: "ModuleIndex"
	},
	{
		Header: "Column1",
		accessor: "Column1"
	},
	{
		Header: "Version",
		accessor: "Version"
	},
	{
		Header: "Section Level 1",
		accessor: "Section Level 1"
	},
	{
		Header: "Section1Index",
		accessor: "Section1Index"
	},
	{
		Header: "Section Level 2",
		accessor: "Section Level 2"
	},
	{
		Header: "Section2Index",
		accessor: "Section2Index"
	},
	{
		Header: "Section Level 3",
		accessor: "Section Level 3"
	},
	{
		Header: "Section3Index",
		accessor: "Section3Index"
	},
	{
		Header: "Domain",
		accessor: "Domain"
	},
	{
		Header: "Nb Section",
		accessor: "Nb Section"
	},
	{
		Header: "List of SDGs",
		accessor: "List of SDGs"
	}
];

export const adminTranslationsTableStructure = () => [
	{
		Header: "Language",
		accessor: "_id"
	},
	{
		Header: "Last updated",
		accessor: "updatedAt"
	}
];
