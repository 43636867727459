import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import ArrayFieldEditor from "./ArrayFieldEditor";
import ObjectEditor from "./ObjectEditor";
import ObjectGroupEditor from "./ObjectGroupEditor";
import FieldTransformerController from "./FieldTransformerController";
import SelectFieldEditor from "./AdminSupplierSelectFieldEditor";
import CalendarController from "./CalendarController";
import CheckboxController from "./CheckboxController";
import AsyncSelectFieldEditor from "./AdminSupplierAsyncSelect";
import UpdateFormBtnGroup from "../button/UpdateFormBtnGroup";
import Popconfirm from "../modal/Popconfirm";
import SimpleSelectFieldEditor from "./SelectFieldEditor";

import IF from "../../utils/IF";

import {
	appDefinition, typeSelector, fieldAttributeBuilder, searchSelector, setValueKeyBuilder,
	currentFieldOptions
} from "../../apps/configs/AdminConfig";

import {
	RemoveKey, searchOptionBuilder, FieldNameAccessor
} from "./FormUtils";

import { clarityV4ReferentialDeleteEntry } from "./AdminFormOnConfirm";

const ClarityV4ReferentialForm = ({
	appSlice,
	clearRefineKeys,
	renderButtonGroup = true,
	screenHeight,
	onConfirm,
	supplychainView,
	setSupplychainView,
	setMarkerPosition,
	disabledFields = [],
	requiredFields,
	alertFields,
	alertText,
	setAlert,
	mode,
	onSuccess
}) => {
	const dispatch = useDispatch();

	const { t } = useTranslation(appDefinition.locales);

	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	// The form is used for both creating and updating the entry. If the mode is "create" we will use the empty form
	// (the targetDocument will be empty). If the mode is "update" we will use the pre-filled form (targetDocument
	// will have the data inside).
	const getTargetDocument = (mode) => {
		if (mode === "create") {
			return {
				Source: "",
				Module: "",
				ModuleIndex: "",
				Column1: "",
				Version: "",
				"Section Level 1": "",
				Section1Index: "",
				"Section Level 2": "",
				Section2Index: "",
				"Section Level 3": "",
				Section3Index: "",
				Domain: "",
				"List of SDGs": "",
				"Nb Section": ""
			};
		}
		return activeRefines?.clarityV4RefEntry;
	};

	const targetDocument = getTargetDocument(mode);

	const [rollBack, setRollBack] = useState(false);
	const [confirmModal, setconfirmModal] = useState(false);
	const [confirmModalFactoryRes, setConfirmModalFactoryRes] = useState(false);

	// on click to enable specific field (siteLabelId) which is disabled by default
	const [enableField, setEnableField] = useState({ siteLabelId: false, siteId: false, siteUniqueId: false });

	const allFieldsOfCurrentCollection = [...new Set(activeDatasets?.clarityV4Referential.data.flatMap((el) => Object.keys(el)))];

	// all the fields in key type pair
	const AllKeyTypePair = Object.entries(
		Object.fromEntries(
			new Map(activeDatasets?.clarityV4Referential.data.flatMap((el) => Object.entries(el))))
	)
	?.reduce((acc, cur) => ({ ...acc, [cur[0]]: typeSelector(cur, activeRefines) }), {});

	// all key in target document in update mode
	// transform all flat array to array of object with key k
	const targetDocumentWithK = targetDocument && Object.keys(targetDocument)?.reduce((acc, cur) => {
		let objWithK;
		if (Array.isArray(targetDocument[cur])) { objWithK = targetDocument[cur].map((el) => ({ k: el })); return { ...acc, [cur]: objWithK }; }

		return { ...acc, [cur]: targetDocument[cur] };
	}, {});

	const {
		control, register, handleSubmit, formState: { errors }, reset, setValue, getValues, watch
	} = useForm({
		defaultValues: targetDocumentWithK,
		criteriaMode: "firstError",
		shouldFocusError: true
	});

	// select on sidebar and refresh form content
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => reset(targetDocumentWithK), [activeRefines.fourthLevelMenu]);

	useEffect(() => {
		if (rollBack) {
			setRollBack(false);
			reset(targetDocument);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rollBack]);

	const onSubmit = (e) => {
		e.preventDefault();
		setconfirmModal(true);
	};

	// remove id in case error rewrite _id in mongoDb
	const submit = (submitValue) => {
		const res = RemoveKey(submitValue);

		const resWithoutId = { ...res };

		// remove key in avoid overwriting existing
		// ["_id", "key", "updateDate"].forEach((e) => delete resWithoutId[e]);
		// Update the "key" field automatically because it contains the fields already present in the form

		// remove key in avoid overwriting existing
		["_id"].forEach((e) => delete resWithoutId[e]);

		onConfirm({
			res,
			resWithoutId,
			setconfirmModal
		});
	};

	// prevent enter event from submitting form
	const checkKeyDown = (e) => {
		if (e.key === "Enter") e.preventDefault();
	};

	return (
		<>
			{
				confirmModal && (
					<Popconfirm
						title={`${mode.slice(0, 1).toUpperCase() + mode.slice(1, 6)} referential`}
						description={`Are you sure you want ${mode.slice(0, 6)} ${mode === "update" ? "this" : "a"} referential?`}
						confirmBtnText="Confirm"
						onClickConfirm={handleSubmit(submit)}
						onClickCancel={() => setconfirmModal(false)}
					/>
				)
			}
			{confirmModal &&
			// If update or create has been successful, we can execute onSuccess method
			// to run the script for ClarityV4Referential formating
				onSuccess()
			}
			{
				confirmModalFactoryRes && (
					<Popconfirm
						title="Something went wrong"
						description={`No result for your search! 
						NOTE: Please aware that siteLabelId will be rfieldAttributeBuildereassgined and the values of some relavant 
						fields might not be matching in current form.`}
						onClickCancel={() => setConfirmModalFactoryRes(false)}
						showConfirm={false}
						iconsType="exclamationMark"
					/>
				)
			}
			<UpdateFormBtnGroup appSlice={appSlice} locales={appDefinition.locales} renderButtonGroup={renderButtonGroup}
				defaultValue={targetDocumentWithK} reset={reset} clearRefineKeys={clearRefineKeys} supplychainView={supplychainView}
				setSupplychainView={setSupplychainView} setMarkerPosition={setMarkerPosition} renderDeleteBtn={false}
				onDelete={clarityV4ReferentialDeleteEntry} setAlert={setAlert} />

			<form
				className="flex flex-col px-2 my-4 space-y-6 overflow-y-auto"
				style={{ height: screenHeight - 260 }}
				onSubmit={onSubmit}
				onKeyDown={(e) => checkKeyDown(e)}
			>
				{allFieldsOfCurrentCollection
				.filter((item) => !fieldAttributeBuilder(activeRefines.secLevelMenu, "hiddenFields")?.includes(item))
				.map((field, i) => (
					<div key={`field-${nanoid()}-${i}`}>
						<IF condition={AllKeyTypePair[field] === "checkbox"}>
							<label key={field + i} className={`relative w-1/3 flex font-medium gap-y-1 mt-4 capitalize
                            text-admin_primary-default border px-2 py-3 rounded-lg items-center`}>
								<span className=" bg-white uppercase text-sm">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<CheckboxController {...{ control, name: `${field}`, className: "ml-4" }} />
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "array"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                            text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<ArrayFieldEditor
									control={control}
									field={field}
									register={register}
									requiredFields={requiredFields}
									activeRefines={activeRefines}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "object"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                            text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<ObjectEditor
									control={control}
									field={field}
									register={register}
									data={targetDocument?.[`${field}`]}
									requiredFields={requiredFields}
									activeRefines={activeRefines}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "objectGroup"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								{targetDocument?.[`${field}`] &&
								Object.entries(targetDocument?.[`${field}`]).map(([subField, fieldValue]) => (
									<ObjectGroupEditor
										key={nanoid()}
										field={`${field}.${subField}`}
										fieldValue={fieldValue}
										control={control}
										register={register}
										activeRefines={activeRefines}
										appSlice={appSlice}
										getValues={getValues}
										setValue={setValue}
										requiredFields={requiredFields}
										errors={errors}
										targetDocument={targetDocument}
									/>
								))}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "search"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<SelectFieldEditor
									field={field}
									control={control}
									appSlice={appSlice}
									options={searchOptionBuilder(activeRefines, field, searchSelector, activeDatasets)}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem 0",
											borderRadius: "0.5rem"
										})
									}}
									requiredFields={requiredFields}
									disabledFields={disabledFields}
									activeRefines={activeRefines}
									placeholder={getValues(field)}
									setValue={setValue}
									getValues={getValues}
									activeDatasets={activeDatasets}
									reset={reset}
									setMarkerPosition={setMarkerPosition}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
								{alertFields?.includes(field)
										&& <span className="text-red-600 text-small uppercase">
											{alertText(field, activeRefines)}
										</span>}
							</label>
						</IF>
						<IF condition={AllKeyTypePair[field] === "asyncsearch"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<AsyncSelectFieldEditor
									field={field}
									control={control}
									appSlice={appSlice}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem 0",
											borderRadius: "0.5rem"
										})
									}}
									requiredFields={requiredFields}
									disabledFields={disabledFields}
									setValue={setValue}
									setValueKey={(d) => setValueKeyBuilder(d, activeDatasets, activeRefines)}
									setMarkerPosition={setMarkerPosition}
									placeholder={getValues(field)}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "number"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                 text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								{/* transform str to num */}
								<FieldTransformerController
									transform={{
										input: (value) => Number.isNaN(value) ? "" : value?.toString(),
										output: (e) => {
											const output = parseInt(e.target.value, 10);
											return Number.isNaN(output) ? 0 : output;
										}
									}}
									control={control}
									register={register}
									requiredFields={requiredFields}
									disabledFields={disabledFields}
									name={`${field}`}
									defaultValue=""
									placeholder="enter a number"
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "date"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<CalendarController control={control} name={`${field}`} />
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>
						<IF condition={AllKeyTypePair[field] === "select"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                    text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<SimpleSelectFieldEditor
									field={field}
									control={control}
									appSlice={appSlice}
									options={currentFieldOptions(field)}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem 0",
											borderRadius: "0.5rem"
										})
									}}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>
						<IF condition={
							AllKeyTypePair[field] !== "array"
								&& AllKeyTypePair[field] !== "object"
								&& AllKeyTypePair[field] !== "objectGroup"
								&& AllKeyTypePair[field] !== "select"
								&& AllKeyTypePair[field] !== "search"
								&& AllKeyTypePair[field] !== "asyncsearch"
								&& AllKeyTypePair[field] !== "number"
								&& AllKeyTypePair[field] !== "checkbox"
								&& AllKeyTypePair[field] !== "date"
						}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                    text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
									{
										Object.keys(enableField).includes(field)
										&& <BorderColorIcon className="text-lg mx-1.5 hover:cursor-pointer"
											onClick={() => setEnableField({ ...enableField, [field]: !enableField[field] })}/>
									}
								</span>

								<input {...register(`${field}`, { required: requiredFields.includes(field) })}
									disabled={enableField[field]
										? [...disabledFields].filter((f) => f !== field).includes(field)
										: disabledFields.includes(field)}
									autoComplete="off"
									className={`p-2 my-2 text-black border rounded-lg focus:outline-none focus:ring-2 
										focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base
										${(enableField[field] ? [...disabledFields].filter((f) => f !== field).includes(field)
										: disabledFields.includes(field)) ? "bg-gray-200" : "bg-white"}`}
								/>
								{alertFields?.includes(field)
										&& <span className="text-red-600 text-small uppercase">
											{alertText(field, activeRefines)}
										</span>}

								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

					</div>
				))}

				<div className="flex flex-col space-y-4 mt-10">
					<button className="border bg-admin_primary-default hover:bg-admin_primary-dark
                    text-white font-bold py-1 px-3 rounded uppercase" type="submit">
						<DoneAllIcon />
					</button>
				</div>

			</form>
		</>
	);
};

ClarityV4ReferentialForm.propTypes = {
	appSlice: PropTypes.object,
	clearRefineKeys: PropTypes.array,
	data: PropTypes.array.isRequired,
	renderButtonGroup: PropTypes.bool,
	screenHeight: PropTypes.number,
	targetDocAccessor: PropTypes.func,
	onConfirm: PropTypes.func,
	setMarkerPosition: PropTypes.func,
	supplychainView: PropTypes.bool,
	setSupplychainView: PropTypes.func,
	disabledFields: PropTypes.array,
	requiredFields: PropTypes.array,
	alertFields: PropTypes.array,
	alertText: PropTypes.string,
	setAlert: PropTypes.func,
	mode: PropTypes.string,
	onSuccess: PropTypes.func
};

export default ClarityV4ReferentialForm;
