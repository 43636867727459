import { useEffect } from "react";

// TODO: comment the next two import when local development is no longer needed
// Launcher / Authenticator / App / UserProfile / package.json @aws-amplify/ui-react / .env
import {
	AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignUp, AmplifySignIn
} from "@aws-amplify/ui-react";

import Div100vh from "react-div-100vh";

import awsConfig from "../aws-exports";

import "../styles/auth.css";

export const oAuthScope = "openid";

const IDPHRef = `https://
${process.env.REACT_APP_AMPLIFY_OAUTH_DOMAIN ? process.env.REACT_APP_AMPLIFY_OAUTH_DOMAIN : process.env.REACT_APP_OAUTH_DOMAIN}
/oauth2/authorize?
&redirect_uri=${window.location.href.split(/[?#]/)[0]}
&response_type=TOKEN
&client_id=${awsConfig.aws_user_pools_web_client_id}
&scope=${oAuthScope}`;

export function openSaml() {
	window.location.assign(IDPHRef);
}

function Authenticator() {
	const onlySAML = process.env.REACT_APP_AMPLIFY_ONLY_SAML ? process.env.REACT_APP_AMPLIFY_ONLY_SAML : process.env.REACT_APP_ONLY_SAML;

	useEffect(() => {
		if (onlySAML === "True") {
			window.location.href = IDPHRef;
		}
	}, [onlySAML]);

	// TODO: comment the return two import when local development is no longer needed
	// Launcher / Authenticator / App / UserProfile / package.json @aws-amplify/ui-react
	return ((onlySAML !== "True" &&
		<AmplifyAuthContainer>
			<AmplifyAuthenticator usernameAlias="email">

				<Div100vh slot="sign-in" className="relative w-full bg-gray-100">
					<div className="flex flex-col flex-grow justify-between h-full w-full bg-top bg-cover bg-no-repeat bg-launcher">
						<header></header>
						<main className="relative flex justify-center items-center h-full w-full bg-top bg-cover bg-no-repeat bg-launcher">
							<AmplifySignIn headerText="" slot="sign-in" usernameAlias="email"
								className="rounded-2xl alphaBlur">
								<div slot="header-subtitle" className="flex flex-col justify-center items-center">
									<img src="/images/CompanyLogos/BVLogo.svg" width="80px" className="pb-1" />
									<p className="text-2xl font-semibold text-black">Log in</p>
								</div>
							</AmplifySignIn>
						</main>
						<footer className="self-center p-4">
							<p className="text-white font-light text-sm">© 2021 Bureau Veritas All rights reserved</p>
						</footer>
					</div>
				</Div100vh>
			</AmplifyAuthenticator>
		</AmplifyAuthContainer>
	));
}

export default Authenticator;
