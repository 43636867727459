/* eslint-disable react/prop-types */
import React from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const defaultCustomStyles = (refineValue) => ({
	control: (provided, state) => ({
		...provided,
		width: provided.width,
		minWidth: 220
		// backgroundColor: refineValue !== undefined ? "#1876bd" : provided.backgroundColor
	}),

	singleValue: (provided, state) => ({
		...provided,
		color: refineValue !== undefined ? "white" : provided.backgroundColor
	}),

	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#1876bd" : provided.backgroundColor
	}),

	menu: (provided, state) => ({
		...provided,
		zIndex: 2000,
		minWidth: 160
	}),

	indicatorSeparator: (provided) => ({
		...provided
	}),

	loadingIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "0px",
		paddingRight: "0px"
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		color: refineValue !== undefined ? "white" : provided.backgroundColor,
		paddingLeft: "6px",
		paddingRight: "6px"
	})
});

/**
 * Style for the filters
 */
export const defaultCleanCustomStyles = (refineValue, placeHolder, color) => ({
	control: (provided, state) => ({
		...provided,
		width: provided.width,
		minWidth: 220,
		borderRadius: "0.8rem"
	}),

	singleValue: (provided, state) => ({
		...provided,
		width: "100%",
		textAlign: "center",
		fontWeight: refineValue !== undefined ? "bold" : "normal",
		color: "black",
		maxWidth: 300,
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		marginLeft: "0px",
		marginRight: "0px",
		paddingLeft: "0px",
		paddingRight: "0px"
	}),

	option: (provided, state) => ({
		...provided,
		borderRadius: "0.8rem",
		backgroundColor: state.isFocused ? "#F6F6F6" : "white",
		fontWeight: "normal",
		color: state.isSelected ? color : provided.color
	}),

	menu: (provided, state) => ({
		...provided,
		zIndex: 200,
		width: "200px",
		borderRadius: "0.8rem",
		padding: "10px"
	}),

	menuList: (provided) => ({
		...provided,
		"::-webkit-scrollbar": {
			display: "none"
		}
	}),

	indicatorSeparator: (provided) => ({
		...provided
	}),

	placeholder: (provided, state) => ({
		...provided,
		width: "100%",
		textAlign: "center",
		fontWeight: "normal",
		color: refineValue !== undefined ? "black" : provided.color,
		marginLeft: "0px",
		marginRight: "0px",
		paddingLeft: "0px",
		paddingRight: "0px"
	}),

	loadingIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "0px",
		paddingRight: "0px"
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		paddingLeft: "6px",
		paddingRight: "6px"
	})

});

const SimpleSelector = ({
	options, textClasses = undefined, className, locales, placeHolder, cleanStyle = false, styles, onChange, defaultValue
}) => {
	const dispatch = useDispatch();

	const { t } = useTranslation(locales);

	const formatOptionLabel = (option) => (
		<div className={"rounded-lg -m-2 p-2 cursor-pointer text-sm"}>
			{t(`${option.label}`)}
		</div>
	);

	const placeholderComponent = (
		<div className={`flex items-center w-full justify-center ${textClasses}` }>
			<div className="flex items-center gap-x-1">
				<p>{t(`${placeHolder}`)}</p>
			</div>
		</div>
	);

	const LoadingIndicator = () => (
		<svg className="animate-spin -ml-3 mr-1 h-3 w-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
			<circle className="opacity-25" cx="" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
			<path className="opacity-75" fill="black"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
		</svg>
	);

	return (
		<Select
			className={className}
			options={options}
			components={(cleanStyle ? {
				DropdownIndicator: () => null,
				IndicatorSeparator: () => null,
				LoadingIndicator
			} : { LoadingIndicator })
			}
			styles={defaultCleanCustomStyles("Average Supplier Finding Ratio")}
			autosize={true}
			onChange={onChange}
			blurInputOnSelect={true}
			menuShouldScrollIntoView={true}
			placeholder={placeholderComponent}
			formatOptionLabel={formatOptionLabel}
			defaultValue={defaultValue}
		/>
	);
};

SimpleSelector.propTypes = {
	className: PropTypes.string,
	cleanStyle: PropTypes.bool,
	locales: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	placeHolder: PropTypes.string,
	styles: PropTypes.object,
	textClasses: PropTypes.string,
	defaultValue: PropTypes.object
};

export default SimpleSelector;
