import PropTypes from "prop-types";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as d3 from "d3";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RestoreIcon from "@mui/icons-material/Restore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { TORCollection } from "../../apps/configs/AdminConfig";
import { deleteManyDocs } from "../../data/slices/createAdminSlice";
import AlphaTooltip from "../tooltips/AlphaTooltip";

const UpdateFormBtnGroup = ({
	appSlice,
	locales,
	defaultValue,
	reset,
	renderButtonGroup,
	clearRefineKeys,
	supplychainView,
	setSupplychainView,
	setMarkerPosition,
	renderRollBackBtn = true,
	renderDeleteBtn = false,
	deleteFunction = () => console.log("delete"),
	deleteFuncProps = {},
	onDelete,
	setAlert
}) => {
	const { t } = useTranslation(locales);

	const [, forceUpdate] = useState();

	const dispatch = useDispatch();

	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const supplyChainData = useSelector(appSlice.selectDatasets)?.[`${activeRefines.secLevelMenu}SupplyChainTree`];

	return (
		renderButtonGroup && (
			<div className="flex flex-row justify-start space-x-1 border-b border-admin_primary-default pb-2">
				<AlphaTooltip
					placement="top-start"
					title={
						<div
							className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90
                          bg-admin_primary-default rounded-2xl w-48"
						>
							<span className="font-bold">{t("tooltip.back.title")}</span>
							{t("tooltip.back.description")}
						</div>
					}
				>
					<button
						type="button"
						className="border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold py-1 px-3 rounded uppercase"
						onClick={() => {
							dispatch(appSlice.actions.clear(clearRefineKeys));
							if (setMarkerPosition) { setMarkerPosition(""); }
						}}
					>
						<ArrowBackIcon />
					</button>
				</AlphaTooltip>

				{renderRollBackBtn
				&& <AlphaTooltip
					placement="right-end"
					title={
						<div
							className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90
                          bg-admin_primary-default rounded-2xl w-48"
						>
							<span className="font-bold">{t("tooltip.rollback.title")}</span>
							{t("tooltip.rollback.description")}
						</div>
					}
				>
					<button
						type="button"
						className="border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold py-1 px-3 rounded uppercase"
						onClick={() => reset(defaultValue)}
					>
						<RestoreIcon />
					</button>
				</AlphaTooltip>}

				{renderDeleteBtn
				&& <AlphaTooltip
					placement="top-start"
					title={
						<div
							className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90
                          bg-admin_primary-default rounded-2xl w-48"
						>
							<span className="font-bold">{t("tooltip.delete.title")}</span>
							{t("tooltip.delete.description")}
						</div>
					}
				>
					<button
						type="button"
						className="border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold py-1 px-3 rounded uppercase"
						onClick={() => {
							// nodes that should be remove including the current node
							const deleteNodes = d3.hierarchy(supplyChainData?.data)
							.find((node) => node?.data?._id === activeRefines?.site)
							.descendants()
							.map((node) => node?.data?._id);

							if (window.confirm("Delete this doc?")) {
								onDelete({
									appSlice,
									dispatch,
									deleteManyDocs,
									TORCollection,
									activeRefines,
									deleteNodes,
									forceUpdate,
									setAlert,
									supplychainView
								});
							}
						}}
					>
						<DeleteForeverIcon />
					</button>
				</AlphaTooltip>}
			</div>
		)
	);
};

UpdateFormBtnGroup.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	clearRefineKeys: PropTypes.array,
	renderButtonGroup: PropTypes.bool,
	reset: PropTypes.func,
	defaultValue: PropTypes.object,
	supplychainView: PropTypes.bool,
	setSupplychainView: PropTypes.func,
	setMarkerPosition: PropTypes.func,
	renderRollBackBtn: PropTypes.bool,
	renderDeleteBtn: PropTypes.bool,
	deleteFunction: PropTypes.func,
	deleteFuncProps: PropTypes.object,
	onDelete: PropTypes.func,
	setAlert: PropTypes.func
};

export default UpdateFormBtnGroup;
