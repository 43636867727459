function getValueNestedObject(object, path) {
	let current = object;

	if (!path || path.length === 0 || !current) {
		return current;
	}

	const array = path.split(".");

	for (let i = 0; i < array.length; i++) {
		current = current && current?.[array[i]] !== null ? current[array[i]] : "";
	}
	return current;
}

export default getValueNestedObject;
