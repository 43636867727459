import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const SupplyRInfoPageHeader = ({
	page, Picto, locales, children
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<div className="flex justify-center items-center w-full pt-4 pb-12 pl-4 md:pl-16 pr-12 h-80
        bg-gradient-to-r from-supplyr_primary-gradientL via-supplyr_primary-gradientV to-supplyr_primary-gradientR">

			<div className="flex md:justify-between items-center w-full h-full py-4">
				<div className="flex flex-grow items-center justify-between">
					<div className="relative flex flex-col z-10 mt-20 w-full">
						<div className="absolute -top-16 -left-12 opacity-60
                                    text-white" style={{ zIndex: -1 }}>
							<Picto style={{ fontSize: 240 }} />
						</div>
						<p className="font-semibold text-3xl md:text-5xl text-white mt-20">{t(`${page}.header.title`)}</p>
						<p className="pt-3 font-light text-white text-sm md:text-base">{t(`${page}.header.subTitle`)}</p>
					</div>
				</div>
				{children &&
                    <div className="hidden md:block self-end rounded-xl px-6 w-full">
                    	{children}
                    </div>
				}
			</div>
		</div>
	);
};

SupplyRInfoPageHeader.propTypes = {
	page: PropTypes.string,
	Picto: PropTypes.object,
	locales: PropTypes.string,
	children: PropTypes.object
};

export default SupplyRInfoPageHeader;
