import * as d3 from "d3";

// There are cases where there is no data for a specific month, this is why the month may be missing but we
// do not want to have gaps on the graph. For example, sometimes we may have Oct-Nov-Dec-Jan and sometimes Oct-Dec-Jan.
// If this is the case we'd add Nov to the array.
const addMissingMonths = (arr1, arr2) => {
	const res = [];
	arr1.forEach((el) => {
		const found = arr2.find((item) => (el.year === item.year && el.month === item.month));
		if (!found) {
			res.push(el);
		}
	});
	return res;
};

export default function prepareTargetData(targetData, dataToAlignWith, actionPlanData) {
	// Months sequence, this is our time span for the graph (from - to)
	// January is 0, December is 11
	const start = new Date(dataToAlignWith[0].year, dataToAlignWith[0].month - 1);
	// The end date is actually the "month + 1" because the sequence "d3.timeMonths" calculates "month -1" as the end date.
	// const end = new Date(targetData[targetData.length - 1].year, targetData[targetData.length - 1].month);
	// If there is no "target date" calculate it as follows: "publication date (start)" + 30 days.
	const end = new Date((actionPlanData[0]?.actionPlanTargetDate ? actionPlanData[0].actionPlanTargetDate :
		new Date(start.getTime() + 32 * 24 * 60 * 60 * 1000).toString()));

	// const seq = d3.timeMonths(start, end, 1).map((el) => (`${el.toString().substring(4, 7) }-${ el.toString().substring(13, 15)}`));
	const seq = d3.timeMonths(start, end, 1);
	const monthYearKeys = seq.map((el) => ({ month: el.getMonth() + 1, year: parseInt(el.toString().substring(11, 15), 10) }));

	const extendedData = [...targetData, ...addMissingMonths(monthYearKeys, targetData)];

	const sortedExtData = extendedData
	.sort((dotA, dotB) => ((dotA.year > dotB.year) ? 1 : ((dotA.year < dotB.year) ? -1 : ((dotA.month > dotB.month) ? 1 : -1))));

	// Remove the dates that are younger than the one present on index 0 of dataToAlignWith
	// (the collection of nonConformities) so that testData starts from the same date as nonConformities.
	const startElem = sortedExtData.find((el) => el.year === dataToAlignWith[0].year && el.month === dataToAlignWith[0].month);
	const startIndex = sortedExtData.indexOf(startElem);
	const abridgedData = sortedExtData.slice(startIndex, extendedData.length);

	// Calculate the non-conformities before the publication date (i.e. those "closed" and "supposed to be closed"
	// (depending on collection you pass to this method) before the publication date).
	// We will use it for calculating the first (initial) value for the graph line
	let allNCsBeforePublicationDate = sortedExtData.slice(0, startIndex).reduce((acc, currentValue, currentIndex) => {
		if (currentIndex === 0) {
			// initialize variables with zeros to avoid arithmetic operations with undefined values further in the code.
			acc = Object.entries(dataToAlignWith[0]).reduce((acc, curr) => {
				acc[curr[0]] = typeof curr[1] === "object" ?
					Object.entries(curr[1]).reduce((ac, cur) => {
						ac[cur[0]] = 0;
						return ac;
					}, {}) : 0;
				return acc;
			}, {});

			acc.nonCriticalNC = 0;
			acc.criticalNC = 0;
		}

		const nC = currentValue.nbNC - currentValue.nbCNC;
		const cNc = currentValue.nbCNC;

		acc.nonCriticalNC += nC;
		acc.criticalNC += cNc;

		// calculate (sum) non-critcal tooltips values
		Object.entries(acc.tooltipNCStatus).forEach((item) => {
			const propName = item[0];
			if (currentValue.tooltipNCStatus[propName]) {
				acc.tooltipNCStatus[propName] += currentValue.tooltipNCStatus[propName];
			}
		});

		// calculate (sum) critcal tooltips values
		Object.entries(acc.tooltipCNCStatus).forEach((item) => {
			const propName = item[0];
			if (currentValue.tooltipCNCStatus[propName]) {
				acc.tooltipCNCStatus[propName] += currentValue.tooltipCNCStatus[propName];
			}
		});

		return acc;
	}, {});

	if (Object.keys(allNCsBeforePublicationDate).length === 0) {
		// if the object (it is possible that there was nothing with target or closure dates
		// before the publication date) is empty just initialize it with zero values
		// to prevent crashes if we try to access the unexisting properties.
		allNCsBeforePublicationDate = Object.entries(dataToAlignWith[0]).reduce((acc, curr) => {
			acc[curr[0]] = typeof curr[1] === "object" ?
				Object.entries(curr[1]).reduce((ac, cur) => {
					ac[cur[0]] = 0;
					return ac;
				}, {}) : 0;
			return acc;
		}, {});
		allNCsBeforePublicationDate.nonCriticalNC = 0;
		allNCsBeforePublicationDate.criticalNC = 0;
	}

	abridgedData.push(allNCsBeforePublicationDate);

	return abridgedData;
}

export function verkorLinesByQuarter(data) {
	const byQuarters = data.map((line, index) => {
		// Add the quaters to the objects
		const lineWithQuaters = line.list.map((el) => ({ ...el, quarter: Math.ceil((el.date.getMonth() + 1) / 3) }));
		const grouped = lineWithQuaters.reduce((acc, currentValue) => {
			const quarter = `Q${currentValue.quarter}-${currentValue.month.substring(4, 6)}`;
			if (acc[quarter] == null) acc[quarter] = [];
			// Push the item to the accumulator and overwrite the month value by a quarter value.
			// For example "month: Oct-2022" becomes "month: Q4-2022".
			acc[quarter].push({ ...currentValue, month: quarter, monthOld: currentValue.month });
			return acc;
		}, {});
		// Sort by date
		const sorted = Object.entries(grouped).sort((a, b) => a[0][1].date - b[0][1].date);
		// Pick the last element from the arrays grouped by quarters
		const itemsByQuarters = sorted.map((el) => el[1][el[1].length - 1]);
		return { _id: data[index]._id, list: itemsByQuarters };
	});
	return byQuarters;
}

export function verkorSitesByQuarter(lines, allData) {
	// Filter out the months we do not need
	const filtered = lines.filter((site) => allData.list.find((item) => site.month === item.monthOld));
	// Overwrite the month's value by quarter's value. AllData's month values = quater values.
	return filtered.map((site) => {
		const found = allData.list.find((item) => site.month === item.monthOld);
		return { ...site, month: found.month };
	});
}

export function getLongest(data) {
	const lengths = data.map((a) => a.list.length);
	const index = lengths.indexOf(Math.max(...lengths));
	return index < 0 ? 0 : index;
}
