import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import ClarityIndicator from "../graphs/ClarityIndicator";
import { getMarkerColor } from "../../apps/configs/ClarityConfig";

function ClarityIndicatorCard({
	appSlice, moduleKey, module, isPushed = false,
	moduleScore = {
		score: 0, nbSites: 0, nbNC: 0, trend: ""
	}, reportsLink = false, externalLink = false, displayNbSites = true, isPercent = false, border = true,
	locales
}) {
	if (moduleKey === "Business Continuity") console.log("moduleScore", moduleScore);
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const siteData = activeDatasets?.sites;

	if (module === undefined) {
		return null;
	}

	const domainLogo = module?.domainLogo ?? undefined;
	return (
		<div onClick={() => (!isPushed) ?
			(activeRefines.domain === undefined && moduleScore?.nbSites !== 0 ?
				dispatch(appSlice.actions.refine([{ key: "domain", value: moduleKey }]))
				: dispatch(appSlice.actions.refine([{ key: "domain", value: null }]))
			) : null}
		className={`bg-white rounded-xl py-4 h-60 w-40 md:w-52 ${border && "border"} border-transparent  flex flex-col
            ${isPushed ?
			"bg-opacity-60 shadow-innerxl"
			: "bg-opacity-90 shadow-xs cursor-pointer hover:border-gray-300"}`}>

			<div className={"relative w-full text-center m-auto z-10 flex h-12 justify-center items-center"}>
				<div className={`absolute text-md font-semibold tracking-tight w-full line-clamp-2
                ${moduleScore?.nbSites === 0 ? " text-gray-400" : ""}`}>
					{t(`domains.${module?.name}.name`)}
				</div>

				<div className="absolute w-full flex justify-center items-start
                text-5xl text-gray-200" style={{ zIndex: -1 }}>
					{domainLogo && typeof domainLogo === "object" ?
						<domainLogo fontSize="inherit" />
						: <img src={domainLogo} alt={domainLogo} className="self-center w-11 h-11 opacity-30 mt-2" />
					}
				</div>
			</div>
			<div className="">
				<ClarityIndicator label={t(`domains.${module?.locale}.name`)} moduleScore={moduleScore}
					color = {getMarkerColor(parseInt(moduleScore.score, 10))}
					isPercent={isPercent} displayNbSites={displayNbSites}/>
			</div>

			{
				<p className="text-sm text-center ">
					{isPushed && !reportsLink ?
						<a target="_blank" rel="noopener noreferrer"
							className="text-medium underline"
							href={`mailto:clarity.info@bureauveritas.com
                    ?subject=${`${clientParameters?.client} - ${t("homePage.discover.requestAccess")} ${t(`domains.${module?.locale}.name`)}`}`}>
							{t("homePage.discover.requestAccess")}
						</a>
						: (moduleScore?.nbSites > 0 ?
							!reportsLink && displayNbSites && (`${moduleScore?.nbSites} ${t("homePage.discover.site", { count: moduleScore?.nbSites })}`)
							: <span className="text-gray-400">{t("homePage.discover.noData")}</span>
						)}
				</p>
			}

			{reportsLink && moduleScore?.nbSites > 0 &&
				<div className="flex justify-center items-center w-full">
					<a target="_blank" rel="noopener noreferrer"
						href={`https://odsdatahub.bureauveritas.com/reports/
					${siteData?.data?.[0]?.report?.[(siteData?.data?.[0]?.report?.length === 1) ? 0
							: (siteData?.data?.[0]?.domain?.indexOf(moduleKey))]}`}
						className="flex justify-center items-center space-x-1
                                    border border-gray-200 rounded-lg px-3 py-2 text-medium font-light w-28
                                    hover:border-gray-400" >
						<InsertDriveFileIcon style={{ fontSize: "1rem" }} />
						<p className="flex flex-shrink-0">{t("NCsPage.listNCs.nonConformity.viewReports")}</p>
					</a>
				</div>
			}

		</div>
	);
}

ClarityIndicatorCard.propTypes = {
	appSlice: PropTypes.object,
	moduleKey: PropTypes.string,
	module: PropTypes.object,
	isPushed: PropTypes.bool,
	moduleScore: PropTypes.object,
	reportsLink: PropTypes.bool,
	externalLink: PropTypes.bool,
	displayNbSites: PropTypes.bool,
	isPercent: PropTypes.bool,
	border: PropTypes.bool,
	locales: PropTypes.string
};

export default ClarityIndicatorCard;
