import React from "react";
import PropTypes from "prop-types";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { index } from "d3";

const mockData = [
	{ title: "Projects", description: "Unlimted projects for you", value: 78 },
	{ title: "KPI", description: "KPI for your project", value: 62 },
	{ title: "User", description: "User and customer analytics", value: 33 },
	{ title: "Analytics", description: "Data analytics", value: -2 }
];

const indexSelector = (value) => {
	if (value > 50) return { color: "bg-green-400", icon: <KeyboardArrowUpIcon /> };
	if (value < 50 && value > 0) return { color: "bg-yellow-400", icon: <KeyboardArrowUpIcon /> };
	if (value === 0) return { color: "bg-gray-300", icon: null };
	if (value < 0) return { color: "bg-red-500", icon: <KeyboardArrowDownIcon /> };
};

const SectionIndexs = (props) => (
	<div className="container px-4 py-4 mx-auto">
		<div className="grid gap-6 md:grid-cols-2 xl:grid-cols-4">

			{mockData.map((item, i) => (
				<div key={i} className="flex items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm">
					<div className="p-3 mr-4 bg-admin_primary-default text-white rounded-full">
						<svg className="w-6 h-6" fill="none" stroke="currentColor"
							viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path strokeLinecap="round" strokeLinejoin="round"
								strokeWidth="2" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2
                        2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"></path></svg>
					</div>
					<div className="w-full">
						<div className="flex flex-row justify-between">
							<p className="mb-2 text-sm font-medium text-gray-900">{item.title}</p>
							<p className={`flex items-center justify-center text-small rounded-full h-5 w-16 text-white
                                           ${indexSelector(item.value).color}`}>
								{item.value}%{indexSelector(item.value).icon}
							</p>
						</div>
						<p className="text-sm font-normal text-gray-800">{item.description}</p>
					</div>
				</div>
			))}

		</div>
	</div>
);

SectionIndexs.propTypes = {

};

export default SectionIndexs;
