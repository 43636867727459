import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Tooltip } from "@mui/material";
// import { VerticalAlignCenterIcon } from "@mui/icons-material";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ProgressBarFlat from "../graphs/ProgressBarFlat";
import ClarityIndicator from "../graphs/ClarityIndicator";
import { getScoreColor } from "../../apps/configs/AtAGlanceConfig";

function AtAGlanceIndicatorCard({
	appSlice, moduleKey, module, isPushed = false,
	moduleScore = {
		score: 0, nbSites: 0, nbNC: 0, trend: ""
	}, reportsLink = false, externalLink = false, displayNbSites = true, isPercent = false, border = true,
	getAltBarColor = undefined, altBarScore1, altBarScore2,
	locales, program, site = undefined
}) {
	if (moduleKey === "Maturity Index") console.log("moduleScore", moduleScore);
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	const navigate = useNavigate();
	// Load data from the store
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const clarityClientParameters = activeDatasets?.clarityClientParameters?.data[0];
	const siteData = activeDatasets?.sites;

	const cardTitleRef = useRef();

	const handleLongTitle = () => {
		// If the height is more than 24 than it means there are two lines
		if (cardTitleRef?.current?.clientHeight > 24) {
			return "leading-4 relative top-[6px]";
		}
	};

	window.addEventListener("resize", handleLongTitle);

	if (module === undefined) {
		return null;
	}

	const handleRedirect = () => {
		const projectName = clientParameters.client;
		const dimension = moduleKey;
		if (program === "supplyr") {
			const url = "/supplyr";
			// put the params in the localstorage so we could get them in Supply-R app for refining.
			window.localStorage.setItem("ataglanceURLparams", JSON.stringify(
				{
					projectName,
					module: dimension,
					supplier: site?.supplier,
					product: activeRefines?.product,
					type: activeRefines?.type,
					tierLevel: activeRefines?.tierLevel,
					country: activeRefines?.country
				}));
			navigate(url);
		}
		if (program === "clarity") {
			const { spaceLabel } = clarityClientParameters;
			const url = "/clarity";
			// setting campaignId to default current one
			const getCampaignId = clientParameters.clarity.campaigns[0].rank;
			// put the params in the localstorage so we could get them in Clarity app for refining.
			window.localStorage.setItem("ataglanceURLparams", JSON.stringify({
				domain: dimension, spaceLabel, siteId: site?.siteId, country: activeRefines?.country, campaignId: getCampaignId
			}));
			navigate(url);
		}
	};

	// Add the line break at first ampersand (&)
	const lineBreakAtAmpersand = (str) => {
		if (str.includes("&")) {
			const index = str.indexOf("&");
			return `${str.slice(0, index) }\n${ str.slice(index, str.length)}`;
		}
		return str;
	};

	const Logo = module?.logo ?? undefined;

	const scoreColor = getScoreColor(moduleScore.score, program);

	return (
		<><div data-test={`ataglance-indicator-card-${moduleKey.replaceAll(" ", "-").toLowerCase()}`} onClick={handleRedirect}
			className={`bg-white rounded-xl ${getAltBarColor ? "pt-4" : "py-4 h-56"} w-full ${border && "border"} border-transparent  flex flex-col
		justify-center items-center relative
            ${isPushed ?
				"bg-opacity-60 shadow-innerxl"
				: "bg-opacity-90 shadow-xs cursor-pointer hover:border-gray-300"}`}>

			<div className="absolute top-[10px] w-full text-center m-auto z-10">
				{/* If there is an ampersand it means that the title will be on two lines
			and we'll apply a different style to make it look good. */}
				<div ref={cardTitleRef} className={`absolute text-md font-semibold tracking-tight w-full break-words
                ${moduleScore?.nbSites === 0 ? " text-gray-400" : ""} ${handleLongTitle()}`}>
					{lineBreakAtAmpersand(t(`domains.${module?.locale}.name`))}
				</div>

				<div className="absolute top-0 w-full flex justify-center items-start -mt-4
                text-5xl text-gray-200" style={{ zIndex: -1 }}>
					{Logo && typeof Logo === "object" ?
						<Logo fontSize="inherit" />
						: <img src={Logo} alt={Logo} className="self-center w-11 h-11 opacity-30 mt-2" />
					}
				</div>
			</div>
			<div className="mt-7">
				<ClarityIndicator label={t(`domains.${module?.locale}.name`)} moduleScore={moduleScore} color = {scoreColor} isPercent={isPercent}/>
			</div>

			{
				<p className="text-sm text-center ">
					{isPushed && !reportsLink ?
						<a target="_blank" rel="noopener noreferrer"
							className="text-medium underline"
							href={`mailto:clarity.info@bureauveritas.com
                    ?subject=${`${clientParameters?.client} - ${t("homePage.discover.requestAccess")} ${t(`domains.${module?.locale}.name`)}`}`}>
							{t("homePage.discover.requestAccess")}
						</a>
						: (moduleScore?.nbSites > 0 ?
							!reportsLink && displayNbSites && (`${moduleScore?.nbSites} ${t("homePage.discover.site", { count: moduleScore?.nbSites })}`)
							: <span className="text-gray-400">{t("homePage.discover.noData")}</span>
						)}
				</p>
			}

			{reportsLink && moduleScore?.nbSites > 0 &&
				<div className="flex justify-center items-center w-full">
					<a target="_blank" rel="noopener noreferrer"
						href={`https://odsdatahub.bureauveritas.com/reports/
					${siteData?.data?.[0]?.report?.[(siteData?.data?.[0]?.report?.length === 1) ? 0
							: (siteData?.data?.[0]?.domain?.indexOf(moduleKey))]}`}
						className="flex justify-center items-center space-x-1
                                    border border-gray-200 rounded-lg px-3 py-2 text-medium font-light w-28
                                    hover:border-gray-400" >
						<InsertDriveFileIcon style={{ fontSize: "1rem" }} />
						<p className="flex flex-shrink-0">{t("NCsPage.listNCs.nonConformity.viewReports")}</p>
					</a>
				</div>
			}
			{getAltBarColor && <div className="flex flex-col w-full">
				<Tooltip title={t("supplyRIndicators.resistance")} placement="top">
					<div className="grid grid-cols-4 items-center w-full gap-x-5">
						<VerticalAlignCenterIcon style={{ fontSize: 14 }} className="mx-1" />
						<ProgressBarFlat
							strokeColor={getAltBarColor(altBarScore1)}
							endPoint={altBarScore1}
							width={76}
							height={9} />
						<p className="text-medium col-start-4 text-right">{(`${altBarScore1}%`) ?? "n/a"}</p>
					</div>
				</Tooltip>
				<Tooltip title={t("supplyRIndicators.responsiveness")} placement="top">
					<div className="grid grid-cols-4 items-center w-full gap-x-5">
						<TimelapseIcon style={{ fontSize: 14 }} className="mx-1"/>
						<ProgressBarFlat
							strokeColor={getAltBarColor(altBarScore2)}
							endPoint={altBarScore2}
							width={76}
							height={9} />
						<p className="text-medium col-start-4 text-right">{(`${altBarScore2}%`) ?? "n/a"}</p>
					</div>
				</Tooltip>
			</div>}
		</div>
		</>
	);
}

AtAGlanceIndicatorCard.propTypes = {
	appSlice: PropTypes.object,
	moduleKey: PropTypes.string,
	module: PropTypes.object,
	isPushed: PropTypes.bool,
	moduleScore: PropTypes.object,
	reportsLink: PropTypes.bool,
	externalLink: PropTypes.bool,
	displayNbSites: PropTypes.bool,
	isPercent: PropTypes.bool,
	border: PropTypes.bool,
	locales: PropTypes.string,
	getAltBarColor: PropTypes.func,
	altBarScore1: PropTypes.number,
	altBarScore2: PropTypes.number,
	program: PropTypes.string,
	site: PropTypes.object
};

export default AtAGlanceIndicatorCard;
