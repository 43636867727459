import React from "react";
import { useTranslation } from "react-i18next";
import { appDefinition } from "../../apps/configs/SupplyRConfig";

const SupplyRSplash = () => {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	return 	<div className="grid grid-cols-1 grid-flow-row my-6 mx-4 md:mx-10 gap-y-10 gap-x-4 md:grid-cols-1 md:gap-x-6 ">
		<div className="grid grid-cols-2 bg-white px-3 py-3">
			<img src="/images/SupplyR/Splashscreen/Splashscreen-001.png" className="h-full w-full" />
			<div className="flex flex-col gap-y-2  justify-center px-6">
				<p className="flex-shrink-0 font-semibold">
					{t("splashscreen.welcome.title")}
				</p>
				<p className="text-medium font-light">
					{t("splashscreen.welcome.description")}
				</p>
			</div>
		</div>

		<div className="grid grid-cols-2 bg-white px-3 py-3">
			<img src="/images/SupplyR/Splashscreen/Splashscreen-002.png" className="h-full w-full" />
			<div className="flex flex-col gap-y-2 justify-center px-6">
				<p className="flex-shrink-0 font-semibold">
					{t("splashscreen.page1.title")}
				</p>
				<p className="text-medium font-light">
					{t("splashscreen.page1.description")}
				</p>
			</div>
		</div>

		<div className="grid grid-cols-2 bg-white px-3 py-3">
			<img src="/images/SupplyR/Splashscreen/Splashscreen-003.png" className="h-full w-full" />
			<div className="flex flex-col gap-y-2 justify-center px-6">
				<p className="flex-shrink-0 font-semibold">
					{t("splashscreen.page2.title")}
				</p>
				<p className="text-medium font-light">
					{t("splashscreen.page2.description")}
				</p>
			</div>
		</div>

		<div className="grid grid-cols-2 bg-white px-3 py-3">
			<img src="/images/SupplyR/Splashscreen/Splashscreen-004.png" className="h-full w-full" />
			<div className="flex flex-col gap-y-2 justify-center px-6">
				<p className="flex-shrink-0 font-semibold">
					{t("splashscreen.page3.title")}
				</p>
				<p className="text-medium font-light">
					{t("splashscreen.page3.description")}
				</p>
			</div>
		</div>

		<div className="grid grid-cols-2 bg-white px-3 py-3">
			<img src="/images/SupplyR/Splashscreen/Splashscreen-005.png"className="h-full w-full" />
			<div className="flex flex-col gap-y-2 justify-center px-6">
				<p className="flex-shrink-0 font-semibold">
					{t("splashscreen.page4.title")}
				</p>
				<p className="text-medium font-light">
					{t("splashscreen.page4.description")}
				</p>
			</div>
		</div>

	</div>;
};

export default SupplyRSplash;
