import PropTypes from "prop-types";

const SplitText = ({
	label1 = "programs",
	value1 = 3,
	label2 = "facilities",
	value2 = 8,
	theme = "alpha",
	showLabel = true,
	className = "flex justify-items-center w-full h-36",
	textClassName = "text-5xl font-bold",
	labelClassName = "text-xs font-light"
}) => (
	<div className={className}>
		<svg viewBox="0 0 100 100" className="split-text w-full">
			<defs>
				<linearGradient id="linesplittext" x1="0%" y1="100%" x2="0%" y2="0%">
					<stop offset="0%" className={`chart-initcolor ${theme}`} />
					<stop offset="100%" className={`chart-endcolor ${theme}`} />
				</linearGradient>
			</defs>
			<line x1="96" y1="10" x2="4" y2="96" className="line" />
			<text x="30" y="44" textAnchor="end" letterSpacing="-0.1em" className={textClassName}>
				{value1}
			</text>
			{showLabel && <text x="78" y="15" textAnchor="end" className={labelClassName}>
				{label1}
			</text>}
			<text x="68" y="96" letterSpacing="-0.1em" className={textClassName}>
				{value2}
			</text>
			{showLabel && <text x="26" y="96" className={labelClassName}>
				{label2}
			</text>}
		</svg>
	</div>
);

SplitText.propTypes = {
	className: PropTypes.string,
	label1: PropTypes.string,
	label2: PropTypes.string,
	labelClassName: PropTypes.string,
	showLabel: PropTypes.bool,
	textClassName: PropTypes.string,
	theme: PropTypes.string,
	value1: PropTypes.number,
	value2: PropTypes.number
};

export default SplitText;
