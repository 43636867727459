/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "admin",
            "endpoint": "https://f0v9s867ia.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "audits",
            "endpoint": "https://7rngvmxia9.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "gsca",
            "endpoint": "https://a5ecgfb32k.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "opendata",
            "endpoint": "https://bqzkmcygaf.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:87829fe8-b74b-4462-95e5-9bfa62ac6e2d",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_RJvx9EpmK",
    "aws_user_pools_web_client_id": "2jvitr5igk03ulb40i8bo1o75q",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bv-myradar-adminsave133624-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "geo": {
        "amazon_location_service": {
            "region": "eu-west-1",
            "search_indices": {
                "items": [
                    "placeindex635affab-prod"
                ],
                "default": "placeindex635affab-prod"
            }
        }
    }
};


export default awsmobile;
