import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import SummarizeIcon from "@mui/icons-material/Summarize";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Table from "../table/Table";
import { tableSectionsStructure } from "../../apps/configs/VendorDDConfig";

function VendorDDModuleCard({
	appSlice, label, link, dataset, filterData, defaultShow = false, locales
}) {
	// Set up state for opened form
	const [show, setShow] = useState(defaultShow);

	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<div className="flex flex-col alphaBlur rounded-lg  pt-1 overflow-hidden">
			<div className="flex justify-between cursor-pointer px-4 py-3"
				onClick={(e) => setShow(!show)}>
				<div className="flex items-center gap-x-2">
					<SummarizeIcon fontSize="large" />
					<p className="text-lg font-semibold">
						{label}
					</p>
				</div>
				<a target="_blank" rel="noopener noreferrer"
					href={`https://odsdatahub.bureauveritas.com/reports/${link}`}
					className="flex justify-center items-center gap-x-2 bg-white
                                    border border-white rounded-lg px-3 py-2 text-medium font-light w-32
                                    hover:border-vendorDD_primary-menu hover:text-vendorDD_primary-menu hover:font-semibold" >
					<CloudDownloadIcon fontSize="small" />
					<p className="flex flex-shrink-0">{t("modulePage.viewReport")}</p>
				</a>
			</div>
			{show && <div className="bg-white w-full py-3">
            	<Table pageTable="modulePage"
					appSlice={appSlice}
					dataset={dataset}
					filterData={filterData}
					structureTable={tableSectionsStructure}
					sortByRefineFields={[{ id: "section1Label", desc: false } ]}
					themePalette="vendorDD"
					locales={locales} />
			</div>
			}
		</div>
	);
}

VendorDDModuleCard.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	label: PropTypes.string,
	link: PropTypes.string,
	filterData: PropTypes.func,
	defaultShow: PropTypes.bool,
	locales: PropTypes.string
};

export default VendorDDModuleCard;
