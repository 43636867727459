import React, {
	useState, useEffect, useRef, useMemo
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import SearchOffIcon from "@mui/icons-material/SearchOff";

import { debounce } from "lodash";
import ArrayFieldEditor from "./ArrayFieldEditor";
import ObjectEditor from "./ObjectEditor";
import ObjectGroupEditor from "./ObjectGroupEditor";
import FieldTransformerController from "./FieldTransformerController";
import SelectFieldEditor from "./AdminSupplierSelectFieldEditor";
import CalendarController from "./CalendarController";
import CheckboxController from "./CheckboxController";
import AsyncSelectFieldEditor from "./AdminSupplierAsyncSelect";
import UpdateFormBtnGroup from "../button/UpdateFormBtnGroup";
import Popconfirm from "../modal/Popconfirm";
import AlphaTooltip from "../tooltips/AlphaTooltip";
import SimpleSelectFieldEditor from "./SelectFieldEditor";

import IF from "../../utils/IF";

import {
	appDefinition, typeSelector, fieldAttributeBuilder, TORDataset, searchSelector, convertRegion, convertCountry, setValueKeyBuilder,
	fieldOrderBuilder, currentFieldOptions, newBranchFirstNode
} from "../../apps/configs/AdminConfig";

import {
	RemoveKey, AllFieldsBuilder, AllKeyTypePairBuilder, isCoordinates, searchOptionBuilder, getGeoPointByClick, refreshSiteLabelId, clearRefines,
	setReferenceFields, FieldNameAccessor
} from "./FormUtils";

import { SupplierDelete } from "./AdminFormOnConfirm";

const AdminSupplierUpdateForm = ({
	data,
	appSlice,
	clearRefineKeys,
	targetDocAccessor,
	renderButtonGroup = true,
	screenHeight,
	onConfirm,
	supplychainView,
	setSupplychainView,
	setMarkerPosition,
	disabledFields = [],
	requiredFields,
	alertFields,
	alertText,
	setAlert,
	childrenDocsAccessor
}) => {
	const dispatch = useDispatch();

	const { t } = useTranslation(appDefinition.locales);

	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	// prefill the form with current node's info
	const targetDocument = useMemo(() => ({
		...newBranchFirstNode({ activeRefines, activeDatasets, source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0] }),
		...data.find(targetDocAccessor)
	}), [activeRefines, data, activeDatasets, targetDocAccessor]);

	const productRef = useRef(targetDocument?.product);
	const geoRef = useRef(targetDocument?.geopointToR);
	const supplierCodeRef = useRef(targetDocument?.supplierCode);
	const factoryCodeRef = useRef(targetDocument?.factoryCode);
	const factoryBranchRef = useRef(targetDocument?.factoryBranch);

	const siteIdRef = useRef(targetDocument?.siteId);
	const siteLabelIdRef = useRef(targetDocument?.siteLabelId);
	const siteUniqueIdRef = useRef(targetDocument?.siteUniqueId);

	const [rollBack, setRollBack] = useState(false);
	const [confirmModal, setconfirmModal] = useState(false);
	const [confirmModalFactoryRes, setConfirmModalFactoryRes] = useState(false);

	// on key generate coordinate based on address
	const [geoList, setGeoList] = useState([]);

	// on click to enable specific field (siteLabelId) which is disabled by default
	const [enableField, setEnableField] = useState({ siteLabelId: false, siteId: false, siteUniqueId: false });

	// all adjacent children nodes of current target document
	const childrenNode = activeDatasets[TORDataset[activeRefines.secLevelMenu]]?.data
	?.filter(childrenDocsAccessor(activeRefines.secLevelMenu, targetDocument));

	// sort field by its priority
	const fieldOrder = fieldOrderBuilder(activeRefines);

	// get all the field from mongoDB
	const allFieldsOfCurrentCollection = [...new Set(
		AllFieldsBuilder({
			project: activeRefines.secLevelMenu,
			data,
			activeRefines,
			activeDatasets,
			source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0]
		})
	)]
	.sort((a, b) => {
		const finalDisabledField = activeRefines.supplyChainAction !== "updateLocation"
			? disabledFields.filter((f) => f !== "siteLabelId" && f !== "siteId" && f !== "siteUniqueId")
			: disabledFields.filter((f) => f);

		if (finalDisabledField.includes(a) && !finalDisabledField.includes(b)) {
			return 1;
		}

		if (!finalDisabledField.includes(a) && finalDisabledField.includes(b)) {
			return -1;
		}

		return fieldOrder.indexOf(a) - fieldOrder.indexOf(b);
	});

	// all the fields in key type pair
	const AllKeyTypePair = Object.entries(
		Object.fromEntries(
			new Map(
				AllKeyTypePairBuilder({
					project: activeRefines.secLevelMenu,
					data,
					activeRefines,
					activeDatasets,
					source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0]
				})
			))
	)
	?.reduce((acc, cur) => ({ ...acc, [cur[0]]: typeSelector(cur, activeRefines) }), {});

	// all key in target document in update mode
	// transform all flat array to array of object with key k
	const targetDocumentWithK = targetDocument && Object.keys(targetDocument)?.reduce((acc, cur) => {
		let objWithK;
		if (Array.isArray(targetDocument[cur])) { objWithK = targetDocument[cur].map((el) => ({ k: el })); return { ...acc, [cur]: objWithK }; }

		// convert value of specific key, ex: South Korea -> Korea
		if (cur === "country") return { ...acc, [cur]: convertCountry[targetDocument[cur]] ? convertCountry[targetDocument[cur]] : targetDocument[cur] };
		if (cur === "region") return { ...acc, [cur]: convertRegion[targetDocument[cur]] ? convertRegion[targetDocument[cur]] : targetDocument[cur] };
		return { ...acc, [cur]: targetDocument[cur] };
	}, {});

	const {
		control, register, handleSubmit, formState: { errors }, reset, setValue, getValues, watch
	} = useForm({
		defaultValues: targetDocumentWithK,
		criteriaMode: "firstError",
		shouldFocusError: true
	});

	// select on sidebar and refresh form content
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => reset(targetDocumentWithK), [activeRefines.thirdLevelMenu]);

	useEffect(() => {
		if (rollBack) {
			setRollBack(false);
			reset(targetDocument);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rollBack]);

	// form will be filled with values based on selected option in dropdown
	useEffect(() => {
		// clear refine in case client select between supplier and auditedCompany/company which will raise unexpected result
		clearRefines(appSlice, activeRefines, dispatch);
		// select from supplier list in supplyR
		if ((activeRefines.supplyChainAction === "updateNode" || activeRefines.supplyChainAction === "updateLocation")
		&& activeRefines.supplier && activeRefines.auditedCompany && activeRefines.secLevelMenu === "supplyR") {
			const selectedDocument = data.find((item) => item.supplier === activeRefines.supplier && item.auditedCompany === activeRefines.auditedCompany);

			setReferenceFields({
				selectedDocument, getValues, activeRefines, reset, setMarkerPosition
			});
		}

		// select from supplier list in clarity
		if ((activeRefines.supplyChainAction === "updateNode" || activeRefines.supplyChainAction === "updateLocation")
		&& activeRefines.supplier && activeRefines.company && activeRefines.secLevelMenu === "clarity") {
			const selectedDocument = data.find((item) => item.supplier === activeRefines.supplier && item.company === activeRefines.company);

			setReferenceFields({
				selectedDocument, getValues, activeRefines, reset, setMarkerPosition
			});
		}

		// select from supplier list in ataglance
		if ((activeRefines.supplyChainAction === "updateNode" || activeRefines.supplyChainAction === "updateLocation")
				&& activeRefines.supplier && activeRefines.auditedCompany && activeRefines.secLevelMenu === "ataglance") {
			const selectedDocument = data.find((item) => item.supplier === activeRefines.supplier && item.auditedCompany === activeRefines.auditedCompany);

			setReferenceFields({
				selectedDocument, getValues, activeRefines, reset, setMarkerPosition
			});
		}

		// select from auditedCompany list in supplyR
		if ((activeRefines.supplyChainAction === "updateNode" || activeRefines.supplyChainAction === "updateLocation")
		&& !activeRefines.supplier && activeRefines.auditedCompany && activeRefines.secLevelMenu === "supplyR") {
			// In case select from supplier first then auditedCompany
			const selectedDocument = data.find((item) => {
				if (activeRefines.factoryIndex) {
					return item.supplier === getValues("supplier")
					&& item.auditedCompany === activeRefines.auditedCompany
					&& item.factoryIndex === activeRefines.factoryIndex
					&& item.validated === "True";
				}
				return item.supplier === getValues("supplier") && item.auditedCompany === activeRefines.auditedCompany;
			});

			if (!selectedDocument) {
				setValue("auditedCompany", activeRefines.auditedCompany);

				const factoryCode = activeDatasets?.factoryCode?.data
				?.filter((item) => item?.auditedCompany === activeRefines.auditedCompany)?.[0]?.factoryCode ?? "";

				setValue("factoryCode", factoryCode);

				refreshSiteLabelId({
					activeDatasets, activeRefines, getValues, setValue, reset, setMarkerPosition
				});

				setConfirmModalFactoryRes((prev) => !prev);

				return;
			}

			setReferenceFields({
				selectedDocument, getValues, activeRefines, reset, setMarkerPosition
			});
		}

		// select from company list in clarity
		if ((activeRefines.supplyChainAction === "updateNode" || activeRefines.supplyChainAction === "updateLocation")
		&& !activeRefines.supplier && activeRefines.company && activeRefines.secLevelMenu === "clarity") {
			// In case select from supplier first then auditedCompany
			const selectedDocument = data.find((item) => item.supplier === getValues("supplier") && item.company === activeRefines.company);

			if (!selectedDocument) {
				setValue("company", activeRefines.company);
				return;
			}

			setReferenceFields({
				selectedDocument, getValues, activeRefines, reset, setMarkerPosition
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeRefines.supplyChainAction, activeRefines.supplier, activeRefines.auditedCompany, activeRefines.company]);

	const onSubmit = (e) => {
		e.preventDefault();
		setconfirmModal(true);
	};

	// remove id in case error rewrite _id in mongoDb
	const submit = (submitValue) => {
		const res = RemoveKey(submitValue);

		const resWithoutId = { ...res };

		// remove key in avoid overwriting existing
		["_id", "key", "updateDate"].forEach((e) => delete resWithoutId[e]);

		if (activeRefines.secLevelMenu !== "supplyR" && activeRefines.secLevelMenu !== "clarity") {
			["supplierCode", "factoryCode"].forEach((e) => delete resWithoutId[e]);
		}

		onConfirm({
			res,
			resWithoutId,
			setconfirmModal,
			currentTarProduct: productRef.current,
			childrenNode,
			currentTarget: targetDocumentWithK,
			currentTarSupplierCode: supplierCodeRef.current,
			currentTarFactoryCode: factoryCodeRef.current,
			currentTarFactoryBranch: factoryBranchRef.current,
			currentTarSiteId: siteIdRef.current,
			currentTarSiteLabelIdRef: siteLabelIdRef.current,
			currentTarSiteUniqueIdRef: siteUniqueIdRef.current
		});

		// renew product ref when there is children and current product is not equal to productRef
		if (productRef.current && (productRef.current !== resWithoutId?.product)
				&& childrenNode.length > 0) {
			productRef.current = resWithoutId?.product;
		}

		// renew siteId ref when there is children and current siteId is not equal to siteIdRef
		if (siteIdRef.current && (siteIdRef.current !== resWithoutId?.siteId)
				&& childrenNode.length > 0) {
			siteIdRef.current = resWithoutId?.siteId;
		}

		// renew factoryBranch ref when current factoryBranch is not equal to factoryBranchRef
		if (factoryBranchRef.current && (factoryBranchRef.current !== resWithoutId?.factoryBranch)) {
			factoryBranchRef.current = resWithoutId?.factoryBranch;
		}

		// renew siteLabelId ref when there is children and current siteLabelId is not equal to siteLabelIdRef
		if (siteLabelIdRef.current && (siteLabelIdRef.current !== resWithoutId?.siteLabelId)
				&& childrenNode.length > 0) {
			siteLabelIdRef.current = resWithoutId?.siteLabelId;
		}

		// renew siteUniqueId ref when there is children and current siteUniqueId is not equal to siteUniqueIdRef
		if (siteUniqueIdRef.current && (siteUniqueIdRef.current !== resWithoutId?.siteUniqueId)
				&& childrenNode.length > 0) {
			siteUniqueIdRef.current = resWithoutId?.siteUniqueId;
		}
	};

	// prevent enter event from submitting form
	const checkKeyDown = (e) => {
		if (e.key === "Enter") e.preventDefault();
	};

	const stringFieldInput = (e, field) => {
		// update only when new geo info diff from origin
		if (field === "geopointToR" && e.target.value !== geoRef.current) {
			if (
				isCoordinates(e.target.value)
			) {
				// update marker postion
				setMarkerPosition(e.target.value);
				setValue(`${field}`, e.target.value);
				if (geoList?.length) {
					setGeoList([]);
				}
			} else {
				console.log("bad coordination", e.target.value);
				setValue(`${field}`, "");
			}

			return;
		}

		setValue(`${field}`, e.target.value);
	};

	const debouncedStrInput = debounce(stringFieldInput, 2000);

	return (
		<>
			{
				confirmModal &&
				<Popconfirm
					title="Update current supplier"
					description="Are you sure you want update this supplier?"
					confirmBtnText="Confirm"
					onClickConfirm={handleSubmit(submit)}
					onClickCancel={() => setconfirmModal(false)}
				/>
			}
			{
				confirmModalFactoryRes && (
					<Popconfirm
						title="Something went wrong"
						description={`No result for your search! 
						NOTE: Please aware that siteLabelId will be rfieldAttributeBuildereassgined and the values of some relavant 
						fields might not be matching in current form.`}
						onClickCancel={() => setConfirmModalFactoryRes(false)}
						showConfirm={false}
						iconsType="exclamationMark"
					/>
				)
			}

			<UpdateFormBtnGroup appSlice={appSlice} locales={appDefinition.locales} renderButtonGroup={renderButtonGroup}
				defaultValue={targetDocumentWithK} reset={reset} clearRefineKeys={clearRefineKeys} supplychainView={supplychainView}
				setSupplychainView={setSupplychainView} setMarkerPosition={setMarkerPosition} renderDeleteBtn
				onDelete={SupplierDelete} setAlert={setAlert} />

			<form
				className="flex flex-col px-2 my-4 space-y-6 overflow-y-auto"
				style={{ height: screenHeight - 260 }}
				onSubmit={onSubmit}
				onKeyDown={(e) => checkKeyDown(e)}
			>
				{allFieldsOfCurrentCollection
				.filter((item) => !fieldAttributeBuilder(activeRefines.secLevelMenu, "hiddenFields")?.includes(item))
				.map((field, i) => (
					<div key={`field-${nanoid()}-${i}`}>
						<IF condition={AllKeyTypePair[field] === "checkbox"}>
							<label key={field + i} className={`relative w-1/3 flex font-medium gap-y-1 mt-4 capitalize
                            text-admin_primary-default border px-2 py-3 rounded-lg items-center`}>
								<span className=" bg-white uppercase text-sm">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<CheckboxController {...{ control, name: `${field}`, className: "ml-4" }} />
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "array"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                            text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<ArrayFieldEditor
									control={control}
									field={field}
									register={register}
									requiredFields={requiredFields}
									activeRefines={activeRefines}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "object"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                            text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<ObjectEditor
									control={control}
									field={field}
									register={register}
									data={targetDocument?.[`${field}`]}
									requiredFields={requiredFields}
									activeRefines={activeRefines}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "objectGroup"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
							text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								{targetDocument?.[`${field}`] &&
								Object.entries(targetDocument?.[`${field}`]).map(([subField, fieldValue]) => (
									<ObjectGroupEditor
										key={nanoid()}
										field={`${field}.${subField}`}
										fieldValue={fieldValue}
										control={control}
										register={register}
										activeRefines={activeRefines}
										appSlice={appSlice}
										getValues={getValues}
										setValue={setValue}
										requiredFields={requiredFields}
										errors={errors}
										targetDocument={targetDocument}
									/>
								))}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "search"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<SelectFieldEditor
									field={field}
									control={control}
									appSlice={appSlice}
									options={searchOptionBuilder(activeRefines, field, searchSelector, activeDatasets)}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem 0",
											borderRadius: "0.5rem"
										})
									}}
									requiredFields={requiredFields}
									disabledFields={disabledFields}
									activeRefines={activeRefines}
									placeholder={getValues(field)}
									setValue={setValue}
									getValues={getValues}
									activeDatasets={activeDatasets}
									reset={reset}
									setMarkerPosition={setMarkerPosition}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
								{alertFields?.includes(field)
										&& <span className="text-red-600 text-small uppercase">
											{alertText(field, activeRefines)}
										</span>}
							</label>
						</IF>
						<IF condition={AllKeyTypePair[field] === "asyncsearch"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<AsyncSelectFieldEditor
									field={field}
									control={control}
									appSlice={appSlice}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem 0",
											borderRadius: "0.5rem"
										})
									}}
									requiredFields={requiredFields}
									disabledFields={disabledFields}
									setValue={setValue}
									setValueKey={(d) => setValueKeyBuilder(d, activeDatasets, activeRefines)}
									setMarkerPosition={setMarkerPosition}
									placeholder={getValues(field)}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "number"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                 text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								{/* transform str to num */}
								<FieldTransformerController
									transform={{
										input: (value) => Number.isNaN(value) ? "" : value?.toString(),
										output: (e) => {
											const output = parseInt(e.target.value, 10);
											return Number.isNaN(output) ? 0 : output;
										}
									}}
									control={control}
									register={register}
									requiredFields={requiredFields}
									disabledFields={disabledFields}
									name={`${field}`}
									defaultValue=""
									placeholder="enter a number"
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

						<IF condition={AllKeyTypePair[field] === "date"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<CalendarController control={control} name={`${field}`} />
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>
						<IF condition={AllKeyTypePair[field] === "select"}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                    text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
								</span>
								<SimpleSelectFieldEditor
									field={field}
									control={control}
									appSlice={appSlice}
									options={currentFieldOptions(field)}
									styles={{
										control: (base) => ({
											...base,
											border: "1px solid lightgray", // default border color
											"&:hover": { borderColor: "gray" }, // border style on hover
											boxShadow: "none",
											margin: "0.5rem 0",
											borderRadius: "0.5rem"
										})
									}}
								/>
								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>
						<IF condition={
							AllKeyTypePair[field] !== "array"
								&& AllKeyTypePair[field] !== "object"
								&& AllKeyTypePair[field] !== "objectGroup"
								&& AllKeyTypePair[field] !== "select"
								&& AllKeyTypePair[field] !== "search"
								&& AllKeyTypePair[field] !== "asyncsearch"
								&& AllKeyTypePair[field] !== "number"
								&& AllKeyTypePair[field] !== "checkbox"
								&& AllKeyTypePair[field] !== "date"
						}>
							<label key={field + i} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
                                                    text-admin_primary-default border px-2 py-3 rounded-lg">
								<span className="h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
									{`${requiredFields.includes(field) ? "*" : ""} ${FieldNameAccessor({ activeRefines, field })}`}
									{
										Object.keys(enableField).includes(field)
										&& <BorderColorIcon className="text-lg mx-1.5 hover:cursor-pointer"
											onClick={() => setEnableField({ ...enableField, [field]: !enableField[field] })}/>
									}
									{
										field === "geopointToR"
											&& <AlphaTooltip placement="top-start"
												title={<div className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90
                                              bg-admin_primary-default rounded-2xl w-48">
													<span className="font-bold">{t("tooltip.getCoordinates.title")}</span>
													{t("tooltip.getCoordinates.description")}
												</div>}>
												<MyLocationIcon className="text-lg mx-1.5 hover:cursor-pointer"
													onClick={() => {
														const addr = getValues("address"); console.log("addr", addr);
														getGeoPointByClick(addr, setGeoList);
													}}
												/>
											</AlphaTooltip>
									}
									{
										field === "geopointToR"
											&&
											<AlphaTooltip placement="top-start"
												title={<div className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90
                                              bg-admin_primary-default rounded-2xl w-48">
													<span className="font-bold">{t("tooltip.clearGeoList.title")}</span>
													{t("tooltip.clearGeoList.description")}
												</div>}>
												<SearchOffIcon className="text-lg mx-1.5 hover:cursor-pointer"
													onClick={() => {
														if (geoList.length > 0) {
															setGeoList([]);
														}
													}}
												/>
											</AlphaTooltip>
									}
								</span>

								<input {...register(`${field}`, { required: requiredFields.includes(field) })}
									disabled={enableField[field]
										? [...disabledFields].filter((f) => f !== field).includes(field)
										: disabledFields.includes(field)}
									autoComplete="off"
									className={`p-2 my-2 text-black border rounded-lg focus:outline-none focus:ring-2 
										focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base
										${(enableField[field] ? [...disabledFields].filter((f) => f !== field).includes(field)
										: disabledFields.includes(field)) ? "bg-gray-200" : "bg-white"}`}
									onChange={(e) => {
										debouncedStrInput(e, field);
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											stringFieldInput(e, field);
										}
									}}
									onBlur={(e) => {
										stringFieldInput(e, field);
									}}
								/>
								<ul className="flex flex-col w-max space-y-3">
									{field === "geopointToR"
											&& geoList?.map((item, index) => (
												<li key={`geoList-${nanoid()}-${index}`} className="cursor-pointer"
													onClick={() => setValue("geopointToR", `${item?.geometry?.point[1]},${item?.geometry?.point[0]}`)}>
													{item?.geometry?.point?.length === 2
														// eslint-disable-next-line max-len
														? `[ ${item?.geometry?.point[1]},${item?.geometry?.point[0]} ] - ${item?.country ?? ""} - ${item?.municipality ?? ""}`
														: ""}
												</li>
											))}
								</ul>
								{alertFields?.includes(field)
										&& <span className="text-red-600 text-small uppercase">
											{alertText(field, activeRefines)}
										</span>}

								{errors?.[`${field}`] && <span className="text-red-600 text-sm">This field is required</span>}
							</label>
						</IF>

					</div>
				))}

				<div className="flex flex-col space-y-4 mt-10">
					<button className="border bg-admin_primary-default hover:bg-admin_primary-dark
                    text-white font-bold py-1 px-3 rounded uppercase" type="submit">
						<DoneAllIcon />
					</button>
				</div>

			</form>
		</>
	);
};

AdminSupplierUpdateForm.propTypes = {
	appSlice: PropTypes.object,
	clearRefineKeys: PropTypes.array,
	data: PropTypes.array.isRequired,
	renderButtonGroup: PropTypes.bool,
	screenHeight: PropTypes.number,
	targetDocAccessor: PropTypes.func,
	onConfirm: PropTypes.func,
	setMarkerPosition: PropTypes.func,
	supplychainView: PropTypes.bool,
	setSupplychainView: PropTypes.func,
	disabledFields: PropTypes.array,
	requiredFields: PropTypes.array,
	alertFields: PropTypes.array,
	alertText: PropTypes.string,
	setAlert: PropTypes.func,
	childrenDocsAccessor: PropTypes.func
};

export default AdminSupplierUpdateForm;
