import React from "react";
import PropTypes from "prop-types";

const BezierLink = ({
	data,
	rectW,
	compensation = 55,
	stroke = "#686C7E",
	strokeWidth = "7",
	strokeOpacity = "0.55",
	currentTreeShifting,
	startXCompensation = 0,
	endXCompensation = 0
}) => (
	<path
		d={`M ${data.source.y + rectW + startXCompensation},${data.source.x + currentTreeShifting}
            C ${data.source.y + rectW + compensation},${data.source.x + currentTreeShifting}
            ${data.target.y - rectW - compensation},${data.target.x + currentTreeShifting}
            ${data.target.y - rectW + endXCompensation},${data.target.x + currentTreeShifting}`}
		fill="transparent"
		stroke={stroke}
		strokeWidth={strokeWidth}
		strokeOpacity={strokeOpacity}
		className="treecontent"
	/>
);

BezierLink.propTypes = {
	data: PropTypes.object,
	rectW: PropTypes.number,
	compensation: PropTypes.number,
	stroke: PropTypes.string,
	strokeWidth: PropTypes.string,
	strokeOpacity: PropTypes.string,
	currentTreeShifting: PropTypes.number,
	startXCompensation: PropTypes.number,
	endXCompensation: PropTypes.number
};

export default BezierLink;
