import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import React, { useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import { Tooltip } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AlphaTooltip from "../tooltips/AlphaTooltip";
import { appAllOptions } from "../../apps/configs/LauncherConfig";
import { colorsAndBreakdowns, appDefinition } from "../../apps/configs/AtAGlanceConfig";
import ColorLegend from "../legends/ColorLegend";

function TitleCard({
	titleLocal, subTitleLocal = undefined, picto = undefined,
	prefix = "", infoLocal, classTitle, small = false, xsmall = false, theme = "clarity", locales,
	versionWithIcon = false, program, description = undefined, projectName = undefined, spaceLabel = undefined,
	site = undefined, activeRefines = undefined
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);
	const Picto = picto?.picto;
	const appOptions = appAllOptions?.find((opt) => opt.label === program);

	const navigate = useNavigate();

	const [showLegendTooltip, setShowLegendTooltip] = useState(false);

	// Used only if the card has program icons which is currently specific to "At a Glance" only.
	const isAtAGlance = true;
	const atAGlanHelpIconStyle = showLegendTooltip ? { fontSize: "24px", zIndex: 1 } : { color: "#44b9b4" };

	const handleRedirect = () => {
		if (program === "Supply-R") {
			const url = "/supplyr";
			// put the params in the localstorage so we could get them in Supply-R app for refining.
			window.localStorage.setItem("ataglanceURLparams", JSON.stringify(
				{
					projectName,
					supplier: site?.supplier,
					product: activeRefines?.product,
					type: activeRefines?.type,
					tierLevel: activeRefines?.tierLevel,
					country: activeRefines?.country
				}));
			navigate(url);
		}
		if (program === "Clarity") {
			const url = "/clarity";
			// put the params in the localstorage so we could get them in Supply-R app for refining.
			window.localStorage.setItem("ataglanceURLparams", JSON.stringify({ spaceLabel, siteId: site?.siteId, country: activeRefines?.country }));
			navigate(url);
		}
	};

	// The legend is specific to "At a Glance". It has the design of "Clarity" and
	// the colors of "Supply-R".
	const tooltipLegend = () => (
		<div className="relative z-500 -bottom-[6px] justify-center flex flex-row -mb-[43px]">
			<ColorLegend
				locales={appDefinition.locales}
				// RegEx to remove special characters
				colorLegend={colorsAndBreakdowns[program?.replace(/[^\w\s]/gi, "").toLowerCase()]}
				scale="scale-120"
				legendFontSize="text-8"
				indicatorFontSize={12}
				isRounded={true}
			/>
		</div>
	);

	const titleCardWithIcon = () => {
		const pictoUrl = appOptions?.picto;
		const styles = {
			tooltipColor: "[#44b9b4]"
		};
		return (
			<>
				<div data-test="TitleCard" className="flex">
					<div className="pr-4">
						<img className="drop-shadow-xl cursor-pointer" src={pictoUrl} alt={`${program}_picto`} width="50" height="50"
							onClick={handleRedirect} ></img>
					</div>
					<div>
						<div className="flex">
							<div className="font-bold text-xl mr-1 cursor-pointer" onClick={handleRedirect} >{program}</div>
							<div className="cursor-pointer">
								<ExitToAppIcon style={{ fontSize: "18px" }} onClick={handleRedirect} />
							</div>
						</div>
						<div className="flex">
							{description}
							<div className="flex relative">
								{isAtAGlance ?
									<HelpIcon data-test={titleLocal.replaceAll(".", "-").toLowerCase()}
										onMouseOver={() => setShowLegendTooltip(true)} onMouseLeave={() => setShowLegendTooltip(false)}
										fontSize="small" className="ml-2 cursor-pointer" style={atAGlanHelpIconStyle} />
									:
									<AlphaTooltip placement="top-start"
										title={
											<div className={`flex flex-col items-baseline gap-y-1 p-4
											bg-opacity-90 bg-${theme}_primary-accent rounded-2xl w-48`}>
												<span className="font-bold">{t("homePage.tooltip.title")}</span>
												{t(infoLocal)}
											</div>
										}
									>
										<HelpIcon data-test={titleLocal.replaceAll(".", "-").toLowerCase()}
											fontSize="small" className={`ml-2 text-${styles.tooltipColor}`} />
									</AlphaTooltip>
								}
								{showLegendTooltip &&
									<div className="w-112 absolute bg-[#efefef] rounded-xl h-36 bottom-0 left-[8px] opacity-90">
										<p className="font-bold pt-8 pl-11">How it works</p>
										<p className="pl-11 text-sm">{t("homePage.tooltip.legendInfo")}</p>
										{tooltipLegend()}
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		versionWithIcon ? titleCardWithIcon()
			:
			<div data-test="TitleCard" className="flex flex-col ">
				<div className="flex w-full items-center font-bold gap-x-1">
					{picto !== undefined &&
						<Tooltip title={t(picto.locale)} placement="top">
							<Picto />
						</Tooltip>
					}
					<p className={`pr-2 ${small ? "text-md" : (xsmall ? "text-medium" : "text-xl")} 
                ${classTitle}`}>
						{t(`${prefix}`)} {t(titleLocal)}
					</p>
					{infoLocal &&
						<AlphaTooltip placement="top-start"
							title={
								<div className={`flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90 bg-${theme}_primary-accent rounded-2xl w-48`}>
									<span className="font-bold">{t("tooltip.title")}</span>
									{t(infoLocal)}
								</div>
							}
						>
							<HelpIcon data-test={titleLocal.replaceAll(".", "-").toLowerCase()}
								fontSize="small" className={` text-${theme}_primary-accent `} />
						</AlphaTooltip>
					}
				</div>
				{subTitleLocal &&
					<p className={`w-full font-light mb-4 md:mb-6 ${small ? "text-small md:text-sm" : " text-sm md:text-md "}`}>
						{t(subTitleLocal)}
					</p>
				}
			</div>
	);
}
TitleCard.propTypes = {
	titleLocal: PropTypes.string,
	subTitleLocal: PropTypes.string,
	picto: PropTypes.object,
	prefix: PropTypes.string,
	infoLocal: PropTypes.string,
	classTitle: PropTypes.string,
	small: PropTypes.bool,
	xsmall: PropTypes.bool,
	theme: PropTypes.string,
	locales: PropTypes.string,
	versionWithIcon: PropTypes.bool,
	program: PropTypes.string,
	description: PropTypes.string,
	projectName: PropTypes.string,
	spaceLabel: PropTypes.string,
	site: PropTypes.object,
	activeRefines: PropTypes.object
};
export default TitleCard;
