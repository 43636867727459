import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ParentSize } from "@visx/responsive";

import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

function SimpleCardWithOptions({
	appSlice, large = false, locales, label = undefined, subLabel = undefined, description, options = undefined, refineKey
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store
	const activeDatasets = useSelector(appSlice.selectDatasets);

	// state options selection
	const [selected, setSelected] = useState(0);

	// options styles
	const switcherActive = "text-clarity_primary-accent font-semibold text-small cursor-pointer scale-110";
	const switcherInactive = "hover:font-semibold hover:text-black text-gray-600 cursor-pointer";

	const value = activeDatasets?.[options[selected].dataset]?.data[0]?.[refineKey];
	return (
		<>
			<div
				className="flex flex-col alphaBlur rounded-xl px-2 py-3 w-full h-full gap-y-2"
			>

				{/* <div className="flex gap-x-1">
					<VerifiedUserIcon className={`text-compass_primary-accent
							${large ? "text-3xl" : "text-lg md:text-xl 2xl:text-3xl"}`} />
					<div className="flex flex-col w-full">
						<p className={`font-semibold text-compass_primary-accent
							${large ? "text-2xl" : "text-medium md:text-base 2xl:text-lg"}`}>
							{t(label).toUpperCase()}
						</p>
					</div>
				</div> */}
				<div className="flex justify-between items-start w-full h-1/5">
					<div className="flex gap-x-1 w-full">
						<VerifiedUserIcon className={`text-compass_primary-accent 
							${large ? "text-3xl" : "text-lg md:text-xl 2xl:text-3xl"}`} />

						<p className={`font-semibold text-compass_primary-accent
							${large ? "text-2xl" : "text-medium md:text-base 2xl:text-lg"}`}>
							{t(label).toUpperCase()}
						</p>

					</div>
				</div>

				<div className="flex flex-col justify-center items-center h-full">
					<div className="flex flex-col justify-evenly items-center h-full">
						{description &&
                        <div className="text-small md:text-medium 2xl:text-base line-clamp-1 leading-3 w-full flex text-center">
                        	{description}
                        </div>}
						<div className="flex flex-col justify-center items-center text-6xl ">
							{value && typeof value === "number" ? value.toFixed(1) : value}
							{subLabel &&
								<span className="text-base">{t(subLabel)}</span>}
						</div>
					</div>
					<div className={"flex flex-wrap gap-x-2 justify-center items-end text-small w-2/3"}>
						{
							options.map((choice, i) => (
								<span
									key={`choice-no${i}`}
									onClick={() => setSelected(i)}
									className={selected === i ? switcherActive : switcherInactive}>{choice.label}</span>
							))
						}
					</div>
				</div>
			</div></>

	);
}

SimpleCardWithOptions.propTypes = {
	appSlice: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.string,
	subLabel: PropTypes.string,
	description: PropTypes.string,
	large: PropTypes.bool,
	locales: PropTypes.string,
	options: PropTypes.array,
	refineKey: PropTypes.string
};

export default SimpleCardWithOptions;
