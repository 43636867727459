import React from "react";

export const AnimatedPlus = () => (
	<>
		<circle r={6.5} fill="white" stroke="#1C3FAA" cx="12.5" cy="12.5" />
		<svg
			fill="indigo"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="-9.5 -10 30 30"
			width= "17"
			height="17"
		>
			<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
			<path d="M0 0h24v24H0z" fill="none" />
		</svg>
	</>
);

export const AnimatedEdit = () => (
	<svg
		fill="indigo"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="-9.5 -11.5 40 40"
		width= "22"
		height="22"
	>
		<svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="-5 5 37.5 32" width="32px" height="32px" stroke="#1C3FAA">
			<path d="M 23.90625 3.96875 C 22.859375 3.96875 21.8125 4.375 21 5.1875 L 5.1875 21 L 5.125 21.3125 L 4.03125 26.8125
                L 3.71875 28.28125 L 5.1875 27.96875 L 10.6875 26.875 L 11 26.8125 L 26.8125 11 C 28.4375 9.375 28.4375 6.8125 26.8125
                5.1875 C 26 4.375 24.953125 3.96875 23.90625 3.96875 Z M 23.90625 5.875 C 24.410156 5.875 24.917969 6.105469 25.40625
                6.59375 C 26.378906 7.566406 26.378906 8.621094 25.40625 9.59375 L 24.6875 10.28125 L 21.71875 7.3125 L 22.40625 6.59375
                C 22.894531 6.105469 23.402344 5.875 23.90625 5.875 Z M 20.3125 8.71875 L 23.28125 11.6875 L 11.1875 23.78125 C 10.53125
                22.5 9.5 21.46875 8.21875 20.8125 Z M 6.9375 22.4375 C 8.136719 22.921875 9.078125 23.863281 9.5625 25.0625 L 6.28125 25.71875 Z"/>
		</svg>
	</svg>
);

export const AnimatedBin = () => (
	<svg
		fill="#1C3FAA"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width= "17"
		height="17"
	>
		<path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
);

export const AnimateEditLocation = () => (
	<svg xmlns="http://www.w3.org/2000/svg" x="2.5" y="2.6" viewBox="0 0 25 25" width="15px" height="15px" fill="#1C3FAA">
		<path d="M13,24c-3.26,0-6.19-1.99-7.4-5.02l-3.03-7.61C2.26,10.58,3,9.79,3.81,10.05l0.79,0.26c0.56,0.18,1.02,0.61,1.24,1.16
		L7.25,15H8V3.25C8,2.56,8.56,2,9.25,2s1.25,0.56,1.25,1.25V12h1V1.25C11.5,0.56,12.06,0,12.75,0S14,0.56,14,1.25V12h1V2.75
		c0-0.69,0.56-1.25,1.25-1.25c0.69,0,1.25,0.56,1.25,1.25V12h1V5.75c0-0.69,0.56-1.25,1.25-1.25S21,5.06,21,5.75V16 C21,20.42,17.42,24,13,24z"/>
	</svg>
);
