import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import React, { useMemo } from "react";
import * as d3 from "d3";

import { Group } from "@visx/group";
import {
	Treemap,
	hierarchy,
	treemapBinary
} from "@visx/hierarchy";
import SVGNoDataAvailable from "../../pages/SVGNoDataAvailable";

import AlphaTooltip from "../tooltips/AlphaTooltip";

const margin = {
	top: 0,
	right: 0,
	left: 0,
	bottom: 0
};

const cascade = (root, offset) => {
	const x = new Map();
	const y = new Map();
	return root.eachAfter((d) => {
		if (d.children) {
			x.set(d, 1 + d3.max(d.children, (c) => c.x1 === d.x1 - offset ? x.get(c) : NaN));
			y.set(d, 1 + d3.max(d.children, (c) => c.y1 === d.y1 - offset ? y.get(c) : NaN));
		} else {
			x.set(d, 0);
			y.set(d, 0);
		}
	}).eachBefore((d) => {
		d.x1 -= 2 * offset * x.get(d);
		d.y1 -= 2 * offset * y.get(d);
	});
};

const TreeMap = ({
	width, height, appSlice, dataSet, lv1BgColor = "#5564DB", lv2BgColor = "#6373F6", lv3BgColor = "white", refineKeyL1, refineKeyL3,
	highlightKey = undefined, highlightColor = "white"
}) => {
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const treeMapData = useSelector(appSlice.selectDatasets)?.[dataSet]?.data;

	const isHighlighted = useMemo(() => Object.keys(activeRefines).includes(highlightKey), [activeRefines, highlightKey]);

	const activeHighlightValue = useMemo(() => {
		if (!Object.keys(activeRefines).includes(highlightKey)) return [];

		return (
			Object.keys(activeRefines).includes(highlightKey)
			&& Array.isArray(activeRefines[highlightKey]))
			? activeRefines[highlightKey]
			: [activeRefines[highlightKey]];
	}, [activeRefines, highlightKey]);

	const dispatch = useDispatch();

	const root = useMemo(() => cascade(hierarchy(treeMapData)
	.sum((d) => d.size)
	.sort((a, b) => (b.value || 0) - (a.value || 0)), 3), [treeMapData]);

	if (!treeMapData || treeMapData.children.length === 0) {
		return <SVGNoDataAvailable width={width} height={height}/>;
	}
	return width < 10 ? null : (
		<div>
			<svg width={width} height={height}>
				<Treemap
					top={margin.top}
					root={root}
					size={[width, height]}
					tile={treemapBinary}
					paddingOuter={4}
					paddingInner={4}
					paddingTop={32}
					round
				>
					{(treemap) => (
						<Group>
							{
								treemap
								.descendants()
								.map((node, i) => {
									const nodeWidth = node.x1 - node.x0;
									const nodeHeight = node.y1 - node.y0;

									const isLV3highligted = node.depth === 3 && isHighlighted && activeHighlightValue.length > 0
										&& activeHighlightValue.includes(node.data.name);
									return (
										<Group
											key={`node_lv1-2-3_${i}`}
											top={node.y0 + margin.top}
											left={node.x0 + margin.left}
										>
											{node.depth === 1
											&& (
												<>
													<foreignObject
														width={nodeWidth}
														height={nodeHeight}>
														<AlphaTooltip
															followCursor={true}
															title={
																<div
																	className="flex items-start p-2 bg-opacity-90 bg-supplyr_primary-accent
														rounded-lg min-w-fit">
																	<span className="font-bold">
																		{`${node.data.name}`}
																	</span>
																</div>}
															arrow
														>
															<div
																onClick={() => refineKeyL1 && activeRefines[refineKeyL1]
																	? (activeRefines[refineKeyL1].length === 1
																		? dispatch(appSlice.actions.clear([{ refine: refineKeyL1 }]))
																		: dispatch(appSlice.actions.refine([{
																			key: refineKeyL1,
																			value: [...activeRefines[refineKeyL1].filter((e) => e !== node.data.name)]
																		}])))
																	: dispatch(appSlice.actions.refine([{ key: refineKeyL1, value: [node.data.name] }]))}
																className={`h-full w-full bg-supplyr_primary-menu rounded-md
															text-white text-sm
															flex justify-center
															hover:bg-supplyr_primary-lightFocus hover:text-black
															${refineKeyL1 && "cursor-pointer"}`}>
																<text className="p-2 text-ellipsis">
																	{nodeWidth > 30 &&
																	`${node.data.name}`}
																</text>
															</div>
														</AlphaTooltip>
													</foreignObject>
												</>
											)}

											{node.depth === 2 && nodeWidth > 7 && nodeHeight > 11 && (
												<>
													<foreignObject
														width={nodeWidth}
														height={nodeHeight}>
														<AlphaTooltip
															followCursor={true}
															title={
																<div
																	className="flex justify-start
																	p-2 bg-opacity-90 bg-supplyr_primary-accent
														rounded-lg">
																	<span className="font-bold">
																		{`${node.data.name}`}
																	</span>
																</div>}
															arrow>
															<div
																className={
																`w-full h-full rounded-md
																flex justify-center
																text-white overflow-hidden text-ellipsis
														 ${node.data.name === "Failed"
																	? "bg-[#EB4C72]"
																	: "bg-[hsl(29,77%,55%)]"}
																hover:border-2`}>
																<text className="text-sm pt-2">
																	{nodeWidth > 70 && nodeHeight > 30 &&
																	`${node.data.name}`}
																</text>
															</div>
														</AlphaTooltip>
													</foreignObject>
												</>
											)}

											{node.depth === 3 && nodeWidth > 7 && nodeHeight > 7 &&
												<>
													<foreignObject
														width={nodeWidth}
														height={nodeHeight}>
														<AlphaTooltip
															followCursor={true}
															title={
																<div className="flex items-baseline p-2 bg-opacity-90 bg-supplyr_primary-accent
														rounded-lg">
																	<span className="font-bold">{`
																${node.data.name} : ${node.data.size} 
																${isLV3highligted ? "/" : ""} 
																${isLV3highligted ? ((node.data.size / node.parent.data.num) * 100).toFixed(2) : ""} 
																${isLV3highligted ? "%" : ""}`}
																	</span>
																</div>
															}
															arrow>
															<div
																onClick={() => refineKeyL3 && isLV3highligted
																	? dispatch(appSlice.actions.refine([{
																		key: refineKeyL3,
																		value: [...activeRefines[refineKeyL3].filter((e) => e !== node.data.name)]
																	}]))
																	: (activeRefines?.[refineKeyL3]
																		? dispatch(appSlice.actions.refine([{
																			key: refineKeyL3,
																			value: [...activeRefines[refineKeyL3], node.data.name]
																		}]))
																		: dispatch(appSlice.actions.refine([{
																			key: refineKeyL3,
																			value: [node.data.name]
																		}]))
																	)
																}
																className={`w-full h-full
																flex justify-center items-center
														rounded-md text-black ${refineKeyL3 && "cursor-pointer"} text-ellipsis bg-[#fffdf6]
														${isLV3highligted
																	? "bg-supplyr_primary-accent border-2 border-supplyr_primary-menu font-semibold"
																	: ""}
																	${!isLV3highligted && activeRefines?.country !== undefined
																		&& "opacity-80"}
														hover:border-2`}>
																{isLV3highligted
																	? <div
																		className="flex flex-col justify-center items-center">
																		<span
																			className="text-medium whitespace-pre-wrap text-ellipsis px-1">
																			{nodeWidth > 38 && nodeHeight > 14 &&
																		`${node.data.name}`}
																		</span>
																		<span
																			className="text-medium whitespace-pre-wrap text-ellipsis text-red-400">
																			{nodeWidth > 38 && nodeHeight > 45 &&
																	`${((node.data.size / node.parent.data.num) * 100) === 100
																		? 1 : ((node.data.size / node.parent.data.num) * 100).toFixed(2)}
																${((node.data.size / node.parent.data.num) * 100) === 100 ? "" : "%"}`}
																		</span>
																	</div>
																	: <span
																		className="text-medium whitespace-pre-wrap text-ellipsis p-1">
																		{nodeWidth > 30 && nodeHeight > 14 &&
																	`${node.data.name}`}
																	</span>}

															</div>
														</AlphaTooltip>
													</foreignObject>
												</>
											}
										</Group>
									);
								})}
						</Group>
					)}
				</Treemap>
			</svg>
		</div>
	);
};

TreeMap.propTypes = {
	appSlice: PropTypes.object,
	data: PropTypes.array,
	dataSet: PropTypes.string,
	height: PropTypes.number,
	lv1BgColor: PropTypes.string,
	lv2BgColor: PropTypes.string,
	lv3BgColor: PropTypes.string,
	width: PropTypes.number,
	isClickable: PropTypes.bool,
	refineKeyL1: PropTypes.string,
	refineKeyL3: PropTypes.string,
	highlightKey: PropTypes.string,
	highlightColor: PropTypes.string
};

export default TreeMap;
