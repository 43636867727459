import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ColorLegend = ({
	locales, colorLegend = {}, scale = "scale-100", indicatorFontSize = 7, legendFontSize = undefined, colorModuleWidth = "16", displayAllLabels = false,
	isRounded = true
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<div data-test="color-legend" className={`relative flex flex-col text-xs ${scale}`}>
			<div className="flex   text-white text-center">
				{Object.keys(colorLegend).map((element, i, { length }) => <div key={element}
					className={`flex w-${colorModuleWidth} h-6
					items-center justify-center leading-tight border-t-2 border-b-2 border-white ${legendFontSize}
                    ${ i === 0 && "border-l-2"} ${ i === (length - 1) && " border-r-2"}
					${ (i === 0 && isRounded) && "rounded-l-full"} ${ (isRounded && i === (length - 1)) && "rounded-r-full"}`}
					style={{ backgroundColor: colorLegend[element].color }}>
					{displayAllLabels
						? t(`legend.${element}`)
						: (i === 0 || i === (length - 1)) && t(`legend.${element}`)}
				</div>
				)}
			</div>
			<div className="absolute flex w-full justify-evenly items-center h-full px-2">
				{Object.keys(colorLegend).map((element, i, { length }) => (
					(i !== 0) && <div key={`${element}-${i}`} className="flex items-center
                    justify-center w-5 h-5 bg-white rounded-full font-semibold"
					style={{ fontSize: indicatorFontSize }}>
						{colorLegend[element].range[0]}
					</div>
				))}
			</div>
		</div>

	);
};

ColorLegend.propTypes = {
	locales: PropTypes.string,
	colorLegend: PropTypes.object,
	scale: PropTypes.string,
	indicatorFontSize: PropTypes.number,
	legendFontSize: PropTypes.string,
	colorModuleWidth: PropTypes.string,
	displayAllLabels: PropTypes.bool,
	isRounded: PropTypes.bool
};

export default ColorLegend;
