import PropTypes from "prop-types";
import Loading from "../../utils/Loading";

function ProgressBar({
	className,
	label,
	theme = "alpha",
	linearX1 = 5,
	linearY1 = 10,
	linearX2 = 100,
	linearY2 = 10,
	viewboxWidth = 100,
	viewboxHeight = 20,
	width = "100%",
	height = 10,
	backgroudColor = "fill-current text-gray-500",
	backgroudX = 0,
	backgroudY = 5,
	lineX1 = 5,
	lineX2 = 100,
	lineY1 = 10,
	lineY2 = 10,
	showCircle = false,
	cy = 31,
	r = 4,
	strokeWidth = 10,
	percentage,
	showPercentage = true,
	loadStatus = "idle",
	backgroundRadius = 5
}) {
	if (loadStatus !== "idle") {
		return 	<div className={className} style={{ height: "5rem" }}>
			<Loading isComponent />
		</div>;
	}

	return (
		<div className={className}>
			<svg viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`}>
				<defs>
					<linearGradient id="lineprogressbar" x1={`${linearX1}`} y1={`${linearY1}`}
						x2={`${linearX2}`} y2={`${linearY2}`} gradientUnits="userSpaceOnUse">
						<stop offset="0" className={`chart-initcolor ${theme}`} />
						<stop offset="1" className={`chart-endcolor ${theme}`} />
					</linearGradient>
				</defs>

				<rect
					x={`${backgroudX}`}
					y={`${backgroudY}`}
					rx={`${backgroundRadius}`}
					ry={`${backgroundRadius}`}
					width={`${width}`}
					height={`${height}`}
					className={backgroudColor}
				/>

				<line x1={`${lineX1}`} y1={`${lineY1}`} x2={`${lineX2}`} y2={`${lineY2}`}
					strokeWidth={`${strokeWidth}`} strokeLinecap="round" className="stroke-fill">
					<animate attributeName="x2" from="0" to={(percentage / 100) * viewboxWidth - 5} dur="1.5s" fill="freeze"/>
				</line>

				{showCircle && <circle cx="{(percentage / 100) * viewboxWidth - 5}" cy={`${cy}`} r={`${r}`} fill="white">
					<animate attributeName="cx" from="0" to={(percentage / 100) * viewboxWidth - 5} dur="1.5s" fill="freeze"/>
				</circle>}

				<text x="0" y="15.1" className="fill-current text-white text-base font-black ">
					{label}
				</text>
				{showPercentage && <text x="85" y="11.5" className="fill-current text-white font-bold chart-value">
					{percentage}%
				</text>}
			</svg>
		</div>);
}

ProgressBar.propTypes = {
	backgroudColor: PropTypes.string,
	backgroudX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	backgroudY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	backgroundRadius: PropTypes.number,
	className: PropTypes.string,
	cy: PropTypes.number,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.string,
	lineX1: PropTypes.number,
	lineX2: PropTypes.number,
	lineY1: PropTypes.number,
	lineY2: PropTypes.number,
	linearX1: PropTypes.number,
	linearX2: PropTypes.number,
	linearY1: PropTypes.number,
	linearY2: PropTypes.number,
	loadStatus: PropTypes.string,
	percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	r: PropTypes.number,
	showCircle: PropTypes.bool,
	showPercentage: PropTypes.bool,
	strokeWidth: PropTypes.number,
	theme: PropTypes.string,
	viewboxHeight: PropTypes.number,
	viewboxWidth: PropTypes.number,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ProgressBar;
