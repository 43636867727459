import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function RYBNoValidSite({ siteCheck, locales }) {
	const { t } = useTranslation(locales);

	return (
		<div className="absolute flex flex-col flex-grow w-full h-full justify-center
         items-center m-auto z-50">
			<div className="bg-white rounded-lg max-w-md mx-10 shadow-2xl">
				<div className="flex items-center justify-center h-36">
					<p className="flex items-center justify-center text-2xl lg:text-3xl font-bold py-2 px-6
                                  transform rotate-6 text-red-700 uppercase rounded-2xl
                                  border-8 border-double border-red-700 ryb-mask-stamp ">
						{t("noValidSite.title")}
					</p>
				</div>
				<div className="flex flex-col space-y-0 bg-ryb_primary-bg text-white rounded-b-xl text-sm py-3 px-4">
					<p className="pb-2">
						{t("noValidSite.description", { siteId: siteCheck })}
					</p>
					<p className="pb-4">
						{t("noValidSite.opening")}
					</p>
					<a href="/ryb" className="self-center flex items-center justify-center font-semibold
                    uppercase cursor-pointer bg-ryb_primary-default rounded-lg w-24 p-1">
						{t("noValidSite.close")}
					</a>
				</div>
			</div>
		</div>
	);
}

RYBNoValidSite.propTypes = {
	siteCheck: PropTypes.string,
	locales: PropTypes.string
};

export default RYBNoValidSite;
