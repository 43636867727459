import {
	useState, useEffect
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Moment from "react-moment";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import { Tooltip } from "@mui/material";

import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import IF from "../../utils/IF";
import exportToExcel from "../../utils/ExportToExcel";
import TitleCard from "./TitleCard";
import ColumnGraph from "../graphs/ColumnGraph";
import SimpleSelector from "../filters/SimpleSelector";

import {
	ClarityKPIDistributionQuery, listFacetsToRefine, sdgsOptions, clarityKPIsSelectOptions, clarityInitDatasets
} from "../../apps/configs/ClarityConfig";

const getDistribKPIsData = (dataset, query) => ({
	name: dataset,
	// pages: ["KPIs"],
	collection: "ClarityKPIsDetails",
	fetch: "aggregation",
	query,
	facetsToRefine: clarityInitDatasets?.ClarityKPIs?.facetsToRefine,
	limit: 500,
	skip: 0
});

function ClarityKPIsList({ appSlice, locales, filterStyle }) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Declare a new state variable for a selectedKPI
	const [selectedKPI, setSelectedKPI] = useState(undefined);

	// Load data from the store
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const loadStatus = useSelector(appSlice.selectLoadDataStatus);

	const ClarityKPIsList = activeDatasets?.ClarityKPIs?.data?.map((kpi) => kpi.kpi);

	// state to store selected option from SimpleSelector and re-render on change
	const [selectOption, setSelectOption] = useState(clarityKPIsSelectOptions[0].key);

	// groups for ClarityKPIDistributionQuery
	const groups = ["spaceLabel", "kpiLabel", "sdgName", "SDG",
		"precision", "targetWay", "targetCYear", "target", "yearOfTarget", "unit", "note"];

	// BVKPIs pillars
	const pillars = [...new Set(Object.values(sdgsOptions).map((v) => Object.keys(v).includes("pillar") ? v.pillar : null).filter((el) => el !== null))];

	useEffect(() => {
		if (selectedKPI === undefined) {
			return null;
		}
		dispatch(appSlice.fetchDataset(getDistribKPIsData("ClarityKPIsDistribPerDate",
			ClarityKPIDistributionQuery(selectedKPI.kpi, [...groups, "date"], false))));
		dispatch(appSlice.fetchDataset(getDistribKPIsData("ClarityKPIsDistribPerOG",
			ClarityKPIDistributionQuery(selectedKPI.kpi, [...groups, "OG"], true))));
		dispatch(appSlice.fetchDataset(getDistribKPIsData("ClarityKPIsDistribPerOrgUnit",
			ClarityKPIDistributionQuery(selectedKPI.kpi, [...groups, "orgUnit"], true))));

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedKPI, activeRefines]);

	// adapt query to selected option
	useEffect(() => {
		if (selectOption?.prevYear) {
			dispatch(appSlice.fetchDataset(getDistribKPIsData("ClarityKPIs",
				ClarityKPIDistributionQuery(undefined, groups, undefined, { prevYear: selectOption?.prevYear }))));
		}
		if (selectOption?.quarter) {
			dispatch(appSlice.fetchDataset(getDistribKPIsData("ClarityKPIs",
				ClarityKPIDistributionQuery(undefined, groups, undefined, { quarter: selectOption?.quarter }))));
		} else {
			dispatch(appSlice.fetchDataset(getDistribKPIsData("ClarityKPIs",
				ClarityKPIDistributionQuery(undefined, groups, true, undefined))));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectOption]);
	return (
		<div className={`flex flex-col ${ selectedKPI !== undefined} -mt-16`}>

			{selectedKPI === undefined &&
				(<div className="flex gap-4 w-full justify-end items-center">
					<SimpleSelector
						cleanStyle={false}
						styles={filterStyle(clarityKPIsSelectOptions[0].label, clarityKPIsSelectOptions[0].label)}
						onChange={(value) => setSelectOption(value.key)}
						options={clarityKPIsSelectOptions}
						defaultValue={clarityKPIsSelectOptions[0].key}
						placeHolder={clarityKPIsSelectOptions[0].label}
						isSearchable={false}
					/>
					<button
						className="flex self-end bg-white p-2 rounded-xl z-40
                                cursor-pointer hover:shadow hover:text-clarity_primary-accent"
						onClick={(e) => exportToExcel(
							activeDatasets.ClarityKPIs.data.map((kpiToExport) => ({
								kpi: kpiToExport.kpi,
								kpiName: kpiToExport.kpiLabel,
								sdg: kpiToExport.sdgName,
								domain: kpiToExport.domain,
								score: kpiToExport.score,
								format: kpiToExport.formatUnit,
								target: kpiToExport.target,
								date: kpiToExport.lastDate
							})),
                `Clarity KPIs Export ${new Date().toISOString().split("T")[0]}`)}
					>
						<DownloadIcon />
						<p>{t("KPIsPage.download")}</p>
					</button>
				</div>)
			}

			<div className="flex flex-wrap gap-4">

				{selectedKPI !== undefined && <button
					className="flex self-start p-2 bg-white text-clarity_primary-accent rounded-xl z-40"
					onClick={() => setSelectedKPI(undefined)}>
					<ArrowBackIcon />
				</button>}
			</div>
			{/* Create a card for each pillar */}
			{pillars
			// filter unnecessary pillars when a KPI is selected
			.filter((opt) => selectedKPI === undefined || sdgsOptions[selectedKPI?.SDG].pillar === opt)
			// map pillars
			.map((pillar, i) => <div key={i} className="mt-8 p-4 rounded-xl bg-white drop-shadow-md">
				<p className="text-4xl tracking-[0.15em] text-center font-semibold uppercase ">{pillar}</p>

				{/* Map each correspondant SDGs that are populated with data */}
				{Object.entries(sdgsOptions)
				// filter if a kpi is selected
				.filter((opt) => selectedKPI === undefined || selectedKPI.SDG === opt[0])
				// filter per pillar
				.filter((opt) => opt[1].pillar === pillar)
				// map
				.map((el, i) => {
					const SDGCode = el[0];
					const mappedSDG = el[1];
					// check if the kpi is populated with data coming from ClarityKPIs dataset or if the kpi is present in Clarity BVKPIsList
					const isPopulated = (activeDatasets?.ClarityKPIs?.data?.some((kpi) => kpi.SDG === SDGCode))
				|| (activeDatasets?.ClarityBVKPIsList?.data?.some((kpi) => kpi.sdg === SDGCode));
					const SDGColor = mappedSDG.color;
					const SDGLightColor = mappedSDG.lightColor;
					const ClarityKPIsDataToDisplay = activeDatasets?.ClarityKPIs?.data
					?.filter((el) => el.SDG === SDGCode)
					.filter((kpi) => selectedKPI === undefined || selectedKPI.kpi === kpi.kpi)
					.sort((a, b) => (a.kpi > b.kpi) ? 1 : -1);
					return <>
						{isPopulated &&
						<div
							key={`SDG-${i}`}
							className={`w-full h-10 rounded-md my-4 p-4 overflow-hidden drop-shadow-lg
						flex justify-start items-center bg-[hsl(108,48%,42%)]
						text-white`}
							style={{ background: `linear-gradient(to right, ${SDGColor} 65%, ${SDGLightColor})` }}
						>
							<img src={mappedSDG.picto} className="h-10 mr-4 filter brightness-0 invert opacity-60" />
							<span>{SDGCode} - {mappedSDG.label}</span>
						</div>}
						<div className="flex items-center">
							<div className="flex flex-wrap gap-4 justify-start">

								{
									ClarityKPIsDataToDisplay.map((kpi, i) => {
										const scoreLabel = (kpi.score === "NA" ? "NA" : (kpi?.score?.toFixed(kpi?.precision ?? 0)) ?? 0)
								+ (kpi.formatUnit !== "na" ? kpi.formatUnit : "");
										const target = Number.parseFloat(kpi.target.replace(/,/g, "."));
										const targetCYear = Number.parseFloat(kpi.targetCYear.replace(/,/g, "."));

										const color =
								((kpi.targetWay === "above" && kpi.score >= target)
								|| (kpi.targetWay === "below" && kpi.score <= target)) ? "#43BCAF" : (kpi.targetWay === "above" &&
								kpi.score >= targetCYear && kpi.score < target) ||
								(kpi.targetWay === "below" && kpi.score <= targetCYear
								&& kpi.score > target) ? "#C6D682" : (kpi.targetWay === "below" &&
								kpi.score > targetCYear) || (kpi.targetWay === "above" && kpi.score < targetCYear) ? "#FF475C" : "black";

										return (
											<div key={`kpi ${i}`}
												className={`flex flex-col items-center gap-y-2 alphaBlur rounded-xl mb-4 ml-4 px-4 py-2 min-w-max
                        ${ (selectedKPI === undefined) && " cursor-pointer"}
						${kpi.BVImportant && "border-2 border-clarity_primary-menu border-l-8"}`}
												onClick={() => selectedKPI === undefined && setSelectedKPI(kpi)}>

												<div className="flex flex-col items-center justify-center w-full gap-y-1">
													<p className="px-2 line-clamp-1 h-fit text-medium bg-clarity_primary-accent text-white rounded">
														{kpi?.domain}
													</p>
													{kpi.BVImportant && <img src="\images\Clarity\ClarityPicto.png"
														className="absolute h-5 mb-1 right-1 top-1"></img>}

													<Tooltip title={kpi?.kpiLabel} placement="top">
														<p className="line-clamp-1 font-semibold text-sm mt-1">
															{kpi?.kpiLabel}
														</p>
													</Tooltip>
												</div>
												{kpi.note
													? <Tooltip title={kpi?.note} placement="right-end">
														<div className="flex">
															<p className="text-4xl font-black text-center"
																style={{ color }}>
																{scoreLabel}
															</p>
															{kpi?.note && <span className="text-2xl font-semibold" style={{ color }}> *</span>}
														</div>
													</Tooltip>
													: <p className="text-4xl font-black text-center"
														style={{ color }}>
														{scoreLabel}
													</p>
												}
												<div className="flex flex-col items-center justify-between h-full ">
													{kpi.target !== null && <p className="font-light">
                        vs {kpi?.yearOfTarget} {t("KPIsPage.indicator.target")} {kpi.target + (kpi.formatUnit !== "na" ? kpi.formatUnit : "")}
													</p>}
													<p className="font-light text-medium">
														{t("KPIsPage.indicator.lastUpdate")}: <Moment format="YYYY/MM/DD">{kpi?.lastDate}</Moment>
													</p>
												</div>
											</div>);
									}
									)
								}

								{/* Display placeholder KPIS */}
							 {
									activeDatasets?.ClarityBVKPIsList?.data
									?.filter((kpi) => !ClarityKPIsList?.some((el) => el === kpi.kpiId))
									?.filter((el) => el.sdg === SDGCode)
									.filter((kpi) => selectedKPI === undefined || selectedKPI.kpi === kpi.kpi)
									.sort((a, b) => (a.kpi > b.kpi) ? 1 : -1)
									?.map((placeholderKpi, idx) => (<div key={`kpi ${idx}`}
										className={`flex flex-col items-center gap-y-2 alphaBlur rounded-xl mb-4 ml-4 px-4 py-2 min-w-max
								 opacity-40`}>
										<div className="flex flex-col items-center justify-center w-full gap-y-1 overflow-hidden">
											<p className="px-2 line-clamp-1 text-medium bg-clarity_primary-accent text-white rounded">
												{placeholderKpi?.domain}
											</p>
											<Tooltip title={placeholderKpi?.kpiLabel} placement="top">
												<p className="line-clamp-1 break-all font-semibold text-sm"
												>
													{placeholderKpi?.kpiLabel}
												</p>
											</Tooltip>
										</div>

										<p className="text-4xl font-black text-center">
										N/A
										</p>
										<div className="flex flex-col items-center justify-center ">
											{placeholderKpi.target !== null && <p className="font-light">
										vs {placeholderKpi?.yearOfTarget} {t("KPIsPage.indicator.target")} {placeholderKpi.target
										+ (placeholderKpi.formatUnit !== "na" ? placeholderKpi.formatUnit : "")}
											</p>}
											<p className="font-light text-medium">
												{t("KPIsPage.indicator.lastUpdate")}: <Moment format="YYYY/MM/DD">{placeholderKpi?.lastDate}</Moment>
											</p>
										</div>
									</div>))
								}
							</div>
						</div>
					</>;
				})}
			</div>)}

			<IF condition={selectedKPI !== undefined}>
				<div className="grid grid-cols-1 grid-flow-row gap-x-4 md:grid-cols-2 md:gap-x-8 2xl:px-12">

					<div className="flex flex-col md:col-span-2 mt-6">
						<TitleCard titleLocal="KPIsPage.evolution.title" subTitleLocal="KPIsPage.evolution.subTitle" locales={locales} />
						<ParentSize>
							{(parent) => (
								<ColumnGraph
									appSlice={appSlice}
									dataset={"ClarityKPIsDistribPerDate"}
									filterData={(d) => d.kpi === selectedKPI.kpi && d.score !== "NA"}
									sortData={(a, b) => a.date > b.date ? 1 : -1}
									color={"#1876bd"}
									width={parent.width}
									strokeWidth={8}
									gradientStartBarColor="#CBDA72"
									gradientEndBarColor="#6E8F57"
									nonConformities
									getScore = {(d) => d.score}
									scoreScaleMax={true}
									scoreDecimal={selectedKPI.precision}
									margin={ {
										top: 20,
										left: 10,
										right: 30,
										bottom: 0
									}}
									targets={[
										{
											label: "2025 Target",
											value: Number.parseFloat(selectedKPI.target.replace(/,/g, ".")),
											color: "#43BCAF",
											stroke: 4
										},
										{
											label: "CY Target",
											value: Number.parseFloat(selectedKPI.targetCYear.replace(/,/g, ".")),
											color: "#C6D682",
											stroke: 4
										}
									]}
									getModule = {(d) => new Date(d.date).toDateString()}
									locales={locales}
								/>
							)}
						</ParentSize>
					</div>

					<IF condition={activeRefines.orgUnit === undefined}>
						<IF condition={activeRefines.OG === undefined}>
							<div className="flex flex-col ">
								<TitleCard titleLocal="KPIsPage.distribOG.title" subTitleLocal="KPIsPage.distribOG.subTitle" locales={locales} />
                    	<ParentSize>
									{(parent) => (
										<ColumnGraph
											appSlice={appSlice}
											dataset={"ClarityKPIsDistribPerOG"}
											filterData={(d) => d.kpi === selectedKPI.kpi && d.score !== "NA"}
											color={"#1876bd"}
											width={parent.width}
											strokeWidth={8}
											gradientStartBarColor="#CBDA72"
											gradientEndBarColor="#6E8F57"
											nonConformities
											getScore = {(d) => d.score}
											scoreScaleMax={true}
											sortScoreDimension
											margin={ {
												top: 20,
												left: 10,
												right: 30,
												bottom: 0
											}}
											targets={[
												{
													label: "2025 Target",
													value: Number.parseFloat(selectedKPI.target.replace(/,/g, ".")),
													color: "#43BCAF",
													stroke: 4
												},
												{
													label: "CY Target",
													value: Number.parseFloat(selectedKPI.targetCYear.replace(/,/g, ".")),
													color: "#C6D682",
													stroke: 4
												}
											]}
											scoreDecimal={selectedKPI.precision}
											getModule = {(d) => d.OG}
											locales={locales}
										/>
									)}
								</ParentSize>
							</div>
						</IF>
						<div className="flex flex-col ">
							<TitleCard titleLocal="KPIsPage.distribOrgUnit.title" subTitleLocal="KPIsPage.distribOrgUnit.subTitle" locales={locales} />
							<ParentSize>
								{(parent) => (
									<ColumnGraph
										appSlice={appSlice}
										dataset={"ClarityKPIsDistribPerOrgUnit"}
										filterData={(d) => d.kpi === selectedKPI.kpi && d.score !== "NA"}
										color={"#1876bd"}
										width={parent.width}
										strokeWidth={8}
										gradientStartBarColor="#CBDA72"
										gradientEndBarColor="#6E8F57"
										nonConformities
										getScore = {(d) => d.score}
										scoreScaleMax={true}
										sortScoreDimension
										margin={ {
											top: 20,
											left: 10,
											right: 30,
											bottom: 0
										}}
										targets={[
											{
												label: "2025 Target",
												value: Number.parseInt(selectedKPI.target, 10),
												color: "#43BCAF",
												stroke: 4
											},
											{
												label: "CY Target",
												value: Number.parseInt(selectedKPI.targetCYear, 10),
												color: "#C6D682",
												stroke: 4
											}
										]}
										scoreDecimal={selectedKPI.precision}
										getModule = {(d) => d.orgUnit}
										locales={locales}
									/>
								)}
							</ParentSize>
						</div>

					</IF>
				</div>
			</IF>
		</div>
	);
}

ClarityKPIsList.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	filterStyle: PropTypes.func
};

export default ClarityKPIsList;
