/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useMemo, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
	useAsyncDebounce,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable
} from "react-table";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownUp from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import { Pagination } from "@mui/material";

import CreatFormBtnGroup from "../button/CreatFormBtnGroup";

// Define a default UI for filtering
const GlobalFilter = ({
	placeHolder,
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter
}) => {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="flex justify-between items-center bg-gray-100 py-2 px-2 md:px-6 space-x-4 rounded-xl ">
			<input
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={placeHolder}
				className="bg-transparent outline-none "
			/>
			<SearchIcon />
		</div>
	);
};

GlobalFilter.propTypes = {
	placeHolder: PropTypes.string,
	preGlobalFilteredRows: PropTypes.array,
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
	const defaultRef = React.useRef();
	const resolvedRef = ref || defaultRef;

	React.useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<>
			<input type="checkbox" ref={resolvedRef} {...rest} />
		</>
	);
});

IndeterminateCheckbox.propTypes = {
	indeterminate: PropTypes.any
};

const AdminAppScopeTable = ({
	appSlice,
	locales,
	dataset = undefined,
	structureTable = undefined,
	sortByRefineFields = [{ id: "_id", desc: false }],
	refineKeys = (row) => [{ key: "application", value: row.original._id }],
	renderGroup,
	renderBack,
	renderAdd
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	const activeDatasets = useSelector(appSlice.selectDatasets);

	const data = useMemo(() => activeDatasets[dataset]?.data, [activeDatasets, dataset]);

	const columns = useMemo(
		() => structureTable(),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		rows,
		gotoPage,
		preGlobalFilteredRows,
		setGlobalFilter,
		state: { pageIndex, pageSize, selectedRowIds }
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: 9,
				sortBy: sortByRefineFields
			}
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				{
					id: "selection",
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					Cell: ({ row }) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					)
				},
				...columns
			]);
		}
	);

	if (dataset === undefined || structureTable === undefined) {
		console.log("You need to define your dataset and table structure to display this component");
		return null;
	}

	return (
		<div className="flex flex-col text-sm space-y-4 max-x-full w-full overflow-x-auto">
			<div className="self-center flex justify-between items-center w-full">
				<div className="flex flex-row items-center">

					<CreatFormBtnGroup appSlice={appSlice} locales={locales} renderGroup={renderGroup} renderBack={renderBack} renderAdd={renderAdd}
						refineKey={[{ key: "action", value: "createApplication" }]} addText="addScope"/>

					<GlobalFilter
						placeHolder={"Search for ..."}
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
				</div>

				<div className="flex space-x-2 items-center">
					<p className="hidden md:block">
						{rows.length} Results
					</p>
				</div>
			</div>
			<table {...getTableProps()} className="w-full">
				<thead>
					{headerGroups.map((headerGroup, i) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							key={`headerGroup${i}`}
						>
							{headerGroup.headers.map((column, j) => (
								<th
									key={`header${j}`}
									{...column.getHeaderProps(column.getSortByToggleProps())}
									className="first:pl-6 last:pr-6 text-left font-medium p-2 max-w-xs"
								>
									<div className="flex items-center space-x-2">
										{column.render("Header")}
										<span>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<KeyboardArrowDownUp fontSize="inherit" />
												) : (
													<KeyboardArrowDownIcon fontSize="inherit" />
												)
											) : (
												""
											)}
										</span>
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, k) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps()}
								key={`row${k}`}
								className={
									"odd:bg-gray-100 hover:bg-admin_primary-default hover:bg-opacity-20 cursor-pointer"
								}
							>
								{row.cells.map((cell, m) => (
									<td
										key={`cell${m}`}
										{...cell.getCellProps()}
										className="first:pl-6 last:pr-6 p-2 max-w-xs"
										onClick={() => {
											if (cell.column.id !== "selection") {
												dispatch(appSlice.actions.refine(refineKeys(row)));
												dispatch(appSlice.actions.refine([{ key: "action", value: "updateApplication" }]));
											}
										}}
									>
										{cell.render("Cell")}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			{rows.length > pageSize && (
				<Pagination
					page={pageIndex + 1}
					count={Math.floor(rows.length / pageSize + 1)}
					className="md:self-center px-4 md:px-6"
					onChange={async (event, value) => {
						const page = value ? Number(value) - 1 : 0;
						gotoPage(page);
					}}
				/>
			)}
		</div>
	);
};

AdminAppScopeTable.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	structureTable: PropTypes.func,
	sortByRefineFields: PropTypes.array,
	locales: PropTypes.string,
	refineKeys: PropTypes.func,
	renderGroup: PropTypes.bool,
	renderBack: PropTypes.bool,
	renderAdd: PropTypes.bool
};

export default AdminAppScopeTable;
