import React, { useState, Children } from "react";
import PropTypes from "prop-types";

// **********How to apply*********
//
// <TabsControl>
//	<div name="tabName1">
//			tab content 1
//	</div>
//	<div name="tabName2">
//			tab content 1
//	</div>
//	<div name="tabName3">
//			tab content 1
//	</div>
// </TabsControl>;
//
// *******************************

const defaultTabActiveStyle = `bg-white text-ryb_primary-bg font-bold rounded border-2 
								border-b-0 z-40 -mb-0.5 overflow-hidden`;
const defaultTabInactiveStyle = "border-0";

const defaultContentActiveStyle = "block bg-tab-content-bg m-3 p-4";
const defaultContentInactiveStyle = "hidden";

const TabsControl = ({
	children,
	tabActiveClassName,
	tabInActiveClassName,
	contentActiveClassName,
	contentIntentActiveClassName
}) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const checkTitleIndex = (index) => index === currentIndex
		? tabActiveClassName || defaultTabActiveStyle
		: tabInActiveClassName || defaultTabInactiveStyle;

	const checkItemIndex = (index) => index === currentIndex
		? contentActiveClassName || defaultContentActiveStyle
		: contentIntentActiveClassName || defaultContentInactiveStyle;

	return (
		<div className="flex flex-col">
			<div className="flex justify-start w-full cursor-pointer">
				{Children.map(children, (element, index) => (
					<div
						onClick={() => setCurrentIndex(index)}
						className={`flex justify-center items-center py-3 px-3 ${checkTitleIndex(index)}`}
					>
						<div className="bg-ryb_primary-bg w-3 h-3 mr-1 rounded-sm"></div>
						<p className="text-sm">{ element.props.name }</p>
					</div>
				))}
			</div>
			<div className="Tab_item_wrap border-2 bg-white">
				{Children.map(children, (element, index) => (
					<div className={ checkItemIndex(index) }>
						{element}
					</div>
				))}
			</div>
		</div>
	);
};

TabsControl.propTypes = {
	children: PropTypes.object,
	tabActiveClassName: PropTypes.string,
	tabInActiveClassName: PropTypes.string,
	contentActiveClassName: PropTypes.string,
	contentIntentActiveClassName: PropTypes.string
};

export default TabsControl;
