import React from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import { typeSelector, currentFieldOptions } from "../../apps/configs/AdminConfig";
import ArrayFieldEditor from "./ArrayFieldEditor";
import SimpleSelectFieldEditor from "./SelectFieldEditor";

const transform = {
	input: (value) => Number.isNaN(value) ? "" : value?.toString(),
	output: (e) => {
		const output = parseInt(e.target.value, 10);

		return Number.isNaN(output) ? 0 : output;
	}
};

const stringField = ({
	label, value, field, register, englishTranslationData
}) => (
	<div className="flex flex-col items-center w-full">
		<div className="flex flex-row items-center w-full">
			<label htmlFor={`${label}`} className="uppercase text-medium mr-2">{label}</label>
			<p className="text-black capitalze text-medium mr-2 font-light italic">
				<span className="uppercase font-normal">en: </span>{englishTranslationData[label]}</p>
		</div>
		<input
			autoComplete="off"
			defaultValue={`${value}`}
			{...register(`${field}`)}
			className="pl-2 py-1 border text-black focus:outline-none rounded-lg focus:ring-2 focus:ring-admin_primary-default
			focus:border-transparent text-sm focus:text-base w-full"
		/>
	</div>
);

const textareaField = ({
	label, value, control, field, register, activeRefines, englishTranslationData
}) => (
	<div className="flex flex-col w-full">
		<label key={field}>
			<div className="flex flex-row items-center w-full">
				<span className="uppercase text-medium mr-2">{`${label}`}</span>
				<p className="text-black capitalze text-medium mr-2 font-light italic">
					<span className="uppercase font-normal">en: </span>{englishTranslationData[label]}</p>
			</div>
			<textarea defaultValue={`${value}`}
				{...register(`${field}`)}
				className="align-top h-30 p-2 my-2 text-black border rounded-lg bg-white
					focus:outline-none focus:ring-2 focus:ring-admin_primary-default
					focus:border-transparent text-sm focus:text-base w-full"/>
		</label>
	</div>
);

const arrayField = ({
	label, value, control, field, register, activeRefines
}) => (
	<div className="flex flex-col">
		<label htmlFor={`${field}`} className="uppercase text-medium mr-2">{label}:</label>
		<ArrayFieldEditor
			control={control}
			field={field}
			activeRefines={activeRefines}
		/>
	</div>
);

const numberField = ({
	label, value, field, register
}) => (
	<div className="flex flex-col">
		<label htmlFor={`${label}`} className="uppercase text-medium mr-2">{label}:</label>
		<input
			defaultValue={field.value}
			type="number"
			className="pl-2 py-1 border text-black focus:outline-none rounded-lg focus:ring-2
			focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"
			onChange={(e) => field.onChange(transform.output(e))} value={transform.input(field.value)} />
	</div>
);

const objectFieldBuilder = ({
	entry, field, control, register, remove, activeRefines, englishTranslationData
}) => {
	const [label, value] = entry;

	const type = typeSelector(entry, activeRefines);

	switch (type) {
		case "string":
			return stringField({
				label, value, field, register, englishTranslationData
			});

		case "array":
			return arrayField({
				label, value, control, field, register, activeRefines
			});

		case "number":
			return numberField({
				label, value, field, register
			});

		case "textarea":
			return textareaField({
				label, value, field, register, control, englishTranslationData
			});

		default:
			break;
	}
};

const TranslationsObjectEditor = ({
	control, field: formField = "", register, data, activeRefines, englishTranslationData, requiredFields = []
}) => {
	const objEntries = Object.entries(data);

	return (
		<ul className="w-full space-y-2 my-2">
			{objEntries.map((entry, index) => (
				<li key={`list-${nanoid()}-${index}`} className="flex justify-between items-center rounded">
					{objectFieldBuilder({
						entry, control, activeRefines, register, englishTranslationData, field: `${formField}.${entry[0]}`
					})}
				</li>)
			)}
		</ul>
	);
};

TranslationsObjectEditor.propTypes = {
	control: PropTypes.object,
	field: PropTypes.string,
	register: PropTypes.func,
	data: PropTypes.any,
	englishTranslationData: PropTypes.any,
	requiredFields: PropTypes.array,
	activeRefines: PropTypes.object
};

export default TranslationsObjectEditor;
