import React from "react";
import Amplify from "aws-amplify";
import { Storage } from "@aws-amplify/storage";

// import Amplify config
import awsconfig from "../aws-exports";

Amplify.configure(awsconfig);

const uploadToS3 = async (uploadFolder, projectName, data) => {
	console.log("data", data);
	try {
		const uploadPromises = data.map(async (file) => {
			const result = await Storage.put(
		`${uploadFolder}/${projectName}/${file.fileName}`,
		file.data,
		{
			contentType: file.contentType
		}
			);
			return result.key;
		});

		const results = await Promise.all(uploadPromises);
		return results;
	} catch (error) {
		console.log("Error while uploading file to S3:", error);
		throw error;
	}
};

export default uploadToS3;
