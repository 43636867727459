// eslint-disable-next-line arrow-body-style
import * as d3 from "d3";

const CountryTreeMapData = (treeMapLv1Data, treeMapLv12Data) => {
	// initialize root
	const map = new Map()
	.set("name", "treeMapRoot")
	.set("children", [])
	.set("parent", null)
	.set("size", 0);

	const groupLv12ChildrenRes = treeMapLv12Data.map((lv12) => ({
		inspectionTaskL1: lv12.inspectionTaskL1,
		defectLevel2: lv12.defectLevel2,
		groupChildren: [...d3.rollup(lv12.lv2Children, (g) => g.length, (d) => d).entries()]
	}));

	treeMapLv1Data?.forEach((lv1) => {
		const groupLv1ChildrenRes = [...d3.rollup(lv1.lv1Children, (g) => g.length, (d) => d).entries()];

		map.get("children")
		.push({
			name: lv1.inspectionTaskL1,
			id: lv1.inspectionTaskL1,
			children: groupLv1ChildrenRes.map((item) => ({
				name: item[0],
				num: item[1],
				children: groupLv12ChildrenRes
				?.find(((el) => (el.defectLevel2 === item[0]) && (el.inspectionTaskL1 === lv1.inspectionTaskL1)))
				?.groupChildren
				?.map((lv3) => ({
					name: lv3[0],
					size: lv3[1]
				}))
			}))
		});
	});

	const obj = Array.from(map).reduce((obj, [key, value]) => (
		Object.assign(obj, { [key]: value })
	), {});

	return obj;
};

const EquipmentTreeMapData = (treeMapLv1Data, treeMapLv12Data) => {
	// initialize root
	const map = new Map()
	.set("name", "treeMapRoot")
	.set("children", [])
	.set("parent", null)
	.set("size", 0);

	const groupLv12ChildrenRes = treeMapLv12Data.map((lv12) => ({
		equipmentCategory: lv12.equipmentCategory,
		defectLevel1: lv12.defectLevel1,
		groupChildren: [...d3.rollup(lv12.lv2Children, (g) => g.length, (d) => d).entries()]
	}));

	treeMapLv1Data?.forEach((lv1) => {
		const groupLv1ChildrenRes = [...d3.rollup(lv1.lv1Children, (g) => g.length, (d) => d).entries()];

		map.get("children")
		.push({
			name: lv1.equipmentCategory,
			id: lv1.equipmentCategory,
			children: groupLv1ChildrenRes.map((item) => ({
				name: item[0],
				// size: item[1],
				children: groupLv12ChildrenRes
				?.find(((el) => (el.defectLevel1 === item[0]) && (el.equipmentCategory === lv1.equipmentCategory)))
				?.groupChildren
				?.map((lv3) => ({
					name: lv3[0],
					size: lv3[1]
				}))
			}))
		});
	});

	const obj = Array.from(map).reduce((obj, [key, value]) => (
		Object.assign(obj, { [key]: value })
	), {});

	return obj;
};

const InspectionTreeMapData = (treeMapLv1Data, treeMapLv12Data) => {
	// initialize root
	const map = new Map()
	.set("name", "treeMapRoot")
	.set("children", [])
	.set("parent", null)
	.set("size", 0);

	const groupLv12ChildrenRes = treeMapLv12Data.map((lv12) => ({
		inspectionTasksL2: lv12.inspectionTasksL2,
		defectLevel1: lv12.defectLevel1,
		groupChildren: [...d3.rollup(lv12.lv2Children, (g) => g.length, (d) => d).entries()]
	}));

	treeMapLv1Data?.forEach((lv1) => {
		const groupLv1ChildrenRes = [...d3.rollup(lv1.lv1Children, (g) => g.length, (d) => d).entries()];

		map.get("children")
		.push({
			name: lv1.inspectionTasksL2,
			id: lv1.inspectionTasksL2,
			children: groupLv1ChildrenRes.map((item) => ({
				name: item[0],
				// size: item[1],
				children: groupLv12ChildrenRes
				?.find(((el) => (el.defectLevel1 === item[0]) && (el.inspectionTasksL2 === lv1.inspectionTasksL2)))
				?.groupChildren
				?.map((lv3) => ({
					name: lv3[0],
					size: lv3[1]
				}))
			}))
		});
	});

	const obj = Array.from(map).reduce((obj, [key, value]) => (
		Object.assign(obj, { [key]: value })
	), {});

	return obj;
};

const getTreeMapData = {
	CountryTreeMapData,
	EquipmentTreeMapData,
	InspectionTreeMapData
};

export default getTreeMapData;
