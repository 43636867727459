import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { max } from "d3-array";
import { Bar, LinePath } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom } from "@visx/axis";
import * as allCurves from "@visx/curve";
import { withTooltip, useTooltipInPortal } from "@visx/tooltip";
import { Text } from "@visx/text";
import { nanoid } from "nanoid";

// To find the elements that are present in base but not in array
function arrayDifference(array, base) {
	// Create a Set from each array to eliminate duplicates
	const set1 = new Set(array);

	// Use the filter method on the second array to keep only elements that are not in the first array
	const difference = base.filter((item) => !set1.has(item)).map((item) => ({ month: item, index: base.indexOf(item) }));

	return difference;
}

// accessors
const getDefaultX = (d) => d.month;
const getDefaultY = (d) => d.value;
const getDefaultFrequency = (d) => d.frequency;
const getDefaultTopInfo = (d) => d.topInfo;
const line1 = [{
	month: "Jan", value: 70
},
{
	month: "Feb", value: 75
},
{
	month: "Mar", value: 73
},
{
	month: "Apr", value: 65
},
{
	month: "May", value: 50
},
{
	month: "June", value: 30
}];

const line2 = [{
	month: "Jan", value: 110
},
{
	month: "Feb", value: 115
},
{
	month: "Mar", value: 110
},
{
	month: "Apr", value: 105
},
{
	month: "May", value: 110
},
{
	month: "June", value: 110
}];

const line3 = [{
	month: "Jan", value: 150
},
{
	month: "Feb", value: 145
},
{
	month: "Mar", value: 145
},
{
	month: "Apr", value: 145
},
{
	month: "May", value: 165
},
{
	month: "June", value: 175
}];

const columns = [
	{
		month: "Jan", frequency: 85
	},
	{
		month: "Feb", frequency: 70
	},
	{
		month: "Mar", frequency: 88
	},
	{
		month: "Apr", frequency: 120
	},
	{
		month: "May", frequency: 120
	},
	{
		month: "June", frequency: 120
	}
];

let tooltipTimeout;

const defaultColors = ["red", "orange", "green"];
/**
 * How to define MonthAnalysisNC section:
 * graph = linesAxisGroup + barsGroup
 * linesAxisGroup = lines + dots + tooltip + axisX
 * barGroup = bar + bar background + bar top circle + bar top info
 * @component
 */
// stroke-clarity_primary-red stroke-clarity_primary-yellow stroke-clarity_primary-orange stroke-clarity_primary-green
// fill-clarity_primary-red fill-clarity_primary-yellow fill-clarity_primary-orange fill-clarity_primary-green
const MonthAnalysisNC = ({
	appSlice,
	width = 500,
	height = 250,
	margin = {
		top: 0, left: 0, right: 0, bottom: 50
	},
	showPoints = true,
	linesNonConformities = [line1, line2, line3],
	lineNbSites = columns,
	getX = getDefaultX,
	getY = getDefaultY,
	getFrequency = getDefaultFrequency,
	getTopInfo = getDefaultTopInfo,
	linesColor = ["#FF475C", "#FFC362", "#CCDB73"],
	tooltipOpen,
	tooltipLeft,
	tooltipTop,
	tooltipData,
	hideTooltip,
	showTooltip,
	fillCircleColor = "#CECECE",
	fillBarColor = "#CECECE",
	graphTop = 15,
	barsGroupTop = 0,
	linesAxisGroupTop = 0,
	barTopInfoTop = -7,
	barTopInfoLeft = -2,
	linesGroupTop = 0,
	yScaleDomainFactor = 1,
	colScaleDomainFactor = 2,
	isRounded = true,
	maturityDisplay = false,
	colorScale = undefined,
	maturityColorScale = undefined,
	locales
}) => {
	// In case NC missing in linesNonConformities, we do the append
	const monthList = lineNbSites?.map((item) => item.month);

	// To insert the missing data for one or more periods in proper place by index defined in lineNBSites
	const ajustedLinesNonConformities = linesNonConformities.reduce((acc, cur) => {
		if (cur.list.length !== monthList.length) {
			const newCur = { ...cur };
			const diff = arrayDifference(cur.list.map((e) => e.month), monthList);

			for (let i = 0; i < diff.length; i++) {
				newCur.list = [...newCur.list.slice(0, diff[i].index), { month: diff[i].month, value: 0 }, ...newCur.list.slice(diff[i].index)];
			}
			return [...acc, newCur];
		}
		return [...acc, cur];
	}, []);

	// Internationalization hook
	const { t } = useTranslation(locales);
	// client parameters
	const clientParameters = useSelector(appSlice.selectClientParameters);

	function getLongest(data) {
		const lengths = data.map((a) => a.list.length);
		const index = lengths.indexOf(Math.max(...lengths));
		return index < 0 ? 0 : index;
	}

	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;

	const dataLength = ajustedLinesNonConformities[getLongest(ajustedLinesNonConformities)]?.list.length;
	const graphLeft = (xMax / dataLength) / 2;

	// populate line with related color. If no color scale is defined use defaultColors array
	const linesNCWithColor = ajustedLinesNonConformities.map((node, i) => {
		const lineColor = maturityDisplay
			? maturityColorScale
				? maturityColorScale?.filter((colorObj) => colorObj.label === node._id)
				: [{ color: defaultColors[i] }]
			: colorScale
				? colorScale.filter((colorObj) => colorObj.label === node._id)
				: [{ color: defaultColors[i] }];
		const color = lineColor[0]?.color;
		return node.list.map((listEl) => ({ ...listEl, color }));
	});

	const allData = Array.prototype.concat.apply([], (linesNCWithColor));
	const linesData = linesNCWithColor;
	const xScale = useMemo(
		() => scaleBand({
			range: [0, xMax],
			domain: allData.map(getX)
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[allData, xMax]
	);

	const yScale = useMemo(
		() => scaleLinear({
			range: [0, yMax],
			domain: [max(allData, getY) * yScaleDomainFactor, 0]
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[allData, yMax]
	);

	const colScale = scaleLinear({
		range: [0, yMax],
		domain: [max(lineNbSites, getFrequency) * colScaleDomainFactor, 0],
		clamp: true
	});

	const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
		scroll: true,
		detectBounds: true
	});

	const handleTooltip = (data) => {
		if (["string", "number"].includes(typeof data)) return data;
		if (typeof data === "object" && !Array.isArray(data)) {
			return Object.entries(data).map((e) => (<div
				key={`tooltipObject - ${nanoid()}`}
				className="my-2 mx-4">
				<span className=" font-semibold">{`${t(`tooltip.${e[0]}`)}: `}</span>
				<span>{`${e[1]}`}</span>
			</div>));
		} return null;
	};

	// There are cases when two numbers are placed too close to each other on the vertical
	// bar which results in overlapping. We group the data by months to access all the values
	// of the specific month and we check (the method calcY) the distance between the items.
	// If it's too close we apply vertical offset to prevent overlapping.
	const merged = linesData.flat(1);
	const groupedByMonth = merged.reduce((grouped, item) => {
		const { month } = item;
		if (grouped[month] == null) grouped[month] = [];
		grouped[month].push(item);
		return grouped;
	}, {});

	// Tracking the values that have already been compared. For example if we have compared
	// 27 to 28 there is no need to compare 28 to 27.
	const hasBeenCompared = [];

	const calcY = (data) => {
		let positionOffset = 0;
		const objectsPerMonth = groupedByMonth[data.month];
		const posY = yScale(getY(data));
		// compare the y-position of the current item to the y-position of all the itmes for the same month
		// because they are placed on the same vertical bar
		objectsPerMonth.forEach((el) => {
			// no need to compare the item to itself
			if (data.value !== el.value) {
				const verticalPos = yScale(getY(el));
				// Sort so that we always substract from the bigger value to avoid the results
				// with minus sign.
				const valuesToCompare = [posY, verticalPos].sort((a, b) => b - a);
				if (valuesToCompare[0] - valuesToCompare[1] < 5 && !hasBeenCompared.includes(el.value)) {
					positionOffset = 8;
					hasBeenCompared.push(data.value);
				}
			}
		});
		return yScale(getY(data)) - positionOffset;
	};
	return width < 10 ? null : (
		<div ref={containerRef}>
			<svg width={width} height={height} >
				<Group top={graphTop} left={graphLeft}>
					{lineNbSites.map((d, i) => {
						const relativeBarHeight = colScale(getFrequency(d)) ? colScale(getFrequency(d)) : 0;

						const month = getX(d);
						const barWidth = 7;
						const barHeight = yMax - relativeBarHeight;
						const barX = xScale(getX(d)) - barWidth / 2;
						const barY = yMax - barHeight + barsGroupTop;

						return <g key={i}>
							<text x={barX + barTopInfoLeft} y={barTopInfoTop} className="text-xs">{getTopInfo(d)}</text>
							<rect width={barWidth} height={yMax} x={barX} y={0} fill="#FBFBFB" />
							{isRounded && <circle
								r={3}
								cx={xScale(getX(d))}
								cy={colScale(getFrequency(d)) + 1}
								fill={fillCircleColor}
								stroke="transparent"
								strokeWidth={5}
								onMouseOut={() => {
									tooltipTimeout = window.setTimeout(() => {
										hideTooltip();
									}, 1000);
								}}
								onMouseOver={() => {
									if (tooltipTimeout) clearTimeout(tooltipTimeout);
									showTooltip({
										tooltipData: getFrequency(d),
										tooltipTop: colScale(getFrequency(d)),
										tooltipLeft: xScale(getX(d))
									});
								}}
							/>}
							<Bar
								key={`bar-${month}`}
								x={barX}
								y={barY}
								width={barWidth}
								height={barHeight}
								fill={fillBarColor}
							/>
						</g>;
					})}
					<Group top={linesAxisGroupTop}>
						{linesData.map((lineData, i) => (
							<g key={`lines-${i}`}>
								<Group top={linesGroupTop}>
									<LinePath
										curve={allCurves.curveMonotoneX}
										data={lineData}
										x={(d) => xScale(getX(d)) ?? 0}
										y={(d) => yScale(getY(d)) ?? 0}
										// style={{ stroke: lineData[0].color }}
										className={`stroke-clarity_primary-${lineData[0].color}`}
										strokeDasharray={lineData[1]?.strokeDasharray ? "5, 5" : "0, 0"}
									/>
									{showPoints &&
										lineData.map((d, j) => <g key={i + j}>
											<circle
												// style={{ fill: d.color }}
												className={`fill-clarity_primary-${d.color}`}
												r={4}
												cx={xScale(getX(d))}
												cy={yScale(getY(d))}
												// fill={linesColor[i]}
												stroke="transparent"
												strokeWidth={2}
												onMouseOut={() => {
													tooltipTimeout = window.setTimeout(() => {
														hideTooltip();
													}, 500);
												}}
												onMouseOver={() => {
													if (tooltipTimeout) clearTimeout(tooltipTimeout);
													showTooltip({
														tooltipData: d.tooltip || getY(d),
														tooltipTop: yScale(getY(d)),
														tooltipLeft: xScale(getX(d))
													});
												}}
											/>
											<Text
												x={xScale(getX(d)) + 6}
												y={calcY(d)}
												width="50"
												verticalAnchor="start"
												style={{ fontSize: "0.6rem" }}
											>
												{getY(d)}

											</Text>
										</g>
										)}
								</Group>
								<AxisBottom
									hideAxisLine
									hideTicks
									top={yMax}
									left={-graphLeft}
									scale={xScale}
									stroke="black"
									tickLabelProps={() => ({
										fontSize: 11,
										fontWeight: "1px",
										textAnchor: "middle"
									})}
								/>
								{tooltipOpen && (
									<foreignObject x={`${tooltipLeft}`} y={`${tooltipTop}`} width="100" height="100" >
										<TooltipInPortal
											key={Math.random()} // needed for bounds to update correctly
											left={tooltipLeft}
											top={tooltipTop}
										>
											{handleTooltip(tooltipData)}
										</TooltipInPortal>

									</foreignObject>
								)}
							</g>
						))}
					</Group>
				</Group>
			</svg>
		</div>
	);
};

MonthAnalysisNC.propTypes = {
	/** defiens appslice */
	appSlice: PropTypes.object,
	/** left margin for bar top info */
	barTopInfoLeft: PropTypes.number,
	/** top margin for bar top info */
	barTopInfoTop: PropTypes.number,
	/** variable to adjust bar group y position */
	barsGroupTop: PropTypes.number,
	/** colScale domain scale factor */
	colScaleDomainFactor: PropTypes.number,
	/** fil color of the bars */
	fillBarColor: PropTypes.string,
	/** fill color of rounded tip */
	fillCircleColor: PropTypes.string,
	/** frequency accessor */
	getFrequency: PropTypes.func,
	/** top info accessor */
	getTopInfo: PropTypes.func,
	/** x axis accessor */
	getX: PropTypes.func,
	/** y axis accessor */
	getY: PropTypes.func,
	/** graph top positionning */
	graphTop: PropTypes.number,
	/** defines height */
	height: PropTypes.number,
	/** */
	hideTooltip: PropTypes.func,
	/** */
	lineNbSites: PropTypes.array,
	/** */
	linesAxisGroupTop: PropTypes.number,
	/** */
	linesColor: PropTypes.array,
	/** */
	linesGroupTop: PropTypes.number,
	/** */
	linesNonConformities: PropTypes.array,
	/** */
	margin: PropTypes.object,
	/** */
	showPoints: PropTypes.bool,
	/** */
	showTooltip: PropTypes.func,
	/** */
	tooltipData: PropTypes.any,
	/** */
	tooltipLeft: PropTypes.number,
	/** */
	tooltipOpen: PropTypes.bool,
	/** */
	tooltipTop: PropTypes.number,
	/** */
	width: PropTypes.number,
	/** */
	yScaleDomainFactor: PropTypes.number,
	/** */
	isRounded: PropTypes.bool,
	/** toggle maturity display */
	maturityDisplay: PropTypes.bool,
	/** color scale */
	colorScale: PropTypes.array,
	/** color scale for maturity display */
	maturityColorScale: PropTypes.array,
	/** locales */
	locales: PropTypes.string
};

export default withTooltip(MonthAnalysisNC);
