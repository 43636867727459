import getValueNestedObject from "../../utils/GetValueNestedObject";

function GetMenuValues(menuData, activeRefine, displayField, sortKey1, sortKey2 = undefined) {
	const uniqueTags = [];

	if (!menuData || !menuData.length === 0) {
		return uniqueTags;
	}

	if (typeof menuData[0] !== "object") {
		menuData
		.slice()
		.sort((a, b) => (a > b ? 1 : -1))
		.forEach((item) => {
			uniqueTags.push({
				value: item,
				label: item
			});
		});
		return uniqueTags;
	}

	const uniqueKeys = [];
	menuData
	.slice()
	.sort((a, b) => sortKey1
		? sortKey2
			? (typeof a[sortKey1] !== "string"
				? a[sortKey1] - b[sortKey1]
				: a[sortKey1].localeCompare(b[sortKey1]))
			|| (typeof a[sortKey2] !== "string"
				? a[sortKey2] - b[sortKey2]
				: a[sortKey2].localeCompare(b[sortKey2]))
			: a[sortKey1] > b[sortKey1] ? 1 : -1
		: getValueNestedObject(a, displayField || activeRefine) > getValueNestedObject(b, displayField || activeRefine) ? 1 : -1)
	.forEach((row) => {
		const tag = getValueNestedObject(row, activeRefine);

		if (tag !== null && tag !== undefined) {
			if (!displayField && Array.isArray(tag)) {
				tag.forEach((item) => uniqueTags.push({
					value: item,
					label: item
				}));
			} else if (typeof tag !== "string") {
				uniqueTags.push({
					value: tag,
					label: (displayField ? getValueNestedObject(row, displayField) : tag)
				});
			} else {
				tag.split("/").forEach((item) => {
					if (uniqueKeys.indexOf(item) === -1) {
						uniqueKeys.push(item);
						uniqueTags.push({
							value: item,
							label: (displayField ? getValueNestedObject(row, displayField) : item)
						});
					}
				});
			}
		}
	});

	return uniqueTags;
}

export default GetMenuValues;
