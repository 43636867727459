import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function PageHeader({
	page, Picto, textColorClass = "text-supplyr_primary-menu", locales, children
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<div className="flex justify-center items-center w-full pt-4 h-44" >
			<div className="flex justify-between items-center w-full h-full py-4">
				<div className="flex flex-grow items-center justify-between">
					<div className="relative flex flex-col md:pl-20 z-0">
						<div className={`absolute -top-16 left-0 opacity-40 ${ textColorClass}`}
							style={{ zIndex: -1 }}>
							<Picto style={{ fontSize: 240 }} />
						</div>
						<p className="font-semibold text-2xl md:text-5xl">{t(`${page}.header.title`)}</p>
						<p className="pt-3 font-light pr-20 text-sm md:text-base">{t(`${page}.header.subTitle`)}</p>
					</div>
				</div>
				{children &&
                    <div className= "hidden md:block self-center alphaBlur rounded-xl px-6 pb-4">
                    	{children}
                    </div>
				}
			</div>
		</div>
	);
}

PageHeader.propTypes = {
	page: PropTypes.string,
	Picto: PropTypes.object,
	textColorClass: PropTypes.string,
	locales: PropTypes.string,
	children: PropTypes.object
};

export default PageHeader;
