import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import BusinessIcon from "@mui/icons-material/Business";
import PinDropIcon from "@mui/icons-material/PinDrop";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { domainsOptions } from "../../apps/configs/ClarityConfig";
import IF from "../../utils/IF";

function ClarityTooltip({ appSlice, marker, locales }) {
	// Load the store hook
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);

	// Load data from the store
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const clarityClientParameters = activeDatasets?.clarityClientParameters?.data[0];
	const { siteId } = marker;

	// Internationalization hook
	const { t } = useTranslation(locales);

	const handleRedirect = () => {
		const url = "/clarity";
		// put the params in the localstorage so we could get them in clarity app for refining.
		const { spaceLabel } = clarityClientParameters;
		window.localStorage.setItem("ataglanceURLparams", JSON.stringify({ projectname: clientParameters.client, spaceLabel, siteId }));
		navigate(url);
	};

	return (
		<div className="flex flex-col items-center w-64 pl-2 pt-1 gap-y-3 bg-white -mt-3 -ml-3">
			<div className="flex flex-shrink-0 items-center text-xl gap-x-2 w-full">
				<BusinessIcon fontSize="inherit" />
				<p className="w-44 text-sm font-semibold line-clamp-1 pt-1">
					{marker.auditedCompany}
				</p>
			</div>
			<div className="flex flex-wrap w-full gap-2">
				<div className="self-start flex items-center text-base px-2 h-8  rounded-lg border">
					<PinDropIcon fontSize="normal" />
					<p className="text-xs pl-1">{marker.country}, {marker.city}</p>
				</div>
				{marker.domain && Array.from(new Set(marker.domain)).map((domain, i) => {
					const infoDomain = domainsOptions[domain];
					const Picto = infoDomain?.logo;

					return (<a key={i} className="flex items-center text-base text-black px-2 h-8 rounded-lg border
                    hover:text-alpha_primary-default hover:border-alpha_primary-default"
					target="_blank" rel="noopener noreferrer"
					href={`https://odsdatahub.bureauveritas.com/reports/${marker.report?.[i]}`}>
						{typeof Picto === "object" ?
							<Picto style={{ fontSize: "1rem" }} />
							:
							<img src={Picto} alt={Picto} className="w-4 h-4 opacity-100 filter brightness-0" />
						}
						<p className="text-xs pl-1">
							{t(`domains.${infoDomain?.locale}.name`)}
						</p>
					</a>);
				})}
			</div>
			<div className="flex items-center text-base px-4 h-8 rounded-lg border hover:border-alpha_primary-default hover:text-alpha_primary-default"
				onClick={() => {
					if (menu === "MAP") {
						handleRedirect();
					} else {
						dispatch(appSlice.actions.refine([{ key: "siteId", value: marker.siteId }]));
					}
				}}>
				<BusinessIcon fontSize="normal" />
				<p className="text-xs pl-1 cursor-pointer">{t("map.tooltip.viewSite")}</p>
			</div>
		</div>
	);
}

ClarityTooltip.propTypes = {
	appSlice: PropTypes.object,
	marker: PropTypes.object,
	locales: PropTypes.string
};

export default ClarityTooltip;
