import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import BusinessIcon from "@mui/icons-material/Business";
import PinDropIcon from "@mui/icons-material/PinDrop";

import Moment from "react-moment";
import { getMarkerColor, getScoreColor } from "../../apps/configs/CompassConfig";
import CompassIndicatorCard from "./CompassIndicatorCard";

function CompassSiteCard({
	appSlice, siteData, demoReportLink = undefined, locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load data from the store
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const activeDatasets = useSelector(appSlice.selectDatasets);

	if (!siteData) {
		return null;
	}

	const { finalRating } = siteData.additionalInfo;

	return (
		<div className="flex justify-between flex-col w-full h-full gap-y-2">
			<div className="gap-y-1 ">
				<div className="flex gap-x-1 w-full">
					<BusinessIcon className="text-chargescan_primary-default text-2xl xl:text-3xl"/>
					<p className="font-semibold line-clamp-2 w-full text-xl xl:text-2xl">
						{siteData.auditedCompany}
					</p>
				</div>
				<div className="flex items-center gap-x-2 font-light text-sm rounded-lg px-2 py-1 border">
					<PinDropIcon fontSize="small" />
					<p>{(`${siteData.site.city }-`) ?? ""}{siteData.site.country}</p>
				</div>
			</div>
			<div className="flex gap-y-2 justify-center j pt-4 w-full">
				{!clientParameters?.scoreBased && finalRating && <p className="rounded px-4 py-2 font-semibold uppercase"
					style={{ backgroundColor: getMarkerColor(finalRating) }}>
					{finalRating}
				</p>}
				{clientParameters?.scoreBased && <p className="rounded px-4 py-2 text-xl font-semibold uppercase"
					style={
						{ backgroundColor: getScoreColor(siteData?.score) }
					}
				>
					{(clientParameters?.scoringBySection
					&& (activeRefines?.section1Label !== undefined || activeRefines?.section2Label !== undefined)
					&& activeDatasets?.avgScoreBySection?.data[0]?.score)
						? `Score: ${(activeDatasets.avgScoreBySection.data[0].score * 100)?.toFixed(2)}`
						: (!Number.isNaN(siteData?.score)
						&& (typeof siteData?.score === "number")
						&& `Score : ${(siteData.score * 100)?.toFixed(2)}`)}
				</p>}
			</div>
			<div className="flex justify-center gap-x-6 items-center text-sm">
				<p className="flex items-center px-2 bg-red-100 rounded-sm">
                        NC : {siteData.nc}
				</p>
				<p className="flex items-center px-2 bg-red-500 rounded-sm text-white">
                        CNC : {siteData.cnc}
				</p>
			</div>
			<div className="flex justify-between items-baseline">
				<p className="text-medium pt-6">
					{t("siteComponent.lastAudit")} <Moment format="YYYY/MM/DD">{siteData.publicationDate}</Moment>
				</p>
				{demoReportLink && <a target="_blank" rel="noopener noreferrer"
					href={`https://odsdatahub.bureauveritas.com/reports/${demoReportLink}`}
					className="flex justify-center items-center space-x-1
                                    border border-gray-200 rounded-lg px-3 py-2 text-medium font-light w-28
                                    hover:border-gray-400" >
					<InsertDriveFileIcon style={{ fontSize: "1rem" }} />
					<p className="flex flex-shrink-0">{t("siteComponent.viewReport")}</p>
				</a>}
			</div>
		</div>
	);
}

CompassSiteCard.propTypes = {
	appSlice: PropTypes.object,
	siteData: PropTypes.object,
	demoReportLink: PropTypes.string,
	locales: PropTypes.string
};

export default CompassSiteCard;
