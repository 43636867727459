import React, {
	useState, useMemo
} from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";

import DoneAllIcon from "@mui/icons-material/DoneAll";

import NestedArray from "./AdminUserNestedArray";

import UpdateFormBtnGroup from "../button/UpdateFormBtnGroup";
import Popconfirm from "../modal/Popconfirm";

import { appDefinition, searchSelector } from "../../apps/configs/AdminConfig";

import { deleteDoc } from "../../data/slices/createAdminSlice";
import { adminUserDelete } from "./AdminFormOnConfirm";

// remove k key when field type is array
const RemoveKey = (submitValue) => {
	// remove key k in case field is type of array of object
	const sumbitKeyValuePair = Object.entries(submitValue);

	const removeKeyK = sumbitKeyValuePair.reduce((arr, cur) => {
		let getValue;
		if (Array.isArray(cur[1])) {
			getValue = cur[1].map((el) => (el.k));
			return { ...arr, [cur[0]]: getValue };
		}

		return { ...arr, [cur[0]]: cur[1] };
	}, {});

	return removeKeyK;
};

const flattenObj = (obj, parent, res = {}) => {
	Object.keys(obj).forEach((key) => {
		const propName = parent ? `${parent}.${key}` : key;

		if (typeof obj[key] === "object") {
			flattenObj(obj[key], propName, res);
		}

		res[propName] = obj[key];
	});

	return res;
};

const userRightStructureBuilder = (res) => ({
	_id: res._id,
	ADMIN: res.AppScope.reduce((acc, cur) => {
		const { name, nestedArray } = cur;
		return {
			...acc,
			[name.toUpperCase()]: nestedArray.reduce((a, c) => ({ ...a, [c.k.toUpperCase()]: {} }), {})
		};
	}, {})
});

const AdminuUserForm = ({
	mode,
	data,
	appSlice,
	clearRefineKeys,
	targetDocAccessor,
	renderButtonGroup = true,
	screenHeight,
	onConfirm,
	setAlert
}) => {
	const dispatch = useDispatch();
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const [confirmModal, setconfirmModal] = useState(false);

	const targetDocument = useMemo(() => (mode === "updateUser" ? data.find(targetDocAccessor) : data), [data, targetDocAccessor, mode]);

	const flatObj = flattenObj(targetDocument);

	// when mode updateUser it will return [{name:"CLARITY",k:[{k: 'AUCHAN'},{k: 'BOUYGUES'}]},{name:'SUPPLYR',k[{k:'SHELL}]}]
	// otherwise which means createUser: [{ name: "", nestedArray: [{ k: "" }] }]
	const lv2Keys = mode === "updateUser"
		? Object.keys(flatObj).filter((item) => item.split(".").length === 2 && item.split(".").at(0) === "ADMIN")
		.map((item) => ({ name: item.split(".").at(1), nestedArray: Object.keys(flatObj[item]).map((el) => ({ k: el })) }))
		: [{ name: "", nestedArray: [{ k: "" }] }];

	const defaultValue = {
		_id: targetDocument._id ?? "",
		AppScope: lv2Keys
	};

	const {
		control, register, handleSubmit, formState: { errors }, reset, getValues, setValue, watch
	} = useForm({
		defaultValues: defaultValue,
		criteriaMode: "firstError",
		shouldFocusError: true
	});

	const onSubmit = (e) => {
		e.preventDefault();
		setconfirmModal(true);
	};

	const submit = (submitValue) => {
		onConfirm({ res: userRightStructureBuilder(submitValue), setconfirmModal });
	};

	// prevent enter event from triggering form to submit
	const checkKeyDown = (e) => {
		if (e.key === "Enter") e.preventDefault();
	};

	// dropdown content builder
	const searchOptionBuilder = (activeRefines, field) => {
		if (typeof searchSelector(activeRefines.secLevelMenu, field) === "string") {
			return activeDatasets[searchSelector(activeRefines.secLevelMenu, field)]?.data;
		}

		// if not return string type then return type is array, which means we concatenate response from multiple collections
		const res = searchSelector(activeRefines.secLevelMenu, field)?.map((collection) => activeDatasets[collection].data).flat()
		?.filter((item, i, self) => self?.findIndex((item2) => (item2?.label === item?.label)) === i);

		return res;
	};

	return (
		<>
			{
				confirmModal && (
					<Popconfirm
						title= {`${mode.slice(0, 1).toUpperCase() + mode.slice(1, 6)} user`}
						description={`Are you sure you want ${mode.slice(0, 6)} ${mode === "updateUser" ? "this" : "a"} user?`}
						confirmBtnText="Confirm"
						onClickConfirm={handleSubmit(submit)}
						onClickCancel={() => setconfirmModal(false)}
					/>
				)
			}
			<UpdateFormBtnGroup appSlice={appSlice} locales={appDefinition.locales} renderButtonGroup={renderButtonGroup}
				defaultValue={defaultValue} reset={reset} clearRefineKeys={clearRefineKeys} renderRollBackBtn={false} renderDeleteBtn={true}
				deleteFunction={deleteDoc} deleteFuncProps={{ id: targetDocument._id, setAlert }} onDelete={adminUserDelete}/>

			<form className="flex flex-col px-2 my-4 space-y-6 overflow-y-auto" style={{ height: screenHeight - 260 }}
				onSubmit={onSubmit} onKeyDown={(e) => checkKeyDown(e)}>

				<label key={"_id"} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
					text-admin_primary-default border px-2 py-3 rounded-lg">
					<span className="w-min h-min p-0.5 absolute -top-3 bg-white
						uppercase text-sm flex justify-center items-center">
						{"User"}
					</span>
					<input {...register("_id", { required: true })}
						className="p-2 my-2 text-black border rounded-lg bg-white focus:outline-none focus:ring-2
							focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"/>
					{errors?._id && <span className="text-red-600 text-sm">This field is required</span>}
				</label>

				<label key={"App"} className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
								text-admin_primary-default border px-2 py-3 rounded-lg">
					<span className="w-min h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
						{"ADMIN"}
					</span>

					<NestedArray {...{
						control,
						register,
						searchOptionBuilder,
						appSlice,
						activeRefines,
						activeDatasets,
						getValues,
						setValue,
						watch
					}} />
				</label>

				<div className="flex flex-col space-y-4 mt-10">
					<button className="border bg-admin_primary-default hover:bg-admin_primary-dark
                    text-white font-bold py-1 px-3 rounded uppercase" type="submit">
						<DoneAllIcon />
					</button>
				</div>

			</form>
		</>
	);
};

AdminuUserForm.propTypes = {
	mode: PropTypes.string.isRequired,
	data: PropTypes.any.isRequired,
	appSlice: PropTypes.object,
	clearRefineKeys: PropTypes.array,
	targetDocAccessor: PropTypes.func,
	renderButtonGroup: PropTypes.bool,
	screenHeight: PropTypes.number,
	onConfirm: PropTypes.func,
	setAlert: PropTypes.func
};

export default AdminuUserForm;
