import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { max, mean } from "d3-array";
import { BarStackHorizontal } from "@visx/shape";
import { Text } from "@visx/text";
import { Group } from "@visx/group";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";

import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import { SvgIcon, Tooltip } from "@mui/material";

import { list } from "postcss";
import Loading from "../../utils/Loading";
import IF from "../../utils/IF";
import { domainsOptions } from "../../apps/configs/ClarityConfig";

const defaultLineHeight = 40;

const defaultMargin = {
	top: 20,
	left: 10,
	right: 30,
	bottom: 20
};
/**
 * Horizontal bar graph
 * @category Graphs
 * @component
 */
function ColumnGraph({
	appSlice = null,
	dataset = undefined,
	filterData = (d) => d,
	mapData = (d) => d,
	sortScoreDimension = false,
	color = "#1876bd",
	textColor = "Black",
	textSize = 11,
	width,
	height,
	margin = defaultMargin,
	lineHeight = defaultLineHeight,
	strokeWidth = 6,
	backgroundBarColor = "#F6F6F6",
	gradientStartBarColor = "#8C92CD",
	gradientEndBarColor = "#4C57B1",
	nonConformities = false,
	nonConformitiesGreen = false,
	avgLineColor = undefined,
	hideAvgLine = false,
	ncMargin = 10,
	getScore = (d) => d.score,
	scoreScaleMax = false,
	scoreDecimal = 0,
	targets = undefined,
	getModule = (d) => d.label,
	getColor = undefined,
	getPicto = undefined,
	getNC = (d) => d.nc,
	getNCbis = (d) => d.nc,
	sortData = (a, b) => (sortScoreDimension ? getScore(a) > getScore(b) : getModule(a) < getModule(b)) ? 1 : -1,
	itemOnClick = undefined,
	refineKeys = undefined,
	locales,
	highlight,
	prefix = "",
	hasReportLink = false,
	placeholderDataset = undefined,
	unit = "",
	bubbleColor = "#ff475c",
	bubbleTextColor = "white",
	bubblePercent = false,
	colorsByModule = false,
	legend = false,
	niceScale = true
}) {
	const { t } = useTranslation(locales);

	// Load data from the store
	const placeholderData = useSelector(appSlice.selectDatasets)[placeholderDataset];
	const graphData = useSelector(appSlice.selectDatasets)[dataset];
	const loadStatus = useSelector(appSlice.selectLoadDataStatus);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const selectedCountries = Array.isArray(activeRefines?.country) ? activeRefines.country : [activeRefines.country];
	const dispatch = useDispatch();
	const [showLegend, setShowLegend] = useState(false);

	if (
		graphData === undefined ||
			graphData.data === undefined ||
			graphData.data.length === 0
	) {
		return null;
	}

	if (loadStatus !== "idle") {
		return <Loading isComponent />;
	}
	// If placeholder data to add, create a new Array and merge datas
	let mergedData = [...graphData.data];
	if (placeholderData) {
		placeholderData.data.forEach((pl) => {
			if (!graphData.data.map((el) => getModule(el)).some((el) => el === getModule(pl))) {
				return mergedData = [...mergedData, pl];
			}
		});
	}

	const data = [ ...mergedData ]
	.map(mapData)
	.filter(filterData)
	.sort(sortData);
	// Average line
	const average = mean(data.map((element) => getScore(element)));
	const maximum = (scoreScaleMax) ? max([...data
	.map((element) => getScore(element)), ...(targets?.map((element) => element.value) ?? [])]) : 100;

	// scales
	const scoreScale = scaleLinear({
		domain: [0, scoreScaleMax ? maximum : 100],
		nice: niceScale

	});

	const moduleScale = scaleBand({
		domain: data.map(getModule),
		range: [0, 10000]
	});

	// get values on which to refine for each module and key, as object to optimize retrieval
	const refineValues = data?.reduce((acc, e) => {
		acc[getModule(e)] = refineKeys?.reduce((acc2, k) => {
			acc2[k] = e[k]; return acc2;
		}, {}); return acc;
	}, {});
	const colorScale = scaleOrdinal({
		domain: ["active"],
		range: [color, "grey"]
	});

	// calculate dynamic height if height not specified
	if (height === undefined) {
		height =
			Array.from(new Set(data.map(getModule))).length * lineHeight +
			margin.bottom +
			margin.top;
	}

	// If non Conformities, let some space for it
	const nonConformitiesMargin = (nonConformities || nonConformitiesGreen) ? strokeWidth * 2 + ncMargin : 0;

	// bounds
	const xMax = width - margin.left - margin.right - nonConformitiesMargin;
	const yMax = height - margin.top - margin.bottom;
	const compensationY = lineHeight / 2 + strokeWidth + 4;

	scoreScale.rangeRound([0, xMax]);
	moduleScale.rangeRound([yMax, 0]);

	function renderPicto(data) {
		const Picto = getPicto(data).picto;
		return (
			<Tooltip title={t(getPicto(data).locale)} placement="top">
				<Picto style={{ fontSize: textSize * 1.2 }}/>
			</Tooltip>
		);
	}

	// https://stackoverflow.com/a/51567564
	function isBackgroundLight(color) {
		const hex = color?.replace("#", "");
		const cR = parseInt(hex?.substring(0, 0 + 2), 16);
		const cG = parseInt(hex?.substring(2, 2 + 2), 16);
		const cB = parseInt(hex?.substring(4, 4 + 2), 16);
		const brightness = ((cR * 299) + (cG * 587) + (cB * 114)) / 1000;
		return brightness > 155;
	}

	const getTextFill = (bar) => {
		const fullBarLength = scoreScale.range()?.[1];
		// Calculate the width (pixels) of the colored bar if the colored bar is 95% filled.
		const ninetyFivePercentValue = (fullBarLength * 95) / 100;
		// If the color bar is 95% filled it means that the text will be placed
		// on the bar (see "getXposition") and it has to be white.
		if (bar.width >= ninetyFivePercentValue) {
			return "white";
		}
		return "black";
	};

	const getXposition = (bar, currentValue) => {
		// Format current value to remove the decimal numbers and convert it to string
		// to calculate the length (number of digits).
		let formatted;

		if (currentValue === null) {
			formatted = "0";
		} else {
			formatted = currentValue.toLocaleString("en", {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0
			});
		}

		// Count the length of the current value.
		const numberOfDigits = formatted.length;

		const fullBarLength = scoreScale.range()?.[1];
		// Calculate the width (pixels) of the colored bar if the colored bar is 95% filled.
		const ninetyFivePercentValue = (fullBarLength * 95) / 100;
		// If the colored bar is 95% filled we place the text value on the colored bar.
		if (bar.width >= ninetyFivePercentValue) {
			// For long values (those that exceed 4 digits) we'll need extra space.
			if (numberOfDigits > 4) {
				return bar.width - 70;
			}
			return bar.width - 30;
		}
		// Otherwise place the text value at the end of the colored bar.
		return bar.width + 10;
	};

	// Used for getting the colors out of the "domainsOptions" object
	const uniqueDomainsData = graphData?.data.filter((value, index, self) => self.findIndex((v) => v.dm === value.dm) === index);

	return width < 10 ? null : (
		<>
			<svg width={width} height={height}>
				<Group top={margin.top} left={margin.left}>
					<AxisLeft
						hideAxisLine
						hideTicks
						numTicks={data.length}
						scale={moduleScale}
						top={-1 * compensationY}
						stroke="black"
						tickStroke="black"
						tickLabelProps={() => ({
							fill: textColor,
							fontSize: textSize,
							fontWeight: 400,
							textAnchor: "start",
							dx: "0.5em"
						})}
						tickClassName= {`axisLeftLabel ${ refineKeys !== undefined ? "cursor-pointer" : "cursor-default"}`}
						tickComponent={ ({ formattedValue, ...tickProps }) => (
							<g>
								<foreignObject
									x={tickProps.x}
									y={tickProps.y - textSize}
									width="100%"
									height={30}
									onClick={() => {
										if (refineKeys !== undefined) {
											dispatch(appSlice.actions.refine(
												refineKeys.map((refineKey) => ({
													key: refineKey,
													value: refineValues?.[formattedValue]?.[refineKey]
												})))
											);
										}
									}}
								>
									<div className="flex gap-x-1 pl-1 line-clamp-1" style={{ fontSize: textSize, width: width - 60 }}
										title={formattedValue}>
							        {getPicto !== undefined
                                    && renderPicto(data?.[data.length - ((tickProps.y + lineHeight / 2) / lineHeight)])}
										{prefix ? t(`${prefix}.${formattedValue}`) : t(`${formattedValue}`)}
									</div>
								</foreignObject>
							</g>
						)}
					/>

					<AxisBottom
						top={yMax - compensationY}
						scale={scoreScale}
						numTicks={4}
						stroke="transparent"
						tickStroke="transparent"
						tickLabelProps={() => ({
							fill: textColor,
							fontSize: textSize,
							textAnchor: "middle",
							dx: "0.5em"
						})}
					/>
					<BarStackHorizontal
						data={data}
						keys={["label"]}
						height={yMax}
						order="descending"
						value={getScore}
						color={colorScale}
						y={getModule}
						xScale={scoreScale}
						yScale={moduleScale}
					>
						{(barStacks) => barStacks[0].bars.map((bar, index) => {
							if (getScore(bar.bar.data) === undefined) {
								return;
							}
							return (
								<g
									key={`barstack-horizontal-${barStacks[0].index}-${bar.index}`}
								>
									{highlight && selectedCountries.includes(bar.bar.data.country) && (
										<>
											<rect className="blur-sm"
												x={bar.x - 1}
												y={(bar.y - strokeWidth / 2) }
												rx={strokeWidth / 2}
												ry={strokeWidth / 2}
												width={xMax + 2}
												height={strokeWidth + 1}
												fill={"#61C7FF"}
											/>
											<rect
												x={bar.x - 7}
												y={(bar.y - strokeWidth / 2) - 1.5}
												rx={(strokeWidth / 1.5)}
												ry={(strokeWidth)}
												width={xMax + 8.5}
												height={strokeWidth + 3}
												fill={"#61C7FF"}
											/>
										</>
									)}
									<rect
										x={bar.x - 4.25}
										y={bar.y - strokeWidth / 2}
										rx={strokeWidth / 2}
										width={xMax + 8.5}
										height={strokeWidth}
										fill={backgroundBarColor}
									/>
									<defs>
										<linearGradient
											id="colGraph"
											x1={bar.x}
											y1={bar.y}
											x2={bar.width}
											y2={bar.y}
											gradientUnits="userSpaceOnUse"
										>
											<stop offset="0%" stopColor={gradientStartBarColor} />
											<stop offset="100%" stopColor={gradientEndBarColor} />
										</linearGradient>
									</defs>
									{getScore(bar.bar.data) &&
									// display filled line if value exists, else display placeholder

									<line
										x1={bar.x}
										y1={bar.y}
										x2={bar.width}
										y2={bar.y}
										strokeWidth={strokeWidth}
										stroke={bar.bar.data.inactive ? "grey" : getColor ? getColor(colorsByModule ? bar.bar.data
											: getScore(bar.bar.data)) : gradientEndBarColor }
										strokeLinecap="round"
										className={refineKeys !== undefined ? "cursor-pointer" : ""}
										onClick={() => {
											if (refineKeys !== undefined) {
												dispatch(appSlice.actions.refine(
													refineKeys.map((refineKey) => ({ key: refineKey, value: bar?.bar?.data[refineKey] }))
												));
											}
										}}

									>
										<animate
											attributeName="x2"
											from="0"
											to={`${bar.width}`}
											dur="1s"
										/>
									</line>
									}

									<Text
										x={getXposition(bar, getScore(bar.bar.data))}
										y={bar.y - strokeWidth / 2}
										width="50"
										verticalAnchor="start"
										style={{
											fontSize: `${(strokeWidth - 1) / 10}rem`,
											fontWeight: "normal",
											fill: getTextFill(bar)
										}}
									>
										{bar && bar.bar && bar.bar.data && (getScore(bar.bar.data) >= 0 && getScore(bar.bar.data) !== null)
											? `${getScore(bar.bar.data)?.toLocaleString("en-US", { maximumFractionDigits: scoreDecimal })}${unit}`
											: bar.bar.data.placeholder || "nc"}
									</Text>

									{nonConformities && getNC(bar?.bar?.data) > 0 && (
										<g>
											<circle
												cx={xMax + nonConformitiesMargin}
												cy={bar.y}
												r={(strokeWidth * 3) / 2}
												fill={bubbleColor}
											/>
											<Text
												x={xMax + nonConformitiesMargin}
												y={bar.y}
												textAnchor="middle"
												verticalAnchor="middle"
												fill={bubbleTextColor}
												style={{ fontSize: `${(strokeWidth - 1) / 10}rem` }}
											>
												{bar && bar.bar && bar.bar.data
													? `${getNC(bar.bar.data)} ${bubblePercent ? "%" : ""}`
													: "nc"}
											</Text>
										</g>
									)}
									{nonConformities && getNCbis(bar?.bar?.data) > 0 && (
										<g>
											<circle
												cx={xMax + nonConformitiesMargin * 2}
												cy={bar.y}
												r={(strokeWidth * 3) / 2}
												fill="orange"
											/>
											<Text
												x={xMax + nonConformitiesMargin * 2}
												y={bar.y}
												textAnchor="middle"
												verticalAnchor="middle"
												fill="white"
												style={{ fontSize: `${(strokeWidth - 1) / 10}rem` }}
											>
												{bar && bar.bar && bar.bar.data
													? getNCbis(bar.bar.data)
													: "nc"}
											</Text>
										</g>
									)}

									{nonConformitiesGreen && getNC(bar?.bar?.data) !== "n/a" && (
										<g
											className={`
										${itemOnClick !== undefined ? "cursor-pointer" : ""}
										h-[300]`}
											onClick={() => (itemOnClick !== undefined && itemOnClick(bar.bar.data))}
										>
											<rect
												x={xMax + nonConformitiesMargin - ((strokeWidth * 3) / 2)}
												y={bar.y - strokeWidth}
												rx={strokeWidth / 2}
												width={strokeWidth * 3}
												height={strokeWidth * 2}
												fill="#CCDB73"
											/>
											<Text
												x={xMax + nonConformitiesMargin}
												y={bar.y + 1}
												textAnchor="middle"
												verticalAnchor="middle"
												fill="black"
												fontWeight="bold"
												style={{ fontSize: `${(strokeWidth - 2) / 10}rem` }}
											>
												{bar && bar.bar && bar.bar.data
													? `${getNC(bar.bar.data) }`
													: "nc"}
											</Text>

										</g>
									)}
									{activeRefines?.siteId && hasReportLink &&
									<a
										href={`https://odsdatahub.bureauveritas.com/reports/${bar.bar.data.assignmentTechnicalid}`}
										target="_blank" rel="noreferrer"
										className="hover:text-clarity_primary-netZeroAlt active:text-clarity_primary-accent"
									>
										<SvgIcon component={DescriptionRoundedIcon}
											width="20" height="20" x={xMax + (nonConformitiesMargin + strokeWidth * 2)}
											y={bar.y - (strokeWidth * 1.2)} viewBox="0 0 20 25"
										/>
									</a>}
								</g>

							);
						})}
					</BarStackHorizontal>
				</Group>
				{!hideAvgLine && average > 0 && (
					<Tooltip
						title={
							<div className="flex flex-col justify-center items-center">
								<span>Average</span>
								<span>{average}</span>
							</div>
						}
						followCursor={true}>
						<line className="cursor-pointer"
							x1={(average / maximum) * xMax + margin.left + strokeWidth / 2}
							y1={0 + defaultLineHeight / 2 }
							x2={(average / maximum) * xMax + margin.left + strokeWidth / 2}
							y2={yMax - defaultLineHeight / 2 + strokeWidth}
							strokeWidth="2"
							stroke={nonConformitiesGreen ? "white"
								: avgLineColor || "red"}
							strokeOpacity=".2"
						/>
					</Tooltip>

				)}
				{targets !== undefined && targets.map((target, t) => (
					<>
						<Tooltip key={`tooltip-${target}${t}`}
							title={
								<div className="flex flex-col justify-center items-center">
									<span>{target.label}</span>
									<span>{target.value}</span>
								</div>
							}
							followCursor={true}>
							<line key={`target ${ t}`} className="cursor-pointer"
								x1={(target.value / maximum) * xMax + margin.left + strokeWidth / 2}
								y1={0 + defaultLineHeight / 2 }
								x2={(target.value / maximum) * xMax + margin.left + strokeWidth / 2}
								y2={yMax - defaultLineHeight / 2 + strokeWidth}
								strokeWidth={target.stroke}
								stroke={target.color}
								strokeOpacity=".6"
							/>
						</Tooltip>
					</>
				)
				)}
				{/* <foreignObject width={width} height={height} x={0} y={0}>
			<div className="w-full h-full bg-red-400">yoooo</div>

				</foreignObject> */}
			</svg>
			<div className="flex justify-end mb-5">
				{targets && targets.map((target, i) => {
					const targetLineColor = target?.color;
					return <div key={`targetLegend-${i}`}className="flex justify-center items-center mx-4">
						<div style={{ backgroundColor: targetLineColor }}
							className="w-1 h-4 opacity-60">
						</div>
						<span className="text-small ml-1">{target.label}</span>
					</div>;
				})}
			</div>
			{legend &&
			<div className="flex justify-end items-center col-span-1 flex-wrap">
				<IF condition={!showLegend}>
					<div className="flex cursor-pointer hover:text-gray-500" onClick={() => setShowLegend(!showLegend)}>
						<span className="mx-2">Click for more information</span>
						{uniqueDomainsData?.map((k, i) => (
							<div key={`${k}-${i}`} className="flex justify-center items-center -mx-1" >
								<div className="rounded-full w-4 h-4 bg-black" style={{ background: domainsOptions[k?.dm]?.color }}></div>
							</div>
						))}
					</div>
				</IF>
				<IF condition={showLegend}>
					<div className="flex flex-wrap flex-row-reverse cursor-pointer hover:text-gray-500" onClick={() => setShowLegend(!showLegend)}>
						{uniqueDomainsData?.map((k, i) => (
							<div key={`${k}-${i}`} className="flex justify-center items-center mx-2">
								<div className="rounded-full w-2 h-2 bg-black" style={{ background: domainsOptions[k?.dm]?.color }}></div>
								<span className="text-sm">&nbsp;{k?.dm}&nbsp;</span>
							</div>
						))}
					</div>
				</IF>
			</div>
			}
		</>
	);
}

ColumnGraph.propTypes = {
	/** Defines appslice */
	appSlice: PropTypes.object,
	/** Defines dataset */
	dataset: PropTypes.string,
	/** Defines mapping function */
	mapData: PropTypes.func,
	/** Defines filtering function */
	filterData: PropTypes.func,
	/** Defines sorting function */
	sortData: PropTypes.func,
	/** Defines bar color. */
	color: PropTypes.string,
	/** Defines text color. */
	textColor: PropTypes.string,
	/** Defines text size */
	textSize: PropTypes.number,
	/** Defines if graph scale fit maximum value/target. */
	scoreScaleMax: PropTypes.bool,
	/** Defines number of decimals for scores. */
	scoreDecimal: PropTypes.number,
	/** Defines width. */
	width: PropTypes.number,
	/** Defines height. */
	height: PropTypes.number,
	/** Defines all four margins. */
	margin: PropTypes.object,
	/** Defines line height. */
	lineHeight: PropTypes.number,
	/** Defines stroke width. */
	strokeWidth: PropTypes.number,
	/** Defines color for the background bar track. */
	backgroundBarColor: PropTypes.string,
	/** Defines gradient start color. */
	gradientStartBarColor: PropTypes.string,
	/** Defines gradient end color */
	gradientEndBarColor: PropTypes.string,
	/** Defines if there is nonConformities to display as an informative red circle at the end of the bar. */
	nonConformities: PropTypes.bool,
	/** Defines if there is nonConformities to display as an informative green circle at the end of the bar. */
	nonConformitiesGreen: PropTypes.bool,
	/** Defines margin for non conformities. */
	ncMargin: PropTypes.number,
	/** An array of objects used to defines targets. Format [{label: "xxx", value: "xxx"}, {...}]. */
	targets: PropTypes.array,
	/** Defines if sorting function is using getScore (true) or getModule (false). */
	sortScoreDimension: PropTypes.bool,
	/** Score accessor. */
	getScore: PropTypes.func,
	/** color accessor. */
	getColor: PropTypes.func,
	/** Module accessor. */
	getModule: PropTypes.func,
	/** Picto accessor. */
	getPicto: PropTypes.func,
	/** Non Conformities accessor. */
	getNC: PropTypes.func,
	/** additional Non Conformities accessor. */
	getNCbis: PropTypes.func,
	/** onClick function. Currently used with nonConformitiesGreen */
	itemOnClick: PropTypes.func,
	/** Defines keys used for onClick refines. */
	refineKeys: PropTypes.array,
	/** locales */
	locales: PropTypes.string,
	/** Enable highlight on country */
	highlight: PropTypes.bool,
	/** Prefix ta access locale for Picto's tooltip */
	prefix: PropTypes.string,
	/** Enable report link to Maia+. */
	hasReportLink: PropTypes.bool,
	/** Color for average line. Displays red when undefined */
	avgLineColor: PropTypes.string,
	/** Defines dataset for placeholder data. */
	placeholderDataset: PropTypes.string,
	hideAvgLine: PropTypes.bool,
	/** score unit to display on graph */
	unit: PropTypes.string,
	bubbleColor: PropTypes.string,
	bubbleTextColor: PropTypes.string,
	bubblePercent: PropTypes.bool,
	colorsByModule: PropTypes.bool,
	legend: PropTypes.bool,
	niceScale: PropTypes.bool
};
export default ColumnGraph;
