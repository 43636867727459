import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function ClarityNetZeroLegend({ locales, small = false }) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<div className={`flex items-center w-full gap-x-4 ${ small ? "text-small" : "text-medium"}`}>
			<div className="flex items-center gap-x-2">
				<div className={`rounded-full bg-clarity_primary-netZeroAlt ${ small ? "w-3 h-3" : "w-4 h-4 "}`} />
				<p>{t("netZero.legend.index")}</p>
			</div>
			<div className="flex items-center gap-x-2">
				<div className={`rounded-full bg-clarity_primary-menu ${ small ? "w-3 h-3" : "w-4 h-4 "}`} />
				<p>{t("netZero.legend.tCO2")}</p>
			</div>
		</div>
	);
}

ClarityNetZeroLegend.propTypes = {
	small: PropTypes.bool,
	locales: PropTypes.string
};

export default ClarityNetZeroLegend;
