import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import SpeedIcon from "@mui/icons-material/Speed";

function SupplyRIndicator({
	score, logo, locale, locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	return (
		<div className="relative h-32 w-24 flex flex-col items-center -mt-2">
			<img src={logo}
				className="absolute h-32 filter brightness-50" />
			<div className="flex flex-col items-center pt-6 filter drop-shadow-lg text-white">
				<SpeedIcon fontSize="small" className="z-20 " />
				<p className="z-20 text-3xl font-light">{score}%</p>
				<p className="text-center text-smaller w-24 line-clamp-2 leading-3">
					{t(`domains.${locale}.name`)}
				</p>
			</div>
		</div>
	);
}

SupplyRIndicator.propTypes = {
	score: PropTypes.number,
	logo: PropTypes.string,
	locale: PropTypes.string,
	locales: PropTypes.string
};

export default SupplyRIndicator;
