import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const ImgWithFallbackSrc = ({
	src1 = "",
	src2 = "",
	styleString = "",
	alt = "",
	imgRef
}) => {
	const [imageSource, setImageSource] = useState(src1);

	useEffect(() => {
		const img = new Image();

		const handleImageLoad = () => {
			setImageSource(img.src);
		};

		const handleImageError = () => {
			setImageSource(src2);
			img.removeEventListener("load", handleImageLoad);
			img.removeEventListener("error", handleImageError);
		};

		img.addEventListener("load", handleImageLoad);
		img.addEventListener("error", handleImageError);

		// Set the source to src1
		img.src = src1;

		// Clean up event listeners when the component unmounts
		return () => {
			img.removeEventListener("load", handleImageLoad);
			img.removeEventListener("error", handleImageError);
		};
	}, [src1, src2]);

	return <img src={imageSource} alt={alt} className={styleString} imgRef={imgRef} />;
};

ImgWithFallbackSrc.propTypes = {
	src1: PropTypes.string,
	src2: PropTypes.string,
	styleString: PropTypes.string,
	alt: PropTypes.string,
	imgRef: PropTypes.any
};

export default ImgWithFallbackSrc;
