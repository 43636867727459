import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { appAllOptions } from "../../apps/configs/LauncherConfig";

const ToggleGroupProgramsAtAGlance = ({ appSlice }) => {
	// const data = [
	// 	{
	// 		title: "Clarity",
	// 		state: true
	// 	},
	// 	{
	// 		title: "Supply-R",
	// 		state: false
	// 	},
	// 	{
	// 		title: "Chargescan",
	// 		state: false
	// 	},
	// 	{
	// 		title: "Compass",
	// 		state: false
	// 	}
	// ];

	// const data = [
	// 	{
	// 		title: "Clarity",
	// 		state: true
	// 	},
	// 	{
	// 		title: "Supply-R",
	// 		state: false
	// 	},
	// 	{
	// 		title: "Chargescan",
	// 		state: false
	// 	}
	// ];

	const data = [
		{
			title: "Clarity",
			state: true
		},
		{
			title: "Supply-R",
			state: false
		}
	];

	const programBuilder = {
		Clarity: "clarity",
		"Supply-R": "supplyR"
	};

	const [programs, setPrograms] = useState(data);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(appSlice.actions.refine([{ key: "program", value: "clarity" }]));
		return () => {
			dispatch(appSlice.actions.clear([{ refine: "program" }]));
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleProgram = (name) => {
		// The toggle behavior is different depending on the number of programs.
		// If there are only two programs then we can toggle between them just by clicking
		// one and the same button. If there are more than 2 programs then onClick we activate
		// the program we need and we deactivate the rest. The second click on the same program
		// will not do anything.
		if (programs.length > 2) {
			const toggled = programs.map((program) => {
				if (program.title === name) {
					program.state = true;
				} else {
					program.state = false;
				}
				return program;
			});
			const activeProgram = toggled.find((program) => (program.state === true));
			setPrograms(toggled); // re-render button group
			dispatch(appSlice.actions.refine([{ key: "program", value: programBuilder[activeProgram.title] }]));
		} else {
			const toggled = programs.map((program) => ({ ...program, state: !program.state }));
			const activeProgram = toggled.find((program) => (program.state === true));
			setPrograms(toggled); // re-render button group
			dispatch(appSlice.actions.refine([{ key: "program", value: programBuilder[activeProgram.title] }]));
		}
	};

	// The switches overlap each other progressively
	const positionOffset = data.length * 20 - 20;

	return (
		<div className="flex">
			<p className="flex items-center font-bold">Filter</p>
			{programs.map((program, index) => (
				<div key={index} style={{ position: "relative", left: `${positionOffset - 20 * index}px` }}>
					<ToggleProgramsAtAGlance program={program.title} isActive={program.state} toggle={toggleProgram} appSlice={appSlice} />
				</div>
			))}
		</div>
	);
};

ToggleGroupProgramsAtAGlance.propTypes = {
	appSlice: PropTypes.object
};

export default ToggleGroupProgramsAtAGlance;

// Individual toggle switch
const ToggleProgramsAtAGlance = ({
	program, isActive, toggle
}) => {
	const appOptions = appAllOptions?.find((opt) => opt.label === program);
	const pictoUrl = appOptions?.picto;
	const grayscale = isActive ? "" : "grayscale";
	const grayText = isActive ? "" : "text-slate-500";
	const opacity = isActive ? "" : "opacity-[25%]";

	return (
	// container for all the toggles
		<div className="flex">
			<div data-test={`program-toggle-${program}`} className={`flex bg-[#eaeaea] rounded-xl h-[50px] cursor-pointer select-none ${grayscale}`}
				onClick={() => {
					toggle(program);
				}}>
				<div>
					<img className={`${opacity}`} src={pictoUrl} alt={`${program}_picto`} width="50" height="50" ></img>
				</div>
				<div className={`font-bold self-center pl-2 w-30 ${grayText}`}>{program}</div>
			</div>
		</div>
	);
};

ToggleProgramsAtAGlance.propTypes = {
	program: PropTypes.string,
	isActive: PropTypes.bool,
	toggle: PropTypes.func,
	appSlice: PropTypes.object
};
