import PropTypes from "prop-types";
import React from "react";
import { useFieldArray, Controller } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";

const NestedArray = ({
	nestIndex, nestedField, control, register
}) => {
	const { fields, remove, append } = useFieldArray({
		control,
		name: `appScopes.${nestIndex}.nestedArray.scope`
	});

	return (
		<div className="relative flex flex-col font-medium gap-y-2.5 mt-4 capitalize text-admin_primary-default border px-2 py-3 rounded-lg">
			<label className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
								text-admin_primary-default border px-2 py-3 rounded-lg">
				<span className="w-min h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
					{"field"}
				</span>
				<Controller
					key={nestedField.name + nestedField.nestedArray.field + nestedField.id}
					name={`appScopes.${nestIndex}.nestedArray.field`}
					control={control}
					render={({ field }) => (
						<div className="flex flex-row">
							<input
								{...field}
								defaultValue={field.value}
								autoComplete="off"
								className="w-min pl-2 py-1 border text-black focus:outline-none rounded-lg focus:ring-2
                            				focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"/>
							<button
								type="button"
								className="w-min ml-2 border bg-admin_primary-default hover:bg-admin_primary-dark
											text-white font-bold px-2 rounded"
								onClick={() => remove(nestIndex)}>
								<BlockIcon className="text-base" />
							</button>
						</div>
					)}
				/>
			</label>

			<label className="relative flex flex-col font-medium gap-y-1 mt-4 capitalize
								text-admin_primary-default border px-2 py-3 rounded-lg">
				<span className="w-min h-min p-0.5 absolute -top-3 bg-white uppercase text-sm flex justify-center items-center">
					{"scope"}
				</span>

				{fields.map((item, k) => (
					<Controller
						key={item.id}
						name={`appScopes.${nestIndex}.nestedArray.scope.${k}.k`}
						control={control}
						render={({ field }) => (
							<div className="flex flex-row">
								<input
									{...field}
									defaultValue={field.value}
									autoComplete="off"
									className="w-min pl-2 py-1 border text-black focus:outline-none rounded-lg focus:ring-2
                                focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"/>
								<button
									type="button"
									className="w-min ml-2 border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold px-2 rounded"
									onClick={() => remove(k)}
								>
									<BlockIcon className="text-base" />
								</button>
							</div>
						)}
					/>
				)
				)}
				<div className="mt-1">
					<button
						type="button"
						className="border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold px-2 rounded"
						onClick={() => { append(""); }}
					>
						<AddIcon className="text-base" />
					</button>
				</div>
			</label>

		</div>
	);
};

NestedArray.propTypes = {
	control: PropTypes.object,
	nestIndex: PropTypes.any,
	register: PropTypes.func,
	nestedField: PropTypes.string
};

const AdminAppNestedArray = ({ control, register }) => {
	const {
		fields, append, remove
	} = useFieldArray({
		control,
		name: "appScopes"
	});

	return (
		<>
			<ul className="space-y-2 ">
				{fields.map((item, index) => (
					<fieldset key={item.id} className="border p-3  rounded-lg" >
						<legend>Project {index}</legend>

						<li>
							<Controller
								key={`appScopes.${index}.name`}
								name={`appScopes.${index}.name`}
								control={control}
								render={({ field }) => (
									<div className="flex flex-row">
										<input
											{...field}
											defaultValue={field.value}
											autoComplete="off"
											className="w-min pl-2 py-1 border text-black focus:outline-none rounded-lg focus:ring-2
                                            focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base"/>
										<button
											type="button"
											className="w-min ml-2 border bg-admin_primary-default hover:bg-admin_primary-dark
                                            text-white font-bold px-2 rounded"
											onClick={() => remove(index)}>
											<BlockIcon className="text-base" />
										</button>
									</div>
								)}
							/>

							<NestedArray {...{
								nestIndex: index, nestedField: item, control, register
							}} />
						</li>
					</fieldset>
				))}
			</ul>
			<section>
				<button
					type="button"
					className="border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold px-2 rounded"
					onClick={() => {
						append({ name: "", nestedArray: { field: "", scope: [{ k: "" }] } });
					}}
				>
					<AddIcon className="text-base" />
				</button>

			</section>

		</>
	);
};

AdminAppNestedArray.propTypes = {
	control: PropTypes.object,
	register: PropTypes.func
};

export default AdminAppNestedArray;
