import DashboardIcon from "@mui/icons-material/Dashboard";
import MapIcon from "@mui/icons-material/Map";
import BusinessIcon from "@mui/icons-material/Business";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PinDropIcon from "@mui/icons-material/PinDrop";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} slice Name of the slice related to the app created and references in the store ./store.js
 * @param {String} logo Label of the menu, if none, only the picto
 */
export const appDefinition = {
	name: "CEE",
	subName: "",
	launcherID: "CEE",
	slice: "CEE",
	locales: "translationsCEE",
	theme: "clarity",
	favicon: "/images/Compass/favicon.ico",
	logo: "/images/CEE/CEE.svg"
};

/**
 * Set up  menu options to display
 *
 * @param {String} menu ID of the menu (use for the initial menu definition and the render of the app)
 * @param {String} type Route or App = Route is a link to jump to a new url, App is a internal link to the App to display specific information
 * @param {String} url [OPTIONAL] If type == url, the new url to link the menu (it has to be routed in ./App.js)
 * @param {String} label [OPTIONAL] Label of the menu, if none, only the picto
 * @param {String} sectionTitleWhite [OPTIONAL] Section title in white displayed on top of the page when the menu is clicked
 * @param {String} sectionTitleBlack [OPTIONAL] Section title in black displayed on top of the page when the menu is clicked
 * @param {Component} picto Material UI Icon to represent the field in the select, to be imported in this file
 */
export const menuOptions = [
	{
		menu: "HOME",
		type: "App",
		initial: true,
		label: "Home",
		clearRefine: ["siteId"],
		picto: DashboardIcon
	},
	{
		menu: "DISTRIB",
		type: "App",
		label: "Distribution",
		clearRefine: ["siteId"],
		picto: BarChartOutlinedIcon
	},
	{
		menu: "MAP",
		type: "App",
		initial: true,
		label: "Map",
		clearRefine: ["siteId"],
		picto: MapIcon
	},
	// ,
	// {
	// 	menu: "NCs",
	// 	type: "App",
	// 	initial: true,
	// 	label: "Nonconformities",
	// 	clearRefine: ["siteId", "critical"],
	// 	picto: FlagOutlinedIcon
	// },
	{
		menu: "SITEs",
		type: "App",
		initial: true,
		label: "Your Sites",
		clearRefine: ["critical"],
		picto: PinDropIcon
	}
	// ,
	// {
	//	menu: "INFO",
	//	type: "App",
	//	label: "Info",
	//	noMobileMenu: true,
	//	picto: ContactSupportIcon
	// }
];

/**
 * It will allow project data to be filtered by cross group fields in the same filter
 */
export const CEEGroupFilter = { isGroupFilter: true, groupFields: ["departement", "region"] };

/**
 * for slider filter
 * for those who has sliderFields but it should be affected by sliderFields
 * it has to filter sliderFields in listFacetsToRefine or it will lead to error when first match in aggregation
 */

export const CEESliderFilter = { isSliderFilter: true, sliderFields: ["dptScore"], excludedCollections: ["listProjects"] };

/**
 * Name of the dataset with the client parameters
 */
const CEEClientParameters = "CEEClientParameters";

/**
 * Dataset for assignedForm level of detail
 */
export const CEEAssignedForm = "CEEAssignedForm";

/**
 * Dataset for Travaux (works) level of detail
 */
const CEETravaux = "CEETravaux";
/**
 * Dataset for Departments Scores & avgCompletion Time
 */
const CEEDepartmentScore = "CEEDepartmentScore";

/**
  * Query to load and update the non conformities
  */
// export const listNonConformityQuery = [
// 	{
// 		$match: {
// 			nc: { $ne: 0 }
// 		}
// 	},
// 	{
// 		$project: {
// 			publicationDate: 1,
// 			question: 1,
// 			answer: "$rating",
// 			observation: "$observation",
// 			auditedCompany: "$site.auditedCompany",
// 			siteType: "$additionalInfo.siteType",
// 			critical: 1,
// 			module: 1,
// 			criticality: { $cond: [{ $eq: ["$critical", true] }, "Critical", "Non Critical"] },
// 			productCategory: "$additionalInfo.productCategoryFilter",
// 			country: "$site.country",
// 			city: "$site.country",
// 			section1Label: 1
// 		}
// 	}
// ];

/**
 * Array of the full list of facets to refine
 */
// TODO: fix year issue
const listFacetsToRefine = [
	"project",
	"travaux",
	"typeDeLogement",
	"departement",
	"region",
	"siteId",
	"dptScore",
	"publicationYear"
];

/**
 * Client parameters
 */
export const clientInitParameters = {
	name: "clientParameters",
	collection: CEEClientParameters,
	facetsToRefine: ["project"],
	fetch: "find",
	limit: 10
};

const now = Date.now();
/**
 * Function to refresh the list of nonConformities after a click on a new page
 *
 * @param {Number} value Page to refresh the list of sites
 */
// export const updateListNonConformities = (dataset, limit, value) => ({
// 	name: dataset,
// 	collection: CEETravaux,
// 	fetch: "aggregation",
// 	query: listNonConformityQuery,
// 	facetsToRefine: listFacetsToRefine.concat("critical"),
// 	limit,
// 	skip: ((value - 1) * limit)
// });

/**
  * Active DataSets definition for the app
  *
  * @param {String} name
  * @param {String} collection
  * @param {String} fetch
  * @param {String[]} facetsToRefine
  * @param {Object[]} query
  * @param {Number} limit
  * @param {Object[]} data
  */
export const CEEInitDatasets = {
	listProjects: {
		collection: CEEClientParameters,
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						projectLabel: "$projectLabel",
						project: "$project"
					},
					nbClients: { $sum: 1 }
				}
			},
			{
				$project: {
					projectLabel: "$_id.projectLabel",
					project: "$_id.project",
					nbClients: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listTravaux: {
		collection: CEETravaux,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "travaux"),
		query: [{
			$group: {
				_id: {
					travaux: "$travaux"
				},
				nbTravaux: {
					$sum: 1
				},
				score: {
					$avg: "$score"
				}
			}
		}, {
			$project: {
				travaux: "$_id.travaux",
				nbTravaux: 1,
				score: 1,
				_id: 0
			}
		}],
		limit: 500
	},
	listConclusions: {
		collection: CEETravaux,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "conclusion"),
		query: [
			{
				$group: {
					_id: {
						conclusion: "$conclusion"
					},
					nbConclusions: {
						$sum: 1
					},
					score: {
						$avg: "$score"
					}
				}
			}, {
				$project: {
					conclusion: "$_id.conclusion",
					nbConclusions: 1,
					score: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listDepartements: {
		collection: CEEAssignedForm,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "departement" && element !== "region"),
		query: [{
			$project: {
				"option.label": "$departement",
				"option.value": "$departement",
				region: "$region",
				score: "$score"
			}
		}, {
			$group: {
				_id: "$region",
				options: {
					$addToSet: "$option"
				},
				score: {
					$avg: "$score"
				}
			}
		}, {
			$addFields: {
				label: "$_id",
				value: "$_id",
				refine: "region"
			}
		}, {
			$match: {
				_id: {
					$nin: ["undefined", null]
				}
			}
		}],
		limit: 500
	},
	listTypesDeLogements: {
		collection: CEEAssignedForm,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "typeDeLogement"),
		query: [{
			$group: {
				_id: {
					typeDeLogement: "$typeDeLogement"
				},
				nbTypesDeLogements: {
					$sum: 1
				},
				score: {
					$avg: "$score"
				}
			}
		}, {
			$project: {
				typeDeLogement: "$_id.typeDeLogement",
				nbTypesDeLogements: 1,
				score: 1,
				_id: 0
			}
		}],
		limit: 500
	},
	listYear: {
		collection: CEETravaux,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$group: {
				_id: {
					year: { $year: "$publicationDate" }
				},
				score: {
					$avg: "$score"
				}
			}
		},
		{
			$project: {
				publicationYear: "$_id.year",
				score: 1,
				_id: 0
			}
		}],
		limit: 500
	},
	avgCompletionTime: {
		collection: CEEAssignedForm,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		sliderInsertIndex: 1,
		query: [
			{
				$group: {
					_id: "",
					avgDureeTrvx: {
						$avg: "$effectiveDuration"
					}
				}
			}
		]
	},
	avgCompletionTimeYTD: {
		collection: CEEAssignedForm,
		fetch: "aggregation",
		sliderInsertIndex: 1,
		query: [
			{
				$addFields: {
					cmpYear: {
						$cmp: [
							"$publicationYear", {
								$year: {
									$dateFromString: {
										dateString: new Date()
									}
								}
							}
						]
					}
				}
			}, {
				$match: {
					cmpYear: 0
				}
			}, {
				$group: {
					_id: "",
					avgDureeTrvx: {
						$avg: "$effectiveDuration"
					},
					count: {
						$sum: 1
					}
				}
			}
		]
	},
	avgCompletionTimePrevYear: {
		collection: CEEAssignedForm,
		fetch: "aggregation",
		sliderInsertIndex: 1,
		query: [
			{
				$addFields: {
					cmpPrevYear: {
						$cmp: [
							{
								$subtract: [
									{
										$year: {
											$dateFromString: {
												dateString: new Date()
											}
										}
									}, 1
								]
							}, "$publicationYear"
						]
					}
				}
			}, {
				$match: {
					cmpPrevYear: 0
				}
			}, {
				$group: {
					_id: "",
					avgDureeTrvx: {
						$avg: "$effectiveDuration"
					},
					count: {
						$sum: 1
					}
				}
			}
		]
	},
	avgCompletionTimePrevMonth: {
		collection: CEEAssignedForm,
		fetch: "aggregation",
		sliderInsertIndex: 1,
		query: [
			{
				$addFields: {
					publicationMonth: {
						$month: "$publicationDate"
					}
				}
			}, {
				$addFields: {
					cmpYear: {
						$cmp: [
							"$publicationYear", {
								$year: {
									$dateFromString: {
										dateString: new Date()
									}
								}
							}
						]
					},
					cmpPrevMonth: {
						$cmp: [
							{
								$subtract: [
									{
										$month: {
											$dateFromString: {
												dateString: new Date()
											}
										}
									}, 1
								]
							}, "$publicationMonth"
						]
					}
				}
			}, {
				$match: {
					cmpYear: 0,
					cmpPrevMonth: 0
				}
			}, {
				$group: {
					_id: "",
					avgDureeTrvx: {
						$avg: "$effectiveDuration"
					},
					count: {
						$sum: 1
					}
				}
			}
		]
	},
	avgCompletionTimePrevQuarter: {
		collection: CEEAssignedForm,
		fetch: "aggregation",
		sliderInsertIndex: 1,
		query: [
			{
				$addFields: {
					publicationQuarter: {
						$cond: [
							{
								$lte: [
									{
										$month: "$publicationDate"
									}, 3
								]
							}, 1, {
								$cond: [
									{
										$lte: [
											{
												$month: "$publicationDate"
											}, 6
										]
									}, 2, {
										$cond: [
											{
												$lte: [
													{
														$month: "$publicationDate"
													}, 9
												]
											}, 3, 4
										]
									}
								]
							}
						]
					},
					currentQuarter: {
						$cond: [
							{
								$lte: [
									{
										$month: {
											$dateFromString: {
												dateString: new Date()
											}
										}
									}, 3
								]
							}, 1, {
								$cond: [
									{
										$lte: [
											{
												$month: {
													$dateFromString: {
														dateString: new Date()
													}
												}
											}, 6
										]
									}, 2, {
										$cond: [
											{
												$lte: [
													{
														$month: {
															$dateFromString: {
																dateString: new Date()
															}
														}
													}, 9
												]
											}, 3, 4
										]
									}
								]
							}
						]
					}
				}
			}, {
				$addFields: {
					cmpYear: {
						$cmp: [
							"$publicationYear", {
								$year: {
									$dateFromString: {
										dateString: new Date()
									}
								}
							}
						]
					},
					cmpPrevQuarter: {
						$cmp: [
							{
								$subtract: [
									"$currentQuarter", 1
								]
							}, "$publicationQuarter"
						]
					}
				}
			}, {
				$match: {
					cmpYear: 0,
					cmpPrevQuarter: 0
				}
			}, {
				$group: {
					_id: "",
					avgDureeTrvx: {
						$avg: "$effectiveDuration"
					},
					count: {
						$sum: 1
					}
				}
			}
		]
	},
	avgScoreNbInspectPerMon: {
		collection: CEETravaux,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						month: { $month: "$publicationDate" },
						year: { $year: "$publicationDate" }
					},
					score: {
						$avg: "$score"
					},
					uniqueAssignments: {
						$addToSet: "$assignmentId"
					}
				}
			},
			{
				$addFields: {
					score100: { $multiply: ["$score", 100] }
				}
			},
			{
				$project: {
					publicationYear: "$_id.year",
					month: "$_id.month",
					totalAssignments: {
						$size: "$uniqueAssignments"
					},
					score: 1,
					score100: "$score100",
					_id: 0
				}
			}
		],
		limit: 500
	},
	typesDeLogements: {
		collection: CEEAssignedForm,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$group: {
				_id: "$typeDeLogement",
				score: { $avg: "$score" }
			}
		}],
		limit: 100
	},
	conclusionsTypesDeLogement: {
		collection: CEETravaux,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$group: {
				_id: {
					conclusion: "$conclusion",
					typeDeLogement: "$typeDeLogement"
				},
				uniqueAssignments: {
					$addToSet: "$assignmentId"
				},
				uniqueSites: {
					$addToSet: "$siteId"
				},
				score: { $avg: "$score" }

			}
		}, {
			$project: {
				travaux: "$_id.travaux",
				conclusion: "$_id.conclusion",
				typeDeLogement: "$_id.typeDeLogement",
				totalAssignments: {
					$size: "$uniqueAssignments"
				},
				uniqueSites: 1,
				score: 1,
				totalSites: {
					$size: "$uniqueSites"
				},
				_id: 0
			}
		}],
		limit: 500
	},
	// TODO: not sure the second $avg: "$score"
	conclusionsTravaux: {
		collection: CEETravaux,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$group: {
				_id: {
					conclusion: "$conclusion",
					travaux: "$travaux"
				},
				uniqueAssignments: {
					$addToSet: "$assignmentId"
				},
				uniqueSites: {
					$addToSet: "$siteId"
				},
				score: { $avg: "$score" }

			}
		}, {
			$project: {
				totalAssignments: {
					$size: "$uniqueAssignments"
				},
				totalSites: {
					$size: "$uniqueSites"
				},
				score: 1
			}
		}, {
			$group: {
				_id: "$_id.travaux",
				travaux: {
					$first: "$_id.travaux"
				},
				conclusion: {
					$push: "$_id.conclusion"
				},
				totalAssignments: {
					$push: "$totalAssignments"
				},
				totalSites: {
					$push: "$totalSites"
				},
				score: {
					$avg: "$score"
				}
			}
		}],
		limit: 100
	}, /*
	scope: {
		collection: CEEAssignedForm,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						typeDeLogement: {
							$toUpper: "$typeDeLogement"
						}
					},
					uniqueAssignments: {
						$addToSet: "$assignmentId"
					}
				}
			},
			{
				$project: {
					typeDeLogement: "$_id.typeDeLogement",
					nbAssessments: { $size: "$uniqueAssignments" },
					_id: 0
				}
			}
		],
		limit: 10
	}, */
	sitesTable: {
		collection: "CEESiteScores",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$sort: {
				publicationDate: -1
			}
		}, {
			$project: {
				idClient: 1,
				typeDeLogement: 1,
				city: 1,
				departement: 1,
				publicationDate: 1,
				score: 1,
				statut: 1,
				assignmentTechnicalid: 1,
				_id: 0
			}
		}],
		limit: 20000
	},
	departementScores: {
		collection: "CEESiteScores",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		facetsToTransform: { departement: "departement", region: "region" },
		insertIndex: 1,
		transformLogic: "or",
		query: [
			{
				$group: {
					_id: "$departement",
					departement: {
						$first: "$departement"
					},
					region: {
						$first: "$region"
					},
					nbTravaux: {
						$sum: "$nbTravaux"
					},
					dptScore: {
						$first: "$dptScore"
					}
				}
			}, {
				$match: {
					_id: {
						$ne: null
					}
				}
			}, {
				$lookup: {
					from: "franceGeoDepartements",
					localField: "_id",
					foreignField: "dep_name",
					as: "geo"
				}
			}, {
				$addFields: {
					geometry: {
						$arrayElemAt: [
							"$geo.geo_shape", 0
						]
					}
				}
			}, {
				$project: {
					geo: 0
				}
			}
		],
		limit: 100
	}
};

/**
 * Init Refine of the Application
 *
 * @param {Object} CEEInitRefines
 * Object representing the initial refine of the datasets
 * in the App slice, the key being the name of the field
 * in the DB Collection to filter on, the value being the value to filter on
 */
export const CEEInitRefines = { project: undefined };

// Define the MapToolTip
export const CEEMapTooltip = [
	{ label: "Type de logement", key: "typeDeLogement" },
	{ label: "Score", key: "score" }
];

/**
 * Set up refine filter options to display
 *
 * @param {String} refine name of the field in the DB Collection to filter on
 * @param {String} loadFromDataset name of the collection to filter on the field
 * @param {String} placeHolder [OPTIONAL] text when nothing is selected
 * @param {Component} tag Material UI Icon to represent the field in the select, to be imported in this file
 * @param {Boolean} clear will be cleared if the button clear is pushed
 * @param {Boolean} toClearOnChange execute a clear of other clearable fields (see above) if the value has changed
 */
export const filterOptions = [
	{
		refine: "dptScore",
		label: "score",
		placeHolder: "filters.score",
		isSlider: true,
		sliderColor: "#CCDB73"
	},
	{
		refine: "project",
		displayField: "projectLabel",
		label: "Projects",
		loadFromDataset: "listProjects",
		placeHolder: "filters.projects",
		isMulti: false,
		isClearable: false,
		isSearchable: false,
		tag: AccountTreeIcon,
		hiddenIfUnique: true,
		clear: false,
		toClearOnChange: true
	},
	{
		label: "Travaux",
		refine: "travaux",
		loadFromDataset: "listTravaux",
		placeHolder: "filters.travaux",
		isMulti: true,
		isClearable: true,
		isSearchable: false,
		tag: StarHalfIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Types de logement",
		refine: "typeDeLogement",
		loadFromDataset: "listTypesDeLogements",
		placeHolder: "filters.typesDeLogements",
		isMulti: true,
		isClearable: true,
		isSearchable: false,
		tag: PinDropOutlinedIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Départements",
		refine: "departement",
		refineGroup: "region",
		loadFromDataset: "listDepartements",
		placeHolder: "filters.departements",
		isMulti: true,
		isClearable: true,
		isSearchable: true,
		isGrouped: true,
		groupSelection: true,
		tag: PinDropOutlinedIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Année",
		refine: "publicationYear",
		loadFromDataset: "listYear",
		placeHolder: "filters.year",
		isMulti: false,
		isClearable: true,
		isSearchable: true,
		tag: AccessTimeIcon,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "region",
		manualPosition: true,
		isMulti: true,
		clear: true,
		toClearOnChange: false
	}
];

export const ratingsConfig = [
	{ rating: "SATISFAISANT", color: "#6DC3A0", value: 1 },
	{ rating: "NON SATISFAISANT", color: "#FF475C", value: 0 }
];

const scoreToRating = (score) => score >= 1 ? "Satisfaisant"
	: score < 1 && score > 0 ? "Partiel"
		: score === 0 ? "Insuffisant"
			: score || "N/A";
/**
 * Define general CEE ranking rules
 */
export const colorLegend = {
	unsatisfactory: {
		color: "#FF475C",
		range: [0, 75]
	},
	subStandard: {
		color: "#EB4C72",
		range: [75, 80]
	},
	inProgress: {
		color: "#FF8585",
		range: [80, 85]
	},
	average: {
		color: "#FFC362",
		range: [85, 90]
	},
	good: {
		color: "#CCDB73",
		range: [90, 95]
	},
	veryGood: {
		color: "#6DC3A0",
		range: [95, 110]
	}

};
/**
 * Define the rule for the Marker color on the map
 */
export const getMarkerColor = (score) => {
	if (score === undefined || score < 0 || score > 100) return "#c9bbbd";
	// eslint-disable-next-line no-restricted-syntax
	for (const k in colorLegend) {
		if (score >= colorLegend[k].range[0] &&
			score < colorLegend[k].range[1]) {
			return colorLegend[k].color;
		}
	}
};

/**
 * Define the color code and name for non conformities
 */
export const nonConformities = { Critical: "#FF475C", "Non Critical": "#FFC362" };

/**
 * Array of tags to be displayed for each non conformities
 */
// export const listTagsNonConformities = {
// 	auditedCompany: {
// 		accessor(d, t) { return d.auditedCompany; },
// 		picto(d) { return BusinessIcon; },
// 		refine: [{ key: "siteId", valueKey: (d) => d.siteId }]
// 	},
// 	location: {
// 		accessor(d, t) { return ((d.city && `${d.city }-`) + d.country); },
// 		picto(d) { return PinDropIcon; }
// 	},
// 	siteType: {
// 		accessor(d, t) { return d.siteType; },
// 		picto(d) { return CompareArrowsIcon; }
// 	},
// 	module: {
// 		accessor(d, t) { return d.module; },
// 		picto(d) { return VerifiedUserIcon; }
// 	},
// 	section: {
// 		accessor(d, t) { return d.section1Label; },
// 		picto(d) { return VerticalSplitOutlinedIcon; }
// 	},
// 	product: {
// 		accessor(d, t) { return d.productCategory.map((element, i) => (element) + ((i < (d.productCategory.length - 1)) ? "," : "")); },
// 		picto(d) { return AccountTreeOutlinedIcon; }
// 	}
// };

export const tableStructure = (activeRefines, t) => [
	{
		Header: "idClient",
		accessor: "idClient"
	},
	{
		Header: "typeDeLogement",
		accessor: "typeDeLogement"
	},
	{
		Header: "city",
		accessor: "city"
	},
	{
		Header: "departement",
		accessor: "departement"
	},
	{
		Header: "publicationDate",
		accessor: "publicationDate",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => new Date(value).toLocaleDateString("fr")
	},
	{
		Header: "score",
		accessor: (row) => row.score !== null ? row.score : row.statut,
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => scoreToRating(value)
	},
	{
		Header: "assignmentTechnicalid",
		accessor: "assignmentTechnicalid",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => <span>
			<a
				className="relative z-xl compassHovering text-gray-600 hover:font-semibold visited:text-gray-400"
				href={`https://odsdatahub.bureauveritas.com/reports/${value}`}
				target="_blank" rel="noreferrer">Voir le rapport</a>
		</span>
	}
];

export const tableSectionsStructure = (activeRefines, t) => [
	{
		Header: "Section Level 1",
		accessor: "section1Label"
	},
	{
		Header: "Section Level 2",
		accessor: "section2Label"
	},
	{
		Header: "NCs",
		accessor: "nc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-red-500 rounded-full h-7 w-7 text-white">{value}</p> : <p/>
	},
	{
		Header: "CNCs",
		accessor: "cnc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-red-500 rounded-full h-7 w-7 text-white">{value}</p> : <p/>
	}
];
