import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ParentSize } from "@visx/responsive";

import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import SimpleDonutGraph from "../graphs/SimpleDonutGraph";

function SCAIndicatorCard({
	appSlice, dataset, dataset2 = undefined, joinKey = undefined, module, description,
	ratingsConfig, large = false, locales, refineKey = undefined, indicators = [], globalStats = false, label = undefined
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	// get dataset rawData
	const rawData = useMemo(() => (
		dataset2 && joinKey
			? activeDatasets?.[dataset].data.map((item) => {
				let temp = { ...item };

				const res = activeDatasets?.[dataset2]?.data
				.find((el) => el[joinKey] === item[joinKey]);

				temp = { ...temp, ...res };
				return temp;
			})
			: activeDatasets?.[dataset]?.data
	), [activeDatasets, dataset, dataset2, joinKey]);

	// get exact data which will be diplayed based on activeRefine
	const moduleData = useMemo(() => rawData
	?.filter((filterValue) => filterValue[refineKey] === module), [rawData, refineKey, module]);

	const donutData = useMemo(() => (
		ratingsConfig.reduce(
			(acc, curValue, index) => {
				acc[index] = {
					label: curValue.rating,
					score: moduleData?.[0] ? moduleData[0][`${curValue.rating}`] : 0
				};
				return acc;
			}, [{}])
	), [moduleData, ratingsConfig]);

	const calOverAllScore = (value) => {
		const nbSuccess = value?.[0]?.score;
		const nbFailed = value?.[1]?.score;

		if ((nbSuccess + nbFailed) === 0) return 0;

		return !globalStats ? (nbFailed / (nbSuccess + nbFailed)) * 100 : (nbSuccess / (nbSuccess + nbFailed)) * 100;
	};

	return (
		moduleData?.map((item, i) => (
			<div key={`item-${i}`}
				className="flex flex-col alphaBlur rounded-xl px-2 py-3 w-full h-full gap-y-2 cursor-pointer"
				onClick={() => {
					if (activeRefines[refineKey]) {
						dispatch(appSlice.actions.clear([{ refine: refineKey }]));
					}
				}}>
				<div className="flex justify-between items-center w-full">
					<div className="flex flex-col w-full">
						<div className="flex gap-x-1">
							<VerifiedUserIcon className={`text-compass_primary-accent 
							${ large ? "text-3xl" : "text-lg md:text-xl 2xl:text-3xl"}` } />
							<div className="flex flex-col w-full">
								<p className={`font-semibold line-clamp-1 text-compass_primary-accent
							${ large ? "text-2xl" : "text-medium md:text-base 2xl:text-lg"}`}>
									{
										label || module
									}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="flex h-full justify-center items-center w-full">
					<div className="flex flex-col gap-y-1 h-full justify-center items-center">
						{description &&
						<div className="text-small md:text-medium 2xl:text-base line-clamp-1 leading-3 w-full flex text-center">
							{description}
						</div>}
						<div>
							<ParentSize debounceTime={10}>

								{(parent) => (
									<div className="flex justify-center">
										<SimpleDonutGraph
											value={donutData}
											donutThickness={10}
											// donutThickness={globalStats ? 25 : 10}
											// donutInnerThickness={globalStats ? 30 : 18}
											donutInnerThickness={18}
											// width={300}
											// height={globalStats ? 330 : 150}
											height={150}
											values={true}
											donutColor={ratingsConfig.map((rating) => rating.color)}
											// fontSizeTextCenter={globalStats ? 80 : 60}
											fontSizeTextCenter={47}
											// fontSizeTextCenter={60}
											subTextCenter="%"
											subTextCenterSize={18}
											// subTextCenterSize={globalStats ? 40 : 18}
											margin = {{
												top: 10,
												right: 0,
												bottom: -10,
												left: 0
											}}
											textInnerColor={"black"}
											innerValueDigits={2}
											calOverAllScore={calOverAllScore}
										/>
									</div>
								)}
							</ParentSize>
						</div>

						{indicators && <div className="grid grid-cols-2 h-1/4 grid-rows-2 gap-x-1 mt-3 text-medium">
							{
								indicators?.map((indicator, i) => (
									<p key={`${indicator}${i}`} className={"ml-3 rounded-lg"}>
										<span className="font-semibold text-gray-700">{
											indicator.label}
										</span> : {item[indicator.key] > 0 ? item[indicator.key].toFixed(indicator.digit) : 0}
									</p>
								))
							}

						</div>}

					</div>

					{large && <div className="flex flex-col h-full pt-3">
						<div className="font-semibold pb-2 border-r">Legend</div>
						{ratingsConfig.map((element, i) => <div key={`legendRating ${ i}`}
							className="flex items-center gap-x-2 ">
							<div className="h-4 w-4 rounded-full" style={{ backgroundColor: element.color }} />
							<p className="text-medium">{element.rating}</p>
						</div>
						)}

					</div>}
				</div>

			</div>
		))
	);
}

SCAIndicatorCard.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	dataset2: PropTypes.string,
	joinKey: PropTypes.string,
	module: PropTypes.string,
	description: PropTypes.string,
	ratingsConfig: PropTypes.array,
	large: PropTypes.bool,
	locales: PropTypes.string,
	refineKey: PropTypes.string,
	indicators: PropTypes.array,
	label: PropTypes.string,
	globalStats: PropTypes.bool
};

export default SCAIndicatorCard;
