import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import AlphaTooltip from "../tooltips/AlphaTooltip";

const CreatFormBtnGroup = ({
	appSlice,
	locales,
	renderGroup = true,
	renderBack = false,
	renderAdd = true,
	renderDelete = false,
	renderValidate = false,
	clearRefineKeys = [],
	refineKey = [],
	onDelete = () => console.log("delete"),
	onValidate = () => console.log("validate"),
	addText = "",
	deleteText = "",
	validateText = "",
	setMarkerPosition
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const dispatch = useDispatch();

	return (renderGroup &&
		<div className="space-x-1">
			{renderBack
			&& <AlphaTooltip placement="top-start"
				title={<div className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90 bg-admin_primary-default rounded-2xl w-48">
					<span className="font-bold">{t("tooltip.back.title")}</span>
					{t("tooltip.back.description")}
				</div>}>
				<button
					type="button"
					className="h-9 border bg-admin_primary-default hover:bg-admin_primary-dark text-white font-bold py-1 px-3 rounded uppercase"
					onClick={() => {
						dispatch(appSlice.actions.clear(clearRefineKeys));
						if (setMarkerPosition) { setMarkerPosition(""); }
					}}>
					<ArrowBackIcon />
				</button>
			</AlphaTooltip>}
			{renderAdd
			&& <AlphaTooltip placement="top-start"
				title={<div className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90 bg-admin_primary-default rounded-2xl w-48">
					<span className="font-bold">{t(`tooltip.${addText}.title`)}</span>
					{t(`tooltip.${addText}.description`)}
				</div>}>
				<button
					type="button"
					className="h-9 border bg-admin_primary-default hover:bg-admin_primary-dark
                                    text-white font-bold py-1 px-3 rounded uppercase ml-1 mr-3"
					onClick={() => { dispatch(appSlice.actions.refine(refineKey)); }}>
					<AddIcon />
				</button>
			</AlphaTooltip>}
			{renderDelete
			&& <AlphaTooltip placement="top-start"
				title={<div className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90 bg-admin_primary-default rounded-2xl w-48">
					<span className="font-bold">{t(`tooltip.${deleteText}.title`)}</span>
					{t(`tooltip.${deleteText}.description`)}
				</div>}>
				<button
					type="button"
					className="h-9 border bg-admin_primary-default hover:bg-admin_primary-dark
                                    text-white font-bold py-1 px-3 rounded uppercase ml-1 mr-3"
					onClick={() => { onDelete(); }}>
					<DeleteForeverIcon />
				</button>
			</AlphaTooltip>}
			{renderValidate
			&& <AlphaTooltip placement="top-start"
				title={<div className="flex flex-col items-baseline gap-y-1 p-4 bg-opacity-90 bg-admin_primary-default rounded-2xl w-48">
					<span className="font-bold">{t(`tooltip.${validateText}.title`)}</span>
					{t(`tooltip.${validateText}.description`)}
				</div>}>
				<button
					type="button"
					className="h-9 border bg-admin_primary-default hover:bg-admin_primary-dark
                                    text-white font-bold py-1 px-3 rounded uppercase ml-1 mr-3"
					onClick={() => { onValidate(); }}>
					<DoneAllIcon />
				</button>
			</AlphaTooltip>}
		</div>
	);
};

CreatFormBtnGroup.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	renderAdd: PropTypes.bool,
	renderBack: PropTypes.bool,
	renderDelete: PropTypes.bool,
	renderValidate: PropTypes.bool,
	renderGroup: PropTypes.bool,
	clearRefineKeys: PropTypes.array,
	refineKey: PropTypes.array,
	onDelete: PropTypes.func,
	onValidate: PropTypes.func,
	addText: PropTypes.string,
	deleteText: PropTypes.string,
	validateText: PropTypes.string,
	setMarkerPosition: PropTypes.func
};

export default CreatFormBtnGroup;
