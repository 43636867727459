import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";
import { Auth } from "aws-amplify";

import LanguageIcon from "@mui/icons-material/Language";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { AuthState } from "@aws-amplify/ui-components";

import { userSelector, requestSignIn } from "../data/slices/UserSlice";

import IF from "../utils/IF";

import { appDefinition, appAllOptions, languageOption } from "./configs/LauncherConfig";
import "../styles/launcher.css";

import BVLogo from "../assets/svg/bvlogo_grey.svg";
import { openSaml } from "../auth/Authenticator";

const AppPictoRender = ({ appOption }) => {
	const MaterialPicto = appOption.pictoType === "materialUiIcon" ? appOption.picto : null;

	return (
		<React.Fragment>
			{appOption.pictoType === "materialUiIcon" ?
				<div className={`flex items-center justify-center w-16 md:w-20 h-16 md:h-20 rounded-2xl md:rounded-3xl 
                    transform transition duration-500 hover:scale-110 
                    ${appOption.access ? " " : " opacity-30"}`}
				style={{ backgroundColor: appOption.color }} >
					<div className="flex items-center justify-center text-white filter drop-shadow-glow">
						<MaterialPicto fontSize="large" className="text-white filter drop-shadow-glow" />
					</div>
				</div>
				:
				<div className={`w-16 md:w-20 h-16 md:h-20
                transform transition duration-500 hover:scale-110
                ${appOption.access ? " " : " opacity-30"}`}>
					<img src={appOption.picto} alt={appOption.picto} />
				</div>

			}
		</React.Fragment>
	);
};

AppPictoRender.propTypes = {
	appOption: PropTypes.object
};

const AppRender = ({ appOption }) => (
	<div className="flex flex-col flex-grow items-center md:w-80 space-x-1 space-y-2">
		<IF condition={appOption.external}>
			<a href={appOption.url}
				className={!appOption.access ? "pointer-events-none" : "cursor-pointer"}>
				<AppPictoRender appOption={appOption} />
			</a>
		</IF>
		<IF condition={!appOption.external}>
			<Link to={appOption.url}
				className={!appOption.access ? "pointer-events-none" : "cursor-pointer"}>
				<AppPictoRender appOption={appOption} />
			</Link>
		</IF>
		<div className="flex justify-center items-center">
			<p className={`text-center  md:text-xl font-semibold pr-2 leading-4
             ${!appOption.access ? " text-gray-500" : ""}`}>
				{appOption.label}
			</p>
		</div>
		<p className="text-center text-xs md:text-sm font-light px-4">
			{appOption.description}
		</p>
	</div>
);

AppRender.propTypes = {
	appOption: PropTypes.object,
	user: PropTypes.object
};

const Launcher = () => {
	// Internationalization hook
	const { t, i18n } = useTranslation(appDefinition.locales);

	// Load optional URL parameters
	const urlQuery = new URLSearchParams(window.localStorage.getItem("searchParams"));
	const authorizationCode = urlQuery.get("code");
	const [isAuthorizationCodeCopied, setIsAuthorizationCodeCopied] = useState(false);
	const [, forceUpdate] = useState();

	// Load the user data from the store of the App
	const user = useSelector(userSelector);

	const dispatch = useDispatch();

	useEffect(() => {
		const defautLanguages = ["en", "fr", "zh-Hans", "zh-Hant"];
		const isDefaultLanguage = defautLanguages.includes(i18n.language);

		if (!isDefaultLanguage) {
			i18n.changeLanguage("en");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load the favion
	useEffect(() => {
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isAuthorizationCodeCopied) {
			const timer = setTimeout(() => {
				navigator.clipboard.writeText(authorizationCode);
				window.localStorage.removeItem("searchParams");
				forceUpdate({});
			}, 3000);
			return () => {
				setIsAuthorizationCodeCopied(!isAuthorizationCodeCopied);
				return clearTimeout(timer);
			};
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthorizationCodeCopied]);

	const onlySAML = process.env.REACT_APP_AMPLIFY_ONLY_SAML ? process.env.REACT_APP_AMPLIFY_ONLY_SAML : process.env.REACT_APP_ONLY_SAML;

	async function signOut() {
		try {
			await Auth.signOut();
			window.location.reload();
		} catch (error) {
			console.log("error signing out: ", error);
		}
	}

	const appAllowed = user?.rights?.map((option) => option.app);

	const appOptions = user.rights
		? (
			appAllOptions.map((option) => {
				const identifiedOption = option;
				identifiedOption.access = !option.disabled && (!option.private ||
			user.rights.filter((e) => e.app === "ALPHA").length >= 1 ||
			user.rights.filter((e) => e.app === option.group).length >= 1 ||
			(user.type === "bv" && option.demobv));
				return identifiedOption;
			})
			.filter((appOption) => {
				if (appAllowed.includes("VERKOR") && !appAllowed.includes("SUPPLYR")) {
					return appOption.app !== "SUPPLYR";
				}
				if (!appAllowed.includes("VERKOR") && appAllowed.includes("SUPPLYR")) {
					return appOption.app !== "Verkor";
				}
				return appOption;
			})
		)
		: (
			appAllOptions
			.filter((app) => !app.private)
			.map((option) => {
				const identifiedOption = option;
				identifiedOption.access = !option.disabled;
				return identifiedOption;
			})
		);

	function checkUser() {
		Auth.currentAuthenticatedUser()
		// .then((user) => console.log({ user }))
		.catch((err) => console.log(err));
	}

	return (
		<div className="flex flex-col space-between w-full min-h-full  bg-gray-100">

			{/* <div className="flex flex-shrink-0 h-14">
				<Notification classNameLabel="flex h-full w-full items-center justify-between bg-gray-700 text-white px-2 md:px-10 ">
					<p>&nbsp;</p>
					<p className="text-sm">
						<span className="text-red-600 font-bold pr-1">NEW</span>
                        Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...
					</p>
				</Notification>
			</div> */}

			<div className="flex flex-col flex-grow bg-top bg-cover bg-no-repeat bg-launcher pt-6">
				<main className="flex flex-col flex-grow items-center justify-center w-full max-w-screen-2xl mx-auto z-10">
					<div className="flex flex-col justify-start rounded-xl min-h-128 mx-2 mb-4 alphaBlur">

						<div className="flex justify-between items-center p-2 md:p-6">
							<div className="flex justify-start items-center space-x-2">
								<p className="flex items-center justify-center rounded-full w-10 h-10
                                            text-white font-semibold bg-gray-600" >
									{user && user.loginState === AuthState.SignedIn ? user.initials : "G"}
								</p>
								<p className="text-sm md:text-base">{user && user.loginState === AuthState.SignedIn ? user.name : t("login.guest")}</p>
							</div>
							<div className="flex justify-center items-center space-x-4">
								{/* <p className="cursor-pointer" onClick={checkUser}>
									<InfoIcon fontSize="small" className="pr-1" />
									<span className="text-sm font-light underline">info</span>
								</p> */}

								{/* TODO: comment this part when local development is no longer needed */}
								{/* Launcher / Authenticator / App / UserProfile / package.json @aws-amplify/ui-react / .env */}
								{user && user.loginState === AuthState.SignedIn ? (
									<>
										{user.rights.filter((e) => e.app === "ALPHA" || e.app === "ADMIN").length >= 1 && <Link to={"/admin"}>
											<p className="cursor-pointer" onClick={checkUser}>
												<PermDataSettingIcon fontSize="small" className="pr-1" />
												<span className="text-sm font-light underline">{t("apps.admin")}</span>
											</p>
										</Link>}
										<p className="cursor-pointer" onClick={() => signOut()}>
											<PowerSettingsNewIcon fontSize="small" className="pr-1" />
											<span className="text-sm font-light underline">Logout</span>
										</p>
									</>
								) : (<>
									{onlySAML !== "True" &&
									<p className="cursor-pointer" onClick={() => dispatch(requestSignIn())}>
										<PowerSettingsNewIcon fontSize="small" className="pr-1" />
										<span className="text-sm font-light underline">Sign-in Cognito</span>
									</p>
									}
									<p className="cursor-pointer" id="sign_in" onClick={() => openSaml()}>
										<PowerSettingsNewIcon fontSize="small" className="pr-1" />
										<span className="text-sm font-light underline">Sign-in</span>
									</p>
								</>
								)}

								{/* {user && user.loginState === AuthState.SignedIn ? (
									<>
										{user.rights.filter((e) => e.app === "ALPHA").length >= 1 && <Link to={"/admin"}>
											<p className="cursor-pointer" onClick={checkUser}>
												<PermDataSettingIcon fontSize="small" className="pr-1" />
												<span className="text-sm font-light underline">{t("apps.admin")}</span>
											</p>
										</Link>}
										<p className="cursor-pointer" onClick={() => signOut()}>
											<PowerSettingsNewIcon fontSize="small" className="pr-1" />
											<span className="text-sm font-light underline">{t("login.signOut")}</span>
										</p></>
								) : (
									<>
										<div className="relative flex flex-col items-center ">
											<div className="absolute animate-bounce bottom-0 flex flex-col items-center w-28 mb-6 ">
												<span className="relative z-10 p-2 text-medium rounded-lg text-center
                                            leading-none text-white whitespace-no-wrap  shadow-xl"
												style={{ backgroundColor: "#FC3650" }} >
													<b>{t("login.signIn")}</b> {t("login.forPrivateApps")}
												</span>
												<div className="w-3 h-3 -mt-2 transform rotate-45 "
													style={{ backgroundColor: "#FC3650" }} />
											</div>

											<p className="cursor-pointer" onClick={() => openSaml()}>
												<PowerSettingsNewIcon fontSize="small" className="pr-1" />
												<span className="text-sm font-light underline">{t("login.signIn")}</span>
											</p>
										</div>
									</>
								)} */}
							</div>
						</div>
						<img className="self-center w-20 -mt-8 md:w-28 md:-mt-16" src={BVLogo} alt="bvlogo" />
						<div className="self-center text-center text-xl md:text-4xl font-bold pt-6">
							<p>
								<span style={{ color: "#FC3650" }}>MyRadar</span> <span className="font-light" style={{ color: "#858585" }}>
									{t("content.by")}
								</span>
							</p>
							<p className="font-light leading-5">Bureau Veritas</p>
						</div>
						{!authorizationCode && <div className="grid grid-cols-2 gap-y-10 my-10 mx-10 md:my-16 md:mx-28">
							{appOptions && appOptions.length > 0 && appOptions
							.sort((a, b) => a.label > b.label ? 1 : -1)
							.sort((a, b) => a.private < b.private ? 1 : -1)
							.sort((a, b) => a.access < b.access ? 1 : -1)
							.map((appOption, i) => (
								<AppRender key={i} appOption={appOption} />
							))}
						</div>}
						{authorizationCode &&
						<div className="grid grid-cols-1 gap-y-10 my-10 mx-10 md:my-16 md:mx-28 self-center text-center
						text-sm md:text-base">

							<p>Authorization Code:</p>
							<p className="cursor-pointer">
								{authorizationCode}&nbsp;<ContentCopyIcon onClick={() => {
									setIsAuthorizationCodeCopied(true);
								}}/>
							</p>
							{isAuthorizationCodeCopied &&
							<div className="-mt-5 font-bold text-red-500 self-center text-center">
								Authorization code copied. Redirect to launcher in 3s.
							</div>}
							<p>Expires in the next hour</p>
						</div>}
					</div>
				</main>
				<footer className="flex justify-center text-medium alphaBlur rounded-t-xl overflow-hidden text-black px-2 py-1 z-10">
					{/* <div>&nbsp;</div> */}
					<div className="flex w-full justify-between text-gray-800">
						<div className="w-1/3">&nbsp;</div>
						<div className="w-1/3 flex justify-center">© {new Date().getFullYear()} {t("content.legal")}</div>
						<div className="w-1/3 flex justify-end items-center space-x-2 text-gray-800">
							<div className="flex items-center space-x-1 ">
								<LanguageIcon fontSize="inherit" />
								<select name="language" id="language-select" className="w-20 rounded-sm bg-transparent outline-none cursor-pointer"
									onClick={(e) => i18n.changeLanguage(e.target.value)}>
									<option
										value="en"
										selected={i18n.language === "en"}
										className={`cursor-pointer hover:underline ${i18n.language === "en"
											? " font-bold text-alpha_primary-default"
											: ""
										}`}
									>
									EN
									</option>
									<option
										value="fr"
										selected={i18n.language === "fr"}
										className={`cursor-pointer hover:underline ${i18n.language === "fr"
											? " font-bold text-alpha_primary-default"
											: ""
										}`}
									>
									FR
									</option>
									<option
										value="zh-Hans"
										selected={i18n.language === "zh-Hans"}
										className={`cursor-pointer hover:underline ${i18n.language === "zh-Hans"
											? " font-bold text-alpha_primary-default"
											: ""
									}`}
									>
									Simplified Chinese (简体)
									</option>
									<option
										value="zh-Hant"
										selected={i18n.language === "zh-Hant"}
										className={`cursor-pointer hover:underline ${i18n.language === "zh-Hant"
											? " font-bold text-alpha_primary-default"
											: ""
									}`}
									>
									Traditional Chinese (繁體)
									</option>
								</select>
							</div>
						</div>
					</div>

				</footer>
			</div>
		</div>
	);
};

Launcher.propTypes = {
	userGroups: PropTypes.array
};

export default Launcher;
