import { Geo } from "aws-amplify";
import { customAlphabet } from "nanoid";
import merge from "lodash/merge";
import {
	dropdownRefBuilder, convertCountry, convertRegion, newProject, typeSelector, newBranchFirstNode
} from "../../apps/configs/AdminConfig";

// ***************************supplier Form*************************** //

// clear all fields except the based keys
export const clearRefines = (appSlice, activeRefines, dispatch, field = undefined) => {
	let baseRefineKeys = ["secLevelMenu", "projectName", "spaceLabel", "appAction", "supplyChainAction", "site", "thirdLevelMenu"];

	if (field) {
		baseRefineKeys = [...baseRefineKeys, ...field];
	}

	const activeRefinesKeys = Object.keys(activeRefines).filter((key) => !baseRefineKeys.includes(key));

	if (activeRefinesKeys.length > 0) {
		const transformActiveRefinesKeys = activeRefinesKeys.map((key) => ({ refine: key }));
		dispatch(appSlice.actions.clear(transformActiveRefinesKeys));
	}
};

// remove k key when field type is array before submit [{k:v}]->[v]
export const RemoveKey = (submitValue) => {
	// remove key k in case field is type of array of object
	const sumbitKeyValuePair = Object.entries(submitValue);

	const removeKeyKSubmitValue = sumbitKeyValuePair.reduce((arr, cur) => {
		let getValue;
		if (Array.isArray(cur[1])) {
			getValue = cur[1].map((el) => (el.k));
			return { ...arr, [cur[0]]: getValue };
		}

		return { ...arr, [cur[0]]: cur[1] };
	}, {});

	return removeKeyKSubmitValue;
};

// This part is only for additional field
// Do not forget to synchronize this with the following function AllKeyTypePairBuilder for additional custom field
export const AllFieldsBuilder = ({
	project, data, activeRefines, activeDatasets, source
}) => {
	const existingField = data?.flatMap((el) => Object.keys(el));

	const allKeysOfCurCollection = Object.keys(newBranchFirstNode({ activeRefines, activeDatasets, source }));

	switch (project) {
		case "supplyR":
			return [...existingField, ...allKeysOfCurCollection, "supplierCode", "factoryCode"];
		case "verkor":
			return [...existingField, ...allKeysOfCurCollection, "address", "geopointToR", "city", "targetDate"];
		case "clarity":
			return [...existingField, ...allKeysOfCurCollection, "inactive", "supplierCode", "factoryCode"];
		case "ataglance":
			return [...existingField, ...allKeysOfCurCollection, "siteUniqueId", "siteId" ];

		default:
			return existingField;
	}
};

// This part is only for additional field
export const AllKeyTypePairBuilder = ({
	project, data, activeRefines, activeDatasets, source
}) => {
	const existingFieldValue = data.flatMap((el) => Object.entries(el));

	const allKeyValueTypeOfCurCollection = Object.entries(newBranchFirstNode({ activeRefines, activeDatasets, source }))
	.reduce((acc, [k, v]) => [...acc, [k, v]], []);

	switch (project) {
		case "supplyR":
			return [...existingFieldValue, ...allKeyValueTypeOfCurCollection, ["supplierCode", ""], ["factoryCode", ""]];
		case "verkor":
			return [...existingFieldValue, ...allKeyValueTypeOfCurCollection, ["address", ""], ["geopointToR", ""], ["city", ""], ["targetDate", ""]];
		case "clarity":
			return [...existingFieldValue, ...allKeyValueTypeOfCurCollection, ["inactive", false], ["supplierCode", ""], ["factoryCode", ""]];
		case "ataglance":
			return [...existingFieldValue, ...allKeyValueTypeOfCurCollection, ["siteUniqueId", ""], ["siteId", ""] ];

		default:
			return existingFieldValue;
	}
};

// check if coordinates is well formatted
export const regExpPattern = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const isCoordinates = (value) => regExpPattern.test(value);

// dropdown content builder
export const searchOptionBuilder = (activeRefines, field, searchSelector, activeDatasets) => {
	if (typeof searchSelector(activeRefines.secLevelMenu, field) === "string") {
		return activeDatasets[searchSelector(activeRefines.secLevelMenu, field)]?.data;
	}

	// if not return string type then return type is array, which means we concatenate response from multiple collections
	const res = searchSelector(activeRefines.secLevelMenu, field)?.map((collection) => activeDatasets[collection].data).flat()
	?.filter((item, i, self) => self?.findIndex((item2) => (item2?.label === item?.label)) === i);

	return res;
};

// on key generate coordinates based on address field
export const getGeoPointByClick = (inputValue, setGeoList) => {
	Geo.searchByText(inputValue, {
		maxResults: 10
	})
	.then((res) => {
		console.log("location results loaded", res);
		if (res) { setGeoList(res); }
		return res;
	})
	.catch((err) => console.log("err", err));
};

export const setReferenceFields = ({
	selectedDocument, getValues, activeRefines, reset, setMarkerPosition
}) => {
	// convert value of specific key, ex: South Korea -> Korea
	const convertTargetSupplier = {
		...selectedDocument,
		country: convertCountry[selectedDocument?.country] ? convertCountry[selectedDocument?.country] : selectedDocument?.country,
		region: convertRegion[selectedDocument?.region] ? convertRegion[selectedDocument?.region] : selectedDocument?.region
	};

	const newSupplierData = dropdownRefBuilder({ selectedDocument: convertTargetSupplier, currentDocument: getValues(), activeRefines });

	reset(newSupplierData);

	// fill the form with existing data and set the map view for preview if possible
	if (newSupplierData.geopointToR) { setMarkerPosition(newSupplierData.geopointToR); }
};

// rerender siteLabelId/siteId when supplier/auditedCompany/company changed
export const refreshSiteLabelId = ({
	activeDatasets, activeRefines, getValues, setValue, reset, setMarkerPosition
}) => {
	let newSiteLabelId = "";
	let targetSupplier = {};
	let supplierSeq = [];

	// for supplyR refreshing siteLabelId
	// get all documents related to current supplier value in current project
	// then calculate the sequence num for new siteLabelId
	if (activeRefines.secLevelMenu === "supplyR") {
		supplierSeq = activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data
		.filter((item) => getValues("supplier").toLowerCase() === item.supplier.toLowerCase())
		.map((item) => Number(item.siteLabelId.split("-")?.[1]))
		.sort((a, b) => b - a);

		// if undefine then create a new siteLabelId otherwise retake the current target Document's siteLabelId
		targetSupplier = activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data
		.find((item) => getValues("supplier").toLowerCase() === item.supplier.toLowerCase() &&
                        getValues("auditedCompany").toLowerCase() === item.auditedCompany.toLowerCase());

		if (!targetSupplier) {
			const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);
			newSiteLabelId = nanoid();
			setValue("siteLabelId", newSiteLabelId);
		}

		if (targetSupplier) {
			setReferenceFields({
				selectedDocument: targetSupplier, getValues, activeRefines, reset, setMarkerPosition
			});
		}
	}

	// for clarity refreshing siteId
	if (activeRefines.secLevelMenu === "clarity") {
		targetSupplier = activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data
		.find((item) => getValues("supplier").toLowerCase() === item.supplier.toLowerCase() &&
                        getValues("company").toLowerCase() === item.company.toLowerCase());

		if (targetSupplier) {
			setReferenceFields({
				selectedDocument: targetSupplier, getValues, activeRefines, reset, setMarkerPosition
			});
		}
	}

	if (activeRefines.secLevelMenu === "ataglance") {
		// if undefine then create a new siteLabelId otherwise retake the current target Document's siteLabelId
		targetSupplier = activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data
		.find((item) => getValues("supplier").toLowerCase() === item.supplier.toLowerCase() &&
                        getValues("auditedCompany").toLowerCase() === item.auditedCompany.toLowerCase());

		if (!targetSupplier) {
			const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);
			newSiteLabelId = nanoid();

			setValue("siteLabelId", newSiteLabelId);
			setValue("siteUniqueId", newSiteLabelId);
		}

		if (targetSupplier) {
			setReferenceFields({
				selectedDocument: targetSupplier, getValues, activeRefines, reset, setMarkerPosition
			});
		}
	}
};

export const FieldNameAccessor = ({ field, activeRefines }) => {
	if (field === "siteLabelId" && activeRefines.secLevelMenu === "supplyR") return "SiteLabelID (MAIA+ Site Description)";

	if (activeRefines.secLevelMenu === "ataglance") {
		if (field === "auditedCompany") return "auditedCompany / company";
		if (["domain", "inactive", "siteId", "volume"].includes(field)) return `${field} (Clarity only)`;

		if (["factoryBranch", "factoryCode", "factoryIndex", "geopointToR", "logisticAgent",
			"nbModules", "PAQid", "supplierCode", "siteLabelId", "tierLabel", "validated"].includes(field)) {
			return `${field} (SupplyR only)`;
		}

		if (field === "siteUniqueId") return `${field} (SupplyR/Clarity)`;
		if (field === "client" && (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams")) return "common.client";
		if (field === "logo" && (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams")) return "common.logo";
		if (field === "uploadLogo"
				&& (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams")) return "common.uploadLogo";
		if (field === "projectLabel" && (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams")) {
			return "common.projectLabel";
		}
		if (field === "projectName" && (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams")) {
			return "common.projectName";
		}
		if (field === "supplychainParams" && (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams")) {
			return "common.supplychainParams";
		}
		if (field === "PAQpreprocessed" && (activeRefines.appAction === "createParams" || activeRefines.appAction === "updateParams")) {
			return "common.PAQpreprocessed";
		}
	}

	return field;
};

// ***************************project Form*************************** //
// This part is only for additional field
// Do not forget to synchronize this with the following function projectAllKeyTypePairBuilder
// case 1: existingField -> all the keys in current document
// case 2: allKeysOfCurCollection -> all the keys in current collection, existingField may be various since the access right is not always the same,
// we should align the field to case 1. For example, for admin right user(with Auchan and Bouygues access right only), he can not access the key like BVSites, MaturityColors etc
// which are only possessed by another project Boohoo(for example), so he cannot manipulate them since they re missing in updateForm.
// case 3: particular field never exists before among all projects, for example statusTracking.
export const projectAllFieldsBuilder = ({ project, data }) => {
	const existingField = data?.flatMap((el) => Object.keys(el));

	const allKeysOfCurCollection = Object.keys(newProject({ project }));

	switch (project) {
		case "clarity":
			return [...existingField, ...allKeysOfCurCollection, "statusTracking"];
		case "supplyR":
			return [...existingField, ...allKeysOfCurCollection];
		case "verkor":
			return [...existingField, ...allKeysOfCurCollection];
		case "ataglance":
			return [...existingField, ...allKeysOfCurCollection];

		default:
			return existingField;
	}
};

// This part is only for additional field
export const projectAllKeyTypePairBuilder = ({ project, data }) => {
	const existingFieldValue = data.flatMap((el) => Object.entries(el));

	const allKeyValueTypeOfCurCollection = Object.entries(newProject({ project }))
	.reduce((acc, [k, v]) => [...acc, [k, v]], []);

	switch (project) {
		case "clarity":
			return [...existingFieldValue, ...allKeyValueTypeOfCurCollection, ["statusTracking", false] ];
		case "supplyR":
			return [...existingFieldValue, ...allKeyValueTypeOfCurCollection];
		case "verkor":
			return [...existingFieldValue, ...allKeyValueTypeOfCurCollection];
		case "ataglance":
			return [...existingFieldValue, ...allKeyValueTypeOfCurCollection];

		default:
			return existingFieldValue;
	}
};

const isSubset = (subset, set) => subset.every((item) => set.includes(item));

export const RemoveEmptyKey = (submitValue) => {
	const removePattern = ["", undefined, [""]];

	const result = Object.entries(submitValue).reduce((acc, cur) => {
		const [k, v] = cur;

		if (
			removePattern.includes(v) ||
			(Array.isArray(v) && v?.length === 0) ||
			(Array.isArray(v) && v?.length === 1 && v[0] === "") ||
			(Array.isArray(v) &&
				v?.length === 1 &&
				typeof v[0] === "object" &&
				!Array.isArray(v[0]) &&
				isSubset(Object.values(v[0]), ["", 0]))
		) {
			return { ...acc };
		}

		return { ...acc, [k]: v };
	}, {});

	return result;
};

// ***************************common*************************** //

export const transformValueWithK = (targetDocument) => (
	targetDocument && Object.keys(targetDocument)?.reduce((acc, cur) => {
		let objWithK;
		if (Array.isArray(targetDocument[cur])) { objWithK = targetDocument[cur].map((el) => ({ k: el })); return { ...acc, [cur]: objWithK }; }
		if (typeof (targetDocument[cur]) === "object" && !Array.isArray(targetDocument[cur])) {
			const nestedObjWithK = Object.entries(targetDocument[cur]).reduce((pre, [key, value]) => {
				let res;
				if (Array.isArray(value)) {
					res = value.map((el) => ({ k: el }));
					return { ...pre, [key]: res };
				}

				if ((typeof value === "object") && !Array.isArray(value)) {
					res = transformValueWithK(value);

					return { ...pre, [key]: res };
				}

				return { ...pre, [key]: value };
			}, {});

			return { ...acc, [cur]: nestedObjWithK };
		}
		return { ...acc, [cur]: targetDocument[cur] };
	}, {})
);

const isEmptyObject = (obj) => {
	if (!Array.isArray(obj) && typeof obj === "object") {
		return obj != null && Object.keys(obj).length === 0;
	}
	return false;
};

const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0;

const removeFalsyKeys = (obj) => Object.entries(obj).reduce((acc, [key, value]) => {
	if (
		value !== null &&
		value !== undefined &&
		!isEmptyArray(value) &&
		!isEmptyObject(value) &&
		value !== ""
	) {
		if (typeof value === "object" && !Array.isArray(value)) {
			acc[key] = removeFalsyKeys(value);
		} else {
			acc[key] = value;
		}
	}
	return acc;
}, {});

// deeply merge two object as one, but if the key's value in latter one is null or [] or {} or "", then take the key's value of the former one object
export const mergeObjects = (obj1, obj2) => {
	// remove falsy value from obj2 in order to avoid overwrite in obj1 (base)
	removeFalsyKeys(obj2);
	return merge(obj1, obj2);
};

// {
// 	k1:{
// 		k2:{
// 			k3:"test"
// 		 }
// 	 },
//  j1:{
// 		j2:{
// 			j3:"test"
// 		 }
// 	 }
// }
// If you call joinKeys with nestedObj and a depth of 2 (joinKeys({ obj:nestedObj, keys:[k1,j1], depth:2 })),
// the function will return ["k1.k2", "j1.j2"]. If you call it with a depth of 3 (joinKeys({ obj:nestedObj, keys:[k1,j1], depth:3 })),
// it will return ["k1.k2.k3", "j1.j2.j3"].
// keys means the key should be processed
export function joinKeys({ obj, keys, depth }) {
	const result = [];
	const traverse = (currObj, currPath, currDepth) => {
		Object.keys(currObj).forEach((key) => {
			const newPath = currPath.length ? `${currPath}.${key}` : key;
			if (keys.includes(newPath.split(".")[0])) {
				if (typeof currObj[key] === "object" && currDepth < depth) {
					traverse(currObj[key], newPath, currDepth + 1);
				} else if (currDepth === depth) {
					result.push(newPath);
				}
			}
		});
	};
	traverse(obj, "", 1);
	return result;
}
