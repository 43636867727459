import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { getAggregation } from "../../api/client";
import FilterMenu from "./FilterMenu";
import RYBSearchMenu from "./RYBSearchMenu";

import { fieldsToSearch, filterTypeOptions } from "../../apps/configs/RYBConfig";

const customStyles = (refineValue, placeHolder) => ({
	indicatorSeparator: (state) => ({
		display: "none"
	}),
	control: (provided, state) => ({
		...provided,
		background: "#fff",
		borderColor: "#9e9e9e",
		minHeight: "30px",
		height: "30px",
		border: 0,
		boxShadow: state.isFocused ? null : null
	}),
	input: (provided, state) => ({
		...provided,
		margin: "0px"
	}),
	valueContainer: (provided, state) => ({
		...provided,
		height: "30px",
		padding: "0 0px",
		fontSize: 14,
		fontWeight: 400
	})
});

function RYBFilterMenu({ appSlice, locales, rybColorMap }) {
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	// Internationalization hook
	const { t } = useTranslation(locales);

	// load options using API call
	const loadOptions = async (inputValue) => {
		const results = await Promise.all(
			fieldsToSearch(activeRefines, inputValue)
			.map(async (field) => getAggregation("ryb", field.collection, field.aggregation, field.limit, 0, true))
		);

		return Array.prototype.concat.apply([], results);
	};

	return (
		<div
			className="absolute flex items-center w-2/3 rounded shadow-lg left-1/2
            transform -translate-x-1/2 -bottom-10 text-sm text-ryb_primary-bg bg-white p-1 z-10 xl:w-3/5 lg:p-2 "
		>
			<div className="h-full min-w-min flex flex-shrink-0 justify-start flex-col content-start flex-grow px-2">
				<p>{t("search.sitesearch")}</p>
				<RYBSearchMenu
					appSlice={appSlice}
					customStyle={customStyles}
					placeHolder={t("search.sitesearchplaceholder")}
					loadOptions = {loadOptions}
					labelSiteKey="site"
					valueSiteKey ="siteTechnicalId"
					labelCityKey="_id.city"
					valueCityKey="siteCity"
					labelCountryKey ="_id.country"
					valueCountryKey ="siteCountry"
					rybColorMap={rybColorMap}
				/>
			</div>
			<div className="h-full w-48 hidden lg:flex justify-start flex-col border-l-1 border-gray-300 px-2">
				<p>{t("search.typesearch")}</p>
				<FilterMenu
					appSlice={appSlice}
					customStyle={customStyles}
					options={filterTypeOptions(t("search.alltypes"))}
					menuColor="#1876bd"
					isSearchable={false}
					locales={locales}
				/>
			</div>
		</div>
	);
}

RYBFilterMenu.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	rybColorMap: PropTypes.object
};

export default RYBFilterMenu;
