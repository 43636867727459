import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useSpring, animated, config } from "react-spring";
import { useTranslation } from "react-i18next";

function RenderSDGBar({ targetHeight, color }) {
	// animation conf for the scope bar
	const sdgHeight = useSpring({
		from: { height: 0 },
		to: { height: targetHeight ?? 0 },
		delay: 200,
		config: config.molasses
	});

	return (
		<animated.div className="absolute bottom-0 w-full"
			style={{ backgroundColor: color, ...sdgHeight }} />
	);
}

RenderSDGBar.propTypes = {
	targetHeight: PropTypes.number,
	color: PropTypes.string
};

function ClaritySDGCard({
	appSlice, appDefinition, SDG, SDGScore, SDGOption
}) {
	// Load the store hook
	const dispatch = useDispatch();

	const { t } = useTranslation(appDefinition.locales);

	return (
		<div className="relative flex flex-col justify-center items-center rounded-xl shadow-md
        text-white gap-y-8 py-4 w-40 md:w-52 h-36  overflow-hidden cursor-pointer"
		onClick={() => dispatch(appSlice.actions.refine([{ key: "SDG", value: SDG }])) }>
			<div className="absolute w-full h-full filter brightness-75 contrast-150"
				style={{ backgroundColor: SDGOption?.color }} />
			<RenderSDGBar targetHeight={SDGScore * 1.44} color={SDGOption?.color} />
			<div className="flex items-center self-start gap-x-2 px-2 z-10  ">
				<img src={SDGOption?.picto} className="h-12 filter brightness-0 invert" />
				<p className="font-semibold text-left text-sm line-clamp-3 w-32" >
					{SDG} - {t(`SDGs.${SDG}.name`)}
				</p>
			</div>
			<p className="text-5xl font-bold z-10" >{(SDGScore)?.toFixed(0)}%</p>
		</div>
	);
}

ClaritySDGCard.propTypes = {
	appSlice: PropTypes.object,
	SDG: PropTypes.string,
	SDGScore: PropTypes.number,
	SDGOption: PropTypes.object,
	appDefinition: PropTypes.object
};

export default ClaritySDGCard;
