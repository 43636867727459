import PropTypes from "prop-types";
import { useSpring, animated, config } from "react-spring";

function RenderSDGBar({ targetHeight, color }) {
	// animation conf for the scope bar
	const sdgHeight = useSpring({
		from: { height: 0 },
		to: { height: Number.isNaN(targetHeight) ? 0 : targetHeight },
		delay: 200,
		config: config.molasses
	});

	return (
		<animated.div className="absolute bottom-0 w-full  z-0"
			style={{ background: color, ...sdgHeight }} />
	);
}

RenderSDGBar.propTypes = {
	targetHeight: PropTypes.string,
	color: PropTypes.string
};

function ClaritySDGIndicatorCard({ score, color }) {
	return (
		<div className="relative flex flex-shrink-0 items-center justify-center w-28 h-32 overflow-hidden rounded-3xl shadow-md z-10">
			<div className="absolute h-full w-full bg-black opacity-30 " style={{ zIndex: -1 }}/>
			<RenderSDGBar targetHeight={score * 1.28} color={color} />
			<p className="text-white text-3xl font-light text-shadow z-10">
				{(score).toFixed(0)}%
			</p>
		</div>
	);
}

ClaritySDGIndicatorCard.propTypes = {
	score: PropTypes.number,
	color: PropTypes.string
};

export default ClaritySDGIndicatorCard;
