import PropTypes from "prop-types";
import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Group } from "@visx/group";
import { hierarchy, Tree } from "@visx/hierarchy";

import { nanoid } from "nanoid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandIcon from "@mui/icons-material/Expand";

import { appDefinition, TORCollection } from "../../apps/configs/AdminConfig";

import AdminTooltip from "../tooltips/AdminTooltip";
import BezierLink from "./BezierLink";
import TreeType from "./TreeType";
import {
	AnimatedPlus, AnimatedEdit, AnimatedBin, AnimateEditLocation
} from "./ManipulationGroup";

import { deleteManyDocs } from "../../data/slices/createAdminSlice";
import { SupplierDelete } from "../form/AdminFormOnConfirm";
import Popconfirm from "../modal/Popconfirm";

const productIcon = <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="product-hunt"
	className="svg-inline--fa fa-product-hunt fa-w-16 h-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
	<path fill="currentColor" d="M326.3 218.8c0 20.5-16.7 37.2-37.2 37.2h-70.3v-74.4h70.3c20.5 0 37.2 16.7 37.2 37.2zM504
     256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-128.1-37.2c0-47.9-38.9-86.8-86.8-86.8H169.2v248h49
     .6v-74.4h70.3c47.9 0 86.8-38.9 86.8-86.8z"></path></svg>;

// Number of children to expand initally
const nbChildrenToInitialExpand = 10;

// node size
const rectW = 110;
const rectH = 50;

const subTreesBetweenDistance = 100;
const collapsedSubTreesBetweenDistance = 10;

const verticalSeparationNodes = 0.3;
const verticalSeparationParentNodes = 0.32;

const AdminTreeNodesGraph = ({
	width: parentWidth = 600,
	height: parentHeight = 1000,
	margin = {
		top: 70, left: -200, right: 0, bottom: 220
	},
	appSlice,
	datasetTree,
	supplychainView,
	setSupplychainView,
	setAlert
}) => {
	const subTreeHeightArr = [0];
	const currentSubTreeTopHalfHeight = [];
	const subTreeWidthArr = [];

	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const supplyChainData = useSelector(appSlice.selectDatasets)[datasetTree];
	const activeFinalProducts = useSelector(appSlice.selectDatasets)[`${activeRefines?.secLevelMenu}SupplyChainActiveFinalProducts`]?.data;

	const loadStatus = useSelector(appSlice.selectLoadDataStatus);

	const [collapseAll, setCollapseAll] = useState(undefined);
	const [confirmModal, setconfirmModal] = useState(false);
	const [deleteNodes, setDeleteNodes] = useState(undefined);
	const [parentNode, setParentNode] = useState(undefined);

	const dispatch = useDispatch();

	const [selected, setSelected] = useState(null);

	const [dynamicWidth, setDynamicWidth] = useState(0);
	const [dynamicHeight, setDynamicHeight] = useState(0);

	const [expandedNodeDepths, setExpandedNodeDepths] = useState([
		0, 1, 2, 3, 4, 5
	]);
	const [, forceUpdate] = useState();

	const treeData = useMemo(() => {
		if (
			!supplyChainData ||
			!supplyChainData.data ||
			supplyChainData.data.children.length === 0
		) {
			return null;
		}

		return hierarchy(supplyChainData.data);
	}, [supplyChainData]);

	const svgWidth = useMemo(() => dynamicWidth - 2 * rectW > parentWidth
		? dynamicWidth - 2 * rectW
		// eslint-disable-next-line react-hooks/exhaustive-deps
		: parentWidth, [dynamicWidth]);

	const svgHeight = useMemo(() => dynamicHeight - 2 * rectH > parentHeight
		? dynamicHeight - 2 * rectH
		// eslint-disable-next-line react-hooks/exhaustive-deps
		: parentHeight, [dynamicHeight]);

	// use for svg dynamic height and width
	// we only setState when the last tree renders otherwise error
	const maxIndex = treeData && treeData.children && treeData.children.length - 1;

	if (loadStatus !== "idle" || !treeData?.children?.[0]?.data?.name || treeData?.children?.[0]?.data?.name === "") {
		return null;
	}

	const onClickDelete = () => (
		SupplierDelete({
			appSlice,
			dispatch,
			deleteManyDocs,
			TORCollection,
			activeRefines,
			deleteNodes,
			parentNode,
			setSelected,
			forceUpdate,
			setAlert,
			supplychainView,
			setconfirmModal
		})
	);

	return parentWidth < 10 ? null : (

		<div className="relative w-full h-full">

			{confirmModal &&
				<Popconfirm
					title="Delete current supplier"
					description="Would you like to delete this supplier along with all of its upstream nodes (to the right), if they exist?"
					confirmBtnText="Confirm"
					onClickConfirm={onClickDelete}
					onClickCancel={() => { setconfirmModal(false); setDeleteNodes(undefined); setParentNode(undefined); }}
				/>
			}
			<AddCircleOutlineIcon className="absolute top-2 right-5 text-admin_primary-default hover:cursor-pointer" fontSize="large"
				onClick={() => {
					dispatch(appSlice.actions.refine([
						{ key: "supplyChainAction", value: "createBranch" }
					]));
				}}/>

			<ExpandIcon className="absolute top-2 right-20 text-admin_primary-default hover:cursor-pointer rotate-90" fontSize="large"
				onClick={() => { setCollapseAll(!collapseAll); }}/>

			<svg width={svgWidth} height={svgHeight}>

				<Group top={margin.top} left={margin.left}>
					{treeData?.children.map((item, i) => (
						<Tree
							key={i}
							root={hierarchy(item, (d) => {
								if (
									d.depth >= 2 &&
									d.isExpanded === undefined &&
									collapseAll === undefined &&
									d.children &&
									d.children.length > nbChildrenToInitialExpand
								) {
									d.isExpanded = true;
								}

								if (d.depth >= 2 && collapseAll) {
									d.isExpanded = true;
								}

								if (
									d.depth >= 2 &&
									collapseAll !== undefined &&
									!collapseAll
								) {
									d.isExpanded = false;
								}

								return expandedNodeDepths.includes(d.depth) &&
								!d.isExpanded
									? d.children
									: null;
							})}

							nodeSize={[240, 270]}
							separation={(a, b) => (a.parent === b.parent ? verticalSeparationNodes : verticalSeparationParentNodes)}
						>
							{(tree) => {
								const treeCoordinateYArr = tree
								.descendants()
								.map((node) => node.x);

								const treeCoordinateXArr = tree
								.descendants()
								.map((node) => node.y);

								// get tree lowest y/ top y / right x / left x
								const treeYMax = Math.max(...treeCoordinateYArr) + rectH;
								const treeYmin = Math.min(...treeCoordinateYArr);

								const treeXMax = Math.max(...treeCoordinateXArr);
								const treeXmin = Math.min(...treeCoordinateXArr);

								// get current sub tree height / width
								const subTreeH = treeYMax - treeYmin;
								const subTreeW = treeXMax - treeXmin;

								// calculate current sub tree shifting
								subTreeHeightArr.push(
									subTreeH + subTreesBetweenDistance
								);
								currentSubTreeTopHalfHeight.push(
									Math.abs(treeYmin) + rectH / 2
								);

								// rendre subtree base on collapse
								const isRendered = activeFinalProducts.find(
									(element) => element.finalProduct === tree?.data?.data?.name
								);

								// collapse or not
								if (!isRendered) {
									subTreeHeightArr[i + 1] =
										rectH / 2 + collapsedSubTreesBetweenDistance;
								}

								// current tree cumulative shifting
								const currentTreeShifting = subTreeHeightArr.reduce((acc, cur, key) => i !== key - 1 ? acc + cur : acc
								) + currentSubTreeTopHalfHeight[i];

								// calculate dynamic width

								subTreeWidthArr.push(subTreeW + 2 * rectW);

								// console.log(`*********${i} DEBUG START***********`);
								// console.log("isRendered", isRendered);
								// console.log("currentTreeShifting", currentTreeShifting);
								// console.log("single branch num",
								// 	currentSubTreeTopHalfHeight.filter((item, index) => item === rectH / 2));
								// console.log("treeCoordinateYArr", treeCoordinateYArr);
								// console.log("treeYax", treeYMax);
								// console.log("treeYmin", treeYmin);
								// console.log("subTreeH", subTreeH);
								// console.log("treeXMax", treeXMax);
								// console.log("treeXmin", treeXmin);
								// console.log("subTreeW", subTreeW);
								// console.log("subTreeHeightArr", subTreeHeightArr);
								// console.log("subTreeWidthArr", subTreeWidthArr);
								// console.log("currentSubTreeTopHalfHeight", currentSubTreeTopHalfHeight);
								// console.log("maxIndex", maxIndex);
								// console.log(`----------${i} DEBUG END----------`);

								// svg height = last sub tree shifting + last sub tree lower half height + margin bottom
								// svg width =maximum sub tree width + 2 * rectW   (default 1050)
								if (i === maxIndex) {
									setDynamicWidth(Math.max(...subTreeWidthArr));
									setDynamicHeight(
										currentTreeShifting +
												Math.abs(treeYMax) +
												margin.bottom
									);
								}

								return <Group top={origin.y} left={origin.x}>
									{tree.links().map((link, i) => (
										link.source.depth !== 0
										&& isRendered
										&& <BezierLink
											/* key={`link-${link.source.data.data._id ?? nanoid()}-${link.target.data.data._id ?? nanoid()}`} */
											key={`link-${nanoid()}`}
											data={link}
											rectW={70}
											currentTreeShifting={currentTreeShifting}
											stroke="#1C3FAA"
											startXCompensation={20}
											endXCompensation={35}
											compensation={100}
										/>
									))}

									{tree.descendants().map((node, key) => (
										<Group top={node.x} left={node.y}
											key={node.depth === 0
												? `node-${node.depth}-${node.data.data.name}-${key}`
												: `node-${node.depth}-${node.data.data._id}-${key}`}
										>
											{node.depth === 0 && (
												<TreeType
													key={`TreeType-${node.depth}-${node.data.data.name ?? nanoid()}-${key}`}
													appSlice={appSlice}
													treeTypeHeight={
														node.depth === 0 && treeYmin - rectH / 2
													}
													name={node.data.data.name}
													currentTreeShifting={currentTreeShifting}
													activeFinalProducts={activeFinalProducts}
													nbTotalFinalProducts={
														supplyChainData?.data?.children?.length
													}
													locales={appDefinition.locales}
													textX={255}
													arrowX={235}
													titleCase={false}
												/>
											)}

											{node.depth !== 0 && isRendered && (
												<>
													<g className="group">
														<foreignObject width={1.3 * rectW} height={rectH} x={-0.35 * rectW}
															y={-0.5 * rectH + currentTreeShifting}
														>
															<div className={"w-full h-full rounded-xl pr-3 mr-10"}
																key={`nodeBody-${node.depth}-${node.data.data._id ?? nanoid()}-${key}`}>
																<div
																	className={`relative flex flex-grow border-l-8 border-t border-b 
																		border-r mr-16 justify-between items-center text-xs w-full 
																		h-full py-2 pl-2 pr-4 rounded-xl bg-white 
																		
																		${node?.data?.data.validated === "False"
																		? "border-l-4 border-risk-high opacity-70"
																		: node?.data?.data.validated === "On Hold"
																			? "border-l-4 border-risk-low opacity-70"
																			: "border-admin_primary-default"}`}
																	onClick={() => {
																		node.data.isExpanded = !node.data.isExpanded;
																		node.descendants()
																		.forEach((node, i) => { if (i !== 0) { node.data.isExpanded = true; } });

																		node.data.selected = true;

																		setCollapseAll(undefined);
																		setSelected(node);
																	}}
																>
																	<div className="flex flex-col w-full" >
																		<p className="flex font-light leading-4">
																			<span className="w-14 overflow-ellipsis
																						overflow-hidden inline-flex capitalize">
																				{node?.data.data?.type ?? "type"}
																			</span>
																			&nbsp;
																			<span className="w-9 overflow-ellipsis overflow-hidden capitalize">
																				{node?.data.data?.criticality ?? "criticality"}
																			</span>
																		</p>
																		<p className="w-full leading-3 text-smaller font-semibold line-clamp-1
																					overflow-hidden overflow-ellipsis capitalize" >
																			{node?.data.data?.auditedCompany ?? "company"}
																		</p>
																		<div className="flex leading-3 items-center gap-x-1 text-smaller ">
																			<p className="flex-shrink-0">{productIcon}</p>
																			<p className="line-clamp-1 w-full overflow-hidden overflow-ellipsis
																						capitalize">
																				{node?.data?.data?.product ?? "product"}
																			</p>
																		</div>
																	</div>

																</div>
															</div>
														</foreignObject>
														<foreignObject width={30} height={30} x={0.75 * rectW}
															y={-0.15 * rectH + currentTreeShifting}>
															{(<div className={`flex items-center justify-center flex-shrink-0 rounded-full 
																bg-admin_primary-default w-4.5 h-4.5 font-bold text-white cursor-pointer text-xs`}>
																{(node.data.children && node.data.children.length) || 0}
															</div>)}
														</foreignObject>

														{(
															<AdminTooltip
																appSlice={appSlice}
																node={node}
																rectW={rectW * 2}
																currentTreeShifting={
																	currentTreeShifting
																}
															/>
														)}
													</g>

													<>
														<svg
															key={`add-${node.data.data._id ?? nanoid()}`}
															className="cursor-pointer"
															width="25"
															height="25"
															x={-40}
															y={currentTreeShifting + 21}
															onClick={(e) => {
																dispatch(appSlice.actions.refine([
																	{ key: "supplyChainAction", value: "createNode" },
																	{ key: "site", value: node.data.data._id }
																]));
															}}
														>
															<AnimatedPlus />
														</svg>

														<svg
															key={`edit-${node.data.data._id ?? nanoid()}`}
															className="cursor-pointer"
															width="25"
															height="25"
															x={-22}
															y={currentTreeShifting + 21}
															onClick={(e) => {
																dispatch(appSlice.actions.refine([
																	{ key: "supplyChainAction", value: "updateNode" },
																	{ key: "site", value: node.data.data._id }]));
															}}
														>
															<AnimatedEdit />
														</svg>

														<svg
															key={`location-${node.data.data._id ?? nanoid()}`}
															className="cursor-pointer"
															width="25"
															height="25"
															x={5}
															y={currentTreeShifting + 23.5}
															onClick={(e) => {
																dispatch(appSlice.actions.refine([
																	{ key: "supplyChainAction", value: "updateLocation" },
																	{ key: "site", value: node.data.data._id }]));
															}}
														>
															<AnimateEditLocation />
														</svg>

														<svg
															key={`delete-${node.data.data._id ?? nanoid()}`}
															className="cursor-pointer"
															width="25"
															height="25"
															x={28}
															y={currentTreeShifting + 25}
															onClick={(e) => {
																e.preventDefault();
																const parentNode = node.parent;

																if (!parentNode || !parentNode.data.children) {
																	alert(`Expected defined parent with defined children but got ${parentNode}`);
																	return;
																}

																const deleteNodes = node.descendants().map((item) => item.data.data._id);

																setconfirmModal(true);
																setDeleteNodes(deleteNodes);
																setParentNode(parentNode);
															}}
														>
															<AnimatedBin />
														</svg>

													</>
												</>
											)}
										</Group>
									))}
								</Group>;
							}}
						</Tree>
					))}
				</Group>
			</svg>
		</div>
	);
};

AdminTreeNodesGraph.propTypes = {
	appSlice: PropTypes.object,
	datasetTree: PropTypes.string,
	margin: PropTypes.object,
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	supplychainView: PropTypes.bool,
	setSupplychainView: PropTypes.func,
	setAlert: PropTypes.func
};

export default AdminTreeNodesGraph;
