import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const FieldTransformerController = ({
	control,
	register,
	requiredFields = [],
	transform,
	name,
	defaultValue,
	placeholder,
	disabledFields = []
}) => (
	<Controller
		defaultValue={defaultValue}
		control={control}
		name={name}
		rules={{ required: requiredFields.includes(name) }}
		render={({ field }) => (
			<input
				className={`pl-2 py-2 my-2 border text-black rounded-lg focus:outline-none
				focus:ring-2 focus:ring-admin_primary-default focus:border-transparent text-sm focus:text-base 
				${disabledFields.includes(name) ? "bg-gray-200" : "bg-white"}`}
				disabled={disabledFields?.includes(name)}
				// {...register(`${field}`, { required: requiredFields.includes(field) })}
				{...field}
				autoComplete="off"
				placeholder={placeholder}
				onChange={(e) => field.onChange(transform.output(e))}
				value={transform.input(field.value)}
			/>
		)}
	/>
);

FieldTransformerController.propTypes = {
	control: PropTypes.object,
	register: PropTypes.func,
	requiredFields: PropTypes.array,
	defaultValue: PropTypes.string,
	name: PropTypes.string,
	transform: PropTypes.shape({
		input: PropTypes.func,
		output: PropTypes.func
	}),
	placeholder: PropTypes.string,
	disabledFields: PropTypes.array
};

export default FieldTransformerController;
