import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { ParentSize } from "@visx/responsive";

import HelpIcon from "@mui/icons-material/Help";

import ColumnGraph from "../graphs/ColumnGraph";
import ClarityNetZeroLegend from "../legends/ClarityNetZeroLegend";

function getNetZeroTCO2eq(sectionKPIsData, moduleIndex, section1Label) {
	const totalTCO2eq = sectionKPIsData
	?.filter((kpi) => (kpi.moduleIndex === moduleIndex && kpi.section1Label === section1Label))
	?.reduce((previousValue, currentValue) => previousValue + currentValue.emissionValue, 0);

	if (totalTCO2eq === undefined) {
		return "n/a";
	}

	return ((totalTCO2eq > 1000) ? (`${(totalTCO2eq / 1000).toFixed(0) }k`) : totalTCO2eq.toFixed(0));
}

function ClarityNetZeroScopeCard({
	appSlice, nbScope, moduleIndex, className = "", locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store of the App
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const sectionKPIsData = useSelector(appSlice.selectDatasets)?.netZeroCO2perType?.data;

	const active = activeDatasets.sectionsScore?.data?.filter((d) => d.moduleIndex === moduleIndex)?.length > 0;

	return (
		<div className={`flex flex-col items-center justify-between  rounded-xl 
          gap-y-4 pl-3 pt-4 border border-white z-20 ${className} 
         ${active ? "bg-clarity_primary-netZero text-white " : "text-black alphaBlur"}`}
		>
			<div className="flex items-center gap-x-2 w-full">
				<p className="text-medium self-start">{t("netZero.scopeCards.scope")} {nbScope }</p>
				{active && <HelpIcon fontSize="inherit" className="text-white" />}
			</div>
			{active ?
				<ParentSize>
					{(parent) => (
						<ColumnGraph
							appSlice={appSlice}
							dataset={"sectionsScore"}
							filterData={(d) => d.moduleIndex === moduleIndex}
							color={"#1876bd"}
							textColor="white"
							width={parent.width}
							margin={{
								top: 20,
								left: 5,
								right: 50,
								bottom: 0
							}}
							strokeWidth={8}
							backgroundBarColor="#4C938F"
							gradientStartBarColor="#FFC362"
							gradientEndBarColor="#CC7E00"
							nonConformitiesGreen
							getScore = {(d) => d.score * 100}
							getModule = {(d) => d.section1Label}
							getNC = {(d) => getNetZeroTCO2eq(sectionKPIsData, d.moduleIndex, d.section1Label)}
							refineKeys={["section1Label"]}
							locales={locales}
							hasReportLink
						/>
					)}
				</ParentSize>
				:
				<div className="flex flex-col items-center justify-center">
					<p className="text-medium">{t("netZero.scopeCards.notActivated")}</p>
					<a target="_blank" rel="noopener noreferrer"
						className="text-medium underline"
						href={`mailto:clarity.info@bureauveritas.com
							?subject=${`${clientParameters?.client} - ${t("netZero.scopeCards.requestAccess")} 
- "Net Zero" -  ${t("netZero.scopeCards.scope")} ${nbScope}`}`}>
						{t("netZero.scopeCards.requestAccess")}
					</a>
				</div>

			}
			<div className="mb-2">
				<ClarityNetZeroLegend locales={locales} small />
			</div>
		</div>
	);
}

ClarityNetZeroScopeCard.propTypes = {
	appSlice: PropTypes.object,
	nbScope: PropTypes.number,
	moduleIndex: PropTypes.string,
	className: PropTypes.string,
	locales: PropTypes.string
};

export default ClarityNetZeroScopeCard;
