import AppsIcon from "@mui/icons-material/Apps";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ContactMailIcon from "@mui/icons-material/ContactMail";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} logo Label of the menu, if none, only the picto
 * @param {String} version Version of the alpha project
 */
export const appDefinition = {
	name: "InsightsBY",
	subName: "InsightsBY platform",
	favicon: "/favicon.ico",
	logo: "/images/CompanyLogos/BVLogoWhite.svg",
	locales: "translationsLauncher",
	version: "0.1"
};

/**
 * Set up  menu options to display
 *
 * @param {String} menu ID of the menu (use for the initial menu definition and the render of the app)
 * @param {String} type Route or App = Route is a link to jump to a new url, App is a internal link to the App to display specific information
 * @param {String} url [OPTIONAL] If type == url, the new url to link the menu (it has to be routed in ./App.js)
 * @param {String} label [OPTIONAL] Label of the menu, if none, only the picto
 * @param {String} sectionTitleWhite [OPTIONAL] Section title in white displayed on top of the page when the menu is clicked
 * @param {String} sectionTitleBlack [OPTIONAL] Section title in black displayed on top of the page when the menu is clicked
 * @param {Component} picto Material UI Icon to represent the field in the select, to be imported in this file
 */
export const menuOptions = [
	{
		menu: "LAUNCHER",
		type: "App",
		label: "Launcher",
		initial: true,
		sectionTitlePrimary: "version",
		sectionTitleSecondary: appDefinition.version,
		picto: AppsIcon
	},
	{
		menu: "INFO",
		type: "App",
		label: "Info",
		sectionTitlePrimary: "Info",
		sectionTitleSecondary: "Page",
		picto: ContactSupportIcon
	},
	{
		menu: "CONTACT",
		type: "App",
		label: "Contact us",
		sectionTitlePrimary: "Contact",
		sectionTitleSecondary: "Page",
		picto: ContactMailIcon
	}
];

/**
 * Define All the Apps to display on the launcher home page
 *
 * @param {String} app Name ID of the app
 * @param {String} group User group name to see and get access to the app
 * @param {String} url Url of the App - related routes to be defined in App.js
 * @param {String} label Label of the App
 * @param {String} icon Link to the related image logo for the app, to be hosted in the public folder
 */
export const appAllOptions = [
	{
		app: "ONBOARDING",
		url: "https://onboardwith.bureauveritas.com/#/registration",
		external: true,
		private: false,
		label: "Onboard with BV",
		description: "Order online Bureau Veritas services for your business​",
		color: "#FC3650",
		pictoType: "image",
		picto: "/images/Onboarding/appicon - onboard.svg"
	},
	{
		app: "COMPASS",
		group: "COMPASS",
		url: "/compass",
		private: true,
		demobv: true,
		disabled: false,
		label: "Compass",
		description: "Your portal to see the results coming from your audit programs",
		color: "#F9CA48",
		pictoType: "image",
		picto: "/images/Compass/CompassAppIconSideBar.png"
	},
	{
		app: "RYB",
		url: "/ryb",
		private: false,
		label: "Restart Your Business",
		description: "Find the sites with the label safeguard",
		type: "PUBLIC",
		color: "#3577FA",
		pictoType: "image",
		picto: "/images/RYB/appicon - safeguard.svg"
	},
	{
		app: "SUPPLYR",
		group: "SUPPLYR",
		url: "/supplyr",
		demobv: true,
		private: true,
		label: "Supply-R",
		description: "Check the resilience of your supply-chain",
		color: "#313B8A",
		pictoType: "image",
		picto: "/images/SupplyR/appicon - supply-r.svg"
	},
	{
		app: "CLARITY",
		group: "CLARITY",
		url: "/clarity",
		demobv: true,
		private: true,
		label: "Clarity",
		description: "Check your sustainability maturity against best practices ",
		color: "#44B9B4",
		pictoType: "image",
		picto: "/images/Clarity/appicon - clarity.svg"
	},
	{
		app: "CHARGESCAN",
		group: "CHARGESCAN",
		url: "/chargescan",
		demobv: true,
		private: true,
		label: "Chargescan",
		description: "Monitor the reliability of your electric vehicles network",
		color: "#F39E1C",
		pictoType: "image",
		picto: "/images/ChargeScan/appicon - chargescan.svg"
	},
	{
		app: "VENDORDD",
		group: "VENDORDD",
		url: "/vendordd",
		demobv: false,
		private: true,
		disabled: false,
		label: "Vendor Due Diligence",
		description: "Monitor the viability of the partners of your marketplace",
		color: "#232D63",
		pictoType: "image",
		picto: "/images/VendorDD/appicon - cpsvendor.svg"
	}, {
		app: "CEE",
		group: "CEE",
		url: "/ControlesEcoEnergie",
		demobv: false,
		private: true,
		disabled: false,
		label: "Contrôles Eco Energie",
		description: "Monitorez la conformité des certificats d'économie d'énergie",
		color: "#232D63",
		pictoType: "image",
		picto: "/images/CEE/CEE.svg"
	},
	{
		app: "Supply Chain Analytics",
		group: "SUPPLYR",
		url: "/sca",
		demobv: true,
		private: true,
		label: "Supply Chain Analytics",
		description: "Supply Chain Analytics",
		color: "#313B8A",
		pictoType: "image",
		picto: "/images/SupplyR/appicon - supply-r.svg"
	},
	{
		app: "Verkor",
		group: "VERKOR",
		url: "/verkor",
		demobv: true,
		private: true,
		label: "Supply-R",
		description: "Check the maturity of your supply-chain",
		color: "#007F6E",
		pictoType: "image",
		picto: "/images/SupplyR/appicon - supply-r.svg"
	},
	{
		app: "ATAGLANCE",
		group: "ATAGLANCE",
		url: "/ataglance",
		private: true,
		demobv: true,
		disabled: false,
		label: "At A Glance",
		description: "Your portal to see the results coming from your audit programs",
		color: "#F9CA48",
		pictoType: "image",
		picto: "/images/AtAGlance/appicon - ataglance.svg"
	}
];

export const languageOption = [
	{ label: "English", value: "en" },
	{ label: "Français", value: "fr" },
	{ label: "Español", value: "es" },
	{ label: "Português", value: "pt" },
	{ label: "Simplified Chinese (简体)", value: "zh-Hans" },
	{ label: "Traditional Chinese (繁體)", value: "zh-Hant" }
];
