import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Tooltip } from "@mui/material";

const NonConformity = ({
	appSlice = null,
	refineKey = undefined,
	additionalRefineKey = undefined,
	additionalRefineValue = undefined,
	refineValueHigh = undefined,
	refineValueMedium = undefined,
	refineValueLow = undefined,
	noTitle = false,
	ncHigh = 50,
	ncMedium = 30,
	ncLow = 30,
	thickness = undefined,
	locales
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);
	// active refines
	const dispatch = useDispatch();
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const ncHighValue = 100 * ncHigh * (1 / (ncHigh + ncMedium + ncLow));
	const ncMediumValue = 100 * ncMedium * (1 / (ncHigh + ncMedium + ncLow));
	const ncLowValue = 100 * ncLow * (1 / (ncHigh + ncMedium + ncLow));

	return <React.Fragment>
		{!noTitle &&
		<div className="flex justify-between items-center">
			<p className="flex items-center">
				<span className="mr-2">{t("NCsComponent.title")}</span>
				<ReportProblemOutlinedIcon fontSize="inherit" className="text-red-500"/>
			</p>
			<p className="font-bold text-red-500 text-3xl">{ncHigh + ncMedium + ncLow}</p>
		</div>
		}
		<div className="relative flex flex-row h-5 mt-10 mb-5">
			{ncHigh > 0 &&
			<Tooltip title={"High"} followCursor>
				<div
					className={`bg-rect-horizontalleft rounded-3xl h-3 mx-0.5 flex 
				justify-center ${thickness === "thin" ? "h-2" : (thickness === "large" ? "h-4" : (thickness === "thin" ? "h-2" : "h-3"))}
				${refineValueHigh && "cursor-pointer"}`}
					style={{ width: `${ncHighValue}%` }}
					onClick={
						// case refineValueHigh && additional refine
						((refineValueHigh && additionalRefineValue)
							// clear refines if they are active
							? () => ((activeRefines?.[refineKey] && activeRefines?.[additionalRefineKey])
								? dispatch(appSlice.actions.clear([
									{ refine: refineKey },
									{ refine: additionalRefineKey }
								]))
								// refine on main filter if refineKey is inactive & additional refine is active
								: (!activeRefines?.[refineKey] && activeRefines?.[additionalRefineKey])
									? dispatch(appSlice.actions.refine([
										{ key: refineKey, value: refineValueHigh }
									]))
									// clear main filter if active and additional refine inactive
									: (activeRefines?.[refineKey] && !activeRefines?.[additionalRefineKey])
										? dispatch(appSlice.actions.clear([{ refine: refineKey }]))
									// else dispatch both
										: dispatch(appSlice.actions.refine([
											{ key: refineKey, value: refineValueHigh },
											{ key: additionalRefineKey, value: additionalRefineValue }
										])))
						// case refineValueHigh only
							: (refineValueHigh && additionalRefineValue === undefined)
								? () => (activeRefines?.[refineKey]
									? dispatch(appSlice.actions.clear([
										{ refine: refineKey }
									]))
									: dispatch(appSlice.actions.refine([
										{ key: refineKey, value: refineValueHigh }
									]))
								)
								: "")
					}
				>
					<span className="absolute -top-6 text-medium font-bold">
						{ncHigh}
					</span>
				</div>
			</Tooltip>
			}
			{ncMedium > 0 &&
			<Tooltip title={"Medium"} followCursor>
				<div
					className={`bg-rect-horizontalmid rounded-3xl h-3 mx-0.5 flex 
				justify-center ${thickness === "thin" ? "h-2" : (thickness === "large" ? "h-4" : (thickness === "thin" ? "h-2" : "h-3"))}
				${refineValueMedium && "cursor-pointer"}`}
					style={{ width: `${ncMediumValue}%` }}
					onClick={
						// case refineValueMedium && additional refine
						((refineValueMedium && additionalRefineValue)
							// clear refines if they are active
							? () => ((activeRefines?.[refineKey] && activeRefines?.[additionalRefineKey])
								? dispatch(appSlice.actions.clear([
									{ refine: refineKey },
									{ refine: additionalRefineKey }
								]))
								// refine on main filter if refineKey is inactive & additional refine is active
								: (!activeRefines?.[refineKey] && activeRefines?.[additionalRefineKey])
									? dispatch(appSlice.actions.refine([
										{ key: refineKey, value: refineValueMedium }
									]))
									// clear main filter if active and additional refine inactive
									: (activeRefines?.[refineKey] && !activeRefines?.[additionalRefineKey])
										? dispatch(appSlice.actions.clear([{ refine: refineKey }]))
									// else dispatch both
										: dispatch(appSlice.actions.refine([
											{ key: refineKey, value: refineValueMedium },
											{ key: additionalRefineKey, value: additionalRefineValue }
										])))
						// case refineValueMedium only
							: (refineValueMedium && additionalRefineValue === undefined)
								? () => (activeRefines?.[refineKey]
									? dispatch(appSlice.actions.clear([
										{ refine: refineKey }
									]))
									: dispatch(appSlice.actions.refine([
										{ key: refineKey, value: refineValueMedium }
									]))
								)
								: "")
					}
				>
					<span className="absolute -top-6 text-medium font-bold">
						{ncMedium}
					</span>
				</div>
			</Tooltip>
			}
			{ncLow > 0 &&
			<Tooltip title={"Low"} followCursor>
				<div
					className={`bg-rect-horizontalright rounded-3xl h-3 mx-0.5 flex 
				justify-center ${thickness === "thin" ? "h-2" : (thickness === "large" ? "h-4" : (thickness === "thin" ? "h-2" : "h-3"))}
				${refineValueLow && "cursor-pointer"}`}
					style={{ width: `${ncLowValue}%` }}
					onClick={
						// case refineValueLow && additional refine
						((refineValueLow && additionalRefineValue)
							// clear refines if they are active
							? () => ((activeRefines?.[refineKey] && activeRefines?.[additionalRefineKey])
								? dispatch(appSlice.actions.clear([
									{ refine: refineKey },
									{ refine: additionalRefineKey }
								]))
								// refine on main filter if refineKey is inactive & additional refine is active
								: (!activeRefines?.[refineKey] && activeRefines?.[additionalRefineKey])
									? dispatch(appSlice.actions.refine([
										{ key: refineKey, value: refineValueLow }
									]))
									// clear main filter if active and additional refine inactive
									: (activeRefines?.[refineKey] && !activeRefines?.[additionalRefineKey])
										? dispatch(appSlice.actions.clear([{ refine: refineKey }]))
									// else dispatch both
										: dispatch(appSlice.actions.refine([
											{ key: refineKey, value: refineValueLow },
											{ key: additionalRefineKey, value: additionalRefineValue }
										])))
						// case refineValueLow only
							: (refineValueLow && additionalRefineValue === undefined)
								? () => (activeRefines?.[refineKey]
									? dispatch(appSlice.actions.clear([
										{ refine: refineKey }
									]))
									: dispatch(appSlice.actions.refine([
										{ key: refineKey, value: refineValueLow }
									]))
								)
								: "")
					}
				>
					<span className="absolute -top-6 text-medium font-bold">
						{ncLow}
					</span>
				</div>
			</Tooltip>
			}
		</div>
	</React.Fragment>;
};

NonConformity.propTypes = {
	appSlice: PropTypes.object,
	refineKey: PropTypes.string,
	additionalRefineKey: PropTypes.string,
	additionalRefineValue: PropTypes.string,
	refineValueHigh: PropTypes.string,
	refineValueMedium: PropTypes.string,
	refineValueLow: PropTypes.string,
	noTitle: PropTypes.bool,
	ncHigh: PropTypes.number,
	ncMedium: PropTypes.number,
	ncLow: PropTypes.number,
	thickness: PropTypes.string,
	locales: PropTypes.string
};

export default NonConformity;
