import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";
import PropTypes from "prop-types";

import {
	Tooltip
} from "@mui/material";

import SpeedIcon from "@mui/icons-material/Speed";

import "../../styles/tree.css";
import ProductIcon from "../../assets/svg/ProductIcon";

const TreeNode = forwardRef(({
	rectW,
	rectH,
	node,
	currentTreeShifting,
	appSlice,
	nodeIdKey,
	refineHighlightKeys,
	refineSiteKeys,
	onlyAudited = false,
	getMarkerColor = (d) => "blue",
	setExpandedNodeDepths,
	setCollapseAll,
	locales,
	setSelected,
	consolidated,
	inactive,
	index,
	TreeTooltip = () => null,
	displayTooltip = false,
	auditedFields = ["module"],
	divsPerFilterRefs,
	enableNodeDispatch = true
}, ref) => {
	const { t, i18n } = useTranslation(locales);

	const dispatch = useDispatch();
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const nodeData = node?.data?.data;

	const color = nodeData.score ? getMarkerColor(nodeData.score.toFixed(0)) : "#c9bbbd";

	const targetAuditedCompany = activeRefines[nodeIdKey];
	// isHighlighted - elements chosen by the filters. If no filter has been chosen all
	// the elements are considered highlighted.
	const isHighlighted = refineHighlightKeys.reduce((acc, refine) => (
		acc && (
			!activeRefines[refine]
		||
			(!Array.isArray(activeRefines[refine]) && activeRefines[refine] === nodeData[refine])
		||
			((!Array.isArray(activeRefines[refine]) && Array.isArray(nodeData[refine]) && nodeData[refine]?.includes(activeRefines[refine]))
			&& auditedFields.includes(refine) && nodeData.resilience > 0)
		||
			((!Array.isArray(activeRefines[refine]) && Array.isArray(nodeData[refine]) && nodeData[refine]?.includes(activeRefines[refine]))
			&& !auditedFields.includes(refine))
		||
			(Array.isArray(activeRefines[refine]) && activeRefines[refine]?.includes(nodeData[refine]))
		)), true);

	const isConsolidatedHighlight = node.data.isHighlighted && consolidated;

	// This method adds all the divs based on the page's filter to the collection of refs.
	// The collection is "Map()" with the IDs of of nodes as "keys" to avoid duplicates.
	const handleDivsPerFilterRefs = (elem) => {
		//
		if (elem) {
			divsPerFilterRefs.current.set(nodeData._id, elem);
		}
	};

	return (
		<g className={`${inactive && "opacity-60"} snap-y `}>

			<foreignObject
				className="pointer-events-auto"
				width={rectW * 2} height={rectH} x={-rectW} y={-rectH / 2 + currentTreeShifting}>
				<Tooltip
					PopperProps={{
						sx: {
							"& .MuiTooltip-tooltip": {
								bgcolor: "rgba(190,190,190, 0.4)",
								borderRadius: "10px",
								display: !displayTooltip && "none",
								fontFamily: "Fira Sans, sans-serif"
							}
						}
					}}
					title={
						<TreeTooltip
							appSlice={appSlice} height={rectH * 2} width={rectW * 4}
							companiesInfo={node?.data?.data}
							transparent={false}
							className="rounded-xl" />
					}
				>
					<div id={node.data.data._id + node.data.data.product} ref={isHighlighted ? (ref[index], handleDivsPerFilterRefs) : null}
						className={`w-full h-full  rounded-xl pr-3 mr-10  ${!onlyAudited ? "cursor-pointer" : ""} 
					${(isHighlighted || isConsolidatedHighlight) ? null : "opacity-30 filter grayscale blur-xs contrast-20" }`}
					>
						<div className={`flex flex-grow border-l-8 border-t border-b border-r mr-16
                    justify-between items-center text-xs w-full  h-full py-2 pl-2 pr-4 rounded-xl bg-white
					${inactive && "border-dashed border-l-4 "}`}
						style={{ borderColor: color }}
						onClick={(e) => {
							if ((!onlyAudited || nodeData.score) && enableNodeDispatch) {
								e.preventDefault();
								if (!targetAuditedCompany || targetAuditedCompany !== nodeData[nodeIdKey]) {
									dispatch(appSlice.actions.refine(
										refineSiteKeys.map((refineKey) => ({ key: refineKey, value: nodeData[refineKey] }))
									));
								}
								if (targetAuditedCompany && targetAuditedCompany === nodeData[nodeIdKey]) {
									dispatch(appSlice.actions.clear(
										refineSiteKeys.map((refineKey) => ({ refine: refineKey }))
									));
								}

								setSelected(node);
							}
						}}
						>
							{/* TODO: translation in clarity */}
							<div className="flex flex-col w-11/12" >
								<p className="font-light leading-4 line-clamp-1">
									{activeRefines?.supplyAudits ? t(`supplyChainGraph.type.${nodeData.siteSegmentation}`)
										: t(`supplyChainGraph.type.${nodeData.type}`)}{" "}
									{nodeData.criticality ? `(${t(`supplyChainGraph.criticality.${nodeData.criticality}`)})` : ""}

								</p>
								<p className="w-full leading-3 text-smaller font-semibold line-clamp-1" >
									{nodeData.auditedCompany || "TBD"}
								</p>
								{(nodeData.product || nodeData.scope) && <div className="flex items-center gap-x-1 text-smaller ">
									<p className="flex-shrink-0">
										{ProductIcon}
									</p>

									<div className="flex line-clamp-1">
										{i18n.exists(`${locales}:product.${nodeData.product}`) ?
											t(`product.${nodeData.product}`)
											: (nodeData.product || nodeData.scope)}
									</div>
								</div>}
							</div>
							<div className="w-1/12 flex flex-col items-center justify-center ">
								<SpeedIcon fontSize="small" className="pl-1" />
								<p className="text-sm pl-1">
									{(!nodeData.score || nodeData.score === -999) ? "n/a" : nodeData.score.toFixed(0)}
								</p>
							</div>

						</div>
					</div>
				</Tooltip>
			</foreignObject>
			<foreignObject width={30} height={30} x={rectW - 24} y={-rectH / 2 + currentTreeShifting + 12}>
				{node.data.children && node.data.children.length && (
					<div className={`flex items-center justify-center flex-shrink-0 rounded-full bg-gray-500 w-6 h-6
                            font-bold text-white cursor-pointer text-xs 
                            ${(isHighlighted || node.data.selected) ? null : "opacity-30 filter grayscale blur-xs contrast-20" }`}
					onClick={() => {
						node.data.isExpanded = !node.data.isExpanded;
						node.descendants().forEach((node, i) => { if (i !== 0) { node.data.isExpanded = true; } });
						setCollapseAll(undefined);
						setExpandedNodeDepths((prevState) => [
							...prevState,
							node.depth
						]);
					}}>
						{(node.data.children && node.data.children.length) || 0}
					</div>
				)}
			</foreignObject>
		</g>
	);
});

TreeNode.propTypes = {
	rectW: PropTypes.number,
	rectH: PropTypes.number,
	node: PropTypes.object,
	currentFinalProduct: PropTypes.string,
	currentTreeShifting: PropTypes.number,
	appSlice: PropTypes.object,
	nodeIdKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	refineHighlightKeys: PropTypes.array,
	refineSiteKeys: PropTypes.array,
	onlyAudited: PropTypes.bool,
	getMarkerColor: PropTypes.func,
	setExpandedNodeDepths: PropTypes.func,
	setCollapseAll: PropTypes.func,
	setCurrentNodeDetail: PropTypes.func,
	locales: PropTypes.string,
	setSelected: PropTypes.func,
	consolidated: PropTypes.bool,
	inactive: PropTypes.bool,
	index: PropTypes.any,
	TreeTooltip: PropTypes.any,
	displayTooltip: PropTypes.bool,
	auditedFields: PropTypes.array,
	divsPerFilterRefs: PropTypes.object,
	enableNodeDispatch: PropTypes.bool
};

export default TreeNode;
