import React from "react";
import PropTypes from "prop-types";
import SupplyRSupplierCard from "../cards/SupplyRSupplierCard";

const ToolTips = ({
	appSlice, node, rectW, currentTreeShifting
}) => {
	const width = rectW + 20;
	const height = 250;
	const padding = 8;

	return <g><foreignObject x={-width / 2 - padding} y={currentTreeShifting - 250}
		width={width} height={height}
		className="invisible hover:visible group-hover:visible "
	>
		<div className="h-full w-full flex items-end p-2">
			<SupplyRSupplierCard appSlice={appSlice} height={height} width={width - 34} companiesInfo={node?.data?.data} />
		</div>
	</foreignObject>
	</g>;
};

ToolTips.propTypes = {
	appSlice: PropTypes.object,
	node: PropTypes.object.isRequired,
	rectW: PropTypes.number,
	currentTreeShifting: PropTypes.number
};

export default ToolTips;
