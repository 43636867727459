import PropTypes from "prop-types";

import DashboardIcon from "@mui/icons-material/Dashboard";
import MapIcon from "@mui/icons-material/Map";
import CompareIcon from "@mui/icons-material/Compare";
import LabelIcon from "@mui/icons-material/Label";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import UpdateIcon from "@mui/icons-material/Update";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SecurityIcon from "@mui/icons-material/Security";
import SpeedIcon from "@mui/icons-material/Speed";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import ConstructionIcon from "@mui/icons-material/Construction";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} slice Name of the slice related to the app created and references in the store ./store.js
 * @param {String} logo Label of the menu, if none, only the picto
 */
export const appDefinition = {
	name: "CHARGESCAN",
	subName: "",
	launcherID: "CHARGESCAN",
	slice: "chargeScan",
	locales: "translationsChargeScan",
	theme: "chargeScan",
	favicon: "/images/ChargeScan/favicon.ico",
	logo: "/images/ChargeScan/ChargeScanAppIconSideBar.png"
};

/**
 * Set up  menu options to display
 *
 * @param {String} menu ID of the menu (use for the initial menu definition and the render of the app)
 * @param {String} type Route or App = Route is a link to jump to a new url, App is a internal link to the App to display specific information
 * @param {String} url [OPTIONAL] If type == url, the new url to link the menu (it has to be routed in ./App.js)
 * @param {String} label [OPTIONAL] Label of the menu, if none, only the picto
 * @param {String} sectionTitleWhite [OPTIONAL] Section title in white displayed on top of the page when the menu is clicked
 * @param {String} sectionTitleBlack [OPTIONAL] Section title in black displayed on top of the page when the menu is clicked
 * @param {Component} picto Material UI Icon to represent the field in the select, to be imported in this file
 */
export const menuOptions = [
	{
		menu: "HOME",
		type: "App",
		initial: true,
		label: "Home",
		picto: DashboardIcon
	},
	{
		menu: "MAP",
		type: "App",
		initial: true,
		label: "Map",
		picto: MapIcon
	},
	{
		menu: "DISTRIB",
		type: "App",
		initial: true,
		label: "Distribution",
		picto: CompareIcon
	},
	{
		menu: "NCs",
		type: "App",
		initial: true,
		label: "Nonconformities",
		picto: FlagOutlinedIcon
	},
	{
		menu: "INFO",
		type: "App",
		label: "Info",
		picto: ContactSupportIcon
	}
];

/**
 * Name of the dataset with the client parameters
 */
const ChargeScanClientParameters = "ChargeScanClientParameters";

/**
 * Client parameters
 */
export const clientInitParameters = {
	name: "clientParameters",
	collection: ChargeScanClientParameters,
	facetsToRefine: ["spaceId"],
	fetch: "find",
	limit: 1
};

export const listNonConformityQuery = [
	{
		$match: {
			badAnswersCounter: 1
		}
	},
	{
		$project: {
			module: 1,
			domain: "$module",
			checkpointReference: 1,
			question: 1,
			comment: 1,
			siteId: 1,
			label: 1,
			country: 1,
			city: 1,
			publicationDate: 1,
			assignmentTechnicalid: 1,
			critical: 1,
			section1Label: 1,
			section2Label: 1
		}
	}
];

/**
 * Arry of the full list of facets to refine
 */
export const listFacetsToRefine = ["spaceId", "module", "region", "country", "type", "label"];

/**
  * Active DataSets definition for the app
  *
  * @param {String} name
  * @param {String} collection
  * @param {String} fetch
  * @param {String[]} facetsToRefine
  * @param {Object[]} query
  * @param {Number} limit
  * @param {Object[]} data
  */

export const chargeScanInitDatasets = {
	listSpaces: {
		collection: ChargeScanClientParameters,
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						spaceId: "$spaceId"
					},
					projectLabel: { $first: "$projectLabel" },
					nbSpaces: { $sum: 1 }
				}
			},
			{
				$project: {
					spaceId: "$_id.spaceId",
					projectLabel: "$projectLabel",
					nbSpaces: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listTypes: {
		collection: "ChargeScanModulesAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "type"),
		query: [
			{
				$group: {
					_id: {
						type: "$type"
					},
					nbAssignements: { $sum: 1 }
				}
			},
			{
				$project: {
					type: "$_id.type",
					nbAssignements: 1,
					_id: 0
				}
			}
		]
	},
	listModules: {
		collection: "ChargeScanModulesAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "module"),
		query: [
			{
				$group: {
					_id: {
						module: "$module"
					},
					nbAssignements: { $sum: 1 }
				}
			},
			{
				$project: {
					module: "$_id.module",
					nbAssignements: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	allModules: {
		collection: "ChargeScanModulesAggregates",
		fetch: "aggregation",
		facetsToRefine: ["spaceId", "type"],
		query: [
			{
				$group: {
					_id: {
						module: "$module"
					},
					nbAssignements: { $sum: 1 }
				}
			},
			{
				$project: {
					module: "$_id.module",
					nbAssignements: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listRegions: {
		collection: "ChargeScanModulesAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "region"),
		query: [
			{
				$group: {
					_id: {
						region: "$region"
					},
					nbAssignements: { $sum: 1 }
				}
			},
			{
				$project: {
					region: "$_id.region",
					nbAssignements: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listCountries: {
		collection: "ChargeScanModulesAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "country"),
		query: [
			{
				$group: {
					_id: {
						country: "$country"
					},
					nbAssignements: { $sum: 1 }
				}
			},
			{
				$project: {
					country: "$_id.country",
					nbAssignements: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listLabels: {
		collection: "ChargeScanModulesAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "label"),
		query: [
			{
				$group: {
					_id: {
						label: "$label"
					},
					nbAssignements: { $sum: 1 }
				}
			},
			{
				$project: {
					label: "$_id.label",
					nbAssignements: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	sites: {
		collection: "ChargeScanSitesAggregates",
		fetch: "find",
		facetsToRefine: listFacetsToRefine,
		limit: 1000
	},
	scope: {
		collection: "ChargeScanSitesAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$project: {
					_id: 0,
					status: 1,
					nbCompliant: { $cond: [{ $eq: ["$status", "Compliant"] }, 1, 0] },
					nbNotCompliant: { $cond: [{ $eq: ["$status", "Not Compliant"] }, 1, 0] }
				}
			},
			{
				$group: {
					_id: 0,
					nbCompliant: { $sum: "$nbCompliant" },
					nbNotCompliant: { $sum: "$nbNotCompliant" }
				}
			},
			{
				$project: {
					nbCompliant: 1,
					nbNotCompliant: 1,
					nbSites: { $add: ["$nbCompliant", "$nbNotCompliant"] },
					_id: 0
				}
			}
		],
		limit: 1000
	},
	moduleScores: {
		collection: "ChargeScanModulesAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						module: "$module"
					},
					nbCompliant: { $sum: "$isCompliant" },
					nbAssignements: { $sum: 1 }
				}
			},
			{
				$project: {
					module: "$_id.module",
					nbCompliant: 1,
					nbAssignements: 1,
					moduleScore: {
						$cond: [{ $eq: ["$nbAssignements", 0] }, 1, { $divide: ["$nbCompliant", "$nbAssignements"] }]
					},
					_id: 0
				}
			}
		],
		limit: 500
	},
	chargingStationData: {
		collection: "ChargeScanSectionsAggregates",
		fetch: "aggregation",
		facetsToRefine: [...listFacetsToRefine, "chargingStationId"],
		query: [{
			$group: {
				_id: {
					chargingStationId: "$chargingStationId",
					module: "$module"
				},
				nbNC: {
					$sum: "$badAnswersCounter"
				},
				assignmentTechnicalid: {
					$first: "$assignmentTechnicalid"
				}
			}
		}, {
			$project: {
				chargingStationId: "$_id.chargingStationId",
				module: "$_id.module",
				nbNC: 1,
				assignmentTechnicalid: "$assignmentTechnicalid",
				_id: 0
			}
		}, {
			$group: {
				_id: {
					chargingStationId: "$chargingStationId"
				},
				module: {
					$addToSet: "$module"
				},
				nbNC: {
					$push: "$nbNC"
				},
				totalNC: {
					$sum: "$nbNC"
				},
				assignmentTechnicalid: {
					$first: "$assignmentTechnicalid"
				}
			}
		}, {
			$project: {
				chargingStationId: "$_id.chargingStationId",
				module: 1,
				nbNC: 1,
				totalNC: 1,
				assignmentTechnicalid: "$assignmentTechnicalid"
			}
		}],
		limit: 500
	},
	sectionAlertList: {
		collection: "ChargeScanSectionsAggregates",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$group: {
				_id: {
					section1label: "$section1label",
					section2label: "$section2label",
					section3label: "$section3label"
				},
				alert: {
					$sum: "$badAnswersCounter"
				}
			}
		}, {
			$project: {
				section1label: "$_id.section1label",
				section2label: "$_id.section2label",
				section3label: "$_id.section3label",
				alert: 1,
				_id: 0
			}
		}],
		limit: 500
	},
	listNonConformities: {
		collection: "ChargeScanCheckpoints",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: listNonConformityQuery,
		limit: 100
	}
};

/**
 * Init Refine of the Application
 *
 * @param {Object} chargeScanInitRefines
 * Object representing the initial refine of the datasets
 * in the App slice, the key being the name of the field
 * in the DB Collection to filter on, the value being the value to filter on
 */
export const chargeScanInitRefines = { type: "construction" };

// Define the MapToolTip
export const chargeScanMapTooltip = [
	{ label: "Audited Company", key: "company" },
	{ label: "Scope", key: "type" },
	{ label: "Country", key: "country" },
	{ label: "NB Stations", key: "nbStations" },
	{ label: "Status", key: "status" }
];

/**
 * Define the rule for the Marker color on the map
 */
export const getMarkerColor = (status) => {
	if (!status) return "#c9bbbd";
	if (status === "Not Compliant") return "#FF475C";
	return "#6DC3A0";
};

/**
 * Set up refine filter options to display
 *
 * @param {String} refine name of the field in the DB Collection to filter on
 * @param {String} loadFromDataset name of the collection to filter on the field
 * @param {String} placeHolder [OPTIONAL] text when nothing is selected
 * @param {Component} tag Material UI Icon to represent the field in the select, to be imported in this file
 * @param {Boolean} clear will be cleared if the button clear is pushed
 * @param {Boolean} toClearOnChange execute a clear of other clearable fields (see above) if the value has changed
 */
export const filterOptions = [
	{
		label: "SpaceId",
		refine: "spaceId",
		loadFromDataset: "listSpaces",
		placeHolder: "filters.spaceId",
		hiddenIfUnique: true,
		isClearable: false,
		isSearchable: false,
		clear: false,
		toClearOnChange: true,
		displayField: "projectLabel"
	},
	{
		label: "Modules",
		refine: "module",
		loadFromDataset: "listModules",
		placeHolder: "filters.modules",
		hiddenIfUnique: true,
		isClearable: true,
		isMulti: false,
		isSearchable: false,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Regions",
		refine: "region",
		loadFromDataset: "listRegions",
		placeHolder: "filters.region",
		isClearable: true,
		isMulti: false,
		isSearchable: false,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Countries",
		refine: "country",
		loadFromDataset: "listCountries",
		placeHolder: "filters.countries",
		isClearable: true,
		isMulti: false,
		isSearchable: false,
		clear: true,
		toClearOnChange: false
	},
	{
		label: "Sites",
		refine: "label",
		loadFromDataset: "listLabels",
		mobileNotRender: true,
		placeHolder: "filters.sites",
		isClearable: true,
		isMulti: false,
		isSearchable: true,
		clear: true,
		toClearOnChange: false
	}
];

export const additionalFilters = [{ refine: "chargingStationId" }];

export const ChargingStationPicto = ({ color = "black" }) => <svg aria-hidden="true"
	focusable="false" data-prefix="fas"
	data-icon="charging-station"
	className="svg-inline--fa fa-charging-station fa-w-18"
	role="img" xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 576 512">
	<path fill={color}
		d="M336 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h320c8.84
        0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zm208-320V80c0-8.84-7.16-16-16-16s-16
        7.16-16 16v48h-32V80c0-8.84-7.16-16-16-16s-16 7.16-16 16v48h-16c-8.84 0-16
        7.16-16 16v32c0 35.76 23.62 65.69 56 75.93v118.49c0 13.95-9.5 26.92-23.26
        29.19C431.22 402.5 416 388.99 416 372v-28c0-48.6-39.4-88-88-88h-8V64c0-35.35-28.65-64-64-64H96C60.65
        0 32 28.65 32 64v352h288V304h8c22.09 0 40 17.91 40 40v24.61c0 39.67 28.92 75.16 68.41 79.01C481.71
        452.05 520 416.41 520 372V251.93c32.38-10.24 56-40.17 56-75.93v-32c0-8.84-7.16-16-16-16h-16zm-283.91
        47.76l-93.7 139c-2.2 3.33-6.21 5.24-10.39 5.24-7.67 0-13.47-6.28-11.67-12.92L167.35 224H108c-7.25
        0-12.85-5.59-11.89-11.89l16-107C112.9 99.9 117.98 96 124 96h68c7.88 0 13.62 6.54 11.6 13.21L192
        160h57.7c9.24 0 15.01 8.78 10.39 15.76z">
	</path>
</svg>;

ChargingStationPicto.propTypes = {
	color: PropTypes.string
};

/**
 * List of module to be shown on the chargescan app
 */

export const moduleOptions = {
	regulatoryCompliance: {
		locale: "regulatoryCompliance",
		picto: LabelIcon
	},
	availability: {
		locale: "availability",
		picto: UpdateIcon
	},
	securityAndSafety: {
		locale: "securityAndSafety",
		picto: SecurityIcon
	},
	performance: {
		locale: "performance",
		picto: SpeedIcon
	},
	"Site Assessment":
	{
		locale: "siteAssessment",
		picto: VisibilityIcon
	},
	"Design Review":
	{
		locale: "designReview",
		picto: DesignServicesIcon
	},
	Construction:
	{
		locale: "construction",
		picto: ConstructionIcon
	},
	Commissioning:
	{
		locale: "commissioning",
		picto: PlayCircleFilledIcon
	}
};

// Array for section table structure
export const sectionStructure = [
	{ headerLocale: "sections.SectionLvl1", accessor: (d) => d.section1label },
	{ headerLocale: "sections.SectionLvl2", accessor: (d) => d.section2label },
	{ headerLocale: "sections.SectionLvl3", accessor: (d) => d.section3label }
];
