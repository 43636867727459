import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import SpeedIcon from "@mui/icons-material/Speed";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import BusinessIcon from "@mui/icons-material/Business";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PinDropIcon from "@mui/icons-material/PinDrop";
import ProgressBarFlat from "../graphs/ProgressBarFlat";
import ProductIcon from "../../assets/svg/ProductIcon";

import IF from "../../utils/IF";

function SupplyRSupplierCard({
	appSlice, companiesInfo, width = 182, transparent = false, locales
}) {
	// Internationalization hook
	const { t, i18n } = useTranslation("translationsSupplyR");

	// Load the store hook
	const dispatch = useDispatch();

	const arrProducts = Array.isArray(companiesInfo?.product) ? companiesInfo?.product : [companiesInfo?.product];

	return (
		<div className={`relative flex flex-col w-full gap-y-1.5 rounded-2xl px-4 py-2.5 border-2 ${ !transparent && "alphaBlur"}` }
			style={{ width }}>

			<div id="company" className={`flex ${companiesInfo?.supplierCode ? "items-center" : ""} gap-x-2 w-full`} >
				<BusinessIcon />
				<div className="flex flex-col w-full" >
					<p className={`text-medium ${companiesInfo.supplierCode ? "leading-normal" : "leading-6"} line-clamp-2 font-semibold`}>
						<span className="line-clamp-1">
							{companiesInfo.supplier}
						</span>
						<span className="text-xs line-clamp-1">
							{companiesInfo?.supplierCode ?? ""}
						</span>
					</p>
				</div>
			</div>

			<div className={`flex ${companiesInfo?.supplierCode ? "items-center" : ""} w-full gap-x-2.5`}>
				<MapsHomeWorkIcon className="text-sm ml-2"/>

				<p className="text-small line-clamp-3" >
					<span className="line-clamp-2">
						{companiesInfo.auditedCompany}
					</span>
					<span className="text-xs line-clamp-1">
						{companiesInfo?.factoryCode ?? ""}
					</span>
				</p>
			</div>

			{arrProducts && <div id="company-product" className="flex items-center gap-x-1 text-small w-full">
				<p className="flex-shrink-0">{ProductIcon}</p>
				<div className="flex">
					{arrProducts?.map((product, i) => {
						const productName = i18n.exists(`${locales}:product.${product}`) ?
							t(`product.${product}`)
							: product;
						const sep = (i >= 1) ? ", " : "";
						return <p key={`product ${ i}`}>{sep}{productName}</p>;
					})}
				</div>
			</div>}

			<div id="location" className="flex border items-center gap-x-2 px-2 py-1 border-gray-200 rounded-md text-medium ">
				<PinDropIcon fontSize="inherit" />
				<p className="text-small leading-4 line-clamp-1 font-light" style={{ maxWidth: width - 25 }}>
					{companiesInfo.city} - {companiesInfo.country}
				</p>
			</div>

			<div className="flex flex-col items-center text-base gap-y-1 justify-center w-full cursor-default">
				{
					companiesInfo && companiesInfo?.resilience && companiesInfo?.resilience !== 0
						? <>
							<div className="flex justify-between items-center gap-x-2"
								title={t("Info.content.resilienceTitle")}>

								<SpeedIcon fontSize="inherit" />

								<ProgressBarFlat
									endPoint ={(companiesInfo?.resilience?.toFixed(0)) || "na"}
									width={width - 50}
									height={10}
									strokeColor="#5862B6"
									showPercentage={true}
									fontSize={10}
									fontWeight={600}
								/>
							</div>
							{companiesInfo?.resistance && <div className="flex justify-between items-center gap-x-2"
								title={t("Info.content.resistanceTitle")}>

								<VerticalAlignCenterIcon fontSize="inherit" />

								<ProgressBarFlat
									endPoint ={(companiesInfo?.resistance?.toFixed(0)) || "na"}
									width={width - 50}
									height={10}
									strokeColor="#5862B6"
									showPercentage={true}
									fontSize={10}
								/>
							</div>}
							{companiesInfo?.responsiveness && <div className="flex justify-between items-center gap-x-2"
								title={t("Info.content.responsivenessTitle")}>

								<TimelapseIcon fontSize="inherit"/>

								<ProgressBarFlat
									endPoint ={(companiesInfo?.responsiveness?.toFixed(0)) || "na"}
									width={width - 50}
									height={10 }
									strokeColor="#5862B6"
									showPercentage={true}
									fontSize={10}
								/>
							</div>}

						</>
						: <p className="text-center text-sm font-bold mt-2" >
							{t("DetailCard.notAudited")}
						</p>
				}
			</div>

			<IF condition={companiesInfo
                && companiesInfo?.resilience
                && companiesInfo?.resilience !== 0}>
				<div className="flex items-center justify-center gap-x-2">
					<button className="self-center flex rounded-lg text-medium bg-supplyr_primary-menu text-white px-2 py-1 cursor-pointer"
						onClick={() => {
							dispatch(appSlice.actions.setPage("SITES"));
							dispatch(appSlice.actions.setSubPage("SITE"));
							dispatch(appSlice.actions.refine([
								{ key: "supplier", value: companiesInfo?.supplier },
								{ key: "siteId", value: companiesInfo?.siteId }
							]));
						}
						}>
						{t("tooltip.sitePage")}
					</button>
					<button className="self-center flex rounded-lg text-medium bg-supplyr_primary-menu text-white px-2 py-1 cursor-pointer"
						onClick={() => {
							dispatch(appSlice.actions.setPage("SITES"));
							dispatch(appSlice.actions.setSubPage("ALTERNATIVES"));
							dispatch(appSlice.actions.refine([
								{ key: "supplier", value: companiesInfo?.supplier },
								{ key: "siteId", value: companiesInfo?.siteId }
							]));
						}
						}>
						{t("tooltip.alternativesPage")}
					</button>
				</div>
			</IF>
		</div>
	);
}

SupplyRSupplierCard.propTypes = {
	appSlice: PropTypes.object,
	height: PropTypes.number,
	width: PropTypes.number,
	transparent: PropTypes.bool,
	companiesInfo: PropTypes.object,
	locales: PropTypes.string
};

export default SupplyRSupplierCard;
