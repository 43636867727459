import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

const CheckboxController = ({ control, name, className = "" }) => (
	<Controller
		control={control}
		name={name}
		render = {({ field: { onChange, value } }) => (
			<input
				onChange={onChange}
				checked={value}
				defaultValue={false}
				type="checkbox"
				className={className}
			/>
		)}
	/>
);

CheckboxController.propTypes = {
	control: PropTypes.object,
	name: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default CheckboxController;
