import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import AppsIcon from "@mui/icons-material/Apps";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import { userSelector } from "../../data/slices/UserSlice";
import LanguageFilter from "../filters/LanguageFilter";

function SideBar({
	appSlice, appDefinition, menuOptions, menu, noTitle = false, altTitle = false, indicator = undefined,
	colorMenu = "#3D49AB", colorBgMenu = "white", colorAccent = "#1876BD", textColor = "text-gray-500",
	langListValue = [{ value: "en", label: "EN" }]
}) {
	async function signOut() {
		try {
			await Auth.signOut();
			window.location.reload();
		} catch (error) {
			console.log("error signing out: ", error);
		}
	}

	// Load the store hook
	const dispatch = useDispatch();

	// translation
	const { t } = useTranslation(appDefinition.locales);

	// Set up state for mobile Filter Menu
	const [mobileDetails, setMobileDetails] = useState(false);

	// Load user
	const user = useSelector(userSelector);

	return (
		<>
        	{/*
            * Menu Desktop
            */}
			<div className="absolute group hidden md:flex flex-col flex-shrink-0 box-content z-xl
                                h-full w-24 overflow-hidden bg-opacity-80 shadow-lg backdrop-filter backdrop-blur-md
                                rounded-tr-3xl hover:w-64 transition-all duration-700 overflow-y-auto no-scrollbar"
			style={{
				backgroundColor: colorBgMenu
			}}>

				<div className="flex flex-col z-20  -mb-1"
					style={{ backgroundColor: colorBgMenu }}>
					<Link to="/">
						<AppsIcon className={`ml-9 mt-5 2xl:mt-10 cursor-pointer ${ textColor}`} />
					</Link>
					<div className="flex flex-col justify-center items-center mt-5 2xl:mt-6 -mb-2 2xl:mb-4 w-24 ">
						<img src={appDefinition.logo} className="w-16 max-h-16 object-scale-down" />
						{!noTitle &&
                    <>
                    	<p className="hidden 2xl:inline font-semibold pt-1">{(altTitle || appDefinition.name).slice(0, 13)}<br /></p>
                    	<p className="hidden 2xl:inline font-semibold pt-1">{(altTitle || appDefinition.name).slice(13)}<br /></p>
                    	<p className="hidden 2xl:inline text-sm">{appDefinition.subName}</p>
                    </>
						}
					</div>
				</div>
				<div className="relative flex flex-col justify-between h-full border-l-8"
					style={{ backgroundColor: colorBgMenu, borderColor: colorMenu }} >
					<div className="absolute -top-6 -left-2 w-16 h-16 rounded-full z-10"
						style={{ backgroundColor: colorBgMenu }}/>
					<div className="flex flex-col ">
						<div className=" flex flex-col w-full pr-4 pt-10 xl:-space-y-2 2xl:space-y-6">
							{menuOptions.map((option, i) => {
								const Picto = option.picto;
								return (
									<div data-test={option?.menu} key ={`menu ${i}`}
										className="relative  pl-9 list-none w-full rounded-tr-full rounded-br-full "
										style={{ backgroundColor: (menu === option.menu) ? colorMenu : "" }}
										onClick={() => {
											dispatch(appSlice.actions.setPage(option.menu));
											option.clearRefine?.map((clearRefine) => (dispatch(appSlice.actions
											.refine([{ key: clearRefine, value: undefined }]))));
										}}>
										{menu === option.menu && (
											<>
												<div className="absolute -top-12 left-0 w-12 h-12  rounded-full"
													style={{ backgroundColor: colorBgMenu, boxShadow: `-24px 24px 0 ${ colorMenu}` }} />
												{/* <div className="absolute -bottom-12 left-0 w-12 h-12  rounded-full "
													style={{ backgroundColor: colorBgMenu, boxShadow: `-24px -24px 0 ${ colorMenu}` }} /> */}
												<div className="absolute w-6 h-12 rounded-l-full
																transform rotate-45 "
												style={{
													left: "3.5px",
													bottom: "-40px",
													// backgroundColor: colorBgMenu,
													boxShadow: `-15px -10px 0 ${ colorMenu}`
												}} />
											</>
										)}
										<a href="#" className="relative w-full flex justify-start items-center text-white">
											{(indicator?.menu === option.menu) &&
										<div className="absolute left-5 w-5 h-5 top-2 leading-5
										flex  justify-center items-center rounded-full
										bg-red-500 text-white text-smaller">
											{indicator.value}
										</div>
											}
											<p className={`flex items-center h-14 z-40 ${menu !== option.menu ? textColor : ""}`}><Picto /></p>
											<p className={`hidden group-hover:flex items-center pl-4 h-14 font-semibold whitespace-nowrap 
                                    ${menu !== option.menu ? textColor : ""}`}>{t(`sidebar.${option.label}`)}</p>
										</a>
									</div>
								);
							})}
						</div>
					</div>

					<div className={`flex flex-col ml-5 mb-12 gap-y-2 ${ textColor}`}>
						<div className="flex items-center space-x-3">
							<p className="flex flex-shrink-0 items-center justify-center my-2 rounded-full w-14 h-14 text-white font-semibold"
								style={{ backgroundColor: colorAccent }}>
								{user.initials}
							</p>
							<div className="hidden group-hover:flex flex-col  flex-shrink-0">
								<p className=" text-sm ">{user.name}</p>
								<p className="text-sm underline font-semibold pl-1 cursor-pointer"
									onClick={() => signOut()}>
									{t("sidebar.Logout")}
								</p>
							</div>
						</div>

						<div className="w-14">
							{
								langListValue.length > 1
								&& <LanguageFilter
									themeColor={{ controlBorderColor: colorMenu, optionBgColor: colorMenu }}
									langListValue={langListValue}
									appDefinition={appDefinition}
								/>
							}
						</div>

						<p className="text-sm font-semibold pl-1 cursor-pointer"
							onClick={() => {
								dispatch(appSlice.actions.setPage("TERMs"));
							}}>
							{t("sidebar.Terms")}
						</p>

					</div>
				</div>
			</div>

			{/*
            * Menu Mobile
            */}
			<div className={`absolute bottom-0 w-full flex flex-col shadow-2xl
                md:hidden rounded-tl-3xl rounded-tr-3xl overflow-hidden z-3xl
                transition-height  duration-500 ease-out border-t
                ${ !mobileDetails ? "  h-16 bg-white" : " h-full alphaBlur"}`}>

				{mobileDetails && <div className="relative flex flex-col justify-center h-full pt-4 px-10 gap-y-6 overflow-y-auto">

					<div className="self-center flex items-center pb-6 gap-x-4 gap-y-1 ">
						<img src={appDefinition.logo} className="h-24"/>
					</div>

					{menuOptions.map((option, i) => {
						const Picto = option.picto;
						return (
							<div key ={`menu ${i}`}
								className="list-none w-full pl-4"
								onClick={() => {
									dispatch(appSlice.actions.setPage(option.menu));
									option.clearRefine?.map((clearRefine) => (dispatch(appSlice.actions
									.refine([{ key: clearRefine, value: undefined }]))));
									setMobileDetails(!mobileDetails);
								}}>
								<a href="#" className="relative w-full flex justify-start items-center gap-x-8 ">
									{(indicator?.menu === option.menu) &&
										<div className="absolute left-5 w-5 h-5 top-2 leading-5
										flex  justify-center items-center rounded-full
										bg-red-500 text-white text-smaller">
											{indicator.value}
										</div>
									}
									<p className={"flex items-center  text-gray-500"}><Picto /></p>
									<p className={"items-center  text-2xl font-semibold whitespace-nowrap text-gray-500"}>
										{option.label}
									</p>
								</a>
							</div>
						);
					})}
					<div className="flex items-center mt-6 gap-x-4 gap-y-1 ">
						<p className="flex flex-shrink-0 items-center justify-center mb-2 rounded-full w-14 h-14 text-white font-semibold"
							style={{ backgroundColor: colorAccent }}>
							{user.initials}
						</p>
						<div className="flex flex-col justify-center h-full text-gray-500 ">
							<p className="flex flex-shrink-0 text-sm ">{user.name}</p>
							<p className="text-medium font-semibold pl-1 cursor-pointer"
								onClick={() => signOut()}>
                                Logout
							</p>
						</div>
					</div>
				</div>
				}

				<div className={"flex"}>
					<div className="flex items-center between w-full h-full px-4 pt-2">
						<div className="flex items-center gap-x-2 h-full w-full ">

							{!mobileDetails ?
								<img src={appDefinition.logo} className="h-10 pr-2" />
								:
								<Link to="/" className="flex items-center gap-x-2 text-gray-500 cursor-pointer">
									<AppsIcon />
									<p className="pt-1 text-sm">Launcher</p>
								</Link>
							}

							{!mobileDetails && menuOptions.filter((element) => !element.noMobileMenu).map((option, i) => {
								const Picto = option.picto;
								return (
									<div key ={`menu ${i}`}
										className="relative px-3 w-12 h-full list-none  rounded-tl-full rounded-tr-full "
										style={{ backgroundColor: (menu === option.menu) ? colorMenu : "" }}
										onClick={() => {
											dispatch(appSlice.actions.setPage(option.menu));
											option.clearRefine?.map((clearRefine) => (dispatch(appSlice.actions
											.refine([{ key: clearRefine, value: undefined }]))));
										}}>
										<a href="#" className="relative w-full flex justify-start items-center text-white">
											{(indicator?.menu === option.menu) &&
										<div className="absolute left-5 w-5 h-5 top-2 leading-5
										flex  justify-center items-center rounded-full
										bg-red-500 text-white text-smaller">
											{indicator.value}
										</div>
											}
											<p className={`flex items-center h-14 ${menu !== option.menu ? " text-gray-500" : ""}`}>
												<Picto />
											</p>
										</a>
									</div>
								);
							})}
						</div>

						<button className="py-4" onClick={() => setMobileDetails(!mobileDetails)}>
							{mobileDetails ?
								<CloseIcon className="text-gray-500 cursor-pointer" />
								: <MenuIcon className="text-gray-500 cursor-pointer" />
							}
						</button>
					</div>
				</div>

			</div>

		</>

	);
}

SideBar.propTypes = {
	appSlice: PropTypes.object,
	appDefinition: PropTypes.object,
	menuOptions: PropTypes.array,
	menu: PropTypes.string,
	noTitle: PropTypes.bool,
	altTitle: PropTypes.string,
	colorMenu: PropTypes.string,
	colorBgMenu: PropTypes.string,
	colorAccent: PropTypes.string,
	textColor: PropTypes.string,
	indicator: PropTypes.object,
	themeColor: PropTypes.object,
	langListValue: PropTypes.array
};

export default SideBar;
