import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

const AdminSimpleCard = ({
	appSlice, locales, cardTitle, button1 = undefined, button2 = undefined, children
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const dispatch = useDispatch();

	return (
		<div className="bg-gray-200 rounded-xl py-4 border bg-opacity-90 cursor-pointer
        hover:border-gray-400 z-xl" onClick={() => {}}>

			<p className="text-sm text-center mt-3 uppercase font-semibold">{cardTitle}</p>

			<div className="flex items-center space-x-1 justify-center mt-4 divide-x divide-admin_primary-light">
				{ button1 &&
						<button
							className="bg-admin_primary-default text-white hover:bg-admin_primary-dark w-14
							font-bold uppercase text-smaller md:text-xs lg:text-smaller xl:text-xs 2xl:text-smaller px-3
							md:px-1 lg:px-2 xl:px-1.5 2xl:px-3 py-1 rounded ease-linear transition-all duration-150 z-3xl"
							type="button"
							onClick={() => dispatch(appSlice.actions.refine([
								{ key: "fourthLevelMenu", value: cardTitle },
								{ key: "appAction", value: button1.action }
							]))}
						>
							{button1.name}
						</button>
				}
				{ button2 &&
					<button
						className="bg-admin_primary-default text-white hover:bg-admin_primary-dark w-14
							font-bold uppercase text-smaller md:text-xs lg:text-smaller xl:text-xs 2xl:text-smaller px-3
							md:px-1 lg:px-2 xl:px-1.5 2xl:px-3 py-1 rounded ease-linear transition-all duration-150 z-3xl"
						type="button"
						onClick={() => dispatch(appSlice.actions.refine([
							{ key: "fourthLevelMenu", value: cardTitle },
							{ key: "appAction", value: button2.action }
						]))}
					>
						{button2.name}
					</button>
				}
				{children}
			</div>
		</div>
	);
};

AdminSimpleCard.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string,
	cardTitle: PropTypes.string,
	button1: PropTypes.object,
	button2: PropTypes.object,
	component: PropTypes.func,
	children: PropTypes.object

};

export default AdminSimpleCard;
