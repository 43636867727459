import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Controller } from "react-hook-form";

import { useDispatch } from "react-redux";

// TODO: require validator
const MultiSelectFieldEditor = ({
	field,
	control,
	options,
	appSlice = {},
	placeholder = "Select...",
	styles,
	defaultValue,
	defaultInputValue,
	isDispatch = false,
	isMulti = true,
	requiredFields = [],
	getValues
}) => {
	const dispatch = useDispatch();
	const getDefaultValue = () => {
		if (defaultValue !== undefined) {
			const formatedDefaultValue = defaultValue.map((e) => ({ value: e.k, label: e.k }));
			return (formatedDefaultValue);
		}
		return (undefined);
	};

	return (
		<Controller
			name={`${field}`}
			control={control}
			rules={{ required: requiredFields.includes(field) }}
			render={({
				field: {
					onChange, value, name, ref
				}
			}) => <>
				<Select
					value={options?.find((c) => c.value === value)}
					inputRef={ref}
					options={options}
					placeholder={placeholder}
					onChange={(selectedOption) => {
						onChange(selectedOption.map((e) => e.value));
					}}
					defaultValue={getDefaultValue}
					styles={styles}
					isMulti={isMulti}
				/>
				<p className="pl-3 text-gray-600 text-medium sm:text-sm">Selected:
					<span className="font-bold text-base"> {getValues(field)?.length || 0}</span>
				</p>
			</>}
		/>
	);
};

MultiSelectFieldEditor.propTypes = {
	control: PropTypes.object,
	field: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.array,
	appSlice: PropTypes.object,
	styles: PropTypes.object,
	defaultValue: PropTypes.object,
	defaultInputValue: PropTypes.object,
	isDispatch: PropTypes.bool,
	isMulti: PropTypes.bool,
	requiredFields: PropTypes.array,
	getValues: PropTypes.func
};

export default MultiSelectFieldEditor;
