import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { sdgsOptions } from "../../apps/configs/ClarityConfig";
import convertHextoRGBA from "../../utils/ConvertHextoRGBA";

function ClaritySDGHeader({
	appSlice, selectedSDG, listActiveSDGs, locales, children
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	return (
		<div className="relative flex flex-col w-full overflow-hidden h-80">
			<div className="absolute h-full w-full filter brightness-50 saturate-150 "
				style={{
					background: `linear-gradient(to left, ${convertHextoRGBA(sdgsOptions[selectedSDG]?.color, 80)}
                        , ${sdgsOptions[selectedSDG]?.color}`
				}} />
			<div className="flex space-x-2 z-40 px-4 md:px-16 pt-4 text-small xl:text-sm">
				{listActiveSDGs.map((element, i) => (
					<div key={i}
						onClick={() => dispatch(appSlice.actions.refine([{ key: "SDG", value: element.SDG }]))}
						className={`flex items-center rounded-xl border px-2 py-2 cursor-pointer border-white text-white
							${(element.SDG === selectedSDG) ?
							"bg-white text-clarity_primary-accent " : ""}`}>
						{element.SDG}
					</div>
				))}
			</div>
			<div className="flex items-center px-4 md:px-16  pb-12 flex-grow justify-between">
				<div className="relative flex flex-col z-10">
					<div className="absolute -top-52 -left-20 opacity-20 w-112
									text-white" style={{ zIndex: -1 }}>
						<img src={sdgsOptions[selectedSDG]?.picto} alt={sdgsOptions[selectedSDG]?.picto}
							className="object-contain filter brightness-0 invert contrast-200 "/>
					</div>

					<div className="flex gap-x-2 items-center">
						<div
							onClick={() => dispatch(appSlice.actions.refine([{ key: "SDG", value: undefined }]))}
							className="flex items-center text-clarity_primary-accent bg-white rounded-xl text-2xl font-bold p-2 cursor-pointer">
							<ArrowBackIcon fontSize="inherit" />
						</div>
						<p className="font-light line-clamp-1 text-2xl md:text-3xl xl:text-4xl text-white">
						Goal {selectedSDG.substring(3, 5)}
						</p>
					</div>
					<p className="pt-1 font-semibold line-clamp-1 text-3xl xl:text-4xl text-white">
						{t(`SDGs.${selectedSDG}.name`)}
					</p>

					<p className="pt-2 font-light text-medium md:text-base 2xl:text-lg text-white">
						{t(`SDGs.${selectedSDG}.description`)}
					</p>
				</div>
				{children}
			</div>
		</div>
	);
}

ClaritySDGHeader.propTypes = {
	appSlice: PropTypes.object,
	selectedSDG: PropTypes.string,
	listActiveSDGs: PropTypes.array,
	children: PropTypes.object,
	locales: PropTypes.string
};

export default ClaritySDGHeader;
