/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import { ParentSize } from "@visx/responsive";

import NonConformity from "./NonConformity";
import MaturityBars from "./MaturityBars";
import MonthAnalysis from "../graphs/MonthAnalysis";
import MonthAnalysisNC from "../graphs/MonthAnalysisNC";
import TitleCard from "./TitleCard";
import CallToAction from "../button/CallToAction";

const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

function NonConformitiesCard({
	appSlice = null, nonConformities, datasetListCriticalities = "listCriticalities", criticalityKey = "criticality",
	dataset = undefined, datasetNCMediumLow = undefined, synthesis = false, noMonthlyAnalysis = false, theme = "clarity",
	locales, showNonConformity = true,
	callToAction = false, ctaContent, ctaAssignementIcon, ctaLink, colorScale, maturityColorScale, ctaOptionLabelAccessor, ctaOptionUrlAccessor
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store
	const listCriticalities = useSelector(appSlice.selectDatasets)[datasetListCriticalities]?.data;
	const nonConformitiesData = useSelector(appSlice.selectDatasets)[dataset]?.data;
	const nonConformitiesDataMediumLow = useSelector(appSlice.selectDatasets)?.[datasetNCMediumLow]?.data || [];
	const monthAnalysisMaturityData = useSelector(appSlice.selectDatasets)?.linesMaturity?.data;
	const maturityNbSites = useSelector(appSlice.selectDatasets)?.maturityNbSites?.data;
	const sitePage = useSelector(appSlice.selectPage);
	// client parameters
	const clientParameters = useSelector(appSlice.selectClientParameters);

	if (listCriticalities === undefined || nonConformitiesData === undefined) {
		return null;
	}

	const sortedNonConformitiesData = [...nonConformitiesData]
	.sort((dotA, dotB) => ((dotA.year > dotB.year) ? 1 : ((dotA.year < dotB.year) ? -1 : ((dotA.month > dotB.month) ? 1 : -1))));
	const sortedNonConformitiesDataMediumLow = [...nonConformitiesDataMediumLow]
	.sort((dotA, dotB) => ((dotA.year > dotB.year) ? 1 : ((dotA.year < dotB.year) ? -1 : ((dotA.month > dotB.month) ? 1 : -1))));

	// eslint-disable-next-line object-curly-newline
	const { openedNC, criticalNC, sites } = sortedNonConformitiesData
	.reduce((acc, value) => ({
		openedNC: (acc.openedNC + value.nbNC),
		criticalNC: (acc.criticalNC + value.nbCNC),
		nbLowNC: (acc.nbLowNC + value.nbLowNC),
		nbMediumNC: (acc.nbMediumNC + value.nbMediumNC),
		sites: (
			new Set([...acc.sites, ...value.sites])
		)
	}),
	// eslint-disable-next-line object-curly-newline
	{ openedNC: 0, criticalNC: 0, sites: new Set(), nbLowNC: 0, nbMediumNC: 0 });
	// eslint-disable-next-line object-curly-newline
	const { sitesML, nbLowNC, nbMediumNC } = sortedNonConformitiesDataMediumLow && sortedNonConformitiesDataMediumLow
	.reduce((acc, value) => ({
		nbLowNC: (acc.nbLowNC + value.nbLowNC),
		nbMediumNC: (acc.nbMediumNC + value.nbMediumNC),
		sitesML: (
			new Set([...acc.sitesML, ...value.sites])
		)
	}),
	// eslint-disable-next-line object-curly-newline
	{ sitesML: new Set(), nbLowNC: 0, nbMediumNC: 0 });
	const nbSites = sites.size + (sitesML.size || 0);

	if (openedNC < 1) {
		return null;
	}

	const lineCriticalNC = sortedNonConformitiesData
	.reduce((acc, currentValue, currentIndex) => {
		acc[currentIndex] = {
			month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
			value: (typeof currentValue.nbCNC === "number" && !Number.isNaN(currentValue.nbCNC))
			&& currentValue.nbCNC + (currentIndex > 0 ? acc[currentIndex - 1].value : 0),
			tooltip: currentValue?.tooltipCNCStatus
				? Object.entries(currentValue?.tooltipCNCStatus).reduce((tNCAcc, tNCCur) => {
					// eslint-disable-next-line prefer-destructuring
					tNCAcc[tNCCur[0]] = currentIndex > 0
						? acc[currentIndex - 1].tooltip[tNCCur[0]] + tNCCur[1]
						: tNCCur[1];
					return tNCAcc;
				}, {})
				: null
		};
		return acc;
	}, [{}]);
	const lineMediumNC = sortedNonConformitiesDataMediumLow
	.reduce((acc, currentValue, currentIndex) => {
		acc[currentIndex] = {
			month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
			value: currentValue.nbMediumNC + (currentIndex > 0 ? acc[currentIndex - 1].value : 0),
			tooltip: currentValue.tooltipMediumNCStatus
				? Object.entries(currentValue.tooltipMediumNCStatus).reduce((tNCAcc, tNCCur) => {
					// eslint-disable-next-line prefer-destructuring
					tNCAcc[tNCCur[0]] = currentIndex > 0
						? acc[currentIndex - 1].tooltip[tNCCur[0]] + tNCCur[1]
						: tNCCur[1];
					return tNCAcc;
				}, {})
				: null
		};
		return acc;
	}, [{}]);
	const lineLowNC = sortedNonConformitiesDataMediumLow
	.reduce((acc, currentValue, currentIndex) => {
		acc[currentIndex] = {
			month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
			value: currentValue.nbLowNC + (currentIndex > 0 ? acc[currentIndex - 1].value : 0),
			tooltip: currentValue.tooltipLowNCStatus
				? Object.entries(currentValue.tooltipLowNCStatus).reduce((tNCAcc, tNCCur) => {
					// eslint-disable-next-line prefer-destructuring
					tNCAcc[tNCCur[0]] = currentIndex > 0
						? acc[currentIndex - 1].tooltip[tNCCur[0]] + tNCCur[1]
						: tNCCur[1];
					return tNCAcc;
				}, {})
				: null
		};
		return acc;
	}, [{}]);

	const lineOpenedNC = sortedNonConformitiesData
	.reduce((acc, currentValue, currentIndex) => {
		acc[currentIndex] = {
			month: `${monthString[currentValue.month - 1]}-${currentValue.year?.toString()?.substring(2)}`,
			value: currentValue.nbNC - currentValue.nbCNC + (currentIndex > 0 ? acc[currentIndex - 1].value : 0),
			tooltip: currentValue.tooltipNCStatus
				? Object.entries(currentValue.tooltipNCStatus).reduce((tNCAcc, tNCCur) => {
					// eslint-disable-next-line prefer-destructuring
					tNCAcc[tNCCur[0]] = currentIndex > 0
						? acc[currentIndex - 1].tooltip[tNCCur[0]] + tNCCur[1]
						: tNCCur[1];
					return tNCAcc;
				}, {})
				: null
		};
		return acc;
	}, [{}]);
	const lineNbSites = sortedNonConformitiesData
	.map((dot, i) => ({ month: `${monthString[dot.month - 1]}-${dot.year?.toString()?.substring(2)}`, frequency: (dot.nbSites) }));
	const linesNonConformities = [(lineCriticalNC.reduce((a, b) => a + b.value, 0) > 0
		? { _id: "high", list: lineCriticalNC }
		: null),
	(lineOpenedNC.reduce((a, b) => a + b.value, 0) > 0
		? { _id: "medium", list: lineOpenedNC }
		: null)].filter((e) => e !== null);
	const threeLevelNCsLine = [(lineCriticalNC.reduce((a, b) => a + b.value, 0) > 0
		? { _id: "high", list: lineCriticalNC }
		: null),
	(lineMediumNC.reduce((a, b) => a + b.value, 0) > 0
		? { _id: "medium", list: lineMediumNC }
		: null),
	(lineLowNC.reduce((a, b) => a + b.value, 0) > 0
		? { _id: "low", list: lineLowNC }
		: null)].filter((e) => e !== null);
	return (

		synthesis
			?
			<div className={`relative flex items-center h-full gap-x-8
					${showNonConformity ? "w-full justify-between" : "w-44 h-28 justify-center" }`}>
				<div className="flex flex-shrink-0 flex-col items-center justify-center h-full">
					<p className="text-5xl font-light text-red-500">{openedNC}</p>
					<p className="text-medium font-semibold tracking-tight">{t("NCsComponent.synthesis.areasOfConcern")}</p>
					<p className="text-medium font-light tracking-tight">{nbSites} {nbSites > 1
						? t("NCsComponent.synthesis.sites")
						: t("NCsComponent.synthesis.site")}</p>
				</div>
				{callToAction &&
					<CallToAction ctaContent={ctaContent} assignementIcon={ctaAssignementIcon} ctaLink={ctaLink}
						optionLabelAccessor={ctaOptionLabelAccessor} optionUrlAccessor={ctaOptionUrlAccessor}/>}
				{showNonConformity &&	<div className="w-52">

					{
						clientParameters?.maturityColors
							? <MaturityBars
								appSlice={appSlice}
								dataset="maturityRatios"
								noTitle
								locales={locales} />
							: <NonConformity
								appSlice={appSlice}
								noTitle
								refineKey={clientParameters?.threeLevelNC ? "criticalityLevel" : "critical"}
								refineValueHigh={clientParameters?.threeLevelNC ? "High" : "Y"}
								refineValueMedium={clientParameters?.threeLevelNC ? "Medium" : "N"}
								refineValueLow={clientParameters?.threeLevelNC && "Low"}
								ncHigh={criticalNC}
								ncMedium={clientParameters?.threeLevelNC ? nbMediumNC : openedNC - criticalNC}
								ncLow={clientParameters?.threeLevelNC ? nbLowNC : 0}
								locales={locales} />
					}

				</div>}
			</div>
			:
			<div className="w-full">
				<ParentSize>
					{(parent) => (
						<>
							{
								clientParameters?.maturityColors
									? <MaturityBars
										appSlice={appSlice}
										dataset="maturityRatios"
										noTitle
										locales={locales} />
									: <NonConformity
										appSlice={appSlice}
										noTitle
										refineKey={clientParameters?.threeLevelNC ? "criticalityLevel" : "critical"}
										refineValueHigh={clientParameters?.threeLevelNC ? "High" : "Y"}
										refineValueMedium={clientParameters?.threeLevelNC ? "Medium" : "N"}
										refineValueLow={clientParameters?.threeLevelNC && "Low"}
										ncHigh={criticalNC}
										ncMedium={clientParameters?.threeLevelNC ? nbMediumNC : openedNC - criticalNC}
										ncLow={clientParameters?.threeLevelNC ? nbLowNC : 0}
										locales={locales} />
							}
							<div className="flex justify-between items-center mb-2 w-full flex-wrap">
								<div className="flex items-center text-small font-light flex-wrap">
									{clientParameters?.maturityColors
										? clientParameters.maturityColors.map((element, i) => (
											<div key={i} className="flex items-center mr-5">
												<span className={`block w-3 h-3 rounded-full mr-1 md:mr-2 bg-clarity_primary-${element.color}`}></span>
												<p>{t(`NCsComponent.maturityColors.${element.label}`)}</p>
											</div>
										))
										: listCriticalities.map((element, i) => (
											<div key={i} className="flex items-center mr-5">
												<span className="block w-3 h-3 rounded-full mr-1 md:mr-2"
													style={{ backgroundColor: nonConformities?.[element?.[criticalityKey]] }}></span>
												<p>{t(`NCsComponent.legend.${element?.[criticalityKey]}`)}</p>
											</div>
										))}
									{!noMonthlyAnalysis && lineOpenedNC.length > 1 &&
							<div className="flex items-center">
								<span className={"block w-[5px] h-3 rounded-t-md bg-[#cecece] mr-1 md:mr-2"}></span>
								<p>{t("NCsComponent.legend.nbSuppliers")}</p>
							</div>
									}
								</div>
								{ sitePage !== "NCs"
								&& <p className={`flex-shrink-0 text-sm font-semibold text-${theme}_primary-accent cursor-pointer`}
									onClick={() => dispatch(appSlice.actions.setPage("NCs"))}>
									{t("NCsComponent.seeThemAll")}
								</p>}
							</div>
							{!noMonthlyAnalysis && lineOpenedNC.length >= 1 &&
                            <div className="flex flex-col">
                            	<TitleCard titleLocal="NCsComponent.graph.title" // eslint-disable-next-line no-mixed-spaces-and-tabs
                            		subTitleLocal="" xsmall theme={theme} // eslint-disable-next-line no-mixed-spaces-and-tabs
                            		infoLocal="NCsComponent.graph.info" locales={locales} />
                            	<MonthAnalysisNC
                            		appSlice={appSlice}
                            		colorScale={colorScale}
                            		maturityColorScale={maturityColorScale}
                            		linesNonConformities={clientParameters?.maturityColors
                            			? monthAnalysisMaturityData
                            			: clientParameters?.threeLevelNC
                            				? threeLevelNCsLine
                            			: linesNonConformities}
                            		maturityDisplay={!!clientParameters?.maturityColors}
                            		lineNbSites={clientParameters?.maturityColors ? maturityNbSites : lineNbSites}
                            		width={parent.width} locales={locales} />
                            </div>
							}
						</>
					)}
				</ParentSize>
			</div>

	);
}

NonConformitiesCard.propTypes = {
	appSlice: PropTypes.object,
	nonConformities: PropTypes.object,
	datasetListCriticalities: PropTypes.string,
	criticalityKey: PropTypes.string,
	dataset: PropTypes.string,
	datasetNCMediumLow: PropTypes.string,
	synthesis: PropTypes.bool,
	noMonthlyAnalysis: PropTypes.bool,
	theme: PropTypes.string,
	locales: PropTypes.string,
	showNonConformity: PropTypes.bool,
	callToAction: PropTypes.bool,
	ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	ctaContent: PropTypes.string,
	ctaAssignementIcon: PropTypes.bool,
	colorScale: PropTypes.array,
	maturityColorScale: PropTypes.array,
	ctaOptionLabelAccessor: PropTypes.string,
	ctaOptionUrlAccessor: PropTypes.string
};

export default NonConformitiesCard;
