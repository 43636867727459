import React from "react";
import PropTypes from "prop-types";

const VerkorApi = (props) => (
	<div>
		<a
			target="_blank"
			href="http://bv-sho-alpha-swaggerui.s3-website-eu-west-1.amazonaws.com/"
			className="underline hover:text-blue-500" rel="noreferrer">
                API documentation
		</a>
	</div>
);

VerkorApi.propTypes = {};

export default VerkorApi;
