import moment from "moment";
import {
	newBranchFirstNode, chainRefineFieldBuilder, formatClarityV4Referential, clearRefineKeys
} from "../../apps/configs/AdminConfig";
import {
	supplyRLocUpdateValidator, supplyRNodeUpdateValidator, clarityNodeUpdateValidator, clarityLocUpdateValidator,
	projectCreateValidator, projectUpdateValidator, userCreateValidator, userUpdateValidator, appCreateValidator,
	appReplaceValidator, ataglanceNodeUpdateValidator, ataglanceLocUpdateValidator
} from "./AdminFormValidator";

const ADMINUSER = "AdminUser";
const APPSCOPE = "AppScopes";
const CLARITYDOMAIN = "ClarityDomains";
const CLARITYTRANSLATIONS = "ClarityTranslations";

export const adminUserCreate = ({
	res,
	appSlice,
	dispatch,
	addOneProject,
	setconfirmModal,
	setAlert
}) => {
	// check data compliance first
	const shouldCreate = userCreateValidator();

	if (shouldCreate) {
		dispatch(
			addOneProject({
				appName: "admin",
				dataset: {
					collection: ADMINUSER,
					fetch: "insertOne",
					data: { ...res },
					adminCallFromApp: ""
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Admin user create success");

				setconfirmModal(false);
				dispatch(appSlice.actions.clear([{ refine: "action" }]));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The creation of admin user failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Admin user create error", rejectedValueOrSerializedError);
		});
	}
};

export const adminUserUpdate = ({
	res,
	resWithoutId,
	appSlice,
	dispatch,
	updateProject,
	setconfirmModal,
	setAlert
}) => {
	// check data compliance first
	const shouldUpdate = userUpdateValidator();

	if (shouldUpdate) {
		dispatch(
			updateProject({
				appName: "admin",
				dataset: {
					collection: ADMINUSER,
					fetch: "updateOne",
					data: { ...resWithoutId },
					id: res._id,
					limit: 1,
					adminCallFromApp: "",
					isObjectId: false
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Admin user success");

				setconfirmModal(false);
				dispatch(appSlice.actions.clear([{ refine: "user" }, { refine: "action" }]));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The update of admin user failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Admin user error", rejectedValueOrSerializedError);
		});
	}
};

export const adminUserDelete = ({
	appSlice,
	dispatch,
	deleteFunction,
	id,
	setAlert
}) => {
	dispatch(deleteFunction({
		appName: "admin",
		dataset: {
			collection: ADMINUSER,
			fetch: "deleteOne",
			id,
			adminCallFromApp: "",
			isObjectId: false
		},
		resetSkip: false
	}))
	.unwrap()
	.then((originalPromiseResult) => {
		if (originalPromiseResult.data.res.n) {
			console.log("User delete success");
			dispatch(appSlice.fetchDataset(ADMINUSER));

			dispatch(appSlice.actions.clear([{ refine: "user" }, { refine: "action" }]));
		} else {
			setAlert({
				isDiplayed: true,
				msg: "User delete failed"
			});
		}
	})
	.catch((rejectedValueOrSerializedError) => {
		console.log("User delete error", rejectedValueOrSerializedError);
	});
};

export const adminAppCreate = ({
	res,
	appSlice,
	dispatch,
	addOneProject,
	setconfirmModal,
	setAlert
}) => {
	// check data compliance first
	const shouldCreate = appCreateValidator();

	if (shouldCreate) {
		dispatch(
			addOneProject({
				appName: "admin",
				dataset: {
					collection: APPSCOPE,
					fetch: "insertOne",
					data: { ...res },
					adminCallFromApp: ""
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("App scope create success");

				setconfirmModal(false);
				dispatch(appSlice.actions.clear([{ refine: "action" }]));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The creation of app scope failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("App scope create error", rejectedValueOrSerializedError);
		});
	}
};

export const adminAppReplace = ({
	res,
	resWithoutId,
	appSlice,
	dispatch,
	replaceDoc,
	setconfirmModal,
	setAlert
}) => {
	// check data compliance first
	const shouldReplace = appReplaceValidator();

	if (shouldReplace) {
		dispatch(
			replaceDoc({
				appName: "admin",
				dataset: {
					collection: APPSCOPE,
					fetch: "replaceOne",
					data: { ...resWithoutId },
					id: res._id,
					limit: 1,
					adminCallFromApp: "",
					isObjectId: false
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("App scope update success");

				setconfirmModal(false);
				dispatch(appSlice.actions.clear([{ refine: "application" }, { refine: "action" }]));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The update of app scope failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("App scope update error", rejectedValueOrSerializedError);
		});
	}
};

export const adminAppDelete = ({
	appSlice,
	dispatch,
	deleteFunction,
	id,
	setAlert
}) => {
	dispatch(deleteFunction({
		appName: "admin",
		dataset: {
			collection: APPSCOPE,
			fetch: "deleteOne",
			id,
			adminCallFromApp: "",
			isObjectId: false
		},
		resetSkip: false
	}))
	.unwrap()
	.then((originalPromiseResult) => {
		if (originalPromiseResult.data.res.n) {
			console.log("App scope delete success");
			dispatch(appSlice.fetchDataset(APPSCOPE));

			dispatch(appSlice.actions.clear([{ refine: "application" }, { refine: "action" }]));
		} else {
			setAlert({
				isDiplayed: true,
				msg: "App scope delete failed"
			});
		}
	})
	.catch((rejectedValueOrSerializedError) => {
		console.log("App scope delete error", rejectedValueOrSerializedError);
	});
};

export const projectCreate = ({
	resWithoutId,
	appSlice,
	dispatch,
	activeRefines,
	addOneProject,
	clientCollection,
	setconfirmModal,
	setAlert,
	clientParamsDatasetBuilder,
	logo,
	refineKey
}) => {
	// check data compliance first
	const shouldCreate = projectCreateValidator();

	if (shouldCreate) {
		dispatch(
			addOneProject({
				appName: "admin",
				dataset: {
					collection: clientCollection[activeRefines.secLevelMenu],
					fetch: "insertOne",
					data: { ...resWithoutId },
					adminCallFromApp: activeRefines?.secLevelMenu,
					logo,
					refineKey
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Client create success");

				setconfirmModal(false);
				dispatch(appSlice.actions.clear([{ refine: "fourthLevelMenu" }, { refine: "projectName" },
					{ refine: "spaceLabel" }, { refine: "appAction" }]));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The creation of a project failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Client create error", rejectedValueOrSerializedError);
		});
	}
};

export const projectUpdate = ({
	res,
	resWithoutId,
	appSlice,
	dispatch,
	activeRefines,
	updateProject,
	clientCollection,
	setconfirmModal,
	setAlert,
	clientParamsDatasetBuilder,
	logo,
	refineKey
}) => {
	// check data compliance first
	const shouldUpdate = projectUpdateValidator();

	if (shouldUpdate) {
		dispatch(
			updateProject({
				appName: "admin",
				dataset: {
					collection: clientCollection[activeRefines.secLevelMenu],
					fetch: "replaceOne",
					data: { ...resWithoutId },
					id: res._id,
					limit: 1,
					adminCallFromApp: activeRefines?.secLevelMenu,
					logo,
					refineKey
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Client update success");

				setconfirmModal(false);
				dispatch(appSlice.actions.clear([{ refine: "fourthLevelMenu" }, { refine: "projectName" },
					{ refine: "spaceLabel" }, { refine: "appAction" }]));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The update of project failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Client update error", rejectedValueOrSerializedError);
		});
	}
};

export const projectDelete = ({
	isAlpha,
	appSlice,
	dispatch,
	activeRefines,
	deleteDoc,
	clientCollection,
	collectionId,
	setconfirmModal,
	setAlert
}) => {
	if (isAlpha) {
		dispatch(deleteDoc({
			appName: "admin",
			dataset: {
				collection: clientCollection[activeRefines.secLevelMenu],
				fetch: "deleteOne",
				id: collectionId,
				adminCallFromApp: activeRefines?.secLevelMenu,
				isObjectId: true
			},
			resetSkip: false
		}))
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Client delete success");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear([ { refine: "appAction" }]));
			} else {
				setconfirmModal(false);
				setAlert({
					isDiplayed: true,
					msg: "Client delete failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Client delete error", rejectedValueOrSerializedError);
		});
	}
};

// check if coordinates is well formatted
const regExpPattern = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

const isCoordinates = (value) => regExpPattern.test(value);

export const supplyRSupplierCreate = ({
	currentNode,
	originTargetDoc,
	setconfirmModal,
	activeDatasets,
	activeRefines,
	dispatch,
	appSlice,
	addOneProject,
	TORCollection,
	setMarkerPosition,
	setAlert
}) => {
	// if undefine then create a new siteLabelId otherwise retake the current target Document's siteLabelId
	const targetSupplier = activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data
	.find((item) => currentNode.supplier.toLowerCase() === item.supplier.toLowerCase() &&
            currentNode.auditedCompany.toLowerCase() === item.auditedCompany.toLowerCase());

	const isCreateNode = activeRefines.supplyChainAction === "createNode";

	const newNodeDataToTor = isCreateNode
		? {
			...currentNode,
			projectName: originTargetDoc.projectName,
			finalProduct: originTargetDoc.finalProduct,
			targetProduct: originTargetDoc.product === "Transportation" ? originTargetDoc.finalProduct : originTargetDoc.product,
			nextSupplier: originTargetDoc.siteLabelId,
			tierLevel: Number(originTargetDoc.tierLevel) + 1,
			tierLabel: `Tier ${Number(originTargetDoc.tierLevel) + 2}`,
			client: originTargetDoc.client.toUpperCase(),
			supplier: currentNode.supplier.toUpperCase(),
			auditedCompany: targetSupplier
				? targetSupplier.auditedCompany
				: `${currentNode.auditedCompany}`,
			key: {
				client: currentNode.client.toUpperCase(),
				siteLabelId: currentNode.siteLabelId,
				product: currentNode.product
			}
		}
		: {
			...currentNode,
			nextSupplier: activeDatasets[`${activeRefines?.secLevelMenu}ClientParameters`]?.data
			?.find((item) => item[chainRefineFieldBuilder[activeRefines?.secLevelMenu]][0] ===
			activeRefines[chainRefineFieldBuilder[activeRefines?.secLevelMenu]]).client,
			projectName: newBranchFirstNode({
				activeRefines,
				activeDatasets,
				source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0]
			}).projectName,
			tierLabel: "Tier 1",
			tierLevel: 0,
			client: currentNode.client.toUpperCase(),
			supplier: currentNode.supplier.toUpperCase(),
			key: {
				client: currentNode.client.toUpperCase(),
				siteLabelId: currentNode.siteLabelId,
				product: currentNode.product
			}
		};

	// whether we should dispatch the final submit
	let shouldCreate = true;

	// if true then it means creating node otherwise it means creating a new branch
	if (isCreateNode) {
		// TODO: not sure if this applicable in the future
	}

	// TODO: type manufacturer can not have product transportation
	// TODO: not sure if this applicable in the future
	if (!isCreateNode) {
		if (newNodeDataToTor.finalProduct !== newNodeDataToTor.targetProduct) {
			setAlert({
				isDiplayed: true,
				// eslint-disable-next-line max-len
				msg: "FinalProduct and TargetProduct should be the same value when creating tier 0, please check the value or letter case"
			});
			shouldCreate = false;
		}
	}

	if (shouldCreate) {
		dispatch(
			addOneProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "insertOne",
					data: { ...newNodeDataToTor },
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Tor create success");

				// clear marker position when submit
				setMarkerPosition("");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear(clearRefineKeys));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The creation of a supplier for SupplyR failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Tor create error", rejectedValueOrSerializedError);
		});
	}
};

export const supplyRNodeUpdate = ({
	res,
	resWithoutId,
	setconfirmModal,
	currentTarProduct,
	childrenNode,
	activeRefines,
	activeDatasets,
	dispatch,
	appSlice,
	updateProject,
	updateManyProject,
	TORCollection,
	setMarkerPosition,
	currentTarget,
	setAlert,
	currentTarSupplierCode,
	currentTarFactoryCode,
	currentTarFactoryBranch,
	currentTarSiteLabelIdRef
}) => {
	let siteWithSameSupplierCode;
	let siteWithSameFactoryCode;
	let siteWithSameFactoryBranch;
	// keep updating key if product exists
	if (resWithoutId.product) {
		resWithoutId.key = {
			client: resWithoutId.client.toUpperCase(),
			siteLabelId: resWithoutId.siteLabelId,
			product: resWithoutId.product
		};
	}

	if (activeRefines.secLevelMenu === "supplyR") {
		siteWithSameSupplierCode = activeDatasets?.supplyRChainToR?.data
		?.filter((item) => item?.supplier === currentTarget?.supplier)
		?.map((item) => item?._id);

		siteWithSameFactoryCode = activeDatasets?.supplyRChainToR?.data
		?.filter((item) => item?.auditedCompany === currentTarget?.auditedCompany)
		?.map((item) => item?._id);

		siteWithSameFactoryBranch = activeDatasets?.supplyRChainToR?.data
		?.filter((item) => item?.siteLabelId === currentTarget?.siteLabelId)
		?.map((item) => item?._id);
	}

	// check data compliance first
	const shouldUpdate = supplyRNodeUpdateValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);

	if (shouldUpdate) {
		dispatch(updateProject({
			appName: activeRefines?.secLevelMenu,
			dataset: {
				collection: TORCollection[activeRefines.secLevelMenu],
				fetch: "updateOne",
				data: {
					...resWithoutId
				},
				id: res._id,
				limit: 1,
				adminCallFromApp: activeRefines?.secLevelMenu
			},
			resetSkip: false
		}))
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Tor update success");

				// clear marker position when submit
				setMarkerPosition("");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear(clearRefineKeys));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The update of a supplier for SupplyR failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Tor update error", rejectedValueOrSerializedError);
		});

		// when change parent node's product, then change all its children nodes' target product
		if (currentTarProduct && (currentTarProduct !== resWithoutId.product) && childrenNode.length > 0) {
			const childrenNodeIds = childrenNode.map((item) => item._id);

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						targetProduct: resWithoutId.product
					},
					id: childrenNodeIds,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for SupplyR failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}

		// when change supplierCode it should change all supplierCode of the same supplier of current project
		if (currentTarSupplierCode && (currentTarSupplierCode !== resWithoutId.supplierCode) && siteWithSameSupplierCode.length > 0) {
			// renew supplierCode ref
			currentTarSupplierCode = resWithoutId.supplierCode;

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						supplierCode: resWithoutId.supplierCode
					},
					id: siteWithSameSupplierCode,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for SupplyR failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}

		// when change factoryCode it should change all factoryCode of the same auditedCompany of current project
		if (currentTarFactoryCode && (currentTarFactoryCode !== resWithoutId.factoryCode) && siteWithSameFactoryCode.length > 0) {
			// renew factoryCode ref
			currentTarFactoryCode = resWithoutId.factoryCode;

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						factoryCode: resWithoutId.factoryCode
					},
					id: siteWithSameFactoryCode,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for SupplyR failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}

		// when change factoryBranch it should change all factoryBranch of the same siteLabelId of current project
		if (currentTarFactoryBranch && (currentTarFactoryBranch !== resWithoutId.factoryBranch) && siteWithSameFactoryBranch.length > 0) {
			// renew factoryBranch ref
			currentTarFactoryBranch = resWithoutId.factoryBranch;

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						factoryBranch: resWithoutId.factoryBranch
					},
					id: siteWithSameFactoryBranch,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for SupplyR failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}

		// when change parent node's siteLabelId, then change all its children nodes' nextSupplier
		if (currentTarSiteLabelIdRef && (currentTarSiteLabelIdRef !== resWithoutId.siteLabelId) && childrenNode.length > 0) {
			const childrenNodeIds = childrenNode.map((item) => item._id);

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						nextSupplier: resWithoutId.siteLabelId
					},
					id: childrenNodeIds,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for SupplyR failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}
	}
};

export const supplyRLocationUpdate = ({
	res,
	resWithoutId,
	setconfirmModal,
	currentTarProduct,
	childrenNode,
	activeRefines,
	activeDatasets,
	dispatch,
	appSlice,
	updateProject,
	updateManyProject,
	TORCollection,
	setMarkerPosition,
	currentTarget,
	setAlert
}) => {
	// check data compliance first
	const parentNode = supplyRLocUpdateValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);

	if (!parentNode) return;

	// adjust current target document tierLevel and tierLabel
	resWithoutId.tierLevel = Number(parentNode?.tierLevel) + 1;
	resWithoutId.tierLabel = `Tier ${Number(parentNode?.tierLevel) + 2}`;

	// adjust current target document finalProduct/ targetProduct/ product
	if (parentNode?.product === "Transportation") {
		resWithoutId.finalProduct = parentNode?.finalProduct;
		resWithoutId.targetProduct = parentNode?.finalProduct;
		resWithoutId.product = parentNode?.finalProduct;
	} else {
		resWithoutId.finalProduct = parentNode?.finalProduct;
		resWithoutId.targetProduct = parentNode?.product;
	}

	// keep updating key if product exists
	if (resWithoutId.product) {
		resWithoutId.key = {
			client: resWithoutId.client.toUpperCase(),
			siteLabelId: resWithoutId.siteLabelId,
			product: resWithoutId.product
		};
	}

	// if current target document has children, then adapt all its children's tierLevel/tierLabel
	const tierDiff = Number(currentTarget.tierLevel) - Number(resWithoutId.tierLevel);

	if (parentNode) {
		dispatch(updateProject({
			appName: activeRefines?.secLevelMenu,
			dataset: {
				collection: TORCollection[activeRefines.secLevelMenu],
				fetch: "updateOne",
				data: {
					...resWithoutId
				},
				id: res._id,
				limit: 1,
				adminCallFromApp: activeRefines?.secLevelMenu
			},
			resetSkip: false
		}))
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Tor update success");

				// clear marker position when submit
				setMarkerPosition("");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear(clearRefineKeys));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The update of a supplier for SupplyR failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Tor update error", rejectedValueOrSerializedError);
		});

		// when change parent node's product, then change all its children nodes' target product
		if (currentTarProduct && (currentTarProduct !== resWithoutId.product) && childrenNode.length > 0) {
			const childrenNodeIds = childrenNode.map((item) => item._id);

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						targetProduct: resWithoutId.product
					},
					id: childrenNodeIds,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for SupplyR failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}

		// if current target document has children, then update all its children
		if (childrenNode?.length > 0) {
			const newChildrenNode = childrenNode.reduce((acc, child) => {
				const res = { ...child };
				delete res._id;
				const newChild = {
					id: child._id,
					data: {
						...res,
						finalProduct: resWithoutId?.finalProduct,
						tierLevel: Number(res?.tierLevel) - tierDiff,
						tierLabel: `Tier ${Number(res?.tierLevel) - tierDiff + 1}`
					}
				};

				return [...acc, newChild];
			}, []);

			newChildrenNode.forEach((node) => {
				dispatch(updateProject({
					appName: activeRefines?.secLevelMenu,
					dataset: {
						collection: TORCollection[activeRefines.secLevelMenu],
						fetch: "updateOne",
						data: {
							...node.data
						},
						id: node.id,
						limit: 1,
						adminCallFromApp: activeRefines?.secLevelMenu
					},
					resetSkip: false
				}))
				.unwrap()
				.then((originalPromiseResult) => {
					if (originalPromiseResult.data.res.n) {
						console.log("Tor update success");

						// clear marker position when submit
						setMarkerPosition("");
						setconfirmModal(false);
					} else {
						setAlert({
							isDiplayed: true,
							// eslint-disable-next-line max-len
							msg: "The update of a supplier for SupplyR failed, please try again. If the issue persists, please contact the our digital team."
						});
					}
				})
				.catch((rejectedValueOrSerializedError) => {
					console.log("Tor update error", rejectedValueOrSerializedError);
				});
			});
		}
	}
};

export const SupplierDelete = ({
	appSlice,
	dispatch,
	deleteManyDocs,
	TORCollection,
	activeRefines,
	deleteNodes,
	parentNode,
	setSelected,
	forceUpdate,
	setAlert,
	supplychainView,
	setconfirmModal
}) => {
	if (activeRefines.supplyChainAction === "deleteNode") {
		dispatch(appSlice.actions.clear([{ refine: "supplyChainAction" }]));
	}

	dispatch(deleteManyDocs({
		appName: "admin",
		dataset: {
			collection: TORCollection[activeRefines.secLevelMenu],
			fetch: "deleteMany",
			id: deleteNodes,
			adminCallFromApp: activeRefines?.secLevelMenu
		},
		resetSkip: false
	}))
	.unwrap()
	.then((originalPromiseResult) => {
		if (originalPromiseResult.data.res.n) {
			console.log("Tor delete success");

			dispatch(appSlice.actions.refine([
				{ key: "supplyChainAction", value: "deleteNode" }]));

			setconfirmModal(false);

			if (!supplychainView) {
				dispatch(appSlice.actions.clear([{ refine: "site" }, { refine: "supplyChainAction" }]));
			}

			if (supplychainView) { dispatch(appSlice.actions.clear([{ refine: "site" }])); }

			if (parentNode && setSelected) {
				parentNode.data.selected = true;
				setSelected(parentNode);
			}
		} else {
			setAlert({
				isDiplayed: true,
				msg: "Tor delete failed"
			});
		}
	})
	.catch((rejectedValueOrSerializedError) => {
		console.log("Tor delete error", rejectedValueOrSerializedError);
	});
};

// Check if string contains only digits as siteId can be string or number
const isNum = (value) => /^\d+$/.test(value);

export const claritySupplierCreate = ({
	currentNode,
	originTargetDoc,
	setconfirmModal,
	activeDatasets,
	activeRefines,
	dispatch,
	appSlice,
	addOneProject,
	TORCollection,
	setMarkerPosition,
	setAlert
}) => {
	const isCreateNode = activeRefines.supplyChainAction === "createNode";

	const newNodeDataToTor = isCreateNode
		? {
			...currentNode,
			client: originTargetDoc.client.toUpperCase(),
			projectName: originTargetDoc.projectName,
			// building up a tree not possible if keeping it as number
			nextSupplier: typeof originTargetDoc?.siteId === "number" ? originTargetDoc.siteId.toString(10) : originTargetDoc?.siteId,
			finalProduct: originTargetDoc.finalProduct,
			targetProduct: originTargetDoc.product,
			product: currentNode.product,
			tierLevel: Number(originTargetDoc.tierLevel) + 1,
			supplier: currentNode.supplier.toUpperCase(),
			siteId: isNum(currentNode?.siteId)
				? Number(currentNode?.siteId) : currentNode.siteId.charAt(0).toUpperCase() + currentNode.siteId.slice(1).toLowerCase(),
			key: {
				product: currentNode.product,
				siteId: isNum(currentNode?.siteId)
					? Number(currentNode?.siteId) : currentNode.siteId.charAt(0).toUpperCase() + currentNode.siteId.slice(1).toLowerCase()
			}
		}
		: {
			...currentNode,
			nextSupplier: activeDatasets.clarityClientParameters?.data
			?.find((item) => item[chainRefineFieldBuilder.clarity][0] === activeRefines[chainRefineFieldBuilder.clarity]).client,
			projectName: newBranchFirstNode({
				activeRefines,
				activeDatasets,
				source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0]
			}).projectName,
			tierLevel: 0,
			siteId: isNum(currentNode?.siteId)
				? Number(currentNode?.siteId) : currentNode.siteId.charAt(0).toUpperCase() + currentNode.siteId.slice(1).toLowerCase(),
			product: currentNode.product,
			key: {
				product: currentNode.product,
				siteId: isNum(currentNode?.siteId)
					? Number(currentNode?.siteId) : currentNode.siteId.charAt(0).toUpperCase() + currentNode.siteId.slice(1).toLowerCase()
			}
		};

	// Check duplicates
	const siteId = activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data
	.filter((item) => {
		const newNodeSiteId = typeof newNodeDataToTor.siteId === "string" ? newNodeDataToTor.siteId : newNodeDataToTor.siteId.toString(10);
		const itemSiteId = typeof item.siteId === "string" ? item.siteId : item.siteId.toString(10);

		return newNodeSiteId === itemSiteId;
	});

	// whether we should dispatch the final submit
	let shouldCreate = true;

	// if true then it means creating node otherwise it means creating a new branch
	if (isCreateNode) {
		// TODO: not sure if this applicable in the future
	}

	if (!isCreateNode) {
		if (newNodeDataToTor.finalProduct !== newNodeDataToTor.targetProduct) {
			setAlert({
				isDiplayed: true,
				// eslint-disable-next-line max-len
				msg: "FinalProduct and TargetProduct should be the same value when creating tier 0, please check the value or letter case"
			});
			shouldCreate = false;
		}
	}

	if (shouldCreate) {
		dispatch(
			addOneProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "insertOne",
					data: { ...newNodeDataToTor },
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Tor create success");

				// clear marker position when submit
				setMarkerPosition("");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear(clearRefineKeys));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The creation of a supplier for Clarity failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Tor create error", rejectedValueOrSerializedError);
		});
	}
};

export const clarityNodeUpdate = ({
	res,
	resWithoutId,
	setconfirmModal,
	currentTarProduct,
	childrenNode,
	activeRefines,
	activeDatasets,
	dispatch,
	appSlice,
	updateProject,
	updateManyProject,
	TORCollection,
	setMarkerPosition,
	currentTarget,
	setAlert,
	currentTarSiteId
}) => {
	// check data compliance first
	const shouldUpdate = clarityNodeUpdateValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);

	if (!shouldUpdate) return;

	// transform siteId into proper type before updating
	resWithoutId.siteId = isNum(resWithoutId?.siteId)
		? Number(resWithoutId?.siteId) : resWithoutId.siteId.charAt(0).toUpperCase() + resWithoutId.siteId.slice(1).toLowerCase();

	// add key to current submitValue
	resWithoutId.key = {
		product: resWithoutId.product,
		siteId: isNum(resWithoutId?.siteId)
			? Number(resWithoutId?.siteId) : resWithoutId.siteId.charAt(0).toUpperCase() + resWithoutId.siteId.slice(1).toLowerCase()
	};

	if (shouldUpdate) {
		dispatch(updateProject({
			appName: activeRefines?.secLevelMenu,
			dataset: {
				collection: TORCollection[activeRefines.secLevelMenu],
				fetch: "updateOne",
				data: {
					...resWithoutId
				},
				id: res._id,
				limit: 1,
				adminCallFromApp: activeRefines?.secLevelMenu
			},
			resetSkip: false
		}))
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Tor update success");

				// clear marker position when submit
				setMarkerPosition("");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear(clearRefineKeys));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The update of a supplier for Clarity failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Tor update error", rejectedValueOrSerializedError);
		});

		// when change parent node's product, then change all its children nodes' target product
		if (currentTarProduct && (currentTarProduct !== resWithoutId.product) && childrenNode.length > 0) {
			const childrenNodeIds = childrenNode.map((item) => item._id);

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						targetProduct: resWithoutId.product
					},
					id: childrenNodeIds,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for Clarity failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}

		// when change parent node's siteId, then change all its children nodes' nextSupplier
		if (currentTarSiteId && (currentTarSiteId !== resWithoutId.siteId) && childrenNode.length > 0) {
			const childrenNodeIds = childrenNode.map((item) => item._id);

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						nextSupplier: resWithoutId.siteId
					},
					id: childrenNodeIds,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for Clarity failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}
	}
};

// TODO: not yet definitive
export const clarityLocationUpdate = ({
	res,
	resWithoutId,
	setconfirmModal,
	currentTarProduct,
	childrenNode,
	activeRefines,
	activeDatasets,
	dispatch,
	appSlice,
	updateProject,
	updateManyProject,
	TORCollection,
	setMarkerPosition,
	currentTarget,
	setAlert
}) => {
	// check data compliance first
	const parentNode = clarityLocUpdateValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);

	if (!parentNode) return;

	// adjust current target document tierLevel and tierLabel
	resWithoutId.tierLevel = Number(parentNode.tierLevel) + 1;

	// transform siteId into proper type before updating
	resWithoutId.siteId = isNum(resWithoutId?.siteId)
		? Number(resWithoutId?.siteId) : resWithoutId.siteId.charAt(0).toUpperCase() + resWithoutId.siteId.slice(1).toLowerCase();

	// add key to current submitValue
	resWithoutId.key = {
		product: resWithoutId.product,
		siteId: isNum(resWithoutId?.siteId)
			? Number(resWithoutId?.siteId) : resWithoutId.siteId.charAt(0).toUpperCase() + resWithoutId.siteId.slice(1).toLowerCase()
	};

	const tierDiff = Number(currentTarget.tierLevel) - Number(resWithoutId.tierLevel);

	if (parentNode) {
		dispatch(updateProject({
			appName: activeRefines?.secLevelMenu,
			dataset: {
				collection: TORCollection[activeRefines.secLevelMenu],
				fetch: "updateOne",
				data: {
					...resWithoutId
				},
				id: res._id,
				limit: 1,
				adminCallFromApp: activeRefines?.secLevelMenu
			},
			resetSkip: false
		}))
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Tor update success");

				// clear marker position when submit
				setMarkerPosition("");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear(clearRefineKeys));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The update of a supplier for Clarity failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Tor update error", rejectedValueOrSerializedError);
		});

		// when change parent node's product, then change all its children nodes' target product
		if (currentTarProduct && (currentTarProduct !== resWithoutId.product) && childrenNode.length > 0) {
			const childrenNodeIds = childrenNode.map((item) => item._id);

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						targetProduct: resWithoutId.product
					},
					id: childrenNodeIds,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for Clarity failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}

		if (childrenNode?.length > 0) {
			const newChildrenNode = childrenNode.reduce((acc, child) => {
				const res = { ...child };
				delete res._id;
				const newChild = {
					id: child._id,
					data: {
						...res,
						finalProduct: resWithoutId?.finalProduct,
						tierLevel: Number(`${Number(res?.tierLevel) - tierDiff}`)
					}
				};

				return [...acc, newChild];
			}, []);

			newChildrenNode.forEach((node) => {
				dispatch(updateProject({
					appName: activeRefines?.secLevelMenu,
					dataset: {
						collection: TORCollection[activeRefines.secLevelMenu],
						fetch: "updateOne",
						data: {
							...node.data
						},
						id: node.id,
						limit: 1,
						adminCallFromApp: activeRefines?.secLevelMenu
					},
					resetSkip: false
				}))
				.unwrap()
				.then((originalPromiseResult) => {
					if (originalPromiseResult.data.res.n) {
						console.log("Tor update success");

						// clear marker position when submit
						setMarkerPosition("");
						setconfirmModal(false);
					} else {
						setAlert({
							isDiplayed: true,
							// eslint-disable-next-line max-len
							msg: "The update of a supplier for Clarity failed, please try again. If the issue persists, please contact the our digital team."
						});
					}
				})
				.catch((rejectedValueOrSerializedError) => {
					console.log("Tor update error", rejectedValueOrSerializedError);
				});
			});
		}
	}
};

export const ataglanceSupplierCreate = ({
	currentNode,
	originTargetDoc,
	setconfirmModal,
	activeDatasets,
	activeRefines,
	dispatch,
	appSlice,
	addOneProject,
	TORCollection,
	setMarkerPosition,
	setAlert
}) => {
	// if undefine then create a new siteLabelId otherwise retake the current target Document's siteLabelId
	const targetSupplier = activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data
	.find((item) => currentNode.supplier.toLowerCase() === item.supplier.toLowerCase() &&
            currentNode.auditedCompany.toLowerCase() === item.auditedCompany.toLowerCase());

	const isCreateNode = activeRefines.supplyChainAction === "createNode";

	const newNodeDataToTor = isCreateNode
		? {
			...currentNode,
			projectName: originTargetDoc.projectName,
			finalProduct: originTargetDoc.finalProduct,
			targetProduct: originTargetDoc.product === "Transportation" ? originTargetDoc.finalProduct : originTargetDoc.product,
			nextSupplier: originTargetDoc.siteUniqueId,
			tierLevel: Number(originTargetDoc.tierLevel) + 1,
			client: originTargetDoc.client.toUpperCase(),
			supplier: currentNode.supplier.toUpperCase(),
			auditedCompany: targetSupplier
				? targetSupplier.auditedCompany
				: `${currentNode.auditedCompany}`,
			siteId: isNum(currentNode?.siteId)
				? Number(currentNode?.siteId) : currentNode.siteId.charAt(0).toUpperCase() + currentNode.siteId.slice(1).toLowerCase()
		}
		: {
			...currentNode,
			// TODO:dynamic way for projectLabel
			nextSupplier: activeDatasets[`${activeRefines?.secLevelMenu}ClientParameters`]?.data
			?.find((item) => item.projectLabel === activeRefines[chainRefineFieldBuilder[activeRefines?.secLevelMenu]]).client.toUpperCase(),
			projectName: newBranchFirstNode({
				activeRefines,
				activeDatasets,
				source: activeDatasets[`${activeRefines.secLevelMenu}ChainToR`]?.data?.[0]
			}).projectName,
			tierLevel: 0,
			client: currentNode.client.toUpperCase(),
			supplier: currentNode.supplier.toUpperCase(),
			siteId: isNum(currentNode?.siteId)
				? Number(currentNode?.siteId) : currentNode.siteId.charAt(0).toUpperCase() + currentNode.siteId.slice(1).toLowerCase()
		};

	// whether we should dispatch the final submit
	let shouldCreate = true;

	// if true then it means creating node otherwise it means creating a new branch
	if (isCreateNode) {
		// TODO: not sure if this applicable in the future
	}

	if (!isCreateNode) {
		if (newNodeDataToTor.finalProduct !== newNodeDataToTor.targetProduct) {
			setAlert({
				isDiplayed: true,
				// eslint-disable-next-line max-len
				msg: "FinalProduct and TargetProduct should be the same value when creating tier 0, please check the value or letter case"
			});
			shouldCreate = false;
		}
	}

	if (shouldCreate) {
		dispatch(
			addOneProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "insertOne",
					data: { ...newNodeDataToTor },
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Tor create success");

				// clear marker position when submit
				setMarkerPosition("");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear(clearRefineKeys));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The creation of a supplier for AtAGlance failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Tor create error", rejectedValueOrSerializedError);
		});
	}
};

export const ataglanceNodeUpdate = ({
	res,
	resWithoutId,
	setconfirmModal,
	currentTarProduct,
	childrenNode,
	activeRefines,
	activeDatasets,
	dispatch,
	appSlice,
	updateProject,
	updateManyProject,
	TORCollection,
	setMarkerPosition,
	currentTarget,
	setAlert,
	currentTarSiteLabelIdRef,
	currentTarSiteUniqueIdRef
}) => {
	// check data compliance first
	const shouldUpdate = ataglanceNodeUpdateValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);

	if (shouldUpdate) {
		dispatch(updateProject({
			appName: activeRefines?.secLevelMenu,
			dataset: {
				collection: TORCollection[activeRefines.secLevelMenu],
				fetch: "updateOne",
				data: {
					...resWithoutId
				},
				id: res._id,
				limit: 1,
				adminCallFromApp: activeRefines?.secLevelMenu
			},
			resetSkip: false
		}))
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Tor update success");

				// clear marker position when submit
				setMarkerPosition("");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear(clearRefineKeys));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The update of a supplier for AtAGlance failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Tor update error", rejectedValueOrSerializedError);
		});

		// when change parent node's product, then change all its children nodes' target product
		if (currentTarProduct && (currentTarProduct !== resWithoutId.product) && childrenNode.length > 0) {
			const childrenNodeIds = childrenNode.map((item) => item._id);

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						targetProduct: resWithoutId.product
					},
					id: childrenNodeIds,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for AtAGlance failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}

		// when change parent node's siteUniqueId, then change all its children nodes' nextSupplier
		if (currentTarSiteUniqueIdRef && (currentTarSiteUniqueIdRef !== resWithoutId.siteUniqueId) && childrenNode.length > 0) {
			const childrenNodeIds = childrenNode.map((item) => item._id);

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						nextSupplier: resWithoutId.siteUniqueId
					},
					id: childrenNodeIds,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for AtAGlance failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}
	}
};

export const ataglanceLocationUpdate = ({
	res,
	resWithoutId,
	setconfirmModal,
	currentTarProduct,
	childrenNode,
	activeRefines,
	activeDatasets,
	dispatch,
	appSlice,
	updateProject,
	updateManyProject,
	TORCollection,
	setMarkerPosition,
	currentTarget,
	setAlert
}) => {
	// check data compliance first
	const parentNode = ataglanceLocUpdateValidator(resWithoutId, activeDatasets, activeRefines, currentTarget, setAlert);

	if (!parentNode) return;

	// adjust current target document tierLevel and tierLabel
	resWithoutId.tierLevel = Number(parentNode?.tierLevel) + 1;

	// adjust current target document finalProduct/ targetProduct/ product
	if (parentNode?.product === "Transportation") {
		resWithoutId.finalProduct = parentNode?.finalProduct;
		resWithoutId.targetProduct = parentNode?.finalProduct;
		resWithoutId.product = parentNode?.finalProduct;
	} else {
		resWithoutId.finalProduct = parentNode?.finalProduct;
		resWithoutId.targetProduct = parentNode?.product;
	}

	// if current target document has children, then adapt all its children's tierLevel/tierLabel
	const tierDiff = Number(currentTarget.tierLevel) - Number(resWithoutId.tierLevel);

	if (parentNode) {
		dispatch(updateProject({
			appName: activeRefines?.secLevelMenu,
			dataset: {
				collection: TORCollection[activeRefines.secLevelMenu],
				fetch: "updateOne",
				data: {
					...resWithoutId
				},
				id: res._id,
				limit: 1,
				adminCallFromApp: activeRefines?.secLevelMenu
			},
			resetSkip: false
		}))
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Tor update success");

				// clear marker position when submit
				setMarkerPosition("");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear(clearRefineKeys));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The update of a supplier for AtAGlance failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Tor update error", rejectedValueOrSerializedError);
		});

		// when change parent node's product, then change all its children nodes' target product
		if (currentTarProduct && (currentTarProduct !== resWithoutId.product) && childrenNode.length > 0) {
			const childrenNodeIds = childrenNode.map((item) => item._id);

			dispatch(updateManyProject({
				appName: "admin",
				dataset: {
					collection: TORCollection[activeRefines.secLevelMenu],
					fetch: "updateMany",
					data: {
						targetProduct: resWithoutId.product
					},
					id: childrenNodeIds,
					adminCallFromApp: activeRefines?.secLevelMenu
				},
				resetSkip: false
			}))
			.unwrap()
			.then((originalPromiseResult) => {
				if (originalPromiseResult.data.res.n) {
					console.log("Tor update many success");

					setconfirmModal(false);
				} else {
					setAlert({
						isDiplayed: true,
						msg: "The update of a supplier for AtAGlance failed, please try again. If the issue persists, please contact the our digital team."
					});
				}
			})
			.catch((rejectedValueOrSerializedError) => {
				console.log("Tor update many error",
					rejectedValueOrSerializedError);
			});
		}

		// if current target document has children, then update all its children
		if (childrenNode?.length > 0) {
			const newChildrenNode = childrenNode.reduce((acc, child) => {
				const res = { ...child };
				delete res._id;
				const newChild = {
					id: child._id,
					data: {
						...res,
						finalProduct: resWithoutId?.finalProduct,
						tierLevel: Number(res?.tierLevel) - tierDiff,
						tierLabel: `Tier ${Number(res?.tierLevel) - tierDiff + 1}`
					}
				};

				return [...acc, newChild];
			}, []);

			newChildrenNode.forEach((node) => {
				dispatch(updateProject({
					appName: activeRefines?.secLevelMenu,
					dataset: {
						collection: TORCollection[activeRefines.secLevelMenu],
						fetch: "updateOne",
						data: {
							...node.data
						},
						id: node.id,
						limit: 1,
						adminCallFromApp: activeRefines?.secLevelMenu
					},
					resetSkip: false
				}))
				.unwrap()
				.then((originalPromiseResult) => {
					if (originalPromiseResult.data.res.n) {
						console.log("Tor update success");

						// clear marker position when submit
						setMarkerPosition("");
						setconfirmModal(false);
					} else {
						setAlert({
							isDiplayed: true,
							// eslint-disable-next-line max-len
							msg: "The update of a supplier for AtAGlance failed, please try again. If the issue persists, please contact the our digital team."
						});
					}
				})
				.catch((rejectedValueOrSerializedError) => {
					console.log("Tor update error", rejectedValueOrSerializedError);
				});
			});
		}
	}
};

export const adminValidateNodes = ({
	appSlice,
	dispatch,
	validateMany,
	nodesToValidate,
	setValidateModal,
	TORCollection,
	activeRefines,
	setAlert,
	forceUpdate,
	supplychainView
}) => {
	if (activeRefines.supplyChainAction === "validateNode") {
		dispatch(appSlice.actions.clear([{ refine: "supplyChainAction" }]));
	}
	dispatch(validateMany({
		appName: "admin",
		dataset: {
			collection: TORCollection[activeRefines.secLevelMenu],
			fetch: "updateMany",
			data: {
				validated: "True"
			},
			id: nodesToValidate,
			adminCallFromApp: activeRefines?.secLevelMenu
		},
		resetSkip: false
	}))
	.unwrap()
	.then((originalPromiseResult) => {
		if (originalPromiseResult.data.res.n) {
			console.log("Tor update many success");
			dispatch(appSlice.actions.refine([
				{ key: "supplyChainAction", value: "validateNode" }]));
			if (!supplychainView) {
				dispatch(appSlice.actions.clear([{ refine: "site" }, { refine: "supplyChainAction" }]));
			}

			if (supplychainView) { dispatch(appSlice.actions.clear([{ refine: "site" }])); }
			setValidateModal(false);
		} else {
			setAlert({
				isDiplayed: true,
				msg: "Tor update many failed"
			});
		}
	})
	.catch((rejectedValueOrSerializedError) => {
		console.log("Tor update many error",
			rejectedValueOrSerializedError);
	});
};

export const adminDomainCreate = ({
	resWithoutId,
	appSlice,
	dispatch,
	addOneDoc,
	setconfirmModal,
	setAlert
}) => {
	// check data compliance first
	const shouldCreate = appCreateValidator();

	if (shouldCreate) {
		dispatch(
			addOneDoc({
				appName: "admin",
				dataset: {
					collection: CLARITYDOMAIN,
					fetch: "insertOne",
					data: { ...resWithoutId },
					adminCallFromApp: ""
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Domain create success");

				setconfirmModal(false);
				dispatch(appSlice.actions.clear([{ refine: "fourthLevelMenu" }, { refine: "appAction" }]));
				dispatch(appSlice.actions.refine([{ key: "appAction", value: "readListDomains" },
					{ key: "fourthLevelMenu", value: "Domains options" }]));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The creation of a domain failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Domain create error", rejectedValueOrSerializedError);
		});
	}
};

export const executeAdminPipeline = ({
	appSlice,
	activeRefines,
	dispatch,
	updateProject,
	sourceCollection,
	targetCollection,
	query
}) => {
	dispatch(updateProject({
		appName: activeRefines?.secLevelMenu,
		dataset: {
			sourceCollection,
			targetCollection,
			fetch: "executePipeline",
			adminCallFromApp: activeRefines?.secLevelMenu,
			query
		},
		resetSkip: false
	}))
	.unwrap()
	.then((originalPromiseResult) => {
		if (originalPromiseResult.data.res.n) {
			console.log("Execution successful");
		} else {
			console.log("Not really successful");
		}
	})
	.catch((rejectedValueOrSerializedError) => {
		console.log("Execution error", rejectedValueOrSerializedError);
	});
};

export const clarityV4ReferentialCreateEntry = ({
	resWithoutId,
	setconfirmModal,
	dispatch,
	updateProject,
	setAlert,
	appSlice
}) => {
	dispatch(updateProject({
		appName: "admin",
		dataset: {
			collection: formatClarityV4Referential.sourceCollection,
			fetch: "insertOne",
			data: {
				...resWithoutId
			},
			adminCallFromApp: "clarity"
		},
		resetSkip: false
	}))
	.unwrap()
	.then((originalPromiseResult) => {
		if (originalPromiseResult.data.res.n) {
			console.log("ClarityV4Referential entry creation success");

			setconfirmModal(false);
			dispatch(appSlice.actions.clear([{ refine: "clarityV4Referential" }]));
		} else {
			setAlert({
				isDiplayed: true,
				msg: "ClarityV4Referential entry creation failed. If the issue persists, please contact the our digital team."
			});
		}
	})
	.catch((rejectedValueOrSerializedError) => {
		console.log("ClarityV4Referential entry creation error", rejectedValueOrSerializedError);
	});
};

export const clarityV4ReferentialUpdateEntry = ({
	res,
	resWithoutId,
	setconfirmModal,
	dispatch,
	updateProject,
	setAlert,
	appSlice
}) => {
	dispatch(updateProject({
		appName: "admin",
		dataset: {
			collection: formatClarityV4Referential.sourceCollection,
			fetch: "updateOne",
			data: {
				...resWithoutId
			},
			id: res._id,
			limit: 1,
			adminCallFromApp: "clarity",
			isObjectId: true
		},
		resetSkip: false
	}))
	.unwrap()
	.then((originalPromiseResult) => {
		if (originalPromiseResult.data.res.n) {
			console.log("ClarityV4Referential update success");

			setconfirmModal(false);
			dispatch(appSlice.actions.clear([{ refine: "clarityV4Referential" }]));
		} else {
			setAlert({
				isDiplayed: true,
				msg: "ClarityV4Referential update failed. If the issue persists, please contact the our digital team."
			});
		}
	})
	.catch((rejectedValueOrSerializedError) => {
		console.log("ClarityV4Referential update error", rejectedValueOrSerializedError);
	});
};

export const clarityV4ReferentialDeleteEntry = ({
	appSlice,
	dispatch,
	deleteManyDocs,
	activeRefines,
	deleteNodes,
	setAlert,
	setconfirmModal,
	onSuccessfulDelete
}) => {
	if (activeRefines.clarityV4Ref === "deleteEntry") {
		dispatch(appSlice.actions.clear([{ refine: "clarityV4Ref" }]));
	}

	dispatch(deleteManyDocs({
		appName: "admin",
		dataset: {
			collection: formatClarityV4Referential.sourceCollection,
			fetch: "deleteMany",
			id: deleteNodes,
			adminCallFromApp: "clarity"
		},
		resetSkip: false
	}))
	.unwrap()
	.then((originalPromiseResult) => {
		if (originalPromiseResult.data.res.n) {
			console.log("ClarityV4Referential entry was successfully deleted");
			onSuccessfulDelete();
			setconfirmModal(false);

			dispatch(appSlice.actions.refine([
				{ key: "clarityV4Ref", value: "deleteEntry" }]));
		} else {
			setAlert({
				isDiplayed: true,
				msg: "The entry deletion failed. If the issue persists, please contact the our digital team."
			});
		}
	})
	.catch((rejectedValueOrSerializedError) => {
		console.log("ClarityV4Referential entry deletion error", rejectedValueOrSerializedError);
	});
};

export const adminDomainUpdate = ({
	res,
	resWithoutId,
	appSlice,
	dispatch,
	replaceDoc,
	setconfirmModal,
	setAlert
}) => {
	// check data compliance first
	const shouldUpdate = appReplaceValidator();

	if (shouldUpdate) {
		dispatch(
			replaceDoc({
				appName: "admin",
				dataset: {
					collection: CLARITYDOMAIN,
					fetch: "replaceOne",
					data: { ...resWithoutId },
					id: res._id,
					adminCallFromApp: "",
					limit: 1,
					isObjectId: true
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Domain update success");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear([
					{ refine: "id" },
					{ refine: "domainsOptions" }
				]));
				dispatch(appSlice.actions.refine([
					{ key: "appAction", value: "readListDomains" },
					{ key: "thirdLevelMenu", value: "Clarity settings" },
					{ key: "fourthLevelMenu", value: "Domains options" }
				]));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The update of domain failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Domain update error", rejectedValueOrSerializedError);
		});
	}
};

export const adminTranslationUpdate = ({
	res,
	resWithoutId,
	appSlice,
	dispatch,
	replaceDoc,
	setconfirmModal,
	setAlert
}) => {
	// check data compliance first
	const shouldUpdate = appReplaceValidator();

	const currentDateAndTime = moment();

	if (shouldUpdate) {
		dispatch(
			replaceDoc({
				appName: "admin",
				dataset: {
					collection: CLARITYTRANSLATIONS,
					fetch: "replaceOne",
					data: { ...resWithoutId, updatedAt: currentDateAndTime.format("YYYY-MM-DD HH:mm:ss") },
					id: res._id,
					adminCallFromApp: "",
					limit: 1,
					isObjectId: false
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Translation update success");
				setconfirmModal(false);
				dispatch(appSlice.actions.clear([{ refine: "appAction" }, { refine: "id" }]));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The update of translations failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Translation update error", rejectedValueOrSerializedError);
		});
	}
};

export const adminTranslationCreate = ({
	resWithoutId,
	appSlice,
	dispatch,
	addOneDoc,
	setconfirmModal,
	setAlert,
	defaultTranslationData
}) => {
	// Dispatch triggers the data reload
	dispatch(appSlice.actions.clear([{ refine: "createTranslation" }]));
	// check data compliance first
	const shouldCreate = appCreateValidator();

	const currentDateAndTime = moment();

	const dataToPersist = { ...defaultTranslationData, _id: resWithoutId.language, updatedAt: currentDateAndTime.format("YYYY-MM-DD HH:mm:ss") };

	if (shouldCreate) {
		dispatch(
			addOneDoc({
				appName: "admin",
				dataset: {
					collection: "ClarityTranslations",
					fetch: "insertOne",
					data: { ...dataToPersist },
					adminCallFromApp: ""
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Translation create success");

				setconfirmModal(false);
				dispatch(appSlice.actions.refine([{ key: "appAction", value: "readClarityTranslations" },
					{ key: "fourthLevelMenu", value: "clarity translations" }]));
			} else {
				setAlert({
					isDiplayed: true,
					msg: "The creation of translation failed, please try again. If the issue persists, please contact the our digital team."
				});
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Translation create error", rejectedValueOrSerializedError);
		});

		// Translation can be enabled or disabled (active-inactive). The activity is tracked
		// in a seperate collection. When new translation is created we need to create the object
		// in ClarityTranslationsActivity too.
		const language = {
			_id: dataToPersist._id,
			active: false
		};

		dispatch(
			addOneDoc({
				appName: "admin",
				dataset: {
					collection: "ClarityTranslationsActivity",
					fetch: "insertOne",
					data: { ...language },
					adminCallFromApp: ""
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Translation activity create success");
			} else {
				console.log("Translation activity creation failed");
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Translation activity create error", rejectedValueOrSerializedError);
		});
	}
};

export const adminTranslationDelete = ({
	appSlice,
	dispatch,
	deleteManyDocs,
	activeRefines,
	deleteNodes,
	setAlert,
	setconfirmModal
}) => {
	if (activeRefines.clarityTranslation === "deleteEntry") {
		dispatch(appSlice.actions.clear([{ refine: "clarityTranslation" }]));
	}
	dispatch(deleteManyDocs({
		appName: "admin",
		dataset: {
			collection: "ClarityTranslations",
			fetch: "deleteMany",
			id: deleteNodes.translations,
			adminCallFromApp: "clarity",
			isObjectId: false
		},
		resetSkip: false
	}))
	.unwrap()
	.then((originalPromiseResult) => {
		if (originalPromiseResult.data.res.n) {
			console.log("Clarity translation entry was successfully deleted");
			setconfirmModal(false);

			dispatch(appSlice.actions.refine([
				{ key: "clarityTranslation", value: "deleteEntry" }]));
		} else {
			setAlert({
				isDiplayed: true,
				msg: "Translation deletion failed. If the issue persists, please contact the our digital team."
			});
		}
	})
	.catch((rejectedValueOrSerializedError) => {
		console.log("Translation  deletion error", rejectedValueOrSerializedError);
	});

	// Translation can be enabled or disabled (active-inactive). The activity is tracked
	// in a seperate collection. When new translation is deleted we need to delete the object
	// in ClarityTranslationsActivity too.
	dispatch(deleteManyDocs({
		appName: "admin",
		dataset: {
			collection: "ClarityTranslationsActivity",
			fetch: "deleteMany",
			id: deleteNodes.activities,
			adminCallFromApp: "clarity",
			isObjectId: false
		},
		resetSkip: false
	}))
	.unwrap()
	.then((originalPromiseResult) => {
		if (originalPromiseResult.data.res.n) {
			console.log("Clarity translation activity entry was successfully deleted");
		} else {
			console.log("Clarity translation activity deletion error");
		}
	})
	.catch((rejectedValueOrSerializedError) => {
		console.log("Clarity translation activity entry deletion error", rejectedValueOrSerializedError);
	});
};

export const adminTranslationActivityUpdate = ({
	res,
	resWithoutId,
	appSlice,
	dispatch,
	replaceDoc,
	activeRefines,
	setActivityToggleError
}) => {
	// check data compliance first
	const shouldUpdate = appReplaceValidator();

	if (activeRefines.clarityTranslation === "activityUpdate") {
		dispatch(appSlice.actions.clear([{ refine: "clarityTranslation" }]));
	}

	if (shouldUpdate) {
		dispatch(
			replaceDoc({
				appName: "admin",
				dataset: {
					collection: "ClarityTranslationsActivity",
					fetch: "replaceOne",
					data: { ...resWithoutId },
					id: res._id,
					adminCallFromApp: "",
					limit: 1,
					isObjectId: false
				},
				resetSkip: false
			})
		)
		.unwrap()
		.then((originalPromiseResult) => {
			if (originalPromiseResult.data.res.n) {
				console.log("Translation activity update success");
				// dispatch(appSlice.fetchDataset(CLARITYTRANSLATIONS));
				dispatch(appSlice.actions.refine([
					{ key: "clarityTranslation", value: "activityUpdate" }]));
			} else {
				setActivityToggleError(true);
			}
		})
		.catch((rejectedValueOrSerializedError) => {
			console.log("Translation activity update error", rejectedValueOrSerializedError);
		});
	}
};
