import DashboardIcon from "@mui/icons-material/Dashboard";
import MapIcon from "@mui/icons-material/Map";
import BusinessIcon from "@mui/icons-material/Business";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PinDropIcon from "@mui/icons-material/PinDrop";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import LinkIcon from "@mui/icons-material/Link";

import getSupplyRchainChildren from "../../components/tree/SupplyRSupplychainChildren";
import getClaritychainChildren from "../../components/tree/ClaritySupplychainChildren";
import SupplyRTreeTooltipSupplier from "../../components/cards/SupplyRTreeTooltipSupplier";

/**
 * Set up  menu options to display
 *
 * @param {String} name Name of the Application displayed on the front page
 * @param {String} subname SubName of the Application displayed on the front page
 * @param {String} slice Name of the slice related to the app created and references in the store ./store.js
 * @param {String} logo Label of the menu, if none, only the picto
 */
export const appDefinition = {
	name: "ATAGLANCE",
	subName: "",
	launcherID: "ATAGLANCE",
	slice: "ataglance",
	locales: "translationsAtAGlance",
	theme: "compass",
	favicon: "/images/AtAGlance/favicon.ico",
	logo: "/images/AtAGlance/AtAGlanceSideBarBright.png",
	clientAccessor: "projectName"
};

/**
 * Set up  menu options to display
 *
 * @param {String} menu ID of the menu (use for the initial menu definition and the render of the app)
 * @param {String} type Route or App = Route is a link to jump to a new url, App is a internal link to the App to display specific information
 * @param {String} url [OPTIONAL] If type == url, the new url to link the menu (it has to be routed in ./App.js)
 * @param {String} label [OPTIONAL] Label of the menu, if none, only the picto
 * @param {String} sectionTitleWhite [OPTIONAL] Section title in white displayed on top of the page when the menu is clicked
 * @param {String} sectionTitleBlack [OPTIONAL] Section title in black displayed on top of the page when the menu is clicked
 * @param {Component} picto Material UI Icon to represent the field in the select, to be imported in this file
 */
export const menuOptions = [
	{
		menu: "HOME",
		type: "App",
		initial: true,
		label: "Home",
		clearRefine: ["module", "siteId", "critical"],
		picto: DashboardIcon
	},
	{
		menu: "MAP",
		type: "App",
		initial: true,
		label: "Map",
		clearRefine: ["siteId", "critical"],
		picto: MapIcon
	},
	{
		menu: "SC",
		type: "App",
		label: "My Supply Chain",
		noMobileMenu: true,
		picto: LinkIcon
	}
	// {
	// 	menu: "NCs",
	// 	type: "App",
	// 	initial: true,
	// 	label: "Nonconformities",
	// 	clearRefine: ["siteId", "critical"],
	// 	picto: FlagOutlinedIcon
	// },
	// {
	// 	menu: "SITEs",
	// 	type: "App",
	// 	initial: true,
	// 	label: "Your Sites",
	// 	clearRefine: ["critical"],
	// 	picto: PinDropIcon
	// }
	// ,
	// {
	//	menu: "INFO",
	//	type: "App",
	//	label: "Info",
	//	noMobileMenu: true,
	//	picto: ContactSupportIcon
	// }
];

/**
 * Name of the dataset with the client parameters
 */
const CompassClientParameters = "CompassClientParameters";

/**
 * Name of the dataset Form for the  web app
 */
const CompassModuleAggregates = "CompassModuleAggregates";

/**
 * Name of the dataset Checkpoint for the web app
 */
export const CompassCheckpoints = "CompassCheckpoints";

/**
  * Query to load and update the non conformities
  */
export const listNonConformityQuery = [
	{
		$match: {
			nc: { $ne: 0 }
		}
	},
	{
		$project: {
			publicationDate: 1,
			question: 1,
			answer: "$rating",
			observation: "$observation",
			auditedCompany: "$site.auditedCompany",
			siteType: "$additionalInfo.siteType",
			critical: 1,
			module: 1,
			criticality: { $cond: [{ $eq: ["$critical", true] }, "Critical", "Non Critical"] },
			productCategory: "$additionalInfo.productCategoryFilter",
			country: "$site.country",
			city: "$site.country",
			section1Label: 1
		}
	}
];

/**
 * Array of the full list of facets to refine
 */
const listFacetsToRefine = [
	"projectName",
	"additionalInfo.finalRating",
	"additionalInfo.siteType",
	"additionalInfo.productCategoryFilter",
	"module",
	"site.region",
	"site.country",
	"siteUniqueId",
	"supplier",
	"product",
	"type",
	"tierLevel",
	"country"
];

/**
 * Client parameters
 */
export const clientInitParameters = {
	name: "clientParameters",
	collection: "AtAGlanceClientParameters",
	facetsToRefine: ["projectName"],
	fetch: "find",
	limit: 1
};

/**
 * Function to refresh the list of nonConformities after a click on a new page
 *
 * @param {Number} value Page to refresh the list of sites
 */
export const updateListNonConformities = (dataset, limit, value) => ({
	name: dataset,
	collection: CompassCheckpoints,
	fetch: "aggregation",
	query: listNonConformityQuery,
	facetsToRefine: listFacetsToRefine.concat("critical"),
	limit,
	skip: ((value - 1) * limit)
});

/**
  * Active DataSets definition for the app
  *
  * @param {String} name
  * @param {String} collection
  * @param {String} fetch
  * @param {String[]} facetsToRefine
  * @param {Object[]} query
  * @param {Number} limit
  * @param {Object[]} data
  */
export const atAGlanceInitDatasets = {
	listClients: {
		collection: "AtAGlanceClientParameters",
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {
						projectName: "$projectName",
						project: "$project"
					},
					nbClients: { $sum: 1 }
				}
			},
			{
				$project: {
					projectName: "$_id.projectName",
					project: "$_id.project",
					nbClients: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listFinalRatings: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "additionalInfo.finalRating"),
		query: [
			{
				$group: {
					_id: {
						finalRating: "$additionalInfo.finalRating"
					},
					nbModules: { $sum: 1 }
				}
			},
			{
				$project: {
					additionalInfo: {
						finalRating: "$_id.finalRating"
					},
					nbModules: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listModules: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "module"),
		query: [
			{
				$group: {
					_id: {
						module: "$module"
					},
					nbModules: { $sum: 1 }
				}
			},
			{
				$project: {
					module: "$_id.module",
					nbModules: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listProducts: {
		collection: "SupplyRChainScores",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "product" && element !== "finalProduct"),
		query: [
			{
				$project: {
					"option.label": "$product",
					"option.value": "$product",
					productFamilyCp: "$productFamilyCp"
				}
			}, {
				$group: {
					_id: "$productFamilyCp",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					refine: "finalProduct"
				}
			}
		],
		limit: 500
	},
	listTypes: {
		collection: "AtAGlance-ToR",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "type"),
		query: [
			{
				$group: {
					_id: {
						type: "$type"
					},
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					type: "$_id.type",
					nbSites: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listTiers: {
		collection: "AtAGlance-ToR",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "tierLevel"),
		query: [
			{
				$group: {
					_id: {
						tierLevel: "$tierLevel"
					},
					tierLabel: {
						$first: "$supplyR.tierLabel"
					},
					nbSites: {
						$sum: 1
					}
				}
			}, {
				$project: {
					tierLevel: "$_id.tierLevel",
					tierLabel: "$tierLabel",
					nbSites: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listGeographies: {
		collection: "AtAGlance-ToR",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "country" &&
			element !== "region"),
		query: [
			{
				$project: {
					"option.label": "$country",
					"option.value": "$country",
					region: "$region"
				}
			}, {
				$group: {
					_id: "$region",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					refine: "region"
				}
			}, {
				$match: {
					_id: {
						$ne: "undefined"
					}
				}
			}
		],
		limit: 500
	},
	listEntities: {
		collection: "AtAGlance-ToR",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$project: {
					"option.label": "$auditedCompany",
					"option.value": "$siteUniqueId",
					"option.city": "$city",
					"option.country": "$country",
					"option.product": "$product",
					supplier: "$supplier"
				}
			}, {
				$group: {
					_id: "$supplier",
					options: {
						$addToSet: "$option"
					}
				}
			}, {
				$addFields: {
					label: "$_id",
					value: "$_id",
					supplier: "$_id",
					refine: "supplier"
				}
			}, {
				$match: {
					_id: {
						$ne: "undefined"
					}
				}
			}
		],
		limit: 500
	},
	numberOfAssessmentsPerSupplier: {
		collection: "SupplyRChainScores",
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$lookup: {
					from: "ClarityChainScores",
					localField: "siteUniqueId",
					foreignField: "siteUniqueId",
					as: "clarityChainScores"
				}
			}, {
				$unwind: {
					path: "$clarityChainScores"
				}
			}, {
				$group: {
					_id: "$supplier",
					companiesPerSupplier: {
						$sum: 1
					},
					auditedCompanies: {
						$addToSet: "$siteUniqueId"
					},
					"Supply-R": {
						$sum: {
							$cond: [
								{
									$lte: [
										"$resilience", null
									]
								}, 0, 1
							]
						}
					},
					Clarity: {
						$sum: {
							$cond: [
								{
									$lte: [
										"$clarityChainScores.siteScore", null
									]
								}, 0, 1
							]
						}
					}
				}
			}
		],
		limit: 1000
	},
	listCountries: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "site.country"),
		query: [
			{
				$group: {
					_id: {
						country: "$site.country"
					},
					nbCountries: { $sum: 1 }
				}
			},
			{
				$project: {
					site: {
						country: "$_id.country"
					},
					nbCountries: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listRegions: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((element) => element !== "site.region"),
		query: [
			{
				$group: {
					_id: {
						region: "$site.region"
					},
					nbAssessments: { $sum: 1 }
				}
			},
			{
				$project: {
					site: {
						region: "$_id.region"
					},
					nbAssessments: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listSites: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						siteId: "$siteId",
						auditedCompany: "$site.auditedCompany"
					},
					nbSites: { $sum: 1 }
				}
			},
			{
				$project: {
					siteId: "$_id.siteId",
					auditedCompany: "$_id.auditedCompany",
					nbSites: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	listCriticalities: {
		collection: CompassCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						critical: { $ifNull: ["$critical", false] },
						criticality: { $cond: [{ $eq: ["$critical", true] }, "Critical", "Non Critical"] }
					},
					nbQuestions: { $sum: 1 }
				}
			},
			{
				$project: {
					critical: "$_id.critical",
					criticality: "$_id.criticality",
					nbQuestions: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	supplyRSupplyChain: {
		collection: "SupplyRChainScores",
		pages: ["SC", "MAP"],
		fetch: "find",
		facetsToRefine: ["projectName"],
		query: [],
		limit: 5000,
		refreshed: true
	},
	claritySupplyChain: {
		collection: "ClarityChainScores",
		pages: ["SC"],
		fetch: "find",
		facetsToRefine: ["projectName"],
		query: [],
		limit: 5000,
		refreshed: true
	},
	supplyRChainActiveFinalProducts: {
		collection: "SupplyRChainScores",
		pages: ["SC"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			},
			{
				$project: {
					finalProduct: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 500
	},
	clarityChainActiveFinalProducts: {
		collection: "ClarityChainScores",
		pages: ["SC"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						finalProduct: "$finalProduct"
					}
				}
			},
			{
				$project: {
					finalProduct: "$_id.finalProduct",
					_id: 0
				}
			}
		],
		limit: 500
	},
	modulesRatings: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						module: "$module",
						finalRating: "$additionalInfo.finalRating"
					},
					totalAssignments: { $sum: 1 },
					uniqueSites: { $addToSet: "$siteId" },
					moduleNbNC: {
						$sum: "$nc"
					},
					moduleNbCNC: {
						$sum: "$cnc"
					}
				}
			},
			{
				$project: {
					module: "$_id.module",
					additionalInfo: {
						finalRating: "$_id.finalRating"
					},
					totalAssignments: 1,
					uniqueSites: 1,
					moduleNbNC: 1,
					moduleNbCNC: 1,
					_id: 0
				}
			}
		],
		limit: 50
	},
	listSiteTypes: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.filter((e) => e !== "additionalInfo.siteType"),
		query: [{
			$group: {
				_id: {
					siteType: "$additionalInfo.siteType"
				},
				nbAssessments: {
					$sum: 1
				}
			}
		},
		{
			$project: {
				additionalInfo: {
					siteType: "$_id.siteType"
				},
				nbAssessments: 1,
				_id: 0
			}
		}
		],
		limit: 10
	},
	scope: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						siteType: "$additionalInfo.siteType"
					},
					nbAssessments: { $sum: 1 }
				}
			},
			{
				$project: {
					additionalInfo: {
						siteType: "$_id.siteType"
					},
					nbAssessments: 1,
					_id: 0
				}
			}
		],
		limit: 10
	},
	sites: {
		collection: CompassModuleAggregates,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$project: {
					auditedCompany: "$site.auditedCompany",
					geometry: "$site.geometry",
					publicationDate: 1,
					site: {
						city: "$site.city",
						country: "$site.country",
						address: "$site.address"
					},
					additionalInfo: {
						siteType: "$additionalInfo.siteType",
						productCategoryFilter: "$additionalInfo.productCategoryFilter",
						finalRating: "$additionalInfo.finalRating"
					},
					siteId: 1,
					nc: 1,
					cnc: 1
				}
			}
		],
		limit: 1000
	},
	supplyRallSites: {
		collection: "SupplyRChainScores",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId"
					},
					project: { $first: "$project" },
					spaceId: { $first: "$spaceId" },
					supplier: { $first: "$supplier" },
					siteId: { $first: "$siteId" },
					auditedCompany: { $first: "$auditedCompany" },
					address: { $first: "$address" },
					country: { $first: "$country" },
					city: { $first: "$city" },
					resilience: { $avg: "$resilience" },
					resistance: { $avg: "$resistance" },
					responsiveness: { $avg: "$responsiveness" },
					sumNbOpenedNc: { $sum: "$sumNbOpenedNc" },
					product: { $addToSet: "$product" },
					module: { $addToSet: "$module" },
					resilienceScores: { $addToSet: "$resilienceScores" },
					report: { $addToSet: "$report" },
					geometry: { $first: "$geometry" }
				}
			}
		],
		limit: 1000
	},
	clarityallSites: {
		collection: "ClarityChainScores",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat(["marketSegment"]),
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$project: {
					siteId: 1,
					auditedCompany: "$company",
					siteType: 1,
					address: 1,
					country: 1,
					region: 1,
					city: 1,
					siteScore: 1,
					sumNbOpenedNc: 1,
					sumNbCnc: 1,
					domain: 1,
					domainScores: 1,
					product: 1,
					report: 1,
					marketSegment: 1,
					geometry: 1
				}
			}, {
				$addFields: {
					domains: {
						$reduce: {
							input: "$domain",
							initialValue: "",
							in: {
								$concat: [
									"$$value", "$$this", "-"
								]
							}
						}
					}
				}
			}
		],
		limit: 2000
	},
	// Responsible for color layer on Clarity map. ProjectName is the only refine needed here.
	clarityCountries: {
		collection: "ClarityCountryScores",
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true
				}
			},
			{
				$project: {
					region: 1,
					country: 1,
					countryScore: 1,
					sumNbOpenedNc: 1,
					domain: 1,
					domainScores: 1,
					geometry: 1
				}
			}
		],
		limit: 1000
	},
	// Used for map of Supply-R
	mapSupplyChainSiteId: {
		collection: "SupplyRChainScores",
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {
						siteId: "$siteId"
					}
				}
			},
			{
				$project: {
					siteId: "$_id.siteId",
					_id: 0
				}
			}
		],
		limit: 5000
	},
	sectionNCsList: {
		collection: CompassCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine,
		query: [{
			$group: {
				_id: {
					section1Label: "$section1Label",
					section2Label: "$section2Label"
				},
				nc: {
					$sum: "$nc"
				},
				cnc: {
					$sum: "$cnc"
				}
			}
		}, {
			$project: {
				section1Label: "$_id.section1Label",
				section2Label: "$_id.section2Label",
				nc: 1,
				cnc: 1,
				_id: 0
			}
		}],
		limit: 500
	},
	listNonConformities: {
		collection: CompassCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat("critical"),
		query: listNonConformityQuery,
		skip: 0,
		limit: 10
	},
	nonConformities: {
		collection: CompassCheckpoints,
		fetch: "aggregation",
		facetsToRefine: listFacetsToRefine.concat("critical"),
		query: [
			{
				$group: {
					_id: {
						year: { $year: "$publicationDate" },
						month: { $month: "$publicationDate" },
						siteId: "$siteId"
					},
					nbNC: { $sum: "$nc" },
					nbCNC: { $sum: "$cnc" },
					nbSites: { $sum: 1 }
				}
			},
			{
				$group: {
					_id: {
						year: "$_id.year",
						month: "$_id.month"
					},
					nbSites: { $sum: 1 },
					sites: { $addToSet: "$_id.siteId" },
					nbNC: { $sum: "$nbNC" },
					nbCNC: { $sum: "$nbCNC" }
				}
			},
			{
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					sites: 1,
					nbSites: 1,
					nbNC: 1,
					nbCNC: 1,
					_id: 0
				}
			}
		],
		limit: 1000
	},
	clarityMaturityIndex: {
		collection: "ClarityChainScores",
		callFromApp: "clarity",
		facetsToRefine: listFacetsToRefine,
		fetch: "aggregation",
		query: [
			{
				$match: {
					siteScore: {
						$ne: null
					}
				}
			}, {
				$project: {
					projectName: 1,
					campaignId: 1,
					domain: 1,
					siteId: 1,
					sumNbCnc: 1,
					domainScoresAVG: {
						$avg: "$domainScores"
					}
				}
			}, {
				$group: {
					_id: {
						projectName: "$projectName",
						campaignId: "$campaignId"
					},
					score: {
						$avg: "$domainScoresAVG"
					},
					domains: {
						$addToSet: "$domain"
					},
					nbSites: {
						$addToSet: "$siteId"
					},
					nbCNC: {
						$sum: "$sumNbCnc"
					}
				}
			}, {
				$addFields: {
					domains: {
						$arrayElemAt: [
							"$domains", 0
						]
					}
				}
			}, {
				$project: {
					projectName: "$_id.projectName",
					module: "Maturity Index",
					campaignId: "$_id.campaignId",
					score: {
						$multiply: [
							"$score", 100
						]
					},
					domains: 1,
					nbSites: 1,
					nbCNC: 1,
					_id: 0
				}
			}
		],
		refresh: true
	},
	clarityModulesScoresBis: {
		collection: "ClarityChainScores",
		callFromApp: "clarity",
		facetsToRefine: listFacetsToRefine,
		fetch: "aggregation",
		query: [
			{
				$lookup: {
					from: "ClarityAssignmentScores",
					localField: "siteId",
					foreignField: "siteId",
					as: "clarityAssignmentScores"
				}
			}, {
				$addFields: {
					scoresPerDomain: {
						$zip: {
							inputs: [
								"$domain", "$domainScores"
							],
							useLongestLength: true
						}
					}
				}
			}, {
				$unwind: {
					path: "$scoresPerDomain"
				}
			}, {
				$group: {
					_id: {
						domain: {
							$arrayElemAt: [
								"$scoresPerDomain", 0
							]
						},
						campaignId: "$campaignId"
					},
					avgScore: {
						$avg: {
							$arrayElemAt: [
								"$scoresPerDomain", 1
							]
						}
					},
					uniqueSites: {
						$addToSet: "$siteId"
					},
					clarityAssignmentScores: {
						$push: "$clarityAssignmentScores"
					}
				}
			}, {
				$addFields: {
					filteredAssignmentScores: {
						$map: {
							input: "$clarityAssignmentScores",
							as: "scores",
							in: {
								$filter: {
									input: "$$scores",
									as: "sc",
									cond: {
										$and: [
											{
												$eq: [
													"$$sc.domain", "$_id.domain"
												]
											}, {
												$eq: [
													"$$sc.campaignId", "$_id.campaignId"
												]
											}
										]
									}
								}
							}
						}
					}
				}
			}, {
				$addFields: {
					filteredAssignmentScores: {
						$map: {
							input: "$filteredAssignmentScores",
							as: "scores",
							in: {
								$arrayElemAt: [
									"$$scores", 0
								]
							}
						}
					}
				}
			}, {
				$addFields: {
					moduleNbNC: {
						$sum: {
							$map: {
								input: "$filteredAssignmentScores",
								as: "scores",
								in: "$$scores.sumNbOpenedNc"
							}
						}
					},
					moduleNbCNC: {
						$sum: {
							$map: {
								input: "$filteredAssignmentScores",
								as: "scores",
								in: "$$scores.sumNbCnc"
							}
						}
					}
				}
			}, {
				$project: {
					domain: "$_id.domain",
					campaignId: "$_id.campaignId",
					moduleScore: "$avgScore",
					uniqueSites: 1,
					moduleNbNC: 1,
					moduleNbCNC: 1
				}
			}
		]
	},
	clarityClientParameters: {
		collection: "ClarityClientParameters",
		callFromApp: "clarity",
		facetsToRefine: ["projectName"],
		fetch: "find"
	},
	clarityNonConformities: {
		collection: "ClarityCheckpoints",
		callFromApp: "clarity",
		// pages: ["HOME", "HOME/DOMAINs", "HOME/NETZERO", "SDGs", "SDGs/SDG", "NCs", "HOME/SITE", "SITEs/SITE", "DISTRIB/SITE"],
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		// dateToTransform: { date: "publicationDate" },
		query: [
			{
				$match: {
					last: true,
					maturity: {
						$nin: [
							"Informative", "N/A", "NA", null
						]
					}
				}
			},
			{
				$group: {
					_id: {
						year: {
							$year: "$publicationDate"
						},
						month: {
							$month: "$publicationDate"
						},
						siteId: "$siteId",
						campaignId: "$campaignId"
					},
					nbNC: {
						$sum: "$nbOpenedNc"
					},
					nbCNC: {
						$sum: "$nbCnc"
					}
				}
			}, {
				$group: {
					_id: {
						year: "$_id.year",
						month: "$_id.month",
						campaignId: "$_id.campaignId"
					},
					sites: {
						$addToSet: "$_id.siteId"
					},
					nbNC: {
						$sum: "$nbNC"
					},
					nbCNC: {
						$sum: "$nbCNC"
					}
				}
			}, {
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					sites: 1,
					nbSites: {
						$size: "$sites"
					},
					nbNC: 1,
					nbCNC: 1,
					campaignId: "$_id.campaignId",
					_id: 0
				}
			}
		]
	},
	supplyRScores: {
		collection: "SupplyRChainScores",
		callFromApp: "supplyr",
		facetsToRefine: ["projectName"],
		fetch: "aggregation",
		query: [
			{
				$group: {
					_id: {},
					resilience: {
						$avg: "$resilience"
					},
					resistance: {
						$avg: "$resistance"
					},
					responsiveness: {
						$avg: "$responsiveness"
					}
				}
			},
			{
				$project: {
					_id: 0,
					resilienceScore: "$resilience",
					resistanceScore: "$resistance",
					responsivenessScore: "$responsiveness"
				}
			}
		],
		limit: 500
	},
	supplyRScoresModule: {
		collection: "SupplyRChainScores",
		fetch: "aggregation",
		callFromApp: "supplyr",
		facetsToRefine: listFacetsToRefine,
		query: [
			{
				$group: {
					_id: {},
					resilience: {
						$avg: "$resilience"
					},
					resistance: {
						$avg: "$resistance"
					},
					responsiveness: {
						$avg: "$responsiveness"
					},
					nbCNC: {
						$sum: "$sumNbCnc"
					}
				}
			}, {
				$match: {
					resilience: {
						$ne: null
					}
				}
			}, {
				$project: {
					_id: 0,
					score: "$resilience",
					resistanceScore: "$resistance",
					responsivenessScore: "$responsiveness",
					nbCNC: 1
				}
			}
		],
		limit: 500,
		refresh: true
	},
	supplyRNonConformities: {
		collection: "SupplyRCheckpoints",
		callFromApp: "supplyr",
		facetsToRefine: ["projectName"],
		fetch: "aggregation",
		query: [
			{
				$match: {
					last: true
				}
			}, {
				$group: {
					_id: {
						year: { $year: "$publicationDate" },
						month: { $month: "$publicationDate" },
						siteId: "$siteId"
					},
					nbNC: { $sum: "$nbOpenedNc" },
					nbCNC: { $sum: "$nbCnc" },
					nbSites: { $sum: 1 }
				}
			},
			{
				$group: {
					_id: {
						year: "$_id.year",
						month: "$_id.month"
					},
					sites: { $addToSet: "$_id.siteId" },
					nbSites: { $sum: 1 },
					nbNC: { $sum: "$nbNC" },
					nbCNC: { $sum: "$nbCNC" }
				}
			},
			{
				$project: {
					year: "$_id.year",
					month: "$_id.month",
					sites: 1,
					nbSites: 1,
					nbNC: 1,
					nbCNC: 1,
					_id: 0
				}
			}
		]
	},
	modulesDistribution: {
		collection: "SupplyRChainScores",
		callFromApp: "supplyr",
		facetsToRefine: listFacetsToRefine,
		fetch: "aggregation",
		query: [
			{
				$lookup: {
					from: "SupplyRAssignmentScores",
					localField: "siteId",
					foreignField: "siteId",
					as: "supplyRAssignmentScores"
				}
			}, {
				$addFields: {
					supplyRAssignmentScores: {
						$filter: {
							input: "$supplyRAssignmentScores",
							as: "score",
							cond: {
								$and: [
									{
										$eq: [
											"$$score.last", true
										]
									}, {
										$eq: [
											"$$score.finalProduct", "$finalProduct"
										]
									}
								]
							}
						}
					}
				}
			}, {
				$unwind: {
					path: "$supplyRAssignmentScores"
				}
			}, {
				$group: {
					_id: {
						module: "$supplyRAssignmentScores.module"
					},
					auditType: {
						$first: "$supplyRAssignmentScores.auditType"
					},
					auditMode: {
						$first: "$supplyRAssignmentScores.auditMode"
					},
					resilienceAvg: {
						$avg: "$supplyRAssignmentScores.resilience"
					},
					resistanceAvg: {
						$avg: "$supplyRAssignmentScores.resistance"
					},
					responsivenessAvg: {
						$avg: "$supplyRAssignmentScores.responsiveness"
					},
					reports: {
						$addToSet: "$supplyRAssignmentScores.assignmentTechnicalId"
					},
					sumNbCnc: {
						$sum: "$supplyRAssignmentScores.sumNbCnc"
					},
					sumNbOpenedNc: {
						$sum: "$supplyRAssignmentScores.sumNbOpenedNc"
					},
					uniqueSites: {
						$addToSet: "$supplyRAssignmentScores.siteId"
					}
				}
			}, {
				$project: {
					key: "module",
					module: "$_id.module",
					auditType: 1,
					auditMode: 1,
					reports: 1,
					score: "$resilienceAvg",
					resistance: "$resistanceAvg",
					responsiveness: "$responsivenessAvg",
					nbCNC: "$sumNbCnc",
					nbNC: {
						$subtract: [
							"$sumNbOpenedNc", "$sumNbCnc"
						]
					},
					uniqueSites: 1,
					nbSites: {
						$size: "$uniqueSites"
					},
					_id: 0
				}
			}
		],
		limit: 500
	},
	supplyRSitesCount: {
		collection: "SupplyRChainScores",
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId"
					}
				}
			}
		],
		limit: 1000
	},
	supplyRSitesDistribution: {
		collection: "SupplyRChainScores",
		fetch: "aggregation",
		facetsToRefine: ["projectName", "module", "tierLevel", "type", "finalProduct",
			"product", "region", "country", "supplier", "siteId", "supplierCriticality"],
		query: [
			{
				$match: {
					resilience: { $exists: true }
				}
			},
			{
				$group: {
					_id: {
						supplier: "$supplier",
						siteId: "$siteId",
						auditedCompany: "$auditedCompany"
					},
					resilienceScore: {
						$avg: "$resilience"
					}
				}
			},
			{
				$project: {
					key: "sites",
					supplier: "$_id.supplier",
					siteId: "$_id.siteId",
					auditedCompany: "$_id.auditedCompany",
					resilienceScore: 1,
					_id: 0
				}
			}
		],
		limit: 500
	},
	clarityAssessementsParCampaign: {
		collection: "ClaritySiteScoresAfterSites",
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$group: {
					_id: {
						campaign: "$campaignId"
					},
					sitesPerCampaign: {
						$sum: 1
					},
					assessed: {
						$sum: {
							$cond: [
								{
									$eq: ["$state", "ASSESSED"]
								},
								1,
								0
							]
						}
					}
				}
			},
			{
				$sort:
				{
					"_id.campaign": -1
				}
			}
		]
	},
	// Used to translate siteUniqueId to siteId of Clarity and Supply-R
	siteIDs: {
		collection: "AtAGlance-ToR",
		fetch: "aggregation",
		facetsToRefine: ["projectName"],
		query: [
			{
				$lookup: {
					from: "SupplyRChainScores",
					localField: "siteLabelId",
					foreignField: "siteLabelId",
					as: "supplyRData"
				}
			}, {
				$addFields: {
					supplyRData: {
						$arrayElemAt: [
							"$supplyRData", 0
						]
					}
				}
			}, {
				$addFields: {
					supplyRSiteId: "$supplyRData.siteId"
				}
			}, {
				$project: {
					siteId: 1,
					siteUniqueId: 1,
					supplyRSiteId: 1,
					supplier: 1
				}
			}
		]
	}
};

/**
 * SUPPLYR DOMAINS TWEAK
 */
export const supplyRDomainsOptions = {
	"Business Continuity": {
		locale: "businessContinuity",
		color: "#5963B7",
		logo: "/images/Clarity/Modules/icn-performance-business.svg"
	},
	"Data Integrity": {
		locale: "dataIntegrity",
		color: "#44B9B4",
		logo: "/images/Clarity/Modules/icn-cyber-data.svg"
	},
	"HSE Resistance": {
		locale: "HSEResistance",
		color: "#FF475C",
		logo: "/images/Clarity/Modules/icn-social-community.svg"
	},
	"Information Security": {
		locale: "informationSecurity",
		color: "#5963B7",
		logo: "/images/Clarity/Modules/icn-cyber-info.svg"
	},
	"Logistics & Inventory Practices": {
		locale: "logisticsInventoryPractices",
		color: "#CCDB73",
		logo: "/images/Clarity/Modules/icn-performance-customer.svg"
	},
	"Supplier Technical Assessment": {
		locale: "supplierTechnicalAssessment",
		color: "#FF475C",
		logo: "/images/Clarity/Modules/icn-performance-asset.svg"
	},
	"Food safety": {
		locale: "foodSafety",
		color: "#5963B7",
		logo: "/images/Clarity/Modules/icn-health-safety.svg"
	},
	"Business Governance & Social Engagement": {
		locale: "businessGovernance",
		color: "#44B9B4",
		logo: "/images/Clarity/Modules/icn-social-working.svg"
	}
};

/**
 * Init Refine of the Application
 *
 * @param {Object} compassInitRefines
 * Object representing the initial refine of the datasets
 * in the App slice, the key being the name of the field
 * in the DB Collection to filter on, the value being the value to filter on
 */
export const atAGlanceInitRefines = {};

// Define the MapToolTip
export const compassMapTooltip = [
	{ label: "Audited Company", key: "auditedCompany" },
	{ label: "Final Rating", key: "additionalInfo.finalRating" }
];

/**
 * Set up refine filter options to display
 *
 * @param {String} refine name of the field in the DB Collection to filter on
 * @param {String} loadFromDataset name of the collection to filter on the field
 * @param {String} placeHolder [OPTIONAL] text when nothing is selected
 * @param {Component} tag Material UI Icon to represent the field in the select, to be imported in this file
 * @param {Boolean} clear will be cleared if the button clear is pushed
 * @param {Boolean} toClearOnChange execute a clear of other clearable fields (see above) if the value has changed
 */
export const filterOptions = [
	{
		refine: "projectName",
		// displayField: "client",
		label: "Client Projects",
		loadFromDataset: "listClients",
		placeHolder: "Clients",
		isMulti: false,
		isSearchable: false,
		hiddenIfUnique: true,
		clear: false,
		toClearOnChange: true
	},
	// {
	// 	label: "Final Rating",
	// 	refine: "additionalInfo.finalRating",
	// 	loadFromDataset: "listFinalRatings",
	// 	placeHolder: "filters.ratings",
	// 	isMulti: true,
	// 	isClearable: true,
	// 	isSearchable: false,
	// 	tag: StarHalfIcon,
	// 	clear: true,
	// 	toClearOnChange: false
	// },
	{
		refine: "product",
		loadFromDataset: "listProducts",
		placeHolder: "filters.products",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: true,
		isGrouped: true,
		clear: true,
		toClearOnChange: false,
		groupSelection: false
	},
	{
		refine: "type",
		loadFromDataset: "listTypes",
		placeHolder: "filters.type",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isClearable: false,
		clear: true,
		toClearOnChange: false
	},
	{
		refine: "tierLevel",
		loadFromDataset: "listTiers",
		placeHolder: "filters.tier",
		displayField: "tierLabel",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: false,
		isClearable: false,
		clear: true,
		toClearOnChange: false
	},
	// {
	// 	label: "Modules",
	// 	refine: "module",
	// 	loadFromDataset: "listModules",
	// 	placeHolder: "filters.modules",
	// 	isMulti: false,
	// 	isClearable: true,
	// 	isSearchable: false,
	// 	tag: VerifiedUserIcon,
	// 	clear: true,
	// 	toClearOnChange: false,
	// 	hiddenIfUnique: true
	// },
	// {
	// 	label: "Regions",
	// 	refine: "site.region",
	// 	loadFromDataset: "listRegions",
	// 	placeHolder: "filters.regions",
	// 	isMulti: true,
	// 	isClearable: true,
	// 	isSearchable: true,
	// 	tag: PinDropOutlinedIcon,
	// 	clear: true,
	// 	toClearOnChange: false,
	// 	hiddenIfUnique: true
	// },
	{
		refine: "country",
		loadFromDataset: "listGeographies",
		placeHolder: "filters.geographies",
		manualRenderOption: true,
		isMulti: true,
		isSearchable: true,
		isGrouped: true,
		clear: true,
		toClearOnChange: false,
		groupSelection: false
	},
	{
		label: "Supplier",
		refine: "siteUniqueId",
		refineGroup: "supplier",
		loadFromDataset: "listEntities",
		placeHolder: "filters.searchForASite",
		manualPosition: true,
		// manualRenderOption: true,
		isSearchable: true,
		isClearable: true,
		isGrouped: true,
		tag: BusinessIcon,
		clear: true,
		toClearOnChange: false
	}
	// {
	// 	label: "Site",
	// 	refine: "siteId",
	// 	displayField: "auditedCompany",
	// 	loadFromDataset: "listSites",
	// 	placeHolder: "filters.searchForASite",
	// 	isClearable: true,
	// 	isSearchable: true,
	// 	tag: BusinessIcon,
	// 	clear: true,
	// 	toClearOnChange: false,
	// 	manualPosition: true
	// }
	// {
	// 	label: "Criticality",
	// 	refine: "critical",
	// 	displayField: "criticality",
	// 	loadFromDataset: "listCriticalities",
	// 	placeHolder: "filters.NCLevel",
	// 	isClearable: true,
	// 	isSearchable: false,
	// 	clear: true,
	// 	toClearOnChange: false,
	// 	manualPosition: true
	// }
];

export const ratingsConfig = [
	{ rating: "Satisfactory", color: "#6DC3A0" },
	{ rating: "Needs Refinement", color: "#6BD0FF" },
	{ rating: "Needs Improvement", color: "#FF9900" },
	{ rating: "Conditionally Approved", color: "#FF475C" },
	{ rating: "Not approved", color: "#FF475C" }
];

/**
 * Define the rule for the Marker color on the map
 */
export const getMarkerColor = (finalRating) => {
	if (!finalRating) return "#c9bbbd";
	return ratingsConfig.find((element) => finalRating === element.rating)?.color;
};

/**
 * Define the color code and name for non conformities
 */
export const nonConformities = { Critical: "#FF475C", "Non Critical": "#FFC362" };

/**
 * Array of tags to be displayed for each non conformities
 */
export const listTagsNonConformities = {
	auditedCompany: {
		accessor(d, t) { return d.auditedCompany; },
		picto(d) { return BusinessIcon; },
		refine: [{ key: "siteId", valueKey: (d) => d.siteId }]
	},
	location: {
		accessor(d, t) { return ((d.city && `${d.city}-`) + d.country); },
		picto(d) { return PinDropIcon; }
	},
	siteType: {
		accessor(d, t) { return d.siteType; },
		picto(d) { return CompareArrowsIcon; }
	},
	module: {
		accessor(d, t) { return d.module; },
		picto(d) { return VerifiedUserIcon; }
	},
	section: {
		accessor(d, t) { return d.section1Label; },
		picto(d) { return VerticalSplitOutlinedIcon; }
	},
	product: {
		accessor(d, t) { return d.productCategory.map((element, i) => (element) + ((i < (d.productCategory.length - 1)) ? "," : "")); },
		picto(d) { return AccountTreeOutlinedIcon; }
	}
};

// export const tableStructure = (activeRefines, t) => [
// 	{
// 		Header: "Audited Company",
// 		accessor: "auditedCompany"
// 	},
// 	{
// 		Header: "City",
// 		accessor: "site.city"
// 	},
// 	{
// 		Header: "Country",
// 		accessor: "site.country"
// 	},
// 	{
// 		Header: "Site Type",
// 		accessor: "additionalInfo.siteType"
// 	},
// 	{
// 		Header: "Product",
// 		accessor: "additionalInfo.productCategoryFilter",
// 		// eslint-disable-next-line react/prop-types
// 		Cell: ({ value }) => Array.from(new Set(value))
// 		.reduce((acc, value, currentIndex) => `${acc} ${(currentIndex !== 0) ? "-" : ""} ${value}`, "")
// 	},
// 	{
// 		Header: "Ranking",
// 		accessor: "additionalInfo.finalRating",
// 		// eslint-disable-next-line react/prop-types
// 		Cell: ({ value }) => (
// 			<p className="font-semibold" style={{ color: getMarkerColor(value) }}>
// 				{value}
// 			</p>)
// 	},
// 	{
// 		Header: "NCs",
// 		accessor: "nc",
// 		// eslint-disable-next-line react/prop-types
// 		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
//         bg-red-500 rounded-full h-7 w-7 text-white">{value}</p> : <p/>
// 	},
// 	{
// 		Header: "CNCs",
// 		accessor: "cnc",
// 		// eslint-disable-next-line react/prop-types
// 		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
//         bg-red-500 rounded-full h-7 w-7 text-white">{value}</p> : <p/>
// 	}
// ];

export const tableSectionsStructure = (activeRefines, t) => [
	{
		Header: "Section Level 1",
		accessor: "section1Label"
	},
	{
		Header: "Section Level 2",
		accessor: "section2Label"
	},
	{
		Header: "NCs",
		accessor: "nc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-red-500 rounded-full h-7 w-7 text-white">{value}</p> : <p />
	},
	{
		Header: "CNCs",
		accessor: "cnc",
		// eslint-disable-next-line react/prop-types
		Cell: ({ value }) => (value && value !== 0) ? <p className="flex items-center justify-center text-medium
        bg-red-500 rounded-full h-7 w-7 text-white">{value}</p> : <p />
	}
];

export const getSupplychainChildren = (activeRefines, finalProduct, maxCampaignId) => {
	if (activeRefines.program === "clarity") {
		return getClaritychainChildren(
			finalProduct[1],
			"",
			0,
			finalProduct[0],
			activeRefines?.campaignId ?? 1,
			maxCampaignId ?? 1
		);
	}
	if (activeRefines.program === "supplyR") {
		return getSupplyRchainChildren(finalProduct[1], "", 0, finalProduct[0]);
	}
};

export const treeTooltipBuilder = (activeRefines) => {
	if (activeRefines.program === "clarity") {
		return { tooltip: undefined, displayTooltip: false };
	}
	if (activeRefines.program === "supplyR") {
		return { tooltip: SupplyRTreeTooltipSupplier, displayTooltip: true };
	}
};

// used by Legend
export const colorsAndBreakdowns = {
	supplyr: {
		unsatisfactory: {
			color: "#eb4c72",
			range: [0, 30]
		},
		subStandard: {
			color: "#fe8f71",
			range: [30, 55]
		},
		inProgress: {
			color: "#fec461",
			range: [55, 65]
		},
		average: {
			color: "#e7e401",
			range: [65, 80]
		},
		good: {
			color: "#ccdb74",
			range: [80, 95]
		},
		veryGood: {
			color: "#70ad46",
			range: [95, 100]
		}
	},
	clarity: {
		unsatisfactory: {
			color: "#eb4c72",
			range: [0, 20]
		},
		subStandard: {
			color: "#fe8f71",
			range: [20, 35]
		},
		inProgress: {
			color: "#fec461",
			range: [35, 50]
		},
		average: {
			color: "#e7e401",
			range: [50, 75]
		},
		good: {
			color: "#ccdb74",
			range: [75, 90]
		},
		veryGood: {
			color: "#70ad46",
			range: [90, 100]
		}
	}
};

export const getClarityScoreColor = (score) => {
	if (!score) return "#c9bbbd";
	if (score >= 0 && score < 20) return "#eb4c72";
	if (score >= 20 && score < 35) return "#fe8f71";
	if (score >= 35 && score < 50) return "#fec461";
	if (score >= 50 && score < 75) return "#e7e401";
	if (score >= 75 && score <= 90) return "#ccdb74";
	if (score >= 90 && score <= 100) return "#70ad46";
};

export const getSupplyRScoreColor = (score) => {
	if (!score) return "#c9bbbd";
	if (score >= 0 && score < 30) return "#eb4c72";
	if (score >= 30 && score < 55) return "#fe8f71";
	if (score >= 55 && score < 65) return "#fec461";
	if (score >= 65 && score < 80) return "#e7e401";
	if (score >= 80 && score <= 95) return "#ccdb74";
	if (score >= 95 && score <= 100) return "#70ad46";
};

export const getScoreColor = (score, program) => {
	if (program === "supplyr") {
		return getSupplyRScoreColor(score);
	}
	if (program === "clarity") {
		return getClarityScoreColor(score);
	}
};
