// Create the App Slice
import React, { useEffect, createRef } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useTranslation } from "react-i18next";

import { ParentSize } from "@visx/responsive";
import { use100vh } from "react-div-100vh";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import createAppSlice from "../data/slices/createAppSlice";
import { requestSignIn, userSelector } from "../data/slices/UserSlice";

import SideBar from "../components/menu/SideBar";
import CEEClientHeader from "../components/header/CEEClientHeader";
import HorizontalFilters from "../components/filters/HorizontalFilters";
import { customStylesCompass, customStylesSearchCompass } from "../components/filters/CompassFilterStyle";
import TitleCard from "../components/cards/TitleCard";
import MapLeaflet from "../components/map/MapLeaflet";
import CEELayer from "../components/map/CEELayer";
import ColumnGraph from "../components/graphs/ColumnGraph";
import TimeEvolutionCard from "../components/graphs/TimeEvolutionCard";
import ListOfNonConformities from "../components/cards/ListOfNonConformities";
import CompassScopeCard from "../components/cards/CompassScopeCard";
import PageHeader from "../components/header/PageHeader";
import Table from "../components/table/Table";
import PageMenuHeader from "../components/header/PageMenuHeader";
import PercentIndicatorCard from "../components/cards/PercentIndicatorCard";
import ColorLegend from "../components/legends/ColorLegend";
import SimpleIndicatorCard from "../components/cards/SimpleIndicatorCard";
import SimpleCardWithOptions from "../components/cards/SimpleCardWithOptions";

import Page401 from "../pages/Page401";
import IF from "../utils/IF";
import Loading from "../utils/Loading";

import {
	appDefinition, menuOptions, CEEInitDatasets, CEEInitRefines, CEEGroupFilter, CEESliderFilter,
	filterOptions, clientInitParameters, CEEMapTooltip, getMarkerColor, ratingsConfig, colorLegend, nonConformities, tableStructure
} from "./configs/CEEConfig";
import CompassSiteCard from "../components/cards/CompassSiteCard";
import CompassTooltip from "../components/tooltips/CompassTooltip";
import { openSaml } from "../auth/Authenticator";

const appSlice = createAppSlice(
	appDefinition.slice,
	menuOptions.filter((option) => option.initial)[0].menu,
	CEEInitRefines,
	CEEInitDatasets,
	false,
	CEEGroupFilter,
	CEESliderFilter
);

const mdScreens = 768;

function CEE() {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	// Create and load the app slice in the store
	const store = useStore();

	// Load the store hook
	const dispatch = useDispatch();

	// Load user
	const user = useSelector(userSelector);

	// Get the screen height
	const screenHeight = use100vh();

	// Load ref of content section
	const topRef = createRef();

	// Load the reducer and favicon
	useEffect(() => {
		store.injectReducer(appDefinition.slice, appSlice.reducer);
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const isActiveFilter = useSelector(appSlice.isRefined);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	// Load client parameters as a filter for the indicators
	useEffect(() => {
		dispatch(appSlice.fetchDataset(clientInitParameters));
	},
	[activeRefines?.project]); // eslint-disable-line react-hooks/exhaustive-deps

	// Load client parameters active indicators as an initial filter for the datasets
	// and load the first space as the initial refine for spaceLabel
	useEffect(() => {
		if (clientParameters === undefined || loadDataStatus !== "idle") {
			return;
		}

		if (JSON.stringify(clientParameters?.project) !== JSON.stringify(activeRefines?.project)) {
			dispatch(appSlice.actions.refine([{ key: "project", value: clientParameters?.project }]));
		}
	},
	[clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch and refresh data in the store
	useEffect(() => {
		if (clientParameters === undefined || activeDatasets === undefined
            || activeRefines.project === undefined
            || JSON.stringify(clientParameters?.project) !== JSON.stringify(activeRefines?.project)) {
			return;
		}
		dispatch(appSlice.fetchDatasets());
	}, [activeRefines, clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps

	// dispatch refine on slider value when loaded
	useEffect(() => {
		if (CEESliderFilter && CEESliderFilter?.isSliderFilter && CEESliderFilter?.sliderFields.length > 0) {
			dispatch(appSlice.actions.refine(CEESliderFilter.sliderFields.map((field) => ({ key: field, value: 1 }))));
		}
	}, [CEESliderFilter]);// eslint-disable-line react-hooks/exhaustive-deps

	// Fetch calculated Data to be put in the store
	// useEffect(() => {
	// 	// Fetch total number of non conformities in the store
	// 	if (activeDatasets?.nonConformities?.data?.length > 0) {
	// 		const totalNonConformities = activeDatasets.nonConformities
	// 		.data?.reduce((acc, element) => { acc.nbNC += element.nbNC; acc.nbCNC += element.nbCNC; return acc; },
	// 			{ nbNC: 0, nbCNC: 0 });
	// 		// Dispatch the number of non conformities in the store
	// 		dispatch(
	// 			appSlice.actions.fetchCalculatedDataset({
	// 				datasetName: "totalNonConformities",
	// 				datasetData: totalNonConformities
	// 			})
	// 		);
	// 	}
	// }, [activeDatasets?.nonConformities]); // eslint-disable-line react-hooks/exhaustive-deps

	// Reload window if data expired
	useEffect(() => {
		if (loadDataStatus === "sessionExpired") {
			setTimeout(() => {
				dispatch(appSlice.actions.reset());
				dispatch(requestSignIn());
				openSaml();
			}, 2000);
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	// Go top if menu has changed
	useEffect(() => {
		document?.getElementById("content-scroll")?.scroll({ top: 0, behavior: "smooth" });
	}, [menu, activeRefines?.siteId, activeRefines?.module]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (activeRefines?.travaux !== undefined && activeRefines?.departement !== undefined
			&& menu !== "HOME") {
			dispatch(appSlice.actions.setPage("HOME"));
		}
	}, [menu, activeRefines?.travaux, activeRefines?.departement]); // eslint-disable-line react-hooks/exhaustive-deps

	if (loadDataStatus === "sessionExpired") {
		return <Page401 />;
	}

	// Wait for Data to render App
	if (activeDatasets === undefined) {
		return <Loading message="Wait a moment while we load your app." />;
	}

	// Accessor for distribution scores
	const scoreAccessor = (d) => {
		const index = d.conclusion.indexOf("SATISFAISANT");

		return 100 * (d.totalAssignments[index] / d.totalAssignments.reduce((acc, n) => acc += n, 0));
	};

	// Accessor for the departement score
	const deptScoreAccessor = (d) => d.dptScore * 100;

	return (
		<div className="relative h-full w-full">

			<IF condition={loadDataStatus === "loading"}>
				<Loading />
			</IF>

			<div className="relative flex justify-between max-w-screen-3xl mx-auto h-full bg-clarity_primary-bg"
				id="full-screen">

				{/*
				* Menu Bar of the CEE APP
				*/}
				<SideBar
					appSlice={appSlice}
					appDefinition={appDefinition}
					menuOptions={menuOptions}
					menu={menu}
					noTitle
					colorMenu="#CCDB73"
					colorAccent = "#5FC3BF"
					// indicator={{ menu: "NCs", value: activeDatasets?.totalNonConformities?.data?.nbCNC ?? 0 }}
					locales={appDefinition.locales}
				/>

				{/*
				* Content of the APP
				*/}
				<div id="content-scroll" className="w-full overflow-y-auto"
					style={{ height: screenHeight }}>
					<div ref={topRef} className="flex-grow md:pl-24">

						<IF condition={(menu !== "HOME" || activeRefines.module === undefined)
                        && activeRefines.siteId === undefined}>
							<div className="full-screen-hide">
								<CEEClientHeader clientParameters={clientParameters} locales={appDefinition.locales} />
							</div>
						</IF>

						<IF condition={(menu === "HOME" && activeRefines.module !== undefined)
                        || activeRefines.siteId !== undefined}>
							<div className="md:-ml-4" style={{ width: (window.innerWidth > mdScreens ? "calc(100% + 16px)" : "100%") }}>
								<PageMenuHeader
									appSlice={appSlice}
									Picto={activeRefines.module !== undefined ? VerifiedUserIcon : BusinessIcon}
									filterOption={filterOptions.find((option) => option.label === "Modules")}
									customStylesSearch={customStylesSearchCompass}
									themePalette="compass"
									locales={appDefinition.locales}
								/>
							</div>
						</IF>

						<div className="grid grid-cols-1 grid-flow-row gap-y-4 px-4 py-6 md:px-8 md:grid-cols-4 md:gap-x-8 2xl:px-12 full-screen-hide">

							<IF condition={menu === "HOME" && activeRefines.module === undefined && activeRefines.siteId === undefined}>

								{/* <div className="mb-6 md:mb-10 mt-2 md:col-span-4">
									<p className="text-4xl font-bold mb-1">{t("homePage.header.welcome")} {user.firstName},</p>
									<div className="flex justify-between">
										<p className="pt-1 md:text-lg w-full md:w-2/3 font-light leading-6">
											{t("homePage.header.introduction")}
										</p>
									</div>
								</div> */}

								<div className="col-span-4 xl:col-span-2">
									<div className="col-span-4 xl:col-span-2">
										<TitleCard titleLocal="homePage.modules.title" subTitleLocal="homePage.modules.subTitle"
											infoLocal="homePage.modules.info" theme="compass" locales={appDefinition.locales} />
									</div>

									<div className="grid col-span-full xl:grid-cols-2 xl:col-span-2 gap-4">

										{activeDatasets.typesDeLogements?.data.map((element, i) => element._id &&
										<PercentIndicatorCard
											key={`PercentIndicatorCard ${ i}`}
											appSlice={appSlice}
											dataset={activeDatasets.conclusionsTypesDeLogement}
											moduleKey="typeDeLogement"
											module={element._id}
											ratingKey="conclusion"
											ratingValue="SATISFAISANT"
											ratingsConfig={ratingsConfig}
											scoreKey="totalAssignments"
											locales={appDefinition.locales}/>
										)}
										<SimpleCardWithOptions
											key={`SimpleIndicatorCard ${0}`}
											label="indicators.avgCompletionTime"
											subLabel="indicators.days"
											appSlice={appSlice}
											locales={appDefinition.locales}
											refineKey="avgDureeTrvx"
											options={activeRefines.publicationYear
												? [{
													label: activeRefines.publicationYear,
													dataset: "avgCompletionTime"
												}, {
													label: `YTD (${new Date().getFullYear()})`,
													dataset: "avgCompletionTimeYTD"
												}]
												: [
													{
														label: "Global",
														dataset: "avgCompletionTime"
													},
													{
														label: `YTD (${new Date().getFullYear()})`,
														dataset: "avgCompletionTimeYTD"
													},
													{
														label: "Année dernière",
														dataset: "avgCompletionTimePrevYear"
													},
													{
														label: "Trimestre dernier",
														dataset: "avgCompletionTimePrevQuarter"
													},
													{
														label: "Mois dernier",
														dataset: "avgCompletionTimePrevMonth"
													}

												]}

										/>
										<div className='flex flex-wrap mt-4 justify-center items-center gap-x-4 xl:col-span-2 text-sm' >
											<div className="font-semibold pr-4 border-r">{t("legend.title")}</div>
											{ratingsConfig.map((element, i) => <div key={`legendRating ${ i}`}
												className="flex items-center gap-x-2 ">
												<div className="h-4 w-4 rounded-full" style={{ backgroundColor: element.color }} />
												<p className="text-medium">{element.rating}</p>
											</div>
											)}
										</div>
									</div>

									<div className="col-span-2 my-4 py-4">
										<ParentSize>
											{(parent) => (
												<TimeEvolutionCard appSlice={appSlice}
													nonConformities={nonConformities} dataset="avgScoreNbInspectPerMon" locales={appDefinition.locales}
													showNonConformity={false} dotGroupKey="score100" topInfoKey="totalAssignments" width={parent.width}
													fillBarColor="#CCDB73" graphTop={29} barsGroupTop={-13} barTopInfoTop={-20}
													getFrequency={(d) => d.totalAssignments}
												/>
											)}
										</ParentSize>
									</div>
								</div>

								<div className="col-span-4 xl:col-span-2">
									<div className="col-span-4 xl:col-span-2">
										<TitleCard titleLocal="homePage.details.title" subTitleLocal="homePage.details.subTitle"
											infoLocal="homePage.details.info" theme="compass" locales={appDefinition.locales} />
									</div>

									<div className="relative flex flex-col w-full overflow-hidden rounded-lg xl:col-span-2">
										<ParentSize>
											{(parent) => (
												<MapLeaflet
													appSlice={appSlice}
													datasetCountries="departementScores"
													getMarkerColor={getMarkerColor}
													deptScoreAccessor={deptScoreAccessor}
													countryScoreAccessor={deptScoreAccessor}
													fieldsTooltip={CEEMapTooltip}
													mapType="jawgTerrain"
													maxClusterRadius={30}
													defaultPosition = {[47.221, 2.672]}
													zoom={5}
													minZoom={3}
													maxZoom={10}
													scrollWheelZoom={true}
													mapHeight={ screenHeight - (window.innerWidth > mdScreens ? 350 : 200)}
													locales={appDefinition.locales}
													CustomLayer={CEELayer}
												/>
											)}
										</ParentSize>
										<div className="w-full xl:col-span-2 absolute bottom-1 z-500 flex flex-row">
											<ColorLegend
												locales={appDefinition.locales}
												colorLegend={colorLegend} />
										</div>
									</div>
								</div>

							</IF>

							{/*
							* DISTRIBUTION PAGE
							*/}
							<IF condition={menu === "DISTRIB" && activeRefines.travaux === undefined}>
								<div className="flex flex-col w-full overflow-hidden">
									<TitleCard titleLocal="DistribPage.distribPerTravaux.title"
										subTitleLocal="DistribPage.distribPerTravaux.subTitle"
										infoLocale="" locales={appDefinition.locales} />
								</div>
								<div className="flex flex-col w-full overflow-hidden mt-4 md:mt-0 md:col-span-3">
									<ParentSize>
										{(parent) => (
											<ColumnGraph
												appSlice={appSlice}
												dataset={"conclusionsTravaux"}
												color={"#1876bd"}
												width={parent.width}
												strokeWidth={8}
												gradientStartBarColor="#CBDA72"
												gradientEndBarColor="#6E8F57"
												getScore = {scoreAccessor}
												getModule = {(d) => d.travaux}
												refineKeys = {["travaux"]}
												sortData = {
													(a, b) => (scoreAccessor(a) < scoreAccessor(b)) ? 1 : -1
												}
												locales={appDefinition.locales}
											/>
										)}
									</ParentSize>
								</div>
							</IF>
							<IF condition={menu === "DISTRIB" && activeRefines.travaux !== undefined}>
								<div className="flex flex-col w-full overflow-hidden">
									<TitleCard titleLocal="DistribPage.distribPerDept.title"
										subTitleLocal="DistribPage.distribPerDept.subTitle"
										infoLocale="" locales={appDefinition.locales} />
								</div>
								<div className="flex flex-col w-full overflow-hidden mt-4 md:mt-0 md:col-span-3">
									<ParentSize>
										{(parent) => (
											<ColumnGraph
												appSlice={appSlice}
												dataset={"departementScores"}
												color={"#1876bd"}
												width={parent.width}
												strokeWidth={8}
												gradientStartBarColor="#CBDA72"
												gradientEndBarColor="#6E8F57"
												getScore = {deptScoreAccessor}
												getModule = {(d) => d.departement}
												sortData = {
													(a, b) => (deptScoreAccessor(a) < deptScoreAccessor(b)) ? 1 : -1
												}
												refineKeys={["departement"]}
												locales={appDefinition.locales}
											/>
										)}
									</ParentSize>
								</div>
							</IF>

							{/*
                            * MAP PAGE
                            */}
							<IF condition={menu === "MAP" && activeRefines.siteId === undefined}>
								{/* <div className="hidden md:flex flex-col w-full md:col-span-4">
									<PageHeader
										page="mapPage"
										Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
										locales={appDefinition.locales}
									/>
								</div> */}

								<div className="flex flex-col w-full rounded-3xl overflow-hidden mt-4 md:mt-0 md:col-span-4">
									<MapLeaflet
										appSlice={appSlice}
										datasetCountries="departementScores"
										getMarkerColor={getMarkerColor}
										countryScoreAccessor={deptScoreAccessor}
										fieldsTooltip={CEEMapTooltip}
										mapType="jawgTerrain"
										maxClusterRadius={30}
										defaultPosition = {[47.221, 2.672]}
										zoom={4}
										minZoom={3}
										maxZoom={10}
										scrollWheelZoom={true}
										mapHeight={ screenHeight - (window.innerWidth > mdScreens ? 350 : 200)}
										locales={appDefinition.locales}
										CustomLayer={CEELayer}
									/>
								</div>

								<div className="absolute z-500 bottom-4 right-8 flex flex-row">
									<ColorLegend
										locales={appDefinition.locales}
										colorLegend={colorLegend} />
								</div>

							</IF>

							{/*
                            * NON CONFORMITIES PAGE
                            */}
							{/* <IF condition={menu === "NCs" && activeRefines.siteId === undefined}>

								<div className="hidden md:flex flex-col  w-full md:col-span-4">
									<PageHeader
										page="NCsPage"
										Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
										locales={appDefinition.locales}
									/>
								</div>
								<div className="flex flex-col w-full alphaBlur rounded-lg mt-4 md:mt-0 px-4 md:px-6 py-4 md:col-span-4">
									<ListOfNonConformities
										appSlice={appSlice}
										appNCConfig={{
											nonConformities,
											listNonConformityQuery,
											nonConformityDataset: CompassCheckpoints,
											updateListNonConformities,
											listTagsNonConformities,
											customStylesSubFilter: undefined,
											filterOptions: filterOptions.filter((option) => option.label === "Section" || option.label === "Criticality")
										}}
										dataset="listNonConformities"
										totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
										contentRef={topRef}
										themePalette="compass"
										demoReportLink="88cbfabe-45b5-49bd-92b9-ea1016bf52ce"
										locales={appDefinition.locales} />
								</div>

							</IF> */}

							{/*
                            * SITES TABLE PAGE
                            */}
							<IF condition={menu === "SITEs" && activeRefines.siteId === undefined}>
								<div className="hidden md:flex flex-col  w-full md:col-span-4">
									<PageHeader
										page="SitesPage"
										Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
										locales={appDefinition.locales}
									/>
								</div>
								<div className="flex flex-col w-full alphaBlur rounded-lg mt-4 md:mt-0  py-4  md:col-span-4">
									<Table
										appSlice={appSlice}
										dataset="sitesTable"
										structureTable={tableStructure}
										sortByRefineFields={[{ id: "publicationDate", desc: true }
											/* { id: "cnc", desc: true },
											{ id: "nc", desc: true } */]}
										themePalette="compass"
										locales={appDefinition.locales} />
								</div>

							</IF>

							{/*
                            * SELECTED MODULE or SITE PAGE
                            */}
							<IF condition={(menu === "HOME" && activeRefines.module !== undefined)
                                            || activeRefines.siteId !== undefined}>

								<div className="-mt-28 md:-mt-36 md:col-span-2 flex gap-x-2 h-64 w-full">
									<div onClick={() => dispatch(appSlice.actions
									.refine([{ key: (activeRefines.siteId === undefined ? "module" : "siteId"), value: undefined }]))}
									className="flex items-center text-clarity_primary-accent bg-white rounded-xl
                                                   text-2xl font-bold px-2 cursor-pointer h-10 w-10 z-40">
										<ArrowBackIcon fontSize="inherit" />
									</div>
									{ activeRefines.siteId === undefined && <PercentIndicatorCard
										appSlice={appSlice}
										module={activeRefines.module}
										ratingsConfig={ratingsConfig}
										large
										locales={appDefinition.locales}/>
									}
									{ activeRefines.siteId !== undefined && <div className="alphaBlur rounded-xl px-2 py-3 w-full h-full">
										<CompassSiteCard appSlice={appSlice} siteData={activeDatasets.sites?.data?.[0]}
											demoReportLink="88cbfabe-45b5-49bd-92b9-ea1016bf52ce" locales={appDefinition.locales} />
									</div>
									}
								</div>

								<div className="relative md:-mt-36 h-52 md:h-64 flex flex-col w-full  overflow-hidden rounded-lg md:col-span-2">
									<ParentSize debounceTime={10}>
										{(parent) => (
											<MapLeaflet
												appSlice={appSlice}
												dataset="sites"
												datasetCountries="departementScores"
												getMarkerColor={getMarkerColor}
												deptScoreAccessor={deptScoreAccessor}
												countryScoreAccessor={deptScoreAccessor}
												fieldsTooltip={CEEMapTooltip}
												customTooltip={CompassTooltip}
												mapType="jawgTerrain"
												maxClusterRadius={30}
												defaultPosition = {
													[47.221, 2.672]
												}
												zoom={3}
												minZoom={2}
												maxZoom={10}
												mapHeight={parent.height}
												locales={appDefinition.locales}
											/>
										)}
									</ParentSize>
									{activeRefines.siteId !== undefined && <div
										className="absolute bottom-6 left-1/2 transform -translate-x-1/2 w-4/5 gap-x-2 font-light text-medium
                                               flex justify-center items-center rounded-xl alphaBlur border px-3 py-1 z-500">
										<LocationOnIcon fontSize="small" />
										<p className="line-clamp-1">
											{activeDatasets.sites?.data?.[0]?.site.country}&nbsp;-&nbsp;
											{activeDatasets.sites?.data?.[0]?.site.city}&nbsp;-&nbsp;
											{activeDatasets.sites?.data?.[0]?.site.address}
										</p>
									</div>}
								</div>

								<div className="w-full md:col-span-4 mt-6">
									<TitleCard titleLocal="modulePage.sections.title" subTitleLocal="modulePage.sections.subTitle"
										infoLocal="modulePage.sections.info" theme="compass" locales={appDefinition.locales} />
								</div>

								{/* <div className="flex flex-col w-full alphaBlur rounded-lg py-4 md:col-span-4 ">
									<Table
										pageTable="modulePage"
										appSlice={appSlice}
										dataset="sectionNCsList"
										structureTable={tableSectionsStructure}
										sortByRefineFields={[{ id: "cnc", desc: true },
											{ id: "nc", desc: true }]}
										themePalette="compass"
										locales={appDefinition.locales} />
								</div> */}

								{/* <div className="w-full md:col-span-4 mt-6">
									<TitleCard titleLocal="modulePage.nonconformities.title" subTitleLocal="modulePage.nonconformities.subTitle"
										infoLocal="modulePage.nonconformities.info" theme="compass" locales={appDefinition.locales} />
								</div> */}

								{/* <div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 md:col-span-4 ">
									<ListOfNonConformities
										appSlice={appSlice}
										appNCConfig={{
											nonConformities,
											listNonConformityQuery,
											nonConformityDataset: CompassCheckpoints,
											updateListNonConformities,
											listTagsNonConformities,
											customStylesSubFilter: undefined,
											filterOptions: filterOptions.filter((option) => option.label === "Section" || option.label === "Criticality")
										}}
										dataset="listNonConformities"
										totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
										contentRef={topRef}
										themePalette="compass"
										demoReportLink="88cbfabe-45b5-49bd-92b9-ea1016bf52ce"
										locales={appDefinition.locales} />
								</div> */}

							</IF>

						</div>

					</div>
				</div>

				{/*
				* FILTER SECTION OF THE PAGE
				*/}

				<HorizontalFilters appSlice={appSlice} theme="compass" filterOptions={filterOptions} initRefines={CEEInitRefines}
					classTitle="compass_primary-accent" filterDesktop
					customStyle={customStylesCompass} customStyleSearch={customStylesSearchCompass}
					isActiveFilter={isActiveFilter} locales={appDefinition.locales}>
				</HorizontalFilters>

			</div>

		</div>
	);
}

export default CEE;
