import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ParentSize } from "@visx/responsive";

import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import SimpleDonutGraph from "../graphs/SimpleDonutGraph";

function PercentIndicatorCard({
	appSlice, dataset, moduleKey, module, ratingKey, ratingValue, ratingsConfig, scoreKey, large = false, locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Load data from the store
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const moduleData = dataset.data
	.filter((filterValue) => filterValue[moduleKey] === module);

	const totalScore = moduleData?.reduce((acc, e) => acc += e[scoreKey], 0);
	const ratingConfig = ratingsConfig?.find((e) => e[ratingKey] === ratingValue);

	const donutData = [moduleData?.filter((e) => e[ratingKey] === ratingValue).reduce(
		(acc, curValue, index) => {
			acc = {
				label: ratingValue,
				score: acc.score + (100 * (curValue[scoreKey] / totalScore) ?? 0)
				// ,
				// nbNC: moduleRatingData?.moduleNbNC ?? 0,
				// nbCNC: moduleRatingData?.moduleNbCNC ?? 0
			};
			return acc;
		}, { label: null, score: 0 })];

	const calOverAllScore = (value) => value.reduce((acc, currentValue) => acc
		+ Number((!currentValue.score || Number.isNaN(currentValue.score)) ? 0 : currentValue.score), 0);

	return (<div className="flex flex-col alphaBlur rounded-xl px-2 py-3 w-full h-full
                   gap-y-2 cursor-pointer"
	onClick={() => activeRefines[moduleKey] === undefined ?
		dispatch(appSlice.actions.refine([{ key: moduleKey, value: module }]))
		: dispatch(appSlice.actions.refine([{ key: moduleKey, value: undefined }]))
	}>

		<div className="flex justify-center items-start w-full h-1/5">
			<div className="flex gap-x-1 w-full">
				<VerifiedUserIcon className={`text-compass_primary-accent 
                     ${ large ? "text-3xl" : "text-lg md:text-xl 2xl:text-3xl"}` } />

				<p className={`font-semibold text-compass_primary-accent
                     ${ large ? "text-2xl" : "text-medium md:text-base 2xl:text-lg"}`}>
					{module}
				</p>

			</div>
		</div>
		<div className="flex justify-around items-center w-full">
			<div className="flex flex-col gap-y-2">
				<ParentSize debounceTime={10}>
					{(parent) => (
						<SimpleDonutGraph
							value={donutData}
							donutThickness={10}
							donutInnerThickness={18}
							width={parent.width}
							height={150}
							values={false}
							donutColor={ratingsConfig.map((rating) => rating.color)}
							fontSizeTextCenter={60}
							subTextCenter="%"
							subTextCenterSize={18}
							margin = {{
								top: 10,
								right: 0,
								bottom: -10,
								left: 0
							}}
							textInnerColor={"black"}
							calOverAllScore={calOverAllScore}
						/>
					)}
				</ParentSize>

				<div className="flex flex-wrap justify-center gap-x-6 gap-y-2 items-center text-sm">
					<p className="flex items-center px-2 bg-red-100 rounded-lg">
                		Inspections : {totalScore}
					</p>
					<p className="flex items-center px-2 bg-red-100 rounded-lg">
                		Sites : {moduleData.reduce((acc, v) => acc + (v.totalSites ?? 0), 0)}
					</p>
				</div>
			</div>

			{large && <div className="flex flex-col h-full pt-3">
				<div className="font-semibold pb-2 border-r">Legend</div>
				{ratingsConfig.map((element, i) => <div key={`legendRating ${ i}`}
					className="flex items-center gap-x-2 ">
					<div className="h-4 w-4 rounded-full" style={{ backgroundColor: element.color }} />
					<p className="text-medium">{element.rating}</p>
				</div>
				)}

			</div>}
		</div>

	</div>);
}

PercentIndicatorCard.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.object,
	moduleKey: PropTypes.string,
	module: PropTypes.string,
	ratingKey: PropTypes.string,
	ratingValue: PropTypes.string,
	ratingsConfig: PropTypes.array,
	scoreKey: PropTypes.string,
	large: PropTypes.bool,
	locales: PropTypes.string
};

export default PercentIndicatorCard;
