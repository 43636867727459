import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import VariableRadiusDonutBigger from "../graphs/VariableRadiusDonutBigger";
import TitleCard from "./TitleCard";

const capitalize = (dataInfo) => dataInfo.charAt(0).toUpperCase() + dataInfo.slice(1);

const dataInfoBuilder = ({ data, scopelist, scopeKey }) => {
	const res = scopelist?.map((item) => {
		const [label, color] = Object.values(item);

		return {
			label,
			value: (data?.find((element) => element[scopeKey] === capitalize(label)))?.frequency ?? 0,
			color
		};
	});

	return res;
};

const VerkorActionPlanScope = ({
	titleLocale = "analysis.scope",
	appSlice = null,
	dataset = "",
	scopeList,
	categoriesListDataset = "listSupplierCriticalities",
	categoriesKey = "supplierCriticality",
	categoriesLocale = "analysis.scope.criticality",
	totalLocaleKey = "factory",
	displayPills = true,
	displayInner = true,
	total = 0,
	totalCovered = 0,
	planedCount = 0,
	inProgressCount = 0,
	delayedCount = 0,
	counterPills = [{ id: "assessed", counter: totalCovered }],
	locales,
	showTitleCard = true,
	refineOnClick = false,
	innerText = undefined,
	apTargetDate,
	theme,
	children
}) => {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const dispatch = useDispatch();

	const { data } = useSelector(appSlice.selectDatasets)[dataset];
	const activeRefines = useSelector(appSlice.selectActiveRefines);

	const projectScopeList = scopeList;

	const dataInfo = dataInfoBuilder({ data, scopelist: projectScopeList, scopeKey: categoriesKey });

	const totalFrequency = dataInfo?.reduce((acc, element) => acc + (element?.value ?? 0), 0);

	const actionPlanTargetDate = new Date(apTargetDate).toLocaleString("fr-FR", {
		year: "numeric",
		month: "numeric",
		day: "numeric"
	});

	const actionPlanTargetDateDelayed = !(new Date(apTargetDate) > new Date());

	// If there are more than one action plan the text in the donut would be different
	const isAggregatedView = data?.length > 1;

	return <>
		{showTitleCard && <TitleCard titleLocal={`${titleLocale}.title`} subTitleLocal=""
			small infoLocal={`${titleLocale}.desc`} theme={theme} locales={locales} />}
		<div className="flex flex-col xl:flex-row justify-center xl:justify-between items-center mx-4">
			<div className="flex flex-col gap-4 justify-center my-4">
				<div className="flex flex-row justify-center xl:flex-col gap-4 mb-4">
					{dataInfo?.map((data, index) => (
						<span key={`scope-${data}-${index}`}
							className={`flex justify-start items-center text-base
							${refineOnClick && data?.value ? "cursor-pointer" : "pointer-events-none"}`}
							onClick={() => Object.keys(activeRefines)?.includes("actionPlanNcStatus")
								? dispatch(appSlice.actions.clear([{ refine: "actionPlanNcStatus" }]))
								: dispatch(appSlice.actions.refine([
									{ key: "actionPlanNcStatus", value: data?.label }]))}>
							<div className={"w-2 h-2 rounded-full mr-1"} style={{ backgroundColor: data.color }}></div>
							{t(`${categoriesLocale}.${data.label}`)} {((data.value / totalFrequency) * 100).toFixed(0)}%
						</span>
					))}
				</div>
				<div className="flex flex-col gap-y-1 justify-center ">

					<div className="flex gap-4 items-center justify-center">
						<div className="flex flex-col justify-center 2xl:flex-row 2xl:items-center">
							<p className="mr-2">
								<span className="text-base mr-1">
									{total}
								</span>
								<span className="text-xs">
									{t(`analysis.scope.${totalLocaleKey}`, { count: total })}
								</span>
							</p>
						</div>
						{displayPills && <div className="grid gap-2">
							{counterPills.map((pill) => (
								<p key={`pill-${pill.id}`} className={`text-small text-center ${pill.color} ${pill.textWhite && "text-white"}
								px-2.5 rounded`}>
									{((pill.counter / total) * 100).toFixed(0)}% {t(`analysis.scope.${pill.id}`)}
								</p>
							))}
						</div>}
					</div>
				</div>
			</div>
			<div className="xl:-mt-8">
				<VariableRadiusDonutBigger dataInfo={dataInfo} total={total}
					totalCovered={totalCovered} planedCount={planedCount} inProgressCount={inProgressCount}
					displayInner={displayInner} innerText={innerText}/>
			</div>
			<div className={`${isAggregatedView ? "invisible" : "xl:self-end my-4"}`}>
				<span className="text-sm">Target date: </span>
				<span className={`${actionPlanTargetDateDelayed && "font-semibold text-risk-high"}`}>{actionPlanTargetDate}</span>
			</div>

		</div>
	</>;
};

VerkorActionPlanScope.propTypes = {
	appSlice: PropTypes.object,
	dataset: PropTypes.string,
	scopeList: PropTypes.object,
	categoriesListDataset: PropTypes.string,
	categoriesKey: PropTypes.string,
	titleLocale: PropTypes.string,
	categoriesLocale: PropTypes.string,
	totalLocaleKey: PropTypes.string,
	total: PropTypes.number,
	totalCovered: PropTypes.number,
	planedCount: PropTypes.number,
	inProgressCount: PropTypes.number,
	delayedCount: PropTypes.number,
	counterPills: PropTypes.array,
	locales: PropTypes.string,
	showTitleCard: PropTypes.bool,
	displayPills: PropTypes.bool,
	displayInner: PropTypes.bool,
	refineOnClick: PropTypes.bool,
	children: PropTypes.object,
	innerText: PropTypes.string,
	apTargetDate: PropTypes.string,
	theme: PropTypes.string
};

export default VerkorActionPlanScope;
