import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import ClearButton from "../filters/ClearButton";

import FilterMenu from "../filters/FilterMenu";
import { customStylesSearchSupplyR, customStylesSupplyRMobile } from "../filters/SupplyRFiltersStyle";
import { filterOptions, vendorDDInitRefines } from "../../apps/configs/VendorDDConfig";
import HorizontalFilters from "../filters/HorizontalFilters";

import IF from "../../utils/IF";

function VendorDDHeader({ appSlice, locales }) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	// Load the store hook
	const dispatch = useDispatch();

	// Find related products to current auditedcompany
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const isActiveFilter = useSelector(appSlice.isRefined);
	const activeDatasets = useSelector(appSlice.selectDatasets);

	return (
		<>
			<header className="relative flex flex-col w-full gap-x-6 gap-y-6 px-6 mt-6 md:mt-12 md:mb-6  z-500 ">

				<div className="flex items-center justify-between w-full">

					<div className="flex flex-row">
						<div className="flex flex-col">
							<p className="font-black text-4xl md:text-6xl text-white md:text-black uppercase">
								{clientParameters?.client ?? "CLIENT"}
							</p>
							<p className="bg-vendorDD_primary-menu text-white font-semibold px-3 md:px-6 py-1">
								{t("homePage.header.tag")}
							</p>
						</div>
						<div className="flex m-4">
							<img className="w-1/2" src={`/images/CompanyLogos/${clientParameters?.logo}`}/>
						</div>
					</div>

					<div className="hidden md:inline text-3xl md:text-5xl font-extrabold  mb-1 mr-6">
						<IF condition={activeRefines.vendorId === undefined}>
							<Trans i18nKey="homePage.header.headline">
								<span className="text-white">overall</span> status
							</Trans>
						</IF>
						<IF condition={activeRefines.vendorId !== undefined}>
							<p className="text-white">{activeDatasets.sites?.data?.[0]?.vendorId}</p>
						</IF>
					</div>

				</div>

				<div className=" hidden md:flex flex-col justify-center mt-3 space-y-2 w-full">

					{/* main filters */}
					<div className="flex flex-col justify-center  space-y-2 w-full z-20">

						<div className="flex flex-grow items-center gap-x-6 ">
							{filterOptions
							.filter((option) => !option.manualPosition)
							.map((options, i) => (
								<FilterMenu
									key={`menu ${ i}`}
									appSlice={appSlice}
									options={options}
									cleanStyle
									menuSelectedColor="#1764FD"
									displayNb
									manualRenderOption
									clearOnChange={filterOptions.filter((option) => option.clear)}
									locales={locales}
								/>
							))}

							<div className="self-end ml-2">
								<ClearButton appSlice={appSlice} isActiveFilter={isActiveFilter}
									options={filterOptions.filter((option) => option.clear)}
									inLine={false} locales={locales}/>
							</div>
						</div>
					</div>

					{/* subfilter */}
					<div className="flex flex-wrap gap-x-4 gap-y-1  ">
						{filterOptions.filter((option) => option.isMulti && Object.keys(activeRefines)
						.includes(option.refine))
						.map((option, i) => {
							if (!activeRefines[option.refine]) {
								return null;
							}
							const listOptions = activeDatasets[option.loadFromDataset];

							const refine = Array.isArray(activeRefines[option.refine]) ?
								activeRefines[option.refine] : [activeRefines[option.refine]];
							return (
								<div key={i} className="flex justify-center items-center text-medium">
									{t(option.placeHolder)}:
									{refine.map((item, i) => (<button key={i} type="button"
										className="bg-supplyr_primary-menu text-white rounded-lg p-1.5 ml-2"
										onClick={() => {
											dispatch(appSlice.actions.deleteFromSubFilter({ field: option.refine, item }));
										}}>
										{option.displayField ?
										// if displayField, retrieve the associated value
											listOptions?.data?.find((e) => e[option.refine] === item)[option.displayField]
										// else directly display the refined value
											: item }
										<CloseIcon fontSize="x-small" className="ml-3 text-gray-400" />
									</button>))}
								</div>
							);
						})}
					</div>

				</div>

			</header>

			{/*
            * MOBILE VERSION OF THE FILTERS
            */}
			<div className="inline md:hidden">
				<HorizontalFilters appSlice={appSlice} filterOptions={filterOptions} initRefines={vendorDDInitRefines}
					siteMenuLabel="Vendors" customStyle={customStylesSupplyRMobile} customStyleSearch={customStylesSearchSupplyR}
					classTitle="vendorDD_primary-menu" noTitle isActiveFilter={isActiveFilter} locales={locales} filterDesktop >
					<div className="absolute top-0 left-3 w-2/3 h-32 overflow-hidden " >
						<img onError={(ev) => ev.target.src = "/images/SupplyR/Markets/default.jpg" }
							src={`/images/SupplyR/Markets/${clientParameters?.market}`} alt="Market Img"
							className="absolute top-0 w-full h-full rounded-b-full" style={{ zIndex: -1 }}/>
					</div>
				</HorizontalFilters>
			</div>
		</>
	);
}

VendorDDHeader.propTypes = {
	appSlice: PropTypes.object,
	locales: PropTypes.string
};

export default VendorDDHeader;
