import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import TitleCard from "../cards/TitleCard";
import FilterMenu from "./FilterMenu";
import ClearButton from "./ClearButton";
import GetMenuValues from "./GetMenuValues";

import Slider from "./Slider";
import DatePicker from "./SimpleDatePicker";
import SwitchSelector from "./SwitchSelector";

function RenderFilters({
	appSlice, filterOptions, isActiveFilter, siteMenuLabel, classTitle, theme, noTitle = false,
	customStyle, customStyleSearch, customDatePickerStyles, children, locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const clientParameters = useSelector(appSlice.selectClientParameters);

	return 	(
		<>
			<div className="flex pt-4 ">
				{children}
			</div>
			<div className="flex flex-col space-y-6 md:space-y-8 pt-6 w-full">
				<div className="flex justify-between items-center">
					{noTitle ? <p>&nbsp;</p> : <TitleCard titleLocal="filters.title" classTitle={`text-${classTitle}`} small
						theme={theme} infoLocal="filters.info" locales={locales} />}
					<div className="flex-shrink-0">
						<ClearButton appSlice={appSlice} isActiveFilter={isActiveFilter}
							options={filterOptions.filter((option) => option.clear)} locales={locales} />
					</div>
				</div>
				{siteMenuLabel && <div>
					<FilterMenu
						appSlice={appSlice}
						options={filterOptions.find((option) => option.label === siteMenuLabel)}
						customStyle={customStyleSearch}
						menuColor={"grey"}
						clearOnChange={filterOptions.find((option) => option.label === siteMenuLabel)?.clear ?? []}
						className={"w-full"}
						isSearch
						cleanStyle
						locales={locales}
					/>
				</div>
				}
				{/* <div className="flex flex-col space-y-4">
                    <p className="font-semibold text-sm pb-2">
                            Score
                    </p>
                    <div className="w-full px-4 pt-4">
                        <RangeSlider />
                    </div>
                </div> */}
				{filterOptions.filter((option) => !option.manualPosition).map((options, i) => {
					const menuData = activeDatasets?.[options?.loadFromDataset]?.data;
					const listValue = GetMenuValues(menuData, options?.refine, options?.displayField);

					if (options?.onlyIfRefine !== undefined && !options?.onlyIfRefine(activeRefines, clientParameters)) {
						return null;
					}
					if (options.hiddenIfUnique && listValue.length < 2) {
						return null;
					}
					if (options.hiddenIfEmpty && listValue.length === 0) {
						return null;
					}
					if (options?.mobileNotRender) { return null; }

					if (options.isSlider) {
						return <Slider
							appSlice={appSlice} sliderColor={options.sliderColor} refineKey={options.refine} options={options} locales={locales}/>;
					}

					if (options.isDatePicker) {
						return <DatePicker appSlice={appSlice} label={options.label} locales={locales} customDatePickerStyles={customDatePickerStyles} />;
					}

					if (options.isSwitchSelector) {
						return 	(
							<div className="flex flex-col md:space-y-4">
								<p className="relative font-semibold text-sm">
									{t(`filters.${options.label}`)}
								</p>
								<div className={options.swtich.className}>
									<SwitchSelector
										onChange={(value) => { options.swtich.onChange(value); }}
										options={options.swtich.SwitchSelectorOpt}
										initialSelectedIndex={options.swtich.initialSelectedIndex}
										backgroundColor={options.swtich.bgColor}
										selectedfontcolor={options.swtich.selectedfontcolor}
										selectionIndicatorMargin={options.swtich.selectionIndicatorMargin}
									/>
								</div>
							</div>
						);
					}

					return (
						<div key={`options ${i}`} className="flex flex-col md:space-y-4">
							<p className="font-semibold text-sm pb-2">
								{t(`filters.${options.label}`)}
							</p>
							<FilterMenu
								key={i}
								appSlice={appSlice}
								options={options}
								customStyle={customStyle}
								clearOnChange={filterOptions.filter((option) => option.clear)}
								className={"w-full"}
								locales={locales}
							/>
						</div>

					);
				})}
			</div>
		</>);
}

RenderFilters.propTypes = {
	appSlice: PropTypes.object,
	filterOptions: PropTypes.array,
	isActiveFilter: PropTypes.bool,
	siteMenuLabel: PropTypes.string,
	classTitle: PropTypes.string,
	theme: PropTypes.string,
	noTitle: PropTypes.bool,
	customStyle: PropTypes.func,
	customStyleSearch: PropTypes.func,
	customDatePickerStyles: PropTypes.object,
	children: PropTypes.object,
	locales: PropTypes.string
};

function HorizontalFilters({
	appSlice, filterOptions, initRefines = {}, isActiveFilter, siteMenuLabel = undefined, classTitle = "clarity_primary-accent",
	theme = undefined, customStyle, customStyleSearch, customDatePickerStyles = {}, children, locales, filterDesktop = false, noTitle = false,
	hideIconScreenSize = "md"
}) {
	// Set up state for mobile Filter Menu
	const [filtersMenu, setFiltersMenu] = useState(false);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const nbActiveFilters = ((Object.keys(activeRefines ?? {})?.filter((element) => element !== "undefined").length ?? 0)
    - (Object.keys(initRefines ?? {})?.length ?? 0));

	return (
		<>
			{/*
            * Filters Desktop
            */}
			{filterDesktop && <div className="hidden md:flex flex-col flex-shrink-0 h-full overflow-y-auto
							  rounded-bl-3xl bg-white w-80 border-r px-4 py-6 border-gray-100">
				<RenderFilters appSlice={appSlice} filterOptions={filterOptions} siteMenuLabel={siteMenuLabel}
					classTitle={classTitle} customStyle={customStyle} theme={theme} customStyleSearch={customStyleSearch}
					customDatePickerStyles={customDatePickerStyles} isActiveFilter={isActiveFilter} locales={locales}>
					{children}
				</RenderFilters>
			</div>}
			{/*
            * Filters Mobile
            */}
			<div className={`absolute top-0 pt-4 right-0 ${hideIconScreenSize}:hidden flex justify-end items-start z-xl 
            transition-all  duration-500 ease-in-out 
             ${ !filtersMenu ? " mr-6 w-12 h-16" : "h-full w-full"}`}>
				<div className="relative bg-white shadow-xl rounded-2xl w-full h-full  z-10"
				>
					<button className={`absolute top-0 rounded-2xl p-3 outline-none
                    text-${classTitle} cursor-pointer z-2xl 
                    ${ !filtersMenu ? "bg-white right-0" : "  bg-transparent right-6"}`}
					onClick={() => setFiltersMenu(!filtersMenu)}>
						<FilterAltIcon />
					</button>

					{nbActiveFilters > 0 && <p className={`absolute -top-1 w-5 h-5 flex items justify-center
                    bg-gray-200  text-small font-semibold leading-5 rounded-full shadow-2xl z-2xl ${ !filtersMenu ? " -right-1" : " right-4"}`}>
						{nbActiveFilters}
					</p>
					}

					{filtersMenu && <div className="px-8 pt-4 pb-28 h-full overflow-y-auto animate-fade-in-long" >
                    	<RenderFilters appSlice={appSlice} filterOptions={filterOptions} siteMenuLabel={siteMenuLabel}
							classTitle={classTitle} theme={theme} customStyle={customStyle} customStyleSearch={customStyleSearch}
							noTitle={noTitle} isActiveFilter={isActiveFilter} locales={locales}>
                    		{children}
                    	</RenderFilters>
                    	<button className={"w-full rounded-xl  border py-3 mt-6 cursor-pointer "}
							onClick={() => setFiltersMenu(!filtersMenu)}>
                            Apply and close
                    	</button>
					</div>
					}
				</div>

			</div>
		</>
	);
}

HorizontalFilters.propTypes = {
	appSlice: PropTypes.object,
	filterOptions: PropTypes.array,
	initRefines: PropTypes.object,
	isActiveFilter: PropTypes.bool,
	siteMenuLabel: PropTypes.string,
	classTitle: PropTypes.string,
	theme: PropTypes.string,
	customStyle: PropTypes.func,
	customStyleSearch: PropTypes.func,
	customDatePickerStyles: PropTypes.object,
	children: PropTypes.object,
	locales: PropTypes.string,
	filterDesktop: PropTypes.bool,
	noTitle: PropTypes.bool,
	hideIconScreenSize: PropTypes.string
};

export default HorizontalFilters;
